import {Component, OnInit} from "@angular/core";
import {PageBase} from "../../../../pagebase/pagebase";
import {MatDialogConfig} from "@angular/material/dialog";
import {AddClaimExpensePopupComponent} from "./add_claim_expense_popup/add_claim_expense_popup.component";

@Component({
  selector: 'app-approve-expense-claim',
  templateUrl: '../../../../pagebase/pagebasev2.html'
})
export class ApproveExpenseClaimComponent extends PageBase implements OnInit, IPage {

  showCustomFilter = true;
  customFilter: any = {
    name: 'Status',
    key: 'status',
    value: 'new',
    values: [],
    show: true
  };
  expensetypes = [];

  ngOnInit(): void {
    this.sharedService.setHeader('Approve Expense Claims');
    this.getAuthorizedActions();
    this.customFilter.values = [{id: '', itemName: 'All'}, {id: 'new', itemName: 'New'}, {id: 'pending', itemName: 'Pending'}, {id: 'approved', itemName: 'Approved'}, {id: 'rejected', itemName: 'Rejected'},
      {id: 'settled', itemName: 'Settled'}];
    this.statusConfig.success = 'approved';
    this.statusConfig.warning = 'pending';
    this.statusConfig.error = 'rejected';
    this.statusConfig.new = 'new';
    this.statusConfig.processed = 'settled';
    this.API_URL = '/api/sales_expenses/expense_claims_list';
    this.tabIndex = 1;
    this.loadServerData();
  }

  configureGrid() {
    const inThis = this;
    this.columnDefs = [
      {
        headerName: 'Date', field: 'expense_date', pinned: 'left', width: 100, lockPosition: true,
        cellRenderer(params) {
          if (params.data && params.data.state === 'TOTAL') {
            const displayElem = '<span style="font-weight: bold">' + params.value + '</span>';
            return displayElem;
          } else {
            return params.value;
          }
        }
      },
      {headerName: 'User', field: 'user_name', pinned: 'left', width: 90},
      {
        headerName: 'Claimed', field: 'total_claim', filter: 'agNumberColumnFilter', cellStyle: {textAlign: 'right'}, width: 80,
        cellRenderer(params) {
          if (params.data.max_claim_exceeded) {
            const displayElem = '<span style="color:#E24260; font-weight: bold">' + params.value + '</span>';
            return displayElem;
          } else {
            return params.value;
          }
        }
      },
      {headerName: "Approved", field: 'appr_amt', cellStyle: {textAlign: 'right'}, width: 80},
      {headerName: "Rejected", field: 'reject_amt', cellStyle: {textAlign: 'right'}, width: 80},
      {headerName: "Expense Type", field: 'policy', width: 120},
      {headerName: "Claim Status", field: 'claim_status', width: 80},
      {headerName: "Remarks", field: 'comments', width: 150},
      {headerName: "Status", field: 'state', width: 80, cellRenderer: inThis.sharedService.statusCellRenderer}
    ];
  }

  postProcessData() {
    this.rowData = [];
    if (this.apiData != null && this.apiData.hasOwnProperty('master') && this.apiData.hasOwnProperty('detail')) {
      const master = this.apiData.master;
      const detail = this.apiData.detail;
      this.expensetypes = this.apiData.detail.map(p => p.exp_type)
        .filter((type, index, arr) => arr.indexOf(type) === index);
      for (const key in master) {
        let indrow = {};
        let masterRow: any = {};
        const detailRow: any = {};
        let detailsLine = [];

        masterRow = master[key];

        detailsLine = detail.filter(dt => dt.expense_date === masterRow.expense_date && dt.master_id === masterRow.master_id);

        if (detailsLine != null && detailsLine != undefined && detailsLine.length > 0) {
          for (const key1 in detailsLine) {
            detailRow[detailsLine[key1].exp_type] = detailsLine[key1].total;
            detailRow['max_claim_' + detailsLine[key1].exp_type] = detailsLine[key1].max_claim;

          }
        }

        indrow = {
          ...masterRow,
          ...detailRow
        };
        this.rowData.push(indrow);
      }
      this.configureGrid();
    }
  }

  onCellClicked(event) {
    const rows = this.gridApi.getSelectedRows();
    const filteredRowData = this.gridApi.rowModel.rowsToDisplay;
    const column = event.column.getColId();
    if (filteredRowData !== null && filteredRowData.length > 0) {
      const ids = [];
      filteredRowData.forEach(r => {
        ids.push(r.data.id);
      });
      localStorage.setItem('active_ids', JSON.stringify(ids));
      localStorage.setItem('activeID', event.data.id);
      this.router.navigate([this.apiService.redirect_url + '/dms/expense_claims_detail']);
    }
  }
}
