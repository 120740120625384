import {Component, ElementRef, Inject, OnInit, Renderer2, ViewChild} from "@angular/core";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {ApiService} from "../../../_services/api.service";
import {Router} from "@angular/router";
import {DatePipe, DOCUMENT} from "@angular/common";
import {SharedService} from "../../../_services/shared_service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {ConfirmDialogComponent} from "../../../components/confirmdialog/confirmdialog.component";
import {DomSanitizer} from "@angular/platform-browser";
import {ImagePopupComponent} from "../../../components/image_popup/image_popup.component";

@Component({
  selector: 'app-scheme-master-detail-component',
  templateUrl: './scheme_master_detail.component.html',
  styleUrls: ['./scheme_master_detail.component.scss']
})
export class SchemeMasterDetailComponent implements OnInit {

  @ViewChild('imageClick') imageClick: ElementRef<HTMLElement>;
  public columnDefs = [];
  public columnDefs1 = [];
  public rowData = [];
  public rowData1 = [];
  public gridOptions: GridOptions;
  public gridOptions1: GridOptions;
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public loaded1 = false;
  public preloader1 = false;
  public empty_state1 = false;
  public defaultColDef;
  public styleGrid;
  public frameworkComponents;
  public context;
  public pinnedTotalData = [
    {
      code: 'Add an Item',
      image: 'bottom',
      actions: 'bottom'
    }
  ];
  public schemeDetails: any = {};
  public edit = false;
  API_URL = '';
  user_data = {
    date: null,
    access_token: null,
    url: null,
    data: {
      start_date: null,
      end_date: null,
      id: null
    }
  };
  viewtype = 'edit';
  selectedOutlets = [];
  public photo;
  res_data;
  task_types = [];
  selected_task_type: any = {};
  allow_start_date: boolean = true;
  private gridApi;
  private gridColumnApi;

  constructor(private apiService: ApiService,
              private router: Router,
              private datePipe: DatePipe,
              private renderer: Renderer2,
              private sharedService: SharedService,
              private elRef: ElementRef,
              public dialog: MatDialog,
              public domSanitizer: DomSanitizer,
              private toastr: ToastrService,
              @Inject(DOCUMENT) private _document: Document) {

    this.user_data.access_token = localStorage.getItem('resfreshToken');

    this.task_types = JSON.parse(localStorage.getItem('task_types'));
    console.log(this.task_types);

    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left'},
      /* getRowStyle: params => {
         if (params.node.rowIndex === params.api.rowModel.rowsToDisplay.length - 1){
           return { background: 'red' };
         }
         else {
           return {}
         }
       },*/
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      rowSelection: 'multiple'
    } as GridOptions;

    this.gridOptions1 = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple'
    } as GridOptions;

    let datas: any = {};
    datas = history.state;

    // console.log(datas);
    this.user_data.data.id = localStorage.getItem("schemeid");
    this.viewtype = datas.viewtype;
    if (this.viewtype === 'create') {
      this.edit = true;
    }

    /*  this.schemeDetails.name = "Johnie Walker Gift Display Promo";
      this.schemeDetails.description = "Gift Display Promo";
      this.schemeDetails.type = "Activation";
      this.schemeDetails.start_date = "12/06/2021";
      this.schemeDetails.end_date = "19/06/2021";*/

    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;

    this.columnDefs = [
      {headerName: "Code", headerClass: 'myagheader', field: "code", sortable: true, filter: true, lockPosition: true, width: 100, resizable: true},
      {headerName: "Name", headerClass: 'myagheader', field: "name", sortable: true, filter: true, lockPosition: true, width: 180, resizable: true}
    ];
    //this.loaded = true;
    this.fetchschemeDetails();
  }

  ngOnInit(): void {
  }

  fetchschemeDetails() {
    this.API_URL = '/api/sale_schemes/get_scheme_detail';
    this.apiService.post(this.API_URL, this.user_data)
      .subscribe(res => {
          console.log(res);
          this.loaded = true;
          if (res.hasOwnProperty('results') && (res.results.status <= 200)) {

            if (res.results.status < 200 && res.results.msg !== null) {
              this.toastr.warning(res.results.msg);
            }

            this.schemeDetails = res.results.data;
            this.rowData = this.schemeDetails.retailers;
            console.log(this.schemeDetails);
            console.log(this.rowData);
            if (this.gridApi !== null && typeof this.gridApi !== 'undefined') {
              this.gridApi.setRowData(this.rowData);
            }
            //this.loaded = true;
          } else {
            this.preloader = false;
            //this.empty_state = true;
          }
        },
        error => {
          console.log(error);
          this.preloader = false;
        });
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData);
    //this.gridApi.setDomLayout('autoHeight');
    //console.log(this.rowData);
    this.preloader = false;
    // this.gridApi.setDomLayout("autoHeight");

    const sortModel = [
      {colId: 'Date', sort: 'desc'}
    ];

    //this.gridApi.setSortModel(sortModel);
    //console.log(this.rowData);

    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });

    if (screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }

    // this.gridColumnApi.autoSizeColumns();


    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    if (column === 'delete') {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "550px",
        maxHeight: "250px",
        data: {
          title: "Delete Task Line !!",
          message1: rows[0].retailer_code + " - " + rows[0].retailer,
          message2: "",
          showNoButton: true,
          yesButton: "DELETE",
          noButton: "CANCEL",
          deleteButton: true
        }
      };

      const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);

      confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

        console.log(data);
        if (data === 'yes') {
          rows[0].active = false;
          this.createOrUpdateTask();
        } else {

        }
      });
    } else {
      if (rows !== null && rows.length > 0 && rows[0].hasOwnProperty('task_store_fname') && rows[0].task_store_fname !== null && rows[0].task_store_fname !== "") {
        if (screen.width > 991) {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "70%",
            maxHeight: "95%",
            data: {
              store_fname: rows[0].task_store_fname,
              container: "task_line"
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        } else {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "90%",
            maxHeight: "65%",
            data: {
              store_fname: rows[0].task_store_fname,
              container: "task_line"
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        }

      } else {

      }

    }

  }

  editClick() {
    this.edit = !this.edit;
    this.gridApi.setRowData(this.rowData);
  }

  discardClick() {
    this.edit = !this.edit;
    this.gridApi.setRowData(this.rowData);
    this.fetchschemeDetails();
  }

  cancelClick() {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "550px",
      maxHeight: "250px",
      data: {
        title: "Delete Task!!",
        message1: "",
        message2: "Do you wish to Delete this Task?",
        showNoButton: true,
        yesButton: "DELETE",
        noButton: "CANCEL",
        deleteButton: true
      }
    };

    const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);

    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

      console.log(data);
      if (data === 'yes') {
        this.schemeDetails.active = false;
        this.createOrUpdateTask();
      } else {

      }
    });
  }

  createDialog() {
  }

  quickSearch() {
    this.gridApi.setQuickFilter(document.getElementById('filter-text-box')['value']);
  }

  setStartDate(dt) {
    //console.log(this.newExpenseData.expense_date)
    this.schemeDetails.start_date = this.datePipe.transform(this.schemeDetails.start_date, 'yyyy-MM-dd');
  }

  setEndDate(dt) {
    //console.log(this.newExpenseData.expense_date)
    this.schemeDetails.end_date = this.datePipe.transform(this.schemeDetails.end_date, 'yyyy-MM-dd');
  }

  createOrUpdateTask() {
    console.log(this.schemeDetails);
    let paramObject: any = {};
    let apiTaskData: any = {};
    apiTaskData.id = this.schemeDetails.id;
    apiTaskData.name = this.schemeDetails.name;
    apiTaskData.type_id = this.schemeDetails.type_id;
    apiTaskData.code = this.schemeDetails.code;
    apiTaskData.type_id = this.schemeDetails.type_id;
    apiTaskData.description = this.schemeDetails.description;
    apiTaskData.instructions = this.schemeDetails.instructions;
    apiTaskData.start_date = this.schemeDetails.start_date;
    apiTaskData.end_date = this.schemeDetails.end_date;
    apiTaskData.active = this.schemeDetails.active;
    apiTaskData.status = this.schemeDetails.status;
    if (this.schemeDetails.brand_id) {
      apiTaskData.brand_id = this.schemeDetails.brand_id;
    }
    apiTaskData.line_items = [];
    paramObject.access_token = this.user_data.access_token;
    for (let key in this.schemeDetails.line_items) {
      let ind_line: any = {};
      ind_line.task_id = this.schemeDetails.id;
      ind_line.id = this.schemeDetails.line_items[key].id;
      ind_line.partner_id = this.schemeDetails.line_items[key].partner_id;
      ind_line.status = this.schemeDetails.line_items[key].status;
      ind_line.active = this.schemeDetails.line_items[key].active;
      ind_line.comments = this.schemeDetails.line_items[key].comments;
      ind_line.task_store_fname = this.schemeDetails.line_items[key].task_store_fname;
      ind_line.reason_id = this.schemeDetails.line_items[key].reason_id;
      if (this.schemeDetails.line_items[key].hasOwnProperty('user_id')) {
        ind_line.user_id = this.schemeDetails.line_items[key].user_id;
      }
      apiTaskData.line_items.push(ind_line);
    }
    if (!apiTaskData.hasOwnProperty("status")) {
      apiTaskData.status = 'draft';
    }


    paramObject.data = apiTaskData;
    this.API_URL = '/api/tasks/create_task';
    console.log(this.schemeDetails);
    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          if (this.viewtype === 'create') {
            this.toastr.success('Task created Successfully');
            this.viewtype = 'detail';
          } else {
            this.toastr.success('Task updated Successfully');
          }

          if (res.results.hasOwnProperty('task_id')) {
            localStorage.setItem("taskid", res.results.task_id);
          }

          if (this.edit) {
            this.edit = !this.edit;
          }

          if (this.schemeDetails.active) {
            //this._document.defaultView.location.reload();
            this.fetchschemeDetails();
          } else {
            window.history.go(-1);
          }

        } else if (res.hasOwnProperty('results')) {
          this.toastr.error(res.results.msg);
        } else {
          this.toastr.error("Task Updation Failed");
        }
      });

  }

  checkAllowStartDate() {
    if (this.task_types !== null) {
      let typeid = this.schemeDetails.type_id;
      if (typeid) {
        typeid = parseInt(typeid, 0);
        const allowStartDate = this.task_types.filter(type => type.id === typeid).map(obj => obj.allow_start_date);
        console.log(allowStartDate);
        if (allowStartDate !== null) {
          this.allow_start_date = allowStartDate[0];
        } else {
          this.allow_start_date = true;
        }
      } else {
        typeid = 0;
        this.allow_start_date = true;
      }
    }
  }

  viewReport() {
    this.router.navigate([this.apiService.redirect_url + '/reports/task_activation'], {
      state: {

        taskID: this.schemeDetails.id,
        start_date: this.schemeDetails.start_date,
        end_date: this.schemeDetails.end_date
      }
    });
  }

  goToSurvey() {
    this.router.navigate([this.apiService.redirect_url + '/reports/task_survey'],{state: {
        surveyID : this.schemeDetails.survey_id
      }});
  }

}
