<div class="common-wrapper">
    <div class="common-container">
      <div class="main-wrapper pro_dc_list">
        <div id="overlay"></div>
        <div class="search-list">
          <div class="col-md-6 pad0">
              <div class="search">
                <span class="search-control"><img src="assets/searchicon.png"/></span>
                <input type="text" placeholder="filter" (keyup)="quickSearch()" [(ngModel)]="searchValue" class="search-control-area"
                placeholder="Search for DC">
              </div>
          </div>
          <div class="col-md-6 pad0">
            <div class="filters">
              <ul class="list-unstyled">
                <li *ngIf="no_po" class="margLR" style="margin-top: 1%;margin-left: -12%;">
                  <button (click)="new_loadout()" class="btn confirm-blue-btn">Add Loadout</button>
                </li>
                <li *ngIf="createPO && new_po" class="margLR" style="margin-top: 1%;margin-left: 10px;">
                  <button (click)="po_create()" class="btn confirm-blue-btn">Create PO</button>
                </li>
                <li class="margLR" style="margin-left: 1%;">
                  <app-dropdown-icon (statusChange)="changeStatus($event)"></app-dropdown-icon>
                </li>
                <li class="margLR" style="margin-left: -1%">
                    <div class="date-picker">
                        <span class="datepicker-icon"><img src="../../assets/date.png"></span>
                        <input  [disabled] = "!apiloaded" [readonly]="true" [(ngModel)]="myDate" [owlDateTimeTrigger]="dt" [owlDateTime]="dt" placeholder="Date">
                        <!--<span class="dropdown-icon"><img src="../../assets/dropdown.png"></span>-->
                        <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
                    </div>
                </li>
                  <li class="margLR">
                      <app-excelicon [gridOption]="gridOptions" [title]="'DC Production Order List'"></app-excelicon>
                  </li>
                <li *ngIf="upload_enable" style="margin-top: 0%;margin-left: -1%" class="margLR">
                  <img data-toggle="modal" data-target="#popupModal" src="./../../assets/upload.svg"/>
                </li>
                </ul>
              </div>
          </div>
          <div class="col-md-12 pl-0" style="margin-top: 10px;margin-right: 8px;">
            <div class="my-table dcvanlist">
              <ag-grid-angular id="myGrid" *ngIf="loaded" style="width: 100%;" class="ag-theme-balham" [columnDefs]="columnDefs"
                               [gridOptions]="gridOptions"  (gridReady)="onGridReady($event)" [defaultColDef]="defaultColDef"
                               [frameworkComponents]="frameworkComponents">
              </ag-grid-angular>
              <div *ngIf="preloader">
                <app-loadingstate></app-loadingstate>
              </div>
              <div *ngIf="empty_state">
                <app-emptystate></app-emptystate>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

<div class="approve-modal modal" id="popupModal" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <span class="close" data-dismiss="modal">
        <img src="../../assets/close.png">
      </span>
      <div class="modal-body">
        <div class="approve-modal-heading">
          <label style="padding-bottom: 5%;">UPLOAD LOADOUT</label>
          <input #fileUp type="file" class="form-control" (change)="uploadedFile($event)" placeholder="Upload file" accept=".xlsx">
        </div>
        <p style="font-size: medium" [style.color]="uploadCaption[1] == 0 ? 'green' : 'red'">{{uploadCaption[0]}}</p>
        <div class="approve-modal-content">
          <a *ngIf="!uploading" style="margin-top: 10%;" (click)="uploadexcel()" class="btn confirm-blue-btn">Upload</a>
          <div *ngIf="upload_loader">
            <img style="width:5%;margin-top: 10%;" src="assets/images/loader_icon.gif"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="pol-modal pol-width pol-send modal" id="polModal" role="dialog">
  <div class="modal-dialog" style="width: 770px !important;">
    <div class="modal-content">
      <span class="close" data-dismiss="modal">
        <img src="../../assets/close.png">
      </span>
      <div class="modal-body">
        <div class="pol-modal-heading">
          <h1>CREATE NEW LOADOUT</h1>
          <label>Enter The Quantity For</label>
        </div>
        <div style="width: 40%;font-size: 130%;font-weight: 500;display: inline-block;margin-top: -6%;">
          <label style="color: #b0bac9;font-size: 85%;letter-spacing: 1.13px;line-height: 100%;font-weight: 400;padding-bottom: 2%;">VAN</label>
          <ng-template adHost2></ng-template>
        </div>
        <div style="width: 100%;font-size: 130%;font-weight: 500;">
          <label style="color: #b0bac9;font-size: 85%;letter-spacing: 1.13px;line-height: 100%;font-weight: 400;padding-bottom: 2%;">PRODUCT</label>
          <ng-template style="position:relative;top:20px;" adHost3></ng-template>
        </div>
        <div>
          <button (click)="addProduct()" style="position: relative;margin-top: -33.5%;left: 91%;margin-left: -9%;" class="btn confirm-blue-btn">ADD</button>
        </div>
        <div class="eod-table">
          <div class="dms_table">
            <ag-grid-angular id="myGrid1" *ngIf="loaded1" style="width: 100%;" class="ag-theme-balham"
                             [columnDefs]="columnDefs1" [gridOptions]="gridOptions1"  (gridReady)="onGridReady1($event)"
                             [frameworkComponents]="frameworkComponents" [context]="context">
            </ag-grid-angular>
          </div>
        </div>
        <div>
          <button (click)="confirmProd()" style="position: relative;margin-top: 3%;left: 91%;margin-left: -13%;" data-dismiss="modal" class="btn confirm-blue-btn">CONFIRM</button>
        </div>
      </div>
    </div>
  </div>
</div>

<button #newLoadout id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#polModal"></button>
