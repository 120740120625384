import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";

@Component({
  selector: 'invoice-editor',
  template: `<div class="eod-top" style="padding-left: 6%;padding-bottom: 2vw;width: 100%;" layout="row">` +
  `<span class="element-po"><input type="text" [disabled]="disable" [(ngModel)]="invoiceValue" (ngModelChange)="editCell()" class="custom-inputbox"/></span>` +
  `</div>`
})
export class InvoiceEditRenderer implements ICellRendererAngularComp {
  public params: any;
  public invoiceValue = "";
  public disable = false;

  agInit(params: any): void {
    this.params = params;
    this.invoiceValue = this.params.data['INVOICE NO'];
    if (this.params.data['approved'] == true || this.params.data['state'] == 'cancel') {
      this.disable = true;
    }else {
      this.disable = false;
    }
  }

  public editCell() {
    this.params.data['INVOICE NO'] = this.invoiceValue;
  }

  refresh(): boolean {
    return false;
  }
}
