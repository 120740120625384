import {Component, OnInit} from '@angular/core';
import {GridOptions} from '@ag-grid-enterprise/all-modules';
import {MatDialogConfig} from "@angular/material/dialog";
import {ChainVerifyPopupComponent} from "./chain_verify_popup/chain-verify-popup.component";
import {GstinVerifyPopupComponent} from "./gstin_verify_popup/gstin-verify-popup.component";
import {ApprovePopupComponent} from "./approve_popup/approve-popup.component";
import {FinanceVerifyPopupComponent} from "./finance_verify_popup/finance-verify-popup.component";
import {PageBase} from "../../pagebase/pagebase";
import {OutletVerifyComponent} from "../../outletVerify.component";
import {LocationVerifyPopupComponent} from "./location_verify_popup/location_verify_popup.component";

declare var $: any;

@Component({
  selector: 'app-approve-cust',
  templateUrl: '../../pagebase/pagebase.html',
  styleUrls: ['../../pagebase/pagebase.scss']
})
export class ApprovalRequestComponent extends PageBase implements OnInit {

  user;
  res_data;
  attributeTypes = [];
  showCustomFilter = true;
  customFilter: any = {
    name: 'Verified',
    key: 'type',
    value: 'not_verified',
    values: [],
    show: true
  };
  /* user_data = {
     date: null,
     supplier_id: null,
     access_token: null,
     url: null,
     offset: null,
     data: {
       start_date: null,
       end_date: null,
       territory_ids: [],
       showAll: true
     }
   };
   showableFilters = {
     region: true,
     salesman: true,
     cluster: true,
     territory: true,
     status: true,
     statusValues: [
       {id: 'new', itemname: 'New'},
       {id: 'pending', itemname: 'Pending'},
       {id: 'approved', itemname: 'Approved'},
       {id: 'settled', itemname: 'Settled'},
       {id: 'rejected', itemname: 'Rejected'},
     ],
   };*/
  public columnDefs = [];
  public frameworkComponents;
  public rowData = [];
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public styleGrid;
  /* public myDate = [];
   public collapse_disable;
   public isModelOpened = true;*/
  public newRetailer = {
    code: null,
    name: null,
    mobile: null,
    retailer_type: null,
    class_type: null,
    territory: null,
    beat: null,
    beat_name: null
  };
  public typeList;
  public selectedType = [];
  public classType = [];
  public selectedClass = [];
  public selectedTerr = [];
  public selectedBeat = [];
  public beatList = [];
  public terrList = [];
  public confirmEnable = true;
  public team_territories_list = [];
  public API_URL: string;
  public context;
  public finance_feature = false;
  public is_verify_feature = false;
  private is_approve_feature = false;

  ngOnInit() {
    this.sharedService.setHeader('New Outlets');
    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable === 'true' ? 'addClass' : 'removeClass']
    (this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');

    this.showableFilters.dc = true;
    this.showableFilters.region = true;
    this.showableFilters.state = true;
    this.showableFilters.territory = true;
    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.outlet_type = true;
    this.showableFilters.payment_mode = true;

    this.customFilter.values = [
      {id: "not_verified", itemName: "Not Verified"},
      {id: "verified", itemName: "Verified"},
    ];

    this.gridOptions.rowGroupPanelShow = 'never';

    if (this.sharedService.ACLcheck('feature_finance_verify')) {
      this.finance_feature = true;
    }

    if (this.sharedService.ACLcheck('feature_customer_verify')) {
      this.is_verify_feature = true;
    }

    if (this.sharedService.ACLcheck('feature_final_approve')) {
      this.is_approve_feature = true;
    }

    this.sharedService.setHeader('New Outlets');
    this.gridOptions.getRowStyle = params => {
      if (params.data && params.data.reject_reason && params.data.reject_reason !== '') {
        return {background: '#F8D1C8'};
      }
    };
    this.API_URL = '/api/res_partners/get_customers';
    this.loadServerData();

  }

  openLocationVerify(params) {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: '40vw',
        height: 'fit-content',
        maxWidth: '70%',
        maxHeight: '98%',
        restoreFocus: true,
        data: {
          verify_json: params.verify,
          id: params.id,
          params
        }
      };
      const creatediaeref = this.dialog.open(LocationVerifyPopupComponent, config);
      creatediaeref.afterClosed().subscribe(result => {
        if (creatediaeref.componentInstance.refreshDlg) {
          this.loadServerData();
        }
      });
    } else {
      const config: MatDialogConfig = {
        width: '100vw',
        height: '80vh',
        maxWidth: '90%',
        maxHeight: '100%',
        restoreFocus: true,
        data: {
          verify_json: params.verify,
          id: params.id,
          params
        }
      };

      const creatediaeref = this.dialog.open(LocationVerifyPopupComponent, config);
      creatediaeref.afterClosed().subscribe(result => {
        this.loadServerData();
      });
    }
  }

  openChainVerify(params) {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: '40vw',
        height: 'fit-content',
        maxWidth: '70%',
        maxHeight: '98%',
        restoreFocus: true,
        data: {
          verify_json: params.verify,
          id: params.id,
          params
        }
      };
      const creatediaeref = this.dialog.open(ChainVerifyPopupComponent, config);
      creatediaeref.afterClosed().subscribe(result => {
        if (creatediaeref.componentInstance.refreshDlg) {
          this.loadServerData();
        }
      });
    } else {
      const config: MatDialogConfig = {
        width: '100vw',
        height: '80vh',
        maxWidth: '90%',
        maxHeight: '100%',
        restoreFocus: true,
        data: {
          verify_json: params.verify,
          id: params.id,
          params
        }
      };

      const creatediaeref = this.dialog.open(ChainVerifyPopupComponent, config);
      creatediaeref.afterClosed().subscribe(result => {
        this.loadServerData();
      });
    }
  }

  openGSTINVerify(params) {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "50%",
        maxHeight: "93%",
        panelClass: 'custom-dialog-container',
        data: {
          params,
          type: 'create'
        }
      };
      const creatediaeref = this.dialog.open(GstinVerifyPopupComponent, config);
      creatediaeref.afterClosed().subscribe(result => {
        if (creatediaeref.componentInstance.refreshDlg) {
          this.loadServerData();
        }
      });
    } else {
      const config: MatDialogConfig = {
        width: '100vw',
        height: '80vh',
        maxWidth: '90%',
        maxHeight: '100%',
        panelClass: 'custom-dialog-container',
        data: {
          params,
          type: 'create'
        }
      };

      const creatediaeref = this.dialog.open(GstinVerifyPopupComponent, config);
      creatediaeref.afterClosed().subscribe(result => {
        if (creatediaeref.componentInstance.refreshDlg) {
          this.loadServerData();
        }
      });
    }
  }

  openVerify(params) {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: '70vw',
        height: 'fit-content',
        maxWidth: '80%',
        maxHeight: '98%',
        data: {
          params
        }
      };
      const creatediaeref = this.dialog.open(ApprovePopupComponent, config);
      creatediaeref.afterClosed().subscribe(result => {
        this.loadServerData();
      });
    } else {
      const config: MatDialogConfig = {
        width: '100vw',
        height: '80vh',
        maxWidth: '90%',
        maxHeight: '100%',
        data: {
          params
        }
      };

      const creatediaeref = this.dialog.open(ApprovePopupComponent, config);
      creatediaeref.afterClosed().subscribe(result => {
        this.loadServerData();
      });
    }
  }

  openFinanceVerify(params) {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: '40vw',
        height: 'fit-content',
        maxWidth: '70%',
        maxHeight: '98%',
        data: {
          params
        }
      };
      const creatediaeref = this.dialog.open(FinanceVerifyPopupComponent, config);
      creatediaeref.afterClosed().subscribe(result => {
        if (creatediaeref.componentInstance.refreshDlg) {
          this.loadServerData();
        }
      });
    } else {
      const config: MatDialogConfig = {
        width: '100vw',
        height: '80vh',
        maxWidth: '90%',
        maxHeight: '100%',
        data: {
          params
        }
      };

      const creatediaeref = this.dialog.open(FinanceVerifyPopupComponent, config);
      creatediaeref.afterClosed().subscribe(result => {
        if (creatediaeref.componentInstance.refreshDlg) {
          this.loadServerData();
        }
      });
    }
  }


  onGridReady(params) {

    const inThis = this;
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    let columndefs = [];
    if (this.gridColumnApi.columnModel) {
      columndefs = this.gridColumnApi.columnModel.columnDefs;
    } else {
      columndefs = this.gridColumnApi.columnController.columnDefs;
    }
    if (this.is_verify_feature) {
      const getData = columndefs.filter(existed => (existed.headerName === "VERIFICATION"));
      getData[0]['children'].forEach(col => {
        if (col.headerName === 'CHAIN VERIFY' || col.headerName === 'GSTIN VERIFY' || col.headerName === 'LOCATION VERIFY') {
          col.hide = false;
        }
      });
    }
    if (this.is_approve_feature) {
      const getData = columndefs.filter(existed => (existed.headerName === "VERIFICATION"));
      getData[0]['children'].forEach(col => {
        if (col.headerName === 'APPROVE') {
          col.hide = false;
        }
      });
    }
    if (this.finance_feature) {
      const getData = columndefs.filter(existed => (existed.headerName === "VERIFICATION"));
      getData[0]['children'].forEach(col => {
        if (col.headerName === 'FINANCE VERIFY') {
          col.hide = false;
        }
      });
    }
    params.api.setRowData(this.rowData);
    this.gridApi.setColumnDefs(this.columnDefs);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (screen.width > 991) {
          inThis.sharedService.resetPinned(inThis.gridOptions, [
            {colId: 'code', pinned: 'left'},
            {colId: 'name', pinned: 'left'},
            {colId: 'otp', pinned: 'right'},
            {colId: 'chain', pinned: 'right'},
            {colId: 'location', pinned: 'right'},
            {colId: 'gstin', pinned: 'right'},
            {colId: 'finance', pinned: 'right'},
            {colId: 'approve', pinned: 'right'},
          ]);
          // params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
          inThis.sharedService.clearPinned(inThis.gridOptions);
        }
      });
    });
    if (screen.width > 991) {
      // this.gridOptions.api.sizeColumnsToFit();
      inThis.sharedService.resetPinned(inThis.gridOptions, [
        {colId: 'code', pinned: 'left'},
        {colId: 'name', pinned: 'left'},
        {colId: 'otp', pinned: 'right'},
        {colId: 'chain', pinned: 'right'},
        {colId: 'location', pinned: 'right'},
        {colId: 'gstin', pinned: 'right'},
        {colId: 'finance', pinned: 'right'},
        {colId: 'approve', pinned: 'right'},

      ]);
    } else {
      this.gridColumnApi.autoSizeColumns();
      inThis.sharedService.clearPinned(inThis.gridOptions);
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
    this.gridApi.setQuickFilter(document.getElementById('filter-text-box')['value']);
  }

  newRetailerCreation() {
    $('#retailermasterModal').modal('show');
    this.confirmEnable = true;
  }


  configureGrid() {
    this.context = {componentParent: this};
    this.frameworkComponents = {
      outletVerifyRenderer: OutletVerifyComponent
    };

    const inThis = this;

    this.columnDefs = [
      {
        headerName: "",
        children: [
          {
            headerName: 'DETAILS', headerClass: 'myagheader', field: 'details', width: 100, pinned: 'left',
            cellRenderer(params) {
              if (params.data) {
                return '<button class="btn teal-btn" data-action-type="approve" style="padding: 1px 20px; text-transform: none" >View</button>';
              }
            }
          },
          {
            headerName: "OUTLET CODE",
            headerClass: 'myagheader',
            field: "code",
            menuTabs: [],
            width: 80,
            pinned: 'left'
          },
          {headerName: 'NAME', headerClass: 'myagheader', field: 'name', menuTabs: [], width: 200, pinned: 'left'},
        ]
      },
      {
        headerName: "",
        children: [
          {headerName: "ID", headerClass: 'myagheader', field: "id", menuTabs: [], width: 60},
          {headerName: "STATE", headerClass: 'myagheader', field: "state", menuTabs: [], width: 80},
          {headerName: "TERRITORY", headerClass: 'myagheader', field: "territory", menuTabs: [], width: 80},
          {headerName: "BEAT", headerClass: 'myagheader', field: "beats", menuTabs: [], width: 100},
          {headerName: "TYPE", headerClass: 'myagheader', field: "out_type", menuTabs: [], width: 80},
          {headerName: "CHANNEL", headerClass: 'myagheader', field: "channel", menuTabs: [], width: 80},
          {headerName: "ADDRESS", headerClass: 'myagheader', field: "address", menuTabs: [], width: 100},
          {headerName: "PAYMENT MODE", headerClass: 'myagheader', field: "mode", menuTabs: [], width: 80},
          {headerName: "CREDIT LIMIT", headerClass: 'myagheader', field: "credit_limit", menuTabs: [], width: 60},
          {headerName: "GSTIN", headerClass: 'myagheader', field: "gst_id", menuTabs: [], width: 100},
          {headerName: "MOBILE", headerClass: 'myagheader', field: "mobile", menuTabs: [], width: 80},
          {headerName: "EMAIL", headerClass: 'myagheader', field: "email", menuTabs: [], width: 80},
          {headerName: "CREATED BY", headerClass: 'myagheader', field: "created_by", menuTabs: [], width: 80},
          {headerName: "UPDATED BY", headerClass: 'myagheader', field: "updated_by", menuTabs: [], width: 80},
          {
            headerName: "UPDATED DATE", headerClass: 'myagheader', field: "write_date", menuTabs: [], width: 80,
            valueGetter: params => {
              if (params.data) {
                if (params.data.write_date) {
                  return inThis.datePipe.transform((new Date(params.data.write_date + ' UTC')).toString(), 'yyyy-MM-dd');
                } else {
                  return params.data.write_date;
                }
              } else {
                return '';
              }
            }
          },
          {
            headerName: "REQUEST AGE", headerClass: 'myagheader', field: "req", menuTabs: [], width: 100,
            valueGetter(params) {
              if (params.data) {
                let date1;
                const date2: any = new Date();
                const oneDay = 24 * 60 * 60 * 1000;
                if (params.data.verify.hasOwnProperty('revoke') && params.data.verify.revoke.date !== '') {
                  date1 = new Date(params.data.verify.revoke.date);
                } else {
                  date1 = new Date(params.data.create_date.substring(0, 10));
                }
                const diffDays = Math.round(Math.abs((date2 - date1) / oneDay));
                return diffDays.toString() + ' days ago';
              }
            }
          },
          {headerName: "REJECT REASON", headerClass: 'myagheader', field: "reject_reason", width: 100},
          {
            headerName: "REJECT COMMENTS", headerClass: 'myagheader', field: "reject_comments", menuTabs: [], width: 100,
            valueGetter(params) {
              if (params.data) {
                if (params.data.verify) {
                  if (params.data.verify.reject_comments) {
                    return params.data.verify.reject_comments;
                  } else {
                    return '';
                  }
                } else {
                  return '';
                }
              }
            }
          },
          {
            headerName: "STATUS", headerClass: 'myagheader', field: "status", menuTabs: [], width: 100,
            valueGetter(params) {
              if (params.data) {
                if (params.data.verify.gst.verified === 'true' || params.data.verify.gst.verified === true) {
                  if (params.data.verify.gst.hasOwnProperty('comments')) {
                    return params.data.verify.gst.comments;
                  } else {
                    return 'New';
                  }
                } else {
                  return 'New';
                }
              }
            }
          },
        ]
      },
      {
        headerName: "ATTRIBUTES",
        children: []
      }
    ];

    for (const item of this.apiData) {
      if (item.attributes !== null && item.attributes.length > 0) {
        const attr = JSON.parse(item.attributes);
        for (const i in attr) {
          if (!this.attributeTypes.includes(i)) {
            this.attributeTypes.push({name: i, value: attr[i]});
          }
        }
      }
    }
    for (const key in this.attributeTypes) {
      this.columnDefs[2].children.push({
        headerName: this.attributeTypes[key].name,
        field: this.attributeTypes[key].name,
        cellStyle: {textAlign: 'center'},
        width: 100,
        valueGetter(params) {
          if (params.data) {
            if (params.data.attributes !== null && params.data.attributes.length > 0) {
              const attr = JSON.parse(params.data.attributes);
              if (attr !== null && attr.hasOwnProperty(params.colDef.headerName)) {
                return attr[params.colDef.headerName];
              } else {
                return '';
              }
            } else {
              return '';
            }
          }
        }
      });
    }
    this.columnDefs.push({  headerName: "VERIFICATION", pinned: 'right', children: [{
        headerName: "OTP VERIFY", headerClass: 'myagheader', field: "otp", menuTabs: [], width: 100, pinned: 'right', cellStyle: {textAlign: 'center'},
        cellRenderer(params) {
          if (params.data && params.data.otp) {
            return '<span class="ml-1 status2" style="font-weight: bold;background-color:#E1F0E7; border:#E1F0E7; color: #379862" >Verified</span>';
          } else {
            return '<span class="ml-1 status2" style="font-weight: bold;background-color:#FBE3E7; border:#FBE3E7; color: #E24260">Unverified</span>';
          }
        }
      }]});
    if (this.is_verify_feature) {
      this.columnDefs[this.columnDefs.length - 1].children.push( {
          headerName: "CHAIN VERIFY",
          headerClass: 'myagheader',
          field: "chain",
          menuTabs: [],
          width: 100,
          hide: true,
          cellRenderer: "outletVerifyRenderer",
          pinned: 'right',
          cellStyle: {textAlign: 'center'}
        },
        {
          headerName: "LOCATION VERIFY",
          headerClass: 'myagheader',
          field: "location",
          menuTabs: [],
          width: 100,
          hide: true,
          cellRenderer: "outletVerifyRenderer",
          pinned: 'right',
          cellStyle: {textAlign: 'center'}
        },
        {
          headerName: "GSTIN VERIFY", headerClass: 'myagheader', field: "gstin",
          menuTabs: [], width: 100, hide: true,
          cellRenderer: "outletVerifyRenderer", pinned: 'right',
          cellStyle: {textAlign: 'center'}
        });
    }
    if (this.finance_feature) {
      this.columnDefs[this.columnDefs.length - 1].children.push({
        headerName: "FINANCE VERIFY", headerClass: 'myagheader', field: "finance",
        menuTabs: [], width: 100, hide: true,
        cellRenderer: "outletVerifyRenderer", pinned: 'right',
        cellStyle: {textAlign: 'center'}
      });
    }
    if (this.is_approve_feature) {
      this.columnDefs[this.columnDefs.length - 1].children.push({
        headerName: "APPROVE", headerClass: 'myagheader', field: "approve",
        menuTabs: [], width: 100, pinned: 'right', hide: true,
        cellRenderer: "outletVerifyRenderer",
        cellStyle: {textAlign: 'center'}
      });
    }
    this.columnDefs.push(
      {
        headerName: "verify_json", headerClass: 'myagheader', field: "verify",
        menuTabs: [], width: 120, hide: true
      },
      {
        headerName: "id", headerClass: 'myagheader', field: "id",
        menuTabs: [], width: 120, hide: true
      },
      {
        headerName: "street", headerClass: 'myagheader', field: "street",
        menuTabs: [], width: 120, hide: true
      },
      {
        headerName: "street2", headerClass: 'myagheader', field: "street2",
        menuTabs: [], width: 120, hide: true
      },
      {
        headerName: "zip", headerClass: 'myagheader', field: "zip",
        menuTabs: [], width: 120, hide: true
      },
      {
        headerName: "has_einvoice", headerClass: 'myagheader', field: "has_einvoice",
        menuTabs: [], width: 120, hide: true
      },
      {
        headerName: "has_purchase_order", headerClass: 'myagheader', field: "has_purchase_order",
        menuTabs: [], width: 120, hide: true
      },
      {
        headerName: "parent", headerClass: 'myagheader', field: "parent",
        menuTabs: [], width: 120, hide: true
      },
      {
        headerName: "group_code", headerClass: 'myagheader', field: "program_type",
        menuTabs: [], width: 120, hide: true
      },
      {
        headerName: "out_type", headerClass: 'myagheader', field: "out_type",
        menuTabs: [], width: 120, hide: true
      },
      {
        headerName: "zone", headerClass: 'myagheader', field: "zone",
        menuTabs: [], width: 120, hide: true
      },
      {
        headerName: "dc_name", headerClass: 'myagheader', field: "dc_name",
        menuTabs: [], width: 120, hide: true
      },
      {
        headerName: "dc_code", headerClass: 'myagheader', field: "dc_code",
        menuTabs: [], width: 120, hide: true
      },
      {
        headerName: "state_code", headerClass: 'myagheader', field: "state_code",
        menuTabs: [], width: 120, hide: true
      },
      {
        headerName: "pricelist", headerClass: 'myagheader', field: "pricelist",
        menuTabs: [], width: 120, hide: true
      },
      {
        headerName: "chain_pricelist", headerClass: 'myagheader', field: "chain_pricelist",
        menuTabs: [], width: 120, hide: true
      },
      {
        headerName: "ret_pricelist", headerClass: 'myagheader', field: "ret_pricelist",
        menuTabs: [], width: 120, hide: true
      },
      {
        headerName: "credit_days", headerClass: 'myagheader', field: "cl_days",
        menuTabs: [], width: 120, hide: true
      },
      {
        headerName: "approved by", headerClass: 'myagheader', field: "approval_user",
        menuTabs: [], width: 120, hide: true
      },
    );
  }

  /* changeDate(dt) {

     this.user_data.data.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
     this.user_data.data.end_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');

     this.loadServerData();
   }*/

  gridSearch(searchText) {
    this.gridApi.setQuickFilter(searchText);
  }

  postProcessData() {
    this.configureGrid();
    if (this.apiData && this.apiData.length > 0) {
      this.data = this.apiData[0];
    }
    this.rowData = this.apiData;
  }

  onCellClicked($event) {
    const column = $event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    const filteredRowData = this.gridApi.rowModel.rowsToDisplay;
    console.log(column);
    if (column === 'details') {
      const filteredRows = [];
      for (const key in filteredRowData) {
        filteredRows.push(filteredRowData[key].data);
      }
      if (filteredRows !== null && filteredRows.length > 0) {
        this.storeCustomerData(filteredRows);
      }
      localStorage.setItem('customerid', rows[0].id);
      const index = JSON.parse(localStorage.getItem("customerdata")).indexOf(rows[0].id);
      localStorage.setItem('customerindex', index);
      this.router.navigate([this.apiService.redirect_url + '/customers/retailers/details'], {
        state: {
          viewtype: 'detail',
          customerid: rows[0].id,
        }
      });
    } else if (column !== 'otp' && column !== 'chain' && column !== 'finance' && column !== 'location' && column !== 'gstin' && column !== 'approve') {
      this.openVerify(rows[0]);
    }
  }

  storeCustomerData(data) {
    const ids = [];
    for (const key in data) {
      ids.push(data[key].id);
    }
    localStorage.setItem('customerdata', JSON.stringify(ids));
  }
}
