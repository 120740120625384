<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper">
      <div id="overlay"></div>
      <div>
        <app-page-loader [show]="!pageReady"></app-page-loader>
      </div>
      <div class="col-md-12 top-card">
        <div>
          <div >
            <div>
              <div class="search-list">
                <div class="col-md-12 pt-3 col-12 nopadding pl-0" style="margin-bottom: 10px;">
                  <div class="row">

<!--                    <div  *ngIf="!editMode && activeObject['status'] === 'draft'" class="button-row">-->
<!--                      <button-->
<!--                        class="btn green-button"-->
<!--                        (click)="action_edit()"-->
<!--                      >EDIT-->
<!--                      </button>-->
<!--                    </div>-->
                    <div  *ngIf="!editMode && activeObject['state'] === 'draft'" class="button-row">
                      <button
                        class="btn confirm-green-btn"
                        (click)="openApproveRejectPopup('posted')"
                      >APPROVE
                      </button>
                    </div>
                    <div  *ngIf="!editMode && activeObject['state'] === 'draft'" class="button-row" style="margin-left: 15px;">
                      <button
                        class="btn reject-btn"
                        (click)="openApproveRejectPopup('rejected')"
                      >REJECT
                      </button>
                    </div>
                      <div class="button-row centeralign" style="margin-top: 15px;">
                        <div *ngIf="!newMode && !editMode && listLength > 0" style="position: absolute;right: 40px;">
                          <div *ngIf="activeObject['state'] !== 'rejected'" style="display: inline-block; margin-right: 15px;"
                               class="btn light-blue-btn">
                        <span
                          style="height: 8px;width: 8px;margin-right: 5px;background-color: #379862;border-radius: 50%; display: inline-block;"></span><span
                            style="color: #2D2D2D">{{activeObject['state']}}</span>
                          </div>
                         <span (click)="movePrevious()"><i
                           [ngClass]="{'disabledspan': rowIndex === 0,
                         'enabledspan': rowIndex >0}"
                           class="fal fa-arrow-circle-left fa-2x"></i></span>
                        <span style="margin-left: 5px; margin-right: 5px; margin-bottom: 2px;font-size: 12px;">{{rowIndex+1}}/{{listLength}}</span>
                        <span (click)="moveNext()"><i
                          [ngClass]="{'disabledspan': rowIndex === listLength-1,
                         'enabledspan': rowIndex < listLength-1}"
                          class="fal fa-arrow-circle-right fa-2x"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 pt-3 pad0"  *ngIf="!newMode">
                <div class="col-md-12 pt-3 pad0">
                  <div class="col-md-3 pl-0 pr-30">
                    <label class="lbl">Payment By</label>

                    <div>
                      <p class="lbl3" *ngIf="activeObject['partner_name']">{{activeObject['partner_name']}}</p>
                      <p *ngIf="activeObject['partner_gst_id']" class="lbl2">Tax Id : {{activeObject['partner_gst_id']}}</p>
                      <p *ngIf="activeObject['partner_code']" class="lbl2">Code : {{activeObject['partner_code']}}</p>
                    </div>
                  </div>

                  <div class="col-md-3 pl-0 pr-30">
                    <label class="lbl">Payment To</label>

                    <div>
                      <p class="lbl3" *ngIf="activeObject['supplier_name']">{{activeObject['supplier_name']}}</p>
                      <p *ngIf="activeObject['supplier_code']" class="lbl2">Code : {{activeObject['supplier_code']}}</p>
                    </div>
                  </div>

                  <div class="col-md-2 pl-0 pr-30">
                    <label class="lbl">Amount</label>
                    <div>
                      <p class="lbl3">{{activeObject['amount']}}</p>
                    </div>
                  </div>

                  <div class="col-md-2 pl-0 pr-30">
                    <label class="lbl">Payment Method</label>
                    <div>
                      <p class="lbl3">{{activeObject['payment_type']}}</p>
                      <div  *ngIf="activeObject['config_json']">
                        <p *ngIf="activeObject['config_json']['cheque_no'] && activeObject['config_json']['cheque_no']!= ''" class="lbl2">No : {{activeObject['config_json']['cheque_no']}}</p>
                        <p *ngIf="activeObject['config_json']['cheque_date'] && activeObject['config_json']['cheque_date'] != ''" class="lbl2">Date : {{activeObject['config_json']['cheque_date']}}</p>
                        <p *ngIf="activeObject['config_json']['branch_name'] && activeObject['config_json']['branch_name'] != ''" class="lbl2">Branch : {{activeObject['config_json']['branch_name']}}</p>
                        <p *ngIf="activeObject['config_json']['upi_id'] && activeObject['config_json']['upi_id'] != ''" class="lbl2">UPI ID : {{activeObject['config_json']['upi_id']}}</p>
                        <p *ngIf="activeObject['config_json']['card_no'] && activeObject['config_json']['card_no'] != ''" class="lbl2">Card No : {{activeObject['config_json']['card_no']}}</p>
                        <p *ngIf="activeObject['config_json']['card_name'] && activeObject['config_json']['card_name'] != ''" class="lbl2">Card Name : {{activeObject['config_json']['card_name']}}</p>
                        <p *ngIf="activeObject['config_json']['ref_id'] && activeObject['config_json']['ref_id'] != ''" class="lbl2">Ref ID : {{activeObject['config_json']['ref_id']}}</p>
                      </div>

                    </div>
                  </div>

                  <div class="col-md-2 pl-0 pr-30">
                    <label class="lbl">Attachment</label>
                    <div (click)="openImagePopup()" style="cursor: pointer;">
                      <img [src]="photo" style="padding:2px;margin-top:5px;width:100px;height:100px;" alt=""/>
                    </div>
                  </div>
                </div>
            </div>

            <div class="col-md-12 pt-4 pad0">
              <div class="dms_table">
                <ag-grid-angular id="myGrid1" style="width: 100%;" class="ag-theme-balham"
                                 [columnDefs]="columnDefs"
                                 [gridOptions]="gridOptions"
                                 (gridReady)="onGridReady($event)"
                                 [context]="context">
                </ag-grid-angular>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

