import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {GridsterItem} from 'angular-gridster2';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-widget-link',
  template: `
    <div class="info-box">
      <div class="link-content" (click)="openURL()">
        <div class="col-xs-12 col-12">
          <div class=" col-xs-9">
            <span class="heading">{{widget.data.label}}</span>
            <span class="desc">{{widget.data.desc}}</span>
          </div>
          <div class=" col-xs-3">
            <img class="icon" src="{{widget.data.icon}}"/>
          </div>
        </div>
      </div>
    </div>`,
  styleUrls: ['./../dashboard.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LinkWidgetComponent implements OnInit, OnDestroy {
  @Input()
  widget;
  @Input()
  resizeEvent: EventEmitter<GridsterItem>;

  resizeSub: Subscription;

  ngOnInit(): void {
    this.resizeSub = this.resizeEvent.subscribe(widget => {
      if (widget === this.widget) {
        // or check id , type or whatever you have there
        // resize your widget, chart, map , etc.
        console.log(widget);
      }
    });

  }

  ngOnDestroy(): void {
    this.resizeSub.unsubscribe();
  }

  openURL() {
    window.open(this.widget.data.url);
  }
}
