import {Component} from '@angular/core';
import {ITooltipAngularComp} from '@ag-grid-community/angular';
import {ITooltipParams} from '@ag-grid-enterprise/all-modules';

@Component({
  selector: 'app-error-tooltip-component',
  template: `
    <div *ngIf="err_msg" class="error-tooltip">
      <div class="row">
        <div class="col-md-12 pad0">
          <div class="col-md-1 pl-0 pr-0">
            <img src="./../assets/exclamation.svg"/>
          </div>
          <div class="col-md-11 pl-10 pr-0">
            <span>{{err_msg}}</span>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .error-tooltip {
        background-color: #FFFFFF;
        border: 1px solid #E24260;
        border-radius: 5px;
        width: 240px;
        height: 100%;
        padding: 5px;
      }
    `
  ],
})
export class ErrortooltipComponent implements ITooltipAngularComp {
  private params!: { color: string } & ITooltipParams;
  public data;
  public err_msg ;

  agInit(params: { color: string } & ITooltipParams): void {
    this.params = params;
    if (this.params?.data?.err_msg) {
      this.err_msg = this.params.data.err_msg;
    } else {
      this.err_msg = null;
    }
  }
}
