import {Component, ElementRef, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ApiService} from '../../../_services/api.service';
import {ToastrService} from 'ngx-toastr';
import {DatePipe} from "@angular/common";
import {RetailerRejectPopupComponent} from "../../../components/retailer_reject_popup/retailer_reject_popup.component";

@Component({
  selector: 'app-approve-popup',
  templateUrl: './approve-popup.component.html',
  styleUrls: ['./approve-popup.component.scss']

})

export class ApprovePopupComponent implements OnInit {

  public verify_json;
  public partner_id;
  public credit_limit;
  public chain;
  public location;
  public gst;
  public finance;
  public otp;
  public address;
  public street;
  public street2;
  public area;
  public zone;
  public state;
  public zip;
  public parent;
  public is_chain = false;
  public gstin;
  public mode;
  public is_verify = false;
  public unverified = '';
  public has_einvoice = false;
  public has_purchase_order = false;
  public refreshDlg = false;
  public finance_enable = true;
  public credit_days = 0;
  public pricelist = null;
  public chain_pricelist = null;
  public ret_pricelist = null;
  public role_features;
  public sapSync = false;
  sapEnable = false;
  public country_id = 0;
  public currency = 'INR';
  public final_verified = false;
  public finalVerification: any = {};
  approval_user = '';

  constructor(private apiService: ApiService,
              public dialog: MatDialog,
              private toastr: ToastrService,
              private datePipe: DatePipe,
              private elRef: ElementRef,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.verify_json = this.dialogdata.params.verify;
    this.partner_id = this.dialogdata.params.id;
    this.credit_limit = this.dialogdata.params.credit_limit;
    this.credit_days = this.dialogdata.params.cl_days;
    this.chain = this.verify_json.chain;
    this.location = this.verify_json.location;
    this.approval_user = this.dialogdata.params.approval_user;
    if (this.location === null || this.location === undefined) {
      this.location = {};
      this.location['verified'] = false;
    }
    /* this.location = {
       verified: false,
       date: '2022-07-15',
       by: 'Test'
     };*/
    this.gst = this.verify_json.gst;
    this.finance = this.verify_json.finance;
    this.otp = this.dialogdata.params.otp;
    this.gstin = this.dialogdata.params.gst_id;
    this.mode = this.dialogdata.params.mode;
    this.parent = this.dialogdata.params.parent;
    this.has_einvoice = this.dialogdata.params.has_einvoice;
    this.has_purchase_order = this.dialogdata.params.has_purchase_order;
    this.pricelist = this.dialogdata.params.pricelist;
    this.chain_pricelist = this.dialogdata.params.chain_pricelist;
    this.ret_pricelist = this.dialogdata.params.ret_pricelist;
    this.country_id = this.dialogdata.params.country_id;
    this.street = this.dialogdata.params.street;
    this.street2 = this.dialogdata.params.street2;
    this.area = this.dialogdata.params.area;
    this.zone = this.dialogdata.params.zone;
    this.state = this.dialogdata.params.state;
    this.zip = this.dialogdata.params.zip;
    this.address = this.dialogdata.params.street + ',' + this.dialogdata.params.street2 + ',' +
      this.dialogdata.params.area + ',' + this.dialogdata.params.zone + ',' + this.dialogdata.params.state +
      +',' + this.dialogdata.params.zip;
    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids;

    const sync = this.role_features.filter(existed => 'feature_sap_push' === existed.name);
    if (sync.length > 0) {
      this.sapSync = true;
    }
    const integrs = JSON.parse(localStorage.getItem('integrations'));
    const updated = integrs.filter(existed => existed.integration_type === 'sap');
    if (updated.length > 0 && updated[0].config_json && this.sapSync) {
      this.sapEnable = true;
    } else {
      this.sapEnable = false;
    }
    if (this.parent) {
      this.is_chain = true;
    }
    this.is_verify = true;
    const otprequired = this.role_features.filter(existed => 'feature_otp_verify' === existed.name);
    if (!this.gstin && !this.otp) {
      if (otprequired !== null && otprequired.length > 0) {
        this.is_verify = false;
        this.unverified = 'OTP, ';
      }
    }
    if (this.chain.verified === false || this.chain.verified === 'false') {
      this.is_verify = false;
      this.unverified = this.unverified + 'Chain, ';
    }
    if (this.location === null || this.location === undefined || this.location.verified === false || this.location.verified === 'false') {
      this.is_verify = false;
      this.unverified = this.unverified + 'Location, ';
    }
    if (this.gst.verified === false || this.gst.verified === 'false') {
      this.is_verify = false;
      this.unverified = this.unverified + 'GSTIN, ';
    }
    if ((this.mode === 'credit') && (this.finance.verified === false || this.finance.verified === 'false')) {
      this.is_verify = false;
      this.unverified = this.unverified + 'Finance ';
    }

    if (this.verify_json.hasOwnProperty('final_verification') && this.verify_json.final_verification !== null && this.verify_json.final_verification !== undefined) {
      if (this.verify_json.final_verification.hasOwnProperty('verified') && this.verify_json.final_verification.verified === true) {
        this.is_verify = false;
        this.final_verified = true;
        this.finalVerification = this.verify_json.final_verification;
      }
    }
  }

  reject() {
    const userData = {
      access_token: localStorage.getItem('resfreshToken'),
      data: {
        partner_id: this.partner_id,
        active: false
      }
    };
    this.apiService.post('/api/res_partners/updateCustomer', userData)
      .subscribe(res2 => {
          console.log(res2);
          if (res2.hasOwnProperty('results') && (res2.results.status === 200)) {
            this.toastr.success('Inactivated Successfully');
            this.refreshDlg = true;
            this.dialog.closeAll();
          } else {
            this.toastr.error('Error inactivating Approval Request');
          }
        },
        error => {
          console.log(error);
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
          this.toastr.error('Error inactivating Approval Request');
        });
  }

  checkSapPush() {
    if (this.sapEnable && this.sapSync) {
      this.verify();
    } else {
      this.verify_json.final_verification.verified = true.toString();
      this.verify_json.final_verification.date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      this.verify_json.final_verification.by = JSON.parse(localStorage.getItem('user')).id;
      const userData = {
        access_token: localStorage.getItem('resfreshToken'),
        data: {
          partner_id: this.partner_id,
          is_verified: true,
          verification_json: this.verify_json,
          final_verify: false,
        }
      };
      this.updateCustomer(userData);
    }
  }

  verify() {
    //check for any other sync
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    const inthis = this;
    const integrs = JSON.parse(localStorage.getItem('integrations'));
    let updated = integrs.filter(existed => existed.integration_type === 'sap' && existed.country_id === this.country_id);
    if (this.country_id === null || this.country_id === undefined || this.country_id === 0) {
      this.toastr.error('Country not set for outlet : ' + this.dialogdata.params.code);
      this.dialog.closeAll();
    } else if (updated.length > 0 && updated[0].config_json) {
      const sapConfig = updated[0].config_json;
      if (sapConfig.hasOwnProperty("Currency") && sapConfig.Currency !== null && sapConfig.Currency !== '') {
        this.currency = sapConfig.Currency;
      } else {
        this.currency = "INR";
      }
      const payload = {
        access_token: localStorage.getItem('resfreshToken'),
        data: {
          url: sapConfig.AuthUrl,
          payload: {
            CompanyDB: sapConfig.CompanyDB,
            Password: sapConfig.Password,
            UserName: sapConfig.UserName
          }
        }
      };
      this.apiService.post('/api/sap_connects/sapLogin', payload)
        .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && res.results.body && !JSON.parse(res.results.body).hasOwnProperty("error")) {
            const session = JSON.parse(res.results.body)['SessionId'].toString();
            let final_json;
            if (this.country_id === 0 || this.country_id !== 105) {
              final_json = {
                CardCode: this.dialogdata.params.code,
                CardName: this.dialogdata.params.name,
                GroupCode: this.dialogdata.params.program_code,
                U_OutletType: this.dialogdata.params.channel,
                U_Type: this.dialogdata.params.channel,
                U_Addrss1: this.dialogdata.params.street,
                U_Addrss2: this.dialogdata.params.street2,
                U_Route: this.dialogdata.params.beats,
                U_Zone: this.dialogdata.params.zone,
                U_Mode: this.dialogdata.params.mode,
                Phone1: this.dialogdata.params.mobile,
                EmailAddress: this.dialogdata.params.email,
                U_Warehouse: this.dialogdata.params.dc_name,
                U_WhsCode: this.dialogdata.params.dc_code,
                SubjectToWithholdingTax: "N",
                CardType: 'cCustomer',
                Currency: this.currency,
                DebitorAccount: "13201011",
                U_Approved: "Yes",
                U_Label: "Invoice",
                FatherCard: this.dialogdata.params.fathercard,
                BPAddresses: [],
                BPFiscalTaxIDCollection: []
              };
            } else {
              final_json = {
                CardCode: this.dialogdata.params.code,
                CardName: this.dialogdata.params.name,
                GroupCode: this.dialogdata.params.program_code,
                U_OutletType: this.dialogdata.params.channel,
                U_Type: this.dialogdata.params.channel,
                U_GSTIN: this.dialogdata.params.gst_id,
                U_Addrss1: this.dialogdata.params.street,
                U_Addrss2: this.dialogdata.params.street2,
                U_Route: this.dialogdata.params.beats,
                U_Zone: this.dialogdata.params.zone,
                U_Mode: this.dialogdata.params.mode,
                Phone1: this.dialogdata.params.mobile,
                EmailAddress: this.dialogdata.params.email,
                U_Warehouse: this.dialogdata.params.dc_name,
                U_WhsCode: this.dialogdata.params.dc_code,
                SubjectToWithholdingTax: "N",
                CardType: 'cCustomer',
                Currency: this.currency,
                DebitorAccount: "13201011",
                U_Approved: "Yes",
                U_Label: "Invoice",
                FatherCard: this.dialogdata.params.fathercard,
                BPAddresses: [],
                BPFiscalTaxIDCollection: []
              };
            }
            let gst_type;
            if (this.dialogdata.params.gst_id) {
              gst_type = 'gstRegularTDSISD';
            } else {
              gst_type = '';
            }
            if (this.country_id === 0 || this.country_id !== 105) {
              final_json.BPAddresses.push({
                AddressName: this.dialogdata.params.name.substring(0, 50),
                Street: this.dialogdata.params.street.substring(0, 50),
                ZipCode: this.dialogdata.params.zip,
                City: this.dialogdata.params.city,
                Country: this.dialogdata.params.country_code,
                State: this.dialogdata.params.state_code,
                AddressType: 'bo_BillTo',
                BPCode: '',
                County: '',
                Block: '',
                FederalTaxID: '',
                BuildingFloorRoom: '',
                AddressName2: '',
                AddressName3: '',
                TypeOfAddress: '',
                StreetNo: this.dialogdata.params.name.substring(0, 50),
                RowNum: 0,
                GlobalLocationNumber: '',
                Nationality: '',
                TaxOffice: ''
              });
              final_json.BPAddresses.push({
                AddressName: this.dialogdata.params.name.substring(0, 50),
                Street: this.dialogdata.params.street.substring(0, 50),
                ZipCode: this.dialogdata.params.zip,
                City: this.dialogdata.params.city,
                Country: this.dialogdata.params.country_code,
                State: this.dialogdata.params.state_code,
                AddressType: 'bo_ShipTo',
                BPCode: '',
                County: '',
                Block: '',
                FederalTaxID: '',
                BuildingFloorRoom: '',
                AddressName2: '',
                AddressName3: '',
                TypeOfAddress: '',
                StreetNo: this.dialogdata.params.name.substring(0, 50),
                RowNum: 0,
                GlobalLocationNumber: '',
                Nationality: '',
                TaxOffice: ''
              });
            } else {
              final_json.BPAddresses.push({
                AddressName: this.dialogdata.params.name.substring(0, 50),
                Street: this.dialogdata.params.street.substring(0, 50),
                ZipCode: this.dialogdata.params.zip,
                City: this.dialogdata.params.city,
                Country: this.dialogdata.params.country_code,
                State: this.dialogdata.params.state_code,
                GSTIN: this.dialogdata.params.gst_id,
                GstType: gst_type,
                AddressType: 'bo_BillTo',
                BPCode: '',
                County: '',
                Block: '',
                FederalTaxID: '',
                BuildingFloorRoom: '',
                AddressName2: '',
                AddressName3: '',
                TypeOfAddress: '',
                StreetNo: this.dialogdata.params.name.substring(0, 50),
                RowNum: 0,
                GlobalLocationNumber: '',
                Nationality: '',
                TaxOffice: ''
              });
              final_json.BPAddresses.push({
                AddressName: this.dialogdata.params.name.substring(0, 50),
                Street: this.dialogdata.params.street.substring(0, 50),
                ZipCode: this.dialogdata.params.zip,
                City: this.dialogdata.params.city,
                Country: this.dialogdata.params.country_code,
                State: this.dialogdata.params.state_code,
                GSTIN: this.dialogdata.params.gst_id,
                GstType: gst_type,
                AddressType: 'bo_ShipTo',
                BPCode: '',
                County: '',
                Block: '',
                FederalTaxID: '',
                BuildingFloorRoom: '',
                AddressName2: '',
                AddressName3: '',
                TypeOfAddress: '',
                StreetNo: this.dialogdata.params.name.substring(0, 50),
                RowNum: 0,
                GlobalLocationNumber: '',
                Nationality: '',
                TaxOffice: ''
              });
            }
            const bpaAddress = JSON.parse(localStorage.getItem('inv_address'));
            if (bpaAddress && bpaAddress.length > 0) {
              updated = bpaAddress.filter(existed => existed.parent_id === inthis.partner_id);
              if (updated.length > 0) {
                final_json.BPAddresses = [];
                updated.forEach(child_data => {
                  if (child_data['gstin']) {
                    gst_type = child_data['gsttype'];
                  } else {
                    gst_type = '';
                  }
                  if (this.country_id === 0 || this.country_id !== 105) {
                    final_json.BPAddresses.push({
                      AddressName: child_data['addressname'].substring(0, 50),
                      Street: child_data['street'],
                      Block: child_data['block'],
                      ZipCode: child_data['zipcode'],
                      City: child_data['city'],
                      County: child_data['county'],
                      Country: child_data['country'],
                      State: child_data['state'],
                      FederalTaxID: child_data['federaltaxid'],
                      TaxCode: child_data['taxcode'],
                      BuildingFloorRoom: child_data['buildingfloorroom'],
                      AddressType: child_data['addresstype'],
                      AddressName2: child_data['addressname2'].substring(0, 50),
                      AddressName3: child_data['addressname3'].substring(0, 50),
                      TypeOfAddress: child_data['typeofaddress'],
                      StreetNo: child_data['streetno'],
                      BPCode: child_data['bpcode'],
                      RowNum: child_data['rownum'],
                      GlobalLocationNumber: child_data['globallocationnumber'],
                      Nationality: child_data['nationality'],
                      TaxOffice: child_data['taxoffice'],
                    });
                  } else {
                    final_json.BPAddresses.push({
                      AddressName: child_data['addressname'].substring(0, 50),
                      Street: child_data['street'],
                      Block: child_data['block'],
                      ZipCode: child_data['zipcode'],
                      City: child_data['city'],
                      County: child_data['county'],
                      Country: child_data['country'],
                      State: child_data['state'],
                      FederalTaxID: child_data['federaltaxid'],
                      TaxCode: child_data['taxcode'],
                      BuildingFloorRoom: child_data['buildingfloorroom'],
                      AddressType: child_data['addresstype'],
                      AddressName2: child_data['addressname2'].substring(0, 50),
                      AddressName3: child_data['addressname3'].substring(0, 50),
                      TypeOfAddress: child_data['typeofaddress'],
                      StreetNo: child_data['streetno'],
                      BPCode: child_data['bpcode'],
                      RowNum: child_data['rownum'],
                      GlobalLocationNumber: child_data['globallocationnumber'],
                      Nationality: child_data['nationality'],
                      TaxOffice: child_data['taxoffice'],
                      GSTIN: child_data['gstin'],
                      GstType: gst_type,
                    });
                  }

                });
              }
            }
            if (this.country_id !== 105) {
              final_json.BPFiscalTaxIDCollection.push({
                Address: "",
                CNAECode: null,
                TaxId0: "",
                TaxId1: "",
                TaxId2: this.dialogdata.params.gst_id,
                TaxId3: "",
                TaxId4: "",
                TaxId5: "",
                TaxId6: "",
                TaxId7: "",
                TaxId8: "",
                TaxId9: "",
                TaxId10: "",
                TaxId11: "",
                BPCode: this.dialogdata.params.code,
                AddrType: "bo_ShipTo",
                TaxId12: null,
                TaxId13: ""
              });
            } else if (this.dialogdata.params.pan !== '') {
              final_json.BPFiscalTaxIDCollection.push({
                Address: "",
                CNAECode: null,
                TaxId0: this.dialogdata.params.pan,
                TaxId1: "",
                TaxId2: "",
                TaxId3: "",
                TaxId4: "",
                TaxId5: "",
                TaxId6: "",
                TaxId7: "",
                TaxId8: "",
                TaxId9: "",
                TaxId10: "",
                TaxId11: "",
                BPCode: this.dialogdata.params.code,
                AddrType: "bo_ShipTo",
                TaxId12: null,
                TaxId13: ""
              });
            }
            if (this.dialogdata.params.property !== null) {
              final_json['Properties' + this.dialogdata.params.property] = "tYES";
            }
            this.verify_json.final_verification.verified = true.toString();
            this.verify_json.final_verification.date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
            this.verify_json.final_verification.by = JSON.parse(localStorage.getItem('user')).id;
            const userData = {
              access_token: localStorage.getItem('resfreshToken'),
              data: {
                partner_id: this.partner_id,
                is_verified: true,
                verification_json: this.verify_json,
                sessionId: session,
                db: sapConfig.CompanyDB,
                payload: final_json,
                final_verify: true,
                url: sapConfig.PushBp
              }
            };
            this.updateCustomer(userData);
          } else if (res.hasOwnProperty('results') && res.results.body && JSON.parse(res.results.body).hasOwnProperty("error")) {
            this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
            this.toastr.error("SAP Login error : " + JSON.parse(res.results.body).error.message.value);
          } else {
            this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
            this.toastr.error('SAP Login Error ');
          }

        }, error => {
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
          this.toastr.error('SAP Login Error ', error.error.error.message);
        });
    } else {
      this.verify_json.final_verification.verified = true.toString();
      this.verify_json.final_verification.date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      this.verify_json.final_verification.by = JSON.parse(localStorage.getItem('user')).id;
      const userData = {
        access_token: localStorage.getItem('resfreshToken'),
        data: {
          partner_id: this.partner_id,
          is_verified: true,
          verification_json: this.verify_json
        }
      };
      this.apiService.post('/api/res_partners/updateCustomer', userData)
        .subscribe(res => {
            console.log(res);
            if (res.hasOwnProperty('results') && (res.results.status === 200)) {
              this.toastr.success('Updated Successfully');
              this.refreshDlg = true;
              this.dialog.closeAll();
            } else {
              this.toastr.error('Error updating Approval Request');
            }
          },
          error => {
            console.log(error);
            this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
            this.toastr.error('Error updating Approval Request');
          });
    }
  }

  ngOnInit(): void {
  }

  updateCustomer(userData) {
    if (this.verify_json.hasOwnProperty('revoke')) {
      delete this.verify_json.revoke;
      userData.data['verification_json'] = this.verify_json;
    }
    this.apiService.post('/api/res_partners/updateCustomer', userData)
      .subscribe(res2 => {
          console.log(res2);
          if (res2.hasOwnProperty('results') && (res2.results.status === 200)) {
            this.toastr.success('Updated Successfully');
            this.refreshDlg = true;
            this.dialog.closeAll();
          } else {
            this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
            this.toastr.error('Error updating Approval Request');
          }
        },
        error => {
          console.log(error);
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
          this.toastr.error('Error updating Approval Request');
        });
  }


  closeDialog() {
    this.dialog.closeAll();
  }

  rejectPopup() {

    const config: MatDialogConfig = {
      width: '100vw',
      height: '100vh',
      maxWidth: '450px',
      maxHeight: '300px',
    };

    const confirmdiaeref = this.dialog.open(RetailerRejectPopupComponent, config);

    confirmdiaeref.componentInstance.submit.subscribe(data => {
      this.verify_json.reject_reason = data.reject_reason;
      this.verify_json.reject_comments = data.reject_comments;
      const userData = {
        access_token: localStorage.getItem('resfreshToken'),
        data: {
          partner_id: this.partner_id,
          final_verify: false,
          verification_json: this.verify_json
        }
      };
      this.updateCustomer(userData);
    });
  }

}
