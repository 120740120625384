import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-gallerycontainer',
  templateUrl: './gallery-container.component.html',
  styleUrls: ['./gallery-container.component.scss']
})

export class GalleryContainerComponent implements OnInit {

  @Input() rowData: [];
  @Input() galleryData: {};
  @Input() filteredData: [];
  @Output() cardClick: EventEmitter<any> = new EventEmitter<any>();
  pageData = [];
  pageSize = 1;
  pageCount = 1;
  currentIndex = 1;
  currentPage = 1;
  store;
  allSelect = false;
  selectedCount = 0;
  notSelectedCount = 0;
  selectionCriteria: any = {};

  constructor() {
    console.log(this.filteredData);
  }

  ngOnInit() {
    console.log(this.filteredData);
    if (this.galleryData) {
      this.pageSize = this.galleryData['pageSize'];
      this.selectionCriteria = this.galleryData['selectionCriteria'];
    }
    /* $("#rowspan").on('change', function(){
       //Do calculation and change value of other span2,span3 here
       console.log(this.rowData);
     });*/

    const content = document.getElementById('rowspan');
    const append = () => content.innerText += ' change';

    content.addEventListener('DOMSubtreeModified', () => this.initialise());

    this.initialise();
  }

  initialise() {
    this.currentIndex = 1;
    this.currentPage = 1;
    if (this.filteredData.length < this.pageSize) {
      this.pageCount = 1;
    } else {
      this.pageCount = Math.round(this.filteredData.length / this.pageSize);
    }
    this.getPageData();
  }

  checkIfSelectAllowed(selectedItem, selectionCriteria) {
    if (selectionCriteria.matches) {
      if (!selectedItem[selectionCriteria.field]) {
        return true;
      } else if (selectedItem[selectionCriteria.field].includes(selectionCriteria.values)) {
        return true;
      } else {
        return false;
      }
    } else {
      if (!selectedItem[selectionCriteria.field].includes(selectionCriteria.values)) {
        return true;
      } else {
        return false;
      }
    }
  }

  checkSelectionCriteria(selectedItem) {
    if (this.selectionCriteria) {
      let matches = true;
      for (const criteria of this.selectionCriteria) {
        if (!this.checkIfSelectAllowed(selectedItem, criteria)) {
          matches = false;
          return false;
        }
      }
      return matches;
    } else {
      return true;
    }
  }

  galleryClick(item) {
    console.log(item);
    const selectedItem = this.pageData.find(x => x.id === item.id);
    if (item.click === 'single') {
      if (selectedItem && this.checkSelectionCriteria(selectedItem)) {
        if (selectedItem) {
          selectedItem['item_selected'] = item.selected;
        } else {
        }

        this.getSelectedCount();
      }
    } else {

    }
    const clickObject = {
      item,
      data: this.pageData
    };
    this.cardClick.emit(clickObject);
  }

  getSelectedCount () {
    const selectedData = this.pageData.filter(x => x['item_selected'] === true);
    if (selectedData && selectedData.length > 0) {
      this.selectedCount = selectedData.length;
      this.notSelectedCount = this.pageSize - selectedData.length;
    } else {
      this.selectedCount = 0;
      this.notSelectedCount = 0;
    }
  }

  selectAll() {
    let item_selected = false;
    let selectedCount = 0;
    if (this.allSelect) {
      item_selected = true;
    } else {
      item_selected = false;
    }
    for (const item of this.pageData) {
      if (item_selected) {
        if (this.checkSelectionCriteria(item)) {
          selectedCount += 1;
          item['item_selected'] = true;
        } else {
          item['item_selected'] = false;
        }
      } else {
        if (item_selected) {
          selectedCount += 1;
        }
        item['item_selected'] = item_selected;
      }
    }
    if (this.allSelect) {
      this.selectedCount = selectedCount;
    } else {
      this.selectedCount = 0;
    }
    const clickObject = {
      item: "all",
      data: this.pageData
    };
    this.cardClick.emit(clickObject);
  }

  nextPage() {
    if ((this.currentIndex + this.pageData.length - 1) < this.filteredData.length) {
      this.currentIndex = this.currentIndex + this.pageSize;
      this.currentPage += 1;
      this.getPageData();
    }
  }

  previousPage() {
    if (this.currentIndex > 1) {
      this.currentIndex = this.currentIndex - this.pageSize;
      this.currentPage -= 1;
      this.getPageData();
    }
  }

  firstPage() {
    if (this.currentPage > 1) {
      this.currentIndex = 1;
      this.currentPage = 1;
      this.getPageData();
    }
  }

  lastPage() {
    if (this.currentPage < this.pageSize) {
      this.currentIndex = ((this.pageCount - 1) * this.pageSize) + 1;
      this.currentPage = this.pageCount;
      this.getPageData();
    }
  }

  getPageData() {
    this.pageData = [];
    let range = ((this.currentPage * this.pageSize) + 1);
    if ((this.currentIndex + this.pageSize) > this.filteredData.length) {
      range = this.filteredData.length + 1;
    }
    for (let i = this.currentIndex; i < range; i++) {
      this.pageData.push(this.filteredData[i - 1]);
    }
    localStorage.setItem('currentgalleryIndex', JSON.stringify(this.currentIndex));
    localStorage.setItem('currentgalleryPage', JSON.stringify(this.currentPage));
    this.getSelectedCount();
  }
}

