import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class PurchaseOrderImeiReportComponent extends ReportBase implements IReport, OnInit {

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Purchase Order IMEI Report');
    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getPurchaseOrderImei';
    const inThis = this;
    this.columnDefs = [
      {headerName: 'STATE', field: 'partner_state', width: 100},
      {headerName: 'PO NO', field: 'po', width: 100},
      {headerName: 'CUSTOMER', field: 'outlet_name', width: 100},
      {headerName: 'SUPPLIER', field: 'supplier', width: 80},
      {
        headerName: 'DATE', field: 'date', width: 100,
        valueGetter: params => {
          if (params.data) {
            if (params.data.date) {
              return inThis.datePipe.transform((new Date(params.data.date)).toString(), 'yyyy-MM-dd');
            } else {
              return params.data.date;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: 'PRODUCT', field: 'prod_name', width: 100},
      {headerName: 'P CODE', field: 'prod_code', width: 150},
      {headerName: 'CATEGORY', field: 'category', width: 100},
      {headerName: 'BRAND', field: 'brand', width: 100},
      {headerName: 'INVOICE NO', field: 'inv_no', width: 150},
      {headerName: 'IMEI', field: 'serial_no', width: 150},
      {headerName: 'STATUS', field: 'status', width: 80},
      {headerName: 'DELIVERY STATUS', field: 'd_status', width: 80},
    ];

  }

}
