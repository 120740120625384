<div id="overlay"></div>
<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <h2 style="color: #7D7D7D;">Verify Chain Mapping</h2>
      <div style="border-bottom: 1px solid #E5E5E5;"></div>
    </div>
    <div class="body-section">
      <div style="display: inline-block" class="mt-5">
        <h4>Chain Outlet</h4>
        <mat-radio-group aria-label="Select an option"
                         [(ngModel)]="is_chain"
        >
          <mat-radio-button value="1">Yes</mat-radio-button>
          <mat-radio-button value="0">No</mat-radio-button>
        </mat-radio-group>
      </div>
      <div style="display: inline-block" class="mt-5 ml-5">
        <h4>Has Pre-Order</h4>
        <mat-radio-group aria-label="Select an option"
                         [(ngModel)]="has_preorder"
        >
          <mat-radio-button value="1">Yes</mat-radio-button>
          <mat-radio-button value="0">No</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="row" style="margin-top:20px;">
        <div class="col-md-5 pl-0 mt-3">
          <label>Mobile</label>
          <input
            class="chaininput mt-3"
            type="text"
            name="name"
            style="width: 100%"
            [(ngModel)]="mobile"
          >
        </div>
      </div>
      <div class="row" style="margin-top:20px;">
        <div class="col-md-5 pl-0 mt-3">
          <label>Email</label>
          <input
            class="chaininput mt-3"
            type="text"
            name="name"
            style="width: 100%"
            [(ngModel)]="email"
          >
        </div>
      </div>
      <div [hidden]="is_chain==='0'">
        <div class="row">
          <div class="col-md-6 pl-0 mt-5">
            <label>Chain</label>
            <div *ngIf="customers.length > 0" style="width:100%;" class="drpdwnselect">
              <ng-select [items]="customers"
                         bindLabel="name"
                         multiple="true"
                         maxSelectedItems="1"
                         appendTo="body"
                         [virtualScroll]="true"
                         [(ngModel)]="selectedChain"
                         (ngModelChange)="custChange($event)"
              >
              </ng-select>
            </div>
            <!--            <div *ngIf="customers.length > 0" style="width:100%;" class="drpdwnselect">-->
            <!--              <select  name="selectedStatus" id="aantal" class="form-control status-drp"-->
            <!--                       [(ngModel)]="selCust" (ngModelChange)="custChange($event)">-->
            <!--                <option *ngFor="let cust of customers"-->
            <!--                        [ngValue]="cust">{{cust.name}}</option>-->
            <!--              </select>-->
            <!--            </div>-->
          </div>
          <div *ngIf="gstin" class="col-md-6 pl-0 mt-5">
            <label>Chain GSTIN</label>
            <p class="mt-3">{{gstin}}</p>
          </div>
        </div>
        <div class="row">
          <div *ngIf="date" class="col-md-6 pl-0 mt-5">
            <label>GSTIN Verify Date</label>
            <p class="mt-3">{{date}}</p>
          </div>
          <div *ngIf="pgm_type" class="col-md-6 pl-0 mt-5">
            <label>Chain Group</label>
            <p class="mt-3">{{pgm_type}}</p>
          </div>
        </div>
        <div class="row">
          <div *ngIf="pricelist" class="col-md-6 pl-0 mt-5">
            <label>Chain Pricelist</label>
            <p class="mt-3">{{pricelist}}</p>
          </div>
        </div>
      </div>
      <div>
        <div class="mt-5">
          <img src="../../../../assets/warning_red.svg">
          <p style="display: inline;color: #D97C0E;
                    margin-left: 1%;">Upon clicking Verify, the selected outlet details will be updated from the chain
            mapping.</p>
        </div>
        <div class="mt-3">
          <button (click)="verifyChain()"  style=" margin-right:15px;" class="btn confirm-blue-btn">VERIFY</button>
          <button (click)="closeDialog()" class="btn grey-btn">CLOSE</button>
        </div>
      </div>
    </div>
  </div>
</section>
