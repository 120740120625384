import {Component, OnInit, ElementRef, Renderer2, ViewChild, ComponentFactoryResolver} from '@angular/core';
import { ApiService} from "../_services/api.service";
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import {GridOptions,ChartType} from "@ag-grid-enterprise/all-modules";
import {SuppProdDropdownComponent} from "../components/supp_prod_dropdown/supp-prod-dropdown.component";
import {AdDirective} from "../ad.directive";
import {AdDirective1} from "../ad1.directive";
import {AdDirective2} from "../ad2.directive";
import { SharedService } from "../_services/shared_service";
import {SerialNoRenderer} from "../serialNo.component";
import {BeatDropdownComponent} from "../components/beat_dropdown/beat-dropdown.component";
import {OutletDropdownComponent} from "../components/outlet_dropdown/outlet-dropdown.component";

@Component({
  selector: 'app-new_order_page',
  templateUrl: './new_retailer_page.component.html',
  styleUrls: ['./new_retailer_page.component.scss']
})
export class New_retailer_pageComponent implements OnInit {
  @ViewChild(AdDirective, {static: true}) adHost: AdDirective;
  @ViewChild(AdDirective1, {static: true}) adHost1: AdDirective1;
  @ViewChild(AdDirective2, {static: true}) adHost2: AdDirective2;
  @ViewChild('imageClick') imageClick: ElementRef<HTMLElement>;
  @ViewChild('serialIn') serialIn: ElementRef<HTMLElement>;

  user_data = {
    "date": null,
    "dc_id": 0,
    "supplier_id": null,
    "pricelist_id": null,
    "access_token": null,
    "url": null,
    "offset": null,
    "po_data": null
  };
  res_data;
  public dashDisplay = false;
  public myDate;
  public cardData = {
    'total_vans': 0,
    'loaded_out': 0,
    'deviation_count': 0,
    'settled': 0,
    'not_loaded': 0,
    'total_expense': 0,
    'unapproved_orders': '...',
    'cash_collected': '...',
    'pay_amount': '...',
    'credit_sales': '...'
  };
  public preloader = false;
  public role_features;
  public expense = false;
  public settlemnt_dash = false;
  public dc_dash = false;
  public rowData = [];
  public chart_show = false;
  private gridApi;
  private gridColumnApi;
  public context;
  public frameworkComponents;
  public columnDefs = [];
  public data;
  public gridOptions: GridOptions;
  public loaded = false;
  public preloader1 = false;
  public empty_state = false;
  public browserRefresh = false;
  public subscription;
  public last_updated;
  public entered_qty = 0;
  public discount = 0;
  public extra_margin = 0.00;
  public max_margin = 0;
  public min_margin = 0;
  public fixed_cost = 0;
  public trade_disc = 0.00;
  public scheme_discount = 0;
  public po;

  public order_no = null;
  public beat_id = null;
  public customer_mail = null;
  public beat_name = null;
  public note = null;
  public customer_tax = null;
  public prod_show = false;
  public add_prod = true;
  public supplier_products = [];
  public product_id = null;
  public product_name = null;
  public uom_name = null;
  public uom_id = null;
  public stock = 0;
  public tax = null;
  public po_date = null;
  public due_date = null;
  public supplier_name  = null;
  public supplier_id  = null;
  public d_status = null
  public invoice_no;
  public serno1;
  public count = 1;
  public sernos = {};
  public line_id;
  public beats = [];
  public outlets = [];
  public pricelist = null;
  public pricelist_id = 1;
  public unit_price = 0;
  public sale_price = 0;
  public margin = 0;
  public customer_name = null;
  public customer_id = null;
  public line_data = [];
  public taxmaster_id;
  public order_details;


  constructor(private elRef: ElementRef,
              private renderer: Renderer2,
              private apiService: ApiService,
              private datePipe: DatePipe,
              public router: Router,
              public sharedService: SharedService,
              private componentFactoryResolver: ComponentFactoryResolver) {

    this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.po_date = this.myDate;

    this.user_data.date = this.myDate;
    this.user_data.offset = -1 * (new Date(this.user_data.date)).getTimezoneOffset();
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');
    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids;
    this.supplier_id = JSON.parse(localStorage.getItem('user')).partner_id;
    this.user_data.supplier_id = this.supplier_id;
    let inside_this = this;
    this.preloader = true;
    this.last_updated = localStorage.getItem('last_updated');
    if (this.sharedService.new) {
      this.productEntry([], this.sharedService.new, this.sharedService.mark_dispatch, this.sharedService.invoice, this.sharedService.flag);
    }else {

      this.customer_name = this.sharedService.dms_retailer['Retailer']
    }

    if (this.sharedService.new) {
      this.get_beats();
    }

    // this.vanreport();

    this.gridOptions = <GridOptions>{
      rowHeight: 28,
      headerHeight: 30,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'single',
      enableRangeSelection: true,
      enableCharts: true,
      chartThemes: ['ag-pastel-dark']
    };
  }

  get_beats() {
    this.res_data = this.apiService.getBeats(this.user_data);
    this.res_data.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res['results'].status == 200)) {
          if (res['results'].beats) this.beats = res['results'].beats;
          if (res['results'].outlets) this.outlets = res['results'].outlets;
          this.beatList();
      }else {
        window.alert ('Fetching Beats Failed')
      }
    }, error => {
      window.alert ('Fetching Beats Failed')
    })
  }

  addInput() {
    if (this.sernos[this.line_id]) {
      let serialin = this.serialIn;
      this.sernos[this.line_id].forEach(function(no){
        serialin.nativeElement.insertAdjacentHTML('beforeend', '<input type="text" class="form-control" style="width: 90% !important;padding: 1% 10%;margin-top: 5%;" value="'+no+'" />')
      })
    }else {
      this.serialIn.nativeElement.insertAdjacentHTML('beforeend', '<input type="text" class="form-control" style="width: 90% !important;padding: 1% 10%;margin-top: 5%;" />')
    }
  }

  confirmSerial () {

    let elements = this.serialIn.nativeElement.querySelectorAll('input');
    let nos = [];
    elements.forEach(function(elem) {
      nos.push(elem.value);
    });
    this.sernos[this.line_id] = nos;
    elements.forEach(function(elem) {
      elem.remove();
    })
  }

  savePO() {

    let order = {
      'order_id': null,
      'partner_id': this.customer_id,
      'distributor_id': this.supplier_id,
      'pricelist_id': this.pricelist_id,
      'beat_plan_id': this.beat_id,
      'date_order': this.user_data.date,
      'order_type': 'sale',
      'name': 'O_' + this.supplier_id + '_' + Date.now(),
      'line_data': this.line_data,
      'dms_order': true
    }
    let order_data = {
      "access_token": this.user_data.access_token,
      "url": this.user_data.url,
      "orders": [order],
      "distributor_id": this.supplier_id,
      "order_date": this.datePipe.transform(new Date(), 'yyyy-MM-dd')
    };

    this.res_data = this.apiService.savemtsales(order_data);

    this.res_data.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res['results'].statusCode == 200)) {
        window.alert("Created Successfully");
        this.sharedService.new = false;
        this.sharedService.order_id = res['results'].id;
        this.sharedService.flag = 'same';
        this.orderDetails();
      }else {
        window.alert("Creation failed");
      }
    },error => {
      console.log(error);
      if (error.error && error.error.error == undefined) {
        window.alert("Creation failed");
      }else {
        window.alert("Creation failed: " + error.error.error.message);
      }
    })
  }

  dispatch() {
    let order = {
      'order_id': this.sharedService.order_id,
      'supplier_id': this.supplier_id,
      'line_data': []
    }
    let line_data = []
    let order_detail = this.order_details;

    this.order_details.sale_items.forEach(function (line) {
      order.line_data.push({
        'line_id': line.id,
        'product_id': line.product_id,
        'territory_id': order_detail.territory_id,
        'distributor_id': order_detail.supplier_id,
        'quantity': line.delivered_qty,
        'valid': false
      })
    })

    let order_data = {
      "access_token": this.user_data.access_token,
      "url": this.user_data.url,
      "order": order
    };

    this.res_data = this.apiService.markDispatch(order_data);

    this.res_data.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res['results'].statusCode == 200)) {
        window.alert("dispatched Successfully");
        this.sharedService.flag = 'same';
        this.sharedService.mark_dispatch = false;
        this.orderDetails();
      }else {
        window.alert("Dispatch failed");
      }
    },error => {
      console.log(error);
      if (error.error && error.error.error == undefined) {
        window.alert("Dispatch failed");
      }else {
        window.alert("Dispatch failed: " + error.error.error.message);
      }
    })
  }

  orderDetails () {

    let order_data = {
      "access_token": this.user_data.access_token,
      "url": this.user_data.url,
      "order_id": [this.sharedService.order_id]
    };

    this.res_data = this.apiService.getOrderDetail(order_data);

    this.res_data.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res['results'].status == 200) && (res['results'].data.length > 0)) {
        let data = res['results'].data[0];
        this.order_details = res['results'].data[0];
        this.order_no = this.order_details.name;
        this.po_date = this.datePipe.transform(this.order_details.schedule_date, 'dd/MM/yyyy');;
        this.supplier_name = this.order_details.supplier;
        this.d_status = this.order_details.d_status;
        this.beat_name = this.order_details.beat_name;
        this.customer_id = this.order_details.partner_id;
        this.customer_name = this.order_details.partner;
        this.customer_tax = this.order_details.partner_gstn;
        this.customer_mail = this.order_details.partner_email;
        this.invoice_no = this.order_details.invoice_no
        let line_discount = 0;
        let spcl_discount = 0;
        this.po = this.order_details.po

        this.order_details.sale_items.forEach(function(line){
          let subtotal = parseFloat(line.unit_price) * parseFloat(line.qty)
          line_discount += subtotal * (1-(parseFloat(line.discount)/100));
          spcl_discount += (parseFloat(line.qty) * parseFloat(line.trade_disc))
        })

        this.order_details['line_discount'] = line_discount.toFixed(this.apiService.decimalPolicy);
        this.order_details['spcl_discount'] = spcl_discount.toFixed(this.apiService.decimalPolicy);

        if (this.order_details.d_status != 'dispatched' && this.order_details.d_status != 'delivered') {
          if (this.order_details.invoice_status == 'draft') {
            this.sharedService.invoice = true;
          }else if (this.order_details.invoice_status == 'invoiced') {
            this.sharedService.mark_dispatch = true;
          }
        }

        let order_lines = this.order_details.sale_items;
        this.productEntry(order_lines, this.sharedService.new, this.sharedService.mark_dispatch, this.sharedService.invoice, this.sharedService.flag)
      }
    }, error => {
        console.log(error);
    })
  }

  saveProduct() {

    if (this.entered_qty > 0) {

      if (parseInt(this.entered_qty.toString(),10) <= parseInt(this.stock.toString(),10)) {

        let line_data = [];
        let price_subtotal = this.sale_price * parseFloat(this.entered_qty.toString());
        price_subtotal = price_subtotal * (1 - (this.extra_margin / 100));
        price_subtotal = price_subtotal - (parseFloat(this.entered_qty.toString()) * this.trade_disc)
        let price_total = this.unit_price * parseFloat(this.entered_qty.toString());
        let product_line = {
          'product_id': parseInt(this.product_id, 10),
          'qty': this.entered_qty,
          'price_subtotal': price_subtotal,
          'price_unit': this.sale_price,
          'new': false,
          'product_uom': this.uom_id,
          'foc': false,
          'foc_product_id': null,
          'scheme_id': null,
          'scheme_discount': 0,
          'taxmaster_id': this.taxmaster_id,
          'fixed_cost': this.fixed_cost,
          'trade_disc': this.trade_disc,
          'extra_margin': this.extra_margin
        }
        line_data.push(product_line)
        let order = {
          'order_id': null,
          'partner_id': this.customer_id,
          'distributor_id': this.supplier_id,
          'line_data': line_data
        }

        let order_data = {
          "access_token": this.user_data.access_token,
          "url": this.user_data.url,
          "orders": [order]
        };

        let all_lines = this.line_data;
        let scheme_discount = 0;

        this.res_data = this.apiService.generateInvoice(order_data);

        this.res_data.subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res['results'].statusCode == 200)) {
            let free_orders = res['results'].orders;
            free_orders.forEach(function (res_order) {
              res_order.lines.forEach(function (schemes) {
                schemes.lines.forEach(function (scheme_line) {
                  product_line['scheme_code'] = scheme_line.scheme_code;
                  if (parseFloat(scheme_line.scheme_discount) > 0) {
                    product_line['scheme_discount'] = scheme_line.scheme_discount;
                    product_line['scheme_used'] = true;
                    scheme_discount = product_line['scheme_discount'];
                  }
                })
                schemes.free.forEach(function (free_line) {
                  all_lines.push({
                    'product_id': free_line.product_id,
                    'qty': free_line.qty,
                    'price_unit': free_line.price_unit,
                    'scheme_code': product_line['scheme_code'],
                    'scheme_used': true,
                    'new': true,
                    'foc': false,
                    'foc_product_id': null,
                    'scheme_id': null,
                    'scheme_discount': 0,
                    'discount': 0
                  })
                })
              })
            })
            this.scheme_discount = scheme_discount;
            all_lines.push(product_line);
            this.line_data = all_lines;
            this.rowData.push({
              "PRODUCT": this.product_name,
              "STOCK": this.stock,
              "UOM": this.uom_name,
              "QTY": this.entered_qty,
              "PRICE": this.sale_price,
              "DISC%": this.extra_margin,
              "SPCL DIS/qty": this.trade_disc,
              "FIXED COST": this.fixed_cost,
              "SCHEME DIS": this.scheme_discount,
              "TAX": this.tax,
              "TOTAL": price_subtotal,
              "product_id": this.product_id
            });
            this.gridApi.setRowData(this.rowData);
          }
        }, error => {
          console.log(error)
        })
      }else {
        window.alert("Stock available is "+this.stock+" only");
      }
    }else {
      window.alert("Enter Quantity");
    }
  }

  addProduct() {
    this.res_data = this.apiService.supplierProducts(this.user_data);
    this.res_data.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res['results'].status == 200) && (res['results'].data.length > 0)) {

        this.supplier_products = res['results'].data[0].products;

        const viewContainerRef2 = this.adHost2.viewContainerRef;
        viewContainerRef2.clear();

        const componentFactory2 = this.componentFactoryResolver.resolveComponentFactory(SuppProdDropdownComponent);

        const componentRef2 = viewContainerRef2.createComponent<SuppProdDropdownComponent>(componentFactory2);
        componentRef2.instance.itemList = this.supplier_products;
        componentRef2.instance.statusChange.subscribe(res => {

          if (res && res.length > 0) {
            this.product_name = res[0].itemName;
            this.product_id = res[0].id;
            this.uom_name = res[0].uom;
            this.uom_id = res[0].uom_id;
            this.stock = res[0].stock;
            this.tax = res[0].tax;
            this.margin = res[0].margin
            this.taxmaster_id = res[0].taxmaster_id
            this.extra_margin = res[0].extra_margin;
            this.max_margin = res[0].max_margin;
            this.min_margin = res[0].min_margin;
            this.fixed_cost = res[0].fixed_cost;
            this.trade_disc = 0;
            this.entered_qty = 0;
            if (res[0].tax_include) {
              this.unit_price = res[0].unit_price;
              this.sale_price = (this.unit_price*100)/(100+parseFloat(this.tax));
            }else {
              this.sale_price = res[0].unit_price;
              this.unit_price = this.sale_price * ((100+parseFloat(this.tax))/100)
            }
            this.unit_price = this.unit_price * (1 - (this.margin / 100));
            this.sale_price = this.sale_price * (1 - (this.margin / 100));
          }
        });
      }
    })
  }

  beatList() {

    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(BeatDropdownComponent);

    const componentRef = viewContainerRef.createComponent<BeatDropdownComponent>(componentFactory);
    componentRef.instance.itemList = this.beats;

    componentRef.instance.selectedItems = [this.beats[this.beats.length-1]];
    this.beat_name = componentRef.instance.selectedItems[0].itemName;
    this.beat_id = componentRef.instance.selectedItems[0].id;
    this.supplier_name = componentRef.instance.selectedItems[0].supplier;

    this.customerList(this.beat_id);

    componentRef.instance.statusChange.subscribe(res => {

      if (res && res.length > 0) {
        this.beat_id = res[0].id;
        this.beat_name = res[0].itemName;
        this.customerList(this.beat_id);
      } else {
        this.beat_id = null;
        this.beat_name = null;
      }

    });
  }

  customerList(beat_id) {
    let filtered_outlet = [];
    this.outlets.forEach(function (outlet) {
      if (parseInt(outlet.beat_plan_id,10) == parseInt(beat_id, 10)) {
        filtered_outlet = outlet.outlets;
      }
    });


    const viewContainerRef1 = this.adHost1.viewContainerRef;
    viewContainerRef1.clear();

    const componentFactory1 = this.componentFactoryResolver.resolveComponentFactory(OutletDropdownComponent);

    const componentRef1 = viewContainerRef1.createComponent<OutletDropdownComponent>(componentFactory1);
    componentRef1.instance.itemList = filtered_outlet;

    componentRef1.instance.statusChange.subscribe(res => {

      if (res.length > 0) {
        this.customer_id = res[0].id;
        this.customer_name = res[0].itemName;
        this.customer_mail = res[0].email;
        this.customer_tax = res[0].gstn;
        this.pricelist = res[0].pricelist;
        this.pricelist_id = res[0].pricelist_id;
        this.user_data.pricelist_id = this.pricelist_id;
        this.addProduct();
        this.prod_show = true;
      }else {
        this.customer_id = null;
        this.customer_name = "";
        this.customer_mail = "";
        this.customer_tax = "";
        this.pricelist = "";
        this.pricelist_id = 1;
        this.prod_show = false;
      }
    });

  }

  productEntry(lines, nw, dispatch, inv, flag){
      this.loaded = false;
      this.preloader = true;
      this.rowData = [];
      if (nw) {
        this.data = {
          "PRODUCT": "",
          "STOCK": "",
          "UOM": "",
          "QTY": "",
          "SCHEME": "",
          "PRICE": "",
          "DISC%": "",
          "SPCL DIS/qty": "",
          "FIXED COST": "",
          "SCHEME DIS": "",
          "TAX": "",
          "TOTAL": "",
          "product_id": "",
        };
      }else {
        let rowData = [];
        if (flag == 'same') this.columnDefs = [];
        lines.forEach(function(line) {
          rowData.push ({
            "PRODUCT": line.product_name,
            "STOCK": line.stock,
            "UOM": line.product_uom,
            "QTY": line.qty,
            "INVOICED QTY": line.delivered_qty,
            "SCHEME": "",
            "PRICE": line.unit_price,
            "DISC%": line.extra_margin,
            "SPCL DIS/qty": line.trade_disc,
            "FIXED COST": line.fixed_cost,
            "SCHEME DIS": line.scheme_discount,
            "TAX": line.tax,
            "TOTAL": line.amount_total,
            "Serial NO": "../../assets/edit.svg",
            "id": line.id,
            "product_id": line.product_id,
            "tax_amount": line.tax_perc
          })
        });
        this.data = rowData[0];
        this.rowData = rowData;
      }

    for (var key in this.data) {
      if (this.data.hasOwnProperty(key)) {
        if (key == "DISC%" || key == "SPCL DIS/qty") {
          this.columnDefs.push({
            headerName: key, field: key, sortable: true, filter: true, unSortIcon: true, lockPosition: true,editable:false,resizable: true
          });
        }else if (key == "PRODUCT" ) {
          var edt = false
          if (nw) edt = true
          this.columnDefs.push({
            headerName: key, field: key, sortable: true, filter: true, unSortIcon: true, lockPosition: true,editable:false,resizable: true,
            checkboxSelection: edt
          });
        }else if (key == "INVOICED QTY" ) {
          var edt = false
          if (inv) edt = true
          this.columnDefs.push({
            headerName: key, field: key, sortable: true, filter: true, unSortIcon: true, lockPosition: true,editable:edt,resizable: true
          });
        }else if (key == 'product_id' || key == 'id' || key == 'tax_amount') {
          this.columnDefs.push({
            headerName: key, field: key, sortable: true, filter: false, hide: true, unSortIcon: true, lockPosition: true,editable:true,resizable: true
          });
        }else if (key == 'Serial NO' ) {
          this.columnDefs.push({
            headerName: key, field: key, lockPosition: true,resizable: true,hide:true,
            cellRenderer: "serialNoRenderer",
          });
        }else {
          this.columnDefs.push({
            headerName: key, field: key, sortable: true, filter: false, unSortIcon: true, lockPosition: true,resizable: true
          });
        }
      }
    }
    this.context = {componentParent: this};
    this.frameworkComponents = {
      serialNoRenderer: SerialNoRenderer
    };
    this.loaded = true;
    if (flag == 'same') {
      this.gridApi.setRowData(this.rowData);
      this.preloader = false;
      if (screen.width > 991) {
        this.gridOptions.api.sizeColumnsToFit();
      }
      else {
        this.gridColumnApi.autoSizeColumns();
      }
    }
  }

  removeProduct() {
    let nodes = this.gridApi.getSelectedNodes();
    let in_this = this;
    nodes.forEach(function(node) {
      in_this.rowData.splice(node.rowIndex, 1);
      in_this.line_data.splice(node.rowIndex, 1);
    })
    this.gridApi.setRowData(this.rowData);
  }

  enterSerialNo(data) {

     this.line_id = data.id;
      let el: HTMLElement = this.imageClick.nativeElement;
      el.click();
      if (this.sernos[this.line_id]) {
        let elements = this.serialIn.nativeElement.querySelectorAll('input');
        elements.forEach(function(elem) {
          elem.remove();
        });
        this.addInput();
      }
  }

  receivePO() {
    let order_data = {
      "access_token": this.user_data.access_token,
      "url": this.user_data.url,
      "order": null,
      "flag": null
    };
    let order = {
      "order_id": this.sharedService.order_id,
      "invoice_no": this.invoice_no,
      "partner_id": this.customer_id,
      "distributor_id": this.supplier_id,
      "line_data": [],
      "po": this.po
    };
    let serialnos = this.sernos;
    let order_flag = 'same';
    this.rowData.forEach(function(line) {
      if (parseInt(line['INVOICED QTY']) != parseInt(line['QTY'])) order_flag = 'new';
      let line_data = {
        "order_qty": line['QTY'],
        "qty": line['INVOICED QTY'],
        "product_id": line.product_id,
        "unit_price": line['PRICE'],
        "tax": line['TAX'],
        "serials": [],
        "tax_amount": line.tax_amount
      };
      if (serialnos[line.id]) {
        serialnos[line.id].forEach(function (serial) {
          line_data.serials.push({
            "serial_no": serial
          })
        });
      }
      order.line_data.push(line_data);
    });
    order_data.flag = order_flag;

    order_data.order = order;

    this.res_data = this.apiService.createInvoice(order_data);
    this.res_data.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res['results'].statusCode == 200)) {
        window.alert("Created Successfully");
        this.sharedService.new = false;
        this.sharedService.order_id = res['results'].id;
        this.sharedService.flag = 'same';
        this.sharedService.invoice = false;
        this.sharedService.mark_dispatch = false;
        this.orderDetails();
      }else {
        window.alert("Creation failed");
      }
      // this.router.navigate([this.apiService.redirect_url+"vanuat.salesdiary.in/order_invoices"]);
    },error => {
      console.log(error);
      if (error.error && error.error.error == undefined) {
        window.alert("Creation failed");
      }else {
        window.alert("Creation failed: " + error.error.error.message);
      }
    })
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData);
    this.preloader = false;
    this.gridApi.setDomLayout("autoHeight");
    window.addEventListener('resize', function() {
      setTimeout(function() {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        }else {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    if (screen.width > 991) {
      this.gridOptions.api.sizeColumnsToFit();
    }
    else {
      this.gridColumnApi.autoSizeColumns();
    }

  }

  ngOnInit() {

  }

  changeDate(dt) {

    this.po_date = this.datePipe.transform(this.myDate, 'dd/MM/yyyy');
  }
}

