import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class BeatActivitySummaryReportComponent extends ReportBase implements IReport, OnInit {

  public context;
  public checkInDistance;
  public activityTypes = [];
  apiData: any = [];

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Beat Activity - Summary Report');

    this.showableFilters.salesman = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    /* this.gridOptions.autoGroupColumnDef = {
       headerName: 'EMPLOYEE',
       field: 'salesman',
       minWidth: 200,
       cellRenderer: 'agGroupCellRenderer',
       pinned: 'left'
     };
 */
    this.context = {componentParent: this};
    this.checkInDistance = localStorage.getItem('app') !== null ? JSON.parse(localStorage.getItem('app')).checkin_distance : 0;

    //this.configureGrid();
    this.API_URL = '/api/pwa_reports/getBeatActivityReport';
    this.showProgress = true;
    this.showProgressCustomText = false;

    this.userData.offsetID = 0;
    this.userData.offset = 10000;
    this.loadInBatches(10000, 'retailer_id');
  }

  configureGrid() {

    this.activityTypes = this.apiData.map(p => p.activity_type)
      .filter((type, index, arr) => arr.indexOf(type) === index);

    console.log(this.activityTypes);

    this.columnDefs = [
      {headerName: 'STATE', field: 'state', enableRowGroup: true, width: 100},
      {headerName: 'REGION', field: 'region', enableRowGroup: true, width: 100},
      {headerName: 'CLUSTER', field: 'cluster', enableRowGroup: true, width: 100},
      {headerName: 'EMP CODE', field: 'emp_code', width: 80},
      {headerName: 'EMP NAME', field: 'salesman', enableRowGroup: true, width: 150},
      {headerName: 'ROLE', field: 'role', enableRowGroup: true, width: 100},
      {headerName: 'REPORTING MANAGER', field: 'reporting_manager', hide: true, enableRowGroup: true, width: 150},
      {headerName: 'PARENT TEAM', field: 'parent_team', width: 100, enableRowGroup: true},
      {headerName: 'TEAM', field: 'team_name', width: 100, enableRowGroup: true},
      {headerName: 'BEAT', field: 'beat', enableRowGroup: true, width: 100},
      {headerName: 'BEAT CODE', field: 'beat_code', hide: true, width: 80},
      {headerName: 'PROGRAM', field: 'program', width: 80},
      {headerName: 'RETAILER ID', field: 'retailer_id', enableRowGroup: true, hide: true, width: 50},
      {headerName: 'RETAILER CODE', field: 'retailer_code', enableRowGroup: true, width: 80},
      {headerName: 'RETAILER', field: 'retailer_name', enableRowGroup: true, width: 150},
      {headerName: 'RETAILER TYPE', field: 'retailer_type', enableRowGroup: true, width: 80},
      {
        headerName: 'CLASS', field: 'class_type', enableRowGroup: true, width: 80,
        valueGetter(params) {
          if (params.data) {
            if (params.data.class_type != null) {
              return params.data.class_type.toUpperCase();
            }
          }
        }
      },
      {headerName: 'MOBILE', field: 'mobile', width: 100},
      {headerName: 'EMAIL', field: 'email', hide: true, width: 150},
      {headerName: 'GST ID', field: 'gst_id', hide: true, width: 100},
      {headerName: 'ADDRESS', field: 'address', hide: true, width: 100},
      {headerName: 'DATE', field: 'report_date', enableRowGroup: true, width: 80},
      {headerName: 'CHECK IN', field: 'check_in', width: 120},
      {headerName: 'CHECK OUT', field: 'check_out', width: 120},
    ];
    /* { headerName: "ACTIVITY",  field: "user_activity", enableRowGroup: true, width: 100},
     { headerName: "ACTIVITY TYPE",  field: "activity_type", enableRowGroup: true, width: 100},*/

    // tslint:disable-next-line:forin
    for (const key in this.activityTypes) {
      this.columnDefs.push({
        headerName: this.activityTypes[key],
        field: this.activityTypes[key],
        cellStyle: {textAlign: 'center'},
        width: 100,
        aggFunc: 'sum'
      });
    }
    this.columnDefs.push(
      /* {headerName: 'ACTIVITY COMMENTS', field: 'activity_comments', width: 100},
          {headerName: 'ACTIVITY LOCATION', field: 'activity_location', width: 80},
        {headerName: 'START TIME', field: 'activity_time', width: 120},
        {headerName: 'END TIME', field: 'end_time', width: 120},*/
      {
        headerName: 'DEVIATION', field: 'checkin_deviation', width: 100, aggFunc: 'sum',
        cellRenderer(params) {
          let displayElem = '';
          if (params._self.checkInDistance == null && params.value > 300) {
            displayElem = '<div style=" background-color:#F8D1C8;  text-align: center; padding: 0px;">' + params.value + '</div>';
          } else if (params._self.checkInDistance && params._self.checkInDistance !== null && params.value !== null && params._self.checkInDistance > 0 && params.value > params._self.checkInDistance) {
            //if (params.value > 300) {
            displayElem = '<div style=" background-color:#F8D1C8;  text-align: center;">' + params.value + '</div>';
            // return {textAlign: 'center', backgroundColor: '#F8D1C8'};
          } else {
            displayElem = '<div style=" text-align: center;">' + params.value + '</div>';
            //return {textAlign: 'center'};
          }
          return displayElem;
        },
        cellRendererParams: {
          _self: this
        }
      },
      {headerName: 'VISIT FREQUENCY', field: 'visit_frequency', enableRowGroup: true, cellStyle: {textAlign: 'center'}, width: 120},
      {
        headerName: 'GEO TAGGED', field: 'r_lat', width: 120, cellStyle: {textAlign: 'center'}, enableRowGroup: true,
        aggFunc: this.geoTagAggFunction,
        valueGetter(params) {
          if (params.data) {
            if (params.data.r_lat != null && params.data.r_lat !== "0.0" && params.data.r_long != null && params.data.r_long !== "0.0") {
              return 'true';
            } else {
              return 'false';
            }
          }
        }
      },
      /*{
        headerName: 'STATUS', field: 'status', width: 100,
        cellRenderer(params) {
          let displayElem = '';
          if (params.value === '1') {
            displayElem = '<span style="color:#379862; font-weight: 500;">COMPLETE</span>';
          } else {
            displayElem = '<span style="color:#B82525; font-weight: 500;">INCOMPLETE</span>';
          }

          return displayElem;
        },
      }*/
    );

  }

  /* loadServerData() {
  this.goClicked = true;
      //debugger;
     /!*   const t = trace(this.appModule.perf, this.sharedService.headerTitleSource.value);
        t.start();
        t.putAttribute('companyKey', localStorage.getItem('company_key'));*!/
     this.userData.offsetID = 0;
     this.userData.offset = 10000;
     this.apiData = [];
     this.showProgressCustomText = false;
     this.progressText = '';
     this.dataLoaded = false;
     this.preloader = true;
     this.emptyState = false;
     this.errorExists = false;

     if (this.API_URL === '') {
       return this.handleError(ErrorHandler.getErrorObject('API Not Configured'));
     }

     this.userData.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
     this.userData.end_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');

     this.fetch('retailer_id');
   }*/

  /* async fetch(key) {
     const res = await this.apiService.postPromise(this.API_URL, this.userData);
     console.log(res);
     if (res.hasOwnProperty('results') && (res.results.status === 200)) {
       // console.log(res);
       //this.apiData.push(res.results.data);
       for (const item of res.results.data) {
         this.apiData.push(item);
       }
       if (res.results.data.length === this.userData.offset) {
         this.userData.offsetID = this.apiData[this.apiData.length - 1][key];
         this.progressText = 'Downloaded ' + this.apiData.length + ' records';
         await this.fetch(key);
       } else {
         /!* this.dataLoaded = true;
          this.preloader = false;
          this.emptyState = (this.rowData.length === 0);*!/
         this.showProgressCustomText = false;
         this.configureGrid();
         this.configureData();
       }
     }
   }*/

  postProcessData() {
    this.configureData();
  }

  configureData() {

    this.progress = 0;
    this.rowData = [];
    let masterRow: any = {};
    masterRow = this.apiData.map(o => new Object(
      {
        salesman: o.salesman,
        report_date: o.report_date,
        retailer_id: o.retailer_id,
      }));

    /*   masterRow = masterRow.filter((thing, index, self) =>
         index === self.findIndex((t) => (
           t.salesman === thing.salesman && t.report_date === thing.report_date && t.retailer_id === thing.retailer_id
         )));*/
    let keyProps = ['salesman', 'report_date', 'retailer_id'];
    let kvArray = masterRow.map(entry => {
      const key = keyProps.map(k => entry[k]).join('|');
      return [key, entry];
    });
    let map = new Map(kvArray);
    masterRow = Array.from(map.values());

    /*let remaster : any = [];
    masterRow.forEach((master) => {
        if(mast)
    }*/

    const inThis = this;
    let i = 0;
    const diff = 100 - this.progress;
    const initial = this.progress;
    if (masterRow !== null) {
      const count = masterRow.length;
      const res = (async () => {
        for (const master of masterRow) {
          i++;
          if (i % 10000 === 0) {
            this.progress = Math.round(initial + ((i / count) * diff));
            await this.timeout(500);
          }

          let activityRow: any = {};
          let activityListRow: any = [];
          const checkindata: any = [];
          const orderdata: any = [];
          /* activityListRow = this.apiData.filter(api => api.salesman === master.salesman
             && api.report_date === master.report_date
             && api.retailer_id === master.retailer_id);*/
          this.apiData.forEach((api) => {
            if (api.salesman === master.salesman
              && api.report_date === master.report_date
              && api.retailer_id === master.retailer_id) {
              activityListRow.push(api);
              if (api.activity_type === 'Check In') {
                checkindata.push(api);
              }
              if (api.activity_type === 'Order') {
                orderdata.push(api);
              }
            }
          });
          /*  const checkindata = this.apiData.filter(api => api.salesman === master.salesman
              && api.report_date === master.report_date
              && api.activity_type === 'Check In'
              && api.retailer_id === master.retailer_id);*/

          /* keyProps = ['salesman', 'report_date', 'retailer_id'];
           kvArray = this.apiData.map(entry => {
             if (entry.activity_type === 'Check In') {
               const key = keyProps.map(k => entry[k]).join('|');
               return [key, entry];
             } else {
               return [];
             }
           });
           map = new Map(kvArray);
           const checkindata = Array.from(map.values());*/

          /* if (checkindata != null && checkindata.length > 0) {
             checkindata.sort((a, b) => (a.deviation < b.deviation) ? 1 : -1);
             activityRow.checkin_deviation = checkindata[0].deviation;
           } else {
             const orderdata = this.apiData.filter(api => api.salesman === master.salesman
               && api.report_date === master.report_date
               && api.activity_type === 'Order'
               && api.retailer_id === master.retailer_id);
             if (orderdata != null && orderdata.length > 0) {
               orderdata.sort((a, b) => (a.deviation < b.deviation) ? 1 : -1);
               activityRow.checkin_deviation = orderdata[0].deviation;
             } else {
               activityRow.checkin_deviation = 0;
             }
           }*/

          if (checkindata != null && checkindata.length > 0) {
            checkindata.sort((a, b) => (a.deviation < b.deviation) ? 1 : -1);
            activityRow.checkin_deviation = checkindata[0].deviation;
          } else {
            if (orderdata != null && orderdata.length > 0) {
              orderdata.sort((a, b) => (a.deviation < b.deviation) ? 1 : -1);
              activityRow.checkin_deviation = orderdata[0].deviation;
            } else {
              activityRow.checkin_deviation = 0;
            }
          }


          for (const key1 in this.activityTypes) {
            let activityData: any = [];
            /* activityData = this.apiData.filter(api => api.salesman === master.salesman
               && api.report_date === master.report_date
               && api.activity_type === inThis.activityTypes[key1]
               && api.retailer_id === master.retailer_id);*/

            this.apiData.forEach((api) => {
              if (api.salesman === master.salesman
                && api.report_date === master.report_date
                && api.retailer_id === master.retailer_id
                && api.activity_type === inThis.activityTypes[key1]) {
                activityData.push(api);
              }
            });
            if (activityData != null && activityData.length > 0) {
              activityRow[inThis.activityTypes[key1]] = activityData.length;
              if (this.activityTypes[key1] === 'Check In') {
                activityRow.check_in = activityData[0].activity_time;
                activityRow.check_out = activityData[0].end_time;
              }
            } else {
              activityRow[inThis.activityTypes[key1]] = 0;
            }
          }
          if (activityListRow != null && activityListRow.length > 0) {
            activityListRow = activityListRow[0];
          }

          Object.keys(activityListRow).forEach((key2, index) => {
            activityRow[key2] = activityListRow[key2];
          });

          this.rowData.push(activityRow);
          if (i === count - 1) {
            this.dataLoaded = true;
            this.preloader = false;
          }
        }
      })();
      console.log(this.rowData);
    }
  }

  /*configureData() {
    this.rowData = [];
    for (const key in this.apiData) {
      let indRow = {};
      let masterRow: any = {};
      let activityRow: any = {};
      masterRow = this.apiData[key];
      const inThis = this;
    /!*  activityListRow = this.apiData.filter(api => api.salesman === masterRow.salesman
        && api.report_date === masterRow.report_date
        && api.retailer_id === masterRow.retailer_id);*!/
      for (const key in this.activityTypes) {
      /!*  activityRow[this.activityTypes[key]] = this.apiData.filter(api => api.salesman === masterRow.salesman
          && api.report_date === masterRow.report_date
          && api.activity_type === inThis.activityTypes[key]
          && api.retailer_id === masterRow.retailer_id);
        if (activityRow[inThis.activityTypes[key]] != null && activityRow[inThis.activityTypes[key]].length > 0) {
          activityRow[inThis.activityTypes[key]] = activityRow[inThis.activityTypes[key]].length;
        } else {
          activityRow[inThis.activityTypes[key]] = 0;
        }*!/
        const activitiesRow = this.apiData.filter(api => api.salesman === masterRow.salesman
          && api.report_date === masterRow.report_date
          && api.activity_type === inThis.activityTypes[key]
          && api.retailer_id === masterRow.retailer_id);

        if (activitiesRow != null && activitiesRow.length > 0) {
          activityRow[inThis.activityTypes[key]] = activitiesRow.length;
        }
      }

      if (activityListRow != null && activityListRow.length > 0) {
        activityListRow = activityListRow[0];
      }

      Object.keys(activityListRow).forEach((key, index) => {
        activityRow[key] = activityListRow[key];
      });
     /!* indRow = {
        ...masterRow,
        ...activityRow
      };*!/
      this.rowData.push(activityRow);
    }
  }*/

  geoTagAggFunction(params) {
    // the average will be the sum / count
    var sum = 0;
    var count = 0;
    params.values.forEach(function(value) {
      var groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        // we are aggregating groups, so we take the
        // aggregated values to calculated a weighted average
        sum += value.avg * value.count;
        count += value.count;
      } else {
        // skip values that are not numbers (ie skip empty values)
        if (value === 'true') {
          sum += 1;
          count++;
        }
      }
    });
    // avoid divide by zero error
    if (count !== 0) {
      var avg = sum / count;
    } else {
      avg = null;
    }

    avg = Math.round(Math.round((avg + Number.EPSILON) * 100) / 100);

    // the result will be an object. when this cell is rendered, only the avg is shown.
    // however when this cell is part of another aggregation, the count is also needed
    // to create a weighted average for the next level.
    var result = {
      count: count,
      avg: avg,
      // the grid by default uses toString to render values for an object, so this
      // is a trick to get the default cellRenderer to display the avg value
      toString: function() {
        return sum;
      },
    };

    return result;

  }

  /*processData() {
    const beatOrderData = this.apiData.beatorders;
    let processedData = [];
    let i = 0;
    const count = beatOrderData.length;
    if (beatOrderData != null) {
      const res = (async () => {
        for (const beatOrder of beatOrderData) {
          i++;
          if (i % 10000 === 0) {
            this.progress = Math.round(((i / count) * 100) / 2);
            await this.timeout(500);
          }
          let indrow = {};
          let retailerRow: any = {};
          let salesmanRow: any = {};

          this.apiData.retailers.forEach((retailers) => {
            if (beatOrder.retailer_id === retailers.retailer_id) {
              retailerRow = retailers;
            }
          });

          this.apiData.salesman.forEach((salesman) => {
            if (beatOrder.salesman_id === salesman.salesman_id) {
              salesmanRow = salesman;
            }
          });

          indrow = {
            ...beatOrder,
            ...retailerRow,
            ...salesmanRow
          };

          processedData.push(indrow);
          if (i === beatOrderData.length - 1) {
            this.apiData = [];
            this.apiData = processedData;
            this.configureData();
          }
        }
      })();
    }
  }*/

}
