import {Component, OnInit} from "@angular/core";
import {ReportBase} from "../_common/reportbase";

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class VolumeDistributionReportComponent extends ReportBase implements IReport, OnInit  {

  /*showableFilters={
    max_claim:false,
    policy:false,
    salesman:true,
    status:false,
    class:false,
    beat:false,
    trax_outlet:false,
    non_trax_outlet:false,
    exclude_trax_audit:false,
    region:true,
    cluster:true,
    team:true,
    territory:false,
    outlet_type: false,
    dc: false
  }*/

  setTitle() {
      throw new Error("Method not implemented.");
  }

  ngOnInit() {

    this.sharedService.setHeader('Volume Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;


    this.configureGrid();
      }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getVolumeAuditsReport';

      this.columnDefs = [
      {headerName: 'REGION', field: 'region', enableRowGroup: true, maxWidth: 80},
      {headerName: 'CLUSTER', field: 'cluster', enableRowGroup: true, width: 120},
      {headerName: 'TERRITORY', field: 'territory', enableRowGroup: true, hide: true, width: 100},
      {headerName: 'SALESMAN', field: 'salesman', enableRowGroup: true, width: 100},
      { headerName: "CATEGORY",  field: "category", enableRowGroup: true, width: 100 },
      { headerName: "R CODE",  field: "o_code", enableRowGroup: true, width: 100, },
      { headerName: "RETAILER",  field: "o_name", enableRowGroup: true, width: 150, },
      { headerName: "P CODE",  field: "t_code", enableRowGroup: true, width: 100, },
      { headerName: "PRODUCT",  field: "product", enableRowGroup: true, width: 150, rowGroup: true },
      { headerName: "VOLUME",  field: "volume", width: 100, cellStyle: {textAlign: 'center'}, aggFunc: 'sum'},
      { headerName: "MARKET SHARE %",  field: "ms", cellStyle: {textAlign: 'center'}, width: 100, aggFunc: this.avgAggFunction},
      { headerName: "GROWTH %",  field: "growth", cellStyle: {textAlign: 'center'}, width: 100, aggFunc: this.avgAggFunction},
    ];
  }

  avgAggFunction(params) {
    // the average will be the sum / count
    var sum = 0;
    var count = 0;

    params.values.forEach(function (value) {
      var groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        // we are aggregating groups, so we take the
        // aggregated values to calculated a weighted average
        sum += value.avg * value.count;
        count += value.count;
      } else {
        // skip values that are not numbers (ie skip empty values)
        if (typeof value === 'number') {
          sum += value;
          count++;
        }
      }
    });
    // avoid divide by zero error
    if (count !== 0) {
      var avg = sum / count;
    } else {
      avg = null;
    }

    avg =  Math.round(Math.round((avg + Number.EPSILON) * 100) / 100);

    // the result will be an object. when this cell is rendered, only the avg is shown.
    // however when this cell is part of another aggregation, the count is also needed
    // to create a weighted average for the next level.
    var result = {
      count: count,
      avg: avg,
      // the grid by default uses toString to render values for an object, so this
      // is a trick to get the default cellRenderer to display the avg value
      toString: function () {
        return this.avg;
      },
    };

    return result;
  }

}
