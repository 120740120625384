import {Component, OnInit} from "@angular/core";
import {ReportBase} from "../_common/reportbase";
import {ErrorHandler} from "../../error/error-handler";
import {GridOptions} from "@ag-grid-enterprise/all-modules";

@Component({
  selector: 'app-tl-cluster-level-rollup-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class AvailabilityReportComponent extends ReportBase implements IReport,OnInit  {

  setTitle() {
    throw new Error("Method not implemented.");
  }

  ngOnInit() {

    this.sharedService.setHeader('Brand-wise Availability Report');

    this.showableFilters.territory = false;
    this.showableFilters.team = false;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;

    this.configureGrid();

  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getDistributionRawData';

    this.gridOptions.autoGroupColumnDef = {
      headerName: 'RETAILER',
      field: 'outlet',
      minWidth: 250,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        checkbox: true,
      },
    };


      this.columnDefs = [
        { headerName: '',
          children: [
            { headerName: 'CLUSTER', field: 'cluster', rowGroup: true, enableRowGroup: true, hide: true },
            { headerName: 'TERRITORY', field: 'territory', rowGroup: true, enableRowGroup: true, hide: true },
            { headerName: 'PRODUCT', field: 'product', rowGroup: true, enableRowGroup: true, hide: true},
            { headerName: 'TCODE', field: 'tcode',  enableRowGroup: true, width: 80},
            { headerName: 'RETAILER', field: 'outlet',  enableRowGroup: true, width: 80},
            { headerName: 'RCODE', field: 'rcode', enableRowGroup: true,  width: 80},
            { headerName: 'RTYPE', field: 'r_type', enableRowGroup: true,  width: 80},
            { headerName: 'CLASS', field: 'r_class', enableRowGroup: true,  width: 80},
            { headerName: 'TOWN TIER', field: 'program_type',  enableRowGroup: true, width: 80},
          ]},
        { headerName: 'AVAILABILITY',
          children: [
            { headerName: 'A->L', field: 'A->L', menuTabs: [], valueGetter: this.getvariantvalue, width: 50,  aggFunc: this.customavgAggFunction },
            { headerName: 'A-Q', field: 'A-Q', menuTabs: [], valueGetter: this.getvariantvalue, width: 50,  aggFunc: this.customavgAggFunction },
            { headerName: 'A-P', field: 'A-P', menuTabs: [], valueGetter: this.getvariantvalue, width: 50,  aggFunc: this.customavgAggFunction },
            { headerName: 'A-N', field: 'A-N', menuTabs: [], valueGetter: this.getvariantvalue, width: 50,  aggFunc: this.customavgAggFunction },
            { headerName: 'A-<D', field: 'A-<D', menuTabs: [], valueGetter: this.getvariantvalue, width: 50,  aggFunc: this.customavgAggFunction }
          ]},
        { headerName: 'TRAX',
          children: [
            { headerName: 'T->L', field: 'T->L', menuTabs: [], valueGetter: this.getvariantvalue, width: 50,  aggFunc: this.customavgAggFunction },
            { headerName: 'T-Q', field: 'T-Q', menuTabs: [], valueGetter: this.getvariantvalue, width: 50,  aggFunc: this.customavgAggFunction },
            { headerName: 'T-P', field: 'T-P', menuTabs: [], valueGetter: this.getvariantvalue, width: 50,  aggFunc: this.customavgAggFunction },
            { headerName: 'T-N', field: 'T-N', menuTabs: [], valueGetter: this.getvariantvalue, width: 50,  aggFunc: this.customavgAggFunction },
            { headerName: 'T-<D', field: 'T-<D', menuTabs: [], valueGetter: this.getvariantvalue, width: 50,  aggFunc: this.customavgAggFunction }
          ]},
      ];
  }

  getvariantvalue(params) {
    if (params.data) {
      const jv = params.data.distribution.find(x => x.v === params.colDef.field);
      if (jv) {
        return jv.a;
      }
    }
  }

  customavgAggFunction(params) {
    let avg;
// the average will be the sum / count
    var sum = 0;
    var count = 0;
    params.values.forEach(value => {
      const groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        // we are aggregating groups, so we take the
        // aggregated values to calculated a weighted average
        sum += value.avg * value.count;
        count += value.count;
      } else {
        // skip values that are not numbers (ie skip empty values)
        if (typeof value === 'number'  && (value === 100 || value === 0)) {
          sum += value;
          count++;
        }
      }
    });
    // avoid divide by zero error
    if (count !== 0) {
      avg = sum / count;
    } else {
      avg = null;
    }

    avg =  Math.round(Math.round((avg + Number.EPSILON) * 100) / 100);

    // the result will be an object. when this cell is rendered, only the avg is shown.
    // however when this cell is part of another aggregation, the count is also needed
    // to create a weighted average for the next level.
    const result = {
      count: count,
      avg: avg,
      // the grid by default uses toString to render values for an object, so this
      // is a trick to get the default cellRenderer to display the avg value
      toString: function() {
        return avg;
      },
    };

    return result;

  }

  avgAggFunction(params) {
    // the average will be the sum / count
    var sum = 0;
    var count = 0;

    params.values.forEach(function (value) {
      var groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        // we are aggregating groups, so we take the
        // aggregated values to calculated a weighted average
        sum += value.avg * value.count;
        count += value.count;
      } else {
        // skip values that are not numbers (ie skip empty values)
        if (typeof value === 'number') {
          sum += value;
          count++;
        }
      }
    });
    // avoid divide by zero error
    if (count !== 0) {
      var avg = sum / count;
    } else {
      avg = null;
    }

    avg = Math.round((avg + Number.EPSILON) * 100) / 100

    // the result will be an object. when this cell is rendered, only the avg is shown.
    // however when this cell is part of another aggregation, the count is also needed
    // to create a weighted average for the next level.
    var result = {
      count: count,
      avg: avg,
      // the grid by default uses toString to render values for an object, so this
      // is a trick to get the default cellRenderer to display the avg value
      toString: function () {
        return this.avg.toFixed(0);
      },
    };

    return result;
  }


}
