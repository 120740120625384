<div (click)="onBtPrint()" class="icon-printer-bg" data-toggle="tooltip" data-placement="bottom" title="Print">
  <svg
    width="24px"
    height="22px"
    viewBox="0 0 25 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      opacity="0.699999988"
    >
      <g
        id="Production-order"
        transform="translate(-1280.000000, -121.000000)"
        fill="#000000"
        fill-rule="nonzero"
      >
        <g id="Group-15" transform="translate(1267.000000, 110.000000)">
          <g
            id="noun_print_2647374"
            transform="translate(13.000000, 11.000000)"
          >
            <g id="Group">
              <path
                d="M24.2283951,9.56790123 L20.2160494,9.56790123 L20.2160494,0.894135802 C20.2160494,0.553395062 19.9395062,0.276851852 19.5987654,0.276851852 L5.40123457,0.276851852 C5.06049383,0.276851852 4.78395062,0.553395062 4.78395062,0.894135802 L4.78395062,9.56790123 L0.771604938,9.56790123 C0.430864198,9.56790123 0.154320988,9.84444444 0.154320988,10.1851852 L0.154320988,16.2037037 C0.154320988,16.5444444 0.430864198,16.8209877 0.771604938,16.8209877 L4.78395062,16.8209877 L4.78395062,22.4518519 C4.78395062,22.7925926 5.06049383,23.0691358 5.40123457,23.0691358 L19.5987654,23.0691358 C19.9395062,23.0691358 20.2160494,22.7925926 20.2160494,22.4518519 L20.2160494,16.8209877 L24.2283951,16.8209877 C24.5691358,16.8209877 24.845679,16.5444444 24.845679,16.2037037 L24.845679,10.1851852 C24.845679,9.84444444 24.5691358,9.56790123 24.2283951,9.56790123 Z M6.01851852,1.51141975 L18.9814815,1.51141975 L18.9814815,9.56790123 L6.01851852,9.56790123 L6.01851852,1.51141975 Z M18.9814815,21.8345679 L6.01851852,21.8345679 L6.01851852,13.8117284 L18.9814815,13.8117284 L18.9814815,21.8345679 Z M23.6111111,15.5864198 L20.2160494,15.5864198 L20.2160494,13.1944444 C20.2160494,12.8537037 19.9395062,12.5771605 19.5987654,12.5771605 L5.40123457,12.5771605 C5.06049383,12.5771605 4.78395062,12.8537037 4.78395062,13.1944444 L4.78395062,15.5864198 L1.38888889,15.5864198 L1.38888889,10.8024691 L5.40123457,10.8024691 L19.5987654,10.8024691 L23.6111111,10.8024691 L23.6111111,15.5864198 Z"
                id="Shape"
              ></path>
              <path
                d="M8.10185185,16.8209877 L16.8981481,16.8209877 C17.2388889,16.8209877 17.5154321,16.5444444 17.5154321,16.2037037 C17.5154321,15.862963 17.2388889,15.5864198 16.8981481,15.5864198 L8.10185185,15.5864198 C7.76111111,15.5864198 7.4845679,15.862963 7.4845679,16.2037037 C7.4845679,16.5444444 7.76111111,16.8209877 8.10185185,16.8209877 Z"
                id="Path"
              ></path>
              <path
                d="M8.10185185,19.5259259 L16.8981481,19.5259259 C17.2388889,19.5259259 17.5154321,19.2493827 17.5154321,18.908642 C17.5154321,18.5679012 17.2388889,18.291358 16.8981481,18.291358 L8.10185185,18.291358 C7.76111111,18.291358 7.4845679,18.5679012 7.4845679,18.908642 C7.4845679,19.2493827 7.76111111,19.5259259 8.10185185,19.5259259 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</div>
