import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-eodcarddesc',
  templateUrl: './eodcarddesc.component.html',
  styleUrls: ['./eodcarddesc.component.scss']
})
export class EodcarddescComponent implements OnInit {
  @Input() title: string;
  @Input() achv: string;
  @Input() target: string;
  @Input() perc: string;

  constructor() { }

  ngOnInit() {
  }

}

