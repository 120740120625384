import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {ErrorHandler} from "../../error/error-handler";

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

// tslint:disable-next-line:component-class-suffix
export class RetailerVerticalDistributorMasterReport extends ReportBase implements IReport, OnInit {

  public attributeTypes = [];
  showCustomFilter = true;
  customFilter: any = {
    name: 'Active',
    key: 'active',
    value: 'true',
    values: [],
    show: true
  };
  apiData: any = [];

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Retailer Supplier Mapping Report');

    /*this.showableFilters.salesman = true;
    this.showableFilters.team = true;*/
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.showDate = false;

    this.loadInBatches(10000, 'rank');
    this.showProgressCustomText = true;



    this.customFilter.values = [
      {id: '', itemName: 'All'},
      {id: 'true', itemName: 'Active'},
      {id: 'false', itemName: 'Inactive'}
    ];
    this.gridOptions.autoGroupColumnDef = {
      headerName: 'Retailer',
      field: 'r_name',
      minWidth: 300,
      cellRenderer: 'agGroupCellRenderer',
      resizable: true,
      pinned: 'left'
    };
    this.API_URL = '/api/pwa_reports/getDealerDpVerticalMapping';
    this.configureGrid();
  }

  configureGrid() {
    const inThis = this;
    this.columnDefs = [
      {headerName: 'ID', field: 'r_id', width: 60, },
      {headerName: 'CODE', field: 'r_code', width: 60},
      {headerName: 'NAME', field: 'r_name', enableRowGroup: true, width: 100},
      {headerName: 'TYPE', field: 'r_type', enableRowGroup: true, width: 60},
      {headerName: 'PROGRAM', enableRowGroup: true, field: 'prg_type', width: 60},
      {headerName: 'PRICELIST', field: 'pricelist_name', width: 80, hide: true},
      {
        headerName: 'CLASS', field: 'r_class', enableRowGroup: true, width: 40,
        valueGetter(params) {
          if (params.data) {
            if (params.data.r_class != null) {
              return params.data.r_class.toUpperCase();
            }
          }
        }
      },
      {headerName: 'CHANNEL', field: 'channel', enableRowGroup: true, width: 80},
      {headerName: 'CITY', field: 'rp_city', enableRowGroup: true, width: 60},
      {headerName: 'ZIP', field: 'rp_zip', enableRowGroup: true, width: 60},
      {headerName: 'DISTRICT', field: 'zone', enableRowGroup: true, width: 80},
      {headerName: 'STATE', field: 'state', enableRowGroup: true, width: 80},
      {headerName: 'CONTACT ', field: 'contact_name', width: 80},
      {headerName: 'MOBILE NO ', field: 'contact_no', width: 80},
      {headerName: 'GST', field: 'gstin', width: 80},
      {headerName: 'PAN', field: 'pan', width: 80},
      {
        headerName: 'GEO TAGGED', field: 'r_lat', width: 120, cellStyle: {textAlign: 'center'}, enableRowGroup: true,
        aggFunc: this.geoTagAggFunction,
        valueGetter(params) {
          if (params.data) {
            if (params.data.lat != null && params.data.lat !== "0.0" && params.data.lng != null && params.data.lng !== "0.0") {
              return 'true';
            } else {
              return 'false';
            }
          }
        }
      },
      {headerName: 'LAT', field: 'lat', width: 60 , hide: true},
      {headerName: 'LONG', field: 'lng', width: 60, hide: true},
      {headerName: 'ACTIVE', field: 'status', enableRowGroup: true, width: 80},
      {headerName: 'CREATE DATE', field: 'created_on', enableRowGroup: true, width: 80, hide: true,
        valueGetter: params => {
          if (params.data) {
            if (params.data.created_on) {
              return inThis.datePipe.transform((new Date(params.created_on + ' UTC')).toString(), 'yyyy-MM-dd');
            } else {
              return params.data.created_on;
            }
          } else {
            return '';
          }
        }},
      {headerName: 'CREATED BY', field: 'create_name', enableRowGroup: true, width: 80, hide: true},
      {headerName: 'LAST MODIFIED', field: 'write_date', enableRowGroup: true, width: 80, hide: true},
      {headerName: 'LAST MODIFIED BY', field: 'write_name', enableRowGroup: true, width: 80, hide: true},
      {headerName: 'POTENTIAL', field: 'potential', width: 60, hide: true},

      {headerName: 'VERTICAL CODE', field: 'bg_code', enableRowGroup: true,  width: 80},
      {headerName: 'VERTICAL', field: 'bg_name', enableRowGroup: true,  width: 80},

      {headerName: 'DIST ID', field: 'd_is', width: 80, hide: true},
      {headerName: 'DIST CODE', field: 'd_code', enableRowGroup: true,  width: 80},
      {headerName: 'DIST NAME', field: 'd_name', enableRowGroup: true, width: 100},
      {headerName: 'DIST TYPE', field: 'd_type', enableRowGroup: true, width: 80},


    ];


  }



  geoTagAggFunction(params) {
    let avg;
// the average will be the sum / count
    let sum = 0;
    let count = 0;
    params.values.forEach(value => {
      const groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        // we are aggregating groups, so we take the
        // aggregated values to calculated a weighted average
        sum += value.avg * value.count;
        count += value.count;
      } else {
        // skip values that are not numbers (ie skip empty values)
        if (value === 'true') {
          sum += 1;
          count++;
        }
      }
    });
    // avoid divide by zero error
    if (count !== 0) {
      avg = sum / count;
    } else {
      avg = null;
    }

    avg = Math.round(Math.round((avg + Number.EPSILON) * 100) / 100);

    // the result will be an object. when this cell is rendered, only the avg is shown.
    // however when this cell is part of another aggregation, the count is also needed
    // to create a weighted average for the next level.
    const result = {
      count,
      avg,
      // the grid by default uses toString to render values for an object, so this
      // is a trick to get the default cellRenderer to display the avg value
      toString: () => sum,
    };

    return result;

  }



}
