import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class BeatPlanningAndTargetTrackerComponent extends ReportBase implements IReport, OnInit {

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader(' Beat Planning and Target Tracker');
    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.state = true;
    this.showableFilters.region = true;
    this.showDate = true;
    this.configureGrid();
  }

  mainAggFunction(params) {
    let avg;
// the average will be the sum / count
    let sum = 0;
    let count = 0;
    let type = '';
    let salesmanGroup = false;
    let loopParam = params.rowNode;
    while (loopParam.hasOwnProperty('parent')) {
      if (loopParam.parent.field === 'salesman') {
        salesmanGroup = true;
      }
      loopParam = loopParam.parent;
    }
    if (params.rowNode.hasOwnProperty('rowGroupColumn') && params.rowNode.rowGroupColumn.colDef.headerName === 'SALESMAN') {
      salesmanGroup = true;
    }

    params.values.forEach(function(value) {
      const groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      type = value.type;
      if (groupNode) {
        // we are aggregating groups, so we take the
        // aggregated values to calculated a weighted average
        if (!salesmanGroup) {
          // sum += value.avg * value.count;
          sum += value.sum;
          count += value.count;
        } else {
          sum = value.sum;
        }
        /*  sum += value;
          count += value.count;*/
      } else {
        // skip values that are not numbers (ie skip empty values)
        if (typeof value.sum === 'number') {
          //sum += value;
          if (value.type === 'MAIN') {
            sum = value.sum;
          } else {
            sum += value.sum;
          }
          count++;
        } else {
        }
      }
    });

    const result = {
      count,
      type,
      sum,
      toString() {
        return sum.toFixed(2);
      },
    };

    return result;
  }

  avgAggFunction(params) {
    let avg;
// the average will be the sum / count
    let sum = 0;
    let count = 0;

    params.values.forEach(function(value) {
      const groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        // we are aggregating groups, so we take the
        // aggregated values to calculated a weighted average
        sum += value.avg * value.count;
        count += value.count;
      } else {
        // skip values that are not numbers (ie skip empty values)
        if (typeof value === 'number') {
          sum += value;
          count++;
        }
      }
    });
    // avoid divide by zero error
    if (count !== 0) {
      avg = sum / count;
    } else {
      avg = null;
    }

    avg = Math.round(Math.round((avg + Number.EPSILON) * 100) / 100);

    // the result will be an object. when this cell is rendered, only the avg is shown.
    // however when this cell is part of another aggregation, the count is also needed
    // to create a weighted average for the next level.
    const result = {
      count,
      avg,
      // the grid by default uses toString to render values for an object, so this
      // is a trick to get the default cellRenderer to display the avg value
      toString() {
        return this.avg.toFixed(2);
      },
    };

    return result;
  }

  configureGrid() {
    this.API_URL = '/api/pwa_v2_reports/beat_VisitAndRevenue_TargetReport';
    const inthis = this;
    this.columnDefs = [

      {headerName: 'REGION', field: 'region', width: 80},
      {headerName: 'STATE', field: 'state', width: 80},
      {headerName: 'TEAM', field: 'team', width: 100},
      {headerName: 'EMP CODE', field: 'emp_code', menuTabs: [], width: 80},
      {headerName: 'EMP NAME', field: 'salesman', width: 150},
      {headerName: 'ROLE', field: 'role', width: 100},
      {headerName: 'MOBILE', field: 'mobilenumber', width: 100},
      {headerName: 'BEAT ID', field: 'beat_id', width: 150},
      {headerName: 'BEAT', field: 'beat_name', width: 150},
      {headerName: 'MONTH', field: 'jc_name', width: 150},
      {
        headerName: 'Beat Visit',
        children: [
          {headerName: 'Target', field: 'bv_target',   width: 100,aggFunc: 'sum'},
          {headerName: 'Planned', field: 'bv_planned', width: 100,aggFunc: 'sum'},
          {headerName: 'Ach', field: 'bv_ach', width: 120,aggFunc: 'sum',},
          {headerName: 'Ach %', field: 'bv_ach_pct',  width: 100,aggFunc: 'avg'},
          {headerName: 'Planned TC', field: 'planned_tc',  width: 100,aggFunc: 'sum'},
          {headerName: 'MTD PC', field: 'mtd_pc', width: 100,aggFunc: 'sum'},
          {headerName: 'MTD UPC', field: 'mtd_upc', width: 100,aggFunc: 'sum'},
          {
            headerName: 'PROGRESS', field: 'progress', minWidth: 180, menuTabs: [], aggFunc: this.avgAggFunction,
            valueGetter: params => {
              if (params.data) {
                  return Math.trunc(params.data.bv_ach_pct);
                }
              },
            cellRenderer: params => {
              let displayElem = '<div class="row">\n' +
                '            <div class="progresscontainer1">\n' +
                '              <div class="progressinside1" style=" ';

              if (params.value > 50) {
                displayElem = displayElem + ' background-color: #379862;';
              } else if (params.value > 20) {
                displayElem = displayElem + ' background-color: #D97C0E;';
              } else {
                displayElem = displayElem + ' background-color: #E24260;';
              }
              let prog = params.value;
              if (params.value && parseFloat(params.value) > 100) {
                prog = 100;
              }
              displayElem = displayElem + 'width:' + prog + '%;"></div>\n' +
                '            </div>\n' +
                '            <div class="progresstext1" style="';

              if (params.value > 50) {
                displayElem = displayElem + ' color: #379862;';
              } else if (params.value > 20) {
                displayElem = displayElem + ' color: #D97C0E;';
              } else {
                displayElem = displayElem + ' color: #E24260;';
              }

              displayElem = displayElem + '">' + params.value + '%</div></div>';


              return displayElem;
            }
          }
        ]
      },
      {
        headerName: 'Beat Revenue',
        children: [
          {headerName: 'Target', field: 'br_target',  width: 100,aggFunc: 'sum'},
          {headerName: 'Planned', field: 'br_planned',   width: 100,aggFunc: 'sum'},
          {headerName: 'Ach', field: 'br_ach', width: 100,aggFunc: 'sum'},
          {headerName: 'Ach %', field: 'br_ach_pct',  width: 100,aggFunc: 'avg'},
          {headerName: 'MTD ABV', field: 'mtd_abv',  width: 100,aggFunc: 'sum'},
          {headerName: 'MTD ALS', field: 'als',width: 100,aggFunc: 'sum'},
          {headerName: 'Sales Per Outlet', field: 'spo',  width: 100,aggFunc: 'sum'},
          {
            headerName: 'PROGRESS', field: 'progress', minWidth: 180, menuTabs: [], aggFunc: this.avgAggFunction,
            valueGetter: params => {
              if (params.data) {
                return Math.trunc(params.data.br_ach_pct);
              }
            },
            cellRenderer: params => {
              let displayElem = '<div class="row">\n' +
                '            <div class="progresscontainer1">\n' +
                '              <div class="progressinside1" style=" ';

              if (params.value > 50) {
                displayElem = displayElem + ' background-color: #379862;';
              } else if (params.value > 20) {
                displayElem = displayElem + ' background-color: #D97C0E;';
              } else {
                displayElem = displayElem + ' background-color: #E24260;';
              }
              let prog = params.value;
              if (params.value && parseFloat(params.value) > 100) {
                prog = 100;
              }
              displayElem = displayElem + 'width:' + prog + '%;"></div>\n' +
                '            </div>\n' +
                '            <div class="progresstext1" style="';

              if (params.value > 50) {
                displayElem = displayElem + ' color: #379862;';
              } else if (params.value > 20) {
                displayElem = displayElem + ' color: #D97C0E;';
              } else {
                displayElem = displayElem + ' color: #E24260;';
              }

              displayElem = displayElem + '">' + params.value + '%</div></div>';


              return displayElem;
            }
          }
        ]
      },
    ];

  }

}
