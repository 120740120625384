import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-outlet-last-visit-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class BeatLastVisitReportComponent extends ReportBase implements IReport, OnInit {

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Beat Last Visit Report');

    //this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.showDate = false;

    this.configureGrid();

    this.gridOptions.headerHeight = 40;
    this.gridOptions.autoGroupColumnDef = {
      headerName: 'Retailer Name',
      field: 'retailer_name',
      minWidth: 200,
      cellRenderer: 'agGroupCellRenderer',
      resizable: true
    };
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getBeatLastVisitReport';

    this.columnDefs = [

      {headerName: 'REGION', field: 'region', enableRowGroup: true, width: 80},
      {headerName: 'CLUSTER', field: 'cluster', enableRowGroup: true, width: 80},
      {headerName: 'TCODE', field: 'terr_code', enableRowGroup: true, hide: true, width: 80},
      {headerName: 'TERRITORY', field: 'territory', enableRowGroup: true, width: 80},
      {headerName: 'BEAT ID', field: 'beat_id', enableRowGroup: true, hide: true, width: 80},
      {headerName: 'BEAT', field: 'beat', enableRowGroup: true, width: 120},
      {headerName: 'SALESMAN', field: 'salesman', enableRowGroup: true, width: 100},
      {headerName: 'LAST VISIT', field: 'last_visit_date', maxWidth: 100},
      {headerName: 'VISIT AGEING', field: 'last_visit_since', maxWidth: 100},
      {headerName: 'TARGET', field: 'target',  maxWidth: 80},
      {headerName: 'REVENUE', field: 'revenue', minWidth: 80},
      {headerName: 'OUTLET COUNT', field: 'tc', maxwidth: 80},
      {headerName: 'VISITED COUNT', field: 'vc', maxwidth: 80},
      {headerName: 'PRODUCTIVE COUNT', field: 'pc', maxwidth: 80},
      {headerName: 'NOT VISITED', field: 'nv', maxwidth: 80},
      {headerName: 'NO ORDER', field: 'nr', maxwidth: 80}

    ];

  }

}
