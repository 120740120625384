import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {GridsterItem} from 'angular-gridster2';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-widget-progressbar',
  template: `
    <div class="info-box">
      <span class="heading">{{widget.data.label}}</span>
      <div class="progresscontainer" style="width: 100%">
        <div id="progressinside" class="progressinside">
        </div>
      </div>
      <div class="progresstext">
        <div class="row" style="display:block;">
          <span style="display:block; float:left;">{{widget.data.value[widget.data.data[0].key]}}</span>
          <span style="display:block; float:right;">{{widget.data.value[widget.data.data[1].key]}}</span>

        </div>
        <div class="row" style="display:block;">
          <span style="display:block; float:left;">{{widget.data.data[0].label}}</span>
          <span style="display:block; float:right;">{{widget.data.data[1].label}}</span>
        </div>
      </div>
    </div>`,
  styleUrls: ['./../dashboard.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ProgressBarWidgetComponent implements OnInit, OnDestroy {
  @Input()
  widget;
  @Input()
  resizeEvent: EventEmitter<GridsterItem>;

  resizeSub: Subscription;
  progress = 0;

  ngOnInit(): void {
    this.resizeSub = this.resizeEvent.subscribe(widget => {
      if (widget === this.widget) {
        // or check id , type or whatever you have there
        // resize your widget, chart, map , etc.
        console.log(widget);
      }
    });
    this.progress = (this.widget.data.value[this.widget.data.data[0].key] / this.widget.data.value[this.widget.data.data[1].key]) * 100;
    document.getElementById('progressinside').style.width = String(this.progress) + '%';
  }

  ngOnDestroy(): void {
    this.resizeSub.unsubscribe();
  }
}
