import {PageBase} from "../../../pagebase/pagebase";
import {Component, OnInit} from "@angular/core";
import {ErrorHandler} from "../../../error/error-handler";
import {MatDialogConfig} from "@angular/material/dialog";
import {PaymentCollectPopupComponent} from "../payments/payment_collect_popup/payment_collect_popup.component";
import {CreditNotePopupComponent} from "./credit_note_popup/credit_popup.component";
import {PrintInvoiceComponent} from "../../../printInvoice.component";
import {PrintClickRenderer} from "../../../printClickRenderer.component";


@Component({
  selector: 'app-credit-note-list',
  templateUrl: '../../../pagebase/pagebase.html',
  styleUrls: ['../../../pagebase/pagebase.scss']
})

export class CreditNoteListComponent extends PageBase implements OnInit, IPage {

  customFilter: any = {
    name: 'Status',
    key: 'status',
    value: '1',
    values: [],
    show: true
  };
  printShowField = 'invoice_id';

  ngOnInit(): void {

    this.sharedService.setHeader('Credit Notes');
    this.showActionButton = true;
    this.customFilter.values = [
      {id: '0', itemName: 'All'},
      {id: '1', itemName: 'Open'},
      {id: '2', itemName: 'Used'},
    ];
    this.showCustomFilter = true;
    this.showPinnedFooter = true;
    this.columnsWithAggregation = ['amount', 'balance'];
    this.statusConfig.success = 'Used';
    this.statusConfig.warning = 'Open';
    this.checkAndLoadPreviousSate();
    this.getAuthorizedActions();
    this.checkAccessControl();
    this.configureGrid();
    this.loadServerData();
  }

  configureGrid() {

    this.API_URL = '/api/pwa_dms_connects/get_credit_notes';
    this.columnDefs = [
      {headerName: "ID", field: "id", width: 60, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: params => {
          if (params.data) {
            if (params.data.invoice_id) {
              return true;
            } else {
              return false;
            }
          }
        }},
      {headerName: "CN NO", field: "name", width: 50},
      {headerName: "CN Date", field: "cn_date", width: 60},
      {headerName: "Customer Code", field: "cust_code", width: 60},
      {headerName: "Customer Name", field: "cust_name", width: 250},
      {headerName: "Payment Type", field: "payment_type", width: 50, hide: true},
      {headerName: "Amount", field: "amount", width: 50,  aggFunc: 'sum', cellStyle: {textAlign: 'right'}, valueFormatter: params => this.formatNumber(params.value)},
      {headerName: "Balance", field: "balance", width: 50, aggFunc: 'sum', cellStyle: {textAlign: 'right'}, valueFormatter: params => this.formatNumber(params.value)},
      {headerName: "Invoice", field: "invoice_no", width: 100},
      {headerName: "CN Type", field: "cn_type", width: 50},
      {headerName: "Narration", field: "comments", width: 200},
      {headerName: "Status", field: "status", width: 100, cellRenderer: this.sharedService.statusCellRenderer},
      {
        headerName: 'PRINT', field: 'PRINT', pinned: 'right',
        headerComponent: PrintInvoiceComponent,
        cellRenderer: PrintClickRenderer, width: 50
      }
    ];

    this.getRowId = (params) => params.data.id;
  }

  printAll(rows) {
    this.appComponent.printAll(rows, 'invoice_id', '/api/pwa_dms_connects/get_invoice_print_details', null);
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    const filteredRowData = this.gridApi.rowModel.rowsToDisplay;
    if (column === 'PRINT') {
      this.appComponent.printAll([event.data], 'invoice_id', '/api/pwa_dms_connects/get_invoice_print_details', null);
    } else {
     /* if (filteredRowData !== null && filteredRowData.length > 0) {
        const ids = [];
        filteredRowData.forEach(r => {
          ids.push(r.data.id);
        });
        localStorage.setItem('active_ids', JSON.stringify(ids));
        this.router.navigate([this.apiService.redirect_url + '/dms/sale_invoice'], {
          relativeTo: this.route,
          queryParams: {id: rows[0].id}
        });
      }*/
    }
  }

  checkAccessControl() {
    // this._localCacheService.getAccessControlList().then(list => {
    this.showCreate = true;
    // });
  }

  openCreateDialog() {

    let creatediaeref;
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        maxWidth: "30%",
        height: "auto",
        maxHeight: '100%',
        panelClass: 'custom-dialog-container',
      };
      creatediaeref = this.dialog.open(CreditNotePopupComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',

      };
      creatediaeref = this.dialog.open(CreditNotePopupComponent, config);
    }

    creatediaeref.componentInstance.refreshPageAction.subscribe(data => {
      if (data) {
        this.loadServerData();
        // this.dialog.closeAll();
      } else {
        // this.dialog.closeAll();
      }
    });
  }

}
