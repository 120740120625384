<div class="header">
  <div class="header-content" id="header-content">
      <label class="header-text">{{_title}}</label>
    <div class="header-profile">
      <ul style=" display:flex !important;padding-top: 0px;margin-top: 0px;" class="nav">
        <li>
          <div>
            <a style="margin: 3px;display: inline" (click)="profileClick()">
              <img style="width:36px;" src="../../../assets/menu/profile.svg"/>
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

