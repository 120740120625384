<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button class="closebtn" (click)="closeDialog()">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Change Promoters</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F3F6FD; padding: 15px;">
      <div style="display: inline-block">
        <label style="font-weight: 400;font-size: 12px;color: #2D2D2D">
          You have selected
        </label>
        <label style="font-weight: 500;font-size: 12px;color: #2D2D2D; margin-left: 3px;">
          {{selected_retailer_count}}
        </label>
        <label style="font-weight: 400;font-size: 12px;color: #2D2D2D; margin-left: 3px;">
          retailers
        </label>
      </div>


      <div style="display: inline-block; margin-top: 10px; width: 100%">
        <form novalidate id="ngForm" #changePromoterform="ngForm">



          <div style="margin-top: 20px; margin-bottom: 20px; border-left: 4px solid #17A2B8">
            <div style="display: inline;">
              <p style="padding-left: 8px;">
                <mat-radio-group aria-label="last_retailer_option"
                                 [(ngModel)]="retailer_promoter_mode" [ngModelOptions]="{standalone: true}" (change)="changeSelection()">
                  <mat-radio-button value="remove" checked>
                    Remove the current Promoter mapping.
                  </mat-radio-button>
                  <mat-radio-button value="change" style="margin-top: 5px;">
                    Change the current Promoter with anther promoter.
                  </mat-radio-button>
                </mat-radio-group>
              </p>

            </div>
          </div>


          <div *ngIf="retailer_promoter_mode==='change'" style="margin-top: 20px; margin-bottom: 20px;">
            <div>
              <label>Role</label>
              <div style="margin-top: 5px;">
                <div class="formselectwhitebg">
                  <select
                    name="role_id"
                    [(ngModel)]="selectedData.role_id"
                    (change) = "onChangeRole()"
                    #role_id="ngModel"
                    style="width: 100%;">
                    <option
                      *ngFor="let item of rolesList"
                      value="{{ item.id }}"
                    > {{ item.itemname }}</option>
                  </select>
                </div>
              </div>
            </div>

            <div style="margin-top: 15px;">
              <label>Promoter/Salesman</label>
              <div style="margin-top: 5px;">
                <div class="formselectwhitebg">
                  <select
                    name="salesman_id"
                    [(ngModel)]="selectedData.salesman_id"
                    #salesman_id="ngModel"
                    (change) = "onSalesmanChange()"
                    style="width: 100%;">
                    <option
                      *ngFor="let item of filteredSalesmanList"
                      value="{{ item.id }}"
                    > {{ item.itemname }}</option>
                  </select>
                </div>
                <div>
                  <p class="lbl3" *ngIf="selectedData.team_name">Team: {{selectedData.team_name}}</p>
                  <p class="lbl3" *ngIf="selectedData.region_name">Region: {{selectedData.region_name}}</p>
                  <p *ngIf="!selectedData.salesman_id" style="font-weight: 400;font-size: 12px;color: #2D2D2D; padding-left: 8px;">
                    <span style="font-weight: 400;font-size: 12px;color: #E24260; margin-left: 3px; margin-right: 3px;">No Promoter selected!</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
    <div class="button-row" style="margin-top: 10px;">
      <div style="float: right; margin: 15px 30px 15px 0px;">
           <span style=" vertical-align:bottom;margin-top: 20px; margin-right: 15px;" (click)="closeDialog()">
           <a class="btn grey-btn" >CANCEL</a>
        </span>
        <button
          mat-button
          class="btn confirm-green-btn"
          (click)="onSave()"
          form="ngForm"
          [disabled]="changePromoterform.form.invalid"
        >
          SAVE
        </button>
      </div>
    </div>
  </div>
</section>
