<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Create User - Sales Executive</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <div style="margin-top: 10px;">
        <div class="row">
          <div class="col-md-6 pl-0 mt-0">
            <span class="dialoglabel">Name</span>
            <span class="requiredspan"> *</span>
            <input
              class="forminputwhitebg"
              style="display: block; color: #0762AD; font-size: 14px; margin-top: 5px;width: 100%;"
              type="text"
              name="customer_name"
              (keydown)="onUserKeyDown($event)"
              [(ngModel)]="activeObject.user_name"
              #user_name="ngModel"
              placeholder="Enter Customer Name"
              required
            >
            <div *ngIf="user_name.errors && (user_name.dirty || user_name.touched)">
              <span *ngIf="user_name.errors.required" class="text-danger">Customer Name is required</span>
            </div>
          </div>
          <div class="col-md-6 pl-0 mt-0">
            <span class="dialoglabel">EMP Code</span>
            <span class="requiredspan"> *</span>
            <div>
              <input
                class="forminputwhitebg"
                style="display: block; color: #0762AD; font-size: 14px; margin-top: 5px;width: 100%;"
                type="text"
                name="customer_code"
                [(ngModel)]="activeObject.emp_code"
                #emp_code="ngModel"
                (keydown)="onEmpCodeKeyDown($event)"
                placeholder="unique Code or alias"
                required
              >
              <div *ngIf="emp_code.errors && (emp_code.dirty || emp_code.touched)">
                <span *ngIf="emp_code.errors.required" class="text-danger">Customer Code is required</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 10px;">
        <div class="row">
          <div class="col-md-6 pl-0 mt-0">
            <span class="dialoglabel">Login/Email</span>
            <span class="requiredspan"> *</span>
            <div>
              <input
                class="forminputwhitebg"
                style="display: block; color: #0762AD; font-size: 14px; margin-top: 5px;width: 100%;"
                type="text"
                name="login"
                [(ngModel)]="activeObject.login"
                #login="ngModel"
                (keydown)="onLoginDown($event)"
                placeholder="unique login"
                required
              >
              <div *ngIf="login.errors && (login.dirty || login.touched)">
                <span *ngIf="login.errors.required" class="text-danger">Unique Login is required</span>
              </div>
            </div>
          </div>
          <div class="col-md-6 pl-0 mt-0 pr-0">
            <span class="dialoglabel">Mobile</span>
            <span class="requiredspan"> *</span>
            <div>
              <input
                class="forminputwhitebg"
                style="display: block; color: #0762AD; font-size: 14px; margin-top: 5px;width: 100%;"
                type="number"
                name="customer_mobile"
                [(ngModel)]="activeObject.mobile"
                #customer_mobile="ngModel"
                placeholder="Enter Mobile No"
                required
              >
              <div *ngIf="customer_mobile.errors && (customer_mobile.dirty || customer_mobile.touched)">
                <span *ngIf="customer_mobile.errors.required" class="text-danger">Customer Mobile is required</span>
              </div>
            </div>
          </div>

        </div>
      </div>


      <div class="row">
        <div class="col-md-6 pl-0 mt-0 pr-0">
          <span class="dialoglabel">Role</span>
          <span class="requiredspan"> *</span>
          <div style="margin-top: 10px;">
            <ng-select [items]="rolesList"
                       bindLabel="itemname"
                       bindValue="id"
                       class="custom"
                       [(ngModel)]="activeObject.role_id">
              >
            </ng-select>
          </div>
        </div>

        <div class="col-md-6 pl-0 mt-0">
          <span class="dialoglabel">Sales Area Code</span>
          <div>
            <input
              class="forminputwhitebg"
              style="display: block; color: #0762AD; font-size: 14px; margin-top: 5px;width: 100%;"
              type="text"
              name="customer_code"
              [(ngModel)]="activeObject.sac_code"
              #sac_code="ngModel"
              (keydown)="onSACCodeKeyDown($event)"
              placeholder="unique Sales Area Code"
              required
            >
            <div *ngIf="sac_code.errors && (sac_code.dirty || sac_code.touched)">
              <span *ngIf="sac_code.errors.required" class="text-danger">Sales Area Code is required</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 pl-0 mt-0">
          <span class="dialoglabel">Reporting Team</span>
          <span class="requiredspan"> *</span>
          <div style="margin-top: 10px;">
            <ng-select [items]="teamList"
                       bindLabel="itemname"
                       bindValue="id"
                       class="custom"
                       (ngModelChange)="onTeamChangeEvent($event)"
                       [(ngModel)]="activeObject.sale_team_id">
              >
            </ng-select>
          </div>
        </div>
        <div class="col-md-6 pl-0 mt-0">
          <span class="dialoglabel">State</span>
          <span class="requiredspan"> *</span>
          <div style="margin-top: 10px;">
            <ng-select [items]="statesList"
                       bindLabel="itemName"
                       bindValue="id"
                       class="custom"
                       [(ngModel)]="activeObject.state_id">
              >
            </ng-select>
          </div>
        </div>
      </div>
    </div>
    <div class="button-row" style="margin-top: 20px;">

      <div style="position: absolute;display: inline-block; right: 0px;">
        <button
          mat-button
          style=" margin-right:15px;"
          class="btn grey-btn"
          (click)="closeDialog()"
        >
          Cancel
        </button>
        <button
          mat-button
          style=" margin-right:15px;"
          type="submit" ng-disabled="form.$invalid"
          class="btn confirm-blue-btn"
          (click)="saveUser()"
        >
          Save
        </button>
      </div>

    </div>
  </div>
</section>
