import {Component, OnInit} from '@angular/core';
import {New_order_base} from './new_order_base';
import {SerialNoRenderer} from '../../serialNo.component';
import {CellEdit2Renderer} from '../../editCell2.component';
import {DeleteComponent} from '../../deleteCell.component';
import {PriceCellRendererComonent} from '../../priceCellRenderer.comonent';
import {UomComponent} from '../../uom.component';

@Component({
  selector: 'app-dms-order',
  templateUrl: './new_create.html',
  styleUrls: ['./new_order_base.scss']
})

export class Dms_order_Component extends New_order_base implements OnInit {

  userData = {
    date: null,
    dc_id: 0,
    supplier_id: null,
    customer_id: null,
    pricelist_id: null,
    access_token: null,
    url: null,
    offset: null,
    po_data: null
  };
  resData;
  public roleFeatures;
  public context;
  public frameworkComponents;
  public browserRefresh = false;
  public subscription;
  public enteredQty = 0;
  public discount = 0;
  public extraMargin = 0.00;
  public maxMargin = 0;
  public minMargin = 0;
  public fixedCost = 0;
  public tradeDisc = 0.00;
  public schemeDiscount = 0;
  public lineType = '';
  public po;
  public invoiceOrder: any;

  ngOnInit() {

    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    // redirection from invoice and return page
    this.sharedService.dmsParams.goToInv.active = false;
    this.sharedService.dmsParams.goToInv.ref = '';
    this.sharedService.dmsParams.goToRef.active = false;
    this.sharedService.dmsParams.goToRef.ref = '';

    this.invEnable = false;
    this.userData.date = this.myDate;
    this.userData.offset = -1 * (new Date(this.userData.date)).getTimezoneOffset();
    this.userData.access_token = localStorage.getItem('resfreshToken');
    this.context = {componentParent: this};
    this.frameworkComponents = {
      serialNoRenderer: SerialNoRenderer,
      cellEdit2Renderer: CellEdit2Renderer,
      priceCellRenderer: PriceCellRendererComonent,
      cellDeleteRenderer: DeleteComponent,
      uomRenderer: UomComponent
    };
    //Initializing functions
    this.beatChange = this.beat_change;
    this.outChange = this.out_change;
    this.prodChange = this.prod_change;
    this.userChange = this.user_change;
    this.suppChange = this.supp_Change;
    this.shipaddrssChange = this.orderShipAddrsChange;
    this.sharedService.dmsParams.order_id = localStorage.getItem('dms_order_id');
    // checking new order or exist
    if (this.sharedService.dmsParams.order_id && this.sharedService.dmsParams.order_id === 'null') {
      this.sharedService.dmsParams.new_mode = true;
    }
    this.configureGrid();
    this.sharedService.dmsParams.dmsType = localStorage.getItem('dms_type');
    this.sharedService.dmsParams.type = localStorage.getItem('dms_order_type');
    if (this.sharedService.dmsParams.new_mode || !this.sharedService.dmsParams.order_id) {
      if (this.sharedService.dmsParams.dmsType === 'S') {
        this.supplierId = JSON.parse(localStorage.getItem('user')).partner_id;
        this.userId = JSON.parse(localStorage.getItem('user')).id;
        this.userName = JSON.parse(localStorage.getItem('user')).user_name;
        this.userCode = JSON.parse(localStorage.getItem('user')).emp_code;
        this.userData.supplier_id = this.supplierId;
        this.get_beats();
        this.get_users();
        this.productEntry();
      } else {
        this.customerId = JSON.parse(localStorage.getItem('user')).partner_id;
        this.userData.customer_id = this.customerId;
        this.userData.dc_id = this.customerId;
        this.get_suppliers();
        this.get_customer();
        this.productEntry();
      }
    } else {
      this.sharedService.dmsParams.flag = 'new';
      this.orderDetails();
    }
    this.title = 'Order';
    this.save = this.savePO;
    this.edit = this.editOrder;
    this.confirm = this.confirmOrder;
    this.discard = this.disCard;
    this.custShow = this.show_customers;
    this.confirmSerial = this.confirmserial;
    this.cancel = this.cancelOrder;
  }

  configureGrid() {
    this.columnDefs = [
      {
        headerName: 'PRODUCT', field: 'product_name', sortable: true, filter: true, resizable: true, lockPosition: true, width: 300,
        cellRenderer(params) {
          let displayElem = '';
          if (params.data) {
            const product_code = params.data.product_code ? params.data.product_code : '';
            displayElem = '<div><p style="padding: 0px;margin: 0px;">' + params.data.product_name + '</p><p style="font-size: 10px;padding: 0px;">' + product_code + '</p></div>';
          }
          return displayElem;
        }
      },
      {headerName: 'STOCK', field: 'stock', sortable: true, filter: true, resizable: true, lockPosition: true, width: 80},
      {
        headerName: 'SUPP STOCK', field: 'supp_stock', sortable: true, filter: true, resizable: true, lockPosition: true,
        width: 80, hide: true
      },
      {
        headerName: 'UOM', field: 'uoms', sortable: true, filter: true, resizable: true, lockPosition: true, width: 80,
        cellRenderer: 'uomRenderer'
      },
      {
        headerName: 'QTY', field: 'QTY', sortable: true, filter: true, resizable: true, lockPosition: true, width: 80,
        cellRenderer: 'cellEdit2Renderer'
      },
      {headerName: 'ORDERED', field: 'ordered_qty', sortable: true, filter: true, resizable: true, lockPosition: true, width: 80,},
      {
        headerName: 'INVOICED', field: 'invoiced_qty', sortable: true, filter: true, resizable: true,
        lockPosition: true, width: 80
      },
      {
        headerName: 'DELIVERED', field: 'qty_delivered', sortable: true, filter: true, resizable: true,
        lockPosition: true, width: 80
      },
      {headerName: 'SCHEME', field: 'scheme', sortable: true, filter: true, resizable: true, lockPosition: true, width: 80},
      {
        headerName: 'PRICE', field: 'PRICE', sortable: true, filter: true, resizable: true, lockPosition: true, width: 80,
        cellRenderer: 'cellEdit2Renderer'
      },
      {
        headerName: 'DISC%', field: 'extra_margin', sortable: true, filter: true, resizable: true, lockPosition: true,
        cellRenderer: 'cellEdit2Renderer', width: 80
      },
    ];
    if (this.showCardRate) {
      this.columnDefs.push({
        headerName: 'CARD RATE', field: 'cardRate', sortable: true, filter: true, resizable: true, lockPosition: true,
        cellRenderer: 'cellEdit2Renderer', width: 80
      });
    }
    if (this.showCardRateDiscount) {
      this.columnDefs.push({
        headerName: 'CARD RATE DISC', field: 'cardDiscount', sortable: true, filter: true, resizable: true, lockPosition: true,
        cellRenderer: 'cellEdit2Renderer', width: 80
      });
    }
    if (this.showWeight) {
      this.columnDefs.push({
        headerName: 'WEIGHT', field: 'weight', sortable: true, filter: true, resizable: true, lockPosition: true,
        cellRenderer: 'cellEdit2Renderer', width: 80
      });
    }
    this.columnDefs.push({
        headerName: 'SPCL DISC/qty', field: 'trade_disc', sortable: true, filter: true, resizable: true, lockPosition: true,
        cellRenderer: 'cellEdit2Renderer', width: 80
      },
      {headerName: 'CHARGES', field: 'fixed_cost', sortable: true, filter: true, resizable: true, lockPosition: true, width: 80},
      {
        headerName: 'SCHEME DIS', field: 'scheme_discount', sortable: true, filter: true, resizable: true, lockPosition: true, width: 80,
        valueGetter: params => {
          if (params.data) {
            return params.data.scheme_discount.toFixed(2);
          }
        }
      },
      {headerName: 'TAX', field: 'tax_name', sortable: true, filter: true, resizable: true, lockPosition: true, width: 80},
      {headerName: 'SUB TOTAL', field: 'TOTAL', sortable: true, filter: true, resizable: true, lockPosition: true, width: 80},
      {
        headerName: 'Serial NO', sortable: true, filter: true, resizable: true, lockPosition: true, hide: true,
        cellRenderer: 'serialNoRenderer', width: 80
      },
      {
        headerName: '', field: 'delete', resizable: true, lockPosition: true,
        cellRenderer: 'cellDeleteRenderer', width: 60
      },
      {headerName: 'sale_price', field: 'sale_price', hide: true},
      {headerName: 'product_id', field: 'product_id', hide: true},
      {headerName: 'uom_id', field: 'uom_id', hide: true},
      {headerName: 'product_uom', field: 'product_uom', hide: true},
      {headerName: 'taxmaster_id', field: 'taxmaster_id', hide: true},
      {headerName: 'edit_status', field: 'edit_status', hide: true},
      {headerName: 'line_type', field: 'line_type', hide: true},
      {headerName: 'id', field: 'id', hide: true},
      {headerName: 'tax_amount', field: 'tax', hide: true},
      {headerName: 'uom_type', field: 'uom_type', hide: true},
      {headerName: 'factor_inv', field: 'factor_inv', hide: true},
      {headerName: 'base_uom_inv', field: 'base_uom_inv', hide: true},
      {headerName: 'factor', field: 'factor', hide: true},
      {headerName: 'max_margin', field: 'max_margin', hide: true},
      {headerName: 'min_margin', field: 'min_margin', hide: true},
      {headerName: 'real_stock', field: 'real_stock', hide: true},
      {headerName: 'real_supp_stock', field: 'real_supp_stock', hide: true},
      {headerName: 'real_price', field: 'real_price', hide: true});
  }

  confirmserial() {

    this.sernos[this.line_id] = this.serl_arr;
  }

  enterSerialNo(data) {

    this.serl_arr = [];
    this.line_id = data.id;
    let exist_sers = this.sernos[this.line_id];
    if (exist_sers && exist_sers.length > 0) {
      this.serl_arr = this.sernos[this.line_id];
    } else {
      for (var i = 0; i < parseInt(data['QTY'], 10); i++) {
        this.serl_arr.push({
          "no": null
        });
      }
    }
    let el: HTMLElement = this.serial.nativeElement;
    el.click();
  }

  //Redirecting to Invoice and return
  gotoInv() {
    this.sharedService.setDmsParams();
    this.sharedService.dmsParams.goToInv.active = true;
    this.sharedService.dmsParams.goToInv.ref = this.orderNo;
    this.router.navigate([this.apiService.redirect_url + '/order_invoices'],
      {
        relativeTo: this.route,
        queryParams: {type: this.sharedService.dmsParams.dmsType}
      });
  }

  gotoRef() {
    this.sharedService.setDmsParams();
    this.sharedService.dmsParams.goToRef.active = true;
    this.sharedService.dmsParams.goToRef.ref = this.orderNo;
    this.router.navigate([this.apiService.redirect_url + '/order_invoices'],
      {
        relativeTo: this.route,
        queryParams: {type: this.sharedService.dmsParams.dmsType, order_type: 'return'}
      });
  }

  disCard() {
    this.router.navigate([this.apiService.redirect_url + '/orders'],
      {
        relativeTo: this.route,
        queryParams: {type: this.sharedService.dmsParams.dmsType},
        queryParamsHandling: 'merge'
      });
  }

  get_suppliers() {
    this.resData = this.apiService.getSuppliers(this.userData);
    this.resData.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res['results'].status == 200) && (res['results'].data.length > 0)) {
        this.supplierList = res['results'].data[0].suppliers;
        if (this.supplierList.length > 1) {
          this.suppEnable = true;
          this.selectedSupp = this.supplierList[0];
          this.supplierId = this.supplierList[0].id;
          this.supplierCode = this.supplierList[0].code;
          this.supplierName = this.supplierList[0].itemName;
          this.supplierTax = this.supplierList[0].tax;
        } else {
          this.suppEnable = false;
        }
      }
    });
  }

  supp_Change(event) {
    this.prodShow = false;
    this.selectedSupp = event;
    this.supplierId = event.id;
    this.supplierCode = event.code;
    this.supplierName = event.itemName;
    this.supplierTax = event.tax;
    this.addProduct();
  }

  get_customer() {
    let inthis = this;
    this.resData = this.apiService.getCustomerDetails(this.userData);
    this.resData.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res.results.status === 200)) {
        if (res.results.data.length > 0) {
          const data = res.results.data;
          if (!this.suppEnable) {
            this.supplierId = data[0].supplier_id;
            this.supplierCode = data[0].supplier_code;
            this.supplierName = data[0].supplier_name;
            this.supplierTax = data[0].supplier_tax;
          }
          this.customerName = data[0].customer;
          this.customerMail = data[0].customer_mail;
          this.customerTax = data[0].gstn;
          this.customerCode = data[0].code;
          this.customerAddress = data[0].address;
          this.supplierAddress = data[0].address;
          this.partnerInvId = this.customerId;
          this.partnerShippId = this.customerId;
          this.addrssList = JSON.parse(localStorage.getItem('cust_addresses')).filter(function(existed) {
            return ((parseInt(existed.parent_id, 10) === parseInt(inthis.customerId, 10)) && (existed.type === 'invoice'));
          });
          this.suppaddrssList = JSON.parse(localStorage.getItem('cust_addresses')).filter(function(existed) {
            return ((parseInt(existed.parent_id, 10) === parseInt(inthis.customerId, 10)) && (existed.type === 'delivery'));
          });
          this.addrssList = [{id: this.customerId, parent_id: this.customerId, itemname: this.customerAddress}].concat(this.addrssList);
          this.addrssSel2 = this.addrssList[0];
          this.suppaddrssList = [{id: this.customerId, parent_id: this.customerId, itemname: this.supplierAddress}].concat(this.suppaddrssList);
          this.addrssSel = this.suppaddrssList[0];
          this.pricelist = data[0].pricelist;
          this.pricelistId = data[0].pricelist_id;
          this.taxSource = data[0].tcs;
          this.custSupp = data[0].cust_supp;
          this.addProduct();
        } else {
          this.toastr.error('Check the Customer config');
        }
        this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
      } else {
        this.toastr.error(res.results.msg);
        this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
      }
    }, error => {
      this.toastr.error('Fetching Customer Failed');
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    });
  }

  show_customers() {
    // let el: HTMLElement = this.showCustomer.nativeElement;
    // el.click();
    this.custDisable = false;
  }

  get_users() {
    this.userList = JSON.parse(localStorage.getItem('dms_users'));
  }


  get_beats() {
    const customers = JSON.parse(localStorage.getItem('customers_list'));
    let inthis = this;
    if (customers && customers.length > 0) {
      this.rowData1 = customers;
      this.loaded1 = true;
      // this.supplierName = this.rowData1[0].supplier;
      this.customerId = this.rowData1[0].id;
      this.customerName = this.rowData1[0].name;
      this.customerTax = this.rowData1[0].gst_id;
      this.customerMail = this.rowData1[0].email;
      this.customerCode = this.rowData1[0].code;
      this.partnerInvId = this.customerId;
      this.partnerShippId = this.customerId;
      this.customerAddress = this.rowData1[0].address;
      this.supplierAddress = this.rowData1[0].address;
      this.pricelistId = this.rowData1[0].pricelist_id;
      this.pricelist = this.rowData1[0].pricelist;
      this.taxSource = this.rowData1[0].tax_source;
      this.custSupp = this.rowData1[0].cust_supp;
      if (this.rowData1[0].beat && this.rowData1[0].beat.length > 0) {
        this.beatId = this.rowData1[0].beat[0].bp_id;
        this.beatName = this.rowData1[0].beat[0].bp;
        this.userId = this.rowData1[0].beat[0].sm_id;
        this.userName = this.rowData1[0].beat[0].sm;
      } else {
        this.beatId = null;
        this.beatName = null;
        this.userId = null;
        this.userName = null;
      }
      this.selectedUser = [{id: this.userId, itemName: this.userName}];
      this.addrssList = JSON.parse(localStorage.getItem('cust_addresses')).filter(function(existed) {
        return ((parseInt(existed.parent_id, 10) === parseInt(inthis.customerId, 10)) && (existed.type === 'invoice'));
      });
      this.suppaddrssList = JSON.parse(localStorage.getItem('cust_addresses')).filter(function(existed) {
        return ((parseInt(existed.parent_id, 10) === parseInt(inthis.customerId, 10)) && (existed.type === 'delivery'));
      });
      this.addrssList = [{id: this.customerId, parent_id: this.customerId, itemname: this.customerAddress}].concat(this.addrssList);
      this.addrssSel2 = this.addrssList[0];
      this.suppaddrssList = [{id: this.customerId, parent_id: this.customerId, itemname: this.supplierAddress}].concat(this.suppaddrssList);
      this.addrssSel = this.suppaddrssList[0];
      this.addProduct();
      this.prodShow = true;
      // if (res.results.outlets) {
      //   this.outList = res.results.outlets;
      //   if (this.outList.length > 0) {
      //     this.selectedOut = [this.outList[0]];
      //     this.beatId = this.selectedOut[0].beat_id;
      //     this.beatName = this.selectedOut[0].beat_name;
      //     this.supplierName = this.selectedOut[0].supplier;
      //     this.customerId = this.selectedOut[0].id;
      //     this.customerName = this.selectedOut[0].itemName.split('/')[2];
      //     this.customerTax = this.selectedOut[0].gstn;
      //     this.customerMail = this.selectedOut[0].email;
      //     this.pricelistId = this.selectedOut[0].pricelist_id;
      //     this.pricelist = this.selectedOut[0].pricelist;
      //     this.taxSource = this.selectedOut[0].tcs;
      //     this.addProduct();
      //     this.prodShow = true;
      //   }
      //   // this.outlets = res.results.outlets;
      //   // this.outlets.forEach(outlet => {
      //   //   if (parseInt(outlet.beat_plan_id, 10) === parseInt(this.beatId, 10)) {
      //   //     this.outList = outlet.outlets;
      //   //   }
      //   // });
      // }
      // if (res.results.outlets) { this.outlets = res.results.outlets; }
    } else {
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
      this.toastr.warning('Customer List not Loaded yet.Try Again');
    }
  }

  billaddrssChange(event) {
    if (event.id) {
      this.partnerInvId = event.id;
    }
  }

  orderShipAddrsChange(event) {
    if (event.id) {
      this.partnerShippId = event.id;
    }
  }

  user_change(event) {
    if (event.length > 0 && event[0].id) {
      this.userName = event[0].itemName;
      this.userId = event[0].id;
    } else {
      this.userName = null;
      this.userId = null;
    }
  }

  beat_change(event) {
    this.outList = [];
    this.selectedOut = [];
    if (event.length > 0) {
      this.selectedBeat = event;
      this.beatId = event[0].id;
      this.beatName = event[0].itemName;
      this.supplierName = event[0].supplier;
      this.outlets.forEach(outlet => {
        if (parseInt(outlet.beat_plan_id, 10) === parseInt(this.beatId, 10)) {
          this.outList = outlet.outlets;
        }
      });
    }
  }

  out_change() {
    const rows = this.gridApi1.getSelectedRows();
    if (rows.length > 0) {
      let inthis = this;
      if (rows[0].beat && rows[0].beat.length > 0) {
        this.beatId = rows[0].beat[0].bp_id;
        this.beatName = rows[0].beat[0].bp;
        this.userId = rows[0].beat[0].sm_id;
        this.userName = rows[0].beat[0].sm;
      } else {
        this.beatId = null;
        this.beatName = null;
        this.userId = null;
        this.userName = null;
      }
      this.selectedUser = [{id: this.userId, itemName: this.userName}];
      // this.supplierName = rows[0].supplier;
      this.customerId = rows[0].id;
      this.customerName = rows[0].name;
      this.customerTax = rows[0].gst_id;
      this.customerMail = rows[0].email;
      this.customerCode = rows[0].code;
      this.customerAddress = rows[0].address;
      this.supplierAddress = rows[0].address;
      this.partnerShippId = this.customerId;
      this.partnerInvId = this.customerId;
      this.pricelistId = rows[0].pricelist_id;
      this.pricelist = rows[0].pricelist;
      this.taxSource = rows[0].tax_source;
      this.custSupp = rows[0].cust_supp;
      this.custDisable = true;
      this.addrssList = JSON.parse(localStorage.getItem('cust_addresses')).filter(function(existed) {
        return ((parseInt(existed.parent_id, 10) === parseInt(inthis.customerId, 10)) && (existed.type === 'invoice'));
      });
      this.suppaddrssList = JSON.parse(localStorage.getItem('cust_addresses')).filter(function(existed) {
        return ((parseInt(existed.parent_id, 10) === parseInt(inthis.customerId, 10)) && (existed.type === 'delivery'));
      });
      this.addrssList = [{id: this.customerId, parent_id: this.customerId, itemname: this.customerAddress}].concat(this.addrssList);
      this.addrssSel2 = this.addrssList[0];
      this.suppaddrssList = [{id: this.customerId, parent_id: this.customerId, itemname: this.customerAddress}].concat(this.suppaddrssList);
      this.addrssSel = this.suppaddrssList[0];
      this.addProduct();
    }
  }

  addProduct() {
    this.selectedProd = [];
    this.enteredQty = 0;
    this.stock = 0;
    if (!this.pricelistId) {
      this.pricelistId = 0;
    }
    this.userData.supplier_id = this.supplierId;
    this.userData.pricelist_id = this.pricelistId;
    this.userData.customer_id = this.customerId;
    this.resData = this.apiService.supplierProducts(this.userData);
    this.resData.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
        this.prodList = res.results.data[0].products;
        this.uomList = res.results.uoms;
        if (this.pricelistId === 0) {
          this.pricelistId = this.prodList[0].pricelist_id;
        }
        this.pricelist = this.prodList[0].pricelist;
        this.supplierName = this.prodList[0].supplier;
        this.supplierTax = this.prodList[0].supplier_tax;
        this.supplierCode = this.prodList[0].supplier_code;
        if (this.sharedService.dmsParams.new_mode) {
          this.prodShow = true;
        }
        if (this.sharedService.dmsParams.edit_mode) {
          this.prodShow = true;
          this.productEntry();
        }
        if (this.dmsStatus === 'confirm' || this.dmsStatus === 'sale') {
          // this.myDate = new Date(this.poDate);
          const sampleProd = [];
          this.rowData.filter(obj => {
            if (obj.line_type === 'sample' || obj.line_type === 'promo') {
              sampleProd.push(obj);
            }
            if (obj.scheme) {
              obj.scheme = null;
            }
            if (obj.scheme_discount) {
              obj.TOTAL = (parseFloat(obj.TOTAL) + parseFloat(obj.scheme_discount)).toFixed(this.apiService.decimalPolicy);
              obj.scheme_discount = 0;
            }
          });
          let tempData = this.rowData.filter(val => !sampleProd.includes(val));
          this.gridApi.setRowData(tempData);
        }
      } else {
        this.toastr.error(res.results.msg);
      }
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    });
  }

  prod_change(event) {
    this.stock = 0;
    this.supp_stock = 0;
    if (event.length > 0 && event[0].id) {
      this.real_stock = event[0].stock;
      this.stock = parseFloat((event[0].stock * event[0].factor).toFixed(2));
      if (this.sharedService.dmsParams.dmsType === 'P') {
        this.real_stock = event[0].cust_stock;
        this.real_supp_stock = event[0].stock;
        this.stock = parseFloat((event[0].cust_stock * event[0].factor).toFixed(2));
        this.supp_stock = parseFloat((event[0].stock * event[0].factor).toFixed(2));
      }
      this.productName = event[0].itemName;
      this.productId = event[0].id;
      this.categoryId = event[0].category_id;
      this.brandId = event[0].brand_id;
      this.tax = event[0].tax;
      this.taxName = event[0].tax_name;
      this.margin = event[0].margin;
      this.taxmasterId = event[0].taxmaster_id;
      this.extraMargin = event[0].extra_margin;
      this.maxMargin = event[0].max_margin;
      this.minMargin = event[0].min_margin;
      this.fixedCost = event[0].fixed_cost;
      this.uomId = event[0].uom_id;
      this.uomName = event[0].uom;
      this.factorInv = event[0].factor_inv;
      this.uomType = event[0].uom_type;
      this.factor = event[0].factor;
      this.tradeDisc = 0;
      this.enteredQty = 0;
      this.lineType = 'sale';
      this.uoms = [];
      const findUom = this.uomList.filter(existed => this.productId === existed.id);
      if (findUom.length > 0) {
        this.uoms = findUom[0].uoms;
      }
      if (event[0].tax_include) {
        this.unitPrice = event[0].unit_price;
        this.salePrice = (this.unitPrice * 100) / (100 + parseFloat(this.tax));
      } else {
        this.salePrice = event[0].unit_price;
        this.unitPrice = this.salePrice * ((100 + parseFloat(this.tax)) / 100);
      }
      this.unitPrice = this.unitPrice * (1 - (this.margin / 100));
      this.salePrice = this.salePrice * (1 - (this.margin / 100));

      //Santosh: change focus on qty field after product selection
      if (this.qtyElement.nativeElement.value === "0") {
        this.qtyElement.nativeElement.value = "";
      }
      this.qtyElement.nativeElement.focus();
    }
  }

  saveProduct() {
    const total = parseFloat(String(this.unitPrice)) * parseFloat(this.enteredQty.toString());
    this.rowData.push({
      product_name: this.productName,
      stock: this.stock,
      supp_stock: this.supp_stock,
      product_uom: this.uomName,
      uom_id: this.uomId,
      uoms: this.uoms,
      QTY: this.enteredQty,
      PRICE: this.salePrice.toFixed(this.apiService.decimalPolicy),
      extra_margin: this.extraMargin,
      trade_disc: this.tradeDisc,
      fixed_cost: this.fixedCost,
      scheme_discount: this.schemeDiscount,
      tax: this.tax,
      tax_name: this.taxName,
      TOTAL: total.toFixed(this.apiService.decimalPolicy),
      product_id: this.productId,
      category_id: this.categoryId,
      brand_id: this.brandId,
      sale_price: this.salePrice,
      taxmaster_id: this.taxmasterId,
      line_type: this.lineType,
      uom_type: this.uomType,
      factor_inv: this.factorInv,
      base_uom_inv: this.factorInv,
      factor: this.factor,
      max_margin: this.maxMargin,
      min_margin: this.minMargin,
      real_stock: this.real_stock,
      real_supp_stock: this.real_supp_stock,
      real_price: this.salePrice.toFixed(this.apiService.decimalPolicy),
    });
    if (this.enteredQty > 0) {
      this.savePO('edit');
    } else {
      this.gridApi.setRowData(this.rowData);
    }
    this.enteredQty = 0;
    this.selectedProd = [];
    this.stock = 0;
    this.supp_stock = 0;
    this.productSelector.focus();
  }

  productEntry() {
    this.loaded = false;
    this.preloader = true;
    this.rowData = [];
    if (this.sharedService.dmsParams.new_mode) {
      this.columnDefs.filter(obj => {
        if (obj.headerName === 'Serial NO' || obj.field === 'invoiced_qty' || obj.field === 'ordered_qty' ||
          obj.field === 'qty_delivered') {
          obj.hide = true;
        }
        if (this.supplierStock && this.sharedService.dmsParams.dmsType === 'P') {
          this.columnDefs.filter(obj => {
            if (obj.field === 'supp_stock') {
              obj.hide = false;
            }
          });
        }
      });
    } else {
      // if (this.invStatus === 'invoiced') {
      //   this.returnEnable = true;
      // }
      let edit_status = false;
      let qty = 0;
      this.lineData.forEach(line => {
        edit_status = false;
        if (parseFloat(line.qty) > 0) {
          if (line.type === 'sample' || line.type === 'promo') {
            edit_status = true;
            line.product_name = line.product_name + '(promo)';
          }
          this.uoms = [];
          const findUom = this.uomList.filter(existed => line.product_id === existed.id);
          if (findUom.length > 0) {
            this.uoms = findUom[0].uoms;
          }
          qty = line.qty;
          if ((this.dmsStatus === 'confirm' || this.dmsStatus === 'sale') &&
            this.sharedService.dmsParams.edit_mode &&
            (parseFloat(line.qty) > parseFloat(line.qty_invoiced))) {
            qty = parseFloat(line.qty) - parseFloat(line.qty_invoiced);
          }
          if (parseFloat(line.qty_invoiced) >= parseFloat(line.qty)) {
            edit_status = true;
          }
          if (this.sharedService.dmsParams.dmsType === 'P') {
            line.supp_stock = line.stock;
            line.stock = line.cust_stock;
          }
          let cardRate = '0.00';
          let cardDiscount = '0.00';
          let weight = '0.00';
          if (line.hasOwnProperty('config_json') && line.config_json !== null && line.config_json !== undefined) {
            if (line.config_json.hasOwnProperty('cardRate') && line.config_json.cardRate !== null && line.config_json.cardRate !== undefined) {
              this.showCardRate = true;
              cardRate = parseFloat(line.config_json.cardRate).toFixed(2);
            }
            if (line.config_json.hasOwnProperty('updatedCardRate') && line.config_json.updatedCardRate !== null && line.config_json.updatedCardRate !== undefined) {
              this.showCardRateDiscount = true;
              cardDiscount = parseFloat(line.config_json.updatedCardRate).toFixed(2);
            }
            if (line.config_json.hasOwnProperty('totalWeight') && line.config_json.totalWeight !== null && line.config_json.totalWeight !== undefined) {
              this.showWeight = true;
              weight = parseFloat(line.config_json.totalWeight).toFixed(2);
            }
          }
          this.rowData.push({
            product_name: line.product_name,
            product_code: line.product_code,
            stock: (parseFloat(line.stock) * parseFloat(line.factor)).toFixed(2),
            supp_stock: (parseFloat(line.supp_stock) * parseFloat(line.factor)).toFixed(2),
            product_uom: line.product_uom,
            uoms: this.uoms,
            ordered_qty: line.qty,
            QTY: qty,
            invoiced_qty: line.qty_invoiced,
            qty_delivered: line.delivered_qty,
            PRICE: parseFloat(line.unit_price).toFixed(this.apiService.decimalPolicy),
            extra_margin: line.extra_margin,
            trade_disc: line.trade_disc,
            fixed_cost: line.fixed_cost,
            scheme: line.scheme,
            scheme_discount: line.scheme_discount,
            tax_name: line.tax,
            tax: line.tax_perc,
            TOTAL: parseFloat(line.amount_subtotal).toFixed(this.apiService.decimalPolicy),
            product_id: line.product_id,
            category_id: line.category_id,
            brand_id: line.brand_id,
            uom_id: line.uom_id,
            taxmaster_id: line.taxmaster_id,
            line_type: line.line_type,
            id: line.id,
            uom_type: line.uom_type,
            factor_inv: line.factor_inv,
            base_uom_inv: line.factor_inv,
            factor: line.factor,
            edit_status,
            min_margin: line.min_margin,
            max_margin: line.max_margin,
            real_stock: line.stock,
            real_supp_stock: line.supp_stock,
            real_price: line.real_price,
            cardRate,
            cardDiscount,
            weight
          });
        }
      });
      this.configureGrid();
      if ((this.dmsStatus === 'confirm' || this.dmsStatus === 'sale') && this.sharedService.dmsParams.edit_mode) {
        this.returnEnable = false;
        this.columnDefs.filter(obj => {
          if (obj.field === 'ordered_qty' || obj.field === 'QTY' || obj.field === 'invoiced_qty' ||
            obj.field === 'qty_delivered') {
            obj.hide = false;
          }
        });
      } else if ((this.dmsStatus === 'confirm' || this.dmsStatus === 'sale')) {
        this.columnDefs.filter(obj => {
          if (obj.field === 'QTY' || obj.field === 'delete') {
            obj.hide = true;
          } else if (obj.field === 'invoiced_qty' || obj.field === 'ordered_qty' || obj.field === 'qty_delivered') {
            obj.hide = false;
          }
        });
      } else {
        this.returnEnable = false;
        this.columnDefs.filter(obj => {
          if (obj.field === 'invoiced_qty' || obj.headerName === 'Serial NO' || obj.headerName === 'ORDERED QTY'
            || obj.field === 'delete' || obj.field === 'qty_delivered') {
            obj.hide = true;
          }
        });
      }
      if (this.sharedService.dmsParams.edit_mode) {
        this.columnDefs.filter(obj => {
          if (obj.field === 'delete') {
            obj.hide = false;
          }
        });
      }
      if (this.supplierStock && this.sharedService.dmsParams.dmsType === 'P') {
        this.columnDefs.filter(obj => {
          if (obj.field === 'supp_stock') {
            obj.hide = false;
          }
        });
      }
      if (this.gridApi) {
        this.gridApi.setRowData(this.rowData);
        this.gridApi.setColumnDefs(this.columnDefs);
        this.gridApi.sizeColumnsToFit();
        this.gridOptions.api.setFocusedCell(0, 'QTY', null);
        this.preloader = false;
      }
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    }
    this.loaded = true;
  }

  // updateOrd() {
  //   this.createInvoice(this.invoiceOrder);
  //   this.updatedInvoice = false;
  //   this.prodShow = false;
  // }

  savePO(key) {

    let splDate = this.poDate.split('/');
    if (splDate.length > 1) {
      this.poDate = splDate[2] + '-' + splDate[1] + '-' + splDate[0];
    }
    this.sharedService.fetch_periodID(this.poDate);
    let period_id = localStorage.getItem('period_id');
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    const lineData = [];
    let custSupp;
    if (this.orderdetails) { custSupp = this.orderdetails.customer_supplier; }
    this.orderdetails = {
      amount_total: 0,
      amount_tax: 0,
      amount_untaxed: 0,
      total: 0,
      tcs: 0,
      line_discount: 0,
      spcl_discount: 0,
      charges: 0,
      customer_supplier: custSupp,
    };
    //removing sample or promo from input data since we need to recalculate scheme always
    const sampleProd = [];
    this.rowData.filter(obj => {
      if (obj.line_type === 'sample' || obj.line_type === 'promo') {
        sampleProd.push(obj);
      }
    });
    this.rowData = this.rowData.filter(val => !sampleProd.includes(val));
    const prodList = this.prodList;
    this.rowData.forEach(line => {
      if (!line.edit_status && parseFloat(line.QTY) > 0) {
        if (!line.ordered_qty) { line.ordered_qty = 0; }
        if (!line.invoiced_qty) { line.invoiced_qty = 0; }

        if ((parseFloat(line.min_margin) === 0 && parseFloat(line.max_margin) === 0) ||
          (parseFloat(line.extra_margin) >= parseFloat(line.min_margin) &&
            parseFloat(line.extra_margin) <= parseFloat(line.max_margin))) {

          // if (parseFloat(line.ordered_qty) !== 0 && parseFloat(line.invoiced_qty) !== 0) {
          if (!this.priceEdited) {
            line.PRICE = (parseFloat(line.real_price) * parseFloat(line.factor_inv))/(parseFloat(line.base_uom_inv));
          }
          let priceSubtotal = parseFloat(line.QTY) * parseFloat(line.PRICE);
          priceSubtotal = priceSubtotal - Math.abs(line.fixed_cost);
          priceSubtotal = priceSubtotal * (1 - (parseFloat(line.extra_margin) / 100));
          priceSubtotal = priceSubtotal - (parseFloat(line.trade_disc) * parseFloat(line.QTY));
          const prodUoms = this.uomList.filter(existed => line.product_id === existed.id);
          line.TOTAL = priceSubtotal;
          const productLine = {
            id: line.id,
            product_name: line.product_name,
            stock: line.stock,
            product_id: parseInt(line.product_id, 10),
            category_id: parseInt(line.category_id, 10),
            brand_id: parseInt(line.brand_id, 10),
            qty: line.QTY,
            scheme_qty: parseFloat(line.QTY),
            prod_uoms: prodUoms[0].uoms,
            ordered_qty: line.ordered_qty,
            invoiced_qty: line.invoiced_qty,
            qty_delivered: 0,
            price_subtotal: priceSubtotal,
            price_unit: line.PRICE,
            new: false,
            product_uom_name: line.product_uom,
            product_uom: line.uom_id,
            foc: false,
            foc_product_id: null,
            scheme_id: null,
            scheme_discount: 0,
            tax: line.tax,
            taxmaster_id: line.taxmaster_id,
            fixed_cost: line.fixed_cost,
            trade_disc: line.trade_disc,
            extra_margin: line.extra_margin,
            schemeCode: undefined,
            schemeUsed: undefined,
            type: 'normal',
            edit_status: line.edit_status,
            real_price: line.real_price,
          };
          line.stock = (parseFloat(line.real_stock) * parseFloat(line.factor)).toFixed(2);
          line.supp_stock = (parseFloat(line.real_supp_stock) * parseFloat(line.factor)).toFixed(2);
          line.scheme_id = null;
          line.scheme = '';
          line.scheme_discount = 0;
          lineData.push(productLine);
          // } else {
          //   this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
          //   this.toastr.error('Qty should be more than 0 for the product - ' + line.product_name);
          //   this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
          // }
        } else {
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
          this.toastr.warning('Entered Discount is Out of range' + line.product_name);
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
        }
      }
    });
    if (lineData.length > 0) {
      const order = {
        order_id: this.sharedService.dmsParams.order_id,
        partner_id: this.customerId,
        partner_shipping_id: this.partnerShippId,
        partner_invoice_id: this.partnerInvId,
        user_id: this.userId,
        distributor_id: this.supplierId,
        dist_code: this.supplierCode,
        pricelist_id: this.pricelistId,
        beat_plan_id: this.beatId,
        po: this.po_number,
        date_order:  this.datePipe.transform(new Date().getUTCFullYear() + '/' + ( new Date().getUTCMonth() + 1) + '/' + new Date().getUTCDate()  + ' ' + new Date().getUTCHours() + ':' + new Date().getUTCMinutes() + ':' + new Date().getUTCSeconds(), 'yyyy-MM-dd HH:mm:ss'),
        name: 'W_' +  Date.now() +  '_' + this.customerId,
        line_data: lineData,
        type: 'S',
        state: 'draft',
        discount: this.orderDiscount,
        scheme_type: 'S',
        comments: this.comments,
        period_id
      };
      // defining secondary or primary
      if (this.custSupp) { order.scheme_type = 'P'; }
      if (this.custSupp) { order.type = 'P'; }
      const orderData = {
        access_token: this.userData.access_token,
        url: this.userData.url,
        orders: [order],
        distributor_id: this.supplierId,
        upload: false
      };

      const rowData = this.rowData;
      let inthis = this;
      // scheme fetching api
      this.resData = this.apiService.generateInvoice(orderData);

      this.resData.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.statusCode === 200)) {
          const freeOrders = res.results.orders;
          freeOrders.forEach(resOrder => {
            resOrder.lines.forEach(function(schemes) {
              schemes.lines.forEach(schemeLine => {
                const product_line = lineData.filter(obj => {
                  return (obj.product_id === schemeLine.product_id);
                });
                const row_line = rowData.filter(obj => {
                  return (obj.product_id === schemeLine.product_id);
                });
                product_line[0].schemeCode = schemeLine.scheme_code;
                product_line[0].scheme_id = schemeLine.scheme_id;
                row_line[0].scheme = schemeLine.scheme_name;
                row_line[0].scheme_id = schemeLine.scheme_id;
                if (parseFloat(schemeLine.scheme_discount) > 0) {
                  product_line[0].scheme_discount = schemeLine.scheme_discount;
                  product_line[0].price_subtotal = parseFloat(product_line[0].price_subtotal) - parseFloat(schemeLine.scheme_discount);
                  row_line[0].scheme_discount = schemeLine.scheme_discount;
                  row_line[0].TOTAL = product_line[0].price_subtotal;
                }
              });
              let stock = 0;
              let supp_stock = 0;
              schemes.free.forEach(freeLine => {
                const prodData = prodList.filter(obj => {
                  return (obj.id === freeLine.product_id);
                });
                // const productline = lineData.filter(obj => {
                //   return (obj.product_id === freeLine.product_id);
                // });
                let uomData = inthis.uomList.filter(existed => freeLine.product_id === existed.id);
                uomData =  uomData[0].uoms.filter(obj => {
                  return (freeLine.product_uom === obj.id);
                });
                // const uomData = productline[0].prod_uoms.filter(obj => {
                //   return (freeLine.product_uom === obj.id);
                // });
                const prodFact = uomData[0].factor;
                stock = 0;
                if (inthis.sharedService.dmsParams.dmsType === 'P') {
                  stock = parseFloat((prodData[0].cust_stock * prodFact).toFixed(2));
                  supp_stock = parseFloat((prodData[0].stock * prodFact).toFixed(2));
                } else {
                  stock = parseFloat((prodData[0].stock * prodFact).toFixed(2));
                }
                lineData.push({
                  id: null,
                  product_name: freeLine.product_name,
                  stock,
                  supp_stock,
                  product_id: parseInt(freeLine.product_id, 10),
                  qty: freeLine.qty,
                  price_subtotal: 0,
                  price_tax: 0,
                  price_total: 0,
                  tcs: 0,
                  price_unit: freeLine.price_unit,
                  new: false,
                  product_uom: freeLine.product_uom,
                  foc: false,
                  foc_product_id: null,
                  scheme_id: freeLine.scheme_id,
                  scheme_discount: 0,
                  taxmaster_id: prodData[0].taxmaster_id,
                  fixed_cost: 0,
                  trade_disc: 0,
                  extra_margin: 0,
                  schemeCode: undefined,
                  schemeUsed: undefined,
                  type: 'promo',
                  edit_status: false
                });
                rowData.push({
                  product_name: prodData[0].itemName + '(promo)',
                  stock,
                  supp_stock,
                  product_id: parseInt(freeLine.product_id, 10),
                  QTY: freeLine.qty,
                  price_subtotal: 0,
                  PRICE: 0,
                  TOTAL: 0,
                  new: false,
                  uom_id: freeLine.product_uom,
                  product_uom: freeLine.product_uom_name,
                  scheme_id: freeLine.scheme_id,
                  scheme_discount: freeLine.scheme_discount,
                  taxmaster_id: prodData[0].taxmaster_id,
                  tax_name: prodData[0].tax_name,
                  fixed_cost: 0,
                  trade_disc: 0,
                  extra_margin: 0,
                  schemeCode: freeLine.schemeCode,
                  line_type: 'promo',
                  ordered_qty: freeLine.qty,
                  invoiced_qty: 0,
                  qty_delivered: 0,
                  id: null,
                  edit_status: true
                });
              });
            });
          });
          order.line_data = lineData;
          orderData.orders = [order];
          // checking edit or save directly
          if (key === 'edit') {
            let updatedLine;
            rowData.forEach(line => {
              if (line.line_type !== 'sample' && line.line_type !== 'promo') {
                if (!this.priceEdited) {
                  line.PRICE = (parseFloat(line.real_price) * parseFloat(line.factor_inv)) / (parseFloat(line.base_uom_inv));
                }
                let priceSubtotal = parseFloat(line.QTY) * parseFloat(line.PRICE);
                const linediscount = (priceSubtotal * parseFloat(line.extra_margin)) / 100;
                priceSubtotal = priceSubtotal - Math.abs(line.fixed_cost);
                priceSubtotal = priceSubtotal * (1 - (parseFloat(line.extra_margin) / 100));
                priceSubtotal = priceSubtotal - (parseFloat(line.trade_disc) * parseFloat(line.QTY));
                priceSubtotal = priceSubtotal - parseFloat(line.scheme_discount);
                line.PRICE = parseFloat(line.PRICE).toFixed(this.apiService.decimalPolicy);
                line.TOTAL = priceSubtotal.toFixed(this.apiService.decimalPolicy);
                const priceTax = (priceSubtotal * parseFloat(line.tax)) / 100;
                const priceTotal = priceSubtotal + priceTax;
                const tcs = priceTotal * (parseFloat(this.taxSource) / 100);
                const total = priceTotal + tcs;
                const spcldiscount = (parseFloat(line.QTY) * parseFloat(line.trade_disc));
                this.orderdetails.amount_tax += priceTax;
                this.orderdetails.amount_total += priceTotal;
                this.orderdetails.amount_untaxed += priceSubtotal;
                this.orderdetails.tcs += tcs;
                this.orderdetails.total += total;
                this.orderdetails.line_discount += linediscount;
                this.orderdetails.spcl_discount += spcldiscount;
                this.orderdetails.charges += Math.abs(line.fixed_cost);
              }
            });
            this.orderdetails.amount_tax = this.orderdetails.amount_tax.toFixed(this.apiService.decimalPolicy);
            this.orderdetails.amount_total = this.orderdetails.amount_total.toFixed(this.apiService.decimalPolicy);
            this.orderdetails.amount_untaxed = this.orderdetails.amount_untaxed.toFixed(this.apiService.decimalPolicy);
            this.orderdetails.tcs = this.orderdetails.tcs.toFixed(this.apiService.decimalPolicy);
            this.orderdetails.total = (this.orderdetails.total - this.orderDiscount).toFixed(this.apiService.decimalPolicy);
            this.orderdetails.line_discount = this.orderdetails.line_discount.toFixed(this.apiService.decimalPolicy);
            this.orderdetails.spcl_discount = this.orderdetails.spcl_discount.toFixed(this.apiService.decimalPolicy);
            this.orderdetails.charges = this.orderdetails.charges.toFixed(this.apiService.decimalPolicy);
            this.rowData = rowData;
            this.gridApi.setRowData(this.rowData);
            let inthis = this;
            // checking sample prod is there in rowdata
            sampleProd.forEach(function(line) {
              updatedLine = rowData.filter(function (existed) {
                return ((parseInt(line.product_id, 10) === parseInt(existed.product_id, 10)) &&
                  (line.line_type === existed.line_type));
              });
              if (updatedLine.length === 0) {
                rowData.push({
                  product_name: line.product_name,
                  stock: (parseFloat(line.stock) * parseFloat(line.factor)).toFixed(0),
                  supp_stock: (parseFloat(line.supp_stock) * parseFloat(line.factor)).toFixed(0),
                  product_uom: line.product_uom,
                  uoms: line.uoms,
                  ordered_qty: line.qty,
                  QTY: line.qty,
                  invoiced_qty: line.qty_invoiced,
                  qty_delivered: line.delivered_qty,
                  PRICE: parseFloat(line.unit_price).toFixed(inthis.apiService.decimalPolicy),
                  extra_margin: line.extra_margin,
                  trade_disc: line.trade_disc,
                  fixed_cost: line.fixed_cost,
                  scheme: line.scheme,
                  scheme_discount: line.scheme_discount,
                  tax_name: line.tax,
                  tax: line.tax_perc,
                  TOTAL: parseFloat(line.amount_subtotal).toFixed(inthis.apiService.decimalPolicy),
                  product_id: line.product_id,
                  uom_id: line.uom_id,
                  taxmaster_id: line.taxmaster_id,
                  line_type: 'promo',
                  id: line.id,
                  factor_inv: line.factor_inv,
                  base_uom_inv: line.factor_inv,
                  factor: line.factor,
                  edit_status: true,
                  min_margin: line.min_margin,
                  max_margin: line.max_margin,
                  real_stock: line.stock,
                  real_supp_stock: line.supp_stock
                });
              }
            });
            this.rowData = rowData;
            this.gridApi.sizeColumnsToFit();
            this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
          } else if (this.sharedService.dmsParams.new_mode || this.dmsStatus === 'draft') {
            if (this.dmsStatus === 'draft') {
              // checking deleted products in input data
              this.removedRows.forEach(function (line) {
                order.line_data.push({
                  id: line.id,
                  product_name: line.product_name,
                  stock: line.stock,
                  product_id: parseInt(line.product_id, 10),
                  qty: 0,
                  ordered_qty: 0,
                  invoiced_qty: 0,
                  qty_delivered: 0,
                  price_subtotal: 0,
                  price_unit: line.PRICE,
                  new: false,
                  product_uom_name: line.product_uom,
                  product_uom: line.uom_id,
                  foc: false,
                  foc_product_id: null,
                  scheme_id: null,
                  scheme_discount: 0,
                  tax: line.tax,
                  taxmaster_id: line.taxmaster_id,
                  fixed_cost: line.fixed_cost,
                  trade_disc: line.trade_disc,
                  extra_margin: line.extra_margin,
                  schemeCode: undefined,
                  schemeUsed: undefined,
                  type: 'normal',
                  edit_status: line.edit_status
                });
              });
              let updatedLine;
              sampleProd.forEach(function(line) {
                updatedLine = order.line_data.filter(function (existed) {
                  return ((parseInt(line.product_id, 10) === parseInt(existed.product_id, 10)) &&
                    (line.line_type === existed.type));
                });
                if (updatedLine.length === 0) {
                  order.line_data.push({
                    id: line.id,
                    product_name: line.product_name,
                    stock: line.stock,
                    product_id: parseInt(line.product_id, 10),
                    qty: 0,
                    ordered_qty: 0,
                    invoiced_qty: 0,
                    qty_delivered: 0,
                    price_subtotal: 0,
                    price_unit: line.PRICE,
                    new: false,
                    product_uom_name: line.product_uom,
                    product_uom: line.uom_id,
                    foc: false,
                    foc_product_id: null,
                    scheme_id: null,
                    scheme_discount: 0,
                    tax: line.tax,
                    taxmaster_id: line.taxmaster_id,
                    fixed_cost: line.fixed_cost,
                    trade_disc: line.trade_disc,
                    extra_margin: line.extra_margin,
                    schemeCode: undefined,
                    schemeUsed: undefined,
                    type: 'promo',
                    edit_status: line.edit_status
                  });
                }
              });
            }
            this.priceEdited = false;
            this.resData = this.apiService.saveDmsOrder(orderData);
            this.removedRows = [];
            this.resData.subscribe(res1 => {
              console.log(res1);
              if (res1.hasOwnProperty('results') && (res1.results.statusCode === 200)) {
                this.toastr.success('Created Successfully');
                this.suppEnable = false;
                this.sharedService.setDmsParams();
                this.sharedService.dmsParams.order_id = res1.results.id;
                this.orderDetails();
              } else {
                this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
                this.toastr.error(res.results.msg);
              }
            }, error => {
              this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
              console.log(error);
              if (error.error && error.error.error === undefined) {
                this.toastr.error('Creation failed');
              } else {
                this.toastr.error('Creation failed: ' + error.error.error.message);
              }
            });
          } else if ((this.dmsStatus === 'confirm' || this.dmsStatus === 'sale')) {
            // this.rowData = rowData;
            // this.gridApi.setRowData(this.rowData);
            // this.gridApi.sizeColumnsToFit();
            // this.updatedInvoice = true;
            this.priceEdited = false;
            this.invoiceOrder = order;
            this.createInvoice(this.invoiceOrder);
          }
        } else {
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
          this.toastr.error(res.results.msg);
        }
      }, error => {
        this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
        console.log(error);
        if (error.error && error.error.error === undefined) {
          this.toastr.error('Scheme Fetch failed');
        } else {
          this.toastr.error('Scheme Fetch failed: ' + error.error.error.message);
        }
      });
    } else {
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
      this.toastr.warning('No Valid entry to create ');
      this.prodShow = true;
    }
  }

  savePOnew(key) {

    let splDate = this.poDate.split('/');
    if (splDate.length > 1) {
      this.poDate = splDate[2] + '-' + splDate[1] + '-' + splDate[0];
    }
    this.sharedService.fetch_periodID(this.poDate);
    let period_id = localStorage.getItem('period_id');
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    const lineData = [];
    let custSupp;
    if (this.orderdetails) {
      custSupp = this.orderdetails.customer_supplier;
    }
    this.orderdetails = {
      amount_total: 0,
      amount_tax: 0,
      amount_untaxed: 0,
      total: 0,
      tcs: 0,
      line_discount: 0,
      spcl_discount: 0,
      charges: 0,
      customer_supplier: custSupp,
    };
    //removing sample or promo from input data since we need to recalculate scheme always
    const sampleProd = [];
    this.rowData.filter(obj => {
      if (obj.line_type === 'sample' || obj.line_type === 'promo') {
        sampleProd.push(obj);
      }
    });
    this.rowData = this.rowData.filter(val => !sampleProd.includes(val));
    const prodList = this.prodList;
    this.rowData.forEach(line => {
      if (!line.edit_status && parseFloat(line.QTY) > 0) {
        if (!line.ordered_qty) {
          line.ordered_qty = 0;
        }
        if (!line.invoiced_qty) {
          line.invoiced_qty = 0;
        }

        if ((parseFloat(line.min_margin) === 0 && parseFloat(line.max_margin) === 0) ||
          (parseFloat(line.extra_margin) >= parseFloat(line.min_margin) &&
            parseFloat(line.extra_margin) <= parseFloat(line.max_margin))) {

          // if (parseFloat(line.ordered_qty) !== 0 && parseFloat(line.invoiced_qty) !== 0) {
          if (!this.priceEdited) {
            line.PRICE = (parseFloat(line.real_price) * parseFloat(line.factor_inv)) / (parseFloat(line.base_uom_inv));
          }
          let priceSubtotal = parseFloat(line.QTY) * parseFloat(line.PRICE);
          priceSubtotal = priceSubtotal - Math.abs(line.fixed_cost);
          priceSubtotal = priceSubtotal * (1 - (parseFloat(line.extra_margin) / 100));
          priceSubtotal = priceSubtotal - (parseFloat(line.trade_disc) * parseFloat(line.QTY));
          const prodUoms = this.uomList.filter(existed => line.product_id === existed.id);
          line.TOTAL = priceSubtotal;
          const productLine = {
            id: line.id,
            product_name: line.product_name,
            stock: line.stock,
            product_id: parseInt(line.product_id, 10),
            category_id: parseInt(line.category_id, 10),
            brand_id: parseInt(line.brand_id, 10),
            qty: parseInt(line.QTY, 10),
            scheme_qty: parseFloat(line.QTY),
            prod_uoms: prodUoms[0].uoms,
            ordered_qty: line.ordered_qty,
            invoiced_qty: line.invoiced_qty,
            qty_delivered: 0,
            price_subtotal: priceSubtotal,
            price_unit: line.PRICE,
            new: false,
            product_uom_name: line.product_uom,
            product_uom: line.uom_id,
            foc: false,
            foc_product_id: null,
            scheme_id: null,
            scheme_discount: 0,
            tax: line.tax,
            taxmaster_id: line.taxmaster_id,
            fixed_cost: line.fixed_cost,
            trade_disc: line.trade_disc,
            extra_margin: line.extra_margin,
            schemeCode: undefined,
            schemeUsed: undefined,
            type: 'normal',
            edit_status: line.edit_status,
            real_price: line.real_price,
          };
          line.stock = (parseFloat(line.real_stock) * parseFloat(line.factor)).toFixed(2);
          line.supp_stock = (parseFloat(line.real_supp_stock) * parseFloat(line.factor)).toFixed(2);
          line.scheme_id = null;
          line.scheme = '';
          line.scheme_discount = 0;
          lineData.push(productLine);
          // } else {
          //   this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
          //   this.toastr.error('Qty should be more than 0 for the product - ' + line.product_name);
          //   this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
          // }
        } else {
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
          this.toastr.warning('Entered Discount is Out of range' + line.product_name);
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
        }
      }
    });
    if (lineData.length > 0) {
      const order = {
        order_id: this.sharedService.dmsParams.order_id,
        partner_id: this.customerId,
        partner_shipping_id: this.partnerShippId,
        partner_invoice_id: this.partnerInvId,
        user_id: this.userId,
        distributor_id: this.supplierId,
        dist_code: this.supplierCode,
        pricelist_id: this.pricelistId,
        beat_plan_id: this.beatId,
        po: this.po_number,
        date_order: this.datePipe.transform(new Date().getUTCFullYear() + '/' + (new Date().getUTCMonth() + 1) + '/' + new Date().getUTCDate() + ' ' + new Date().getUTCHours() + ':' + new Date().getUTCMinutes() + ':' + new Date().getUTCSeconds(), 'yyyy-MM-dd HH:mm:ss'),
        name: 'W_' + Date.now() + '_' + this.customerId,
        line_data: lineData,
        type: 'S',
        state: 'draft',
        discount: this.orderDiscount,
        scheme_type: 'S',
        comments: this.comments,
        period_id
      };
      // defining secondary or primary
      if (this.custSupp) {
        order.scheme_type = 'P';
      }
      if (this.custSupp) {
        order.type = 'P';
      }
      const orderData = {
        access_token: this.userData.access_token,
        url: this.userData.url,
        orders: [order],
        distributor_id: this.supplierId,
        upload: false
      };


      const rowData = this.rowData;
      const inthis = this;
      // scheme fetching api
      this.resData = this.apiService.generateInvoice(orderData);

      this.resData.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.statusCode === 200)) {
          const free_items = res.results.orders;
          const bxgx_items = [];
          free_items.forEach(scheme_lines => {
            const line_data = [];
            order.line_data.forEach(each_line => {
              scheme_lines.lines.forEach(function(schemes) {
                schemes.free.forEach(free_lines => {
                  if (!free_lines.new) {
                    free_lines.new = true;
                    if (free_lines.scheme_type === 'bxgx') {
                      bxgx_items.push(free_lines);
                    } else {
                      lineData.push(free_lines);
                    }
                    // line_data.push(free_lines);
                  }
                });
                schemes.lines.forEach(line => {
                  if (each_line.product_id === line.product_id) {
                    const product_line = lineData.filter(obj => {
                      return (obj.product_id === line.product_id);
                    });
                    const row_line = rowData.filter(obj => {
                      return (obj.product_id === line.product_id);
                    });
                    product_line[0].schemeCode = line.scheme_code;
                    product_line[0].scheme_id = line.scheme_id;
                    row_line[0].scheme = line.scheme_name;
                    row_line[0].scheme_id = line.scheme_id;
                    /*if (parseFloat(schemeLine.scheme_discount) > 0) {
                      product_line[0].scheme_discount = schemeLine.scheme_discount;
                      product_line[0].price_subtotal = parseFloat(product_line[0].price_subtotal) - parseFloat(schemeLine.scheme_discount);
                      row_line[0].scheme_discount = schemeLine.scheme_discount;
                      row_line[0].TOTAL = product_line[0].price_subtotal;
                    }*/
                    each_line.price_unit = line.price_unit;
                    const temp = parseFloat(line.price_subtotal);
                    if (each_line.price_subtotal !== temp) {
                      each_line.price_subtotal = each_line.price_subtotal - parseFloat(line.scheme_discount);
                      each_line.price_subtotal = each_line.price_subtotal / (1 + (parseFloat(line.tax) / 100));
                    } else {
                      each_line.price_subtotal = each_line.price_subtotal - parseFloat(line.scheme_discount);
                    }
                    let price_tax = 0;
                    price_tax = (each_line.price_subtotal * line.tax) / 100;
                    each_line.price_tax = price_tax;
                    each_line.price_total = each_line.price_subtotal + price_tax;
                    each_line.tcs = each_line.price_total * (parseFloat(order['tax_source']) / 100);
                    each_line.scheme_id = line.scheme_id;
                    each_line.scheme_discount = line.scheme_discount;
                    if (bxgx_items && bxgx_items.length > 0) {
                      const bxgxdata = bxgx_items.find(x => x.product_id === each_line.product_id);
                      if (bxgxdata) {
                        const bxgx_scheme = bxgxdata['qty'] * parseFloat(each_line.sale_price);
                        each_line.scheme_discount = each_line.scheme_discount + bxgx_scheme;
                        each_line.qty += bxgxdata['qty'];
                      }
                    }


                    const row = rowData.find(obj => {
                      return (obj.product_id === line.product_id);
                    });

                    if (row) {
                      row['PRICE'] = line.price_unit;
                      row['QTY'] = each_line.qty;
                    }
                  }
                });
                let stock = 0;
                let supp_stock = 0;
                schemes.free.forEach(freeLine => {
                  const prodData = prodList.filter(obj => {
                    return (obj.id === freeLine.product_id);
                  });
                  // const productline = lineData.filter(obj => {
                  //   return (obj.product_id === freeLine.product_id);
                  // });
                  let uomData = inthis.uomList.filter(existed => freeLine.product_id === existed.id);
                  uomData = uomData[0].uoms.filter(obj => {
                    return (freeLine.product_uom === obj.id);
                  });
                  // const uomData = productline[0].prod_uoms.filter(obj => {
                  //   return (freeLine.product_uom === obj.id);
                  // });
                  const prodFact = uomData[0].factor;
                  stock = 0;
                  if (inthis.sharedService.dmsParams.dmsType === 'P') {
                    stock = parseFloat((prodData[0].cust_stock * prodFact).toFixed(2));
                    supp_stock = parseFloat((prodData[0].stock * prodFact).toFixed(2));
                  } else {
                    stock = parseFloat((prodData[0].stock * prodFact).toFixed(2));
                  }
                  /* lineData.push({
                     id: null,
                     product_name: freeLine.product_name,
                     stock,
                     supp_stock,
                     product_id: parseInt(freeLine.product_id, 10),
                     qty: freeLine.qty,
                     price_subtotal: 0,
                     price_tax: 0,
                     price_total: 0,
                     tcs: 0,
                     price_unit: freeLine.price_unit,
                     new: false,
                     product_uom: freeLine.product_uom,
                     foc: false,
                     foc_product_id: null,
                     scheme_id: freeLine.scheme_id,
                     scheme_discount: 0,
                     taxmaster_id: prodData[0].taxmaster_id,
                     fixed_cost: 0,
                     trade_disc: 0,
                     extra_margin: 0,
                     schemeCode: undefined,
                     schemeUsed: undefined,
                     type: 'promo',
                     edit_status: false
                   });
                   rowData.push({
                     product_name: prodData[0].itemName + '(promo)',
                     stock,
                     supp_stock,
                     product_id: parseInt(freeLine.product_id, 10),
                     QTY: freeLine.qty,
                     price_subtotal: 0,
                     PRICE: 0,
                     TOTAL: 0,
                     new: false,
                     uom_id: freeLine.product_uom,
                     product_uom: freeLine.product_uom_name,
                     scheme_id: freeLine.scheme_id,
                     scheme_discount: freeLine.scheme_discount,
                     taxmaster_id: prodData[0].taxmaster_id,
                     tax_name: prodData[0].tax_name,
                     fixed_cost: 0,
                     trade_disc: 0,
                     extra_margin: 0,
                     schemeCode: freeLine.schemeCode,
                     line_type: 'promo',
                     ordered_qty: freeLine.qty,
                     invoiced_qty: 0,
                     qty_delivered: 0,
                     id: null,
                     edit_status: true
                   });*/
                });
              });
              line_data.push(each_line);
            });
            order.line_data = line_data;
          });
         // order.line_data = lineData;
          orderData.orders = [order];
          // checking edit or save directly
          if (key === 'edit') {
            let updatedLine;
            rowData.forEach(line => {
              if (line.line_type !== 'sample' && line.line_type !== 'promo') {
                if (!this.priceEdited) {
                  line.PRICE = (parseFloat(line.real_price) * parseFloat(line.factor_inv)) / (parseFloat(line.base_uom_inv));
                }
                let priceSubtotal = parseFloat(line.QTY) * parseFloat(line.PRICE);
                const linediscount = (priceSubtotal * parseFloat(line.extra_margin)) / 100;
                priceSubtotal = priceSubtotal - Math.abs(line.fixed_cost);
                priceSubtotal = priceSubtotal * (1 - (parseFloat(line.extra_margin) / 100));
                priceSubtotal = priceSubtotal - (parseFloat(line.trade_disc) * parseFloat(line.QTY));
                priceSubtotal = priceSubtotal - parseFloat(line.scheme_discount);
                line.PRICE = parseFloat(line.PRICE).toFixed(this.apiService.decimalPolicy);
                line.TOTAL = priceSubtotal.toFixed(this.apiService.decimalPolicy);
                const priceTax = (priceSubtotal * parseFloat(line.tax)) / 100;
                const priceTotal = priceSubtotal + priceTax;
                const tcs = priceTotal * (parseFloat(this.taxSource) / 100);
                const total = priceTotal + tcs;
                const spcldiscount = (parseFloat(line.QTY) * parseFloat(line.trade_disc));
                this.orderdetails.amount_tax += priceTax;
                this.orderdetails.amount_total += priceTotal;
                this.orderdetails.amount_untaxed += priceSubtotal;
                this.orderdetails.tcs += tcs;
                this.orderdetails.total += total;
                this.orderdetails.line_discount += linediscount;
                this.orderdetails.spcl_discount += spcldiscount;
                this.orderdetails.charges += Math.abs(line.fixed_cost);
              }
            });
            this.orderdetails.amount_tax = this.orderdetails.amount_tax.toFixed(this.apiService.decimalPolicy);
            this.orderdetails.amount_total = this.orderdetails.amount_total.toFixed(this.apiService.decimalPolicy);
            this.orderdetails.amount_untaxed = this.orderdetails.amount_untaxed.toFixed(this.apiService.decimalPolicy);
            this.orderdetails.tcs = this.orderdetails.tcs.toFixed(this.apiService.decimalPolicy);
            this.orderdetails.total = (this.orderdetails.total - this.orderDiscount).toFixed(this.apiService.decimalPolicy);
            this.orderdetails.line_discount = this.orderdetails.line_discount.toFixed(this.apiService.decimalPolicy);
            this.orderdetails.spcl_discount = this.orderdetails.spcl_discount.toFixed(this.apiService.decimalPolicy);
            this.orderdetails.charges = this.orderdetails.charges.toFixed(this.apiService.decimalPolicy);
            this.rowData = rowData;
            this.gridApi.setRowData(this.rowData);
            let inthis = this;
            // checking sample prod is there in rowdata
            sampleProd.forEach(function(line) {
              updatedLine = rowData.filter(function(existed) {
                return ((parseInt(line.product_id, 10) === parseInt(existed.product_id, 10)) &&
                  (line.line_type === existed.line_type));
              });
              if (updatedLine.length === 0) {
                rowData.push({
                  product_name: line.product_name,
                  stock: (parseFloat(line.stock) * parseFloat(line.factor)).toFixed(0),
                  supp_stock: (parseFloat(line.supp_stock) * parseFloat(line.factor)).toFixed(0),
                  product_uom: line.product_uom,
                  uoms: line.uoms,
                  ordered_qty: line.qty,
                  QTY: line.qty,
                  invoiced_qty: line.qty_invoiced,
                  qty_delivered: line.delivered_qty,
                  PRICE: parseFloat(line.unit_price).toFixed(inthis.apiService.decimalPolicy),
                  extra_margin: line.extra_margin,
                  trade_disc: line.trade_disc,
                  fixed_cost: line.fixed_cost,
                  scheme: line.scheme,
                  scheme_discount: line.scheme_discount,
                  tax_name: line.tax,
                  tax: line.tax_perc,
                  TOTAL: parseFloat(line.amount_subtotal).toFixed(inthis.apiService.decimalPolicy),
                  product_id: line.product_id,
                  uom_id: line.uom_id,
                  taxmaster_id: line.taxmaster_id,
                  line_type: 'promo',
                  id: line.id,
                  factor_inv: line.factor_inv,
                  base_uom_inv: line.factor_inv,
                  factor: line.factor,
                  edit_status: true,
                  min_margin: line.min_margin,
                  max_margin: line.max_margin,
                  real_stock: line.stock,
                  real_supp_stock: line.supp_stock
                });
              }
            });
            this.rowData = rowData;
            this.gridApi.sizeColumnsToFit();
            this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
          } else if (this.sharedService.dmsParams.new_mode || this.dmsStatus === 'draft') {
            if (this.dmsStatus === 'draft') {
              // checking deleted products in input data
              this.removedRows.forEach(function(line) {
                order.line_data.push({
                  id: line.id,
                  product_name: line.product_name,
                  stock: line.stock,
                  product_id: parseInt(line.product_id, 10),
                  qty: 0,
                  ordered_qty: 0,
                  invoiced_qty: 0,
                  qty_delivered: 0,
                  price_subtotal: 0,
                  price_unit: line.PRICE,
                  new: false,
                  product_uom_name: line.product_uom,
                  product_uom: line.uom_id,
                  foc: false,
                  foc_product_id: null,
                  scheme_id: null,
                  scheme_discount: 0,
                  tax: line.tax,
                  taxmaster_id: line.taxmaster_id,
                  fixed_cost: line.fixed_cost,
                  trade_disc: line.trade_disc,
                  extra_margin: line.extra_margin,
                  schemeCode: undefined,
                  schemeUsed: undefined,
                  type: 'normal',
                  edit_status: line.edit_status
                });
              });
              let updatedLine;
              sampleProd.forEach(function(line) {
                updatedLine = order.line_data.filter(function(existed) {
                  return ((parseInt(line.product_id, 10) === parseInt(existed.product_id, 10)) &&
                    (line.line_type === existed.type));
                });
                if (updatedLine.length === 0) {
                  order.line_data.push({
                    id: line.id,
                    product_name: line.product_name,
                    stock: line.stock,
                    product_id: parseInt(line.product_id, 10),
                    qty: 0,
                    ordered_qty: 0,
                    invoiced_qty: 0,
                    qty_delivered: 0,
                    price_subtotal: 0,
                    price_unit: line.PRICE,
                    new: false,
                    product_uom_name: line.product_uom,
                    product_uom: line.uom_id,
                    foc: false,
                    foc_product_id: null,
                    scheme_id: null,
                    scheme_discount: 0,
                    tax: line.tax,
                    taxmaster_id: line.taxmaster_id,
                    fixed_cost: line.fixed_cost,
                    trade_disc: line.trade_disc,
                    extra_margin: line.extra_margin,
                    schemeCode: undefined,
                    schemeUsed: undefined,
                    type: 'promo',
                    edit_status: line.edit_status
                  });
                }
              });
            }
            this.priceEdited = false;
            this.resData = this.apiService.saveDmsOrder(orderData);
            this.removedRows = [];
            this.resData.subscribe(res1 => {
              console.log(res1);
              if (res1.hasOwnProperty('results') && (res1.results.statusCode === 200)) {
                this.toastr.success('Created Successfully');
                this.suppEnable = false;
                this.sharedService.setDmsParams();
                this.sharedService.dmsParams.order_id = res1.results.id;
                this.orderDetails();
              } else {
                this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
                this.toastr.error(res.results.msg);
              }
            }, error => {
              this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
              console.log(error);
              if (error.error && error.error.error === undefined) {
                this.toastr.error('Creation failed');
              } else {
                this.toastr.error('Creation failed: ' + error.error.error.message);
              }
            });
          } else if ((this.dmsStatus === 'confirm' || this.dmsStatus === 'sale')) {
            // this.rowData = rowData;
            // this.gridApi.setRowData(this.rowData);
            // this.gridApi.sizeColumnsToFit();
            // this.updatedInvoice = true;
            this.priceEdited = false;
            this.invoiceOrder = order;
            this.createInvoice(this.invoiceOrder);
          }
        } else {
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
          this.toastr.error(res.results.msg);
        }
      }, error => {
        this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
        console.log(error);
        if (error.error && error.error.error === undefined) {
          this.toastr.error('Scheme Fetch failed');
        } else {
          this.toastr.error('Scheme Fetch failed: ' + error.error.error.message);
        }
      });
    } else {
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
      this.toastr.warning('No Valid entry to create ');
      this.prodShow = true;
    }
  }

  createInvoice(invOrder) {
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    const invDate = this.poDate;
    if ((new Date(this.poDate)).setHours(0, 0, 0, 0) >= this.orderDate.setHours(0, 0, 0, 0)) {
      // const invDate = invDates[2] + '-' + invDates[1] + '-' + invDates[0];
      let serialnos = this.sernos;
      const orderData = {
        access_token: this.userData.access_token,
        url: this.userData.url,
        orders: []
      };
      const order = {
        order_id: this.sharedService.dmsParams.order_id,
        order_type: 'sale',
        type: 'out_invoice',
        sale_ref: this.orderNo,
        date_invoice: invDate,
        pricelist_id: this.pricelistId,
        partner_id: this.customerId,
        user_id: this.userId,
        partner_shipping_id: this.partnerShippId,
        supplier_id: this.supplierId,
        dist_code: this.supplierCode,
        territory_id: null,
        supplier_territory_id: null,
        state_id: null,
        region_id: null,
        zone_id: null,
        area_id: null,
        company_id: null,
        invoice_type: 'S',
        scheme_id: null,
        scheme_discount: 0,
        discount: this.orderDiscount,
        po: this.po_number,
        comment: this.comments,
        beat_id: this.beatId,
        team_id: null,
        amount_total: 0,
        amount_tax: 0,
        amount_untaxed: 0,
        tax_source: 0,
        inv_items: [],
        state: 'confirm',
        period_id: invOrder.period_id
      };
      if (this.dmsConfirm) {
        order.state = 'draft';
      }
      if (this.custSupp) {
        order.invoice_type = 'P';
      }
      let prod_stock_check = {};
      invOrder.line_data.forEach(line => {
        if (prod_stock_check[line.product_id]) {
          prod_stock_check[line.product_id] += parseFloat(line.qty);
        } else {
          prod_stock_check[line.product_id] = parseFloat(line.qty);
        }
        if (!line.edit_status && (line.type === 'promo' || parseInt(line.qty, 10) > 0) &&
          (!this.stockCheck || (parseInt(line.stock, 10) >= parseInt(prod_stock_check[line.product_id], 10)))) {
          const lineData = {
            product_id: line.product_id,
            quantity: line.qty,
            uom_id: line.product_uom,
            price_unit: line.price_unit,
            taxmaster_id: line.taxmaster_id,
            discount: line.extra_margin,
            scheme_id: line.scheme_id,
            scheme_discount: line.scheme_discount,
            trade_disc: line.trade_disc,
            fixed_cost: line.fixed_cost,
            price_subtotal: line.price_subtotal,
            tax_source: line.tcs,
            type: line.type
          };
          if (serialnos[line.id]) {
            lineData['serials'] = serialnos[line.id];
            // serialnos[line.id].forEach(function (serial) {
            //   line_data.serials.push({
            //     "serial_no": serial
            //   })
            // });
          }
          order.inv_items.push(lineData);
        } else {
          order.inv_items = [];
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
          this.toastr.warning('Product QTY/Stock is zero:- ' + line.product_name);
        }
      });
      if (order.inv_items.length > 0) {
        orderData.orders = [order];

        this.resData = this.apiService.createInvoice(orderData);
        this.resData.subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.statusCode === 200)) {
            this.toastr.success(res.results.msg);
            this.prodShow = false;
            // this.sharedService.setDmsParams();
            // this.sharedService.dmsParams.order_id = res.results.id;
            // this.sharedService.dmsParams.flag = 'same';
            // this.orderDetails();
            this.sharedService.setDmsParams();
            this.sharedService.dmsParams.order_id = res.results.invoice_id;
            this.sharedService.dmsParams.cust_supp = this.orderdetails.customer_supplier;
            localStorage.setItem('invoice_order_id', res.results.invoice_id);
            if (this.sharedService.dmsParams.dmsType === 'S') {
              this.appComp.header_title = 'Sale Invoice';
            } else {
              this.appComp.header_title = 'Purchase Invoice';
            }
            this.router.navigate([this.apiService.redirect_url + "/new_invoice"]);
          } else {
            this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
            this.toastr.error(res.results.msg);
          }
        }, error => {
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
          console.log(error);
          if (error.error && error.error.error === undefined) {
            this.toastr.error('Creation failed');
          } else {
            this.toastr.error('Creation failed: ' + error.error.error.message);
          }
        });
      } else {
        this.toastr.warning('No Valid entry to create ');
      }
    } else {
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
      this.toastr.warning('Order Date is greater');
    }
  }

  cancelOrder() {

    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    const data = {
      order_id: this.sharedService.dmsParams.order_id,
    };

    const orderData = {
      access_token: this.userData.access_token,
      url: this.userData.url,
      orders: data
    };

    this.resData = this.apiService.markDmsCancelOrder(orderData);
    this.resData.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res.results.status === 200)) {
        this.toastr.success('Succesfully Cancelled');
        this.sharedService.setDmsParams();
        this.sharedService.dmsParams.order_id = res.results.id;
        this.orderDetails();
      } else {
        this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
        this.toastr.error(res.results.msg);
      }
    }, error => {
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
      console.log(error);
      this.toastr.error('Failed');
    });
  }

  editOrder() {

    if (this.dmsStatus === 'confirm') {
      this.poDate = this.myDate;
    }
    this.sharedService.dmsParams.edit_mode = true;
    if (!this.prodList || this.prodList.length === 0) {
      this.addProduct();
    } else {
      this.prodShow = true;
      this.productEntry();
      // removing schemes display
      if (this.dmsStatus === 'confirm' || this.dmsStatus === 'sale') {
        // this.myDate = new Date(this.poDate);
        const sampleProd = [];
        this.rowData.filter(obj => {
          if (obj.line_type === 'sample' || obj.line_type === 'promo') {
            sampleProd.push(obj);
          }
          if (obj.scheme) {
            obj.scheme = null;
          }
          if (obj.scheme_discount) {
            obj.TOTAL = (parseFloat(obj.TOTAL) + parseFloat(obj.scheme_discount)).toFixed(this.apiService.decimalPolicy);
            obj.scheme_discount = 0;
          }
        });
        let tempData = this.rowData.filter(val => !sampleProd.includes(val));
        this.gridApi.setRowData(tempData);
      }
    }
    this.orderdetails = null;
    // if (this.dmsStatus === 'draft') {
    //   if (!this.prodList || this.prodList.length === 0) {
    //     this.addProduct();
    //   } else {
    //     this.prodShow = true;
    //     this.gridApi.setRowData(this.rowData);
    //   }
    // } else if ((this.dmsStatus === 'confirm' || this.dmsStatus === 'sale')) {
    //   this.productEntry();
    //   this.prodShow = true;
    // }
  }

  confirmOrder() {
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    const orderData = {
      access_token: this.userData.access_token,
      url: this.userData.url,
      order_id: this.sharedService.dmsParams.order_id,
      type: 'order'
    };

    this.resData = this.apiService.confirmDmsOrder(orderData);

    this.resData.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res.results.statusCode === 200)) {
        this.toastr.success('Confirmed Succesfully');
        this.sharedService.setDmsParams();
        this.sharedService.dmsParams.order_id = res.results.order_id;
        this.orderDetails();
      } else {
        this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
        this.toastr.error(res.results.msg);
      }
    }, error => {
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
      console.log(error);
    });
  }

  // completeing the order with remaining qty
  autoComplete() {
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    const inData2 = {
      access_token: localStorage.getItem('resfreshToken'),
      order_id: this.sharedService.dmsParams.order_id
    };
    this.apiService.post('/api/dms_connects/auto_complete_order', inData2)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.orderDetails();
        } else {
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
          this.toastr.error(res.results.msg);
        }
      }, error => {
        this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
        this.toastr.error('Failed to auto complete');
      });
  }

  orderDetails() {

    const orderData = {
      access_token: this.userData.access_token,
      url: this.userData.url,
      order_id: [this.sharedService.dmsParams.order_id]
    };

    this.apiService.post('/api/dms_connects/get_sale_order', orderData)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
          const data = res.results.data[0];
          this.orderdetails = res.results.data[0];
          this.orderNo = this.orderdetails.name;
          this.uomList = this.orderdetails.uoms;
          this.poDate = this.datePipe.transform((new Date(this.orderdetails.date_order + ' UTC')).toString(), 'dd/MM/yyyy');
          this.orderDate = new Date(this.orderdetails.date_order + ' UTC');
          this.displayOrdDate = this.datePipe.transform((new Date(this.orderdetails.date_order + ' UTC')).toString(), 'dd/MM/yyyy');
          if (this.orderdetails.confirmation_date) {
            try {
              this.confirmationOrdDate = this.datePipe.transform((new Date(this.orderdetails.date_order + ' UTC')).toString(), 'dd/MM/yyyy');
            } catch (e) {
            }
          }
          this.supplierName = this.orderdetails.supplier;
          this.dStatus = this.orderdetails.d_status;
          this.beatName = this.orderdetails.beat_name;
          this.beatId = this.orderdetails.beat_id;
          this.customerId = this.orderdetails.partner_id;
          this.supplierId = this.orderdetails.supplier_id;
          this.supplierCode = this.orderdetails.supp_code;
          this.customerName = this.orderdetails.partner;
          this.customerTax = this.orderdetails.partner_gstn;
          this.customerMail = this.orderdetails.partner_email;
          this.customerAddress = this.orderdetails.address;
          this.supplierAddress = this.orderdetails.address;
          this.partnerShippId = this.orderdetails.partner_shipping_id;
          if (this.orderdetails.partner_invoice_id !== this.customerId) {
            this.customerAddress = this.orderdetails.inv_address;
          }
          this.invAddrName = this.orderdetails.inv_name;
          this.invAddrCode = this.orderdetails.inv_code;
          if (this.orderdetails.partner_shipping_id !== this.customerId) {
            this.supplierAddress = this.orderdetails.shp_address;
          }
          this.shipAddrName = this.orderdetails.shp_name;
          this.shipAddrCode = this.orderdetails.shp_code;
          this.supplierTax = this.orderdetails.gstn;
          // this.invoiceNo = this.orderdetails.invoice_no;
          this.pricelistId = this.orderdetails.pricelist_id;
          this.pricelist = this.orderdetails.pricelist;
          this.taxSource = this.orderdetails.tax_source;
          this.orderDiscount = parseFloat(this.orderdetails.discount);
          this.custSupp = this.orderdetails.customer_supplier;
          this.supplierCode = this.orderdetails.supp_code;
          this.customerCode = this.orderdetails.code;
          this.userName = this.orderdetails.van;
          this.comments = this.orderdetails.comment;
          this.po_number = this.orderdetails.po;
          this.userId = this.orderdetails.user_id;
          this.invoiceNo = this.orderdetails.invoice_no;
          this.invoiceDate = this.orderdetails.invoice_date;
          this.activityJson = this.orderdetails.activity_json;
          if (this.activityJson !== null && this.activityJson !== undefined) {
            for (const activity of this.activityJson) {
              activity.t = activity.t.replace('T', ' ').replace('Z', '');
            }
          }
          if (this.orderdetails.hasOwnProperty('config_json') && this.orderdetails.config_json !== null && this.orderdetails.config_json !== undefined) {
            if (this.orderdetails.config_json.hasOwnProperty('plant') && this.orderdetails.config_json.plant !== null && this.orderdetails.config_json.plant !== undefined) {
              this.showPlant = true;
              this.plant = this.orderdetails.config_json.plant;
            }
            if (this.orderdetails.config_json.hasOwnProperty('inco_term') && this.orderdetails.config_json.inco_term !== null && this.orderdetails.config_json.inco_term !== undefined) {
              this.showIncoTerms = true;
              this.inco_term = this.orderdetails.config_json.inco_term;
            }
            if (this.orderdetails.config_json.hasOwnProperty('totalWeight') && this.orderdetails.config_json.totalWeight !== null && this.orderdetails.config_json.totalWeight !== undefined) {
              this.totalWeight = parseFloat(this.orderdetails.config_json.totalWeight).toFixed(2);
            }
            if (this.orderdetails.config_json.hasOwnProperty('totalMeasure') && this.orderdetails.config_json.totalMeasure !== null && this.orderdetails.config_json.totalMeasure !== undefined) {
              this.totalMeasure = parseFloat(this.orderdetails.config_json.totalMeasure).toFixed(2);
            }
          }
          let linediscount = 0;
          let spcldiscount = 0;
          const sernos = {};
          localStorage.setItem('dms_order_id', data.id);
          this.dmsStatus = data.state;
          this.displayStatus = data.state;
          if (this.dmsStatus === 'no') {
            this.displayStatus = 'draft';
          }
          if (this.dmsStatus === 'sale') {
            this.displayStatus = 'complete';
          }
          if (this.dmsStatus === 'to_invoice' || this.dmsStatus === 'to invoice') {
            this.displayStatus = 'partial';
          }
          this.displayStatus = this.displayStatus.charAt(0).toUpperCase() + this.displayStatus.slice(1);
          this.invStatus = data.invoice_status;
          if (this.sharedService.dmsParams.dmsType === 'P' &&
            this.customerId === JSON.parse(localStorage.getItem('user')).partner_id) {
            this.primary = false;
          } else {
            this.primary = true;
          }
          this.orderdetails.charges = 0;
          this.orderdetails.sale_items.forEach(line => {
            sernos[line.id] = line.sers;
            linediscount += (parseFloat(line.qty) * parseFloat(line.unit_price) * parseFloat(line.discount)) / 100;
            spcldiscount += (parseFloat(line.qty) * parseFloat(line.trade_disc));
            this.orderdetails.charges += Math.abs(line.fixed_cost);
          });

          this.orderdetails.line_discount = linediscount.toFixed(this.apiService.decimalPolicy);
          this.orderdetails.spcl_discount = spcldiscount.toFixed(this.apiService.decimalPolicy);
          this.orderdetails.total = parseFloat(this.orderdetails.amount_total) + parseFloat(this.orderdetails.tcs);
          this.orderdetails.total = this.orderdetails.total - this.orderDiscount;
          this.orderdetails.total = this.orderdetails.total.toFixed(this.apiService.decimalPolicy);
          this.orderdetails.tcs = this.orderdetails.tcs.toFixed(this.apiService.decimalPolicy);
          this.lineData = this.orderdetails.sale_items;
          this.productEntry();
        } else {
          this.toastr.error(res.results.msg);
        }
      }, error => {
        console.log(error);
      });
  }

  fetchPreviousData() {
    //this.nextClick.emit('previous');
    if (this.rowIndex !== 0) {
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
      this.rowIndex = this.rowIndex - 1;
      this.sharedService.dmsParams.order_id = this.orderData[this.rowIndex];
      this.orderDetails();
    }
  }

  fetchNextData() {
    //this.nextClick.emit('next');
    if (this.rowIndex !== this.orderData.length - 1) {
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
      this.rowIndex = this.rowIndex + 1;
      this.sharedService.dmsParams.order_id = this.orderData[this.rowIndex];
      this.orderDetails();
    }
  }
}

