import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";

const KEY_BACKSPACE = 'Backspace';
const KEY_F2 = 'F2';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';
@Component({
  selector: 'app-paymentcell-renderer',
  template: ` <input *ngIf="!isPinnedBottomRow"
           #input
           type="number"
           class="custom-inputbox long bg_white"
           style="color:#0762AD; font-size: 14px; font-weight:500;"
            [disabled]="true"
           [(ngModel)]="value"
    />
    <span *ngIf="!isPinnedBottomRow && cr_used > 0" style="display: block;
    float: left;
    line-height: 12px;"> CR : {{cr_used}}</span>
 <!-- <span *ngIf="!editMode && !isPinnedBottomRow" class="element-req" style="margin-left: 5px;">{{value}}</span>-->
    <span *ngIf="isPinnedBottomRow" class="element-req" style="margin-left: 5px;">{{params.value}}</span>
   `,
  styleUrls: []
})
export class PaymentCellRendererComponent implements ICellRendererAngularComp {
  private params: any;
  public value!: number;
  public highlightAllOnFocus = true;
  private cancelBeforeStart = false;
  public editMode = false;
  public context;
  isPinnedBottomRow = false;
  cr_used;

  agInit(params: any): void {
    this.params = params;
    // this.editMode = false;
    if (params && params.node && params.node.rowPinned === 'bottom') {
      this.isPinnedBottomRow = true;
    } else {
      this.isPinnedBottomRow = false;
      this.cr_used = this.params.data.cr_used;
      if (this.cr_used) {
        this.cr_used = this.cr_used.toFixed(2);
      }
    }
    if (this.params.data) {
      this.value = this.params.data.tot_amt;
    }
  }

  refresh(): boolean {
    return false;
  }
}
