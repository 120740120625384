<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>QUESTION DETAILS</h2>
    </div>
    <div class="body-section w-100" style=" padding: 20px 15px;">
      <div>
        <form novalidate  id="ngForm" #questionform="ngForm">
          <div>
            <div class="col-md-6 pl-0 pr-30" style="margin-top: 20px;">
              <label class="dialoglabel1">QUESTION</label>
              <div style="margin-top: 5px;">
                <div *ngIf="edit">
                  <input
                    class="forminput"
                    type="text"
                    name="name"
                    [(ngModel)]="questionData.name"
                    #name="ngModel"
                    style="width: 90%;"
                    required
                  >
                  <div *ngIf="name.errors && (name.dirty || name.touched)">
                    <span *ngIf="name.errors.required" class="text-danger">Name is required</span>
                  </div>
                </div>
                <p class="detailvalue1" *ngIf="!edit">{{questionData.name}}</p>
              </div>
            </div>
            <div class="col-md-3 pl-0 pr-30" style="margin-top: 20px;">
              <label class="dialoglabel1">CODE</label>
              <div style="margin-top: 5px;">
                <div *ngIf="edit">
                  <input
                    class="forminput"
                    type="text"
                    name="code"
                    [(ngModel)]="questionData.code"
                    #code="ngModel"
                    style="width: 50%;"
                    required
                  >
                  <div *ngIf="code.errors && (code.dirty || code.touched)">
                    <span *ngIf="code.errors.required" class="text-danger">Code is required</span>
                  </div>
                </div>
                <p class="detailvalue1" *ngIf="!edit && questionData.code">{{questionData.code}}</p>
                <p class="detailvalue1" *ngIf="!edit && !questionData.code">NA</p>
              </div>
            </div>
            <div class="col-md-2 pl-0 pr-30" style="margin-top: 20px;">
              <label class="dialoglabel1">SEQUENCE</label>
              <div style="margin-top: 5px;">
                <div *ngIf="edit">
                  <input
                    class="forminput"
                    type="text"
                    name="sequence"
                    [(ngModel)]="questionData.sequence"
                    #sequence="ngModel"
                    style="width: 90%;"
                    required
                  >
                  <div *ngIf="sequence.errors && (sequence.dirty || sequence.touched)">
                    <span *ngIf="sequence.errors.required" class="text-danger">Sequence is required</span>
                  </div>
                </div>
                <p class="detailvalue1" *ngIf="!edit">{{questionData.sequence}}</p>
              </div>
            </div>
            <div class="col-md-1 pl-0 pr-30" style="margin-top: 20px;">
            </div>
          </div>
          <div class="col-md-12 pl-0 pr-30" style="margin-top: 20px;">
            <div class="col-md-6 pl-0 pr-30" >
              <label class="dialoglabel1">SECTION</label>
              <div style="margin-top: 5px;">
                <div *ngIf="edit">
                  <input
                    class="forminput"
                    type="text"
                    name="section"
                    [(ngModel)]="questionData.section"
                    #section="ngModel"
                    style="width: 50%;"
                  >
                </div>
                <p class="detailvalue1" *ngIf="!edit && questionData.section">{{questionData.section}}</p>
                <p class="detailvalue1" *ngIf="!edit && !questionData.section">NA</p>
              </div>
            </div>
            <div class="col-md-3 pl-3 pr-30" >
              <label class="dialoglabel1">ALLOW COMMENTS</label>
              <div style="margin-top: 5px;">
                <div>
                  <input
                    class="forminput"
                    type="checkbox"
                    name="allow_remarks"
                    [(ngModel)]="questionData.allow_remarks"
                    [disabled]="!edit"
                    #allow_remarks="ngModel"
                    style="height: 20px;width: 20px;"
                  >
                </div>
              </div>
            </div>
            <div class="col-md-3 pl-0 pr-30" >
              <label class="dialoglabel1">MANDATORY</label>
              <div style="margin-top: 5px;">
                <div >
                  <input
                    class="forminput"
                    type="checkbox"
                    name="mandatory"
                    [(ngModel)]="questionData.mandatory"
                    [disabled]="!edit"
                    #mandatory="ngModel"
                    style="height: 20px;width: 20px;"
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 pad0">
            <div class="col-md-6 pl-0 pr-30" style="margin-top: 20px;">
              <label class="dialoglabel1">TYPE</label>
              <div style="margin-top: 5px;">
                <div  class="formselect" >
                  <select
                    *ngIf="edit"
                    name="questiontype"
                    [(ngModel)]="questionData.type"
                    required
                    #questiontype="ngModel"
                    style="width: 50%;"
                  >
                    <option value="single choice" selected>Single Choice</option>
                    <option value="text">Text</option>
                    <option value="image">Image</option>
                    <option value="selection">Selection</option>
                    <option value="prepopulate">Prepopulate</option>
                    <option value="location">Location</option>
                    <option value="date">Date</option>
                    <option value="number">Number</option>
                  </select>
                  <p class="detailvalue1" *ngIf="!edit">{{questionData.type}}</p>
                </div>
              </div>
            </div>
            <div *ngIf="questionData.type==='selection' || questionData.type==='prepopulate'" class="col-md-3 pl-0 pr-30" style="margin-top: 20px;">
              <label class="dialoglabel1">MULTI ANSWER</label>
              <div style="margin-top: 5px;">
                <div >
                  <input
                    class="forminput"
                    type="checkbox"
                    name="multianswer"
                    [(ngModel)]="questionData.multianswer"
                    [disabled]="!edit"
                    #multianswer="ngModel"
                    style="height: 20px;width: 20px;"
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 pad0" *ngIf="questionData.type==='selection' || questionData.type==='prepopulate'">
            <div class="col-md-6 pl-0 pr-30" style="margin-top: 20px;">
              <label class="dialoglabel1">SELECTION LABEL</label>
              <div style="margin-top: 5px;">
                <div  *ngIf="edit" class="formselect">
                  <input
                    class="forminput"
                    type="text"
                    name="selection_label"
                    [(ngModel)]="questionData.selection_label"
                    #selection_label="ngModel"
                    style="width: 50%;"
                    required
                  >
                  <div *ngIf="selection_label.errors && (selection_label.dirty || selection_label.touched)">
                    <span *ngIf="selection_label.errors.required" class="text-danger">Selection Label is required</span>
                  </div>
                </div>
                <p class="detailvalue1" *ngIf="!edit && questionData.selection_label">{{questionData.selection_label}}</p>
                <p class="detailvalue1" *ngIf="!edit  && !questionData.selection_label">NA</p>
              </div>
            </div>
            <div class="col-md-6 pl-0 pr-30" style="margin-top: 20px;">
              <label class="dialoglabel1">ANSWER LABEL</label>
              <div style="margin-top: 5px;">
                <div  *ngIf="edit" class="formselect">
                  <input
                    class="forminput"
                    type="text"
                    name="comment_label"
                    [(ngModel)]="questionData.comment_label"
                    #comment_label="ngModel"
                    style="width: 50%;"
                    required
                  >
                  <div *ngIf="comment_label.errors && (comment_label.dirty || comment_label.touched)">
                    <span *ngIf="comment_label.errors.required" class="text-danger">Answer Label is required</span>
                  </div>
                </div>
                <p class="detailvalue1" *ngIf="!edit && questionData.comment_label">{{questionData.comment_label}}</p>
                <p class="detailvalue1" *ngIf="!edit  && !questionData.comment_label">NA</p>
              </div>
            </div>
          </div>

        </form>
      </div>
      <div class="col-md-12 pad0" style="margin-top: 20px; margin-bottom: 30px;">
        <div  style="display: inline-block;">
          <span style="font-size: 20px; color:#59A7A2;font-weight: 400;margin-top: 5px;">Available Answers</span>
        </div>
        <div style="float:right;margin-top: 5px;cursor:pointer;" (click)="addQuestion()" *ngIf="edit">
          <i class="fas fa-plus-circle" style="color:#17A2B8;margin-right: 5px;margin-top:5px;font-size:16px;"></i>
          <span style="font-size: 14px; font-weight: 400;">Add</span>
        </div>
        <div>
          <ag-grid-angular *ngIf=loaded id="myGrid1" style="width: 100%;height:200px;" class="ag-theme-balham"   [defaultColDef]="defaultColDef"
                           [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                           (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                           (cellClicked)="onCellClicked($event)"
                           [context]="context">
          </ag-grid-angular>
          <div *ngIf="preloader">
            <app-loadingstate></app-loadingstate>
          </div>
          <div *ngIf="empty_state">
            <app-emptystate></app-emptystate>
          </div>
        </div>
      </div>
      <div *ngIf="edit" class="button-row" style="float: right; margin-bottom: 30px;">
        <button
          mat-button
          style=" margin-right:15px;"
          class="btn grey-btn"
          (click)="closeDialog()"
        >
          Discard
        </button>
        <button
          mat-button
          class="btn confirm-blue-btn"
          form="ngForm"
          [disabled]="questionform.form.invalid"
          (click)="saveQuestion()"
        >
          Save
        </button>
      </div>

    </div>
  </div>
</section>
