import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {StockInvalidateComponent} from "../../StockInvalidateComponent.component";

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class PrimarySalesStockReportComponent extends ReportBase implements IReport, OnInit {


  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Primary Sales Stock Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.state = true;
    this.showableFilters.region = true;

    this.context = {componentParent: this};
    this.frameworkComponents['invalidateRenderer'] = StockInvalidateComponent;
    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getPrimarySalesStock';

    this.columnDefs = [

      {headerName: 'REGION', field: 'region', enableRowGroup: true, width: 80},
      {headerName: 'CLUSTER', field: 'cluster', enableRowGroup: true, width: 80},
      {headerName: 'VAN', field: 'van', enableRowGroup: true, width: 80},
      {headerName: 'LINE ID', field: 'id', width: 100, enableRowGroup: true},
      {headerName: 'ORDER REF', field: 'name', width: 100, enableRowGroup: true},
      {headerName: 'ORDER DATE', field: 'date_order', width: 100, enableRowGroup: true},
      {headerName: 'PRODUCT', field: 'product', enableRowGroup: true, width: 80},
      {headerName: 'PCODE', field: 'p_code', enableRowGroup: true, width: 80},
      {headerName: 'DELIVERED QTY', field: 'qty_delivered', width: 80},
      {headerName: 'STOCK LOG', field: 'stock_count', enableRowGroup: true, width: 150},
      {
        headerName: 'STOCK', field: 'd_status',
        cellRenderer: "invalidateRenderer", pinned: 'right', width: 120
      },
    ];

  }

  async invalidate(params, node) {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.line_id = params.id;

    this.apiService.post('/api/pwa_connects/invalidateStock', paramObject)
      .subscribe(res => {
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            console.log(res);
            this.toastr.success("Invalidate success!!");
            this.loadServerData();
          } else {
            this.preloader = false;
            this.toastr.error("Invalidate failed!!");
          }
        },
        error => {
          //console.log(error);
          this.toastr.error("Invalidate failed!!");
          this.preloader = false;
        });
  }

}
