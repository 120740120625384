import {
  Component,
  OnInit,
  ElementRef,
  Renderer2,
  ViewChild,
  ComponentFactoryResolver,
  ChangeDetectorRef
} from '@angular/core';
import { GridOptions } from "../../../../node_modules/@ag-grid-enterprise/all-modules";
import { ApiService} from "../../_services/api.service";
import { Router, ActivatedRoute  } from '@angular/router';
import { DatePipe } from '@angular/common';
import {PrintInvoiceComponent} from "../../printInvoice.component";
import {PrintClickRenderer} from "../../printClickRenderer.component";
import {SharedService} from "../../_services/shared_service";
import {AppComponent} from "../../app.component";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-my-ledger-report',
  templateUrl: './my-ledger-report.component.html',
  styleUrls: ['./my-ledger-report.component.scss']
})
export class MyLedgerReportComponent implements OnInit {
  @ViewChild('interPrint') public interPrint: ElementRef;
  @ViewChild('ledgerPrint') public ledgerPrint: ElementRef;

  user;
  res_data;
  user_data = {
    access_token: null,
    url: null,
    offset: null,
    data: {
      start_date: null,
      end_date: null,
      out_id: 0,
      out_code: '',
      inv_ids: [],
      supplier_id: 0
    }
  };

  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public searchValue;
  public frameworkComponents;
  public context;
  public rowData = [];
  public navigate;
  public defaultColDef;
  public sideBar;
  private rowHeight;
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public styleGrid;
  public myDate = [];
  public collapse_disable;
  public report_flag;
  public orderDetails;
  private dms_enable = false;
  private API_URL: string;
  public all_orders = [];
  public custList = [];
  public selectedCust = null;
  public printcolumnDefs = [];
  public supplier;
  public printgridOptions: GridOptions;
  public allData: any[];

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef:ElementRef,
              private renderer: Renderer2,
              private datePipe: DatePipe,
              public appComp: AppComponent,
              private sharedService: SharedService,
              public  domSanitizer: DomSanitizer,
              private componentFactoryResolver: ComponentFactoryResolver) {
    this.myDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.myDate[1] = this.myDate[0];
    this.user_data.data.start_date = this.myDate[0];
    this.user_data.data.end_date = this.myDate[1];
    this.user_data.offset = -1 * (new Date(this.user_data.data.start_date)).getTimezoneOffset();
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');
    this.custList = JSON.parse(localStorage.getItem('customers_list'));
    let updatedList = [];
    if (this.custList && this.custList.length > 0) {
      this.custList.forEach(cust => {
        updatedList.push({
          id: cust.id,
          name: cust.name + '(' + cust.code + ')'
        });
      });
    }
    this.custList = updatedList;

    localStorage.setItem('user_data', JSON.stringify(this.user_data));
    this.sharedService.setHeader('Primary Ledger');
    if (localStorage.getItem('dms_module') === 'true' || localStorage.getItem('module_dmsv2') === 'true') {
      this.dms_enable = true;
    }
    this.user_data.data.out_id = JSON.parse(localStorage.getItem('user')).partner_id;
    let ins = this;
    this.columnDefs = [
      { headerName: 'ID',  field: 'cust_id',
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true, width: 110},
      { headerName: 'CODE',  field: 'code', width: 90 },
      { headerName: 'CUSTOMER',  field: 'name', width: 200 },
      { headerName: 'SUPPLIER',  field: 'supplier', width: 150 },
      { headerName: 'REF',  field: 'ref', width: 200 },
      { headerName: 'INV DATE',  field: 'date_invoice',width: 100,
        cellRenderer: (data) => {
          return data.value ? ins.datePipe.transform((new Date(data.value + ' UTC')).toString(), 'dd/MM/yyyy') : '';
        }},
      { headerName: 'PAY DATE',  field: 'payment_date', width: 100,
        cellRenderer: (data) => {
          return data.value ? ins.datePipe.transform((new Date(data.value + ' UTC')).toString(), 'dd/MM/yyyy') : '';
        }},
      { headerName: 'TYPE',  field: 'type', width: 100},
      { headerName: 'MODE',  field: 'mode', width: 100},
      { headerName: 'CREDIT',  field: 'credit', cellStyle: {textAlign: 'right'}, width: 90},
      { headerName: 'DEBIT',  field: 'debit', cellStyle: {textAlign: 'right'}, width: 90},
      { headerName: 'COMMENTS',  field: 'comments', width: 120},
      { headerName: 'OUTSTANDING',  field: 'balance', cellStyle: {textAlign: 'right'}, width: 110, pinned: 'right'},
      { headerName: 'PRINT', cellStyle: {textAlign: 'right'}, pinned: 'right', width: 100,
        headerComponent: 'printInvoicecomponent',
        cellRenderer: 'printClickRenderer'},
      { headerName: 'id',  field: 'id', hide: true},
      { headerName: 'payment_id',  field: 'payment_id', hide: true},
    ];

    this.printcolumnDefs = [
      { headerName: 'PAY DATE',  field: 'payment_date', width: 100,
        cellRenderer: (data) => {
          return data.value ? ins.datePipe.transform((new Date(data.value + ' UTC')).toString(), 'yyyy-MM-dd') : '';
        }},
      { headerName: 'CODE',  field: 'code', width: 100},
      { headerName: 'SUPPLIER',  field: 'supplier',  width: 150},
      { headerName: 'REF',  field: 'ref', width: 180},
      { headerName: 'MODE',  field: 'mode', width: 90},
      { headerName: 'CREDIT',  field: 'credit', width: 80},
      { headerName: 'DEBIT',  field: 'debit', width: 80},
      { headerName: 'COMMENTS',  field: 'comments', width: 120},
      { headerName: 'OUTSTANDING',  field: 'balance', width: 120}
    ];

    this.gridOptions = <GridOptions>{ rowHeight: 40,
      rowStyle: { 'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left' },
      statusBar: {
        statusPanels: [
          { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
          { statusPanel: 'agTotalRowCountComponent', align: 'center' },
          { statusPanel: 'agFilteredRowCountComponent' },
        ]
      },
      defaultColDef: {
        headerClass: 'myagheader',
        filter : true,
        sortable : true,
        resizable : true
      },
      rowSelection: 'multiple'};

    this.printgridOptions = <GridOptions>{ rowHeight: 65,
      rowStyle: { 'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left' },
      defaultColDef: {
        headerClass: 'myagheader',
        filter : true,
        sortable : true,
        resizable : true
      },
      rowSelection: 'multiple'};

  }

  run () {
    this.ledgerreport();
  }

  printLedger() {
    debugger;
    this.allData = [];
    let row_data = JSON.parse(JSON.stringify(this.rowData));
    let section;
    do {
      section = row_data.splice(20);
      this.allData.push({
        data: row_data,
      });
      row_data = section;
    } while (row_data.length > 0);
    let el1: HTMLElement = this.ledgerPrint.nativeElement;
    el1.click();
  }

  custChange(event) {
    if (event.length > 0 && event[0].id) {
      this.user_data.data.out_id = event[0].id;
    } else {
      this.user_data.data.out_id = 0;
      this.sharedService.dmsParams.ret_id = null;
    }
  }

  ledgerreport() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    let rowdata = [];

    if (this.dms_enable) {
      this.API_URL = '/api/dms_connects/finance_ledger_report';
    } else {
      this.API_URL = '/api/pwa_connects/finance_ledger_report';
    }
    this.apiService.post(this.API_URL, this.user_data)
    .subscribe(res => {
        debugger;
        if (res.hasOwnProperty('results') && (res['results'].status == 200)) {
          this.rowData = res['results'].data;
          if (this.rowData.length > 0) {
            this.supplier = this.rowData[0].name;
          }
          this.loaded = true;
          this.context = {componentParent: this};
          this.frameworkComponents = {
            printInvoicecomponent: PrintInvoiceComponent,
            printClickRenderer: PrintClickRenderer
          };

        } else {
          this.preloader = false;
          this.empty_state = true;
        }

      },
      error => {
        console.log(error);
        this.preloader = false;
      });
  }

  onCellClicked(event) {
    if (event.colDef.headerName == 'PRINT') {
      let rows = [{
        payment_id: event.data.payment_id
      }];

      this.printAll(rows);
    }
  }

  printAll(rows) {
    this.all_orders = [];
    let pay_ids = [];
    rows.forEach(van_row => {
      pay_ids.push(van_row['payment_id']);
    });

    let order_data = {
      access_token: this.user_data.access_token,
      url: this.user_data.url,
      data: {
        pay_ids,
      }
    };

    if (this.dms_enable) {
      this.API_URL = '/api/dms_connects/cash_receipt';
    } else {
      this.API_URL = '/api/pwa_connects/cash_receipt';
    }

    this.apiService.post(this.API_URL, order_data)
    .subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res['results'].status == 200) && (res['results'].data.length > 0)) {
        let orderDetails = [];
        let datePipe = this.datePipe;
        let inthis = this;
        res['results'].data.forEach(function(data) {
          orderDetails = data;
          orderDetails['label_config'] = {};
          let labels = JSON.parse(localStorage.getItem('custom_labels'));
          labels.forEach(function (lab) {
            orderDetails['label_config'][lab.label] = lab.display;
          });
          orderDetails['invoice_date'] = datePipe.transform((new Date(orderDetails['date_invoice'] + ' UTC')).toString(), 'dd/MM/yyyy');
          orderDetails['payment_date'] = datePipe.transform((new Date(orderDetails['payment_date'] + ' UTC')).toString(), 'dd/MM/yyyy HH:mm:ss');
          if (orderDetails['payment_type'] === 'Cr Used') {
            orderDetails['receipt_type'] = 'Cr Note';
          }else if (orderDetails['payment_type'] === 'cash_and_credit') {
            orderDetails['receipt_type'] = 'Credit With Payment';
          }else if (orderDetails['payment_type'] === 'credit') {
            orderDetails['receipt_type'] = 'Credit Without Payment';
          }else if (orderDetails['payment_type'] === 'cash') {
            orderDetails['receipt_type'] = 'Cash';
          }
          if (orderDetails['logo']) {
            const res_data = inthis.apiService.fetchImage({
              access_token: inthis.user_data.access_token,
              url: inthis.user_data.url,
              data: [{store_fname: orderDetails['logo'], container: "res_partner"}]
            });
            res_data.subscribe(resp => {
                console.log(resp);
                orderDetails['logo'] = inthis.domSanitizer.bypassSecurityTrustUrl("data:image/png;base64, "+resp['result'][0].bill_datas);
                inthis.all_orders.push(orderDetails);
              },
              error => console.log(error)
            );
          } else {
            inthis.all_orders.push(orderDetails);
          }
        });
        this.triggerFalseClick2();
      }
    });
  }

  triggerFalseClick2() {
    let el: HTMLElement = this.interPrint.nativeElement;
    el.click();
  }

  printGridReady(params, rowdata) {
    params.api.setRowData(rowdata);
    params.api.setDomLayout("autoHeight");
    params.columnApi.autoSizeColumns();
  }

  onGridReady(params) {

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.setRowData(this.rowData);
    this.preloader = false;
    window.addEventListener('resize', function() {
      setTimeout(function() {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        }else {
          params.columnApi.autoSizeColumns();
        }
      });
    });

    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    }else {
      params.columnApi.autoSizeColumns();
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  ngOnInit() {

    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable == "true" ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {

    this.user_data.data.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.user_data.data.end_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    localStorage.setItem('user_data', JSON.stringify(this.user_data));

  }

}
