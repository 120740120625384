<div class="center">
  <span class="titletext">Publish Task</span>
  <p class="messagetext1">Please select Product Brand if applicable (Optional)</p>

  <ng-select [items]="brandList"
             bindLabel="name"
             multiple="true"
             maxSelectedItems="1"
             [(ngModel)]="selectedBrand"
  >
  </ng-select>

  <div  class="button-row" style="margin-top: 235px;">
     <span>
        <a class="btn white-btn" style="margin-right:30px;" (click)="closeDialog()">CANCEL</a>
     </span>
    <button
      mat-button
      class="btn teal-btn"
      (click)="performAction('yes')"
    >
     PUBLISH
    </button>
  </div>
</div>
