import {Component, OnInit} from "@angular/core";
import {PageBase} from "../pagebase/pagebase";

@Component({
  selector: 'app-leads',
  templateUrl: '../pagebase/pagebase.html',
  styleUrls: ['../pagebase/pagebase.scss']
})


export class SupplierListComponent extends PageBase implements OnInit {

  ngOnInit(): void {

    this.sharedService.setHeader('Suppliers');

    this.showableFilters.region = true;
    this.showableFilters.territory = true;
    this.showableFilters.salesman = true;
    this.showableFilters.team = true;

    this.configureGrid();
    this.loadServerData();
  }


  configureGrid() {
    this.API_URL = '/api/res_partner/get_supplier_list';
    const inthis = this;
    this.columnDefs = [
      {headerName: "AREA", field: "area", width: 100},
      {headerName: "ZONE", field: "zone", width: 100},
      {headerName: "NAME", field: "name", width: 100},
      {headerName: "CUSTOMER", field: "partner_name", width: 150},
      {headerName: "EMAIL", field: "email_from", width: 80},
      {headerName: "MOBILE", field: "mobile", width: 100},
      {headerName: "PLANNED REVENUE", field: "planned_revenue", width: 100},
      {headerName: "TYPE", field: "type", width: 150},
      {headerName: "DESCRIPTION", field: "description", hide: true, width: 50},
      {headerName: "STAGE", field: "stage", width: 150},
      {
        headerName: "CREATE DATE", field: "create_date", width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.create_date) {
              return inthis.datePipe.transform((new Date(params.data.create_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return params.data.create_date;
            }
          } else {
            return '';
          }
        }
      },
    ];
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    const filteredRowData = this.gridApi.rowModel.rowsToDisplay;
    let filteredRows = [];
    for (const key in filteredRowData) {
      /* if (!filteredRowData[key].hasOwnProperty('data')) {
         filteredRows.push(filteredRowData[key]);
       }*/
      filteredRows.push(filteredRowData[key].data);
    }
    if (filteredRows !== null && filteredRows.length > 0) {
      this.storeSupplierData(filteredRows);
    }
    localStorage.setItem('supplierid', rows[0].id);
    localStorage.setItem('supplierindex', event.rowIndex);
   /* this.router.navigate([this.apiService.redirect_url + '/customers/leads/details'], {
      state: {
        viewtype: 'detail',
        customerid: rows[0].id
      }
    });*/
  }

  postProcessData() {
    this.rowData = this.apiData;
    this.storeSupplierData(this.apiData);
    return;
  }

  storeSupplierData(data) {
    const ids = [];
    for (const key in data) {
      ids.push(data[key].id);
    }
    localStorage.setItem('supplierdata', JSON.stringify(ids));
  }
}
