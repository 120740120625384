import {Component, OnInit} from "@angular/core";
import {PageBase} from "../../pagebase/pagebase";
import {MatDialogConfig} from "@angular/material/dialog";
import {QuestionnaireUploadComponent} from "../questionnaire_upload.component";

@Component({
  selector: 'app-retail-survey_market-component',
  templateUrl: './../../pagebase/pagebase.html',
  styleUrls: ['./../../pagebase/pagebase.scss']
})
export class RetailSurveyMarketComponent extends PageBase implements OnInit {

  ngOnInit(): void {
    this.sharedService.setHeader('Market Intelligence Survey');
    this.showDate = false;
    this.userData.customFilter['type'] = 'retailer';
    this.showBaseUpload = true;
    this.configureGrid();
    this.loadServerData();
  }

  configureGrid() {
    this.API_URL = '/api/crm_profiling_questionnaires/get_retail_survey_list';
    this.columnDefs = [
      {headerName: "CODE", field: "code", width: 150},
      {headerName: "QUESTIONNAIRE", field: "name", width: 150},
      {headerName: "NO OF QUESTIONS", field: "questions", cellStyle: {textAlign: 'right'}, width: 80},
      {headerName: "START DATE", field: "start_date", width: 100},
      {headerName: "END DATE", field: "end_date", width: 80},
      {headerName: "FREQUENCY", field: "period", width: 80},
      {
        headerName: "SCORE MTD  %", field: "score", cellStyle: {textAlign: 'center'}, width: 80,
        valueGetter(params) {
          return Math.round((params.data.score + Number.EPSILON) * 100) / 100;
        }
      },
      {headerName: "COMPLETED MTD", field: "completed", cellStyle: {textAlign: 'center'}, width: 80},
    ];
  }

  onCellClicked(event) {
    const rows = this.gridApi.getSelectedRows();
    localStorage.setItem('surveytype', 'retail');
    localStorage.setItem('surveyid', rows[0].id);
    this.router.navigate([this.apiService.redirect_url + '/survey_detail'], {
      state: {
        type: 'detail',
        surveyid: rows[0].id
      }
    });
  }

  showUploadPopup() {
    let creatediaeref;
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "90%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
      };
      creatediaeref = this.dialog.open(QuestionnaireUploadComponent, config);
      creatediaeref.afterClosed().subscribe(result => {
        this.loadServerData();
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
      };
      creatediaeref = this.dialog.open(QuestionnaireUploadComponent, config);
      creatediaeref.afterClosed().subscribe(result => {
        this.loadServerData();
      });
    }
  }
}
