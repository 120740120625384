<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button class="closebtn" (click)="closeDialog()">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>POD Details</h2>
    </div>
    <div class="body-section w-100">
      <div id="heaader"  style="padding: 15px;"  [ngClass]="{'rejectedbg': rejectComments,
                         'bg': !rejectComments}">
        <div class="row" *ngIf="!rejectComments">
          <span style="font-size: 18px;color:#FFFFFF">Delivery Verified</span>
          <span><i class="fa-regular fa-circle-check"></i></span>
        </div>
        <div class="row" *ngIf="rejectComments">
          <span style="font-size: 18px;color:#FFFFFF">Rejected</span>
        </div>
        <div class="row" style="margin-top: 15px;">
          <span style="color:#FFFFFF">Delivery Date :</span>
          <span class="dataspan" style="color:#FFFFFF">{{date_delivered}}</span>
        </div>
        <div class="row"  style="margin-top: 5px;">
          <span style="color:#FFFFFF">Invoice Number :</span>
          <span class="dataspan" style="color:#FFFFFF">{{data['INVOICE NO']}}</span>
        </div>
      </div>
    </div>
    <div id="body"  style="background-color: #F5F7F9;padding: 15px;padding-bottom: 40px;">
      <span style="display: block">Verification Type</span>
      <span style="font-size: 22px;display: block;">{{type}}</span>
      <div class="row" style="margin-top: 15px;">
        <span >Recipient Name :</span>
        <span class="dataspan">{{name}}</span>
      </div>
      <div class="row" style="margin-top: 5px;">
        <span>Recipient Number :</span>
        <span class="dataspan">{{data['mobile']}}</span>
      </div>
      <div *ngIf="rejectComments" class="row" style="margin-top: 5px;">
        <span>Reject Comments :</span>
        <span class="dataspan" style="color: red">{{rejectComments}}</span>
      </div>
      <div *ngIf="type !== 'OTP'" style="margin-top: 25px;text-align: center;">
        <img [src]="photo" style="width:70%;height:70%;">
      </div>
    </div>
    <div *ngIf="showApprove" class="button-row" style="position: absolute;  right: 0px; margin-top: 20px;padding: 15px;">
      <button
        mat-button
        style=" margin-right:15px;"
        class="btn reject-btn"
        (click)="openRejectPopup()"
      >
        Reject
      </button>
      <button
        mat-button
        style=" margin-right:15px;"
        class="btn confirm-blue-btn"
        (click)="verifyPOD()"
      >
        Verify
      </button>
    </div>
  </div>
</section>
