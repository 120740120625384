import {AfterViewChecked, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {New_order_base} from './new_order_base';
import {GridOptions} from '@ag-grid-enterprise/all-modules';
import {SerialNoRenderer} from '../../serialNo.component';
import {SuppProdDropdownComponent} from '../../components/supp_prod_dropdown/supp-prod-dropdown.component';
import {CellEdit2Renderer} from '../../editCell2.component';
import {PriceCellRendererComonent} from '../../priceCellRenderer.comonent';
import {DeleteComponent} from '../../deleteCell.component';
import {UomComponent} from '../../uom.component';
import {parse} from 'ts-node';

@Component({
  selector: 'app-dms-invoice',
  templateUrl: './new_create.html',
  styleUrls: ['./new_order_base.scss']
})

export class Dms_Invoice_New_Component extends New_order_base implements OnInit  {
  @ViewChild('serial') serial: ElementRef<HTMLElement>;
  @ViewChild('serialIn') serialIn: ElementRef<HTMLElement>;
  userData = {
    date: null,
    dc_id: 0,
    supplier_id: null,
    customer_id: null,
    pricelist_id: null,
    access_token: null,
    url: null,
    offset: null,
    po_data: null
  };
  resData;
  public roleFeatures;
  public context;
  public frameworkComponents;
  public browserRefresh = false;
  public subscription;
  public enteredQty = 0;
  public discount = 0;
  public extraMargin = 0.00;
  public maxMargin = 0;
  public minMargin = 0;
  public fixedCost = 0;
  public tradeDisc = 0.00;
  public schemeDiscount = 0;
  public po;
  public invoiceOrder: any;
  public supplierTerritory;
  public orderID;
  public sampleProd = [];
  private API_URL: string;
  public old_path = null;

  ngOnInit() {
    this.columnDefs = [
      { headerName: 'PRODUCT', field: 'product_name' , sortable: true, filter : true, resizable: true, lockPosition: true, width: 350},
      { headerName: 'STOCK', field : 'stock', sortable: true, filter : true, resizable: true, lockPosition: true, width: 80},
      { headerName: 'UOM', field: 'uoms', sortable: true, filter : true, resizable: true, lockPosition: true, width: 90,
        cellRenderer: 'uomRenderer'},
      { headerName: 'QTY', field: 'QTY', sortable: true, filter : true, resizable: true, lockPosition: true,
        cellRenderer: 'cellEdit2Renderer', width: 80},
      { headerName: 'RETURN', field: 'RET', sortable: true, filter : true, resizable: true, lockPosition: true,
        cellRenderer: 'cellEdit2Renderer', width: 80, hide: true},
      { headerName: 'REUSE', field: 'DMG', sortable: true, filter : true, resizable: true, lockPosition: true,
        cellRenderer: 'cellEdit2Renderer', width: 80, hide: true},
      { headerName: 'invoiced_qty', field: 'invoiced_qty', sortable: true, filter : true, resizable: true, lockPosition: true,
        width: 80, hide: true},
      { headerName: 'SCHEME', field: 'scheme', sortable: true, filter : true, resizable: true, lockPosition: true, width: 100},
      { headerName: 'PRICE', field: 'PRICE', sortable: true, filter : true, resizable: true, lockPosition: true, width: 80,
        cellRenderer: 'cellEdit2Renderer'},
      { headerName: 'DISC%', field: 'extra_margin', sortable: true, filter : true, resizable: true, lockPosition: true, width: 80,
        cellRenderer: 'cellEdit2Renderer'},
      { headerName: 'SPCL DISC/qty', field: 'trade_disc', sortable: true, filter : true, resizable: true, lockPosition: true,
        width: 80, cellRenderer: 'cellEdit2Renderer'},
      { headerName: 'CHARGES', field: 'fixed_cost', sortable: true, filter : true, resizable: true, lockPosition: true, width: 80},
      { headerName: 'SCHEME DIS', field: 'scheme_discount', sortable: true, filter : true, resizable: true, lockPosition: true, width: 90},
      { headerName: 'TAX', field: 'tax_name', sortable: true, filter : true, resizable: true, lockPosition: true, width: 100},
      { headerName: 'SUB TOTAL', field: 'TOTAL', sortable: true, filter : true, resizable: true, lockPosition: true, width: 100},
      { headerName: 'Serial NO', sortable: true, filter : true, resizable: true, lockPosition: true, hide: true,
        cellRenderer: 'serialNoRenderer', width: 80},
      { headerName: '', field: 'delete', resizable: true, lockPosition: true,
        cellRenderer: 'cellDeleteRenderer', width: 60},
      { headerName: 'sale_price', field: 'sale_price', hide: true},
      { headerName: 'product_id', field: 'product_id', hide: true},
      { headerName: 'line_id', field: 'line_id', hide: true},
      { headerName: 'line_type', field: 'line_type', hide: true},
      { headerName: 'uom_id', field: 'uom_id', hide: true},
      { headerName: 'product_uom', field: 'product_uom', hide: true},
      { headerName: 'tax_amount', field: 'tax', hide: true},
      { headerName: 'factor_inv', field: 'factor_inv', hide: true},
      { headerName: 'base_uom_inv', field: 'base_uom_inv', hide: true},
      { headerName: 'factor', field: 'factor', hide: true},
      { headerName: 'max_margin', field: 'max_margin', hide: true},
      { headerName: 'min_margin', field: 'min_margin', hide: true},
      { headerName: 'real_stock', field: 'real_stock', hide: true}

    ];
    this.invEnable = true;
    this.invoiceNo = null;
    this.userData.date = this.myDate;
    this.userData.offset = -1 * (new Date(this.userData.date)).getTimezoneOffset();
    this.userData.access_token = localStorage.getItem('resfreshToken');
    this.context = {componentParent: this};
    this.frameworkComponents = {
      serialNoRenderer: SerialNoRenderer,
      cellEdit2Renderer: CellEdit2Renderer,
      priceCellRenderer: PriceCellRendererComonent,
      cellDeleteRenderer: DeleteComponent,
      uomRenderer: UomComponent
    };
    this.beatChange = this.beat_change;
    this.outChange = this.out_change;
    this.prodChange = this.prod_change;
    this.userChange = this.user_change;
    this.shipaddrssChange = this.invShipAddrsChange;
    this.sharedService.dmsParams.order_id = localStorage.getItem('invoice_order_id');
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    if (this.sharedService.dmsParams.order_id && this.sharedService.dmsParams.order_id === 'null') {
      this.sharedService.dmsParams.new_mode = true;
    }
    this.sharedService.dmsParams.dmsType = localStorage.getItem('dms_type');
    this.sharedService.dmsParams.type = localStorage.getItem('dms_order_type');
    if (this.sharedService.dmsParams.new_mode || !this.sharedService.dmsParams.order_id) {
      this.supplierId = JSON.parse(localStorage.getItem('user')).partner_id;
      this.userData.supplier_id = this.supplierId;
      this.get_beats();
      this.get_users();
      this.productEntry();
    } else {
      this.sharedService.dmsParams.flag = 'new';
      this.orderDetails();
    }
    this.title = 'Invoice';
    this.save = this.saveInvoice;
    this.edit = this.editInvoice;
    this.confirm = this.confirmInvoice;
    this.markDelivery = this.markInvDelivery;
    this.markDispatch = this.markInvDispatch;
    this.cancel = this.cancelInvoice;
    this.markReceive = this.markInvReceive;
    this.transChange = this.transpChange;
    this.typeChange = this.typChange;
    this.discard = this.disCard;
    this.makeReturn = this.returnMake;
    this.custShow = this.show_customers;
    this.confirmSerial = this.confirmserial;
  }

  confirmserial() {

    this.sernos[this.line_id] = this.serl_arr;
  }

  enterSerialNo(data) {

    this.serl_arr = [];
    this.line_id = data.id;
    const exist_sers = this.sernos[this.line_id];
    if (exist_sers && exist_sers.length > 0) {
      this.serl_arr = this.sernos[this.line_id];
    } else {
      for (let i = 0; i < parseInt(data['QTY'], 10); i++) {
        this.serl_arr.push({
          no: null
        });
      }
    }
    const el: HTMLElement = this.serial.nativeElement;
    el.click();
  }

  show_customers() {
    // const el: HTMLElement = this.showCustomer.nativeElement;
    // el.click();
    this.custDisable = false;
  }

  transpChange(event) {
    if (event.length > 0) {
      this.transpID = event[0].id;
      this.selectedTrans = event;
    }
  }

  typChange(event) {
    if (event.length > 0) {
      this.transType = event[0].itemName;
      this.selectedType = event;
    }
  }

  gotoOrder() {
    this.sharedService.dmsParams.goToOrd.active = true;
    this.sharedService.dmsParams.goToOrd.ref = this.orderdetails.sale_ref;
    this.router.navigate([this.apiService.redirect_url + '/orders'],
      {
        relativeTo: this.route,
        queryParams: {type: this.sharedService.dmsParams.dmsType}
      });
  }

  disCard() {
    this.router.navigate([this.apiService.redirect_url + '/order_invoices'],
      {
        relativeTo: this.route,
        queryParams: {type: this.sharedService.dmsParams.dmsType,
          order_type: this.sharedService.dmsParams.type}
      });
  }

  returnMake() {
    this.sharedService.dmsParams.type = 'return';
    this.editInvoice();
  }

  get_users() {
    this.userList =  JSON.parse(localStorage.getItem('dms_users'));
  }

  get_beats() {
    const customers =  JSON.parse(localStorage.getItem('customers_list'));
    const inthis = this;
    if (customers && customers.length > 0) {
      this.rowData1 = customers;
      this.loaded1 = true;
      // this.supplierName = this.rowData1[0].supplier;
      this.customerId = this.rowData1[0].id;
      this.customerName = this.rowData1[0].name;
      this.customerTax = this.rowData1[0].gst_id;
      this.customerMail = this.rowData1[0].email;
      this.customerCode = this.rowData1[0].code;
      this.partnerShippId = this.customerId;
      this.customerAddress = this.rowData1[0].address;
      this.supplierAddress = this.rowData1[0].address;
      this.pricelistId = this.rowData1[0].pricelist_id;
      this.pricelist = this.rowData1[0].pricelist;
      this.taxSource = this.rowData1[0].tax_source;
      this.custSupp = this.rowData1[0].cust_supp;
      if (this.rowData1[0].beat && this.rowData1[0].beat.length > 0) {
        this.beatId = this.rowData1[0].beat[0].bp_id;
        this.beatName = this.rowData1[0].beat[0].bp;
        this.userId = this.rowData1[0].beat[0].sm_id;
        this.userName = this.rowData1[0].beat[0].sm;
      } else {
        this.beatId = null;
        this.beatName = null;
        this.userId = null;
        this.userName = null;
      }
      this.selectedUser = [{id: this.userId, itemName: this.userName}];
      this.addrssList = JSON.parse(localStorage.getItem('cust_addresses')).filter(function(existed) {
        return ((parseInt(existed.parent_id, 10) === parseInt(inthis.customerId, 10)) && (existed.type === 'invoice'));
      });
      this.suppaddrssList = JSON.parse(localStorage.getItem('cust_addresses')).filter(function(existed) {
        return ((parseInt(existed.parent_id, 10) === parseInt(inthis.customerId, 10)) && (existed.type === 'delivery'));
      });
      this.addrssList = [{id: this.customerId, parent_id: this.customerId, itemname: this.customerAddress}].concat(this.addrssList);
      this.addrssSel2 = this.addrssList[0];
      this.suppaddrssList = [{id: this.customerId, parent_id: this.customerId, itemname: this.supplierAddress}].concat(this.suppaddrssList);
      this.addrssSel = this.suppaddrssList[0];

      this.addProduct();
      this.prodShow = true;
      // if (res.results.outlets) {
      //   this.outList = res.results.outlets;
      //   if (this.outList.length > 0) {
      //     this.selectedOut = [this.outList[0]];
      //     this.beatId = this.selectedOut[0].beat_id;
      //     this.beatName = this.selectedOut[0].beat_name;
      //     this.supplierName = this.selectedOut[0].supplier;
      //     this.customerId = this.selectedOut[0].id;
      //     this.customerName = this.selectedOut[0].itemName.split('/')[2];
      //     this.customerTax = this.selectedOut[0].gstn;
      //     this.customerMail = this.selectedOut[0].email;
      //     this.pricelistId = this.selectedOut[0].pricelist_id;
      //     this.pricelist = this.selectedOut[0].pricelist;
      //     this.taxSource = this.selectedOut[0].tcs;
      //     this.addProduct();
      //     this.prodShow = true;
      //   }
      //   // this.outlets = res.results.outlets;
      //   // this.outlets.forEach(outlet => {
      //   //   if (parseInt(outlet.beat_plan_id, 10) === parseInt(this.beatId, 10)) {
      //   //     this.outList = outlet.outlets;
      //   //   }
      //   // });
      // }
      // if (res.results.outlets) { this.outlets = res.results.outlets; }
    } else {
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
      this.toastr.warning('Customer List not Loaded yet.Try Again');
    }
  }

  invShipAddrsChange(event) {
    if (event.id) {
      this.partnerShippId = event.id;
    }
  }

  user_change(event) {
    if (event.length > 0 && event[0].id) {
      this.userName = event[0].itemName;
      this.userId = event[0].id;
    } else {
      this.userName = null;
      this.userId = null;
    }
  }

  beat_change(event) {
    this.outList = [];
    this.selectedOut = [];
    if (event.length > 0) {
      this.selectedBeat = event;
      this.beatId = event[0].id;
      this.beatName = event[0].itemName;
      this.supplierName = event[0].supplier;
      this.outlets.forEach(outlet => {
        if (parseInt(outlet.beat_plan_id, 10) === parseInt(this.beatId, 10)) {
          this.outList = outlet.outlets;
        }
      });
    }
  }

  out_change() {
    const rows = this.gridApi1.getSelectedRows();
    if (rows.length > 0) {
      const inthis = this;
      if (rows[0].beat && rows[0].beat.length > 0) {
        this.beatId = rows[0].beat[0].bp_id;
        this.beatName = rows[0].beat[0].bp;
        this.userId = rows[0].beat[0].sm_id;
        this.userName = rows[0].beat[0].sm;
      } else {
        this.beatId = null;
        this.beatName = null;
        this.userId = null;
        this.userName = null;
      }
      // this.supplierName = rows[0].supplier;
      this.customerId = rows[0].id;
      this.customerName = rows[0].name;
      this.customerTax = rows[0].gst_id;
      this.customerMail = rows[0].email;
      this.customerCode = rows[0].code;
      this.partnerShippId = this.customerId;
      this.customerAddress = rows[0].address;
      this.supplierAddress = rows[0].address;
      this.pricelistId = rows[0].pricelist_id;
      this.pricelist = rows[0].pricelist;
      this.taxSource = rows[0].tax_source;
      this.custDisable = true;
      this.custSupp = rows[0].cust_supp;
      this.selectedUser = [{id: this.userId, itemName: this.userName}];
      this.addrssList = JSON.parse(localStorage.getItem('cust_addresses')).filter(function(existed) {
        return ((parseInt(existed.parent_id, 10) === parseInt(inthis.customerId, 10)) && (existed.type === 'invoice'));
      });
      this.suppaddrssList = JSON.parse(localStorage.getItem('cust_addresses')).filter(function(existed) {
        return ((parseInt(existed.parent_id, 10) === parseInt(inthis.customerId, 10)) && (existed.type === 'delivery'));
      });
      this.addrssList = [{id: this.customerId, parent_id: this.customerId, itemname: this.customerAddress}].concat(this.addrssList);
      this.addrssSel2 = this.addrssList[0];
      this.suppaddrssList = [{id: this.customerId, parent_id: this.customerId, itemname: this.supplierAddress}].concat(this.suppaddrssList);
      this.addrssSel = this.suppaddrssList[0];

      this.addProduct();
    }
  }

  removeImage() {
    this.damagePhoto = null;
    this.returnphotodata = null;
  }

  addProduct() {
    this.userData.supplier_id = this.supplierId;
    if (!this.pricelistId) { this.pricelistId = 0; }
    this.userData.pricelist_id = this.pricelistId;
    this.userData.customer_id = this.customerId;
    this.resData = this.apiService.supplierProducts(this.userData);
    this.resData.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
        this.prodList = res.results.data[0].products;
        this.uomList = res.results.uoms;
        if (this.pricelistId === 0) {
          this.pricelistId = this.prodList[0].pricelist_id;
        }
        this.pricelist = this.prodList[0].pricelist;
        this.supplierName = this.prodList[0].supplier;
        this.supplierTax = this.prodList[0].supplier_tax;
        this.supplierCode = this.prodList[0].supplier_code;
        if (this.sharedService.dmsParams.edit_mode) {
          this.prodShow = true;
          this.productEntry();
          const sampleProd = [];
          this.rowData.filter(obj => {
            if (obj.line_type === 'sample' || obj.line_type === 'promo') {
              sampleProd.push(obj);
            }
            if (obj.scheme) {
              obj.scheme = null;
            }
            if (obj.scheme_discount) {
              obj.TOTAL = (parseFloat(obj.TOTAL) + parseFloat(obj.scheme_discount)).toFixed(this.apiService.decimalPolicy);
              obj.scheme_discount = 0;
            }
          });
          const tempData = this.rowData.filter(val => !sampleProd.includes(val));
          this.gridApi.setRowData(tempData);
        }
      }
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    });
  }

  prod_change(event) {
    this.stock = 0;
    if (event.length > 0 && event[0].id) {
      this.productName = event[0].itemName;
      this.productId = event[0].id;
      this.uomName = event[0].uom;
      this.uomId = event[0].uom_id;
      this.real_stock = event[0].stock;
      this.stock = parseFloat((event[0].stock * event[0].factor).toFixed(2));
      this.tax = event[0].tax;
      this.taxName = event[0].tax_name;
      this.margin = event[0].margin;
      this.taxmasterId = event[0].taxmaster_id;
      this.extraMargin = event[0].extra_margin;
      this.maxMargin = event[0].max_margin;
      this.minMargin = event[0].min_margin;
      this.fixedCost = event[0].fixed_cost;
      this.factorInv = event[0].factor_inv;
      this.factor = event[0].factor;
      this.tradeDisc = 0;
      this.enteredQty = 0;
      this.uoms = [];
      const findUom = this.uomList.filter(existed => this.productId === existed.id);
      if (findUom.length > 0) { this.uoms = findUom[0].uoms; }
      if (event[0].tax_include) {
        this.unitPrice = event[0].unit_price;
        this.salePrice = (this.unitPrice * 100) / (100 + parseFloat(this.tax));
      } else {
        this.salePrice = event[0].unit_price;
        this.unitPrice = this.salePrice * ((100 + parseFloat(this.tax)) / 100);
      }
      this.unitPrice = this.unitPrice * (1 - (this.margin / 100));
      this.salePrice = this.salePrice * (1 - (this.margin / 100));

      //Santosh: change focus on qty field after product selection
      if (this.qtyElement.nativeElement.value === "0") {
        this.qtyElement.nativeElement.value = "";
      }
      this.qtyElement.nativeElement.focus();
    }
  }

  saveProduct() {
    const total = parseFloat(String(this.unitPrice)) * parseFloat(this.enteredQty.toString());
    this.rowData.push({
      product_name: this.productName,
      stock: this.stock,
      product_uom: this.uomName,
      uom_id: this.uomId,
      uoms: this.uoms,
      QTY: this.enteredQty,
      RET: this.enteredQty,
      DMG: 0,
      PRICE: this.salePrice.toFixed(this.apiService.decimalPolicy),
      extra_margin: this.extraMargin,
      trade_disc: this.tradeDisc,
      fixed_cost: this.fixedCost,
      scheme_discount: this.schemeDiscount,
      tax: this.tax,
      tax_name: this.taxName,
      TOTAL: total.toFixed(this.apiService.decimalPolicy),
      product_id: this.productId,
      sale_price: this.salePrice,
      taxmaster_id: this.taxmasterId,
      factor_inv: this.factorInv,
      base_uom_inv: this.factorInv,
      factor: this.factor,
      max_margin: this.maxMargin,
      min_margin: this.minMargin,
      real_stock: this.real_stock,
      real_price: this.salePrice
    });
    if (this.enteredQty > 0) {
      this.saveInvoice('edit');
    } else {
      this.gridApi.setRowData(this.rowData);
    }
    this.enteredQty = 0;
    this.selectedProd = [];
    this.stock = 0;
    this.productSelector.focus();
  }

  productEntry() {
    this.loaded = false;
    this.preloader = true;
    this.rowData = [];

    if (this.sharedService.dmsParams.new_mode) {
      if (this.sharedService.dmsParams.type === 'return') {
        this.columnDefs.filter(obj => {
          if (obj.field === 'QTY') {
            obj.hide = true;
          }
          if (obj.field === 'RET' || obj.field === 'DMG') {
            obj.hide = false;
          }
        });
      }
    } else {
      let edit_status = false;
      this.lineData.forEach(line => {
        edit_status = false;
        const prd = this.prodList.filter(existed => line.prd_id === existed.id);
        if (prd && prd.length > 0) {
          line.stock = prd[0].stock;
          line.cust_stock = prd[0].cust_stock;
        } else {
          line.stock = 0;
          line.cust_stock = 0;
        }

        if (Math.abs(parseFloat(line.qty)) > 0) {
          if (line.line_type === 'sample' || line.line_type === 'promo') {
            line.prd_name = line.prd_name + '(promo)';
            edit_status = true;
          }
          if (this.sharedService.dmsParams.dmsType === 'P') {
            line.stock = line.cust_stock;
          }
          this.uoms = [];
          const findUom = this.uomList.filter(existed => line.prd_id === existed.id);
          if (findUom && findUom.length > 0) {
            this.uoms = findUom[0].uoms;
          }
          this.rowData.push({
            product_name: line.prd_name,
            stock: (parseFloat(line.stock) * parseFloat(line.factor)).toFixed(2),
            uoms: this.uoms,
            product_uom: line.product_uom,
            invoiced_qty: Math.abs(line.qty),
            QTY: Math.abs(line.qty),
            RET: Math.abs(line.qty),
            DMG: Math.abs(line.reusable_qty),
            PRICE: parseFloat(line.price_unit).toFixed(this.apiService.decimalPolicy),
            extra_margin: line.discount,
            trade_disc: line.trade_disc,
            fixed_cost: line.fixed_cost,
            scheme: line.scheme_code,
            scheme_discount: line.scheme_discount,
            tax: line.tax_amount,
            tax_name: line.tax_name,
            TOTAL: Math.abs(Number(parseFloat(line.price_subtotal).toFixed(this.apiService.decimalPolicy))),
            product_id: line.prd_id,
            uom_id: line.uom_id,
            taxmaster_id: line.taxmaster_id,
            line_id: line.line_id,
            line_type: line.line_type,
            factor_inv: line.factor_inv,
            base_uom_inv: line.factor_inv,
            factor: line.factor,
            min_margin: line.min_margin,
            max_margin: line.max_margin,
            real_stock: line.stock,
            real_price: line.real_price,
            edit_status
          });
        }
      });
      this.columnDefs.filter(obj => {
        if (obj.field === 'delete' || obj.field === 'RET' || obj.field === 'DMG') {
          obj.hide = true;
        }
        if (obj.field === 'QTY') {
          obj.hide = false;
        }
      });
      if (this.sharedService.dmsParams.type === 'return') {
        this.columnDefs.filter(obj => {
          if (obj.field === 'RET' || obj.field === 'DMG') {
            obj.hide = false;
          }
        });
      }
      if (this.sharedService.dmsParams.edit_mode) {
        this.columnDefs.filter(obj => {
          if (obj.field === 'delete') {
            obj.hide = false;
          }
        });
        if (this.dmsStatus === 'confirm' && this.sharedService.dmsParams.type === 'return') {
          this.columnDefs.filter(obj => {
            if (obj.field === 'QTY') {
              obj.hide = false;
            }
            if (obj.field === 'RET' || obj.field === 'DMG') {
              obj.hide = false;
            }
          });
        }
        if (this.dmsStatus === 'draft' && this.sharedService.dmsParams.type === 'return') {
          this.columnDefs.filter(obj => {
            if (obj.field === 'QTY') {
              obj.hide = false;
            }
            if (obj.field === 'RET' || obj.field === 'DMG') {
              obj.hide = false;
            }
          });
        }
      }
      if (this.gridApi) {
        this.gridApi.setRowData(this.rowData);
        this.gridApi.setColumnDefs(this.columnDefs);
        this.gridApi.sizeColumnsToFit();
        this.preloader = false;
      }
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    }
    this.loaded = true;
  }

  // updateOrd() {
  //   this.createInvoice(this.invoiceOrder);
  //   this.updatedInvoice = false;
  //   this.prodShow = false;
  // }

  saveInvoice(key) {
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    this.sharedService.fetch_periodID(this.poDate);
    const period_id = localStorage.getItem('period_id');
    const lineData = [];
    this.orderdetails = {
      amount_total: 0,
      amount_tax: 0,
      amount_untaxed: 0,
      total: 0,
      tcs: 0,
      line_discount: 0,
      spcl_discount: 0,
      charges: 0
    };
    const sampleProd = [];
    this.rowData.filter(obj => {
      if (obj.line_type === 'sample' || obj.line_type === 'promo') {
        if (this.dmsStatus !== 'confirm') {
          sampleProd.push(obj);
        }
      }
    });
    this.rowData = this.rowData.filter(val => !sampleProd.includes(val));
    const prodList = this.prodList;
    this.rowData.forEach(line => {
      if (this.dmsStatus !== 'confirm' || ((line.line_type === 'sample' || line.line_type === 'promo')) ||
        (this.dmsStatus === 'confirm' && parseFloat(line.QTY) <= parseFloat(line.prvs_qty))) {
        if ((line.line_type === 'sample' || line.line_type === 'promo') ||
          (parseFloat(line.min_margin) === 0 && parseFloat(line.max_margin) === 0) ||
          (parseFloat(line.extra_margin) >= parseFloat(line.min_margin) && parseFloat(line.extra_margin) <= parseFloat(line.max_margin))) {
          if (!this.priceEdited) {
            line.PRICE = (parseFloat(line.real_price) * parseFloat(line.factor_inv)) / parseFloat(line.base_uom_inv);
          }
          if (this.sharedService.dmsParams.type === 'return' && line.RET) {
            line.QTY = line.DMG;
          }
          let priceSubtotal = parseFloat(line.QTY) * parseFloat(line.PRICE);
          priceSubtotal = priceSubtotal - Math.abs(line.fixed_cost);
          priceSubtotal = priceSubtotal * (1 - (parseFloat(line.extra_margin) / 100));
          priceSubtotal = priceSubtotal - (parseFloat(line.trade_disc) * parseFloat(line.QTY));
          const prodUoms = this.uomList.filter(existed => line.product_id === existed.id);
          line.TOTAL = priceSubtotal;
          const productLine = {
            product_name: line.product_name,
            stock: line.stock,
            product_id: parseInt(line.product_id, 10),
            prod_uoms: prodUoms[0].uoms,
            qty: line.RET,
            dmg: line.DMG,
            scheme_qty: parseFloat(line.RET),
            price_subtotal: priceSubtotal,
            price_unit: line.PRICE,
            new: false,
            product_uom: line.uom_id,
            product_uom_name: line.product_uom,
            foc: false,
            foc_product_id: null,
            scheme_id: null,
            scheme_discount: line.scheme_discount,
            taxmaster_id: line.taxmaster_id,
            fixed_cost: line.fixed_cost,
            trade_disc: line.trade_disc,
            extra_margin: line.extra_margin,
            schemeCode: undefined,
            schemeUsed: undefined,
            type: 'normal',
            real_price: line.real_price
          };
          line.scheme_id = null;
          line.stock = (parseFloat(line.real_stock) * parseFloat(line.factor)).toFixed(2);
          line.scheme = '';
          if (this.sharedService.dmsParams.type === 'return') {
            productLine.type = 'return';
          }
          lineData.push(productLine);
        } else {
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
          this.toastr.warning('Entered Discount is Out of range' + line.product_name);
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
        }
      } else {
        this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
        this.toastr.warning('Entered QTY is wrong ' + line.product_name);
        this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
      }
    });
    const order = {
      order_id: null,
      partner_id: this.customerId,
      partner_shipping_id: this.partnerShippId,
      user_id: this.userId,
      distributor_id: this.supplierId,
      pricelist_id: this.pricelistId,
      beat_plan_id: this.beatId,
      date_order: this.poDate,
      order_type: 'sale',
      name: 'O_' + this.supplierId + '_' + Date.now(),
      po: this.po_number,
      line_data: lineData,
      dms_order: true,
      discount: this.orderDiscount,
      scheme_type: 'S',
      period_id
    };
    if (this.custSupp) { order.scheme_type = 'P'; }
    if (lineData.length > 0) {
      const orderData = {
        access_token: this.userData.access_token,
        url: this.userData.url,
        orders: [order],
        distributor_id: this.supplierId
      };
      const inthis = this;
      const rowData = this.rowData;
      if (this.sharedService.dmsParams.type !== 'return') {

        this.resData = this.apiService.generateInvoice(orderData);

        this.resData.subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.statusCode === 200)) {
            const freeOrders = res.results.orders;
            freeOrders.forEach(resOrder => {
              resOrder.lines.forEach(function(schemes) {
                schemes.lines.forEach(schemeLine => {
                  const product_line = lineData.filter(obj => {
                    return (obj.product_id === schemeLine.product_id);
                  });
                  const row_line = rowData.filter(obj => {
                    return (obj.product_id === schemeLine.product_id);
                  });
                  product_line[0].schemeCode = schemeLine.scheme_code;
                  product_line[0].scheme_id = schemeLine.scheme_id;
                  row_line[0].scheme = schemeLine.scheme_name;
                  row_line[0].scheme_id = schemeLine.scheme_id;
                  if (parseFloat(schemeLine.scheme_discount) > 0) {
                    product_line[0].scheme_discount = schemeLine.scheme_discount;
                    product_line[0].price_subtotal = parseFloat(product_line[0].price_subtotal) - parseFloat(schemeLine.scheme_discount);
                    row_line[0].scheme_discount = schemeLine.scheme_discount;
                    row_line[0].TOTAL = product_line[0].price_subtotal;
                  }
                });
                let stock = 0;
                schemes.free.forEach(freeLine => {
                  const prodData = prodList.filter(obj => {
                    return (obj.id === freeLine.product_id);
                  });
                  let uomData = inthis.uomList.filter(existed => freeLine.product_id === existed.id);
                  uomData =  uomData[0].uoms.filter(obj => {
                    return (freeLine.product_uom === obj.id);
                  });
                  const prodFact = uomData[0].factor;
                  stock = 0;
                  if (inthis.sharedService.dmsParams.dmsType === 'P') {
                    stock = parseFloat((prodData[0].cust_stock * prodFact).toFixed(2));
                  } else {
                    stock = parseFloat((prodData[0].stock * prodFact).toFixed(2));
                  }
                  lineData.push({
                    product_id: parseInt(freeLine.product_id, 10),
                    qty: freeLine.qty,
                    price_subtotal: 0,
                    price_tax: 0,
                    price_total: 0,
                    tcs: 0,
                    price_unit: freeLine.price_unit,
                    new: false,
                    product_uom: freeLine.product_uom,
                    foc: false,
                    foc_product_id: null,
                    scheme_id: freeLine.scheme_id,
                    scheme_discount: 0,
                    taxmaster_id: prodData[0].taxmaster_id,
                    fixed_cost: 0,
                    trade_disc: 0,
                    extra_margin: 0,
                    schemeCode: undefined,
                    schemeUsed: undefined,
                    type: 'promo',
                    stock
                  });
                  rowData.push({
                    product_name: prodData[0].itemName + '(promo)',
                    stock,
                    product_id: parseInt(freeLine.product_id, 10),
                    QTY: freeLine.qty,
                    price_subtotal: 0,
                    PRICE: 0,
                    TOTAL: 0,
                    new: false,
                    uom_id: prodData[0].uom_id,
                    product_uom: prodData[0].uom,
                    scheme_id: freeLine.scheme_id,
                    scheme_discount: freeLine.scheme_discount,
                    taxmaster_id: prodData[0].taxmaster_id,
                    tax_name: prodData[0].tax_name,
                    fixed_cost: 0,
                    trade_disc: 0,
                    extra_margin: 0,
                    schemeCode: freeLine.schemeCode,
                    line_type: 'promo',
                    edit_status: true
                  });
                });
              });
            });
            order.line_data = lineData;
            orderData.orders = [order];
            if (key === 'edit') {
              let updatedLine;
              this.rowData.forEach(line => {
                if (line.line_type !== 'sample' && line.line_type !== 'promo') {
                  if (!this.priceEdited) {
                    line.PRICE = (parseFloat(line.real_price) * parseFloat(line.factor_inv)) / parseFloat(line.base_uom_inv);
                  }
                  let priceSubtotal = parseFloat(line.QTY) * parseFloat(line.PRICE);
                  const linediscount = (priceSubtotal * parseFloat(line.extra_margin)) / 100;
                  priceSubtotal = priceSubtotal - Math.abs(line.fixed_cost);
                  priceSubtotal = priceSubtotal * (1 - (parseFloat(line.extra_margin) / 100));
                  priceSubtotal = priceSubtotal - (parseFloat(line.trade_disc) * parseFloat(line.QTY));
                  priceSubtotal = priceSubtotal - parseFloat(line.scheme_discount);
                  line.PRICE = parseFloat(line.PRICE).toFixed(this.apiService.decimalPolicy);
                  line.TOTAL = priceSubtotal.toFixed(this.apiService.decimalPolicy);
                  const priceTax = (priceSubtotal * parseFloat(line.tax)) / 100;
                  const priceTotal = priceSubtotal + priceTax;
                  const tcs = priceTotal * (parseFloat(this.taxSource) / 100);
                  const total = priceTotal + tcs;
                  const spcldiscount = (parseFloat(line.QTY) * parseFloat(line.trade_disc));
                  this.orderdetails.amount_tax += priceTax;
                  this.orderdetails.amount_total += priceTotal;
                  this.orderdetails.amount_untaxed += priceSubtotal;
                  this.orderdetails.tcs += tcs;
                  this.orderdetails.total += total;
                  this.orderdetails.line_discount += linediscount;
                  this.orderdetails.spcl_discount += spcldiscount;
                  this.orderdetails.charges += Math.abs(line.fixed_cost);
                }
              });
              this.orderdetails.amount_tax = this.orderdetails.amount_tax.toFixed(this.apiService.decimalPolicy);
              this.orderdetails.amount_total = this.orderdetails.amount_total.toFixed(this.apiService.decimalPolicy);
              this.orderdetails.amount_untaxed = this.orderdetails.amount_untaxed.toFixed(this.apiService.decimalPolicy);
              this.orderdetails.tcs = this.orderdetails.tcs.toFixed(this.apiService.decimalPolicy);
              this.orderdetails.total = this.orderdetails.total.toFixed(this.apiService.decimalPolicy);
              this.orderdetails.total = (this.orderdetails.total - this.orderDiscount).toFixed(this.apiService.decimalPolicy);
              this.orderdetails.line_discount = Math.abs(this.orderdetails.line_discount).toFixed(this.apiService.decimalPolicy);
              this.orderdetails.spcl_discount = Math.abs(this.orderdetails.spcl_discount).toFixed(this.apiService.decimalPolicy);
              this.orderdetails.charges = this.orderdetails.charges.toFixed(this.apiService.decimalPolicy);
              this.rowData = rowData;
              const inthis = this;
              this.gridApi.setRowData(rowData);
              sampleProd.forEach(function(line) {
                updatedLine = rowData.filter(function(existed) {
                  return ((parseInt(line.product_id, 10) === parseInt(existed.product_id, 10)) &&
                    (line.line_type === existed.line_type));
                });
                if (updatedLine.length === 0) {
                  rowData.push({
                    product_name: line.product_name,
                    stock: (parseFloat(line.stock) * parseFloat(line.factor)).toFixed(0),
                    uoms: line.uoms,
                    product_uom: line.product_uom,
                    QTY: line.QTY,
                    PRICE: parseFloat(line.PRICE).toFixed(inthis.apiService.decimalPolicy),
                    extra_margin: line.extra_margin,
                    trade_disc: line.trade_disc,
                    fixed_cost: line.fixed_cost,
                    scheme: line.scheme,
                    scheme_discount: line.scheme_discount,
                    tax: line.tax,
                    tax_name: line.tax_name,
                    TOTAL: 0,
                    product_id: line.product_id,
                    uom_id: line.uom_id,
                    taxmaster_id: line.taxmaster_id,
                    line_id: line.line_id,
                    line_type: line.line_type,
                    factor_inv: line.factor_inv,
                    base_uom_inv: line.factor_inv,
                    factor: line.factor,
                    min_margin: line.min_margin,
                    max_margin: line.max_margin,
                    real_stock: line.real_stock
                  });
                }
              });
              this.rowData = rowData;
              this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
            } else {
              let updatedLine;
              sampleProd.forEach(function(line) {
                updatedLine = order.line_data.filter(function(existed)  {
                  return ((parseInt(line.product_id, 10) === parseInt(existed.product_id, 10)) &&
                    (line.line_type === existed.type));
                });
                if (updatedLine.length === 0) {
                  order.line_data.push({
                    product_name: line.product_name,
                    stock: line.stock,
                    product_id: parseInt(line.product_id, 10),
                    qty: 0,
                    price_subtotal: 0,
                    price_unit: line.PRICE,
                    new: false,
                    product_uom: line.uom_id,
                    product_uom_name: line.product_uom,
                    foc: false,
                    foc_product_id: null,
                    scheme_id: null,
                    scheme_discount: 0,
                    taxmaster_id: line.taxmaster_id,
                    fixed_cost: line.fixed_cost,
                    trade_disc: line.trade_disc,
                    extra_margin: line.extra_margin,
                    schemeCode: undefined,
                    schemeUsed: undefined,
                    type: 'promo'
                  });
                }
              });
              this.priceEdited = false;
              this.invoiceOrder = order;
              this.createInvoice(this.invoiceOrder);
              this.removedRows = [];
            }
          } else {
            this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
            this.toastr.error('Scheme Fetch failed');
          }
        }, error => {
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
          console.log(error);
          if (error.error && error.error.error === undefined) {
            this.toastr.error('Scheme Fetch failed');
          } else {
            this.toastr.error('Scheme Fetch failed: ' + error.error.error.message);
          }
        });
      } else {
        if (key === 'edit') {
          this.rowData.forEach(line => {
            if (line.line_type !== 'sample' || line.line_type === 'promo') {
              if (!this.priceEdited) {
                line.PRICE = (parseFloat(line.real_price) * parseFloat(line.factor_inv)) / parseFloat(line.base_uom_inv);
              }
              let priceSubtotal = parseFloat(line.QTY) * parseFloat(line.PRICE);
              priceSubtotal = priceSubtotal - Math.abs(line.fixed_cost);
              priceSubtotal = priceSubtotal * (1 - (parseFloat(line.extra_margin) / 100));
              priceSubtotal = priceSubtotal - (parseFloat(line.trade_disc) * parseFloat(line.QTY));
              priceSubtotal = priceSubtotal - parseFloat(line.scheme_discount);
              line.TOTAL = priceSubtotal;
              const priceTax = (priceSubtotal * parseFloat(line.tax)) / 100;
              const priceTotal = priceSubtotal + priceTax;
              const tcs = priceTotal * (parseFloat(this.taxSource) / 100);
              const total = priceTotal + tcs;
              const linediscount = (priceSubtotal * parseFloat(line.extra_margin)) / 100;
              const spcldiscount = (parseFloat(line.QTY) * parseFloat(line.trade_disc));
              this.orderdetails.amount_tax += priceTax;
              this.orderdetails.amount_total += priceTotal;
              this.orderdetails.amount_untaxed += priceSubtotal;
              this.orderdetails.tcs += tcs;
              this.orderdetails.total += total;
              this.orderdetails.line_discount += linediscount;
              this.orderdetails.spcl_discount += spcldiscount;
              this.orderdetails.charges += Math.abs(line.fixed_cost);
              if (line.invoiced_qty) { line.QTY = line.invoiced_qty; }
            }
          });
          this.orderdetails.amount_tax = this.orderdetails.amount_tax.toFixed(this.apiService.decimalPolicy);
          this.orderdetails.amount_total = this.orderdetails.amount_total.toFixed(this.apiService.decimalPolicy);
          this.orderdetails.amount_untaxed = this.orderdetails.amount_untaxed.toFixed(this.apiService.decimalPolicy);
          this.orderdetails.tcs = this.orderdetails.tcs.toFixed(this.apiService.decimalPolicy);
          this.orderdetails.total = this.orderdetails.total.toFixed(this.apiService.decimalPolicy);
          this.orderdetails.line_discount = this.orderdetails.line_discount.toFixed(this.apiService.decimalPolicy);
          this.orderdetails.spcl_discount = this.orderdetails.spcl_discount.toFixed(this.apiService.decimalPolicy);
          this.orderdetails.charges = this.orderdetails.charges.toFixed(this.apiService.decimalPolicy);
          this.rowData = rowData;
          this.gridApi.setRowData(rowData);
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
        } else {
          this.invoiceOrder = order;
          this.priceEdited = false;
          this.createInvoice(this.invoiceOrder);
        }
      }
    } else {
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
      this.toastr.warning('No Valid entry to create ');
      this.prodShow = true;
    }
  }

  createInvoice(invOrder) {
    const invDates = this.poDate.split('/');
    let invDate;
    const dmg_lines = [];
    if (invDates.length > 1) {
      invDate = invDates[2] + '-' + invDates[1] + '-' + invDates[0];
    } else {
      invDate = invDates[0];
    }
    const serialnos = this.sernos;
    const orderData = {
      access_token: this.userData.access_token,
      url: this.userData.url,
      orders: [],
      upload: false
    };
    const order = {
      order_id: this.orderID,
      order_type: 'sale',
      type: 'out_invoice',
      sale_ref: this.orderNo,
      invoice_no: this.invoiceNo,
      invoice_ref: this.orderNo,
      po: this.po_number,
      date_invoice: invDate,
      pricelist_id: this.pricelistId,
      partner_id: this.customerId,
      user_id: this.userId,
      partner_shipping_id: this.partnerShippId,
      supplier_id: this.supplierId,
      territory_id: null,
      supplier_territory_id: null,
      state_id: null,
      region_id: null,
      zone_id: null,
      area_id: null,
      company_id: null,
      invoice_type: 'S',
      scheme_id: null,
      scheme_discount: 0,
      discount: this.orderDiscount,
      comment: this.comments,
      beat_id: parseInt(this.beatId, 10),
      team_id: null,
      amount_total: 0,
      amount_tax: 0,
      amount_untaxed: 0,
      tax_source: 0,
      inv_items: [],
      state: 'confirm',
      period_id: invOrder.period_id,
      return_image_data: null
    };
    if (this.dmsConfirm) { order.state = 'draft'; }
    if (this.custSupp) { order.invoice_type = 'P'; }
    if (this.sharedService.dmsParams.type === 'return') {
      order.invoice_ref = this.invoiceNo;
      order.type = 'out_refund';
      order.invoice_no = this.retInvoiceNo;
      order.state = 'draft';

      if (this.returnphotodata) {
        const imageObject: any = {};
        imageObject.datas = this.returnphotodata;
        imageObject.store_fname = 'Return_image_' + invDate;
        imageObject.name = imageObject.store_fname;
        imageObject.old_path = this.old_path;
        order.return_image_data = imageObject;
      } else {
        order.return_image_data = null;
      }
    }
    const prod_stock_check = {};
    invOrder.line_data.forEach(line => {
      if (prod_stock_check[line.product_id]) {
        prod_stock_check[line.product_id] += parseFloat(line.qty);
      } else {
        prod_stock_check[line.product_id] = parseFloat(line.qty);
      }
      if ((order.type === 'out_refund' && (parseInt(line.qty, 10) + parseInt(line.dmg, 10)) !== 0) ||
        (order.type === 'out_invoice' && (!this.sharedService.dmsParams.new_mode || (line.type === 'promo' ||
            parseInt(line.qty, 10)  > 0)) &&
          (!this.stockCheck || parseInt(line.stock, 10)  >= prod_stock_check[line.product_id]))) {
        if (order.type === 'out_refund') {
          line.qty = -1 * line.qty;
          line.price_subtotal = -1 * line.price_subtotal;
          line.type = 'return';

          // checking damage qty is there or not
          /* if (parseFloat(line.dmg) > 0) {
             dmg_lines.push({
               product_id: line.product_id,
               quantity: line.dmg,
               uom_id: parseInt(line.product_uom, 10),
               price_unit: line.price_unit,
               taxmaster_id: line.taxmaster_id,
               discount: 0,
               scheme_id: null,
               scheme_discount: 0,
               trade_disc: 0,
               fixed_cost: 0,
               price_subtotal: 0,
               tax_source: 0,
               type: 'damage'
             });
           }*/
        }
        if (parseInt(line.qty, 10) !== 0) {
          const lineData = {
            product_id: line.product_id,
            quantity: line.qty,
            reusable_qty: line.dmg,
            uom_id: parseInt(line.product_uom, 10),
            price_unit: line.price_unit,
            taxmaster_id: line.taxmaster_id,
            discount: line.extra_margin,
            scheme_id: line.scheme_id,
            scheme_discount: line.scheme_discount,
            trade_disc: parseFloat(line.trade_disc),
            fixed_cost: line.fixed_cost,
            price_subtotal: line.price_subtotal,
            tax_source: line.tcs,
            type: line.type
          };
          if (serialnos[line.id]) {
            lineData['serials'] = serialnos[line.id];
            // serialnos[line.id].forEach(function (serial) {
            //   line_data.serials.push({
            //     "serial_no": serial
            //   })
            // });
          }
          order.inv_items.push(lineData);
        }
      } else {
        order.inv_items = [];
        this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
        this.toastr.warning('Product QTY/Stock is zero:- ' + line.product_name);
      }
    });
    /* if (dmg_lines.length > 0) {
       const dmg_order = JSON.parse(JSON.stringify(order));
       dmg_order.inv_items = dmg_lines;
       dmg_order.type = 'out_damage';
       orderData.orders.push(dmg_order);
     }*/
    if (order.inv_items.length > 0) {
      orderData.orders.push(order);
    }
    if (orderData.orders.length > 0) {

      this.resData = this.apiService.createInvoice(orderData);
      this.resData.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.statusCode === 200)) {
          this.toastr.success(res.results.msg);
          this.prodShow = false;
          if (orderData.orders.length === 2) {
            this.sharedService.setDmsParams();
            this.sharedService.dmsParams.goToInv.active = true;
            this.sharedService.dmsParams.goToInv.ref = this.orderNo;
            this.router.navigate([this.apiService.redirect_url + '/order_invoices'],
              {
                relativeTo: this.route,
                queryParams: {type: this.sharedService.dmsParams.dmsType, order_type: 'return'}
              });
          } else {
            if (order.type === 'out_refund') {
              if (this.sharedService.dmsParams.dmsType === 'S') {
                this.appComp.header_title = 'Sale Return';
              } else {
                this.appComp.header_title = 'Purchase Return';
              }
            }
            this.sharedService.setDmsParams();
            this.sharedService.dmsParams.order_id = res.results.invoice_id;
            this.orderDetails();
          }
        } else {
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
          if (res.results.msg) {
            this.toastr.error(res.results.msg);
          } else {
            this.toastr.error('Creation failed');
          }
        }
      }, error => {
        this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
        console.log(error);
        if (error.error && error.error.error === undefined) {
          this.toastr.error('Creation failed');
        } else {
          this.toastr.error('Creation failed: ' + error.error.error.message);
        }
      });
    } else {
      this.toastr.warning('No Valid entry to create ');
    }
  }

  editInvoice() {
    debugger;
    this.prodShow = true;
    this.sharedService.dmsParams.edit_mode = true;
    if (this.dmsStatus === 'draft') {
      this.addProduct();
      // if (!this.prodList || this.prodList.length === 0) {
      //   this.addProduct();
      // } else {
      //   this.prodShow = true;
      //   const sampleProd = [];
      //   this.rowData.filter(obj => {
      //     if (obj.line_type === 'sample' || obj.line_type === 'promo') {
      //       sampleProd.push(obj);
      //     }
      //     if (obj.scheme) {
      //       obj.scheme = null;
      //     }
      //     if (obj.scheme_discount) {
      //       obj.TOTAL = (parseFloat(obj.TOTAL) + parseFloat(obj.scheme_discount)).toFixed(this.apiService.decimalPolicy);
      //       obj.scheme_discount = 0;
      //     }
      //   });
      //   let tempData = this.rowData.filter(val => !sampleProd.includes(val));
      //   this.gridApi.setRowData(tempData);
      // }
      this.orderdetails = null;
    } else if (this.dmsStatus === 'confirm') {
      this.poDate = this.myDate;
      this.productEntry();
      const sampleProd = [];
      this.rowData.filter(obj => {
        if (obj.line_type === 'sample' || obj.line_type === 'promo') {
          sampleProd.push(obj);
        } else {
          obj.prvs_qty = obj.QTY;
        }
        if (obj.scheme) {
          obj.edit_status = true;
          obj.scheme = null;
        }
      });
      // let tempData = this.rowData.filter(val => !sampleProd.includes(val));
      this.gridApi.setRowData(this.rowData);
    }
    // if (this.dmsStatus === 'confirm') {
    //   this.productEntry();
    // } else {
    //   this.gridApi.setRowData(this.rowData);
    // }
  }

  confirmInvoice() {
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    const orderData = {
      access_token: this.userData.access_token,
      url: this.userData.url,
      order_id: this.sharedService.dmsParams.order_id,
      type: 'invoice',
      inv: {
        id: this.sharedService.dmsParams.order_id,
        total: this.orderdetails.total,
        partner_id: this.customerId,
        user_id: this.userId,
        type: this.orderdetails.type
      }
    };

    this.resData = this.apiService.confirmDmsOrder(orderData);

    this.resData.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res.results.statusCode === 200)) {
        this.toastr.success('Confirmed Succesfully');
        this.sharedService.setDmsParams();
        this.sharedService.dmsParams.order_id = res.results.order_id;
        this.orderDetails();
      } else {
        this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
        this.toastr.error(res.results.msg);
      }
    }, error => {
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
      console.log(error);
    });
  }

  async markInvReceive() {
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    const data = {
      territory_id: this.territoryId,
      customer_id: this.customerId,
      invoice_id: this.sharedService.dmsParams.order_id,
      order_id: this.orderID,
      stock_data: [],
      stock_log_data: []
    };

    const orderData = {
      access_token: this.userData.access_token,
      url: this.userData.url,
      order: data
    };

    const resp = await this.apiService.markReceiveDmsAll(orderData);
    if (resp.hasOwnProperty('results') && resp['results'].failed_invoices.length === 0) {
      this.toastr.success('Succesfully Received');
      this.sharedService.setDmsParams();
      this.sharedService.dmsParams.order_id = resp['results'].invoice_id;
      this.sharedService.dmsParams.flag = 'same';
      this.orderDetails();
    } else {
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
      this.toastr.error(resp['results'].msg);
    }
  }

  async markInvDispatch() {
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    const data = {
      territory_id: this.territoryId,
      customer_id: this.customerId,
      supplier_territory_id: this.supplierTerritory,
      distributor_id: this.supplierId,
      invoice_id: this.sharedService.dmsParams.order_id,
      order_id: this.orderID,
      tracking_ref: this.trackRef,
      transporter_id: this.transpID , // Fetch From res.transporter table
      transport_type: this.transType,
      no_of_bundle: this.bundle,
      weight: this.weight,
      comments: 'PWA',
      stock_data: [],
      stock_log_data: [],
      feature_primary_confirm: this.featurePrimaryConfirm,
      feature_primary_dispatch: this.featurePrimaryDispatch,
      feature_receive: this.featureReceive,
      type: this.orderdetails.type,
      id: this.sharedService.dmsParams.order_id,
      total: this.orderdetails.total,
      user_id: this.userId,
      partner_id: this.customerId
    };

    const orderData = {
      access_token: this.userData.access_token,
      url: this.userData.url,
      order: data
    };

    const resp = await this.apiService.markDispatchDmsAll(orderData);
    if (resp.hasOwnProperty('results') && resp['results'].failed_invoices.length === 0) {
      this.toastr.success('Successfully Dispatched');
      this.sharedService.setDmsParams();
      this.sharedService.dmsParams.order_id = resp['results'].invoice_id;
      this.sharedService.dmsParams.flag = 'same';
      this.orderDetails();
    } else {
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
      this.toastr.error('Failed -' + resp['results'].msg);
    }
  }

  markConfirmRefund() {
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    const data = {
      dist_territory_id: this.supplierTerritory,
      distributor_id: this.supplierId,
      supplier_id: this.supplierId,
      customer_id: this.customerId,
      partner_id: this.customerId,
      user_id: this.userId,
      credit_amt: this.orderdetails['total'],
      cust_territory_id: this.territoryId,
      invoice_id: this.sharedService.dmsParams.order_id,
      invoice_type: this.orderdetails.invoice_type,
      type: this.orderdetails.type,
      order_id: this.orderID,
      stock_data: [],
      stock_log_data: []
    };
    let retqty = 0;
    this.rowData.forEach(line => {
      if (this.featureReuse) {
        retqty = parseFloat(line.DMG) * parseFloat(line.factor_inv);
      } else {
        retqty = parseFloat(line.RET) * parseFloat(line.factor_inv);
      }
      const pushData = {
        invoice_line_id: line.line_id,
        product_id: line.product_id,
        uom_id: line.uom_id,
        quantity: retqty,
        line_type: line.line_type
      };
      data.stock_log_data.push(pushData);
      const updated_order_line = data.stock_data.filter(function(existed) {
        return parseInt(line.product_id, 10) === parseInt(existed.product_id, 10);
      });
      if (updated_order_line.length > 0) {
        updated_order_line[0].quantity = parseInt(updated_order_line[0].quantity, 10) + parseInt(line.QTY, 10);
      } else {
        if (this.featureReuse) {
          retqty = parseFloat(line.DMG) * parseFloat(line.factor_inv);
        } else {
          retqty = parseFloat(line.RET) * parseFloat(line.factor_inv);
        }
        const pushData1 = {
          invoice_line_id: line.line_id,
          product_id: line.product_id,
          uom_id: line.uom_id,
          quantity: retqty,
          line_type: line.line_type
        };
        data.stock_data.push(pushData1);
      }
    });

    const orderData = {
      access_token: this.userData.access_token,
      url: this.userData.url,
      datas: data
    };

    this.resData = this.apiService.markConfirmDmsRefund(orderData);
    this.resData.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res.results.status === 200)) {
        this.toastr.success('Succesfully Confirmed');
        this.sharedService.setDmsParams();
        this.sharedService.dmsParams.order_id = res.results.invoice_id;
        this.sharedService.dmsParams.flag = 'same';
        this.orderDetails();
      } else {
        this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
        this.toastr.error('Failed:-' + res.results.msg);
      }
    }, error => {
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
      console.log(error);
      this.toastr.error('Failed');
    });
  }

  async markInvDelivery(proc) {
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    const data = {
      supplier_territory_id: this.supplierTerritory,
      distributor_id: this.supplierId,
      invoice_id: this.sharedService.dmsParams.order_id,
      order_id: this.orderID,
      stock_data: [],
      stock_log_data: [],
      feature_confirm: this.featureConfirm,
      feature_dispatch: this.featureDispatch,
      feature_delivery: this.featureDelivery,
      stock_added: false,
      label: proc,
      type: this.orderdetails.type,
      id: this.sharedService.dmsParams.order_id,
      total: this.orderdetails.total,
      user_id: this.userId,
      partner_id: this.customerId
    };
    const orderData = {
      access_token: this.userData.access_token,
      url: this.userData.url,
      order: data
    };

    const resp = await this.apiService.markDeliveryDmsAll(orderData);
    if (resp.hasOwnProperty('results') && resp['results'].failed_invoices.length === 0) {
      if (this.featureDispatch && proc === 'mark_dispatch' ) {
        this.toastr.success('Successfully Confirmed');
      } else {
        this.toastr.success('Successfully Delivered');
      }
      this.sharedService.setDmsParams();
      this.sharedService.dmsParams.order_id = resp['results'].invoice_id;
      this.sharedService.dmsParams.flag = 'same';
      this.orderDetails();
    } else {
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
      this.toastr.error('Failed:-' + resp['results'].msg);
    }
  }

  cancelInvoice() {
    debugger;
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    const data = {
      invoice_id: this.sharedService.dmsParams.order_id,
      order_id: this.orderID,
      product_data: []
    };
    if (!this.orderID) { data.order_id = 0; }
    this.rowData.forEach(line => {
      const pushData = {
        product_id: line.product_id,
        quantity: line.QTY,
        type: line.line_type
      };
      data.product_data.push(pushData);
    });

    const orderData = {
      access_token: this.userData.access_token,
      url: this.userData.url,
      orders: data
    };

    this.resData = this.apiService.markDmsCancel(orderData);
    this.resData.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res.results.status === 200)) {
        this.toastr.success('Succesfully Cancelled');
        this.sharedService.setDmsParams();
        this.sharedService.dmsParams.order_id = res.results.id;
        this.orderDetails();
      } else {
        this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
        this.toastr.error(res.results.msg);
      }
    }, error => {
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
      console.log(error);
      this.toastr.error('Failed');
    });
  }

  orderDetails() {
    const orderData = {
      access_token: this.userData.access_token,
      url: this.userData.url,
      inv_id: this.sharedService.dmsParams.order_id,
      supplier_id: null,
      customer_id: null
    };
    this.API_URL = '/api/dms_connects/get_invoice_details';
    this.apiService.post(this.API_URL, orderData)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
          const data = res.results.data[0];
          this.orderdetails = res.results.data[0];
          // this.uomList = this.orderdetails.uoms;
          this.orderNo = this.orderdetails.sale_ref;
          this.po_number = this.orderdetails.po;
          this.poDate = this.datePipe.transform(this.orderdetails.inv_date, 'dd/MM/yyyy');
          this.displayInvDate = this.poDate;
          if (this.orderdetails.ord_date) {
            this.displayOrdDate = this.datePipe.transform(this.orderdetails.ord_date, 'dd/MM/yyyy');
          }
          this.supplierName = this.orderdetails.supp_name;
          this.dStatus = this.orderdetails.d_status;
          this.beatName = this.orderdetails.beat_name;
          this.customerId = this.orderdetails.cust_id;
          this.territoryId = this.orderdetails.territory_id;
          this.supplierId = this.orderdetails.supp_id;
          this.supplierTerritory = this.orderdetails.supplier_territory_id;
          this.orderID = this.orderdetails.order_id,
            this.customerName = this.orderdetails.cust_name;
          this.customerTax = this.orderdetails.cust_gstn;
          this.customerMail = this.orderdetails.cust_mail;
          this.customerAddress = this.orderdetails.address;
          this.supplierAddress = this.orderdetails.address;
          this.partnerShippId = this.orderdetails.partner_shipping_id;
          if (this.orderdetails.partner_shipping_id !== this.customerId) {
            this.supplierAddress = this.orderdetails.shp_address;
          }
          this.supplierTax = this.orderdetails.supp_tax;
          this.supplierCode = this.orderdetails.supp_code;
          this.customerCode = this.orderdetails.cust_code;
          this.taxSource = this.orderdetails.tax_source;
          this.invoiceNo = this.orderdetails.inv_no;
          this.pricelistId = this.orderdetails.pricelist_id;
          this.orderDiscount = parseFloat(this.orderdetails.discount);
          this.custSupp = this.orderdetails.customer_supplier;
          this.userName = this.orderdetails.sman;
          this.userId = this.orderdetails.sman_id;
          this.beatId = this.orderdetails.beat_id;
          this.comments = this.orderdetails.notes;
          this.old_path = this.orderdetails.return_store_fname;
          this.retInvoiceNo = null;
          if (this.orderdetails.type === 'out_refund') {
            this.retInvoiceNo = this.invoiceNo;
          }
          if (this.orderdetails.return_store_fname) {
            const orderData1 = {
              access_token: this.userData.access_token,
              data: [{store_fname: this.orderdetails.return_store_fname, container: 'account_invoice'}]
            };
            this.API_URL = '/api/pwa_connects/fetch_image';
            this.apiService.post(this.API_URL, orderData1)
              .subscribe(res1 => {
                if (res1.hasOwnProperty('result') && res1.result.length > 0) {
                  this.damagePhoto = this.domSanitizer.bypassSecurityTrustUrl("data:image/png;base64, " + res1['result'][0].bill_datas);
                } else {
                  this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
                  this.toastr.error(res.results.msg);
                }
              });
          }
          let linediscount = 0;
          let spcldiscount = 0;
          if (this.orderdetails.ret && this.orderdetails.ret.length > 0) { this.returnDisable = true; }
          if (this.sharedService.dmsParams.dmsType === 'P' &&
            this.customerId === JSON.parse(localStorage.getItem('user')).partner_id) {
            this.primary = false;
          } else {
            this.primary = true;
          }
          if (!this.sharedService.dmsParams.cust_supp) {
            this.sharedService.dmsParams.cust_supp = this.orderdetails.customer_supplier;
            // this.sharedService.dmsParams.dmsType = this.orderdetails.invoice_type;
            // localStorage.setItem('dms_type', this.sharedService.dmsParams.dmsType);
          }
          localStorage.setItem('invoice_order_id', data.inv_id);
          this.dmsStatus = this.orderdetails.state;
          this.orderdetails.charges = 0;
          this.orderdetails.sale_items.forEach(line => {
            linediscount += (parseFloat(line.qty) * parseFloat(line.price_unit) * parseFloat(line.discount)) / 100;
            spcldiscount += (parseFloat(line.qty) * parseFloat(line.trade_disc));
            this.orderdetails.charges += Math.abs(line.fixed_cost);
          });

          this.orderdetails.line_discount = Math.abs(linediscount).toFixed(this.apiService.decimalPolicy);
          this.orderdetails.spcl_discount = Math.abs(spcldiscount).toFixed(this.apiService.decimalPolicy);
          this.orderdetails.total = parseFloat(this.orderdetails.amount_total) + parseFloat(this.orderdetails.tcs);
          this.orderdetails.total = this.orderdetails.total - this.orderDiscount;
          this.orderdetails.total = this.orderdetails.total.toFixed(this.apiService.decimalPolicy);
          this.orderdetails.tcs = this.orderdetails.tcs.toFixed(this.apiService.decimalPolicy);
          this.lineData = this.orderdetails.sale_items;
          orderData.supplier_id = this.supplierId;
          orderData.customer_id = this.customerId;
          this.API_URL = '/api/dms_connects/get_product_stock';
          this.apiService.post(this.API_URL, orderData)
            .subscribe(res1 => {
              if (res1.hasOwnProperty('results') && (res1.results.status === 200)) {
                if (res1.results.data.length > 0) {
                  this.uomList = res1.results.data;
                  this.prodList = res1.results.data;
                } else {
                  this.uomList = [];
                  this.prodList = [];
                }
                this.productEntry();
              } else {
                this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
                this.toastr.error(res.results.msg);
              }
            });
        } else {
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
          this.toastr.error(res.results.msg);
        }
      }, error => {
        this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
        console.log(error);
      });
  }

  fetchPreviousData() {
    //this.nextClick.emit('previous');
    if (this.rowIndex !== 0) {
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
      this.rowIndex = this.rowIndex - 1;
      this.sharedService.dmsParams.order_id = this.orderData[this.rowIndex];
      this.orderDetails();
    }
  }

  fetchNextData() {
    //this.nextClick.emit('next');
    if (this.rowIndex !== this.orderData.length - 1) {
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
      this.rowIndex = this.rowIndex + 1;
      this.sharedService.dmsParams.order_id = this.orderData[this.rowIndex];
      this.orderDetails();
    }
  }
}
