import {Component, OnInit} from "@angular/core";
import {BaseUpload} from "../../pagebase/base_upload/base_upload";

@Component({
  selector: 'app-new-user-upload',
  templateUrl: '../../pagebase/base_upload/base_upload.html',
  styleUrls: ['../../pagebase/base_upload/base_upload.scss']

})

export class NewUserUploadComponent extends BaseUpload {

  allUsersList = [];
  stateList = [];
  regionsList = [];
  all_roles_list = [];

  async setViewParams() {
    this.header = 'New Customer Upload';
    this.sampleText1 = 'DOWNLOAD TEMPLATE';
    this.sampleText2 = '';

    this.excel_columns = [{C: 'A', H: 'Code', V: 'EMP001', required: false},
      {C: 'B', H: 'Employee Name', V: 'Adarsh', required: true},
      {C: 'C', H: 'Sales Area Code', V: 'TTY001', required: false},
      {C: 'D', H: 'Type', V: 'Wholesale', required: true, T: 'FK', field: 'type', DBF: 'retailer_type', validation: {null_check: true}},
      {C: 'E', H: 'Mobile', V: '9480439477', required: true, T: 'int', field: 'mobile', DBF: 'mobile', validation: {null_check: false, regex: '^[6-9]\\d{9}$'}},
      {C: 'F', H: 'Tax Id', V: '29AALCA4969J1ZY', required: false, T: 'text', field: 'gst_id', DBF: 'gst_id', validation: {null_check: false}},
      {C: 'G', H: 'Class', V: 'A', required: false, T: 'text', field: 'class', DBF: 'class_type'},
      {C: 'H', H: 'Promoter', V: '[EMP Name/Code]', required: false, T: 'FK', field: 'promoter', DBF: 'user_id'},
      {C: 'I', H: 'Street 1', V: '365, 2nd Cross', required: false, T: 'text', field: 'street1', DBF: 'street1'},
      {C: 'J', H: 'Street 2', V: 'MG Road', required: false, T: 'text', field: 'street2', DBF: 'street2'},
      {C: 'K', H: 'Zip', V: '560043', required: false, T: 'int', field: 'zip', DBF: 'zip'},
      {C: 'L', H: 'City', V: 'Bengaluru', required: false, T: 'text', field: 'city', DBF: 'city'},
      {C: 'M', H: 'State', V: 'Karnataka', required: true, T: 'FK', field: 'state', DBF: 'state_id'},
      {C: 'N', H: 'Balance', V: '5000', required: false, T: 'int', field: 'balance', DBF: 'balance'},
    ];
    this.step1Text = 'Download all customer list or sample template for reference.';
    this.step2Text = 'Required fields are name, beat,  type, state';
    this.actionText = 'Fill excel with new customers';
    this.loadMasterDataForCustomerCreate();
    await this.fetchCustomerData();
    await this.fetchBeatsData();
    this.dataLoaded = true;
  }

  sampleDownload1() {
    this.downloadSampleData( 'new_customer_upload');
  }

  sampleDownload2() {
    // this.downloadSampleData('new_customer_upload_template');
  }
  configureGrid() {
    this.API_URL = '/api/pwa_dms_connects/saveUsers';
    this.columnDefs = [
      {headerName: 'ID', field: 'uid', width: 100},
      {headerName: 'EMP Code', field: 'emp_code', width: 100},
      {headerName: 'Employee Name', field: 'name', width: 200},
      {headerName: 'Sales Area Code', field: 'sac', width: 100},
      {headerName: 'Mobile', field: 'mobile', width: 100},
      {headerName: 'email', field: 'email', width: 100},
      {headerName: 'Role', field: 'role', width: 100},
      {headerName: 'Role ID', field: 'role_id', width: 100, hide: true},
      {headerName: 'Reporting Manager', field: 'manager', width: 100},
      {headerName: 'manager_id', field: 'manager_id', width: 100, hide: true},
      {headerName: 'Team', field: 'team', width: 100},
      {headerName: 'Team ID', field: 'team_id', width: 100, hide: true},
      {headerName: 'Region', field: 'region', width: 100},
      {headerName: 'Region ID', field: 'region_id', width: 100, hide: true},
      {headerName: 'State', field: 'state', width: 100, hide: true},
      {headerName: 'State ID', field: 'state_id', width: 100, hide: true},
      {headerName: 'Date Of Joining', field: 'doj', width: 100}
    ];
  }

  async fetchCustomerData() {
    const res = await this.apiService.postPromise('/api/res_users/get_all_users', this.userData );
    if (res && res.results.status === 200) {
      this.customerList =  res.results.data;
    } else {
      this.toastr.error('Error fetching custer master');
    }
  }


  async fetchBeatsData() {
    const res = await this.apiService.postPromise('/api/beat_plans/get_beat_plans_list_for_dropdown', this.userData );
    if (res && res.results.status === 200) {
      this.beatList =  res.results.data;
    } else {
      this.toastr.error('Error fetching Beat master');
    }
  }

  loadMasterDataForCustomerCreate() {

    // this.customerTypeList = JSON.parse(localStorage.getItem('all_out_types'));
    // this.beatList = JSON.parse(localStorage.getItem('terr_beats'));
    this.stateList = JSON.parse(localStorage.getItem('all_states'));
    // this.LoadDefaultSupplier(callback);
  }



  validate() {
    const inThis = this;
    let index = 0;
    this.validRecords = 0;
    this.errorRecords = 0;
    if (localStorage.getItem('user_states') != null) {
      this.stateList = JSON.parse(localStorage.getItem('user_states'));
    }
    if (localStorage.getItem('all_out_types') != null) {
      // this.customerTypeList = JSON.parse(localStorage.getItem('all_out_types'));
    }

    this.uploaded_data.forEach(data => {
      const indrow: any = {};

      indrow['code'] = this.getExcelFieldValue(data, ['Code', 'code']);
      if (indrow['code'] !== undefined && indrow['code'] !== '') {
        const custObject = this.customerList.find(x => (x.code !== undefined && x.code.toLowerCase() === indrow['code'].toLowerCase()));
        if (custObject) {
          indrow.valid = 'Invalid';
          indrow.errorMsg = 'Record exists with same Code, ID: ' + custObject.id;
        }
      }

      indrow['name'] = this.getExcelFieldValue(data, ['name', 'Name', 'Customer Name']);
      indrow['beat'] = this.getExcelFieldValue(data, ['beat', 'Beat']);
      indrow['type'] = this.getExcelFieldValue(data, ['Type', 'type']);
      indrow['gst_id'] = this.getExcelFieldValue(data, ['Tax Id', 'tax id', 'TAN', 'GST']);
      indrow['mobile'] = this.getExcelFieldValue(data, ['mobile', 'Mobile', , 'Mobile No']);
      indrow['street'] = this.getExcelFieldValue(data, ['Street 1', 'street1', 'street', 'street 1']);
      indrow['street2'] = this.getExcelFieldValue(data, ['Street 2', 'street2', 'street 2']);
      indrow['city'] = this.getExcelFieldValue(data, ['City', 'city']);
      indrow['zip'] = this.getExcelFieldValue(data, ['Zip', 'zip', 'Zip Code']);
      indrow['city'] = this.getExcelFieldValue(data, ['City', 'city']);
      indrow['state'] = this.getExcelFieldValue(data, ['State', 'state']);
      indrow['promoter'] = this.getExcelFieldValue(data, ['Promoter', 'promoter']);
      indrow['balance'] = this.getExcelFieldValue(data, ['Balance', 'balance']);

      if (!indrow['name'] || indrow['name'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Name Is mandatory Field';
      } else {

        const custObj1 = inThis.customerList.find(x => (x.name !== undefined && x.name.toLowerCase() === indrow['name'].toLowerCase()));
        if (custObj1) {
          indrow.valid = 'Invalid';
          indrow.errorMsg = 'Record exists with same Name, ID: ' + custObj1.id;
        }

        if (!indrow['beat'] || indrow['beat'] === '') {
          indrow.valid = 'Invalid';
          indrow.errorMsg = 'Beat Is mandatory Field';
        } else {

          const beat = inThis.beatList.find(x => ((x.name !== undefined && x.name.toLowerCase() === indrow['beat'].toLowerCase()) || (x.id !== undefined && x.id === indrow['beat'])));
          if (!beat) {
            indrow.valid = 'Invalid';
            indrow.errorMsg = 'Beat Not Found ';
          } else {
            indrow['beat_plan_id'] = beat.id;
            indrow['territory_id'] = beat.territory_id;
            indrow['territory'] = '';
            indrow['salesman'] = beat.salesman_id;


            // if (indrow['mobile'] || indrow['mobile'] === '') {
            //   // TODO Check for regular expression
            //   indrow.valid = 'Invalid';
            //   indrow.errorMsg = 'Mobile Is mandatory Field';
            // }


            // if (indrow['gstin']) {
            //   // TODO Check for regular expression
            //   // indrow.valid = 'Invalid';
            //   // indrow.errorMsg = 'Mobile Is mandatory Field';
            // }

            if (!indrow['type'] || indrow['type'] === '') {
              indrow.valid = 'Invalid';
              indrow.errorMsg = 'Type Is mandatory Field';
            } else {
              //
              // const retailerType = inThis.customerTypeList.find(x => (x.itemName !== undefined && x.itemName.toLowerCase() === indrow['type'].toLowerCase()));
              // if (!retailerType) {
              //   indrow.valid = 'Invalid';
              //   indrow.errorMsg = 'Type Not Found ';
              // } else {
              //   indrow['retailer_type'] = retailerType.id;


                if (!indrow['state'] || indrow['state'] === '') {
                  indrow.valid = 'Invalid';
                  indrow.errorMsg = 'State Is Mandatory Field';
                } else {

                  const state = this.stateList.find(x => (x.itemName !== undefined && x.itemName.toLowerCase() === indrow['state'].toLowerCase()));
                  if (!state) {
                    indrow.valid = 'Invalid';
                    indrow.errorMsg = 'No Match found for State';
                  } else {

                    indrow['state_id'] = state.id;

                    if (indrow['promoter'] && indrow['promoter'] !== '') {
                      indrow['user_id'] = this.getLookupId('user_id', indrow['promoter']);
                      if (!indrow['user_id']) {
                        indrow.valid = 'Invalid';
                        indrow.errorMsg = 'No Match found for Promoter';
                      }
                    }

                    if (indrow['balance'] && indrow['balance'] !== '' && isNaN(Number(indrow['balance'].toString()))) {
                      indrow.valid = 'Invalid';
                      indrow.errorMsg = 'Balance has to be numeric';
                    }
                  }
                }
              }
            }
          }
        }
      // }

      if (indrow.valid === 'Invalid') {
        this.errorRecords++;
      } else {
        indrow.valid = 'Valid';
        this.validRecords++;
      }
      index++;
      indrow['uid'] = index;
      this.rowData.push(indrow);
    });
    this.totalRecords = this.rowData.length;
    if (this.gridOptions && this.gridOptions.api) {
      this.gridOptions.api.setRowData(this.rowData);
    }
  }
}
