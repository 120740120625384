<!DOCTYPE html PUBLIC "-//W3C//DTD HTML 4.01//EN" "http://www.w3.org/TR/html4/strict.dtd">
<html>
<head>
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
  <style type="text/css">

    body {
      margin: 25px;
      background-color: #ffffff;
    }

    #page_1 {
      overflow: hidden;
      padding: 0px;
      border: none;
      width: 100%;
    }

    #page_1 #id1_1 {
      border: none;
      margin: 0px 0px 0px 0px;
      padding: 0px;
      border: 1px solid black;
      width: auto;
      overflow: hidden;
    }

    #page_1 #id1_2 {
      border: none;
      padding: 0px;
      border: none;
      width: auto;
      overflow: hidden;
    }

    #page_1 #p1dimg1 {
      position: absolute;
      top: 30px;
      right: 30px;
      height: 100px;
    }

    #page_1 #p1dimg1 #p1img1 {
      width: 68px;
      height: 67px;
    }

    #page_1 #p1dimg2 {
      position: absolute;
      top: 30px;
      left: 30px;
      height: 100px;
    }

    .companylogo {
      height: 100px;
    }

    #page_1 #p1dimg2 #p1dimg2 {
      width: 100px;
      height: 100px;
    }


    .ft0 {
      font: 1px 'Arial';
      line-height: 1px;
    }

    .ft1 {
      font: bold 20px 'Arial';
      line-height: 29px;
    }

    .ft2 {
      font: 11px 'Arial';
      line-height: 14px;
    }

    .ft3 {
      font: bold 11px 'Arial';
      line-height: 14px;
    }

    .ft4 {
      font: 1px 'Arial';
      line-height: 7px;
    }

    .ft5 {
      font: bold 13px 'Arial';
      line-height: 16px;
    }

    .ft6 {
      font: 1px 'Arial';
      line-height: 2px;
    }

    .ft7 {
      font: 14px 'Calibri';
      line-height: 10px;
    }

    .p0 {
      text-align: left;
      margin-top: 0px;
      margin-bottom: 0px;
      white-space: nowrap;
    }

    .p1 {
      text-align: center;
      margin-top: 46px;
      margin-bottom: 0px;
      white-space: nowrap;
    }

    .p2 {
      text-align: center;
      margin-top: 0px;
      margin-bottom: 0px;
      white-space: nowrap;
    }

    .p3 {
      text-align: center;
      padding-right: 41px;
      margin-top: 0px;
      margin-bottom: 0px;
      white-space: nowrap;
    }

    .p4 {
      text-align: center;
      padding-right: 45px;
      margin-top: 0px;
      margin-bottom: 0px;
      white-space: nowrap;
    }

    .p5 {
      text-align: left;
      padding: 4px !important;
      margin-top: 0px;
      margin-bottom: 0px;
      white-space: nowrap;
    }

    .p50 {
      text-align: center;
      padding-left: 2px;
      margin-top: 0px;
      margin-bottom: 0px;
      white-space: nowrap;
    }

    .p6 {
      text-align: left;
      padding-left: 1px;
      margin-top: 0px;
      margin-bottom: 0px;
      white-space: nowrap;
    }

    .p7 {
      text-align: left;
      padding-left: 6px;
      margin-top: 0px;
      margin-bottom: 0px;
      padding-right: 30px;
    }

    .p8 {
      text-align: left;
      padding-left: 3px;
      margin-top: 0px;
      margin-bottom: 0px;
    }

    .p9 {
      text-align: left;
      padding-left: 5px;
      margin-top: 0px;
      margin-bottom: 0px;
      white-space: nowrap;
    }

    .p10 {
      text-align: right;
      padding-right: 6px;
      margin-top: 0px;
      margin-bottom: 0px;
      white-space: nowrap;
    }

    .p11 {
      text-align: right;
      padding-right: 5px;
      margin-top: 4px;
      margin-bottom: 0px;
      white-space: nowrap;
    }

    .p12 {
      text-align: left;
      margin-top: 2px;
      margin-bottom: 0px;
    }

    .p13 {
      text-align: left;
      margin-top: 0px;
      margin-bottom: 0px;
    }

    .table_style {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      border: 1px;
      table-layout:fixed;
      width: 97%;
      border: 1px solid #dddddd;
      margin: 10px;
    }


    .td0 {
      border-left: #dddddd 1px solid;
      border-top: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 100%;
      vertical-align: bottom;
    }

    .td1 {
      border-top: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 94px;
      vertical-align: bottom;
    }

    .td2 {
      border-top: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 229px;
      vertical-align: bottom;
    }

    .td3 {
      border-top: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 116px;
      vertical-align: bottom;
    }

    .td4 {
      border-right: #dddddd 1px solid;
      border-top: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 106px;
      vertical-align: bottom;
    }

    .td5 {
      border-left: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 169px;
      vertical-align: bottom;
    }

    .td6 {
      padding: 0px;
      margin: 0px;
      width: 323px;
      vertical-align: bottom;
    }

    .td7 {
      padding: 0px;
      margin: 0px;
      width: 116px;
      vertical-align: bottom;
    }

    .td8 {
      border-right: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 106px;
      vertical-align: bottom;
    }

    .td9 {
      padding: 5px;
      margin: 0px;
      width: 15%;
      vertical-align: middle;
    }

    .td9s {
      padding: 5px;
      margin: 0px;
      width: 5%;
      vertical-align: middle;
    }

    .td9m {
      padding: 5px;
      margin: 0px;
      width: 10%;
      vertical-align: middle;
    }

    .td10 {
      padding: 5px;
      margin: 0px;
      width: 35%;
      vertical-align: middle;
    }

    .td11 {
      border-left: #dddddd 1px solid;
      border-bottom: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 169px;
      vertical-align: bottom;
    }

    .td12 {
      border-bottom: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 94px;
      vertical-align: bottom;
    }

    .td13 {
      border-bottom: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 60px;
      vertical-align: bottom;
    }

    .td14 {
      border-bottom: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 169px;
      vertical-align: bottom;
    }

    .td15 {
      border-bottom: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 116px;
      vertical-align: bottom;
    }

    .td16 {
      border-right: #dddddd 1px solid;
      border-bottom: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 106px;
      vertical-align: bottom;
    }

    .td17 {
      border-right: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 59px;
      vertical-align: bottom;
    }

    .td18 {
      padding: 0px;
      margin: 0px;
      width: 169px;
      vertical-align: bottom;
    }

    .td19 {
      border-right: #dddddd 1px solid;
      border-bottom: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 59px;
      vertical-align: bottom;
    }

    .td20 {
      padding: 0px;
      margin: 0px;
      width: 60px;
      vertical-align: bottom;
    }

    .td21 {
      border-left: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 168px;
      vertical-align: bottom;
    }

    .td22 {
      border-right: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 168px;
      vertical-align: bottom;
    }

    .td23 {
      border-right: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 115px;
      vertical-align: bottom;
    }

    .td24 {
      border-left: #dddddd 1px solid;
      border-right: #dddddd 1px solid;
      border-bottom: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 168px;
      vertical-align: bottom;
    }

    .td25 {
      border-right: #dddddd 1px solid;
      border-bottom: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 168px;
      vertical-align: bottom;
    }

    .td26 {
      border-right: #dddddd 1px solid;
      border-bottom: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 115px;
      vertical-align: bottom;
    }


    .tdProdName {
      padding-left: 4px;
      margin: 0px;
      width: 20%;
      vertical-align: center;
    }

    .serialNumber {
      border-bottom: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 10%;
      vertical-align: bottom;
    }

    .expenseType {
      border-bottom: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 30%;
      vertical-align: bottom;
    }

    .amount {
      border-bottom: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 30%;
      vertical-align: bottom;
    }

    .companyShare {
      border-bottom: #dddddd 1px solid;
      padding: 0px;
      margin: 0px;
      width: 30%;
      vertical-align: bottom;
    }

    .tr0 {
      height: 42px;
    }

    .tr1 {
      height: 16px;
    }

    .tr2 {
      height: 15px;
    }

    .tr3 {
      height: 7px;
    }

    .tr4 {
      height: 25px;
    }

    .tr5 {
      height: 2px;
    }

    .tr6 {
      height: 21px;
    }

    .tr7 {
      height: 20px;
    }

    .t0 {
      width: 100%;
      font: 11px 'Arial';
    }

  </style>
</head>

<body>
<div id=page_1>
  <div id=p1dimg1>
    <qrcode class="p11 ft7" *ngIf="order_detail['ewb_no'] && eway_QR" [elementType]="'svg'"
            [qrdata]="eway_QR" [width]="120" [errorCorrectionLevel]="'M'"></qrcode>
  </div>
  <div id="p1dimg2">
    <img class="companylogo" [src]="order_detail['logo']">
  </div>
  <div id=id1_1>
    <p *ngIf="order_detail['ewb_no']" class="p1 ft1"><u>E-Way Bill</u></p>
    <p *ngIf="!order_detail['ewb_no']" class="p1 ft1"><u>Delivery Challan</u></p>

    <br><br>
    <br><br>
    <table class="table_style" style="width: 97%;">
      <tr>
        <td class="r2"><p class="p5 ft3"><span class="ft2">Eway Bill No: </span> {{order_detail['ewb_no']}} </p></td>
        <td class="r2 td9"><p class="p0 ft0"> </p></td>
        <td class="r2"><p class="p5 ft3"><span class="ft2">Generated Date: </span>   {{order_detail['docDate']}}  </p></td>
        <td class="r2 td9"><p class="p0 ft0"> </p></td>
        <td class="r2 "><p class="p5 ft3"><span class="ft2">Generated By: </span> {{order_detail['tax_code']}} </p>
        <div *ngIf="order_detail['eway_type'] !== 'Consolidated'">
          <p class="p5 ft3"><span class="ft2">Valid Upto: </span>  {{order_detail['valid_upto']}}  </p>
        </div>
        </td>
      </tr>
      <tr>
        <td class="r5 td11"><p class="p0 ft6"> </p></td>
        <td class="r5 td12"><p class="p0 ft6"> </p></td>
        <td class="r5 td19"><p class="p0 ft6"> </p></td>
        <td class="r5 td14"><p class="p0 ft6"> </p></td>
        <td class="r5 td15"><p class="p0 ft6"> </p></td>
        <td class="r5 td16"><p class="p0 ft6"> </p></td>
      </tr>
      <tr>
        <td  class="r2"><p class="p5 ft3"><span class="ft2">Type: </span>{{order_detail['eway_type']}}</p></td>
        <td class="r2 td9"><p class="p0 ft0"> </p></td>
        <td  class="r2"><p class="p5 ft3"><span class="ft2">Approx Distance: </span>{{order_detail['distance']}}Km </p></td>
        <td class="r2 td9"><p class="p0 ft0"> </p></td>
        <td class="r2"><p class="p5 ft3"><span class="ft2">Van: </span>  {{order_detail['van']}}  </p></td>
      </tr>
      <tr>
        <td class="r5 td11"><p class="p0 ft6"> </p></td>
        <td class="r5 td12"><p class="p0 ft6"> </p></td>
        <td class="r5 td19"><p class="p0 ft6"> </p></td>
        <td class="r5 td14"><p class="p0 ft6"> </p></td>
        <td class="r5 td15"><p class="p0 ft6"> </p></td>
        <td class="r5 td16"><p class="p0 ft6"> </p></td>
      </tr>
      <tr *ngIf="order_detail['ewb_no']">
        <td  class="r2"><p class="p5 ft3"><span class="ft2">Mode: </span>{{order_detail['trans_mode']}}</p></td>
        <td class="r2 td9"><p class="p0 ft0"> </p></td>
        <td  class="r2"><p class="p5 ft3"><span class="ft2">Transaction Type: </span>Regular </p></td>
        <td class="r2 td9"><p class="p0 ft0"> </p></td>
        <td class="r2"><p class="p5 ft3"><span class="ft2">Vehicle No: </span>  {{order_detail['veh_no']}}  </p></td>
      </tr>
      <tr *ngIf="order_detail['ewb_no']">
        <td class="r5 td11"><p class="p0 ft6"> </p></td>
        <td class="r5 td12"><p class="p0 ft6"> </p></td>
        <td class="r5 td19"><p class="p0 ft6"> </p></td>
        <td class="r5 td14"><p class="p0 ft6"> </p></td>
        <td class="r5 td15"><p class="p0 ft6"> </p></td>
        <td class="r5 td16"><p class="p0 ft6"> </p></td>
      </tr>
      <tr *ngIf="order_detail['ewb_no'] && order_detail['docType'] && order_detail['docType'] === 'INV'">
        <td  class="r2"><p class="p5 ft3"><span class="ft2">Invoice No: </span>  {{order_detail['docNo']}}  </p></td>
        <td class="r2 td9"><p class="p0 ft0"> </p></td>
        <td  class="r2" colspan="2"><p class="p5 ft3"><span class="ft2">IRN: </span>{{order_detail['irn']}} </p></td>
      </tr>
      <tr *ngIf="order_detail['ewb_no'] && order_detail['docType'] && order_detail['docType'] === 'INV'">
        <td class="r5 td11"><p class="p0 ft6"> </p></td>
        <td class="r5 td12"><p class="p0 ft6"> </p></td>
        <td class="r5 td19"><p class="p0 ft6"> </p></td>
        <td class="r5 td14"><p class="p0 ft6"> </p></td>
        <td class="r5 td15"><p class="p0 ft6"> </p></td>
        <td class="r5 td16"><p class="p0 ft6"> </p></td>
      </tr>

    </table>
    <br>
    <p  *ngIf="order_detail['eway_type'] !== 'Consolidated'" class="p5 ft3" style="margin-left: 10px;">Address Details </p>
    <table class="table_style"  *ngIf="order_detail['eway_type'] !== 'Consolidated'">
      <th width="50%"></th>
      <th width="50%"></th>
      <tr>
        <td class="r6"  style="white-space: pre-wrap !important; width:50%; padding-top: 5px;"><span class="p7 ft3">From </span></td>
        <td class="r6" style="white-space: pre-wrap !important; width:50%; padding-top: 5px;"><span class="p7 ft3">To </span></td>
      </tr>
      <tr>
        <td class="r6"  style="white-space: pre-wrap !important; width:50%; padding-top: 5px;"><p class="p7 ft2" >{{order_detail['from']['name']}}</p></td>
        <td class="r6" style="white-space: pre-wrap !important; width:50%; padding-top: 5px;"><p class="p7 ft2" >{{order_detail['to']['name']}}</p></td>
      </tr>
      <tr>
        <td class="r6" style="white-space: pre-wrap !important; width:50%"><p class="p7 ft2" >{{order_detail['from']['addr']}}</p></td>
        <td class="r6" style="white-space: pre-wrap !important; width:50%"><p class="p7 ft2">{{order_detail['to']['addr']}} <br>
        </p></td>
      </tr>
      <tr>
        <td class="r6"  style="white-space: pre-wrap !important; width:50%;"><p class="p7 ft3" style="margin-top: 10px;"><span class="ft2">GSTIN: </span>  {{order_detail['from']['gstin']}}</p></td>
        <td class="r6"  style="white-space: pre-wrap !important; width:50%;"><p class="p7 ft3" style="margin-top: 10px;"><span class="ft2">GSTIN: </span>  {{order_detail['to']['gstin']}}</p></td>
      </tr>
      <tr>
        <td *ngIf="order_detail['from']['ph']" class="r6"  style="white-space: pre-wrap !important; width:50%;"><p class="p7 ft3"><span class="ft2">Phone: </span>  {{order_detail['from']['ph']}}</p></td>
        <td *ngIf="order_detail['to']['ph']" class="r6" style="white-space: pre-wrap !important; width:50%; "><p class="p7 ft3" ><span class="ft2">Phone: </span> {{order_detail['to']['ph']}}</p></td>
      </tr>
      <tr>
        <td *ngIf="order_detail['from']['em']" class="r6"  style="white-space: pre-wrap !important; width:50%;"><p class="p7 ft3" ><span class="ft2">Email: </span>  {{order_detail['from']['em']}}</p></td>
        <td *ngIf="order_detail['to']['em']" class="r6" style="white-space: pre-wrap !important; width:50%; "><p class="p7 ft3"><span class="ft2">Email: </span> {{order_detail['to']['em']}}</p></td>
      </tr>
      <tr>
        <td *ngIf="order_detail['driver_name']" class="r6" style="white-space: pre-wrap !important; width:50%"><p class="p7 ft3" >Driver: {{order_detail['driver_name']}}</p></td>
      </tr>
      <tr>
        <td *ngIf="order_detail['driver_contact']" class="r6" style="white-space: pre-wrap !important; width:50%"><p class="p7 ft3" >Driver Contact: {{order_detail['driver_contact']}}</p></td>
      </tr>
    </table>

    <p *ngIf="order_detail['ewb_no'] && order_detail['eway_type'] === 'Consolidated' && consolidatedData && consolidatedData.length > 0"
       class="p5 ft3" style="margin-left: 10px;">Eway List</p>
    <table *ngIf="order_detail['ewb_no'] && order_detail['eway_type'] === 'Consolidated' && consolidatedData && consolidatedData.length > 0"
           class="table_style">
      <tr style=" border-bottom: #dddddd 1px solid;">
        <td class="r6 td9s"><p class="p7 ft3">Sl No</p></td>
        <td class="r6 td9"><p class="p7 ft3">Date</p></td>
        <td class="r6 td9"><p class="p7 ft3">Eway Bill</p></td>
        <td class="r6 td9"><p class="p7 ft3">Type</p></td>
        <td class="r6 td10"><p class="p8 ft3">To</p></td>
        <td class="r6 td9"><p class="p8 ft3">Value</p></td>
        <td class="r6 td9"><p class="p8 ft3">Valid Upto</p></td>
      </tr>
      <br>
      <tr *ngFor="let ewb of consolidatedData; let i = index" style="border-bottom: #dddddd 1px solid;">
        <td class="r6 td9s"><p class="p7 ft2">{{i+1}}</p></td>
        <td class="r6 td9"><p class="p7 ft2">{{ewb['docDate']}}</p></td>
        <td class="r6 td9"><p class="p7 ft2">{{ewb['ewb_no']}}</p></td>
        <td class="r6 td9"><p class="p8 ft2">{{ewb['type']}}</p></td>
        <td class="r6 td10"><p class="p8 ft2">{{ewb['to']}}</p></td>
        <td class="r6 td9"><p class="p8 ft2">{{ewb['amount_total']}}</p></td>
        <td class="r6 td9"><p class="p8 ft2">{{ewb['valid_upto']}}</p></td>
      </tr>
    </table>

    <p class="p5 ft3" style="margin-left: 10px;">Product Details</p>
    <table class=table_style>
      <tr style=" border-bottom: #dddddd 1px solid;">
        <td class="r10 td9s"><p class="p7 ft3">Sl. No.</p></td>
        <td class="r6 td10"><p class="p7 ft3">Particulars</p></td>
        <td class="r6 td9"><p class="p7 ft3">HSN</p></td>
        <td class="r6 td9m"><p class="p8 ft3">Qty In</p></td>
        <td class="r6 td9"><p class="p8 ft3">UOM</p></td>
        <td class="r6 td9"><p class="p8 ft3">Unit Rate</p></td>
        <td class="r6 td9"><p class="p8 ft3">Gross<br>Amount</p></td>
      </tr>
      <br>
      <tr *ngFor="let product of order_detail['items']; let i = index">
        <td class="r10 td9s"><p class="p7 ft2">{{i+1}}</p></td>
        <td class="r6 td10"><p class="p7 ft2">{{product['name']}}</p></td>
        <td class="r6 td9"><p class="p7 ft2">{{product['hsn']}}</p></td>
        <td class="r6 td9m"><p class="p8 ft2">{{product['qty']}}</p></td>
        <td class="r6 td9"><p class="p8 ft2">{{product['uom']}}</p></td>
        <td class="r6 td9"><p class="p8 ft2">{{product['unit_price']}}</p></td>
        <td class="r6 td9"><p class="p8 ft2">{{product['amount_total']}}</p></td>
      </tr>
    </table>
    <br><br>
    <div style="margin-right: 5px;">
      <p class="p11 ft2">Total Qty : <span class="ft3">{{totalQty}}</span></p>
      <p *ngIf="order_detail['amount_subtotal']" class="p11 ft2">Taxable Amount : <span class="ft3">{{order_detail['amount_subtotal']}}</span></p>
      <div *ngIf="order_detail['amount_subtotal'] && order_detail['tax']" >
        <div *ngFor="let tax of order_detail['tax'] | keyvalue">
          <p class="p11 ft2">{{tax.key.toString().toUpperCase()}} : <span class="ft3">{{tax.value}}</span></p>
        </div>
      </div>
      <p *ngIf="order_detail['amount_total']" class="p11 ft2">Total Amount : <span class="ft3">{{order_detail['amount_total']}}</span></p>
    </div>

    <br>
    <p *ngIf="order_detail['company']" class="p11 ft2">For {{order_detail['company']}}</p>
    <div class="p2 ft1">
      <ngx-barcode [bc-value]="order_detail['ewb_no']" [bc-display-value]="order_detail['ewb_no']" [bc-width]="1" [bc-height]="50" [bc-font-size]="10"></ngx-barcode>
    </div>
  </div>
</div>
</body>
</html>
