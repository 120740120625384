import {Component, OnInit} from "@angular/core";
import {ErrortooltipComponent} from "../../../errortooltip";
import {TabPageBase} from "../../../pagebase/tabpagebase";
import * as XLSX from "xlsx-js-style";

@Component({
  selector: 'app-sale-order-list',
  templateUrl: '../../../pagebase/tabpagebase.html',
  styleUrls: ['../../../pagebase/pagebase.scss']
})

export class LedgerListComponent extends TabPageBase implements OnInit {

  matActionMenuItemDeclaration = [{name: 'Print'}];
  openingBalance = 0;
  closingBalance = 0;
  totalPayment = 0;
  totalSales = 0;
  totalCredits = 0;
  searchableFilter: any = {
    name: "Customer",
    bindLabel: "name",
    bindValue: "id",
    value: 0,
    values: []
  };

  ngOnInit(): void {

    this.sharedService.setHeader('Payment Ledger');
    this.showUpload = false;
    this.tabList = [{id: 'ledger', itemName: 'Ledger'}, {id: 'receipts', itemName: 'Receipts'}, {id: 'credit', itemName: 'Credit Notes'}];
    this.checkAndLoadPreviousSate();

    this.showSearchableFilter = true;
    this.showCustomFilter = false;
    this.showActionButton = false;
    this.showDate = true;

    this.gridOptions1.getRowStyle = this.getRowStyleFunc;
    this.frameworkComponents1 = {
      errortooltipComponent: ErrortooltipComponent
    };

    this.gridOptions1.isRowSelectable = params => {
      if (params.data) {
        if (params.data.state === 'cancel') {
          return false;
        } else {
          return true;
        }
      }
    };
    this.loadPartnersForSales(this.userData, () => {
    });
    this.getAuthorizedActions();
    this.checkAccessControl();
    this.configureGrid();
  }

  getRowStyleFunc(params) {
    if (params.data && params.data.id === 'TOTAL') {
      return {'font-weight': 'bold'};
    }
  }

  configureGrid() {
    this.API_URL = '/api/pwa_dms_connects/get_party_ledger';

    const OrderStatusCellClassRules = {
      'rag-green-outer': (params) => params.value === 'sale',
      'rag-amber-outer': (params) => params.value === 'confirmed',
      'rag-red-outer': (params) => params.value === 'cancel',
    };

    this.columnDefs1 = [
      {headerName: "ID", field: "id", width: 25},
      {headerName: "Date", field: "date", width: 40},
      {headerName: "Payment Date", field: "pay_date", width: 40},
      {headerName: "Voucher", field: "t", width: 40},
      {
        headerName: "Credit/Debit", field: "cd", width: 30,
        cellClassRules: OrderStatusCellClassRules,
        valueGetter: params => {
          if (params.data && params.data.hasOwnProperty('cd')) {
            if (params.data.cd === 'Cr') {
              return 'Credit';
            } else if (params.data.cd === 'Dr') {
              return 'Debit';
            } else {
              return params.data.cd;
            }
          }
        },
        cellRenderer(params) {
          let displayElem = '';
          if (params.data) {
            if (params.value === 'Credit') {
              displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #379862;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
            } else if (params.value === 'Debit') {
              displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #E24260;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
            } else {
              if (params.value) {
                displayElem = '<span>' + params.value + '</span>';
              }
            }

            return displayElem;
          }
        }
      },
      {
        headerName: "Amount", field: "amt", width: 50, cellStyle: {textAlign: 'right'}, aggFunc: 'sum',
        valueFormatter: params => this.formatNumber(params.value)
      },
      {
        headerName: "Balance", field: "balance", width: 50, cellStyle: {textAlign: 'right'}, aggFunc: 'sum',
        valueFormatter: params => this.formatNumber(params.value)
      },
      {headerName: "Invoice No", field: "inv_no", width: 60},
      {headerName: "Payment Ref", field: "pn", width: 60},
      {headerName: "Payment Type", field: "pt", width: 60},
      {headerName: "Order Ref", field: "ord_ref", width: 40},
      {headerName: "Salesman", field: "s", width: 40},
      {headerName: "Created By", field: "c", width: 40},
    ];

    this.columnDefs2 = [
      {headerName: "ID", field: "id", width: 25},
      {headerName: "Date", field: "dt", width: 60},
      {headerName: "Name", field: "name", width: 40},
      {
        headerName: "Amount", field: "amt", width: 50, cellStyle: {textAlign: 'right'}, aggFunc: 'sum',
        valueFormatter: params => this.formatNumber(params.value)
      },
    ];

    this.columnDefs3 = [
      {headerName: "ID", field: "id", width: 25},
      {headerName: "Date", field: "cn_date", width: 60},
      {headerName: "Name", field: "name", width: 40},
      {
        headerName: "Amount", field: "amount", width: 50, cellStyle: {textAlign: 'right'}, aggFunc: 'sum',
        valueFormatter: params => this.formatNumber(params.value)
      },
      {
        headerName: "Balance", field: "balance", width: 50, cellStyle: {textAlign: 'right'}, aggFunc: 'sum',
        valueFormatter: params => this.formatNumber(params.value)
      },
    ];

    this.getRowId = (params) => params.data.id;
  }

  postProcessData() {
    if (this.apiData) {
      this.rowData1 = this.apiData.ledger;
      this.rowData2 = this.apiData.invoices;
      this.rowData3 = this.apiData.credit_notes;

      if (this.rowData1 && this.rowData1.length > 0) {
        this.openingBalance = parseFloat(parseFloat(this.rowData1[0]['balance']).toFixed(2));
        this.closingBalance = parseFloat(parseFloat(this.rowData1[this.rowData1.length - 1]['balance']).toFixed(2));
        this.totalSales = 0;
        this.rowData1.forEach(row => {
          if (row.cd === 'Dr') {
            this.totalSales += parseFloat(row['amt']);
          }
        });
        this.totalSales = parseFloat(this.totalSales.toFixed(2));
      } else {
        this.openingBalance = 0;
        this.closingBalance = 0;
        this.totalSales = 0;
      }

      if (this.rowData2 && this.rowData2.length > 0) {
        this.totalPayment = 0;
        this.rowData2.forEach(row => {
          this.totalPayment += parseFloat(row['amt']);
        });
        this.totalPayment = parseFloat(this.totalPayment.toFixed(2));
      } else {
        this.totalPayment = 0;
      }

      if (this.rowData3 && this.rowData3.length > 0) {
        this.totalCredits = 0;
        this.rowData3.forEach(row => {
          this.totalCredits += parseFloat(row['amount']);
        });
        this.totalCredits = parseFloat(this.totalCredits.toFixed(2));
      } else {
        this.totalCredits = 0;
      }

    }
    return;
  }

  customerChangeEvent(event) {

  }

  onCellClicked1(event) {
    const column = event.column.getColId();
    const rows = this.gridApi1.getSelectedRows();
    const filteredRowData = this.gridApi1.rowModel.rowsToDisplay;
    if (filteredRowData !== null && filteredRowData.length > 0) {
      const ids = [];
      filteredRowData.forEach(r => {
        ids.push(r.data.id);
      });
      localStorage.setItem('active_ids', JSON.stringify(ids));
      this.router.navigate([this.apiService.redirect_url + '/dms/sale_order'], {
        relativeTo: this.route,
        queryParams: {id: rows[0].id}
      });
    }
  }


  onBtExport() {
    let supplier_name = '';
    let supplier_code = '';
    if (this.rowData1 && this.rowData1.length > 0) {
      supplier_name = this.rowData1[0].supp;
      supplier_code = this.rowData1[0].supp_c;
    } else if (this.rowData2 && this.rowData2.length > 0) {
      supplier_name = this.rowData2[0].supp;
      supplier_code = this.rowData2[0].supp_c;
    } else if (this.rowData3 && this.rowData3.length > 0) {
      supplier_name = this.rowData3[0].supp;
      supplier_code = this.rowData3[0].supp_c;
    }

    const wb = XLSX.utils.book_new();
    let wscols = [];
    /* Initial row */
    const ws = XLSX.utils.json_to_sheet([
      {A: "Customer Name", B: "Customer Code", C: "Supplier Name", D: "Supplier Code", E: "Date", F: "Opening Balance", G: "Closing Balance", H: "Total Payments", I: "Total Sales", J: "Total Credits"},
      {
        A: this.selectedSearchableData.name, B: this.selectedSearchableData.code, C: supplier_name, D: supplier_code,
        E: this.myDate[0] + ' - ' + this.myDate[1], F: this.openingBalance, G: this.closingBalance, H: this.totalPayment,
        I: this.totalSales, J: this.totalCredits
      },
    ], {header: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"], skipHeader: true});
    /* Write data starting at A5 */

    wscols = [{wch: 25}, {wch: 20}, {wch: 25}, {wch: 20}, {wch: 25}, {wch: 18}, {wch: 18}, {wch: 18}, {wch: 18}, {wch: 20}, {wch: 18}];
    const ledgerdata = [];
    if (this.rowData1) {
      this.rowData1.forEach(row => {
        const indrow = {};
        this.columnDefs1.forEach(column => {
          indrow[column.headerName] = row[column.field];
        });
        ledgerdata.push(indrow);
      });
    }
    ws['!cols'] = wscols;
    this.sharedService.applyExcelColumnStyle(ws, 1, 10);
    XLSX.utils.sheet_add_json(ws, ledgerdata, {skipHeader: false, origin: "A5"});
    this.sharedService.applyExcelColumnStyle(ws, 5, this.columnDefs1.length);
    XLSX.utils.book_append_sheet(wb, ws, "Ledger");

    const receiptsdata = [];
    if (this.rowData2) {
      this.rowData2.forEach(row => {
        const indrow = {};
        this.columnDefs2.forEach(column => {
          indrow[column.headerName] = row[column.field];
        });
        receiptsdata.push(indrow);
      });
    }

    const ws1 = XLSX.utils.json_to_sheet([
      {A: "Customer Name", B: "Customer Code", C: "Supplier Name", D: "Supplier Code", E: "Date", F: "Opening Balance", G: "Closing Balance", H: "Total Payments", I: "Total Sales", J: "Total Credits"},
      {
        A: this.selectedSearchableData.name, B: this.selectedSearchableData.code, C: supplier_name, D: supplier_code,
        E: this.myDate[0] + ' - ' + this.myDate[1], F: this.openingBalance, G: this.closingBalance, H: this.totalPayment,
        I: this.totalSales, J: this.totalCredits
      },
    ], {header: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"], skipHeader: true});
    ws1['!cols'] = wscols;
    this.sharedService.applyExcelColumnStyle(ws1, 1, 10);
    XLSX.utils.sheet_add_json(ws1, receiptsdata, {skipHeader: false, origin: "A5"});
    this.sharedService.applyExcelColumnStyle(ws1, 5, this.columnDefs2.length);
    XLSX.utils.book_append_sheet(wb, ws1, "Receipts");

    const creditnotedata = [];
    if (this.rowData3) {
      this.rowData3.forEach(row => {
        const indrow = {};
        this.columnDefs3.forEach(column => {
          indrow[column.headerName] = row[column.field];
        });
        creditnotedata.push(indrow);
      });
    }

    const ws2 = XLSX.utils.json_to_sheet([
      {A: "Customer Name", B: "Customer Code", C: "Supplier Name", D: "Supplier Code", E: "Date", F: "Opening Balance", G: "Closing Balance", H: "Total Payments", I: "Total Sales", J: "Total Credits"},
      {
        A: this.selectedSearchableData.name, B: this.selectedSearchableData.code, C: supplier_name, D: supplier_code,
        E: this.myDate[0] + ' - ' + this.myDate[1], F: this.openingBalance, G: this.closingBalance, H: this.totalPayment,
        I: this.totalSales, J: this.totalCredits
      },
    ], {header: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"], skipHeader: true});
    ws2['!cols'] = wscols;
    this.sharedService.applyExcelColumnStyle(ws2, 1, 10);
    XLSX.utils.sheet_add_json(ws2, creditnotedata, {skipHeader: false, origin: "A5"});
    this.sharedService.applyExcelColumnStyle(ws2, 5, this.columnDefs3.length);
    XLSX.utils.book_append_sheet(wb, ws2, "Credit Notes");
    XLSX.writeFile(wb, "ledger.xlsx");
  }

}
