import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {ErrorHandler} from '../../error/error-handler';
import {formatDate} from '@angular/common';
import {DatePipe} from "@angular/common";
@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class DailyTrackerReportComponent extends ReportBase implements IReport, OnInit {

  apiData: any = [];
  daylist: any = [];

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Daily Tracker Report');
    this.API_URL = '/api/pwa_v2_reports/getDailyTrackerReport';
    this.showableFilters.state = true;
    this.showableFilters.team = true;
    this.showableFilters.region = true;
    this.configureGrid();
  }

  configureGrid() {
    const inThis = this;
    this.daylist = this.getDaysArray(new Date(this.myDate[0]), new Date(this.myDate[1]));
    this.daylist.map((v) => v.toISOString().slice(0, 10)).join('');

    this.columnDefs = [{headerName: 'KPI', field: 'label', enableRowGroup: true, width: 80,pinned: 'left',}]
    for (const key in this.daylist) {
      const formatted_date = formatDate(new Date(this.daylist[key]), 'dd/MM/yyyy', 'en-US').substring(0, 10);
      this.columnDefs.push(
        {
          headerName: formatted_date.substring(0, 5), field: formatted_date, menuTabs: [], width: 55
        }
      );
    }
  }

  getDaysArray(start, end) {
    for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  }

  postProcessData() {
    this.configureData();
  }

  configureData() {
    this.rowData = [];
    this.apiData.forEach(data => {
      const inrow:any = {};
      for (const key in this.daylist) {
        const formatted_date = formatDate(new Date(this.daylist[key]), 'yyyy-MM-dd', 'en-US').substring(0, 10);
        const value = data.data_json.find(x => x.date === formatted_date);
        if (value){
          const formatted_date1 = formatDate(new Date(this.daylist[key]), 'dd/MM/yyyy', 'en-US').substring(0, 10);
          inrow[formatted_date1] = value.val;
        }
      }
      inrow.label = data.label;
      this.rowData.push(inrow);
    });
  }
}
