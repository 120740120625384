import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-outlet-last-visit-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class BeatCoverageReportComponent extends ReportBase implements IReport, OnInit {

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Beat Coverage Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.showableFilters.state = true;
    //this.showDate = false;

    this.configureGrid();

    this.gridOptions.headerHeight = 40;
    this.gridOptions.autoGroupColumnDef = {
      headerName: 'Salesman',
      field: 'salesman',
      minWidth: 200,
      cellRenderer: 'agGroupCellRenderer',
      resizable: true
    };
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getBeatCoverageReport';

    this.columnDefs = [

      {headerName: 'ID', field: 'id', hide: true, maxWidth: 80},
      {headerName: 'STATE', field: 'state', enableRowGroup: true, width: 80},
      {headerName: 'REGION', field: 'region', enableRowGroup: true, width: 80},
      {headerName: 'CLUSTER', field: 'cluster', enableRowGroup: true, width: 80},
      {headerName: 'TID', field: 'terr_id', enableRowGroup: true, hide: true, width: 80},
      {headerName: 'TCODE', field: 'terr_code', enableRowGroup: true, hide: true, width: 80},
      {headerName: 'TERRITORY', field: 'territory', enableRowGroup: true, width: 80},
      {headerName: 'BEAT ID', field: 'beat_id', enableRowGroup: true, width: 120},
      {headerName: 'BEAT', field: 'beat', enableRowGroup: true, width: 120},
      {headerName: 'TYPE', field: 'travel_type', enableRowGroup: true, width: 80},
      {headerName: 'BUSINESS GROUP', field: 'bg_name', enableRowGroup: true, width: 80},
      {headerName: 'SALESMAN', field: 'salesman', enableRowGroup: true, width: 80},
      {headerName: 'EMP CODE', field: 'emp_code', enableRowGroup: true, hide: true, width: 80},
      {headerName: 'TEAM', field: 'team', enableRowGroup: true, width: 80},
      {headerName: 'HIERARCHY', field: 'team_hierarchy', enableRowGroup: true, width: 160},
      {headerName: 'ROLE', field: 'role', enableRowGroup: true, hide: true, width: 80},

      {headerName: 'WEEK', field: 'week', enableRowGroup: true, hide: true, width: 80},
      {headerName: 'MONTH', field: 'month', enableRowGroup: true, hide: true, width: 80},
      {headerName: 'QUARTER', field: 'quarter', enableRowGroup: true, hide: true, width: 80},
      {headerName: 'YEAR', field: 'year', enableRowGroup: true, hide: true, width: 80},
      {headerName: 'DATE', field: 'schedule_date', width: 80},

      {
        headerName: 'TARGET', field: 'target', enableRowGroup: true, width: 80,  aggFunc: 'sum',
        valueGetter: params => {
          if (params.data) {
            if (params.data.target) {
              return params.data.target;
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: 'REVENUE', field: 'revenue', enableRowGroup: true, width: 80, aggFunc: 'sum',
        valueGetter: params => {
          if (params.data) {
            if (params.data.revenue) {
              return params.data.revenue;
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName:  'ACH %', field: 'ach_pct', enableRowGroup: true, width: 80, aggFunc:this.avgAggFunction,
        valueGetter: params => {
          if (params.data) {
            if (params.data.ach_pct) {
              return parseFloat(params.data.ach_pct.toFixed(2));
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: 'BEAT COUNT', field: 'beat_counts', enableRowGroup: true, width: 80, aggFunc: 'sum',
        valueGetter: params => {
          if (params.data) {
            if (params.data.beat_counts) {
              return params.data.beat_counts;
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: 'AVG BILL', field: 'abv', enableRowGroup: true, width: 80, aggFunc: this.avgAggFunction,
        valueGetter: params => {
          if (params.data) {
            if (params.data.abv) {
              return parseFloat(params.data.abv.toFixed(2));
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: 'UNIQUE OUTLET COUNT', field: 'urc', enableRowGroup: true, width: 80, aggFunc: 'sum',
        valueGetter: params => {
          if (params.data) {
            if (params.data.urc) {
              return params.data.urc;
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: 'TC', field: 'tc', enableRowGroup: true, width: 80, aggFunc: 'sum',
        valueGetter: params => {
          if (params.data) {
            if (params.data.tc) {
              return params.data.tc;
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: 'VISITED COUNT', field: 'vc', enableRowGroup: true, width: 80, aggFunc: 'sum',
        valueGetter: params => {
          if (params.data) {
            if (params.data.vc) {
              return params.data.vc;
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: 'PRODUCTIVE COUNT', field: 'pc', enableRowGroup: true, width: 80, aggFunc: 'sum',
        valueGetter: params => {
          if (params.data) {
            if (params.data.pc) {
              return params.data.pc;
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName:  'PC %', field: 'pc_pct', enableRowGroup: true, width: 80, aggFunc:this.avgAggFunction,
        valueGetter: params => {
          if (params.data) {
            if (params.data.pc_pct) {
              return parseFloat(params.data.pc_pct.toFixed(2));
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: 'NOT VISITED', field: 'nv', enableRowGroup: true, width: 80, aggFunc: 'sum',
        valueGetter: params => {
          if (params.data) {
            if (params.data.nv) {
              return params.data.nv;
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: 'NO ORDER', field: 'no_order_count', enableRowGroup: true, width: 80, aggFunc: 'sum',
        valueGetter: params => {
          if (params.data) {
            if (params.data.no_order_count) {
              return params.data.no_order_count;
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: 'UNIQUE PC', field: 'unique_pc', enableRowGroup: true, width: 80, aggFunc: 'sum',
        valueGetter: params => {
          if (params.data) {
            if (params.data.unique_pc) {
              return params.data.unique_pc;
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: 'GREEN CALL', field: 'gc_count', enableRowGroup: true, width: 80, aggFunc: 'sum',
        valueGetter: params => {
          if (params.data) {
            if (params.data.gc_count) {
              return params.data.gc_count;
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: 'TOTAL SKU', field: 'total_sku', enableRowGroup: true, width: 80, aggFunc: 'sum',
        valueGetter: params => {
          if (params.data) {
            if (params.data.total_sku) {
              return params.data.total_sku;
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: 'UNIQUE SKU', field: 'unique_sku', enableRowGroup: true, width: 80, aggFunc: 'sum',
        valueGetter: params => {
          if (params.data) {
            if (params.data.unique_sku) {
              return params.data.unique_sku;
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: 'Base UOM QTY', field: 'qty', enableRowGroup: true, width: 80, aggFunc: 'sum',
        valueGetter: params => {
          if (params.data) {
            if (params.data.qty) {
              return params.data.qty;
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: 'AVG UNIT VALUE', field: 'avg_unit_val', enableRowGroup: true, width: 80, aggFunc: this.avgAggFunction,
        valueGetter: params => {
          if (params.data) {
            if (params.data.avg_unit_val) {
              return parseFloat(params.data.avg_unit_val.toFixed(2));
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: 'AVG SKU/ORDER', field: 'avg_sku_order', enableRowGroup: true, width: 80, aggFunc: this.avgAggFunction,
        valueGetter: params => {
          if (params.data) {
            if (params.data.avg_sku_order) {
              return parseFloat(params.data.avg_sku_order.toFixed(2));
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: 'TC/BEAT', field: 'tc_beat', enableRowGroup: true, width: 80, aggFunc:this.avgAggFunction,
        valueGetter: params => {
          if (params.data) {
            if (params.data.tc_beat) {
              return  parseFloat(params.data.tc_beat.toFixed(2));
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: 'PC/BEAT', field: 'pc_beat', enableRowGroup: true, width: 80, aggFunc: this.avgAggFunction,
        valueGetter: params => {
          if (params.data) {
            if (params.data.pc_beat) {
              return  parseFloat(params.data.pc_beat.toFixed(2));
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: 'ORDER VALUE/BEAT', field: 'ord_val_beat', enableRowGroup: true, width: 80, aggFunc: this.avgAggFunction,
        valueGetter: params => {
          if (params.data) {
            if (params.data.ord_val_beat) {
              return  parseFloat(params.data.ord_val_beat.toFixed(2));
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: 'TOTAL SKU/Beat', field: 'total_sku_beat', enableRowGroup: true, width: 80, aggFunc: this.avgAggFunction,
        valueGetter: params => {
          if (params.data) {
            if (params.data.total_sku_beat) {
              return parseFloat(params.data.total_sku_beat.toFixed(2));
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: 'UNIQUE SKU/Beat', field: 'us_per_beat', enableRowGroup: true, width: 80, aggFunc: this.avgAggFunction,
        valueGetter: params => {
          if (params.data) {
            if (params.data.us_per_beat) {
              return parseFloat(params.data.us_per_beat.toFixed(2));
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName:  'MSS', field: 'mss_ach', enableRowGroup: true, width: 80, aggFunc: this.avgAggFunction,
        valueGetter: params => {
          if (params.data) {
            if (params.data.mss_ach) {
              return  parseFloat(params.data.mss_ach.toFixed(2));
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: 'GROWTH CALL', field: 'gc_count', enableRowGroup: true, width: 80, aggFunc: 'sum',
        valueGetter: params => {
          if (params.data) {
            if (params.data.gc_count) {
              return params.data.gc_count;
            } else {
              return 0;
            }
          }
        }
      },
      {headerName: 'WORKING (Minutes)', field: 'working_mins', maxwidth: 80},
      {headerName: 'STATUS', field: 'active', enableRowGroup: true, hide: true, width: 80},
      {headerName: 'REASON', field: 'reason', enableRowGroup: true, hide: true, width: 80}

    ];

  }

  avgAggFunction(params) {
    // the average will be the sum / count
    var sum = 0;
    var count = 0;

    params.values.forEach(function (value) {
      var groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        // we are aggregating groups, so we take the
        // aggregated values to calculated a weighted average
        sum += value.avg * value.count;
        count += value.count;
      } else {
        // skip values that are not numbers (ie skip empty values)
        if (typeof value === 'number') {
          sum += value;
          count++;
        }
      }
    });
    // avoid divide by zero error
    if (count !== 0) {
      var avg = sum / count;
    } else {
      avg = null;
    }

    avg = Math.round((avg + Number.EPSILON) * 100) / 100

    // the result will be an object. when this cell is rendered, only the avg is shown.
    // however when this cell is part of another aggregation, the count is also needed
    // to create a weighted average for the next level.
    var result = {
      count: count,
      avg: avg,
      // the grid by default uses toString to render values for an object, so this
      // is a trick to get the default cellRenderer to display the avg value
      toString: function () {
        return this.avg.toFixed(2);
      },
    };

    return result;
  }
}
