import {Component, OnInit} from "@angular/core";
import {PageBase} from "../../pagebase/pagebase";
import {AGGridEditButtonRendererComponent} from "../../users/AGGridEditButtonRendererComponent";
import {MatDialogConfig} from "@angular/material/dialog";
import {StockUploadComponent} from "./upload_stock/stock_upload.component";
import {DMSBasePage} from "../../pagebase/components/DMSBasePage";


@Component({
  selector: 'app-my-stock-list',
  templateUrl: '../../pagebase/pagebase.html',
  styleUrls: ['../../pagebase/pagebase.scss']
})

export class MyStockListComponent extends PageBase implements OnInit, IPage {

  uom_master_list;

  customFilter: any = {
    name: 'Distributor',
    key: 'id',
    value: 0,
    values: [],
    show: true
  };

  ngOnInit(): void {
    this.sharedService.setHeader('My Stock');
    this.frameworkComponents = {
      buttonRenderer: AGGridEditButtonRendererComponent,
    };
    this.customFilter.values = [
      {id: 0, itemName: 'All'}
    ];
    // this.configureGrid();
    this.showDate = false;
    this.showUpload = true;
    this.customFilter.value = JSON.parse(localStorage.getItem('user')).partner_id;
    this.API_URL = '/api/pwa_admin_connects/get_supplier_stock_with_uom_master';
    this.loadServerData();
  }


  postProcessData() {
    this.configureData();
    this.filteredData = this.rowData;
  }


  configureData() {
    this.rowData = [];
    this.uom_master_list = this.apiData['uom_master'];
    this.rowData = this.apiData['stock'];
    this.configureGrid();
  }

  configureGrid() {

    const inThis = this;
    this.columnDefs = [
      {headerName: 'Supplier Code', field: 'supp_code', hide: true},
      {headerName: 'Supplier', field: 'supplier', hide: true},
      {headerName: 'Product ID', field: 'prod_id'},
      {headerName: 'Product Code', field: 'default_code'},
      {headerName: 'Product Name', field: 'name', width: 250},
      {headerName: 'Category', field: 'category'},
      {headerName: 'Brand', field: 'brand'},
      {headerName: 'Stock', field: 'stock', cellStyle: {textAlign: 'right'}},
      {headerName: 'UOM', field: 'uom', hide: true},
      {headerName: 'Description', field: 'description', valueGetter(params) {
          if (params.data) {
            return DMSBasePage.GetStockDescription(inThis.uom_master_list, params.data.stock, params.data.uom_id);
          } else {
            return '';
          }
        }},
      {
        headerName: 'Cost History', field: 'cost_history', menuTabs: [], cellStyle: {textAlign: 'center'}, hide: true,
        cellRenderer(params) {
          if (params.data) {
            return '<div><button class="btn blue-btn" style="padding: 2px 18px; font-size: 83%">VIEW</button></div>';
          }
        }
      },
      {
        headerName: 'Stock Log', field: 'stock_log', menuTabs: [], cellStyle: {textAlign: 'center'},
        cellRenderer(params) {
          if (params.data) {
            return '<div><button class="btn confirm-green-btn" style="padding: 2px 18px; font-size: 83%">VIEW</button></div>';
          }
        }
      },
      {headerName: 'supplier_id', field: 'dist_id', hide: true},
    ];
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    if (column === 'cost_history') {
      this.goToLogs('cost', event.data);
    } else if (column === 'stock_log') {
      this.goToLogs('stock', event.data);
    }
  }

  goToLogs(value, params) {
    this.sharedService.logData.distributor_id = params.dist_id;
    this.sharedService.logData.product_id = params.prod_id;
    this.sharedService.logData.territory_id = params.territory_id;

    if (value === 'stock') {
      this.router.navigate([this.apiService.redirect_url + '/dms/stock_log'], {
        relativeTo: this.route,
        queryParams: {id: params.prod_id}
      });
    } else if (value === 'cost') {
      this.router.navigate([this.apiService.redirect_url + "/cost_log_entry"]);
    }
  }

  showUploadPopup() {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "90%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
        }
      };
      const creatediaeref = this.dialog.open(StockUploadComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {
        }
      };
      const creatediaeref = this.dialog.open(StockUploadComponent, config);
    }
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllDisplayedColumns().length;
    params.api.setRowData(this.rowData);
    this.preloader = false;
    const inThis = this;

    if (this.showPinnedFooter && this.columnsWithAggregation.length > 0) {
      setTimeout(() => {
        const pinnedBottomData = this.generatePinnedBottomData();
        this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
      }, 500);
    }

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
        if (inThis.pinnedParams.length > 0) {
          inThis.sharedService.resetPinned(inThis.gridOptions, inThis.pinnedParams);
        }
      });
    });

    this.gridApi.sizeColumnsToFit();
    if (inThis.pinnedParams.length > 0) {
      inThis.sharedService.resetPinned(inThis.gridOptions, inThis.pinnedParams);
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');

    /* const columnState = JSON.parse(localStorage.getItem('myColumnState'));
     if (columnState) {
       this.gridColumnApi.applyColumnState(
         {
           state: columnState,
           applyOrder: true,
         }
       );
     }*/

    /*fetch('C:\Users\Nivil\Downloads\pwaconfig.txt').then(response => response.text())
      .then(data => {
        // Do something with your data
        console.log(data);
      });*/

    if (this.sharedService.currListData && this.sharedService.currListData.url === this.router.url) {
      this.agGridFilter = this.sharedService.currListData.agGridFilter;
      this.gridApi.setFilterModel(this.agGridFilter);
    }
    if (this.columnState) {
      this.gridColumnApi.applyColumnState(
        {
          state: this.columnState,
          applyOrder: true,
        }
      );
      this.quickSearch();
    }
  }

}
