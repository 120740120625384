import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {RegenerateComponent} from "../../regenerate.component";
import {RegenerateHeaderComponent} from "../../regenerateHeader.component";
import {PrintInvoiceComponent} from "../../printInvoice.component";
import {PrintClickRenderer} from "../../printClickRenderer.component";
import {CustomGridToolPanelComponent} from "../_common/CustomGridToolPanel.component";
import {EwayCancelComponent} from "../../ewaycancel.component";

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class EwayBillReportComponent extends ReportBase implements IReport, OnInit {
  showCustomFilter = true;
  customFilter: any = {
    name: 'DC',
    key: 'id',
    value: 0,
    values: [],
    show: true
  };

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Eway Bill Report');
    this.customFilter.values = [
      {id: 0, itemName: 'All'}
    ];
    this.customFilter.values = this.customFilter.values.concat(JSON.parse(localStorage.getItem('all_dcs')));
    this.printType = 'eway';
    this.context = {componentParent: this};
    this.frameworkComponents = {
      CustomToolPanel: CustomGridToolPanelComponent,
      regenerateRenderer: RegenerateComponent,
      regenerateHeadercomponent: RegenerateHeaderComponent,
      ewayCancelRenderer: EwayCancelComponent,
      printInvoicecomponent: PrintInvoiceComponent,
      printClickRenderer: PrintClickRenderer
    };
    this.statusConfig.success = 'completed';
    this.statusConfig.error = 'error';
    this.statusConfig.warning = 'processing';
    this.statusConfig.new = 'new';
    this.configureGrid();
  }

  configureGrid() {
    const inThis = this;
    this.API_URL = '/api/pwa_v2_reports/getEwayBillReport';
    this.columnDefs = [
      {headerName: 'Eway Date', field: 'ewb_date'},
      {headerName: 'Eway No', field: 'ewb_no'},
      {
        headerName: 'Valid Upto', field: 'valid_upto',
        valueGetter(params) {
          if (params.data) {
            if (params.data?.config_json?.valid_upto) {
              return inThis.datePipe.transform((new Date(params.data.config_json.valid_upto + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            }
          }
        }
      },
      {headerName: 'Type', field: 'type'},
      {headerName: 'Eway Status', field: 'status', cellRenderer: inThis.sharedService.statusCellRenderer},
      {headerName: 'DC', field: 'dc'},
      {headerName: 'Van', field: 'van'},
      {headerName: 'Code', field: 'code'},
      {headerName: 'Outlet', field: 'outlet'},
      {headerName: 'Order', field: 'order'},
      {headerName: 'Invoice No', field: 'invoice_no'},
      {headerName: 'Comments', field: 'remarks'},
      {headerName: 'order_id', field: 'order_id', width: 80, hide: true},
      {headerName: 'Action', field: 'action', headerComponent: "regenerateHeadercomponent", cellRenderer: "regenerateRenderer", pinned: 'right', width: 150},
      {headerName: 'Print', field: 'print', pinned: 'right', headerComponent: "printInvoicecomponent", cellRenderer: "printClickRenderer", width: 120},
      {headerName: 'Cancel Eway Bill', field: 'cancel_eway', cellRenderer: "ewayCancelRenderer", pinned: 'right', width: 120},
    ];
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    console.log(column);
    if (column === 'print') {
      this.printInvoice();
    }
  }

  printInvoice() {
    const rows = this.gridApi.getSelectedRows();
    if (rows) {
      const paramObject = {
        access_token: localStorage.getItem('resfreshToken'),
        type: rows[0].type,
        vansale_ids: [rows[0].vansale_id],
        id: rows[0].id
      };
      this.apiService.post('/api/eway_bills/getEwayForVansales', paramObject)
        .subscribe(async res => {
          console.log(res);
          if (res.hasOwnProperty('result') && (res['result'].status === 200) && (res['result'].data)) {
            await this.generateEwayPrint(res.result.data);
            const el: HTMLElement = this.interPrint.nativeElement;
            el.click();
          }
        });
    }
  }

  async generateEwayPrint(orderdata) {
    const all_orders = [];
    for (const data of orderdata) {
      if (data?.print_dtls) {
        all_orders.push(data.print_dtls);
      }
    }
    this.print_details = all_orders;
  }

  async regenerate(params, node) {
    const tax_code = params?.config_json?.tax_code;
    if (tax_code) {
      const instances = node.beans.gridApi.getCellRendererInstances({columns: ['action'], rowNodes: [node]});
      const paramObject = {
        access_token: localStorage.getItem('resfreshToken'),
        tax_code,
        vansale_id: params.vansale_id
      };
      let api_url = '';
      if (params.type === 'CarryOver') {
        api_url = '/api/eway_bills/generate_carryover_eway_bills';
        paramObject['vansale_id'] = params.vansale_id;
      } else if (params.type === 'Invoiced') {
        paramObject['orders'] = [params.order_id];
        api_url = '/api/eway_bills/generate_irn_eway_bill';
      } else if (params.type === 'UnInvoiced') {
        paramObject['loadout_orders'] = [params.order_id];
        api_url = '/api/eway_bills/generate_vansale_eway_bills';
      } else if (params.type === 'Consolidated') {
        api_url = '/api/eway_bills/generateConsolidatedEway';
        paramObject['vansale_id'] = params.vansale_id;
      }
      const res_data = await this.apiService.postPromise(api_url, paramObject);
      if (res_data.hasOwnProperty('result') && (res_data['result'].statusCode === 200)) {
        if (res_data['result']?.ewb_nos?.length > 0) {
          this.toastr.success("Regeneration Success");
          this.loadServerData();
        } else {
          if (res_data['result']?.failures && res_data['result']?.failures.length > 0) {
            this.toastr.error("Regeneration Failed!!");
            this.loadServerData();
          } else {
            window.alert(res_data['result'].msg);
          }
        }
        instances.forEach(instance => {
          instance.loader = false;
          instance.enable_regenerate = true;
        });
      } else {
        this.toastr.error("Regeneration Failed!!");
        instances.forEach(instance => {
          instance.loader = false;
          instance.enable_regenerate = true;
        });
      }
    }

  }

  async cancelEway(params, node) {
    const tax_code = params?.config_json?.tax_code;
    if (tax_code) {
      const paramObject = {
        access_token: localStorage.getItem('resfreshToken'),
        order_id: params.order_id
      };
      const res_data = await this.apiService.postPromise('/api/eway_bills/cancelEwayBillforOrder', paramObject);
      if (res_data.hasOwnProperty('result') && (res_data['result'].statusCode === 200)) {
        this.toastr.success("Cancelled Eway Successully");
      } else {
        this.toastr.error("Failed to Cancel Eway");
      }
    }

  }

}
