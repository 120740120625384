import {ReportBase} from '../_common/reportbase';
import {Component, OnInit} from '@angular/core';
import {ErrorHandler} from '../../error/error-handler';


@Component({
  selector: 'app-tcs-report',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class TcsReportComponent extends ReportBase implements OnInit, IReport {
    ngOnInit(): void {
        this.sharedService.setHeader('TCS Report');
        this.showDate = true;
        this.configureGrid();
    }

    setTitle() {
        this.title = 'TCS Report';
    }

    configureGrid() {
        this.API_URL = '/api/pwa_v2_reports/get_tcs_report';
        this.columnDefs = [
            {headerName: 'Invoice No.',  field: 'inv_num',  width: 100},
            {headerName: 'Invoice Date', field : 'inv_date', width: 100},
            {headerName: 'Customer Name', field: 'cust_name', width: 120},
            {headerName: 'PAN of Customer', field: 'pan', width: 150},
            {headerName: 'GSTID', field: 'gstid',  width: 100, hide: true},
            {headerName: 'Taxable Value', field: 'amount_untaxed',  width: 100},
            {headerName: 'Tax Amount', field: 'amount_tax',  width: 100, hide: true},
            {headerName: 'Invoice Value', field: 'amount_total',  width: 100},
            {headerName: 'TCS Rate (%)', field: 'tax_source',  width: 100},
            {headerName: 'TCS Amount', field: 'tcs_amount',  width: 100}
        ];
    }
}
