<div class="dash-card {{category}}" >

  <app-gadget-header
    (mouseover)="showGadgetControls(true)"
    (mouseleave)="showGadgetControls(false)"
    [title]="title"
    [inRun]="inRun"
    [inConfig]="inConfig"
    [actionInitiated]="actionInitiated"
    [showOperationControls]="showOperationControls"
    [gadgetHasOperationControls]="gadgetHasOperationControls"
    [showConfigurationControl]="showConfigurationControl"
    [showControls]="showControls"
    [showDeleteControl]="showDeleteControl"
    (toggleConfigModeEvent)="toggleConfigMode()"
    (removeEvent)="remove()"
    (runEvent)="run()"
    (stopEvent)="stop()"
    (redirectEvent)="redirect()"
    (helpEvent)="help()">
  </app-gadget-header>
<div *ngIf="dataUpdated && !inConfig">
  <div>
    <div class="dash-card-row-one right"
         [ngClass]=data.field2color>
      <h1>{{data.field2}}</h1>
    </div>
    <div class="dash-card-row-one normal">
      <h1>{{data.field1}}</h1>
    </div>
  </div>

  <div>
    <div class="dash-card-row-two-right">
      <label >{{data.field2label}}</label>
    </div>
    <div class="dash-card-row-two">
      <label>{{data.field1label}}</label>
    </div>
  </div>
</div>
  <app-dynamic-form *ngIf="inConfig"
                    [gadgetTags]="gadgetTags"
                    [propertyPages]="propertyPages"
                    [instanceId]="instanceId"
                    (updatePropertiesEvent)="updateProperties($event)">
  </app-dynamic-form>

  <app-error-handler
    [errorObject]="errorObject"
    [errorExists]="errorExists">
  </app-error-handler>

</div>

