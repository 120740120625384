import {Component, ElementRef, OnInit, Renderer2} from "@angular/core";
import {PageBase} from "../pagebase/pagebase";
import {MatDialogConfig} from "@angular/material/dialog";
import {CreateEditTeamComponent} from "./create_edit_team/create_edit_team.component";
import {AGGridEditButtonRendererComponent} from "./AGGridEditButtonRendererComponent";

@Component({
  selector: 'app-team-list',
  templateUrl: '../pagebase/pagebase.html',
  styleUrls: ['../pagebase/pagebase.scss']
})
export class UserTeamComponent extends PageBase implements OnInit, IPage {

  ngOnInit(): void {

    this.frameworkComponents = {
      buttonRenderer: AGGridEditButtonRendererComponent,
    };

    this.sharedService.setHeader('Teams');

    this.showableFilters.region = false;
    this.showableFilters.state = false;
    this.showableFilters.team = false;
    this.customFilter.values = [
      {id: 'true', itemName: 'Active'},
      {id: 'false', itemName: 'Inactive'},
      {id: '', itemName: 'All'}
    ];

    this.showDate = false;
    this.showCreate = true;
    this.configureGrid();
    this.loadServerData();
  }


  configureGrid() {
    this.API_URL = '/api/crm_teams/getTeamListPWA';
    const inthis = this;
    this.columnDefs = [
      {headerName: 'NAME', field: 'name', enableRowGroup: true, width: 150},
      {headerName: 'LEAD', field: 'teamlead', enableRowGroup: true, width: 150},
      {headerName: 'LEAD_USER_ID', field: 'user_id', hide: true},
      {headerName: 'PARENT TEAM', field: 'parent_team', width: 100},
      {headerName: 'PARENT TEAM ID', field: 'parent_id', hide: true},
      {headerName: 'ACTIVE', field: 'status', enableRowGroup: true, width: 80},
      {
        headerName: "CREATE DATE", field: "create_date", width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.create_date) {
              return inthis.datePipe.transform((new Date(params.data.create_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return params.data.create_date;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: 'MEMBERS', field: 'members_count', enableRowGroup: true, width: 80},
      {
        headerName: '',
        cellRenderer: 'buttonRenderer',
        cellRendererParams: {
          onClick: this.onEditClick.bind(this),
          label: 'edit'
        }
      },

    ];
  }

  onEditClick(e) {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "450px",
      maxHeight: "550px",
      data: {
        teamObject : e.rowData
      }
    };

    const confirmdiaeref = this.dialog.open(CreateEditTeamComponent, config);

    confirmdiaeref.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.loadServerData();
      }
    });

  }

  openCreateDialog() {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "450px",
      maxHeight: "550px",
      data: {
        teamObject : null
      }
    };

    const confirmdiaeref = this.dialog.open(CreateEditTeamComponent, config);

    confirmdiaeref.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.loadServerData();
      }
    });

  }

}
