import {ReportBase} from '../_common/reportbase';
import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-outlet-sales-report-v1',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class PrimarySalesDetailedReportComponent extends ReportBase implements IReport, OnInit {

  apiData: any = [];

  /*showCustomFilter = true;

  customFilter:any={
    name:'Sale Type',
    key:'type',
    value:'S',
    values:[],
    show:true};*/

  setTitle() {
    this.title = 'Primary Sales Report - Detailed';
  }

  ngOnInit() {
    this.setTitle();
    this.sharedService.setHeader('Primary Sales Report - Detailed');
    this.API_URL = '/api/pwa_reports/getOutletSalesReportDetailed';
    //this.customFilter.value = 'P';
    this.userData.customFilter['type'] = 'P';
    /*  this.customFilter.values = [
        {id: "S", itemName: "Secondary"},
        {id: "P", itemName: "Primary"},
        {id: "T", itemName: "Tertiary"}
      ];*/
    this.showableFilters.state = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;

  }

  configureGrid() {

    this.columnDefs = [];
    this.columnDefs = [
      {headerName: "Region", field: "region_name",   width: 100},
      {headerName: "Cluster", field: "cluster_name",   width: 100},
      {headerName: "User", field: "user_name",   width: 100},
      {headerName: "Role", field: "user_role",   width: 100},
      {headerName: "Sale Date", field: "ord_date",    width: 100},

      {
        headerName: 'CUSTOMER',
        children: [
          {headerName: "Customer Name", field: "cust_name",   width: 100},
          {headerName: "Customer Code", field: "cust_code",  width: 100},
          {
            headerName: "Customer Status", field: "cust_status",  width: 100,
            valueGetter(params) {
              if (params.data) {
                if (params.data.cust_status) {
                  return 'Active';
                } else {
                  return 'Inactive';
                }
              }
            }
          },
          {headerName: "Customer GST", field: "cust_gst",  width: 100},
          {headerName: "Customer Mobile", field: "cust_mobile",  width: 100},
          {headerName: "Customer Email", field: "cust_email",  width: 100},
          {headerName: "Customer Class", field: "cust_class",  width: 100},
          {headerName: "Customer Type", field: "cust_type",   width: 100},
          {headerName: "Customer Partner Type", field: "cust_partner_type",   width: 100},
          {headerName: "Customer Program Type", field: "cust_pgm_type",   width: 100},
          {headerName: "Customer Lat", field: "cust_lat",  width: 100},
          {headerName: "Customer Long", field: "cust_long",  width: 100},
          {headerName: "Customer Address", field: "cust_address",  width: 100},
          {headerName: "Customer State", field: "cust_state",  width: 100},
          {headerName: "Customer District", field: "cust_zone",  width: 100},
          {headerName: "Customer Area", field: "cust_area",  width: 100},
          {headerName: "Customer City", field: "cust_city",  width: 100},
          {headerName: "Customer Created", field: "cust_created",  width: 100},
          {headerName: "Customer Target", field: "cust_target",  width: 100},
          {headerName: "Customer Manager Code", field: "cust_manager_code",  width: 100},
          {headerName: "Customer Manager", field: "cust_manager",  width: 100},
        ]
      },

      {
        headerName: 'SALES TEAM',

        children: [
          {headerName: "Emp Code", field: "user_code",  width: 100},
          {headerName: "SAC", field: "user_sac",  width: 100},
          {headerName: "Team",  field: "team",  width: 100},
          {headerName: "Team Lead", field: "team_lead",  width: 100},
          {headerName: "User State", field: "user_state",  width: 100},
          /*  { headerName: "Beat ID",  field: "beat_id",   width: 100},
            { headerName: "Beat Name",  field: "beat_name",   width: 100},*/
          {headerName: "Terr Code", field: "terr_code",  width: 100},
          {headerName: "Terr Name", field: "terr_name",  width: 100},
        ]
      },
      {
        headerName: 'SUPPLIER',

        children: [
          {headerName: "Supplier ID", field: "dist_id",  width: 100},
          {headerName: "Supplier Name",  field: "dist_name",  width: 100},
          {headerName: "Supplier Code", field: "dist_code",  width: 100},
          {headerName: "Supplier Address", field: "dist_address",  width: 100},
          {headerName: "Supplier City", field: "dist_city",  width: 100},
          {headerName: "Supplier State", field: "dist_state",  width: 100},
          {headerName: "Supplier GST", field: "dist_gst",  width: 100},
          {headerName: "Supplier PAN", field: "dist_pan",  width: 100},
        ]
      },
      {
        headerName: 'ORDER INFO',

        children: [
          {headerName: "Order ID", field: "ord_id",  width: 100},
          {headerName: "Order Reference", field: "ord_reference",  width: 100},
          {headerName: "Order Type", field: "ord_type",  width: 100},
          {headerName: "Order Status", field: "ord_state",  width: 100},
          {headerName: "Invoice No", field: "invoice_no",  width: 100},
          {headerName: "Delivered Date", field: "dlr_date",  width: 100},
          {headerName: "Line Type", field: "line_type",  width: 100},
        ]
      },
      {
        headerName: 'PRODUCT INFO',

        children: [
          {headerName: "Product Name", field: "prod_name",  width: 100},
          {headerName: "Product Code", field: "prod_code",  width: 100},
          {headerName: "Product Family", field: "prod_family",  width: 100},
          {headerName: "Product Brand", field: "prod_brand",  width: 100},
          {headerName: "Product Category", field: "prod_categ",  width: 100},
          {headerName: "Price", field: "price_unit",  width: 100},
          {headerName: "Secondary Qty", field: "qty",  width: 100, aggFunc: 'sum'},
          {headerName: "Delivered Qty", field: "dlr_qty",  width: 100, aggFunc: 'sum'},
          {headerName: "Invoiced Qty", field: "inv_qty",  width: 100, aggFunc: 'sum'},
          {headerName: "Qty in RUOM", field: "qty_ruom",  width: 100, aggFunc: 'sum'},
          {headerName: "Delivered Qty in RUOM", field: "dlr_qty_ruom",  width: 100},
          {headerName: "Invoiced Qty in RUOM", field: "inv_qty_ruom",  width: 100},
          {headerName: "Line Disc(%)", field: "line_discount_in_pct",  width: 100},
          {headerName: "Line Disc", field: "line_discount_in_amt",  width: 100},
          {headerName: "SPL Disc", field: "spl_discount",  width: 100},
          {headerName: "Scheme Disc", field: "scheme_disc",  width: 100},
          {headerName: "Discount", field: "discount",  width: 100},
          {headerName: "Credits", field: "credits",  width: 100},
          {headerName: "Subtotal", field: "price_subtotal",  width: 100, aggFunc: 'sum'}
        ]
      },
    ];

    const taxMaster = this.apiData.taxmaster;
    for (const key in taxMaster) {
      this.columnDefs.push({
        headerName: taxMaster[key].description,
        field: taxMaster[key].description,
        cellStyle: {textAlign: 'center'},
        width: 100
      });
    }

    this.columnDefs.push({
      headerName: "Tax",
      field: "price_tax",
      cellStyle: {textAlign: 'center'},
      width: 100
    });
    this.columnDefs.push({
      headerName: "Total",
      field: "price_total",
      cellStyle: {textAlign: 'center'},
      width: 100
    });
    this.columnDefs.push({
      headerName: "TDS/TCS", field: "tax_source",  width: 100,
      valueGetter: params => {
        if (params.data) {
          return Math.round((params.data.tax_source + Number.EPSILON) * 100) / 100;
        }
      }
    });
  }

  postProcessData() {
    this.configureGrid();
    this.configureData();
  }

  configureData() {
    this.rowData = [];
    const taxMaster = this.apiData.taxmaster;
    const taxLines = this.apiData.taxlines;

    for (const key in this.apiData.sales) {

      let indrow = {};
      const taxRow: any = {};
      let salesRow: any = {};
      let taxLine: any = [];

      salesRow = this.apiData.sales[key];

      taxLine = taxLines.filter(tax => tax.id === salesRow.line_id);

      for (const key1 in taxMaster) {
        taxRow[taxMaster[key1].description] = taxLine.filter(tax => tax.description === taxMaster[key1].description)[0];
        if (taxRow[taxMaster[key1].description] !== null && taxRow[taxMaster[key1].description] !== undefined) {
          taxRow[taxMaster[key1].description] = taxRow[taxMaster[key1].description].tax_amt;
        }
      }

      indrow = {
        ...salesRow,
        ...taxRow
      };
      this.rowData.push(indrow);
    }
  }
}
