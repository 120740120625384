<div id="main-card" class="eod-list-card"
     (click)="singleClick($event)"
     (dblclick)="doubleCLick($event)"
>
  <div >
    <div style="text-align: center">
      <div style="height: 183px;">
        <img [src]="photo"  style="width: 244px;height: 183px;object-fit: cover;"/>
      </div>
    </div>
    <div style=" padding: 0.8vw;">
      <span class="eod-list-card-heading">{{name}}</span>
      <div class="row" style=" margin-top: 3px;">
        <span class="eod-list-card-desc">{{field1}}</span>
        <span *ngIf="field2" style="margin-left: 6px; margin-right: 6px;"> | </span>
        <span *ngIf="field2" class="eod-list-card-desc" style="margin-right: 6px;">{{field2}}</span>
        <span *ngIf="field3" style="margin-left: 6px; margin-right: 6px;"> | </span>
        <span *ngIf="field3" class="eod-list-card-desc" style="margin-right: 6px;">{{field3}}</span>
        <span [ngClass]="{'statuscompleted': statusType === 'completed',
                         'statussuccess': statusType === 'success',
                         'statusnew': statusType === 'new',
                         'statuspending': statusType === 'pending',
                         'statuserror': statusType === 'error'}" class="ml-1 status2">{{status}}</span>
      </div>
    </div>
  </div>
</div>
