import {ReportBase} from '../_common/reportbase';
import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-retailer-asset-sales',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class DealerBillingReportComponent extends ReportBase implements IReport, OnInit {

  showDate = false;
  showCustomFilter = true;
  customFilter: any = {
    name: 'Period',
    key: 'type',
    value: '',
    values: [],
    show: true
  };
  currentYear;
  currentMonth;
  prvsYear;
  monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
  displayMonths;


  setTitle() {
    this.title = 'Dealer Billing Report';
  }

  ngOnInit() {
    this.setTitle();
    this.sharedService.setHeader('Dealer Billing Report');

    this.currentYear = new Date().getFullYear();
    this.prvsYear = this.currentYear - 1;
    this.currentMonth = new Date().getMonth() + 1;
    this.getCustomFilterValues();
    this.dateCheckandUpdate();
    this.displayMonths = this.dateRange(this.myDate[0], this.myDate[1]);
    this.API_URL = '/api/pwa_reports/getDealerBillingReport';
    //this.loadServerData();
  }

  getCustomFilterValues() {
    if (this.currentMonth < 4) {
      this.customFilter.values = [
        {id: this.currentYear.toString(), itemName: this.prvsYear + '-' + this.currentYear},
        {id: this.prvsYear.toString(), itemName: (this.prvsYear - 1) + '-' + this.prvsYear}
      ];
    } else {
      this.customFilter.values = [
        {id: this.currentYear.toString(), itemName: this.currentYear + '-' + (this.currentYear + 1)},
        {id: this.prvsYear.toString(), itemName: this.prvsYear + '-' + this.currentYear}
      ];
    }
    this.customFilter.values.push({id: 'currentQuarter', itemName: 'Current Quarter'}, {id: 'lastQuarter', itemName: 'Last Quarter'}, {id: 'currentSixMonths', itemName: 'Current 6 Months'}, {id: 'lastSixMonths', itemName: 'Last 6 Months'}, {id: 'currentMonth', itemName: 'Current Month'}, {id: 'lastMonth', itemName: 'Last Month'});
    this.customFilter.value = this.currentYear.toString();
  }

  dateRange(startDate, endDate) {
    const start = startDate.split('-');
    const end = endDate.split('-');
    const startYear = parseInt(start[0], 0);
    const endYear = parseInt(end[0], 0);
    const dates = [];

    for (let i = startYear; i <= endYear; i++) {
      const endMonth = i !== endYear ? 11 : parseInt(end[1], 0) - 1;
      const startMon = i === startYear ? parseInt(start[1], 0) - 1 : 0;
      for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        const month = j + 1;
        //let displayMonth = month < 10 ? '0' + month : month;
        //dates.push([i, displayMonth, '01'].join('-'));
        /*  if (typeof displayMonth === "string") {
            dates.push([{month: displayMonth, display: this.monthNames[parseInt(displayMonth, 0) - 1] + ' ' + i}]);
          }*/
        dates.push({month, display: this.monthNames[month - 1] + ' ' + i});
      }
    }
    console.log(dates);
    return dates;
  }

  setCustomFilter(customfilterdata) {
    if (customfilterdata.hasOwnProperty('type')) {
      this.customFilter.value = customfilterdata.type;
    }
    for (const [key, value] of Object.entries(customfilterdata)) {
      this.userData.customFilter[key] = value;
    }
    this.dateCheckandUpdate();
    this.displayMonths = this.dateRange(this.myDate[0], this.myDate[1]);
  }

  configureGrid() {
    this.columnDefs = [
      {headerName: "STATE", field: "state", width: 100},
      {headerName: "ID", field: "r_id", width: 100},
      {headerName: "RCode", field: "r_code", width: 100},
      {headerName: "Retailer", field: "r_name", width: 100},
      {headerName: "CITY", field: "city", width: 100},
      {headerName: "DISTRICT", field: "zone", width: 100},
      {headerName: "PINCODE", field: "pin", width: 100},
      {headerName: "POTENTIAL", field: "potential", width: 100},
      {headerName: "BEAT ID", field: "b_id", width: 100},
      {headerName: "BEAT", field: "beat", width: 100},
      {headerName: "EMP CODE", field: "emp_code", width: 100},
      {headerName: "SALESMAN", field: "user", width: 100}
    ];
    for (let i = 0; i < this.displayMonths.length; i++) {
      this.columnDefs.push({headerName: this.displayMonths[i].display, field: this.displayMonths[i].month.toString(), width: 100});
    }
    this.columnDefs.push(
      {headerName: "TOTAL", field: "total", width: 100},
      {headerName: "AVG", field: "avg", width: 100},
      {headerName: "COUNTER SHARE", field: "cs", width: 100},
      {headerName: "BILLED MONTHS", field: "nm", width: 100},
      {headerName: "BILLING FREQUENCY", field: "billing_frequency", width: 100},
      {headerName: "NO. OF VISITS", field: "number_of_visits", width: 100},
      {headerName: "AVG. TIME SPENT (MINUTES)", field: "avg_time_spent", width: 100}
    )
  }


  postProcessData() {
    this.configureGrid();
    this.configureData();
  }

  configureData() {
    this.rowData = [];
    const innerthis = this;
    const masterdata = this.apiData;
    if (masterdata !== null && masterdata !== undefined && masterdata.length > 0) {
      masterdata.forEach(obj => {
        let indrow = {};
        indrow = obj;
        indrow['total'] = 0.00;
        indrow['avg'] = 0.00;
        //counter share
        indrow['cs'] = 0.00;
        //number of months
        indrow['nm'] = 0;
        //configuring monthly data
        if (obj.json_agg !== null && obj.json_agg !== undefined && obj.json_agg.length > 0) {
          for (const monthdata of obj.json_agg) {
            indrow[monthdata.M] = monthdata.V;
            indrow['total'] += monthdata.V;
            indrow['nm']++;
          }
        }
        indrow['total'] = indrow['total'].toFixed(2);
        indrow['avg'] = (indrow['total'] / this.displayMonths.length).toFixed(2);
        if (indrow['potential'] !== null && indrow['potential'] !== undefined && indrow['potential'] !== 0) {
          indrow['cs'] = (indrow['total'] / indrow['potential']).toFixed(2);
        } else {
          indrow['cs'] = 0;
        }
        innerthis.rowData.push(indrow);
      });
    }
    console.log(this.rowData);
  }
}
