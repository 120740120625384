import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-outlet-last-visit-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class SaleMetricReportComponent extends ReportBase implements IReport, OnInit {

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Sale Metric Report');
    this.showableFilters.state = true;
    this.showableFilters.team = true;
    this.showableFilters.region = true;
    this.showDate = true;
    const date = new Date();
    this.myDate[0] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd');
    this.userData.start_date = this.myDate[0];
    this.userData.end_date = this.myDate[1];
    this.API_URL = '/api/pwa_v2_reports/saleMetricReportV2';
    this.configureGrid();
  }

  configureGrid() {
    // this.API_URL = '/api/pwa_v2_reports/saleMetricReportV1';

    this.columnDefs = [
      {headerName: 'STATE', field: 'state', width: 80},
      {headerName: 'REGION', field: 'region', width: 80},
      {headerName: 'TEAM', field: 'team', width: 80},
      {headerName: 'TEAM LEAD', field: 'tl_name', width: 80},
      {headerName: 'TEAM LEAD CODE', field: 'tl_code', width: 80},

      {headerName: 'EMP CODE', field: 'emp_code', width: 80},
      {headerName: 'SALESMAN', field: 'salesman', width: 80},
      {headerName: 'ROLE', field: 'role', width: 80},
      {headerName: 'SAC', field: 'sac', width: 80},
      {headerName: 'MOBILE', field: 'user_mob', width: 80},

      {headerName: 'TOTAL BEATS', field: 'total_beats', width: 80},
      {headerName: 'TOTAL PLANNED', field: 'beats_planned', width: 80},
      {headerName: 'CANCELLED BEATS', field: 'cancelled_schedules', width: 80},
      {headerName: 'NOT VISITED BEATS', field: 'beats_nv', width: 80},
      {headerName: 'BEAT REVENUE', field: 'beat_revenue', width: 80},
      {headerName: 'AVG BEAT SALES', field: 'avg_beat_sales', width: 80},

      {headerName: 'OUTLET UNIVERSE', field: 'outlet_universe', width: 80},
      {headerName: 'NEW OUTLETS', field: 'new_outlets', width: 80},
      {headerName: 'KEY OUTLETS', field: 'key_outlets', width: 80},
      {headerName: 'NEW OUTLETS SALES', field: 'new_outlets_sales', width: 80},
      {headerName: 'KEY OUTLETS SALES', field: 'key_outlets_sales', width: 80},

      {headerName: 'UNIQUE VC', field: 'unique_vc', width: 80},
      {headerName: 'ZS OUTLETS', field: 'zs_outlets', width: 80},
      {headerName: 'ZS OUTLETS CONVERTED', field: 'zs_converted', width: 80},
      {headerName: 'ZS OUTLETS CONVERTED SALES', field: 'zs_converted_sales', width: 80},

      {
        headerName: "DISCIPLINARY PARAMETER (AVG)",
        children: [
          {headerName: 'Attendance Time', field: 'avg_check_in', enableRowGroup: true, width: 80},
          {headerName: 'EOD Time', field: 'avg_check_out', width: 80},
          {headerName: "First Activity Time", field: "avg_fat", enableRowGroup: true, width: 100},
          {headerName: "Last Activity Time", field: "avg_lat", enableRowGroup: true, width: 100},
          {headerName: "Retail Time", field: "avg_retail_time", width: 100, enableRowGroup: true},
          {headerName: "Working Time", field: "avg_hour", width: 100, enableRowGroup: true},
          {headerName: "Time Spent / OUTLET", field: "avg_time_spent", width: 100, enableRowGroup: true}
        ]
      },
      {
        headerName: "COVERAGE PARAMETERS",
        children: [
          {headerName: 'Total Universe', field: 'outlet_universe', enableRowGroup: true, width: 80},
          {headerName: 'TC', field: 'tc', width: 80},
          {headerName: "UTC", field: "outlet_universe", width: 100, enableRowGroup: true},
          {headerName: "VC", field: "total_vc", enableRowGroup: true, width: 100},
          {headerName: "Unique VC", field: "unique_vc", enableRowGroup: true, width: 100},
          {headerName: "Beat Jump VC", field: "beat_jump_vc", width: 100, enableRowGroup: true},
          {headerName: "KO VC", field: "ko_vc", width: 100, enableRowGroup: true},
          {headerName: "Unique KO VC", field: "ko_unique_vc", width: 100, enableRowGroup: true},

          {headerName: "PC", field: "total_pc", enableRowGroup: true, width: 100},
          {headerName: "Unique PC", field: "unique_pc", enableRowGroup: true, width: 100},
          {headerName: "Beat Jump PC", field: "beat_jump_pc", width: 100, enableRowGroup: true},
          {headerName: "KO PC", field: "ko_pc", width: 100, enableRowGroup: true},
          {headerName: "Unique KO PC", field: "ko_unique_pc", width: 100, enableRowGroup: true},

          {headerName: "Productivity%(PC/VC)",  width: 100, enableRowGroup: true,
            valueGetter: params => {
                if (params.data) {
                  if (params.data.tc !== 0) {
                    return params.data.total_pc / params.data.tc;
                  } else {
                    return 0;
                  }
                } else {
                  return 0;
                }
              }
          },
          {headerName: "Coverage% (UVC/TC)" , width: 100, enableRowGroup: true,
            valueGetter: params => {
              if (params.data) {
                if (params.data.tc !== 0) {
                  return params.data.unique_vc / params.data.tc;
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }
            },
          {headerName: "Order Outlet% (WRT TOTAL OUTLET )", width: 100, enableRowGroup: true,
            valueGetter: params => {
              if (params.data) {
                if (params.data.tc !== 0 && params.data.tc !== null) {
                  return params.data.total_pc / params.data.tc;
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }
          }
        ]
      },
      {
        headerName: "SALES DETAILS",
        children: [
          {headerName: 'Secondary Sales', field: 'avg_check_in', enableRowGroup: true, width: 80},
          {headerName: 'QTY(In Base UOM)', field: 'ord_qty', enableRowGroup: true, width: 80},
          {headerName: 'QTY(In Reporting UOM)', field: 'report_qty', enableRowGroup: true, width: 80},
          {headerName: 'Order Volume', field: 'ord_qty_volume', enableRowGroup: true, width: 80},

          {headerName: 'Qty Delivered', field: 'dlr_qty', enableRowGroup: true, width: 80},
          {headerName: 'Delivered Volume', field: 'dlr_qty_volume', enableRowGroup: true, width: 80},
          {headerName: 'Fill Rate',  enableRowGroup: true, width: 80,
            valueGetter: params => {
              if (params.data) {
                if (params.data.ord_qty !== 0) {
                  return params.data.dlr_qty / params.data.ord_qty;
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }},

          {headerName: 'Unique SKU', field: 'unique_sku', enableRowGroup: true, width: 80},
          {headerName: 'Unique SKU/PC', field: 'unique_sku_pc', enableRowGroup: true, width: 80},
          {headerName: 'Avg Order/PC', field: 'avg_value_pc', enableRowGroup: true, width: 80},
        ]
      },
      {
        headerName: "Distributor Visits",
        children: [
          {headerName: 'Planned', field: 'supp_planned_visits', enableRowGroup: true, width: 80},
          {headerName: 'Completed', field: 'supp_completed_visits', enableRowGroup: true, width: 80},
          {headerName: 'Time Spent', field: 'supp_hours_spent', enableRowGroup: true, width: 80},
          {headerName: 'Primary Sales', field: 'total_current_month_p_sales', enableRowGroup: true, width: 80},
        ]
      },
      {
        headerName: "BEAT PRODUCTIVTY",
        children: [
          {headerName: 'Beats', field: 'total_beats', width: 80},
          {headerName: 'Order Value/Beat', field: 'avg_beat_sales', enableRowGroup: true, width: 80},
          {headerName: 'SKU /Beat', enableRowGroup: true, width: 80,
            valueGetter: params => {
              if (params.data) {
                if (params.data.total_beats !== 0) {
                  return params.data.total_sku / params.data.total_beats;
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }},
          {headerName: 'Unique SKU /Beat', enableRowGroup: true, width: 80,
            valueGetter: params => {
              if (params.data) {
                if (params.data.total_beats !== 0) {
                  return params.data.unique_sku / params.data.total_beats;
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }},
        ]
      },
      {
        headerName: "TARGETS",
        children: [
          {headerName: 'Secondary Target', field: 'sec_target', width: 80},
          {headerName: 'Secondary Ach', field: 'sec_ach', enableRowGroup: true, width: 80},
          {headerName: 'Secondary Ach%', enableRowGroup: true, width: 80,
            valueGetter: params => {
              if (params.data) {
                if (params.data.sec_target !== 0) {
                  return params.data.sec_ach / params.data.sec_target;
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }},
          {headerName: 'Primary Target', field: 'pri_target', enableRowGroup: true, width: 80},
          {headerName: 'Primary Ach', field: 'pri_ach', enableRowGroup: true, width: 80},
          {headerName: 'Primary Ach%', enableRowGroup: true, width: 80 ,
            valueGetter: params => {
              if (params.data) {
                if (params.data.pri_target !== 0) {
                  return params.data.pri_ach / params.data.pri_target;
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }
          },
          {headerName: 'MTD Target', field: 'mtd_target', enableRowGroup: true, width: 80},
          {headerName: 'MTD Ach', field: 'mtd_ach', enableRowGroup: true, width: 80},
          {headerName: 'MTD Ach%', enableRowGroup: true, width: 80,
            valueGetter: params => {
              if (params.data) {
                if (params.data.mtd_target !== 0) {
                  return  params.data.mtd_ach / params.data.mtd_target;
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }
          },
        ]
      },
      {
        headerName: "NEW BUSINESS",
        children: [
          {headerName: 'New Outlets', field: 'new_outlets', width: 80},
          {headerName: 'New Outlets PC', field: 'new_outlet_pc', enableRowGroup: true, width: 80},
          {headerName: 'New Outlets Sales', field: 'new_outlets_sales', enableRowGroup: true, width: 80}
        ]
      },
      {
        headerName: "ASSETS DETAILS",
        children: [
          {headerName: 'Requested', field: 'created', enableRowGroup: true, width: 80},
          {headerName: 'Approved', field: 'approved', enableRowGroup: true, width: 80},
          {headerName: 'Rejected', field: 'rejected', enableRowGroup: true, width: 80}
        ]
      },
      {
        headerName: "SURVEY",
        children: []
      },
      {
        headerName: "LEAD DETAILS",
        children: [
          {headerName: 'New Leads', field: 'new_leads', enableRowGroup: true, width: 80},
          {headerName: 'Won Leads', field: 'won_leads', enableRowGroup: true, width: 80},
          {headerName: 'Lost Leads', field: 'lost_leads', enableRowGroup: true, width: 80},
          {headerName: 'Lead Activity', field: 'activity_cnt', enableRowGroup: true, width: 80}
        ]
      },
      {
        headerName: 'Beatwise Details', width: 80,
        valueGetter(params) {
          if (params.data) {
            return 'View';
          } else {
            return '';
          }
        }, cellRenderer: this.sharedService.statusCellRenderer
      },
      {
        headerName: 'User Activity', width: 80,
        valueGetter(params) {
          if (params.data) {
            return 'View';
          } else {
            return '';
          }
        }, cellRenderer: this.sharedService.statusCellRenderer
      }
    ];
    let uniqueSurveys = [];
    if (this.apiData.survey && this.apiData.survey.length > 0){
      uniqueSurveys = this.apiData.survey.map(p => p.name)
        .filter((type, index, arr) => arr.indexOf(type) === index);

      for (const key in uniqueSurveys) {
        this.columnDefs[33].children.push(
          {headerName: uniqueSurveys[key], field: uniqueSurveys[key], menuTabs: [], width: 60, cellStyle: {textAlign: 'center'}},
        );
      }
    }
  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }

  configureData() {
    this.rowData = [];
    for (var key in this.apiData.main) {
      var indrow = {};
      var mainrow: any = {};
      var surveysrow: any = [];
      var surveyrow: any = [];

      mainrow = this.apiData.main[key];
      if (this.apiData.survey && this.apiData.survey.length > 0){
        surveysrow = this.apiData.survey.filter(survey => survey.user_id === mainrow.salesman_id);
        if (surveysrow != null && surveysrow.length > 0) {
          for (const i in surveysrow) {
            surveyrow[surveysrow[i].name] = surveysrow[i].survey_cnt;
          }
        }
      }
      indrow = {
        ...mainrow,
        ...surveyrow
      };
      this.rowData.push(indrow);

    }
  }
  onCellClicked(event) {
    if (event.colDef.headerName === 'Beatwise Details' && event.data.salesman) {
      this.redirectToDetailedReport(event.data.salesman);
      console.log("Redirecting with Salesman - " + event.data.salesman);
    }
    if (event.colDef.headerName === 'User Activity' && event.data.salesman) {
      this.redirectToUserActivityReport(event.data.salesman_id);
      console.log("Redirecting with Salesman - " + event.data.salesman);
    }
  }

  private redirectToDetailedReport(salesman) {
    localStorage.setItem('user_id', salesman);
    localStorage.setItem('start_date', this.userData.start_date);
    localStorage.setItem('end_date', this.userData.end_date);
    this.router.navigate([this.apiService.redirect_url + '/reports/sale_metric_beatwise_report'], {
      relativeTo: this.route,
      queryParams: {user_id: salesman}
    });
  }

  private redirectToUserActivityReport(salesman_id) {
    localStorage.setItem('user_id', salesman_id);
    localStorage.setItem('start_date', this.userData.start_date);
    localStorage.setItem('end_date', this.userData.end_date);
    this.router.navigate([this.apiService.redirect_url + '/reports/user_activity'], {
      relativeTo: this.route,
      queryParams: {redirect: 'sale_metric'}
    });
  }

}
