import {Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {ApiService} from '../../_services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {DatePipe} from "@angular/common";
import {ToastrService} from "ngx-toastr";
import {MatMenuTrigger} from "@angular/material";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {ErrorObject} from "../../error/error-model";
import {SharedService} from "../../_services/shared_service";

@Component({
  selector: 'app-delivery-plan-add-invoice-popup',
  templateUrl: './delivery_plan_add_invoice_popup.component.html',
  styleUrls: ['./delivery_plan_add_invoice_popup.component.scss']

})


export class DeliveryPlanAddInvoicePopupComponent implements OnInit {

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @Output() updatedDP: EventEmitter<any> = new EventEmitter<any>();

  access_token;

  public myDate = [];
  filterSet = false;
  showableFilters = {
    max_claim: false,
    policy: false,
    salesman: false,
    status: false,
    class: false,
    beat: false,
    trax_outlet: false,
    non_trax_outlet: false,
    exclude_trax_audit: false,
    region: false,
    cluster: false,
    team: false,
    territory: false,
    outlet_type: false,
    payment_mode: false,
    dc: false,
    state: false
  };
  userData = {
    start_date: null,
    end_date: null,
    period_id: 0,
    access_token: null,
    url: null,
    offset: null,
    offsetID: 0,
    flag: true,
    type: '',
    data: {
      id: null,
      date: null
    },
    filterData: {
      date: null,
      access_token: null,
      url: null,
      last_date: null,
      offset: null,
      flag: true,
      out_type: -1,
      salesmanID: 0,
      policyID: 0,
      outlet_type: 0,
      travel_type_id: 0,
      class_type: null,
      program_type: 0,
      status: null,
      max_claim: false,
      trax_outlet: false,
      non_trax_outlet: false,
      exclude_trax_audit: false,
      region_id: 0,
      cluster_id: 0,
      team_id: 0,
      territory_id: 0,
      dc_id: 0,
      displayData: [],
      payment_mode: ''
    },
    customFilter: {}
  };
  public columnDefs = [];
  public searchValue;
  public frameworkComponents;
  public rowData = [];
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public dataLoaded = false;
  public preloader = false;
  public emptyState = false;
  errorObject: ErrorObject;
  errorExists = false;
  private gridApi;
  private gridColumnApi;
  context;
  public styleGrid;
  API_URL = '';
  deliveryPlanData;
  invoiceSelected = false;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              public dialog: MatDialog,
              private sharedService: SharedService,
              private datePipe: DatePipe,
              private toastr: ToastrService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {

    this.deliveryPlanData = dialogdata.dpData;

    this.access_token = localStorage.getItem('resfreshToken');

    const today = new Date();
    today.setDate(today.getDate() - 30);
    this.myDate[0] = this.datePipe.transform(today, 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.userData.start_date = this.myDate[0];
    this.userData.end_date = this.myDate[1];
    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.state = true;
    this.showableFilters.region = true;

    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      // groupDisplayType: 'groupRows',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      rowGroupPanelShow: 'always',
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheader',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      /* onColumnVisible: this.onColumnVisible,
       onDragStopped : this.onDragStopped*/
    } as GridOptions;
    this.configureGrid();
    this.getInvoices();
  }

  ngOnInit(): void {

  }

  configureGrid() {
    this.API_URL = '/api/inv_delivery_plans/get_new_invoices_for_delivery_plan_create';
    this.columnDefs = [
      {headerName: 'Invoice', field: 'inv_no', width: 230, checkboxSelection: true},
      {headerName: "Customer", field: "cust_name", width: 150},
      {headerName: "Salesman", field: "sman", width: 150},
      {headerName: "Beat", field: "beat", width: 120},
      {headerName: "Grand Total", field: "grand_total", width: 80},
      {headerName: "Qty", field: "quantity", width: 60},
      /*  {headerName: "Cases", field: "cluster", width: 70},
        {headerName: "Units", field: "cluster", width: 70},
        {headerName: "Pcs", field: "cluster", width: 70},*/
    ];
  }

  getInvoices() {
    this.dataLoaded = false;
    this.preloader = true;
    this.emptyState = false;
    this.rowData = [];
    const paramObject: any = {};
    paramObject.API_URL = this.API_URL;
    paramObject.access_token = localStorage.getItem('resfreshToken');
    /* paramObject.order_status = "draft";
     paramObject.order_type = "sale";
     paramObject.pay_status = "new";
     paramObject.status = "new";*/
    paramObject.d_status = "new";
    paramObject.type = "S";
    paramObject.start_date = this.userData.start_date;
    paramObject.end_date = this.userData.end_date;
    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
          this.rowData = res['results'].data;
          //this.rowData should be ready now
          this.preloader = false;
          if (this.rowData === null || this.rowData === undefined) {
            this.emptyState = true;
          } else if (this.rowData.length === 0) {
            this.emptyState = true;
          } else {
            this.dataLoaded = true;
          }
        }
      });
  }

  setFilter(filterdata) {
    this.userData.filterData.displayData = [...this.userData.filterData.displayData];
    this.userData.filterData = filterdata;
    this.trigger.closeMenu();
  }

  removeFilter(displayData: any) {
    this.sharedService.filterCancelCLick(displayData.key);
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  quickSearch() {
    this.gridApi.setQuickFilter(document.getElementById('filter-text-box')['value']);
    console.log(this.gridApi);
  }

  public handleError(error: ErrorObject) {
    this.preloader = false;
    this.emptyState = false;
    this.dataLoaded = false;
    this.errorExists = true;
    this.errorObject = error;
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllColumns().length;

    params.api.setRowData(this.rowData);
    this.preloader = false;
    const inThis = this;

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        if (noOfColumns < 11 && screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeAllColumns();
        }
      });
    });
    if (noOfColumns < 11 && screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeAllColumns();
    }
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  onCellClicked(event) {

  }

  onRowSelected(event) {
    const count = this.gridApi.getSelectedRows();
    if (count.length > 0) {
      this.invoiceSelected = true;
    } else {
      this.invoiceSelected = false;
    }
  }

  addInvoices() {
    const rows = this.gridApi.getSelectedRows();
    // tslint:disable-next-line:forin
    const invoiceids = [];

    rows.forEach(r => {
     invoiceids.push(r.inv_id);
    });

    const context_data = {
      access_token: this.access_token,
      delivery_plan_id: this.deliveryPlanData.id,
      invoice_ids: invoiceids,
      action: 'add'
    };
    this.apiService.post('/api/inv_delivery_plans/update_invoice_with_delivery_plan', context_data)
      .subscribe(async res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
          this.updatedDP.emit(this.deliveryPlanData.id);
        } else {
          this.toastr.error('Failed To Remove Invoice');
        }
      });
  }

  changeDate(dt) {
    this.userData.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.userData.end_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
  }
}
