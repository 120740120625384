import {Component, OnInit} from "@angular/core";
import {ReportBase} from "../_common/reportbase";

@Component({
  selector: 'app-pre-order-van-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class Pre_order_vanComponent extends ReportBase implements IReport, OnInit  {

 /* showableFilters={
    max_claim:false,
    policy:false,
    salesman:false,
    status:false,
    class:false,
    beat:false,
    trax_outlet:false,
    non_trax_outlet:false,
    exclude_trax_audit:false,
    region:false,
    cluster:false,
    team:false,
    territory:false,
    outlet_type: true,
    dc: true
  }*/

  setTitle() {
    this.title = 'Van Pre Order Report';
  }

  ngOnInit() {

    this.sharedService.setHeader('Van Pre Order Reports');
    this.configureGrid();

    this.showableFilters.outlet_type = true;
    this.showableFilters.dc = true;
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/pre_order_van_report';
    // this.filterConfig.salesman = true;
    // this.filterConfig.outletType = true;
    this.columnDefs = [
      { headerName: 'DC',  field: 'dc_name',   width: 100},
      { headerName: 'Van',  field: 'user',
        width: 100, },
      { headerName: 'Invoice Date',  field: 'planned_date',
        width: 100},
      { headerName: 'Product',  field: 'product_name', cellStyle: {textAlign: 'center'},
          width: 100},
      { headerName: 'Prod Code',  field: 'product_code', cellStyle: {textAlign: 'right'},
           width: 80},
      { headerName: 'Qty',  field: 'qty', cellStyle: {textAlign: 'right'},
          width: 80}
    ];
  }

  // loadServerData() {
  //   var indrow = {
  //     "category":"Scotch",
  //     "brands":"Black & White",
  //     "volume":"231 Cases",
  //     "market_share":"64%",
  //     "growth":"20%",
  //   }
  //
  //   this.rowData.push(indrow);
  //   this.preloader = false;
  //   this.emptyState = false;
  //
  //   this.dataLoaded = true;
  // }

}
