<div class="cardbox" style="border: 1px solid #D7DDE6;background: #FFFFFF;position: absolute;
    bottom: 15px;
    top: 15px;
    left: 15px;
    right: 15px;">
  <div>
    <h3>{{meetDetails.meet_name}}</h3>
    <div class="mt-4 meetingday" style="border-radius: 3px; padding: 20px;">
      <span style="color: #2D2D2D; font-size: 12px;font-weight: 600;">Meet Details</span>
      <div class="row centeralign" style="margin-top: 10px;">
        <span class="detaillabel mr-2">Meet Name</span>
        <span class="detailvalue">{{meetDetails.meet_name}}</span>
      </div>
      <div class="row centeralign" style="margin-top: 5px;">
        <span class="detaillabel mr-2">Date & Time</span>
        <span *ngIf="days !== 0" class="detailvalue" style="color: #757BC8">{{meetDetails.planned_date}}</span>
        <span *ngIf="days === 0" class="detailvalue">{{meetDetails.planned_date}}</span>
      </div>
      <div class="row centeralign" style="margin-top: 5px;">
        <span class="detaillabel mr-2">Meet Type</span>
        <span class="detailvalue">{{meetDetails.type}}</span>
      </div>
      <div class="row centeralign" style="margin-top: 5px;">
        <span class="detaillabel mr-2">Sponsors</span>
        <span class="detailvalue">{{sponsors}}</span>
      </div>
      <div class="row centeralign" style="margin-top: 5px;">
        <span class="detaillabel mr-2">Organiser</span>
        <span class="detailvalue">{{meetDetails.user}}</span>
      </div>
      <div *ngIf="meetDetails.mobile" class="row centeralign">
        <a class="detailvaluedesc" href="tel:{{meetDetails.mobile}}"><img style="margin-right: 5px;height: 10px;"
                                                                          src="../../../../assets/images/call.svg"
                                                                          alt="Call Organiser"/>Call Organiser</a>
      </div>
    </div>
    <div *ngIf="dataLoaded && (days < 0 || meetCompleted)" class="mt-2" style="display: flex;">
      <div style="display: inline-block;">
        <img src="../../../../assets/warning_red.svg">
      </div>
      <div style="display: inline-block; margin-left: 10px;">
        <p *ngIf="meetCompleted" style="display: inline;color: #E24260;margin-left: 1%; font-size: 11px;">Attendance cannot be marked after meeting is completed</p>
        <p *ngIf="days < 0 && !meetCompleted" style="display: inline;color: #E24260;margin-left: 1%; font-size: 11px;">Attendance cannot be marked as it
          was not recorded within 24 hours of the meeting</p>
      </div>
    </div>
    <div *ngIf="dataLoaded && days >= 0 && !meetCompleted">
      <span style="color: #2D2D2D; font-size: 12px;font-weight: 600;margin-top: 30px; display: block;">Enter your details below to register for the meeting</span>
      <form novalidate id="ngForm" #attendeeform="ngForm">
        <div style="margin-top: 20px;">
          <span class="dialoglabel">Name</span>
          <span class="requiredspan"> *</span>
          <input
            class="forminputwhitebg"
            style="display: block; font-size: 14px; margin-top: 5px;width: 100%;"
            type="text"
            name="name"
            [(ngModel)]="attendeeDetails.name"
            #name="ngModel"
            placeholder="Enter Name"
            required
          >
          <div *ngIf="name.errors && (name.dirty || name.touched)">
            <span *ngIf="name.errors.required" class="text-danger">Name is required</span>
          </div>
        </div>

        <div style="margin-top: 20px;">
          <span class="dialoglabel">Mobile</span>
          <span class="requiredspan"> *</span>
          <input
            class="forminputwhitebg"
            style="display: block; font-size: 14px; margin-top: 5px;width: 100%;"
            type="number"
            name="mobile"
            [(ngModel)]="attendeeDetails.mobile"
            #mobile="ngModel"
            maxlength="10"
            placeholder="Enter your 10 digit Mobile number"
            required
          >
          <div *ngIf="mobile.errors && (mobile.dirty || mobile.touched)">
            <span *ngIf="mobile.errors.required" class="text-danger">Mobile is required</span>
          </div>
        </div>

        <div style="margin-top: 20px;">
          <span class="dialoglabel">Email</span>
          <input
            class="forminputwhitebg"
            style="display: block; font-size: 14px; margin-top: 5px;width: 100%;"
            type="text"
            name="email"
            [(ngModel)]="attendeeDetails.email"
            #email="ngModel"
            (input)="validateEmail()"
            placeholder="Enter Email"
          >
          <span *ngIf="invalidEmail" class="text-danger">Invalid Email</span>
        </div>
        <div *ngIf="days >= 0 && !meetCompleted" class="button-row" style="position:absolute; bottom: 20px; left: 15px; right:15px;">
          <button
            mat-button
            style="width: 100%;"
            class="btn confirm-blue-btn"
            (click)="verifyAttendee()"
            form="ngForm"
            [disabled]="attendeeform.form.invalid || (attendeeDetails.email && invalidEmail)"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
