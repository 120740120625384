import {Component} from "@angular/core";
import {IToolPanel, IToolPanelParams} from "@ag-grid-enterprise/all-modules";
import {CustomConfigService} from "../../_services/CustomConfigService";
import {FavoritesDialog} from "./dialog-favoraite.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-cell-editor2',
  template: `
    <div style="margin:8px;">
      <div>
        <!-- <h2><i style="padding: 8px" class="fas fa-tools"></i>Tools</h2>-->
        <label style="font-size: 18px;font-weight: 400;">Grid Options</label>
        <div style="margin-top: 10px;">
          <button class="btn blue-btn"
                  style="margin-right: 8px; padding: 2px 5px !important; font-size: 80% !important"
                  (click)="expandAll()">Expand All
          </button>
          <button class="btn blue-btn"
                  style="padding: 2px 5px !important; font-size: 80% !important"
                  (click)="collapseAll()">Collapse All
          </button>
        </div>
      </div>
      <hr style="  display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;"/>
      <div style="margin-top: 15px;">
        <label style="font-size: 18px;font-weight: 400;">Report Configurations</label>
        <span *ngIf="user_report_config.length > 0" style="display: block;margin-top: 10px;">
        <!--  <ul class="items" *ngFor="let item of user_report_config">
            <li><input [value]="item['id']" type="checkbox" [(ngModel)]="profile" />{{item.name}}</li>
          </ul>-->
          <ul style="padding: 0">
              <li *ngFor="let item of userConfigs">
                <mat-checkbox
                  [(ngModel)]="item.selected"
                  (ngModelChange)="onCheckBoxSelected(item)"
                >
                  {{item.name}}
                </mat-checkbox>
              </li>
         </ul>
        </span>
        <span *ngIf="userConfigs.length === 0" style="display: block;margin-top: 10px;">
          No Configurations Added. Click on New to save the current grid configuration
        </span>
        <div style="margin-top: 10px;">
          <button class="btn green-btn1"
                  style="margin-right: 8px; padding: 2px 5px !important; font-size: 80% !important"
                  (click)="onSaveProfileClicked()">Save New Favorite
          </button>
          <button *ngIf="userConfigs.length > 0 && selected_fav_name !== '' " class="btn blue-btn"
                  style="padding: 2px 5px !important; font-size: 80% !important"
                  (click)="delete_state()">Delete
          </button>
        </div>
      </div>
    </div>`,
})

export class CustomGridToolPanelComponent implements IToolPanel {

  selected_fav_name;
  report_component_name;
  user_report_config = [];
  role_report_config = [];
  configService;
  userConfigs = [];
  private params: IToolPanelParams;
  private user_report_json: any;

  constructor(protected customConfigService: CustomConfigService,
              public dialog: MatDialog) {
    this.configService = customConfigService;
  }

  agInit(params: IToolPanelParams): void {
    this.params = params;
    this.report_component_name = this.params['reportname'];
    this.get_favorites();
    // calculate stats when new rows loaded, i.e. onModelUpdated
    this.params.api.addEventListener('modelUpdated', this.calculateStats.bind(this));
  }

  load_favorites() {
    this.user_report_config.forEach(fav => {
      if (this.selected_fav_name === fav.name) {
        if (Object.keys(fav.columnState).length) {
          this.params.columnApi.applyColumnState(
            {
              state: fav.colState,
              applyOrder: true,
            }
          );
        }
      }
    });
  }

  get_favorites() {
    this.user_report_config = [];
    this.userConfigs = [];
    this.configService.getReportByName(this.report_component_name).subscribe(rConfig => {
      this.user_report_json = rConfig;
      if (rConfig !== undefined && rConfig.hasOwnProperty('config') && rConfig['config'].length > 0) {
        this.user_report_config = rConfig['config'];
        let selectedFav = false;
        let default_col_state = [];
        this.user_report_config.forEach(fav => {
          if (Object.keys(fav.columnState).length > 0) {
            if (fav.name === this.user_report_json.default) {
              selectedFav = true;
              default_col_state = fav.columnState;
            } else {
              selectedFav = false;
            }
          }
          this.userConfigs.push({name: fav.name, selected: selectedFav, colState : fav.columnState});
        });

        if (rConfig !== undefined && rConfig.hasOwnProperty('role_config') && rConfig['role_config'].length > 0) {
          this.role_report_config = rConfig['role_config'];
          this.role_report_config.forEach(fav => {
            this.userConfigs.push({name: fav.name, selected: false, colState : fav.columnState});
          });
        }

        if (Object.keys(default_col_state).length) {
           this.params.columnApi.applyColumnState(
             {
               state: default_col_state,
               applyOrder: true,
             }
           );
         }
      }
    });

  }

  onSaveProfileClicked() {
    let fav_name = '';
    if (this.user_report_config) {
      fav_name = 'Favorites ' + parseInt(String(this.user_report_config.length + 1), 10);
    }
    const config = {
      name: fav_name,
      default: false
    };


    const dialogRef = this.dialog.open(FavoritesDialog, {
      width: '300px',
      data: {favoritesData: config}
    });
    dialogRef.afterClosed().subscribe(fav_response => {
      const fav_config = {};
      if (fav_response !== undefined && fav_response.hasOwnProperty('name')) {
        fav_config['name'] = fav_response['name'];
        fav_config['columnState'] = JSON.parse(JSON.stringify(this.params.columnApi.getColumnState()));
        let isDefaultFav = false;
        if (fav_response.hasOwnProperty('default') && fav_response['default'] === true) {
          isDefaultFav = true;
        }
        this.configService.addReportConfig(this.report_component_name, fav_config, isDefaultFav).subscribe(rConfig => {
          this.user_report_config = rConfig['config'];
          this.get_favorites();
        });
      }
    });
  }

  onCheckBoxSelected(item) {
    this.selected_fav_name = item.name;
    this.userConfigs.forEach(fav => {
      if (item.name === fav.name) {
        this.params.columnApi.applyColumnState(
          {
            state: fav.colState,
            applyOrder: true,
          }
        );
      } else {
        fav.selected = false;
      }
    });
  }

  delete_state() {
    this.customConfigService.deleteReportConfig(this.report_component_name, this.selected_fav_name).subscribe(rConfig => {
      this.get_favorites();
    });
  }

  refresh() {
  }

  public expandAll() {
    this.params.api.expandAll();
  }

  collapseAll() {
    this.params.api.collapseAll();
  }



  calculateStats(params) {
  }
}
