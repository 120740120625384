<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" style="float: right;background-color:#FDE0D4;">
        <i class="fas fa-times"></i>
      </button>
      <h2>Product Visibility</h2>
    </div>
    <div class="body-section w-100" style="text-align: center;">
      <div class="col-md-6  pl-4 pr-5">
        <span style="display: block;margin-bottom: 15px;">Before</span>
        <img [src]="photo1" style="width:90%;height:90%">
        <div style="margin-top:3%;">
          <label style="font-weight: 600;">Visibility: </label><span style="margin-left: 3%">{{beforevis}}</span>
        </div>
      </div>
      <div class="col-md-6  pl-4 pr-5">
        <span style="display: block;margin-bottom: 15px;">After</span>
        <img [src]="photo2" style="width:90%;height:90%">
        <div style="margin-top:3%;">
          <label style="font-weight: 600;">Visibility: </label><span style="margin-left: 3%">{{aftervis}}</span>
        </div>
      </div>
      <div class="col-md-12  pl-4 pr-5">
        <div style="margin-top:3%;">
          <label style="font-weight: 600;">Product Category: </label><span style="margin-left: 3%">{{category}}</span>
        </div>
      </div>
      <div class="col-md-12  pl-4 pr-5">
        <div style="margin-top:3%;">
          <label style="font-weight: 600;">Comments: </label><span style="margin-left: 3%">{{comments}}</span>
        </div>
      </div>
    </div>
  </div>
</section>
