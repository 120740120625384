<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" style="float: right;background-color:#FDE0D4;">
        <i class="fas fa-times"></i>
      </button>
      <h2>Review Meet</h2>
    </div>
    <div class="body-section" style="margin-top: 20px;">
      <div style="margin-top: 20px;">
        <span class="headerlabel">Comments</span>
        <textarea
          class="forminputwhitebg"
          style="display: block; color: #7D7D7D; font-size: 14px;margin-top: 5px;width: 100%;height:100px;"
          type="text"
          name="comments"
          [(ngModel)]="review_comments"
          #comments="ngModel"
          placeholder="Enter Comments"
        ></textarea>
      </div>
      <div style="margin-top: 20px;">
        <button (click)="performAction()" data-dismiss="modal" class="btn blue-btn">Submit</button>
      </div>
    </div>
  </div>
</section>
