import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-partner-raw-checkin-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class PartnerRAWCheckinComponent extends ReportBase implements IReport, OnInit {

  public attributeTypes = [];
  showCustomFilter = false;
  apiData: any = [];
  uqTypes;

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('RAW Partner Checkin Report');

    // this.showableFilters.cluster = true;
    // this.showableFilters.territory = true;
    // this.showableFilters.salesman = true;
    this.showableFilters.state = true;
    this.showableFilters.outlet_type = true;
    this.showDate = true;

    this.loadInBatches(50000, 'visit_id');
    this.showProgressCustomText = true;
    this.API_URL = '/api/pwa_v2_reports/getRawCheckin';
  }

  configureGrid() {
    const inThis = this;
    this.columnDefs = [
      {
        headerName: "Partner Details",
        children: [
          {headerName: 'PARTNER ID', field: 'retailer_id', width: 80},
          {headerName: 'PARTNER NAME', field: 'outlet_name', enableRowGroup: true, width: 80},
          {headerName: 'CODE', field: 'outlet_code', width: 80},
          {headerName: 'TERRITORY', field: 'partner_territory', enableRowGroup: true, width: 100},
          {headerName: 'STATE', field: 'partner_state', enableRowGroup: true, width: 80},
          {headerName: 'TYPE', field: 'partner_type', enableRowGroup: true, width: 80},
          {headerName: 'OUTLET TYPE', field: 'outlet_type', enableRowGroup: true, width: 80},
          {headerName: 'CLASS', field: 'partner_class', enableRowGroup: true, width: 80},
          {headerName: 'PROGRAM TYPE', field: 'program_type', enableRowGroup: true, width: 80}
        ]
      }, {
        headerName: "Visit Details",
        children: [
          {headerName: 'VISIT TYPE', field: 'visit_type', enableRowGroup: true, width: 80},
          {headerName: 'COMPLETION', field: 'visit_completion', enableRowGroup: true, width: 80},
          {headerName: 'VISITED USER', field: 'visited_user', enableRowGroup: true, width: 80},
          {headerName: 'EMPLOYEE CODE', field: 'visited_emp_code', width: 80},
          {
            headerName: 'VISIT START', field: 'visit_start', width: 80, valueGetter: params => {
              if (params.data.visit_start) {
                return inThis.datePipe.transform((new Date(params.data.visit_start + ' UTC')).toString(), 'yyyy-MM-dd HH:mm');
              } else {
                return 'NA';
              }
            }
          },
          {
            headerName: 'VISIT END', field: 'visit_end', width: 80, valueGetter: params => {
              if (params.data.visit_end) {
                return inThis.datePipe.transform((new Date(params.data.visit_end + ' UTC')).toString(), 'yyyy-MM-dd HH:mm');
              } else {
                return 'NA';
              }
            }
          },
          {headerName: 'COMMENT', field: 'visit_comment', width: 80},
          {headerName: 'SYNC TIME', field: 'sync_time', width: 80, valueGetter: params => {
              if (params.data.sync_time) {
                return inThis.datePipe.transform((new Date(params.data.sync_time + ' UTC')).toString(), 'yyyy-MM-dd HH:mm');
              } else {
                return 'NA';
              }
            }
          },
          {headerName: 'UPDATED ON', field: 'update_time', width: 80, valueGetter: params => {
              if (params.data.update_time) {
                return inThis.datePipe.transform((new Date(params.data.update_time + ' UTC')).toString(), 'yyyy-MM-dd HH:mm');
              } else {
                return 'NA';
              }
            }
          },
          {headerName: 'ID', field: 'visit_id', width: 80},
          {headerName: 'DEVIATION', field: 'distance', width: 80}
        ]
      }
    ];
  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }


  configureData() {
    this.rowData = this.apiData;
    console.log(this.rowData);
  }
}
