import {Component, ElementRef, NgZone, OnInit, Renderer2, ViewChild} from "@angular/core";
import {ApiService} from "../../../_services/api.service";
import {Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {SharedService} from "../../../_services/shared_service";
import {DomSanitizer} from "@angular/platform-browser";
import {ToastrService} from "ngx-toastr";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {ErrorObject} from "../../../error/error-model";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {CustomGridToolPanelComponent} from "../../../reports/_common/CustomGridToolPanel.component";
import {ErrorHandler} from "../../../error/error-handler";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {DeliveryPlanAddInvoicePopupComponent} from "../../../components/delivery_plan_add_invoice_popup/delivery_plan_add_invoice_popup.component";
import {ConfirmDialogComponent} from "../../../components/confirmdialog/confirmdialog.component";

@Component({
  selector: 'app-delivery-plan-detail',
  templateUrl: './delivery-plan-detail.component.html',
  styleUrls: ['./delivery-plan-detail.component.scss']
})
export class DeliveryPlanDetailComponent implements OnInit {
  @ViewChild('interPrint') public interPrint: ElementRef;

  apiData = [];
  userData = {
    start_date: null,
    end_date: null,
    period_id: 0,
    access_token: null,
    url: null,
    offset: null,
    offsetID: 0,
    flag: true,
    type: '',
    data: {
      id: null,
      date: null
    },
    filterData: {
      date: null,
      access_token: null,
      url: null,
      last_date: null,
      offset: null,
      flag: true,
      out_type: -1,
      salesmanID: 0,
      policyID: 0,
      outlet_type: 0,
      travel_type_id: 0,
      class_type: null,
      program_type: 0,
      status: null,
      max_claim: false,
      trax_outlet: false,
      non_trax_outlet: false,
      exclude_trax_audit: false,
      region_id: 0,
      cluster_id: 0,
      team_id: 0,
      territory_id: 0,
      dc_id: 0,
      displayData: [],
      payment_mode: ''
    },
    customFilter: {}
  };
  myDate: any;
  deliveryPlanData: any = {};
  invoiceData: any = [];
  edit = false;
  activetab = 'Invoices';
  API_URL = '';

  public gridColumnApi: any;
  public gridApi: any;
  public pinnedParams = [];
  preloader: any;
  emptyState: any;
  errorObject: ErrorObject;
  errorExists = false;
  dataLoaded: any;
  gridOptions: GridOptions;
  styleGrid: any;
  columnDefs = [];
  frameworkComponents: any;
  context: any;
  rowData = [];

  public gridColumnApi1: any;
  public gridApi1: any;
  public pinnedParams1 = [];
  preloader1: any;
  emptyState1: any;
  errorObject1: ErrorObject;
  errorExists1 = false;
  dataLoaded1: any;
  gridOptions1: GridOptions;
  styleGrid1: any;
  columnDefs1 = [];
  frameworkComponents1: any;
  context1: any;
  rowData1 = [];

  rowIndex = 0;
  invoicecount = 0;
  weight = 0.00;
  volume = 0.00;
  grandtotal = 0.0;
  userList = [];
  all_orders = [];
  printType = 'Invoice';

  constructor(private apiService: ApiService,
              private router: Router,
              private datePipe: DatePipe,
              private renderer: Renderer2,
              private sharedService: SharedService,
              private ngzone: NgZone,
              private elRef: ElementRef,
              private domSanitizer: DomSanitizer,
              public dialog: MatDialog,
              private toastr: ToastrService) {
    this.userData.access_token = localStorage.getItem('resfreshToken');
    this.userData.url = localStorage.getItem('private_url');
    this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.userData.data.date = this.myDate;
    this.deliveryPlanData = JSON.parse(localStorage.getItem('dpdata'));
    this.rowIndex = parseInt(localStorage.getItem('dpindex'), 0);
    this.userData.data.id = this.deliveryPlanData[this.rowIndex];

    this.gridOptions = {
      context: this,
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      defaultColDef: {
        headerClass: 'myagheader',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true
      },
    } as GridOptions;
    this.frameworkComponents = {
      CustomToolPanel: CustomGridToolPanelComponent
    };
    this.context = {componentParent: this};

    this.gridOptions1 = {
      context: this,
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      defaultColDef: {
        headerClass: 'myagheader',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true
      },
      getRowHeight: params => {
        if (params.node && params.node.detail) {
          const offset = 40;
          const allDetailRowHeight =
            params.data.lines.length *
            params.api.getSizesForCurrentTheme().rowHeight;
          const gridSizes = params.api.getSizesForCurrentTheme();
          return (
            allDetailRowHeight +
            ((gridSizes && gridSizes.headerHeight) || 0) +
            offset
          );
        }
      },
      detailCellRendererParams: this.getDetailRendererParams(),
    } as GridOptions;
    this.frameworkComponents1 = {
      CustomToolPanel: CustomGridToolPanelComponent
    };
    this.context1 = {componentParent: this};
  }

  getDetailRendererParams() {
    return (params) => {
      const res: any = {};
      res.detailGridOptions = {
        suppressRowClickSelection: true,
        enableRangeSelection: false,
        pagination: false,
        rowHeight: 40,
        columnDefs: [
          {headerName: "Product code", field: "product_code", width: 80},
          {headerName: "Product name", field: "product_name", width: 200},
          {headerName: "Quantity", field: "quantity", width: 80},
          {headerName: "UOM", field: "uom", width: 80},
          {
            headerName: "Value", field: "total", width: 80,
            valueGetter(params1) {
              if (params1.data) {
                if (params1.data.total) {
                  return parseFloat(params1.data.total).toFixed(2);
                }
              }
            }
          }
        ],
        defaultColDef: {
          headerClass: 'myagheaderblue',
          filter: true,
          sortable: true,
          resizable: true,
          enableRowGroup: true,
          flex: 1,
        },
        context: {
          params
        },
        onGridReady: (params1) => {
          // using auto height to fit the height of the detail grid
          params1.api.setDomLayout('autoHeight');
        }
      };
      res.getDetailRowData = (params1) => {
        params1.successCallback(params1.data.lines);
      };

      return res;
    };
  }

  ngOnInit(): void {
    this.sharedService.setHeader("Delivery Plan Details");
    this.userData.access_token = localStorage.getItem('resfreshToken');
    this.configureProductGrid();
    this.configureInvoiceGrid();
    this.fetchDeliveryPlanDetails();
  }

  configureProductGrid() {
    this.columnDefs = [
      {headerName: "Category", field: "category", width: 80},
      {headerName: "Brand", field: "brand", width: 80},
      {headerName: "Code", field: "product_code", width: 80},
      {headerName: "Name", field: "product_name", width: 200},
      {headerName: "Quantity", field: "quantity", width: 80},
      {headerName: "UOM", field: "uom", width: 80},
      {
        headerName: "Value", field: "total", width: 80,
        valueGetter(params) {
          if (params.data) {
            if (params.data.total) {
              return params.data.total.toFixed(2);
            }
          }
        }
      },
      {
        headerName: "Delivered Qty", field: "quantity", width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.d_status && params.data.d_status === 'delivered') {
              return params.data.quantity;
            } else {
              return '';
            }
          } else {
            return '';
          }
        }
      },
    ];
  }

  configureInvoiceGrid() {
    this.columnDefs1 = [
      {headerName: "Invoice number", field: "inv_no", width: 150, cellRenderer: 'agGroupCellRenderer'},
      {headerName: "Customer", field: "cust_name", width: 150},
      {headerName: "Products", field: "line_count", width: 100},
      {headerName: "Quantity", field: "qty", width: 100},
      {
        headerName: "Value", field: "total", width: 80,
        valueGetter(params) {
          if (params.data) {
            if (params.data.total) {
              return parseFloat(params.data.total).toFixed(2);
            }
          }
        }
      },
      {headerName: "Status", field: "d_status", width: 100},
      {
        headerName: "", headerClass: 'myagheader', field: "delete", cellStyle: {textAlign: 'center'}, sortable: true,
        filter: true, width: 50, resizable: true,
        cellRenderer: params => {
          if (params._self.edit) {
            return ' <span title="Remove Invoice" style="cursor: pointer" ><i class="fas fa-trash delete" data-action-type="delete"></i></span>';
          } else {
            return "";
          }
        }, cellRendererParams: {
          _self: this
        }
      }
    ];
  }

  fetchDeliveryPlanDetails() {
    this.API_URL = '/api/inv_delivery_plans/getDeliveryPlanDetails';
    this.dataLoaded = false;
    this.preloader = true;
    this.emptyState = false;
    this.errorExists = false;

    if (this.API_URL === '') {
      return this.handleError(ErrorHandler.getErrorObject('API Not Configured'));
    }
    this.apiService.post(this.API_URL, this.userData)
      .subscribe(res => {
          console.log(res);
          // t.stop();
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.apiData = res.results.data; //TODO: check if this line is needed
            this.deliveryPlanData = res.results.data.master;
            this.invoiceData = this.prepareInvoiceData(res.results.data);
            this.invoicecount = this.deliveryPlanData.invoices.length;
            if (this.invoiceData !== null && this.invoiceData.length > 0) {

              // tslint:disable-next-line:forin
              for (const key in this.invoiceData) {
                this.grandtotal += parseFloat(this.invoiceData[key].total);
              }
              this.configureProductData(res.results.data.invoices);
              if (this.rowData === null || this.rowData === undefined) {
                this.emptyState = true;
              } else if (this.rowData.length === 0) {
                this.emptyState = true;
              } else {
              }
              this.dataLoaded = true;
              //this.rowData should be ready now
              this.preloader = false;
              this.rowData1 = this.invoiceData;
              //this.rowData1.sort((a, b) => a.inv_no.localeCompare(b.inv_no));
              if (this.rowData1 === null || this.rowData1 === undefined) {
                this.emptyState1 = true;
              } else if (this.rowData1.length === 0) {
                this.emptyState1 = true;
              } else {
              }
              this.dataLoaded1 = true;
              //this.rowData should be ready now
              this.preloader1 = false;
            }
          } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
            this.emptyState = true;
            this.emptyState1 = true;
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.emptyState = true;
            this.emptyState1 = true;
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        // tslint:disable-next-line:no-shadowed-variable
        error => this.handleError(ErrorHandler.getErrorObject(error)));
  }

  prepareInvoiceData(apiData) {
    const pickListData = [];
    const uqInvoices = apiData.master.invoices;
    const invoiceList = apiData.invoices;
    const invoiceData = [];

    if (uqInvoices && uqInvoices.length > 0) {
      for (const invoice_id of uqInvoices) {
        const invoices = invoiceList.filter(x => x.id === invoice_id);
        let total = 0.00;
        let qty = 0;
        const invoiceRow = {
          id: invoices[0].id,
          inv_no: invoices[0].inv_no,
          order_id: invoices[0].order_id,
          cust_name: invoices[0].cust_name,
          line_count: invoices.length,
          total: 0.00,
          qty: 0,
          d_status: invoices[0].d_status,
          lines: []
        };
        invoices.forEach(invoice => {
          total += parseFloat(invoice.total);
          qty += parseInt(invoice.quantity, 10);
          const lineData = {
            inv_no: invoice.inv_no,
            product_name: invoice.product_name,
            product_code: invoice.product_code,
            category: invoice.category,
            brand: invoice.brand,
            quantity: invoice.quantity,
            volume: invoice.volume,
            weight: invoice.weight,
            total: invoice.total,
            price_unit: invoice.price_unit,
            uom: invoice.uom
          };
          invoiceRow.lines.push(lineData);
        });
        invoiceRow.total = total;
        invoiceRow.qty = qty;
        invoiceData.push(invoiceRow);
      }
    }
    return invoiceData;
  }

  configureProductData(productData) {
    if (productData !== null && productData !== undefined) {
      productData.sort((a, b) => a.category.localeCompare(b.category));
      const grouped = Object.values(productData.reduce((acc, {
        category,
        brand,
        product_code,
        product_name,
        uom,
        quantity,
        weight,
        volume,
        total
      }) => {
        acc[category + '|' + brand + '|' + product_code + '|' + product_name + '|' + uom] =
          acc[category + '|' + brand + '|' + product_code + '|' + product_name + '|' + uom] ||
          {category, brand, product_code, product_name, uom, quantity: 0, weight: 0.00, volume: 0.00, total: 0};
        acc[category + '|' + brand + '|' + product_code + '|' + product_name + '|' + uom].quantity += parseInt(quantity, 0);
        acc[category + '|' + brand + '|' + product_code + '|' + product_name + '|' + uom].weight = parseFloat(weight) * parseInt(quantity, 0);
        acc[category + '|' + brand + '|' + product_code + '|' + product_name + '|' + uom].volume = parseFloat(volume) * parseInt(quantity, 0);
        acc[category + '|' + brand + '|' + product_code + '|' + product_name + '|' + uom].total += parseFloat(total);
        this.weight += acc[category + '|' + brand + '|' + product_code + '|' + product_name + '|' + uom].weight;
        this.volume += acc[category + '|' + brand + '|' + product_code + '|' + product_name + '|' + uom].volume;
        return acc;
      }, {}));
      this.rowData = grouped;
    } else {
      this.rowData = productData;
    }
    //console.log(grouped);
  }



  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllColumns().length;

    params.api.setRowData(this.rowData);
    this.preloader = false;
    const inThis = this;

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        if (noOfColumns < 11 && screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeAllColumns();
        }
      });
    });

    if (noOfColumns < 11 && screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeAllColumns();
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  onGridReady1(params) {
    this.gridOptions1.api.showLoadingOverlay();

    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
    const noOfColumns = this.gridColumnApi1.getAllColumns().length;

    params.api.setRowData(this.rowData1);
    this.preloader1 = false;
    const inThis = this;

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        if (noOfColumns < 11 && screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi1.autoSizeAllColumns();
        }
      });
    });

    if (noOfColumns < 11 && screen.width > 991) {
      this.gridApi1.sizeColumnsToFit();
    } else {
      this.gridColumnApi1.autoSizeAllColumns();
    }

    this.styleGrid1 = this.elRef.nativeElement.querySelector('#myGrid2');
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi1.getSelectedRows();
    if (column === 'delete') {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "650px",
        maxHeight: "250px",
        data: {
          title: "Remove Invoice From Delivery Plan !!",
          message1: rows[0].inv_no,
          message2: "This invoice has " + rows[0].qty + " items with a total value of " + parseFloat(rows[0].total).toFixed(2),
          showNoButton: true,
          yesButton: "REMOVE",
          noButton: "CANCEL",
          deleteButton: true
        }
      };

      const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);

      confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

        if (data === 'yes') {
          this.deleteInvoice(rows[0]);
        } else {

        }
      });
    } else {

    }
  }

  async editClick() {
    this.edit = !this.edit;
    if (this.gridApi1) {
      this.gridApi1.setRowData(this.rowData1);
    }
  }

  setPrinterFriendly(api) {
    let eGridDiv;
    if (this.activetab === 'Picklist') {
      eGridDiv = document.querySelector('#myGrid1');
    } else {
      eGridDiv = document.querySelector('#myGrid2');
    }

    eGridDiv['style'].width = '';
    eGridDiv['style'].height = '';
    api.setDomLayout('print');
  }

  setNormal(api) {
    let eGridDiv;
    if (this.activetab === 'Picklist') {
      eGridDiv = document.querySelector('#myGrid1');
    } else {
      eGridDiv = document.querySelector('#myGrid2');
    }
    eGridDiv['style'].width = '700px';
    eGridDiv['style'].height = '200px';

    api.setDomLayout();
  }

  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  discardClick() {
    this.edit = !this.edit;
    window.location.reload();
  }

  setStartDate(dt) {
    //console.log(this.newExpenseData.expense_date)
    this.deliveryPlanData.start_date = this.datePipe.transform(this.deliveryPlanData.start_date, 'yyyy-MM-dd');
  }

  changeActiveTab(event: MatTabChangeEvent) {
    const tab = event.tab.textLabel;
    this.activetab = tab;
  }

  openAddInvoicePopup() {
    let creatediaeref;
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "70%",
        maxHeight: "95%",
        data: {
          dpData: this.deliveryPlanData
        },
        panelClass: 'custom-dialog-container'
      };
      creatediaeref = this.dialog.open(DeliveryPlanAddInvoicePopupComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "95%",
        maxHeight: "90%",
        data: {
          dpData: this.deliveryPlanData
        },
        panelClass: 'custom-dialog-container'
      };
      creatediaeref = this.dialog.open(DeliveryPlanAddInvoicePopupComponent, config);
    }
    creatediaeref.componentInstance.updatedDP.subscribe(data => {
      this.deliveryPlanData = data;
      this.saveDeliveryPlan();
    });
  }

  saveDeliveryPlan() {
    this.API_URL = '/api/inv_delivery_plans/saveInvoiceDeliveryPlan';
    const paramObject: any = {};
    paramObject.API_URL = this.API_URL;
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = [];
    paramObject.data.push(this.deliveryPlanData);
    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.toastr.success('Delivery Plan updated Successfully');
            this.dialog.closeAll();
            window.location.reload();
          } else if (res.hasOwnProperty('results')) {
            this.toastr.error(res.results.msg);
          } else {
            this.toastr.error("Delivery Plan updation Failed");
          }
        },
        error => {
          console.log(error);
          this.toastr.error("Delivery Plan updation Failed");
        });
  }

  quickSearch() {
    if (this.activetab === 'Picklist') {
      this.gridApi.setQuickFilter(document.getElementById('filter-text-box')['value']);
    } else {
      this.gridApi1.setQuickFilter(document.getElementById('filter-text-box')['value']);
    }
  }

  printInvoices() {
    this.printType = 'Invoice';
    this.getPrintDetails();
  }

  printPicklist() {
    this.printType = 'Picklist';
    this.getPrintDetails();
  }

  getPrintDetails() {
    const order_ids = [];
    this.invoiceData.forEach(row => {
      order_ids.push(row.order_id);
    });
    const order_data = {
      access_token: this.userData.access_token,
      order_id: order_ids
    };
    this.apiService.post('/api/pwa_connects/get_order_print_details', order_data)
      .subscribe(async res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
          let orderdata = res.results.data;
          if (this.printType === 'Picklist') {
            const lines = this.preparePrintPicklistData(res.results.data);
            orderdata = orderdata[0];
            orderdata.sale_items = lines;
            orderdata.invoice_date = this.deliveryPlanData.date;
            orderdata.driver_name =  this.deliveryPlanData.del_person;
            orderdata.driver_contact =  this.deliveryPlanData.del_contact;
            orderdata = [orderdata];
            this.all_orders = orderdata;
          } else {
            await this.processPrintDetails(orderdata);
          }
          console.log("test");
          const el: HTMLElement = this.interPrint.nativeElement;
          el.click();
        }
      });
  }

  preparePrintPicklistData(ordersList) {
    const pickListData = [];
    const uqProducts = new Set();
    const lineData = [];
    ordersList.forEach(order => {
      if (order.sale_items) {
        order.sale_items.forEach(line => {
          lineData.push(line);
          uqProducts.add(line.product_id);
        });
      }
    });

    if (uqProducts && uqProducts.size > 0) {
      for (const product_id of uqProducts) {
        const lines = lineData.filter(x => x.product_id === product_id);
        if (lines && lines.length > 0) {
          const productData = {
            product_id: lines[0].product_id,
            product_name: lines[0].product_name,
            product_uom: lines[0].product_uom,
            hsn: lines[0].hsn,
            unit_price: parseFloat(lines[0].unit_price),
            abs_qty: 0,
            amount_total: 0.00,
          };
          lines.forEach(line => {
            productData.abs_qty += parseInt(line.qty, 10);
            productData.amount_total += parseFloat(line.amount_total);
          });
          pickListData.push(productData);
        }
      }
    }
    return pickListData;
  }

  async processPrintDetails(orderdata) {
    const inThis = this;
    let total_qty = 0;
    let total_discount = 0;
    let printDetails = {};
    const all_orders = [];
    for (const data of orderdata) {
      // res['results'].data.forEach(async data => {
      total_qty = 0;
      printDetails = data;
      const tax_breaks = [];
      const taxnotes = [];
      printDetails['label_config'] = {};
      const labels = JSON.parse(localStorage.getItem('custom_labels'));
      if (labels) {
        labels.forEach(lab => {
          printDetails['label_config'][lab.label] = lab.display;
        });
      } else {
        printDetails['label_config']['PrimaryCurrency'] = '';
        printDetails['label_config']['SecondaryCurrency'] = '';
        printDetails['label_config']['SupplierLabel'] = 'Supplier';
        printDetails['label_config']['TaxLabel'] = 'GST';
      }
      if (typeof printDetails['tax_notes'] === 'string') {
        printDetails['tax_notes'] = JSON.parse(printDetails['tax_notes']);
      }
      if (printDetails['tax_notes']) {
        Object.keys(printDetails['tax_notes']).forEach(each_tax => {
          tax_breaks.push({
            name: each_tax,
            tax: printDetails['tax_notes'][each_tax].toFixed(inThis.apiService.decimalPolicy)
          });
          taxnotes.push(each_tax);
        });
      }
      printDetails['tax_breakup'] = tax_breaks;
      if (printDetails['partner_id'] === printDetails['partner_shipping_id']) {
        printDetails['customer_skip'] = true;
      } else {
        printDetails['customer_skip'] = false;
      }
      printDetails['print_text'] = inThis.domSanitizer.bypassSecurityTrustHtml(printDetails['print_text']);
      let rate = 0;
      let spli;
      printDetails['sale_items'].forEach(sale_item => {
        sale_item['label'] = '[' + sale_item.type + ']';
        const breaks = [];
        sale_item['new_tax'] = 0;
        if (sale_item['tax_notes']) {
          sale_item['tax_notes'] = JSON.parse(sale_item['tax_notes']);
          if (typeof sale_item['tax_notes'] === 'string' && sale_item['tax_notes'] !== "") {
            sale_item['tax_notes'] = JSON.parse(sale_item['tax_notes']);
          }
        }
        if (sale_item['tax_notes'] && sale_item['tax_notes'].constructor === Object) {
          Object.keys(sale_item['tax_notes']).forEach(each_tax => {
            if (sale_item['tax_notes'][each_tax].includes('(')) {
              spli = sale_item['tax_notes'][each_tax].split('(');
              rate = spli[1].split('%')[0].split(')')[0];
              breaks.push({name: each_tax, tax: spli[0], rate});
            } else {
              if (sale_item.hasOwnProperty('tax')) {
                spli = sale_item['tax'].split('(');
                rate = spli[1].split('%')[0].split(')')[0];
              } else {
              }
              breaks.push({name: each_tax, tax: sale_item['tax_notes'][each_tax], rate});
            }

          });
        }
        sale_item['tax_notes'] = breaks;

        total_discount += parseFloat(sale_item.vps_dis);
        if (printDetails['d_status'] === 'delivered') {
          sale_item['abs_qty'] = Math.abs(sale_item['delivered_qty']);
          total_qty += parseInt(sale_item.delivered_qty, 0);
          sale_item.unit_price = parseFloat(sale_item.unit_price) - (parseFloat(sale_item.scheme_discount) / parseFloat(sale_item.delivered_qty)) -
            (parseFloat(sale_item.unit_price) * (parseFloat(sale_item.discount) / 100));
        } else {
          sale_item['abs_qty'] = Math.abs(sale_item['qty']);
          total_qty += parseInt(sale_item.qty, 0);
          sale_item.unit_price = parseFloat(sale_item.unit_price) - (parseFloat(sale_item.scheme_discount) / parseFloat(sale_item.qty)) -
            (parseFloat(sale_item.unit_price) * (parseFloat(sale_item.discount) / 100));
        }
        sale_item.unit_price = sale_item.unit_price.toFixed(inThis.apiService.decimalPolicy);
      });
      printDetails['total_qty'] = total_qty;
      printDetails['discount'] = total_discount.toFixed(inThis.apiService.decimalPolicy);
      if (printDetails['date_delivery']) {
        printDetails['invoice_date'] = inThis.datePipe.transform((new Date(printDetails['date_delivery'] + ' UTC')).toString(), 'dd/MM/yyyy');
      } else if (printDetails['date_delivered'] != null) {
        printDetails['invoice_date'] = inThis.datePipe.transform((new Date(printDetails['date_delivered'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
      }
      printDetails['order_date'] = inThis.datePipe.transform((new Date(printDetails['order_date'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
      if (printDetails['date_delivered'] != null) {
        printDetails['date_delivered'] = inThis.datePipe.transform((new Date(printDetails['date_delivered'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
      }
      if (printDetails['logo']) {
        const resp = await inThis.apiService.fetchImageWait({
          access_token: inThis.userData.access_token,
          data: [{store_fname: printDetails['logo'], container: "res_partner"}]
        });
        // res_data.subscribe(resp => {
        console.log(resp);
        if (resp['result'].length > 0) {
          printDetails['logo'] = inThis.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + resp['result'][0].bill_datas);
        }
        all_orders.push(printDetails);
      } else {
        all_orders.push(printDetails);
      }
      this.all_orders = all_orders;
      console.log(all_orders);
    }
  }

  deleteInvoice(row) {
    const order_data = {
      access_token: this.userData.access_token,
      data: {}
    };
    this.apiService.post('/api/inv_delivery_plans/update_invoice_with_delivery_plan', order_data)
      .subscribe(async res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
          this.toastr.success('Successfully Removed Invoice');
          window.location.reload();
        } else {
          this.toastr.error('Failed To Remove Invoice');
        }
      });
  }



  public handleError(error: ErrorObject) {
    this.preloader = false;
    this.emptyState = false;
    this.dataLoaded = false;
    this.errorExists = true;
    this.errorObject = error;
  }
}
