<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" style="float: right;background-color:#FDE0D4;">
        <i class="fas fa-times"></i>
      </button>
      <h2>Create {{header}}</h2>
    </div>
    <div class="body-section w-100" style="text-align: center;">
      <div style="margin-top: 25px;">
        <form novalidate id="ngForm" #createform="ngForm">
          <div class="cardgrid">
            <ng-container *ngFor="let item of formData; let i = index" class="col-md-3 eod-width">
              <div>
                <label style="float: left">{{item.name}}</label>
                <div style="margin-top: 5px;">
                  <div>
                    <input
                      class="forminput"
                      type="text"
                      name="name_{{i}}"
                      [(ngModel)]="item.value"
                      style="width: 100%;"
                      required
                    >
                    <div *ngIf="item.value.errors && (item.value.dirty || item.value.touched)">
                      <span *ngIf="item.value.errors.required" class="text-danger">{{item.name}} is required</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </form>
      </div>
      <div style="float: left">
      <div class="button-row" style="margin-top: 10px;" >
        <button
          mat-button
          style=" vertical-align:bottom;margin-top: 20px; margin-right: 15px;"
          class="btn teal-btn"
          (click)="submit()"
          form="ngForm"
          [disabled]="createform.form.invalid"
        >
          SAVE
        </button>
        <span (click)="closeDialog()">
           <a class="btn white-btn">DISCARD</a>
        </span>
      </div>
      </div>
    </div>
  </div>
</section>
