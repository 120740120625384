<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper">
      <div id="overlay"></div>
      <div>
        <app-page-loader [show]="!pageReady"></app-page-loader>
      </div>
      <div class="col-md-12 top-card">
        <div>
          <div >
            <div>
              <div class="search-list">
                <div class="col-md-12 pt-3 col-12 nopadding pl-0" style="margin-bottom: 10px;">
                  <div class="row">

                    <div  *ngIf="activeObject['state'] === 'complete'" class="button-row">
                      <button
                        class="btn blue-button"
                        (click)="action_revert_eod()"
                      >REVERT EOD
                      </button>
                    </div>

                    <div *ngIf="!newMode && !editMode && listLength > 0" style="position: absolute;right: 40px;">
                      <div class="button-row">
                         <span title="Print" style="margin-right: 20px;cursor: pointer"><img (click)="getPrintDetails()"
                                                                                             src="./../../../../assets/icons/print.svg"/></span>
                         <span (click)="movePrevious()"><i
                           [ngClass]="{'disabledspan': rowIndex === 0,
                         'enabledspan': rowIndex >0}"
                           class="fal fa-arrow-circle-left fa-2x"></i></span>
                        <span style="margin-left: 5px; margin-right: 5px; margin-bottom: 2px;font-size: 12px;">{{rowIndex+1}}/{{listLength}}</span>
                        <span (click)="moveNext()"><i
                          [ngClass]="{'disabledspan': rowIndex === listLength-1,
                         'enabledspan': rowIndex < listLength-1}"
                          class="fal fa-arrow-circle-right fa-2x"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <div class="col-md-12 pt-3 pad0"  style="margin-top: 10px;">
              <div class="col-md-9 pt-3 pad0">
                <div class="col-md-12 pt-3 pad0">
                  <div class="col-md-3 pl-0 pr-30">
                    <label class="lbl">VAN</label>
                    <div style="display: inline">
                      <p class="lbl3">{{activeObject['van']}}</p>
                    </div>
                  </div>
                  <div class="col-md-3 pl-0 pr-30">
                    <label class="lbl">DISTRIBUTION CENTRE</label>
                    <div>
                      <p class="lbl3">{{activeObject['supplier_name']}}</p>
                    </div>
                  </div>
                  <div class="col-md-3 pl-0 pr-30" >
                    <label class="lbl">DATE</label>
                    <div>
                      <p class="lbl3" *ngIf="activeObject['schedule_date']">{{activeObject['schedule_date']}}</p>
                    </div>
                  </div>
                  <div class="col-md-3 pl-0 pr-30" >
                    <label class="lbl">STATUS</label>
                    <div>
                      <p class="lbl3" *ngIf="activeObject['state']">{{activeObject['state']}}</p>
                    </div>
                  </div>
              </div>

            </div>
              <div class="col-md-12 pt-4 pad0">
                <div class="dms_table">
                  <ag-grid-angular id="myGrid2" *ngIf="dataLoaded" style="height: 73vh" class="ag-theme-alpine"
                                   [columnDefs]="columnDefs2" [gridOptions]="gridOptions2"
                                   (gridReady)="onGridReady2($event)" [frameworkComponents]="frameworkComponents2"
                                   [getRowId]="getRowId"
                                   [context]="context">
                  </ag-grid-angular>
                </div>
              </div>
            <div class="col-md-12 pt-4 pad0">
              <div class="dms_table">
                <ag-grid-angular id="myGrid1" *ngIf="dataLoaded" style="width: 100%; height: 40vh" class="ag-theme-balham"
                                 [columnDefs]="columnDefs"
                                 [gridOptions]="gridOptions"
                                 (gridReady)="onGridReady($event)"
                                 [frameworkComponents]="frameworkComponents"
                                 [context]="context"
                                 [isExternalFilterPresent]="isExternalFilterPresent"
                                 [doesExternalFilterPass]="doesExternalFilterPass"
                                 >
                </ag-grid-angular>
              </div>
            </div>
              <div class="col-md-12 pt-4 pad0" *ngIf="activeObject['state'] === 'complete'">
                <button
                  class="btn blue-button"
                  (click)="action_settle()"
                >SETTLE
                </button>
              </div>

              <div class="col-md-12 pt-4 pad0">
                <div class="col-md-7 pad0">
                </div>
                <div class="col-md-5 pad0">
                  <div class="eodset-cash">
                    <div class="eodtop-card" style="padding-top: 15px;">
                      <ul class="list-unstyled">
                        <li>
                          <h2>SUMMARY</h2>
                        </li>
                      </ul>
                    </div>
                    <div class="eodcash-card" style="padding-top: 10px;">
                      <div class="amount-heading">
                        <h2>AMOUNT</h2>
                      </div>
                      <div *ngIf="settlement" class="cash-bottomcard">
                        <ul>
                          <li *ngIf="settlement['loadout_value'] > 0">
                            <label style="font-weight: 100;">Loadout Value</label>
                            <span>{{ settlement['loadout_value'] }}</span>
                          </li>
                          <li *ngIf="settlement['opening_value'] > 0">
                            <label style="font-weight: 100;">Opening Value</label>
                            <span>{{ settlement['opening_value'] }}</span>
                          </li>
                          <li *ngIf="settlement['unapproved_sale'] > 0">
                            <label style="font-weight: 100;">Unapproved Cash Sales</label>
                            <span>{{ settlement['unapproved_sale'] }}</span>
                          </li>
                          <li *ngIf="settlement['credit'] > 0">
                            <label>Credit Sales</label>
                            <span style="font-weight: 100;" class="up">+{{ settlement['credit'] }}</span>
                          </li>
                          <li *ngIf="settlement['cash'] > 0">
                            <label>Cash Sales</label>
                            <span style="font-weight: 100;">{{ settlement['cash'] }}</span>
                          </li>
                          <li *ngIf="partial_approve">
                            <label>Bonus/Penalty</label>
                            <span style="font-weight: 100;">{{ bonus }}/{{ penalty }}</span>
                          </li>
                          <li *ngIf="partial_approve">
                            <label>Total Return</label>
                            <span style="font-weight: 100;">{{ total_return }}</span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="eodtop-card" style="padding-top: 15px;">
                      <ul class="list-unstyled">
                        <li>
                          <h2>COLLECTION</h2>
                        </li>
                      </ul>
                    </div>
                    <div class="eodcash-card" style="padding-top: 10px;">
                      <div *ngIf="settlement" class="cash-bottomcard">
                        <ul>
                          <li *ngIf="!expense_hide">
                            <label>Total Expense</label>
                            <span class="down">-{{ settlement['expense'] }}</span>
                          </li>
                          <li *ngIf="settlement['pay_cash'] > 0">
                            <label>Cash</label>
                            <span style="font-weight: 100;">{{ settlement['pay_cash'] }}</span>
                          </li>
                          <li *ngIf="settlement['pay_cheque'] > 0">
                            <label>Cheque</label>
                            <span style="font-weight: 100;">{{ settlement['pay_cheque'] }}</span>
                          </li>
                          <ng-container *ngFor="let item of settlement['paymentObj'];">
                            <li>
                              <label>{{ item.name }}</label>
                              <span style="font-weight: 100;">{{ item.value }}</span>
                            </li>
                          </ng-container>
                          <li *ngIf="settlement['credits'] > 0">
                            <label>Today's Credits</label>
                            <span style="font-weight: 100;">{{ settlement['credits'] }}</span>
                          </li>
                          <li *ngIf="settlement['todays_payment'] > 0">
                            <label>Todays Payment</label>
                            <span style="font-weight: 100;">{{ settlement['todays_payment'] }}</span>
                          </li>
                        </ul>
                        <div class="settle-section">
                          <ul>
                            <li>
                              <label> Amount Payable</label>
                              <span>Rs. {{ settlement['total'] }}</span>
                            </li>
                            <li *ngIf="show_outstanding && settlement['total_out'] > 0">
                              <label> Total Outstanding</label>
                              <span>{{ settlement['total_out'] }}</span>
                            </li>
                          </ul>

                          <div  *ngIf="activeObject['state'] === 'complete'">
                            <button
                              class="btn blue-button"
                              (click)="action_settle()"
                            >SETTLE
                            </button>
                          </div>
<!--                          <label *ngIf="checkEodSettleShow()"-->
<!--                                 data-toggle="modal"-->
<!--                                 data-target="#approveModal"-->
<!--                                 class="btn confirm-blue-btn"-->
<!--                                 style="margin-left: 25px; margin-top: 10px;"-->
<!--                          >SETTLE</label>-->
                          <label class="mainerror">{{ getSettleMainErrorMessage() }}</label>
                          <label class="suberror">{{ getSettleSupportErrorMessage() }}</label>
                          <ul *ngIf="feature_allow_marksettled && activeObject['state'] === 'settled' && activeObject['apr_comments']">
                            <li>
                              <span>{{ activeObject['apr_comments'] }}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
        </div>
    </div>
  </div>
</div>

