import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";

@Component({
  selector: 'convert-cell',
  template: `<span><div *ngIf="loader">
                        <img style="height: 24px;position: relative;left: 60px;" src="../assets/images/loader_icon.gif"/>
  </div><div *ngIf="approv"><button class="btn white-blue-btn" (click)="approveClick()">{{value}}</button></div>
    <div *ngIf="approved_after"><button class="btn white-green-btn" (click)="approveClick()">{{value}}</button></div>
    <div *ngIf="cancel">{{value}}</div></span>`,
  styles: []
})
export class ConvertCellRenderer implements ICellRendererAngularComp {
  public params: any;
  public value;
  public loader = false;
  public approv = false;
  public approved_after = false;
  public cancel = false;

  agInit(params: any): void {

    this.params = params;
    this.value = params.value;
    if (this.value == 'Cancelled') {
      this.cancel = true;
      this.approv = false;
      this.approved_after = false;
    }else if (this.value == 'Approved') {
      this.approved_after = true;
      this.approv = false;
    }else {
      this.cancel = false;
    }
  }

  public approveClick() {

    if (this.value != "Approved") {
      this.loader = true;
      this.approv = false;
      this.params.context.componentParent.approveClick(this.params.data, this.params.api.getSelectedNodes()).then(res => {
        if (res[0]) {
          this.value = "Approved";
          this.approved_after = true;
        }else {
          this.approv = true;
        }
        this.loader = false;
      });
    }
  }

  refresh(): boolean {
    return false;
  }
}
