import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class DailyGoalsReportComponent extends ReportBase implements IReport, OnInit {

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Daily Goals Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.state = true;
    this.showableFilters.region = true;
    this.API_URL = '/api/pwa_reports/dailyGoalsReport';
  }

  configureGrid() {

    this.columnDefs = [

      {headerName: 'DATE', field: 'date', enableRowGroup: true, width: 80},
      {headerName: 'SALESMAN', field: 'user_name', enableRowGroup: true, width: 80},
      {headerName: 'SCODE', field: 'emp_code', width: 100, enableRowGroup: true},
    ];

    let uniqueTypes = [];
    uniqueTypes = this.apiData.map(p => p.type)
      .filter((type, index, arr) => arr.indexOf(type) === index);

    for (const key in uniqueTypes) {
      this.columnDefs.push({
        headerName: uniqueTypes[key].toString().replace('_', ' ').toUpperCase(),
        children: [
          {
            headerName: "Target",
            field: uniqueTypes[key] + '_target',
            width: 100,
          },
          {
            headerName: "Achievement",
            field: uniqueTypes[key] + '_achievement',
            width: 100,
          }
        ]
      });
    }
    this.columnDefs.push(
      {headerName: 'STATUS', field: 'status', width: 100, enableRowGroup: true},
      {headerName: 'RATING', field: 'rating', width: 100, enableRowGroup: true}
    );
  }

  postProcessData() {
    this.configureGrid();
    this.configureData();
  }

  configureData() {
    this.rowData = [];
    const innerthis = this;
    let uniqueSalesmen = [];
    let salesmanData = [];
    uniqueSalesmen = this.apiData.map(p => p.user_id)
      .filter((type, index, arr) => arr.indexOf(type) === index);
    let masterRow: any = {};
   /* masterRow = this.apiData.map(o => new Object(
      {
        user_id: o.user_id,
        date: o.date
      }));*/
    masterRow = this.unique(this.apiData, ['user_id', 'date']);

    if (masterRow !== null && masterRow !== undefined && masterRow.length > 0) {
      for (const key1 in masterRow) {
        salesmanData = [];
        salesmanData = innerthis.apiData.filter(existed => existed.user_id === masterRow[key1].user_id
          && existed.date === masterRow[key1].date);
        const salesmanRow: any = {};
        salesmanRow.date = salesmanData[0].date;
        salesmanRow.user_name = salesmanData[0].user_name;
        salesmanRow.emp_code = salesmanData[0].emp_code;
        salesmanRow.mngr_comment = salesmanData[0].mngr_comment;
        salesmanRow.status = salesmanData[0].status;
        salesmanRow.rating = salesmanData[0].rating;
        for (const key in salesmanData) {
          salesmanRow[salesmanData[key].type + '_target'] = salesmanData[key].target;
          salesmanRow[salesmanData[key].type + '_achievement'] = salesmanData[key].achievement;
        }
        innerthis.rowData.push(salesmanRow);
      }
    }
  }

  unique(arr, keyProps) {
    const kvArray = arr.map(entry => {
      const key = keyProps.map(k => entry[k]).join('|');
      return [key, entry];
    });
    const map = new Map(kvArray);
    return Array.from(map.values());
  }

}
