import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-printicon',
  templateUrl: './printicon.component.html',
  styleUrls: ['./printicon.component.scss']
})
export class PrinticonComponent implements OnInit {
  @Input() gridOption;
  @Input() styleGrid;

  constructor() { }

  ngOnInit() {
  }

  onBtPrint() {

    let gridApi;
    let styleGrid;
    let gridOption;
    if (this.gridOption != undefined) {
      gridApi = this.gridOption.api;
      styleGrid = this.styleGrid;
      gridOption = this.gridOption;
    }

    this.setPrinterFriendly(gridApi);
    let normal = this.setNormal;

    setTimeout( function( ) {
      print();
      normal(gridApi, styleGrid, gridOption);
    }, 2000);
  }

  setPrinterFriendly(api) {
    if (this.gridOption != undefined) {
      this.styleGrid.style.width = '';
      this.styleGrid.style.height = '';
    }
    api.setDomLayout('print');
  }

  setNormal(api, styleGrid, gridOptions) {
    if (this.gridOption != undefined) {
      styleGrid.style.width = '100%';
    }
    // api.setDomLayout(null);

  }

}
