import {Component, ElementRef, Renderer2} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";

@Component({
  selector: 'regenerate-click',
  template: `<span><div *ngIf="loader">
                        <img style="height: 24px;position: relative;left: 60px;" src="../assets/images/loader_icon.gif"/>
  </div><div *ngIf="enable_invalidate"><button class="btn white-blue-btn" style="height:23px !important;" (click)="invalidate()">Invalidate</button></div></span>`,
})
export class StockInvalidateComponent implements ICellRendererAngularComp {
  public params: any;
  public enable_invalidate = false;
  public loader = false;

  constructor(private elRef: ElementRef,
              private renderer: Renderer2) {
  }

  agInit(params: any): void {
    this.params = params;
    if (this.params.data.qty_delivered !== null && this.params.data.qty_delivered !== 0) {
      if (this.params.data.stock_count === null || this.params.data.stock_count === 0) {
        /*   if (this.params.data.stock_count !== 0) {
             this.enable_invalidate = true;
           }*/
        this.enable_invalidate = true;
      } else {
        this.enable_invalidate = false;
      }
    } else {
      this.enable_invalidate = false;
    }
  }

  invalidate() {

    this.loader = true;
    this.enable_invalidate = false;
    this.params.context.componentParent.invalidate(this.params.data, this.params.node);
  }

  refresh(): boolean {
    return false;
  }
}
