<section class="dialog-section" xmlns="http://www.w3.org/1999/html" xmlns="http://www.w3.org/1999/html">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Add Expense Claim</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <form novalidate id="ngForm" #expenseclaim="ngForm">

        <div class="newCard" *ngIf="expenseDetails.expense_line_id" style="margin-bottom: 20px;">
          <span class="dialoglabel1" style="color: #0762AD;">Note</span>
          <ul style="padding-left: 15px;margin-top: 5px;">
            <li *ngIf="selectedPolicy.policy_line_html && selectedPolicy.policy_line_html !== '<p><br></p>'" style="color: #0762AD;">
              <div [innerHTML]="selectedPolicy.policy_line_html"></div>
            </li>
            <li *ngIf="selectedPolicy.max_claim_amount" >
              <span class="dialoglabel2" style="color: #0762AD;">Max Claim Amount- </span>
              <span class="dialoglabel1" style="color: #0762AD;">{{selectedPolicy.max_claim_amount}}</span>
            </li>
            <li *ngIf="selectedPolicy.bill_mandatory" style="color: #0762AD;">Bill upload is mandatory for this expense type</li>
          </ul>
        </div>

        <div>
          <span class="detaillabel2">Expense Type</span>
          <span class="requiredspan1">*</span>
          <div class="formselectwhitebg">
            <select
              name="expense_line_id"
              [(ngModel)]="expenseDetails.expense_line_id"
              #expense_line_id="ngModel"
              style="width: 100%;margin-top: 5px;"
              (change) = "onTypeChange()"
              required>
              <option
                *ngFor="let item of policyLineData"
                value="{{ item.id }}"
              >
                {{ item.itemName }}
              </option>
            </select>
            <div *ngIf="expense_line_id.errors && (expense_line_id.dirty || expense_line_id.touched)">
              <span *ngIf="expense_line_id.errors.required" class="text-danger">Expense Type is required</span>
            </div>
          </div>
        </div>
        <div style="display: flex; margin-top: 20px;">
          <div style="flex: 1">
            <span class="detaillabel2">Date</span>
            <span class="requiredspan1">*</span>
            <div class="date-picker-form">
              <input [selectMode]="'single'"
                     [readonly]="true"
                     [(ngModel)]="expenseDetails.expense_date"
                     [owlDateTimeTrigger]="dt"
                     [owlDateTime]="dt" placeholder="Expense Date"
                     name="expense_date"
                     #expense_date="ngModel"
                     style="display: inline; color: #2D2D2D; font-size: 14px;width: 100%; margin-top: 5px;">
              <div style=" position: absolute;right: 10px;top: 16px;">
                <span style="padding-left: 5px;"><i class="far fa-calendar-alt"></i></span>
              </div>
              <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
            </div>
          </div>
          <div style="flex: 1; margin-left: 15px;">
            <span class="detaillabel2">Amount</span>
            <span class="requiredspan1">*</span>
            <input
              id="tot_amount"
              class="forminputwhitebg"
              style="display: block; color: #0762AD; font-size: 16px; font-weight: 500;margin-top: 5px;width: 100%;height:35px;"
              type="number"
              name="amount"
              [(ngModel)]="expenseDetails.amount"
              (keyup)="onAmountChange()"
              #amount="ngModel"
              required
            >
            <div *ngIf="amount.errors && (amount.dirty || amount.touched)">
              <span *ngIf="amount.errors.required" class="text-danger">Amount is required</span>
            </div>
            <span *ngIf="maxamountExceeded" class="text-danger">Amount exceeds Max Claim amount({{selectedPolicy.max_claim_amount}})</span>
          </div>
        </div>
        <div style="margin-top: 20px;">
          <span class="detaillabel2">Comments</span>
          <textarea
            class="forminputwhitebg"
            style="display: block; font-size: 14px;margin-top: 5px;width: 100%;height:100px;"
            type="text"
            name="note"
            [(ngModel)]="expenseDetails.note"
            #comments="ngModel"
            placeholder="Enter Comments"
          ></textarea>
        </div>
        <div class="row" style="margin-top:20px;">
          <div class="galleryrowgrid row centeralign">
            <ng-container *ngFor="let tile of expensephotos">
              <div style=" display: inline-block;align-items: center;cursor: pointer;">
                <img *ngIf="tile.type !== 'application/pdf'" [src]="tile.photos" (click)="showImagePopup(tile.photos)"
                     style="height: 80px;width:80px;margin-right: 8px;margin-bottom: 8px;cursor: pointer"/>
                <img *ngIf="tile.type === 'application/pdf'" src="./../../../assets/pdf_icon.png"
                     (click)="openPDFinNewTab(tile.photos)"
                     style="height: 80px;width:80px;margin-right: 8px;margin-bottom: 8px;cursor: pointer"/>
                <p style="display: block">{{ tile.name }}</p>
              </div>
            </ng-container>
            <div style=" display: inline-flex;align-items: center;">
              <input
                type="file"
                #file
                style="display:none"
                accept="image/png, image/jpeg, application/pdf"
                (change)="handleFileSelect($event)"/>
              <span style="margin-right:5px;display:inline" (click)="file.click()"
              >
                  <img src="assets/upload_picture.svg" width="40px"/></span>
              <span *ngIf="!expensephotos" style=" display: inline-flex;align-items: center;">Upload Picture</span>
            </div>
          </div>
        </div>
        <div *ngIf="!expensephoto && expenseDetails.expense_line_id && selectedPolicy && selectedPolicy.bill_mandatory" style="margin-top: 5px;">
          <img src="assets/exclamation.svg" style="width: 12px; height:12px; margin-right: 5px;" alt="">
          <span class="text-danger">Cannot proceed without bill upload. Please provide the required document</span>
        </div>
      </form>
    </div>
    <div class="button-row" style="float: right; margin: 15px 30px 15px 0px;">
      <button
        mat-button
        style=" margin-right:15px;"
        class="btn grey-btn"
        (click)="closeDialog()"
      >
        Cancel
      </button>
      <button
        mat-button
        class="btn confirm-blue-btn"
        (click)="checkAndCreate()"
        form="ngForm"
        [disabled]="expenseclaim.form.invalid || maxamountExceeded || (!expensephoto && expenseDetails.expense_line_id && selectedPolicy && selectedPolicy.bill_mandatory)"
      >
        Save
      </button>
    </div>
  </div>
</section>
