
<div class="common-wrapper">
  <div class="common-container">

    <div class="main-wrapper eod">
      <div id="overlay"></div>
      <div>
        <ul>
          <div *ngIf="sharedService.new_retailer" style="display: inline-block;margin-left: 75%;margin-right: 3%;">
            <button [routerLink]="'/orders'" style="position: relative;margin-top: -2.5%;left: 91%;margin-left: -1%;" class="btn blue-btn">CANCEL</button>
          </div>
          <div *ngIf="sharedService.new_retailer" style="display: inline-block;">
            <button (click)="savePO()" style="position: relative;margin-top: -2.5%;left: 91%;margin-left: -1%;" class="btn blue-btn">SAVE</button>
          </div>
        </ul>
      </div>
      <div class="eodtop-card" style="flex-wrap: nowrap;margin-top: 1.5%;margin-right: 2%;margin-left: 2%;height: max-content;">
        <ul class="list-unstyled">
          <!--<li class="margb">-->
          <!--<h2>VAN</h2>-->
          <!--</li>-->
          <li>
            <div style="height: 140px;
                        width: 125%;
                        border-left: black 1px solid;
                        border-right: black 1px solid;
                        border-top: black 1px solid;
                        border-bottom: black 1px solid;">
            </div>
            <h2 style="font-size: 180%;
                        position: relative;
                        left: 165px;
                        top: -125px;}">{{customer_name}}</h2>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="approve-modal modal" id="serialNoModel" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <span class="close" data-dismiss="modal">
        <img src="../../assets/close.png">
      </span>
      <div class="modal-body">
        <div class="approve-modal-heading">
          <label>ENTER SERIAL NO</label>
        </div>
        <div class="approve-modal-content">
          <div style="display: inline-block;">
            <button (click)="addInput()" class="btn blue-btn">ADD</button>
          </div>
          <div #serialIn>
            <input type="text" class="form-control" style="width: 90% !important;padding: 1% 10%;margin-top: 5%;" [(ngModel)]="serno1" />
          </div>
          <div style="display: inline-block;margin-top: 6%;" data-dismiss="modal">
            <button (click)="confirmSerial()" class="btn blue-btn">CONFIRM</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<button #imageClick id="openImage" [hidden]="true" data-toggle="modal" data-target="#serialNoModel">Open Modal</button>
