import {Component, ElementRef, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {GridOptions} from "@ag-grid-enterprise/all-modules";

@Component({
  selector: 'app-outlet-visit-popup-component',
  templateUrl: './outlet_visit_popup.component.html',
  styleUrls: ['./outlet_visit_popup.component.scss']
})
export class OutletVisitPopupComponent implements OnInit {
  @Output() selectedOutlets: EventEmitter<any> = new EventEmitter<any>();
  public columnDefs = [];
  public rowData = [];
  public gridOptions: GridOptions;
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public defaultColDef;
  public styleGrid;
  public frameworkComponents;
  public context;
  outlet_types = [{id: 0, itemName: 'All Outlets'}];
  program_types = [{id: 0, itemName: 'All Programs'}];
  salesmen = [{id: 0, itemname: 'All Salesmen'}];
  states = [{id: 0, itemName: 'All States'}];
  clusters = [{id: 0, itemName: 'All Clusters'}];
  API_URL = '';
  outletFilters = {
    class_type: 0,
    program_type: 0,
    outlet_type: 0,
    salesman_id: 0,
    state_id: 0,
    cluster_id: 0,
    territory_ids: [],
    showAll: true
  };
  customFilter: any = {
    name: 'Status',
    key: 'type',
    value: 'false',
    values: [],
    show: true
  };
  userData = {
    start_date: null,
    end_date: null,
    period_id: 0,
    access_token: null,
    url: null,
    offset: null,
    offsetID: 0,
    flag: true,
    type: '',
    filterData: {
      date: null,
      access_token: null,
      url: null,
      last_date: null,
      offset: null,
      flag: true,
      out_type: -1,
      salesmanID: 0,
      policyID: 0,
      outlet_type: 0,
      travel_type_id: 0,
      class_type: null,
      program_type: 0,
      status: null,
      max_claim: false,
      trax_outlet: false,
      non_trax_outlet: false,
      exclude_trax_audit: false,
      region_id: 0,
      cluster_id: 0,
      team_id: 0,
      territory_id: 0,
      dc_id: 0,
      salesman_id: 0,
      displayData: []
    },
    customFilter: {}
  };
  team_territories_list = [];
  outletData = [];
  selectedOutlet = [];
  private gridApi;
  private gridColumnApi;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {

    this.userData = dialogdata.userData;
    this.userData.filterData.salesman_id = dialogdata.salesman_id;
    this.customFilter.values = [
      {id: '', itemName: 'All'},
      {id: 'true', itemName: 'Visited'},
      {id: 'false', itemName: 'Not Visited'}
    ];
    this.customFilter.value = 'false';
    this.userData.customFilter = {};
    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      // groupDisplayType: 'groupRows',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      rowGroupPanelShow: 'always',
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      defaultColDef: {
        headerClass: 'myagheader',
        filter: true,
        sortable: true,
        resizable: true
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      /* onColumnVisible: this.onColumnVisible,
       onDragStopped : this.onDragStopped*/
    } as GridOptions;

    /*if (localStorage.getItem('all_out_types') !== null) {
      this.outlet_types = this.outlet_types.concat(JSON.parse(localStorage.getItem('all_out_types')));
    }
    if (localStorage.getItem('all_program_types') !== null) {
      this.program_types = this.program_types.concat(JSON.parse(localStorage.getItem('all_program_types')));
    }
    if (localStorage.getItem('all_salesmans') !== null && localStorage.getItem('all_salesmans') !== 'null' && JSON.parse(localStorage.getItem('all_salesmans')) !== null) {
      this.salesmen = this.salesmen.concat(JSON.parse(localStorage.getItem('all_salesmans')));
    }
    if (localStorage.getItem('all_states') !== null) {
      this.states = this.states.concat(JSON.parse(localStorage.getItem('all_states')));
    }
    if (localStorage.getItem('all_clusters') !== null) {
      this.clusters = this.clusters.concat(JSON.parse(localStorage.getItem('all_clusters')));
    }

    this.team_territories_list = JSON.parse(localStorage.getItem('team_territories'));


    if (this.team_territories_list !== null) {
      for (var i = 0; i < this.team_territories_list.length; i++) {
        this.outletFilters.territory_ids.push(this.team_territories_list[i].id);
      }
    }
*/

    this.columnDefs = [
      {headerName: 'VISIT STATUS', field: 'status', enableRowGroup: true, width: 100, aggFunc: this.visitavgAggFunction},
      {headerName: 'VISIT FREQUENCY', field: 'visit_frequency', enableRowGroup: true, width: 100, aggFunc: this.mainAggFunction},
      {headerName: 'REGION', field: 'region', width: 100, enableRowGroup: true},
      {headerName: 'STATE', field: 'state', width: 100, enableRowGroup: true},
      {headerName: 'CLUSTER', field: 'cluster', width: 100, enableRowGroup: true},
      {headerName: 'TERRITORY', field: 'territory', enableRowGroup: true, width: 80},
      {headerName: 'SALESMAN', field: 'user_name', enableRowGroup: true, width: 150},
      {headerName: 'SCODE', field: 'emp_code', enableRowGroup: true, width: 150},
      {headerName: 'OUTLET', field: 'name', enableRowGroup: true, width: 200},
      {headerName: 'OCODE', field: 'code', enableRowGroup: true, width: 80},
      {headerName: 'OTYPE', field: 'r_type', enableRowGroup: true, width: 80},
      {headerName: 'OACTIVE', field: 'active', enableRowGroup: true, width: 80},
      {headerName: 'CLASS', field: 'class_type', enableRowGroup: true, width: 120},
      {headerName: 'PROGRAM TYPE', field: 'program', enableRowGroup: true, width: 120},
      {headerName: 'BEAT', field: 'beat_name', enableRowGroup: true, width: 120},
    ];
    this.loaded = true;
    this.customFilter.value = 'false';
    this.fetchOutlets();
  }

  ngOnInit(): void {

  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllColumns().length;

    params.api.setRowData(this.rowData);
    this.preloader = false;
    const inThis = this;
    //this.gridApi.setDomLayout("autoHeight");
    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        if (noOfColumns < 11 && screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeAllColumns();
        }
      });
    });
    if (noOfColumns < 11 && screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeAllColumns();
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');

    /* const columnState = JSON.parse(localStorage.getItem('myColumnState'));
     if (columnState) {
       this.gridColumnApi.applyColumnState(
         {
           state: columnState,
           applyOrder: true,
         }
       );
     }*/

    /*fetch('C:\Users\Nivil\Downloads\pwaconfig.txt').then(response => response.text())
      .then(data => {
        // Do something with your data
        console.log(data);
      });*/
  }

  onCellClicked(event) {
    const column = event.column.getColId();
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  quickSearch() {
    this.gridApi.setQuickFilter(document.getElementById('filter-text-box1')['value']);
  }

  fetchOutlets() {
    /*   let paramObject: any = {};
       paramObject.access_token = localStorage.getItem('resfreshToken');
       paramObject.data = this.outletFilters;*/
    this.rowData = [];
    this.API_URL = '/api/pwa_reports/getRetailerCheckINReport';
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;

    if (this.userData.customFilter != null && !this.userData.customFilter.hasOwnProperty(this.customFilter.key)) {
      this.userData.customFilter[this.customFilter.key] = this.customFilter.value;
    }

    this.apiService.post(this.API_URL, this.userData)
      .subscribe(res => {
        this.loaded = true;
        if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
          this.outletData = res.results.data;
          this.rowData = this.outletData;
          if (this.gridApi !== null) {
            this.gridApi.setRowData(this.rowData);
          }

          //this.loaded = true;
        } else {
          this.preloader = false;
          //this.empty_state = true;
        }
      });
  }

  addOutlets() {
    this.selectedOutlet = this.gridApi.getSelectedRows();
    this.selectedOutlets.emit(this.selectedOutlet);
    this.dialog.closeAll();
  }

  setCustomFilter(customfilterdata) {

    for (const [key, value] of Object.entries(customfilterdata)) {
      this.userData.customFilter[key] = value;
    }
  }

  mainAggFunction(params) {
    let avg;
// the average will be the sum / count
    let sum = 0;
    let count = 0;

    params.values.forEach(function(value) {
      const groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        // we are aggregating groups, so we take the
        // aggregated values to calculated a weighted average
        sum += value.avg;
        /*  sum += value;
          count += value.count;*/
      } else {
        // skip values that are not numbers (ie skip empty values)
        if (typeof value === 'number') {
          //sum += value;
          sum = value;
          //count++;
        } else {
        }
      }
    });
    // avoid divide by zero error
    if (count !== 0) {
      avg = sum / count;
    } else {
      avg = null;
    }

    //avg = Math.round(Math.round((avg + Number.EPSILON) * 100) / 100);

    avg = sum;
    count = sum;

    // the result will be an object. when this cell is rendered, only the avg is shown.
    // however when this cell is part of another aggregation, the count is also needed
    // to create a weighted average for the next level.
    const result = {
      count,
      avg,
      // the grid by default uses toString to render values for an object, so this
      // is a trick to get the default cellRenderer to display the avg value
      toString() {
        return this.avg;
      },
    };

    return result;
  }

  visitavgAggFunction(params) {
    // the average will be the sum / count
    var sum = 0;
    var count = 0;
    params.values.forEach(function(value) {
      var groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        // we are aggregating groups, so we take the
        // aggregated values to calculated a weighted average
        sum += value.avg * value.count;
        count += value.count;
      } else {
        // skip values that are not numbers (ie skip empty values)
        if (value === 'Visited') {
          sum += 1;
          count++;
        }
      }
    });
    // avoid divide by zero error
    if (count !== 0) {
      var avg = sum / count;
    } else {
      avg = null;
    }

    avg = Math.round(Math.round((avg + Number.EPSILON) * 100) / 100);

    // the result will be an object. when this cell is rendered, only the avg is shown.
    // however when this cell is part of another aggregation, the count is also needed
    // to create a weighted average for the next level.
    var result = {
      count: count,
      avg: avg,
      // the grid by default uses toString to render values for an object, so this
      // is a trick to get the default cellRenderer to display the avg value
      toString: function() {
        return sum;
      },
    };

    return result;

  }
}
