import {ReportBase} from '../_common/reportbase';
import {Component, OnInit} from '@angular/core';
import {ErrorHandler} from '../../error/error-handler';

@Component({
  selector: 'app-gstr-line-report-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class GstrLineReportComponent extends ReportBase implements  OnInit {

  apiData: any = [];
  showCustomFilter = true;
  customFilter: any = {
    name: 'Type',
    key: 'type',
    value: 'S',
    values: [],
    show: true
  };

  ngOnInit() {
    this.sharedService.setHeader('GSTR Line Report');
    this.API_URL = '/api/pwa_reports/getGstrLineReport1';
    this.customFilter.values = [
      {id: "S", itemName: "Secondary"},
      {id: "P", itemName: "Primary"}
    ];

    this.gridOptions.autoGroupColumnDef = {
      headerName: 'Invoice Date',
      field: 'invoice_date',
      minWidth: 250,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        checkbox: true,
      },
    };

      }

  configureGrid() {
    this.columnDefs = [];
    this.columnDefs = [
      {headerName: "INVOICE DATE", field: "invoice_date", rowGroup: true, hide: true, filter: true},
      {headerName: "CUSTOMER CODE", field: "cust_code"},
      {headerName: "CUSTOMER NAME", field: "cust_name"},
      {headerName: "INVOICE NO", field: "invoice_no"},
      {headerName: "ITEM CODE", field: "item_code"},
      {headerName: "ITEM", field: "item_name", minWidth: 200},
      {headerName: "QTY", field: "item_qty", cellStyle: {textAlign: 'center'}},
      {headerName: "UNIT PRICE", field: "item_unit_price", cellStyle: {textAlign: 'center'}},
      {headerName: "TAXMASTER", field: "taxmaster"},
      {headerName: "TAXABLE AMT", field: "taxable_amount", cellStyle: {textAlign: 'center'}}
    ];
    const taxMaster = this.apiData.taxmaster;
    for (const key in taxMaster) {
      this.columnDefs.push({
        headerName: taxMaster[key].description,

        field: taxMaster[key].description,
        cellStyle: {textAlign: 'center'},
        sortable: true,
        filter: true,
        width: 100,
        resizable: true
      });
    }

    this.columnDefs.push({
      headerName: "TAX AMT", field: "tax_amount", cellStyle: {textAlign: 'center'},
      valueGetter: params => {
        if (params.data) {
          return Math.round((params.data.tax_amount + Number.EPSILON) * 100) / 100;
        }

      }
    });
    this.columnDefs.push({
      headerName: "TOTAL AMT", field: "total_amount", cellStyle: {textAlign: 'center'},
      valueGetter: params => {
        if (params.data) {
          return Math.round((params.data.total_amount + Number.EPSILON) * 100) / 100;
        }

      }
    });
    this.columnDefs.push({headerName: "GSTIN TIN", field: "gstin_tin"});
    this.columnDefs.push({headerName: "ORDER REF", field: "origin"});
    this.columnDefs.push({headerName: "TYPE", field: "invoice_type"});
    this.columnDefs.push({headerName: "HSN", field: "hsn"});
    this.columnDefs.push({headerName: "INVOICE TYPE", field: "invoice_type"});

  }

  postProcessData() {
    this.configureGrid();
    this.configureData();
  }

  configureData() {
    this.rowData = [];
    const taxMaster = this.apiData.taxmaster;
    const salesMaster = this.apiData.sales;
    const taxLines = this.apiData.taxlines;

    for (const key in this.apiData.sales) {

      let indrow = {};
      const taxRow: any = {};
      let salesRow: any = {};
      let taxLine: any = [];

      salesRow = this.apiData.sales[key];

      taxLine = taxLines.filter(tax => tax.id === salesRow.line_id);

      for (const key1 in taxMaster) {
        taxRow[taxMaster[key1].description] = taxLine.filter(tax => tax.description === taxMaster[key1].description)[0];
        if (taxRow[taxMaster[key1].description] !== null && taxRow[taxMaster[key1].description] !== undefined) {
          taxRow[taxMaster[key1].description] = taxRow[taxMaster[key1].description].tax_amt;
        }
      }

      indrow = {
        ...salesRow,
        ...taxRow
      };
      this.rowData.push(indrow);
    }
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllColumns().length;

    params.api.setRowData(this.rowData);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.columnApi.autoSizeAllColumns();
      });
    });
    this.gridColumnApi.autoSizeAllColumns();
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

}

