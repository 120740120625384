import {Component, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {ApiService} from "../../_services/api.service";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {LocalforageService} from "../../_services/localforage.service";
import {ErrorHandler} from "../../error/error-handler";
import {ErrorObject} from "../../error/error-model";

@Component({
  selector: 'app-move-beat-retailer-popup',
  templateUrl: './remove_beat_retailer_popup.component.html',
  styleUrls: ['./remove_beat_retailer_popup.component.scss']

})

export class RemoveBeatRetailerPopupComponent implements OnInit {

  @Output() removeData: EventEmitter<any> = new EventEmitter<any>();

  selectedData = {
    salesman_id: 0,
    territory_id: 0,
    beat_plan_id: 0,
    bpr_ids: [],
    retailer_ids: [],
    from_beat_plan_ids: []
  };

  territoriesList: any = [];
  filteredTerritoryList = [];
  salesmenList: any = [];
  beatPlanList: any = [];
  filteredBeatList = [];
  API_URL = '';
  access_token;
  retailerData;
  uq_ret;
  uq_bp;
  uq_ter;
  retailer_active_mode = "1";

  noBeatArray = [{id: 0, name : "No beat for selected Salesman"}];

  preloader: any;
  errorObject: ErrorObject;
  errorExists = false;
  dataLoaded: any;
  type = '';

  @Output() saveActionEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(private apiService: ApiService,
              public dialog: MatDialog,
              private toastr: ToastrService,
              protected _localforageService: LocalforageService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {

    this.type = dialogdata.type;
    this.retailerData = dialogdata.retailerData;
    this.selectedData.retailer_ids = [...new Set(this.retailerData.map(obj => obj.r_id))];
    this.selectedData.from_beat_plan_ids = [...new Set(this.retailerData.map(obj => obj.bp_id))];
    this.selectedData.bpr_ids = [...new Set(this.retailerData.map(obj => obj.bpr_id))];

    this.uq_ret = dialogdata.uq_ret;
    this.uq_bp = dialogdata.uq_bp;
    this.uq_ter = dialogdata.uq_ter;

  }

  ngOnInit(): void {

  }

  public handleError(error: ErrorObject) {
    this.preloader = false;
    this.dataLoaded = false;
    this.errorExists = true;
    this.errorObject = error;
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  onRemove() {
    const data: any = {};
    console.log("option Selection: " + this.retailer_active_mode);
    data.bpr_ids = this.selectedData.bpr_ids;
    data.retailer_ids = this.selectedData.retailer_ids;
    data.beat_plan_ids = this.selectedData.from_beat_plan_ids;
    data.inactivate_flag = (this.retailer_active_mode === "2");
    this.removeBeatRetailers(data);
  }

  removeBeatRetailers(data) {
    const apiurl = '/api/beat_plan_retailers/remove_beat_retailers';
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = data;
    this.apiService.post(apiurl, paramObject)
      .subscribe(res => {
          console.log('remove retailers');
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.toastr.success('Selected retailers removed from beat plan');
            this.removeData.emit('success');
          } else {
            console.log(res.results);
            this.toastr.error('Error removing retailers from beat plan!');
            this.removeData.emit('error');
          }
        },
        error => {
          console.log(error);
          this.toastr.error('Error removing retailers from beat plan!');
          this.removeData.emit('error');
        });
  }

}
