<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper" style="margin-top: 50px!important">
      <div style="background-color: white;margin-left: 5px;margin-right: 25px;height:650px;margin-top: 0px;">
        <div style="margin:10px;">
          <!--<div class="row" style="margin-top: 10px;">
            <div *ngIf="edit" class="button-row" style="margin-top: 10px;">
              <button
                mat-button
                style="margin-right:15px;"
                class="btn teal-btn"
                (click)="editClick()"
              >
                SAVE
              </button>
              <span>
               <a class="btn white-btn">DISCARD</a>
               </span>
            </div>
            <div *ngIf="!edit" class="button-row" style="margin-top: 10px;">
              <span>
               <a class="btn white-btn" style="margin-right:15px;" (click)="editClick()">EDIT</a>
               </span>
              <span>
               <a class="btn white-btn">DELETE</a>
               </span>
            </div>
            <div *ngIf="!edit" class="button-row" style="position: absolute;  right: 30px;margin-top: 10px; ">
              <div style="width: 350px;">
                <progress max="100" value="66" data-label="Progress 2/3"></progress>
              </div>
              <button
                mat-button
                style="margin-left:15px; margin-right:15px;"
                class="btn teal-btn"
              >
                PUBLISH
              </button>
            </div>
          </div>-->
          <div >
            <div>
              <div class="search-list" style="margin-top: 0px;">
                <div class="col-md-6 col-12 nopadding pl-0" style="margin-bottom: 50px;margin-top: 0px;">
                  <div class="row" style="margin-top: 10px;">
                    <div *ngIf="edit && DADetails.status!='published'" class="button-row" style="margin-top: 10px;">
                      <button
                        mat-button
                        style="margin-right:15px;"
                        class="btn teal-btn"
                        form="ngForm"
                        [disabled]="taskform.form.invalid"
                      >
                        SAVE
                      </button>
                      <span>
                         <a *ngIf="viewtype!='create'" class="btn white-btn" (click)="discardClick()">DISCARD</a>
                      </span>
                    </div>
                    <div *ngIf="!edit && DADetails.status!='published'" class="button-row" style="margin-top: 10px;">
                      <span>
                         <a class="btn white-btn" style="margin-right:15px;" (click)="editClick()">EDIT</a>
                      </span>
                    <!--  <span >
                        <a class="btn white-btn" (click)="cancelClick()">CANCEL</a>
                      </span>-->
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-12 pad0 text-right" style="margin-bottom: 50px;margin-top: 0px;">
                  <div class="filters">
                    <ul class="list-unstyled">
                      <li class="margLR" style="margin-right: 20px;margin-top: 10px;">
                        <span *ngIf="!edit">
                         <a class="btn teal-border-btn" style="margin-right:15px;" (click)="viewReport()">VIEW REPORT</a>
                        </span>
                      </li>
                      <li class="margLR" *ngIf="viewtype!='create'">
                        <div style="width: 200px;margin-top: 15px;">
                          <!--  <label for="file" style="display: block;text-align: center;font-size: 1.0em;">-->
                          <label for="file" class="progresslabel">
                            PROGRESS</label>
                          <label *ngIf="DADetails.progress<30" class="progresslabelcount" style="color:#B82525">{{DADetails.completed_count}}</label>
                          <label *ngIf="DADetails.progress>30" class="progresslabelcount">{{DADetails.completed_count}}</label>
                          <label style="color: black;font-size: 12px;"> /{{DADetails.line_count}}</label>
                          <progress id="file" max="100" value="{{DADetails.progress}}" style="margin-top: 5px;"></progress>
                        </div>
                      </li>
                      <li class="margLR" *ngIf="DADetails.status=='published'" style="margin-left: 5px;margin-bottom: 5px;">
                        <div style="margin-top: 15px;margin-left: 10px;margin-right: 10px;">
                          <img *ngIf="DADetails.status=='published' && DADetails.active" style="height: 18px;width:18px;margin-bottom: 8px;" src="./assets/tickteal.svg">
                          <img *ngIf="DADetails.status=='published' && !DADetails.active" style="height: 18px;width:18px;margin-bottom: 8px;" src="./assets/crossred.svg">
                          <span *ngIf="DADetails.status=='published' && DADetails.active" class="activetext">ACTIVE</span>
                          <span *ngIf="DADetails.status=='published' && !DADetails.active" class="activetext" style="color:#B82525">INACTIVE</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 pad0">
              <form novalidate (ngSubmit)="updateAudit()" id="ngForm" #taskform="ngForm">
                <div class="col-md-12 pl-0">
                  <div class="col-md-4 pl-0 pr-30">
                    <label style="color:#7D7D7D">SALESMAN</label>
                    <div style="margin-top: 5px;">
                      <!-- <div *ngIf="edit">
                         <input
                           class="forminput"
                           style="width: 70%;"
                           type="text"
                           name="salesman"
                           [(ngModel)]="DADetails.salesman"
                           #salesman="ngModel"
                           required
                         >
                         <div *ngIf="salesman.errors && (salesman.dirty || salesman.touched)">
                           <span *ngIf="salesman.errors.required" class="text-danger">Salesman is required</span>
                         </div>
                       </div>-->
                      <p *ngIf="!edit">{{DADetails.salesman}}</p>
                    </div>
                  </div>
                  <div class="col-md-4 pl-0 pr-20">
                    <label style="color:#7D7D7D">START DATE</label>
                    <!-- <div style="margin-top: 5px;" class="formselect">
                       <select
                         *ngIf="edit"
                         name="task_type"
                         [(ngModel)]="DADetails.type_id"
                         #task_type="ngModel"
                         style="width: 80%;"
                         (change) = "checkAllowStartDate()"
                       >
                         <option
                           *ngFor="let item of task_types"
                           value="{{ item.id }}"
                         >
                           {{ item.itemname }}
                           &lt;!&ndash;  <option value="Activation" selected>Activation</option>
                             <option value="BTL Activity">BTL Activity</option>
                             <option value="Scheme">Scheme</option>&ndash;&gt;
                       </select>-->
                    <p *ngIf="!edit">{{DADetails.start_date}}</p>
                  </div>
                  <div class="col-md-4 pl-0 pr-30">
                    <label style="color:#7D7D7D">CURRENT STOCK</label>
                    <div style="margin-top: 5px;">
                      <div *ngIf="edit">
                        <input
                          class="forminput"
                          style="width: 70%;"
                          type="text"
                          name="stock_value"
                          [(ngModel)]="DADetails.stock_value"
                          #stock_value="ngModel"
                          required
                        >
                        <div *ngIf="stock_value.errors">
                          <span *ngIf="stock_value.errors.required" class="text-danger">Stock Value is required</span>
                        </div>
                      </div>
                      <p *ngIf="!edit">{{DADetails.stock_value}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 pl-0">
                  <div class="col-md-4 pl-0 pr-30">
                    <label style="color:#7D7D7D">DISTRIBUTOR</label>
                    <div style="margin-top: 5px;">
                      <!-- <div *ngIf="edit">
                         <input
                           class="forminput"
                           style="width: 70%;"
                           type="text"
                           name="salesman"
                           [(ngModel)]="DADetails.salesman"
                           #salesman="ngModel"
                           required
                         >
                         <div *ngIf="salesman.errors && (salesman.dirty || salesman.touched)">
                           <span *ngIf="salesman.errors.required" class="text-danger">Salesman is required</span>
                         </div>
                       </div>-->
                      <p *ngIf="!edit">{{DADetails.dist}}</p>
                    </div>
                  </div>
                  <div class="col-md-4 pl-0 pr-20">
                    <label style="color:#7D7D7D">END DATE</label>
                    <!-- <div style="margin-top: 5px;" class="formselect">
                       <select
                         *ngIf="edit"
                         name="task_type"
                         [(ngModel)]="DADetails.type_id"
                         #task_type="ngModel"
                         style="width: 80%;"
                         (change) = "checkAllowStartDate()"
                       >
                         <option
                           *ngFor="let item of task_types"
                           value="{{ item.id }}"
                         >
                           {{ item.itemname }}
                           &lt;!&ndash;  <option value="Activation" selected>Activation</option>
                             <option value="BTL Activity">BTL Activity</option>
                             <option value="Scheme">Scheme</option>&ndash;&gt;
                       </select>-->
                    <p *ngIf="!edit">{{DADetails.end_date}}</p>
                  </div>
                  <div class="col-md-4 pl-0 pr-30">
                    <label style="color:#7D7D7D">STOCK QTY</label>
                    <div style="margin-top: 5px;">
                      <div *ngIf="edit">
                        <input
                          class="forminput"
                          style="width: 70%;"
                          type="text"
                          name="stock_qty"
                          [(ngModel)]="DADetails.stock_qty"
                          #stock_qty="ngModel"
                          required
                        >
                        <div *ngIf="stock_qty.errors">
                          <span *ngIf="stock_qty.errors.required" class="text-danger">Stock Qty is required</span>
                        </div>
                      </div>
                      <p *ngIf="!edit">{{DADetails.stock_qty}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 pl-0">
                  <div class="col-md-4 pl-0 pr-30">
                    <label style="color:#7D7D7D">TERRITORY</label>
                    <div style="margin-top: 5px;">
                      <!-- <div *ngIf="edit">
                         <input
                           class="forminput"
                           style="width: 70%;"
                           type="text"
                           name="salesman"
                           [(ngModel)]="DADetails.salesman"
                           #salesman="ngModel"
                           required
                         >
                         <div *ngIf="salesman.errors && (salesman.dirty || salesman.touched)">
                           <span *ngIf="salesman.errors.required" class="text-danger">Salesman is required</span>
                         </div>
                       </div>-->
                      <p *ngIf="!edit">{{DADetails.territory}}</p>
                    </div>
                  </div>
                  <div class="col-md-4 pl-0 pr-20">
                    <label style="color:#7D7D7D">LAST AUDIT DATE</label>
                    <!-- <div style="margin-top: 5px;" class="formselect">
                       <select
                         *ngIf="edit"
                         name="task_type"
                         [(ngModel)]="DADetails.type_id"
                         #task_type="ngModel"
                         style="width: 80%;"
                         (change) = "checkAllowStartDate()"
                       >
                         <option
                           *ngFor="let item of task_types"
                           value="{{ item.id }}"
                         >
                           {{ item.itemname }}
                           &lt;!&ndash;  <option value="Activation" selected>Activation</option>
                             <option value="BTL Activity">BTL Activity</option>
                             <option value="Scheme">Scheme</option>&ndash;&gt;
                       </select>-->
                    <p *ngIf="!edit">{{DADetails.last_audit_date}}</p>
                  </div>
                  <div class="col-md-4 pl-0 pr-30">
                    <label style="color:#7D7D7D">SECONDARY VALUE</label>
                    <div style="margin-top: 5px;">
                      <div *ngIf="edit">
                        <input
                          class="forminput"
                          style="width: 70%;"
                          type="text"
                          name="secondary"
                          [(ngModel)]="DADetails.secondary_value"
                          #secondary="ngModel"
                          required
                        >
                        <div *ngIf="secondary.errors">
                          <span *ngIf="secondary.errors.required" class="text-danger">Secondary Value is required</span>
                        </div>
                      </div>
                      <p *ngIf="!edit">{{DADetails.secondary_value}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 pl-0">
                  <div class="col-md-4 pl-0 pr-30">
                    <label style="color:#7D7D7D">PERIOD</label>
                    <div style="margin-top: 5px;">
                      <!-- <div *ngIf="edit">
                         <input
                           class="forminput"
                           style="width: 70%;"
                           type="text"
                           name="salesman"
                           [(ngModel)]="DADetails.salesman"
                           #salesman="ngModel"
                           required
                         >
                         <div *ngIf="salesman.errors && (salesman.dirty || salesman.touched)">
                           <span *ngIf="salesman.errors.required" class="text-danger">Salesman is required</span>
                         </div>
                       </div>-->
                      <p *ngIf="!edit">{{DADetails.period}}</p>
                    </div>
                  </div>
                  <div class="col-md-4 pl-0 pr-20">
                    <label style="color:#7D7D7D">APPROVED BY</label>
                    <!-- <div style="margin-top: 5px;" class="formselect">
                       <select
                         *ngIf="edit"
                         name="task_type"
                         [(ngModel)]="DADetails.type_id"
                         #task_type="ngModel"
                         style="width: 80%;"
                         (change) = "checkAllowStartDate()"
                       >
                         <option
                           *ngFor="let item of task_types"
                           value="{{ item.id }}"
                         >
                           {{ item.itemname }}
                           &lt;!&ndash;  <option value="Activation" selected>Activation</option>
                             <option value="BTL Activity">BTL Activity</option>
                             <option value="Scheme">Scheme</option>&ndash;&gt;
                       </select>-->
                    <p *ngIf="!edit">{{DADetails.approved_by}}</p>
                  </div>
                  <div class="col-md-4 pl-0 pr-30">
                    <label style="color:#7D7D7D">SECONDARY QTY</label>
                    <div style="margin-top: 5px;">
                      <div *ngIf="edit">
                        <input
                          class="forminput"
                          style="width: 70%;"
                          type="text"
                          name="secondary_qty"
                          [(ngModel)]="DADetails.secondary_qty"
                          #secondary_qty="ngModel"
                          required
                        >
                        <div *ngIf="secondary_qty.errors">
                          <span *ngIf="secondary_qty.errors.required" class="text-danger">Secondary Qty is required</span>
                        </div>
                      </div>
                      <p *ngIf="!edit">{{DADetails.secondary_qty}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 pl-0">
                  <div class="col-md-4 pl-0 pr-30">
                    <label style="color:#7D7D7D">STOCK TYPE</label>
                    <div style="margin-top: 5px;">
                      <!-- <div *ngIf="edit">
                         <input
                           class="forminput"
                           style="width: 70%;"
                           type="text"
                           name="salesman"
                           [(ngModel)]="DADetails.salesman"
                           #salesman="ngModel"
                           required
                         >
                         <div *ngIf="salesman.errors && (salesman.dirty || salesman.touched)">
                           <span *ngIf="salesman.errors.required" class="text-danger">Salesman is required</span>
                         </div>
                       </div>-->
                      <p *ngIf="!edit">{{DADetails.stock_type}}</p>
                    </div>
                  </div>
                  <div class="col-md-4 pl-0 pr-20">
                    <label style="color:#7D7D7D">APPROVED DATE</label>
                    <!-- <div style="margin-top: 5px;" class="formselect">
                       <select
                         *ngIf="edit"
                         name="task_type"
                         [(ngModel)]="DADetails.type_id"
                         #task_type="ngModel"
                         style="width: 80%;"
                         (change) = "checkAllowStartDate()"
                       >
                         <option
                           *ngFor="let item of task_types"
                           value="{{ item.id }}"
                         >
                           {{ item.itemname }}
                           &lt;!&ndash;  <option value="Activation" selected>Activation</option>
                             <option value="BTL Activity">BTL Activity</option>
                             <option value="Scheme">Scheme</option>&ndash;&gt;
                       </select>-->
                    <p *ngIf="!edit">{{DADetails.approved_date}}</p>
                  </div>
                  <div class="col-md-4 pl-0 pr-30">
                    <label style="color:#7D7D7D">PRIMARY VALUE</label>
                    <div style="margin-top: 5px;">
                      <div *ngIf="edit">
                        <input
                          class="forminput"
                          style="width: 70%;"
                          type="text"
                          name="primary_value"
                          [(ngModel)]="DADetails.primary_value"
                          #primary_value="ngModel"
                          required
                        >
                        <div *ngIf="primary_value.errors">
                          <span *ngIf="primary_value.errors.required" class="text-danger">Primary Value is required</span>
                        </div>
                      </div>
                      <p *ngIf="!edit">{{DADetails.secondary_qty}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 pl-0">
                  <div class="col-md-4 pl-0 pr-30">
                  </div>
                  <div class="col-md-4 pl-0 pr-20">

                  </div>
                  <div class="col-md-4 pl-0 pr-30">
                    <label style="color:#7D7D7D">PRIMARY QTY</label>
                    <div style="margin-top: 5px;">
                      <div *ngIf="edit">
                        <input
                          class="forminput"
                          style="width: 70%;"
                          type="text"
                          name="primary_qty"
                          [(ngModel)]="DADetails.primary_qty"
                          #primary_qty="ngModel"
                          required
                        >
                        <div *ngIf="primary_qty.errors">
                          <span *ngIf="primary_qty.errors.required" class="text-danger">Primary Qty is required</span>
                        </div>
                      </div>
                      <p *ngIf="!edit">{{DADetails.primary_qty}}</p>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div style="margin-top: 50px;">
              <div class="search-list">
                <div class="col-md-6 col-12 nopadding pl-0" style="margin-top: 30px;">
                  <div class="search" style="display: inline-block;">
                    <span class="search-control"><img src="assets/searchicon.png"/></span>
                    <input  id="filter-text-box" type="text" placeholder="filter" (input)="quickSearch()" class="search-control-area"
                            placeholder="Search">
                  </div>
                  <div style="display: inline-block;margin-right: 10px;" >
                    <!-- <button (click)="createDialog()" class="btn teal-btn" style="height: 42px;margin-bottom: 10px;margin-left:10px;">NEW TASK</button>-->

                    <!--   <button *ngIf="edit" class="icon-btn icon-btn-teal"
                               style="height: 42px;margin-bottom: 10px;margin-left:10px;"
                               (click)="createDialog()"
                       > <i class="fas fa-plus-circle"></i>
                         ADD</button>-->

                   <!-- <div style="display: inline-block;margin-top: 5px;margin-left:15px;cursor:pointer;" (click)="createDialog()" *ngIf="edit">
                      <i class="fas fa-plus-circle" style="color:#17A2B8;margin-right: 5px;margin-top:5px;font-size:16px;"></i>
                      <span style="font-size: 16px; font-weight: 400;color:#5D5D5D">Add</span>
                    </div>-->

                  </div>
                </div>
                <div class="col-md-6 col-12 pad0 text-right" style="margin-top: 30px;">
                  <div class="filters">
                    <ul class="list-unstyled">
                      <!-- <li class="margLR">
                         <div style="width: 350px;">
                           <progress max="100" value="66" data-label="Progress 2/3"></progress>
                         </div>
                       </li>-->
                      <!-- <li class="margLR" style="margin-left: 10px;">
                         <div
                           class="actionbutton"
                           title="Upload"
                         >
                           <img style=" display:block;margin:auto;" src="../../assets/upload2.svg">
                         </div>
                       </li>-->
                      <li class="margLR">
                        <app-excelicon [gridOption]="gridOptions" [title]="'Task Lines Report'"></app-excelicon>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-12 pad0">
                  <div>
                    <ag-grid-angular id="myGrid1" *ngIf="loaded" style="width: 100%;height:300px;" class="ag-theme-balham"   [defaultColDef]="defaultColDef"
                                     [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                                     (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                                     (cellClicked)="onCellClicked($event)"
                                     [context]="context">
                    </ag-grid-angular>
                    <div *ngIf="preloader">
                      <app-loadingstate></app-loadingstate>
                    </div>
                    <div *ngIf="empty_state">
                      <app-emptystate></app-emptystate>
                    </div>
                  </div>
                  <!--  <p *ngIf="edit" style="font-size: 12px;color:#17A2B8;margin-top: 10px;margin-left: 10px;cursor:pointer" (click)="createDialog()">Add an Item</p>-->
                  <!-- <img *ngIf="edit"
                        style="margin-top: 10px;margin-left: 10px;cursor:pointer"
                        title="Add Item"
                        (click)="createDialog()"
                        src="./../../../assets/additem.svg" />-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="pol-modal pol-send invoice-img modal" id="invoiceModal" role="dialog">
  <div class="modal-dialog" style="width: 300px;">
    <!-- Modal content-->
    <div class="modal-content">
        <span class="close" data-dismiss="modal">
          <img src="../../assets/close.png">
        </span>
      <div class="modal-body">
        <img [src]="photo">
      </div>
    </div>
  </div>
</div>

<button #imageClick id="openImage" [hidden]="true" data-toggle="modal" data-target="#invoiceModal">Open Modal</button>
