import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';


@Component({
  selector: 'app-volume-service-line-spare-parts',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class ServiceLineSparePartsComponent extends ReportBase implements IReport, OnInit {

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Service Line Spare Parts');

    this.showableFilters.salesman = false;
    this.showableFilters.team = false;
    this.showableFilters.state = false;
    this.showableFilters.region = false;
    this.showDate = true;
    this.configureGrid();
    this.API_URL = '/api/pwa_v2_reports/serviceLineSparePartsReport';
  }

  configureGrid() {

    this.columnDefs = [
      {
        headerName: "SERVICE",
        children: [
          {headerName: 'TICKET', field: 'name', width: 80, pinned: 'left'},
          {headerName: 'CUSTOMER', field: 'customer_name', width: 100, pinned: 'left'},
          {headerName: 'CUSTOMER MOBILE', field: 'customer_mobile', width: 100, pinned: 'left', hide: true},
          {headerName: 'STATUS', field: 'status', width: 80, pinned: 'left'},
          {headerName: 'USER', field: 'user_name', width: 80, pinned: 'left'},
          {headerName: 'EMP CODE', field: 'emp_code', width: 80, pinned: 'left'},
          {headerName: 'REQ DATE', field: 'req_date', width: 80, pinned: 'left'},
          {headerName: 'SERVICE START', field: 'service_start', width: 120, pinned: 'left', hide: true},
          {headerName: 'SERVICE CLOSED', field: 'service_closed', width: 120, pinned: 'left', hide: true},
        ]
      },
      {
        headerName: "DEFECTIVE PRODUCT",
        children: [
          {headerName: 'TYPE', field: 'type', width: 80},
          {headerName: 'BATCH NO', field: 'batch_no', width: 60},
          {headerName: 'WARRANTY', field: 'warranty', width: 120},
          {headerName: 'QTY', field: 'qty', width: 80},
          {headerName: 'SERIAL NO', field: 'serial_no', width: 80}
        ]
      },
      {
        headerName: "REPLACEMENT PRODUCT",
        children: [
          {headerName: 'REPLACE BN', field: 'replace_batch_no', width: 120},
          {headerName: 'REPLACE SN', field: 'replace_serial_no', width: 100}
        ]
      },
      {
        headerName: "SPARES",
        children: [
          {headerName: 'SPARE PRODUCT', field: 'spares_product_name', width: 120},
          {headerName: 'SPARES BN', field: 'spares_batch_no', width: 60},
          {headerName: 'SPARES SN', field: 'spares_serial_no', width: 60},
          {headerName: 'SPARES QTY', field: 'spares_qty', width: 60}
        ]
      }
    ];
  }

}
