import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
    selector: 'app-outlet-last-visit-component',
    templateUrl: '../_common/reportbase.html',
    styleUrls: ['../_common/reportbase.scss']
})

export class OutletLastVisitReportComponent extends ReportBase implements IReport, OnInit {

    setTitle() {
        throw new Error('Method not implemented.');
    }

    ngOnInit() {

        this.sharedService.setHeader('Outlet Last Visit Report');

        this.showableFilters.salesman = true;
        this.showableFilters.team = true;
        this.showableFilters.cluster = true;
        this.showableFilters.region = true;
        //this.showDate = false;

        this.loadInBatches(5000, 'retailer_id');
        this.showProgressCustomText = true;

        this.configureGrid();

        this.gridOptions.headerHeight = 40;
        this.gridOptions.autoGroupColumnDef = {
            headerName: 'Retailer Name',
            field: 'retailer_name',
            minWidth: 200,
            cellRenderer: 'agGroupCellRenderer',
            resizable: true
        };
    }

    configureGrid() {
        this.API_URL = '/api/pwa_reports/getOutletLastVisitReport';

        this.columnDefs = [
            {
                headerName: "Territory",
                children: [
                    {headerName: 'REGION', field: 'region', enableRowGroup: true , hide: true},
                    {headerName: 'CLUSTER', field: 'cluster', enableRowGroup: true , hide: true},
                    {headerName: 'TERRITORY', field: 'terr_name', enableRowGroup: true },
                    {headerName: 'TERRITORY CODE', field: 'terr_code', enableRowGroup: true, hide: true },
                    {headerName: 'TEAM', field: 'team_name', width: 100, enableRowGroup: true},
                    {headerName: 'EMP CODE', field: 'emp_code', hide: true, enableRowGroup: true },
                    {headerName: 'SALESMAN', field: 'salesman', enableRowGroup: true },
                    {headerName: 'ROLE', field: 'role', enableRowGroup: true },
                    {headerName: 'REPORTING MANAGER', field: 'team_lead', hide: true, enableRowGroup: true },
                    {headerName: 'PARENT TEAM', field: 'parent_name', hide: true, width: 100, enableRowGroup: true}
                ]
            },
            {
                headerName: "Beat",
                children: [
                    {
                        headerName: 'BEAT CODE', field: 'beats_json' , hide: true,
                        valueGetter: params => {
                            if (params.data && params.data.beats_json && params.data.beats_json.length > 0) {
                                let beat_detail = '';
                                params.data.beats_json.forEach(beat => {
                                    if (beat.hasOwnProperty('code')) {
                                        beat_detail += "" + beat.code + '\n';
                                    }
                                });
                                return beat_detail;
                            } else {
                                return "N/A";
                            }
                        }
                    },
                    {
                        headerName: 'BEAT', field: 'beats_json', enableRowGroup: true ,
                        valueGetter: params => {
                            if (params.data && params.data.beats_json && params.data.beats_json.length > 0) {
                                let beat_detail = '';
                                params.data.beats_json.forEach(beat => {
                                    if (beat.hasOwnProperty('name')) {
                                        beat_detail += "" + beat.name + '\n';
                                    }
                                });
                                return beat_detail;
                            } else {
                                return "N/A";
                            }
                        }
                    },
                    {
                        headerName: 'DISTRIBUTOR', field: 'beats_json', enableRowGroup: true, hide: true ,
                        valueGetter: params => {
                            if (params.data && params.data.beats_json && params.data.beats_json.length > 0) {
                                let beat_detail = '';
                                params.data.beats_json.forEach(beat => {
                                    if (beat.hasOwnProperty('dist')) {
                                        beat_detail += "" + beat.dist + '\n';
                                    }
                                });
                                return beat_detail;
                            } else {
                                return "N/A";
                            }
                        }
                    },
                ]
            },
            {
                headerName: "Outlet",
                children: [
                    {headerName: 'ID', field: 'retailer_id', hide: true },
                    {headerName: 'CODE', field: 'retailer_code', enableRowGroup: true },
                    {headerName: 'NAME', field: 'retailer_name', enableRowGroup: true, width: 150},
                    {headerName: 'TYPE', field: 'retailer_type', enableRowGroup: true },
                    {headerName: 'CLASS', field: 'class', enableRowGroup: true },
                    {headerName: 'PROGRAM TYPE', field: 'program_type', enableRowGroup: true },
                    {headerName: 'CHAIN CODE', field: 'chain_code', enableRowGroup: true },
                    {headerName: 'CHAIN NAME', field: 'chain_name', enableRowGroup: true },
                    {headerName: 'CHAIN GROUP', field: 'chain_program', enableRowGroup: true, hide: true },
                    {headerName: 'VISIT FREQUENCY', field: 'visit_frequency', enableRowGroup: true },
                    {headerName: 'CREATED ON', field: 'create_date', enableRowGroup: true },
                    {headerName: 'STATUS', field: 'status', enableRowGroup: true },
                ]
            },
            {
                headerName: "Selected Date Range Status",
                children: [
                    {headerName: 'LAST VISIT', field: 'last_visit_date', enableRowGroup: true },
                    {headerName: 'LAST VISIT BY', field: 'last_visit_by', enableRowGroup: true },
                    {headerName: 'LAST VISIT ROLE', field: 'last_visit_by_role', enableRowGroup: true },
                    {headerName: 'LAST VISIT ACTIVITY', field: 'last_activity_type', enableRowGroup: true },
                    {
                        headerName: 'NO OF VISIT', field: 'visit_count', enableRowGroup: true , aggFunc: 'sum',
                        valueGetter: params => {
                            if (params.data) {
                                if (params.data.visit_count) {
                                    return params.data.visit_count;
                                } else {
                                    return 0;
                                }
                            }
                        }
                    },
                    {
                        headerName: 'COMPLETED VISITS', field: 'completed_visits', enableRowGroup: true , aggFunc: 'sum',
                        valueGetter: params => {
                            if (params.data) {
                                if (params.data.completed_visits) {
                                    return params.data.completed_visits;
                                } else {
                                    return 0;
                                }
                            }
                        }
                    },
                    {
                        headerName: 'NO OF ORDERS', field: 'order_count', enableRowGroup: true , aggFunc: 'sum',
                        valueGetter: params => {
                            if (params.data) {
                                if (params.data.order_count) {
                                    return params.data.order_count;
                                } else {
                                    return 0;
                                }
                            }
                        }
                    },
                    {headerName: 'LAST ORDER DATE', field: 'last_order_date', enableRowGroup: true },
                    {headerName: 'AVG BILL VALUE', field: 'abv', enableRowGroup: true },
                    {headerName: 'ORDER FREQUENCY', field: 'order_frequency', enableRowGroup: true }
                ]
            },
            {
                headerName: "Recent Status",
                children: [
                    {headerName: 'DAYS SINCE RECENT VISIT', field: 'last_visit_since', enableRowGroup: true },
                    {headerName: 'RECENT VISIT', field: 'recent_visit_date', enableRowGroup: true },
                    {headerName: 'RECENT ORDER', field: 'recent_order_date', enableRowGroup: true },
                ]
            }
        ];

    }

}
