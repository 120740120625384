import {Component, OnInit} from "@angular/core";
import {ReportBase} from "./_common/reportbase";
import {PrintClickRenderer} from "../printClickRenderer.component";
import {RegenerateComponent} from "../regenerate.component";
import {RegenerateHeaderComponent} from "../regenerateHeader.component";
import {CustomGridToolPanelComponent} from "./_common/CustomGridToolPanel.component";
import {GridOptions} from "@ag-grid-enterprise/all-modules";

@Component({
  selector: 'app-credit-returns-component',
  templateUrl: './_common/reportbase.html',
  styleUrls: ['./_common/reportbase.scss']
})

export class CreditReturnsReportComponent extends ReportBase implements IReport, OnInit {

  setTitle() {
    this.title = 'Credit Returns';
  }

  ngOnInit() {

    this.sharedService.setHeader('Credit Returns');
    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      // groupDisplayType: 'groupRows',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      rowGroupPanelShow: 'always',
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      defaultColDef: {
        headerClass: 'myagheader',
        filter: true,
        sortable: true,
        resizable: true
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      icons: {
        'custom-tools': '<span><i style="padding: 8px" class="fal fa-tools"></i></span>',
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
          {
            id: 'customTools',
            labelDefault: 'Tools',
            labelKey: 'customTools',
            iconKey: 'custom-tools',
            toolPanel: 'CustomToolPanel',
            toolPanelParams: {
              reportname: this.constructor.name
            }
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      /* onColumnVisible: this.onColumnVisible,
       onDragStopped : this.onDragStopped*/
    } as GridOptions;
    this.context = {componentParent: this};
    this.frameworkComponents = {
      CustomToolPanel: CustomGridToolPanelComponent,
      regenerateRenderer: RegenerateComponent,
      regenerateHeadercomponent: RegenerateHeaderComponent
    };
    this.configureGrid();

    this.showableFilters.dc = true;
    this.uploadTask = true;
    this.uploadexcel = this.uploadCreditReturns;
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getCreditReturns';
    // this.filterConfig.salesman = true;
    // this.filterConfig.outletType = true;
    const inThis = this;
    this.columnDefs = [
      {
        headerName: 'DATE', field: 'order_date', width: 100, headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        cellRenderer: (data) => {
          return data.value ? inThis.datePipe.transform((new Date(data.value + ' UTC')).toString(), 'dd/MM/yyyy') : '';
        }
      },
      {headerName: 'PERIOD', field: 'period', width: 80},
      {headerName: 'STATE', field: 'dc_state', width: 80},
      {headerName: 'REGION', field: 'region', width: 80},
      {headerName: 'DC NAME', field: 'dc_name', width: 100},
      {headerName: 'TI', field: 'ti_name', width: 100},
      {headerName: 'VAN', field: 'user', width: 100},
      {headerName: 'SALES AREA CODE', field: 'sales_area_code', width: 100},
      {headerName: 'BEAT', field: 'beat', width: 100},
      {headerName: 'ZONE', field: 'zone', width: 80},
      {headerName: 'AREA', field: 'area', width: 80},
      {headerName: 'PRICELIST', field: 'pricelist', width: 100},
      {headerName: 'SUPPLIER CODE', field: 'supp_code', width: 100},
      {headerName: 'SUPPLIER NAME', field: 'supp_name', width: 100},
      {headerName: 'OUTLET CODE', field: 'cust_code', width: 100},
      {headerName: 'OUTLET', field: 'cust_name', width: 100},
      {headerName: 'OUTLET STATUS', field: 'out_status', width: 100},
      {headerName: 'OTYPE', field: 'outlet_type', width: 100},
      {headerName: 'QTY', field: 'qty', width: 80},
      {headerName: 'PRODUCT CODE', field: 'product_code', width: 80},
      {headerName: 'PRODUCT', field: 'product_name', width: 80},
      {headerName: 'PRODUCT CATEGORY', field: 'category', width: 80},
      {headerName: 'HSN', field: 'hsn', width: 80},
      {headerName: 'UNIT PRICE', field: 'ut_price', cellStyle: {textAlign: 'right'}, width: 80},
      {headerName: 'ORDER REF', field: 'order', width: 100},
      {headerName: 'INVOICE', field: 'invoice_no', width: 100},
      {headerName: 'COMMENTS', field: 'comments', width: 100},
      {headerName: 'NET SALE', field: 'price_subtotal', width: 100},
      {headerName: 'TAX', field: 'tax', cellStyle: {textAlign: 'right'}, width: 80},
      {headerName: 'TOTAL TAX(%)', field: 'tax_amount', width: 80},
      {headerName: 'AMOUNT TAX', field: 'amount_tax', cellStyle: {textAlign: 'right'}, width: 80},
      {headerName: 'LINE ID', field: 'line_id', width: 100},
      {headerName: 'ORDER AMOUNT', field: 'amount_total', cellStyle: {textAlign: 'right'}, width: 80},
      {headerName: 'TCS/TDS', field: 'tcs', cellStyle: {textAlign: 'right'}, width: 80},
      {headerName: 'PAYMENT MODE', field: 'mode', width: 100},
      {headerName: 'IRN Status', field: 'IRN Status', width: 100},
      {headerName: 'IRN', field: 'irn', width: 100},
      {headerName: 'GRN', field: 'grn', width: 100},
      {
        headerName: 'PRINT', field: 'PRINT', resizable: true, pinned: 'right',
        cellRenderer: PrintClickRenderer, width: 80
      },
      {
        headerName: 'REGENERATE', field: 'REGENERATE',
        headerComponent: "regenerateHeadercomponent",
        cellRenderer: "regenerateRenderer", pinned: 'right', width: 120
      },
      {headerName: 'id', field: 'id', hide: true, width: 80}
    ];
  }



  regenerateClick(e) {
    e['order_type'] = 'credit_return';
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.orders = [e];
    paramObject.regenerate = false;
    this.apiService.post('/api/einvoice_orders/generate_einvoice', paramObject)
      .subscribe(res => {
          if (res.hasOwnProperty('result') && (res.result.statusCode === 200)) {
            console.log(res);
            this.toastr.success("E-Invoice generate success!!");
            this.loadServerData();
          } else {
            this.preloader = false;
            this.toastr.error("E-Invoice generate failed!!");
          }
        },
        error => {
          //console.log(error);
          this.toastr.error("E-Invoice generate failed!!");
          this.preloader = false;
        });
  }

  async uploadCreditReturns() {
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    this.uploading = true;
    this.upload_loader = true;
    let distinct_order = [];
    const line_items = [];
    const map = new Map();
    this.succcessCount = 0;
    this.failedCount = 0;
    let task_status = "";
    let unauthorised_access = false;
    const paramObject: any = {};
    let obj = {};
    let line_obj = {};
    let fetchItem = [];
    let invalid = false;
    const numcharPattern = /^([a-z0-9]+)$/i;
    const floatPattern = /^\d*\.?\d*$/;
    const numPattern = /^[0-9]*$/;
    paramObject.access_token = this.userData.access_token;
    for (const item of this.upload_task_data) {
      if (item.Invoiceno === null || item.Invoiceno === undefined || item.Invoiceno === "") {
        invalid = true;
        this.toastr.warning('Invoice number cannot be empty');
        break;
      } else if (!numcharPattern.test(item.Invoiceno)) {
        invalid = true;
        this.toastr.warning('Invalid Invoice Number - ' + item.Invoiceno + ' - [a-z0-9]');
        break;
      } else if (item.Invoiceno.length > 30) {
        invalid = true;
        this.toastr.warning('Invalid Invoice Number - ' + item.Invoiceno + ' -  max length 30');
        break;
      } else if (item['Product Code'] === null || item['Product Code'] === undefined || item['Product Code'] === "") {
        invalid = true;
        this.toastr.warning('Product Code cannot be empty');
        break;
      } else if (!numcharPattern.test(item['Product Code'])) {
        invalid = true;
        this.toastr.warning('Invalid Product Code : ' + item['Product Code'] + ' - [a-z0-9]');
        break;
      } else if (item['Qty'] === null || item['Qty'] === undefined || item['Qty'] === "") {
        invalid = true;
        this.toastr.warning('Quantity cannot be empty');
        break;
      } else if (!numPattern.test(item['Qty'])) {
        invalid = true;
        this.toastr.warning('Invalid Quantity : ' + item['Qty']);
        break;
      } else if (item['Unit Price'] === null || item['Unit Price'] === undefined || item['Unit Price'] === "") {
        invalid = true;
        this.toastr.warning('Unit Price cannot be empty');
        break;
      } else if (!floatPattern.test(item['Unit Price'])) {
        invalid = true;
        this.toastr.warning('Invalid Unit Price : ' + item['Unit Price']);
        break;
      }
      obj = {
        name: item.OrderReference,
        invoice_no: item.Invoiceno,
        order_date: new Date(item['Date']),
        cust_code: item['Customer Code'],
        supp_code: item['Supplier Code'],
        product_code: item['Product Code'],
        qty: item['Qty'],
        price_unit: item['Unit Price'],
        irn: item.IRN,
        signed_qr: item['SignedQRCode'],
        ack_date: item['AckDate'],
        line_data: []
      };
      line_obj = {
        product_code: item['Product Code'],
        qty: item['Qty'],
        price_unit: item['Unit Price'],
      };
      if (item['OrderReference'] !== null) {
        if (map.get(item['OrderReference']) !== true) {
          map.set(item['OrderReference'], true);
          obj['line_data'].push(line_obj);
          distinct_order.push(obj);
        } else {
          fetchItem = distinct_order.filter(elem => {
            return item['OrderReference'] === elem.name;
          });
          fetchItem[0].line_data.push(line_obj);
        }
      }
    }
    /*for (const item of distinct_order) {
      if(item.line_data === null || item.line_data.length === 0) {
        invalid = true;
        this.toastr.warning('Line Data missing');
        break;
      }
    }*/
    if (!invalid) {
      let upload_task;
      if (distinct_order.length > 0) {
        do {
          upload_task = distinct_order.splice(1);
          paramObject.order = distinct_order[0];
          const resp = await this.apiService.uploadCreditReturn(paramObject);
          if (resp.hasOwnProperty('result') && resp['result'].statusCode === 200) {
            this.succcessCount += distinct_order.length;
          } else if (resp.hasOwnProperty('result') && resp['result'].statusCode === 199) {
            this.toastr.warning('Unauthorised Access');
            task_status = resp['result'].msg;
            unauthorised_access = true;
            break;
          } else {
            this.failedCount += 1;
          }
          distinct_order = upload_task;
        } while (distinct_order.length > 0);
        if (unauthorised_access) {
          this.uploadCaption = [task_status];
        } else {
          this.uploadCaption = ['Uploading completed, Successes: ' + this.succcessCount + ',Failures: ' + this.failedCount, 0];
        }
        this.uploading = false;
        this.upload_loader = false;
        this.order_data = [];
        this.fileUp.nativeElement.value = null;
        this.loadServerData();
        this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
      } else {
        // this.toastr.warning('Customer List Not Loaded Yet.Try Again Later');
      }
    } else {
      this.uploading = false;
      this.upload_loader = false;
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    }
  }

  onCellClicked(event) {

    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    if (column === 'REGENERATE') {
      this.regenerateClick(rows[0]);
    } else {
      this.generatePrintData(column, rows, 'id');
    }
  }

  triggerFalseClick() {

    const el: HTMLElement = this.myDiv.nativeElement;
    el.click();
  }

  triggerFalseClick2() {
    const el: HTMLElement = this.interPrint.nativeElement;
    el.click();
  }

  async callRegenerate(orders) {

    const batch_count = orders.length / 10;
    let rem_orders;
    let success_count = 0;
    let failure_count = 0;
    do {
      rem_orders = orders.splice(10);
      const order_data = {
        access_token: this.userData.access_token,
        url: this.userData.url,
        orders
      };
      const resp = await this.apiService.regenerateIrn(order_data);
      if (resp['results'] && resp['results'].statusCode === 200) {
        let fail_count = 0;
        if (resp['results'].failures) {
          fail_count = resp['results'].failures.length;
          failure_count += fail_count;
        }
        success_count += orders.length - fail_count;
      } else {
        failure_count += orders.length;
      }
      orders = rem_orders;
    } while (rem_orders.length > 0);
    return {success: success_count, failure: failure_count};

  }

  async regenerateAll(rows, nodes) {
    if (rows.length > 0) {
      this.elRef.nativeElement.querySelector('#overlay').style.display = "block";
      const instances = nodes[0].beans.gridApi.getCellRendererInstances({columns: ['Action'], rowNodes: nodes});
      instances.forEach(instance => {
        instance.loader = true;
        instance.enable_regenerate = false;
      });
      const orders = [];
      if (rows.length > 200) {
        window.alert('First 200 records only will get generated');
        rows.splice(200);
      }
      rows.forEach(van_row => {
        orders.push({
          id: van_row['id'],
          order_type: 'credit_return',
          invoice_no: van_row['invoice_no']
        });
      });

      const resp = await this.callRegenerate(orders);

      window.alert("IRN Regeneration Status:- Success - " + resp.success + " Failure - " + resp.failure);

      instances.forEach(instance => {
        instance.loader = false;
        instance.enable_regenerate = true;
      });

      this.loadServerData();
    } else {
      window.alert('Select Atleast one record');
    }

    this.elRef.nativeElement.querySelector('#overlay').style.display = "none";
  }

  async regenerate(params, node) {

    const instances = node.beans.gridApi.getCellRendererInstances({columns: ['Action'], rowNodes: [node]});
    const order_data = {
      access_token: this.userData.access_token,
      url: this.userData.url,
      orders: [{
        id: params['id'],
        order_type: 'credit_return',
        invoice_no: params['invoice_no']
      }]
    };

    const res_data = await this.apiService.regenerateIrn(order_data);
    if (res_data.hasOwnProperty('results') && (res_data['results'].statusCode === 200)) {
      if (res_data['results'].failures.length === 0) {
        window.alert('IRN Regeneration Success');
        this.loadServerData();
      } else {
        window.alert(res_data['results'].msg);
      }
      instances.forEach(instance => {
        instance.loader = false;
        instance.enable_regenerate = true;
      });
    } else {
      window.alert('IRN Regeneration Failed');
      instances.forEach(instance => {
        instance.loader = false;
        instance.enable_regenerate = true;
      });
    }
  }

}
