<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>TERRITORIES</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <div class="row">
        <div class="row">
          <div class="row search" style="display: inline-block;margin-left: auto;margin-bottom: 0px;">
            <span class="search-control"><img src="assets/searchicon.png"/></span>
            <input id="filter-text-box1" type="text" placeholder="filter" (input)="quickSearch()"
                   class="search-control-area"
                   placeholder="Search">
          </div>
        </div>

        <div class="col-md-12 pad0" style="margin-top: 20px;">
          <div>
            <ag-grid-angular *ngIf=loaded id="myGrid2" style="height:55vh;" class="ag-theme-alpine"
                             [defaultColDef]="defaultColDef"
                             [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                             (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                             (cellClicked)="onCellClicked($event)"
                             [context]="context">
            </ag-grid-angular>
            <div *ngIf="preloader">
              <app-loadingstate></app-loadingstate>
            </div>
            <div *ngIf="emptyState">
              <app-emptystate></app-emptystate>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div style=" display:block; vertical-align:bottom;  padding: 5px 15px;">
      <button
        mat-button
        class="btn confirm-blue-btn"
        style="margin-top: 20px;float: right"
        (click)="addTerritories()"
      >
        ADD
      </button>
    </div>
  </div>
</section>
