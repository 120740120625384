import {Component} from "@angular/core";
import {IHeaderAngularComp} from "@ag-grid-community/angular";

@Component({
  selector: 'convertpopup-header',
  template: `<span><button class="btn white-blue-btn" (click)="convert()">Convert</button></span>`,
  styles: []
})
export class ConvertPopUpComponent implements IHeaderAngularComp {
  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  public convert() {

    this.params.context.componentParent.convertAll(this.params.api.getSelectedRows(), this.params.api.getSelectedNodes());

  }

  refresh(): boolean {
    return false;
  }
}
