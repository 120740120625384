<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Create Service Request</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <form novalidate id="ngForm" #servicereq="ngForm">
      <div class="row centeralign">
        <div class="mt-2">
          <input
            type="checkbox"
            name="type"
            class="mr-3"
            [(ngModel)]="assetLinked"
            (ngModelChange)="assetLinkedChange($event)"
            #type="ngModel"
            style="height: 16px;width: 16px;vertical-align: middle;"
          >
          <label class="dialoglabel" style="display: inline-block;margin-top: 4px; vertical-align: middle">Asset
            Linked Service</label>
        </div>
        <div style="position: absolute; right: 10px;">
          <span style="display: block;margin-top: 20px;">Priority</span>
          <app-star-rating *ngFor="let index of stars" [index]="index" (click)="selected = index"
                           [selected]="selected" (mouseover)="hover = index" (mouseout)="hover = -1"
                           [hover]="hover"></app-star-rating>
        </div>
      </div>
      <div>
        <div class="mt-4">
          <span class="dialoglabel">Customer</span>
          <span class="requiredspan"> *</span>
          <div style="margin-top: 5px;">
            <ng-select *ngIf="customerList" [items]="customerList"
                       bindLabel="name"
                       bindValue="id"
                       class="custom"
                       (ngModelChange)="customerChange($event)"
                       [(ngModel)]="serviceDetails.customer_id"
                       [virtualScroll]="true"
                       name="customer_id"
                       #customer_id="ngModel"
                       required>
              >
            </ng-select>
            <span *ngIf="!customerList" class="detailvaluedesc">Fetching Customers ...</span>
          </div>
        </div>
        <div *ngIf="assetLinked">
          <div class="row mt-4">
            <div class="col-md-6 pl-0 mt-0">
              <span class="dialoglabel">Purchased From</span>
              <span class="requiredspan"> *</span>
              <div class="formselectwhitebg">
                <select name="ppf"  #ppf="ngModel" [(ngModel)]="serviceDetails.ppf" style="margin-top: 5px;">
                  <option value="company">Company</option>
                  <option value="distributor">Distributor</option>
                  <option value="dealer">Dealer</option>
                </select>
              </div>
            </div>
            <div class="col-md-6 pl-0 mt-0 pr-0">
              <span class="dialoglabel">Asset</span>
              <span class="requiredspan"> *</span>
              <div  style="margin-top: 5px;">
                <ng-select *ngIf="assetList" [items]="assetList"
                           bindLabel="name"
                           bindValue="id"
                           class="custom"
                           [(ngModel)]="serviceDetails.partner_asset_id"
                           [virtualScroll]="true"
                           name="partner_asset_id"
                           #partner_asset_id="ngModel"
                           required>
                  >
                </ng-select>
                <span *ngIf="!assetList" class="detailvaluedesc">Fetching Assets ...</span>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-6 pl-0 mt-0">
              <span class="dialoglabel">Source</span>
              <span class="requiredspan"> *</span>
              <div class="formselectwhitebg">
                <select name="source"  #source="ngModel" [(ngModel)]="serviceDetails.source" style="margin-top: 5px;" required>
                  <option *ngFor="let source of sources"
                          [ngValue]="source.name">{{source.name}}</option>
                </select>
              </div>
            </div>
            <div class="col-md-6 pl-0 mt-0 pr-0">
              <span class="dialoglabel">Service Type</span>
              <span class="requiredspan"> *</span>
              <div class="formselectwhitebg">
                <select name="service_type"  #service_type="ngModel" [(ngModel)]="serviceDetails.service_type" style="margin-top: 5px;" required>
                  <option *ngFor="let type of service_types"
                          [ngValue]="type.id">{{type.name}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-6 pl-0 mt-0">
              <span class="dialoglabel">Referrer Name</span>
              <span class="requiredspan"> *</span>
              <div>
                <input
                  class="forminputwhitebg"
                  style="display: block; color: #0762AD; font-size: 14px; margin-top: 5px;width: 100%;"
                  type="text"
                  name="ref_name"
                  [(ngModel)]="serviceDetails.referrer_name"
                  #ref_name="ngModel"
                  placeholder="Enter Referrer Name"
                  required
                >
                <div *ngIf="ref_name.errors && (ref_name.dirty || ref_name.touched)">
                  <span *ngIf="ref_name.errors.required" class="text-danger">Referrer Name is required</span>
                </div>
              </div>
            </div>
            <div class="col-md-6 pl-0 mt-0 pr-0">
              <span class="dialoglabel">Referrer Mobile</span>
              <span class="requiredspan"> *</span>
              <div>
                <input
                  class="forminputwhitebg"
                  style="display: block; color: #0762AD; font-size: 14px; margin-top: 5px;width: 100%;"
                  type="text"
                  name="ref_mobile"
                  [(ngModel)]="serviceDetails.referrer_mobile"
                  #ref_mobile="ngModel"
                  placeholder="Enter Referrer Mobile"
                  required
                >
                <div *ngIf="ref_mobile.errors && (ref_mobile.dirty || ref_mobile.touched)">
                  <span *ngIf="ref_mobile.errors.required" class="text-danger">Referrer Mobile is required</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!assetLinked">
          <div class="row mt-4">
            <div class="col-md-6 pl-0 mt-0">
              <span class="dialoglabel">Purchased From</span>
              <span class="requiredspan"> *</span>
              <div class="formselectwhitebg">
                <select name="ppf"  #ppf="ngModel" [(ngModel)]="serviceDetails.ppf" style="margin-top: 5px;" required>
                  <option value="company">Company</option>
                  <option value="distributor">Distributor</option>
                  <option value="dealer">Dealer</option>
                </select>
              </div>
            </div>
            <div class="col-md-6 pl-0 mt-0 pr-0">
              <span class="dialoglabel">Warranty</span>
              <span class="requiredspan"> *</span>
              <div class="formselectwhitebg">
                <select name="warranty"  #warranty="ngModel" [(ngModel)]="serviceDetails.warranty" style="margin-top: 5px;" required>
                  <option [value]=true>Yes</option>
                  <option [value]=false>No</option>
                </select>
              </div>
            </div>
          </div>
          <!--<div class="row mt-4">
            <div class="col-md-6 pl-0 mt-0">
              <span class="dialoglabel">Customer Mobile</span>
              <span class="requiredspan"> *</span>
              <div>
                <input
                  class="forminputwhitebg"
                  style="display: block; color: #0762AD; font-size: 14px; margin-top: 5px;width: 100%;"
                  type="text"
                  name="cust_mobile"
                  [(ngModel)]="serviceDetails.cust_mobile"
                  #cust_mobile="ngModel"
                  placeholder="Enter Mobile Number"
                  required
                >
                <div *ngIf="cust_mobile.errors && (cust_mobile.dirty || cust_mobile.touched)">
                  <span *ngIf="cust_mobile.errors.required" class="text-danger">Mobile Number is required</span>
                </div>
              </div>
            </div>
            <div class="col-md-6 pl-0 mt-0 pr-0">
              <span class="dialoglabel">Customer Email</span>
              <span class="requiredspan"> *</span>
              <div>
                <input
                  class="forminputwhitebg"
                  style="display: block; color: #0762AD; font-size: 14px; margin-top: 5px;width: 100%;"
                  type="text"
                  name="cust_email"
                  [(ngModel)]="serviceDetails.cust_email"
                  #cust_email="ngModel"
                  placeholder="Enter Email"
                  required
                >
                <div *ngIf="cust_email.errors && (cust_email.dirty || cust_email.touched)">
                  <span *ngIf="cust_email.errors.required" class="text-danger">Email is required</span>
                </div>
              </div>
            </div>
          </div>-->
          <div class="row mt-4">
            <div class="col-md-6 pl-0 mt-0">
              <span class="dialoglabel">Source</span>
              <span class="requiredspan"> *</span>
              <div class="formselectwhitebg">
                <select name="source"  #source="ngModel" [(ngModel)]="serviceDetails.source" style="margin-top: 5px;" required>
                  <option *ngFor="let source of sources"
                          [ngValue]="source.name">{{source.name}}</option>
                </select>
              </div>
            </div>
            <div class="col-md-6 pl-0 mt-0 pr-0">
              <span class="dialoglabel">Referrer Type</span>
              <span class="requiredspan"> *</span>
              <div class="formselectwhitebg">
                <select name="referrer_type"  #referrer_type="ngModel" [(ngModel)]="serviceDetails.referrer_type" style="margin-top: 5px;" required>
                  <option value="company">Company</option>
                  <option value="distributor">Distributor</option>
                  <option value="dealer">Dealer</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-6 pl-0 mt-0">
              <span class="dialoglabel">Referrer Name</span>
              <span class="requiredspan"> *</span>
              <div>
                <input
                  class="forminputwhitebg"
                  style="display: block; color: #0762AD; font-size: 14px; margin-top: 5px;width: 100%;"
                  type="text"
                  name="ref_name"
                  [(ngModel)]="serviceDetails.referrer_name"
                  #ref_name="ngModel"
                  placeholder="Enter Referrer Name"
                  required
                >
                <div *ngIf="ref_name.errors && (ref_name.dirty || ref_name.touched)">
                  <span *ngIf="ref_name.errors.required" class="text-danger">Referrer Name is required</span>
                </div>
              </div>
            </div>
            <div class="col-md-6 pl-0 mt-0 pr-0">
              <span class="dialoglabel">Referrer Mobile</span>
              <span class="requiredspan"> *</span>
              <div>
                <input
                  class="forminputwhitebg"
                  style="display: block; color: #0762AD; font-size: 14px; margin-top: 5px;width: 100%;"
                  type="text"
                  name="ref_mobile"
                  [(ngModel)]="serviceDetails.referrer_mobile"
                  #ref_mobile="ngModel"
                  placeholder="Enter Referrer Mobile"
                  required
                >
                <div *ngIf="ref_mobile.errors && (ref_mobile.dirty || ref_mobile.touched)">
                  <span *ngIf="ref_mobile.errors.required" class="text-danger">Referrer Mobile is required</span>
                </div>
              </div>
            </div>
          </div>
          <!--<div class="row mt-4">
            <div class="col-md-6 pl-0 mt-0">
              <span class="dialoglabel">Address</span>
              <input
                class="forminputwhitebg"
                style="display: block; color: #0762AD; font-size: 14px; margin-top: 5px;width: 100%;"
                type="text"
                name="customer_street1"
                [(ngModel)]="serviceDetails.street"
                #customer_street1="ngModel"
                placeholder="Street1"
              >
            </div>
            <div class="col-md-6 pl-0 mt-0 pr-0">
              <span class="dialoglabel"> </span>
              <input
                class="forminputwhitebg"
                style="display: block; color: #0762AD; font-size: 14px; margin-top: 10px;width: 100%;"
                type="text"
                name="customer_street1"
                [(ngModel)]="serviceDetails.street2"
                #customer_street2="ngModel"
                placeholder="Street2"
              >
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 pl-0 mt-0">
              <input
                class="forminputwhitebg"
                style="display: block; color: #0762AD; font-size: 14px; margin-top: 5px;width: 100%;"
                type="text"
                name="customer_city"
                [(ngModel)]="serviceDetails.city"
                #customer_city="ngModel"
                placeholder="City"
              >
            </div>
            <div class="col-md-6 pl-0 mt-0 pr-0">
              <input
                class="forminputwhitebg"
                style="display: block; color: #0762AD; font-size: 14px; margin-top: 5px;width: 100%;"
                type="number"
                name="customer_pincode"
                [(ngModel)]="serviceDetails.zip"
                #customer_pincode="ngModel"
                placeholder="Pincode"
              >
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 pl-0 mt-0" style="display:inline-block;">

              <ng-select [items]="stateList"
                         bindLabel="itemName"
                         bindValue="id"
                         class="custom"
                         placeholder="State"
                         style=" margin-top: 5px;"
                         name="state_id"
                         #state_id="ngModel"
                         [(ngModel)]="serviceDetails.state_id">
                >
              </ng-select>
            </div>
          </div>-->
          <div class="row mt-4">
            <div class="col-md-6 pl-0 mt-0">
              <span class="dialoglabel">Service Type</span>
              <span class="requiredspan"> *</span>
              <div class="formselectwhitebg">
                <select name="service_type"  #service_type="ngModel" [(ngModel)]="serviceDetails.service_type" style="margin-top: 5px;" required>
                  <option *ngFor="let type of service_types"
                          [ngValue]="type.id">{{type.name}}</option>
                </select>
              </div>
            </div>
            <div class="col-md-6 pl-0 mt-0 pr-0">
              <div *ngIf="productList" class="row" style="margin-top: 40px;" (click)="goToProductSelectorPopup()">
                <span class="dialogstepspan" style="margin-right: 5px;color: #0762AD;">{{selectedProductCount}}</span>
                <span class="dialogsteplabel"> Products Selected</span>
                <div class="row" style="margin-left: 25px; cursor: pointer;"  >
                  <img style="height: 20px;cursor: pointer;" src="../../../assets/additem.svg"/>
                  <span style="color: #0762AD;cursor: pointer;font-size: 14px; font-weight: 600;margin-left: 6px;">Add Products</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </form>
    </div>
    <div class="button-row footer-section">
      <button
        mat-button
        style=" margin-right:15px;"
        class="btn grey-btn"
        (click)="closeDialog()"
      >
        Cancel
      </button>
      <button
        mat-button
        style=" margin-right:15px;"
        class="btn confirm-blue-btn"
        (click)="createServiceRequest()"
        form="ngForm"
      [disabled]="servicereq.form.invalid"
      >
        Submit
      </button>
    </div>
  </div>
</section>


