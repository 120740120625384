import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {ErrorHandler} from "../../error/error-handler";

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class OutletProductRecommendationReportComponent extends ReportBase implements IReport, OnInit {

  showCustomFilter = true;
  customFilter: any = {
    name: 'Active',
    key: 'active',
    value: 'true',
    values: [],
    show: true
  };

  attributeData: any = [];
  attributeTypes: any [];

  verticalData: any = [];
  verticalTypes: any = [];

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Outlet Product Recommendation Report');
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.showDate = false;
    this.customFilter.values = [
      {id: '', itemName: 'All'},
      {id: 'true', itemName: 'Active'},
      {id: 'false', itemName: 'Inactive'}
    ];

    this.userData.offsetID = 0;
    this.userData.offset = 50000;
    this.loadInBatches(50000, 'id');
    this.showProgressCustomText = true;
    this.API_URL = '/api/pwa_reports/getOutletProductRecoReport';
    this.fetchAttributes();
  }

  fetchAttributes() {
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    this.dataLoaded = false;
    this.preloader = true;
    this.emptyState = false;
    this.errorExists = false;
    this.apiService.post('/api/pwa_reports/getProductAttributes', this.userData)
      .subscribe(res => {

          console.log(res);
          // t.stop();
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.attributeData = res.results.data;
            if (this.attributeData) {
              this.attributeTypes = this.attributeData.map(p => p.attribute)
                .filter((type, index, arr) => arr.indexOf(type) === index);
            }
           /* this.verticalData = res.results.data.vert;
            if (this.verticalData) {
              this.verticalTypes = this.verticalData.map(p => p.vertical)
                .filter((type, index, arr) => arr.indexOf(type) === index);
            }*/
            //this.customFilter.values = res.result.data;
            //this.loadServerData();
          } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
            this.emptyState = true;
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.emptyState = true;
            if (res.hasOwnProperty('results') && res.results.hasOwnProperty('errmsg')) {
              console.error(res.results.errmsg);
            }
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        // tslint:disable-next-line:no-shadowed-variable
        error => {
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
          this.handleError(ErrorHandler.getErrorObject(error));
        });
  }

  configureGrid() {

    const inthis = this;
    this.columnDefs = [
      {
        headerName: '',
        pinned: 'left',
        children: [
          {headerName: 'RETAILER', field: 'r_name', width: 150, pinned: 'left'},
          {headerName: 'PRODUCT', field: 'p_name', width: 150, pinned: 'left'}
        ]
      },
      {
        headerName: '',
        children: [
          {headerName: 'REGION', field: 'region', width: 80},
          {headerName: 'CLUSTER', field: 'cluster', width: 80},
          {headerName: 'DC', field: 'dc', width: 80},
          {headerName: 'TERRITORY', field: 'territory', width: 100},
          {headerName: 'TERR CODE', field: 'tcode', width: 80},
          {headerName: 'TERR MANAGER', field: 'ter_mgr', width: 100},
          {headerName: 'RCODE', field: 'r_code', width: 80},
          {headerName: 'VERTICAL', field: 'vertical', width: 150},
          {headerName: 'PCODE', field: 'p_code', width: 80},
          {headerName: 'FAMILY CODE', field: 'family_code', width: 80},
          {headerName: 'FAMILY', field: 'family_name', width: 80},
          {headerName: 'BRAND', field: 'brand', width: 80},
        ]
      },
      {
        headerName: 'ATTRIBUTES',
        children: []
      },
      {
        headerName: 'RECOMMENDATION',
        children: [
          {headerName: 'NAME', field: 'name', width: 80},
          {headerName: 'TYPE', field: 'type', width: 80},
          {
            headerName: 'SUGG', field: 'sugg', width: 80,
            valueGetter: this.configAllValueGetter('sugg')
          },
          {
            headerName: 'UMIN', field: 'umin', width: 80,
            valueGetter: this.configAllValueGetter('umin')
          },
          {
            headerName: 'UMAX', field: 'umax', width: 80,
            valueGetter: this.configAllValueGetter('umax')
          },
          {
            headerName: 'LMIN', field: 'lmin', width: 80,
            valueGetter: this.configAllValueGetter('lmin')
          },
          {
            headerName: 'LMAX', field: 'lmax', width: 80,
            valueGetter: this.configAllValueGetter('lmax')
          },
          {
            headerName: 'EXPECTED CLOSING', field: 'lmax', width: 80,
            valueGetter: this.configValueGetter('expected_closing')
          },
        ]
      },
    ];

    /*if (this.verticalTypes) {
      for (const key in this.verticalTypes) {
        this.columnDefs[2].children.push({
          headerName: 'Vertical',
          field: 'vname_' + this.verticalTypes[key],
          width: 100
        }, {
          headerName: 'Supplier',
          field: 'vsupp_' + this.verticalTypes[key],
          width: 100
        });
      }
    }*/

    if (this.attributeTypes) {
      for (const key in this.attributeTypes) {
        this.columnDefs[2].children.push({
          headerName: this.attributeTypes[key],
          field: this.attributeTypes[key],
          width: 100,
          aggFunc: 'sum'
        });
      }
    }
  }

  configValueGetter(field: string) {
    return (params) => {
      if (params.data) {
        if (params.data.config) {
          if (params.data.config[field]) {
            return params.data.config[field];
          } else {
            return '';
          }
        }
      } else {

        return '';
      }
    };
  }


  configAllValueGetter(field: string) {
    return (params) => {
      if (params.data) {
        if (params.data.config && params.data.config.hasOwnProperty('all')) {
          if (params.data.config.all[field]) {
            return params.data.config.all[field];
          } else {
            return '';
          }
        }
      } else {

        return '';
      }
    };
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllColumns().length;

    params.api.setRowData(this.rowData);
    this.preloader = false;
    const inThis = this;

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        if (noOfColumns < 11 && screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeAllColumns();
        }
      });
    });

    if (noOfColumns < 11 && screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeAllColumns();
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');

    if (this.showPinnedFooter) {
      setTimeout(() => {
        const pinnedBottomData = this.sharedService.generatePinnedBottomData(this.gridApi, this.gridColumnApi);
        this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
      }, 500);
    }

    // this.get_favorites();
    if (this.columnState) {
      this.gridColumnApi.applyColumnState(
        {
          state: this.columnState,
          applyOrder: true,
        }
      );
      this.quickSearch();
    }
  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }

  configureData() {
    this.rowData = [];
    const inThis = this;
    this.apiData.forEach(obj => {
      let indRow = {};
      const attRow = [];
      for (const key1 in inThis.attributeTypes) {
        attRow[inThis.attributeTypes[key1]] = inThis.attributeData.filter(api => api.prd_id === obj.product_id
          && api.attribute === inThis.attributeTypes[key1]);
        if (attRow[inThis.attributeTypes[key1]] != null && attRow[inThis.attributeTypes[key1]].length > 0) {
          attRow[inThis.attributeTypes[key1]] = attRow[inThis.attributeTypes[key1]][0].value;
        }
      }
     /* for (const key1 in inThis.verticalTypes) {
        attRow[inThis.verticalTypes[key1]] = inThis.verticalData.filter(api => api.id === obj.retailer_id
          && api.vertical === inThis.verticalTypes[key1]);
        if (attRow[inThis.verticalTypes[key1]] != null && attRow[inThis.verticalTypes[key1]].length > 0) {
          attRow['vname_' + inThis.verticalTypes[key1]] = attRow[inThis.verticalTypes[key1]][0].vertical;
          attRow['vsupp_' + inThis.verticalTypes[key1]] = attRow[inThis.verticalTypes[key1]][0].supplier;
        }
      }*/
      indRow = {
        ...obj,
        ...attRow
      };
      this.rowData.push(indRow);
    });
  }

}
