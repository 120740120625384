import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {ErrorHandler} from "../../error/error-handler";

@Component({
  selector: 'app-partner-checkin-master-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class PartnerCheckinMasterComponent extends ReportBase implements IReport, OnInit {

  public attributeTypes = [];
  showCustomFilter = true;
  customFilter: any = {
    name: 'Visit Type',
    key: 'type',
    value: 'all',
    values: [
      {id: 'all', itemName: 'All'},
      {id: 'physical', itemName: 'Physical'},
      {id: 'virtual', itemName: 'Virtual'},
      {id: 'not', itemName: 'Not Visited'},
    ],
    show: true
  };
  apiData: any = [];
  uqTypes;

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Partner Checkin Master Report');

    // this.showableFilters.cluster = true;
    // this.showableFilters.territory = true;
    // this.showableFilters.salesman = true;
    this.showableFilters.state = true;
    this.showableFilters.outlet_type = true;
    this.showDate = true;

    this.loadInBatches(50000, 'seq');
    this.showProgressCustomText = true;
    this.API_URL = '/api/pwa_v2_reports/getMasterPartnerVisitsReport';
  }

  configureGrid() {
    const inThis = this;
    this.columnDefs = [
      {
        headerName: "Partner Details",
        children: [
          {headerName: 'PARTNER ID', field: 'partner_id', width: 80},
          {headerName: 'PARTNER NAME', field: 'outlet_name', enableRowGroup: true, width: 80},
          {headerName: 'CODE', field: 'outlet_code', width: 80},
          {headerName: 'TERRITORY', field: 'partner_territory', enableRowGroup: true, width: 100},
          {headerName: 'STATE', field: 'partner_state', enableRowGroup: true, width: 80},
          {headerName: 'TYPE', field: 'partner_type', enableRowGroup: true, width: 80},
          {headerName: 'OUTLET TYPE', field: 'outlet_type', enableRowGroup: true, width: 80},
          {headerName: 'CLASS', field: 'partner_class', enableRowGroup: true, width: 80},
          {headerName: 'PROGRAM TYPE', field: 'program_type', enableRowGroup: true, width: 80}
        ]
      }, {
        headerName: "Visit Details",
        children: [
          {headerName: 'STATUS', field: 'visit_status', enableRowGroup: true, width: 80},
          {headerName: 'VISIT TYPE', field: 'visit_type', enableRowGroup: true, width: 80},
          {headerName: 'COMPLETION', field: 'visit_completion', enableRowGroup: true, width: 80},
          {headerName: 'VISITED USER', field: 'visited_user', enableRowGroup: true, width: 80},
          {headerName: 'EMPLOYEE CODE', field: 'visited_emp_code', width: 80},
          {headerName: 'VISIT START', field: 'visit_start', width: 80},
          {headerName: 'VISIT END', field: 'visit_end', width: 80},
          {headerName: 'COMMENT', field: 'visit_comment', width: 80},
          {headerName: 'ID', field: 'visit_id', width: 80}
        ]
      }
    ];
  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }


  configureData() {
    this.rowData = this.apiData;
    console.log(this.rowData);
  }
}
