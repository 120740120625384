import {ReportBase} from '../_common/reportbase';
import {Component, OnInit} from '@angular/core';
import {ErrorHandler} from '../../error/error-handler';


@Component({
  selector: 'app-purchase-returns-report',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class PurchaseReturnsReportComponent extends ReportBase implements OnInit, IReport {
    ngOnInit(): void {
        this.sharedService.setHeader('Purchase Returns Report');
        this.showDate = true;
        this.configureGrid();
    }

    setTitle() {
        this.title = 'Purchase Returns Report';
    }

    configureGrid() {
        this.API_URL = '/api/pwa_v2_reports/get_purchase_returns'
        this.columnDefs = [
            {headerName: 'Salesman', field: 'user_name', width: 100},
            {headerName: 'Supplier', field: 'supplier', width: 100},
            {headerName: 'Retailer ID', field: 'rid', width: 100},
            {headerName: 'RCode', field: 'rcode', width: 100},
            {headerName: 'Customer', field: 'customer', width: 100},
            {headerName: 'PO No.', field: 'po', width: 100},
            {headerName: 'Invoice No.', field: 'invoice_num', width: 100},
            {headerName: 'Return Inv No.', field: 'return_inv_num', width: 100},
            {headerName: 'Product', field: 'product', width: 100},
            {headerName: 'Product Category', field: 'category', width: 100},
            {headerName: 'Return Quantity', field: 'return_qty', width: 100},
            {headerName: 'Usable', field: 'usable', width: 100},
            {headerName: 'Damaged', field: 'damaged', width: 100},
            {headerName: 'Subtotal', field: 'price_subtotal', width: 100}
        ];
    }
}
