import {ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-partial-return-approve-popup',
  templateUrl: './partial_return_approve_popup.component.html',
  styleUrls: ['./partial_return_approve_popup.component.scss']

})

export class PartialReturnApprovePopupComponent implements OnInit {

  @Output() partialreturnapprovesubmit: EventEmitter<any> = new EventEmitter<any>();
  amountExceeded = false;
  qty;
  approved_qty;
  invalid = false;
  rowdata;

  constructor(
    public dialog: MatDialog,
    private changeDetector: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.rowdata = dialogdata.data;
    this.qty = Math.abs(parseInt(this.rowdata["QTY"], 0));
    this.approved_qty = this.qty;
  }

  ngOnInit(): void {
  }

  performAction() {
    if (this.rowdata.hasOwnProperty('config_json')) {
      const config_json = JSON.parse(this.rowdata.config_json);
      config_json.approved_qty = this.approved_qty;
      this.rowdata.config_json = config_json;
    }
    this.partialreturnapprovesubmit.emit(this.rowdata);
    this.dialog.closeAll();
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  onQtyChange() {
    // tslint:disable-next-line:radix
    if (this.approved_qty !== null && this.approved_qty !== undefined && this.approved_qty !== "") {
      this.approved_qty = parseInt(this.approved_qty.toString().replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1'));
    } else {
      this.approved_qty = 0;
    }

    //const numcharPattern = /^([a-z0-9]+)$/i;
    /*  const aCode = event.which ? event.which : event.keyCode;
      if (aCode > 31 && (aCode < 48 || aCode > 57)) { return false; }

      return true;*/
    if (this.approved_qty > this.qty) {
      this.approved_qty = this.qty;
    }
    document.getElementById('partial-approve-text-box')['value'] = this.approved_qty;
    this.changeDetector.detectChanges();
  }

}
