import {ReportBase} from '../_common/reportbase';
import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-retailer-asset-sales',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class YTDDealerBillingReportComponent extends ReportBase implements IReport, OnInit {

  showDate = false;
  showCustomFilter = true;
  customFilter: any = {
    name: 'Year',
    key: 'type',
    value: '',
    values: [],
    show: true
  };
  currentYear;
  currentMonth;
  prvsYear;
  monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
  displayMonths;


  setTitle() {
    this.title = 'YTD Dealer Billing Report';
  }

  ngOnInit() {
    this.setTitle();
    this.sharedService.setHeader('YTD Dealer Billing Report');
    /* this.myDate[0] = '01-03-2022';
     this.myDate[1] = '01-03-2023';
     this.userData.start_date = '01-03-2022';
     this.userData.end_date = '12-03-2023';*/
    this.currentYear = new Date().getFullYear();
    this.prvsYear = this.currentYear - 1;
    this.currentMonth = new Date().getMonth() + 1;
    this.getCustomFilterValues();
    this.dateCheckandUpdate();
    this.displayMonths = this.dateRange(this.myDate[0], this.myDate[1]);
    //this.configureGrid();
    this.API_URL = '/api/pwa_reports/getTYDDealerBillingreport';
    this.loadServerData();
  }

  getCustomFilterValues() {
    if (this.currentMonth < 4) {
      this.customFilter.values = [
        {id: this.currentYear.toString(), itemName: this.prvsYear + '-' + this.currentYear},
        {id: this.prvsYear.toString(), itemName: (this.prvsYear - 1) + '-' + this.prvsYear}
      ];
    } else {
      this.customFilter.values = [
        {id: this.currentYear.toString(), itemName: this.currentYear + '-' + (this.currentYear + 1)},
        {id: this.prvsYear.toString(), itemName: this.prvsYear + '-' + this.currentYear}
      ];
    }
    this.customFilter.value = this.currentYear.toString();
  }

  dateRange(startDate, endDate) {
    const start = startDate.split('-');
    const end = endDate.split('-');
    const startYear = parseInt(start[0], 0);
    const endYear = parseInt(end[0], 0);
    const dates = [];

    for (let i = startYear; i <= endYear; i++) {
      const endMonth = i !== endYear ? 11 : parseInt(end[1], 0) - 1;
      const startMon = i === startYear ? parseInt(start[1], 0) - 1 : 0;
      for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        const month = j + 1;
        //let displayMonth = month < 10 ? '0' + month : month;
        //dates.push([i, displayMonth, '01'].join('-'));
      /*  if (typeof displayMonth === "string") {
          dates.push([{month: displayMonth, display: this.monthNames[parseInt(displayMonth, 0) - 1] + ' ' + i}]);
        }*/
        dates.push({month, display: this.monthNames[month - 1] + ' ' + i});
      }
    }
    console.log(dates);
    return dates;
  }

  setCustomFilter(customfilterdata) {
    if (customfilterdata.hasOwnProperty('type')) {
      this.customFilter.value = customfilterdata.type;
    }
    for (const [key, value] of Object.entries(customfilterdata)) {
      this.userData.customFilter[key] = value;
    }
    this.dateCheckandUpdate();
    this.displayMonths = this.dateRange(this.myDate[0], this.myDate[1]);
  }

  configureGrid() {
    this.columnDefs = [
      {
        headerName: '',
        children: [
          {headerName: "STATE", field: "state", width: 100},
          {headerName: "ID", field: "id", width: 100},
          {headerName: "EMP CODE", field: "emp_code", width: 100},
          {headerName: "SA CODE", field: "sa", width: 100},
          {headerName: "SALESMAN", field: "name", width: 100}
        ]
      },
      {
        headerName: 'ACTIVE DEALER',
        children: []
      },

    ];
    const types = this.apiData.type;

    for (const type of types) {
      this.columnDefs[1].children.push({headerName: type.name, field: 'ad_' + type.name, width: 100});
    }

    for (let i = 0; i < this.displayMonths.length; i++) {
      this.columnDefs.push({
        headerName: this.displayMonths[i].display,
        children: []
      });

      for (const type of types) {
        this.columnDefs[i + 2].children.push({headerName: type.name, field: this.displayMonths[i].month + '_' + type.name, width: 100});
      }
    }


  }

  getHeaderName(month) {
    if (this.currentMonth >= 4 && this.currentMonth <= 6) {
      return this.monthNames[month];
    } else if (this.currentMonth >= 7 && this.currentMonth <= 9) {
      return this.monthNames[month + 3];
    } else if (this.currentMonth >= 10 && this.currentMonth <= 12) {
      return this.monthNames[month + 6];
    } else {
      return this.monthNames[month + 9];
    }
  }


  postProcessData() {
    this.configureGrid();
    this.configureData();
  }

  configureData() {
    this.rowData = [];
    const innerthis = this;
    const masterData = this.apiData.master;
    if (masterData !== null && masterData !== undefined && masterData.length > 0) {
      masterData.forEach(obj => {
        let indrow = {};
        indrow = obj;
        //configuring active data
        if (obj.master !== null && obj.master !== undefined && obj.master.length > 0 && obj.master[0] !== null && obj.master[0] !== undefined) {
          for (const masteritem of obj.master[0]) {
            indrow['ad' + '_' + masteritem.O] = masteritem.R;
          }
        }
        //configuring monthly data
        if (obj.data !== null && obj.data !== undefined && obj.data.length > 0 && obj.data[0] !== null && obj.data[0] !== undefined) {
          for (const masteritem of obj.data[0]) {
            if (masteritem.D !== null && masteritem.D !== undefined && masteritem.D.length > 0) {
              for (const item of masteritem.D) {
                indrow[masteritem.M + '_' + item.O] = item.C;
              }
            }
          }
        }
        innerthis.rowData.push(indrow);
      });
    }
  }
}
