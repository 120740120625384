import {PageBase} from "../pagebase/pagebase";
import {Component, OnInit} from "@angular/core";
import {MatDialogConfig} from "@angular/material/dialog";
import {CreateBeatPlanPopupComponent} from "./create_beat_plan_popup/create_beat_plan_popup.component";
import {ChangeBeatSalesmanPopupComponent} from "./change_beat_salesman_popup/change_beat_salesman_popup.component";
import {UploadBeatPlanComponent} from "./upload_beat_plan/upload_beat_plan";
import {ConfirmDialogComponent} from "../components/confirmdialog/confirmdialog.component";

@Component({
  selector: 'app-beat-plan-list',
  templateUrl: '../pagebase/pagebase.html',
  styleUrls: ['../pagebase/pagebase.scss']
})


export class BeatPlanListComponent extends PageBase implements OnInit, IPage {

  customFilter: any = {
    name: 'Active',
    key: 'active',
    value: 'true',
    values: [],
    show: true
  };

  matActionMenuItemDeclaration = [{name: 'Change Salesman' , feature: 'action_beat_salesman_change'}];

  ngOnInit(): void {

    this.sharedService.setHeader('Beat Plan');

    this.showableFilters.region = true;
    this.showableFilters.territory = true;
    this.showableFilters.salesman = true;
    this.showableFilters.team = true;

    this.showDate = false;
    this.showCreate = false;
    this.showCustomFilter = true;
    this.showActionButton = true;
    this.showUpload = false;

    this.customFilter.values = [
      {id: '', itemName: 'All'},
      {id: 'true', itemName: 'Active'},
      {id: 'false', itemName: 'Inactive'}
    ];

    this.getAuthorizedActions();
    this.checkAccessControl();
    this.configureGrid();
    this.loadServerData();
  }


  configureGrid() {
    this.API_URL = '/api/beat_plans/get_beat_plan_list_pwa';
    const inthis = this;
    this.columnDefs = [
      {headerName: "BEAT ID", field: "id", width: 40, checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true},
      {headerName: "BPCODE", field: "code", width: 50, hide: true},
      {headerName: "BEAT PLAN", field: "name", width: 120},
      {headerName: "COUNT", field: "retailer_count", width: 40, aggFunc: 'sum'},
      {headerName: "TERRITORY", field: "territory", width: 100},
      {headerName: "EMP CODE", field: "emp_code", hide: true, width: 50},
      {headerName: "SALESMAN", field: "salesman", width: 100},
      {headerName: "TRAVEL TYPE", field: "travel_type", width: 60},
      {headerName: "REGION", field: "region", width: 60},
      {headerName: "CLUSTER", field: "cluster", hide: true, width: 60},
      {headerName: "DISTRICT", field: "district", hide: true, width: 60},
      {headerName: "TEAM", field: "team", width: 100, hide: true},
      {headerName: "VISIT FREQ", field: "visit_frequency", width: 40},
      {headerName: "DISTANCE", field: "distance", width: 40},
      {headerName: "PRIORITY", field: "priority", width: 40, hide: true},
      {headerName: "SCHEDULE", field: "schedule_config", width: 100, hide: true},
      {headerName: "TA", field: "ta", width: 40, hide: true},
      {headerName: "DA", field: "da", width: 40, hide: true},
      {headerName: "SA", field: "sa", width: 40, hide: true},
      {headerName: "UNCOVERED", field: "uncovered", width: 40},
      {headerName: "SCHEDULE TYPE", field: "schedule_type", width: 60, hide: true},
      {headerName: "ACTIVE", field: "active", width: 40},
      {headerName: "CREATED BY", field: "createdby", width: 60},
      {headerName: "CREATED", field: "create_date", width: 60},
      {headerName: 'WEEK 1', field: 'schedule_config', width: 40, valueGetter: params => {
          if (params.data) {
            if (params.data.schedule_config.hasOwnProperty('w1')) {
              return params.data.schedule_config.w1;
            } else {
              return "";
            }
          }
        } , hide: true},
      {headerName: 'WEEK 2', field: 'schedule_config', width: 40, valueGetter: params => {
      if (params.data) {
        if (params.data.schedule_config.hasOwnProperty('w2')) {
          return params.data.schedule_config.w2;
        } else {
          return "";
        }
      }
    } , hide: true},
      {headerName: 'WEEK 3', field: 'schedule_config', width: 40, valueGetter: params => {
          if (params.data) {
            if (params.data.schedule_config.hasOwnProperty('w3')) {
              return params.data.schedule_config.w3;
            } else {
              return "";
            }
          }
        } , hide: true},
      {headerName: 'WEEK 4', field: 'schedule_config', width: 40, valueGetter: params => {
      if (params.data) {
        if (params.data.schedule_config.hasOwnProperty('w4')) {
          return params.data.schedule_config.w4;
        } else {
          return "";
        }
      }
    } , hide: true},
    ];
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    if (rows !== null && rows.length > 0 ) {
      const ids = [];
      this.rowData.forEach(r => {
        ids.push(r.id);
      });
      localStorage.setItem('active_ids', JSON.stringify(ids));
      this.router.navigate([this.apiService.redirect_url + '/beats/beat_plan_detail'], {
        relativeTo: this.route,
        queryParams: {id: rows[0].id }
      });
    }
  }

  openCreateDialog() {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "200vh",
      maxWidth: "450px",
      maxHeight: "650px",
      data: {}
    };

    const confirmdiaeref = this.dialog.open(CreateBeatPlanPopupComponent, config);
  }

  checkAccessControl() {
    this._localCacheService.getAccessControlList().then(list => {
      this.showCreate = list['action_beat_plan_create'];
      this.showUpload = list['action_beat_plan_create'];
    });
  }

  BeatConfigValueGetter(week) {
    return (params) => {
      if (params.data) {
        if (params.data.schedule_type === 'recurring' && params.data.schedule_config && params.data.schedule_config !== "") {
          params.data.schedule_config[week];

          // const week_config = params.data.schedule_config.filter(existed =>
          //   existed.week !== null && existed.week !== undefined &&
          //   existed.week.includes(week_index));
          // if (week_config !== null && week_config !== undefined) {
          //   return week_config[0]['day'];
          // } else {
          //   return '';
          // }
        } else {
          return '';
        }
      } else {
        return '';
      }
    };
  }



  openChangeSalesmanDialog(rows, uq_bp, uq_ter) {
    const beatIds =  [...new Set(rows.map(obj => obj.id))];

    const displayMessage = "You have selected " + uq_bp + " from " + uq_ter + " territories";
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "450px",
      maxHeight: "450px",
      data: {
        beat_ids: beatIds,
        message: displayMessage
      },
      panelClass: 'custom-dialog-container'
    };

    const confirmdiaeref = this.dialog.open(ChangeBeatSalesmanPopupComponent, config);
    confirmdiaeref.componentInstance.changeData.subscribe(status => {
      console.log('remove reponse : ' + status );
      if (status === 'success') {
        this.dialog.closeAll();
        window.location.reload();
      }
    });
  }

  openChangeBeatStatusPopup(status, rowCount) {
    const operation = (status === true ? 'Activate' : 'De-Activate');
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "550px",
      maxHeight: "250px",
      data: {
        title: operation + ' Beat !',
        message1: " You are about to " + operation + " " + rowCount + " beats",
        message2: "",
        showNoButton: true,
        yesButton: operation,
        noButton: "CANCEL",
        deleteButton: true
      }
    };

    const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);

    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

      console.log(data);
      if (data === 'yes') {
        console.log(operation);
        this.dialog.closeAll();
        window.location.reload();
      } else {
        this.dialog.closeAll();
        console.log("Discard");
      }
    });
  }

  onMenuItemSelected(menuitem) {
    const rows = this.gridApi.getSelectedRows();
    if (rows === null || rows === undefined || rows.length === 0) {
      this.toastr.error('Please select at least 1 Beat');
    } else  if (rows.length > 100) {
      this.toastr.error('Please select a maximum of 100 retailers : ' + rows.length + ' selected');
    } else {
      if (menuitem.name === 'Change Salesman') {
        const unique_beat_plan = this.unique(rows, ['beat']).length;
        const unique_territory = this.unique(rows, ['territory']).length;

        this.openChangeSalesmanDialog(rows, unique_beat_plan, unique_territory);

      } else if (menuitem.name === 'Activate') {
        this.openChangeBeatStatusPopup(true, rows.length);
      } else if (menuitem.name === 'De-Activate') {
        this.openChangeBeatStatusPopup(false, rows.length);
      }
    }
  }

  showUploadPopup() {
    this.BeatPlanUploadClick();
  }

  BeatPlanUploadClick() {
    const config: MatDialogConfig = {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'full-screen-modal',
      data: {
        upload_type: 'beat_plan_upload_v1',
        title: "Beat Plan Upload",
        message: "Upload Beatplans",
        instruction: "",
      },
    };

    const dialogRef = this.dialog.open(UploadBeatPlanComponent, config);
    dialogRef.componentInstance.uploadData.subscribe(status => {
      if (status === 'success') {
        this.dialog.closeAll();
        window.location.reload();
      }
    });
  }

}
