import {ReportBase} from '../_common/reportbase';
import {Component, OnInit} from '@angular/core';
import {MatDialogConfig} from "@angular/material/dialog";
import {ImagePopupComponent} from "../../components/image_popup/image_popup.component";
import {ImageComparePopupComponent} from "../../components/image_compare_popup/image_compare_popup.component";

@Component({
  selector: 'app-retailer-asset-sales',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

// tslint:disable-next-line:component-class-suffix
export class VisitSummaryReportComponent extends ReportBase implements IReport, OnInit {


  setTitle() {
    this.title = 'Visit Summary Report';
  }

  ngOnInit() {
    this.setTitle();
    this.sharedService.setHeader('Visit Summary Report');

    this.configureGrid();

  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getVisitSummaryReport';

    this.columnDefs = [
      {headerName: 'STATE', field: 'state', width: 80},
      {headerName: "REGION", field: "region", enableRowGroup: true, width: 60},
      {headerName: "EMP CODE", field: "emp_code", enableRowGroup: true, width: 60},
      {headerName: "SA CODE", field: "sales_area_code", enableRowGroup: true, width: 60},
      {headerName: "SALESMAN", field: "user_name", enableRowGroup: true, width: 60},
      {headerName: "TEAM", field: "team", enableRowGroup: true,  hide: true, width: 60},
      {headerName: "TEAM LEAD", field: "team_lead", enableRowGroup: true, width: 60},
      {headerName: "TL ECODE", field: "tl_emp", enableRowGroup: true, width: 60},
      {headerName: "PLANNED VISIT", field: "planned", width: 40},
      {headerName: "PLANNED VISIT COMPLETED", field: "planned_comp", width: 40},
      {headerName: "BEAT COMPLIANCE", field: "beat_compliance", width: 40},
      {headerName: "DBCP SCORE", field: "dbcp", width: 60},
      {headerName: "TOTAL VISITS", field: "total_comp", width: 60},
      {headerName: "UNSCHEDULED VISITS", field: "unscheduled", width: 40},
      {headerName: "TC", field: "tc", width: 60},
      {headerName: "VC", field: "vc", width: 60},
      {headerName: "PC", field: "pc", width: 60},
      {headerName: "PC%", field: "pc_pct", width: 60},
      {headerName: "Order", field: "order", width: 60},
      {headerName: "Mini Meet", field: "min", width: 60},
      {headerName: "Mega Meet", field: "meg", width: 60},
      {headerName: "RETAILER VISITS", field: "r_visits", width: 60},
      {headerName: "INFLUENCER VISITS", field: "i_visits", width: 60},
      {headerName: "PROJECT VISITS", field: "p_visits", width: 60},
      {headerName: "LEAD VISITS", field: "l_visits", width: 60},
      {headerName: "MARKET WORKING TIME", field: "wrk_mins", width: 60},
      {headerName: "RETAILING TIME", field: "rtl_time", width: 60}
    ];
  }

}
