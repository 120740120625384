import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";

@Component({
  selector: 'app-van-display',
  template: `
    <div *ngIf="delete"><img src="./../../assets/delete1.svg" (click)="deleteOrder()"/><b>{{van_name}}</b></div>
    <div *ngIf="inactive"><b style="color:IndianRed;">{{van_name}}</b><br/>
      <p style="margin-top: -7%;font-size: xx-small;">not placed</p></div>
    <div *ngIf="dispatched"><b style="color:darkgrey;">{{van_name}}</b><br/>
      <p style="margin-top: -7%;font-size: xx-small;">{{params.data.dispatch_status}}</p></div>
    <div *ngIf="settled"><b style="color:darkgrey;">{{van_name}}</b><br/>
      <p style="margin-top: -7%;font-size: xx-small;">settled</p></div>
    <div *ngIf="not_settled"><b style="color:darkgrey;">{{van_name}}</b><br/>
      <p style="margin-top: -7%;font-size: xx-small;">not settled</p></div>
    <div *ngIf="normal"><b>{{van_name}}</b></div>`
})
export class VanDisplayRendererComponent implements ICellRendererAngularComp {
  public params: any;
  public van_name: any;
  public delete = false;
  public inactive = false;
  public dispatched = false;
  public settled = false;
  public not_settled = false;
  public normal = false;

  agInit(params: any): void {
    this.params = params;
    this.van_name = params.data['VAN'];
    if (this.params.data.duplicate) {
      this.delete = true;
    } else if (this.params.data.ostatus === 'inactive') {
      this.inactive = true;
    } else if (params.data.dispatch_status === 'dispatched' || params.data.dispatch_status === 'delivered') {
      this.dispatched = true;
    } else if (params.data.last_eod != null) {
      this.settled = true;
    } else if (params.data.last_started != null) {
      this.not_settled = true;
    } else {
      this.normal = true;
    }

  }

  public deleteOrder() {
    this.params.context.componentParent.deleteOrder(this.params.data.order_id);
  }

  public printOrder() {
    this.params.context.componentParent.printOrder(this.params.data.order_id);
  }

  refresh(): boolean {
    return false;
  }
}
