<div class="ui long modal" #vismodal_tag>
    <div class="header">
        <h2>{{modalheader}}</h2>
    </div>


    <div class="content">

        {{modalconfig}}

    </div>


    <div class="actions">
        <div class="ui approve button">Close</div>
    </div>
</div>
