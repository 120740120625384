import {Component, ElementRef, Renderer2} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";

@Component({
  selector: 'regenerate-click',
  template: `<span><div *ngIf="loader">
                        <img style="height: 24px;position: relative;left: 60px;" src="../assets/images/loader_icon.gif"/>
  </div><div *ngIf="enable_regenerate"><button class="btn white-blue-btn" style="padding: 1px 20px;" (click)="regenerate()">Regenerate</button></div></span>`,
})
export class RegenerateComponent implements ICellRendererAngularComp {
  public params: any;
  public enable_regenerate = false;
  public loader = false;

  constructor(private elRef: ElementRef,
              private renderer: Renderer2) {
  }

  agInit(params: any): void {
    this.params = params;
    let field = 'status';
    if (this.params.data.hasOwnProperty('IRN Status')) {
      field = 'IRN Status';
    }
    if (this.params.data[field] === null ||
      this.params.data[field] === 'Not Created' ||
      this.params.data[field] === 'error' ||
      this.params.data[field] === 'draft' ||
      this.params.data[field] === 'processing') {
      this.enable_regenerate = true;
    } else {
      this.enable_regenerate = false;
    }
  }

  regenerate() {

    this.loader = true;
    this.enable_regenerate = false;
    this.params.context.componentParent.regenerate(this.params.data, this.params.node);
  }

  refresh(): boolean {
    return false;
  }
}
