import {Component, ElementRef, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer} from "@angular/platform-browser";
import {ToastrService} from "ngx-toastr";
import {GridOptions} from "@ag-grid-enterprise/all-modules";

@Component({
  selector: 'app-progress-popup',
  templateUrl: './progress_popup.component.html',
  styleUrls: ['./progress_popup.component.scss']

})

export class ProgressPopupComponent implements OnInit {

  @Output() progressStatus: EventEmitter<any> = new EventEmitter<any>();
  public photo;
  title = '';
  API_URL = '';
  progress = 0;
  container = '';
  user_data: any = {};
  data = [];
  dataname = '';
  batchSize = 1;
  completed = 0;
  total = 0;
  rowData = [];
  type = '';
  failedResponse = [];
  processingComplete = false;

  dataLoaded = false;
  columnDefs = [];
  gridOptions: GridOptions;
  context: any;
  gridApi;
  gridColumnApi;
  styleGrid: any;
  gridData = [];

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              public toastr: ToastrService,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private domSanitizer: DomSanitizer,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.title = dialogdata.title;
    this.user_data = dialogdata.user_data;
    this.API_URL = dialogdata.API_URL;
    this.data = dialogdata.data;
    this.batchSize = dialogdata.batchSize;
    this.dataname = dialogdata.dataname;
    this.total = this.data.length;
    this.rowData = dialogdata.rowData;
    this.type = dialogdata.type;

    this.gridOptions = {
      context: this,
      rowHeight: 40,
      //rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      defaultColDef: {
        headerClass: 'myagheader',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        autoHeight: true,
        wrapText: true
      },
    } as GridOptions;
    this.configureGrid();
  }

  ngOnInit(): void {
    this.processData();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  configureGrid() {

    this.columnDefs = [
      {headerName: 'Outlet Code', field: 'code', width: 80},
      {headerName: 'Error', field: 'err', width: 100}
    ];

  }

  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async processData() {
    let progressError = false;
    let batch = [];
    this.progress = 10;
    await this.timeout(500);
    const total_count = this.data.length;
    let i = 0;
    const initial = this.progress;
    const diff = 100 - this.progress;
    const status = {
      err: '',
      failedCount: 0,
      successCount: 0
    };
    do {
      let failed = false;
      if (this.type === 'generateinvoice') {
        if (this.data.length <= this.batchSize) {
          batch = this.data.splice(0, this.data.length);
          this.data = [];
        } else {
          batch = this.data.splice(0, this.batchSize);
        }
        this.user_data[this.dataname] = batch;
      } else {
        batch = this.data.splice(0, this.batchSize);
        if (batch.length === 1) {
          this.user_data[this.dataname] = batch[0];
        } else {
          this.user_data[this.dataname] = batch;
        }
      }
      i += batch.length;
      if (this.type === 'changerequest' && this.user_data.state === 'approved') {
        if (this.user_data.sapVerifyTypes.includes(this.rowData[i - 1].request_type)) {
          const res1 = await this.verify(this.rowData[i - 1]);
          if (res1 === 1) {
          } else {
            progressError = true;
            status.err = 'Sap verification failed for outlet : ' + this.rowData[total_count - i].code;
            //status.err = this.failedResponse;
            status.failedCount += 1;
            this.progress = Math.round(initial + ((i / total_count) * diff));
            this.completed += batch.length;
            failed = true;
            await this.timeout(500);
          }
        }
      }
      if (!failed) {
        const res = await this.apiService.postPromise(this.API_URL, this.user_data);
        this.progress = Math.round(initial + ((i / total_count) * diff));
        this.completed += batch.length;
        await this.timeout(500);
        if (this.type === 'generateinvoice') {
          if (res.hasOwnProperty('result') && res.result.statusCode === 200) {
            status.successCount += batch.length;
          } else {
            status.failedCount += batch.length;
          }
        } else {
          if (res.hasOwnProperty('result') && (res.result.success)) {
            status.successCount += 1;
          } else if (res.hasOwnProperty('error') && res.error.hasOwnProperty('error')) {
            status.failedCount += 1;
            progressError = true;
            this.failedResponse.push(
              {
                code: this.rowData[i - 1].code,
                err: res.error.error.message
              }
            );
          } else {
            status.failedCount += 1;
            progressError = true;
            this.failedResponse.push(
              {
                code: this.rowData[i - 1].code,
                err: 'Failed to update'
              }
            );
          }
        }
        // this.data = batch;
      }
    } while (this.data.length > 0);
    this.processingComplete = true;
    if (!progressError) {
      this.progressStatus.emit(status);
      this.dialog.closeAll();
    } else {
      if (status.successCount > 0 && status.failedCount === 0) {
        this.toastr.success('Successfully processed ' + status.successCount + ' records');
      } else if (status.successCount > 0) {
        this.toastr.warning('Completed : ' + status.successCount + ' , Failed : ' + status.failedCount);
      } else {
        this.toastr.error('Failed : ' + status.failedCount);
      }
      this.dataLoaded = true;
      this.gridData = this.failedResponse;
    }
  }

  async verify(row) {
    let sapSync = false;
    const integrs = JSON.parse(localStorage.getItem('integrations'));
    const updated = integrs.filter(existed => existed.integration_type === 'sap' && existed.country_id === row.country_id);
    const role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(existed => 'feature_sap_push' === existed.name);
    if (role_features.length > 0) {
      sapSync = true;
    }
    if ((row.country_id === null || row.country_id === undefined) && sapSync) {
      //this.toastr.error('Country not set for outlet : ' + row.code);
      //this.failedResponse = this.failedResponse + 'Country not set for outlet : ' + row.code + ' ,';
      this.failedResponse.push(
        {
          code: row.code,
          err: 'Country not set'
        }
      );
      return 0;
    } else if (updated.length > 0 && updated[0].config_json && sapSync) {
      const sapConfig = updated[0].config_json;
      sapConfig.UpdateBp = sapConfig.UpdateBp.toString().replace("$outletcode$", row.code);
      const payload = {
        access_token: localStorage.getItem('resfreshToken'),
        data: {
          url: sapConfig.AuthUrl,
          payload: {
            CompanyDB: sapConfig.CompanyDB,
            Password: sapConfig.Password,
            UserName: sapConfig.UserName
          }
        }
      };
      const res = await this.apiService.postPromise('/api/sap_connects/sapLogin', payload);
      if (res.hasOwnProperty('results') && res.results.body) {
        const session = JSON.parse(res.results.body)['SessionId'].toString();
        const update_vals = {};
        if (row.request_type.toLowerCase() === 'cash_to_credit') {
          update_vals['U_Mode'] = 'credit';
        }
        if (row.request_type.toLowerCase() === 'credit_to_cash') {
          update_vals['U_Mode'] = 'cash';
        }
        /*  if (row.request_type.toLowerCase() === 'change_beat') {
            update_vals['U_Route'] = JSON.parse(row.new_val)['value'];
          }*/
        if (row.request_type.toLowerCase() === 'program_type') {
          update_vals['GroupCode'] = JSON.parse(row.new_val)['value'];
        }
        if (row.request_type.toLowerCase() === 'contact_details') {
          if (JSON.parse(row.new_val)['phone'] && JSON.parse(row.new_val)['phone'] !== "") {
            update_vals['Phone1'] = JSON.parse(row.new_val)['phone'];
          }
          if (JSON.parse(row.new_val)['email'] && JSON.parse(row.new_val)['email'] !== "") {
            update_vals['EmailAddress'] = JSON.parse(row.new_val)['email'];
          }
          if (JSON.parse(row.new_val)['outlet_name'] && JSON.parse(row.new_val)['outlet_name'] !== "") {
            update_vals['CardName'] = JSON.parse(row.new_val)['outlet_name'];
          }
        }
        if (row.request_type.toLowerCase() === 'classification') {
          if (JSON.parse(row.new_val).hasOwnProperty('outlet_type')) {
            update_vals['U_OutletType'] = JSON.parse(row.new_val)['outlet_type']['value'];
            update_vals['U_Type'] = JSON.parse(row.new_val)['outlet_type']['value'];
          }
        }
        if (row.request_type.toLowerCase() === 'location_with_address') {
          update_vals['U_Zone'] = JSON.parse(row.new_val)['zone'];
          update_vals['U_Addrss1'] = JSON.parse(row.new_val)['street1'];
          update_vals['U_Addrss2'] = JSON.parse(row.new_val)['street2'];
        }
        const userData = {
          access_token: localStorage.getItem('resfreshToken'),
          data: {
            outlet_code: row.code,
            sessionId: session,
            db: sapConfig.CompanyDB,
            payload: update_vals,
            url: sapConfig.UpdateBp
          }
        };
        const res1 = await this.apiService.postPromise('/api/sap_connects/sapUpdatePartner', userData);
        if (res1.hasOwnProperty('results')) {
          if (res1.results.hasOwnProperty('error')) {
            if (res1.results.error.hasOwnProperty("message") && res1.results.error.message.hasOwnProperty("value")) {
              this.failedResponse.push(
                {
                  code: row.code,
                  err: res1.results.error.message.value
                }
              );
            } else {
              this.failedResponse.push(
                {
                  code: row.code,
                  err: res1.results.error
                }
              );
            }
            return 0;
          } else {
            return 1;
          }
        } else {
          //this.failedResponse = this.failedResponse + ' Sap Verification outlet :' + row.code;
          this.failedResponse.push(
            {
              code: row.code,
              err: 'Sap Verification failed'
            }
          );
          return 0;
        }
      } else {
        //this.failedResponse = this.failedResponse + ' Sap Login failed';
        this.failedResponse.push(
          {
            code: row.code,
            err: 'Sap Login failed'
          }
        );
        return 0;
      }
    } else {
      return 1;
    }
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.gridData);
    this.gridApi.setDomLayout("autoHeight");
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    if (screen.width > 991) {
      this.gridOptions.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }
}
