<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper">
      <div id="overlay"></div>
      <div style="margin-right: 10px;">
        <div class="cardbox" style="width: 100%; border-top: 7px solid;border-top-color: #0762AD;">
          <div class="search-list">
            <div class="row" style="height: 33px;">
              <div *ngIf="!editMode && activeObject['state'] === 'draft'" class="button-row">
                <button
                  class="btn confirm-blue-btn"
                  (click)="action_edit()"
                >EDIT
                </button>
              </div>
              <div *ngIf="!editMode && activeObject['state'] === 'draft'" class="button-row" style="margin-left: 10px;">
                <button
                  class="btn confirm-blue-btn"
                  (click)="action_confirm_order()"
                >CONFIRM ORDER
                </button>
              </div>
              <!--                    <div  *ngIf="!editMode && activeObject['state'] === 'draft' && activeObject['invoice_status'] !== 'invoiced'" class="button-row">-->
              <!--                      <button-->
              <!--                        class="btn grey-btn"-->
              <!--                        (click)="action_confirm_and_create_invoice()"-->
              <!--                      >CONFIRM & CREATE FULL INVOICE-->
              <!--                      </button>-->
              <!--                    </div>-->
              <!--                    <div  *ngIf="!editMode && activeObject['state'] === 'sale'  && activeObject['invoice_status'] !== 'invoiced'" class="button-row">-->
              <!--                      <button-->
              <!--                        class="btn green-button"-->
              <!--                        (click)="action_create_invoice()"-->
              <!--                      >CREATE INVOICE-->
              <!--                      </button>-->
              <!--                    </div>-->
              <!--                    <div  *ngIf="!editMode && activeObject['state'] === 'sale'  && activeObject['invoice_status'] !== 'invoiced'" class="button-row">-->
              <!--                      <button-->
              <!--                        class="btn green-button"-->
              <!--                        (click)="action_create_partial_invoice()"-->
              <!--                      >CREATE PARTIAL INVOICE-->
              <!--                      </button>-->
              <!--                    </div>-->
              <!--                    <div  *ngIf="!editMode && activeObject['state'] !== 'cancel' && activeObject['invoice_status'] === 'to invoice'" class="button-row">-->
              <!--                      <button-->
              <!--                        class="btn grey-btn"-->
              <!--                        (click)="action_cancel()"-->
              <!--                      >CANCEL-->
              <!--                      </button>-->
              <!--                    </div>-->
              <div *ngIf="editMode" class="button-row" style="margin-left: 10px;">
                <button
                  style="margin-left:0px !important;"
                  class="btn grey-btn"
                  (click)="discard()"
                >
                  DISCARD
                </button>
              </div>
              <div *ngIf="editMode" class="button-row" style="margin-left: 10px;">
                <button
                  *ngIf="showSave"
                  class="btn confirm-blue-btn"
                  (click)="saveCart()"
                >
                  SAVE
                </button>
              </div>
              <div *ngIf="!newMode && !editMode && listLength > 0" style="position: absolute;right: 40px;">
                <div class="button-row">
                  <div style="display: inline-block; margin-right: 20px;" class="statuscard"
                       [ngClass]="{'warning': activeObject['state'] === 'draft',
                         'new': activeObject['state'] === 'sale'}">
                          <span class="statusindicator"
                                [ngClass]="{'warning': activeObject['state'] === 'draft',
                         'new': activeObject['state'] === 'sale'}"></span>
                    <span [ngClass]="{'warning': activeObject['state'] === 'draft',
                         'new': activeObject['state'] === 'sale'}">{{ activeObject['state'] }}</span>
                  </div>
                        <span title="Print" style="margin-right: 20px;cursor: pointer"><img (click)="getPrintDetails()"
                                                                                            src="./../../../../assets/icons/print.svg"/></span>
                  <span (click)="movePrevious()"><i
                    [ngClass]="{'disabledspan': rowIndex === 0,
                         'enabledspan': rowIndex >0}"
                    class="fal fa-arrow-circle-left fa-2x"></i></span>
                  <span
                    style="margin-left: 5px; margin-right: 5px; margin-bottom: 2px;font-size: 12px;">{{ rowIndex + 1 }}
                    /{{ listLength }}</span>
                  <span (click)="moveNext()"><i
                    [ngClass]="{'disabledspan': rowIndex === listLength-1,
                         'enabledspan': rowIndex < listLength-1}"
                    class="fal fa-arrow-circle-right fa-2x"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 cardbox mt-4">
          <div class="col-md-12 pad0" *ngIf="newMode">
            <div class="col-md-3 pl-0 pr-30">
              <label class="lbl">Supplier</label>
              <div class="formselect">
                <ng-select *ngIf="newMode"
                           [items]="supplierList"
                           bindLabel="name"
                           bindValue="id"
                           (ngModelChange)="supplierChangeEvent($event)"
                           [(ngModel)]="activeObject['distributor_id']">
                  >
                </ng-select>
              </div>
            </div>
            <div class="col-md-3 pl-0 pr-30">
              <label class="lbl">Customer</label>
              <div class="formselect">
                <ng-select *ngIf="newMode"
                           [items]="customerList"
                           bindLabel="name"
                           bindValue="id"
                           (ngModelChange)="customerChangeEvent($event)"
                           [(ngModel)]="activeObject['partner_id']">
                  >
                </ng-select>
                <p class="lbl3" *ngIf="!newMode">{{ activeObject['customer_name'] }}</p>
              </div>
            </div>
            <div class="col-md-3 pl-0 pr-30">
              <label class="lbl">Salesman</label>
              <div class="formselect">
                <ng-select *ngIf="userList && userList.length > 0 && newMode"
                           [items]="userList"
                           bindLabel="itemname"
                           bindValue="id"
                           (ngModelChange)="salesmanChange($event)"
                           [(ngModel)]="activeObject['user_id']">
                  >
                </ng-select>
              </div>
            </div>
            <div class="col-md-3 pl-0 pr-30">
              <label class="lbl">Date</label>
              <div>
                <div>
                  <input
                    class="forminput2"
                    type="text"
                    name="date_order"
                    style="width:60%"
                    [disabled]="dateDisable"
                    [readonly]="true"
                    [(ngModel)]="activeObject['date_order']"
                    #date_order="ngModel"
                    [owlDateTime]="dt"
                    [owlDateTimeTrigger]="dt"
                    required
                  >
                  <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 pad0" *ngIf="newMode">
            <div class="col-md-3 pl-0 pr-30">
            </div>
            <div class="col-md-3 pl-0 pr-30">
            </div>
            <div class="col-md-3 pl-0 pr-30" *ngIf="showPlantField">
              <label class="lbl">Plant</label>
              <div style="margin-top: 5px;" class="formselect">
                <ng-select *ngIf="plantList && plantList.length > 0 && editMode"
                           [items]="plantList"
                           bindLabel="name"
                           bindValue="code"
                           (ngModelChange)="plantChangeEvent($event)"
                           [(ngModel)]="this.activeObject['config_json']['plant']">
                  >
                </ng-select>
              </div>
            </div>
            <div class="col-md-3 pl-0 pr-30" *ngIf="showIncoTerms">
              <label class="lbl">Inco Terms</label>
              <div style="margin-top: 5px;" class="formselect">
                <ng-select *ngIf="inco_terms_list && inco_terms_list.length > 0 && editMode"
                           [items]="inco_terms_list"
                           bindLabel="code"
                           bindValue="code"
                           [(ngModel)]="this.activeObject['config_json']['inco_term']">
                  >
                </ng-select>
              </div>
            </div>

          </div>
          <div class="col-md-12 pad0" *ngIf="!newMode" style="margin-top: 10px;">
            <div class="col-md-10 pad0">
              <div class="col-md-12 pad0">
                <div class="col-md-4 pl-0 pr-30">
                  <label class="lbl">Supplier</label>
                  <div>
                    <p class="lbl3" *ngIf="activeObject['supplier_name']">{{ activeObject['supplier_name'] }}</p>
                    <p *ngIf="activeObject['supplier_gst_id']" class="lbl2">Tax Id
                      : {{ activeObject['supplier_gst_id'] }}</p>
                    <p *ngIf="activeObject['supplier_code']" class="lbl2">Code : {{ activeObject['supplier_code'] }}</p>
                  </div>
                </div>
                <div class="col-md-4 pl-0 pr-30">
                  <label class="lbl">Customer</label>
                  <div style="display: inline">
                    <p class="lbl3" *ngIf="!newMode">{{ activeObject['customer_name'] }}</p>
                    <p style="display: inline" *ngIf="activeObject['customer_code']" class="lbl2">Code
                      : {{ activeObject['customer_code'] }}</p>
                    <p style="display: inline" *ngIf="activeObject['customer_gst_id']" class="lbl2 ml-3">Tax Id
                      : {{ activeObject['customer_gst_id'] }}</p>
                    <p *ngIf="activeObject['pricelist_name']" class="lbl2">Pricelist
                      : {{ activeObject['pricelist_name'] }}</p>
                  </div>
                </div>
                <div class="col-md-4 pl-0 pr-30">
                  <label class="lbl">Salesman</label>
                  <div>
                    <p class="lbl3" *ngIf="activeObject['salesman']">{{ activeObject['salesman'] }}</p>
                    <p *ngIf="activeObject['emp_code']" class="lbl2">Code : {{ activeObject['emp_code'] }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-12 pad0">
                <div class="col-md-3 pl-0 pr-30">

                </div>
                <div class="col-md-3 pl-0 pr-30">

                </div>
                <div class="col-md-2 pl-0 pr-30" *ngIf="showPlantField">
                  <label class="lbl">Plant</label>
                  <div>
                    <p class="lbl3"
                       *ngIf="this.activeObject['config_json']">{{ this.activeObject['config_json']['plant_name'] }}</p>
                  </div>
                </div>
                <div class="col-md-2 pl-0 pr-30" *ngIf="showIncoTerms">
                  <label class="lbl">Inco Terms</label>
                  <div>
                    <p class="lbl3"
                       *ngIf="this.activeObject['config_json']">{{ this.activeObject['config_json']['inco_term'] }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2 pad0">
              <div class="col-md-12 pl-0 pr-0 shadow-box" style="border: 1px solid #0762AD;"
                   *ngIf="!newMode && activeObject['id']">
                <div class="row centeralign" style="padding: 10px;cursor: pointer;" (click)="detailsClick()">
                  <div style="display: inline-block">
                    <img src="/assets/icons/cart.svg">
                  </div>
                  <div style="display: inline-block; margin-left: 10px;">
                    <label class="lbl3" style="font-size: 11px;">Order Info</label>
                    <span class="lbl" style="display: block;margin-top: 0px;">{{ activeObject['name'] }}</span>
                  </div>
                  <img *ngIf="!showDetails" style="position: absolute; right: 10px;height: 8px;"
                       src="/assets/images/down_arrow_black.svg"/>
                  <img *ngIf="showDetails" style="position: absolute; right: 10px;height: 8px;"
                       src="/assets/images/up_arrow.svg"/>
                </div>
                <div *ngIf="showDetails" style="margin-top:4%;padding: 10px;border-top: 1px solid #0762AD;">
                  <div class="row">
                    <label class="lbl4">Order ID</label>
                    <span class="lbl" style="margin-left: 10px;">{{ activeObject['id'] }}</span>
                  </div>
                  <div class="row">
                    <label class="lbl4">Order Date</label>
                    <span class="lbl"
                          style="margin-left: 10px;">{{ activeObject['date_order'] + 'Z' | date:'yyyy-MM-dd HH:mm:ss' }}</span>
                  </div>
                  <div class="row" *ngIf="activeObject['state'] !== 'draft' && activeObject['confirmation_date']">
                    <label class="lbl4">Confirm Date</label>
                    <span class="lbl"
                          style="margin-left: 10px;">{{ activeObject['confirmation_date'] + 'Z' | date:'yyyy-MM-dd HH:mm:ss' }}</span>
                  </div>
                  <div class="row">
                    <label class="lbl4">Invoice Status</label>
                    <span class="lbl" style="margin-left: 10px;">{{ activeObject['invoice_status'] }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 cardbox mt-4">
        <div class="col-md-12 pt-4 pad0" *ngIf="editMode && !reference_order_mode">
          <div class="col-md-4 pl-0 pr-30">
            <label class="lbl">Product</label>
            <div class="formselect">
              <ng-select
                #productSelector
                class="custom3"
                [items]="prodList"
                bindLabel="product"
                bindValue="p_id"
                [virtualScroll]="true"
                [(ngModel)]="selectedProduct"
                (ngModelChange)="productSelectorChange()"
              >
              </ng-select>
            </div>
          </div>
          <div class="col-md-1 pl-0 pr-30">
            <label class="lbl">Qty</label>
            <div>
              <div>
                <input
                  class="forminput2"
                  type="text"
                  name="qty"
                  onlyNumber
                  [(ngModel)]="enteredQty"
                  #qty="ngModel"
                  style="width: 100%;"
                  (keydown.enter)="addProductClick()"
                  required
                >
              </div>
            </div>
          </div>
          <div class="col-md-1 pl-0 pr-30" *ngIf="UOMList">
            <label class="lbl">UOM</label>
            <div class="formselect">
              <ng-select
                #productSelectorUOM
                class="custom3"
                [items]="UOMList"
                bindLabel="name"
                bindValue="id"
                (ngModelChange)="productUOMChange($event)"
                [(ngModel)]="selectedUOM_id"
              >
              </ng-select>
            </div>
          </div>
          <div class="col-md-1 pl-0 pr-30">
            <label class="lbl">Stock</label>
            <div>
              <div>
                <input
                  class="forminput2"
                  type="text"
                  name="stck"
                  [(ngModel)]="stock"
                  [disabled]="true"
                  style="width: 100%;"
                  #stck="ngModel"
                  required
                >
              </div>
            </div>
          </div>
          <div class="col-md-3 pt-4 pl-0 pr-10">
            <button class="btnsmall confirm-blue-btn"
                    (click)="addProductClick()"
            >
              Add
            </button>
            <button style="margin-left: 10px;" class="icon-btn-small confirm-blue-btn"
                    (click)="openBulkSelectPopup()"
            ><i class="fas fa-plus-circle"></i>
              Bulk Product Selection
            </button>
          </div>
        </div>
        <div class="col-md-12 pt-4 pad0" *ngIf="editMode && applicableSchemes && applicableSchemes.length > 0">
          <label class="lbl"> {{ applicableSchemes.length }}  Schemes Applicable</label>
        </div>
        <div class="col-md-12 pt-4 pad0">
          <div class="dms_table">
            <ag-grid-angular id="myGrid1" style="width: 100%;" class="ag-theme-balham"
                             [columnDefs]="columnDefs"
                             [gridOptions]="gridOptions"
                             (gridReady)="onGridReady($event)"
                             [frameworkComponents]="frameworkComponents"
                             [context]="context"
                             [isExternalFilterPresent]="isExternalFilterPresent"
                             [doesExternalFilterPass]="doesExternalFilterPass"
                             (cellEditingStarted)="onCellEditingStarted($event)"
                             (cellEditingStopped)="onCellEditingStopped($event)">
            </ag-grid-angular>
          </div>
        </div>
        <div class="col-md-12 pt-4 pad0">
          <div class="col-md-4 pl-0 pr-30">
            <label class="lbl">Instructions</label>
            <div class="formselect2">
              <div *ngIf="newMode || editMode">
                  <textarea style="background-color: #F5F7F9" class="form-control"
                            rows="4" id="comment" [(ngModel)]="this.activeObject['note']"></textarea>
              </div>
              <p class="lbl3" *ngIf="!editMode">{{ this.activeObject['note'] }}</p>
            </div>
          </div>
          <div class="col-md-4 mt-2" style="margin-top: 20px; !important">
            <div *ngIf="activeObject['activity_json']">
              <label class="lbl">Activities</label>
              <div class="history-tl-container">
                <ul class="tl">
                  <li *ngFor="let activity of activeObject['activity_json']" ng-repeat="item in retailer_history">
                    <div class="item-title">{{ activity.s }}</div>
                    <div class="item-detail">{{ activity.t + 'Z' | date:'yyyy-MM-dd HH:mm:ss' }}</div>
                    <div class="lbl2">{{ activity.m }}</div>
                  </li>
                </ul>
              </div>
            </div>

          </div>
          <div *ngIf="activeObject" class="col-md-4 mt-2">
            <table style="float: right;">
              <tbody>
              <tr *ngIf="activeObject['scheme_discount']">
                <td><p class="p-class">Scheme Discount</p></td>
                <td><p
                  class="p-class1">{{ math.abs(activeObject['scheme_discount']).toFixed(apiService.decimalPolicy) }}</p>
                </td>
              </tr>
              <tr *ngIf="activeObject['amount_untaxed']">
                <td><p class="p-class">Taxable Amount</p></td>
                <td><p
                  class="p-class1">{{ math.abs(activeObject['amount_untaxed']).toFixed(apiService.decimalPolicy) }}</p>
                </td>
              </tr>
              <tr *ngIf="activeObject['amount_tax']">
                <td><p class="p-class">Taxes</p></td>
                <td><p class="p-class1">{{ math.abs(activeObject['amount_tax']).toFixed(apiService.decimalPolicy) }}</p>
                </td>
              </tr>
              <tr *ngIf="showTotalWeight">
                <td><p class="p-class">Total Weight</p></td>
                <td><p class="p-class1">{{ totalWeightFieldValue }}</p></td>
              </tr>
              <tr *ngIf="showTotalMeasure">
                <td><p class="p-class">Total Measure</p></td>
                <td><p class="p-class1">{{ totalMeasureFieldValue }}</p></td>
              </tr>
              <tr style=" border-top: 2px solid #f3f3f3;" *ngIf="activeObject['amount_total']">
                <td><p class="p-class3">Total Payable</p></td>
                <td><p
                  class="p-class4">{{ math.abs(activeObject['amount_total']).toFixed(apiService.decimalPolicy) }}</p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>


