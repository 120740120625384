<div id="overlay"></div>
<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <h2 style="color: #7D7D7D;">Finance Verification</h2>
      <div style="border-bottom: 1px solid #E5E5E5;"></div>
    </div>
    <div *ngIf="enable_finance" class="body-section">
      <div class="row">
        <div class="col-md-6 pl-0 mt-5">
          <label>Payment Mode</label>
          <div style="width:100%;" class="drpdwnselect mt-3">
            <select  name="selectedStatus"
                     id="aantal"
                     class="form-control status-drp"
                     [(ngModel)]="payment_mode"
                     (change)="paymentModeChange()">
              <option *ngFor="let mode of ['Credit', 'Cash']" >{{mode}}</option>
            </select>
          </div>
        </div>
        <div *ngIf="payment_mode==='Credit'" class="col-md-6 pl-0 mt-5">
          <label>Credit Limit</label>
          <input
            class="credinput mt-3"
            type="text"
            name="email"
            style="width: 100%"
            [(ngModel)]="credit_limit"
          >
        </div>
      </div>
      <div class="row">
        <div *ngIf="pricelist" class="col-md-6 pl-0 mt-5">
          <label>Territory Pricelist</label>
          <p class="mt-3">{{pricelist}}</p>
        </div>
        <div *ngIf="dialogdata.params.parent && pricelist" class="col-md-6 pl-0 mt-5">
          <label>Chain Pricelist</label>
          <p class="mt-3">{{chain_pricelist}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 pl-0 mt-5">
          <label>Pricelists</label>
          <div style="width:100%;" class="drpdwnselect mt-3">
            <ng-select [hidden]="pricelists.length === 1"
                       [items]="pricelists"
                       bindLabel="name"
                       multiple="true"
                       maxSelectedItems="1"
                       appendTo="body"
                       [virtualScroll]="true"
                       [(ngModel)]="selectedPrice"
                       (ngModelChange)="priceChange($event)"
            >
            </ng-select>
          </div>
        </div>
        <div *ngIf="payment_mode==='Credit'" class="col-md-6 pl-0 mt-5">
          <label>Credit Days</label>
          <input
            class="credinput mt-3"
            type="text"
            name="cred"
            style="width: 100%"
            [(ngModel)]="credit_days"
          >
        </div>
      </div>
      <div>
        <div class="mt-5">
          <button (click)="verifyFinance()" style=" margin-right:15px;" class="btn confirm-blue-btn">APPROVE</button>
          <button (click)="closeDialog()" class="btn grey-btn">CLOSE</button>
        </div>
      </div>
    </div>
    <div *ngIf="!enable_finance" class="body-section">
      <div class="ml-3">
        <div class="mt-5">
          <img src="../../../../assets/warning_red.svg">
          <p class="pink-text ml-2">Warning! You cannot proceed without verifying Chain</p>
        </div>
      </div>

    </div>
  </div>
</section>
