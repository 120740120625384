import {PageBase} from "../../../pagebase/pagebase";
import {Component, OnInit} from "@angular/core";
import {MatDialogConfig} from "@angular/material/dialog";



@Component({
  selector: 'app-sale-order-list',
  templateUrl: '../../../pagebase/pagebase.html',
  styleUrls: ['../../../pagebase/pagebase.scss']
})

export class DirectOrderListComponent extends PageBase implements OnInit, IPage {

  matActionMenuItemDeclaration = [{name: 'Export'}];
  customFilter: any = {
    name: 'Status',
    key: 'status',
    value: 'draft',
    values: [],
    show: true
  };

  ngOnInit(): void {

    this.sharedService.setHeader('Direct Orders');
    this.showCustomFilter = true;

    this.customFilter.values = [
      {id: 'All', itemName: 'All'},
      {id: 'draft', itemName: 'Draft'},
      {id: 'sale', itemName: 'Confirmed'},
      {id: 'cancel', itemName: 'Cancel'},
    ];
    this.checkAndLoadPreviousSate();
    this.checkAccessControl();
    this.configureGrid();
    this.loadServerData();
  }


  configureGrid() {
    this.API_URL = '/api/pwa_dms_connects/get_direct_orders';

    const OrderStatusCellClassRules = {
      'rag-green-outer': (params) => params.value === 'sale',
      'rag-amber-outer': (params) => params.value === 'confirmed',
      'rag-red-outer': (params) => params.value === 'cancel',
    };

    const inthis = this;
    this.columnDefs = [
      {headerName: "ID", field: "id", width: 25, checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true},
      {headerName: "No", field: "order_no", width: 50},
      {headerName: "DATE", field: "date", width: 40},
      {headerName: "ST CODE", field: "code", width: 50},
      {headerName: "CUSTOMER", field: "outlet", width: 70},
      {headerName: "TYPE", field: "o_type", width: 50},
      {headerName: "VERTICAL", field: "vertical", width: 50},
      {headerName: "BT CODE", field: "bt_code", width: 50},
      {headerName: "BILL TO", field: "bt_name", width: 50},
      {headerName: "SHIP TO", field: "st_name", width: 50, hide: true},
      {headerName: "SUB TOTAL", field: "amount_untaxed", width: 30, aggFunc: 'sum', cellStyle: {textAlign: 'right'}},
      {headerName: "TOTAL", field: "amount_total", width: 30, hide: true, aggFunc: 'sum', cellStyle: {textAlign: 'right'}},
      {headerName: "PLANT", field: "plant_name", width: 40},
      {headerName: "SALESMAN", field: "salesman", width: 40},
      {headerName: "EMP CODE", field: "emp_code", width: 30, hide: true},
      {headerName: "ORDER STATUS", field: "order_status", width: 25,
        cellClassRules: OrderStatusCellClassRules},
      {headerName: "VERIFIED", field: "is_verified", width: 50},
      {headerName: "INVOICE STATUS", field: "invoice_status", width: 25},
      {headerName: "NOTE", field: "note", width: 40},
      {headerName: "MODIFIED", field: "write_date", width: 50, valueGetter: params => {
            if (params.data) {
              if (params.data.write_date) {
                return inthis.datePipe.transform((new Date(params.data.write_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
              } else {
                return params.data.write_date;
              }
            } else {
              return '';
            }
          }
        },
      {headerName: "CONFIRMATION DATE", field: "confirmation_date", width: 50, valueGetter: params => {
          if (params.data) {
            if (params.data.confirmation_date) {
              return inthis.datePipe.transform((new Date(params.data.confirmation_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return params.data.confirmation_date;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: "SUPPLIER", field: "sup_name", width: 40, hide: true},
      {headerName: "BEAT", field: "beat", width: 30},
      {headerName: "TERRITORY", field: "territory", width: 30},
      {headerName: "CLUSTER", field: "cluster", width: 30, hide: true},
      {headerName: "STATE", field: "state", width: 30},
    ];

    this.showPinnedFooter = true;
    this.columnsWithAggregation = ['amount_untaxed'];
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const selectedRows = this.gridApi.getSelectedRows();
    const active_ids = [];
    this.gridApi.forEachNodeAfterFilter(node => {
      active_ids.push(node.data.id);
    });

    console.log('filtered Row Count' + active_ids.length);
    localStorage.setItem('active_ids', JSON.stringify(active_ids));

    if (selectedRows !== null && selectedRows.length > 0 && selectedRows[0].id !== undefined) {
      this.router.navigate([this.apiService.redirect_url + '/dms/direct_order'], {
        relativeTo: this.route,
        queryParams: {id: selectedRows[0].id }
      });
    }
  }

  checkAccessControl() {
    this.showCreate = false;
  }

  openCreateDialog() {
    const default_return_invoice = {};
    default_return_invoice['id'] = 0;
    this.router.navigate([this.apiService.redirect_url + '/dms/direct_order'], {
      relativeTo: this.route,
      queryParams: {id: 0 }
    });
  }

  onMenuItemSelected(menuitem) {
    const rows = this.gridApi.getSelectedRows();
    if (rows === null || rows === undefined || rows.length === 0) {
      this.toastr.error('Please select at least 1 Record');
    } else {
      if (menuitem.name === 'Export') {
        const order_ids = rows.map(item => item.id)
        this.generate_selected_orders_export(order_ids);
      }
    }
  }

  generate_selected_orders_export(order_ids) {

  }

}
