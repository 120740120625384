<div class="common-wrapper">
  <div class="common-container">

    <div class="main-wrapper eod">
      <div id="overlay"></div>
      <div>
        <ul>
          <div *ngIf="sharedService.new && !sharedService.is_return" style="display: inline-block;margin-left: 75%;margin-right: 3%;">
            <button [routerLink]="'/po_po'" style="position: relative;margin-top: -2.5%;left: 91%;margin-left: -1%;" class="btn confirm-blue-btn">CANCEL</button>
          </div>
          <div *ngIf="sharedService.new && !sharedService.is_return" style="display: inline-block;">
            <button (click)="savePO()" style="position: relative;margin-top: -2.5%;left: 91%;margin-left: -1%;" class="btn confirm-blue-btn">SAVE</button>
          </div>
          <div *ngIf="sharedService.mark_receieve" style="display: inline-block;">
            <button (click)="makeRecieve()" style="margin-left: 840%;" class="btn confirm-blue-btn">RECEIVE</button>
          </div>
          <div *ngIf="sharedService.delivered_po || sharedService.mark_receieve" style="display: inline-block;float:left">
            <button (click)="gotoGrn()" class="btn confirm-blue-btn">VIEW GRN</button>
          </div>
          <div *ngIf="sharedService.delivered_po || sharedService.mark_receieve" style="display: inline-block;float:left">
            <button (click)="gotoGrv()" style="margin-left: 5%;" class="btn confirm-blue-btn">VIEW GRV</button>
          </div>
          <div *ngIf="sharedService.is_receipt" style="display: inline-block;float:left">
            <button (click)="gotoPO()" style="margin-left: 5%;" class="btn confirm-blue-btn">VIEW PO</button>
          </div>
          <div *ngIf="sharedService.delivered_po || sharedService.mark_receieve" style="display: inline-block;">
            <button (click)="makeReturn()" style="margin-left: 865%;" class="btn confirm-blue-btn">RETURN</button>
          </div>
          <div *ngIf="!sharedService.delivered_po && feature_dispatch && d_status && d_status !=='dispatched'" style="display: inline-block;">
            <button (click)="dispatch()" style="margin-left: 804%;" class="btn confirm-blue-btn">DISPATCH</button>
          </div>
          <div *ngIf="sharedService.is_return" style="display: inline-block;margin-left: 75%;margin-right: 3%;">
            <button [routerLink]="'/po_po'" style="position: relative;margin-top: -2.5%;left: 91%;margin-left: -1%;" class="btn confirm-blue-btn">CANCEL</button>
          </div>
          <div *ngIf="sharedService.is_return" style="display: inline-block;">
            <button (click)="receivePO(sharedService.order_type)" style="position: relative;margin-top: -2.5%;left: 55%;margin-left: -1%;" class="btn confirm-blue-btn">CONFIRM</button>
          </div>
        </ul>
      </div>
      <div class="eodtop-card" style="flex-wrap: nowrap;margin-top: 3.5%;margin-right: 2%;margin-left: 2%;height: max-content;">
        <ul class="list-unstyled">
          <!--<li class="margb">-->
          <!--<h2>VAN</h2>-->
          <!--</li>-->
          <li>
            <h2 *ngIf="(!sharedService.is_receipt && !sharedService.is_return) || (!sharedService.is_receipt
                      && sharedService.is_return && !sharedService.new)">PURCHASE INFO</h2>
            <h2 *ngIf="(sharedService.is_receipt || (sharedService.is_return && sharedService.new)) && sharedService.order_type == 'sale'">GRN INFO</h2>
            <h2 *ngIf="(sharedService.is_receipt || (sharedService.is_return && sharedService.new)) && sharedService.order_type == 'return'">GRV INFO</h2>
          </li>
        </ul>
        <ul>
          <div class="custom-input" style="margin-top: 2%;margin-left: 2%;display: inline-block;">
            <label *ngIf="(!sharedService.is_receipt && !sharedService.is_return && !sharedService.new) || (!sharedService.is_receipt
                      && sharedService.is_return && !sharedService.new)">PO NO</label>
            <label *ngIf="(sharedService.is_receipt || (sharedService.is_return && sharedService.new))
                  && sharedService.order_type == 'sale'">GRN NO</label>
            <label *ngIf="(sharedService.is_receipt || (sharedService.is_return && sharedService.new))
                   && sharedService.order_type == 'return'">GRV NO</label>
            <br/><label style="color: black;font-size: 150%;margin-top: 2%;">{{order_no}}</label>
<!--            <input [disabled]="true" type="text" class="form-control" style="width: 107% !important;padding: 1% 10%;margin-top: -1%;" [(ngModel)]="order_no" />-->
          </div>
          <label style="position: relative;left: 6%;bottom: 3.5vh;color: #b0bac9;font-size: 115%;letter-spacing: 1.13px;line-height: 100%;font-weight: 400;padding-bottom: 2%;">DATE</label>
          <div *ngIf="sharedService.new || sharedService.is_return" class="date-picker" style="display: inline-block;margin-left: 1%;">
            <span class="datepicker-icon"><img src="../../assets/date.png"></span>
            <input style="height: 35px;width: 80% !important;;background-color: '#e0e7ff';" [readonly]="true" [(ngModel)]="po_date" [owlDateTimeTrigger]="dt" [owlDateTime]="dt" placeholder="Date">
            <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
          </div>
          <div *ngIf="!sharedService.new && !sharedService.is_return" class="custom-input" style="display: inline-block;margin-left: 2%;">
            <label style="color: black;font-size: 150%;">{{po_date}}</label>
<!--            <input [disabled]="true" type="text" class="form-control" style="width: 107% !important;padding: 1% 10%;margin-top: -1%;" [(ngModel)]="po_date" />-->
          </div>
          <div [hidden]="!sharedService.new" style="width: 15%;font-size: 130%;font-weight: 500;display: inline-block;margin-left: 4%;;">
            <label style="color: #b0bac9;font-size: 85%;letter-spacing: 1.13px;line-height: 100%;font-weight: 400;padding-bottom: 2%;">CUSTOMER</label>
            <ng-template adHost></ng-template>
          </div>
          <div [hidden]="sharedService.new" class="custom-input" style="width: 15%;font-size: 130%;font-weight: 500;display: inline-block;margin-left: 2%;">
            <label style="color: #b0bac9;font-size: 85%;letter-spacing: 1.13px;line-height: 100%;font-weight: 400;padding-bottom: 2%;">CUSTOMER</label>
            <label style="color: black;font-size: 140%;margin-top: 3%">{{dc_name}}</label>
<!--            <input [disabled]="true"  type="text" class="form-control" style="width: 107% !important;padding: 1% 10%;margin-top: -1%;" [(ngModel)]="dc_name" />-->
          </div>
          <div class="custom-input" style="margin-top: 2%;margin-left: 4%;display: inline-block;">
            <label>DELIVERY TO</label>
            <br/><label style="color: black;font-size: 175%;margin-top: 3%">{{dc_name}}</label>
<!--            <input [disabled]="true" type="text" class="form-control" style="width: 107% !important;padding: 1% 10%;margin-top: -1%;" [(ngModel)]="dc_name" />-->
          </div>
          <div *ngIf="sharedService.new || sharedService.is_return" class="custom-input" style="margin-top: 2%;margin-left: 4%;display: inline-block;">
            <label>NOTE</label>
            <input type="text" class="form-control" style="width: 115% !important;padding: 1% 10%;margin-top: -1%;" [(ngModel)]="note" />
          </div>
          <div *ngIf="!sharedService.new && !sharedService.is_return" class="custom-input" style="margin-top: 2%;margin-left: 4%;display: inline-block;">
            <label>NOTE</label>
            <input [disabled]="true"  type="text" class="form-control" style="width: 115% !important;padding: 1% 10%;margin-top: -1%;" [(ngModel)]="note" />
          </div>
        </ul>
        <ul style="margin-top: -6%;margin-left: -2%;margin-bottom: 40px;">
          <div [hidden]="!sharedService.new" style="width: 15%;font-size: 130%;font-weight: 500;display: inline-block;margin-left: 4%;;">
            <label style="color: #b0bac9;font-size: 85%;letter-spacing: 1.13px;line-height: 100%;font-weight: 400;padding-bottom: 2%;">SUPPLIER</label>
            <ng-template adHost1></ng-template>
          </div>
          <div [hidden]="sharedService.new" class="custom-input" style="width: 15%;font-size: 130%;font-weight: 500;display: inline-block;margin-left: 4%;">
            <label style="color: #b0bac9;font-size: 85%;letter-spacing: 1.13px;line-height: 100%;font-weight: 400;padding-bottom: 2%;">SUPPLIER</label>
            <br/><label style="color: black;font-size: 125%;margin-top: 3%">{{supplier_name}}</label>
<!--            <input [disabled]="true"  type="text" class="form-control" style="width: 107% !important;padding: 1% 10%;margin-top: -1%;" [(ngModel)]="supplier_name" />-->
          </div>
          <div *ngIf="supplier_mail" class="custom-input" style="margin-top: 2%;margin-left: 4%;display: inline-block;
          margin-bottom: 0px">
            <label>SUPPLIER EMAIL</label>
            <br/><label style="color: black;font-size: 125%;margin-top: 3%">{{supplier_mail}}</label>
<!--            <input [disabled]="true" type="text" class="form-control" style="width: 107% !important;padding: 1% 10%;margin-top: -1%;" [(ngModel)]="supplier_mail" />-->
          </div>
          <div *ngIf="sharedService.is_receipt" class="custom-input" style="margin-top: 2%;margin-left: 4%;display: inline-block;margin-bottom: 0px">
            <label>INVOICE NO</label>
            <input [disabled]="true" type="text" class="form-control" style="width: 115% !important;padding: 1% 10%;margin-top: -1%;" [(ngModel)]="invoice_no" />
          </div>
          <div *ngIf="sharedService.is_return" class="custom-input" style="margin-top: 2%;margin-left: 4%;display: inline-block;margin-bottom: 0px">
            <label>INVOICE NO</label>
            <input type="text" class="form-control" style="width: 115% !important;padding: 1% 10%;margin-top: -1%;" [(ngModel)]="invoice_no" />
          </div>
          <div *ngIf="(sharedService.is_return || sharedService.is_receipt) && parent_po" class="custom-input" style="margin-top: 2%;
          margin-left: 4%;display: inline-block;margin-bottom: 0px">
            <label style="font-size: 135%;">PO</label>
            <label style="width: 115% !important;padding: 1% 10%;margin-top: -1%;margin-left: -9%;
    color: black;
    font-size: 140%;">{{parent_po}}</label>
          </div>
<!--          <label style="position: relative;left: 6%;bottom: 3.5vh;color: #b0bac9;font-size: 115%;letter-spacing: 1.13px;line-height: 100%;font-weight: 400;padding-bottom: 2%;">EXPIRY DATE</label>-->
<!--          <div *ngIf="sharedService.new && !sharedService.is_return" class="date-picker" style="display: inline-block;margin-left: 1%;">-->
<!--            <span class="datepicker-icon"><img src="../../assets/date.png"></span>-->
<!--            <input style="height: 35px;width: 80% !important;;background-color: '#e0e7ff';" [readonly]="true" [(ngModel)]="expiry_date" [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1" placeholder="Date">-->
<!--            <owl-date-time (afterPickerClosed)="changeExpiryDate(dt1)" [pickerType]="'calendar'" #dt1></owl-date-time>-->
<!--          </div>-->
<!--          <label style="position: relative;left: 6%;bottom: 4vh;color: #b0bac9;font-size: 115%;letter-spacing: 1.13px;line-height: 100%;font-weight: 400;padding-bottom: 2%;">DELIVERY DUE</label>-->
<!--          <div [hidden]="!sharedService.new" class="date-picker" style="display: inline-block;margin-left: -1%;">-->
<!--            <span class="datepicker-icon"><img src="../../assets/date.png"></span>-->
<!--            <input style="height: 35px;background-color: '#e0e7ff';" [readonly]="true" [(ngModel)]="myDate" [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1" placeholder="Date">-->
<!--            <owl-date-time (afterPickerClosed)="changeDate(dt1)" [pickerType]="'calendar'" #dt1></owl-date-time>-->
<!--          </div>-->
<!--          <div [hidden]="sharedService.new" class="custom-input"  style="display: inline-block;margin-left: -1%;">-->
<!--            <input [disabled]="true" type="text" class="form-control" style="height: 35px;background-color: '#e0e7ff';" [(ngModel)]="due_date" />-->
<!--          </div>-->
          <div [hidden]="sharedService.new || sharedService.is_return || sharedService.is_receipt" class="custom-input"  style="display: inline-block;margin-left: 3%;">
            <label>delivery status</label>
            <input [disabled]="true" type="text" class="form-control" style="height: 35px;background-color: '#e0e7ff';" [(ngModel)]="d_status" />
          </div>
          <div [hidden]="sharedService.new || sharedService.is_return" class="custom-input"  style="display: inline-block;margin-left: 3%;">
            <label>status</label>
            <input [disabled]="true" type="text" class="form-control" style="height: 35px;background-color: '#e0e7ff';" [(ngModel)]="state" />
          </div>
        </ul>
        <ul>
          <div [hidden]="!sharedService.new || !prod_show" style="width: 60%;font-size: 130%;font-weight: 500;display: inline-block;">
            <label style="color: #b0bac9;font-size: 85%;letter-spacing: 1.13px;line-height: 100%;font-weight: 400;padding-bottom: 2%;">PRODUCT</label>
            <ng-template style="position:relative;top:20px;" adHost2></ng-template>
          </div>
<!--          <div [hidden]="!sharedService.new || !prod_show" class="custom-input" style="margin-bottom: 0%;margin-left: 4%;display: inline-block;">-->
<!--            <label>Qty</label>-->
<!--            <input type="text" class="form-control" style="width: 50%;padding: 1% 10%;margin-top: -1%;" [(ngModel)]="entered_qty" />-->
<!--          </div>-->
<!--          <div [hidden]="!sharedService.new || !prod_show" class="custom-input" style="margin-bottom: 0%;margin-left: 4%;display: inline-block;">-->
<!--            <label>Price</label>-->
<!--            <input type="text" class="form-control" style="width: 50%;padding: 1% 10%;margin-top: -1%;" [(ngModel)]="price" />-->
<!--          </div>-->
          <div [hidden]="!sharedService.new || !prod_show">
            <button (click)="saveProduct()" style="position: relative;margin-top: -2.5%;left: 91%;margin-left: -9%;" class="btn confirm-blue-btn">ADD</button>
            <img title="Upload Products" style="position: relative;margin-top: -2.5%;left: 91%;margin-left: 1%; cursor:pointer;"
                 (click)="openfileUploadDialog()"
                 src="./../../assets/upload.svg"/>
          </div>
<!--          <div [hidden]="!sharedService.new || !prod_show">-->
<!--            <button (click)="removeProduct()" style="position: relative;margin-top: -4.5%;left: 91%;margin-left: -1%;" class="btn confirm-blue-btn">REMOVE</button>-->
<!--          </div>-->
          <!--          <div [hidden]="!sharedService.new">-->
          <!--            <button (click)="addProduct()" style="position: relative;margin-top: -2.5%;left: 91%;margin-left: -1%;" class="btn confirm-blue-btn">Add Product</button>-->
          <!--          </div>-->
        </ul>
        <ul>
          <div class="eod-table">
            <div class="dms_table">
              <ag-grid-angular id="myGrid1" *ngIf="loaded" style="width: 100%;" class="ag-theme-balham"
                               [columnDefs]="columnDefs" [gridOptions]="gridOptions"  (gridReady)="onGridReady($event)"
                               [frameworkComponents]="frameworkComponents" [context]="context">
              </ag-grid-angular>
              <div *ngIf="preloader">
                <app-loadingstate></app-loadingstate>
              </div>
              <div *ngIf="empty_state">
                <app-emptystate></app-emptystate>
              </div>
            </div>
          </div>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="approve-modal modal" id="serialNoModel" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <span class="close" data-dismiss="modal">
        <img src="../../assets/close.png">
      </span>
      <div class="modal-body">
        <div class="approve-modal-heading">
          <label>ENTER SERIAL NO</label>
        </div>
        <div class="approve-modal-content">
          <div #serialIn *ngFor="let ser_no of serl_arr">
            <input [disabled]="!sharedService.new && !sharedService.is_return" type="text" class="form-control" style="width: 90% !important;padding: 1% 10%;margin-top: 5%;" [(ngModel)]="ser_no.no" />
          </div>
          <div *ngIf="sharedService.is_return" style="display: inline-block;margin-top: 6%;" data-dismiss="modal">
            <button (click)="confirmSerial()" class="btn confirm-blue-btn">CONFIRM</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<button #serial id="openImage" [hidden]="true" data-toggle="modal" data-target="#serialNoModel">Open Modal</button>

