import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {ErrorHandler} from "../../error/error-handler";

@Component({
  selector: 'app-influencer-master-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class InfluencerMasterReportComponent extends ReportBase implements IReport, OnInit {

  public attributeTypes = [];
  showCustomFilter = true;
  customFilter: any = {
    name: 'Active',
    key: 'active',
    value: 'true',
    values: [],
    show: true
  };
  apiData: any = [];
  uqTypes;
  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Influencer Master Report');

    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.showDate = false;
    this.loadInBatches(10000, 'i_id');
    this.showProgressCustomText = true;
    this.customFilter.values = [
      {id: '', itemName: 'All'},
      {id: 'true', itemName: 'Active'},
      {id: 'false', itemName: 'Inactive'}
    ];
    this.API_URL = '/api/pwa_reports/getInfluencerMasterReport';
  }

  configureGrid() {
    const inThis = this;
    this.columnDefs =  [
      { headerName: "",
        children: [
                  {
                    headerName: 'REGION', field: 'region', enableRowGroup: true,
                    valueGetter(params) {
                      if (params.data) {
                        if (params.data.region) {
                          return params.data.region;
                        } else {
                          return 'NA';
                        }
                      }
                    }
                  },
                  {
                    headerName: 'CLUSTER', field: 'cluster', enableRowGroup: true,
                    valueGetter(params) {
                      if (params.data) {
                        if (params.data.cluster) {
                          return params.data.cluster;
                        } else {
                          return 'NA';
                        }
                      }
                    }
                  },
                  {headerName: 'ID', field: 'i_id', width: 80},
                  {headerName: 'CODE', field: 'i_code', width: 80},
                  {headerName: 'NAME', field: 'i_name', enableRowGroup: true, width: 100},
                  {headerName: 'MOBILE', field: 'mobile', enableRowGroup: true, width: 100},
                  {headerName: 'PAN', field: 'pan', enableRowGroup: true, width: 100},
                  {headerName: 'TYPE', field: 'i_type', enableRowGroup: true, width: 80},
                  {headerName: 'CHANNEL', field: 'channel', enableRowGroup: true, hide: true,  width: 80},
                  {headerName: 'PROGRAM', enableRowGroup: true, field: 'program', hide: true, width: 100},
                  {headerName: 'ADDRESS', field: 'i_address', enableRowGroup: true, width: 80},
                  {headerName: 'CITY', field: 'i_city', enableRowGroup: true, width: 60},
                  {headerName: 'ZIP', field: 'i_zip', enableRowGroup: true, width: 60},
                  {headerName: 'STATE', field: 'state', enableRowGroup: true, width: 80},
                  {headerName: 'DISTRICT', field: 'zone', enableRowGroup: true, width: 80},
                  {headerName: 'STATUS', field: 'status', enableRowGroup: true, width: 80},
                  {headerName: 'CREATED ON', field: 'created_on', enableRowGroup: true, width: 100},
                  {headerName: 'CREATED BY', field: 'create_name', enableRowGroup: true, width: 60},
                  {headerName: 'LAST MODIFIED', field: 'writed_on', enableRowGroup: true, width: 80},
                  {headerName: 'LAST MODIFIED BY', field: 'write_name', enableRowGroup: true, width: 80},
                  {headerName: 'TCODE', field: 'territory_code', enableRowGroup: true, width: 60},
                  {headerName: 'TERRITORY', field: 'territory', enableRowGroup: true, width: 60},
                  {headerName: 'TERRITORY MANAGER', field: 'territory_manager', enableRowGroup: true, width: 60},
                  {headerName: 'OTP VERIFIED', field: 'otp_verified', hide: true, width: 50},
                  {headerName: 'VERIFIED', field: 'is_verified', hide: true, width: 50},
                  {headerName: 'R ID', field: 'r_id', hide: true, valueGetter: this.RetailerIDGetter, width: 100},
                  {headerName: 'R CODE', field: 'r_code', hide: true, valueGetter: this.RetailerCodeGetter, width: 100},
                  {headerName: 'RETAILERS', field: 'retailers', hide: true, valueGetter: this.RetailerNameGetter, width: 300}
       ]
      }
    ];
    if (this.uqTypes) {
      let headercount = 1;
      for (const params in this.uqTypes) {
        this.columnDefs.push(
          {
            headerName: params,
            children: []
          }
        );
        for (const key of this.uqTypes[params]) {
          this.columnDefs[headercount].children.push(
            {headerName: key, field: params + '_' + key, width: 80}
          );
        }
        headercount++;
      }
    }
  }

  RetailerCodeGetter(params) {
    let res = '';
    if (params.data && params.data.retailers) {
      let i = 0;
      params.data.retailers.forEach(obj => {
        res += obj.code;
        i++;
        if (i < params.data.retailers.length) {
          res += ',';
        }
      });
    }
    return res;
  }

  RetailerIDGetter(params) {
    let res = '';
    if (params.data && params.data.retailers) {
      let i = 0;
      params.data.retailers.forEach(obj => {
        res += obj.id;
        i++;
        if (i < params.data.retailers.length) {
          res += ',';
        }
      });
    }
    return res;
  }

  RetailerNameGetter(params) {
    let res = '';
    if (params.data && params.data.retailers) {
      let i = 0;
      params.data.retailers.forEach(obj => {
        res += obj.name;
        i++;
        if (i < params.data.retailers.length) {
          res += ',';
        }
      });
    }
    return res;
  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }


  configureData() {
    this.uqTypes = [];
    this.apiData.forEach(obj => {
      if (obj.params) {
        if (obj.params.constructor !== Object) {
          try {
            obj.params = JSON.parse(obj.params);
          } catch (e) {
            console.log(obj);
          }
        }
        if (obj.params && obj.params.length > 0) {
          for (const params of obj.params) {
            if (!params.s) {
              params.s = " ";
            }
            if (!this.uqTypes[params.s]) {
              this.uqTypes[params.s] = new Set();
            }
            this.uqTypes[params.s].add(params.n);
            obj[params.s + '_' + params.n] = params.v;
          }
        }

        if (obj.params.params && obj.params.params.length > 0) {
          for (const params of obj.params.params) {
            if (!params.s) {
              params.s = " ";
            }
            if (!this.uqTypes[params.s]) {
              this.uqTypes[params.s] = new Set();
            }
            this.uqTypes[params.s].add(params.n);
            obj[params.s + '_' + params.n] = params.v;
          }
        }
      }
    });
    this.rowData = this.apiData;
    // console.log(this.rowData);
    this.configureGrid();
  }
}
