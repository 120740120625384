import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class DailyUserPerformanceReportComponent extends ReportBase implements IReport, OnInit {

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    // this.showableFilters.salesman = true;
    // this.showableFilters.state = true;
    // this.showableFilters.region = true;
    // this.showableFilters.team = true;
    const date = new Date();
    date.setDate(date.getDate() - 7);
    this.myDate[0] = this.datePipe.transform(new Date(date), 'yyyy-MM-dd');
    this.userData.start_date = this.myDate[0];
    this.sharedService.setHeader('Daily User Performance Report');
    this.API_URL = '/api/pwa_reports/getDailyUserPerformance';
  }

  configureGrid() {
    let inThis = this;
    this.columnDefs = [
      {headerName: 'DATE', field: 'date', width: 80},
      {headerName: 'STATE', field: 'state', width: 80},
      {headerName: 'TEAM', field: 'team', width: 80},
      {headerName: 'PARENT TEAM', field: 'parent_team', width: 80},
      {headerName: 'REPORTING MANAGER', field: 'reporting_manager', width: 80},
      {headerName: 'EMP CODE', field: 'emp_code', width: 80},
      {headerName: 'USER', field: 'user_name', width: 120},
      {headerName: 'ROLE', field: 'user_role', width: 80},
      {
        headerName: 'ATT TIME', field: 'att_time', width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.att_time) {
              return inThis.datePipe.transform((new Date(params.data.att_time + ' UTC')).toString(), 'HH:mm:ss');
            } else {
              return params.data.start_time;
            }
          } else {
            return '';
          }
        }
      },
      {
        headerName: 'EOD TIME', field: 'eod_time', width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.eod_time) {
              return inThis.datePipe.transform((new Date(params.data.eod_time + ' UTC')).toString(), 'HH:mm:ss');
            } else {
              return params.data.eod_time;
            }
          } else {
            return '';
          }
        }
      },
      {
        headerName: 'ATT LOC', field: 'att_loc', width: 80,
        valueGetter: this.generateLocationUrl('latitude', 'longitude'),
        cellRenderer: this.locationIconRenderer('latitude', 'longitude')
      },
      {
        headerName: 'FIRST ACT LOC', field: 'first_act_loc', width: 80,
        valueGetter: this.generateLocationUrl('first_lat', 'first_long'),
        cellRenderer: this.locationIconRenderer('first_lat', 'first_long')
      },
      {
        headerName: 'LAST ACT LOC', field: 'last_act_loc', width: 80,
        valueGetter: this.generateLocationUrl('last_lat', 'last_long'),
        cellRenderer: this.locationIconRenderer('last_lat', 'last_long')
      },
      {
        headerName: "FIRST ACT", field: "start_time", width: 120,
        valueGetter: params => {
          if (params.data) {
            if (params.data.start_time) {
              return inThis.datePipe.transform((new Date(params.data.start_time + ' UTC')).toString(), 'HH:mm:ss');
            } else {
              return params.data.start_time;
            }
          } else {
            return '';
          }
        }
      },
      {
        headerName: "LAST ACT", field: "end_time", width: 120,
        valueGetter: params => {
          if (params.data) {
            if (params.data.end_time) {
              return inThis.datePipe.transform((new Date(params.data.end_time + ' UTC')).toString(), 'HH:mm:ss');
            } else {
              return params.data.end_time;
            }
          } else {
            return '';
          }
        }
      },
      {
        headerName: 'TIME SPENT', field: 'time_spent', enableRowGroup: true, width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.end_time && params.data.start_time) {
              const diff = new Date(params.data.end_time + ' UTC').getTime() - new Date(params.data.start_time + ' UTC').getTime();
              //return (diff / (1000 * 60)).toFixed(2);
              let msec = diff;
              const hh = Math.floor(msec / 1000 / 60 / 60);
              msec -= hh * 1000 * 60 * 60;
              const mm = Math.floor(msec / 1000 / 60);
              msec -= mm * 1000 * 60;
              const ss = Math.floor(msec / 1000);
              msec -= ss * 1000;

              return ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2);
            } else {
              return 0;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: 'SCHEDULE', field: 'sch_count', width: 80},
      {headerName: 'BEAT UNIVERSE', field: 'rc', width: 80},
      {headerName: 'TC', field: 'tc', width: 80},
      {headerName: 'PC', field: 'pc', width: 80},
      {headerName: 'TARGET', field: 'schedule_target', width: 80},
      {headerName: 'BEAT REVENUE', field: 'beat_revenue', width: 80},
      {headerName: 'VISITS', field: 'visited_count', width: 80},
      {headerName: 'SEC TARGET', field: 'sec_target', width: 80},
      {
        headerName: 'PRIMARY SALES',
        children: [
          {headerName: 'ORDER', field: 'p_order', width: 80},
          {headerName: 'SALES', field: 'p_sales', width: 80},
          {headerName: 'TLS', field: 'p_sku_count', width: 80},
          {headerName: 'ULS', field: 'p_uq_sku', width: 80},
          {headerName: 'UQ PC', field: 'p_uq_pc', width: 80},
        ]
      },
      {
        headerName: 'SECONDARY SALES',
        children: [
          {headerName: 'ORDER', field: 's_order', width: 80},
          {headerName: 'SALES', field: 's_sales', width: 80},
          {headerName: 'TLS', field: 's_sku_count', width: 80},
          {headerName: 'ULS', field: 's_uq_sku', width: 80},
          {headerName: 'UQ PC', field: 's_uq_pc', width: 80},
        ]
      },
    ];
    if (this.apiData.kpi && this.apiData.kpi.length > 0) {
      const uniquekpis = this.unique(this.apiData.kpi, ['name']);
      this.columnDefs.push({
          headerName: 'KPI',
          children: []
        },
      );
      for (const kpi of uniquekpis) {
        this.columnDefs[25].children.push(
          {
            headerName: kpi['name'], field: kpi['name'], width: 80
          },
        );
      }
    }
  }

  postProcessData() {
    this.configureGrid();
    this.configureData();
  }

  configureData() {
    this.rowData = [];
    let masterdata = this.apiData.master;
    const kpidata = this.apiData.kpi;
    if (masterdata && masterdata.length > 0) {
      for (const item of masterdata) {
        let indrow = {};
        indrow = item;
        if (kpidata && kpidata.length > 0) {
          const kpifound = kpidata.filter(x => x.salesman_id === item.salesman_id && x.date === item.date);
          if (kpifound && kpifound.length > 0) {
            for (const kpi of kpifound) {
              indrow[kpi.name] = kpi.value;
            }
          }
        }
        this.rowData.push(indrow);
      }
    }
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    if (column.includes('loc')) {
      if (event.value !== null && event.value !== '') {
        window.open(event.value, '_blank');
      }
    }
  }
}
