<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" style="float: right;background-color:#FDE0D4;">
        <i class="fas fa-times"></i>
      </button>
      <h2 *ngIf="teamObject == null">Create Team</h2>
      <h2 *ngIf="teamObject != null">Edit Team</h2>
    </div>
    <div class="body-section">
      <div>
        <form novalidate  id="ngForm" #createeditteamform="ngForm">
          <div style="margin-top: 15px;">
            <label >TEAM MANAGER</label>
            <div style="margin-top: 5px;" class="formselect">
              <select
                name="user_id"
                [(ngModel)]="teamObject.user_id"
                #user_id="ngModel"
                style="width: 90%;"
                (change)="onTeamLeaderChange($event.target)"
                required >
                <option
                  *ngFor="let item of salesmenList"
                  value="{{ item.id }}"
                > {{ item.itemname }}</option>
              </select>
              <div *ngIf="user_id.errors && (user_id.dirty || user_id.touched)">
                <span *ngIf="user_id.errors.required" class="text-danger">Manager is required</span>
              </div>
            </div>
          </div>
          <div style="margin-top: 15px;">
            <label >TEAM NAME</label>
            <div style="margin-top: 5px;">
                <input
                  class="forminput"
                  type="text"
                  name="name"
                  [(ngModel)]="teamObject.name"
                  #name="ngModel"
                  style="width: 90%;"
                  required
                >
                <div *ngIf="name.errors && (name.dirty || name.touched)">
                  <span *ngIf="name.errors.required" class="text-danger">Name is required</span>
                </div>
            </div>
          </div>
          <div style="margin-top: 15px;">
            <label >TEAM CODE</label>
            <div style="margin-top: 5px;">
              <input
                class="forminput"
                type="text"
                name="code"
                [(ngModel)]="teamObject.code"
                #code="ngModel"
                style="width: 90%;"
                required
              >
              <div *ngIf="code.errors && (code.dirty || code.touched)">
                <span *ngIf="code.errors.required" class="text-danger">Code is required</span>
              </div>
            </div>
          </div>
          <div style="margin-top: 15px;">
            <label >PARENT TEAM</label>
            <div style="margin-top: 5px;" class="formselect">
              <select
                name="parent_id"
                [(ngModel)]="teamObject.parent_id"
                #parent_id="ngModel"
                style="width: 90%;">
                <option
                  *ngFor="let item of teamList"
                  value="{{ item.id }}"
                > {{ item.itemname }}</option>
              </select>
            </div>
          </div>

        </form>
      </div>
      <div class="button-row" style="margin-top: 10px;" >
        <button
          mat-button
          style=" vertical-align:bottom;margin-top: 20px; margin-right: 15px;"
          class="btn teal-btn"
          (click)="saveTeam()"
          form="ngForm"
          [disabled]="createeditteamform.form.invalid"
        >
          SAVE
        </button>
        <span (click)="closeDialog()">
           <a class="btn white-btn">DISCARD</a>
        </span>
      </div>
    </div>
  </div>
</section>
