import {Component, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ApiService} from "../../_services/api.service";

@Component({
  selector: 'app-retailer-reject-popup',
  templateUrl: './retailer_reject_popup.component.html',
  styleUrls: ['./retailer_reject_popup.component.scss']

})

export class RetailerRejectPopupComponent implements OnInit {

  @Output() submit: EventEmitter<any> = new EventEmitter<any>();

  reasonID;
  beatActivityData = [];
  comments;

  constructor(
    public dialog: MatDialog,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    if (localStorage.getItem('beat_activity_types')) {
      this.beatActivityData = JSON.parse(localStorage.getItem('beat_activity_types'));
      if (this.beatActivityData) {
        this.beatActivityData = this.beatActivityData.filter(bat => bat.action_type === 'Retailer Rejection');
      }
    }
  }

  ngOnInit(): void {

  }

  performAction() {

    this.submit.emit({reject_reason: this.reasonID, reject_comments: this.comments});
    this.dialog.closeAll();
  }

  closeDialog() {
    this.dialog.closeAll();
  }

}
