import {PageBase} from "../pagebase/pagebase";
import {Component, OnInit} from "@angular/core";
import {MatDialogConfig} from "@angular/material/dialog";
import {CreateBeatPlanPopupComponent} from "./create_beat_plan_popup/create_beat_plan_popup.component";
import {MoveBeatRetailerPopupComponent} from "./move_beat_retailer_popup/move_beat_retailer_popup.component";
import {RemoveBeatRetailerPopupComponent} from "./remove_beat_retailer_popup/remove_beat_retailer_popup.component";

@Component({
  selector: 'app-beat-plan-list',
  templateUrl: '../pagebase/pagebase.html',
  styleUrls: ['../pagebase/pagebase.scss']
})


export class BeatRetailerListComponent extends PageBase implements OnInit, IPage {

  matActionMenuItemDeclaration = [{name: 'Move Beat Plan Retailers', feature: 'action_beat_retailer_move'},
    {name: 'Copy Beat Plan Retailers', feature: 'action_beat_retailer_move'},
    {name: 'Remove Beat Plan Retailers', feature: 'action_beat_retailer_remove'}
  ];

  ngOnInit(): void {

    this.getAuthorizedActions();
    this.sharedService.setHeader('Beat Retailers');

    this.showableFilters.region = true;
    this.showableFilters.territory = true;
    this.showableFilters.salesman = true;
    this.showableFilters.team = true;

    this.showDate = false;
    this.showCreate = false;
    this.showActionButton = true;
    this.configureGrid();
    this.loadServerData();
  }



  configureGrid() {
    this.API_URL = '/api/beat_plan_retailers/get_beat_plan_retailers_pwa';

    const inthis = this;
    this.columnDefs = [

      {headerName: "BPR ID", field: "bpr_id", width: 40, hide: true},
      {headerName: "ID", field: "r_id", width: 400, checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true},
      {headerName: "CODE", field: "r_code", width: 100},
      {headerName: "OUTLET", field: "r_name", width: 120},
      {headerName: "BEAT ID", field: "bp_id", width: 40, hide: true},
      {headerName: "BEAT", field: "beat", width: 100},
      {headerName: "SALESMAN", field: "user_name", width: 100},
      {headerName: "TERRITORY", field: "territory", width: 80},
      {headerName: "CLUSTER", field: "cluster", width: 80},
      {headerName: "REGION", field: "region", width: 80},
      {headerName: "CLASS", field: "class", width: 30},
      {headerName: "PROGRAM", field: "program", width: 60},
      {headerName: "KEY OUTLET", field: "key_outlet", width: 30},
      {headerName: "VISIT FREQ", field: "vf", width: 30},
      {headerName: "Avg BILL VALUE", field: "abv", width: 30},
      {headerName: "ORDER FREQ", field: "of", width: 30},
      {headerName: "LAST ORDER", field: "lod", width: 30},
      {headerName: "LAST VISITED", field: "last_checkin", width: 30},
      {headerName: "POTENTIAL", field: "potential", width: 40, hide: true},
      {headerName: "MARKET SHARE", field: "ms", width: 30},
      {headerName: "REMOVE REQ", field: "remove", width: 40},
      {headerName: "COMMENT", field: "comment", width: 40},

    ];
  }


  openMoveCopyDialog(rows, uq_ret, uq_bp, uq_ter, type) {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "450px",
      maxHeight: "500px",
      data: {
        type,
        retailerData: rows,
        uq_ret,
        uq_bp,
        uq_ter
      },
      panelClass: 'custom-dialog-container'
    };

    const confirmdiaeref = this.dialog.open(MoveBeatRetailerPopupComponent, config);
    confirmdiaeref.componentInstance.saveData.subscribe(status => {
      if (status === 'success') {
        this.dialog.closeAll();
        if (type === 'Move') {
          this.toastr.success('Retailers Moved to selected Beat Plan');
        } else {
          this.toastr.success('Retailers Copied to selected Beat Plan');
        }
        window.location.reload();
      }
    });
  }


  openRemoveDialog(rows, uq_ret, uq_bp, uq_ter) {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "450px",
      maxHeight: "330px",
      data: {
        type: "Copy",
        retailerData: rows,
        uq_ret,
        uq_bp,
        uq_ter
      },
      panelClass: 'custom-dialog-container'
    };

    const confirmdiaeref = this.dialog.open(RemoveBeatRetailerPopupComponent, config);
    confirmdiaeref.componentInstance.removeData.subscribe(status => {
      console.log('remove reponse : ' + status );
      if (status === 'success') {
        this.dialog.closeAll();
        window.location.reload();
      }
    });
  }

  onMenuItemSelected(menuitem) {
    const rows = this.gridApi.getSelectedRows();
    if (rows === null || rows === undefined || rows.length === 0) {
      this.toastr.error('Please select at least 1 Retailer');
    } else  if (rows.length > 100) {
      this.toastr.error('Please select a maximum of 100 retailers : ' + rows.length + ' selected');
    } else {
      const unique_beat_plan = this.unique(rows, ['beat']).length;
      const unique_territory = this.unique(rows, ['territory']).length;
      const unique_retailers = rows.length;
      if (menuitem.name === 'Move Beat Plan Retailers') {
        this.openMoveCopyDialog(rows, unique_retailers, unique_beat_plan, unique_territory, "Move");
      } else if (menuitem.name === 'Copy Beat Plan Retailers') {
        this.openMoveCopyDialog(rows, unique_retailers, unique_beat_plan, unique_territory, "Copy");
      } else if (menuitem.name === 'Remove Beat Plan Retailers') {
        this.openRemoveDialog(rows, unique_retailers, unique_beat_plan, unique_territory);
      }
    }
  }


}
