import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {GridOptions} from '@ag-grid-enterprise/all-modules';
import {formatDate} from "@angular/common";

@Component({
  selector: 'app-distributor-retailer-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class DistributorRetailerMappingComponent extends ReportBase implements IReport, OnInit  {



  setTitle() {
    throw new Error("Method not implemented.");
  }

  ngOnInit() {

    this.sharedService.setHeader('Distributor Retailer Mapping');

    /*this.showableFilters.salesman = true;
    this.showableFilters.team = true;*/
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.showDate = false;

    this.gridOptions.autoGroupColumnDef = {
      headerName: 'Retailer Name',
      field: 'r_name',
      minWidth: 300,
      cellRenderer: 'agGroupCellRenderer',
      resizable: true
    },

      this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getDistributorRetailerMappingReport';

    this.columnDefs = [
      {
        headerName: "REGION",field: "region",  rowGroup: true, enableRowGroup: true, hide: true,
        valueGetter: function(params) {
          if (params.data)
          {
            if(params.data.region) {
              return params.data.region;
            }
            else {
              return 'NA';
            }
          }
        }
      },
      {  headerName: "CLUSTER",field: "cluster",rowGroup: true, enableRowGroup: true,hide: true,
        valueGetter: function(params) {
          if (params.data)
          {
            if(params.data.cluster) {
              return params.data.cluster;
            }
            else {
              return 'NA';
            }
          }
        }
      },
      { headerName: 'DISTRIBUTOR',

        children: [
          { headerName: "CODE",  field: "dist_code", menuTabs: [], width: 100},
          { headerName: "NAME",  field: "dist_name", enableRowGroup:true, menuTabs: [], width: 100},
        ]},
      { headerName: 'BEAT',

        children: [
          { headerName: "BEAT ID",  field: "beat_id", menuTabs: [], width: 80},
          { headerName: "BEAT NAME",  field: "beat_name", enableRowGroup:true, menuTabs: [], width: 100},
          { headerName: "BEAT CODE",  field: "beat_scode", menuTabs: [], width: 50},
          { headerName: "SALESMAN",  field: "beat_salesman",enableRowGroup:true, menuTabs: [], width: 80},
          { headerName: "TERRITORY",  field: "territory", enableRowGroup:true, menuTabs: [], width: 80},
        ]},
      { headerName: 'RETAILER',

        children: [
          { headerName: "ID",  field: "r_id", menuTabs: [], width: 60},
          { headerName: "CODE",  field: "r_code", menuTabs: [], width: 60},
          { headerName: "NAME",  field: "r_name", enableRowGroup:true, menuTabs: [], width: 120},
          { headerName: "TYPE",  field: "r_type", enableRowGroup:true, menuTabs: [], width: 80},
          { headerName: "CLASS",  field: "class_type", enableRowGroup:true, menuTabs: [], width: 60,
            valueGetter: function (params) {
              if(params.data)
              {
                if(params.data.class_type!=null)
                  return params.data.class_type.toUpperCase();
              }
            }
          },
          { headerName: "CITY",  field: "city", enableRowGroup:true, menuTabs: [], width: 80},
          { headerName: "Active",  field: "status", menuTabs: [], width: 60},
          { headerName: "CREATED",  field: "create_date", menuTabs: [], width: 80,
            cellRenderer: (data) => {
              return data.value ? formatDate(new Date(data.value), 'dd/MM/yyyy', 'en-US').substring(0,10):'';
            }
          },
        ]},
    ];

  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllColumns().length;

    params.api.setRowData(this.rowData);
    this.preloader = false;

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(function() {
        if ( noOfColumns < 16 && screen.width > 991 ) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });

    /* if (screen.width > 991) {
       this.gridApi.sizeColumnsToFit();
     } else {
       this.gridColumnApi.autoSizeColumns();
     }*/
    if ( noOfColumns < 16 && screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

}
