import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";

@Component({
  selector: 'po-editor',
  template: `<div class="eod-top" layout="row">` +
             `<span class="element-po"><input [disabled]="disable" type="text" [(ngModel)]="poValue" (ngModelChange)="editCell()" class="custom-inputbox"/></span>` +
             `</div>`
})
export class PoEditRenderer implements ICellRendererAngularComp {
  public params: any;
  public poValue = "";
  public disable = false;

  agInit(params: any): void {
    this.params = params;
    this.poValue = this.params.data['PO'].po;
    if (this.params.data['approved'] == true || this.params.data['state'] == 'cancel') {
      this.disable = true;
    }else {
      this.disable = false;
    }
  }

  public editCell() {
    this.params.data['PO'].po = this.poValue;
  }

  refresh(): boolean {
    return false;
  }
}
