import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {GridOptions} from '@ag-grid-enterprise/all-modules';

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class PromoterVisitReportComponent extends ReportBase implements IReport, OnInit  {

  setTitle() {
    throw new Error("Method not implemented.");
  }

  ngOnInit() {

    this.sharedService.setHeader('Promoter Visit Report');

    //this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    //this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.showableFilters.state = true;
    // this.showDate = false;

    this.gridOptions.autoGroupColumnDef = {
      headerName: 'Promoter',
      field: 'promoter_name',
      minWidth: 250,
      cellRenderer: 'agGroupCellRenderer',
      resizable:true
    };

    this.configureGrid();
      }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/promoterVisitReport';
    let inthis = this;
    this.columnDefs = [
      { headerName: "DATE",  field: "date",
        menuTabs: [], width: 80,hide:true,rowGroup:true, enableRowGroup:true},
      { headerName: "PROMOTER NAME",  field: "promoter_name",
        menuTabs: [], width: 150, enableRowGroup:true},
      { headerName: "PROMOTER CODE",  field: "promoter_code",  enableRowGroup:true,
        menuTabs: [], width: 120},

      {
        headerName: "REPORTING MANAGER",

        field: "reporting_manager",
        enableRowGroup:true,
        menuTabs: [],
        width: 150,
      },

      { headerName: "P/L/A",  field: "attendance",
        menuTabs: [], width: 70,aggFunc: this.attavgAggFunction,enableRowGroup:true,
        cellStyle: function(params) {
          if (params.value==='P' || parseInt(params.value)>0) {
            return {textAlign: 'center', backgroundColor: '#B3DFB0'};
          }else if(params.value=='L') {
            return {textAlign: 'center', backgroundColor: '#F0E199'};
          }else if(params.value=='A' || parseInt(params.value)===0) {
            return {textAlign: 'center', backgroundColor: '#F8D1C8'};
          }
        }
      },
      { headerName: "ATT REASON",  field: "att_type", senableRowGroup:true,ortable: true,
        menuTabs: [], width: 80},
      { headerName: "ATT TIME",  field: "att_time",
        menuTabs: [], width: 80,
        valueGetter: function (params) {
          if (params.data) {
            if (params.data.att_time) {
              return inthis.datePipe.transform((new Date(params.data.att_time + ' UTC')).toString(), 'HH:mm:ss');
            } else {
              return params.data.att_time;
            }
          } else {
            return '';
          }
        }},
      { headerName: "EOD TIME",  field: "eod_time",
        menuTabs: [], width: 80,
        valueGetter: function (params) {
          if (params.data) {
            if (params.data.eod_time) {
              return inthis.datePipe.transform((new Date(params.data.eod_time + ' UTC')).toString(), 'HH:mm:ss');
            } else {
              return params.data.eod_time;
            }
          } else {
            return '';
          }
        }
      },
      { headerName: "ACT TYPE",  field: "comment_type",
        menuTabs: [], width: 100, enableRowGroup: true},
      { headerName: "ACT TIME",  field: "check_in",
        menuTabs: [], width: 80,
        valueGetter: function (params) {
          if (params.data) {
            if (params.data.check_in) {
              return inthis.datePipe.transform((new Date(params.data.check_in + ' UTC')).toString(), 'HH:mm:ss');
            } else {
              return params.data.check_in;
            }
          } else {
            return '';
          }
        }},
      { headerName: "CHECK OUT",  field: "check_out",
        menuTabs: [], width: 80,
        valueGetter: function (params) {
          if (params.data) {
            if (params.data.check_out) {
              return inthis.datePipe.transform((new Date(params.data.check_out + ' UTC')).toString(), 'HH:mm:ss');
            } else {
              return params.data.check_out;
            }
          } else {
            return '';
          }
        }},
      { headerName: "WORKED MINS",  field: "check_out",
        menuTabs: [], width: 80,aggFunc: this.avgAggFunction,
        valueGetter: function (params) {
          if (params.data) {
            if (params.data.check_out && params.data.check_in) {
              let diff = new Date(params.data.check_out + ' UTC').getTime() - new Date(params.data.check_in + ' UTC').getTime();
              return (diff / (1000 * 60)).toFixed(2);
            } else {
              return 0;
            }
          } else {
            return '';
          }
        }
      },
      { headerName: "OUTLET CODE",  field: "code",
        menuTabs: [], width: 100},
      { headerName: "OUTLET NAME",  field: "outlet", enableRowGroup:true,
        menuTabs: [], width: 200},
      { headerName: "CITY",  field: "city", enableRowGroup:true,
        menuTabs: [], width: 100},
      { headerName: "STATE",  field: "state", enableRowGroup:true,
        menuTabs: [], width: 100},
      {
        headerName: "REGION",

        field: "region",
        enableRowGroup:true,
        menuTabs: [],
        width: 80,
      },
      {
        headerName: "REGION MANAGER",

        field: "region_manager",
        enableRowGroup:true,
        menuTabs: [],
        width: 100,
      },
      {
        headerName: "CLUSTER",

        field: "cluster",
        enableRowGroup:true,
        menuTabs: [],
        width: 100,
      },
      {
        headerName: "CLUSTER MANAGER",

        field: "cluster_manager",
        enableRowGroup:true,
        menuTabs: [],
        width: 100,
      },
      {
        headerName: "TERRITORY CODE",
        field: "territory_code",
        enableRowGroup:true,
        menuTabs: [],
        width: 100,
      },
      {
        headerName: "TERRITORY",
        field: "territory",
        enableRowGroup:true,
        menuTabs: [],
        width: 100,
      },
      {
        headerName: "TERRITORY MANAGER",
        field: "territory_manager",
        enableRowGroup:true,
        menuTabs: [],
        width: 100,
      },
      {
        headerName: "STATUS",
        field: "status",
        enableRowGroup:true,
        menuTabs: [],
        width: 100,
      }
      ];

  }

  attavgAggFunction(params) {
    // the average will be the sum / count
    var sum = 0;
    var count = 0;
    params.values.forEach(function (value) {
      var groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        // we are aggregating groups, so we take the
        // aggregated values to calculated a weighted average
        sum += value.avg * value.count;
        count += value.count;
      } else {
        // skip values that are not numbers (ie skip empty values)
        if (value==='P') {
          sum += 1;
          count++;
        }
      }
    });
    // avoid divide by zero error
    if (count !== 0) {
      var avg = sum / count;
    } else {
      avg = null;
    }

    avg =  Math.round(Math.round((avg + Number.EPSILON) * 100) / 100);

    // the result will be an object. when this cell is rendered, only the avg is shown.
    // however when this cell is part of another aggregation, the count is also needed
    // to create a weighted average for the next level.
    var result = {
      count: count,
      avg: avg,
      // the grid by default uses toString to render values for an object, so this
      // is a trick to get the default cellRenderer to display the avg value
      toString: function () {
        return sum;
      },
    };

    return result;

  }

  avgAggFunction(params) {
    // the average will be the sum / count
    var sum = 0;
    var count = 0;

    params.values.forEach(function (value) {
      var groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        // we are aggregating groups, so we take the
        // aggregated values to calculated a weighted average
        sum += value.avg * value.count;
        count += value.count;
      } else {
        // skip values that are not numbers (ie skip empty values)
        if (typeof value === 'number') {
          sum += value;
          count++;
        }
      }
    });
    // avoid divide by zero error
    if (count !== 0) {
      var avg = sum / count;
    } else {
      avg = null;
    }

    avg =  Math.round(Math.round((avg + Number.EPSILON) * 100) / 100);

    // the result will be an object. when this cell is rendered, only the avg is shown.
    // however when this cell is part of another aggregation, the count is also needed
    // to create a weighted average for the next level.
    var result = {
      count: count,
      avg: avg,
      // the grid by default uses toString to render values for an object, so this
      // is a trick to get the default cellRenderer to display the avg value
      toString: function () {
        return this.avg;
      },
    };

    return result;
  }

}
