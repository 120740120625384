<div class="center">
  <span class="titletext">{{title}}</span>
  <p class="messagetext1">{{message1}}</p>
  <p class="messagetext2">{{message2}}</p>
  <div  class="button-row" style="margin-top: 35px;">
     <span *ngIf="showNoButton">
        <a class="btn grey-btn" style="margin-right:30px;" (click)="performAction('no')">{{noButton}}</a>
     </span>
    <button
      *ngIf="!deleteButton"
      mat-button
      class="btn confirm-blue-btn"
      (click)="performAction('yes')"
    >
      {{yesButton}}
    </button>
    <button
      *ngIf="deleteButton"
      mat-button
      class="btn red-btn"
      (click)="performAction('yes')"
    >
      {{yesButton}}
    </button>
  </div>
</div>
