import {Component, OnInit} from "@angular/core";
import {formatDate} from "@angular/common";
import {MatDialogConfig} from "@angular/material/dialog";
import {PageBase} from "../pagebase/pagebase";
import {AssetApprovePopupComponent} from "./asset_approve_popup/asset_approve_popup.component";
import {ConfirmDialogComponent} from "../components/confirmdialog/confirmdialog.component";
import {PartialRejectPopupComponent} from "../components/partial_reject_popup/partial_reject_popup.component";
import {AssetUploadComponent} from "./asset_upload.component";

@Component({
  selector: 'app-assets-request-component',
  templateUrl: '../pagebase/pagebase.html',
  styleUrls: ['../pagebase/pagebase.scss']
})
export class AssetsRequestComponent extends PageBase implements OnInit {

  public show_approve = true;
  showCustomFilter = true;
  customFilter: any = {
    name: 'Status',
    key: 'type',
    value: 'pending',
    values: [],
    show: true
  };
  role_features = [];
  enableApprove = false;
  enableReview = false;
  attributeList: any;
  uqAttributes: any = [];
  beatActivityData: any = [];
  ngOnInit() {
    this.sharedService.setHeader('Request Assets');
    const date = new Date();
    date.setMonth(date.getMonth() - 3);
    this.myDate[0] = this.datePipe.transform(date, 'yyyy-MM-dd');
    const date1 = new Date();
    date1.setDate(date1.getDate() + 1);
    this.myDate[1] = this.datePipe.transform(date1, 'yyyy-MM-dd');
    //this.myDate[1] = '2021-07-10';
    this.userData.start_date = this.myDate[0];
    this.userData.end_date = this.myDate[1];

    this.showableFilters.region = true;
    this.showableFilters.cluster = true;
    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.outlet_type = true;


    this.customFilter.values = [
      {id: "", itemName: "All"},
      {id: "pending", itemName: "Pending"},
      {id: "approved", itemName: "Approved"},
      {id: "rejected", itemName: "Rejected"},
    ];

    this.showBaseUpload = this.sharedService.ACLcheck('feature_request_asset_upload');
    this.enableApprove = this.sharedService.ACLcheck('feature_approve_asset');
    this.enableReview = this.sharedService.ACLcheck('feature_review_asset');

    if (this.enableApprove) {
      this.showApproveBtn = false;
    }

    if (this.enableReview) {
      this.showApproveBtn = true;
    }

    this.showViewSelector = true;
    this.showGalleryview = true;
    this.container = 'partner_asset';
    this.galleryData = {
      pageSize: 50,
      id: 'id',
      image: 'asset_store_fname',
      name: 'asset_name',
      field1: 'asset_type',
      field2: '',
      field3: 'comments',
      status: 'status',
      statusTypes: [
        {name: "new", type: "new"},
        {name: "pending", type: "pending"},
        {name: "approved", type: "completed"},
        {name: "rejected", type: "error"}
      ],
      reviewed: 'status',
      reviewTypes: {
        yes: 'approved',
        no: 'rejected'
      },
      container: this.container,
      selectionCriteria: [
        {
          field: 'status',
          matches: true,
          values: ['pending']
        }
      ]
    };
    const inThis = this;
    this.gridOptions.isRowSelectable = params => {
      if (inThis.enableApprove || inThis.enableReview) {
        return true;
      } else {
        return false;
      }
    };
    this.API_URL = '/api/partner_assets/get_asset_request';

    this.statusConfig.success = 'approved';
    this.statusConfig.warning = 'pending';
    this.statusConfig.error = 'rejected';
    this.statusConfig.new = 'confirmed';

    if (localStorage.getItem('beat_activity_types') != null) {
      this.beatActivityData = JSON.parse(localStorage.getItem('beat_activity_types'));
    }
    this.beatActivityData = this.beatActivityData.filter(bat => bat.action_type === 'Asset');
    this.loadServerData();
  }

  configureGrid() {
    //this.getUniqueAttributes();
    this.columnDefs = [
      {
        headerName: '',
        children: [
          {
            headerName: "ID", headerClass: 'myagheader', field: "id", width: 80,
            headerCheckboxSelection: (this.enableApprove || this.enableReview),
            headerCheckboxSelectionFilteredOnly: (this.enableApprove || this.enableReview),
            checkboxSelection: params => {
              if (params.data) {
                if (params.context.componentParent.enableApprove || params.context.componentParent.enableReview) {
                  return true;
                } else {
                  return false;
                }
              }
            }
          },
          {headerName: "STATUS", headerClass: 'myagheader', field: "status", width: 80, cellRenderer: this.sharedService.statusCellRenderer},
          {headerName: "SALESMAN", headerClass: 'myagheader', field: "salesman", width: 80},
          {headerName: "EMP CODE", headerClass: 'myagheader', field: "scode", width: 80},
          {headerName: "EMP MOBILE", headerClass: 'myagheader', field: "user_mobile", width: 80, hide: true},
          {headerName: "CLASS", headerClass: 'myagheader', field: "class_type", width: 80},
          {headerName: "OUTLET TYPE", headerClass: 'myagheader', field: "out_type", width: 80},
          {headerName: "PARTNER TYPE", headerClass: 'myagheader', field: "partner_type", width: 80},
          {headerName: "MSL TOWN TIER", headerClass: 'myagheader', field: "msl_town_tier", width: 80},
          {headerName: "PARTNER NAME", headerClass: 'myagheader', field: "retailer", width: 120},
          {headerName: "PARTNER CODE", headerClass: 'myagheader', field: "rcode", width: 80},
          {headerName: "STREET", headerClass: 'myagheader', field: "street", width: 80, hide: true},
          {headerName: "AREA", headerClass: 'myagheader', field: "area", width: 80, hide: true},
          {headerName: "DISTRICT", headerClass: 'myagheader', field: "district", width: 80, hide: true},
          {headerName: "ZIP", headerClass: 'myagheader', field: "zip", width: 50, hide: true},
          {headerName: "MOBILE", headerClass: 'myagheader', field: "mobile", width: 80, hide: true},
          {
            headerName: "CREATE DATE", headerClass: 'myagheader', field: "create_date", width: 60,
            cellRenderer: (params) => {
              if (params.data) {
                return params.value ? formatDate(new Date(params.value), 'dd/MM/yyyy', 'en-US').substring(0, 10) : '';
              }
            }
          },
          {headerName: "ASSET NAME", headerClass: 'myagheader', field: "asset_name", width: 100},
          {headerName: "ASSET", headerClass: 'myagheader', field: "asset_type", width: 100},
          {
            headerName: "HAS PHOTO", headerClass: 'myagheader', field: "url", width: 50,
            valueGetter: this.generateImageUrl('asset_store_fname'),
            cellRenderer(params) {
              if (params.data.asset_store_fname === null || params.data.asset_store_fname === "") {
                return '<img style="padding:2px;" src="../../assets/images/bill-disable.png"></img>';
              } else {
                return '<img style="padding:2px;" src="../../assets/images/bill.png"></img>';
              }
            }
          },
          {headerName: "QTY", field: "qty", width: 40},
          {headerName: "APPROVED QTY", field: "approved_qty", width: 40},
          {
            headerName: "REVIEWED", headerClass: 'myagheader', field: "reviewed", width: 40,
            valueGetter(params) {
              if (params.data.reviewed === 'reviewed') {
                return "YES";
              } else {
                return "NO";
              }
            }
          },
          {headerName: "DEPLOYED", field: "deployed", width: 40},
          {headerName: "COMMENTS", headerClass: 'myagheader', field: "comments", resizable: true, width: 100},
        ]
      },
      {
        headerName: 'ATTRIBUTES',

        children: []
      }
    ];

    for (const key of this.uqAttributes) {
      const header = this.sharedService.titleCase(key);
      this.columnDefs[1].children.push(
        {headerName: header, field: 'attr_' + key, width: 80, aggFunc: 'sum'},
      );
    }
  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }

  configureData() {
    this.rowData = [];
    this.uqAttributes = new Set();
    this.apiData.forEach(obj => {
      obj['deployed'] = false;
      if (obj.config_json !== null && obj.config_json !== undefined) {
        for (let [key, value] of Object.entries(obj.config_json)) {
          if (key === 'attribute') {
            for (const [a_key, attribute] of Object.entries(value)) {
              this.uqAttributes.add(a_key);
              obj['attr_' + a_key] = attribute;
            }
          } else {
            this.uqAttributes.add(key);
            if (key.includes('assigned_on')) {
              if (value.toString().includes('Z')) {
                value = value.toString().replace('Z', '');
              }
              if (value.toString().includes('T')) {
                value = value.toString().replace('T', ' ');
              }
              obj['attr_' + key] = this.datePipe.transform((new Date(value + ' UTC')).toString(), 'yyyy-MM-dd HH:mm');
            } else if (key.includes('vendor_assigned_by')) {
              obj['attr_' + key] = obj.vendor_assigned_by;
            } else {
              obj['attr_' + key] = value;
            }
          }
        }
      }
    });
    this.rowData = this.apiData;
    this.filteredData = this.rowData;
  }

  getUniqueAttributes() {
    this.attributeList = new Set();

    this.apiData.forEach(obj => {
      if (obj.config_json !== null && obj.config_json !== undefined && obj.config_json.hasOwnProperty('attribute')
        && obj.config_json.attribute) {
        for (const [key, value] of Object.entries(obj.config_json.attribute)) {
          this.attributeList.add(key);
        }
      }
    });
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    if (column === 'action') {
      const actionType = event.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "approve": {
          //console.log("Edit action clicked");
          this.openConfirmationDialog(event.data, 'approved');
          break;
        }
        case "reject": {

          this.openConfirmationDialog(event.data, "rejected");
          break;
        }
      }
    } else {
      this.showAssetPopup(event.data);
    }
  }

  openConfirmationDialog(row: any, state: string) {
    if (state === 'rejected') {
      this.rejectPopup(row);
    } else {
      this.showAssetPopup(row);
    }
  }

  rejectPopup(row: any) {
    const config: MatDialogConfig = {
      width: '100vw',
      height: 'auto',
      maxWidth: '400px',
      maxHeight: '100%',
      panelClass: 'custom-dialog-container',
      data: {
        beatActivityData: this.beatActivityData,
        title: 'Reject Asset'
      }
    };
    const confirmdiaeref = this.dialog.open(PartialRejectPopupComponent, config);
    confirmdiaeref.componentInstance.partialrejectsubmit.subscribe(data => {
      row.approved_qty = 0;
      row.reason_id = data.reason_id;
      row.reject_reason = data.reject_reason;
      row.reject_comments = data.reject_comments;
      this.updateAsset(row, 'rejected');
    });
  }

  BulkRejectPopup(rows: any) {
    const config: MatDialogConfig = {
      width: '100vw',
      height: 'auto',
      maxWidth: '400px',
      maxHeight: '100%',
      panelClass: 'custom-dialog-container',
      data: {
        beatActivityData: this.beatActivityData,
        title: 'Reject Asset'
      }
    };
    const confirmdiaeref = this.dialog.open(PartialRejectPopupComponent, config);
    confirmdiaeref.componentInstance.partialrejectsubmit.subscribe(data => {
      this.bulkUpdateAsset(rows, 'rejected', data.reject_reason, data.reject_comments);
    });
  }

  updateAsset(row, status) {
    let apiurl = '';
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    apiurl = '/api/partner_assets/update_asset_status';
    paramObject.data = {
      id: row.id,
      status,
      approved_qty: row.approved_qty,
      reject_reason: row.reason_id,
      reject_comments: row.reject_comments
    };

    this.apiService.post(apiurl, paramObject)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.statusCode === 200)) {
            this.toastr.success(status.toUpperCase() + ' Successfully');
            this.dialog.closeAll();
            this.loadServerData();
          } else {
            this.toastr.error('Error updating Asset');
          }
        },
        error => {
          console.log(error);
          this.toastr.error('Error updating Asset');
        });
  }

  bulkUpdateAsset(rows, status, reject_reason, reject_comments) {
    const apiurl = '/api/partner_assets/bulkupdate_asset_status';
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    const ids = [];
    for (let i = 0; i < rows.length; i++) {
      ids.push(rows[i].id);
    }
    paramObject.data = {
      ids,
      status,
      reject_reason,
      reject_comments
    };
    this.apiService.post(apiurl, paramObject)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.toastr.success(status.toUpperCase() + ' Successfully');
            this.dialog.closeAll();
            this.loadServerData();
          } else {
            this.toastr.error('Error updating Asset');
          }
        },
        error => {
          console.log(error);
          this.toastr.error('Error updating Asset');
        });
  }

  onSelectionChanged(event) {
    this.tabIndex = 0;
    if (this.prevIndex !== event.rowIndex) {
      if (event.node.selected && (this.enableApprove || this.enableReview)) {
        this.showApprove = true;
        this.prevIndex = event.rowIndex;
      }
    } else {
      if (event.node.selected && (this.enableApprove || this.enableReview)) {
        this.showApprove = true;
      } else {
        this.showApprove = false;
      }
      this.prevIndex = event.rowIndex;
    }
  }

  onBtExport() {
    const header = this.sharedService.headerTitleSource.getValue();
    const params = {
      fontSize: undefined,
      rowHeight: undefined,
      headerRowHeight: undefined,
      fileName: header
    };
    this.gridApi.exportDataAsExcel(params);
  }

  bulkUpdateProgress(state) {
    let rows;
    if (this.viewGrid) {
      rows = this.gridApi.getSelectedRows();
    } else {
      rows = this.pageData.filter(x => x['item_selected'] === true);
    }
    if (state === 'rejected') {
      this.BulkRejectPopup(rows);
    } else {
      let title = '';
      let message1 = '';
      let yesButton = '';
      let status = '';
      if (state === 'rejected') {
        title = 'Reject Asset';
        message1 = 'Do you wish to REJECT the asset?';
        yesButton = 'REJECT';
        status = 'rejected';
      } else {
        title = "Approve Asset";
        message1 = 'Do you wish to APPROVE the asset?';
        yesButton = 'APPROVE';
        status = 'approved';
      }

      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "550px",
        maxHeight: "250px",
        data: {
          title,
          message1,
          message2: "",
          showNoButton: true,
          yesButton,
          noButton: "CANCEL"
        }
      };

      if (state === 'rejected') {
        config.data.deleteButton = true;
      }

      const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);
      confirmdiaeref.componentInstance.selectedAction.subscribe(data => {
        if (data === 'yes') {
          this.bulkUpdateAsset(rows, status, null, null);
        } else {
          this.dialog.closeAll();
        }
      });
    }
  }

  showAssetPopup(row) {
    let photo;
    if (this.selectedGalleryItem) {
      photo = this.selectedGalleryItem['photo'];
    }
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "47%",
      maxHeight: "95%",
      data: {
        assetDetails: row,
        photo,
        enableReview: this.enableReview,
        enableApprove: this.enableApprove,
        attributeList: this.uqAttributes
      },
      panelClass: 'custom-dialog-container'
    };
    const confirmdiaeref = this.dialog.open(AssetApprovePopupComponent, config);

    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {
      let status = '';
      if (data.action === 'yes') {
        row.approved_qty = data.approved_qty;
        status = 'approved';
      } else {
        row.approved_qty = 0;
        status = 'rejected';
      }
      this.updateAsset(row, status);
    });
  }

  showDetailPopup() {
    const row = this.rowData.find(x => x.id === this.selectedGalleryItem.id);
    this.showAssetPopup(row);
  }

  showUploadPopup() {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "90%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          status: 'pending'
        }
      };
      const creatediaeref = this.dialog.open(AssetUploadComponent, config);
      creatediaeref.afterClosed().subscribe(result => {
        this.loadServerData();
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {
          status: 'pending'
        }
      };
      const creatediaeref = this.dialog.open(AssetUploadComponent, config);
      creatediaeref.afterClosed().subscribe(result => {
        this.loadServerData();
      });
    }
  }
}
