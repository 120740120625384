import {Component, OnInit} from "@angular/core";
import {ReportBase} from "../_common/reportbase";

@Component({
  selector: 'app-claim-scheme-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class LoyaltyProgramDetailsComponent extends ReportBase implements IReport, OnInit {

  showCustomFilter = false;
  showDate = false;
  // customFilter: any = {
  //   name: 'Sale Type',
  //   key: 'type',
  //   value: 'P',
  //   values: [],
  //   show: true
  // };
  setTitle() {
    this.title = 'Loyalty Program Details';
  }

  ngOnInit() {
    this.sharedService.setHeader('Loyalty Program Details');
    this.statusConfig.success = 'Complete';
    this.statusConfig.error = 'Not Started';
    this.statusConfig.warning = 'In Progress';
    this.configureGrid();
    this.loadSchemeDetails();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_v2_reports/getLoyaltyProgramDetails';

    this.columnDefs = [
      {headerName: 'Campaign', field: 'c_name', width: 100},
      {headerName: 'Campaign Code', field: 'c_code', width: 100},
      {headerName: 'Objective', field: 'objective', hide: true, width: 100},
      {headerName: 'Campaign Type', field: 'c_type', width: 100},
      {headerName: 'Name', field: 's_name', width: 100},
      {headerName: 'Code', field: 's_code', width: 100},
      {headerName: 'Description', field: 'description', hide: true, width: 100},
      {headerName: 'Type', field: 's_type', width: 100},
      {headerName: 'Start Date', field: 's_date', width: 100},
      {headerName: 'End Date', field: 'e_date', width: 100},
      {headerName: 'Partner Name', field: 'p_name', width: 100},
      {headerName: 'Partner Code', field: 'p_code', width: 100},
      {headerName: 'Partner Zip', field: 'p_zip', width: 100},
      {headerName: 'Partner City', field: 'p_city', width: 100},
      {headerName: 'Partner Address', field: 'p_address', width: 100},
      {headerName: 'Measure', field: 'm_type', width: 100},
      {headerName: 'Target', field: 'target', width: 100},
      {
        headerName: 'Achieved', field: 'customer_id', width: 100,
        valueGetter(params) {
          if (params.data && params.data.customer_id > 0) {

            if (params.data.m_type === 'saleprice') {
              if (params.data.sec_ordered === null) {
                return 0;
              } else {
                return Number(params.data.sec_ordered);
              }
            } else if (params.data.m_type === 'volume') {
              if (params.data.sec_volume === null) {
                return 0;
              } else {
                return Number(params.data.sec_volume);
              }
            } else if (params.data.m_type === 'weight') {
              if (params.data.sec_weight === null) {
                return 0;
              } else {
                return Number(params.data.sec_weight);
              }
            } else if (params.data.m_type === 'order_delivered') {
              if (params.data.sec_delivered === null) {
                return 0;
              } else {
                return Number(params.data.sec_delivered);
              }
            } else {
              return 0;
            }

          } else {
            return 0;
          }
        }
      },
      {
        headerName: 'Status', field: 'customer_id', enableRowGroup: true, width: 80,
        valueGetter(params) {
          if (params.data && params.data.customer_id > 0) {
            let value = 0;

            if (params.data.m_type === 'saleprice') {
              if (params.data.sec_ordered === null) {
                value = 0;
              } else {
                value = Number(params.data.sec_ordered);
              }
            } else if (params.data.m_type === 'volume') {
              if (params.data.sec_volume === null) {
                value = 0;
              } else {
                value = Number(params.data.sec_volume);
              }
            } else if (params.data.m_type === 'weight') {
              if (params.data.sec_weight === null) {
                value = 0;
              } else {
                value = Number(params.data.sec_weight);
              }
            } else if (params.data.m_type === 'order_delivered') {
              if (params.data.sec_delivered === null) {
                value = 0;
              } else {
                value = Number(params.data.sec_delivered);
              }
            } else {
              value = 0;
            }

            if (value >= params.data.target) {
              return 'Completed';
            } else {
              if (value === 0 || value === null) {
                return 'Not Started';
              } else {
                return 'In Progress';
              }
            }

          } else {
            return 'Not Started';
          }
        }, cellRenderer: this.sharedService.statusCellRenderer
      },
      {
        headerName: 'PC', field: 'customer_id', width: 100,
        valueGetter(params) {
          if (params.data && params.data.customer_id > 0) {

            if (params.data.s_type === 'S') {
              return params.data.sec_count;
            } else {
              return params.data.pri_count;
            }

          } else {
            return 0;
          }
        }
      }
    ];
  }

  private loadSchemeDetails() {
    const scheme_id = localStorage.getItem('loyalty_pgm_id');
    const campaign_id = localStorage.getItem('loyalty_pgm_campaign');
    this.userData.customFilter['scheme_id'] = scheme_id;
    this.userData.customFilter['campaign_id'] = campaign_id;
    this.loadServerData();
  }
}
