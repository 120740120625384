import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class RetailerTargetAchievementReportComponent extends ReportBase implements IReport, OnInit {

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Retailer Target vs Achievement Report');

    this.showableFilters.territory = true;
    this.showableFilters.state = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.showDate = true;
    const date = new Date();
    this.myDate[0] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
    this.myDate[1] =  this.datePipe.transform(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd');
    this.userData.start_date = this.myDate[0];
    this.userData.end_date = this.myDate[1];
    this.gridOptions.pivotMode = true;
    this.gridOptions.autoGroupColumnDef = {
      headerName: 'GROUP',
      minWidth: 250,
      cellRenderer: 'agGroupCellRenderer',
      pinned: 'left'
    };

    this.configureGrid();
      }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getRetailerTargetvsAchievementReport';

    this.columnDefs = [

      /* {headerName: 'START DATE', field: 'start_date', enableRowGroup: true, width: 150},
       {headerName: 'END DATE', field: 'end_date', enableRowGroup: true, width: 150},*/
      {headerName: 'REGION', field: 'region', width: 80, enableRowGroup: true, rowGroup: true, hide: true},
      {headerName: 'STATE', field: 'state', width: 80, enableRowGroup: true, rowGroup: true, hide: true},
      {headerName: 'CLUSTER', field: 'cluster', width: 80, enableRowGroup: true, rowGroup: true, hide: true},
      {headerName: 'TERRITORY', field: 'territory', width: 80, enableRowGroup: true, rowGroup: true, hide: true},
      {
        headerName: 'MONTH', field: 'jc_name', menuTabs: [], width: 120, enableRowGroup: true, pivot: true,
        enablePivot: true, pivotComparator: this.MyMonthPivotComparator
      },
      {headerName: 'OCODE', field: 'r_code', width: 100, enableRowGroup: true},
      {headerName: 'OUTLET', field: 'r_name', width: 150, enableRowGroup: true, rowGroup: true, hide: true},
      {headerName: 'OTYPE', field: 'retailer_type', width: 120, enableRowGroup: true},
      {headerName: 'BEAT', field: 'beat', menuTabs: [], width: 150},
      {headerName: 'PROGRAM TYPE', field: 'program_type', enableRowGroup: true, width: 150},
      {
        headerName: 'MEASURE TYPE', field: 'measure_type', width: 150,
        enableRowGroup: true
      },
      {headerName: 'TARGET', field: 'target', width: 120, aggFunc: 'sum'},
      {headerName: 'ACHIEVEMENT', field: 'achievement', width: 120, aggFunc: 'sum'},
      {
        headerName: 'PROGRESS', field: 'progress', width: 120, aggFunc: this.avgAggFunction,
        valueGetter: params => {
          if (params.data) {
            if (params.data.target === null || parseInt(params.data.target) === 0 || params.data.achievement === null || parseInt(params.data.achievement) === 0) {
              return 0;
            } else {
              return Math.trunc((parseInt(params.data.achievement) / parseInt(params.data.target)) * 100);
            }
          }
        },
        cellRenderer: params => {
          if (params.value === null) {
            return '';
          } else {
            let displayElem = '<div class="row">\n' +
              '            <div class="progresscontainer">\n' +
              '              <div class="progressinside" style=" ';

            if (params.value > 50) {
              displayElem = displayElem + ' background-color: #379862;';
            } else if (params.value > 20) {
              displayElem = displayElem + ' background-color: #D97C0E;';
            } else {
              displayElem = displayElem + ' background-color: #E24260;';
            }

            displayElem = displayElem + 'width:' + params.value + '%;"></div>\n' +
              '            </div>\n' +
              '            <div class="progresstext" style="';

            if (params.value > 50) {
              displayElem = displayElem + ' color: #379862;';
            } else if (params.value > 20) {
              displayElem = displayElem + ' color: #D97C0E;';
            } else {
              displayElem = displayElem + ' color: #E24260;';
            }

            displayElem = displayElem + '">' + params.value + '%</div></div>';


            return displayElem;
          }
        }
      },
    ];

  }

  MyMonthPivotComparator(a, b) {
    return b - a;
  }

  avgAggFunction(params) {
    let avg;
// the average will be the sum / count
    let sum = 0;
    let count = 0;

    params.values.forEach(value => {
      const groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        // we are aggregating groups, so we take the
        // aggregated values to calculated a weighted average
        sum += value.avg * value.count;
        count += value.count;
      } else {
        // skip values that are not numbers (ie skip empty values)
        if (typeof value === 'number') {
          sum += value;
          count++;
        }
      }
    });
    // avoid divide by zero error
    if (count !== 0) {
      avg = sum / count;
    } else {
      avg = null;
    }

    avg = Math.round(Math.round((avg + Number.EPSILON) * 100) / 100);

    // the result will be an object. when this cell is rendered, only the avg is shown.
    // however when this cell is part of another aggregation, the count is also needed
    // to create a weighted average for the next level.
    const result = {
      count,
      avg,
      // the grid by default uses toString to render values for an object, so this
      // is a trick to get the default cellRenderer to display the avg value
      toString() {
        return this.avg;
      },
    };

    return result;
  }

  changeDate(dt) {
    const temp = this.myDate;
    this.myDate = [];
    this.myDate[0] = this.datePipe.transform(new Date(temp[0].getFullYear(), temp[0].getMonth(), 1), 'yyyy-MM-dd');
    this.myDate[1] =  this.datePipe.transform(new Date(temp[1].getFullYear(), temp[1].getMonth() + 1, 0), 'yyyy-MM-dd');

    this.userData.start_date = this.myDate[0];
    this.userData.end_date = this.myDate[1];

    this.changeDetector.detectChanges();
  }

 /* datechanged(dt) {

    this.myDate[0] = this.datePipe.transform(new Date(temp[0].getFullYear(), temp[0].getMonth(), 1), 'yyyy-MM-dd');
    this.myDate[1] =  this.datePipe.transform(new Date(temp[1].getFullYear(), temp[1].getMonth() + 1, 0), 'yyyy-MM-dd');

    this.userData.start_date = this.myDate[0];
    this.userData.end_date = this.myDate[1];

    this.changeDetector.detectChanges();
  }*/

}
