import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {
  @Output() statusChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  itemList = [];
  selectedItems = [];
  settings = {};
  constructor() { }

  ngOnInit() {
    this.itemList = [
      { "id": 1, "itemName": "Sales in Value" },
      { "id": 2, "itemName": "Sales in Qty" },
    ];

    this.selectedItems = [
      { "id": 1, "itemName": "Sales in Value" }];
    this.settings = { singleSelection: true, text: "Sales in Value" };
  }

  onItemSelect(event) {
    this.statusChange.emit(event);
  }

}


