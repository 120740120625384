<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>{{title}}</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <span>Reason</span>
      <div style="margin-top: 5px;" class="formselectwhitebg">
        <select name="comments"
                id="comments"
                [(ngModel)]="rejectData.reason_id"
                #comments="ngModel">
          <option
            *ngFor="let item of beatActivityData"
            value="{{ item.id }}"
          > {{ item.name }}</option>
        </select>
      </div>
      <div  style="height: 70px;margin-top: 20px; margin-bottom: 20px;">
                    <textarea
                      class="forminputwhitebg"
                      style="height: 90px; width: 100%"
                      type="text"
                      name="reject_comments"
                      #reject_reason="ngModel"
                      [(ngModel)]="rejectData.reject_comments"
                      placeholder="Enter Comments"
                    ></textarea>
      </div>
    </div>
    <div class="button-row" style="float: right; margin: 15px 30px 15px 0px;">
      <button
        mat-button
        style=" margin-right:15px;"
        class="btn grey-btn"
        (click)="closeDialog()"
      >
        Cancel
      </button>
      <button
        mat-button
        class="btn confirm-blue-btn"
        (click)="performAction()"
      >
        Confirm
      </button>
    </div>
  </div>
</section>
