import {Component, ElementRef, Renderer2} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";

@Component({
  selector: 'max-claim',
  template: `<div *ngIf="!claim_exceeded"><span>{{params.value}}</span></div>
     <div *ngIf="claim_exceeded"><span style="padding-right: 8px;">{{params.value}}</span>
    <img style="width: 20%;vertical-align: text-bottom;" src="./../assets/alert-icon-1575.png"/></div>`,
})
export class ClaimMaxComponent implements ICellRendererAngularComp {
  public params: any;
  public claim_exceeded = false;

  constructor(private elRef: ElementRef,
              private renderer: Renderer2){}

  agInit(params: any): void {
    this.params = params;
    this.claim_exceeded = this.params.data.max_claim_exceeded;
  }

  refresh(): boolean {
    return false;
  }
}
