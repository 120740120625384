import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {MatDialogConfig} from "@angular/material/dialog";
import {LocationPopupComponent} from "../../components/location_popup/location_popup.component";
import {formatDate} from "@angular/common";

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class UserPerformanceSummaryReportComponent extends ReportBase implements IReport, OnInit {

  daylist: any = [];
  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    // this.showableFilters.salesman = true;
    // this.showableFilters.state = true;
    // this.showableFilters.region = true;
    // this.showableFilters.team = true;
    this.myDate[0] = this.datePipe.transform(new Date(date), 'yyyy-MM-dd');
    this.userData.start_date = this.myDate[0];
    this.sharedService.setHeader('User Performance Summary Report');
    this.API_URL = '/api/pwa_reports/getUserPerformanceSummary';
    this.configureGrid();
  }

  configureGrid() {

    this.columnDefs = [
      {headerName: 'STATE', field: 'state', width: 80},
      {headerName: 'TEAM', field: 'team', width: 80},
      {headerName: 'PARENT TEAM', field: 'parent_team', width: 80},
      {headerName: 'REPORTING MANAGER', field: 'reporting_manager', width: 80},
      {headerName: 'EMP CODE', field: 'emp_code', width: 80},
      {headerName: 'USER', field: 'user_name', width: 120},
      {headerName: 'ROLE', field: 'user_role', width: 80},
      {headerName: 'MARKED PRESENT', field: 'present', width: 80, aggFunc: 'sum'},
      {headerName: 'MISSED ATTENDANCE', field: 'absent', width: 80, aggFunc: 'sum'},
      {headerName: 'LEAVE', field: 'leave',  width: 80, aggFunc: 'sum'},
      {headerName: 'APP LEAVE', field: 'approvedLeave',  width: 80, aggFunc: 'sum'},
      {headerName: 'HOLIDAY', field: 'holiday', width: 80, aggFunc: 'sum'},
      {headerName: 'WEEKLY OFF', field: 'weekly_off',  width: 80, aggFunc: 'sum'},
      {headerName: 'TOTAL WORKING DAYS', field: 'total_working_days',  width: 80, aggFunc: 'sum'},
      {headerName: 'SCHEDULE', field: 'sch_count', width: 80},
      {headerName: 'TC', field: 'tc', width: 80},
      {headerName: 'PC', field: 'pc', width: 80},
      {headerName: 'TARGET', field: 'schedule_target', width: 80},
      {headerName: 'BEAT REVENUE', field: 'beat_revenue', width: 80},
      {headerName: 'VISITS', field: 'visited_count', width: 80},
      {
        headerName: 'PRIMARY SALES',
        children: [
          {headerName: 'ORDER', field: 'p_order', width: 80},
          {headerName: 'SALES', field: 'p_sales', width: 80},
          {headerName: 'TLS', field: 'p_sku_count', width: 80},
          {headerName: 'ULS', field: 'p_uq_sku', width: 80},
          {headerName: 'UQ PC', field: 'p_uq_pc', width: 80},
        ]
      },
      {
        headerName: 'SECONDARY SALES',
        children: [
          {headerName: 'ORDER', field: 's_order', width: 80},
          {headerName: 'SALES', field: 's_sales', width: 80},
          {headerName: 'TLS', field: 's_sku_count', width: 80},
          {headerName: 'ULS', field: 's_uq_sku', width: 80},
          {headerName: 'UQ PC', field: 's_uq_pc', width: 80},
        ]
      },
    ];
  }

  postProcessData() {
    this.configureData();
  }

  configureData() {
    this.daylist = this.getDaysArray(new Date(this.myDate[0]), new Date(this.myDate[1]));
    this.daylist.map((v) => v.toISOString().slice(0, 10)).join('');
    this.rowData = [];
    const self = this;

    const d = new Date();
    d.setDate(d.getDate() - 1);
    const yesterdayDate = formatDate(d, 'yyyy-MM-dd', 'en-US').toString().substring(0, 10);
    for (const key in this.apiData.master) {
      let indRow = {};
      let masterRow: any = {};
      let activityMaster: any = {};
      let attendanceMaster: any = {};
      const attendanceRow: any = {};
      let holidayRow: any = {};

      masterRow = this.apiData.master[key];

      holidayRow = this.apiData.holiday;

      attendanceMaster = this.apiData.attendance.filter(function(attendance) {
        return attendance.salesman_id === masterRow.salesman_id;
      });

      if (attendanceMaster != null && attendanceMaster.length > 0) {
        if (attendanceMaster[0].hasOwnProperty('yest_att_time')) {
          attendanceRow.yest_att_time = attendanceMaster[0].yest_att_time;
        }
        if (attendanceMaster[0].hasOwnProperty('yest_eod_time')) {
          attendanceRow.yest_eod_time = attendanceMaster[0].yest_eod_time;
        }

        for (const days in self.daylist) {
          var formattedDate = formatDate(new Date(self.daylist[days]), 'dd/MM/yyyy', 'en-US').substring(0, 10);
          attendanceRow[formattedDate + 'status'] = attendanceMaster.filter(function(attendance) {
            return formattedDate === formatDate(new Date(attendance.date), 'dd/MM/yyyy', 'en-US').substring(0, 10);
          }).map(obj => obj.status)[0];

          attendanceRow[formattedDate + 'checkin'] = attendanceMaster.filter(function(attendance) {
            return formattedDate === formatDate(new Date(attendance.date), 'dd/MM/yyyy', 'en-US').substring(0, 10);
          }).map(obj => obj.check_in)[0];

        }
      }

      for (var key1 in self.daylist) {
        var formattedDate = formatDate(new Date(self.daylist[key1]), 'dd/MM/yyyy', 'en-US').substring(0, 10);
        if (holidayRow !== null && holidayRow.length > 0 && attendanceRow[formattedDate + 'status'] !== 'P') {
          const holidaycheck = holidayRow.filter(function(holiday) {
            return formattedDate === formatDate(new Date(holiday.holiday), 'dd/MM/yyyy', 'en-US').substring(0, 10);
          })[0];

          if (holidaycheck != null) {
            attendanceRow[formattedDate + 'status'] = 'H';
          }
        }
        if (attendanceRow[formattedDate + 'status'] === undefined || attendanceRow[formattedDate + 'status'] === '') {
          attendanceRow[formattedDate + 'status'] = 'A';
        }
      }

      if (attendanceRow != null) {
        attendanceRow.present = 0;
        attendanceRow.absent = 0;
        attendanceRow.leave = 0;
        attendanceRow.approvedLeave = 0;
        attendanceRow.holiday = 0;
        attendanceRow.weekly_off = 0;

        Object.keys(attendanceRow).forEach((key, index) => {
          // key: the name of the object key
          // index: the ordinal position of the key within the object
          if (key.includes('status') && attendanceRow[key] === 'P') {
            attendanceRow.present += 1;
          }
          if (key.includes('status') && attendanceRow[key] === 'A') {
            attendanceRow.absent += 1;
          }
          if (key.includes('status') && attendanceRow[key] === 'L') {
            attendanceRow.leave += 1;
          }
          if (key.includes('status') && attendanceRow[key] === 'AL') {
            attendanceRow.approvedLeave += 1;
          }
          if (key.includes('status') && attendanceRow[key] === 'H') {
            attendanceRow.holiday += 1;
          }
          if (key.includes('status') && attendanceRow[key] === 'WO') {
            attendanceRow.weekly_off += 1;
          }
        });

        attendanceRow.total_working_days = self.daylist.length - (attendanceRow.holiday + attendanceRow.weekly_off);
      }

      indRow = {
        ...masterRow,
        ...attendanceRow,
        ...holidayRow
      };
      this.rowData.push(indRow);
    }
    console.log(this.rowData);

  }

  getDaysArray(start, end) {
    for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  }

}
