import {Component, ElementRef, Renderer2} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";

@Component({
  selector: 'report-url',
  template: `<div *ngIf="download"><a href={{zip_url}}>Download</a></div>`+
             `<div *ngIf="!download"><a style="color: red;">Not generated</a></div>`
})
export class ReportUrlComponent implements ICellRendererAngularComp {
  public params: any;
  public zip_url;
  public download = false;

  constructor(private elRef: ElementRef,
              private renderer: Renderer2){}

  agInit(params: any): void {
    if (params.data['File Path'] != null) {
      this.zip_url = params.data['File Path'];
      this.download = true;
    } else {
      this.download = false;
    }
  }

  refresh(): boolean {
    return false;
  }
}
