import {Component} from "@angular/core";
import {BaseUpload} from "../../../pagebase/base_upload/base_upload";
import * as XLSX from "xlsx-js-style";

@Component({
  selector: 'app-partner-leads-create-upload',
  templateUrl: '../../../pagebase/base_upload/base_upload.html',
  styleUrls: ['../../../pagebase/base_upload/base_upload.scss']

})

export class PartnerLeadsCreateUploadComponent extends BaseUpload {
  masterData: any = {};
  attributeList: any = {
    retailer: [],
    distributor: [],
    project: [],
    influencer: []
  };
  states = [];

  setViewParams() {
    this.header = 'Partner Leads Attribute Upload';
    this.sampleText1 = 'DOWNLOAD SAMPLE TEMPLATE';
    this.sampleText2 = 'DOWNLOAD MASTER DATA';
    this.excel_columns = [{C: 'A', H: 'Name', V: 'Lead1', required: true},
      {C: 'B', H: 'Type', V: 'Plumber', required: true},
      {C: 'C', H: 'Phone', V: '9145623966', required: true},
      {C: 'D', H: 'Email', V: 'abc@gmail.com', required: false},
      {C: 'E', H: 'Description', V: 'Onboarding done', required: false},
      {C: 'F', H: 'Salesman Code', V: 'JD007', required: true},
      {C: 'G', H: 'Beat Code', V: 'BLR123', required: true},
      {C: 'H', H: 'Address 1', V: '40 Feet Road,', required: true},
      {C: 'I', H: 'Address 2', V: 'Vijay vihar Phase I', required: true},
      {C: 'J', H: 'City', V: 'Bengaluru', required: true},
      {C: 'K', H: 'Zip', V: '560037', required: true},
      {C: 'L', H: 'Latitude', V: 'New', required: true},
      {C: 'M', H: 'Longitude', V: '17.53', required: false},
      {C: 'N', H: 'Stage', V: '50.21', required: false},
      {C: 'O', H: 'Source', V: 'Facebook', required: false},
      {C: 'P', H: 'State', V: 'Karnataka', required: true},
    ];

    this.step1Text = 'Download sample template for reference. Download Master Data for Type wise attributes.';
    this.step2Text = 'Required fields are Name, Type, Phone, Salesman Code, Beat Code, Address 1, Address 2, City, Zip and Stage';
    this.actionText = 'Create a New Partner Lead';
    this.baseData = this.sampleData1;
    this.fetchMasterData();
  }

  sampleDownload1() {
    this.downloadSampleData('partner_leads_template');
  }

  fetchMasterData() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    this.apiService.post('/api/crm_leads/fetch_partner_leads_master_data', paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.masterData = res.results.data;
          this.configureMasterData();
          this.dataLoaded = true;
        } else {
          this.toastr.error("Failed to fetch Master Data");
        }
      });
  }

  configureMasterData() {
    if (this.masterData['attributes']) {
      let retail_attr = this.masterData['attributes'].find(x => x.type === 'retailer');
      if (retail_attr?.attributes) {
        retail_attr = retail_attr.attributes;
        this.attributeList['retailer'] = retail_attr;
      } else {
        this.attributeList['retailer'] = [];
      }

      let dist_attr = this.masterData['attributes'].find(x => x.type === 'distributor');
      if (dist_attr?.attributes) {
        dist_attr = dist_attr.attributes;
        this.attributeList['distributor'] = dist_attr;
      } else {
        this.attributeList['distributor'] = [];
      }

      let project_attr = this.masterData['attributes'].find(x => x.type === 'project');
      if (project_attr?.attributes) {
        project_attr = project_attr.attributes;
        this.attributeList['project'] = project_attr;
      } else {
        this.attributeList['project'] = [];
      }

      let influencer_attr = this.masterData['attributes'].find(x => x.type === 'influencer');
      if (influencer_attr?.attributes) {
        influencer_attr = influencer_attr.attributes;
        this.attributeList['influencer'] = influencer_attr;
      } else {
        this.attributeList['influencer'] = [];
      }
    }
  }

  sampleDownload2() {
    const wb = XLSX.utils.book_new();
    const wscols = [{wch: 24}];
    const wscols1 = [{wch: 24}, {wch: 24}];
    if (this.masterData['type'] && this.masterData['type'].length > 0) {
      const types = [];
      this.masterData['type'].forEach(x => {
        types.push({Type: x.name, ['Partner Type']: x.type});
      });
      const ws = XLSX.utils.json_to_sheet(types);
      ws['!cols'] = wscols1;
      this.sharedService.applyExcelColumnStyle(ws, 1, wscols1.length);
      XLSX.utils.book_append_sheet(wb, ws, "Type");
    } else {
      this.toastr.error("Partner Types not configured!!");
    }

    const ws1 = XLSX.utils.json_to_sheet(this.attributeList['retailer']);

    ws1['!cols'] = wscols1;
    this.sharedService.applyExcelColumnStyle(ws1, 1, wscols1.length);
    XLSX.utils.book_append_sheet(wb, ws1, "Retailer Attributes");

    const ws2 = XLSX.utils.json_to_sheet(this.attributeList['distributor']);
    ws2['!cols'] = wscols1;
    this.sharedService.applyExcelColumnStyle(ws2, 1, wscols1.length);
    XLSX.utils.book_append_sheet(wb, ws2, "Distributor Attributes");

    const ws3 = XLSX.utils.json_to_sheet(this.attributeList['project']);
    ws3['!cols'] = wscols1;
    this.sharedService.applyExcelColumnStyle(ws3, 1, wscols1.length);
    XLSX.utils.book_append_sheet(wb, ws3, "Project Attributes");

    const ws4 = XLSX.utils.json_to_sheet(this.attributeList['influencer']);
    ws4['!cols'] = wscols1;
    this.sharedService.applyExcelColumnStyle(ws4, 1, wscols1.length);
    XLSX.utils.book_append_sheet(wb, ws4, "Influencer Attributes");

    if (this.masterData['stage'] && this.masterData['stage'].length > 0) {
      const stages = [];
      this.masterData['stage'].forEach(x => {
        stages.push({stage: x.name});
      });
      const ws5 = XLSX.utils.json_to_sheet(stages);
      ws5['!cols'] = wscols;
      this.sharedService.applyExcelColumnStyle(ws5, 1, wscols.length);
      XLSX.utils.book_append_sheet(wb, ws5, "Stage");
    } else {
      this.toastr.error("Stage not configured!!");
    }

    if (localStorage.getItem('all_states') !== null) {
      this.states = this.states.concat(JSON.parse(localStorage.getItem('all_states')));
      this.states.sort((a, b) => a.itemName.localeCompare(b.itemName));

      const states = [];

      this.states.forEach(state => {
        states.push({state: state.itemName});
      });

      const ws6 = XLSX.utils.json_to_sheet(states);
      ws6['!cols'] = wscols;
      this.sharedService.applyExcelColumnStyle(ws6, 1, wscols.length);
      XLSX.utils.book_append_sheet(wb, ws6, "States");
    }


    XLSX.writeFile(wb, "master_data.xlsx");
  }

  configureGrid() {
    this.API_URL = '/api/crm_leads/partner_lead_attribute_upload';
    this.columnDefs = [
      {headerName: 'Name', field: 'name', width: 150},
      {headerName: 'Type', field: 'type', width: 150},
      {headerName: 'Phone', field: 'phone', width: 150},
      {headerName: 'Email', field: 'email_from', width: 150},
      {headerName: 'Description', field: 'description', width: 150},
      {headerName: 'Salesman Code', field: 'emp_code', width: 150},
      {headerName: 'Beat Code', field: 'beat_code', width: 150},
      {headerName: 'Address 1', field: 'street', width: 150},
      {headerName: 'Address 2', field: 'street2', width: 150},
      {headerName: 'City', field: 'city', width: 150},
      {headerName: 'Zip', field: 'zip', width: 150},
      {headerName: 'Stage', field: 'stage', width: 150},
      {headerName: 'Latitude', field: 'lat', width: 50},
      {headerName: 'Longitude', field: 'lng', width: 50},
      {headerName: 'State', field: 'state', width: 150},
      {headerName: 'Source', field: 'source', width: 150},

    ];
  }

  validate() {
    const inThis = this;
    this.validRecords = 0;
    this.errorRecords = 0;
    this.states = this.states.concat(JSON.parse(localStorage.getItem('all_states')));
    this.states.sort((a, b) => a.itemName.localeCompare(b.itemName));
    const uniqueCodes = new Set();
    const dynamicAttributes: Set<string> = new Set(); // Track all dynamic attributes found during validation
    this.uploaded_data.forEach(data => {
      const indrow: any = {};
      indrow.valid = 'Valid'; // Assume the record is valid unless proven otherwise
      indrow.errorMsg = '';
      indrow.param = [];

      if (!data['Type'] || data['Type'].trim() === '') {
        indrow.errorMsg = indrow.errorMsg + ' Missing Type ,';
        this.SetInvalidStatus(indrow, 'Missing Type', 'type');
      } else {
        const found = inThis.masterData.type.find(x => x.name === data['Type']);
        if (!found) {
          indrow.errorMsg = indrow.errorMsg + ' Invalid Type ,';
          this.SetInvalidStatus(indrow, 'Invalid Type', 'type');
        } else {
          indrow.type = data['Type'];
          indrow.partner_type= found.type;
          indrow.partner_type_id = found.id;
        }
      }


      if (!data['Phone'] || data['Phone'] === '') {
        indrow.errorMsg = indrow.errorMsg + ' Missing Phone Number, ';
        this.SetInvalidStatus(indrow, 'Missing Phone Number', 'phone');
      } else if (!inThis.phonePattern.test(data['Phone'])) {
        indrow.errorMsg = indrow.errorMsg + ' Invalid Phone Number';
        this.SetInvalidStatus(indrow, 'Invalid Phone Number', 'phone');
      } else {
        indrow.phone = data['Phone'];
      }

      if (!data['Name'] || data['Name'].trim() === '') {
        indrow.errorMsg = indrow.errorMsg + ' Missing Name, ';
        this.SetInvalidStatus(indrow, 'Please provide a name', 'name');
      } else {
        indrow.name = data['Name'];
      }

      if (!data['Salesman Code'] || data['Salesman Code'].trim() === '') {
        indrow.errorMsg = indrow.errorMsg + ' Missing Salesman Code, ';
        this.SetInvalidStatus(indrow, 'Please provide a Salesman Code', 'emp_code');
      } else {
        indrow.emp_code = data['Salesman Code'];
      }

      if (!data['Beat Code'] || data['Beat Code'].trim() === '') {
        indrow.errorMsg = indrow.errorMsg + ' Missing Beat Code, ';
        this.SetInvalidStatus(indrow, 'Please provide a Beat Code', 'beat_code');
      } else {
        indrow.beat_code = data['Beat Code'];
      }

      if (data['Email']) {
        indrow.email_from = data['Email'];
      }

      if (data['Description']) {
        indrow.description = data['Description'];
      }

      if (!data['Address 1'] || data['Address 1'].trim() === '') {
        indrow.errorMsg = indrow.errorMsg + ' Missing Address 1, ';
        this.SetInvalidStatus(indrow, 'Missing Address 1', 'street');
      } else {
        indrow.street = data['Address 1'];
      }

      if (!data['Address 2'] || data['Address 2'].trim() === '') {
        indrow.errorMsg = indrow.errorMsg + ' Missing Address 2 ,';
        this.SetInvalidStatus(indrow, 'Missing Address 2', 'street2');
      } else {
        indrow.street2 = data['Address 2'];
      }

      if (!data['City'] || data['City'].trim() === '') {
        indrow.errorMsg = indrow.errorMsg + ' Missing City ,';
        this.SetInvalidStatus(indrow, 'Missing City', 'city');
      } else {
        indrow.city = data['City'];
      }

      if (!data['Zip'] || data['Zip'].trim() === '') {
        indrow.errorMsg = indrow.errorMsg + ' Missing Zip ,';
        this.SetInvalidStatus(indrow, 'Missing Zip', 'zip');
      } else {
        indrow.zip = data['Zip'];
      }

      if (!data['Stage'] || data['Stage'].trim() === '') {
        indrow.errorMsg = indrow.errorMsg + ' Missing Stage ,';
        this.SetInvalidStatus(indrow, 'Missing Stage', 'stage');
      } else {
        const found = inThis.masterData.stage.find(x => x.name === data['Stage']);
        if (!found) {
          indrow.errorMsg = indrow.errorMsg + ' Invalid Stage ,';
          this.SetInvalidStatus(indrow, 'Invalid Stage', 'stage');
        } else {
          indrow.stage = data['Stage'];
          indrow.stage_id = found.id;
        }
      }

      if (data['Latitude']) {
        indrow.lat = data['Latitude'];
      }

      if (data['Longitude']) {
        indrow.lng = data['Longitude'];
      }

      if (!data['State'] || data['State'].trim() === '') {
        indrow.errorMsg = indrow.errorMsg + ' Missing State ,';
        this.SetInvalidStatus(indrow, 'Missing State', 'state');
      } else {
        const found = inThis.states.find(x => x.itemName === data['State']);
        if (!found) {
          indrow.errorMsg = indrow.errorMsg + ' Invalid State ,';
          this.SetInvalidStatus(indrow, 'Invalid State', 'state');
        } else {
          indrow.state = data['State'];
          indrow.state_id = found.id;
        }
      }

      if (data['Source']) {
        indrow.source = data['Source'];
      }

      // Validate attributes and their values based on the type
      if (indrow.valid === 'Valid') {
        const attributes = Object.keys(data).filter(
          attr =>
        !['Type','valid','errorMsg','Phone','Name','Email','Description', 'Salesman Code', 'Beat Code', 'Address 1','Address 2','City','Zip','Stage',
          'Latitude','Longitude','State','Source'].includes(attr));

        if (attributes && attributes.length > 0) {
          if (inThis.attributeList[indrow.partner_type] && inThis.attributeList[indrow.partner_type].length > 0) {
            attributes.forEach(attribute => {
              const attrfound = inThis.attributeList[indrow.partner_type].filter(x => x.attribute === attribute);
              if (!attrfound || attrfound.length === 0) {
                indrow.errorMsg = indrow.errorMsg + ` Invalid Attribute: ${attribute}`;
                this.SetInvalidStatus(indrow, `Invalid Attribute: ${attribute}`, attribute.toLowerCase().replace(/\s+/g, '_'));
              } else {
                if (attrfound.length > 1) {
                  const attrvaluefound = attrfound.find(x => x.value === data[attribute]);
                  if (!attrvaluefound) {
                    indrow.errorMsg = indrow.errorMsg + ` Invalid Attribute Value: ${attribute}`;
                    this.SetInvalidStatus(indrow, `Invalid Attribute Value: ${attribute}`, attribute.toLowerCase().replace(/\s+/g, '_'));
                  }
                }
              }
              indrow.param.push({
                n: attribute,
                v: data[attribute]
              });
              indrow[attribute.toLowerCase().replace(/\s+/g, '_')] = data[attribute];
              dynamicAttributes.add(attribute);
            });
          } else {
            indrow.errorMsg = indrow.errorMsg + ' Attributes not configured for ' + indrow.partner_type;
            indrow.valid = 'Invalid';
          }
        }
      }

      // Handle duplicate checks
      const combi = Object.values(data).join("-");
      if (indrow.valid === 'Valid' && uniqueCodes.has(combi)) {
        indrow.errorMsg = 'Duplicate Record';
        indrow['Duplicate'] = 'Yes';
        dynamicAttributes.add('Duplicate');
        this.SetInvalidStatus(indrow, 'Duplicate Record', 'duplicate');
      } else {
        if (indrow.valid === 'Invalid') {
          indrow.errorMsg = indrow.errorMsg.substring(0, indrow.errorMsg.length - 2);
        }
        uniqueCodes.add(combi);
      }
      console.log('Combi: ', combi);
      console.log('indrow: ', indrow);
      // Track the number of valid and invalid records
      if (indrow.valid === 'Invalid') {
        this.errorRecords++;
      } else {
        this.validRecords++;
      }

      this.rowData.push(indrow);
    });
    // After validation, dynamically update the column definitions to include the detected attributes
    dynamicAttributes.forEach(attr => {
      this.columnDefs.push({
        headerName: attr,
        field: attr.toLowerCase().replace(/\s+/g, '_'), // Convert attribute name to a valid field name
        width: 150,
      });
    });

    console.log("Final rowdata: ", this.rowData);
    this.totalRecords = this.rowData.length;
    if (this.gridOptions && this.gridOptions.api) {
      this.gridOptions.api.setRowData(this.rowData);
    }
    this.setCellClassRulesAndTooltips();
  }

  downloadDataWithErrors() {
    super.downloadDataWithErrors();
  }


}

