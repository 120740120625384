import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {MatDialogConfig} from "@angular/material/dialog";
import {ImagePopupComponent} from "../../components/image_popup/image_popup.component";

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class CustomAuditReportComponent extends ReportBase implements IReport, OnInit {

  /* showCustomFilter = true;
   customFilter: any = {
     name: 'Active',
     key: 'active',
     value: 'true',
     values: [],
     show: true
   };*/

  imageCounter = 1;
  content_url = '';

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Custom Audit Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    /* this.customFilter.values = [
       {id: '', itemName: 'All'},
       {id: 'true', itemName: 'Active'},
       {id: 'false', itemName: 'Inactive'}
     ];*/
    this.content_url = localStorage.getItem('content_url');
    this.container = 'res_partner_gallery';
    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/customAuditReport';
    const inThis = this;
    this.columnDefs = [
      {
        headerName: "",
        children: [
          {headerName: 'REGION', field: 'region', enableRowGroup: true, width: 80},
          {headerName: 'CLUSTER', field: 'cluster', enableRowGroup: true, width: 80},
          {headerName: 'TERRITORY', field: 'territory', enableRowGroup: true, width: 80},
          {headerName: 'TEAM', field: 'team', width: 100, enableRowGroup: true},
          {headerName: 'SCODE', field: 'scode', enableRowGroup: true, width: 80},
          {headerName: 'SALESMAN', field: 'user_name', menuTabs: [], width: 150},
          {headerName: 'OCODE', field: 'rcode', enableRowGroup: true, width: 80},
          {headerName: 'OUTLET', field: 'retailer', menuTabs: [], width: 150},
          {headerName: 'CHANNEL', field: 'channel', enableRowGroup: true, width: 80},
          {headerName: 'PROGRAM', field: 'program_type', enableRowGroup: true, width: 80},
          {
            headerName: 'PLANNED DATE', field: 'planned_date', enableRowGroup: true, width: 80,
            valueGetter: params => {
              if (params.data) {
                if (params.data.planned_date) {
                  return inThis.datePipe.transform((new Date(params.data.planned_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
                } else {
                  return params.data.planned_date;
                }
              } else {
                return '';
              }
            }
          },
          {
            headerName: 'COMPLETED ON', field: 'completed_date', enableRowGroup: true, width: 80,
            valueGetter: params => {
              if (params.data) {
                if (params.data.completed_date) {
                  return inThis.datePipe.transform((new Date(params.data.completed_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
                } else {
                  return params.data.completed_date;
                }
              } else {
                return '';
              }
            }
          },
          {headerName: 'AUDIT', field: 'audit_name', menuTabs: [], width: 150},
          {headerName: 'AUDIT CODE', field: 'audit_code', enableRowGroup: true, width: 80},
          {headerName: 'CATEGORY', field: 'category', enableRowGroup: true, width: 80},
          {headerName: 'COMMENTS', field: 'comment', menuTabs: [], width: 150},
          {
            headerName: 'BEFORE', field: 'before', enableRowGroup: true, width: 80,
            valueGetter: this.generateImageUrl('before'),
            cellRenderer(params) {
              if (params.data) {
                if (!params.data.hasOwnProperty('before') || params.data.before === null || params.data.before === "") {
                  return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
                } else {
                  return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
                }
              }
            }
          },
          {headerName: 'B VISIBILITY', field: 'visibility', enableRowGroup: true, width: 80},
          {
            headerName: 'AFTER', field: 'after', enableRowGroup: true, width: 80,
            valueGetter: this.generateImageUrl('after'),
            cellRenderer(params) {
              if (params.data) {
                if (!params.data.hasOwnProperty('after') || params.data.after === null || params.data.after === "") {
                  return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
                } else {
                  return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
                }
              }
            }
          },
          {headerName: 'A VISIBILITY', field: 'after_visibility', enableRowGroup: true, width: 80},
        ]
      }
    ];

    /*this.getmax(this.apiData);

    for (let i = 1; i < this.imageCounter + 1; i++) {
      this.columnDefs.push(
        {
          headerName: "CHECKPOINT " + i.toString(),
          children: [
            {
              headerName: 'BEFORE' , field: 'before_' + i.toString(), enableRowGroup: true, width: 50,
              cellRenderer(params) {
                if (params.data) {
                  if (!params.data.hasOwnProperty('before_' + i.toString()) || params.data['before_' + i.toString()] === null || params.data['before_' + i.toString()] === "") {
                    return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
                  } else {
                    return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
                  }
                }
              },
              valueGetter: params => {
                if (params.data) {
                  if (this.content_url !== null && this.content_url !== "" && params.data.hasOwnProperty('before_' + i.toString())
                    && params.data['before_' + i.toString()] !== null && params.data['before_' + i.toString()] !== "") {
                    return this.content_url + 'res_partner_gallery/' + params.data['before_' + i.toString()];
                  } else {
                    return params.data['before_' + i.toString()];
                  }
                }
              }
            },
            {
              headerName: 'AFTER', field: 'after_' + i.toString(), enableRowGroup: true, width: 50,
              cellRenderer(params) {
                if (params.data) {
                  if (!params.data.hasOwnProperty('after_' + i.toString()) || params.data['after_' + i.toString()] === null || params.data['after_' + i.toString()] === "") {
                    return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
                  } else {
                    return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
                  }
                }
              },
              valueGetter: params => {
                if (params.data) {
                  if (this.content_url !== null && this.content_url !== "" && params.data.hasOwnProperty('after_' + i.toString())
                    && params.data['after_' + i.toString()] !== null && params.data['after_' + i.toString()] !== "") {
                    return this.content_url + 'res_partner_gallery/' + params.data['after_' + i.toString()];
                  } else {
                    return params.data['after_' + i.toString()];
                  }
                }
              }
            }
          ]
        }
      );
    }*/

    /* const count = this.mode(this.apiData);

     for (let i = 0; i < count; i++) {
       this.columnDefs.push(
         {headerName: 'BEFORE ' + count.toString(), field: 'before_' + count.toString(), enableRowGroup: true, width: 80},
         {headerName: 'AFTER ' + count.toString(), field: 'after_' + count.toString(), enableRowGroup: true, width: 80}
       );
     }

     this.apiData.forEach(function(obj) {
       const key = obj.id;
       this.imageCounter[key] = (this.imageCounter[key] || 0) + 1;
     });*/
    //  this.imageCounter.sort((a, b) => b - a);
  }

  getmax(array) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].before !== null && array[i].before.length > this.imageCounter) {
        this.imageCounter = array[i].before.length;
      }
    }
  }

  /* mode(array) {
     if (array.length === 0) {
       return null;
     }
     const modeMap = {};
     let maxEl = array[0].id;
     let maxCount = 1;
     for (let i = 0; i < array.length; i++) {
       const el = array[i].id;
       if (modeMap[el] == null) {
         modeMap[el] = 1;
       } else {
         modeMap[el]++;
       }
       if (modeMap[el] > maxCount) {
         maxEl = el;
         maxCount = modeMap[el];
       }
     }
     return maxCount;
   }*/

  /*loadserverdata() { this.goClicked = true;
    this.dataLoaded = false;
    this.preloader = true;
    this.emptyState = false;
    this.errorExists = false;

    if (this.API_URL === '') {
      return this.handleError(ErrorHandler.getErrorObject('API Not Configured'));
    }

    if (this.showCustomFilter) {
      if (this.userData.customFilter != null && !this.userData.customFilter.hasOwnProperty(this.customFilter.key)) {
        this.userData.customFilter[this.customFilter.key] = this.customFilter.value;
      }
    }

    this.apiService.post(this.API_URL, this.userData)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.apiData = res.results.data;
            this.configureGrid();
            this.configureData();
            this.dataLoaded = true;
            this.preloader = false;
            this.emptyState = (this.rowData.length === 0);
          } else if (res.results.status === 203) {
            this.handleError(ErrorHandler.getErrorObject(res.results.status));
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        // tslint:disable-next-line:no-shadowed-variable
        error => this.handleError(ErrorHandler.getErrorObject(error)));
  }*/

  configureData() {

    this.rowData = [];
    const innerthis = this;
    this.apiData.forEach(obj => {
      let indrow: any = {};
      indrow = obj;
      if (obj.before !== null) {
        for (let i = 1; i < obj.before.length + 1; i++) {
          indrow['before_' + i.toString()] = obj.before[i - 1];
        }
      }
      if (obj.after !== null) {
        for (let i = 1; i < obj.after.length + 1; i++) {
          indrow['after_' + i.toString()] = obj.after[i - 1];
        }
      }
      innerthis.rowData.push(indrow);
    });
  }

  onCellClicked(event) {

    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    console.log(column);
    console.log(rows);
    if (column === 'before' || column === 'after') {
      if (rows != null && rows.length > 0 && rows[0].hasOwnProperty(column) && rows[0][column] != null && rows[0][column] !== "") {
        if (screen.width > 991) {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "70%",
            maxHeight: "95%",
            data: {
              store_fname: rows[0][column],
              container: "res_partner_gallery"
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        } else {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "90%",
            maxHeight: "65%",
            data: {
              store_fname: rows[0][column],
              container: "res_partner_gallery"
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        }


      } else {

      }

    } else {
    }


  }

}
