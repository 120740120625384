<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper">
      <div>
        <div class="col-md-6 col-12 nopadding pl-0">
        </div>
        <div class="col-md-6 col-12 pad0 text-right">
          <div class="button-row" style="margin-bottom: 10px;">
             <span *ngIf="!showAll">
                  <a class="btn white-btn" (click)="showReports()" style="margin-right:15px;background-color: #FFFFFF">SHOW ALL</a>
             </span>
            <span *ngIf="showAll">
                  <a class="btn white-btn" (click)="showReports()" style="margin-right:15px;background-color: #FFFFFF">HIDE ALL</a>
             </span>
            <span>
                  <a class="btn white-btn" style="margin-right:15px;background-color: #FFFFFF"
                     (click)="gotoDownloads()">DOWNLOADS
                     <span style="margin-left: 2px;"><i class="far fa-arrow-to-bottom"></i></span></a>

            </span>
          </div>
        </div>
        <div class="col-md-12 col-12 nopadding pl-0">
          <div *ngFor="let c of reportCategories;">
            <div class="cardbox" style="border-radius: 10px; margin-bottom: 10px;cursor: pointer;"

                 [ngClass]="{'audit': c.category === 'Retail Audit' && !c.show,
                             'survey': c.category === 'Survey' && !c.show,
                             'sales': c.category === 'Sales' && !c.show,
                             'finance': c.category === 'Finance' && !c.show,
                             'master': c.category === 'Master' && !c.show,
                             'productivity': c.category === 'Productivity' && !c.show,
                             'stock': c.category === 'Stock' && !c.show,
                             'leads': c.category === 'Leads' && !c.show,
                             'compliance': c.category === 'Compliance' && !c.show,
                             'multiinstance': c.category === 'Multi Instance' && !c.show,
                             'scheme': c.category === 'Scheme' && !c.show,
                             'targets': c.category === 'Targets' && !c.show,
                             'kpi': c.category === 'KPI' && !c.show
                             }"
            >
              <div class="row centeralign" (click)="c.show = !c.show">
                <h3 style="margin-left: 15px;">{{ c.category }}</h3>
                <span *ngIf="c.show"
                      style="position: absolute;right: 30px;">
                  <img src="assets/up_arrow.svg" width="15px"/>
                </span>
                <span *ngIf="!c.show"
                      style="position: absolute;right: 30px;">
                  <img src="assets/down_arrow.svg" width="15px"/>
                </span>
              </div>
              <div class="eod-list-carda" *ngIf="c.show">
                <!--<span style="display: block; font-weight: 400; font-size: 18px;margin-bottom: 5px;margin-left: 15px;">{{c}}</span>-->
                <div style="margin-top: 15px;">
                  <div class="cardgrid">
                    <ng-container *ngFor="let r of filterReport(c.category); let i = index" class="col-md-3 eod-width">
                      <app-reportcard [title]="r.title"
                                      [id]="r.id"
                                      [code]="r.code"
                                      [type]="r.category"
                                      (reportClick)="cardReportClick(r.route)">

                      </app-reportcard>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
