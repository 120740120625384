import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {GridsterItem} from 'angular-gridster2';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-widget-multigrid',
  template: `
    <div class="info-box">
      <span class="heading">{{widget.data.label}}</span>
        <ng-container *ngFor="let value of widget.data.value; let i = index">
          <div style="padding: 5px; margin: 5px; background: #F8F8F8">
            <span class="heading">{{value['heading']}}</span>
            <div class="cardgriddash" >
              <ng-container *ngFor="let data of widget.data.data; let i = index">
                <div *ngIf="data['mapping_key'] !== 'heading'">
                  <span class="value">{{value[data.key]}}</span>
                  <span class="gridtext">{{data.label}}</span>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
    </div>`,
  styleUrls: ['./../dashboard.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class MultiGridWidgetComponent implements OnInit, OnDestroy {
  @Input()
  widget;
  @Input()
  resizeEvent: EventEmitter<GridsterItem>;

  resizeSub: Subscription;

  ngOnInit(): void {
    this.resizeSub = this.resizeEvent.subscribe(widget => {
      if (widget === this.widget) {
        // or check id , type or whatever you have there
        // resize your widget, chart, map , etc.
        console.log(widget);
      }
    });

  }

  ngOnDestroy(): void {
    this.resizeSub.unsubscribe();
  }
}
