import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {MatDialogConfig} from "@angular/material/dialog";
import {ImageGalleryPopupComponent} from "../../components/image_gallery_popup/image_gallery_popup.component";

@Component({
  selector: 'app-partner-merchandise-report-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class PartnerMerchandiseReportComponent extends ReportBase implements IReport, OnInit {

  public attributeTypes = [];
  apiData: any = [];
  uqTypes;

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Partner Merchandise Report');
    this.showDate = true;
    this.sharedService.statusConfig.success = 'Adherent';
    this.sharedService.statusConfig.error = 'Not Adherent';
    this.sharedService.statusConfig.warning = 'Fixable';

    this.loadInBatches(10000, 'master_id');
    this.showProgressCustomText = true;
    this.API_URL = '/api/pwa_v2_reports/getPartnerMerchandiseReport';
  }

  configureGrid() {
    const inThis = this;
    this.columnDefs = [
      {
        headerName: "Partner Details",
        children: [
          {headerName: 'NAME', field: 'partner_name', width: 80},
          {headerName: 'ID', field: 'partner_id', enableRowGroup: true, width: 80},
          {headerName: 'CODE', field: 'partner_code', enableRowGroup: true, width: 80},
          {headerName: 'PARTNER TYPE', field: 'partner_type', width: 80},
          {headerName: 'OUTLET TYPE', field: 'outlet_type', enableRowGroup: true, width: 80},
          {headerName: 'PROGRAM TYPE', field: 'program_type', enableRowGroup: true, width: 80},
          {headerName: 'CLASS', field: 'partner_class', enableRowGroup: true, width: 80},
          {headerName: 'CITY', field: 'partner_city', enableRowGroup: true, width: 80},
          {headerName: 'STATE', field: 'partner_state', enableRowGroup: true, width: 80},
          {headerName: 'TERRITORY', field: 'partner_territory', enableRowGroup: true, width: 80},
          // {headerName: 'BEAT', field: 'partner_beat', width: 80}
        ]
      },
      {
        headerName: "Employee Details",
        children: [
          {headerName: 'EMP ID', field: 'emp_id', width: 80},
          {headerName: 'EMP CODE', field: 'emp_code', width: 80},
          {headerName: 'EMP NAME', field: 'emp_name', width: 80},
          {headerName: 'SAC', field: 'emp_sac', width: 80},
          {headerName: 'ROLE', field: 'emp_role', width: 80},
          {headerName: 'TEAM', field: 'emp_team', enableRowGroup: true, width: 80},
        ]
      },
      {
        headerName: "Planogram Details",
        children: [
          {headerName: 'ID', field: 'master_id', width: 80},
          {headerName: 'SCORE', field: 'master_score', width: 80},
          {headerName: 'DATE', field: 'master_day', width: 80},
          {headerName: 'COMMENTS', field: 'master_comments', width: 80},
          {headerName: 'DURATION', field: 'master_duration', width: 80},
          {
            headerName: 'START TIME', field: 'master_start', width: 80,
            valueGetter: params => {
              if (params.data) {
                if (params.data.master_start) {
                  return inThis.datePipe.transform((new Date(params.data.master_start + ' UTC')).toString(), 'yyyy-MM-dd HH:mm');
                } else {
                  return 'NA';
                }
              }
            }
          },
          {
            headerName: 'END TIME', field: 'master_end', width: 80,
            valueGetter: params => {
              if (params.data) {
                if (params.data.master_end) {
                  return inThis.datePipe.transform((new Date(params.data.master_end + ' UTC')).toString(), 'yyyy-MM-dd HH:mm');
                } else {
                  return 'NA';
                }
              }
            }
          }
        ]
      }, {
        headerName: "Checkpoint Details",
        children: [
          {headerName: 'ID', field: 'merc_id', width: 80},
          {headerName: 'NAME', field: 'merc_name', enableRowGroup: true, width: 80},
          {headerName: 'TYPE', field: 'merc_type', enableRowGroup: true, width: 80},
          {
            headerName: 'COMPLIANCE', field: 'merc_adherence', width: 80, aggFunc: this.avgAggFunction,
            valueGetter(params) {
              if (params.data) {
                if (params.data.merc_adherence) {
                  return 100;
                } else if (params.data.merc_fixable) {
                  return 50;
                } else {
                  return 0;
                }
              }
            }
          },
          {
            headerName: 'STATUS', field: 'merc_adherence', width: 80,
            valueGetter(params) {
              if (params.data) {
                if (params.data.merc_adherence) {
                  return 'Adherent';
                } else if (params.data.merc_fixable) {
                  return 'Fixable';
                } else {
                  return 'Not Adherent';
                }
              }
            }, cellRenderer(params) {
              if (params.data) {
                if (params.data.merc_adherence) {
                  return '<span class="ml-1 status2" style="background-color:#E1F0E7; border:#E1F0E7; color: #379862; font-weight: bold;" >' + params.value + '</span>';
                } else if (params.data.merc_fixable) {
                  return '<span class="ml-1 status2" style="background-color:#FEF2DF; border:#FEF2DF; color: #F6A82C; font-weight: bold;">' + params.value + '</span>';
                } else {
                  return '<span class="ml-1 status2" style="background-color:#FBE3E7; border:#FBE3E7; color: #E24260; font-weight: bold;">' + params.value + '</span>';
                }
              }
            }
          },
          {
            headerName: 'ACTIVITY TIME', field: 'merc_start', width: 80,
            valueGetter: params => {
              if (params.data) {
                if (params.data.merc_start) {
                  return inThis.datePipe.transform((new Date(params.data.merc_start + ' UTC')).toString(), 'yyyy-MM-dd HH:mm');
                } else {
                  return 'NA';
                }
              }
            }
          },
          {headerName: 'ADHERENT', field: 'merc_adherence', width: 80},
          {headerName: 'REASON', field: 'merc_reason', width: 80},
          {headerName: 'FIXABLE', field: 'merc_fixable', width: 80},
          {
            headerName: 'REFERENCE', field: 'merc_reference', width: 80,
            cellRenderer(params) {
              if (params.data) {
                if (!params.data['merc_reference'] || params.data['merc_reference'] === "") {
                  return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
                } else {
                  if (params.data['merc_ref_type'] && params.data['merc_ref_type'] === 'pdf') {
                    return '<img style="padding:2px; width: 35px;" src="./../../../assets/pdf_icon.png"></img>';
                  } else {
                    return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
                  }
                }
              }
            }
          },
          {
            headerName: 'BEFORE IMG', field: 'merc_before_img', width: 80,
            cellRenderer(params) {
              if (params.data) {
                if (!params.data['merc_before_img'] || params.data['merc_before_img'] === "") {
                  return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
                } else {
                  return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
                }
              }
            }
          },
          {
            headerName: 'AFTER IMG', field: 'merc_after_img', width: 80,
            cellRenderer(params) {
              if (params.data) {
                if (!params.data['merc_after_img'] || params.data['merc_after_img'] === "") {
                  return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
                } else {
                  return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
                }
              }
            }
          },
          {headerName: 'COMMENTS', field: 'merc_comments', width: 80},
        ]
      }
    ];
  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }


  configureData() {
    this.rowData = this.apiData;
    console.log(this.rowData);
  }

  onCellClicked(event) {

    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    if (column === 'merc_reference' && rows[0].merc_ref_type && rows[0].merc_ref_type === 'pdf') {
      this.fetchPDFImage(rows[0].merc_reference);
    } else {
      if (rows[0]['merc_reference'] || rows[0]['merc_before_img'] || rows[0]['merc_after_img']) {
        this.showImagePopup(rows[0]);
      }
    }
  }

  showImagePopup(row) {
    const gallery = [];
    if (row['merc_reference'] && row['merc_ref_type'] === 'img') {
      gallery.push({store_fname: row['merc_reference'],
        container: 'retail_planograms'});
    }
    if (row['merc_before_img']) {
      gallery.push({store_fname: row['merc_before_img'],
        container: 'res_partner_gallery'});
    }
    if (row['merc_after_img']) {
      gallery.push({store_fname: row['merc_after_img'],
        container: 'res_partner_gallery'});
    }
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "70%",
        maxHeight: "95%",
        data: {
          gallery
        }
      };

      const creatediaeref = this.dialog.open(ImageGalleryPopupComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        data: {
          gallery
        }
      };

      const creatediaeref = this.dialog.open(ImageGalleryPopupComponent, config);
    }
  }

  async fetchPDFImage(path) {
    const res = await this.apiService.fetchExpenseImage({
      access_token: localStorage.getItem('resfreshToken'),
      url: localStorage.getItem('private_url'),
      data: [{store_fname: path, container: 'retail_planograms'}]
    });
    if (res.hasOwnProperty('result') && res['result'].length > 0) {
      const pdf =  this.domSanitizer.bypassSecurityTrustUrl('data:application/pdf;base64, ' + res['result'][0].bill_datas);
      this.openPDFinNewTab(pdf);
    }
  }

  openPDFinNewTab(pdf) {
    if (pdf && pdf !== 'null') {
      let pdfSrc = pdf.toString();
      pdfSrc = pdfSrc.replace('SafeValue must use [property]=binding: data:application/pdf;base64, ', '');
      pdfSrc = pdfSrc.replace(' (see http://g.co/ng/security#xss)', '');
      let byteCharacters;
      if (pdfSrc === "null") {
        console.log(pdf);
        this.toastr.error("Invalid PDF");
      } else {
        try {
          byteCharacters = atob(pdfSrc);
        } catch (e) {
          console.log(pdf);
          this.toastr.error("Invalid PDF");
        }
        if (byteCharacters) {
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const file = new Blob([byteArray], {type: 'application/pdf'});
          const blobUrl = URL.createObjectURL(file );
          window.open(blobUrl);
        }
      }
    } else {
      console.log(pdf);
      this.toastr.error("Invalid PDF");
    }

  }
}
