import {Component, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {ApiService} from "../../_services/api.service";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {LocalforageService} from "../../_services/localforage.service";
import {ErrorHandler} from "../../error/error-handler";
import {ErrorObject} from "../../error/error-model";

@Component({
  selector: 'app-move-beat-retailer-popup',
  templateUrl: './move_beat_retailer_popup.component.html',
  styleUrls: ['./move_beat_retailer_popup.component.scss']

})

export class MoveBeatRetailerPopupComponent implements OnInit {

  @Output() saveData: EventEmitter<any> = new EventEmitter<any>();

  selectedData = {
    salesman_id: 0,
    territory_id: 0,
    beat_plan_id: 0,
    bpr_ids: [],
    retailer_ids: [],
    from_beat_plan_ids: []
  };

  territoriesList: any = [];
  filteredTerritoryList = [];
  salesmenList: any = [];
  beatPlanList: any = [];
  filteredBeatList = [];
  API_URL = '';
  access_token;
  retailerData;
  uq_ret;
  uq_bp;
  uq_ter;

  noBeatArray = [{id: 0, name : "No beat for selected Salesman"}];

  preloader: any;
  errorObject: ErrorObject;
  errorExists = false;
  dataLoaded: any;
  type = '';

  @Output() saveActionEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(private apiService: ApiService,
              public dialog: MatDialog,
              private toastr: ToastrService,
              protected _localforageService: LocalforageService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {

    this.type = dialogdata.type;
    this.retailerData = dialogdata.retailerData;
    this.selectedData.retailer_ids = [...new Set(this.retailerData.map(obj => obj.r_id))];
    this.selectedData.from_beat_plan_ids = [...new Set(this.retailerData.map(obj => obj.bp_id))];
    this.selectedData.bpr_ids = [...new Set(this.retailerData.map(obj => obj.bpr_id))];
    let uq_salesman_ids: any = [];
    uq_salesman_ids = [...new Set(this.retailerData.map(obj => obj.salesman_id))];

    this.uq_ret = dialogdata.uq_ret;
    this.uq_bp = dialogdata.uq_bp;
    this.uq_ter = dialogdata.uq_ter;

    console.log(this.selectedData);
    if (localStorage.getItem('all_salesmans') !== null && localStorage.getItem('all_salesmans') !== 'null' && JSON.parse(localStorage.getItem('all_salesmans')) !== null) {
      this.salesmenList = JSON.parse(localStorage.getItem('all_salesmans'));
      this.salesmenList.sort((a, b) => a.itemname.localeCompare(b.itemame));
      console.log(this.salesmenList);
    }

    if (localStorage.getItem('all_territories') !== null && localStorage.getItem('all_territories') !== 'null' && JSON.parse(localStorage.getItem('all_territories')) !== null) {
      this.territoriesList = JSON.parse(localStorage.getItem('all_territories'));
      this.territoriesList.sort((a, b) => a.itemname.localeCompare(b.itemname));
    }
    this.access_token = localStorage.getItem('resfreshToken');

    if (uq_salesman_ids.length > 0) {
      this.selectedData.salesman_id = uq_salesman_ids[0];
    } else if (this.salesmenList !== null && this.salesmenList !== undefined && this.salesmenList.length > 0) {
      this.selectedData.salesman_id = this.salesmenList[0].id;
    }

    // if (this.territoriesList !== null && this.territoriesList !== undefined && this.territoriesList.length > 0) {
    //   this.selectedData.territory_id = this.territoriesList[0].id;
    // }

    console.log('constructor');
  }

  ngOnInit(): void {
    this.getBeatPlans();
  }

  getBeatPlans() {
    this._localforageService.get('beat-plan-Dropdown-list').then(data => {

      if (data == null) {
        const paramObject: any = {};
        paramObject.access_token = localStorage.getItem('resfreshToken');
        this.API_URL = '/api/beat_plans/get_beat_plans_list_for_dropdown';
        this.apiService.post(this.API_URL, paramObject)
          .subscribe(res => {


              // t.stop();
              if (res.hasOwnProperty('results') && (res.results.status === 200)) {
                this.beatPlanList = res.results.data;

                this._localforageService.set('beat-plan-Dropdown-list', this.beatPlanList).then(r => {
                  this.preloader = false;
                  this.dataLoaded = true;
                  this.populateSelectedSalemanBeatsNterritories();
                  // this.filteredBeatList = this.beatPlanList.filter(salesman => salesman.salesman_id === this.selectedData.salesman_id);
                });

              } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
                this.handleError(ErrorHandler.getErrorObject(res.results.msg));
              } else {
                this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
              }
            },
            error => this.handleError(ErrorHandler.getErrorObject(error)));

      } else {
        this.beatPlanList = data;
        this.populateSelectedSalemanBeatsNterritories();
        // this.filteredBeatList = this.beatPlanList.filter(salesman => salesman.salesman_id === this.selectedData.salesman_id);
      }
    }).catch(err => {
      // This code runs if there were any errors
      console.log(err);
    });
  }

  public handleError(error: ErrorObject) {
    this.preloader = false;
    this.dataLoaded = false;
    this.errorExists = true;
    this.errorObject = error;
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  onSave() {
    const data: any = {};
    data.retailer_ids = this.selectedData.retailer_ids;
    data.beat_plan_id = this.selectedData.beat_plan_id;
    data.move_flag = (this.type === 'Move');
    data.bpr_ids = this.selectedData.bpr_ids;
    data.beat_plan_ids = this.selectedData.from_beat_plan_ids;
    this.saveData.emit(data);
  }

  moveBeatRetailers() {

    this.API_URL = '/api/beat_plan_retailers/move_beat_retailers';
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {};
    paramObject.data.retailer_ids = this.selectedData.retailer_ids;
    paramObject.data.beat_plan_id = this.selectedData.beat_plan_id;
    paramObject.data.move_flag = (this.type === 'Move');
    paramObject.data.bpr_ids = this.selectedData.bpr_ids;
    paramObject.data.beat_plan_ids = this.selectedData.from_beat_plan_ids;

    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            // this.dialog.closeAll();
            // window.location.reload();
            this.saveData.emit('success');
          } else {
            if (this.type === 'Move') {
              this.toastr.error('Error moving retailers');
            } else {
              this.toastr.error('Error copying retailers');
            }
          }
        },
        error => {
          console.log(error);
          if (this.type === 'Move') {
            this.toastr.error('Error moving retailers');
          } else {
            this.toastr.error('Error copying retailers');
          }
        });
  }

  onSalesmanChange() {
    this.filteredBeatList = [];
    this.filteredTerritoryList = [];
    this.populateSelectedSalemanBeatsNterritories();
  }

  public populateSelectedSalemanBeatsNterritories() {
    const salesman_id = parseInt(String(this.selectedData.salesman_id), 0);
    const filteredTerritoryForSalesman = this.beatPlanList.filter(salesman => salesman.salesman_id === salesman_id);
    const uniqueTerritories = [...new Set(filteredTerritoryForSalesman.map(obj => obj.territory_id))];
    this.filteredTerritoryList = this.territoriesList.filter(el => {
      return uniqueTerritories.some(t => {
        return t === el.id;
      });
    });

    this.selectedData.territory_id = 0;
    if (this.filteredTerritoryList.length > 0) {
      this.selectedData.territory_id = this.filteredTerritoryList[0].id;
    }
    this.populateSelectedSalemanBeats();
  }

  public populateSelectedSalemanBeats() {
    const salesman_id = parseInt(String(this.selectedData.salesman_id), 0);
    const territory_id = parseInt(String(this.selectedData.territory_id), 0);
    this.filteredBeatList = this.beatPlanList.filter(beat => beat.salesman_id === salesman_id && beat.territory_id === territory_id);

    if (this.filteredBeatList.length === 0) {
      this.selectedData.beat_plan_id = null;
    } else {
      this.selectedData.beat_plan_id =  this.filteredBeatList[0].id;
    }

  }

}
