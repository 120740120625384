<div class="common-wrapper">
        <div class="common-container">
          <div class="main-wrapper pnf">
            <div class="page-not-found">
                <div class="pnf-img">
                    <img src="../../assets/pagenot.png">
                </div>
                <div class="pnf-text">
                    <label>Page not Found</label>
                    <p>We’re unable to find the page you’re looking for.</p>
                    <a (click)="home()" style="cursor: pointer">Go Home</a>
                </div>
            </div>
        </div>
      </div>
