import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { GridOptions } from "@ag-grid-enterprise/all-modules";
import { ApiService} from "../../_services/api.service";
import { Router, ActivatedRoute  } from '@angular/router';
import { ColorCellRenderer } from "../../colorCell.component";
import { DatePipe } from '@angular/common';
import { LoadingstateComponent} from "../../components/loadingstate/loadingstate.component"
import {ReportBase} from "../_common/reportbase";

@Component({
  selector: 'app-scheme_report-report',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})
export class SchemeReportComponent extends ReportBase implements IReport, OnInit {

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    this.sharedService.setHeader('Scheme Report');
    this.myDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.configureGrid();
    this.loadServerData();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_connects/scheme_report';
    const inThis = this;
    this.columnDefs = [
      {
        headerName: 'Date', field: 'schedule_date', width: 100,
        valueGetter: params => {
          if (params.data) {
            if (params.data.schedule_date) {
              return inThis.datePipe.transform((new Date(params.data.schedule_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return params.data.schedule_date;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: 'DC', field: 'dc', width: 100},
      {headerName: 'Van', field: 'van', width: 100},
      {headerName: 'TI', field: 'ti', width: 100},
      {headerName: 'Beat', field: 'beat', width: 100},
      {headerName: 'Scheme ID', field: 'scheme_id', width: 100},
      {headerName: 'Scheme', field: 'name', width: 100},
      {headerName: 'Outlet Code', field: 'outlet_code', width: 100},
      {headerName: 'Outlet', field: 'outlet', width: 100},
      {headerName: 'Outlet Type', field: 'outlet_type', width: 100},
      {headerName: 'Invoice', field: 'invoice', width: 100},
      {headerName: 'Order Ref', field: 'invoice_id', width: 100},
      {headerName: 'Product Code', field: 'code', width: 100},
      {headerName: 'Product', field: 'product', width: 100},
      {headerName: 'Purchase QTY', field: 'purchase_qty', width: 100},
      {headerName: 'Free QTY', field: 'free_qty', width: 100},
      {headerName: 'Discount', field: 'discount', width: 100}
    ];
  }
}
