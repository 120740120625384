<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" style="float: right;background-color:#FDE0D4;">
        <i class="fas fa-times"></i>
      </button>
      <h2>Approve</h2>
    </div>
    <div class="body-section">
      <div class="user-input-wrp" style="margin-top: 40px;">
        <br/>
        <input
          id="partial-approve-text-box"
          type="text"
          class="inputText"
          name="approvedqty"
          [(ngModel)]="approved_qty"
          #approvedqty="ngModel"
          (input)="onQtyChange()"
          required/>
        <span class="floating-label">Approved Quantity (Max : {{qty}})</span>
      </div>
      <span *ngIf="amountExceeded" class="text-danger">Amount exceeds Claim amount</span>
      <div style="margin-top: 20px;">
        <button [disabled]="amountExceeded" (click)="performAction()" data-dismiss="modal" class="btn blue-btn">Submit</button>
      </div>
    </div>
  </div>
</section>
