import {Component, ElementRef, Inject, OnInit} from '@angular/core';
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer} from "@angular/platform-browser";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-return-approve-popup',
  templateUrl: './return_approve_popup.component.html'
})

export class ReturnApprovePopupComponent implements OnInit {

  API_URL = '';
  stockData;
  productData;
  dataLoaded = false;
  public columnDefs = [];
  public rowData = [];
  public gridOptions: GridOptions;
  public preloader = false;
  public emptyState = false;
  context;
  public gridColumnApi: any;
  public gridApi: any;
  vansale_id = 0;
  public refreshDlg = false;

  constructor(private apiService: ApiService,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private toastr: ToastrService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.stockData = dialogdata.stockData;
    this.productData = dialogdata.productData;
    this.vansale_id = dialogdata.vansale_id;
    this.context = {componentParent: this};
    this.gridOptions = {
      rowHeight: 35,
      rowSelection: 'multiple',
      enableRangeSelection: true,
      enableCharts: true,
      chartThemes: ['ag-pastel-dark'],
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      rowClassRules: {
        'ag-grid-grey': (params) => {
          return params.node.rowIndex % 2 === 0;
        },
      }
    } as GridOptions;
    this.configureGrid();
    this.configureData();
  }

  ngOnInit(): void {
  }

  configureGrid() {
    this.columnDefs = [
      {
        headerName: "PRODUCT", field: "pid", width: 250, cellStyle: {textAlign: "left"},
        valueGetter: params => {
          if (params.data) {
            return params.context.componentParent.productData[params.data.pid];
          }
        }
      },
      {headerName: "QTY", field: "qty", width: 90},
      {
        headerName: "REJECTED", field: "rejected",  width: 90,
        editable(params) {
          if (params.data) {
            if (params.data.qty !== null && params.data.qty !== undefined && params.data.qty > 0) {
              return true;
            } else {
              return false;
            }
          }
        }
      },
      {
        headerName: "REASON", field: "reason",  width: 250,
        editable(params) {
          if (params.data) {
            if (params.data.qty !== null && params.data.qty !== undefined && params.data.qty > 0) {
              return true;
            } else {
              return false;
            }
          }
        }
      },
    ];
  }

  configureData() {
    this.rowData = [];
    for (const item in this.stockData[0]) {
      if (item !== 'LABEL') {
        const indItem: any = {};
        indItem.pid = item;
        let qty1 = this.stockData.find(existed => existed['LABEL'] === 'Return');
        if (qty1 !== null && qty1 !== undefined) {
          qty1 = parseInt(qty1[item], 0);
        } else {
          qty1 = 0;
        }
        let qty2 = this.stockData.find(existed => existed['LABEL'] === 'Damage');
        if (qty2 !== null && qty2 !== undefined) {
          qty2 = parseInt(qty2[item], 0);
        } else {
          qty2 = 0;
        }
        let lst_price = this.stockData.find(existed => existed['LABEL'] === 'unit_price');
        if (lst_price !== null && lst_price !== undefined) {
          lst_price = parseInt(lst_price[item], 0);
        } else {
          lst_price = 0;
        }

        let rejected = this.stockData.find(existed => existed['LABEL'] === 'Rejected');
        if (rejected !== null && rejected !== undefined) {
          rejected = parseInt(rejected[item], 0);
        } else {
          rejected = 0;
        }

        let rej_reason = this.stockData.find(existed => existed['LABEL'] === 'rej_reason');
        if (rej_reason !== null && rej_reason !== undefined) {
          rej_reason = rej_reason[item];
        } else {
          rejected = "";
        }
        indItem.qty = qty1 + qty2;
        if (indItem.qty > 0) {
          indItem.rejected = rejected;
          indItem.reason = rej_reason;
          indItem.lst_price = lst_price;
          this.rowData.push(indItem);
        }
      }
    }
    this.dataLoaded = true;
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setRowData(this.rowData);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (params.api) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeAllColumns();
          }
        }
      });
    });

    if (screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeAllColumns();
    }
  }

  onCellValueChanged(event) {
    const colId = event.column.getId();
    if (colId === 'rejected') {
      if (event.newValue !== null && event.newValue !== undefined && event.newValue !== "") {
        event.newValue = event.newValue.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
        if (event.newValue !== null && event.newValue !== undefined && event.newValue !== '') {
          event.newValue = parseInt(event.newValue, 0);
        } else {
          event.newValue = 0;
        }
      } else {
        event.newValue = 0;
      }
      if (parseInt(event.newValue, 0) > event.data.qty) {
        event.newValue = event.data.qty;
      }

      if (event.node !== null) {
        event.data.rejected = event.newValue;
        this.rowData[event.node.rowIndex].rejected = event.data.rejected;
        this.gridApi.setRowData(this.rowData);
      }
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  confirm() {
    const vansaleData: any = {};
    vansaleData.vansale_id = this.vansale_id;
    vansaleData.productData = [];
    let totalBonus = 0;
    let totalRejected = 0;
    let totalPenalty = 0;
    for (const item in this.stockData[0]) {
      if (item !== 'LABEL') {
        const retdata = this.rowData.find(existed => existed.pid === item);


        let saleqty = this.stockData.find(existed => existed['LABEL'] === 'Sale');
        if (saleqty !== null && saleqty !== undefined) {
          saleqty = parseInt(saleqty[item], 0);
        } else {
          saleqty = 0;
        }

        let retqty = this.stockData.find(existed => existed['LABEL'] === 'Return');
        if (retqty !== null && retqty !== undefined) {
          retqty = parseInt(retqty[item], 0);
        } else {
          retqty = 0;
        }

        let damageqty = this.stockData.find(existed => existed['LABEL'] === 'Damage');
        if (damageqty !== null && damageqty !== undefined) {
          damageqty = parseInt(damageqty[item], 0);
        } else {
          damageqty = 0;
        }

        let totreturnqty = 0;
        totreturnqty = retqty + damageqty;

        let lst_price = this.stockData.find(existed => existed['LABEL'] === 'lst_price');
        if (lst_price !== null && lst_price !== undefined) {
          lst_price = parseFloat(lst_price[item]);
        } else {
          lst_price = 0;
        }

        let sale_amount = this.stockData.find(existed => existed['LABEL'] === 'Amount');
        if (sale_amount !== null && sale_amount !== undefined) {
          sale_amount = parseFloat(sale_amount[item]);
        } else {
          sale_amount = 0;
        }

        let ret_amount = this.stockData.find(existed => existed['LABEL'] === 'Return Amount');
        if (ret_amount !== null && ret_amount !== undefined) {
          ret_amount = parseFloat(ret_amount[item]);
        } else {
          ret_amount = 0;
        }

        const incentive = sale_amount - (saleqty * lst_price);
        const ret_incentive = ret_amount - (retqty * lst_price);

        let salebonus = 0;
        salebonus = incentive;
        let returnpenalty = 0;
        returnpenalty = ret_incentive;

        let approved = 0;
        let rejected = 0;
        let rej_reason = '';
        if (retdata !== null && retdata !== undefined) {
          approved = retdata.qty - retdata.rejected;
          rejected = retdata.rejected;
          rej_reason = retdata.reason;
        }
        let rejected_penalty = 0.0;
        rejected_penalty = rejected * lst_price;

        const indbonus = salebonus - returnpenalty - rejected_penalty;
        let indproduct: any = {};
        indproduct = {
          product_id: item.slice(2),
          bonus: indbonus,
          rejected,
          penalty: rejected_penalty,
          rej_reason
        };
        vansaleData.productData.push(indproduct);
        totalBonus += +indbonus;
        totalRejected += +rejected_penalty;
        totalPenalty += rejected_penalty;
      }
    }
    vansaleData.bonus = totalBonus;
    vansaleData.rejected = totalRejected;
    vansaleData.penalty = totalPenalty;
    this.updateVansaleData(vansaleData);
  }

  updateVansaleData(vansaleData) {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = vansaleData;
    this.apiService.post('/api/pwa_connects/update_vansale_bonus', paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success("Bonus updated");
          this.refreshDlg = true;
          this.dialog.closeAll();
        } else {
          this.toastr.error("Manage Returns failed!!");
        }
      }, error => {
        this.toastr.error("Manage Returns failed!!");
      });
  }


}
