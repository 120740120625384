import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss']
})
export class MultiselectComponent implements OnInit {

  itemList = [];
  selectedItems = [];
  settings = {};

  constructor() { }
  ngOnInit() {

    this.itemList = [
      { "id": 1, "itemName": "Product 1" },
      { "id": 2, "itemName": "Product 2" },
    ];

    // this.selectedItems = [
    //   { "id": 1, "itemName": "India" },
    //   { "id": 2, "itemName": "Singapore" },
    //   { "id": 3, "itemName": "Australia" },
    //   { "id": 4, "itemName": "Canada" }];
    this.settings = {
      text: "All Products",
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      classes: "myclass custom-class"
    };
  }

}



