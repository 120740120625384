<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper">
      <div>
        <div class="col-md-12 col-12 nopadding pl-0">
          <div class="eod-list-card">
            <!--<span style="display: block; font-weight: 400; font-size: 18px;margin-bottom: 5px;margin-left: 15px;">{{c}}</span>-->
            <div class="row">
              <div class="eod-list-card-heading">
                <h3>{{customerDetails.name}}</h3>
              </div>
              <div style="position: absolute;right: 30px;">
                <div class="button-row">
                   <span (click)="fetchPreviousData()"><i
                     [ngClass]="{'disabledspan': rowIndex === 0,
                     'enabledspan': rowIndex >0}"
                     class="fal fa-arrow-circle-left fa-2x"></i></span>
                  <span style="margin-left: 5px; margin-right: 5px; margin-bottom: 2px;">{{rowIndex+1}}/{{customerData.length}}</span>
                  <span (click)="fetchNextData()"><i
                    [ngClass]="{'disabledspan': rowIndex === customerData.length-1,
                     'enabledspan': rowIndex < customerData.length-1}"
                    class="fal fa-arrow-circle-right fa-2x"></i></span>
                </div>
              </div>
              <!--  <div style="position: absolute;right: 30px;">
                  <div *ngIf="edit" class="button-row">
                    <button
                      mat-button
                      style="margin-right:15px;"
                      class="btn teal-btn"
                    >
                      SAVE
                    </button>
                    <span>
                           <a class="btn white-btn" (click)="discardClick()">DISCARD</a>
                        </span>
                  </div>
                  <div *ngIf="!edit" class="button-row">
                        <span>
                           <a class="btn white-btn" style="margin-right:15px;" (click)="editClick()">EDIT</a>
                        </span>
                  </div>
                </div>-->
            </div>

            <div class="eod-list-card-desc">
              <span style="color:#5D5D5D">Code : {{customerDetails.code}}</span>
            </div>
            <p style="border-bottom: 2px solid #f3f3f3; margin-top: 10px;"></p>
            <div class="eod-list-card-desc" style="width: 100%;">
              <div class="col-md-12 col-12 nopadding pl-0">
                <div class="col-md-2 pad0">
                  <label style="color:#7D7D7D">Mobile</label>
                  <p>{{customerDetails.mobile}}</p>
                </div>
                <div class="col-md-2 pad0">
                  <label style="color:#7D7D7D">Classification</label>
                  <p>{{customerDetails.class_type}}</p>
                </div>
                <div class="col-md-2 pad0">
                  <label style="color:#7D7D7D">Retailer Type</label>
                  <p>{{customerDetails.type}}</p>
                </div>
                <div class="col-md-2 pad0">
                  <label style="color:#7D7D7D">Pricelist</label>
                  <p>{{customerDetails.pricelist}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-12 nopadding pl-0">
          <div>
            <mat-tab-group (selectedTabChange)="changeActiveTab($event)">
              <mat-tab label="Overview">
              </mat-tab>
              <mat-tab label="Gallery">
              </mat-tab>
              <mat-tab label="Attributes">
              </mat-tab>
              <mat-tab label="Change Request">
              </mat-tab>
              <mat-tab label="Scheme">
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
        <div  *ngIf="activetab==='Gallery'" class="col-md-12 col-12 nopadding pl-0" style="margin: 10px;">
          <!-- <div class="col-md-12 pad0" style="padding-right: 10px;">
             <div class="eod-list-card">
               <div class="eod-list-card-heading">
                 <h3 style="font-size: 16px;">GALLERY</h3>
               </div>
               <div class="eod-list-card-desc">
                 <div class="row" style="margin-top:20px;">
                   <div style="display: inline;" >
                     <ng-container *ngFor="let tile of galleryphotodata">
                       <img  [src]="tile['photo']" style="height: 150px;width:250px;margin-right: 15px;margin-bottom: 8px;"/>
                     </ng-container>
                   </div>
                 </div>
               </div>
             </div>
           </div>-->
          <div class="col-md-12 pad0" style="padding-right: 10px;">
            <div class="eod-list-card">
              <div class="eod-list-card-heading">
                <h3 style="font-size: 16px;">SHELF ANALYSIS</h3>
              </div>
              <div class="eod-list-card-desc">
                <div class="row" style="margin-top:20px;">
                  <div style="display: inline;" >
                    <ng-container *ngFor="let tile of shelfGalleryData">
                      <img  [src]="tile['photo']" style="height: 150px;width:250px;margin-right: 15px;margin-bottom: 8px;"/>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 pad0" style="padding-right: 10px;">
            <div class="eod-list-card">
              <div class="eod-list-card-heading">
                <h3 style="font-size: 16px;">BTL ACTIVITY</h3>
              </div>
              <div class="eod-list-card-desc">
                <div class="row" style="margin-top:20px;">
                  <div style="display: inline;" >
                    <ng-container *ngFor="let tile of btlGalleryData">
                      <img  [src]="tile['photo']" style="height: 150px;width:250px;margin-right: 15px;margin-bottom: 8px;"/>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div  *ngIf="activetab==='Overview'" class="col-md-12 col-12 nopadding pl-0" style="margin: 10px;">
          <div class="col-md-5 pad0" style="padding-right: 10px;">
            <div class="eod-list-card">
              <div class="eod-list-card-heading">
                <h3 style="font-size: 16px;">ADDRESS & CONTACT</h3>
              </div>
              <div class="eod-list-card-desc">
                <div class="col-md-12 col-12 nopadding pl-0">
                  <div class="col-md-6 pad0">
                    <label style="color:#7D7D7D">Beat</label>
                    <p>{{customerDetails.beatname}}</p>
                  </div>
                  <div class="col-md-6 pad0">
                    <label style="color:#7D7D7D">Email</label>
                    <p>{{customerDetails.email}}</p>
                  </div>
                </div>
                <div class="col-md-12 col-12 nopadding pl-0">
                  <div class="col-md-6 pad0">
                    <label style="color:#7D7D7D">Contact Person</label>
                    <p>{{customerDetails.contact_name}}</p>
                  </div>
                  <div class="col-md-6 pad0">
                    <label style="color:#7D7D7D">Phone</label>
                    <p>{{customerDetails.phone}}</p>
                  </div>
                </div>
                <div class="col-md-12 col-12 nopadding pl-0">
                  <label style="color:#7D7D7D">Address</label>
                  <p>{{customerDetails.address}}</p>
                </div>
              </div>
            </div>
            <div class="eod-list-card">
              <div class="eod-list-card-heading">
                <h3 style="font-size: 16px;">FINANCE</h3>
              </div>
              <div class="eod-list-card-desc">
                <div class="col-md-12 col-12 nopadding pl-0">
                  <div class="col-md-6 pad0">
                    <label style="color:#7D7D7D">Pricelist</label>
                    <p>{{customerDetails.pricelist}}</p>
                  </div>
                  <div class="col-md-6 pad0">
                    <label style="color:#7D7D7D">GSTIN</label>
                    <p>{{customerDetails.gst_id}}</p>
                  </div>
                </div>
                <div class="col-md-12 col-12 nopadding pl-0">
                  <div class="col-md-6 pad0">
                    <label style="color:#7D7D7D">Credit Limit</label>
                    <p>{{customerDetails.credit_limit}}</p>
                  </div>
                  <div class="col-md-6 pad0">
                    <label style="color:#7D7D7D">Payment Mode</label>
                    <p>{{customerDetails.payment_mode}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-7 pad0">
            <div class="eod-list-card">
              <div class="eod-list-card-heading">
                <h3 style="font-size: 16px;">MAP VIEW</h3>
              </div>
              <div class="eod-list-card-desc" style="width:100%">
                <div class="col-md-12 col-12 nopadding pl-0">
                  <div class="col-md-4 pad0">
                    <label style="color:#7D7D7D">Region</label>
                    <p>{{customerDetails.region}}</p>
                  </div>
                  <div class="col-md-4 pad0">
                    <label style="color:#7D7D7D">Cluster</label>
                    <p>{{customerDetails.cluster}}</p>
                  </div>
                  <div class="col-md-4 pad0">
                    <label style="color:#7D7D7D">Territory</label>
                    <p>{{customerDetails.territory}}</p>
                  </div>
                </div>
                <div class="col-md-12 col-12 nopadding pl-0">
                  <div class="map" id="map1" style="margin-top: 20px;">
                    <agm-map
                      [latitude]="latitude"
                      [longitude]="longitude"
                      [zoom]="zoom">
                      <agm-marker [latitude]="latitude" [longitude]="longitude">
                      </agm-marker>
                    </agm-map>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div  *ngIf="activetab==='Attributes'" class="col-md-12 col-12 nopadding pl-0" style="margin: 10px;">
          <div class="col-md-6 pad0" style="padding-right: 10px;">
            <div class="eod-list-card">
              <div class="row">
                <div class="eod-list-card-heading">
                  <h3 style="font-size: 16px;">ATTRIBUTES</h3>
                </div>
                <span *ngIf="!attributeEdit" (click)="attributeEditClick()" title="Edit" style="position: absolute;right: 40px; color: #183153;"><i class="fas fa-pen edit fa-2x" data-action-type="edit"></i></span>
                <span *ngIf="attributeEdit" (click)="attributeEditClick()" title="Edit" style="position: absolute;right: 40px; color: #183153;"><i class="fad fa-save edit fa-2x" data-action-type="edit"></i></span>
              </div>

              <div class="eod-list-card-desc" style="width:100%">
                <div  *ngIf="attributeEdit" style="display: inline-block;margin-top: 5px;margin-right:15px;cursor:pointer;" (click)="addAttributeRow()">
                  <i class="fas fa-plus-circle" style="color:#17A2B8;margin-right: 5px;margin-top:5px;font-size:16px;"></i>
                  <span style="font-size: 16px; font-weight: 400;color:#5D5D5D">Add</span>
                </div>
                <ag-grid-angular *ngIf="dataLoaded" id="myGrid1" style="height: 50vh" class="ag-theme-balham"
                                 [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                                 (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                                 (cellClicked)="onCellClicked($event)"
                                 [context]="context">
                </ag-grid-angular>
              </div>
            </div>
          </div>
          <div class="col-md-6 pad0" style="padding-right: 10px;">
            <div class="eod-list-card">
              <div class="row">
                <div class="eod-list-card-heading">
                  <h3 style="font-size: 16px;">PARAMS</h3>
                </div>
                <span *ngIf="!paramEdit" (click)="paramEditClick()" title="Edit" style="position: absolute;right: 40px; color: #183153;"><i class="fas fa-pen edit fa-2x" data-action-type="edit"></i></span>
                <span *ngIf="paramEdit" (click)="paramEditClick()" title="Edit" style="position: absolute;right: 40px; color: #183153;"><i class="fad fa-save edit fa-2x" data-action-type="edit"></i></span>
              </div>

              <div class="eod-list-card-desc" style="width:100%">
                <div *ngIf="paramEdit" style="display: inline-block;margin-top: 5px;margin-right:15px;cursor:pointer;" (click)="addParamRow()" >
                  <i class="fas fa-plus-circle" style="color:#17A2B8;margin-right: 5px;margin-top:5px;font-size:16px;"></i>
                  <span style="font-size: 16px; font-weight: 400;color:#5D5D5D">Add</span>
                </div>
                <ag-grid-angular *ngIf="dataLoaded1" id="myGrid2" style="height: 50vh" class="ag-theme-balham"
                                 [columnDefs]="columnDefs1" [gridOptions]="gridOptions1"
                                 (gridReady)="onGridReady1($event)" [frameworkComponents]="frameworkComponents1"
                                 (cellClicked)="onCellClicked1($event)"
                                 [context]="context1">
                </ag-grid-angular>
              </div>
            </div>
          </div>
        </div>
        <div  *ngIf="activetab==='Change Request'" class="col-md-12 col-12 nopadding pl-0" style="margin: 10px;">
          <div class="col-md-12 pad0" style="padding-right: 10px;">
            <div class="eod-list-card">
              <!-- <div class="row">
                 <div class="eod-list-card-heading">
                   <h3 style="font-size: 16px;">ATTRIBUTES</h3>
                 </div>
                 <span *ngIf="!attributeEdit" (click)="attributeEditClick()" title="Edit" style="position: absolute;right: 40px; color: #183153;"><i class="fas fa-pen edit fa-2x" data-action-type="edit"></i></span>
                 <span *ngIf="attributeEdit" (click)="attributeEditClick()" title="Edit" style="position: absolute;right: 40px; color: #183153;"><i class="fad fa-save edit fa-2x" data-action-type="edit"></i></span>
               </div>-->

              <div class="eod-list-card-desc" style="width:100%">
                <ag-grid-angular *ngIf="dataLoaded2" id="myGrid3" style="height: 50vh" class="ag-theme-balham"
                                 [columnDefs]="columnDefs2" [gridOptions]="gridOptions2"
                                 (gridReady)="onGridReady2($event)" [frameworkComponents]="frameworkComponents2"
                                 (cellClicked)="onCellClicked2($event)"
                                 [context]="context2">
                </ag-grid-angular>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
