import {Component, OnInit} from "@angular/core";
import {PageBase} from "../../pagebase/pagebase";

@Component({
  selector: 'app-leads',
  templateUrl: '../../pagebase/pagebase.html',
  styleUrls: ['../../pagebase/pagebase.scss']
})


export class PartnerApproveComponent extends PageBase implements OnInit {

  showMultiFilter = true;
  showCustomFilter = true;
  multiFilter: any = {
    name: 'Type',
    key: 'type',
    value: 'all',
    values: [],
    show: true
  };
  customFilter: any = {
    name: 'Verified',
    key: 'type',
    value: 'not_verified',
    values: [],
    show: true
  };

  ngOnInit(): void {

    this.sharedService.setHeader('Approve Partner');

    this.showableFilters.region = true;
    this.showableFilters.territory = true;
    this.showableFilters.salesman = true;
    this.showableFilters.team = true;

    this.customFilter.values = [
      {id: "not_verified", itemName: "Not Verified"},
      {id: "verified", itemName: "Verified"},
    ];

    this.multiFilter.values = [
      {id: "all", itemName: "All"},
      {id: "retailer", itemName: "Retailer"},
      {id: "supplier", itemName: "Supplier"},
      {id: "direct_dealer", itemName: "Direct Dealers"},
      {id: "shipping_address", itemName: "Shipping Address"},
     /* {id: "bill_addr", itemName: "Billing Address"},*/
      {id: "influencer", itemName: "Influencer"},
      {id: "project", itemName: "Project"},
    ];

    this.configureGrid();
   // this.loadServerData();
  }


  configureGrid() {
    this.API_URL = '/api/res_partners/get_partners_with_verification';
    const inthis = this;
    this.columnDefs = [
      {headerName: "CR TYPE", field: "partner_type", width: 80},
      {headerName: "ID", field: "id", width: 80},
      {headerName: "CODE", field: "code", width: 80},
      {headerName: "NAME", field: "name", width: 100},
      {headerName: "TYPE", field: "o_type", width: 100},
      {headerName: "ADDRESS", field: "address", width: 100},
      {headerName: "CITY", field: "city", width: 100},
      {headerName: "ZIP", field: "zip", width: 100},
      {headerName: "ZONE", field: "zone", width: 100},
      {headerName: "STATE", field: "state", width: 100},
      {headerName: "PARENT ID", field: "parent_id", width: 80},
      {headerName: "PARENT CODE", field: "parent_code", width: 80},
      {headerName: "PARENT NAME", field: "parent_name", width: 150},
      {headerName: "EMAIL", field: "email", width: 80},
      {headerName: "MOBILE", field: "mobile", width: 100},
      {headerName: "GST", field: "gst_id", width: 100},
      {headerName: "PAN", field: "pan", width: 100},
      {headerName: "EMP CODE", field: "emp_code", width: 100},
      {headerName: "SALESMAN", field: "user", width: 100},
      {headerName: "BEAT", field: "beats", width: 100},
    ];
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    const filteredRowData = this.gridApi.rowModel.rowsToDisplay;
    const filteredRows = [];
    for (const key in filteredRowData) {
      /* if (!filteredRowData[key].hasOwnProperty('data')) {
         filteredRows.push(filteredRowData[key]);
       }*/
      filteredRows.push(filteredRowData[key].data);
    }
    if (filteredRows !== null && filteredRows.length > 0) {
      this.storeAddressData(filteredRows);
    }
    localStorage.setItem('addrid', rows[0].id);
    localStorage.setItem('addrindex', event.rowIndex);
    /* this.router.navigate([this.apiService.redirect_url + '/customers/leads/details'], {
       state: {
         viewtype: 'detail',
         customerid: rows[0].id
       }
     });*/
  }

  postProcessData() {
    this.rowData = this.apiData;
    this.storeAddressData(this.apiData);
    return;
  }

  storeAddressData(data) {
    const ids = [];
    for (const key in data) {
      ids.push(data[key].id);
    }
    localStorage.setItem('addrdata', JSON.stringify(ids));
  }
}
