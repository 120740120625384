import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-outlet-last-visit-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class CoverageProductivityReportComponent extends ReportBase implements IReport, OnInit {

  showMultiFilter = true;
  multiFilter: any = {
    name: 'Instance',
    key: 'instance',
    value: '',
    values: []
  };

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Coverage And Productivity');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    //this.showDate = false;
    this.multiFilter.value =  this.accountURLS[0];
    this.multiFilter.values = this.accountURLS;

    this.loadInBatches(5000, 'id');
    this.showProgressCustomText = true;
    this.multiInstance = true;
    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getCoverageandProductivityReport';

    this.columnDefs = [
      {headerName: 'INSTANCE', field: 'instance',  width: 80},
      {headerName: 'REGION', field: 'region',  width: 80},
      {headerName: 'STATE', field: 'state',  width: 80},
      {headerName: 'EMP CODE', field: 'emp_code',  width: 80},
      {headerName: 'SALESMAN', field: 'user_name',  width: 80},
      {headerName: 'TEAM', field: 'team',  width: 80},
      {headerName: 'RETAILERS', field: 'retailers',  width: 80},
      {headerName: 'TC', field: 'tc',  width: 80},
      {headerName: 'VC', field: 'vc',  width: 80},
      {headerName: 'PC', field: 'pc',  width: 80},
      {headerName: 'VC %', field: 'vc_pct',  width: 80},
      {headerName: 'PC %', field: 'pc_pct',  width: 80},
      {headerName: 'UNIQUE VC', field: 'uvc',  width: 80},
      {headerName: 'UNIQUE PC', field: 'upc',  width: 80},
      {headerName: 'UNIQUE VC %', field: 'uvc_pct',  width: 80},
      {headerName: 'UNIQUE PC %', field: 'upc_pct',  width: 80},
      {headerName: 'NOTPLANNED VC', field: 'unp_vc',  width: 80},
      {headerName: 'NOTPLANNED PC', field: 'unp_pc',  width: 80},
      {headerName: 'NOTPLANNED PC %', field: 'un_pc_pct',  width: 80},
      {headerName: 'VISITED PC', field: 'visited_pc',  width: 80},
      {headerName: 'VISITED PC %', field: 'visited_pc_pct',  width: 80},
      {headerName: 'TOTAL SCHEDULE', field: 'total_sch',  width: 80},
      {headerName: 'ACTIVE SCHEDULE', field: 'active_sch',  width: 80},
      {headerName: 'JP ADHERENCE %', field: 'beat_adh',  width: 80},
      {headerName: 'WORK DAYS', field: 'workdays',  width: 80}
    ];

  }

}
