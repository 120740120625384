import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {ApiService} from "../../_services/api.service";
import {SharedService} from "../../_services/shared_service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-sidebar-v2',
  templateUrl: './sidebarv2.component.html',
  styleUrls: ['./sidebarv2.component.scss']
})


export class SidebarV2Component implements OnInit {

  menuCount = 7;

  constructor(private router: Router,
              private apiService: ApiService,
              public sharedService: SharedService
  ) {
    this.computeMenuCount();
  }

  computeMenuCount() {
    let el = document.body.scrollHeight;
    this.menuCount = parseInt(String((el / 75)), 10) - 2;
  }

  ngOnInit(): void {
    this.sharedService.prepareMenuList();
    console.log(this.sharedService.menuList);
    console.log(document.body.scrollHeight);
    window.addEventListener('resize', () => {
      setTimeout(() => {
        this.computeMenuCount();
      });
    });

  }

  goToDashboard() {
    localStorage.setItem('my_date', null);
    if (this.sharedService.role_features) {
      if (this.sharedService.ACLcheck('feature_custom_dashboard')) {
        this.router.navigate([this.apiService.redirect_url + '/dash']);
      } else {
        this.router.navigate([this.apiService.redirect_url + '/boards/board_one']);
      }
    } else {
      this.router.navigate([this.apiService.redirect_url + '/boards/board_one']);
    }
  }

  gotoReports() {
    this.router.navigate([this.apiService.redirect_url + '/reports']);
  }

  gotoAllMenus() {
    this.router.navigate([this.apiService.redirect_url + '/menu_detail']);
  }

  goToRoute(menuItem) {
    if (menuItem.route) {
      this.router.navigate([this.apiService.redirect_url + menuItem.route]);
    } else {
      if (menuItem.sub_menu && menuItem.sub_menu.length > 0) {
        if (menuItem.sub_menu[0].route) {
        }
        this.router.navigate([this.apiService.redirect_url + menuItem.sub_menu[0].route]);
      }
    }
  }

  getIcon(img) {
    if (img) {
      return '/assets/menu/' + img + '.svg';
    } else {
      return '/assets/menu/sales.svg';
    }

  }
}
