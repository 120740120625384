import {ReportBase} from '../_common/reportbase';
import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-retailer-asset-sales',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class DealerPrformanceReportComponent extends ReportBase implements IReport, OnInit {

  showDate = false;
  showCustomFilter = true;
  customFilter: any = {
    name: 'Year',
    key: 'type',
    value: '',
    values: [],
    show: true
  };
  currentYear;
  currentMonth;
  prvsYear;
  monthNames = ["April", "May", "June",
    "July", "August", "September", "October", "November", "December", "January", "February", "March"];

  showQ1 = false;
  showQ2 = false;
  showQ3 = false;
  showQ4 = false;
  uqOutstandingVals: any = [];
  outstandingType = 'list';


  setTitle() {
    this.title = 'Dealer Performance Report';
  }

  ngOnInit() {
    this.setTitle();
    this.sharedService.setHeader('Dealer Performers Report');
    /* this.myDate[0] = '01-03-2022';
     this.myDate[1] = '01-03-2023';
     this.userData.start_date = '01-03-2022';
     this.userData.end_date = '12-03-2023';*/
    this.currentYear = new Date().getFullYear();
    this.prvsYear = this.currentYear - 1;
    this.currentMonth = new Date().getMonth() + 1;
    this.getCustomFilterValues();
    this.dateCheckandUpdate();
    this.configureGrid();
    this.loadServerData();
  }

  getCustomFilterValues() {
    if (this.currentMonth < 4) {
      this.customFilter.values = [
        {id: this.currentYear.toString(), itemName: this.prvsYear + '-' + this.currentYear},
        {id: this.prvsYear.toString(), itemName: (this.prvsYear - 1) + '-' + this.prvsYear}
      ];
    } else {
      this.customFilter.values = [
        {id: this.currentYear.toString(), itemName: this.currentYear + '-' + (this.currentYear + 1)},
        {id: this.prvsYear.toString(), itemName: this.prvsYear + '-' + this.currentYear}
      ];
    }
    this.customFilter.value = this.currentYear.toString();
  }

  setCustomFilter(customfilterdata) {
    if (customfilterdata.hasOwnProperty('type')) {
      this.customFilter.value = customfilterdata.type;
    }
    for (const [key, value] of Object.entries(customfilterdata)) {
      this.userData.customFilter[key] = value;
    }
    this.dateCheckandUpdate();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getDealerPerformanceReport';
    this.checkQuarterShow();
    this.getUniqueOutstandingValues();

    this.columnDefs = [
      {
        headerName: '',
        children: [
          {headerName: "ID", field: "r_id", width: 100},
          {headerName: "RCODE", field: "r_code", width: 100},
          {headerName: "RETAILER", field: "r_name", width: 100},
          {headerName: "PROGRAM TYPE", field: "program", width: 100},
          {headerName: "CITY", field: "rp_city", width: 100},
          {headerName: "TCODE", field: "t_code", width: 100},
          {headerName: "TERRITORY", field: "territory", width: 100},
          {headerName: 'CR LIMIT', field: 'cl', width: 80},
          {headerName: 'SD', field: 'sd', width: 80},
        ]
      },
    ];

    if (this.outstandingType === 'number') {
      this.columnDefs.push({headerName: 'OUTSTANDING', field: 'outst', width: 80});
    } else {
      this.columnDefs.push({headerName: 'OUTSTANDING', children: []});
      for (const key of this.uqOutstandingVals) {
        this.columnDefs[1].children.push(
          {headerName: key, field: 'outst_' + key, width: 80, aggFunc: 'sum'},
        );
      }
    }
    this.columnDefs.push(
      {
        headerName: 'PRV-PRV YEAR',
        children: [
          {headerName: "TARGET", field: "y1_t", width: 100},
          {headerName: "ACH", field: "y1_a", width: 100},
          {
            headerName: "ACH %", field: "y1_ach_pct", width: 100,
            cellStyle: params => {
              if (params.data) {
                if (params.value >= 75) {
                  return {backgroundColor: '#B3DFB0'};
                } else if (params.value > 50) {
                  return {backgroundColor: '#F0E199'};
                } else {
                  return {backgroundColor: '#F8D1C8'};
                }
              }
            }
          }
        ]
      },
      {
        headerName: 'PREVIOUS YEAR',
        children: [
          {headerName: "TARGET", field: "y2_t", width: 100},
          {headerName: "ACH", field: "y2_a", width: 100},
          {
            headerName: "ACH %", field: "y2_ach_pct", width: 100,
            cellStyle: params => {
              if (params.data) {
                if (params.value >= 75) {
                  return {backgroundColor: '#B3DFB0'};
                } else if (params.value > 50) {
                  return {backgroundColor: '#F0E199'};
                } else {
                  return {backgroundColor: '#F8D1C8'};
                }
              }
            }
          }
        ]
      }
    );
    if (this.showQ1) {
      this.columnDefs.push(
        {
          headerName: 'Q1',

          children: [
            {
              headerName: "TARGET", field: "q1_target", width: 100,
              valueGetter(params) {
                if (params.data) {
                  return params.data.q2_target;
                }
              }
            },
            {headerName: "ACH", field: "q1_ach", width: 100},
            {
              headerName: "ACH %", field: "q1_ach_pct", width: 100,
              cellStyle: params => {
                if (params.data) {
                  if (params.value >= 75) {
                    return {backgroundColor: '#B3DFB0'};
                  } else if (params.value > 50) {
                    return {backgroundColor: '#F0E199'};
                  } else {
                    return {backgroundColor: '#F8D1C8'};
                  }
                }
              }
            }
          ]
        },
      );
    }
    if (this.showQ2) {
      this.columnDefs.push(
        {
          headerName: 'Q2',

          children: [
            {headerName: "TARGET", field: "q2_target", width: 100},
            {headerName: "ACH", field: "q2_ach", width: 100},
            {
              headerName: "ACH %", field: "q2_ach_pct", width: 100,
              cellStyle: params => {
                if (params.data) {
                  if (params.value >= 75) {
                    return {backgroundColor: '#B3DFB0'};
                  } else if (params.value > 50) {
                    return {backgroundColor: '#F0E199'};
                  } else {
                    return {backgroundColor: '#F8D1C8'};
                  }
                }
              }
            }
          ]
        });
    }
    if (this.showQ3) {
      this.columnDefs.push(
        {
          headerName: 'Q3',

          children: [
            {headerName: "TARGET", field: "q3_target", width: 100},
            {headerName: "ACH", field: "q3_ach", width: 100},
            {
              headerName: "ACH %", field: "q3_ach_pct", width: 100,
              cellStyle: params => {
                if (params.data) {
                  if (params.value >= 75) {
                    return {backgroundColor: '#B3DFB0'};
                  } else if (params.value > 50) {
                    return {backgroundColor: '#F0E199'};
                  } else {
                    return {backgroundColor: '#F8D1C8'};
                  }
                }
              }
            }
          ]
        },
      );
    }
    if (this.showQ4) {
      this.columnDefs.push(
        {
          headerName: 'Q4',

          children: [
            {headerName: "TARGET", field: "q4_target", width: 100},
            {headerName: "ACH", field: "q4_ach", width: 100},
            {
              headerName: "ACH %", field: "q4_ach_pct", width: 100,
              cellStyle: params => {
                if (params.data) {
                  if (params.value >= 75) {
                    return {backgroundColor: '#B3DFB0'};
                  } else if (params.value > 50) {
                    return {backgroundColor: '#F0E199'};
                  } else {
                    return {backgroundColor: '#F8D1C8'};
                  }
                }
              }
            }
          ]
        }
      );
    }
    this.columnDefs.push({
        headerName: this.getHeaderName(0),
        children: [
          {headerName: "TARGET", field: "m1_target", width: 100},
          {headerName: "ACH", field: "m1_ach", width: 100},
          {
            headerName: "ACH %", field: "m1_ach_pct", width: 100,
            cellStyle: params => {
              if (params.data) {
                if (params.value >= 75) {
                  return {backgroundColor: '#B3DFB0'};
                } else if (params.value > 50) {
                  return {backgroundColor: '#F0E199'};
                } else {
                  return {backgroundColor: '#F8D1C8'};
                }
              }
            }
          }
        ]
      },
      {
        headerName: this.getHeaderName(1),
        children: [
          {headerName: "TARGET", field: "m2_target", width: 100},
          {headerName: "ACH", field: "m2_ach", width: 100},
          {
            headerName: "ACH %", field: "m2_ach_pct", width: 100,
            cellStyle: params => {
              if (params.data) {
                if (params.value >= 75) {
                  return {backgroundColor: '#B3DFB0'};
                } else if (params.value > 50) {
                  return {backgroundColor: '#F0E199'};
                } else {
                  return {backgroundColor: '#F8D1C8'};
                }
              }
            }
          }
        ]
      },
      {
        headerName: this.getHeaderName(2),
        children: [
          {headerName: "TARGET", field: "m3_target", width: 100},
          {headerName: "ACH", field: "m3_ach", width: 100},
          {
            headerName: "ACH %", field: "m3_ach_pct", width: 100,
            cellStyle: params => {
              if (params.data) {
                if (params.value >= 75) {
                  return {backgroundColor: '#B3DFB0'};
                } else if (params.value > 50) {
                  return {backgroundColor: '#F0E199'};
                } else {
                  return {backgroundColor: '#F8D1C8'};
                }
              }
            }
          }
        ]
      }
    );

    this.columnDefs.push(
      {
        headerName: 'YTD',

        children: [
          {headerName: "TARGET", field: "y_target", width: 100},
          {headerName: "ACH", field: "y_ach", width: 100},
          {
            headerName: "ACH %", field: "y_ach_pct", width: 100,
            cellStyle: params => {
              if (params.data) {
                if (params.value >= 75) {
                  return {backgroundColor: '#B3DFB0'};
                } else if (params.value > 50) {
                  return {backgroundColor: '#F0E199'};
                } else {
                  return {backgroundColor: '#F8D1C8'};
                }
              }
            }
          }
        ]
      },
      {headerName: "ROW NO", field: "rn", hide: true, width: 100},
      {headerName: "ROW NO %", field: "rnc", hide: true, width: 100});
  }

  getHeaderName(month) {
    if (this.currentMonth >= 4 && this.currentMonth <= 6) {
      return this.monthNames[month];
    } else if (this.currentMonth >= 7 && this.currentMonth <= 9) {
      return this.monthNames[month + 3];
    } else if (this.currentMonth >= 10 && this.currentMonth <= 12) {
      return this.monthNames[month + 6];
    } else {
      return this.monthNames[month + 9];
    }
  }

  checkQuarterShow() {
    if (this.customFilter.value !== this.currentYear.toString()) {
      this.showQ1 = true;
      this.showQ2 = true;
      this.showQ3 = true;
      this.showQ4 = true;
    } else {
      if (this.currentMonth >= 4) {
        this.showQ1 = true;
      }
      if (this.currentMonth >= 7) {
        this.showQ1 = true;
        this.showQ2 = true;
      }
      if (this.currentMonth >= 10) {
        this.showQ1 = true;
        this.showQ2 = true;
        this.showQ3 = true;
      }
      if (this.currentMonth <= 3) {
        this.showQ1 = true;
        this.showQ2 = true;
        this.showQ3 = true;
        this.showQ4 = true;
      }
    }
  }

  postProcessData() {
    this.configureGrid();
    this.configureData();
  }

  configureData() {
    this.rowData = [];
    const innerthis = this;
    /* const uqOutst = Array.from(this.uqOutstandingVals);
     uqOutst.sort();*/
    this.apiData.forEach(obj => {
      let indrow = {};
      indrow = obj;
      indrow['q1_target'] = 0;
      indrow['q2_target'] = 0;
      indrow['q3_target'] = 0;
      indrow['q4_target'] = 0;
      indrow['y_target'] = 0;
      indrow['m1_target'] = 0;
      indrow['m2_target'] = 0;
      indrow['m3_target'] = 0;

      indrow['q1_ach'] = 0;
      indrow['q2_ach'] = 0;
      indrow['q3_ach'] = 0;
      indrow['q4_ach'] = 0;
      indrow['y_ach'] = 0;
      indrow['m1_ach'] = 0;
      indrow['m2_ach'] = 0;
      indrow['m3_ach'] = 0;

      const q1obj = obj.performance.filter(x => x.m >= 4 && x.m <= 6);
      if (q1obj !== null && q1obj !== undefined && q1obj.length > 0) {
        q1obj.forEach(q1 => {
          indrow['q1_target'] += q1.t;
          indrow['q1_ach'] += q1.ach;
          if (this.currentMonth >= 4 && this.currentMonth <= 6 && q1.m >= 4 && q1.m <= 6) {
            indrow['m' + (q1.m - 3) + '_target'] = q1.t;
            indrow['m' + (q1.m - 3) + '_ach'] = q1.ach;
          }
        });
      }
      indrow['q1_ach_pct'] = this.calculateAchPercent(indrow['q1_target'], indrow['q1_ach']);

      const q2obj = obj.performance.filter(x => x.m >= 7 && x.m <= 9);
      if (q2obj !== null && q2obj !== undefined && q2obj.length > 0) {
        q2obj.forEach(q2 => {
          indrow['q2_target'] += q2.t;
          indrow['q2_ach'] += q2.ach;
          if (this.currentMonth >= 7 && this.currentMonth <= 9 && q2.m >= 7 && q2.m <= 9) {
            indrow['m' + (q2.m - 3) + '_target'] = q2.t;
            indrow['m' + (q2.m - 3) + '_ach'] = q2.ach;
          }
        });
      }
      indrow['q2_ach_pct'] = this.calculateAchPercent(indrow['q2_target'], indrow['q2_ach']);

      const q3obj = obj.performance.filter(x => x.m >= 10 && x.m <= 12);
      if (q3obj !== null && q3obj !== undefined && q3obj.length > 0) {
        q3obj.forEach(q3 => {
          indrow['q3_target'] += q3.t;
          indrow['q3_ach'] += q3.ach;
          if (this.currentMonth >= 10 && this.currentMonth <= 12 && q3.m >= 10 && q3.m <= 12) {
            indrow['m' + (q3.m - 6) + '_target'] = q3.t;
            indrow['m' + (q3.m - 6) + '_ach'] = q3.ach;
          }
        });
      }
      indrow['q3_ach_pct'] = this.calculateAchPercent(indrow['q3_target'], indrow['q3_ach']);

      const q4obj = obj.performance.filter(x => x.m >= 1 && x.m <= 3);
      if (q4obj !== null && q4obj !== undefined && q4obj.length > 0) {
        q4obj.forEach(q4 => {
          indrow['q4_target'] += q4.t;
          indrow['q4_ach'] += q4.ach;
          if (this.currentMonth >= 1 && this.currentMonth <= 3 && q4.m >= 1 && q4.m <= 3) {
            indrow['m' + (q4.m) + '_target'] = q4.t;
            indrow['m' + (q4.m) + '_ach'] = q4.ach;
          }
        });
      }
      indrow['q4_ach_pct'] = this.calculateAchPercent(indrow['q4_target'], indrow['q4_ach']);

      indrow['y_target'] = indrow['q1_target'] + indrow['q2_target'] + indrow['q3_target'] + indrow['q4_target'];
      indrow['y_ach'] = indrow['q1_ach'] + indrow['q1_ach'] + indrow['q1_ach'] + indrow['q1_ach'];
      indrow['y_ach_pct'] = this.calculateAchPercent(indrow['y_target'], indrow['y_ach']);


      indrow['m1_ach_pct'] = this.calculateAchPercent(indrow['m1_target'], indrow['m1_ach']);
      indrow['m2_ach_pct'] = this.calculateAchPercent(indrow['m1_target'], indrow['m2_ach']);
      indrow['m3_ach_pct'] = this.calculateAchPercent(indrow['m1_target'], indrow['m3_ach']);

      indrow['y1_ach_pct'] = this.calculateAchPercent(indrow['y1_t'], indrow['y1_a']);
      indrow['y2_ach_pct'] = this.calculateAchPercent(indrow['y2_t'], indrow['y2_a']);

      if (obj.param_json !== null && obj.param_json !== undefined && obj.param_json.length > 0) {
        for (const params of obj.param_json) {
          if (params.n === 'Outstanding') {
            if (innerthis.outstandingType === 'number') {
              let outst = params.v;
              if (outst !== null && outst !== undefined && outst !== "") {
                outst = parseFloat(outst).toFixed(2);
                indrow['outst'] = parseFloat(outst).toFixed(2);
              }
            } else {
              for (const item of params.v) {
                let outst = item[Object.keys(item)[0]];
                if (outst !== null && outst !== undefined && outst !== "") {
                  outst = parseFloat(outst).toFixed(2);
                  indrow['outst_' + Object.keys(item)[0]] = outst;
                }
              }
            }
          } else if (params.n.toString().toLowerCase() === 'cl') {
            indrow['cl'] = params.v;
          } else if (params.n.toString().toLowerCase() === 'sd') {
            indrow['sd'] = params.v;
          }
        }
      }
      innerthis.rowData.push(indrow);
    });
  }

  calculateAchPercent(target, ach) {
    if (target === null || target === undefined || target === 0) {
      return 0;
    } else if (ach > target) {
      return 100;
    } else {
      return Math.floor((ach * 100) / target);
    }
  }

  getUniqueOutstandingValues() {
    this.outstandingType = 'list';
    this.uqOutstandingVals = new Set();
    /*if (this.apiData[0].param_json !== null && this.apiData[0].param_json !== undefined && this.apiData[0].param_json.length > 0) {
      for (const params of this.apiData[0].param_json) {

      }

      if (this.apiData[0].param_json.t === 'number') {
        this.uqOutstandingVals.add('Outstanding');
        return;
      }
    }*/
    this.apiData.forEach(obj => {
      if (obj.param_json !== null && obj.param_json !== undefined && obj.param_json.length > 0) {
        for (const params of obj.param_json) {
          if (params.n === 'Outstanding') {
            if (params.t === 'number') {
              //this.uqOutstandingVals.add('Outstanding');
              this.outstandingType = 'number';
              return;
            } else {
              for (const item of params.v) {
                this.uqOutstandingVals.add(Object.keys(item)[0]);
              }
            }
          }
        }
      }
    });
  }
}
