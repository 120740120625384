<!-- <app-sidebar></app-sidebar>
<app-components></app-components> -->
<!--
<app-header *ngIf="showHead && !featureMenuv2" [title]="header_title" (profileExpansion)="profClick()" (collapseExpansion)="siderCollapse()"></app-header>
<app-sidebar *ngIf="showHead && !featureMenuv2"></app-sidebar>

<app-sidebar-v2 *ngIf="showHead && featureMenuv2"></app-sidebar-v2>
<app-header-v2 *ngIf="showHead && featureMenuv2" [title]="header_title" (profileExpansion)="profClick()" (collapseExpansion)="siderCollapse()"></app-header-v2>


<app-profilebar *ngIf="showHead"></app-profilebar>
<router-outlet></router-outlet>
<app-footer></app-footer>
-->


<!--<body>-->
<!--<div id="left" class="column">-->
<!--  <div class="top-left">-->
<!--    <app-sidebar-v2 *ngIf="showHead && featureMenuv2"></app-sidebar-v2>-->
<!--  </div>-->
<!--  <div class="bottom"></div>-->
<!--</div>-->
<!--<div id="right" class="column">-->
<!--  <div class="top-right">-->
<!--    <app-header-v2 *ngIf="showHead && featureMenuv2" [title]="header_title" (profileExpansion)="profClick()" (collapseExpansion)="siderCollapse()"></app-header-v2>-->
<!--  </div>-->
<!--  <div class="bottom">-->
<!--    &lt;!&ndash;<router-outlet></router-outlet>&ndash;&gt;-->
<!--    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis quam massa, ornare at turpis quis, varius pulvinar lorem. Morbi eleifend nisi eget viverra fermentum. Vivamus interdum dui quis orci placerat semper. Morbi lobortis ex sit amet risus cursus pharetra. Interdum et malesuada fames ac ante ipsum primis in faucibus. Etiam luctus leo augue, non facilisis nunc aliquam sed. Aliquam erat volutpat. Nam laoreet cursus nunc, id tincidunt justo. Nullam elit magna, finibus at aliquam ut, blandit vel magna. Phasellus ullamcorper urna a leo luctus vestibulum. Duis posuere leo ac lectus auctor, convallis aliquam odio gravida.</p>-->
<!--    <p>Sed porttitor turpis quis est pellentesque, in accumsan risus porta. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Maecenas quis elit sed felis dapibus sodales ut auctor nibh. Mauris dapibus diam in ante scelerisque ultrices. Nam tincidunt lorem vel ultricies sodales. Nullam facilisis placerat pulvinar. Nullam in arcu urna. Pellentesque tempus lorem et ligula viverra convallis. Integer id vehicula quam, sit amet dictum urna. Praesent pellentesque hendrerit risus non pharetra. In a libero finibus, hendrerit justo quis, consequat nunc. Sed iaculis efficitur nunc sagittis hendrerit. Praesent ligula augue, malesuada quis pretium semper, facilisis non quam. Phasellus ac placerat mi. Quisque ac fermentum tellus.</p>-->
<!--    <p>Duis facilisis aliquet neque, nec dignissim dolor venenatis non. Donec quis consectetur nibh. Ut ut purus in metus pellentesque feugiat non ut augue. Aenean at leo vulputate, viverra ligula ut, aliquam turpis. Cras nunc dui, sollicitudin quis lorem sit amet, auctor mollis libero. Integer non magna ipsum. Etiam ullamcorper urna tortor, nec tempor tortor elementum et. Donec placerat mi nisi, id rhoncus lacus sodales sed. Nullam lorem risus, sollicitudin non lacinia a, scelerisque et mauris. Vivamus nisi enim, egestas ac ante eu, molestie vestibulum felis. Praesent vitae dui tincidunt mi malesuada pharetra. Integer vehicula leo at laoreet tempor. In vestibulum rutrum tellus, eget suscipit orci egestas et. Vestibulum tincidunt aliquam dui, ac pharetra eros commodo ut. Sed tempus eget orci a laoreet. Donec gravida imperdiet congue.</p>-->

<!--  </div>-->
<!--</div>-->
<!--</body>-->

<!DOCTYPE html>
<html>
<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width">
  <title>JS Bin</title>
</head>
<body>
<div id="left" class="column">
  <div class="top-left">
    <app-sidebar *ngIf="showHead && !featureMenuv2"></app-sidebar>
    <app-sidebar-v2 *ngIf="showHead && featureMenuv2"></app-sidebar-v2>
  </div>
  <div class="bottom">

  </div>
</div>
<div id="right" class="column" style="flex: 1;">
  <div class="top-right">
    <app-header *ngIf="showHead && !featureMenuv2" [title]="header_title" (profileExpansion)="profClick()" (collapseExpansion)="siderCollapse()"></app-header>
    <app-header-v2 style="flex: 0 0 100%;" *ngIf="showHead && featureMenuv2" [title]="header_title" (profileExpansion)="profClick()" (collapseExpansion)="siderCollapse()"></app-header-v2>
  </div>
  <div class="bottom">
    <app-profilebar *ngIf="showHead"></app-profilebar>
    <router-outlet style="flex: 0 0 100%;"></router-outlet>
    <span class="appver">{{currentApplicationVersion}}</span>
   <!-- <app-footer></app-footer>-->
  </div>
</div>
</body>
</html>


<div class="approve-modal modal" id="CustomPrintModal" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <span class="close" data-dismiss="modal">
        <img src="../../assets/close.png">
      </span>
      <div class="modal-body">
        <div class="approve-modal-heading">
          <h1> Please select Print Template</h1>
        </div>
        <div class="approve-modal-content" style="witdh:50%;height:3vh">
          <select [(ngModel)]="selectedTemplate">
            <option>Select</option>
            <option *ngFor="let templ of templ_config"
                    [ngValue]="templ">{{templ.name}}</option>
          </select>
        </div>
        <button
          [hidden]="!selectedTemplate"
          [useExistingCss]="true"
          printSectionId="print-section-sales"
          ngxPrint>Print</button>

      </div>
    </div>
  </div>
</div>

<div #PrintingModal class="pol-modal pol-send invoice modal" id="PrintingModal" role="dialog">
  <div class="modal-dialog m1">
    <!-- Modal content-->
    <div class="modal-content m2">
      <div>
        <span class="close" data-dismiss="modal">
            <img src="../../assets/close.png">
          </span>
      </div>
      <div class="m3">

        <div id="print-section-sales">
          <div  class="invoice"  style="page-break-after: always" *ngFor="let print_detail of print_details">
            <app-print *ngIf="!template || (selectedTemplate && selectedTemplate.name == 'Default')"
                       [order_detail]="print_detail"></app-print>
            <app-custom-print *ngIf="template && selectedTemplate && selectedTemplate.name != 'Default'"
                              [order_detail]="print_detail"
                              [custom_template]="selectedTemplate"
            ></app-custom-print>
            <app-eway-print *ngIf="printType === 'Eway'" [order_detail]="print_detail"></app-eway-print>
          </div>
        </div>
      </div>

      <div style="margin-top:1%;padding-bottom: 3%;text-align: center;">
        <button [useExistingCss]="true"
                printSectionId="print-section-sales"
                ngxPrint >PRINT</button>
        <button
          #autoprint
          [hidden]="true"
          printSectionId="print-section-sales"
          ngxPrint>Print</button>
      </div>
    </div>
  </div>
</div>
<div #zipDiv *ngIf="zip_order" id="print-section-sales-zip" style=" position:absolute;
   left:-10000px;background: #FFFFFF !important; background-color: #FFFFFF !important;" >
  <div  class="invoice" >
    <app-print *ngIf="!template || (selectedTemplate && selectedTemplate.name == 'Default')"
               [order_detail]="zip_order"></app-print>
    <app-custom-print *ngIf="template && selectedTemplate && selectedTemplate.name != 'Default'"
                      [order_detail]="zip_order"
                      [custom_template]="selectedTemplate"
    ></app-custom-print>
  </div>
</div>
<div #ZipProgressModal class="pol-modal pol-send invoice modal" id="ZipProgressModal" role="dialog" style="overflow: hidden;">
  <div class="modal-dialog m1">
    <!-- Modal content-->
    <div class="modal-content m2"  style="width: 90%;height:80%;">
      <div>
        <span class="close"  style="cursor:pointer;" data-dismiss="modal" (click)="closeZipDialog()">
            <img src="../../assets/close.png">
          </span>
      </div>
      <h2 style="padding-left: 20px;padding-top: 20px;">Zip Generation in Progress</h2>
      <div style="padding: 20px;">
        <mat-progress-bar style="height:25px;margin-top: 30px;" class="progressbar" mode="determinate" [value]="progress"></mat-progress-bar>
        <span  style="display:block;float:left;">{{completed}}/{{total}}</span>
        <span  style="display:block;float:right;">{{progress}}%</span>
      </div>
    </div>
  </div>
</div>
<div #DefaultTemplateModal class="pol-modal pol-send invoice modal"  id="DefaultTemplateModal" role="dialog">
  <div class="modal-dialog m1" style="width: 60vw">
    <!-- Modal content-->
    <div class="modal-content m2" style="width: fit-content">
      <div>
        <span class="close" data-dismiss="modal">
            <img src="../../assets/close.png">
          </span>
      </div>
      <div class="m3">

        <div id="print-section--default">
          <div  class="invoice"  style="page-break-after: always" *ngFor="let order_detail of print_details">
            <app-print *ngIf="printType !== 'Eway' && !order_detail['selectedTemplate'] || (order_detail['selectedTemplate'] && order_detail['selectedTemplate']['name'] == 'Default')"
                       [order_detail]="order_detail"></app-print>
            <app-custom-print *ngIf="printType !== 'Eway' && order_detail['selectedTemplate'] && order_detail['selectedTemplate']['name'] != 'Default'"
                              [order_detail]="order_detail"
                              [custom_template]="order_detail['selectedTemplate']"
            ></app-custom-print>
            <app-eway-print *ngIf="printType === 'Eway'" [order_detail]="order_detail"></app-eway-print>
          </div>
        </div>
      </div>

      <div style="margin-top:1%;padding-bottom: 3%;text-align: center;">
        <button [useExistingCss]="true"
                printSectionId="print-section--default"
                ngxPrint >PRINT</button>
        <button
          #autoprint
          [hidden]="true"
          printSectionId="print-section-sales"
          ngxPrint>Print</button>
      </div>
    </div>
  </div>
</div>
<button #customPrintClick id="openPrint" [hidden]="true" data-toggle="modal" data-target="#CustomPrintModal">Open Modal</button>
<button #interPrint id="openModalButton1" [hidden]="true" data-toggle="modal" data-target="#PrintingModal"></button>

