import {PageBase} from "../../../pagebase/pagebase";
import {Component, OnInit} from "@angular/core";
import {ErrorHandler} from "../../../error/error-handler";
import {RowNode} from "@ag-grid-enterprise/all-modules";
import {PrintClickRenderer} from "../../../printClickRenderer.component";
import {PrintInvoiceComponent} from "../../../printInvoice.component";
import {MatDialogConfig} from "@angular/material/dialog";
import {SaleOrderBulkUploadComponent} from "../sale_order/sale_order_bulk_upload.component";
import {SaleInvoiceBulkUploadComponent} from "./sale_invoice_bulk_upload.component";


@Component({
  selector: 'app-return-invoice-list',
  templateUrl: '../../../pagebase/pagebase.html',
  styleUrls: ['../../../pagebase/pagebase.scss']
})

export class SecondarySaleInvoiceListComponent extends PageBase implements OnInit, IPage {

  customFilter: any = {
    name: 'Status',
    key: 'status',
    value: 'draft',
    values: [],
    show: true
  };

  matActionMenuItemDeclaration = [{name: 'Confirm Invoices'}, {name: 'Mark Delivered'}]; //, feature: 'action_invoice_create'

  ngOnInit(): void {

    this.sharedService.setHeader('Sale Invoices');
    this.showUpload = true;
    this.showActionButton = true;
    this.customFilter.values = [
      {id: 'all', itemName: 'All'},
      {id: 'draft', itemName: 'Draft'},
      {id: 'confirm', itemName: 'Confirmed'},
      {id: 'dispatched', itemName: 'Dispatched'},
      {id: 'delivered', itemName: 'Delivered'},
      {id: 'cancel', itemName: 'Cancel'},
    ];
    this.showCustomFilter = true;
    this.showPinnedFooter = true;
    this.columnsWithAggregation = ['amount', 'paid'];
    this.checkAndLoadPreviousSate();
    this.getAuthorizedActions();
    this.checkAccessControl();
    this.configureGrid();
    this.loadServerData();
  }

  getRowStyleFunc(params) {
    if (params.data && params.data.id === 'TOTAL') {
      return {'font-weight': 'bold'};
    }
  }

  configureGrid() {

    const OrderStatusCellClassRules = {
      'rag-green-outer': (params) => params.value === 'sale',
      'rag-amber-outer': (params) => params.value === 'confirm',
      'rag-red-outer': (params) => params.value === 'cancel',
    };

    this.API_URL = '/api/pwa_dms_connects/get_sale_invoices';
    this.columnDefs = [
      {headerName: "ID", field: "id", width: 40, checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true},
      {headerName: "INVOICE NO", field: "inv_no", width: 80},
      {headerName: "INVOICE DATE", field: "inv_date", width: 60},
      {headerName: "REFERENCE", field: "sale_ref", width: 80},
      {headerName: "CUSTOMER", field: "cust_name", width: 200},
      {headerName: "SALESMAN", field: "salesman", width: 100},
      {
        headerName: "STATUS", field: "state", width: 50,
        cellClassRules: OrderStatusCellClassRules,
        valueGetter: params => {
          if (params.data && params.data.hasOwnProperty('state')) {
            if (params.data.state === 'confirm') {
              return 'Confirmed';
            } else if (params.data.state === 'draft') {
              return 'Draft';
            } else if (params.data.state === 'cancel') {
              return 'Cancel';
            } else if (params.data.state === 'delivered') {
              return 'Delivered';
            } else {
              return params.data.state;
            }
          }
        },
        cellRenderer(params) {
          let displayElem = '';
          if (params.data) {
            if (params.value === 'Confirmed') {
              displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #379862;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
            } else if (params.value === 'Cancel') {
              displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #E24260;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
            } else if (params.value === 'Draft') {
              displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #F6A82C;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
            } else if (params.value === 'Delivered') {
              displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #0762AD;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
            } else {
              if (params.value) {
                displayElem = '<span>' + params.value + '</span>';
              }
            }

            return displayElem;
          }
        }
      },
      {headerName: "AMOUNT", field: "amount", width: 50, aggFunc: 'sum', cellStyle: {textAlign: 'right'}, cellRenderer: params => this.formatNumber(params.value)},
      {headerName: "PAID", field: "paid", width: 50, aggFunc: 'sum', cellStyle: {textAlign: 'right'}, cellRenderer: params => this.formatNumber(params.value)},
      {headerName: "BALANCE", field: "balance", width: 50, aggFunc: 'sum', cellStyle: {textAlign: 'right'}, cellRenderer: params => this.formatNumber(params.value)},
      {headerName: "DELIVERY PLAN", field: "delivery_plan", width: 50},
      {
        headerName: 'PRINT', field: 'PRINT', pinned: 'right',
        headerComponent: PrintInvoiceComponent,
        cellRenderer: PrintClickRenderer, width: 80
      }
    ];

    this.getRowId = (params) => params.data.id;
  }

  onCellClicked(event) {
    const rows = this.gridApi.getSelectedRows();
    const filteredRowData = this.gridApi.rowModel.rowsToDisplay;
    const column = event.column.getColId();
    if (column === 'PRINT') {
      this.appComponent.printAll([event.data], 'id', '/api/pwa_dms_connects/get_invoice_print_details', null);
    } else {
      if (filteredRowData !== null && filteredRowData.length > 0) {
        const ids = [];
        filteredRowData.forEach(r => {
          ids.push(r.data.id);
        });
        localStorage.setItem('active_ids', JSON.stringify(ids));
        this.router.navigate([this.apiService.redirect_url + '/dms/sale_invoice'], {
          relativeTo: this.route,
          queryParams: {id: rows[0].id}
        });
      }
    }
  }

  printAll(rows) {
    this.appComponent.printAll(rows, 'id', '/api/pwa_dms_connects/get_invoice_print_details', null);
  }


  checkAccessControl() {
    // this._localCacheService.getAccessControlList().then(list => {
    this.showCreate = false;
    // });
  }

  openCreateDialog() {
    this.router.navigate([this.apiService.redirect_url + '/dms/sale_invoice'], {
      relativeTo: this.route,
      queryParams: {id: 0}
    });
  }

  showUploadPopup() {
    // this.uploadSaleOrdersClick();
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "90%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
        }
      };
      const creatediaeref = this.dialog.open(SaleInvoiceBulkUploadComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {
        }
      };
      const creatediaeref = this.dialog.open(SaleInvoiceBulkUploadComponent, config);
    }
  }

  onMenuItemSelected(menuitem) {
    const rows = this.gridApi.getSelectedRows();
    if (rows === null || rows === undefined || rows.length === 0) {
      this.toastr.error('Please select at least 1 Invoice');
    } else if (rows.length > 100) {
      this.toastr.error('Please select a maximum of 100 order : ' + rows.length + ' selected');
    } else {

      if (menuitem.name === 'Mark Delivered') {
        const ids = [];
        rows.forEach(r => {
          if (r.state === 'draft' || r.state === 'confirm') {
            ids.push(r.id);
          }
        });
        this.MarkDelivery(ids);
      } else if (menuitem.name === 'Confirm Invoices') {
        const ids = [];
        rows.forEach(r => {
          if (r.state === 'draft') {
            ids.push(r.id);
          }
        });
        this.ConfirmInvoicesClick(ids);
      }
    }
  }


  MarkDelivery(invoice_ids) {
    if (invoice_ids.length > 0) {
      const contenxt = {};
      contenxt['access_token'] = this.userData.access_token;
      contenxt['data'] = {};

      invoice_ids.forEach(inv_id => {

        contenxt['data']['id'] = inv_id;
        contenxt['action'] = 'delivered';
        contenxt['feature_disable_supplier_live_stock'] = this.sharedService.ACLcheck('feature_disable_supplier_live_stock');

        this.apiService.post('/api/pwa_dms_connects/sale_invoice_status_change', contenxt)
          .subscribe(res => {
              if (res.hasOwnProperty('results') && (res.results.status === 200)) {

                const ord = this.rowData.find(item => item.id === inv_id);
                const row = this.updateImmutableObject(ord, {state: "delivered"});

                this.gridApi.applyTransactionAsync({update: [row]}, () => {
                });

              } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
                this.handleError(ErrorHandler.getErrorObject(res.results.msg));
              } else {
                this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
              }
            },
            error => {
              this.handleError(ErrorHandler.getErrorObject(error));
            });

      });
    } else {
      this.toastr.success('No Invoices Selected');
      return;
    }
  }


  async ConfirmInvoicesClick(invoice_ids) {
    if (invoice_ids.length > 0) {
      const contenxt = {};
      contenxt['access_token'] = this.userData.access_token;
      contenxt['data'] = {};

      for (const inv_id of invoice_ids) {

        contenxt['data']['id'] = inv_id;
        contenxt['action'] = 'confirm';
        contenxt['feature_disable_supplier_live_stock'] = this.sharedService.ACLcheck('feature_disable_supplier_live_stock');
        const res = await this.apiService.postPromise('/api/pwa_dms_connects/sale_invoice_status_change', contenxt);
        if (res && res.results.status === 200) {
          const inv = this.rowData.find(item => item.id === inv_id);
          const row = this.updateImmutableObject(inv, {state: res.results.data[0]['state']});

          this.gridApi.applyTransactionAsync({update: [row]}, () => {
          });
        } else {
          this.toastr.error('Error confirming invoice, please contact support');
        }

      }
    } else {
      this.toastr.error('Select atleast one invoice in draft state');
    }
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setColumnDefs(this.columnDefs);
    params.api.setRowData(this.rowData);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (params.api) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      this.gridOptions.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }

    setTimeout(() => {
      const pinnedBottomData = this.sharedService.generatePinnedBottomData(this.gridApi, this.gridColumnApi);
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }

  onFilterChanged($event) {
    setTimeout(() => {
      const pinnedBottomData = this.generatePinnedBottomData();
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }

}
