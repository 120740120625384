import {Component, OnInit} from "@angular/core";
import {TabPageBase} from "../pagebase/tabpagebase";
import {PageBase} from "../pagebase/pagebase";

@Component({
  selector: 'app-tcs-list',
  templateUrl: '../pagebase/pagebase.html',
  styleUrls: ['../pagebase/pagebase.scss']
})

export class TCSListComponent extends PageBase implements OnInit {

  ngOnInit(): void {
    this.sharedService.setHeader('TCS');
   /* this.showUpload = false;
    this.tabList = [{id: 'b2b', itemName: 'B2B'}, {id: 'b2cl', itemName: 'B2CL'}, {id: 'b2cs', itemName: 'B2CS'}, {id: 'hsn', itemName: 'HSN'},
      {id: 'cdnr', itemName: 'CDNR'}, {id: 'cdna', itemName: 'CDNA'}];
    this.checkAndLoadPreviousSate();
    this.showCustomFilter = false;
    this.showActionButton = false;
    this.showDate = true;*/
    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_v2_reports/get_tcs_report';
    this.columnDefs = [
      {headerName: 'Invoice No.',  field: 'inv_num',  width: 100},
      {headerName: 'Invoice Date', field : 'inv_date', width: 100},
      {headerName: 'Customer Name', field: 'cust_name', width: 120},
      {headerName: 'PAN of Customer', field: 'pan', width: 150},
      {headerName: 'GSTID', field: 'gstid',  width: 100, hide: true},
      {headerName: 'Taxable Value', field: 'amount_untaxed',  width: 100},
      {headerName: 'Tax Amount', field: 'amount_tax',  width: 100, hide: true},
      {headerName: 'Invoice Value', field: 'amount_total',  width: 100},
      {headerName: 'TCS Rate (%)', field: 'tax_source',  width: 100},
      {headerName: 'TCS Amount', field: 'tcs_amount',  width: 100}
    ];
  }
}
