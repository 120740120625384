
<div>
  <div (mouseleave)="showDropDown = false">
    <button class="drop-toggle flat" (click)="showDropDown=!showDropDown">
      <span *ngIf="checkedList.length<=0">{{name}}</span>
   <!--   <span *ngIf="checkedList.length>0">{{checkedList.join(', ')}}</span>-->
      <span *ngIf="checkedList.length>0">{{name}}  ({{checkedList.length}})</span>
     <!-- <i class="fa fa-angle-down"></i>-->
    </button>
    <div class="drop-show" *ngIf="showDropDown">
      <label *ngFor="let a of list">
        <div>
          <input type="checkbox" [(ngModel)]="a.checked"
                 (change)="getSelectedValue(a.checked,a.itemName)" />
          <span style="padding-left: 5px;white-space: nowrap;    overflow: hidden !important;text-overflow: ellipsis;width: 208px;">{{a.itemName}}</span>
        </div>
      </label>
    </div>
  </div>
</div>
