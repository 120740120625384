<!--<div class="center">
  <span class="titletext">APPROVE ASSET</span>
  <p class="messagetext1">Enter quantity to approve</p>
  <input
    class="forminput"
    style="width: 80px"
    type="text"
    name="qty"
    [(ngModel)]="approved_qty"
    #qty="ngModel"
    required
  >
  <div  class="button-row" style="margin-top: 35px;">
     <span>
        <a class="btn white-btn" style="margin-right:30px;" (click)="performAction('no')">CANCEL</a>
     </span>
    <button
      mat-button
      class="btn teal-btn"
      (click)="performAction('yes')"
    >
      APPROVE
    </button>
  </div>
</div>-->


<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button class="closebtn" (click)="closeDialog()">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>{{assetDetails.asset_name}}</h2>
    </div>
    <div class="body-section w-100" >
      <div>
        <div class="col-md-12 pad0" style="background-color: #F3F6FD;padding: 15px;">
          <div class="col-md-6 pr-30">
            <div style="height:300px;width:300px;">
              <img [src]="photo" style="width: 100%; margin: auto;"/>
            </div>
          </div>
          <div class="col-md-4 pad0">
            <div class="row">
              <label>Asset : </label>
              <span>{{assetDetails.asset_type}}</span>
            </div>
            <div class="row" style="margin-top: 10px;">
              <label>Status : </label>
              <span>{{assetDetails.status}}</span>
            </div>
            <div class="row" style="margin-top: 10px;">
              <label>Create Date : </label>
              <span>{{assetDetails.create_date}}</span>
            </div>
            <div *ngIf="attributeList" style="margin-top: 10px;">
              <ng-container *ngFor="let item of attributes;">
                <div class="row" style="margin-top: 10px;">
                  <label>{{item.itemName}} : </label>
                  <span>{{item.value}}</span>
                </div>
              </ng-container>
             <!-- <label>Attributes : </label>
              <span>{{attributes}}</span>-->
            </div>
          </div>
        </div>
        <div class="col-md-12 pad0" style="background-color: #F3F6FD;padding: 15px;">
          <div class="col-md-12 pad0">
            <div class="col-md-6 pr-30">
              <div class="row" style="margin-top: 10px;">
                <label>Retailer Code : </label>
                <span>{{assetDetails.rcode}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label>Retailer : </label>
                <span>{{assetDetails.retailer}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label>Retailer Type: </label>
                <span>{{assetDetails.out_type}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label>MSL Town Tier : </label>
                <span>{{assetDetails.msl_town_tier}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label>Requested  Qty : </label>
                <span>{{assetDetails.qty}}</span>
              </div>
            </div>
            <div class="col-md-4 pr-30">
              <div class="row" style="margin-top: 10px;">
                <label>Salesman Code : </label>
                <span>{{assetDetails.scode}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label>Salesman : </label>
                <span>{{assetDetails.salesman}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label>Class : </label>
                <span>{{assetDetails.class_type}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label>Comments : </label>
                <span>{{assetDetails.comments}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label>Approved Qty : </label>
                <span>{{assetDetails.approved_qty}}</span>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!enableReview && enableApprove" class="col-md-12 pad0" style="padding-left: 30px;margin-top: 10px">
          <p class="messagetext1" style="font-size: 14px;">Enter quantity to approve</p>
          <input
            class="forminput"
            style="width: 80px"
            type="text"
            name="qty"
            [(ngModel)]="approved_qty"
            #qty="ngModel"
            required
          >
        </div>
        <div *ngIf="enableReview || enableApprove" class="col-md-12 pad0" style="padding-left: 30px;margin-top: 20px">
          <div class="button-row" style="margin-top: 10px;">
            <button
              mat-button
              style=" vertical-align:bottom;margin-top: 20px; margin-right: 15px;"
              class="btn teal-btn"
              (click)="performAction('yes')"
            >
              APPROVE
            </button>
            <button
              mat-button
              style=" vertical-align:bottom;margin-top: 20px; margin-right: 15px;"
              class="btn reject-btn"
              (click)="performAction('no')"
            >
              REJECT
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
