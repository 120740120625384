<div id="overlay"></div>
<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper dcvanlist">
      <div class="search-list">
        <div class="col-md-6 pad0">
          <div class="search">
            <span class="search-control"><img src="assets/searchicon.png"/></span>
            <input type="text" placeholder="filter" (keyup)="quickSearch()" [(ngModel)]="searchValue" class="search-control-area"
                   placeholder="Search for DC">
          </div>
          <h5 class="loadLabel" *ngIf="!renderComplete && batchComplete === 0">Loading...</h5>
          <h5 class="loadLabel" *ngIf="!renderComplete && batchComplete > 0">Batch{{batchComplete}} ({{batchCount}}) Completed...</h5>
        </div>
        <div class="col-md-6 pad0">
          <div class="filters">
            <ul class="list-unstyled">
              <li style="margin-left:5px;margin-right:5px !important;" class="margLR">
                <div style="display: inline-block;" >
                  <button
                    class="btn blue-btn1"
                    style="height: 44px;margin-bottom: 10px;"
                    (click)="go()"
                  >GO</button>
                </div>
              </li>
              <li style="margin-right: 1px !important;margin-left: 1px !important;" class="margLR">
                <ng-select [items]="distList"
                           bindLabel="name"
                           multiple="true"
                           maxSelectedItems="1"
                           [(ngModel)]="selectedDist"
                           (ngModelChange)="distChange($event)"
                >
                </ng-select>
              </li>
              <li style="margin-right: 1px !important;margin-left: 1px !important;" class="margLR">
                <ng-select [items]="itemList"
                           bindLabel="itemName"
                           multiple="true"
                           maxSelectedItems="1"
                           [(ngModel)]="selectedItems"
                           (ngModelChange)="typeChange($event)"
                >
                </ng-select>
              </li>

              <li class="margLR">
                <app-excelicon [gridOption]="gridOptions" [title]="'Live Stock Report'"></app-excelicon>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-12 pad0">
          <div>
            <ag-grid-angular id="myGrid1" *ngIf="loaded" style="height: 75vh" class="ag-theme-balham"  [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                             (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents" [context]="context">
            </ag-grid-angular>
            <div *ngIf="preloader">
              <app-loadingstate></app-loadingstate>
            </div>
            <div *ngIf="empty_state">
              <app-emptystate></app-emptystate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
