import {ChangeDetectorRef, Component} from '@angular/core';
import {GadgetInstanceService} from '../../grid/grid.service';
import {GadgetPropertyService} from '../_common/gadget-property.service';
import {GadgetBase} from '../_common/gadget-base';
import {OptionsService} from '../../configuration/tab-options/service';
import {ApiService} from '../../../_services/api.service';
import {ErrorHandler} from '../../../error/error-handler';
import {Router} from '@angular/router';
import {DashboardFilterService} from "../../services/dashboard-filter.service";
import {LocalforageService} from "../../../_services/localforage.service";
import {MatDialog} from "@angular/material/dialog";
import {CustomConfigService} from "../../../_services/CustomConfigService";

@Component({
    selector: 'app-dynamic-component',
    moduleId: module.id,
    templateUrl: './view.html',
    styleUrls: ['../_common/styles-gadget.css',
    '../_common/styles-gadget-common.scss']
})
export class StatisticGadgetComponent extends GadgetBase {

    gadgetHasOperationControls = false;

    // runtime document subscription
    data: any;
    category = 'sales';

  constructor(protected _apiService: ApiService,
              protected _gadgetInstanceService: GadgetInstanceService,
              protected _propertyService: GadgetPropertyService,
              protected _changeDetectionRef: ChangeDetectorRef,
              protected _optionsService: OptionsService,
              protected _configService: CustomConfigService,
              protected _dashboardFilterService: DashboardFilterService,
              protected _router: Router,
              protected _localforageService: LocalforageService,
              protected _dialog: MatDialog,

  ) {
    super(_apiService,
      _gadgetInstanceService,
      _propertyService,
      _changeDetectionRef,
      _optionsService,
      _configService,
      _dashboardFilterService,
      _router,
      _localforageService,
      _dialog);

    // this.preRun();

  }


  public preRun(): void {
        // this.updateGadgetWithProperties();
        this.kpiName = this.getPropFromPropertyPages('kpi_name');
        this.dataUpdated = false;
        this.setAPIPath('/api/pwa_boards/get_static_kpi');
        this.run();
    }

    public run() {
        this.initializeRunState(true);
        this.updateData(null);
    }

    public stop() {
        this.setStopState(false);
    }



  private getCacheKey() {
    return this.kpiName + this.filterData.period + '_' + this.filterData.region_id + '_' + this.filterData.cluster_id + '_' + this.filterData.team_id + '_' + this.filterData.salesman_id + '_' + this.filterData.territory_id;
  }

    public updateData(data: any[]) {
      const paramObject: any = {};
      paramObject.cid = JSON.parse(localStorage.getItem('user')).cid;
      paramObject.access_token = localStorage.getItem('resfreshToken');
      paramObject.kpi_name = this.kpiName;
      if (this.filterData != null) {
        paramObject.filterData = this.filterData;
      }

      this._localforageService.get(this.getCacheKey()).then(r => {
        if (r !== null) {
            console.log('Fetched from local');
            this.data = r;
            this.dataUpdated = true;
            this.stop();
            this.changeDetectionRef.detectChanges();
        } else {
          this.dataUpdated = false;
          this._apiService.post(this.getAPIPath(), paramObject)
            .subscribe(res => {
              if (res.hasOwnProperty('results') && (res.results.status === 200)) {
                this.data = res.results.data;
                // if (this.data.hasOwnProperty('title')) {
                //   this.title = this.data.title;
                // }
                // if (this.data.hasOwnProperty('category')) {
                //   this.category = this.data.category;
                // }
                console.log('Fetched from API');
                this._localforageService.set(this.getCacheKey(), this.data);

                this.dataUpdated = true;
                this.stop();
                this.changeDetectionRef.detectChanges();
              } else {
                this.handleError(ErrorHandler.getErrorObject('Internal Error'));
              }
            }, error => this.handleError(ErrorHandler.getErrorObject(error)));
        }
      });
    }


    public updateProperties(updatedProperties: any) {

        /**
         * todo
         *  A similar operation exists on the procmman-config-service
         *  whenever the property page form is saved, the in memory board model
         *  is updated as well as the gadget instance properties
         *  which is what the code below does. This can be eliminated with code added to the
         *  config service or the property page service.
         *
         */
        const updatedPropsObject = JSON.parse(updatedProperties);
      // tslint:disable-next-line:only-arrow-functions
        this.propertyPages.forEach(function(propertyPage) {
          // tslint:disable-next-line:prefer-for-of
            for (let x = 0; x < propertyPage.properties.length; x++) {
                for (const prop in updatedPropsObject) {
                    if (updatedPropsObject.hasOwnProperty(prop)) {
                        if (prop === propertyPage.properties[x].key) {
                            propertyPage.properties[x].value = updatedPropsObject[prop];
                        }

                    }
                }
            }
        });

        this.title = updatedPropsObject.title;
        this.kpiName = this.getPropFromPropertyPages('kpi_name');
        this.updateData(null);
        this.toggleConfigMode();
    }

  clearChartData(): void {
  }

}
