import {Component, ElementRef, NgZone, OnInit, Renderer2, ViewChild} from "@angular/core";
import {ApiService} from "../_services/api.service";
import {Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {SharedService} from "../_services/shared_service";
import * as XLSX from "xlsx-js-style";
import {ToastrService} from "ngx-toastr";
import {PageBase} from "../pagebase/pagebase";

@Component({
  selector: 'app-user-task',
  templateUrl: '../pagebase/pagebase.html',
  styleUrls: ['./task.component.scss']
})
export class UserTaskListComponent extends PageBase implements OnInit, IPage {

  task_types = [];
  ngOnInit(): void {

    this.sharedService.setHeader('User Tasks');
    if (this.sharedService.role_features && this.sharedService.ACLcheck('feature_upload_task')) {
      this.uploadTask = true;
    }
    this.task_types = JSON.parse(localStorage.getItem('task_types'));
    const date = new Date();
    this.myDate[0] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd');
    this.showExcelSample = true;
    this.excel_sample = [
      {
        TaskCode: 'JWBSK10998661',
        TaskName: 'JWB Shelf Presence',
        Type: 'Activation',
        UserCode: 'VermaRak',
        'Start Date': this.myDate[0],
        'End Date': this.myDate[1]
      },
      {
        TaskCode: 'JWBSK10998661',
        TaskName: 'JWB Shelf Presence',
        Type: 'Activation',
        UserCode: 'VermaRak',
        'Start Date': this.myDate[0],
        'End Date': this.myDate[1]
      }
    ];
    this.excel_sample_title = 'user_task_upload';
    this.configureGrid();
    this.loadServerData();
  }

  configureGrid() {
    const inThis = this;
    this.API_URL = '/api/tasks/get_user_task_list';
    this.columnDefs = [
      {headerName: "CODE",  field: "code",     width: 100},
      {headerName: "TASK",  field: "name",     width: 250},
      {headerName: "DESCRIPTION",  field: "description",     width: 150},
      {headerName: "TYPE",  field: "type_name",     width: 100},
      {headerName: "BRAND",  field: "brand",     width: 100},
      {headerName: "QCODE",  field: "q_code",     width: 100},
      {headerName: "QUESTIONNAIRE",  field: "q_name",     width: 150},
      {headerName: "OWNER",  field: "user_name",     width: 100},
      {
        headerName: "CREATE DATE", field: "create_date", width: 150,
        valueGetter: params => {
          if (params.data) {
            if (params.data.create_date) {
              return inThis.datePipe.transform((new Date(params.data.create_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return params.data.create_date;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: "START DATE",  field: "start_date",     width: 100},
      {headerName: "END DATE",  field: "end_date",     width: 100},
      {headerName: "NO OF OUTLET",  field: "line_count",   cellStyle: {textAlign: 'right'},   width: 100},
      {
        headerName: "PROGRESS",  field: "progress",   width: 120, menuTabs: [],

        valueGetter: params => {
          if (parseInt(params.data.line_count, 10) === 0) {
            return 0;
          } else {
            return Math.trunc((parseInt(params.data.completed_count, 10) / parseInt(params.data.line_count, 10)) * 100);
          }

        },
        cellRenderer: params => {
          let displayElem = '<div class="row">\n' +
            '            <div class="progresscontainer1">\n' +
            '              <div class="progressinside1" style=" ';

          if (params.value > 50) {
            displayElem = displayElem + ' background-color: #379862;';
          } else if (params.value > 20) {
            displayElem = displayElem + ' background-color: #D97C0E;';
          } else {
            displayElem = displayElem + ' background-color: #E24260;';
          }

          displayElem = displayElem + 'width:' + params.value + '%;"></div>\n' +
            '            </div>\n' +
            '            <div class="progresstext1" style="';

          if (params.value > 50) {
            displayElem = displayElem + ' color: #379862;';
          } else if (params.value > 20) {
            displayElem = displayElem + ' color: #D97C0E;';
          } else {
            displayElem = displayElem + ' color: #E24260;';
          }

          displayElem = displayElem + '">' + params.value + '%</div></div>';


          return displayElem;
        }
      },
      {headerName: "COMPLETED NO",  field: "completed_count",   cellStyle: {textAlign: 'right'},   width: 100},
      {headerName: "STATUS",  field: "status",     width: 100},
    ];
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.setRowData(this.rowData);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      });
    });
    this.gridApi.sizeColumnsToFit();
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }
  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    localStorage.setItem('taskid', rows[0].id);
    this.router.navigate([this.apiService.redirect_url + '/user_tasks/detail'], {
      state: {
        viewtype: 'detail',
        taskid: rows[0].id
      }
    });
  }

  async uploadexcel() {
    //debugger;
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    this.uploading = true;
    this.upload_loader = true;
    let distinct_order = [];
    const line_items = [];
    const map = new Map();
    const inthis = this;
    let succcessCount = 0;
    let failedCount = 0;
    let failed_tasks = [];
    let task_status = "";
    let unauthorised_access = false;
    const paramObject: any = {};
    let obj = {};
    paramObject.access_token = this.userData.access_token;
    let invalid = false;
    const datepattern = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
    const numcharPattern = /^([a-z0-9]+)$/i;
    for (const item of inthis.upload_task_data) {

      if (item.TaskCode === null || item.TaskCode === undefined || item.TaskCode === "") {
        invalid = true;
        this.toastr.warning('Task Code cannot be empty');
        break;
      }
      if (item.UserCode === null || item.UserCode === undefined || item.UserCode === "") {
        invalid = true;
        this.toastr.warning('User Code cannot be empty');
        break;
      }
      if (item['End Date'] === null || item['End Date'] === undefined) {
        invalid = true;
        this.toastr.warning('End Date cannot be empty');
        break;
      }
      if (item.Type === null || item.Type === undefined || item.Type === "") {
        invalid = true;
        this.toastr.warning('Task Type cannot be empty');
        break;
      } else if (!this.taskTypeValidation(item.Type)) {
        invalid = true;
        this.toastr.warning('Invalid Task Type : ' + item.Type);
        break;
      }
      item['End Date'] = this.sharedService.convertExcelStringToDate(item['End Date']);
      if (!item['End Date'] || item['End Date'] === '') {
        invalid = true;
        this.toastr.warning('Invalid Date Format');
        break;
      }
      let allow_start_date = true;
      let start_date = '';
      if (item['Start Date'] === null || item['Start Date'] === undefined) {
        allow_start_date = false;
        start_date = null;
      } else {
        allow_start_date = true;
        start_date = this.sharedService.convertExcelStringToDate(item['Start Date']);
        if (!start_date || start_date === '') {
          invalid = true;
          this.toastr.warning('Invalid Date Format');
          break;
        }
      }
      obj = {
        name: item.TaskName
          .replace(/"/g, "")
          .replace(/'/g, ""),
        code: item.TaskCode,
        status: 'draft',
        type: item.Type,
        allow_start_date,
        start_date,
        end_date: item['End Date'],
        description: '',
        instructions: '',
        user_code: item.UserCode,
        question_code: item.QuestionCode
      };
      if (item['TaskCode'] !== null) {
        if (map.get(item['TaskCode']) !== true) {
          map.set(item['TaskCode'], true);
          distinct_order.push(obj);
          line_items.push(obj);
        } else {
          line_items.push(obj);
        }
      }
    }
    if (!invalid) {
      let total_len = distinct_order.length;
      let upload_task;
      if (distinct_order.length > 0) {
        do {
          upload_task = distinct_order.splice(1000);
          paramObject.data = distinct_order;
          paramObject.line_items = line_items;
          const resp = await this.apiService.uploadUserTask(paramObject);
          if (resp.hasOwnProperty('results') && resp['results'].status === 200) {
            task_status = resp['results'].data.status;
            if (resp['results'].data.invalidcount > 0) {
              failedCount = resp['results'].data.invalidcount;
              succcessCount = line_items.length - failedCount;
            } else {
              succcessCount += line_items.length;
            }
          } else if (resp.hasOwnProperty('results') && (resp['results'].status === 199 || resp['results'].status === 203)) {
            this.toastr.warning(task_status);
            unauthorised_access = true;
            failedCount += 1;
            break;
          } else {
            if (resp['results'].msg.includes('partner_unique')) {
              this.toastr.warning('duplicate users are available for same task');
            }
            failedCount += 1;
          }
          distinct_order = upload_task;
        } while (distinct_order.length > 0);
        if (unauthorised_access) {
          this.uploadCaption = [task_status];
        } else {
          this.uploadCaption = ['Uploading completed, Successes: ' + succcessCount + ',Failures: ' + failedCount + " - " + task_status, 0];
        }
        this.uploading = false;
        this.upload_loader = false;
        this.fileUp.nativeElement.value = null;
        this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
        this.fileUploaded = null;
        this.loadServerData();
      } else {
        // this.toastr.warning('Customer List Not Loaded Yet.Try Again Later');
      }
    } else {
      this.uploading = false;
      this.upload_loader = false;
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    }

  }

  taskTypeValidation(type) {
    let found = false;
    for (const key in this.task_types) {
      if (this.task_types[key]['itemname'] === type) {
        found = true;
      }
    }
    return found;
  }

  ExcelDateToJSDate(serial) {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    const date_info = new Date(utc_value * 1000);

    const fractional_day = serial - Math.floor(serial) + 0.0000001;

    let total_seconds = Math.floor(86400 * fractional_day);

    const seconds = total_seconds % 60;

    total_seconds -= seconds;
    return ((date_info.getMonth() + 1)).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })
      + '/' + date_info.getDate().toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })
      + '/' + date_info.getFullYear();
  }

}
