import {Component, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ApiService} from "../../_services/api.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-asset-approve-popup',
  templateUrl: './asset_approve_popup.component.html',
  styleUrls: ['./asset_approve_popup.component.scss']
})
export class AssetApprovePopupComponent implements OnInit {

  @Output() selectedAction: EventEmitter<any> = new EventEmitter<any>();
  asset_id = 0;
  approved_qty = 0;
  state = '';
  title = '';
  assetDetails: any = [];
  photo;
  enableReview = false;
  enableApprove = false;
  attributeList;
  attributes = [];

  constructor(
    public dialog: MatDialog,
    public apiService: ApiService,
    private domSanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.assetDetails = dialogdata.assetDetails;
    this.photo = dialogdata.photo;
    this.enableReview = dialogdata.enableReview;
    this.enableApprove = dialogdata.enableApprove;
    this.attributeList = dialogdata.attributeList;
    if (this.attributeList) {
      for (const key of this.attributeList) {
        if (this.assetDetails['attr_' + key]) {
         // this.attributes += key + " : " + this.assetDetails['attr_' + key] + ", ";
          this.attributes.push({
            itemName: key,
            value: this.assetDetails['attr_' + key]
          });
        }
      }
    }

    if (!this.photo) {
      if (this.assetDetails.hasOwnProperty('asset_store_fname') && this.assetDetails.asset_store_fname && this.assetDetails.asset_store_fname !== "") {
        this.fetchImage();
      } else {
        this.photo =  './../../../assets/no-image-icon.png';
      }
    }
  }

  ngOnInit(): void {

  }

  closeDialog() {
    this.dialog.closeAll();
  }

  performAction(action) {
    const item = {
      action,
      approved_qty: this.approved_qty
    };
    this.selectedAction.emit(item);
  }

  fetchImage() {
    const API_URL = '/api/pwa_connects/fetch_file';
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.store_fname = this.assetDetails.asset_store_fname;
    paramObject.container = 'partner_asset';
    console.log(paramObject);
    this.apiService.post(API_URL, paramObject)
      .subscribe(res => {
          console.log(res);

          if (res.hasOwnProperty('result') && res.result.hasOwnProperty('data') && res['result'].data.length > 0) {
            try {
              this.photo = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + res['result'].data);
            } catch (e) {
              this.photo =  './../../../assets/no-image-icon.png';
            }
          } else {
            this.photo =  './../../../assets/no-image-icon.png';
          }
        },
        error => {
          console.log(error);

        });
  }
}
