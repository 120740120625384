import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class TaskAdherenceReportComponent extends ReportBase implements IReport, OnInit {

  showCustomFilter = true;
  customFilter: any = {
    name: 'Status',
    key: 'status',
    value: 'not_visited',
    values: [],
    show: true
  };

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Task Adherence Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.showDate = false;
    this.customFilter.values = [
      {id: '', itemName: 'All'},
      {id: 'not_visited', itemName: 'Not Visited'},
      {id: 'visited_not_complete', itemName: 'Visited and Not Complete'},
      {id: 'exclude_visit_complete', itemName: 'Exclude Visited and Completed'}
    ];

    this.configureGrid();
      }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getEmployeeMasterReport';

    this.columnDefs = [

      {headerName: 'REGION', field: 'region', enableRowGroup: true, width: 80},
      {headerName: 'CLUSTER', field: 'cluster', enableRowGroup: true, width: 80},
      {headerName: 'STATE', field: 'state', enableRowGroup: true, width: 80},
      {headerName: 'OUTLET', field: 'outlet', width: 100, enableRowGroup: true},
      {headerName: 'TASK/SCHEME', field: 'task', width: 100, enableRowGroup: true},
      {headerName: 'VISIT', field: 'visit', menuTabs: [], width: 80},
      {headerName: 'ADHERENCE', field: 'adherence', enableRowGroup: true, width: 150},
      {headerName: 'SCORE', field: 'score', enableRowGroup: true, width: 100},
      {headerName: 'ADJUSTED SCORE', field: 'adjusted_score', enableRowGroup: true, width: 100},
      {headerName: 'FREQUENCY', field: 'frequency', enableRowGroup: true, width: 150},
      {headerName: 'EXPECTED VISITS', field: 'expected_visits', hide: true, enableRowGroup: true, width: 150},
    ];

  }

}
