import {Component, ElementRef, NgZone, OnInit, Renderer2} from "@angular/core";
import {ApiService} from "../../../_services/api.service";
import {Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {SharedService} from "../../../_services/shared_service";
import {ErrorObject} from "../../../error/error-model";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {CustomGridToolPanelComponent} from "../../../reports/_common/CustomGridToolPanel.component";
import {ErrorHandler} from "../../../error/error-handler";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {ToastrService} from "ngx-toastr";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-lead-details',
  templateUrl: './lead_details.component.html',
  styleUrls: ['./lead_details.component.scss']
})
export class LeadDetailsComponent implements OnInit {

  apiData: any = [];
  attributeList: any = [];
  entity: any = {};
  userData = {
    start_date: null,
    end_date: null,
    period_id: 0,
    access_token: null,
    url: null,
    offset: null,
    offsetID: 0,
    flag: true,
    type: '',
    data: {
      id: null,
      date: null
    },
    filterData: {
      date: null,
      access_token: null,
      url: null,
      last_date: null,
      offset: null,
      flag: true,
      out_type: -1,
      salesmanID: 0,
      policyID: 0,
      outlet_type: 0,
      travel_type_id: 0,
      class_type: null,
      program_type: 0,
      status: null,
      max_claim: false,
      trax_outlet: false,
      non_trax_outlet: false,
      exclude_trax_audit: false,
      region_id: 0,
      cluster_id: 0,
      team_id: 0,
      territory_id: 0,
      dc_id: 0,
      displayData: [],
      payment_mode: ''
    },
    customFilter: {}
  };
  showCustomFilter = false;
  customFilter: any = {};
  periodID: 0;
  showDate = true;
  dateFilterType: any = 'range';
  title = '';

  public gridColumnApi: any;
  public gridApi: any;
  public pinnedParams = [];
  preloader: any;
  emptyState: any;
  errorObject: ErrorObject;
  errorExists = false;
  dataLoaded: any;
  gridOptions: GridOptions;
  styleGrid: any;
  columnDefs = [];
  frameworkComponents: any;
  context: any;
  rowData = [];

  public gridColumnApi1: any;
  public gridApi1: any;
  public pinnedParams1 = [];
  preloader1: any;
  emptyState1: any;
  errorObject1: ErrorObject;
  errorExists1 = false;
  dataLoaded1: any;
  gridOptions1: GridOptions;
  styleGrid1: any;
  columnDefs1 = [];
  frameworkComponents1: any;
  context1: any;
  rowData1 = [];

  public gridColumnApi2: any;
  public gridApi2: any;
  public pinnedParams2 = [];
  preloader2: any;
  emptyState2: any;
  errorObject2: ErrorObject;
  errorExists2 = false;
  dataLoaded2: any;
  gridOptions2: GridOptions;
  styleGrid2: any;
  columnDefs2 = [];
  frameworkComponents2: any;
  context2: any;
  rowData2 = [];

  myDate: any;
  searchValue: any;

  filterData: any;

  API_URL = '';

  dateSelectMode = 'range';

  showableFilters = {
    max_claim: false,
    policy: false,
    salesman: false,
    status: false,
    class: false,
    beat: false,
    trax_outlet: false,
    non_trax_outlet: false,
    exclude_trax_audit: false,
    region: false,
    cluster: false,
    team: false,
    territory: false,
    outlet_type: false,
    payment_mode: false,
    dc: false,
    state: false
  };
  filterSet = false;
  progress = 0;
  progressText = '';
  showProgress = false;
  showProgressBar = false;
  progressMode = 'indeterminate';
  showProgressCustomText = false;
  progressTotal = 0;
  keyColumn = '';

  uploadTask = false;
  public uploadCaption = ['', 0];
  public fileUploaded: File;
  public storeData: any;
  public worksheet: any;
  public uploading = false;
  public upload_loader = false;
  public upload_enable = false;
  public upload_task_data;
  public order_data = [];
  succcessCount = 0;
  failedCount = 0;
  uploadexcel;
  public orderDetails = {'label_config': {}};
  public all_orders = [];
  public payment_enable: boolean;
  goClicked = false;

  user_report_config = [];
  role_report_config = [];
  customerData = [];
  customerDetails: any = {};
  rowIndex = 0;
  activetab = 'Overview';
  zoom: number;
  map: any;
  latitude: number;
  longitude: number;
  edit = false;
  verticals: any = [];
  verticalEdit = false;
  params: any = [];
  paramEdit = false;
  changeRequestData = [];
  masterGalleryData = [];
  galleryphotodata = null;
  galleryphotodatas = [];
  mainGalleryData = [];
  shelfGalleryData = [];
  btlGalleryData = [];
  categoryChartData = [];
  view1: any[] = [400, 170];
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = false;
  xAxisLabel: string = 'Country';
  showYAxisLabel: boolean = false;
  yAxisLabel: string = 'Population';
  animations: boolean = true;
  categoryChartTitle = 'Category Projection';
  colorScheme = {
    domain: ['#F3622D', '#57B757']
  };
  salesDataLoaded = false;
  private user_report_json: unknown;

  constructor(private apiService: ApiService,
              private router: Router,
              private datePipe: DatePipe,
              private renderer: Renderer2,
              private sharedService: SharedService,
              private ngzone: NgZone,
              private elRef: ElementRef,
              private domSanitizer: DomSanitizer,
              private toastr: ToastrService) {
    this.userData.access_token = localStorage.getItem('resfreshToken');
    this.userData.url = localStorage.getItem('private_url');
    this.customerData = JSON.parse(localStorage.getItem('customerdata'));
    this.rowIndex = parseInt(localStorage.getItem('customerindex'), 0);
    this.userData.data.id = this.customerData[this.rowIndex];
    this.zoom = 15;

    this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.userData.data.date = this.myDate;

    const that = this;
    this.gridOptions = {
      context: this,
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      defaultColDef: {
        headerClass: 'myagheader',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true
      },
      /*  isExternalFilterPresent() {
          return true;
        },*/

      /* // return true if flag=true
       doesExternalFilterPass(rowNode) {
         return rowNode.data.name !== 'aggridedit';
       }*/

    } as GridOptions;
    this.frameworkComponents = {
      CustomToolPanel: CustomGridToolPanelComponent
    };

    this.gridOptions1 = {
      context: this,
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      defaultColDef: {
        headerClass: 'myagheader',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true
      },
      /*  isExternalFilterPresent() {
          return true;
        },*/

      /* // return true if flag=true
       doesExternalFilterPass(rowNode) {
         return rowNode.data.name !== 'aggridedit';
       }*/

    } as GridOptions;
    this.frameworkComponents1 = {
      CustomToolPanel: CustomGridToolPanelComponent
    };

    this.gridOptions2 = {
      context: this,
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      defaultColDef: {
        headerClass: 'myagheader',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true
      },
      /*  isExternalFilterPresent() {
          return true;
        },*/

      /* // return true if flag=true
       doesExternalFilterPass(rowNode) {
         return rowNode.data.name !== 'aggridedit';
       }*/

    } as GridOptions;
    this.frameworkComponents2 = {
      CustomToolPanel: CustomGridToolPanelComponent
    };

    if (localStorage.getItem('all_attributes') !== null && localStorage.getItem('all_attributes') !== 'null' && JSON.parse(localStorage.getItem('all_attributes')) !== null) {
      let attrlist = JSON.parse(localStorage.getItem('all_attributes'));
      if (attrlist !== null) {
        attrlist = attrlist.filter(attr => attr.type === 'retailer').map(obj => obj.name);
        if (attrlist !== null) {
          this.attributeList = attrlist;
        }
      }
    }

    this.sharedService.filterSetEvent
      .subscribe((set: boolean) => {
        if (set) {
          this.filterSet = true;
        } else {
          this.filterSet = false;
        }
      });

    /*this.gridOptions.doesExternalFilterPass = rowNode => {
      if (that.attributeEdit) {
        return rowNode.data.name === 'aggridedit';
      } else {
        return rowNode.data.name !== 'aggridedit';
      }
    };*/
  }

  ngOnInit(): void {
    this.sharedService.setHeader("Lead Details");
    this.API_URL = '/api/crm_leads/get_lead_details';
    this.configureAttributeGrid();
    this.configureParamGrid();
    this.configureCRGrid();
    this.getCustomerDetails();
    this.getSalesData();
  }

  public handleError(error: ErrorObject) {
    this.preloader = false;
    this.emptyState = false;
    this.dataLoaded = false;
    this.errorExists = true;
    this.errorObject = error;
  }

  configureAttributeGrid() {
    this.columnDefs = [
      {
        headerName: 'SUPPLIER', field: 'supplier_id', menuTabs: [], width: 60,
        editable(params) {
          // allow `min_value` cell to be edited for rows with correct `validation_type`
          return params.context.attributeEdit;
        }
      },
      {
        headerName: 'GROUP', field: 'bg_id', menuTabs: [], width: 60,
        editable(params) {
          // allow `min_value` cell to be edited for rows with correct `validation_type`
          return params.context.attributeEdit;
        }
      },
      {
        headerName: 'POTENTIAL', field: 'config', menuTabs: [], width: 60,
        editable(params) {
          // allow `min_value` cell to be edited for rows with correct `validation_type`
          return params.context.attributeEdit;
        },
        valueGetter(params) {
          if (params.data) {
            if (params.data.config_json !== null) {
              if (params.data.config_json.hasOwnProperty('potential')) {
                return params.data.config_json.potential;
              }
            }
          }
        }
      },
      {
        headerName: "", headerClass: 'myagheader', field: "delete", cellStyle: {textAlign: 'right'}, width: 20, resizable: true,
        cellRenderer(params) {
          if (params.data && params.context.attributeEdit) {
            return ' <span title="Delete" ><i class="fas fa-trash delete" data-action-type="delete"></i></span>';
          } else {
            return "";
          }
        }
      },
    ];
  }

  configureParamGrid() {
    this.columnDefs1 = [
      {
        headerName: 'NAME', field: 'n', menuTabs: [], width: 60,
        editable(params) {
          // allow `min_value` cell to be edited for rows with correct `validation_type`
          return params.context.paramEdit;
        }
      },
      {
        headerName: 'VALUE', field: 'v', menuTabs: [], width: 60,
        editable(params) {
          // allow `min_value` cell to be edited for rows with correct `validation_type`
          return params.context.paramEdit;
        }
      },
      {
        headerName: 'CATEGORY', field: 'c', menuTabs: [], width: 60,
        editable(params) {
          // allow `min_value` cell to be edited for rows with correct `validation_type`
          return params.context.paramEdit;
        }
      },
     /* {
        headerName: 'TYPE', field: 't', menuTabs: [], width: 60,
        editable(params) {
          // allow `min_value` cell to be edited for rows with correct `validation_type`
          return params.context.paramEdit;
        }
      },*/
      {
        headerName: "", headerClass: 'myagheader', field: "delete", cellStyle: {textAlign: 'right'}, width: 20, resizable: true,
        cellRenderer(params) {
          if (params.data && params.context.paramEdit) {
            return ' <span title="Delete" ><i class="fas fa-trash delete" data-action-type="delete"></i></span>';
          } else {
            return "";
          }
        }
      },
    ];
  }

  configureCRGrid() {
    const inThis = this;
    this.columnDefs2 = [
      {
        headerName: 'Request Type', field: 'request_type',
        width: 100, enableRowGroup: true,
        valueGetter: params => {
          if (params.data) {
            /*if (params.data.request_type === 'contact_details') {
              return 'Contact';
            } else if (params.data.request_type === 'location_with_address' ||) {
              return 'Location With Add';
            } else if (params.data.request_type === 'program_type') {
              return 'Program Type';
            } else {
              return params.data.request_type;
            }*/

            if (params.data.request_type != null) {
              /* let str = params.data.request_type.replace(/_/g, ' ');*/
              const str = params.data.request_type;
              return str
                .replace(/_/g, ' ')
                .replace(/(^\w|\s\w)/g, m => m.toUpperCase());
              /*.replace(/\s(.)/g, $1 => $1.toUpperCase())
              .replace(/\s/g, '')
              .replace(/^(.)/, $1 => $1.toLowerCase());*/
            }
          }
        }
      },
      {
        headerName: 'Request Date', field: 'req_date',
        width: 110,
        valueGetter: params => {
          if (params.data) {
            return inThis.datePipe.transform((new Date(params.data.req_date + ' UTC')).toString(), 'dd/MM/yyyy');
          }
        }
      },
      {
        headerName: 'Requested By', field: 'req_by',
        width: 150, enableRowGroup: true
      },
      {
        headerName: 'Old Value', field: 'old_val',
        maxWidth: 200,
        valueGetter: inThis.oldValueGetter
      },
      {
        headerName: 'New Value', field: 'new_val', wrapText: true,
        maxWidth: 200,
        valueGetter: inThis.newValueGetter
      },
      {
        headerName: 'Approved By', field: 'app_by', width: 100
      },
      {
        headerName: 'Approved Date', field: 'app_date', width: 110,
        valueGetter: params => {
          if (params.data && params.data.app_date) {
            return inThis.datePipe.transform((new Date(params.data.app_date + ' UTC')).toString(), 'dd/MM/yyyy');
          }
        }
      },
      {
        headerName: 'Status', field: 'status',
        width: 100, enableRowGroup: true,
        cellRenderer(params) {
          let displayElem = '';
          if (params.data) {
            if (params.value === 'accepted') {
              displayElem = '<span style="color:#379862; font-weight: 500;">' + params.value.toUpperCase() + '</span>';
            } else if (params.value === 'rejected') {
              displayElem = '<span style="color:#B82525; font-weight: 500;">' + params.value.toUpperCase() + '</span>';
            } else {
              displayElem = '<span style="color:#17A2B8; font-weight: 500;">' + params.value.toUpperCase() + '</span>';
            }
          }
          return displayElem;
        },
      },
    ];
  }

  getCustomerDetails() {
    this.goClicked = true;
    this.dataLoaded = false;
    this.preloader = true;
    this.emptyState = false;
    this.errorExists = false;
    this.verticals = [];
    this.params = [];

    if (this.API_URL === '') {
      return this.handleError(ErrorHandler.getErrorObject('API Not Configured'));
    }


    this.apiService.post(this.API_URL, this.userData)
      .subscribe(res => {
          console.log(res);
          // t.stop();
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.apiData = res.results.data; //TODO: check if this line is needed
            this.customerDetails = res.results.data.master;
            this.changeRequestData = res.results.data.cr;
            this.masterGalleryData = res.results.data.gallery;
            /*  if (this.customerDetails.beat !== null && this.customerDetails.beat.length > 0) {
                this.customerDetails.beatname = this.customerDetails.beat[0].bp;
              }*/
            /*  if (this.customerDetails.payment_mode !== null) {
                this.customerDetails.payment_mode = this.titleCase(this.customerDetails.payment_mode.toString().toLowerCase().replace(/_/g, " "));
              }*/
            this.latitude = parseFloat(this.customerDetails.lat);
            this.longitude = parseFloat(this.customerDetails.lng);

            /*   if ((this.customerDetails.verticals !== null)) {
                 const attr = this.customerDetails.verticals;
                 for (const i in attr) {
                   this.verticals.push({name: i, value: attr [i]});
                 }
               }
               this.rowData = this.verticals;*/

            if (this.customerDetails.params !== null) {
              const jsonObject = JSON.parse(this.customerDetails.params);
              if (jsonObject !== null) {
                if (jsonObject.hasOwnProperty('params')) {
                  this.rowData1 = jsonObject.params;
                  this.params = jsonObject.params;
                }
                if (jsonObject.hasOwnProperty('verticals')) {
                  this.rowData = jsonObject.verticals;
                  this.verticals = jsonObject.verticals;
                }

                if (jsonObject.hasOwnProperty('entity_vals')) {
                  this.entity = jsonObject.entity_vals;
                }
              }
            }

            if (res.results.data.hasOwnProperty('details') && res.results.data.details !== null) {
              if (res.results.data.details.hasOwnProperty('beat')) {
                this.customerDetails.beat = res.results.data.details.beat;
                this.entity.beat = res.results.data.details.beat;
              }
              if (res.results.data.details.hasOwnProperty('territory')) {
                this.customerDetails.territory = res.results.data.details.territory;
                this.entity.territory = res.results.data.details.territory;
              }
              if (res.results.data.details.hasOwnProperty('region')) {
                this.customerDetails.region = res.results.data.details.region;
                this.entity.region = res.results.data.details.region;
              }
              if (res.results.data.details.hasOwnProperty('cluster')) {
                this.customerDetails.cluster = res.results.data.details.cluster;
                this.entity.cluster = res.results.data.details.cluster;
              }
              if (res.results.data.details.hasOwnProperty('program')) {
                this.entity.program = res.results.data.details.program;
              }
            }

            this.dataLoaded = true;
            //this.rowData should be ready now
            this.preloader = false;
            if (this.rowData === null || this.rowData === undefined) {
              this.emptyState = true;
            } else if (this.rowData.length === 0) {
              this.emptyState = true;
            } else {
            }

            /*if ((this.customerDetails.params !== null)) {
              const param = this.customerDetails.params;
              for (const i in param) {
                this.params.push({name: i, value: param [i]});
              }
            }*/


            this.dataLoaded1 = true;
            //this.rowData should be ready now
            this.preloader1 = false;
            if (this.rowData1 === null || this.rowData1 === undefined) {
              this.emptyState1 = true;
            } else if (this.rowData1.length === 0) {
              this.emptyState1 = true;
            } else {
            }

            this.rowData2 = this.changeRequestData;
            this.dataLoaded2 = true;
            //this.rowData should be ready now
            this.preloader2 = false;
            if (this.rowData2 === null || this.rowData2 === undefined) {
              this.emptyState2 = true;
            } else if (this.rowData2.length === 0) {
              this.emptyState2 = true;
            } else {
            }

            this.getGalleryImages();

          } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
            this.emptyState = true;
            this.emptyState1 = true;
            this.emptyState2 = true;
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.emptyState = true;
            this.emptyState1 = true;
            this.emptyState2 = true;
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        // tslint:disable-next-line:no-shadowed-variable
        error => this.handleError(ErrorHandler.getErrorObject(error)));
  }

  getSalesData() {
    this.salesDataLoaded = false;
    this.apiService.post('/api/res_partners/get_retailer_monthly_sales', this.userData)
      .subscribe(res => {
          console.log(res);
          // t.stop();
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.constructSalesChart(res.results.data);
          } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        // tslint:disable-next-line:no-shadowed-variable
        error => this.handleError(ErrorHandler.getErrorObject(error)));
  }

  constructSalesChart(data) {
    data.forEach(item => {
      var categoryData: any = {};
      categoryData.name = item.category;
      categoryData.series = [];
      categoryData.series.push(
        {name: 'lmtd_sale_achv', value: item.lmtd_sale_achv},
        {name: 'mtd_sale_achv', value: item.mtd_sale_achv},
      );
      this.categoryChartData.push(categoryData);
    });
    this.salesDataLoaded = true;
  }

  async getGalleryImages() {
    let images = [];
    if (this.masterGalleryData !== null && this.masterGalleryData !== undefined && this.masterGalleryData.length > 0) {
      this.masterGalleryData.forEach(image => {
        if (image != null) {
          images.push({store_fname: image.store_fname, container: 'res_partner_gallery', type: image.type});
        }
      });
      if (images.length > 0) {
        const res = await this.apiService.fetchExpenseImage({
          access_token: this.userData.access_token,
          url: this.userData.url,
          data: images
        });
        if (res.hasOwnProperty('result') && res['result'].length > 0) {
          console.log(res);
          this.galleryphotodata = [];
          const domSanitizer = this.domSanitizer;
          res['result'].forEach(photo => {
            this.galleryphotodata.push({photo: domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + photo.bill_datas), type: photo.type});
          });
          this.mainGalleryData = this.galleryphotodata;
          this.shelfGalleryData = this.galleryphotodata.filter(photo => photo.type === 'Shelf');
          this.btlGalleryData = this.galleryphotodata.filter(photo => photo.type === 'BTL');
        }
      }
    }
  }

  titleCase(str) {
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  }

  camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g,
      (word, index) => index === 0 ? word.toLowerCase() : word.toUpperCase()).replace(/\s+/g, ' ');
  }

  changeActiveTab(event: MatTabChangeEvent) {
    const tab = event.tab.textLabel;
    this.activetab = tab;
    if (this.activetab === 'Sales') {
      this.getSalesData();
    }
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllColumns().length;

    params.api.setRowData(this.rowData);
    this.preloader = false;
    const inThis = this;

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        if (noOfColumns < 11 && screen.width > 991) {
          params.api.sizeColumnsToFit();
          if (inThis.pinnedParams.length > 0) {
            inThis.sharedService.resetPinned(inThis.gridOptions, inThis.pinnedParams);
          }
        } else {
          params.columnApi.autoSizeAllColumns();
          inThis.sharedService.clearPinned(inThis.gridOptions);
        }
      });
    });

    if (noOfColumns < 11 && screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
      if (inThis.pinnedParams.length > 0) {
        inThis.sharedService.resetPinned(inThis.gridOptions, inThis.pinnedParams);
      }
    } else {
      this.gridColumnApi.autoSizeAllColumns();
      inThis.sharedService.clearPinned(inThis.gridOptions);
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');

    // this.get_favorites();
  }

  onGridReady1(params) {
    this.gridOptions1.api.showLoadingOverlay();

    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
    const noOfColumns = this.gridColumnApi1.getAllColumns().length;

    params.api.setRowData(this.rowData1);
    this.preloader1 = false;
    const inThis = this;

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        if (noOfColumns < 11 && screen.width > 991) {
          params.api.sizeColumnsToFit();
          if (inThis.pinnedParams1.length > 0) {
            inThis.sharedService.resetPinned(inThis.gridOptions1, inThis.pinnedParams1);
          }
        } else {
          params.columnApi1.autoSizeAllColumns();
          inThis.sharedService.clearPinned(inThis.gridOptions1);
        }
      });
    });

    if (noOfColumns < 11 && screen.width > 991) {
      this.gridApi1.sizeColumnsToFit();
      if (inThis.pinnedParams1.length > 0) {
        inThis.sharedService.resetPinned(inThis.gridOptions1, inThis.pinnedParams1);
      }
    } else {
      this.gridColumnApi1.autoSizeAllColumns();
      inThis.sharedService.clearPinned(inThis.gridOptions1);
    }

    this.styleGrid1 = this.elRef.nativeElement.querySelector('#myGrid2');

    // this.get_favorites();
  }

  onGridReady2(params) {
    this.gridOptions2.api.showLoadingOverlay();

    this.gridApi2 = params.api;
    this.gridColumnApi2 = params.columnApi;
    const noOfColumns = this.gridColumnApi2.getAllColumns().length;

    params.api.setRowData(this.rowData2);
    this.preloader2 = false;
    const inThis = this;

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        if (noOfColumns < 11 && screen.width > 991) {
          params.api.sizeColumnsToFit();
          if (inThis.pinnedParams2.length > 0) {
            inThis.sharedService.resetPinned(inThis.gridOptions2, inThis.pinnedParams2);
          }
        } else {
          params.columnApi1.autoSizeAllColumns();
          inThis.sharedService.clearPinned(inThis.gridOptions2);
        }
      });
    });

    if (noOfColumns < 11 && screen.width > 991) {
      this.gridApi2.sizeColumnsToFit();
      if (inThis.pinnedParams2.length > 0) {
        inThis.sharedService.resetPinned(inThis.gridOptions2, inThis.pinnedParams2);
      }
    } else {
      this.gridColumnApi2.autoSizeAllColumns();
      inThis.sharedService.clearPinned(inThis.gridOptions2);
    }

    this.styleGrid2 = this.elRef.nativeElement.querySelector('#myGrid3');

    // this.get_favorites();
  }

  updateCustomer(userData) {
    this.apiService.post('/api/res_partners/updateCustomer', userData)
      .subscribe(res2 => {
          console.log(res2);
          if (res2.hasOwnProperty('results') && (res2.results.status === 200)) {
            this.toastr.success('Updated Successfully');
            this.getCustomerDetails();
          } else {
            this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
            this.toastr.error('Error updating  Customer');
          }
        },
        error => {
          console.log(error);
          this.toastr.error('Error updating Approval Request');
        });
  }


  editClick() {
    this.edit = !this.edit;
    this.gridApi.setRowData(this.rowData);
  }

  discardClick() {
    this.edit = !this.edit;
    this.gridApi.setRowData(this.rowData);
    this.getCustomerDetails();
  }

  verticalEditClick() {
    this.verticalEdit = !this.verticalEdit;
    if (this.verticalEdit) {
      this.gridApi.setRowData(this.rowData);
      this.gridColumnApi.getColumn('name').getColDef().editable = true;
    } else {
      this.gridColumnApi.getColumn('name').getColDef().editable = false;
      this.gridApi.stopEditing();
      const paramObject: any = {};
      paramObject.access_token = localStorage.getItem('resfreshToken');
      const objectData: any = {};
      for (const i in this.rowData) {
        objectData[this.rowData[i]['name']] = this.rowData[i]['value'];
      }
      paramObject.data = {
        partner_id: this.customerDetails.id,
        attribute_json: objectData
      };
      this.updateCustomer(paramObject);
    }
  }

  paramEditClick() {
    this.paramEdit = !this.paramEdit;
    if (this.paramEdit) {
      this.gridApi1.setRowData(this.rowData1);
    } else {
      this.gridApi1.stopEditing();
      const paramObject: any = {};
      paramObject.access_token = localStorage.getItem('resfreshToken');
      /* const objectData: any = {};
       for (const i in this.rowData1) {
         objectData[this.rowData1[i]['name']] = this.rowData1[i]['value'];
       }*/
      paramObject.data = {
        partner_id: this.customerDetails.id,
        param_json: JSON.stringify(this.rowData1)
      };
      this.updateCustomer(paramObject);
    }
  }

  addAttributeRow() {
    if (this.attributeList !== null && this.attributeList.length > 0) {
      const indrow = {
        name: this.attributeList[0],
        value: "1"
      };
      this.rowData.splice(0, 0, indrow);
      this.gridApi.setRowData(this.rowData);
    } else {
      this.toastr.error("No Attribute Master records added");
    }
  }

  addParamRow() {
    const indrow = {
      "n": "Name",
      "v": "Value",
      "c": "Category",
      "t": ""
    };
    this.rowData1.splice(0, 0, indrow);
    this.gridApi1.setRowData(this.rowData1);
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    const rowindex = event.rowIndex;
    if (column === 'delete') {
      this.rowData.splice(rowindex, 1);
      this.gridApi.setRowData(this.rowData);
    }
  }

  onCellClicked1(event) {
    const column = event.column.getColId();
    const rows = this.gridApi1.getSelectedRows();
    const rowindex = event.rowIndex;
    if (column === 'delete') {
      this.rowData1.splice(rowindex, 1);
      this.gridApi1.setRowData(this.rowData1);
    }
  }

  onCellClicked2(event) {
    const column = event.column.getColId();
    const rows = this.gridApi2.getSelectedRows();
    const rowindex = event.rowIndex;
  }

  oldValueGetter(params) {
    let isJson = true;
    if (params.data) {
      if (params.data.old_val !== null && params.data.old_val !== "") {
        try {
          JSON.parse(params.data.old_val);
        } catch (e) {
          isJson = false;
        }
      } else {
        isJson = false;
      }
      if (isJson) {
        const jsonValue = JSON.parse(params.data.old_val);
        if (params.data.request_type === 'location_with_address') {
          let address: any = '';
          if (jsonValue.hasOwnProperty('street1')) {
            address = address.concat(jsonValue.street1);
          }
          if (jsonValue.hasOwnProperty('street2')) {
            address = address.concat(jsonValue.street2);
          }
          /* if(jsonValue.hasOwnProperty("area"))
             address = address.concat(jsonValue.area) + ",";
           if(jsonValue.hasOwnProperty("zone"))
             address = address.concat(jsonValue.zone) + ",";
           if(jsonValue.hasOwnProperty("city"))
             address = address.concat(jsonValue.city) + ",";
           if(jsonValue.hasOwnProperty("state"))
             address = address.concat(jsonValue.state) + ",";
           if(jsonValue.hasOwnProperty("zip"))
             address = address.concat(jsonValue.zip) + ",";
           if(jsonValue.hasOwnProperty("country"))
             address = address.concat(jsonValue.country)*/

          return address;
        } else if (params.data.request_type === 'program_type') {
          if (jsonValue.hasOwnProperty('value')) {
            return jsonValue.value;
          } else {
            return params.data.old_val;
          }
        } else if (params.data.request_type === 'classification') {
          if (jsonValue.hasOwnProperty("outlet_type")) {
            if (jsonValue.outlet_type.hasOwnProperty("value")) {
              return jsonValue.outlet_type.value;
            } else {
              return "";
            }
          }
          if (jsonValue.hasOwnProperty("class_type")) {
            return jsonValue.class_type;
          } else {
            return "";
          }
        } else if (params.data.request_type === 'preorder') {
          if (jsonValue.hasOwnProperty("preorder")) {
            return jsonValue.preorder;
          } else {
            return "";
          }
        } else if (params.data.request_type === 'tax_details') {
          if (jsonValue.hasOwnProperty("gst")) {
            return jsonValue.gst;
          } else {
            return "";
          }
        } else if (jsonValue.hasOwnProperty("value")) {
          return jsonValue.value;
        } else {
          const str = JSON.stringify(jsonValue)
            .replace(/{/g, "")
            .replace(/"/g, "")
            .replace(/,/g, ", ")
            .replace(/:/g, ": ")
            .replace("\"", "")
            .replace("\",", "")
            .replace(/}/g, "");
          return str;
        }
      } else {
        return '';
      }
    }
  }

  formatValue(requestType, requestValue) {
    let isJson = true;
    if (requestValue !== null && requestValue !== "") {
      try {
        JSON.parse(requestValue);
      } catch (e) {
        isJson = false;
      }
    } else {
      isJson = false;
    }
    if (isJson) {
      if (this.isJsonString(requestValue)) {
        const jsonValue = JSON.parse(requestValue);
        if (requestType === 'location_with_address') {
          let address: any = '';
          if (jsonValue.hasOwnProperty('street1')) {
            address = address.concat(jsonValue.street1);
          }
          if (jsonValue.hasOwnProperty('street2')) {
            address = address.concat(jsonValue.street2);
          }
          if (jsonValue.hasOwnProperty('area')) {
            address = address.concat(jsonValue.area) + ',';
          }
          if (jsonValue.hasOwnProperty('zone')) {
            address = address.concat(jsonValue.zone) + ',';
          }
          if (jsonValue.hasOwnProperty('city')) {
            address = address.concat(jsonValue.city) + ',';
          }
          if (jsonValue.hasOwnProperty('state')) {
            address = address.concat(jsonValue.state) + ',';
          }
          if (jsonValue.hasOwnProperty('zip')) {
            address = address.concat(jsonValue.zip) + ',';
          }
          if (jsonValue.hasOwnProperty('country')) {
            address = address.concat(jsonValue.country);
          }

          return address;
        } else if (requestType === 'program_type') {
          if (jsonValue.hasOwnProperty('value')) {
            return jsonValue.value;
          } else {
            return '';
          }
        } else if (requestType === 'classification') {
          if (jsonValue.hasOwnProperty("outlet_type")) {
            if (jsonValue.outlet_type.hasOwnProperty("value")) {
              return jsonValue.outlet_type.value;
            } else {
              return "";
            }
          }
          if (jsonValue.hasOwnProperty("class_type")) {
            return jsonValue.class_type;
          } else {
            return "";
          }
        } else if (requestType === 'preorder') {
          if (jsonValue.hasOwnProperty("preorder")) {
            return jsonValue.preorder;
          } else {
            return "";
          }
        } else if (requestType === 'tax_details') {
          if (jsonValue.hasOwnProperty("gst")) {
            return jsonValue.gst;
          } else {
            return "";
          }
        } else if (jsonValue.hasOwnProperty("value")) {
          return jsonValue.value;
        } else {
          const str = JSON.stringify(jsonValue)
            .replace(/{/g, "")
            .replace(/"/g, "")
            .replace(/,/g, ", ")
            .replace(/:/g, ": ")
            .replace("\"", "")
            .replace("\",", "")
            .replace(/}/g, "");
          return str;
        }
      } else {
        return requestValue;
      }
    }
  }

  isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  newValueGetter(params) {
    let isJson = true;
    if (params.data) {
      if (params.data.new_val !== null && params.data.new_val !== "") {
        try {
          JSON.parse(params.data.new_val);
        } catch (e) {
          isJson = false;
        }
      } else {
        isJson = false;
      }
      if (isJson) {
        const jsonValue = JSON.parse(params.data.new_val);
        if (params.data.request_type === 'location_with_address') {
          let address: any = '';
          if (jsonValue.hasOwnProperty('street1')) {
            address = address.concat(jsonValue.street1);
          }
          if (jsonValue.hasOwnProperty('street2')) {
            address = address.concat(jsonValue.street2);
          }
          /*  if (jsonValue.hasOwnProperty("area"))
              address = address.concat(jsonValue.area) + ",";
            if (jsonValue.hasOwnProperty("zone"))
              address = address.concat(jsonValue.zone) + ",";
            if (jsonValue.hasOwnProperty("city"))
              address = address.concat(jsonValue.city) + ",";
            if (jsonValue.hasOwnProperty("state"))
              address = address.concat(jsonValue.state) + ",";
            if (jsonValue.hasOwnProperty("zip"))
              address = address.concat(jsonValue.zip) + ",";
            if (jsonValue.hasOwnProperty("country"))
              address = address.concat(jsonValue.country)*/

          return address;
        } else if (params.data.request_type === 'program_type') {
          if (jsonValue.hasOwnProperty('value')) {
            return jsonValue.value;
          } else {
            return params.data.old_val;
          }
        } else if (params.data.request_type === 'classification') {
          if (jsonValue.hasOwnProperty("outlet_type")) {
            if (jsonValue.outlet_type.hasOwnProperty("value")) {
              return jsonValue.outlet_type.value;
            } else {
              return "";
            }
          }
          if (jsonValue.hasOwnProperty("class_type")) {
            return jsonValue.class_type;
          } else {
            return "";
          }
        } else if (params.data.request_type === 'preorder') {
          if (jsonValue.hasOwnProperty("preorder")) {
            return jsonValue.preorder;
          } else {
            return "";
          }
        } else if (params.data.request_type === 'tax_details') {
          if (jsonValue.hasOwnProperty("gst")) {
            return jsonValue.gst;
          } else {
            return "";
          }
        } else if (jsonValue.hasOwnProperty("value")) {
          return jsonValue.value;
        } else {
          const str = JSON.stringify(jsonValue)
            .replace(/{/g, "")
            .replace(/"/g, "")
            .replace(/,/g, ", ")
            .replace(/:/g, ": ")
            .replace("\"", "")
            .replace("\",", "")
            .replace(/}/g, "");
          return str;
        }
      } else {
        return params.data.new_val;
      }
    }
  }

  fetchPreviousData() {
    //this.nextClick.emit('previous');
    if (this.rowIndex !== 0) {
      this.rowIndex = this.rowIndex - 1;
      this.userData.data.id = this.customerData[this.rowIndex];
      this.getCustomerDetails();
    }
  }

  fetchNextData() {
    //this.nextClick.emit('next');
    if (this.rowIndex !== this.customerData.length - 1) {
      this.rowIndex = this.rowIndex + 1;
      this.userData.data.id = this.customerData[this.rowIndex];
      this.getCustomerDetails();
    }
  }

  formatNumber(val) {
    if (val > 999999) {
      return val / 1000000 + 'M';
    } else if (val > 999) {
      return val / 1000 + 'K';
    } else {
      return val;
    }
    // return val + "%"
  }

  changeDate(dt) {
    this.userData.data.date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    this.getSalesData();
  }


}
