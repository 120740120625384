
export class KpiHelper {
  static getKpiProperties(kpiName): any {
    switch (kpiName) {
      case 'card_secondary_sales':
        return {tittle: 'Secondary', category: 'sales' };
      case 'outlet_coverage_with_order_count':
        return {tittle: 'Outlet Coverage', category: 'coverage',
          colorScheme : {
            domain: [
              '#16a085',
              '#7dcea0',
              '#abebc6',
              '#f7dc6f',
              '#e59866' ]
          } };
      case 'outlet_coverage_with_order_count':
        return {tittle: 'Outlet Coverage', category: 'productivity', xAxisLabel: 'Count', yAxisLabel: 'Count',
          colorScheme : {
            domain: [
              '#16a085',
              '#7dcea0',
              '#abebc6',
              '#f7dc6f',
              '#e59866' ]
          } };
      case 'sales_by_category':
        return {tittle: 'sales by category', category: 'sales', xAxisLabel: 'Count', yAxisLabel: 'Count',
          colorScheme : {
            domain: [
              '#5AA454', '#A10A28', '#C7B42C', '#AAAAAA' ]
          } };
      case 'sales_by_region':
        return {tittle: 'sales by region', category: 'sales',
          colorScheme : {
            domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
          } };
      default:
        return {};
    }
  }
}
