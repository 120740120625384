import {Component, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {Router} from '@angular/router';
import {ApiService} from "../../_services/api.service";
import {SharedService} from "../../_services/shared_service";
import {CustomConfigService} from "../../_services/CustomConfigService";
import {LocalforageService} from "../../_services/localforage.service";

@Component({
  selector: 'app-profilebar',
  templateUrl: './profilebar.component.html',
  styleUrls: ['./profilebar.component.scss']
})
export class ProfilebarComponent implements OnInit {
  @Input() title: string;

  url;

  public user_info;
  public user_image = "../../assets/menu/profile.svg";
  public module_dms = false;

  constructor(private elRef: ElementRef,
              private renderer: Renderer2,
              private router: Router,
              private apiService: ApiService,
              private sharedService: SharedService,
              private localforageService: LocalforageService) {
  }

  ngOnInit() {

    this.user_info = JSON.parse(localStorage.getItem('user'));
    if (this.user_info.image_small != null) {
      this.user_image = this.user_info.image_small;
    }
    this.module_dms = (localStorage.getItem('dms_module') === 'true');
  }

  printformat(event) {
    localStorage.setItem('print-format', event.target.value);
  }

  /* login() {
     this.sharedService.clearCache();
     this.router.navigate([this.apiService.redirect_url+""]);
   }*/

  logOut() {
    this.sharedService.clearCache();
    this.localforageService.DELETE_ALL();
    this.router.navigate([this.apiService.redirect_url + ""]);
    localStorage.setItem('print-format', 'A4');
  }

}
