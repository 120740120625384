import {ReportBase} from '../_common/reportbase';
import {Component, OnInit} from '@angular/core';
import {ErrorHandler} from '../../error/error-handler';
import {formatDate} from "@angular/common";


@Component({
  selector: 'app-product-performance-report',
  templateUrl: '../_common/reportbase.html',

})


// tslint:disable-next-line:component-class-suffix
export class ProductPerformanceReport extends ReportBase implements IReport, OnInit {
  /* showCustomFilter = true;

   customFilter:any={
     name:'Sales Type',
     key:'type',
     value:'S',
     values:[],
     show:true};*/

  public attributeTypes = [];
  apiData: any = [];

  setTitle() {
    throw new Error("Method not implemented.");
  }

  ngOnInit() {

    this.sharedService.setHeader('Product Performance Report');

    this.showDate = false;
    this.customFilter.values = [
      {id: '', itemName: 'All'},
      {id: 'true', itemName: 'Active'},
      {id: 'false', itemName: 'Inactive'}
    ];
    this.API_URL = '/api/pwa_reports/productPerformanceReport';
    this.showDate = true;
    const date = new Date();
    this.myDate[0] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd');
    this.configureGrid();
    this.loadServerData();
  }

  configureGrid() {
    this.columnDefs = [

      {headerName: "DISTRIBUTOR", field: "db_name", enableRowGroup: true, width: 100},
      {headerName: "DCODE", field: "db_code", width: 100},
      {headerName: "PRODUCT", field: "prdct_name", width: 100},
      {headerName: "PCODE", field: "prdct_code", width: 100},
      {headerName: "BRAND", field: "brand_name", enableRowGroup: true, width: 200},
      {headerName: "CATEGORY", field: "cat_name",enableRowGroup: true, width: 100},
      {headerName: "CREATED ON", field: "prd_crtdate", width: 100},
      {headerName: "STOCK IN HAND", field: "inhand_stock", width: 200},
      {headerName: "COST PRICE", field: "cost_price", width: 100},
      {headerName: "STOCK VALUE", field: "stock_value", width: 100},
      {headerName: "FIRST SALE", field: "first_sale", width: 100},
      {headerName: "LAST SALE", field: "last_sale", width: 100},
      {headerName: "SOLD QTY", field: "sold_qty", width: 100},
      {headerName: "SALES VALUE", field: "sales_value", width: 100},
      {headerName: "AVG SALES QTY", field: "avg_sales_qty", width: 100},
      {headerName: "AVG SALES", field: "avg_sales", width: 100},
      {headerName: "NO OF ORDERS", field: "order_count", width: 100},
      {headerName: "RETURN ORDER", field: "return_value", width: 100},
      {headerName: "NO OF RETURN ORDERS", field: "rtn_order_cnt", width: 100},
      {headerName: "RETURN %", field: "ret_pct", width: 100},
      {headerName: "NO OF ORDERS WITH SCHEME", field: "schm_ordr_cnt", width: 100},
      {headerName: "NO OF ORDERS WITHOUT SCHEME", field: "noschm_ordr_cnt", width: 100},
      {headerName: "SCHEME VALUE", field: "scheme_value", width: 100}];
  }

}
