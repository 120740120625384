import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {GridsterItem} from 'angular-gridster2';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-widget-multidonut',
  template: `
    <div class="info-box">
      <div class="row">
        <span class="heading" style="display: inline">{{widget.data.label}}</span>
        <div class="formselect" style="margin-left: auto;margin-right: 0;">
          <select
            style="width: 120px;"
            (mousedown)="$event.stopPropagation()" (touchstart)="$event.stopPropagation()"
            name="stype"
            [(ngModel)]="selectedType"
            #stype="ngModel"
            (change)="onTypeChange()">
            <option
              *ngFor="let item of typeList"
              value="{{ item }}"
            > {{ item }}</option>
          </select>
        </div>
      </div>
      <div style="height: 78%; width: 100%;margin-top: 10px;">
        <ngx-charts-pie-chart
          [scheme]="colorScheme"
          [results]="donutData"
          [gradient]=true
          [legend]=true
          [legendPosition]="'below'"
          [legendTitle]="''"
          [labels]=true
          [doughnut]=true
        >
        </ngx-charts-pie-chart>
      </div>
    </div>`,
  styleUrls: ['./../dashboard.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class MultiDonutWidgetComponent implements OnInit, OnDestroy {
  @Input()
  widget;
  @Input()
  resizeEvent: EventEmitter<GridsterItem>;
  selectedType;
  resizeSub: Subscription;
  colorScheme: any = {
    domain: []
  };
  typeList = [];
  donutData;
  view: any[] = [550, 315];

  ngOnInit(): void {
    this.resizeSub = this.resizeEvent.subscribe(widget => {
      if (widget === this.widget) {
        // or check id , type or whatever you have there
        // resize your widget, chart, map , etc.
        console.log(widget);
      }
    });
    this.view = [this.widget.data.width - 50, this.widget.data.height - 50];

    if (this.widget.data.value) {
      for (const key of Object.keys(this.widget.data.value)) {
        this.typeList.push(key);
      }
      this.selectedType = this.typeList[0];
      this.donutData = this.widget.data.value[this.typeList[0]];
      this.setCustomColor();
    }
  }

  ngOnDestroy(): void {
    this.resizeSub.unsubscribe();
  }

  onTypeChange() {
    this.donutData = [];
    this.donutData = this.widget.data.value[this.selectedType];
    this.setCustomColor();
  }

  setCustomColor() {
    this.colorScheme.domain = [];
    if (this.donutData && this.donutData.length > 0) {
      this.donutData.forEach(data => {
        this.colorScheme.domain.push(data['indicator_colour']);
      });
    }
  }
}
