import {Component, OnInit} from "@angular/core";
import {ReportBase} from "../_common/reportbase";
import {MatDialogConfig} from "@angular/material/dialog";
import {OutletVisitPopupComponent} from "../../components/outlet_visit_popup/outlet_visit_popup.component";

@Component({
  selector: 'app-tl-cluster-level-rollup-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class TLCLusterLevelRollupReportComponent extends ReportBase implements IReport, OnInit {

  apiData: any = [];

  setTitle() {
    throw new Error("Method not implemented.");
  }

  ngOnInit() {

    this.sharedService.setHeader('Callage/Coverage Report');
    this.API_URL = '/api/pwa_reports/get_diageo_tlcluster_report';
  }

  configureGrid() {
    // this.filterConfig.salesman = true;
    // this.filterConfig.outletType = true;

    this.gridOptions.autoGroupColumnDef = {
      headerName: 'SALESMAN',
      field: 'salesman',
      minWidth: 250,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        checkbox: true,
      }
    };


    this.columnDefs = [

      {field: "region", headerName: "REGION", width: 80},
      {field: "cluster_name", headerName: "CLUSTER"},
      {field: "team", headerName: 'TEAM', hide: true},
      {field: "team_lead", headerName: 'TEAM LEAD'},
      {field: "tl_email", headerName: 'TEAM LEAD EMAIL'},
      {field: "salesman", headerName: 'SALESMAN'},
      {field: "semail", headerName: 'SALESMAN EMAIL'},
      {
        headerName: "UNIVERSE",
        children: [
          {headerName: "SCODE", field: "scode", menuTabs: [], width: 80, cellStyle: {textAlign: 'center'}},
          {headerName: "BEAT", field: "beat_count", aggFunc: 'sum', width: 80, cellStyle: {textAlign: 'center'}},
          {headerName: "UNIVERSE", field: "retail_tot", aggFunc: 'sum', menuTabs: [], width: 80, cellStyle: {textAlign: 'center'}},
          {headerName: "OLD-COVERED", field: "retail_cov", aggFunc: 'sum', menuTabs: [], width: 80, cellStyle: {textAlign: 'center'}, hide: true},
          {headerName: "COVERED", field: "Covered", aggFunc: 'sum', menuTabs: [], width: 80, cellStyle: {textAlign: 'center'}},
          {
            headerName: "UNCOVERED", field: "retail_ucv", aggFunc: 'sum', menuTabs: [], width: 100, cellStyle: {textAlign: 'center'},
            valueGetter: params => {
              if (params.data) {
                return params.data.retail_tot - params.data.retail_cov;
              }
            }
          },
        ]
      },
      {
        headerName: "CHANNEL",
        children: [
          {headerName: "OFF", field: "off", menuTabs: [], width: 80, cellStyle: {textAlign: 'center'}, aggFunc: 'sum', valueGetter: this.nullValueChecker},
          {headerName: "POP", field: "pop", menuTabs: [], width: 80, cellStyle: {textAlign: 'center'}, aggFunc: 'sum', valueGetter: this.nullValueChecker},
          {headerName: "PROP", field: "prop", menuTabs: [], width: 80, cellStyle: {textAlign: 'center'}, aggFunc: 'sum', valueGetter: this.nullValueChecker},
          {headerName: "NON OUTLET", field: "non_outlet", menuTabs: [], width: 80, cellStyle: {textAlign: 'center'}, aggFunc: 'sum', valueGetter: this.nullValueChecker},
        ]
      },
      {
        headerName: "CLASSIFICATION",

        children: [
          {headerName: "PLATINUM", field: "class_platinum", menuTabs: [], width: 60, cellStyle: {textAlign: 'center'}, aggFunc: 'sum', valueGetter: this.nullValueChecker},
          {headerName: "GOLD", field: "class_gold", menuTabs: [], width: 60, cellStyle: {textAlign: 'center'}, aggFunc: 'sum', valueGetter: this.nullValueChecker},
          {headerName: "SILVER", field: "class_silver", menuTabs: [], width: 60, cellStyle: {textAlign: 'center'}, aggFunc: 'sum', valueGetter: this.nullValueChecker},
          {headerName: "BRONZE", field: "class_bronze", menuTabs: [], width: 60, cellStyle: {textAlign: 'center'}, aggFunc: 'sum', valueGetter: this.nullValueChecker},
          {headerName: "OTHERS", field: "class_others", menuTabs: [], width: 60, cellStyle: {textAlign: 'center'}, aggFunc: 'sum', valueGetter: this.nullValueChecker},

        ]
      },
      {
        headerName: "CLASSIFICATION VISITS",

        children: [
          {headerName: "PLATINUM", field: "visit_platinum", menuTabs: [], width: 60, cellStyle: {textAlign: 'center'}, aggFunc: 'sum', valueGetter: this.nullValueChecker},
          {headerName: "GOLD", field: "visit_gold", menuTabs: [], width: 60, cellStyle: {textAlign: 'center'}, aggFunc: 'sum', valueGetter: this.nullValueChecker},
          {headerName: "SILVER", field: "visit_silver", menuTabs: [], width: 60, cellStyle: {textAlign: 'center'}, aggFunc: 'sum', valueGetter: this.nullValueChecker},
          {headerName: "BRONZE", field: "visit_bronze", menuTabs: [], width: 60, cellStyle: {textAlign: 'center'}, aggFunc: 'sum', valueGetter: this.nullValueChecker},
          {headerName: "OTHERS", field: "visit_others", menuTabs: [], width: 60, cellStyle: {textAlign: 'center'}, aggFunc: 'sum', valueGetter: this.nullValueChecker},

        ]
      },
      {
        headerName: "MARKET WORKING",

        children: [
          {
            headerName: "WORKING DAYS", field: "work_days", menuTabs: [], width: 80, cellStyle: {textAlign: 'center'}, aggFunc: this.avgAggFunction
            , valueGetter: params => {
              if (params.data) {
                if (params.data.work_days) {
                  return parseInt(params.data.work_days, 10);
                } else {
                  return 0;
                }
              }
            }
          },
          {
            headerName: "CALLAGE", field: "callage", menuTabs: [], width: 80, cellStyle: {textAlign: 'center'}, aggFunc: this.nonroundedavgAggFunction,
            valueGetter: params => {
              if (params.data) {
                let total_calls = 0;
                if (params.data.phy_calls > 0) {
                  total_calls = total_calls + parseInt(params.data.phy_calls, 10);
                }
                if (params.data.verified_calls > 0) {
                  total_calls = total_calls + parseInt(params.data.verified_calls, 10);
                }
                if (params.data.unverified_calls > 0) {
                  total_calls = total_calls + parseInt(params.data.unverified_calls, 10);
                }
                if (params.data.work_days > 0) {
                  const callage = (total_calls / params.data.work_days);
                  return Math.round((callage + Number.EPSILON) * 100) / 100;
                  // return (total_calls / params.data.work_days).toFixed(2);
                } else {
                  return 0;
                }
              }
            }
          },
          {
            headerName: "OLD-COVERAGE%", menuTabs: [], width: 90, cellStyle: {textAlign: 'center'}, hide: true, aggFunc: this.avgAggFunction,
            valueGetter: params => {
              if (params.data) {
                if (params.data.unique_visits > 0 && params.data.retail_cov > 0) {
                  return Math.round(params.data.unique_visits * 100 / params.data.retail_cov);
                } else {
                  return 0;
                }
              }
            }
          },
          {
            headerName: "COVERAGE%", menuTabs: [], width: 90, cellStyle: {textAlign: 'center'}, aggFunc: this.avgAggFunction,
            valueGetter: params => {
              if (params.data) {
                if (params.data['Covered'] !== undefined && params.data['UQ PHY Visits'] !== undefined && params.data['Covered'] > 0) {
                  return ((parseInt(params.data['UQ PHY Visits'], 10) * 100) / parseInt(params.data['Covered'], 10)).toFixed(0);
                } else {
                  return 0;
                }
              }
            }
          },
          {
            headerName: "NOT VISITED", field: 'not_visit', menuTabs: [], width: 80, cellStyle: {textAlign: 'center'}, aggFunc: 'sum',
            valueGetter: params => {
              if (params.data) {
                if (params.data['Covered'] !== undefined && params.data['Covered'] !== undefined) {
                  return (parseInt(params.data['Covered'], 10) - parseInt(params.data['UQ PHY Visits'], 10) );
                } else {
                  return 0;
                }
              }
            }
          },

        ]
      },
      {
        headerName: "VISITS",

        children: [
          {
            headerName: "TOTAL", field: "total_call", menuTabs: [], width: 60, cellStyle: {textAlign: 'center'}, aggFunc: 'sum',
            valueGetter: params => {
              if (params.data) {
                let total_calls = 0;
                if (params.data.phy_calls > 0) {
                  total_calls = total_calls + parseInt(params.data.phy_calls, 10);
                }
                if (params.data.verified_calls > 0) {
                  total_calls = total_calls + parseInt(params.data.verified_calls, 10);
                }
                if (params.data.unverified_calls > 0) {
                  total_calls = total_calls + parseInt(params.data.unverified_calls, 10);
                }
                return total_calls;
              }
            }
          },
          {
            headerName: "PHYSICAL",

            field: "phy_calls",


            menuTabs: [],
            width: 60,

            cellStyle: {textAlign: 'center'},
            aggFunc: 'sum'
            ,
            valueGetter: params => {
              if (params.data) {
                if (params.data.phy_calls) {
                  return parseInt(params.data.phy_calls, 10);
                } else {
                  return 0;
                }
              }
            },
          },
          {
            headerName: "VERIFIED VC", field: "verified_calls", menuTabs: [], width: 60, cellStyle: {textAlign: 'center'}, aggFunc: 'sum',
            valueGetter: params => {
              if (params.data) {
                if (params.data.verified_calls) {
                  return parseInt(params.data.verified_calls, 10);
                } else {
                  return 0;
                }
              }
            },
          },
          {
            headerName: "UNVERIFIED VC", field: "unverified_calls", menuTabs: [], width: 60, cellStyle: {textAlign: 'center'}, aggFunc: 'sum',
            valueGetter: params => {
              if (params.data) {
                if (params.data.unverified_calls) {
                  return parseInt(params.data.unverified_calls, 10);
                } else {
                  return 0;
                }
              }
            },
          },
          {
            headerName: "IN COMPLETE", field: "in_complete_calls", menuTabs: [], width: 60, cellStyle: {textAlign: 'center'}, aggFunc: 'sum',
            valueGetter: params => {
              if (params.data) {
                if (params.data.in_complete_calls) {
                  return parseInt(params.data.in_complete_calls, 10);
                } else {
                  return 0;
                }
              }
            },
          },
          {
            headerName: "OLD UNIQUE", field: "unique_visits", hide: true, menuTabs: [], width: 60, cellStyle: {textAlign: 'center'}, aggFunc: 'sum',
            valueGetter: params => {
              if (params.data) {
                if (params.data.unique_visits) {
                  return parseInt(params.data.unique_visits, 10);
                } else {
                  return 0;
                }
              }
            },
          },
          /*{
            headerName: "UNIQUE", field: "Unique Visits", menuTabs: [], width: 60, cellStyle: {textAlign: 'center'}, aggFunc: 'sum',
            valueGetter: params => {
              if (params.data) {
                if (params.data['Unique Visits']) {
                  return parseInt(params.data['Unique Visits']);
                } else {
                  return 0;
                }
              }
            },
          },*/
          {headerName: "UQ PHY", field: "unique_phy", menuTabs: [], width: 60, cellStyle: {textAlign: 'center'}, aggFunc: 'sum', hide: true, valueGetter: this.nullValueChecker},
          {headerName: "UQ VERIFIED", field: "unique_ver", menuTabs: [], width: 60, cellStyle: {textAlign: 'center'}, aggFunc: 'sum', valueGetter: this.nullValueChecker},
          {headerName: "UQ UNVERIFIED", field: "unverified", menuTabs: [], width: 60, cellStyle: {textAlign: 'center'}, aggFunc: 'sum', valueGetter: this.nullValueChecker},
        ]
      },
      {
        headerName: "TRAX (%)",
        children: [
          {
            headerName: "TRAX ADHERENCE", field: "trax_adherence", menuTabs: [], width: 100, hide: true, aggFunc: this.avgAggFunction,
            valueGetter: params => {
              if (params.data) {
                if (params.data.trax_cov && params.data.uq_trax_visits) {
                  let ta = 0;
                  ta = ((params.data.uq_trax_visits) / (params.data.trax_cov)) * 100;
                  return Math.round(ta);
                } else {
                  return 0;
                }
              }
            },
            cellStyle: params => {
              if (params.data) {
                if (params.value === 100) {
                  return {textAlign: 'center', backgroundColor: '#B3DFB0'};
                } else if (params.value > 70) {
                  return {textAlign: 'center', backgroundColor: '#F0E199'};
                } else {
                  return {textAlign: 'center', backgroundColor: '#F8D1C8'};
                }
              }
            }
          },
          {headerName: "UQ TRAX VISIT", field: "uq_trax_visits", menuTabs: [], width: 100, cellStyle: {textAlign: 'center'}, hide: true, aggFunc: this.avgAggFunction, valueGetter: this.nullValueChecker},
          {headerName: "TRAX OUTLETS", field: "trax_cov", menuTabs: [], width: 100, cellStyle: {textAlign: 'center'}, hide: true},
          {headerName: "TRAX MSL", field: "trax_p5", menuTabs: [], width: 100, cellStyle: {textAlign: 'center'}, hide: true, aggFunc: this.avgAggFunction, valueGetter: this.nullValueChecker},
          {headerName: "COMPLIANCE", field: "trax_msl_compliant", menuTabs: [], width: 100, cellStyle: {textAlign: 'center'}, hide: true, aggFunc: this.avgAggFunction, valueGetter: this.nullValueChecker},
          {headerName: "DISTRIBUTION", field: "trax_score", menuTabs: [], width: 100, cellStyle: {textAlign: 'center'}, hide: true, aggFunc: this.avgAggFunction, valueGetter: this.nullValueChecker},
          {headerName: "SOS", field: "trax_sos", menuTabs: [], width: 100, cellStyle: {textAlign: 'center'}, hide: true, aggFunc: this.avgAggFunction, valueGetter: this.nullValueChecker},
        ]
      },
      {
        headerName: "AVAILABILITY (%)",
        children: [
          {headerName: "DISTRIBUTION", field: "audit_score", menuTabs: [], width: 100, cellStyle: {textAlign: 'center'}, hide: true, aggFunc: this.avgAggFunction, valueGetter: this.nullValueChecker},
          {headerName: "MSL COMPLIANCE", field: "audit_msl_compliant", menuTabs: [], width: 100, hide: true, cellStyle: {textAlign: 'center'}, aggFunc: this.avgAggFunction, valueGetter: this.nullValueChecker},
          {headerName: "MSL", field: "audit_p5", menuTabs: [], width: 60, cellStyle: {textAlign: 'center'}, hide: true, aggFunc: this.avgAggFunction, valueGetter: this.nullValueChecker},
          {headerName: "FOCUS", field: "audit_p4", menuTabs: [], width: 60, cellStyle: {textAlign: 'center'}, hide: true, aggFunc: this.avgAggFunction, valueGetter: this.nullValueChecker},
          {headerName: "SURVEY", field: "audit_p3", menuTabs: [], width: 60, cellStyle: {textAlign: 'center'}, hide: true, aggFunc: this.avgAggFunction, valueGetter: this.nullValueChecker},

        ]
      },
      {
        headerName: "KPI",
        children: []
      }
    ];

    const uniqueKpis = this.apiData.kpi.map(p => p.kpi)
      .filter((type, index, arr) => arr.indexOf(type) === index);

    // tslint:disable-next-line:forin
    for (const key in uniqueKpis) {
      this.columnDefs[15].children.push(
        {headerName: uniqueKpis[key], field: uniqueKpis[key], menuTabs: [], width: 60, cellStyle: {textAlign: 'center'}, aggFunc: 'sum'},
      );
    }
  }

  /* loadServerData() {
     this.goClicked = true;
     this.dataLoaded = false;
     this.preloader = true;
     this.emptyState = false;
     this.errorExists = false;

     if (this.API_URL === '') {
       return this.handleError(ErrorHandler.getErrorObject('API Not Configured'));
     }

     /!*this.userData.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
     this.userData.end_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');*!/

     this.apiService.post(this.API_URL, this.userData)
       .subscribe(res => {
           console.log(res);
           if (res.hasOwnProperty('results') && (res.results.status === 200)) {
             // this.rowData = res.results.data;
             this.apiData = res.results;
             this.configureData();
             this.dataLoaded = true;
             this.preloader = false;
             this.emptyState = (this.rowData.length === 0);
           } else if (res.results.status === 203) {
             this.handleError(ErrorHandler.getErrorObject(res.results.status));
           } else {
             this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
           }
         },
         // tslint:disable-next-line:no-shadowed-variable
         error => this.handleError(ErrorHandler.getErrorObject(error)));
   }*/

  /* loadServerData() {
     var indrow = {
       "cluster_head":"Mohan Das",
       "team_lead":"Chandan Kumar",
       "salesman":"Bhavesh Chirantan",
       "beat":"6Bangalore South 01",
       "universe":"120",
       "cover":"96",
       "unicover":"24",
       "off":"76",
       "pop":"2",
       "prop":"18",
       "non_outlet":"2",
       "platinum":"2",
       "gold":"6",
       "silver":"14",
       "bronze":"19",
       "others":"43",
       "callage":"7.2",
       "coverage":"9.7%",
       "working_days":"20",
       "total":"175",
       "physcial":"71%",
       "virtual":"15%",
       "virtual_verified":"14%",
       "visit_adherence":"75%",
       "beat_adherence":"70%",
       "trax_adherence":"72%",
       "msl_score":"38%",
       "msl_compliance":"64%",
       "distribution_score":"59%",
       "msl":"45%",
       "focus":"61%",
       "survey":"83%",
       "distribution":"75%"
     }

     this.rowData.push(indrow);
     this.preloader = false;
     this.emptyState = false;

     this.dataLoaded = true;
   }*/

  postProcessData() {
    this.configureGrid();
    this.configureData();
  }

  configureData() {

    this.rowData = [];

    for (var key in this.apiData.master) {
      let indrow = {};
      let masterrow: any = {};
      let auditrow: any = {};
      let outletrow: any = [];
      let classrow: any = [];
      let visitsrow: any = [];
      let kpisrow: any = [];
      let classificationrow: any = {};
      let classvisitrow: any = {};
      let adherencerow: any = {};
      let channelrow: any = {};
      let visitrow: any = {};
      let kpirow: any = {};


      masterrow = this.apiData.master[key];

      visitsrow = this.apiData.market_visit.filter(visits => visits.salesman_id === masterrow.salesman_id);

      if (visitsrow != null && visitsrow.length > 0) {
        visitrow = visitsrow[0];
        const unique_phy = visitrow.outlet_visit.map(p => p['3'])
          .filter((type, index, arr) => arr.indexOf(type) === index && typeof type === 'number');

        let unique_ver = visitrow.outlet_visit.map(p => p['2'])
          .filter((type, index, arr) => arr.indexOf(type) === index && typeof type === 'number');

        unique_ver = unique_ver.filter(x => !unique_phy.includes(x));

        let unverified = visitrow.outlet_visit.map(p => p['1'])
          .filter((type, index, arr) => arr.indexOf(type) === index && typeof type === 'number');

        unverified = unverified.filter(x => !unique_phy.includes(x));
        unverified = unverified.filter(x => !unique_ver.includes(x));

        visitrow.unique_phy = unique_phy.length;
        visitrow.unique_ver = unique_ver.length;
        visitrow.unverified = unverified.length;
      }
      auditrow = this.apiData.audit.filter(audit => audit.salesman_id === masterrow.salesman_id);

      outletrow = this.apiData.outlet_type.filter(outlet_type => outlet_type.salesman_id === masterrow.salesman_id);

      channelrow.pop = outletrow.filter(pop => pop.outlet_type === "POP").map(obj => obj.count)[0];

      if (channelrow.pop != null) {
        channelrow.pop = parseInt(channelrow.pop);
      }

      channelrow.prop = outletrow.filter(prop => prop.outlet_type === "PROP").map(obj => obj.count)[0];

      if (channelrow.prop != null) {
        channelrow.prop = parseInt(channelrow.prop);
      }

      channelrow.off = outletrow.filter(off => off.outlet_type === "Off").map(obj => obj.count)[0];

      if (channelrow.off != null) {
        channelrow.off = parseInt(channelrow.off);
      }

      channelrow.non_outlet = outletrow.filter(function(non_outlet) {
        return non_outlet.outlet_type == "Non Outlet";
      }).map(function(obj) {
        return obj.count;
      })[0];

      if (channelrow.non_outlet != null) {
        channelrow.non_outlet = parseInt(channelrow.non_outlet);
      }

      if (auditrow != null && auditrow.length > 0) {
        auditrow = auditrow[0];
      }

      classrow = this.apiData.class.filter(classification => classification.salesman_id === masterrow.salesman_id);

      classificationrow.class_platinum = classrow.filter(platinum => platinum.class === "Platinum").map(obj => obj.ret_count)[0];
      classificationrow.class_platinum_nv = classrow.filter(gold => gold.class === "Platinum").map(obj => obj.not_visit)[0];
      if (classificationrow.class_platinum_nv === null || classificationrow.class_platinum_nv === undefined) {
        classificationrow.class_platinum_nv = 0;
      }

      classificationrow.class_gold = classrow.filter(gold => gold.class === "Gold").map(obj => obj.ret_count)[0];
      classificationrow.class_gold_nv = classrow.filter(gold => gold.class === "Gold").map(obj => obj.not_visit)[0];
      if (classificationrow.class_gold_nv === null || classificationrow.class_gold_nv === undefined) {
        classificationrow.class_gold_nv = 0;
      }

      classificationrow.class_silver = classrow.filter(silver => silver.class === "Silver").map(obj => obj.ret_count)[0];
      classificationrow.class_silver_nv = classrow.filter(silver => silver.class === "Silver").map(obj => obj.not_visit)[0];
      if (classificationrow.class_silver_nv === null || classificationrow.class_silver_nv === undefined) {
        classificationrow.class_silver_nv = 0;
      }

      classificationrow.class_bronze = classrow.filter(bronze => bronze.class === "Bronze").map(obj => obj.ret_count)[0];
      classificationrow.class_bronze_nv = classrow.filter(bronze => bronze.class === "Bronze").map(obj => obj.not_visit)[0];
      if (classificationrow.class_bronze_nv === null || classificationrow.class_bronze_nv === undefined) {
        classificationrow.class_bronze_nv = 0;
      }

      classificationrow.class_others = classrow.filter(others => others.class === "Others").map(obj => obj.ret_count)[0];
      classificationrow.class_others_nv = classrow.filter(others => others.class === "Others").map(obj => obj.not_visit)[0];
      if (classificationrow.class_others_nv === null || classificationrow.class_others_nv === undefined) {
        classificationrow.class_others_nv = 0;
      }

      classificationrow.class_NA = classrow.filter(NA => NA.class === "" || NA.class === "Undefined").map(obj => obj.ret_count)[0];
      classificationrow.class_NA_nv = classrow.filter(others => others.class === "Undefined").map(obj => obj.not_visit)[0];
      if (classificationrow.class_NA_nv === null || classificationrow.class_NA_nv === undefined) {
        classificationrow.class_NA_nv = 0;
      }
      classificationrow.not_visit = 0;
      classificationrow.not_visit = classificationrow.not_visit + classificationrow.class_platinum_nv + classificationrow.class_gold_nv
        + classificationrow.class_silver_nv + classificationrow.class_bronze_nv + classificationrow.class_others_nv;

      // adherencerow.adh_platinum = classrow.filter(platinum => platinum.class === "Platinum").map(obj => obj.adherence)[0];
      // adherencerow.ret_platinum = classrow.filter(platinum => platinum.class === "Platinum").map(obj => obj.ret_count)[0];
      // if (adherencerow.ret_platinum === null || adherencerow.ret_platinum === undefined) {
      //   adherencerow.ret_platinum = 0;
      // }
      //
      // adherencerow.adh_gold = classrow.filter(gold => gold.class === "Gold").map(obj => obj.adherence)[0];
      // adherencerow.ret_gold = classrow.filter(gold => gold.class === "Gold").map(obj => obj.ret_count)[0];
      // if (adherencerow.ret_gold === null || adherencerow.ret_gold === undefined) {
      //   adherencerow.ret_gold = 0;
      // }
      //
      // adherencerow.adh_silver = classrow.filter(silver => silver.class === "Silver").map(obj => obj.adherence)[0];
      // adherencerow.ret_silver = classrow.filter(silver => silver.class === "Silver").map(obj => obj.ret_count)[0];
      // if (adherencerow.ret_silver === null || adherencerow.ret_silver === undefined) {
      //   adherencerow.ret_silver = 0;
      // }
      //
      // adherencerow.adh_bronze = classrow.filter(bronze => bronze.class === "Bronze").map(obj => obj.adherence)[0];
      // adherencerow.ret_bronze = classrow.filter(bronze => bronze.class === "Bronze").map(obj => obj.ret_count)[0];
      // if (adherencerow.ret_bronze === null || adherencerow.ret_bronze === undefined) {
      //   adherencerow.ret_bronze = 0;
      // }
      //
      // adherencerow.adh_others = classrow.filter(others => others.class === "Others").map(obj => obj.adherence)[0];
      // adherencerow.ret_others = classrow.filter(others => others.class === "Others").map(obj => obj.ret_count)[0];
      // if (adherencerow.ret_others === null || adherencerow.ret_others === undefined) {
      //   adherencerow.ret_others = 0;
      // }
      //
      // adherencerow.total_ret_count = adherencerow.ret_platinum + adherencerow.ret_gold + adherencerow.ret_silver
      //   + adherencerow.ret_bronze + adherencerow.ret_others;

      classvisitrow.visit_platinum = classrow.filter(platinum => platinum.class === "Platinum").map(obj => obj.visits)[0];

      classvisitrow.visit_gold = classrow.filter(gold => gold.class === "Gold").map(obj => obj.visits)[0];

      classvisitrow.visit_silver = classrow.filter(silver => silver.class === "Silver").map(obj => obj.visits)[0];

      classvisitrow.visit_bronze = classrow.filter(bronze => bronze.class === "Bronze").map(obj => obj.visits)[0];

      classvisitrow.visit_others = classrow.filter(others => others.class === "Others").map(obj => obj.visits)[0];

      classvisitrow.visit_NA = classrow.filter(NA => NA.class === "" || NA.class === "Undefined").map(obj => obj.visits)[0];

      // indrow =  Object.assign({}, masterrow, auditrow);

      kpisrow = this.apiData.kpi.filter(visits => visits.user_id === masterrow.salesman_id);
      if (kpisrow != null && kpisrow.length > 0) {
        // tslint:disable-next-line:forin
        for (const i in kpisrow) {
          kpirow[kpisrow[i].kpi] = kpisrow[i].value;
        }
      }

      indrow = {
        ...masterrow,
        ...visitrow,
        ...auditrow,
        ...channelrow,
        ...classificationrow,
        ...classvisitrow,
        ...adherencerow,
        ...kpirow
      };
      this.rowData.push(indrow);
    }

    console.log(this.rowData);
  }

  nullValueChecker(params) {
    const field = params.column.getColDef().field;
    if (params.data) {
      if (params.data[field] !== null && params.data[field] !== undefined) {
        return params.data[field];
      } else {
        return 0;
      }
    }
  }

  adherenceStyle(params) {
    const field = params.column.getColDef().field;
    if (params.data) {
      if (params.data[field] !== null && params.data[field] !== undefined) {
        if (params.data[field] === 100) {
          return {textAlign: 'center', backgroundColor: '#B3DFB0'};
        } else if (params.data[field] > 69) {
          return {textAlign: 'center', backgroundColor: '#F0E199'};
        } else if (params.data[field] >= 0) {
          return {textAlign: 'center', backgroundColor: '#F8D1C8'};
        } else {
          return {textAlign: 'center'};
        }
      } else {
        return {textAlign: 'center'};
      }
    }
  }

  avgAggFunction(params) {
    let avg;
// the average will be the sum / count
    let sum = 0;
    let count = 0;

    params.values.forEach(value => {
      const groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        // we are aggregating groups, so we take the
        // aggregated values to calculated a weighted average
        sum += value.avg * value.count;
        count += value.count;
      } else {
        // skip values that are not numbers (ie skip empty values)
        if (typeof value === 'number') {
          sum += value;
          count++;
        }
      }
    });
    // avoid divide by zero error
    if (count !== 0) {
      avg = sum / count;
    } else {
      avg = null;
    }

    avg = Math.round(Math.round((avg + Number.EPSILON) * 100) / 100);

    // the result will be an object. when this cell is rendered, only the avg is shown.
    // however when this cell is part of another aggregation, the count is also needed
    // to create a weighted average for the next level.
    const result = {
      count: count,
      avg: avg,
      // the grid by default uses toString to render values for an object, so this
      // is a trick to get the default cellRenderer to display the avg value
      toString: function() {
        return this.avg;
      },
    };

    return result;
  }

  nonroundedavgAggFunction(params) {
    // the average will be the sum / count
    var sum = 0;
    var count = 0;

    params.values.forEach(function(value) {
      var groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        // we are aggregating groups, so we take the
        // aggregated values to calculated a weighted average
        sum += value.avg * value.count;
        count += value.count;
      } else {
        // skip values that are not numbers (ie skip empty values)
        if (typeof value === 'number') {
          sum += value;
          count++;
        }
      }
    });
    // avoid divide by zero error
    if (count !== 0) {
      var avg = sum / count;
    } else {
      avg = null;
    }

    avg = Math.round((avg + Number.EPSILON) * 100) / 100;

    // the result will be an object. when this cell is rendered, only the avg is shown.
    // however when this cell is part of another aggregation, the count is also needed
    // to create a weighted average for the next level.
    var result = {
      count: count,
      avg: avg,
      // the grid by default uses toString to render values for an object, so this
      // is a trick to get the default cellRenderer to display the avg value
      toString: function() {
        return this.avg;
      },
    };

    return result;
  }

  onCellClicked(event) {

    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    const headername = event.column.originalParent.colGroupDef.headerName;
    /* if (headername !== null && headername !== undefined) {
       if (headername.toLowerCase().includes('visit')) {
         const config: MatDialogConfig = {
           width: "100vw",
           height: "100vh",
           maxWidth: "1000px",
           maxHeight: "620px",
           data: {
             userData: this.userData,
             salesman_id: rows[0].salesman_id
           }
         };

         const creatediaeref = this.dialog.open(OutletVisitPopupComponent, config);
       }

     }*/
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "1000px",
      maxHeight: "620px",
      data: {
        userData: this.userData,
        salesman_id: rows[0].salesman_id
      }
    };

    const creatediaeref = this.dialog.open(OutletVisitPopupComponent, config);
  }

}
