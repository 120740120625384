import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {GridOptions} from "../../../node_modules/@ag-grid-enterprise/all-modules";
import {ApiService} from "../_services/api.service";
import {Router} from '@angular/router';
import {ColorCellRenderer} from "../colorCell.component";
import {DatePipe} from '@angular/common';
import {DateTimeAdapter} from 'ng-pick-datetime';
import {timeout} from "rxjs/operators/timeout";
import {SharedService} from "../_services/shared_service";

@Component({
  selector: 'app-eod-dclist',
  templateUrl: './eod-dclist.component.html'
})
export class EODDCListComponent implements OnInit {

  user;
  res_data;
  user_data = {
    date: null,
    settlement_uid: null,
    access_token: null,
    url: null,
    offset: null,
    dcs: null
  };

  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public searchValue;
  public frameworkComponents;
  public rowData = [];
  public navigate;
  public defaultColDef;
  public sideBar;
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public title_name = "DC LIST";
  public styleGrid;
  public myDate;
  public collapse_disable;

  constructor(private apiService: ApiService,
              private router: Router,
              private elRef: ElementRef,
              private renderer: Renderer2,
              public sharedService: SharedService,
              private datePipe: DatePipe,
              private dateTimeAdapter: DateTimeAdapter<any>) {

    this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.user_data.date = localStorage.getItem('my_date');
    if (this.user_data.date === null || this.user_data.date === "null") {
      this.user_data.date = this.myDate;
      localStorage.setItem('my_date', this.myDate);
    } else {
      this.myDate = this.user_data.date;
    }
    this.user_data.offset = -1 * (new Date(this.user_data.date)).getTimezoneOffset();
    this.user_data.settlement_uid = JSON.parse(localStorage.getItem('user')).id;
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');

    localStorage.setItem('user_data', JSON.stringify(this.user_data));

    dateTimeAdapter.setLocale('en-IN');

    this.apiDcList();

    this.gridOptions = {
      rowHeight: 75,
      rowStyle: {'text-align': 'left', padding: '20px 0px'},
      rowSelection: 'single',
      onRowSelected: params => {
        localStorage.setItem('wflist_params', JSON.stringify(params.data));
        this.router.navigate([this.apiService.redirect_url + "/eod-vanlist"]);
      }
    } as GridOptions;
  }

  apiDcList() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];
    const rowdata = [];

    this.res_data = this.apiService.dclist(this.user_data);
    this.res_data.pipe(
      timeout(960000)
    ).subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
          const dc_ids = [];
          res['results'].data.forEach(data => {
            const pushData = {
              DC: data.dc_name,
              REGION: data.region,
              "TOTAL VANS": data.no_of_vans,
              "VANS NOT PLACED": parseInt(data.no_of_vans, 10) - parseInt(data.ordered_vans, 10),
              "VANS SETTLED": data.settled_vans,
              LOADIN: '...',
              "LOAD VALUE": data.loadout,
              "SALES VALUE": {total: '...', value: '...'},
              "MTD ACHIEVEMENT": {total: '...', value: '...'},
              dc_id: data.dc_id,
              ordered_vans: data.ordered_vans,
              deviation_count: '...'

            };
            dc_ids.push(data.dc_id);
            rowdata.push(pushData);
          });
          this.data = rowdata[0];
          this.rowData = rowdata;

          for (const key in this.data) {
            if (this.data.hasOwnProperty(key)) {
              if (this.data[key] !== null && typeof (this.data[key]) === 'object') {
                console.log(this.data[key]);
                this.columnDefs.push({
                  headerName: key, field: key, sortable: true, filter: false, lockPosition: true,
                  cellRenderer: "colorCellRenderer",
                  pinnedRowCellRenderer(params) {
                    return params.value;
                  }
                });

              } else {
                if (key === "DC") {
                  this.columnDefs.push({
                    headerName: key, field: key, sortable: true, filter: false, unSortIcon: true, lockPosition: true,
                    cellRenderer(params) {
                      return '<b>' + params.value + '</b>';
                    },
                  });

                } else if (key === "dc_id") {
                  this.columnDefs.push({
                    headerName: key, field: key, sortable: true, filter: false, unSortIcon: true, lockPosition: true, hide: true
                  });
                } else if (key === "ordered_vans") {
                  this.columnDefs.push({
                    headerName: key, field: key, sortable: true, filter: false, unSortIcon: true, lockPosition: true, hide: true
                  });
                } else if (key === "deviation_count") {
                  this.columnDefs.push({
                    headerName: key, field: key, sortable: true, filter: false, unSortIcon: true, lockPosition: true, hide: true
                  });
                } else {
                  this.columnDefs.push({headerName: key, field: key, sortable: true, filter: false, lockPosition: true});
                }
              }
            }
          }
          this.loaded = true;
          this.frameworkComponents = {
            colorCellRenderer: ColorCellRenderer
          };
          this.user_data.dcs = dc_ids;
          this.res_data = this.apiService.dclist_2(this.user_data);
          // tslint:disable-next-line:no-shadowed-variable
          this.res_data.subscribe(res => {
            console.log(res);
            const inthis = this;
            let updated_row;
            if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
              this.rowData.forEach(each_row => {
                updated_row = res['results'].data.filter(existed => parseInt(each_row.dc_id, 10) === parseInt(existed.dc_id, 10));
                if (updated_row.length > 0) {
                  let mtd_perc = '0';
                  let sale_perc = '0';
                  if (parseFloat(updated_row[0].mtd_sale) !== 0 && parseFloat(updated_row[0].lmtd_sale) !== 0) {
                    mtd_perc = (((parseFloat(updated_row[0].mtd_sale) - parseFloat(updated_row[0].lmtd_sale)) / parseFloat(updated_row[0].lmtd_sale)) * 100).toFixed(inthis.apiService.decimalPolicy);
                    if (parseFloat(mtd_perc) > 0) {
                      mtd_perc = '+' + mtd_perc;
                    }
                  }
                  if (parseFloat(updated_row[0].sale) !== 0 && parseFloat(updated_row[0].yest_sale) !== 0) {
                    sale_perc = (((parseFloat(updated_row[0].sale) - parseFloat(updated_row[0].yest_sale)) / parseFloat(updated_row[0].yest_sale)) * 100).toFixed(inthis.apiService.decimalPolicy);
                    if (parseFloat(sale_perc) > 0) {
                      sale_perc = '+' + sale_perc;
                    }
                  }
                  each_row['LOADIN'] = updated_row[0].carryover;
                  each_row['SALES VALUE'] = {total: updated_row[0].sale.toFixed(inthis.apiService.decimalPolicy), value: sale_perc + '%'};
                  each_row['MTD ACHIEVEMENT'] = {total: updated_row[0].mtd_sale.toFixed(inthis.apiService.decimalPolicy), value: mtd_perc + '%'};
                  each_row['deviation_count'] = updated_row[0].deviation_count;
                }
              });
              this.gridApi.setRowData(this.rowData);
            }
          }, error => {
            console.log(error);
          });
        } else {
          this.preloader = false;
          this.empty_state = true;
        }

      },
      error => {
        console.log(error);
        this.preloader = false;
      });
  }


  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setRowData(this.rowData);
    this.preloader = false;
    this.gridApi.setDomLayout("autoHeight");
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (params.api) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      this.gridOptions.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid');
  }

  ngOnInit() {
    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.sharedService.setHeader('DC List')
    this.renderer[this.collapse_disable === "true" ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate() {
    this.user_data.date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    localStorage.setItem('my_date', this.user_data.date);
    localStorage.setItem('user_data', JSON.stringify(this.user_data));
    this.apiDcList();
  }
}
