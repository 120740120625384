import {BaseUpload} from "../../admin/upload/common/baseUpload";
import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {ErrorHandler} from "../../error/error-handler";
import {CellEditingStartedEvent, CellEditingStoppedEvent, RowEditingStartedEvent, RowEditingStoppedEvent} from "@ag-grid-enterprise/all-modules";
@Component({
  selector: 'app-upload-beat-plan',
  templateUrl: './upload_beat_plan.html',
  styleUrls: ['../../admin/upload/common/baseUpload.scss']
})
export class UploadBeatPlanComponent extends BaseUpload implements OnInit {
  @Output() uploadData: EventEmitter<any> = new EventEmitter<any>();

  wizard_step = 1;

  public beatplanMasterList = [];
  public salesmenList = [];
  public territoryList = [];
  public beatTravelTypes = [];

  ngOnInit(): void {
    this.configureGrid();

    this.salesmenList = JSON.parse(localStorage.getItem('all_salesmans'));
    this.territoryList = JSON.parse(localStorage.getItem('all_territories'));


    this.getRecords('/api/beat_plans/get_beat_plan_list_pwa', this.userContext, (err, raw_data) => {
      this.beatplanMasterList = raw_data;
    });

    this.getBeatTravelTypes();

  }

  getBeatTravelTypes() {
    const paramObject: any = {};
    paramObject.cid = JSON.parse(localStorage.getItem('user')).cid;
    this.apiService.getFilter('/api/beat_travel_types', this.userContext.access_token, paramObject)
      .subscribe(res => {
        // console.log(res);
        if (res.length > 0) {
          this.beatTravelTypes = res;
        }
      });
  }

  configureGrid() {
    const ValidationCellClassRules = {
      "cell-pass": params => (params.value === 'OK' || params.value === 'SUCCESS'),
      "cell-progress": params => params.value === 'IN PROGRESS',
      "cell-fail": params => (params.value !== 'OK' || params.value !== 'SUCCESS')
    };

    this.excel_columns = [
      {headerName: 'ID', field: 'id', width: 40, editable: false},
      {headerName: 'CODE', field: 'code', width: 40, editable: true},
      {headerName: 'NAME', field: 'name', width: 80, editable: true},
      {headerName: 'SALESMAN', field: 'salesman', width: 80, editable: true},
      {headerName: 'TERRITORY', field: 'territory', width: 80, editable: true},
      {headerName: 'TRAVEL_TYPE', field: 'travel_type', width: 50, editable: true},
      {headerName: 'TA', field: 'ta', width: 50, cellClass: 'number-cell', editable: true},
      {headerName: 'DA', field: 'da', width: 50, cellClass: 'number-cell', editable: true},
      {headerName: 'SA', field: 'sa', width: 50, cellClass: 'number-cell', editable: true},
      {headerName: 'PRIORITY', field: 'priority', width: 40, editable: true},
      {headerName: 'UNCOVERED', field: 'uncovered', width: 40, editable: true},
      {headerName: 'VISIT FREQ', field: 'visit_frequency', width: 40, editable: true},
      {headerName: 'SCHEDULE_TYPE', field: 'schedule_type', width: 30, editable: true},
      {headerName: 'WEEK 1', field: 'w1', width: 30, editable: true},
      {headerName: 'WEEK 2', field: 'w2', width: 30, editable: true},
      {headerName: 'WEEK 3', field: 'w3', width: 30, editable: true},
      {headerName: 'WEEK 4', field: 'w4', width: 30, editable: true},
      {headerName: 'ACTIVE', field: 'active', width: 50, editable: true},
      // {headerName: 'VERTICAL_CODES', field: 'vertical_codes', width: 50},
    ];

    this.stub_data = [{F: 'A', V: '', optional: true},
      {F: 'B', V: 'B132', optional: false},
      {F: 'C', V: 'Yalahanka Town', optional: false},
      {F: 'D', V: 'SOUTH BANGALORE', optional: false},
      {F: 'E', V: 'RAVI KIRAN', optional: false},
      {F: 'F', V: 'Ex-HQ', optional: true},
      {F: 'G', V: '200', optional: true},
      {F: 'H', V: '220', optional: true},
      {F: 'I', V: '0', optional: true},
      {F: 'J', V: '2', optional: true},
      {F: 'K', V: 'False', optional: true},
      {F: 'L', V: '2', optional: true},
      {F: 'M', V: 'MANUAL', optional: true},
      {F: 'N', V: 'Tue', optional: true},
      {F: 'O', V: '', optional: true},
      {F: 'P', V: 'Mon, Fri', optional: true},
      {F: 'Q', V: '', optional: true},
      {F: 'R', V: 'True', optional: true}
    ];

    this.columnDefs = [{headerName: '', field: 'row_no', width: 10}];
    this.excel_columns.forEach(col => {
      this.columnDefs.push(col);
    });
    this.columnDefs.push({headerName: 'VALIDATION', field: 'status', width: 60, cellClassRules: ValidationCellClassRules, pinned: 'right'});
    this.columnDefs.push({headerName: 'ERROR', field: 'err_msg', width: 100, pinned: 'right'});
    this.getRowId = (params) => params.data.row_no;
  }

  saveData() {

  }

  downloadBeatPlanData() {
    if (this.rowData) {
      const excel_rows = [];
      for (const row of this.rowData) {
        const eRow: any = {};
        this.excel_columns.forEach(col => {
          if (row[col.field] !== undefined && row[col.field] !== null) {
            eRow[col.headerName] = row[col.field];
          } else {
            eRow[col.headerName] = '';
          }
        });

        excel_rows.push(eRow);
      }

      this.downloadExcel(excel_rows, 'beat_plan_data.xlsx');
    }
  }

  downloadBeatPlanTemplate() {
    const templateData = [];
    const temp: any = {};
    this.excel_columns.forEach(col => {
      temp[col.headerName] = '';
    });

    templateData.push(temp);
    this.downloadExcel(templateData, 'beat_plan_template.xlsx');
  }


  onValidateClick() {
    return this.validate_beat_plan_records();
  }

  validateRow(input_row) {

    input_row['status'] = 'PROCESSING';
    input_row['err_msg'] = '';

    if (input_row['id'] !== undefined || input_row['id'] > 0) {
      const beat_obj = this.beatplanMasterList.find(item => item.id === parseInt(input_row['id'], 10));
      if (!beat_obj) {
        input_row['status'] = 'INVALID';
        input_row['err_msg'] = 'Beat Id not found, or you do not have access';
      }
    } else {

      if (input_row['name'] !== undefined || input_row['name'] !== '') {
        const beat_obj = this.beatplanMasterList.find(item => item.name.trim().toLowerCase() === input_row['name'].trim().toLowerCase());
        if (beat_obj) {
          input_row['status'] = 'INVALID';
          input_row['err_msg'] = 'Beat Name must be unique';
        }
      }
    }

    if (input_row['name'] === undefined || input_row['name'] === '') {
      input_row['status'] = 'INVALID';
      input_row['err_msg'] = 'Beat Name cannot be blank';
    }

    if (input_row['salesman'] === undefined || input_row['salesman'] === '') {
      input_row['status'] = 'INVALID';
      input_row['err_msg'] = 'Salesman is mandatory for the beat';
    }

    const salesman_obj = this.salesmenList.find(item => item.itemname === input_row['salesman']);
    if (!salesman_obj) {
      input_row['status'] = 'INVALID';
      input_row['err_msg'] = 'Salesman name not matching in the master users list';
    }
    if (input_row['territory'] === undefined || input_row['territory'] === '') {
      if (salesman_obj) {
        input_row['territory'] = 'Salesman Default Territory';
      }
    } else {
      const territory_obj = this.territoryList.find(item => item.itemname === input_row['territory']);
      if (!territory_obj) {
        input_row['status'] = 'INVALID';
        input_row['err_msg'] = 'Territory name not matching in the master territory list';
      }
    }

    if (input_row['status'] === 'PROCESSING') {
      input_row['status'] = 'OK';
    }

    return input_row;
  }

  parseWeekDays(input_value) {
    let wArray = [];
    const weekAry = [];
    const weekdays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    if (input_value) {
      wArray = input_value.split(',');
      wArray.forEach(day => {
        if (weekdays.includes(day.trim().toLowerCase())) {
          weekAry.push(day.trim().toLowerCase());
        }
      });
    }
    return weekAry.join(',');
  }

  validate_beat_plan_records() {
    this.data_to_upload = [];
    this.rowData = [];
    let rowNo = 1;

    if (this.salesmenList === undefined) {
      this.toastr.error('Unable to load salesman list, re-login and try again, or contact support');
    }
    if (this.territoryList === undefined) {
      this.toastr.error('Unable to load Territory list, re-login and try again, or contact support');
    }

    if (this.data_from_sheet.length > 0) {

      this.data_from_sheet.forEach(exlRow => {

        const line = this.toLowerCaseProps(exlRow);
        const exl_row = {};
        exl_row['row_no'] = rowNo++;
        exl_row['id'] = this.getFieldValue(line, 'id');
        exl_row['code'] = this.getFieldValue(line, 'code');
        exl_row['name'] = this.getFieldValue(line, 'name');
        exl_row['territory'] = this.getFieldValue(line, 'territory');
        exl_row['salesman'] = this.getFieldValue(line, 'salesman');
        exl_row['travel_type'] = this.getFieldValue(line, 'traveltype', 'hq');
        exl_row['ta'] = this.getFieldValue(line, 'ta', 0);
        exl_row['da'] = this.getFieldValue(line, 'da', 0);
        exl_row['sa'] = this.getFieldValue(line, 'sa', 0);
        exl_row['visit_frequency'] = this.getFieldValue(line, 'visitfrequency', 4);
        exl_row['uncovered'] = this.getFieldValue(line, 'uncovered', false);
        exl_row['schedule_type'] = this.getFieldValue(line, 'scheduletype', 'manual');
        exl_row['w1'] = this.parseWeekDays(this.getFieldValue(line, 'week1'));
        exl_row['w2'] = this.parseWeekDays(this.getFieldValue(line, 'week2'));
        exl_row['w3'] = this.parseWeekDays(this.getFieldValue(line, 'week3'));
        exl_row['w4'] = this.parseWeekDays(this.getFieldValue(line, 'week4'));
        exl_row['active'] = this.getFieldValue(line, 'active', true);

        if (exl_row['active'].toLowerCase() === 'false' || exl_row['active'].toLowerCase() === '0') {
          exl_row['active'] = false;
        } else {
          exl_row['active'] = true;
        }

        const salesman_obj = this.salesmenList.find(item => item.itemname === exl_row['salesman']);
        const ter_obj = this.territoryList.find(item => item.itemname === exl_row['territory']);
        if (salesman_obj) {
          exl_row['salesman_id'] = salesman_obj.id;
        }

        if (ter_obj) {
          exl_row['territory_id'] = ter_obj.id;
        } else if (salesman_obj) {
          exl_row['territory_id'] = salesman_obj.territory_id;
        }
        const travelType_obj = this.beatTravelTypes.find(item => item.itemname === exl_row['travel_type']);
        if (travelType_obj) {
          exl_row['travel_type_id'] = travelType_obj.id;
        } else {
          exl_row['travel_type_id'] = this.beatTravelTypes[0].id;
        }

        this.rowData.push(this.validateRow(exl_row));
      });

      this.wizard_step = 2;
    }

    if (this.gridApi) {
      this.gridApi.setRowData(this.rowData);
      this.gridApi.setColumnDefs(this.columnDefs);
      this.gridApi.sizeColumnsToFit();
    }

    this.validateButton = false;
    this.upload_loader = false;


    this.totalDataCount = this.rowData.length;
    this.errorDataCount = this.rowData.filter(item => item.status !== 'OK').length;
    this.validDataCount = this.rowData.filter(item => item.status === 'OK').length;

  }

  onBackClick() {
    this.wizard_step = 1;
  }


  onRowEditingStarted(event: RowEditingStartedEvent) {
    console.log('never called - not doing row editing');
  }

  onRowEditingStopped(event: RowEditingStoppedEvent) {
    console.log('never called - not doing row editing');
  }

  onCellEditingStarted(event: CellEditingStartedEvent) {
    console.log('cellEditingStarted');
  }

  onCellEditingStopped(event: CellEditingStoppedEvent) {
    if (event.colDef.field === 'code') {
      const validated_row = this.validateRow(event.data);
      event.node.setDataValue("status", validated_row['status']);
      event.node.setDataValue("err_msg", validated_row['err_msg']);
      event.api.refreshCells({force: true});
    }
  }

  onUploadClick() {
    let inThis = this;
    const dataToUpload = [];
    const valid_rows = this.rowData.filter(item => item.status === 'OK');
    valid_rows.forEach(row => {
      const beat = {...row};
      if (row.id === undefined || row.id <= 0) {
        beat['mid'] = this.get_mid(row.row_no);
        delete beat['id'];
      } else {
        beat['id'] = parseInt(row.id, 10);
      }

      beat.schedule_config = {};
      if (beat.schedule_type === 'recurring') {
        if (beat.w1) {
          beat.schedule_config.w1 = beat.w1;
        }
        if (beat.w2) {
          beat.schedule_config.w2 = beat.w2;
        }
        if (beat.w3) {
          beat.schedule_config.w3 = beat.w3;
        }
        if (beat.w1) {
          beat.schedule_config.w4 = beat.w4;
        }
      }

      dataToUpload.push(beat);
    });

    this.userContext.data = dataToUpload;
    this.apiService.post('/api/beat_plans/save_beat_plan', this.userContext)
      .subscribe(res => {

          if (res.hasOwnProperty('results') && res.results.status === 200) {
            if (res.results.hasOwnProperty('data') && res.results.data.length > 0) {

              res.results.data.forEach(beat => {

              });

              this.toastr.success('Uploaded successfully : ' + res.results.data.length);

            } else {
              this.toastr.success('Uploaded successfully!');
            }
          } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }

        },
        error => {
          this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
        });

  }
}
