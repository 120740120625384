import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-outlet-not-visit-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class OutletNotVisitReportComponent extends ReportBase implements IReport, OnInit {

  apiData: any = [];
  customFilter: any = {
    name: 'Status',
    key: 'type',
    value: '',
    values: [],
    show: true
  };

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Outlet Visit Report');

    this.showableFilters.salesman = true;
    /* this.showableFilters.team = true;*/
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.showCustomFilter = true;
    this.customFilter.values = [
      {id: '', itemName: 'All'},
      {id: 'true', itemName: 'Visited'},
      {id: 'false', itemName: 'Not Visited'}
    ];

    this.userData.offsetID = 0;
    this.userData.offset = 50000;
    this.loadInBatches(50000, 'rank');
    this.showProgressCustomText = true;

    this.configureGrid();
      }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getRetailerVisitReport';

    this.columnDefs = [
      {headerName: 'VISIT STATUS', field: 'status', enableRowGroup: true, width: 100, aggFunc: this.visitavgAggFunction},
      {headerName: 'VISIT FREQUENCY', field: 'visit_frequency', enableRowGroup: true, width: 100, aggFunc: this.mainAggFunction},
      {headerName: 'DATE', field: 'report_date', enableRowGroup: true, width: 80},
      {headerName: 'ACTIVITY', field: 'action_name', enableRowGroup: true, width: 80},
      {headerName: 'ACTIVITY TYPE', field: 'action_type', enableRowGroup: true, width: 80},
      {headerName: 'REGION', field: 'region', width: 100, enableRowGroup: true},
      {headerName: 'STATE', field: 'state', width: 100, enableRowGroup: true},
      {headerName: 'CLUSTER', field: 'cluster', width: 100, enableRowGroup: true},
      {headerName: 'TERRITORY', field: 'territory', enableRowGroup: true, width: 80},
      {headerName: 'VISITED BY', field: 'visited_by', enableRowGroup: true, width: 150},
      {headerName: 'SCODE', field: 'emp_code', enableRowGroup: true, width: 150},
      {headerName: 'OUTLET', field: 'name', enableRowGroup: true, width: 200},
      {headerName: 'OCODE', field: 'code', enableRowGroup: true, width: 80},
      {headerName: 'OTYPE', field: 'r_type', enableRowGroup: true, width: 80},
      {headerName: 'OACTIVE', field: 'active', enableRowGroup: true, width: 80},
      {headerName: 'CLASS', field: 'class_type', enableRowGroup: true, width: 120},
      {headerName: 'PROGRAM TYPE', field: 'program', enableRowGroup: true, width: 120},
      {headerName: 'BEAT', field: 'beat_name', enableRowGroup: true, width: 120},
      {headerName: 'SALESMAN', field: 'beat_salesman', enableRowGroup: true, width: 120},
      {headerName: 'COMPLETION', field: 'completion', enableRowGroup: true, width: 80},

    ];

  }

  /*loadServerData() {
 this.goClicked = true;
     //debugger;
    /!*   const t = trace(this.appModule.perf, this.sharedService.headerTitleSource.value);
       t.start();
       t.putAttribute('companyKey', localStorage.getItem('company_key'));*!/
    console.log(this.sharedService.headerTitleSource.value);
    this.dataLoaded = false;
    this.preloader = true;
    this.emptyState = false;
    this.errorExists = false;

    if (this.API_URL === '') {
      return this.handleError(ErrorHandler.getErrorObject('API Not Configured'));
    }
    this.apiService.post(this.API_URL, this.userData)
      .subscribe(res => {
          console.log(res);
          // t.stop();
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.apiData = res.results.data;
            this.dataLoaded = true;
            this.preloader = false;
            this.emptyState = (this.rowData.length === 0);
            this.configureData();
            // this.rowData = res.results.data;

          } else if (res.results.status === 203) {
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        // tslint:disable-next-line:no-shadowed-variable
        error => this.handleError(ErrorHandler.getErrorObject(error)));

  }*/

  configureData() {
    this.rowData = [];
    const masterData = this.apiData.master;
    const visitData = this.apiData.visited;


    /*for (const key in masterData) {
      let indrow = {};
      let notVisitRow = [];

      notVisitRow = masterData.filter(master => master.id !== visitData.id && master.user_id === visitData.user_id);

    /!*  indrow = {
        ...masterData[key],
        ...notVisitRow
      };*!/
      this.rowData.push(notVisitRow);
    }*/

    this.rowData = masterData.filter(master =>
      !visitData.some(visit => (visit.id === master.id && visit.user_id === master.user_id)));
  }

  mainAggFunction(params) {
    let avg;
// the average will be the sum / count
    let sum = 0;
    let count = 0;

    params.values.forEach(function(value) {
      const groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        // we are aggregating groups, so we take the
        // aggregated values to calculated a weighted average
        sum += value.avg;
        /*  sum += value;
          count += value.count;*/
      } else {
        // skip values that are not numbers (ie skip empty values)
        if (typeof value === 'number') {
          //sum += value;
          sum = value;
          //count++;
        } else {
        }
      }
    });
    // avoid divide by zero error
    if (count !== 0) {
      avg = sum / count;
    } else {
      avg = null;
    }

    //avg = Math.round(Math.round((avg + Number.EPSILON) * 100) / 100);

    avg = sum;
    count = sum;

    // the result will be an object. when this cell is rendered, only the avg is shown.
    // however when this cell is part of another aggregation, the count is also needed
    // to create a weighted average for the next level.
    const result = {
      count,
      avg,
      // the grid by default uses toString to render values for an object, so this
      // is a trick to get the default cellRenderer to display the avg value
      toString() {
        return this.avg;
      },
    };

    return result;
  }

  visitavgAggFunction(params) {
    // the average will be the sum / count
    var sum = 0;
    var count = 0;
    params.values.forEach(function (value) {
      var groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        // we are aggregating groups, so we take the
        // aggregated values to calculated a weighted average
        sum += value.avg * value.count;
        count += value.count;
      } else {
        // skip values that are not numbers (ie skip empty values)
        if (value==='Visited') {
          sum += 1;
          count++;
        }
      }
    });
    // avoid divide by zero error
    if (count !== 0) {
      var avg = sum / count;
    } else {
      avg = null;
    }

    avg =  Math.round(Math.round((avg + Number.EPSILON) * 100) / 100);

    // the result will be an object. when this cell is rendered, only the avg is shown.
    // however when this cell is part of another aggregation, the count is also needed
    // to create a weighted average for the next level.
    var result = {
      count: count,
      avg: avg,
      // the grid by default uses toString to render values for an object, so this
      // is a trick to get the default cellRenderer to display the avg value
      toString: function () {
        return sum;
      },
    };

    return result;

  }

}
