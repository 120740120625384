import {Component, OnInit} from "@angular/core";
import {BaseUpload} from "../../../pagebase/base_upload/base_upload";

@Component({
  selector: 'app-customer-balance-upload',
  templateUrl: '../../../pagebase/base_upload/base_upload.html',
  styleUrls: ['../../../pagebase/base_upload/base_upload.scss']

})

export class OutstandingUploadComponent extends BaseUpload {
  setViewParams() {
    this.header = 'Outstanding Update';
    this.sampleText1 = 'DOWNLOAD ALL CUSTOMER LIST';
    this.sampleText2 = 'DOWNLOAD SAMPLE TEMPLATE';

    this.excel_columns = [{C: 'A', H: 'Code', V: 'ES2355', required: true},
      {C: 'B', H: 'Customer Name', V: 'Shobha Stores Pvt Ltd', required: false},
      {C: 'C', H: 'Current Credits', V: '1000', required: false},
      {C: 'D', H: 'Current Outstanding', V: '5000', required: false},
      {C: 'E', H: 'New Credits', V: '0', required: true},
      {C: 'F', H: 'New Outstanding', V: '10000', required: true}
    ];
    this.step1Text = 'Download all customer list or sample template for reference.';
    this.step2Text = 'Required fields are code, new credits and new outstanding.  ';
    this.actionText = 'Update Outstanding or Credits info in the template';
    this.baseData = this.sampleData1;
    this.dataLoaded = true;
  }

  sampleDownload1() {
    this.downloadExcel(this.sampleData1, 'customer_outstanding_credits');
  }

  sampleDownload2() {
    this.downloadSampleData('outstanding_template');
  }
  configureGrid() {
    this.API_URL = '/api/pwa_dms_connects/upload_customer_balance_correction';
    this.columnDefs = [
      {headerName: 'Code', field: 'code', width: 100},
      {headerName: 'Customer Name', field: 'name', width: 200},
      {headerName: 'Current Credits', field: 'credit_limit', width: 100, cellStyle: {textAlign: 'right'}},
      {headerName: 'Current Outstanding', field: 'outstanding', width: 100, cellStyle: {textAlign: 'right'}},
      {headerName: 'New Credits', field: 'new_credit', width: 100, cellStyle: {textAlign: 'right'}},
      {headerName: 'New Outstanding', field: 'new_balance', width: 100, cellStyle: {textAlign: 'right'}}
    ];
  }
  validate() {
    const inThis = this;
    this.validRecords = 0;
    this.errorRecords = 0;
    this.uploaded_data.forEach(data => {
      if (data['Code'] && ((data['New Credits'] && data['New Credits'] !== '') || (data['New Outstanding'] && data['New Outstanding'] !== ''))) {
        const indrow: any = {};
        const found = inThis.baseData.find(x => x['Code'] && (x['Code'].toString() === data['Code'].toString()));
        indrow.code = data['Code'];
        indrow.new_credit = data['New Credits'];
        indrow.new_balance = data['New Outstanding'];
        if (found) {
          indrow.name = found['Customer Name'];
          indrow.credit_limit = found['Current Credits'];
          indrow.outstanding = found['Current Outstanding'];
          if (data['New Outstanding'] && (parseFloat(indrow.outstanding) === parseFloat(data['New Outstanding']))) {
            return;
          }
          if (data['New Credits'] && (parseFloat(indrow.credit_limit) === parseFloat(data['New Credits']))) {
            return;
          }
        } else {
          indrow.name = data['Customer Name'];
          indrow.credit_limit = data['Current Credits'];
          indrow.outstanding = data['Current Outstanding'];
        }
        if (!data['Code'] || data['Code'] === '') {
          indrow.valid = 'Invalid';
          indrow.errorMsg = 'Customer Code Missing';
        }
        if (data['new_credit'] && !this.numPattern.test(data['new_credit'])) {
          indrow.valid = 'Invalid';
          indrow.errorMsg = 'Invalid New Credits';
        }
        if (data['new_balance'] && !this.numPattern.test(data['new_balance'])) {
          indrow.valid = 'Invalid';
          indrow.errorMsg = 'Invalid New Outstanding';
        }
        if (indrow.valid === 'Invalid') {
          this.errorRecords++;
        } else {
          indrow.valid = 'Valid';
          this.validRecords++;
        }
        this.rowData.push(indrow);
      }
    });
    this.totalRecords = this.rowData.length;
    if (this.gridOptions && this.gridOptions.api) {
      this.gridOptions.api.setRowData(this.rowData);
    }
  }
}
