import {Component, ElementRef, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {ApiService} from "../../../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {DomSanitizer} from "@angular/platform-browser";
import {ToastrService} from "ngx-toastr";
import {SharedService} from "../../../../_services/shared_service";
import {DMSBasePage} from "../../../../pagebase/components/DMSBasePage";
import {DatePipe} from "@angular/common";
import {LocalforageService} from "../../../../_services/localforage.service";
import {ErrorHandler} from "../../../../error/error-handler";
import {AppComponent} from "../../../../app.component";

@Component({
  selector: 'app-credit-note-popup',
  templateUrl: './credit_note_popup.component.html'
})

export class CreditNotePopupComponent extends DMSBasePage implements OnInit {

  @Output() refreshPageAction: EventEmitter<any> = new EventEmitter<any>();

  activeObject = {
    partner_id: 0,
    customer_name: '',
    customer_code: '',
    customer_gst_id: '',
    customer_email: '',
    customer_address: '',
    balance: '',
    credit: '',
    credit_limit: '',
    credit_days: ''
  };
  context: any;

  public credit_note_narration = '';
  public credit_note_amount = 0;
  public gst_verified = true;
  selectedCustomer: any = {};

  constructor(public dialog: MatDialog,
              public apiService: ApiService,
              public sharedService: SharedService,
              public toastr: ToastrService,
              public elRef: ElementRef,
              public datePipe: DatePipe,
              protected route: ActivatedRoute,
              protected _localCacheService: LocalforageService,
              public router: Router,
              public domSanitizer: DomSanitizer,
              public appComponent: AppComponent,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    super(apiService, sharedService, elRef, datePipe, _localCacheService, toastr, route, dialog, router, domSanitizer, appComponent);

    if (dialogdata?.partner_id) {
      if (this.sharedService.ACLcheck('module_dmsv2')) {
        this.activeObject.partner_id = dialogdata.partner_id;
        const cust_obj = this.customerList.find(item => item.id === this.activeObject['partner_id']);
        if (cust_obj) {
          this.activeObject['customer_name'] = cust_obj.name;
          this.activeObject['customer_code'] = cust_obj.code;
          this.activeObject['customer_gst_id'] = cust_obj.gst_id;
          this.activeObject['customer_email'] = cust_obj.email;
          this.activeObject['customer_address'] = cust_obj.address;
        }
      } else if (dialogdata && dialogdata.partner_id) {
        this.activeObject.partner_id = dialogdata.partner_id;
        this.activeObject['customer_name'] = dialogdata.cust_obj.name;
        this.activeObject['customer_code'] = dialogdata.cust_obj.code;
        this.activeObject['customer_gst_id'] = dialogdata.cust_obj.gst_id;
        this.activeObject['customer_email'] = dialogdata.cust_obj.email;
        this.activeObject['customer_address'] = dialogdata.cust_obj.address;
        this.customerList.push(dialogdata.cust_obj);
      }
    } else {
    }
    this.OPERATION_MODE = 'S';
    this.loadPartnersDataForEdit(() => {
      if (this.activeObject?.partner_id && this.activeObject.partner_id > 0) {
        this.fetchPartnerBalanceDetails(this.activeObject.partner_id);
      }
    });
  }

  ngOnInit(): void {
  }

  fetchPartnerBalanceDetails(partner_id) {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');

    paramObject.data = {
      retailer_ids: [partner_id]
    };
    this.apiService.post('/api/res_partners/get_customer_supplier_balance', paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200) && res.results.data) {
          if (res.results.data[0]) {
            this.activeObject.balance = res.results.data[0].balance;
            this.activeObject.credit = res.results.data[0].credit;
            // this.activeObject.credit_limit = res.results.data[0].credit_limit;
          }
        }
        this.dataLoaded = true;
      });
  }

  customerChangeEvent(selected_customer_id) {
    this.selectedCustomer = this.customerList.find(x => x.id === selected_customer_id);
    this.activeObject['customer_name'] = this.selectedCustomer.name;
    this.activeObject['customer_code'] = this.selectedCustomer.code;
    this.activeObject['customer_gst_id'] = this.selectedCustomer.gst_id;
    this.activeObject['customer_email'] = this.selectedCustomer.email;
    this.activeObject['customer_address'] = this.selectedCustomer.address;
    this.fetchPartnerBalanceDetails(selected_customer_id);
  }

  changeDate(dt) {

  }

  closeDialog() {
    this.dialog.closeAll();
  }

  onCreditNoteSaveClick() {

    if (this.sharedService.role_features && this.sharedService.ACLcheck('feature_old_credit_note')) {

      this.userContext['datas'] = {
        partner_id: this.activeObject.partner_id,
        payment_type: 'cash',
        credit_amt: this.credit_note_amount
      };

      this.apiService.post('/api/pwa_connects/generate_credit_note', this.userContext)
        .subscribe(res => {
            if (res.hasOwnProperty('results') && res.results.status === 200) {
              if (res.results.data.length > 0) {

                this.toastr.success('Credit Note Created');
                this.refreshPageAction.emit(true);
                this.dialog.closeAll();
              }

            } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
              this.handleError(ErrorHandler.getErrorObject(res.results.msg));
            } else {
              this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
            }
          },
          error => {
            return this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          });

    } else {

      this.userContext['partner_id'] = this.activeObject.partner_id;
      this.userContext['credit_note_amount'] = this.credit_note_amount;
      this.userContext['credit_note_narration'] = this.credit_note_narration;
      this.userContext['cn_type'] = 'manual';

      this.apiService.post('/api/pwa_dms_connects/save_credit_note', this.userContext)
        .subscribe(res => {
            if (res.hasOwnProperty('results') && res.results.status === 200) {

              if (res.results.data.length > 0) {

                this.toastr.success('Credit Note Created');
                this.refreshPageAction.emit(true);
                this.dialog.closeAll();
              }


            } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
              this.handleError(ErrorHandler.getErrorObject(res.results.msg));
            } else {
              this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
            }
          },
          error => {
            return this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          });
    }
  }

  onCancelClick() {

  }
}
