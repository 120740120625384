import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {MatDialogConfig} from "@angular/material/dialog";
import {ImageGalleryPopupComponent} from "../../components/image_gallery_popup/image_gallery_popup.component";

@Component({
  selector: 'app-asset-service-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class AssetServiceReportComponent extends ReportBase implements IReport, OnInit {

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Asset Service Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.showDate = true;

    this.configureGrid();
    this.API_URL = '/api/pwa_v2_reports/assetServiceReport';
  }


  configureGrid() {

    this.columnDefs = [
      {
        headerName: "SERVICE",
        children: [
          {headerName: 'PRIORITY', field: 'priority', enableRowGroup: false, width: 80},
          {headerName: 'SERVICE NO', field: 'serviceno', enableRowGroup: true, width: 50},
          {headerName: 'REQ DATE', field: 'requested_date', enableRowGroup: true, width: 50},
          {headerName: 'STATUS', field: 'service_status', enableRowGroup: true, width: 50},
          {headerName: 'TYPE', field: 'service_type', enableRowGroup: true, hide: false, width: 80},
          {headerName: 'PURCHASED FROM', field: 'purchased_from', enableRowGroup: true, width: 80},
          {headerName: 'DISTRIBUTOR', field: 'dist_name', enableRowGroup: true, width: 80},
          {headerName: 'SOURCE', field: 'source', hide: false, width: 80}
        ]
      }, {
        headerName: "ENGINEER",
        children: [
          {headerName: 'NAME', field: 'service_engineer', hide: false, width: 80},
          {headerName: 'TEAM', field: 'team', hide: false, width: 80},
          {headerName: 'MOBILE', field: 'assigned_mobile', hide: false, width: 80},
          {headerName: 'REGION', field: 'region', enableRowGroup: true, width: 80},
          {headerName: 'STATE', field: 'state', enableRowGroup: true, width: 80}
        ]
      }, {
        headerName: "ASSIGNED",
        children: [
          {headerName: 'DATE', field: 'assigned_on', hide: false, width: 80},
          {headerName: 'USER', field: 'assigned_user', hide: false, width: 80},
          {headerName: 'MOBILE', field: 'assigned_mobile', hide: false, width: 80},
        ]
      }, {
        headerName: "REFERRER",
        children: [
          {headerName: 'TYPE', field: 'referrer_type', hide: false, width: 80},
          {headerName: 'NAME', field: 'referrer_name', hide: false, width: 80},
          {headerName: 'Mobile', field: 'referrer_mobile', hide: false, width: 80},
        ]
      }, {
        headerName: "CUSTOMER",
        children: [
          {headerName: 'NAME', field: 'customer_name', enableRowGroup: false, width: 80},
          {headerName: 'MOBILE', field: 'customer_mobile', enableRowGroup: true, width: 50},
          {headerName: 'ADDRESS', field: 'address', enableRowGroup: true, hide: true, width: 80},
          {headerName: 'CITY', field: 'city', enableRowGroup: true, width: 80}
        ]
      }, {
        headerName: "ASSET",
        children: [
          {headerName: 'NAME', field: 'asset_name', enableRowGroup: false, width: 80},
          {headerName: 'MASTER', field: 'asset_master', enableRowGroup: true, width: 50},
          {headerName: 'BAR CODE', field: 'barcode', enableRowGroup: true, hide: true, width: 80},
          {headerName: 'INSTALLED ON', field: 'installed_date', enableRowGroup: true, width: 80},
          {
            headerName: "IMAGE", headerClass: 'myagheader', field: "image", width: 50,
            cellRenderer(params) {
              if (!params.data.image_count  || parseInt(params.data.image_count, 10) === 0) {
                return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
              } else {
                return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img><span>(' + params.data.image_count + ')</span>';
              }
            }
          },
        ]
      }, {
        headerName: "CLOSURE",
        children: [
          {headerName: 'DATE', field: 'service_closed', enableRowGroup: false, width: 80},
          {headerName: 'IMAGES', field: 'image_count', enableRowGroup: false, width: 80},
          {headerName: 'TAT(DAYS)', field: 'closing_days', enableRowGroup: true, width: 50},
          {headerName: 'DELAY FROM', field: 'delayed_on', enableRowGroup: true, width: 80},
          {headerName: 'DELAY REASON', field: 'delayed_reason', enableRowGroup: true, hide: true, width: 80},
          {headerName: 'OTP VERIFIED', field: 'otp_verified', enableRowGroup: true, width: 80},
          {headerName: 'FSR REPORT', field: 'fsr_report', enableRowGroup: true, width: 80},
          {headerName: 'SE COMMENTS', field: 'se_comments', enableRowGroup: true, width: 80},
          {headerName: 'CUSTOMER COMMENTS', field: 'customer_comments', enableRowGroup: true, width: 80},
          {headerName: 'CLOSING REASON', field: 'closing_reason', enableRowGroup: true, width: 80},
          {headerName: 'AMOUNT', field: 'service_amt', enableRowGroup: true, width: 80},
          {headerName: 'RCA', field: 'rca', enableRowGroup: true, width: 80},
          {headerName: 'PREVENTION', field: 'prevention', enableRowGroup: true, width: 80},
          {headerName: 'CORRECTION', field: 'correction', enableRowGroup: true, width: 80},
        ]
      },
    ];

  }

  onCellClicked(event) {

    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    if (column === 'image') {
      if (rows[0].image_count && parseInt(rows[0].image_count, 10) > 0) {
        this.showImagePopup(rows[0]);
      }
    } else {
    }
  }

  showImagePopup(row) {
    const gallery = [];
    row.gallery.forEach(image => {
      gallery.push({
        store_fname: image,
        container: 'asset_gallery'
      });
    });
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "70%",
        maxHeight: "95%",
        data: {
          gallery
        }
      };

      const creatediaeref = this.dialog.open(ImageGalleryPopupComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        data: {
          gallery
        }
      };

      const creatediaeref = this.dialog.open(ImageGalleryPopupComponent, config);
    }
  }

}
