import {Component, OnInit, ElementRef, Renderer2, ViewChild} from '@angular/core';
import { GridOptions } from '@ag-grid-enterprise/all-modules';
import { ApiService} from '../../_services/api.service';
import { SharedService } from '../../_services/shared_service';
import { Router } from '@angular/router';
import { ColorCellRenderer } from '../../colorCell.component';
import { DatePipe } from '@angular/common';
import { LoadingstateComponent} from '../../components/loadingstate/loadingstate.component';
import { DateTimeAdapter } from 'ng-pick-datetime';
import * as XLSX from "xlsx-js-style";
import {ToastrService} from 'ngx-toastr';
import {AppComponent} from "../../app.component";
import {ErrorHandler} from "../../error/error-handler";
import {OpenCreditComponent} from "./openCredit.component";

declare var $: any;

@Component({
  selector: 'app-dmspayments',
  templateUrl: './dms_payments.component.html',
  styleUrls: ['./dms_payments.component.scss']
})
export class Dms_paymentsComponent implements OnInit {

  user;
  res_data;
  user_data = {
      date: null,
      supplier_id: null,
      access_token: null,
      url: null,
      offset: null,
      data: null,
      start_date: null,
      end_date: null,
      type: null,
      cd: 'All',
  };

  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public searchValue;
  private gridApi1;
  private gridColumnApi1;
  public columnDefs1 = [];
  public searchValue1;
  public frameworkComponents;
  public rowData = [];
  public gridOptions: GridOptions;
  public rowData1 = [];
  public gridOptions1: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public loaded1 = false;
  public preloader1 = false;
  public empty_state1 = false;
  public styleGrid;
  public myDate = [];
  public collapse_disable;
  public isModelOpened = true;
  public newRetailer = {
    code: null,
    name: null,
    mobile: null,
    retailer_type: null,
    class_type: null,
    territory: null,
    beat: null,
    beat_name: null
  };
  public typeList;
  public selectedType = [];
  public classType = [];
  public selectedClass = [];
  public selectedTerr = [];
  public selectedBeat = [];
  public beatList = [];
  public terrList = [];
  public confirmEnable = true;
  public API_URL = '';
  public cdStatus = 'All';
  public showCrNotes = true;
  public context;

  constructor(private apiService: ApiService,
              private sharedService: SharedService,
              private router: Router,
              private elRef: ElementRef,
              private renderer: Renderer2,
              private datePipe: DatePipe,
              private toastr: ToastrService,
              private appComp: AppComponent,
              private dateTimeAdapter: DateTimeAdapter<any>) {

    this.myDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.user_data.start_date = this.myDate[0];
    this.user_data.end_date = this.myDate[1];
    this.user_data.offset = -1 * (new Date(this.user_data.date)).getTimezoneOffset();
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');
    this.user_data.supplier_id = JSON.parse(localStorage.getItem('user')).partner_id;
    if (this.sharedService.dmsParams.dmsType === 'P') {
      this.appComp.header_title = 'Purchase Payments';
      this.user_data.type = 'P';
    } else {
      this.appComp.header_title = 'Sale Payments';
      this.user_data.type = 'S';
    }

    this.typeList = JSON.parse(localStorage.getItem('all_out_types'));
    this.classType = JSON.parse(localStorage.getItem('all_out_classes'));

    localStorage.setItem('user_data', JSON.stringify(this.user_data));

    dateTimeAdapter.setLocale('en-IN');

    this.columnDefs = [
      { headerName: 'CODE', headerClass: 'myagheader', field: 'code', sortable: true, filter: true, width: 90, resizable: true,lockPosition: true ,},
      { headerName: 'RETAILER', headerClass: 'myagheader', field: 'retailer', sortable: true, filter: true, lockPosition: true ,
        width: 150, resizable: true, },
      { headerName: 'REFERENCE', headerClass: 'myagheader', field: 'ref', sortable: true, filter: true, lockPosition: true ,
        width: 150, resizable: true, },
      { headerName: 'NAME', headerClass: 'myagheader', field: 'payment_name', sortable: true, filter: true, lockPosition: true ,
        width: 90, resizable: true, },
      { headerName: 'SALESMAN', headerClass: 'myagheader', field: 'salesman', sortable: true, filter: true, lockPosition: true ,
        width: 100, resizable: true, },
      { headerName: 'PAYMENT TYPE', headerClass: 'myagheader', field: 'payment_type', sortable: true, filter: true, lockPosition: true ,
        width: 80, resizable: true, },
      { headerName: 'STATUS', headerClass: 'myagheader', field: 'status', sortable: true, filter: true,
        width: 100, resizable: true},
      { headerName: 'TOTAL', headerClass: 'myagheader', field: 'total', sortable: true, filter: true,
        width: 100, resizable: true},
      { headerName: 'COMMENTS', headerClass: 'myagheader', field: 'comments', cellStyle: {textAlign: 'center'},
        sortable: true, filter: true, width: 100, resizable: true},
      { headerName: 'PAYEMENT DATE', headerClass: 'myagheader', field: 'payment_date', sortable: true, filter: true,
        width: 100, resizable: true},
      { headerName: 'BALANCE', headerClass: 'myagheader', field: 'balance', cellStyle: {textAlign: 'right'},
        sortable: true, filter: true,  width: 80, resizable: true},
      { headerName: 'RECEIPT NO', headerClass: 'myagheader', field: 'receipt', cellStyle: {textAlign: 'right'}, sortable: true,
        filter: true,  width: 80, resizable: true},
      { headerName: 'CHEQUE NO', headerClass: 'myagheader', field: 'cheque_no', cellStyle: {textAlign: 'right'}, sortable: true,
        filter: true,  width: 100, resizable: true},
      { headerName: 'CR/DR', headerClass: 'myagheader', field: 'cd', cellStyle: {textAlign: 'right'},
        sortable: true, filter: true,  width: 80, resizable: true},
      { headerName: 'Cr Notes', headerClass: 'myagheader', field: 'config_json', cellStyle: {textAlign: 'right'}, sortable: true,
        filter: true,  width: 120, resizable: true,
        cellRendererSelector(params) {
          if (params.data && params.data.config_json) {
            return {
              component: OpenCreditComponent,
            };
          }
        }}
    ];

    this.columnDefs1 = [
      { headerName: 'DATE', headerClass: 'myagheader', field: 'payment_date', sortable: true, filter: true,
        width: 100, resizable: true,
        cellRenderer: (data) => {
          return data.value ? this.datePipe.transform(data.value, 'yyyy-MM-dd') : '';
        }},
      { headerName: 'RECEIPT', headerClass: 'myagheader', field: 'receipt', sortable: true, filter: true, lockPosition: true ,
        width: 100, resizable: true, },
      { headerName: 'TOTAL', headerClass: 'myagheader', field: 'total', sortable: true, filter: true, lockPosition: true ,
        width: 100, resizable: true, },
      { headerName: 'USED AMOUNT', headerClass: 'myagheader', field: 'amounts', sortable: true, filter: true, lockPosition: true ,
        width: 100, resizable: true,
      },
    ];


    this.gridOptions = { rowHeight: 40,
      rowStyle: { 'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left' },
      rowSelection: 'multiple',
      suppressAggFuncInHeader: true,
      pivotMode: false,
      groupHideOpenParents: true,
      groupDefaultExpanded: 9,
      groupMultiAutoColumn: true,
      autoGroupColumnDef: {
        pinned: 'left'
      },
      statusBar: {
        statusPanels: [
          { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
          { statusPanel: 'agFilteredRowCountComponent' },
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      }
    } as GridOptions;

    this.gridOptions1 = { rowHeight: 40,
      rowStyle: { 'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left' },
      rowSelection: 'multiple',
      suppressAggFuncInHeader: true,
      statusBar: {
        statusPanels: [
          { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
          { statusPanel: 'agFilteredRowCountComponent' },
        ]
      }
    } as GridOptions;


  }

  close() {
    this.isModelOpened = true;
    this.rowData1 = [];
    this.loaded1 = false;
    this.showCrNotes = true;
  }

  cdChange(event) {
    this.user_data.cd = event;
  }

  apiDmsOrderList() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    const rowdata = [];
    const datePipe = this.datePipe;
    this.API_URL = '/api/pwa_connects/dms_payment_list';
    this.apiService.post(this.API_URL, this.user_data)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.rowData = res.results.data;
          this.data = rowdata[0];
          this.context = { componentParent: this };
          this.loaded = true;
          this.preloader = false;
          this.empty_state = (this.rowData.length === 0);
        }
      });
    }

  showAll(config) {
    debugger;
    const cr_note_ids = [];
    let amounts = 0;
    config.forEach(elem => {
      cr_note_ids.push(elem.credit_note_id);
      amounts += parseFloat(elem.amount);
    });
    const orderData = {
      access_token: this.user_data.access_token,
      url: this.user_data.url,
      cr_note_ids,
      amounts
    };

    this.API_URL = '/api/dms_connects/getCreditNoteList';
    this.apiService.post(this.API_URL, orderData)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
          this.rowData1 = res.results.data;
          this.loaded1 = true;
        }
      });
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData);
    this.preloader = false;
    // this.gridApi.setDomLayout("autoHeight");
    window.addEventListener('resize', function() {
      setTimeout(function() {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    if (screen.width > 991) {
      this.gridOptions.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  onGridReady1(params) {
    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData1);
    this.showCrNotes = false;
    // this.gridApi.setDomLayout("autoHeight");
    window.addEventListener('resize', function() {
      setTimeout(function() {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    if (screen.width > 991) {
      this.gridOptions1.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi1.autoSizeColumns();
    }
  }

  newRetailerCreation() {
      $('#retailermasterModal').modal('show');
      this.confirmEnable = true;
  }

  ngOnInit() {

    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable == 'true' ? 'addClass' : 'removeClass']
    (this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {

    this.user_data.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.user_data.end_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    localStorage.setItem('my_date',  this.user_data.date);
    localStorage.setItem('user_data', JSON.stringify(this.user_data));

  }
}
