<div class="maindiv">
  <h2 class="approveheading">{{title}}</h2>
  <span class="detail">{{message1}}</span>

  <div class="custom-input">
    <label>SETTLE AMOUNT</label>
    <input type="number" class="form-control" [(ngModel)]="amt_settled"/>
  </div>

  <div style="margin-top:20px;">
    <span class="commentlabel">Comments</span>
    <div>
      <textarea
        class="forminput"
        style="height: 90px; width: 100%;"
        type="text"
        name="comment"
        #comment="ngModel"
        [(ngModel)]="comments"
        required
      > </textarea>
      <div *ngIf="comment.errors && (comment.dirty || comment.touched)">
        <span *ngIf="comment.errors.required" class="text-danger">Please Enter Comments</span>
      </div>
    </div>
    <div style="float: right;  margin-bottom: 20px; margin-top: 20px;">
      <button
        mat-button
        style=" margin-right:15px;"
        class="btn grey-btn"
        (click)="closeDialog()"
      >
        Cancel
      </button>
      <button
        mat-button
        class="btn confirm-green-btn"
        (click)="submitClick()"
        [disabled]="!comments"
      >
        {{buttonText}}
      </button>
    </div>
  </div>
</div>
