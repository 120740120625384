<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" style="float: right;background-color:#FDE0D4;">
        <i class="fas fa-times"></i>
      </button>
      <h2>Create Beat Plan</h2>
    </div>
    <div class="body-section">
      <div>
        <form novalidate  id="ngForm" #beatplanform="ngForm">
          <div style="margin-top: 15px;">
            <label >NAME</label>
            <div style="margin-top: 5px;">
                <input
                  class="forminput"
                  type="text"
                  name="name"
                  [(ngModel)]="beatPlanData.name"
                  #name="ngModel"
                  style="width: 90%;"
                  required
                >
                <div *ngIf="name.errors && (name.dirty || name.touched)">
                  <span *ngIf="name.errors.required" class="text-danger">Name is required</span>
                </div>
            </div>
          </div>
          <div style="margin-top: 15px;">
            <label >CODE</label>
            <div style="margin-top: 5px;">
              <input
                class="forminput"
                type="text"
                name="code"
                [(ngModel)]="beatPlanData.code"
                #code="ngModel"
                style="width: 90%;"
                required
              >
              <div *ngIf="code.errors && (code.dirty || code.touched)">
                <span *ngIf="code.errors.required" class="text-danger">Code is required</span>
              </div>
            </div>
          </div>
          <div style="margin-top: 15px;">
            <label >TERRITORY</label>
            <div style="margin-top: 5px;" class="formselect">
              <select
                name="territory_id"
                [(ngModel)]="beatPlanData.territory_id"
                #territory_id="ngModel"
                style="width: 80%;">
                <option
                  *ngFor="let item of territoriesList"
                  value="{{ item.id }}"
                > {{ item.itemname }}</option>
              </select>
            </div>
          </div>
          <div style="margin-top: 15px;">
            <label >SALESMAN</label>
            <div style="margin-top: 5px;" class="formselect">
              <select
                name="salesman_id"
                [(ngModel)]="beatPlanData.salesman_id"
                #salesman_id="ngModel"
                style="width: 80%;">
                <option
                  *ngFor="let item of salesmenList"
                  value="{{ item.id }}"
                > {{ item.itemname }}</option>
              </select>
            </div>
          </div>
          <div style="margin-top: 15px;">
            <label>TRAVEL TYPE</label>
            <div style="margin-top: 5px;">
              <div  class="formselect" >
                <select
                  name="travel_type_id"
                  [(ngModel)]="beatPlanData.travel_type_id"
                  required
                  #travel_type_id="ngModel"
                  style="width: 50%;"
                >
                  <option
                    *ngFor="let item of beatTravelTypes"
                    value="{{ item.id }}"
                  >
                    {{ item.itemName }}
                  </option>
                </select>

                <!--  <p *ngIf="!edit && questionData.question_type!=''">{{questionData.question_type}}</p>
                  <p *ngIf="!edit  && !questionData.question_type!=''">NA</p>-->
              </div>
            </div>
          </div>
          <div style="margin-top: 15px;">
            <label>SCHEDULE TYPE</label>
            <div style="margin-top: 5px;">
              <div  class="formselect" >
                <select
                  name="schdeule_type_id"
                  [(ngModel)]="beatPlanData.schedule_type"
                  (change) = "onChangescheduletype()"
                  #schedule_type="ngModel"
                  style="width: 50%;"
                >
                  <option
                    *ngFor="let item of schedule_type_list"
                    value="{{ item }}"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div style="margin-top:15px;">
            <div>
              <label>Comments</label>
              <div   style="height: 70px;">
                    <textarea
                      class="forminput"
                      style="height: 70px;"
                      type="text"
                      name="Config"
                      #schedule_config="ngModel"
                      [(ngModel)]="beatPlanData.schedule_config"
                    ></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="button-row" style="margin-top: 10px;" >
        <button
          mat-button
          style=" vertical-align:bottom;margin-top: 20px; margin-right: 15px;"
          class="btn teal-btn"
          (click)="createBeatPlan()"
          form="ngForm"
          [disabled]="beatplanform.form.invalid"
        >
          SAVE
        </button>
        <span (click)="closeDialog()">
           <a class="btn white-btn">DISCARD</a>
        </span>
      </div>
    </div>
  </div>
</section>
