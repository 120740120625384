<div style="margin-top: -15px;">
  <span style="color:#17A2B8;">{{searchableFilter.name}} </span>
</div>

<div id="custfilter" style="width:250px;">
  <ng-select [items]="searchableFilter.values"
             class="{{containerStyle}}"
             bindLabel= "{{searchableFilter.bindLabel}}"
             bindValue= "{{searchableFilter.bindValue}}"
             (ngModelChange)="searchableFilterChangeEvent($event)"
             [(ngModel)]="searchableFilter.value">
    >
  </ng-select>

</div>
