import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class BeatRetailerReportComponent extends ReportBase implements IReport, OnInit {

  showCustomFilter = true;
  customFilter: any = {
    name: 'Active',
    key: 'active',
    value: 'true',
    values: [],
    show: true
  };

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Beat Retailer Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.state = true;
    this.showableFilters.region = true;
    this.showDate = false;
    this.customFilter.values = [
      {id: '', itemName: 'All'},
      {id: 'true', itemName: 'Active'},
      {id: 'false', itemName: 'Inactive'}
    ];

    this.loadInBatches(25000, 'rank');
    this.showProgressCustomText = true;

    this.configureGrid();
      }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/beatRetailerReport';

    this.columnDefs = [
      {
        headerName: 'ID', field: 'id', width: 100, pinned: 'left'
      },
      {
        headerName: 'Name', field: 'name', width: 250, pinned: 'left'
      },
      {
        headerName: "Code", field: "code", width: 150, pinned: 'left'
      },
      {
        headerName: "Zone", field: "zone", width: 150, resizable: true
      },
      {
        headerName: "Area", field: "area", width: 150, resizable: true
      },
      {
        headerName: "Mobile", field: "mobile", width: 150, resizable: true
      },
      {
        headerName: "Type", field: "type", width: 150, resizable: true
      },
      {
        headerName: "MSL Town Tier", field: "program_type", width: 150, resizable: true
      },
      {
        headerName: "State", field: "state", width: 150, resizable: true
      },
      {
        headerName: "Created By", field: "created_by", width: 150, resizable: true
      },
      {
        headerName: "Beat", field: "beat", width: 150,
        valueGetter(params) {
          if (params.data.beat) {
            const beats = [...new Set(params.data.beat.map(item => item.bp))];
            return beats.join();
          } else {
            return params.data.beat;
          }
        }
      },
      {
        headerName: "Salesman", field: "salesman", width: 150,
        valueGetter(params) {
          if (params.data.beat) {
            const salesman = [...new Set(params.data.beat.map(item => item.sm))];
            return salesman.join();
          } else {
            return params.data.beat;
          }
        }
      },
      {
        headerName: "Create Date", field: "create_date", width: 150
      },
      {headerName: 'Active', field: 'active', enableRowGroup: true, width: 80},
      {headerName: 'OTP Verified', field: 'otp_verified', enableRowGroup: true, width: 80},
      {
        headerName: "Gstin", field: "gst_id", width: 150
      },
      {
        headerName: "Credit Limit", field: "credit_limit", width: 150
      },
      {
        headerName: "Outstanding", field: "outstanding", width: 150, cellRenderer: 'outstandingShowRenderer'
      },
      {
        headerName: 'Credits', field: 'credits', filter: true
      },
      {headerName: "id", field: "id", hide: true},
      {headerName: "user_id", field: "user_id", hide: true},
    ];

  }

}
