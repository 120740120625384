import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-outlet-last-visit-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class DistributorAdoptionReportComponent extends ReportBase implements IReport, OnInit {

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Distributor Adoption Report');
    this.showPinnedFooter = true;
    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_v2_reports/dist_adoption_report';

    this.columnDefs = [
      {headerName: 'Distributor', field: 'name',  width: 80},
      {headerName: 'Orders Created', field: 'created_sales', cellStyle: {textAlign: 'right'}, aggFunc: 'sum', width: 80},
      {headerName: 'Orders Accepted', field: 'confirmed_sales', cellStyle: {textAlign: 'right'}, aggFunc: 'sum',  width: 80},
      {headerName: 'Orders Invoiced', field: 'invoiced_sales', cellStyle: {textAlign: 'right'}, aggFunc: 'sum',  width: 80},
      {headerName: 'Orders Delivered', field: 'delivered_sales', cellStyle: {textAlign: 'right'}, aggFunc: 'sum',  width: 80},
      {headerName: 'Purchase Orders Created', field: 'created_purchase', cellStyle: {textAlign: 'right'}, aggFunc: 'sum',  width: 100},
      {headerName: 'GRNs Created', field: 'invoiced_purchase', cellStyle: {textAlign: 'right'}, aggFunc: 'sum',  width: 80},
      {headerName: 'Credit Notes Created', field: 'c_note', cellStyle: {textAlign: 'right'}, aggFunc: 'sum',  width: 100},
      {headerName: 'Claims Generated', field: 'claims', cellStyle: {textAlign: 'right'}, aggFunc: 'sum',  width: 100},
      {headerName: 'Payments Accepted', field: 'pay', cellStyle: {textAlign: 'right'}, aggFunc: 'sum',  width: 100},
      {headerName: 'Counter Sale Orders Created', cellStyle: {textAlign: 'right'}, aggFunc: 'sum', field: 'created_cs',  width: 80}
    ];
  }

  postProcessData() {
    this.configureData();
  }

  configureData() {
    this.rowData = [];
    if (this.apiData && this.apiData.master && this.apiData.master.length > 0) {
      this.apiData.master.forEach(master => {
        let indrow: any = {};
        let saleRow: any = {};
        let purchaseRow: any = {};
        let cnRow: any = {};
        let claimRow: any = {};
        let payRow: any = {};
        let csRow: any = {};
        if (this.apiData.sales && this.apiData.sales.length > 0) {
          saleRow = this.apiData.sales.find(x => x.distributor_id === master.distributor_id);
        }
        if (this.apiData.purchase && this.apiData.purchase.length > 0) {
          purchaseRow = this.apiData.purchase.find(x => x.partner_id === master.distributor_id);
        }
        if (this.apiData.cn && this.apiData.cn.length > 0) {
          cnRow = this.apiData.cn.find(x => x.supplier_id === master.distributor_id);
        }
        if (this.apiData.claim && this.apiData.claim.length > 0) {
          claimRow = this.apiData.claim.find(x => x.supplier_id === master.distributor_id);
        }
        if (this.apiData.cs && this.apiData.cs.length > 0) {
          csRow = this.apiData.cs.find(x => x.distributor_id === master.distributor_id);
        }
        if (this.apiData.pay && this.apiData.pay.length > 0) {
          payRow = this.apiData.pay.find(x => x.distributor_id === master.distributor_id);
        }
        indrow = {
          ...master,
          ...saleRow,
          ...purchaseRow,
          ...cnRow,
          ...claimRow,
          ...csRow,
          ...payRow
        };
        this.rowData.push(indrow);
      });
    }
  }
}
