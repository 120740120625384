<div class="header">
  <div class="header-img">
    <span (click)="siderCollapse()" id="sidebarCollapse">
      <svg
        width="18px"
        height="14px"
        viewBox="0 0 18 14"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="LoadOut_cash-v2"
            transform="translate(-31.000000, -33.000000)"
            fill="#183153"
          >
            <g id="Component/Navigation/Left-Bar">
              <path
                d="M31,33 L49,33 L49,35 L31,35 L31,33 Z M31,39 L49,39 L49,41 L31,41 L31,39 Z M31,45 L42,45 L42,47 L31,47 L31,45 Z"
                id="Menu-Icon"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </span>
  </div>
  <div class="header-content">
    <div class="header-text">
      <h1>{{_title}}</h1>
    </div>
    <div class="header-profile">
      <ul style=" display:flex !important;padding-top: 0px;margin-top: 0px;" class="nav">
       <!-- <li class="nav-item">
          <a style=" padding-top: 0px;margin-top: 0px;" class="nav-link" routerLinkActive="selected" routerLink="/dashboard">
           &lt;!&ndash; <span title="Dashboard">
              <img  src="./../../../assets/dasboard_grey.svg" />
                &lt;!&ndash; <small style="display:block!important;text-align: center">Dashboard</small>&ndash;&gt;
            </span>
            <label  style="display:block;text-align: center">Dashboard</label>&ndash;&gt;
            <div class="salesdiary-nav-item">
            <mat-icon>dashboard</mat-icon>
            <p>Dashboard</p>
            </div>
          </a>
        </li>
        <li class="nav-item">
          <a style=" padding-top: 0px;margin-top: 0px;" class="nav-link" routerLinkActive="selected" routerLink="/reports/reportmain">
            <div class="salesdiary-nav-item">
            <mat-icon>poll</mat-icon>
            <p>Reports</p>
            </div>
          </a>
        </li>-->
      <!--  <li class="nav-item">
          <a class="logo-sec">
            <img class="client-logo" src={{company_logo}} />
          </a>
        </li>-->
        <li>
         <!-- <a class="logo-sec" style=" padding-top: 0px;margin-top: 0px;">
            <img class="client-logo" src={{company_logo}} />
          </a>-->
          <div style=" border: 3px solid rgba(46, 91, 255, 0.08);
  border-radius: 6px; padding: 0.3vw;" id="main_logo">
          <a style="margin: 3px;display: inline" >

                <img style="width:18vh;" src={{company_logo}}/>

          </a>
            <a style="margin: 3px;display: inline" (click)="profileClick()">
            <!-- <span style=" background-color: #f4f6fc;">-->
                <img style="width:32px;" src="../../assets/menu/profile.svg"/>
           <!--  </span>-->
            </a>
          </div>
          <div  id="mobile_logo">
            <a style="margin: 3px;display: inline" (click)="profileClick()">
              <!-- <span style=" background-color: #f4f6fc;">-->
              <img style="width:32px;" src="../../assets/images/person.svg"/>
              <!--  </span>-->
            </a>
          </div>

        </li>
      <!--  <li class="nav-item">
          <a class="nav-link" routerLinkActive="selected" routerLink="/dashboard">
              <span >
              <img  src="./../../../assets/dasboard_grey.svg" />
               <small style="display:block!important;text-align: center">Dashboard</small>
            </span>
            <label >Dashboard</label>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="selected" routerLink="/reports/reportmain">
             <span >
              <img  src="./../../../assets/report_grey.svg" />
           <small style="display:block!important;text-align: center">Reports</small>
            </span>
            <label >Reports</label>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link disabled" href="#">Disabled</a>
        </li>-->
      </ul>


    </div>
  </div>
</div>
