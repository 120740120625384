import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { GridOptions } from "../../../node_modules/@ag-grid-enterprise/all-modules";
import { ApiService} from "../_services/api.service";
import { Router, ActivatedRoute  } from '@angular/router';
import { ColorCellRenderer } from "../colorCell.component";
import { DatePipe } from '@angular/common';
import { LoadingstateComponent} from "../components/loadingstate/loadingstate.component"
import {SharedService} from "../_services/shared_service";

@Component({
  selector: 'app-loadin-report',
  templateUrl: './pricelist-report.component.html',
  styleUrls: ['./pricelist-report.component.scss']
})
export class PricelistReportComponent implements OnInit {

  user;
  res_data;
  user_data = {
    "date":null,
    "access_token": null,
    "url": null,
    "last_date": null
  };

  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public searchValue;
  private pinnedBottomRowData;
  private pinnedTottomRowData;
  public frameworkComponents;
  public rowData = [];
  public navigate;
  public defaultColDef;
  public sideBar;
  private rowHeight;
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public title_name: string = "LOADIN REPORT";
  public styleGrid;
  public myDate = [];
  public collapse_disable;
  public report_flag;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef:ElementRef,
              private sharedService: SharedService,
              private renderer: Renderer2,
              private datePipe: DatePipe) {

    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');
    this.report_flag = this.route.snapshot.params["report_flag"];

    localStorage.setItem('user_data', JSON.stringify(this.user_data));

    this.pricelistreport();

    this.gridOptions = <GridOptions> {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      enableRangeSelection: true,
      enableCharts: true,
      rowGroupPanelShow: 'always',
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      defaultColDef: {
        headerClass: 'myagheader',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true
      },
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      }
    };

  }

  pricelistreport() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];
    let rowdata = [];
    let loop_data = [];
    let total = 0;


    this.res_data = this.apiService.pricelistReport(this.user_data);
    this.res_data.subscribe(res => {
        console.log(res);
        let inthis = this;
        if (res.hasOwnProperty('results') && (res['results'].status == 200) && (res['results'].data.length > 0)) {
          res['results'].data.forEach(function (data) {
            if (data.before_tax != null) data.before_tax = data.before_tax.toFixed(3);
            if (data.margin_perc != null) data.margin_perc = data.margin_perc.toFixed(3);
            if (data.ptr != null) data.ptr = data.ptr.toFixed(3);
              var pushData = {
                "DC": data.dc,
                "Pricelist": data.pricelist,
                "Product": data.product,
                "Code": data.code,
                "MRP": data.mrp,
                "Margin": data.margin_perc,
                "PTR": data.ptr,
                "Tax": data.tax,
                "Before Tax": data.before_tax
              };
              rowdata.push(pushData);

          });
          this.data = rowdata[0];
          this.rowData = rowdata;

          for (var key in this.data) {
            if (this.data.hasOwnProperty(key)) {
              if (key == 'DC' || key == 'Pricelist' || key == 'Product') {
                this.columnDefs.push({ headerName: key, field: key, sortable: true, filter: true, lockPosition: true });
              }else {
                this.columnDefs.push({ headerName: key, field: key, sortable: true, filter: true, lockPosition: true, cellStyle: {textAlign: "right"} });
              }
            }
          }
          this.loaded = true;

        }else {
          this.preloader = false;
          this.empty_state = true;
        }

      },
      error => {
        console.log(error);
        this.preloader = false;
      });
  }


  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData);
    this.preloader = false;
    // this.gridApi.setDomLayout("autoHeight");
    window.addEventListener('resize', function() {
      setTimeout(function() {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        }else {
          params.columnApi.autoSizeColumns();
        }
      });
    });

    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    }else {
      params.columnApi.autoSizeColumns();
    }

    // this.gridColumnApi.autoSizeColumns();


    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  ngOnInit() {
    this.sharedService.setHeader('PriceList Report');
    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable == "true" ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {

    this.user_data.date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.user_data.last_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    localStorage.setItem('user_data', JSON.stringify(this.user_data));

    this.pricelistreport();
  }
}
