import {Component, OnInit, ElementRef, Renderer2, ViewChild} from '@angular/core';
import { GridOptions } from "../../../node_modules/@ag-grid-enterprise/all-modules";
import { ApiService} from "../_services/api.service";
import { Router } from '@angular/router';
import { ColorCellRenderer } from "../colorCell.component";
import { DatePipe } from '@angular/common';
import { LoadingstateComponent} from "../components/loadingstate/loadingstate.component";
import { DateTimeAdapter } from 'ng-pick-datetime';
import {ChartType} from "@ag-grid-enterprise/all-modules";
import {SharedService} from "../_services/shared_service";

@Component({
  selector: 'app-comm-dash',
  templateUrl: './common-dashboard.component.html',
  styleUrls: ['./common-dashboard.component.scss']
})
export class CommonDashboardComponent implements OnInit {
  @ViewChild('chart1') chart1: ElementRef<HTMLElement>;
  @ViewChild('chart2') chart2: ElementRef<HTMLElement>;

  user;
  res_data;
  user_data = {
      "date":null,
      "settlement_uid": null,
      "access_token": null,
      "url": null,
      "offset": null
  };

  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public searchValue;
  private pinnedBottomRowData;
  private pinnedTottomRowData;
  public frameworkComponents;
  public rowData = [];
  public navigate;
  public defaultColDef;
  public sideBar;
  private rowHeight;
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public title_name: string = "DC LIST";
  public styleGrid;
  public myDate;
  public collapse_disable;
  public cx: ChartType;

  constructor(private apiService: ApiService,
              private router: Router,
              private elRef:ElementRef,
              private renderer: Renderer2,
              private sharedService: SharedService,
              private datePipe: DatePipe,
              private dateTimeAdapter: DateTimeAdapter<any>) {

    this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.user_data.date = localStorage.getItem('my_date');
    if (this.user_data.date == null || this.user_data.date == "null") {
      this.user_data.date = this.myDate;
      localStorage.setItem('my_date', this.myDate);
    }else {
      this.myDate = this.user_data.date;
    }
    this.user_data.offset = -1 * (new Date(this.user_data.date)).getTimezoneOffset();
    this.user_data.settlement_uid = JSON.parse(localStorage.getItem('user')).id;
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');

    localStorage.setItem('user_data', JSON.stringify(this.user_data));

    dateTimeAdapter.setLocale('en-IN');

    this.apiDcList();

    this.gridOptions = <GridOptions>{ rowHeight: 75,
                                      rowStyle: { 'text-align': 'left', 'padding': '20px 0px' },
                                      rowSelection: 'single',
                                      enableCharts: true
                                    };

  }

  apiDcList() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];
    let rowdata = [];


    this.res_data = this.apiService.dashCommon(this.user_data);
    this.res_data.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status == 200) && (res['results'].data.length > 0)) {
          res['results'].data.forEach(function (data) {

            // let sale_perc = (((parseInt(data.sale)-parseInt(data.yest_sale))/parseInt(data.yest_sale)) * 100).toFixed(this.apiService.decimalPolicy);
            // if (parseInt(sale_perc,10) > 0) sale_perc = '+' + sale_perc;
            // let mtd_perc = (((parseInt(data.mtd_sale)-parseInt(data.lmtd_sale))/parseInt(data.lmtd_sale)) * 100).toFixed(this.apiService.decimalPolicy);
            // if (parseInt(mtd_perc,10) > 0) mtd_perc = '+' + mtd_perc;

            // if (parseFloat(sale_perc) > 0 || isNaN(parseFloat(sale_perc))) {
            //   sale_perc = "0";
            // }
            // if (parseFloat(mtd_perc) > 0 || isNaN(parseFloat(mtd_perc))) {
            //   mtd_perc = "0";
            // }
            var pushData = {
              "VAN": data.van,
              "DC": data.dc,
              "BEAT": data.beat,
              "TARGET": parseInt(data.target,10),
              "SALE": parseInt(data.sale,10),
              "SALE QTY": parseInt(data.sale_qty,10),
              "DAMAGE QTY": parseInt(data.dmg_qty,10)

            };
            rowdata.push(pushData);
          });
          this.data = rowdata[0];
          this.rowData = rowdata;

          for (var key in this.data) {
            if (this.data.hasOwnProperty(key)) {
              if (key == 'SALE' || key == 'TARGET' || key == 'SALE QTY' || key == 'DAMAGE QTY') {
                this.columnDefs.push({
                  headerName: key, field: key, sortable: true, filter: true, resizable: true,width:100,
                  type: 'numericColumn', aggFunc: 'sum',enableValue: true
                });
              }else if (key == 'DC' || key == 'VAN' || key == 'BEAT') {
                this.columnDefs.push({
                  headerName: key, field: key, sortable: true, filter: true, resizable: true,width:120,
                  chartDataType: 'category'
                });
              }
            }
          }
          this.loaded = true;

          this.frameworkComponents = {
            colorCellRenderer: ColorCellRenderer
          };

        }else {
          this.preloader = false;
          this.empty_state = true;
        }

      },
      error => {
        console.log(error);
        this.preloader = false;
      });
  }

  onChart1() {
    let el: HTMLElement = this.chart1.nativeElement;
    this.cx = <ChartType>'groupedColumn';
    let chart_params = {
      cellRange: {
        columns: ['VAN', 'DC', 'BEAT', 'TARGET', 'SALE'],
        rowStartIndex: null,
        rowEndIndex: null
      },
      chartType: this.cx,
      aggFunc: 'sum',
      chartContainer: el,
      suppressChartRanges: true,
      chartThemeName: 'ag-pastel-dark'
    };

    this.gridOptions.api.createRangeChart(chart_params);
  }

  onChart2() {
    let el2: HTMLElement = this.chart2.nativeElement;
    this.cx = <ChartType>'groupedColumn';
    let chart_params = {
      cellRange: {
        columns: ['VAN', 'DC', 'BEAT', 'SALE QTY', 'DAMAGE QTY'],
        rowStartIndex: null,
        rowEndIndex: null
      },
      chartType: this.cx,
      aggFunc: 'sum',
      chartContainer: el2,
      suppressChartRanges: true,
      chartThemeName: 'ag-pastel-dark'
    };

    this.gridOptions.api.createRangeChart(chart_params);
  }


  onGridReady(params) {

    params.api.setRowData(this.rowData);
    this.preloader = false;
    this.onChart1();
    this.onChart2();
  }

  ngOnInit() {
    this.sharedService.setHeader('Common Dashboard');
    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable == "true" ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {

    this.user_data.date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    localStorage.setItem('my_date',  this.user_data.date);
    localStorage.setItem('user_data', JSON.stringify(this.user_data));

    this.apiDcList();
  }
}
