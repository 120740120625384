import {Component, OnInit} from "@angular/core";
import {PageBase} from "../pagebase/pagebase";
import {MatDialogConfig} from "@angular/material/dialog";
import {ImageGalleryPopupComponent} from "../components/image_gallery_popup/image_gallery_popup.component";

@Component({
  selector: 'app-asset-servie-ticket-component',
  templateUrl: '../pagebase/pagebasev2.html',
  styleUrls: ['../pagebase/pagebase.scss']
})
export class AssetServiceTicketComponent extends PageBase implements OnInit {

  showCustomFilter = true;
  customFilter: any = {
    name: 'Status',
    key: 'status',
    value: '',
    values: [],
    show: true
  };
  role_features = [];
  uqAttributes: any = [];

  ngOnInit() {
    this.sharedService.setHeader('Asset Service Ticket');
    this.showableFilters.region = true;
    this.showableFilters.cluster = true;
    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.outlet_type = true;
    this.customFilter.values = [
      {id: "", itemName: "All Tickets"},
      {id: "unassigned", itemName: "Unassigned"},
      {id: "assigned", itemName: "Assigned"},
      {id: "inprogress", itemName: "In Progress"},
      {id: "resolved", itemName: "Resolved"},
      {id: "rejected", itemName: "Rejected"}
    ];
    this.container = 'partner_asset';
    this.API_URL = '/api/service_requests/getServiceTicket';

    this.loadServerData();
  }

  configureGrid() {
    this.columnDefs = [
      {
        headerName: "SERVICE",
        children: [
          {headerName: 'ID', field: 'id',  width: 80},
          {headerName: 'PRIORITY', field: 'priority',  width: 80},
          {headerName: 'SERVICE NO', field: 'serviceno',  width: 50},
          {headerName: 'REQ DATE', field: 'requested_date',  width: 50},
          {headerName: 'STATUS', field: 'service_status',  width: 50},
          {headerName: 'TYPE', field: 'service_type',   width: 80},
          {headerName: 'PURCHASED FROM', field: 'purchased_from',  width: 80},
          {headerName: 'DISTRIBUTOR', field: 'dist_name',  width: 80},
          {headerName: 'SOURCE', field: 'source',  width: 80},
          {headerName: 'REJECT COMMENTS', field: 'reject_comments',  width: 80}
        ]
      }, {
        headerName: "ENGINEER",
        children: [
          {headerName: 'NAME', field: 'service_engineer',  width: 80},
          {headerName: 'TEAM', field: 'team',  width: 80},
          {headerName: 'MOBILE', field: 'assigned_mobile',  width: 80},
          {headerName: 'REGION', field: 'region',  width: 80},
          {headerName: 'STATE', field: 'state',  width: 80}
        ]
      }, {
        headerName: "ASSIGNED",
        children: [
          {headerName: 'DATE', field: 'assigned_on',  width: 80},
          {headerName: 'USER', field: 'assigned_user',  width: 80},
          {headerName: 'MOBILE', field: 'assigned_mobile',  width: 80},
        ]
      }, {
        headerName: "REFERRER",
        children: [
          {headerName: 'TYPE', field: 'referrer_type',  width: 80},
          {headerName: 'NAME', field: 'referrer_name',  width: 80},
          {headerName: 'Mobile', field: 'referrer_mobile',  width: 80},
        ]
      }, {
        headerName: "CUSTOMER",
        children: [
          {headerName: 'NAME', field: 'customer_name',  width: 80},
          {headerName: 'MOBILE', field: 'customer_mobile',  width: 50},
          {headerName: 'ADDRESS', field: 'address',  hide: true, width: 80},
          {headerName: 'CITY', field: 'city',  width: 80}
        ]
      }, {
        headerName: "ASSET",
        children: [
          {headerName: 'NAME', field: 'asset_name',  width: 80},
          {headerName: 'MASTER', field: 'asset_master',  width: 50},
          {headerName: 'BAR CODE', field: 'barcode',  hide: true, width: 80},
          {headerName: "Batch ID", field: "batch_id", width: 100},
          {headerName: "Batch No", field: "asset_name", width: 100, hide: true},
          {headerName: 'INSTALLED ON', field: 'installed_date',  width: 80},
          {
            headerName: "IMAGE", headerClass: 'myagheader', field: "image", width: 50, valueGetter: this.generateGalleryImageUrl('gallery'),
            cellRenderer(params) {
              if (!params.data.image_count || parseInt(params.data.image_count, 10) === 0) {
                return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
              } else {
                return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img><span>(' + params.data.image_count + ')</span>';
              }
            }
          },
        ]
      }, {
        headerName: "CLOSURE",
        children: [
          {headerName: 'DATE', field: 'service_closed',  width: 80},
          {headerName: 'IMAGES', field: 'image_count',  width: 80},
          {headerName: 'TAT(DAYS)', field: 'closing_days',  width: 50},
          {headerName: 'DELAY FROM', field: 'delayed_on',  width: 80},
          {headerName: 'DELAY REASON', field: 'delayed_reason',  hide: true, width: 80},
          {headerName: 'OTP VERIFIED', field: 'otp_verified',  width: 80},
          {headerName: 'FSR REPORT', field: 'fsr_report',  width: 80},
          {headerName: 'SE COMMENTS', field: 'se_comments',  width: 80},
          {headerName: 'CUSTOMER COMMENTS', field: 'customer_comments',  width: 80},
          {headerName: 'CLOSING REASON', field: 'closing_reason',  width: 80},
          {headerName: 'AMOUNT', field: 'service_amt',  width: 80},
          {headerName: 'RCA', field: 'rca',  width: 80},
          {headerName: 'PREVENTION', field: 'prevention',  width: 80},
          {headerName: 'CORRECTION', field: 'correction',  width: 80},
        ]
      },
    ];
  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }

  configureData() {
    this.rowData = [];
    this.uqAttributes = new Set();
    this.apiData.forEach(obj => {
      obj['deployed'] = false;
      if (obj.config_json !== null && obj.config_json !== undefined) {
        for (const [key, value] of Object.entries(obj.config_json)) {
          if (key === 'attribute') {
            for (const [a_key, attribute] of Object.entries(value)) {
              this.uqAttributes.add(a_key);
              obj['attr_' + a_key] = attribute;
            }
          } else {
            this.uqAttributes.add(key);
            obj['attr_' + key] = value;
          }
        }
      }
    });
    this.rowData = this.apiData;
    this.filteredData = this.rowData;
  }
  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    const filteredRowData = this.gridApi.rowModel.rowsToDisplay;
    const filteredRows = [];
    if (column === 'image') {
      if (rows[0].image_count && parseInt(rows[0].image_count, 10) > 0) {
        this.showImagePopup(rows[0]);
      }
    } else {
      for (const key in filteredRowData) {
        filteredRows.push(filteredRowData[key].data);
      }
      if (filteredRows !== null && filteredRows.length > 0) {
        this.storeAssetData(filteredRows);
      }
      localStorage.setItem('id', rows[0].id);
      const index = JSON.parse(localStorage.getItem("asset_ids")).indexOf(rows[0].id);
      localStorage.setItem('index', index);
      this.router.navigate([this.apiService.redirect_url + '/assets/service_ticket/details'], {
        state: {
          viewtype: 'detail',
          id: rows[0].id
        }
      });
    }
  }

  storeAssetData(data) {
    const ids = [];
    for (const key in data) {
      ids.push(data[key].id);
    }
    localStorage.setItem('asset_ids', JSON.stringify(ids));
  }

  showImagePopup(row) {
    const gallery = [];
    row.gallery.forEach(image => {
      gallery.push({
        store_fname: image,
        container: 'asset_gallery'
      });
    });
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "70%",
        maxHeight: "95%",
        data: {
          gallery
        }
      };

      const creatediaeref = this.dialog.open(ImageGalleryPopupComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        data: {
          gallery
        }
      };

      const creatediaeref = this.dialog.open(ImageGalleryPopupComponent, config);
    }
  }
}
