<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper" style="margin-top: 50px!important">
      <div style="background-color: white;margin-left: 5px;margin-right: 25px;height:650px;margin-top: 0px;">
        <div style="margin:10px;">
          <!--<div class="row" style="margin-top: 10px;">
            <div *ngIf="edit" class="button-row" style="margin-top: 10px;">
              <button
                mat-button
                style="margin-right:15px;"
                class="btn teal-btn"
                (click)="editClick()"
              >
                SAVE
              </button>
              <span>
               <a class="btn white-btn">DISCARD</a>
               </span>
            </div>
            <div *ngIf="!edit" class="button-row" style="margin-top: 10px;">
              <span>
               <a class="btn white-btn" style="margin-right:15px;" (click)="editClick()">EDIT</a>
               </span>
              <span>
               <a class="btn white-btn">DELETE</a>
               </span>
            </div>
            <div *ngIf="!edit" class="button-row" style="position: absolute;  right: 30px;margin-top: 10px; ">
              <div style="width: 350px;">
                <progress max="100" value="66" data-label="Progress 2/3"></progress>
              </div>
              <button
                mat-button
                style="margin-left:15px; margin-right:15px;"
                class="btn teal-btn"
              >
                PUBLISH
              </button>
            </div>
          </div>-->
          <div >
            <div>
              <div class="search-list" style="margin-top: 0px;">
                <div class="col-md-6 col-12 nopadding pl-0" style="margin-bottom: 50px;margin-top: 0px;">
                  <div class="row" style="margin-top: 10px;">
                    <div *ngIf="edit" class="button-row" style="margin-top: 10px;">
                      <button
                        mat-button
                        style="margin-right:15px;"
                        class="btn teal-btn"
                        form="ngForm"
                        (click)="updateMarketIntelligence()"
                      >
                        SAVE
                      </button>
                      <span>
                         <a class="btn white-btn" (click)="discardClick()">DISCARD</a>
                      </span>
                    </div>
                    <div *ngIf="!edit" class="button-row" style="margin-top: 10px;">
                      <span>
                         <a class="btn white-btn" style="margin-right:15px;" (click)="editClick()">EDIT</a>
                      </span>
                      <span>
                        <a class="btn white-btn">DELETE</a>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-12 pad0 text-right" style="margin-bottom: 50px;margin-top: 10px;">
                  <div class="filters">
                    <ul class="list-unstyled">
                      <li class="margLR" style="margin-right: 20px;margin-top: 10px;">
                        <span *ngIf="!edit">
                         <a class="btn teal-border-btn" style="margin-right:15px;" (click)="editClick()">VIEW REPORT</a>
                        </span>
                      </li>
                      <li class="margLR" style="margin-right: 20px;">
                        <div style="height:12px;margin-top: 10px;">
                          <span class="scorespan">COMPLETED MTD</span>
                        </div>
                        <div>
                          <!-- <span class="scorepercentspanred">{{assessmentDetails.completed}}</span>-->
                          <span *ngIf="assessmentDetails.score>30" class="scorepercentspangreen">{{assessmentDetails.completed}}</span>
                          <span *ngIf="assessmentDetails.score<=30" class="scorepercentspanred">{{assessmentDetails.completed}}</span>
                        </div>
                      </li>
                      <li class="margLR">
                        <div style="height:12px;margin-top: 10px;">
                          <span class="scorespan">SCORE</span>
                          <img
                            *ngIf="assessmentDetails.score>30"
                            style="margin-bottom: 8px;"
                            src="./../../../assets/thumbsup.svg" />
                          <img
                            *ngIf="assessmentDetails.score<=30"
                            src="./../../../assets/thumbsdown.svg" />
                        </div>
                        <div>
                          <!--<span class="scorepercentspanred">{{assessmentDetails.score}}</span>-->
                          <span *ngIf="assessmentDetails.score>30" class="scorepercentspangreen">{{assessmentDetails.score}}%</span>
                          <span *ngIf="assessmentDetails.score<=30" class="scorepercentspanred">{{assessmentDetails.score}}%</span>

                        </div>
                        <!-- <div>
                           <span class="scorediffspan">+10%</span>
                         </div>-->
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 pad0">
              <form novalidate (ngSubmit)="updateMarketIntelligence()" id="ngForm" #taskform="ngForm">
                <div class="col-md-2 pl-0 pr-20">
                  <label style="color:#7D7D7D">CODE</label>
                  <div style="margin-top: 5px;">
                    <!-- <div  *ngIf="edit">
                       <input
                         hidden=true
                         class="forminput"
                         style="width: 80%;"
                         name="mi_code"
                         [(ngModel)]="assessmentDetails.code"
                         #mi_code="ngModel"
                         required
                       >
                       <div *ngIf="mi_code.errors && (mi_code.dirty || mi_code.touched)">
                         <span *ngIf="mi_code.errors.required" class="text-danger">Code is required</span>
                       </div>
                     </div>-->
                    <p >{{assessmentDetails.code}}</p>

                  </div>
                </div>
                <div class="col-md-4 pl-0 pr-30">
                  <label style="color:#7D7D7D">QUESTIONNAIRE</label>
                  <div style="margin-top: 5px;">
                    <!-- <div *ngIf="edit">
                       <input
                         hidden=true
                         class="forminput"
                         style="width: 70%;"
                         type="text"
                         name="mi_name"
                         [(ngModel)]="assessmentDetails.name"
                         #mi_name="ngModel"
                         required
                       >
                       <div *ngIf="mi_name.errors && (mi_name.dirty || mi_name.touched)">
                         <span *ngIf="mi_name.errors.required" class="text-danger">Name is required</span>
                       </div>
                     </div>-->
                    <p >{{assessmentDetails.name}}</p>
                  </div>
                </div>
                <div class="col-md-2 pl-0 pr-20">
                  <label style="color:#7D7D7D">DESCRIPTION</label>
                  <div style="margin-top: 5px;">
                    <!--<div *ngIf="edit">
                      <input
                        hidden=true
                        class="forminput"
                        type="text"
                        name="mi_description"
                        [(ngModel)]="assessmentDetails.description"
                        #mi_description="ngModel"
                        style="width: 80%;"
                        required
                      >
                      <div *ngIf="mi_description.errors && (mi_description.dirty || mi_description.touched)">
                        <span *ngIf="mi_description.errors.required" class="text-danger">Description is required</span>
                      </div>
                    </div>-->
                    <p >{{assessmentDetails.description}}</p>
                  </div>
                </div>
                <div class="col-md-2 pl-0 pr-20">
                  <label style="color:#7D7D7D">START DATE</label>
                  <div style="margin-top: 5px;">
                    <!--<div *ngIf="edit">
                      <input
                        hidden=true
                        class="forminput"
                        type="text"
                        name="mi_start_date"
                        [(ngModel)]="assessmentDetails.start_date"
                        #mi_start_date="ngModel"
                        [owlDateTime]="dt1"
                        [owlDateTimeTrigger]="dt1"
                        required
                      >
                      <owl-date-time (afterPickerClosed)="setStartDate(dt1)" [pickerType]="'calendar'" #dt1></owl-date-time>
                      <div *ngIf="mi_start_date.errors && (mi_start_date.dirty || mi_start_date.touched)">
                        <span *ngIf="mi_start_date.errors.required" class="text-danger">Start Date is required</span>
                      </div>
                    </div>-->
                    <p >{{assessmentDetails.start_date}}</p>
                  </div>
                </div>
                <div class="col-md-2 pl-0 pr-20">
                  <label style="color:#7D7D7D">END DATE</label>
                  <div style="margin-top: 5px;">
                    <!-- <div *ngIf="edit">
                       <input
                         hidden=true
                         class="forminput"
                         type="text"
                         name="mi_end_date"
                         #mi_end_date="ngModel"
                         [(ngModel)]="assessmentDetails.end_date"
                         [owlDateTime]="dt2"
                         [owlDateTimeTrigger]="dt2"
                         required
                       >
                       <owl-date-time (afterPickerClosed)="setEndDate(dt2)" [pickerType]="'calendar'" #dt2></owl-date-time>
                       <div *ngIf="mi_end_date.errors && (mi_end_date.dirty || mi_end_date.touched)">
                         <span *ngIf="mi_end_date.errors.required" class="text-danger">End Date is required</span>
                       </div>
                     </div>-->
                    <p >{{assessmentDetails.end_date}}</p>
                  </div>
                </div>
              </form>
            </div>

            <div style="margin-top: 50px;">
              <div class="search-list">
                <div class="col-md-6 col-12 nopadding pl-0" style="margin-top: 30px;display: inline-block">
                  <!--
                                      <ul >
                                      <li >
                                        SKU LIST
                                      </li>
                                      <li >
                                        TERRITORY
                                      </li>
                                      <li >
                                        CRITERIA
                                      </li>
                                    </ul>-->

                  <div>
                    <mat-tab-group (selectedTabChange)="changeActiveTab($event)">
                      <mat-tab label="QUESTIONS" >
                      </mat-tab>
                      <mat-tab label="TERRITORY">
                      </mat-tab>
                      <mat-tab label="CRITERIA" >
                      </mat-tab>
                    </mat-tab-group>
                  </div>

                </div>
                <div class="col-md-6 col-12 pad0 text-right" style="margin-top: 30px;">
                  <div class="filters">
                    <ul class="list-unstyled">
                      <!-- <li class="margLR">
                         <div style="width: 350px;">
                           <progress max="100" value="66" data-label="Progress 2/3"></progress>
                         </div>
                       </li>-->
                      <li class="margLR" style="margin-left: 10px;">
                        <!--<button *ngIf="edit" class="icon-btn icon-btn-teal"
                                style="height: 42px;margin-bottom: 10px;margin-right:10px;"
                                (click)="createDialog()"
                        > <i class="fas fa-plus-circle"></i>
                          ADD</button>-->
                        <div style="display: inline-block;margin-top: 5px;margin-right:15px;cursor:pointer;" (click)="createDialog()" *ngIf="edit">
                          <i class="fas fa-plus-circle" style="color:#17A2B8;margin-right: 5px;margin-top:5px;font-size:16px;"></i>
                          <span style="font-size: 16px; font-weight: 400;color:#5D5D5D">Add</span>
                        </div>
                        <div class="search" style="display: inline-block;">
                          <span class="search-control"><img src="assets/searchicon.png"/></span>
                          <input  id="filter-text-box" type="text" placeholder="filter" (input)="quickSearch()" class="search-control-area"
                                  placeholder="Search">
                        </div>
                        <div style="display: inline-block;margin-right: 10px;" >
                          <!-- <button (click)="createDialog()" class="btn teal-btn" style="height: 42px;margin-bottom: 10px;margin-left:10px;">NEW TASK</button>-->
                        </div>
                      </li>
                      <!-- <li class="margLR" style="margin-left: 10px;">
                         <div
                           class="actionbutton"
                           title="Upload"
                         >
                           <img style=" display:block;margin:auto;" src="../../assets/upload2.svg">
                         </div>
                       </li>-->
                      <li class="margLR">
                        <app-excelicon  [title]="'Survey Report'"></app-excelicon>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-12 pad0">
                  <div *ngIf="activetab==='QUESTIONS'">
                    <ag-grid-angular id="myGrid1" *ngIf="loaded" style="width: 100%;height:350px;" class="ag-theme-balham"   [defaultColDef]="defaultColDef"
                                     [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                                     (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                                     (cellClicked)="onCellClicked($event)"
                                     [context]="context">
                    </ag-grid-angular>
                    <div *ngIf="preloader">
                      <app-loadingstate></app-loadingstate>
                    </div>
                    <div *ngIf="empty_state">
                      <app-emptystate></app-emptystate>
                    </div>
                  </div>

                  <div *ngIf="activetab==='TERRITORY'">
                    <ag-grid-angular id="myGrid2" *ngIf="loaded1" style="width: 100%;height:350px;" class="ag-theme-balham"   [defaultColDef]="defaultColDef"
                                     [columnDefs]="columnDefs1" [gridOptions]="gridOptions1"
                                     (gridReady)="onGridReady1($event)" [frameworkComponents]="frameworkComponents"
                                     (cellClicked)="onCellClicked1($event)"
                                     [context]="context">
                    </ag-grid-angular>
                    <div *ngIf="preloader1">
                      <app-loadingstate></app-loadingstate>
                    </div>
                    <div *ngIf="empty_state1">
                      <app-emptystate></app-emptystate>
                    </div>
                  </div>

                  <div *ngIf="activetab==='CRITERIA'">
                    <ag-grid-angular id="myGrid3" *ngIf="loaded2" style="width: 100%;height:350px;" class="ag-theme-balham"   [defaultColDef]="defaultColDef"
                                     [columnDefs]="columnDefs2" [gridOptions]="gridOptions2"
                                     (gridReady)="onGridReady2($event)" [frameworkComponents]="frameworkComponents"
                                     (cellClicked)="onCellClicked2($event)"
                                     [context]="context">
                    </ag-grid-angular>
                    <div *ngIf="preloader2">
                      <app-loadingstate></app-loadingstate>
                    </div>
                    <div *ngIf="empty_state2">
                      <app-emptystate></app-emptystate>
                    </div>
                  </div>

                  <!--<p *ngIf="edit" style="font-size: 12px;color:#17A2B8;margin-top: 10px;margin-left: 10px;cursor:pointer" (click)="createDialog()">Add an Item</p>-->
                  <!--<img *ngIf="edit && activetab=='SKU LIST'"
                       style="margin-top: 10px;margin-left: 10px;cursor:pointer"
                       (click)="createDialog()"
                       src="./../../../assets/additem.svg" />-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
