export const HILManagerBoardCollection = {
  boards: [{
    title: 'HIL Dashboard',
    structure: '8-8/8-8/8-8/16',
    id: 8,
    boardInstanceId: 2,
    rows: [
      {
        columns: [
          {
            styleClass: 'four wide',
            gadgets: [
              {
                componentType: 'StatisticArrayGadgetComponent',
                name: 'card_secondary_universe_summary',
                description: 'General statistic.',
                icon: 'images/statistic.png',
                instanceId: 101,
                tags: [
                  {
                    facet: 'Statistic',
                    name: 'counter'
                  }
                ],
                config: {
                  propertyPages: [
                    {
                      displayName: 'Run',
                      groupId: 'run',
                      position: 10,
                      properties: [
                        {
                          key: 'title',
                          value: 'Secondary Universe',
                          label: 'Title',
                          required: false,
                          order: 1,
                          controlType: 'textbox'
                        },
                        {
                          key: 'kpi_name',
                          required: true,
                          order: 2,
                          controlType: 'dropdown',
                          label: "KPI Name",
                          value: "card_secondary_universe_summary",
                          options: [{
                            key: "card_secondary_universe_summary",
                            value: "card_secondary_universe_summary"
                          }, {
                            key: "card_primary_universe_summary",
                            value: "card_primary_universe_summary"
                          }]
                        }
                      ]
                    }
                  ]
                }
              }
            ]
          },
          {
            styleClass: 'four wide',
            gadgets: [
              {
                componentType: 'StatisticArrayGadgetComponent',
                name: 'card_secondary_primary_summary',
                description: 'General statistic.',
                icon: 'images/statistic.png',
                instanceId: 102,
                tags: [
                  {
                    facet: 'Statistic',
                    name: 'counter'
                  }
                ],
                config: {
                  propertyPages: [
                    {
                      displayName: 'Run',
                      groupId: 'run',
                      position: 10,
                      properties: [
                        {
                          key: 'title',
                          value: 'Primary Universe',
                          label: 'Title',
                          required: false,
                          order: 1,
                          controlType: 'textbox'
                        },
                        {
                          key: 'kpi_name',
                          required: true,
                          order: 2,
                          controlType: 'dropdown',
                          label: "KPI Name",
                          value: "card_primary_universe_summary",
                          options: [{
                            key: "card_secondary_universe_summary",
                            value: "card_secondary_universe_summary"
                          }, {
                            key: "card_primary_universe_summary",
                            value: "card_primary_universe_summary"
                          }]
                        }
                      ]
                    }
                  ]
                }
              }
            ]
          },
          {
            styleClass: 'four wide',
            gadgets: [
              {
                componentType: 'StatisticArrayGadgetComponent',
                name: 'user_attendance',
                description: 'General statistic.',
                icon: 'images/statistic.png',
                instanceId: 102,
                tags: [
                  {
                    facet: 'Statistic',
                    name: 'counter'
                  }
                ],
                config: {
                  propertyPages: [
                    {
                      displayName: 'Run',
                      groupId: 'run',
                      position: 10,
                      properties: [
                        {
                          key: 'title',
                          value: 'Users',
                          label: 'Title',
                          required: false,
                          order: 1,
                          controlType: 'textbox'
                        },
                        {
                          key: 'kpi_name',
                          required: true,
                          order: 2,
                          controlType: 'dropdown',
                          label: "KPI Name",
                          value: "user_attendance_array",
                        }
                      ]
                    }
                  ]
                }
              }
            ]
          },
          {
            styleClass: 'four wide',
            gadgets: [
              {
                componentType: 'StatisticArrayGadgetComponent',
                name: 'meets_summary',
                description: 'General statistic.',
                icon: 'images/statistic.png',
                instanceId: 102,
                tags: [
                  {
                    facet: 'Statistic',
                    name: 'counter'
                  }
                ],
                config: {
                  propertyPages: [
                    {
                      displayName: 'Run',
                      groupId: 'run',
                      position: 10,
                      properties: [
                        {
                          key: 'title',
                          value: 'Meets',
                          label: 'Title',
                          required: false,
                          order: 1,
                          controlType: 'textbox'
                        },
                        {
                          key: 'kpi_name',
                          required: true,
                          order: 2,
                          controlType: 'dropdown',
                          label: "KPI Name",
                          value: "card_meets_summary_array",
                          options: [{
                            key: "card_meets_summary_array",
                            value: "card_meets_summary_array"
                          }, {
                            key: "card_meets_summary_array",
                            value: "card_meets_summary_array"
                          }]
                        }
                      ]
                    }
                  ]
                }
              }
            ]
          },
        ]
      },
      {
        columns: [
          {
            styleClass: 'eight wide',
            gadgets: [
              {
                componentType: 'BarGraphGadgetComponent',
                name: 'Primary Dealer Targets',
                description: 'Retailer Month target vs Achivement',
                icon: 'images/trend-line.png',
                instanceId: 301,
                tags: [
                  {
                    facet: 'Performance',
                    name: 'target'
                  },
                  {
                    facet: 'Chart',
                    name: 'bar'
                  }
                ],
                config: {
                  propertyPages: [
                    {
                      displayName: "Run",
                      groupId: "run",
                      position: 10,
                      properties: [
                        {
                          controlType: "textbox",
                          key: "title",
                          label: "Title",
                          value: "Primary Dealer Targets",
                          required: true,
                          order: 1
                        },
                        {
                          controlType: "hidden",
                          key: "state",
                          label: "",
                          value: "run",
                          required: true,
                          order: -1
                        },
                        {
                          key: 'kpi_name',
                          required: true,
                          order: 3,
                          controlType: 'dropdown',
                          label: "KPI Name",
                          value: "cluster_wise_month_target_ach_primary",
                          options: [{
                            key: "region_wise_month_target_ach_primary",
                            value: "region_wise_month_target_ach_primary"
                          },
                          {
                            key: "cluster_wise_month_target_ach_primary",
                            value: "cluster_wise_month_target_ach_primary"
                          },
                          {
                            key: "territory_wise_month_target_ach_primary",
                            value: "territory_wise_month_target_ach_primary"
                          }
                          ]
                        }
                      ]
                    },
                    {
                      displayName: "Chart",
                      groupId: "chart",
                      position: 11,
                      properties: [
                        {
                          controlType: "dropdown",
                          type: "dropdown",
                          key: "barChartType",
                          label: "Bar Chart Type",
                          value: "groupedVertical",
                          required: true,
                          order: 1,
                          options: [
                            {
                              key: "Normalized Horizontal",
                              value: "normalizedHorizontal"
                            },
                            {
                              key: "Normalized Vertical",
                              value: "normalizedVertical"
                            },
                            {
                              key: "Stacked Horizontal",
                              value: "stackedHorizontal"
                            },
                            {
                              key: "Stacked Vertical",
                              value: "stackedVertical"
                            },
                            {
                              key: "Grouped Horizontal",
                              value: "groupedHorizontal"
                            },
                            {
                              key: "Grouped Vertical",
                              value: "groupedVertical"
                            }
                          ]
                        },
                        {
                          controlType: "textbox",
                          key: "yAxisLabel",
                          label: "Y Axis Label",
                          value: "",
                          required: false,
                          order: 2
                        },
                        {
                          controlType: "textbox",
                          key: "xAxisLabel",
                          label: "X Axis Label",
                          value: "",
                          required: false,
                          order: 3
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "gradient",
                          label: "Show colors in gradient",
                          value: true,
                          required: true,
                          order: 4
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showDataLabel",
                          label: "Show data label",
                          value: true,
                          required: false,
                          order: 5
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showXAxis",
                          label: "Show x axis",
                          value: true,
                          required: false,
                          order: 6
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showYAxis",
                          label: "Show y axis",
                          value: true,
                          required: false,
                          order: 7
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showLegend",
                          label: "Show chart legend",
                          value: true,
                          required: false,
                          order: 8
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showXAxisLabel",
                          label: "Show x axis label",
                          value: true,
                          required: false,
                          order: 9
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showYAxisLabel",
                          label: "Show y axis label",
                          value: false,
                          required: false,
                          order: 10
                        }
                      ]
                    }
                  ]
                }
              }
            ]
          },
          {
            styleClass: 'eight wide',
            gadgets: [
              {
                componentType: 'BarGraphGadgetComponent',
                name: 'Secondary Retailer Targets',
                description: 'Retailer Month target vs Achivement',
                icon: 'images/trend-line.png',
                instanceId: 301,
                tags: [
                  {
                    facet: 'Performance',
                    name: 'target'
                  },
                  {
                    facet: 'Chart',
                    name: 'bar'
                  }
                ],
                config: {
                  propertyPages: [
                    {
                      displayName: "Run",
                      groupId: "run",
                      position: 10,
                      properties: [
                        {
                          controlType: "textbox",
                          key: "title",
                          label: "Title",
                          value: "Secondary Retailer Targets",
                          required: true,
                          order: 1
                        },
                        {
                          controlType: "hidden",
                          key: "state",
                          label: "",
                          value: "run",
                          required: true,
                          order: -1
                        },
                        {
                          key: 'kpi_name',
                          required: true,
                          order: 3,
                          controlType: 'dropdown',
                          label: "KPI Name",
                          value: "cluster_wise_month_target_ach_secondary",
                          options: [{
                            key: "region_wise_month_target_ach_secondary",
                            value: "region_wise_month_target_ach_secondary"
                          },
                            {
                              key: "cluster_wise_month_target_ach_secondary",
                              value: "cluster_wise_month_target_ach_secondary"
                            },
                            {
                              key: "territory_wise_month_target_ach_secondary",
                              value: "territory_wise_month_target_ach_secondary"
                            }
                          ]
                        }
                      ]
                    },
                    {
                      displayName: "Chart",
                      groupId: "chart",
                      position: 11,
                      properties: [
                        {
                          controlType: "dropdown",
                          type: "dropdown",
                          key: "barChartType",
                          label: "Bar Chart Type",
                          value: "groupedVertical",
                          required: true,
                          order: 1,
                          options: [
                            {
                              key: "Normalized Horizontal",
                              value: "normalizedHorizontal"
                            },
                            {
                              key: "Normalized Vertical",
                              value: "normalizedVertical"
                            },
                            {
                              key: "Stacked Horizontal",
                              value: "stackedHorizontal"
                            },
                            {
                              key: "Stacked Vertical",
                              value: "stackedVertical"
                            },
                            {
                              key: "Grouped Horizontal",
                              value: "groupedHorizontal"
                            },
                            {
                              key: "Grouped Vertical",
                              value: "groupedVertical"
                            }
                          ]
                        },
                        {
                          controlType: "textbox",
                          key: "yAxisLabel",
                          label: "Y Axis Label",
                          value: "",
                          required: false,
                          order: 2
                        },
                        {
                          controlType: "textbox",
                          key: "xAxisLabel",
                          label: "X Axis Label",
                          value: "",
                          required: false,
                          order: 3
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "gradient",
                          label: "Show colors in gradient",
                          value: true,
                          required: true,
                          order: 4
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showDataLabel",
                          label: "Show data label",
                          value: true,
                          required: false,
                          order: 5
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showXAxis",
                          label: "Show x axis",
                          value: true,
                          required: false,
                          order: 6
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showYAxis",
                          label: "Show y axis",
                          value: true,
                          required: false,
                          order: 7
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showLegend",
                          label: "Show chart legend",
                          value: true,
                          required: false,
                          order: 8
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showXAxisLabel",
                          label: "Show x axis label",
                          value: true,
                          required: false,
                          order: 9
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showYAxisLabel",
                          label: "Show y axis label",
                          value: false,
                          required: false,
                          order: 10
                        }
                      ]
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      {
        columns: [
          {
            styleClass: 'sixteen wide',
            gadgets: [
              {
                componentType: 'HeatGraphGadgetComponent',
                name: 'Heat Chart',
                description: 'Activities',
                icon: 'images/donut.png',
                instanceId: 302,
                tags: [
                  {
                    facet: 'Chart',
                    name: 'metric'
                  },
                  {
                    facet: "Chart",
                    name: "tree"
                  }
                ],
                config: {
                  propertyPages: [
                    {
                      displayName: "Run",
                      groupId: "run",
                      position: 10,
                      properties: [
                        {
                          key: "title",
                          controlType: "textbox",
                          label: "Title",
                          value: "Activities",
                          required: true,
                          order: 1
                        },
                        {
                          key: "state",
                          controlType: "hidden",
                          label: "",
                          value: "stop",
                          required: true,
                          order: -1
                        },
                        {
                          key: 'kpi_name',
                          required: true,
                          order: 3,
                          controlType: 'dropdown',
                          label: "KPI Name",
                          value: "productivity_by_date",
                          options: [{
                            key: "productivity_by_date",
                            value: "productivity_by_date"
                          },
                            {
                              key: "visits_by_date",
                              value: "visits_by_date"
                            },
                            {
                              key: "sales_by_date",
                              value: "sales_by_date"
                            }]
                        }
                      ]
                    },
                    {
                      displayName: "Chart",
                      groupId: "chart",
                      position: 11,
                      properties: [
                        {
                          key: "yAxisLabel",
                          controlType: "textbox",
                          label: "Y Axis Label",
                          value: "",
                          required: false,
                          order: 2
                        },
                        {
                          controlType: "textbox",
                          key: "xAxisLabel",
                          label: "X Axis Label",
                          value: "",
                          required: false,
                          order: 3
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "gradient",
                          label: "Show colors in gradient",
                          value: true,
                          required: true,
                          order: 4
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showXAxis",
                          label: "Show x axis",
                          value: true,
                          required: false,
                          order: 6
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showYAxis",
                          label: "Show y axis",
                          value: true,
                          required: false,
                          order: 7
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showLegend",
                          label: "Show chart legend",
                          value: false,
                          required: false,
                          order: 8
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showXAxisLabel",
                          label: "Show x axis label",
                          value: true,
                          required: false,
                          order: 9
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showYAxisLabel",
                          label: "Show y axis label",
                          value: false,
                          required: false,
                          order: 10
                        }
                      ]
                    }
                  ]
                }
              }
            ]
          }
        ]
      }
    ]
  }
  ],
  reports: [

  ]
};

