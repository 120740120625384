import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from "../../_services/api.service";
import {DomSanitizer} from "@angular/platform-browser";
import {EWAY_MASTERCODES} from "../../../assets/api/eway_mastercodes.json";
import {key} from "localforage";

@Component({
  selector: 'app-eway-print',
  templateUrl: './eway_print.component.html',
  styleUrls: ['./eway_print.component.scss']
})
export class EwayPrintComponent implements OnInit {
  @Input() order_detail: object;
  public math = Math;
  totalQty = 0;
  eway_QR;
  consolidatedData = [];

  constructor(public apiService: ApiService,
              private domSanitizer: DomSanitizer) {
  }

  ngOnInit() {

    if (this.order_detail['logo']) {
      this.fetchImage();
    }
    if (this.order_detail['amount_total']) {
      this.order_detail['amount_total'] = parseFloat(this.order_detail['amount_total']).toFixed(2);
    }
    if (this.order_detail['amount_subtotal']) {
      this.order_detail['amount_subtotal'] = parseFloat(this.order_detail['amount_subtotal']).toFixed(2);
    }
    if (this.order_detail['tax']) {
      Object.keys(this.order_detail['tax']).forEach(t => {
        this.order_detail['tax'][t] = parseFloat(this.order_detail['tax'][t]).toFixed(2);
      });
    }
    if (this.order_detail['items']) {
      this.order_detail['items'].forEach(item => {
        item['unit_price'] = parseFloat(item['unit_price']).toFixed(2);
        item['amount_subtotal'] = parseFloat(item['amount_subtotal']).toFixed(2);
        item['amount_total'] = parseFloat(item['amount_total']).toFixed(2);
        if (item['qty']) {
          this.totalQty += parseInt(item['qty'], 10);
        }
      });
    }
    if (this.order_detail['ewb_no']) {
      if (this.order_detail['ewb_data']) {
        this.consolidatedData = this.order_detail['ewb_data'];
      }
      const qr_payload = this.order_detail['ewb_no'] + "/" + this.order_detail['tax_code'] + "/" + this.order_detail['docDate'];
      this.eway_QR = qr_payload;
    }
  }

  async fetchImage() {
    const resp = await this.apiService.fetchImageWait({
      access_token: localStorage.getItem('resfreshToken'),
      data: [{store_fname: this.order_detail['logo'], container: "res_partner"}]
    });
    console.log(resp);
    if (resp['result'].length > 0 && resp['result'][0].bill_datas) {
      this.order_detail['logo'] = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + resp['result'][0].bill_datas);
    } else {
      this.order_detail['logo'] = null;
    }
  }
}
