import {ChangeDetectorRef, Component, Inject, OnInit} from "@angular/core";
import {ApiService} from "../../_services/api.service";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";


@Component({
  selector: 'app-create-user-popup',
  templateUrl: './create_edit_user.component.html',
  styleUrls: ['./create_edit_user.component.scss']

})

export class CreateEditUserComponent implements OnInit {

  userObject: any = {};
  rolesList: any = [];
  teamList: any = [];
  statesList: any = [];
  API_URL = '';
  access_token;
  PROFILE = 'Executive';

  activeObject = {
    id: 0,
    user_name: '',
    emp_code: '',
    sac_code: '',
    terriory_name: '',
    login: '',
    role_id: 0,
    mobile: '',
    email: '',
    sale_team_id: 0,
    region_id: 0,
    state_id: 0,
  };

  constructor(private apiService: ApiService,
              public dialog: MatDialog,
              private toastr: ToastrService,
              private _changeDetectionRef: ChangeDetectorRef,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {

    console.log(dialogdata.userObject);
    if (dialogdata.userObject === null || dialogdata.userObject === undefined) {
      this.userObject = {};
      this.userObject.id = 0;
    } else {
      this.activeObject = dialogdata.userObject;
    }

    this.access_token =  localStorage.getItem('resfreshToken');

  }

  onUserKeyDown(event) {

  }

  onLoginDown(event) {

  }

  onEmpCodeKeyDown(event) {

  }

  onSACCodeKeyDown(event) {

  }

  onTeamChangeEvent(event) {

  }
  ngOnInit(): void {

    if (localStorage.getItem('all_roles') !== null && localStorage.getItem('all_roles') !== 'null' && JSON.parse(localStorage.getItem('all_roles')) !== null) {
      let roles = JSON.parse(localStorage.getItem('all_roles'));
      this.rolesList = roles.filter(r => r.profile === this.PROFILE);
    }

    if (localStorage.getItem('all_teams') !== null && localStorage.getItem('all_teams') !== 'null' && JSON.parse(localStorage.getItem('all_teams')) !== null) {
      this.teamList = JSON.parse(localStorage.getItem('all_teams'));
      this.teamList.sort((a, b) => a.itemname.localeCompare(b.itemname));
    }

    if (localStorage.getItem('user_states') !== null && localStorage.getItem('user_states') !== 'null' && JSON.parse(localStorage.getItem('user_states')) !== null) {
      this.statesList = JSON.parse(localStorage.getItem('user_states'));
      this.statesList.sort((a, b) => a.itemName.localeCompare(b.itemName));
    }

  }

  onRoleChange(e) {
    const selectObject = this.rolesList.find(item => item.id === parseInt(this.userObject.role_id, 10));
    if (selectObject !== undefined) {
    //  Do Something
    }
  }

  saveUser() {


    this.API_URL = '/api/pwa_admin_connects/save_user';
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.activeObject = this.activeObject;

    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {

            this.toastr.success('Created User Successfully');
            this.dialog.closeAll();

          } else if (res.hasOwnProperty('results') && res.results.hasOwnProperty('msg')) {
            this.toastr.error(res.results.msg);
          } else  {

            this.toastr.error('Error creating/modifying User');
          }
        },
        error => {
          console.log(error);
          this.toastr.error('Error creating/modifying user');
        });

  }


  closeDialog() {
    this.dialog.closeAll();
  }

}
