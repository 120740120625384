export const GST_STATE_CODES = [
  {
    state: "JAMMU AND KASHMIR",
    code: 1
  },
  {
    state: "HIMACHAL PRADESH",
    code: 2
  },
  {
    state: "PUNJAB",
    code: 3
  },
  {
    state: "CHANDIGARH",
    code: 4
  },
  {
    state: "UTTARAKHAND",
    code: 5
  },
  {
    state: "HARYANA",
    code: 6
  },
  {
    state: "DELHI",
    code: 7
  },
  {
    state: "RAJASTHAN",
    code: 8
  },
  {
    state: "UTTAR PRADESH",
    code: 9
  },
  {
    state: "BIHAR",
    code: 10
  },
  {
    state: "SIKKIM",
    code: 11
  },
  {
    state: "ARUNACHAL PRADESH",
    code: 12
  },
  {
    state: "NAGALAND",
    code: 13
  },
  {
    state: "MANIPUR",
    code: 14
  },
  {
    state: "MIZORAM",
    code: 15
  },
  {
    state: "TRIPURA",
    code: 16
  },
  {
    state: "MEGHALAYA",
    code: 17
  },
  {
    state: "ASSAM",
    code: 18
  },
  {
    state: "WEST BENGAL",
    code: 19
  },
  {
    state: "JHARKHAND",
    code: 20
  },
  {
    state: "ODISHA",
    code: 21
  },
  {
    state: "CHATTISGARH",
    code: 22
  },
  {
    state: "MADHYA PRADESH",
    code: 23
  },
  {
    state: "GUJARAT",
    code: 24
  },
  {
    state: "DADRA AND NAGAR HAVELI AND DAMAN AND DIU (NEWLY MERGED UT)",
    code: "26*"
  },
  {
    state: "MAHARASHTRA",
    code: 27
  },
  {
    state: "ANDHRA PRADESH(BEFORE DIVISION)",
    code: 28
  },
  {
    state: "KARNATAKA",
    code: 29
  },
  {
    state: "GOA",
    code: 30
  },
  {
    state: "LAKSHADWEEP",
    code: 31
  },
  {
    state: "KERALA",
    code: 32
  },
  {
    state: "TAMIL NADU",
    code: 33
  },
  {
    state: "PUDUCHERRY",
    code: 34
  },
  {
    state: "ANDAMAN AND NICOBAR ISLANDS",
    code: 35
  },
  {
    state: "TELANGANA",
    code: 36
  },
  {
    state: "ANDHRA PRADESH (NEWLY ADDED)",
    code: 37
  },
  {
    state: "LADAKH (NEWLY ADDED)",
    code: 38
  },
  {
    state: "OTHER TERRITORY",
    code: 97
  },
  {
    state: "CENTRE JURISDICTION",
    code: 99
  }
];
