<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper dcvanlist">
      <div class="search-list">
        <div class="col-md-6 pad0">
          <div class="search">
            <span class="search-control"><img src="assets/searchicon.png"/></span>
            <input type="text" placeholder="filter" (keyup)="quickSearch()" [(ngModel)]="searchValue" class="search-control-area"
                   placeholder="Search for DC">
          </div>
        </div>
        <div class="col-md-6 pad0">
          <div class="filters">
            <ul class="list-unstyled">
              <li>
                <div style="display: inline-block;">
                  <button (click)="run()" style="position: relative;margin-top: 2.5%;margin-left: -86%;" class="btn blue-btn">GO</button>
                </div>
              </li>
              <li style="margin-right: 0% !important;margin-left: 0% !important;margin-top: 1%;" class="margLR">
                <ng-template adHost1></ng-template>
              </li>
              <li class="margLR">
                <div class="date-picker">
                  <span class="datepicker-icon"><img src="../../assets/date.png"></span>
                  <input [readonly]="true" [(ngModel)]="myDate" [owlDateTimeTrigger]="dt" [owlDateTime]="dt" placeholder="Date">
                  <span class="dropdown-icon"><img src="../../assets/dropdown.png"></span>
                  <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
                </div>
              </li>
<!--              <li class="margLR">-->
<!--                <app-printicon [gridOption]="gridOptions" [styleGrid]="styleGrid"></app-printicon>-->
<!--              </li>-->
              <li class="margLR" style="margin-top: 0px;">
                <app-excelicon [gridOption]="gridOptions" [title]="'Stock IN Hand'"></app-excelicon>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div style="margin-left:20%;width: max-content;position: relative;bottom: 35px;height:0px;font-size: 130%;font-weight: 600;">
            <ng-template adHost></ng-template>
          </div>
        </div>
        <div class="col-md-12 pad0">
          <div class="component-container">
            <ag-grid-angular id="myGrid1" *ngIf="loaded" style="width: 100%;height: 72vh;" class="ag-theme-balham"  [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                             (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents">
            </ag-grid-angular>
            <div *ngIf="preloader">
              <!--<div style="position:absolute;left:44%;top:200px;font-size: medium">-->
              <!--<img src="../../assets/images/preloader.gif"/>-->
              <!--</div>-->
              <app-loadingstate></app-loadingstate>
            </div>
            <div *ngIf="empty_state">
              <app-emptystate></app-emptystate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
