<div>
    <button class="compact ui button right floated"
            *ngIf="showControls && showDeleteControl" (click)="remove()">
        <i class="trash icon" style="margin-right:0 !important"></i>
    </button>
    <button class="compact ui button right floated"
            *ngIf="showControls && showConfigurationControl" (click)="toggleConfigMode()">
        <i class="setting  icon" style="margin-right:0 !important"></i>
    </button>
    <button class="compact ui button right floated"
            *ngIf="showControls" (click)="help()">
        <i class="info icon" style="margin-right:0 !important" ></i>
    </button>
    <button class="compact ui button right floated"
            *ngIf="showControls" (click)="redirect()">
      <i class="arrow right icon" style="margin-right:0 !important" ></i>
    </button>

    <app-gadget-operation-control *ngIf="showControls"
                              [inRun]="inRun"
                              [inConfig]="inConfig"
                              [actionInitiated]="actionInitiated"
                              [showOperationControls]="showOperationControls"
                              [gadgetHasOperationControls]="gadgetHasOperationControls"
                              (runEvent)="run()"
                              (stopEvent)="stop()">

    </app-gadget-operation-control>

    <div >
      <h3>{{title}}</h3>
    </div>
</div>
<div class="spacer"></div>
