<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" style="float: right;background-color:#FDE0D4;">
        <i class="fas fa-times"></i>
      </button>
      <h2>OUTLETS</h2>
      <!-- <button class="close-dialog" (click)="closeDialog()">
         <i class="fas fa-times"></i>
       </button>-->
    </div>
    <div class="body-section">
      <div>
        <!--<div class="col-md-2 pl-0 ">
          <label style="color:#7D7D7D">CLASS</label>
          <div style="margin-top: 5px;" class="formselect">
            <select
              name="class"
              [(ngModel)]="outletFilters.class_type"
              #class="ngModel"
              style="width: 80%;">
              <option value="a">A</option>
              <option value="b">B</option>
              <option value="c">C</option>
              <option value="d">D</option>
            </select>
          </div>
        </div>-->
        <div class="col-md-2 pl-0 ">
          <label style="color:#7D7D7D">PROGRAM TYPE</label>
          <div style="margin-top: 5px;" class="formselect">
            <select
              name="program_type"
              [(ngModel)]="outletFilters.program_type"
              #program_type="ngModel"
              style="width: 80%;">
              <option
                *ngFor="let item of program_types"
                value="{{ item.id }}"
              >
                {{ item.itemName }}
              </option>
              <!-- <option value="Rang Raja" selected>Rang Raja</option>
               <option value="Ranga Puja">Ranga Puja</option>
               <option value="Rang Seva">Rang Seva</option>-->
            </select>
          </div>
        </div>
        <div class="col-md-2 pl-0 ">
          <label style="color:#7D7D7D">OUTLET TYPE</label>
          <div style="margin-top: 5px;" class="formselect">
            <select
              name="outlet_type"
              [(ngModel)]="outletFilters.outlet_type"
              #outlet_type="ngModel"
              style="width: 80%;">
              <option
                *ngFor="let item of outlet_types"
                value="{{ item.id }}"
              >
                {{ item.itemName }}
              </option>
              <!-- <option value="Super Market" selected>Super Market</option>
               <option value="Departmental Store">Departmental Store</option>
               <option value="Grocery Store">Grocery Store</option>-->
            </select>
          </div>
        </div>
        <div class="col-md-2 pl-0 ">
          <label style="color:#7D7D7D">SALESMAN</label>
          <div style="margin-top: 5px;" class="formselect">
            <select
              name="beat"
              [(ngModel)]="outletFilters.salesman_id"
              #beat="ngModel"
              style="width: 80%;">
              <option
                *ngFor="let item of salesmen"
                value="{{ item.id }}"
              >
                {{ item.itemname }}
              </option>
              <!-- <option value="a" selected>NE 1</option>
               <option value="b">BTL Activity</option>
               <option value="c">Scheme</option>-->
            </select>
          </div>
        </div>
        <div class="col-md-2 pl-0 ">
          <label style="color:#7D7D7D">TERRITORY</label>
          <div style="margin-top: 5px;" class="formselect">
            <select
              name="territory"
              [(ngModel)]="outletFilters.territory_id"
              #territory="ngModel"
              style="width: 80%;">
              <option
                *ngFor="let item of territories"
                value="{{ item.id }}"
              >
                {{ item.itemname }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-2 pl-0 ">
          <label style="color:#7D7D7D">CLUSTER</label>
          <div style="margin-top: 5px;" class="formselect">
            <select
              name="cluster"
              [(ngModel)]="outletFilters.cluster_id"
              #cluster="ngModel"
              style="width: 80%;">
              <option
                *ngFor="let item of clusters"
                value="{{ item.id }}"
              >
                {{ item.itemname }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-2 pl-0 ">
          <div style="display: inline-block;" >
            <button
              mat-button
              class="btn blue-btn"
              style="margin-top: 19px;"
              (click)="fetchOutlets()"
            >
              GO
            </button>
          </div>
        </div>
      </div>

      <div class="search" style="display: inline-block;margin-top: 40px;margin-right: 15px;">
        <span class="search-control"><img src="assets/searchicon.png"/></span>
        <input  id="filter-text-box1" type="text" placeholder="filter" (input)="quickSearch()" class="search-control-area"
                placeholder="Search">
      </div>
      <div class="col-md-12 pad0" style="margin-top: 30px;">
        <div>
          <ag-grid-angular *ngIf=loaded id="myGrid1" style="width: 100%;height:280px;" class="ag-theme-balham"   [defaultColDef]="defaultColDef"
                           [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                           (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                           (cellClicked)="onCellClicked($event)"
                           [context]="context">
          </ag-grid-angular>
          <div *ngIf="preloader">
            <app-loadingstate></app-loadingstate>
          </div>
          <div *ngIf="empty_state">
            <app-emptystate></app-emptystate>
          </div>
        </div>
      </div>

      <div>
        <button
          mat-button
          class="btn teal-btn"
          style=" vertical-align:bottom;margin-top: 20px;"
          (click)="addRetailers()"
        >
          ADD
        </button>
      </div>

    </div>
  </div>
</section>
