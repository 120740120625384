<div style="margin-top: -15px;">
  <span style="color:#17A2B8;">Month Range </span>
</div>
<div class="date-picker">
  <div style="width:150px;" class="periodselect">
    <span class="datepicker-icon" style="margin-top: 2px;margin-left: -3px;"><img src="../../assets/date.png"></span>
    <select
      style="padding-left: 40px;"
      name="period_name"
      [(ngModel)]="periodID"
      #period_name="ngModel"
    (change)="onPeriodChange()">
      <option
        *ngFor="let item of periodData"
        value="{{ item.id }}"
      >
        {{ item.period_name }}
      </option>
    </select>
  </div>
</div>
