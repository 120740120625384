<div class="common-wrapper" xmlns="http://www.w3.org/1999/html">
  <div class="common-container">
    <div class="main-wrapper">
      <div style="padding: 10px;">
        <div class="cardbox" style="width: 100%">
          <div>
            <div class="row">
              <!--<button
                class="btn grey-btn"
                (click)="cancelMaster()"
              >CANCEL
              </button>-->
              <div>
                <h3>{{expenseMasterData['user_name']}} - {{expenseMasterData['policy']}}</h3>
                <div class="row centeralign">
                  <span class="detaillabel3 mr-2">Supplier</span>
                  <span class="detailvalue1">{{expenseMasterData['supp_name']}}</span>

                  <span class="greyindicator" style="margin-left: 30px;"></span>
                  <span class="detaillabel3 mr-2">Date</span>
                  <span class="detailvalue1">{{expenseMasterData['expense_date']}}</span>

                  <span class="greyindicator" style="margin-left: 30px;"></span>
                  <span class="detaillabel3 mr-2">Total Claim Amount</span>
                  <span class="detailvaluebold1">{{expenseMasterData['total_claim']}}</span>

                  <div *ngIf="expenseMasterData.state !== 'new'" style="display: inline">
                    <span class="greyindicator" style="margin-left: 30px;"></span>
                    <span class="detaillabel3 mr-2">Approved Amount</span>
                    <span class="detailvaluebold1" style="color: #0762AD; ">{{expenseMasterData['appr_amt']}}</span>

                    <span class="greyindicator" style="margin-left: 30px;"></span>
                    <span class="detaillabel3 mr-2">Rejected Amount</span>
                    <span class="detailvaluebold1">{{expenseMasterData['reject_amt']}}</span>
                  </div>
                </div>
              </div>
              <div style="position: absolute;right: 40px;">
                <!--<span title="Print" style="margin-right: 20px;cursor: pointer"><img (click)="getPrintDetails()"
                                                                                    src="./../../../../assets/icons/print.svg"/></span>-->
                <div style="display: inline-block;margin-left: 10px; margin-right: 10px;"  class="btn light-blue-btn">
                  <span
                    style="height: 8px;width: 8px;margin-right: 5px;background-color: #379862;border-radius: 50%; display: inline-block;"></span>
                  <span style="color: #2D2D2D">{{expenseMasterData['state']}}</span>
                </div>
                <span (click)="movePrevious()"><i
                  [ngClass]="{'disabledspan': rowIndex === 0,
                         'enabledspan': rowIndex >0}"
                  class="fal fa-arrow-circle-left fa-2x"></i></span>
                <span style="margin-left: 5px; margin-right: 5px; font-size: 12px;">{{ rowIndex + 1 }}
                  /{{ listLength }}</span>
                <span (click)="moveNext()"><i
                  [ngClass]="{'disabledspan': rowIndex === listLength-1,
                         'enabledspan': rowIndex < listLength-1}"
                  class="fal fa-arrow-circle-right fa-2x"></i></span>
              </div>
            </div>
          </div>
        </div>
        <div class="cardbox" style="width: 100%; margin-top: 20px;">
          <div *ngIf="dataLoaded" class="row"
               style="display: flex;align-items: center;justify-content: space-between; margin-top: 15px;">
            <div style="margin-right: auto;align-items: center;display: flex;">
             <!-- <input
                type="checkbox"
                [checked]="allSelect"
                name="allselected"
                [(ngModel)]="allSelect"
                (change)="selectAll()"
                #allselected=ngModel
                style="width:15px;height:15px;display: inline; "
              />
              <span *ngIf="!allSelect" style="margin-left: 10px;font-size: 17px;  color: #2D2D2D;">Select All</span>
              <span *ngIf="allSelect" style="margin-left: 10px;font-size: 17px;  color: #2D2D2D;">UnSelect All</span>
              <span *ngIf="selectedCount > 0"
                    style="font-size: 17px;  color: #2D2D2D; margin-left: 10px; margin-right: 10px;">|</span>
              <span *ngIf="selectedCount > 0" style="font-size: 17px;  color: #2D2D2D;">{{ selectedCount }}</span>
              <span *ngIf="selectedCount > 0" style="margin-left: 3px;font-size: 17px;  color: #2D2D2D;">Selected</span>-->
            </div>
            <div *ngIf="showApprove && (expenseMasterData['state'] === 'new' || expenseMasterData['state'] === 'pending')">
              <button class="btn confirm-green-btn" style="margin-right:10px;" (click)="showMasterApproveRejectPopup('Approve', null)">Approve</button>
              <button class="btn reject-btn" (click)="masterRejectPopup()">Reject</button>
            </div>
          </div>
          <div class="galleryrowgrid" style="margin-top: 15px;">
            <ng-container *ngFor="let expense of expenseDetailsData">
              <div style="display: flex">
               <!-- <input
                  type="checkbox"
                  [checked]="expense.selected"
                  name="lineselect"
                  [(ngModel)]="expense.selected"
                  (change)="selectExpense()"
                  #lineselect=ngModel
                  style="width:15px; height:100%; margin:10px; "
                />-->
                <div class="cardbox" style="flex: 1; border-radius: 10px;">
                  <div class="row" style="width: 100%">
                    <div class="col-sm-4 col-xs-6" style="margin-top: 10px;">
                      <img *ngIf="expense.photo" class="img-responsive" [src]="expense.photo"
                           style="height: 150px;background-color: #F5F7F9"/>
                      <img *ngIf="!expense.photo" class="img-responsive" src="assets/no-image-icon.png" style="
                                      height: 150px;display: block; margin: 0 auto;"/>
                    </div>
                    <div class="col-sm-8 col-xs-6" style="margin-top: 10px;">
                      <div>
                        <label class="detailvalue1">{{ expense.name }}</label>
                        <span class="ml-1 status2" style="float:right" [ngClass]="{'success': expense.state === 'approved',
                           'error': expense.state === 'rejected',
                           'new': expense.state === 'new',
                           'processed': expense.state === 'settled',
                           'warning': expense.state === 'processing'}">{{expense.state}}</span>
                      </div>
                      <div>
                        <div style=" display: block;align-items: center;">
                          <label *ngIf="expense.state === 'rejected' || expense.state === 'cancelled'"
                                 style="font-size: 22px; font-weight: bold; color:#4D4D4D; text-decoration: line-through red;">{{ expense.amount }}</label>
                          <label *ngIf="expense.state !== 'rejected' && expense.state !== 'cancelled'"
                                 style="font-size: 22px; font-weight: bold; color:#0762AD;">{{ expense.amount }}</label>
                          <img *ngIf="expense.state === 'approved' || expense.state === 'settled'"
                               style="height: 20px; margin-left: 5px; margin-bottom: 10px;"
                               src="assets/green_checked.svg"/>
                        </div>
                      </div>
                      <div class="row centeralign" style="width: 100%;margin-top: 20px;">
                        <span class="detaillabel1 mr-2">Approved Amount</span>
                        <span class="detailvalue1" [ngClass]="{'color-green': expense.amount === expense.app_amount,
                                    'color-red': expense.amount > expense.app_amount}">{{ expense.app_amount }}</span>

                        <span class="greyindicator" style="margin-left: 30px;"></span>
                        <span class="detaillabel1 mr-2">Max</span>
                        <span class="detailvalue1">{{ expense['max_claim_amount'] }}</span>
                      </div>
                      <div *ngIf="expense.note" class="row centeralign" style="width: 100%;margin-top: 5px;">
                        <span class="detaillabel1 mr-2">Comments</span>
                        <span class="detailvalue1">{{ expense['note'] }}</span>
                      </div>
                      <div *ngIf="expense.claim_id" class="row centeralign" style="width: 100%;margin-top: 5px;">
                        <span class="detaillabel1 mr-2">Claim No.</span>
                        <span class="detailvalue1" >{{ expense.claim_name }}</span>

                        <span class="greyindicator" style="margin-left: 30px;"></span>
                        <span class="detaillabel1 mr-2">Claim Status</span>
                        <span class="detailvalue1">{{ expense['claim_status'] }}</span>
                      </div>
                      <div class="row"
                           style="display: flex;align-items: center;justify-content: space-between; margin-top: 15px;">
                        <div style="margin-right: auto;align-items: center;display: flex;">
                          <label (click)="showDetails(expense)"
                            style="font-size: 14px;color:#0762AD;margin-top: 10px;font-weight: 600; text-decoration: underline;">{{showDetailText}}</label>
                        </div>
                        <div *ngIf="showApprove">
                          <button *ngIf="expense.state == 'new' || (expense.state === 'processing' && apprtype==='appr2')"
                                  class="btn confirm-green-btn1" style="margin-right:10px;" (click)="approvePopup(expense)">Approve</button>
                          <button *ngIf=" expense.state == 'new' || (expense.state === 'processing' && apprtype==='appr1' && expense.app1_state!='rejected') || (expense.state === 'processing' && apprtype==='appr2')"
                            class="btn reject-btn1" (click)="rejectPopup(expense)">Reject</button>
                        </div>
                        <div *ngIf="showCancel && expense.state === 'new'">
                          <button class="btn reject-btn1" (click)="showCancelPopup(expense)">Cancel</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="expense.show_details">
                    <div style="margin-top: 20px;background: #F5F7F9; padding: 15px;">
                      <span class="detailvaluebold2">Approver Details</span>
                      <span class="detailvaluebold2" style="display: block; margin-top: 10px;">Approver 1</span>
                      <div>
                        <span class="detaillabel1 mr-2">Name</span>
                        <span class="detailvalue1">{{expense.appr1}}</span>

                        <span class="greyindicator" style="margin-left: 30px;"></span>
                        <span class="detaillabel1 mr-2">Reviewed On</span>
                        <span class="detailvalue1">{{expense['apr1_date']}}</span>

                        <span class="greyindicator" style="margin-left: 30px;"></span>
                        <span class="detaillabel1 mr-2">Status</span>
                        <span class="detailvalue1">{{expense['app1_state']}}</span>
                      </div>
                      <span class="detailvaluebold2" style="display: block; margin-top: 10px;">Approver 2</span>
                      <div>
                        <span class="detaillabel1 mr-2">Name</span>
                        <span class="detailvalue1">{{expense.appr2}}</span>

                        <span class="greyindicator" style="margin-left: 30px;"></span>
                        <span class="detaillabel1 mr-2">Reviewed On</span>
                        <span class="detailvalue1">{{expense['apr2_date']}}</span>

                        <span class="greyindicator" style="margin-left: 30px;"></span>
                        <span class="detaillabel1 mr-2">Status</span>
                        <span class="detailvalue1">{{expense['app2_state']}}</span>
                      </div>

                      <div *ngIf="expense['reject_comments']" style="margin-top: 10px;">
                        <span class="detaillabel1 mr-2">Reject Comments</span>
                        <span class="detailvalue1">{{expense['reject_comments']}}</span>
                      </div>
                    </div>
                    <div style="margin-top: 20px;background: #F5F7F9; padding: 15px;">
                      <span class="detailvaluebold2">Bill Image</span>
                      <div class="galleryrowgrid row centeralign" style="margin-top: 10px;">
                        <ng-container *ngFor="let tile of expense['gallery']">
                          <div style=" display: inline-block;align-items: center;cursor: pointer;">
                            <img *ngIf="tile.type !== 'pdf'" [src]="tile.photo" (click)="showImagePopup(tile.photo)"
                                 style="height: 80px;width:80px;margin-right: 8px;margin-bottom: 8px;cursor: pointer"/>
                            <img *ngIf="tile.type === 'pdf'" src="assets/pdf_icon.png"
                                 (click)="openPDFinNewTab(tile.photo)"
                                 style="height: 80px;width:80px;margin-right: 8px;margin-bottom: 8px;cursor: pointer"/>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
