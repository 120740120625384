import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {ErrorHandler} from "../../error/error-handler";

@Component({
  selector: 'app-kpi-result-leaderboard-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class TerritoryKpiResultLeaderBoardComponent extends ReportBase implements IReport, OnInit {

  public attributeTypes = [];
  // showCustomFilter = true;
  // customFilter: any = {
  //   name: 'Active',
  //   key: 'active',
  //   value: 'true',
  //   values: [],
  //   show: true
  // };
  apiData: any = [];
  uqTypes;

  customFilter: any = {
    name: 'Kpi',
    key: 'id',
    value: 0,
    values: [],
    show: true
  };

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Territory Kpi LeaderBoard');
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.showDate = true;
    let datas: any = {};
    datas = history.state;
    this.userData.customFilter['id'] = datas.kpiID;
    // this.loadInBatches(5000, 'rowid');
    this.showProgressCustomText = true;

    this.API_URL = '/api/pwa_v2_reports/kpiTerritoryResultLeaderBoard';

    this.fetchKpis();
    if (this.userData.customFilter['id'] && this.userData.customFilter['id'] !== 0) {
      this.showCustomFilter = false;
      this.loadServerData();
    }
  }

  fetchKpis() {
    this.elRef.nativeElement.querySelector('#overlay1').style.display = "block";
    this.dataLoaded = false;
    this.preloader = true;
    this.emptyState = false;
    this.errorExists = false;
    this.apiService.post('/api/pwa_v2_reports/getTerritoryKpis', this.userData)
      .subscribe(res => {
          console.log(res);
          // t.stop();
          this.elRef.nativeElement.querySelector('#overlay1').style.display = "none";
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.customFilter.values = [];
            if (res.results.data && res.results.data.length > 0) {
              for (const item of res.results.data) {
                this.customFilter.values.push({id: item.id, itemName: item.name});
              }
              this.customFilter.value = this.customFilter.values[0].id;
              this.userData.customFilter['id'] = this.customFilter.values[0].id;
              /* this.elRef.nativeElement.querySelector('#customfilter')['options'] = this.customFilter.values;
               this.elRef.nativeElement.querySelector('#customfilter')['options'][0].selected = true;*/
              this.showCustomFilter = true;
              console.log(this.userData);
            }
          } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
            this.emptyState = true;
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.emptyState = true;
            if (res.hasOwnProperty('results') && res.results.hasOwnProperty('errmsg')) {
              console.error(res.results.errmsg);
            }
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        // tslint:disable-next-line:no-shadowed-variable
        error => {
          this.elRef.nativeElement.querySelector('#overlay1').style.display = "none";
          this.handleError(ErrorHandler.getErrorObject(error));
        });
  }

  configureGrid() {
    this.columnDefs = [
      {
        headerName: "PLAN",
        children: [
          {headerName: 'NAME', field: 'plan_name', enableRowGroup: true, width: 80, hide: true},
          {headerName: 'STATE', field: 'state', enableRowGroup: true, width: 80, hide: true},
          {headerName: 'START DATE', field: 'start_date', enableRowGroup: true, width: 80, hide: true},
          {headerName: 'END DATE', field: 'end_date', width: 80, hide: true}
        ]
      }
    ];

    this.apiData.forEach(data => {
      let alreadyExist = false;
      let lineHeader;
      if (data.hasOwnProperty("entity_type") && data.entity_type === "user") {
        lineHeader = "USER";
        for (let i = 0; i < this.columnDefs.length; i++) {
          if (this.columnDefs[i].headerName === lineHeader) {
            alreadyExist = true;
            break;
          }
        }
        if (!alreadyExist) {
          this.columnDefs.push({
            headerName: lineHeader,
            children: [
              {
                headerName: "SALESMAN",
                field: 'user_name',
                width: 100
              },
              {
                headerName: "EMP CODE",
                field: 'emp_code',
                width: 100
              }
            ]
          });
        }
      } else if (data.hasOwnProperty("entity_type") && data.entity_type === "territory") {
        lineHeader = "TERRITORY";
        for (let i = 0; i < this.columnDefs.length; i++) {
          if (this.columnDefs[i].headerName === lineHeader) {
            alreadyExist = true;
            break;
          }
        }
        if (!alreadyExist) {
          this.columnDefs.push({
            headerName: lineHeader,
            children: [
              {
                headerName: "TERRITORY",
                field: 'territory_name',
                width: 100
              },
              {
                headerName: "TERRITORY CODE",
                field: 'territory_code',
                width: 100
              },
              {
                headerName: "TERRITORY MGR",
                field: 'territory_manager',
                width: 100, hide: true
              },
              {
                headerName: "CLUSTER",
                field: 'cluster',
                width: 100
              },
              {
                headerName: "CLUSTER MGR",
                field: 'cluster_manager',
                width: 100, hide: true
              },
              {
                headerName: "REGION",
                field: 'region',
                width: 100
              },
              {
                headerName: "REGION MGR",
                field: 'region_manager',
                width: 100, hide: true
              },
              {
                headerName: "STATE",
                field: 'state_name',
                width: 100, hide: true
              }
            ]
          });
        }
      }
      const kpiHeader = data.kpi_name;
      for (let i = 0; i < this.columnDefs.length; i++) {
        if (this.columnDefs[i].headerName === kpiHeader) {
          alreadyExist = true;
          break;
        }
      }
      if (!alreadyExist) {

        this.columnDefs.push({
            headerName: kpiHeader,
            children: [
              {
                headerName: "VALUE",
                field: 'kpi_result_value',
                width: 100
              }, {
                headerName: 'Rank', field: 'rank', maxWidth: 80, menuTabs: [], cellStyle: {textAlign: 'center'},
                cellRenderer(params) {
                  if (params.data) {
                    let displayElem = '';
                    if (params.data.rank) {
                      if (params.data.rank === 1) {
                        displayElem = displayElem + ' <img style="width:16px;" src="../../../assets/images/ic_gold_medal.svg"/>';
                      } else if (params.data.rank === 2) {
                        displayElem = displayElem + ' <img style="width:16px;" src="../../../assets/images/ic_silver_medal.svg"/>';
                      } else if (params.data.rank === 3) {
                        displayElem = displayElem + ' <img style="width:16px;" src="../../../assets/images/ic_bronze_medal.svg"/>';
                      } else {
                        // displayElem = displayElem + ' color: #D97C0E;';
                        displayElem = params.value;
                      }
                    } else {
                      return '';
                    }
                    return displayElem;
                  }
                }
              }
            ]
          },
        );
      }
    });
  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }


  configureData() {
    this.rowData = this.apiData;
    console.log(this.rowData);
  }
}
