<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper dcvanlist">
      <div class="search-list">
        <div class="col-md-6 pad0">
          <div class="search">
            <span class="search-control"><img src="assets/searchicon.png"/></span>
            <input type="text" placeholder="filter" (keyup)="quickSearch()" [(ngModel)]="searchValue" class="search-control-area"
                   placeholder="Search for DC">
          </div>
        </div>
        <div class="col-md-6 pad0">
          <div class="filters">
            <ul class="list-unstyled">
              <li style="padding: 9px;">
                <ng-select class="filter-container filter-box" [items]="dcList"
                           bindLabel="itemName"
                           multiple="true"
                           maxSelectedItems="1"
                           [(ngModel)]="selectedDC"
                           (ngModelChange)="dcChange($event)"
                >
                </ng-select>
              </li>
              <li style="padding: 6px;">
                <div style="display: inline-block;">
                  <button (click)="run()" class="btn blue-btn">GO</button>
                </div>
              </li>
              <li class="margLR">
                <div class="date-picker">
                  <span class="datepicker-icon"><img src="../../assets/date.png"></span>
                  <input [selectMode]="'range'" [readonly]="true" [(ngModel)]="myDate" [owlDateTimeTrigger]="dt" [owlDateTime]="dt" placeholder="Date">
                  <span class="dropdown-icon"><img src="../../assets/dropdown.png"></span>
                  <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
                </div>
              </li>
              <li class="margLR" style="margin-top: 0px;">
                <app-excelicon [gridOption]="gridOptions" [title]="'Product Collections'"></app-excelicon>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-12 pad0">
          <div class="component-container">
            <ag-grid-angular id="myGrid2" *ngIf="loaded" style="height: 75vh;" class="ag-theme-alpine"  [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                             [modules]="modules" (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents">
            </ag-grid-angular>
            <div *ngIf="preloader">
              <app-loadingstate></app-loadingstate>
            </div>
            <div *ngIf="empty_state">
              <app-emptystate></app-emptystate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
