import {Component} from "@angular/core";
import {BaseUpload} from "../pagebase/base_upload/base_upload";
import * as XLSX from "xlsx-js-style";
import {GetRowIdParams} from "@ag-grid-enterprise/all-modules";

@Component({
  selector: 'app-questionnaire-upload',
  templateUrl: '../pagebase/base_upload/base_upload.html',
  styleUrls: ['../pagebase/base_upload/base_upload.scss']

})

export class QuestionnaireUploadComponent extends BaseUpload {

  questionnaire_types = [];
  question_types = [];
  criteria;
  outlet_types = [];
  program_types = [];
  criteriapresent = false;
  answerpresent = false;

  setViewParams() {
    this.header = 'Questionnaire Upload';
    this.sampleText1 = 'DOWNLOAD SAMPLE TEMPLATE';
    this.sampleText2 = 'DOWNLOAD MASTER DATA';
    this.multiSheetUpload = true;
    const date = new Date();
    this.myDate = [];
    this.myDate[0] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd');
    /*
    this.excel_columns = [{C: 'A', H: 'TaskCode', V: 'UP', required: true},
      {C: 'B', H: 'TaskName', V: 'JWBSK1099866', required: true},
      {C: 'C', H: 'Type', V: 'Activation', required: true},
      {C: 'D', H: 'RetailerCode', V: 'RJ42686', required: true},
      {C: 'E', H: 'RetailerName', V: 'Retailer 1', required: false},
      {C: 'F', H: 'Start Date', V: this.myDate[0], required: true},
      {C: 'G', H: 'End Date', V: this.myDate[1], required: true},
      {C: 'H', H: 'QuestionCode', V: 'TQ01', required: false},
    ];
    this.step1Text = 'Download sample template for reference.';
    this.step2Text = 'Required fields are TaskCode, TaskName, Type, RetailerCode, Start Date and End Date';*/
    this.actionText = 'Upload a questionnaire with questions,answers and criterias';
    this.baseData = this.sampleData1;
    this.dataLoaded = true;
    //this.fetchMasterData();
  }

  configureGrid() {
    this.API_URL = '/api/crm_profiling_questionnaires/upload_questionnaires';
    this.gridOptions.getRowId = (params: GetRowIdParams) => {
      return params.data.uid;
    };
    this.columnDefs = [
      {headerName: 'Questionnaire', field: 'name', width: 100},
      {headerName: 'Questionnaire Code', field: 'code', width: 60},
      {headerName: 'Description', field: 'description', width: 100},
      {headerName: 'Period', field: 'period', width: 60},
      {headerName: 'Start Date', field: 'start_date', width: 60},
      {headerName: 'End Date', field: 'end_date', width: 60},
      {headerName: 'Global Survey', field: 'global_survey', width: 60},
      {headerName: 'Type', field: 'type', width: 60},
      {headerName: 'Survey At Retailer', field: 'survey_at_retailer', width: 60},
      {headerName: 'Max Score', field: 'max_score', width: 60},
      {headerName: 'State', field: 'state', width: 100},
      {headerName: 'Criteria', field: 'criteria', width: 100},
      {headerName: 'Crtieria Type', field: 'criteria_type', width: 100},
      {headerName: 'Criteria Attribute', field: 'attr', width: 100},
      {headerName: 'Attribute Value', field: 'attr_value', width: 100},
      {headerName: 'Question', field: 'question', width: 100},
      {headerName: 'QuestionCode', field: 'question_code', width: 100},
      {headerName: 'Allow Comments', field: 'allow_comments', width: 60},
      {headerName: 'Mandatory', field: 'mandatory', width: 60},
      {headerName: 'Question Type', field: 'question_type', width: 60},
      {headerName: 'Section', field: 'section', width: 100},
      {headerName: 'Answer Code', field: 'answer_code', width: 100},
      {headerName: 'Answer', field: 'answer', width: 100},
      {headerName: 'Score', field: 'score', width: 60}
    ];
  }

  sampleDownload1() {
    /*const questionnaire =  [{C: 'A', H: 'Name', V: 'UP', required: true},
      {C: 'B', H: 'Code', V: 'JWBSK1099866', required: true},
      {C: 'C', H: 'Description', V: 'Activation', required: true},
      {C: 'D', H: 'Period', V: 'RJ42686', required: true},
      {C: 'E', H: 'Start Date', V: this.myDate[0], required: true},
      {C: 'F', H: 'End Date', V: this.myDate[1], required: true},
      {C: 'G', H: 'Global Survey', V: 'TQ01', required: false},
      {C: 'G', H: 'Type', V: 'TQ01', required: false},
    ];*/
    const questionnaire = [
      {
        Name: 'AR-Gold-Off',
        Code: 'AGoldOff',
        Description: 'desc',
        Period: 'once',
        'Start Date': this.myDate[0],
        'End Date': this.myDate[1],
        'Global Survey': false,
        Type: 'retailer',
        'Survey At Retailer': false,
        'Max Score': 0,
        State: 'Karnataka'
      }
    ];

    const question = [
      {
        'Questionnaire Code': 'AGoldOff',
        Name: 'Have you taken action to ensure our stock looks great (dust-free, undamaged, fresh)?',
        Code: 'JANV2025',
        'Allow Comments': false,
        Section: '',
        Sequence: 1,
        Mandatory: false,
        Type: 'Number',
        'Multiple Answer': false,
        'Linked Answer Code': 'A1'
      }
    ];

    const criteria = [
      {
        'Questionnaire Code': 'AGoldOff',
        Type: 'Outlet Type',
        Criteria: 'PROP',
        Attribute: 'attr',
        Value: 'val'
      }
    ];

    const answer = [
      {
        'Question Code': 'JANV2025',
        Code: 'A1',
        Name: '10',
        Score: 100
      }
    ];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(questionnaire);
    const wscols = [{wch: 35}, {wch: 35}, {wch: 20}, {wch: 16}, {wch: 16}, {wch: 16}, {wch: 16}, {wch: 18}, {wch: 16}, {wch: 16}, {wch: 16}];
    ws['!cols'] = wscols;
    this.sharedService.applyExcelColumnStyle(ws, 1, wscols.length);

    const ws1 = XLSX.utils.json_to_sheet(question);
    const wscols1 = [{wch: 35}, {wch: 35}, {wch: 16}, {wch: 16}, {wch: 16}, {wch: 16}, {wch: 16}, {wch: 16}, {wch: 16}, {wch: 18}];
    ws1['!cols'] = wscols1;
    this.sharedService.applyExcelColumnStyle(ws1, 1, wscols1.length);

    const ws2 = XLSX.utils.json_to_sheet(criteria);
    const wscols2 = [{wch: 35}, {wch: 16}, {wch: 16}, {wch: 16}, {wch: 16}];
    ws2['!cols'] = wscols2;
    this.sharedService.applyExcelColumnStyle(ws2, 1, wscols2.length);

    const ws3 = XLSX.utils.json_to_sheet(answer);
    const wscols3 = [{wch: 18}, {wch: 18}, {wch: 18}, {wch: 18}];
    ws3['!cols'] = wscols3;
    this.sharedService.applyExcelColumnStyle(ws3, 1, wscols3.length);

    XLSX.utils.book_append_sheet(wb, ws, "Questionnaire");
    XLSX.utils.book_append_sheet(wb, ws1, "Question");
    XLSX.utils.book_append_sheet(wb, ws2, "Criteria");
    XLSX.utils.book_append_sheet(wb, ws3, "Answer");
    XLSX.writeFile(wb, "questionnaire_upload_template.xlsx");
  }

  sampleDownload2() {
    this.configureMasterData();
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(this.questionnaire_types);
    const ws1 = XLSX.utils.json_to_sheet(this.question_types);
    const ws2 = XLSX.utils.json_to_sheet(this.criteria);
    const ws3 = XLSX.utils.json_to_sheet(this.outlet_types);
    const ws4 = XLSX.utils.json_to_sheet(this.program_types);
    const wscols = [{wch: 35}];
    ws['!cols'] = wscols;
    ws1['!cols'] = wscols;
    ws2['!cols'] = wscols;
    ws3['!cols'] = wscols;
    ws4['!cols'] = wscols;
    this.sharedService.applyExcelColumnStyle(ws, 1, 1);
    this.sharedService.applyExcelColumnStyle(ws1, 1, 1);
    this.sharedService.applyExcelColumnStyle(ws2, 1, 1);
    this.sharedService.applyExcelColumnStyle(ws3, 1, 1);
    this.sharedService.applyExcelColumnStyle(ws4, 1, 1);

    XLSX.utils.book_append_sheet(wb, ws, "Questionnaire Type");
    XLSX.utils.book_append_sheet(wb, ws1, "Question Type");
    XLSX.utils.book_append_sheet(wb, ws2, "Criteria Type");
    XLSX.utils.book_append_sheet(wb, ws3, "Outlet Types");
    XLSX.utils.book_append_sheet(wb, ws4, "Program Types");
    XLSX.writeFile(wb, "master_data.xlsx");
  }

  configureMasterData() {

    this.questionnaire_types = [
      {Type: 'retailer'},
      {Type: 'distributor'},
      {Type: 'area'},
      {Type: 'survey'},
      {Type: 'employee'},
      {Type: 'assessment'},
      {Type: 'retailer kyc'},
      {Type: 'distributor kyc'},
      {Type: 'market intelligence'},
      {Type: 'create retailer'},
      {Type: 'create distributor'},
      {Type: 'create influencer'},
      {Type: 'create project'},
      {Type: 'create lead'},
      {Type: 'influencer'},
      {Type: 'influencer kyc'},
      {Type: 'project'},
      {Type: 'project kyc'},
      {Type: 'lead'},
      {Type: 'lead kyc'},
      {Type: 'task'},
      {Type: 'sales lead'},
      {Type: 'sales lead kyc'},
      {Type: 'asset'},
      {Type: 'meet'},
      {Type: 'service'},
    ];

    this.question_types = [
      {Type: 'text'},
      {Type: 'image'},
      {Type: 'selection'},
      {Type: 'yes/no'},
      {Type: 'location'},
      {Type: 'number'},
      {Type: 'date'},
      {Type: 'single choice'},
      {Type: 'phone'},
      {Type: 'email'}
    ];

    const all_outlet_types = JSON.parse(localStorage.getItem('all_out_types'));
    const all_program_types = JSON.parse(localStorage.getItem('all_program_types'));
    this.outlet_types = [];
    all_outlet_types.forEach(type => {
      this.outlet_types.push({'Outlet Type': type.itemName});
    });
    this.program_types = [];
    all_program_types.forEach(type => {
      this.program_types.push({'Program Type': type.itemName});
    });
    this.criteria = [
      {"Criteria Type": 'Outlet Type'},
      {"Criteria Type": 'Program Type'},
      {"Criteria Type": 'Class'},
      {"Criteria Type": 'Key Outlet'},
      {"Criteria Type": 'Trax Outlet'},
      {"Criteria Type": 'Zero Sales'},
      {"Criteria Type": 'New User'},
      {"Criteria Type": 'Role'},
      {"Criteria Type": 'Virtual Call'},
      {"Criteria Type": 'Retail Attribute'},
      {"Criteria Type": 'Chain Outlet'}
    ];
  }

  constructData(data) {
    this.uploaded_data = [];
    const inThis = this;
    if (!data['Questionnaire'] || data['Questionnaire'].length === 0) {
      this.toastr.error("Questionnaire Sheet Missing");
      this.closeDialog();
    }
    if (!data['Question'] || data['Question'].length === 0) {
      this.toastr.error("Question Sheet Missing");
      this.closeDialog();
    }
    if (!data['Answer'] || data['Answer'].length === 0) {
      this.answerpresent = false;
    } else {
      this.answerpresent = true;
    }
    if (!data['Criteria'] || data['Criteria'].length === 0) {
      this.criteriapresent = false;
    } else {
      this.criteriapresent = true;
    }
    data['Questionnaire'].forEach(questionnaire => {
      const questionnaire_row = {};
      Object.keys(questionnaire).forEach(key => {
        questionnaire_row[key.toString().toLowerCase().replace(" ", "_")] = questionnaire[key];
      });
      if (!questionnaire['Code'] || questionnaire['Code'] === '') {
        inThis.uploaded_data.push(questionnaire_row);
      } else if (data['Criteria'] && data['Criteria'].length > 0) {
        const criteriadata = data['Criteria'].filter(x => x['Questionnaire Code'] === questionnaire.Code);
        if (criteriadata && criteriadata.length > 0) {
          criteriadata.forEach(criteria => {
            const criteria_row = {};
            criteria_row['criteria_type'] = criteria.Type;
            criteria_row['criteria'] = criteria.Criteria;
            criteria_row['attr'] = criteria.Attribute;
            criteria_row['attr_value'] = criteria.Value;
            this.constructQuestionsAndAnswers(data, inThis, questionnaire, questionnaire_row, criteria_row);
          });
        } else {
          this.constructQuestionsAndAnswers(data, inThis, questionnaire, questionnaire_row, []);
        }
      } else {
        this.constructQuestionsAndAnswers(data, inThis, questionnaire, questionnaire_row, []);
      }
    });
    this.validate();
  }

  constructQuestionsAndAnswers(data, inThis, questionnaire, questionnaire_row, criteria_row) {
    const questiondata = data['Question'].filter(x => x['Questionnaire Code'] === questionnaire.Code);
    if (questiondata && questiondata.length > 0) {
      questiondata.forEach(question => {
        const question_row = {};
        question_row['question'] = question.Name;
        question_row['question_code'] = question.Code;
        question_row['sequence'] = question.Sequence;
        question_row['allow_comments'] = question['Allow Comments'];
        question_row['mandatory'] = question.Mandatory;
        question_row['question_type'] = question['Type'];
        question_row['section'] = question.Section;
        question_row['linked_answer_code'] = question['Linked Answer Code'];
        if (data['Answer'] && data['Answer'].length > 0) {
          const answerData = data['Answer'].filter(x => x['Question Code'] === question.Code);
          if (answerData && answerData.length > 0) {
            answerData.forEach(answer => {
              const answer_row = {};
              answer_row['answer'] = answer.Name;
              answer_row['score'] = answer.Score;
              answer_row['answer_code'] = answer.Code;
              inThis.uploaded_data.push({
                ...questionnaire_row,
                ...criteria_row,
                ...question_row,
                ...answer_row
              });
            });
          } else {
            inThis.uploaded_data.push({
              ...questionnaire_row,
              ...criteria_row,
              ...question_row,
            });
          }
        } else {
          inThis.uploaded_data.push({
            ...questionnaire_row,
            ...criteria_row,
            ...question_row,
          });
        }
      });
    } else {
      inThis.uploaded_data.push({
        ...questionnaire_row,
        ...criteria_row
      });
    }
  }

  validate() {
    this.configureMasterData();
    this.rowData = [];
    console.log(this.uploaded_data);
    this.totalRecords = this.uploaded_data.length;
    let uid = 0;
    this.uploaded_data.forEach(data => {
      data.errorMsg = '';
      //Questionnaire fields
      if (!data['name'] || data['name'] === '') {
        this.SetInvalidStatus(data, 'Questionnaire Name Missing', 'name');
        data.errorMsg += 'Questionnaire Name Missing, ';
      }
      if (!data['code'] || data['code'] === '') {
        this.SetInvalidStatus(data, 'Questionnaire Code Missing', 'code');
        data.errorMsg += 'Questionnaire Code Missing, ';
      }
      if (!data['period'] || data['period'] === '') {
        this.SetInvalidStatus(data, 'Period Missing', 'period');
        data.errorMsg += 'Period Missing, ';
      }
      if (!data['type'] || data['type'] === '') {
        data.errorMsg += 'Type Missing, ';
        this.SetInvalidStatus(data, 'Type Missing', 'type');
      } else {
        const questionnaire_type = this.questionnaire_types.find(type => type['Type'] === data['type']);
        if (!questionnaire_type) {
          data.errorMsg += 'Invalid Questionnaire Type, ';
          this.SetInvalidStatus(data, 'Invalid Questionnaire Type', 'type');
        }
      }
      if (!data['start_date'] || data['start_date'] === '') {
        this.SetInvalidStatus(data, 'Start Date Missing', 'start_date');
        data.errorMsg += 'Start Date Missing, ';
      } else {
        data['start_date'] = this.sharedService.convertExcelStringToDate(data['start_date']);
        if (!data['start_date'] || data['start_date'] === '') {
          this.SetInvalidStatus(data, 'Invalid Start Date Format', 'start_date');
          data.errorMsg += 'Invalid Start Date Format, ';
        }
      }
      if (!data['end_date'] || data['end_date'] === '') {
        this.SetInvalidStatus(data, 'End Date Missing', 'end_date');
        data.errorMsg += 'End Date Missing, ';
      } else {
        data['end_date'] = this.sharedService.convertExcelStringToDate(data['end_date']);
        if (!data['end_date'] || data['end_date'] === '') {
          this.SetInvalidStatus(data, 'Invalid End Date Format', 'end_date');
          data.errorMsg += 'Invalid End Date Format, ';
        }
      }
      if (!data.errorMsg.includes('End Date') && !data.errorMsg.includes('Start Date') && new Date(data.start_date) > new Date(data.end_date)) {
        this.SetInvalidStatus(data, 'End Date before Start Date', 'end_date');
        data.errorMsg += 'End Date before Start Date, ';
      }
      if (!data['global_survey'] || data['global_survey'] === '') {
        data.global_survey = false;
      } else {
        if (data['global_survey'].toString().toLowerCase() === 'true') {
          data.global_survey = true;
        } else {
          data.global_survey = false;
        }
      }
      if (!data['survey_at_retailer'] || data['survey_at_retailer'] === '') {
        data.survey_at_retailer = false;
      } else {
        if (data['survey_at_retailer'].toString().toLowerCase() === 'true') {
          data.survey_at_retailer = true;
        } else {
          data.survey_at_retailer = false;
        }
      }
      if (data['max_score'] && data['max_score'] !== '' && !this.numPattern.test(data['max_score'])) {
        this.SetInvalidStatus(data, 'Invalid Max Score', 'max_score');
        data.errorMsg += 'Invalid Max Score, ';
      }

      //Criteria fields
      if (this.criteriapresent) {
        if (!data['criteria'] || data['criteria'] === '') {
          this.SetInvalidStatus(data, 'Criteria Name Missing', 'criteria');
          data.errorMsg += 'Criteria Name Missing, ';
        }
        if (!data['criteria_type'] || data['criteria_type'] === '') {
          this.SetInvalidStatus(data, 'Criteria Type Missing', 'criteria_type');
          data.errorMsg += 'Criteria Type Missing, ';
        } else if (data.valid !== 'Invalid') {
          const criteria = this.criteria.find(type => type['Criteria Type'] === data['criteria_type']);
          if (!criteria) {
            this.SetInvalidStatus(data, 'Invalid Criteria Type', 'criteria');
            data.errorMsg += 'Invalid Criteria Type, ';
          } else {
            if (data['criteria_type'] === 'Outlet Type') {
              const out_type = this.outlet_types.find(type => type['Outlet Type'] === data['criteria']);
              if (!out_type) {
                this.SetInvalidStatus(data, 'Invalid Outlet Type Criteria', 'criteria_type');
                data.errorMsg += 'Invalid Outlet Type Criteria, ';
              }
            }
            if (data['criteria_type'] === 'Program Type') {
              const program_type = this.program_types.find(type => type['Program Type'] === data['criteria']);
              if (!program_type) {
                this.SetInvalidStatus(data, 'Invalid Program Type Criteria', 'criteria_type');
                data.errorMsg += 'Invalid Program Type Criteria, ';
              }
            }
          }
        }
      }

      //Question fields
      if (!data['question'] || data['question'] === '') {
        this.SetInvalidStatus(data, 'Question Name Missing', 'question');
        data.errorMsg += 'Question Name Missing, ';
      } else {
        data['question'] = data['question'].replace(/"/g, "").replace(/'/g, "");
      }
      if (!data['question_code'] || data['question_code'] === '') {
        this.SetInvalidStatus(data, 'Question Code Missing', 'question_code');
        data.errorMsg += 'Question Code Missing, ';
      }
      if (!data['allow_comments'] || data['allow_comments'] === '') {
        data.allow_comments = false;
      } else {
        if (data['allow_comments'].toString().toLowerCase() === 'true') {
          data.allow_comments = true;
        } else {
          data.allow_comments = false;
        }
      }
      if (!data['mandatory'] || data['mandatory'] === '') {
        data.mandatory = false;
      } else {
        if (data['mandatory'].toString().toLowerCase() === 'true') {
          data.mandatory = true;
        } else {
          data.mandatory = false;
        }
      }
      if (!data['question_type'] || data['question_type'] === '') {
        data.valid = 'Invalid';
        data.errorMsg += 'Question Type Missing, ';
      } else {
        const question_type = this.question_types.find(type => type['Type'] === data['question_type']);
        if (!question_type) {
          data.errorMsg += 'Invalid QuestionType, ';
          this.SetInvalidStatus(data, 'Invalid Question Type', 'question_type');
        }
      }

      if (!data['multianswer'] || data['multianswer'] === '') {
        data.multianswer = false;
      } else {
        if (data['multianswer'].toString().toLowerCase() === 'true') {
          data.multianswer = true;
        } else {
          data.multianswer = false;
        }
      }
      if (data['linked_answer_code']) {
        data.note = 'child';
      }

      //Answer fields
      if (this.answerpresent) {
        if (data['answer_code'] && data['answer_code'].toString().trim() !== '') {
          if (!data['answer'] || data['answer'] === '') {
            this.SetInvalidStatus(data, 'Answer Missing', 'answer');
            data.errorMsg += 'Answer Missing, ';
          }
          if (data['score'] && !this.numPattern.test(data['score'])) {
            this.SetInvalidStatus(data, 'Invalid Score', 'score');
            data.errorMsg += 'Invalid Score, ';
          }
        }
        if (data['answer'] && data['answer'].toString().trim() !== '') {
          if (!data['answer_code'] || data['answer_code'] === '') {
            this.SetInvalidStatus(data, 'Answer Code Missing', 'answer_code');
            data.errorMsg += 'Answer Code Missing, ';
          }
          if (data['score'] && !this.numPattern.test(data['score'])) {
            this.SetInvalidStatus(data, 'Invalid Score', 'score');
            data.errorMsg += 'Invalid Score, ';
          }
        }
        if (data['score'] && data['score'].toString().trim() !== '') {
          if (!data['answer'] || data['answer'] === '') {
            this.SetInvalidStatus(data, 'Answer Missing', 'answer');
            data.errorMsg += 'Answer Missing, ';
          }
          if (!data['answer_code'] || data['answer_code'] === '') {
            this.SetInvalidStatus(data, 'Answer Code Missing', 'answer_code');
            data.errorMsg += 'Answer Code Missing, ';
          }
          if (!this.numPattern.test(data['score'])) {
            this.SetInvalidStatus(data, 'Invalid Score', 'score');
            data.errorMsg += 'Invalid Score, ';
          }
        }
      }
      if (data.valid === 'Invalid') {
        this.errorRecords++;
      } else {
        data.valid = 'Valid';
        this.validRecords++;
      }
      data.uid = uid;
      uid++;
      if (data.errorMsg && data.errorMsg.length > 2) {
        data.errorMsg = data.errorMsg.substr(0, data.errorMsg.length - 2);
      }
      this.rowData.push(data);
    });
    this.setCellClassRulesAndTooltips();
  }

  async upload() {
    this.uploadStarted = true;
    this.progress = 0;
    let index = 0;
    let lastIndex = 0;
    this.totalRecords = this.rowData.length;
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = [];
    this.completedRecords = 0;
    const validrecords = this.rowData.filter(x => x.valid === 'Valid');
    if (validrecords && validrecords.length > 0) {
      lastIndex = validrecords.length;
      paramObject.data = this.constructDataToUpload(validrecords);
      await this.progressUpdate(index, lastIndex, 'In Progress');
      const res = await this.apiService.postPromise(this.API_URL, paramObject);
      if (res.hasOwnProperty('results') && res['results'].status === 200) {
        this.errorRecords = 0;
        if (res.results.data.length > 0) {
          await this.updateRowS(index, lastIndex, res.results.data, paramObject.data);
        } else {
          await this.progressUpdate(index, lastIndex, 'Error');
        }
      } else {
        await this.progressUpdate(index, lastIndex, 'Error');
      }
    } else {
      this.toastr.error("No Valid Records to upload");
    }
  }

  async updateRowS(index, lastIndex, questionnaires, uploadeddata) {
    const updatedRows = [];
    questionnaires.forEach(questionnaire => {
      if (questionnaire['status'] && questionnaire['status'] === 'Error') {
        const found = this.rowData.filter(x => x['code'] === questionnaire['code'] && x['valid'] === 'Valid');
        if (found) {
          found.forEach(row => {
            row['status'] = "Error";
            if (questionnaire['errorMsg']) {
              row['errorMsg'] = questionnaire['errorMsg'];
            } else {
              row['errorMsg'] = ' Error inserting Questionnaire';
            }
            updatedRows.push(row);
          });
        }
      } else {
        if (questionnaire.questions) {
          questionnaire.questions.forEach(question => {
            const found = this.rowData.filter(x => x['code'] === questionnaire['code']
              && x['question_code'] === question['code'] && x['valid'] === 'Valid');
            if (question['status']) {
              if (found) {
                found.forEach(row => {
                  row['status'] = question['status'];
                  if (question['status'] === 'Error') {
                    if (question['errorMsg']) {
                      row['errorMsg'] = question['errorMsg'];
                    } else {
                      row['errorMsg'] = ' Error inserting Question';
                    }
                  }
                  updatedRows.push(row);
                });
              }
            } else {
              if (question.ans && question.ans.length > 0) {
                question.ans.forEach(ans => {
                  const found1 = this.rowData.find(x => x['uid'] === ans['uid'] && x['valid'] === 'Valid');
                  if (found1) {
                    if (ans.status) {
                      found1['status'] = ans.status;
                    } else {
                      found1['status'] = "Error";
                    }
                    if (ans.errorMsg) {
                      found1['errorMsg'] = ans.errorMsg;
                    }
                    updatedRows.push(found1);
                  }
                });
              } else {
                found['status'] = "Success";
              }
            }
          });
        }
        if (questionnaire.criteria && questionnaire.criteria.length > 0) {
          questionnaire.criteria.forEach(criteria => {
            const found = this.rowData.filter(x => x['code'] === questionnaire['code']
              && x['criteria'] === criteria['name'] && x['criteria_type'] === criteria['type'] && x['valid'] === 'Valid');
            if (found) {
              found.forEach(row => {
                if (row['status'] !== 'Error') {
                  row['status'] = criteria['status'];
                  if (criteria['status'] === 'Error') {
                    if (criteria['errorMsg']) {
                      row['errorMsg'] = criteria['errorMsg'];
                    } else {
                      row['errorMsg'] = ' Error inserting Criteria';
                    }
                  }
                  updatedRows.push(row);
                }
              });
            }
          });
        }
      }
    });
    if (updatedRows && updatedRows.length > 0) {
      if (this.gridOptions && this.gridOptions.api) {
        this.gridApi.applyTransactionAsync({update: updatedRows}, () => {
          this.uploadCompleted = true;
          this.completedRecords = lastIndex;
          this.progress = 100;
          this.uploadCompleteEmit();
        });
      }
    }
  }

  constructDataToUpload(validrecords) {
    const questionnaires = this.sharedService.unique(validrecords, ['code']);
    const questions = this.sharedService.unique(validrecords, ['code', 'question_code']);
    let criterias = [];
    if (this.criteriapresent) {
      criterias = this.sharedService.unique(validrecords, ['code', 'criteria', 'criteria_type']);
    } else {
      criterias = [];
    }
    let answers = [];
    if (this.answerpresent) {
      answers = this.sharedService.unique(validrecords, ['question_code', 'answer_code']);
    } else {
      answers = [];
    }
    const dataToUpload = [];
    questionnaires.forEach(questionnaire => {
      const indrow: any = {};
      indrow.name = questionnaire['name'];
      indrow.code = questionnaire['code'];
      indrow.description = questionnaire['description'];
      indrow.period = questionnaire['period'];
      indrow.start_date = questionnaire['start_date'];
      indrow.end_date = questionnaire['end_date'];
      indrow.type = questionnaire['type'];
      indrow.survey_at_retailer = questionnaire['survey_at_retailer'];
      indrow.global_survey = questionnaire['global_survey'];
      indrow.max_score = questionnaire['max_score'];
      indrow.questions = [];
      indrow.criteria = [];
      const question = questions.filter(x => x['code'] === questionnaire['code']);
      if (question) {
        question.forEach(quest => {
          const questionrow: any = {};
          questionrow.code = quest['question_code'];
          questionrow.name = quest['question'];
          questionrow.allow_comments = quest['allow_comments'];
          questionrow.section = quest['section'];
          questionrow.sequence = quest['sequence'];
          questionrow.mandatory = quest['mandatory'];
          questionrow.question_type = quest['question_type'];
          questionrow.multianswer = quest['multianswer'];
          questionrow.note = quest['note'];
          if (quest['linked_answer_code']) {
            questionrow.linked_answer_code = quest['linked_answer_code'];
          }
          questionrow.ans = [];
          if (this.answerpresent) {
            const answer = answers.filter(x => x['question_code'] === quest['question_code']);
            if (answer) {
              answer.forEach(ans => {
                const answerrow: any = {};
                if (ans['answer_code'] && ans['answer']) {
                  answerrow.code = ans['answer_code'];
                  answerrow.name = ans['answer'];
                  answerrow.score = ans['score'];
                  answerrow.uid = ans['uid'];
                  questionrow.ans.push(answerrow);
                }
              });
            }
          }
          indrow.questions.push(questionrow);
        });
      }
      if (this.criteriapresent) {
        const criteria = criterias.filter(x => x['code'] === questionnaire['code']);
        if (criteria) {
          criteria.forEach(crit => {
            const criteriarow: any = {};
            if (crit['criteria'] && crit['criteria_type']) {
              criteriarow.name = crit['criteria'];
              criteriarow.type = crit['criteria_type'];
              if (crit['attr']) {
                criteriarow.attribute = crit['attr'];
              }
              if (crit['attr_value']) {
                criteriarow.value = crit['attr_value'];
              }
              indrow.criteria.push(criteriarow);
            }
          });
        }
      }
      dataToUpload.push(indrow);
    });
    console.log(dataToUpload);
    return dataToUpload;
  }
}
