import {Component, OnInit} from "@angular/core";
import {ReportBase} from "../_common/reportbase";
import {MatDialogConfig} from "@angular/material/dialog";
import {ImagePopupComponent} from "../../components/image_popup/image_popup.component";
import {SurveyGalleryPopupComponent} from "../../components/survey_gallery_popup/survey_gallery_popup.component";


@Component({
  selector: 'app-partner-kyc-dump',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})
export class PartnerMarketIntelligenceDumpReportComponent extends ReportBase implements OnInit {

  API_URL = '';
  customFilter: any = {
    name: 'Questionnaire',
    key: 'id',
    value: 0,
    values: [],
    show: true
  };


  ngOnInit(): void {
    this.sharedService.setHeader('Partner Market Intelligence Dump Report');
    let datas: any = {};
    datas = history.state;
    this.userData.customFilter['id'] = datas.surveyID;
    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.userData.type = 'market_intelligence';
    this.loadInBatches(50000, 'id');
    this.showProgressCustomText = true;
    this.showViewSelector = true;
    this.showGalleryview = true;
    this.galleryViewOnlyImages = true;
    this.container = 'partner_answer';
    this.galleryData = {
      pageSize: 50,
      id: 'id',
      image: 'store_fname',
      name: 'survey',
      field1: 'question',
      field2: 'rcode',
      field3: 'retailer',
      status: 'question_code',
      statusTypes: [
        {name: "published", type: "completed"},
        {name: "completed", type: "success"},
        {name: "new", type: "draft"}
      ],
      reviewed: 'status',
      reviewTypes: {
        yes: 'approved',
        no: 'rejected'
      },
      container: this.container,
      selectionCriteria: [
        {
          field: 'status',
          matches: true,
          values: ['completed']
        },
        {
          field: 'reviewed',
          matches: true,
          values: ['new']
        }
      ]
    };
    this.API_URL = '/api/pwa_v2_reports/getPartnerSurveyAnswers';
    this.configureGrid();
    if (this.userData.customFilter['id'] && this.userData.customFilter['id'] !== 0) {
      this.showCustomFilter = false;
      this.loadServerData();
    }
  }

  configureGrid() {
    this.columnDefs = [
      {headerName: "SURVEY", field: "survey", width: 120},
      {headerName: "DATE", field: "completion_date", width: 100},
      {headerName: "MAX SCORE", field: "max_score", width: 100},
      {
        headerName: "Team Details", children: [
          {headerName: "EMP CODE", field: "emp_code", width: 100},
          {headerName: "EMP NAME", field: "salesman", width: 100},
          {headerName: "TEAM", field: "team", width: 100},
          {headerName: "TEAM LEAD", field: "tl_name", width: 100},
          {headerName: "TL CODE", field: "tl_code", width: 100},
          {headerName: "PARENT TEAM", field: "parent_team", width: 100},
          {headerName: "PARENT TEAM LEAD", field: "parent_tl", width: 100},
          {headerName: "PARENT TL CODE", field: "parent_tl_code", width: 100},
        ]
      },
      {
        headerName: "Partner Details", children: [
          {headerName: "Chain", field: "prp_name", width: 100},
          {headerName: "Chain Code", field: "prp_code", width: 100},
          {headerName: "Name", field: "retailer", width: 100},
          {headerName: "Code", field: "rcode", width: 100},
          {headerName: "Type", field: "outlet_type", width: 100},
          {headerName: "Program Type", field: "program_type", width: 100},
          {headerName: "Channel", field: "channel", width: 100, hide: true},
          {headerName: "Beat", field: "beat", width: 100},
          {headerName: "Entity Type", field: "partner_type", width: 100},
          {headerName: "Retailer LAT", field: "r_lat", hide: true, width: 50},
          {headerName: "Retailer LNG", field: "r_long", hide: true, width: 50},
          {headerName: "Activity LAT", field: "a_lat", hide: true, width: 50},
          {headerName: "Activity LNG", field: "a_long", hide: true, width: 50},
          {
            headerName: "ACTIVITY LOCATION", field: "url", maxWidth: 80, menuTabs: [],
            valueGetter(params) {
              if (params.data) {
                if (params.data.r_lat != null && params.data.r_lat !== "0.0") {
                  return 'http://www.google.com/maps/dir/' + params.data.r_lat + ',' + params.data.r_long + '/'
                    + params.data.a_lat + ',' + params.data.a_long;
                } else {
                  return 'http://maps.google.com/maps?q=' + params.data.a_lat + ',' + params.data.a_long;
                }
              }
            },
            cellRenderer: params => {
              if (params.data) {
                if (params.data.a_lat != null && params.data.a_lat !== "0.0") {
                  return '<div style="text-align: center;"> ' +
                    '<span>' +
                    ' <i class="fas fa-map-marker-check fa-2x" style="color:#379862;' +
                    'display: inline-block;\n' +
                    '  vertical-align: middle;padding-bottom: 4px;cursor:pointer;"></i>' +
                    '</span>' +
                    '</div>';
                } else {
                  return '<div style="text-align: center;"> ' +
                    '<span>' +
                    ' <i class="fas fa-map-marker-slash fa-2x" style="color:#D82128;' +
                    'display: inline-block;\n' +
                    '  vertical-align: middle;padding-bottom: 4px;cursor:pointer;"></i>' +
                    '</span>' +
                    '</div>';
                }
              }
            }
          },
        ]
      },
      {
        headerName: "Survey Response", children: [
          {headerName: "QUESTION", field: "question", enablePivot: true, width: 150},
          {headerName: "ANSWER", field: "answer_text", width: 80},
          {headerName: "REMARKS", field: "remarks", width: 100},
          {headerName: "SCORE", field: "ind_score", cellStyle: {textAlign: 'right'}, width: 80},
          {
            headerName: "PHOTO", field: "store_fname", width: 80,
            valueGetter: this.generateImageUrl('store_fname'),
            cellRenderer(params) {
              if (params.data) {
                if (params.data.store_fname === null || params.data.store_fname === "") {
                  return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
                } else {
                  return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
                }
              }
            }
          }
        ]
      }
    ];
  }

  onCellClicked(event) {

    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    console.log(column);
    console.log(rows);
    if (rows !== null && rows.length > 0 && rows[0].hasOwnProperty('store_fname') && rows[0]['store_fname'] !== null && rows[0]['store_fname'] !== "") {
      if (screen.width > 991) {
        const config: MatDialogConfig = {
          width: "100vw",
          height: "100vh",
          maxWidth: "70%",
          maxHeight: "95%",
          data: {
            store_fname: rows[0]['store_fname'],
            container: "partner_answer"
          }
        };

        const creatediaeref = this.dialog.open(ImagePopupComponent, config);
      } else {
        const config: MatDialogConfig = {
          width: "100vw",
          height: "100vh",
          maxWidth: "90%",
          maxHeight: "65%",
          data: {
            store_fname: rows[0]['store_fname'],
            container: "partner_answer"
          }
        };

        const creatediaeref = this.dialog.open(ImagePopupComponent, config);
      }

    } else {

    }
  }

  showDetailPopup() {
    const row = this.rowData.find(x => x.id === this.selectedGalleryItem.id);
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "33%",
        maxHeight: "100%",
        data: {
          surveyDetails: row,
          photo: this.selectedGalleryItem.photo
        },
        panelClass: 'custom-dialog-container'
      };
      const creatediaeref = this.dialog.open(SurveyGalleryPopupComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "95%",
        maxHeight: "100%",
        data: {
          surveyDetails: row,
          photo: this.selectedGalleryItem.photo
        },
        panelClass: 'custom-dialog-container'
      };
      const creatediaeref = this.dialog.open(SurveyGalleryPopupComponent, config);
    }
  }

}
