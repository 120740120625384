import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {DetailPageBase} from "../../../pagebase/DetailPageBase";
import {DeleteComponent} from "../../../deleteCell.component";
import {NgSelectComponent} from "@ng-select/ng-select";
import {CellEditingStartedEvent, CellEditingStoppedEvent, GetRowIdFunc, GetRowIdParams, GridOptions, RowEditingStartedEvent, RowEditingStoppedEvent} from "@ag-grid-enterprise/all-modules";
import {NumericEditorComponent} from "../../../pagebase/components/NumericEditorComponent";
import {ErrorHandler} from "../../../error/error-handler";
import {DMSBasePage} from "../../../pagebase/components/DMSBasePage";
import {MatDialogConfig} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../../../components/confirmdialog/confirmdialog.component";
import {BulkProductSelectPopupComponent} from "../../../components/bulk_product_select_popup/bulk_product_select_popup.component";
import {DiscountPopupComponent} from "../../discount_popup/discount_popup.component";
import {VansaleSettlePopupComponent} from "../settle_popup/settle_vansale.component";
import {ApproveRejectCommentsPopupComponent} from "../../../components/approve_reject_comments_popup/approve_reject_comments_popup.component";


@Component({
  selector: 'app-settlement-detail',
  templateUrl: 'settlement_detail.html',
  styleUrls: ['../../../pagebase/pagebase_detail.scss']
})

export class SettlementDetailComponent extends DetailPageBase implements IDetailPage, OnInit {
  dateDisable;
  myDate;

  gridOptions2: GridOptions;
  columnDefs2 = [];
  frameworkComponents2: any = {};
  rowData2 = [];
  public gridColumnApi2: any;
  public gridApi2: any;

  settlement;
  show_outstanding = false;
  partial_approve = false;
  expense_hide = true;
  bonus = 0;
  penalty = 0;
  total_return = 0;

  public feature_block_settle = false;
  public feature_allow_marksettled = false;

  settle_dev = false;
  settle_live_dev = false;
  payment_mismatch = false;
  settled = false;
  stock_neg = false;

  ngOnInit(): void {
    // this.setTitle('Return Invoice');
    this._Detail_GET_URL = "/api/pwa_van_sales_connects/get_eod_summary_detail";
    this.context = {componentParent: this};
    this.sharedService.setHeader('EOD Settlement');

    this.configureGrid();

    // this.initialize_default_values();

    // this.loadMasterDataForView(() => {
    this.readPageParms(params => {


      if (this.activeID > 0) {
        this.newMode = false;
        this.editMode = false;
        //   View Mode
        //   Page Can be ready only after detail Object is fetched

        this.loadPageForView(() => {
          this.pageReady = true;
          this.enableDisableGridEdit();
        });

      } else {
        //TODO: throw Error
      }


    });
  }

  enableDisableGridEdit() {
    this.gridOptions.suppressClickEdit = !this.editMode;
    this.gridOptions.singleClickEdit = this.editMode;
  }

  loadPage() {
    this.pageReady = false;
    this.loadPageForView(() => {
      this.pageReady = true;
    });
  }


  initialize_default_values() {
    this.feature_block_settle = this.sharedService.ACLcheck('feature_block_settle');
    this.feature_allow_marksettled = this.sharedService.ACLcheck('feature_allow_marksettled');
  }

  loadPageForView(callback) {

    this.loadActiveObject(() => {
      if (this.activeObject && this.activeObject['van_stock'] && this.activeObject.van_stock.length > 0) {
        this.rowData = this.prepareLineData(this.activeObject.van_stock, this.activeObject.invoices);
        this.rowData2 = this.activeObject.van_stock;


        // this.gridOptions.api.setRowData(this.rowData);
        // this.gridOptions2.api.setRowData(this.rowData2);

        this.dataLoaded = true;
      }

      callback();
    });

  }


  prepareLineData(lineItems, invoices) {
    console.log(invoices);
    console.log(lineItems);
    return invoices;
  }


  isExternalFilterPresent(): boolean {
    // to hide based on deleted flag
    return true;
  }

  doesExternalFilterPass(node: any): boolean {
    if (node.data) {
      return !(node.data.deleted);
    }
    return true;
  }

  //Page Events



  //GRID Functions
  configureGrid() {
    const inThis = this;
    this.columnDefs = [
      {headerName: 'ID', field: 'id', hide: true},
      {headerName: 'TIME', field: 'create_date', width: 50},
      {headerName: 'RETAILER', field: 'partner_name', width: 80},
      {headerName: 'CODE', field: 'retailer_code', width: 40},
      {headerName: 'VALUE', field: 'amount_total', width: 40},
      {headerName: 'Invoice No', field: 'invoice_no', width: 40},
      {headerName: 'Comments', field: 'comments', width: 40},
      {headerName: 'PHOTO', field: 'photo', width: 40},
      {headerName: 'POD', field: 'pod', width: 40},

    ];
    this.frameworkComponents = {
      cellDeleteRenderer: DeleteComponent,
    };

    this.columnDefs2 = [
      {headerName: 'ID', field: 'id', hide: true},
      {headerName: 'CODE', field: 'code', width: 50},
      {headerName: 'PRODUCT', field: 'name', width: 80},
      {headerName: 'LOAD-IN', field: 'loadin', width: 40},
      {headerName: 'LOAD-OUT', field: 'loadout', width: 40},
      {headerName: 'OPENING', field: 'opening', width: 40},
      {headerName: 'SALES', field: 'sales', width: 40},
      {headerName: 'DAMAGE', field: 'damage', width: 40},
      {headerName: 'CLOSING', field: 'sample', width: 40},
      {headerName: 'SALES AMOUNT', field: 'closing', width: 40},
      {headerName: 'RETURN AMOUNT', field: 'deviation', width: 40},
      {headerName: 'LIVE STOCK', field: 'livestock', width: 40},
    ];


    this.gridOptions2 = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      rowGroupPanelShow: 'always',
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        }
      }
      /* onColumnVisible: this.onColumnVisible,
       onDragStopped : this.onDragStopped*/
    } as GridOptions;

  }

  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.uid;
  }


  getPrintDetails() {
    this.appComponent.printAll([this.activeObject], 'id',  '/api/pwa_connects/get_order_print_details', null);
  }

  onGridReady2(params) {
    this.gridOptions2.api.showLoadingOverlay();
    this.gridApi2 = params.api;
    this.gridColumnApi2 = params.columnApi;
    params.api.setColumnDefs(this.columnDefs2);
    params.api.setRowData(this.rowData2);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (this.gridApi2) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    } else {
      params.columnApi.autoSizeColumns();
    }

    setTimeout(() => {
      const pinnedBottomData = this.sharedService.generatePinnedBottomData(params.api, params.columnApi);
      params.api.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);

    // const searchtext = document.getElementById('filter-text-box')['value'];
    // if (searchtext && searchtext !== "") {
    //   // this.quickSearch();
    // }
  }


  //Page core functions


  checkEodSettleShow() {
    return true;
  }


  action_revert_eod() {

    if (this.activeObject && this.activeID > 0) {
      const contenxt = {};
      contenxt['access_token'] = this.userContext.access_token;
      contenxt['data'] = {};


      contenxt['data']['id'] = this.activeID;
      this.apiService.post('/api/pwa_van_sales_connects/revertVanStatus', contenxt)
        .subscribe(res => {
            if (res.hasOwnProperty('results') && (res.results.status === 200)) {

              if (res.results.data) {
                this.activeObject['state'] = res.results.data['state'];
                this.toastr.success('Record Saved! : ' + this.activeID);
              } else {
                this.toastr.warning('Something Went Wrong, Contact support! : ');
              }


            } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
              this.handleError(ErrorHandler.getErrorObject(res.results.msg));
            } else {
              this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
            }
          },
          error => {
            this.handleError(ErrorHandler.getErrorObject(error));
          });

    } else {
      this.toastr.success('No Van sale Id Not Found');
      return;
    }

  }


  settle_van_sale(confirmation_data) {
    if (this.activeObject && this.activeID > 0) {
      const contenxt = {};
      contenxt['access_token'] = this.userContext.access_token;
      contenxt['data'] = {};


      contenxt['data']['id'] = this.activeID;
      contenxt['data']['apr_comments'] = confirmation_data.apr_comments;
      contenxt['data']['amt_settled'] = confirmation_data.amt_settled;

      contenxt['feature_disable_settle_approve'] = this.sharedService.ACLcheck('feature_disable_settle_approve');
      this.apiService.post('/api/pwa_van_sales_connects/mark_settlement', contenxt)
        .subscribe(res => {
            if (res.hasOwnProperty('results') && (res.results.status === 200)) {

              if (res.results.data) {
                this.activeObject['state'] = res.results.data['state'];

                //TODO: Get rid of the extra variable and use active object all places
                this.settled = true;

                this.toastr.success('Record Saved! : ' + this.activeID);
              } else {
                this.toastr.warning('Something Went Wrong, Contact support! : ');
              }


            } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
              this.handleError(ErrorHandler.getErrorObject(res.results.msg));
            } else {
              this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
            }
          },
          error => {
            this.handleError(ErrorHandler.getErrorObject(error));
          });

    } else {
      this.toastr.success('No Van sale Id Not Found');
      return;
    }
  }


  getSettleMainErrorMessage() {
    if (this.feature_block_settle && (this.settle_dev || this.settle_live_dev) && !this.settled) {
      return "Cannot settle due to stock mismatch";
    } else if (this.feature_block_settle && this.payment_mismatch && !this.settled) {
      return "Cannot settle due to payment mismatch";
    // } else if (this.feature_block_settle && this.payment_logs && !this.settled) {
    //   return "Cannot settle due to Stock Log mismatch";
    // } else if (this.feature_block_settle && !this.is_return_approved && this.eod_status === 'complete') {
    //   return "Cannot settle due to pending return approval";
    // } else if (this.feature_block_settle && this.collection_mismatch && !this.settled) {
    //   return "Cannot settle due to Collection payment mismatch";
    // } else if (!this.settled && this.stock_neg) {
    //   return "Cannot settle due to Negative stock";
    // } else if (this.feature_block_settle && this.pending_pod_count > 0 && this.podApproveRequired && this.eod_status === 'complete') {
    //   return "Cannot settle due to Pending POD";
    // } else if (this.feature_block_settle && this.pending_einvoice_count > 0 && this.eod_status === 'complete') {
    //  return "Cannot settle due to Pending E-Invoice";
    // } else if (this.sapSync && this.countryMissing) {
    //   let missingListString = '';
    //   for (const retailer of this.countryMissingRetailers) {
    //     if (missingListString === '') {
    //       missingListString = retailer.toString();
    //     } else {
    //       missingListString = missingListString + ', ' + retailer;
    //     }
    //   }
    //   return "State mapping missing for " + this.countryMissingRetailers.size + " Retailers - " + missingListString;
    }
  }

  getSettleSupportErrorMessage() {
    if (this.feature_block_settle && !this.settled && this.stock_neg) {
      return "*contact Admin/support team";
    } else if (!this.feature_block_settle && (this.settle_dev || this.settle_live_dev) && !this.settled) {
      return "Kindly validate the stock mismatch";
    } else if (this.settle_dev && !this.settled) {
      return "*Try reversing the EoD status and Marking EoD again from mobile.If that doesnt help contact Admin/support team";
    } else if (this.settle_live_dev && !this.settled) {
      return "*Live Stock is not correct.contact Admin/support team";
    } else if (!this.feature_block_settle && this.payment_mismatch && !this.settled) {
      return "Kindly validate the payment mismatch";
    } else if (this.payment_mismatch && !this.settled) {
      return "*contact Admin/support team";
    // } else if (!this.feature_block_settle && this.payment_logs && !this.settled) {
    //   return "Kindly validate the stocklog mismatch";
    // } else if (this.payment_logs && !this.settled) {
    //   return "*contact Admin/support team";
    // } else if (this.feature_block_settle && this.collection_mismatch && !this.settled) {
    //   return "*contact Admin/support team";
    // } else if (this.pending_einvoice_count > 0 && this.eod_status === 'complete') {
    //   return "Regenerate Invoice for Pending E-Invoices";
    // } else if (this.sapSync && this.countryMissing) {
    //   return "Kindly map States for above Retailers";
    }
  }


  action_settle() {
    let title = '';
    let message1 = '';
    let message2 = '';
    let buttonText = '';


    title = "Settle Van";
    message1 = "Are you sure you want to Settle the Van?";
    message2 = "Please provide Approval Comments";
    buttonText = "Settle";


    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: 'auto',
        maxWidth: "50%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          title,
          message1,
          message2,
          amount: this.activeObject['amt_settled'],
          buttonText
        }
      };
      const creatediaeref = this.dialog.open(VansaleSettlePopupComponent, config);
      creatediaeref.componentInstance.submitClicked.subscribe(data => {
        if (data.amt_settled && data.comments) {
          this.settle_van_sale(data);
        }
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: 'auto',
        maxWidth: "95%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          title,
          message1,
          message2,
          amount: this.activeObject['amt_settled'],
          buttonText
        }
      };
      const creatediaeref = this.dialog.open(VansaleSettlePopupComponent, config);
      creatediaeref.componentInstance.submitClicked.subscribe(data => {
        if (data.amt_settled && data.comments) {
          this.settle_van_sale(data);
        }
      });
    }
  }




}
