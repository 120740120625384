import {Component, ElementRef, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {SharedService} from "../../_services/shared_service";

@Component({
  selector: 'app-attendance-image-popup',
  templateUrl: './attendance_image_popup.component.html',
  styleUrls: ['./attendance_image_popup.component.scss']

})

export class AttendanceImagePopupComponent implements OnInit {

  @Output() nextClick: EventEmitter<any> = new EventEmitter<any>();
  //public photo;
  photo: SafeUrl = './../../../assets/no-image-set.svg';
  store_fname;
  API_URL = '';
  container = '';
  user_data = {
    date: null,
    access_token: null,
    url: null,
    data: {
      start_date: null,
      end_date: null,
      id: null
    }
  };
  latitude = 0.0;
  longitude = 0.0;
  staticMapUrl;
  mapKey = '';
  outletAddress = 'BUGIPURA CHOWK MOGA PUNJAB 142001,Moga,Moga,Punjab,142001';
  rowData: any = [];
  rowIndex = 0;
  attendanceTime = '';
  attendanceDate = '';
  empName = '';
  empCode = '';

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private domSanitizer: DomSanitizer,
              public sharedService: SharedService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.rowData = dialogdata.rowData;
    this.rowIndex = dialogdata.rowIndex;
    this.mapKey = dialogdata.mapKey;
    this.container = dialogdata.container;
    this.attendanceDate = this.rowData[0].date;
    this.processData();
  }

  processData() {
    const lat = this.rowData[this.rowIndex].latitude ? this.rowData[this.rowIndex].latitude : 0.0;
    const long = this.rowData[this.rowIndex].longitude ? this.rowData[this.rowIndex].longitude : 0.0;
    this.store_fname = this.rowData[this.rowIndex].store_fname ? this.rowData[this.rowIndex].store_fname : '';
    this.attendanceTime = this.rowData[this.rowIndex].att_time;
    this.empName = this.rowData[this.rowIndex].name;
    this.empCode = this.rowData[this.rowIndex].emp_code;
    this.latitude = lat;
    this.longitude = long;
    this.outletAddress = this.rowData[this.rowIndex].outlet_address ? this.rowData[this.rowIndex].outlet_address : '';
    this.staticMapUrl = 'https://maps.googleapis.com/maps/api/staticmap?center=' + this.latitude + ',' + this.longitude +
      '&zoom=19&size=400x400&markers=color:red|label:S|' + this.latitude + ',' + this.longitude + '&key=' + this.mapKey;
    if (this.store_fname !== null && this.store_fname !== '') {
      this.fetchImage();
    } else {
      this.photo = './../../../assets/no-image-set.svg';
    }
  }

  ngOnInit(): void {
    const inThis = this;
    window.addEventListener("keydown", event => {
      switch (event.key) {
        case "Left":
          inThis.fetchPreviousData();
          break;
        case "ArrowLeft":
          inThis.fetchPreviousData();
          break;
        case "Right":
          inThis.fetchNextData();
          break;
        case "ArrowRight":
          inThis.fetchNextData();
          break;
        default:
          return; // Quit when this doesn't handle the key event.
      }

      // Cancel the default action to avoid it being handled twice
      event.preventDefault();
    }, true);
  }

  fetchImage() {
    this.API_URL = '/api/pwa_connects/fetch_file';
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.store_fname = this.store_fname;
    paramObject.container = this.container;
    console.log(paramObject);
    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
          console.log(res);

          if (res.hasOwnProperty('result') && res.result.hasOwnProperty('data')) {
            this.photo = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + res['result'].data);
          } else {
            this.photo = './../../../assets/no-image-set.svg';
          }
        },
        error => {
          console.log(error);

        });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  fetchPreviousData() {
    //this.nextClick.emit('previous');
    if (this.rowIndex !== 0) {
      this.rowIndex = this.rowIndex - 1;
      this.processData();
    }
  }

  fetchNextData() {
    //this.nextClick.emit('next');
    if (this.rowIndex !== this.rowData.length - 1) {
      this.rowIndex = this.rowIndex + 1;
      this.processData();
    }
  }
}
