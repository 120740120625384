import {Component, ElementRef, Inject, OnInit} from "@angular/core";
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {DatePipe} from "@angular/common";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-survey-gallery-popup',
  templateUrl: './survey_gallery_popup.component.html'

})

export class SurveyGalleryPopupComponent implements OnInit {

  surveyDetails: any = {};
  photo;

  constructor(public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.surveyDetails = dialogdata.surveyDetails;
    this.photo = dialogdata.photo;
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialog.closeAll();
  }

}
