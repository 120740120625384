import { Component, OnInit, ElementRef, Renderer2, Input } from '@angular/core';
import { Router } from '@angular/router';
import {ApiService} from "../../_services/api.service";
import {SharedService} from "../../_services/shared_service";

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
  @Input() order_detail: object;
  public math = Math;

  constructor(private elRef : ElementRef,
              private renderer: Renderer2,
              private router: Router,
              public apiService: ApiService,
              public sharedService: SharedService) { }

  ngOnInit() {
  }

}
