import {
  AfterViewInit,
  Component,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ICellEditorParams } from "@ag-grid-enterprise/all-modules";
import {ICellEditorAngularComp} from "@ag-grid-community/angular";

// backspace starts the editor on Windows
const KEY_BACKSPACE = 'Backspace';
const KEY_F2 = 'F2';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';

@Component({
  selector: 'app-numeric-cell',
  template: `
    <input *ngIf="editMode && !isPinnedBottomRow"
           #input
           (keydown)="onKeyDown($event)"
           [(ngModel)]="value"
           class="custom-inputbox"
    />
  <span *ngIf="showUOM && !isPinnedBottomRow" class="element-req" style="margin-left: 5px;">{{packDisplay}}</span>`,
})

//<span style="border: solid 1px #F5F7F9;border-radius: 2px;padding: 4px 2px 2px 4px; text-align: right; width: 50px;background-color: #D7DDE6">{{value}}</span>
export class UOMProductNumericEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  private params: any;
  public value!: number;
  public highlightAllOnFocus = true;
  private cancelBeforeStart = false;
  public editMode = false;
  public context;
  public packUOM;
  packSize = 0;
  attributeJson;
  showUOM;
  isPinnedBottomRow = false;
  packDisplay = '';

  @ViewChild('input', { read: ViewContainerRef })
  public input!: ViewContainerRef;

  agInit(params: ICellEditorParams): void {
    this.params = params;
    console.log(this.params);
    if (params && params.colDef && params.colDef.field === 'pack') {
      this.showUOM = true;
    } else {
      this.showUOM = false;
    }
    if (params && params.node && params.node.rowPinned === 'bottom') {
      this.isPinnedBottomRow = true;
    } else {
      this.isPinnedBottomRow = false;
    }
    if (params && params.data && params.data.attribute_json) {
      if (params.data.attribute_json.constructor !== Object) {
        try {
          this.attributeJson = JSON.parse(params.data.attribute_json);
        } catch (e) {
        }
      } else {
        this.attributeJson = params.data.attribute_json;
      }
      if (this.attributeJson && this.attributeJson.constructor === Object) {
        if (this.attributeJson.hasOwnProperty('alt_uom_name')) {
          this.editMode = true;
          this.packUOM = this.attributeJson.alt_uom_name;
        }
        if (this.attributeJson.hasOwnProperty('alt_uom_size')) {
          this.packSize = this.attributeJson.alt_uom_size;
        } else {
          this.packSize = 0;
        }
      }
    }
    if (this.packSize && this.packSize > 0) {
      this.packDisplay = this.packUOM + ' (' + this.packSize + ' units)';
    } else {
      this.packDisplay = this.packUOM;
    }
    if (!this.showUOM) {
      this.editMode = true;
    }
    this.setInitialState(this.params);

    // only start edit if key pressed is a number, not a letter
    this.cancelBeforeStart = !!(
      params.charPress && '1234567890'.indexOf(params.charPress) < 0
    );

   /* if (this.context) {
      this.editMode = this.context.editMode;
    }*/
  }

  setInitialState(params: ICellEditorParams) {
    let startValue;
    let highlightAllOnFocus = true;

    if (params.eventKey === KEY_BACKSPACE) {
      // if backspace or delete pressed, we clear the cell
      startValue = '';
    } else if (params.charPress) {
      // if a letter was pressed, we start with the letter
      startValue = params.charPress;
      highlightAllOnFocus = false;
    } else {
      // otherwise we start with the current value
      startValue = params.value;
      if (params.eventKey === KEY_F2) {
        highlightAllOnFocus = false;
      }
    }

    this.value = startValue;
    this.highlightAllOnFocus = highlightAllOnFocus;
  }

  getValue(): any {
    return this.value;
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  // will reject the number if it greater than 1,000,000
  // not very practical, but demonstrates the method.
  isCancelAfterEnd(): boolean {
    return this.value > 1000000;
  }

  onKeyDown(event: any): void {
    if ((this.isLeftOrRight(event) || this.isBackspace(event))) {
      event.stopPropagation();
      return;
    }

    if (
      !this.finishedEditingPressed(event) &&
      !this.isKeyPressedNumeric(event)
    ) {
      if (event.preventDefault) { event.preventDefault(); }
    }
    if (this.finishedEditingPressed(event)) {
      this.params.context.componentParent.UOMList = this.params.context.componentParent.UOMMasterList.filter(item => item.category_id === this.params.data.uom_category_id);
      if (this.showUOM) {
        this.params.data.quantity = (parseInt(String(this.value), 10) * this.packSize) + parseInt(this.params.data.units, 10);
      } else {
        this.params.data.quantity = (parseInt(String(this.params.data.pack), 10) * this.packSize) + parseInt(String(this.value), 10);
      }
      this.params.data.price_subtotal = parseFloat(this.params.data.price_unit) * parseInt(this.params.data.quantity, 10);
      const prod_match = this.params.context.componentParent.cartItems.find(item => item.product_id === this.params.data.product_id);
      if (!prod_match) {
        let cartlength = 0;
        if (this.params.context.componentParent.cartItems) {
          cartlength = this.params.context.componentParent.cartItems.length;
        }
        this.params.data['uid'] = cartlength + 10;
      }
      this.params.context.componentParent.cartItems = this.params.context.componentParent.rowData.filter(x => x.quantity > 0);
      this.params.data['mid'] = this.params.context.componentParent.get_mid( this.params.context.componentParent.cartItems.length);
      this.params.context.componentParent.compute_scheme_and_tax(this.params.data['uid']);
      this.updateRowData();
      this.input.element.nativeElement.blur();
      this.params.context.componentParent.gridApi.tabToNextCell();
      if (this.params.context.componentParent.cartItems) {
        this.params.context.componentParent.cartItems =   this.params.context.componentParent.rowData.filter(x => x.quantity > 0);
      }
      console.log(this.params.data);
      setTimeout(() => {
        const pinnedBottomData = this.params.context.componentParent.generatePinnedBottomData();
        this.params.context.componentParent.gridApi.setPinnedBottomRowData([pinnedBottomData]);
      }, 500);
    }
  }
  updateRowData() {
    if (this.params.context.componentParent.cartItems ) {
      this.params.context.componentParent.cartItems.forEach(item => {
        const found = this.params.context.componentParent.rowData.find(x => x.product_id === item.product_id);
        found.uid = item.uid;
        found.price_total = item.price_total;
        found.price_subtotal = item.price_subtotal;
        found.price_tax = item.price_tax;
      });
    }
  }
  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  ngAfterViewInit() {
    if (this.editMode) {
      window.setTimeout(() => {
        //this.input.element.nativeElement.focus();
        if (this.highlightAllOnFocus) {
          if (this.input && this.input.element) {
            this.input.element.nativeElement.select();
          }
          this.highlightAllOnFocus = false;
        } else {
          // when we started editing, we want the caret at the end, not the start.
          // this comes into play in two scenarios:
          //   a) when user hits F2
          //   b) when user hits a printable character
          const length = this.input.element.nativeElement.value
            ? this.input.element.nativeElement.value.length
            : 0;
          if (length > 0) {
            this.input.element.nativeElement.setSelectionRange(length, length);
          }
        }

        //this.input.element.nativeElement.focus();
      });
    }
  }

  private isCharNumeric(charStr: string): boolean {
    return !!/\d/.test(charStr);
  }

  private isKeyPressedNumeric(event: any): boolean {
    const charStr = event.key;
    return this.isCharNumeric(charStr);
  }

  private isBackspace(event: any) {
    return event.key === KEY_BACKSPACE;
  }

  private isLeftOrRight(event: any) {
    return ['ArrowLeft', 'ArrowRight'].indexOf(event.key) > -1;
  }

  private finishedEditingPressed(event: any) {
    const key = event.key;
    return key === KEY_ENTER || key === KEY_TAB;
  }
}
