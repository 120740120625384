import {PageBase} from "../../../pagebase/pagebase";
import {Component, OnInit} from "@angular/core";


@Component({
  selector: 'app-pay-receipt-list',
  templateUrl: '../../../pagebase/pagebase.html',
  styleUrls: ['../../../pagebase/pagebase.scss']
})

export class PaymentReceiptListComponent extends PageBase implements OnInit, IPage {

  customFilter: any = {
    name: 'Status',
    key: 'status',
    value: 'draft',
    values: [],
    show: true
  };

  matActionMenuItemDeclaration = [{name: 'Approve Payment'}, {name: 'Reject Payment'}]; //, feature: 'action_invoice_create'

  ngOnInit(): void {

    this.sharedService.setHeader('Payment Receipts');
    this.showActionButton = true;
    this.customFilter.values = [
      {id: 'all', itemName: 'All'},
      {id: 'draft', itemName: 'draft'},
      {id: 'posted', itemName: 'posted'},
    ];
    this.showCustomFilter = true;
    this.showPinnedFooter = true;
    this.columnsWithAggregation = ['amount'];
    this.checkAndLoadPreviousSate();
    this.getAuthorizedActions();
    this.checkAccessControl();
    this.configureGrid();
    this.loadServerData();
  }

  configureGrid() {

    this.API_URL = '/api/pwa_dms_connects/get_payment_receipts';
    this.columnDefs = [
      {headerName: "ID", field: "id", width: 30, hide: true},
      {headerName: "NAME", field: "name", width: 50,  checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true},
      {headerName: "DATE", field: "pay_date", width: 60},
      {headerName: "CUSTOMER CODE", field: "cust_code", width: 100},
      {headerName: "CUSTOMER NAME", field: "cust_name", width: 250},
      {headerName: "PAYMENT TYPE", field: "payment_type", width: 50, hide: true},
      {headerName: "AMOUNT", field: "amount", width: 100,  aggFunc: 'sum', cellStyle: {textAlign: 'right'}, valueFormatter: params => this.formatNumber(params.value)},
      {headerName: "STATE", field: "state", width: 80},
      {headerName: "COLLECTED BY", field: "user_name", width: 100},
      {headerName: "REVIEWED BY", field: "rev_user", width: 100},
      {headerName: "REVIEWED DATE", field: "rev_date", width: 100},
      {headerName: "REF ID", field: "ref_id", width: 80},
      {headerName: "CHEQUE NO", field: "cheque_no", width: 80},
      {headerName: "CHEQUE DATE", field: "cheque_date", width: 80},
      {headerName: "BRANCH", field: "branch_name", width: 80},
      {headerName: "UPI ID", field: "upi_id", width: 80},
      {headerName: "CARD NO", field: "card_no", width: 80},
      {headerName: "CARD NAME", field: "card_name", width: 80},
      {headerName: "NARRATION", field: "comments", width: 100},
    ];

    this.getRowId = (params) => params.data.id;
  }

  // onCellClicked(event) {
  //   const rows = this.gridApi.getSelectedRows();
  //   const filteredRowData = this.gridApi.rowModel.rowsToDisplay;
  //   if (filteredRowData !== null && filteredRowData.length > 0) {
  //     const ids = [];
  //     filteredRowData.forEach(r => {
  //       ids.push(r.data.id);
  //     });
  //     localStorage.setItem('active_ids', JSON.stringify(ids));
  //     this.router.navigate([this.apiService.redirect_url + '/dms/sale_invoice'], {
  //       relativeTo: this.route,
  //       queryParams: {id: rows[0].id}
  //     });
  //   }
  // }

  checkAccessControl() {
    // this._localCacheService.getAccessControlList().then(list => {
    this.showCreate = false;
    // });
  }

  openCreateDialog() {
    // this.router.navigate([this.apiService.redirect_url + '/dms/sale_invoice'], {
    //   relativeTo: this.route,
    //   queryParams: {id: 0 }
    // });
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    const filteredRowData = this.gridApi.rowModel.rowsToDisplay;
    if (filteredRowData !== null && filteredRowData.length > 0) {
      const ids = [];
      filteredRowData.forEach(r => {
        ids.push(r.data.id);
      });
      localStorage.setItem('active_ids', JSON.stringify(ids));
      this.router.navigate([this.apiService.redirect_url + '/dms/payment'], {
        relativeTo: this.route,
        queryParams: {id: rows[0].id}
      });
    }
  }

  onMenuItemSelected(menuitem) {
    const rows = this.gridApi.getSelectedRows();
    const supplier_ids = [];
    const supplier_territory_ids = [];

    const ids = [];
    rows.forEach(r => {
      // if (r.state === 'draft') {
        ids.push(r.id);
      // }
    });

    if (ids.length === 0) {
      this.toastr.error('Please select at least 1 Payment entry in draft');
    } else if (ids.length > 1) {
      this.toastr.error('Please select a maximum of 1 records');
    } else {

      if (!ids[0]['supplier_id'] || ids[0]['supplier_id'] <= 0) {
        this.toastr.warning('Supplier id Missing');
      }
      if (!ids[0]['partner_id'] || ids[0]['partner_id'] <= 0) {
        this.toastr.warning('partner_id id Missing');
      }
      if (!ids[0]['config_json'] || ids[0]['config_json'] <= 0) {
        this.toastr.warning('config_json Missing');
      }

      if (menuitem.name === 'Approve Payment') {
        this.PostPayment(ids[0]);

      } else if (menuitem.name === 'Reject Payment') {
        this.RejectPayment(ids[0]);
      }
    }

  }

  PostPayment(record) {

  }

  RejectPayment(record) {

  }
}
