import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ApiService} from "../../_services/api.service";

/*import {IDropdownSettings} from "ng-multiselect-dropdown";*/

@Component({
  selector: 'app-components-multiselect-filter',
  templateUrl: './multiselect-filter.component.html',
  styleUrls: ['./multiselect-filter.component.scss']

})

export class MultiSelectFilterComponent implements OnInit {

  state: [];

  selectedData: any = {};
  selectedDataString: any = {};
  /*dropdownSettings: IDropdownSettings;*/
  @Input() multiFilters: any;
  @Output() onsetMultiFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() onsetMultiFilterString: EventEmitter<any> = new EventEmitter<any>();

  constructor(private apiService: ApiService) {
    /* this.dropdownSettings = {
       singleSelection: false,
       idField: 'id',
       textField: 'itemName',
       selectAllText: 'Select All',
       unSelectAllText: 'UnSelect All',
       itemsShowLimit: 3,
       allowSearchFilter: true
     };*/
  }

  ngOnInit(): void {

    this.selectedData[this.multiFilters.key] = this.multiFilters.value;
  }

  setMultiFilter($event) {
    this.onsetMultiFilter.emit(this.selectedData);
  }

  onItemSelect(item: any) {
    console.log(item);
  }

  onSelectAll(items: any) {
    console.log(items);
  }

  shareCheckedList(itemObject: any[]) {
    this.selectedData = [];
    this.selectedDataString = [];
    const inThis = this;
    for (const item of itemObject) {
      let indItem: any = {};
      indItem = inThis.multiFilters.values.find(existed => existed.itemName === item);
      if (indItem !== null && indItem !== undefined) {
        inThis.selectedData.push(indItem);
        inThis.selectedDataString.push(indItem.id);
      }
    }
    console.log(this.selectedData);
    this.onsetMultiFilter.emit(this.selectedData);
    this.onsetMultiFilterString.emit(this.selectedDataString);
  }

  shareIndividualCheckedList(item: {}) {
  }


}
