import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {DetailPageBase} from "../../../pagebase/DetailPageBase";
import {DeleteComponent} from "../../../deleteCell.component";
import {NgSelectComponent} from "@ng-select/ng-select";
import {UOMRendererComponent} from "../../../pagebase/components/UOMRendererComponent";
import {CellEditingStartedEvent, CellEditingStoppedEvent, RowEditingStartedEvent, RowEditingStoppedEvent} from "@ag-grid-enterprise/all-modules";
import {NumericEditorComponent} from "../../../pagebase/components/NumericEditorComponent";
import {MatDialogConfig} from "@angular/material/dialog";
import {UpdateOrderStatusComponent} from "../../../components/direct_order_status_popup/update_order_status.component";
import {ApproveRejectCommentsPopupComponent} from "../../../components/approve_reject_comments_popup/approve_reject_comments_popup.component";


@Component({
  selector: 'app-direct-order',
  templateUrl: 'direct_order.html',
  styleUrls: ['../../../pagebase/pagebase_detail.scss']
})

export class DirectOrderComponent extends DetailPageBase implements IDetailPage, OnInit {
  @ViewChild('productSelector') productSelector: NgSelectComponent;
  @ViewChild('qty', {read: ElementRef}) qtyElement: ElementRef;

  public userList;
  public addressLibrary;
  public inco_terms_list;
  public plantList;

  public priceEdited = false;
  public uomEdited = false;

  public pricelist_id;
  public pricelist_name;
  public partner_tcs;
  public salesman_id;
  public billto_adrr_list;
  public shipto_adrr_list;
  public selected_billto_addr_id;

  supplierMasterList = [];
  supplierList;
  customerList;
  dateDisable;
  myDate;

  prodList = [];
  UOMList = [];
  selectedProduct;
  selectedUOM_id;
  enteredQty;
  stock;
  order_ref;

  public selectedUser;

  showPlantField = true;
  showIncoTerms = true;
  showTotalWeight = true;
  showTotalMeasure = true;

  plantFieldValue = '';
  incoTermsFieldValue = '';
  totalMeasureFieldValue = '';
  totalWeightFieldValue = '';

  approverConfig: any;

  showApproverDetails = false;
  approverDetails = [];
  show_approve_button = false;
  feature_multi_level_order_approve = false;

  reorder_mode = false;
  updateReason = '';
  updateComments = '';
  user_role_id;
  user_role_name;
  orderStatus = '';
  finalApprovalStage = false;
  public user_info;


  ngOnInit(): void {
    // this.setTitle('Return Invoice');
    this._Detail_GET_URL = "/api/pwa_dms_connects/get_order_detail";
    this.context = {componentParent: this};
    this.sharedService.setHeader('Direct Order');

    this.feature_multi_level_order_approve = this.sharedService.ACLcheck('feature_multi_level_order_approve');
    this.user_role_id = JSON.parse(localStorage.getItem('user')).role_id;
    this.user_role_name = JSON.parse(localStorage.getItem('user')).role.name;


    this.user_info = JSON.parse(localStorage.getItem('user'));
    this.configureGrid();

    this.initialize_default_values();

    this.readPageParms(params => {

      if (params.get('reorder') === 'true') {

        const reorder_object = JSON.parse(localStorage.getItem('reorder_object'));

        if (reorder_object !== undefined) {
          this.activeObject = reorder_object;
          this.activeObject['state'] = 'draft';
          this.activeID = 0;
          this.newMode = false;
          this.editMode = true;
          this.reorder_mode = true;
          this.sharedService.setHeader('Direct Order');


          let seq = 10;
          this.activeObject.lines.forEach(item => {
            item.mid = this.get_mid(seq);
            seq = seq + 10;
          });

        }
      }

      this.loadPage();
    });
  }


  //Page Initilizefunctions
  initialize_default_values() {
    this.activeObject.amount_tax = 0;
    this.activeObject.amount_total = 0;
    this.activeObject.amount_untaxed = 0;
    this.activeObject.tax_source = 0;
    this.activeObject.charges = 0;
    this.activeObject.total = 0;
    this.activeObject.line_discount = 0;
    this.activeObject.spcl_discount = 0;
    this.partner_tcs = 0;

    this.userList = JSON.parse(localStorage.getItem('all_salesmans'));

    this._localCacheService.getAppConfig().then(app_config => {
      if (app_config.hasOwnProperty('config_json') && app_config['config_json'].hasOwnProperty('inco_terms')) {
        this.inco_terms_list = app_config['config_json']['inco_terms'];
      }
    });

    if (this.showPlantField) {
      this.loadPlants();
    }
  }

  loadUsers() {

  }

  loadCustomers() {
    const userData = {};
    userData['access_token'] = this.userContext.access_token;

    this.customerList = [];
    this.supplierMasterList = [];

    this.apiService.post('/api/pwa_dms_connects/get_direct_customers', userData).subscribe(res => {
      if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
        this.customerList = res['results'].data['customers'];
        this.supplierMasterList = res['results'].data['suppliers'];
      } else {
        this.toastr.error('Error Loading direct customers');
      }
    });
  }

  loadPlants() {
    const userData = {};
    userData['access_token'] = this.userContext.access_token;

    this._localCacheService.get('territory_plants').then(list => {
      if (list === undefined || list === null) {
        this.apiService.post('/api/pwa_dms_connects/get_territory_plants', userData).subscribe(res => {
          if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
            this.plantList = res['results'].data.territory_plants;
            this._localCacheService.set('territory_plants', this.plantList);
          } else {
            this.toastr.error('Error Loading plants');
          }
        });
      } else {
        this.plantList = list;
      }
    });

  }


  loadSuppliers(callback, customer_id) {

  }

  approveCheck() {
    if (this.activeObject.config_json && this.activeObject.config_json.order_approve_json) {
      this.approverDetails = this.activeObject.config_json.order_approve_json.appr_role_wise;
      if (this.approverDetails && this.approverDetails.length > 0) {
        this.approverDetails.forEach(details => {
          if (details.appr_time) {
            details.app_on = this.datePipe.transform((new Date(details.appr_time + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
          }
        });
      }
    } else {
      this.approverDetails = [];
    }

    let completed = true;
    if (this.approverDetails && this.approverDetails.length > 0) {
      for (let i = 0; i < this.approverDetails.length; i++) {
        if (!this.approverDetails[i].status) {
          completed = false;
          if (this.feature_multi_level_order_approve && this.user_role_id === this.approverDetails[i].role_id) {
            this.show_approve_button = true;
          } else {
            this.show_approve_button = false;
          }
          this.orderStatus = 'Pending Approval from ' + this.approverDetails[i].role_name;
          if (i === this.approverDetails.length - 1) {
            this.finalApprovalStage = true;
          } else {
            this.finalApprovalStage = false;
          }
          break;
        }
      }
      if (completed) {
        this.orderStatus = 'approved';
      }
    } else {
      if (this.feature_multi_level_order_approve && this.user_role_id === this.approverConfig[0].role_id) {
        this.show_approve_button = true;
      } else {
        this.show_approve_button = false;
      }
      this.orderStatus = 'Pending Approval from ' + this.approverConfig[0].role_name;
      if (this.approverConfig.length === 1) {
        this.finalApprovalStage = true;
      } else {
        this.finalApprovalStage = false;
      }
    }
  }

  detailsClick() {
    this.showApproverDetails = !this.showApproverDetails;
  }

  openApproveRejectPopup(type) {
    let title = '';
    let message1 = '';
    let message2 = '';
    let buttonText = '';

    title = "Approve Order";
    message1 = "Are you sure you want to Approve Order?";
    message2 = "Please provide Approval Comments";
    buttonText = "Approve";

    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: 'auto',
        maxWidth: "50%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          title,
          message1,
          message2,
          type,
          buttonText
        }
      };
      const creatediaeref = this.dialog.open(ApproveRejectCommentsPopupComponent, config);
      creatediaeref.componentInstance.submitClicked.subscribe(data => {
        this.action_confirm_claim(data);
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: 'auto',
        maxWidth: "95%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          title,
          message1,
          message2,
          type,
          buttonText
        }
      };
      const creatediaeref = this.dialog.open(ApproveRejectCommentsPopupComponent, config);
      creatediaeref.componentInstance.submitClicked.subscribe(data => {
        this.action_confirm_claim(data);
      });
    }
  }

  action_confirm_claim(comments) {
    let apiurl = '';
    apiurl = '/api/sale_orders/updateOrderApprovalStatus';
    const dataObj = {
      access_token : localStorage.getItem('resfreshToken'),
      data: [{
        order_id: this.activeID,
        role_id: this.user_role_id,
        comments,
        user_name: this.user_info.user_name
      }]
    };

    this.apiService.post(apiurl, dataObj)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('result') && (res.result.length > 0 && res.result[0].status === 200)) {
            this.toastr.success(status.toUpperCase() + ' Successfully');
            window.location.reload();
            this.dialog.closeAll();
          } else {
            this.toastr.error('Error updating Order ' + this.updateComments + ' ' + this.updateReason);
          }
        },
        error => {
          console.log(error);
          this.toastr.error('Error updating Order ' + ' ' + this.updateComments + ' ' + this.updateReason + ' ' + this.activeID);
        });

  }

  loadProducts(sec_pricelist_id, callback) {

    this.prodList = [];

    const userData = {};
    userData['access_token'] = this.userContext.access_token;
    userData['supplier_id'] = this.activeObject['supplier_id'];
    userData['pricelist_id'] = sec_pricelist_id;

    if (sec_pricelist_id === 0) {
      this.toastr.error('Error Loading products with no priselist mapped with supplier');
    } else {
      this.apiService.post('/api/pwa_dms_connects/get_products_for_direct_orders', userData).subscribe(res => {
        if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
          this.prodList = res['results'].data;
          return callback();
        } else {
          this.toastr.error('Error fetching products for given pricelist');
        }
      });
    }
  }

  populateCustomerAttributes() {
    const cust_obj = this.customerList.find(item => item.id === this.activeObject['partner_id']);
    if (cust_obj) {
      this.activeObject['customer_name'] = cust_obj.name;
      this.activeObject['customer_code'] = cust_obj.code;
      this.activeObject['customer_gst_id'] = cust_obj.gst_id;
      this.activeObject['customer_email'] = cust_obj.email;
      this.activeObject['customer_address'] = cust_obj.address;
      this.activeObject['beat_id'] = cust_obj.beat_id;
      this.activeObject['user_id'] = cust_obj.salesman_id;
      // this.activeObject['salesman'] = cust_obj.user;
      this.activeObject['tax_source'] = cust_obj.tax_source;
      this.activeObject['pricelist_id'] = cust_obj.pricelist_id;
      this.activeObject['pricelist_name'] = cust_obj.pricelist;

      // this.billto_adrr_list = this.addressLibrary.filter(existed => ((parseInt(existed.parent_id, 10) === parseInt(cust_obj.id, 10)) && (existed.type === 'invoice')));
      // this.billto_adrr_list = [{id: cust_obj.id, parent_id: cust_obj.id, itemname: cust_obj.address}].concat(this.billto_adrr_list);
      //
      // this.shipto_adrr_list = this.addressLibrary.filter(existed => ((parseInt(existed.parent_id, 10) === parseInt(cust_obj.id, 10)) && (existed.type === 'delivery')));
      // this.shipto_adrr_list = [{id: cust_obj.id, parent_id: cust_obj.id, itemname: cust_obj.address}].concat(this.shipto_adrr_list);
    }
  }

  populateSupplierAttributes() {
    const supplier = this.supplierList.find(item => item.id = this.activeObject.distributor_id);
    if (supplier) {
      this.activeObject['supplier_name'] = supplier.name;
      this.activeObject['supplier_code'] = supplier.code;
      this.activeObject['supplier_gst_id'] = supplier.gst_id;
    }
  }


  loadPage() {

    if (this.activeID > 0) {
      this.loadActiveObject(() => {


        if (this.activeObject['partner_id'] === undefined) {
          this.toastr.error('invalid state: partner_id not found');
        }

        if (!this.activeObject['shipping_partner_id'] || this.activeObject['shipping_partner_id'] === 0) {
          this.activeObject['shipping_partner_id'] = this.activeObject['partner_id'];
        }

        // this.populateSupplierAttributes();
        // this.populateCustomerAttributes();

        this.loadlineData();

        if (this.activeObject['pricelist_id']) {
          // this.loadProducts(this.activeObject['pricelist_id'], () => {

          // });
        }

        if (this.activeObject.hasOwnProperty('config_json') && this.activeObject.config_json !== null && this.activeObject.config_json !== undefined) {
          if (this.activeObject.config_json.hasOwnProperty('plant') && this.activeObject.config_json.plant !== null && this.activeObject.config_json.plant !== undefined) {
            this.showPlantField = true;
            this.plantFieldValue = this.activeObject.config_json.plant_name;
          }
          if (this.activeObject.config_json.hasOwnProperty('inco_term') && this.activeObject.config_json.inco_term !== null && this.activeObject.config_json.inco_term !== undefined) {
            this.showIncoTerms = true;
            this.incoTermsFieldValue = this.activeObject.config_json.inco_term;
          }
          if (this.activeObject.config_json.hasOwnProperty('totalWeight') && this.activeObject.config_json.totalWeight !== null && this.activeObject.config_json.totalWeight !== undefined) {
            this.showTotalWeight = true;
            this.totalWeightFieldValue = parseFloat(this.activeObject.config_json.totalWeight).toFixed(2);
          }
          if (this.activeObject.config_json.hasOwnProperty('totalMeasure') && this.activeObject.config_json.totalMeasure !== null && this.activeObject.config_json.totalMeasure !== undefined) {
            this.showTotalMeasure = true;
            this.totalMeasureFieldValue = parseFloat(this.activeObject.config_json.totalMeasure).toFixed(2);
          }
        }

        if (this.activeObject.amount_tax) {
          this.activeObject.amount_tax = parseFloat(this.activeObject.amount_tax);
        }
        if (this.activeObject.amount_total) {
          this.activeObject.amount_total = parseFloat(this.activeObject.amount_total);
        }
        if (this.activeObject.amount_untaxed) {
          this.activeObject.amount_untaxed = parseFloat(this.activeObject.amount_untaxed);
        }
        if (this.activeObject.tax_source) {
          this.activeObject.tax_source = parseFloat(this.activeObject.tax_source);
        } else if (this.activeObject.charges) {
          this.activeObject.charges = parseFloat(this.activeObject.charges);
        }
        this.approveCheck();
        // this.selectedUser = this.userList.find(item => item.id === this.activeObject['user_id']);

      });
    } else {

      //Load all default Values
      this.activeObject['id'] = 0;
      this.billto_adrr_list = [];
      this.shipto_adrr_list = [];

      if (this.reorder_mode === true) {
        this.newMode = false;
        this.editMode = true;
      } else {
        this.newMode = true;
        this.editMode = true;
        this.activeObject = {};
        this.activeObject['id'] = 0;

        this.loadCustomers();
      }

    }

  }

  loadlineData() {
    this.rowData = [];
    if (this.activeObject && this.activeObject['lines'] && this.activeObject.lines.length > 0) {
      this.activeObject.lines.forEach(line => {
        this.rowData.push(line);
      });

      this.gridOptions.api.setRowData(this.rowData);
      // this.compute_totals();
    }
  }


  //GRID Functions
  configureGrid() {

    this.columnDefs = [
      {headerName: 'CODE', field: 'code', width: 80},
      {headerName: 'PRODUCT', field: 'name', width: 150},
      {headerName: 'QTY', field: 'quantity', width: 80, editable: true, cellEditor: NumericEditorComponent},
      {headerName: 'UOM', field: 'uom_name', width: 80},
      {headerName: 'SCHEME', field: 'scheme_code', hide: true},
      {headerName: 'PRICE', field: 'price_unit', width: 80, valueFormatter: params => this.formatNumber(params.data.price_unit)},
      {headerName: 'TOTAL DISC %', field: 'discount', width: 80, hide: true},
      {
        headerName: 'CASH DISC%', field: 'cash_discount', width: 80,
        valueGetter(params) {
          if (params.data) {
            if (params.data?.config_json?.cash_dis) {
              return params.data?.config_json?.cash_dis;
            }
          }
        }
      },
      {
        headerName: 'DISC%', field: 'user_discount', width: 80,
        valueGetter(params) {
          if (params.data) {
            if (params.data?.config_json?.user_dis) {
              return params.data?.config_json?.user_dis;
            }
          }
        }
      },
      {headerName: 'SPCL DISC/qty', field: 'trade_disc', width: 80, editable: true, cellEditor: NumericEditorComponent, hide: true},
      {headerName: 'CHARGES', field: 'fixed_cost', width: 80, hide: true},
      {headerName: 'SCHEME DIS', field: 'scheme_discount', width: 80},
      {headerName: 'SUB TOTAL', field: 'price_subtotal', width: 80, valueFormatter: params => this.formatNumber(params.data.price_subtotal)},
      {headerName: 'TAX', field: 'tax_name', width: 100},
      {headerName: 'TOTAL', field: 'price_total', width: 80, valueFormatter: params => this.formatNumber(params.data.price_total)},
      {headerName: 'Serial NO', hide: true, width: 100},
      {headerName: '', field: 'delete', lockPosition: true, cellRenderer: 'cellDeleteRenderer', width: 40},
    ];
    this.frameworkComponents = {
      cellDeleteRenderer: DeleteComponent,
      uomRenderer: UOMRendererComponent
    };
  }


  onRowEditingStarted(event: RowEditingStartedEvent) {
    console.log('never called - not doing row editing');
  }

  onRowEditingStopped(event: RowEditingStoppedEvent) {
    console.log('never called - not doing row editing');
  }

  onCellEditingStarted(event: CellEditingStartedEvent) {
    console.log('cellEditingStarted');
  }

  onCellEditingStopped(event: CellEditingStoppedEvent) {
    if (event.colDef.field === 'quantity') {
      event.data['price_subtotal'] = parseInt(event.newValue, 10) * parseFloat(event.data['price_unit']);
      if (event.data['tax_amount'] !== undefined && event.data['tax_amount'] > 0) {
        event.data['price_tax'] = (event.data['price_subtotal'] * parseFloat(event.data['tax_amount'])) / 100;
      }
      event.data['price_total'] = parseFloat(event.data['price_subtotal']) + parseFloat(event.data['price_tax']);
    }
    this.compute_totals();
    const params = {
      force: true,
      suppressFlash: true,
    };
    this.gridOptions.api.refreshCells(params);
  }

  isExternalFilterPresent(): boolean {
    // to hide based on deleted flag
    return true;
  }

  doesExternalFilterPass(node: any): boolean {
    if (node.data) {
      return !(node.data.deleted);
    }
    return true;
  }


  //Page Events
  showCustomerSelection() {
    // const config: MatDialogConfig = {
    //   width: "100vw",
    //   height: "100vh",
    //   maxWidth: "1000px",
    //   maxHeight: "620px"
    // };
    //
    // const creatediaeref = this.dialog.open(DMSCustomerSelectionPopupComponent, config);
    //
    // creatediaeref.componentInstance.selectedOutlet.subscribe(data => {
    //   this.changeCustomer(data);
    //   this.dialog.closeAll();
    // });
  }

  changeCustomer(data) {
    console.log(data);
    if (data) {
      this.activeObject['partner_id'] = data.id;
      this.activeObject['customer_name'] = data.name;
      this.activeObject['customer_code'] = data.code;
      this.activeObject['customer_gst_id'] = data.gst_id;
      this.activeObject['customer_email'] = data.email;
      this.activeObject['customer_address'] = data.address;
      this.activeObject['beat_id'] = data.beat_id;
      this.activeObject['user_id'] = data.user_id;
      this.activeObject['salesman'] = data.user;
      this.activeObject['tax_source'] = data.tax_source;
      const prev_pricelist_id = this.activeObject['pricelist_id'];
      this.activeObject['pricelist_id'] = data.pricelist_id;
      this.activeObject['pricelist_name'] = data.pricelist;
      this.activeObject['shipto_address'] = data.address;
      // this.supplierList = [];

      // this.supplierList = [];
      if (data['csr_map']) {
        data['csr_map'].forEach(supplier_id => {
          const supplier = this.supplierMasterList.find(item => item.id === supplier_id);
          this.supplierList.push(supplier);
        });
      }

      this.activeObject['supplier_id'] = this.supplierList[0].id;
      if (this.supplierList[0].sec_pricelist_id) {
        this.activeObject['pricelist_id'] = this.supplierList[0].sec_pricelist_id;
        this.loadProducts(this.supplierList[0].sec_pricelist_id, () => {

        });
      }

    }
  }

  customerChangeEvent(selected_customer_id) {
    const selected_customer = this.customerList.find(item => item.id === selected_customer_id);
    this.changeCustomer(selected_customer);
  }


  supplierChangeEvent(event) {
    if (event.id) {
      this.activeObject['supplier_id'] = event.id;
      this.prodList = [];
      const select_supplier_obj = this.supplierList.find(item => item.id = event.id);
      if (select_supplier_obj) {
        this.loadProducts(select_supplier_obj.sec_pricelist_id, () => {

        });
      }
    }
  }

  plantChangeEvent(plant_code) {
    if (plant_code) {
      this.activeObject['config_json']['plant'] = plant_code;

      const select_plan_obj = this.plantList.find(item => item.code === plant_code);
      if (select_plan_obj) {
        this.activeObject['config_json']['plant_name'] = select_plan_obj.name;
      }
    }
  }

  salesmanChange(user_id) {
    if (user_id) {
      const selecteduser = this.userList.filter(item => item.id === user_id);
      if (selecteduser) {
        this.activeObject['emp_code'] = selecteduser.emp_code;
      }
    }
  }

  productSelectorChange() {
    console.log(this.selectedProduct);
    const prod = this.prodList.filter(item => item.p_id === this.selectedProduct)[0];
    this.UOMList = prod.uoms;
    this.selectedUOM_id = prod.uom_id;

    if (this.qtyElement.nativeElement.value === "0") {
      this.qtyElement.nativeElement.value = "";
    }
    this.qtyElement.nativeElement.focus();
  }

  productUOMChange() {
    console.log(this.selectedUOM_id);

  }

  changeDate(dt) {

  }

  discard() {
    this.editMode = false;
    // this.columnDefs[5].editable = this.isGridCellEditable();
    // this.columnDefs[12].editable = this.isGridCellEditable();
  }

  editOrder() {
    this.editMode = true;
    // this.columnDefs[5].editable = this.isGridCellEditable();
    // this.columnDefs[12].editable = this.isGridCellEditable();
    this.gridApi.redrawRows();

  }

  view_invoices() {

    this.router.navigate([this.apiService.redirect_url + '/dms/direct_primary_invoices'],
      {
        relativeTo: this.route,
        queryParams: {name: this.activeObject['name']}
      });
  }

  showUpdateStatusPopup() {
    const config: MatDialogConfig = {
      width: '100vw',
      height: '100vh',
      maxWidth: '400px',
      maxHeight: '380px',
      panelClass: 'custom-dialog-container',
      data: {
        title: 'Update Order Status'
      }
    };
    const confirmdiaeref = this.dialog.open(UpdateOrderStatusComponent, config);
    confirmdiaeref.componentInstance.updateStatusEmitter.subscribe(data => {
      this.updateReason = data.reason;
      this.updateComments = data.updateComments;
      this.updateOrderStatus(this.updateReason, this.updateComments);
    });
  }

  action_reorder() {

    const order = this.activeObject;
    const ref_order_name = this.activeObject.name;
    order['id'] = 0;
    order['name'] = '';
    order['mid'] = this.get_mid(order.partner_id);

    let seq = 10;
    order.lines.forEach(item => {
      item.mid = this.get_mid(order.partner_id + "_" + seq);
      seq = seq + 10;
    });

    if (order['note']) {
      order['note'] = order['note'] + ' - reordered from order no: [' + ref_order_name + ']';
    } else {
      order['note'] = 'Reordered from order no: [' + ref_order_name + ']';
    }

    localStorage.setItem('reorder_object', JSON.stringify(order));

    this.router.navigate([this.apiService.redirect_url + '/dms/direct_order'],
      {
        relativeTo: this.route,
        queryParams: {reorder: true}
      });

  }

  isGridCellEditable() {
    return this.editMode;
    //  TODO: Check for status
  }

  //Page core functions
  saveCart() {
    this.editMode = false;

    //TODO: Complete basic validation of page and line items
    if (this.activeObject['partner_id'] === undefined || this.activeObject['partner_id'] > 0) {

    }

    this.activeObject['lines'] = [];
    this.rowData.forEach(line => {

      // const prod = this.prodList.filter(item => item.p_id === line.product_id);
      // let priceSubtotal = parseFloat(line.quantity) * parseFloat(line.price_unit);
      // priceSubtotal = priceSubtotal * (1 - (parseFloat(line.discount) / 100));
      line.product_uom_qty = line.quantity;
      line.product_uom = line.uom_id;
      this.activeObject['lines'].push(line);
    });

    //TODO: Validation
    this._Detail_SAVE_URL = "/api/pwa_dms_connects/save_direct_order";
    this.saveDetailPage((err, result) => {
      if (err) {
        this.toastr.error(err);
      }
      this.loadPage();
    });
  }

  addProductLine(productId, uomId, quantity, unitPrice) {
    const prod = this.prodList.filter(item => item.p_id === productId)[0];
    const uom_obj = this.UOMList.filter(item => item.id === uomId)[0];
    if (unitPrice === undefined) {
      unitPrice = parseFloat(String(prod['unit_price']));
    }
    const line_subtotal = unitPrice * parseInt(quantity, 10);

    //TODO: exclude promo/foc items
    const prod_match = this.rowData.filter(item => item.product_id === productId && item.uom_id === uomId);
    let line = {};
    if (prod_match.length > 0) {
      if (prod_match['deleted']) {
        prod_match['deleted'] = false;
        line = prod_match[0];
      }
    }
    let priceTax = 0;
    let priceTotal = 0;
    const disc = parseFloat(prod['margin']);

    if (prod['tax_amount'] !== undefined && prod['tax_amount'] > 0) {
      priceTax = (line_subtotal * prod['tax_amount']) / 100;
    }
    priceTotal = line_subtotal + priceTax;

    line = {
      type: 'return',
      uoms: prod.uoms,
      stock: this.stock,
      product_uom: uom_obj.itemName,
      name: prod['product'],
      product_id: productId,
      tax_amount: parseFloat(prod['tax']),
      uom_id: uomId,
      price_unit: unitPrice,
      discount: disc,
      trade_disc: 0,
      fixed_cost: 0,
      scheme_discount: 0,
      quantity: parseInt(quantity, 10),
      reusable_qty: parseInt(quantity, 10),
      price_subtotal: line_subtotal,
      price_tax: priceTax,
      price_total: priceTotal,
      tax_name: prod['tax_name'],
      taxmaster_id: prod['taxmaster_id'],
      factor_inv: prod['factor_inv'],
      factor: prod['factor'],
    };

    if (line['mid'] === undefined || line['mid'].length === 0) {
      line['mid'] = this.get_mid(this.activeObject.partner_id);
    }
    if (line['line_id'] === undefined) {
      line['line_id'] = 0;
    }

    this.rowData.push(line);
  }

  addProductClick() {

    this.addProductLine(this.selectedProduct, this.selectedUOM_id, this.enteredQty, undefined);

    this.enteredQty = 0;
    this.selectedProduct = 0;
    this.stock = 0;
    this.compute_totals();
    this.gridOptions.api.setRowData(this.rowData);
    this.productSelector.focus();
  }

  removeProduct(index) {
    if (this.editMode) {
      const old_line = this.rowData[index];
      if (this.rowData[index].line_id !== undefined && this.rowData[index].line_id > 0 && this.reorder_mode === false) {
        this.rowData[index]['deleted'] = true;
        this.rowData[index]['quantity'] = 0;
        this.rowData[index]['reusable_qty'] = 0;

        this.gridApi.onFilterChanged();
      } else {
        this.rowData.splice(index, 1);
        this.gridApi.setRowData(this.rowData);
      }
      this.compute_totals();
    }
  }

  compute_totals() {
    this.activeObject.amount_untaxed = 0;
    this.activeObject.amount_tax = 0;
    this.activeObject.amount_total = 0;

    this.rowData.forEach(line => {
      if (line['deleted'] === undefined || line['deleted'] === false) {
        this.activeObject.amount_untaxed = parseFloat(this.activeObject.amount_untaxed) + parseFloat(line.price_subtotal);
        this.activeObject.amount_tax = parseFloat(this.activeObject.amount_tax) + parseFloat(line.price_tax);
        this.activeObject.amount_total = parseFloat(this.activeObject.amount_total) + parseFloat(line.price_total);
      }
    });
  }

  updateOrderStatus(reason, comments) {
    let apiurl = '';
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    apiurl = '/api/sale_orders/update_activity_json';
    paramObject.data = {
      order_id: this.activeID,
      update_reason: reason,
      update_comments: comments
    };

    this.apiService.post(apiurl, paramObject)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.toastr.success(status.toUpperCase() + ' Successfully');
            window.location.reload();
            this.dialog.closeAll();
          } else {
            this.toastr.error('Error updating Order ' + this.updateComments + ' ' + this.updateReason);
          }
        },
        error => {
          console.log(error);
          this.toastr.error('Error updating Order ' + ' ' + this.updateComments + ' ' + this.updateReason + ' ' + this.activeID);
        });
  }

}
