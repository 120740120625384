<div class="survey-list-card"
     [ngClass]="{'sales': type === 'Sales',
     'master': type === 'Master',
     'productivity': type === 'Productivity',
     'deviations': type === 'Deviations',
     'pricelist': type === 'Pricelist',
     'loadout': type === 'Loadout'}"
>

  <div style="cursor: pointer" (click)="goToReport()" class="survey-list-card-heading">
    <!--<h3>{{type}}</h3>-->
    <h3>C0123</h3>
  </div>
  <div style="cursor: pointer" (click)="goToReport()" class="survey-list-card-desc">
    <h2>{{title}}</h2>
  </div>
  <div>
    <div style="cursor: pointer;" (click)="goToList()" class="survey-list-card-open">
      <span>LIST</span>
    </div>
    <div style="cursor: pointer; margin-left: 20px;" (click)="goToReport()" class="survey-list-card-open">
      <span>REPORT</span>
    </div>
  </div>
</div>
