import {Component} from "@angular/core";
import {BaseUpload} from "../../pagebase/base_upload/base_upload";


@Component({
  selector: 'app-planogram-upload',
  templateUrl: '../../pagebase/base_upload/base_upload.html',
  styleUrls: ['../../pagebase/base_upload/base_upload.scss']

})

export class PlanogramUploadComponent extends BaseUpload {

  setViewParams() {
    this.batchSize = 10;
    this.API_URL = '/api/retail_planograms/planogram_retailer_upload';
    this.header = 'Planogram Upload';
    this.sampleText1 = 'DOWNLOAD SAMPLE TEMPLATE';
    this.excel_columns = [{C: 'A', H: 'Outlet Code', V: 'O13241', required: true},
      {C: 'B', H: 'Planogram Code', V: 'Shelf 1', required: true},
    ];
    this.step1Text = 'Download sample for reference.';
    this.step2Text = 'Required fields are Outlet Code and Planogram Code';
    this.actionText = 'Link Planogram to Outlet';
    this.dataLoaded = true;
  }

  async fetchBaseData() {
  }

  sampleDownload1() {
    this.downloadSampleData('planogram_upload_template');
  }

  configureGrid() {
    this.columnDefs = [
      {headerName: 'Outlet Code', field: 'o_code', width: 150},
      {headerName: 'Planogram Code', field: 'p_code', width: 150}
    ];
  }

  validate() {
    const inThis = this;
    this.validRecords = 0;
    this.errorRecords = 0;
    this.rowData = [];
    this.uploaded_data.forEach(row => {
      const indrow: any = {};
      indrow.o_code = row['Outlet Code'];
      indrow.p_code = row['Planogram Code'];
      if (indrow['o_code'] === null || indrow['o_code'] === undefined || indrow['o_code'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Outlet Code Missing';
      } else {
        indrow['o_code'] = indrow['o_code'].toString();
      }
      if (indrow['p_code'] === null || indrow['p_code'] === undefined || indrow['p_code'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Planogram Code Missing';
      } else {
        indrow['p_code'] = indrow['p_code'].toString();
      }
      if (indrow.valid === 'Invalid') {
        this.errorRecords++;
      } else {
        indrow.valid = 'Valid';

        this.validRecords++;
      }
      inThis.rowData.push(indrow);
    });
    if (this.gridOptions && this.gridOptions.api) {
      this.gridOptions.api.setRowData(this.rowData);
    }
  }
}
