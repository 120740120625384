import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-outlet-last-visit-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class DealerOutstandingComponent extends ReportBase implements IReport, OnInit {

  uqOutstandingVals: any = [];
  outstandingType = 'list';

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Dealer Outstanding Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.multiFilter.value =  this.accountURLS[0];
    this.multiFilter.values = this.accountURLS;
    this.showDate = false;

    this.loadInBatches(5000, 'r_id');
    this.showProgressCustomText = true;
    this.API_URL = '/api/pwa_reports/getDealerOutstandingReport';
  }

  configureGrid() {
    this.getUniqueOutstandingValues();
    this.columnDefs = [
      {
        headerName: '',
        children: [
          {headerName: 'REGION', field: 'region', width: 60},
          {headerName: 'STATE', field: 'state', width: 60},
          {headerName: 'ID', field: 'r_id', width: 40},
          {headerName: 'RCODE', field: 'r_code', width: 50},
          {headerName: 'NAME', field: 'r_name', width: 80},
          {headerName: 'CITY', field: 'rp_city', width: 60},
          {headerName: 'DISTRICT', field: 'zone', width: 60},
          {headerName: 'PINCODE', field: 'rp_zip', width: 50},
          {headerName: 'GST', field: 'gst', hide: true, width: 80},
          {headerName: 'EMP CODE', field: 'emp_code', width: 60},
          {headerName: 'SALESMAN', field: 'user_name', width: 80},
          {headerName: 'BEAT ID', field: 'beat_id', width: 50},
          {headerName: 'BEAT', field: 'beat', width: 60},
          {headerName: 'TEAM', field: 'beat', width: 60},
          {headerName: 'PARENT TEAM', field: 'parent_team', width: 60},
          {headerName: 'CR LIMIT', field: 'cl', width: 50},
          {headerName: 'SD', field: 'sd', width: 50}
        ]
      }
    ];

    if (this.outstandingType === 'number') {
      this.columnDefs.push({headerName: 'OUTSTANDING', field: 'outst', width: 80});
    } else {
      this.columnDefs.push({headerName: 'OUTSTANDING', children: []});
      for (const key of this.uqOutstandingVals) {
        this.columnDefs[1].children.push(
          {headerName: key, field: 'outst_' + key, width: 80, aggFunc: 'sum'},
        );
      }
    }

  }

  configureData() {
    this.rowData = [];
    const innerthis = this;
    /* const uqOutst = Array.from(this.uqOutstandingVals);
     uqOutst.sort();*/
    this.apiData.forEach(obj => {
      let indrow = {};
      indrow = obj;
      if (obj.param_json !== null && obj.param_json !== undefined && obj.param_json.length > 0) {
        for (const params of obj.param_json) {
          if (params.n === 'Outstanding') {
            if (innerthis.outstandingType === 'number') {
              let outst = params.v;
              if (outst !== null && outst !== undefined && outst !== "") {
                outst = parseFloat(outst).toFixed(2);
                indrow['outst'] = parseFloat(outst).toFixed(2);
              }
            } else {
              for (const item of params.v) {
                let outst = item[Object.keys(item)[0]];
                if (outst !== null && outst !== undefined && outst !== "") {
                  outst = parseFloat(outst).toFixed(2);
                  indrow['outst_' + Object.keys(item)[0]] = outst;
                }
              }
            }
          } else if (params.n && params.n.toString().toLowerCase() === 'cl') {
            indrow['cl'] = params.v;
          } else if (params.n && params.n.toString().toLowerCase() === 'sd') {
            indrow['sd'] = params.v;
          }
        }
      }
      innerthis.rowData.push(indrow);
    });
  }

  postProcessData() {
    this.configureGrid();
    this.configureData();
  }

  getUniqueOutstandingValues() {
    this.outstandingType = 'list';
    this.uqOutstandingVals = new Set();

    this.apiData.forEach(obj => {
      if (obj.param_json !== null && obj.param_json !== undefined && obj.param_json.length > 0) {
        for (const params of obj.param_json) {
          if (params.n === 'Outstanding') {
            if (params.t === 'number') {
              //this.uqOutstandingVals.add('Outstanding');
              this.outstandingType = 'number';
              return;
            } else {
              for (const item of params.v) {
                this.uqOutstandingVals.add(Object.keys(item)[0]);
              }
            }
          }
        }
      }
    });
  }
}
