import {Component} from "@angular/core";
import {IHeaderAngularComp} from "@ag-grid-community/angular";

@Component({
  selector: 'approve-header',
  template: `<span><button *ngIf="enable_approve" class="btn white-blue-btn" (click)="approveAll()">Approve</button></span>`,
  styles: []
})
export class HeaderClickComponent implements IHeaderAngularComp {
  public params: any;
  public enable_approve;

  agInit(params: any): void {
    if (params.context.componentParent.mark_approve) {
      this.enable_approve = true;
    }else {
      this.enable_approve = false;
    }
    this.params = params;
  }

  public approveAll() {

    this.params.context.componentParent.approveAll(this.params.api.getSelectedRows(), this.params.api.getSelectedNodes());

  }

  refresh(): boolean {
    return false;
  }
}
