import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";

@Component({
  selector: 'approve-cell',
  template: `<span><div *ngIf="loader">
                        <img style="height: 24px;position: relative;left: 60px;" src="../assets/images/loader_icon.gif"/>
  </div><div *ngIf="approv && edit_enable && order_type != 'replace' && order_status != 'dispatched'"><img src="./../assets/edit.svg" (click)="editOrder()" style="cursor: pointer;"/></div>
    <div *ngIf="approved_after"><button class="btn white-green-btn">{{value}}</button></div>
    <div *ngIf="cancel" style="color: red;">{{value}}</div></span>`,
  styles: []
})
export class ApproveClickRenderer implements ICellRendererAngularComp {
  public params: any;
  public value;
  public loader = false;
  public approv = true;
  public approved_after = false;
  public cancel = false;
  public edit_enable = false;
  public order_type;
  public order_status;
  public partial_approve = false;

  agInit(params: any): void {

    this.params = params;
    this.value = params.value;
    this.order_type = params.data.order_type;
    this.order_status = params.data.d_status;
    if (this.value === 'Cancelled') {
      this.cancel = true;
      this.approv = false;
      this.approved_after = false;
    } else if (this.value === 'Approved') {
      this.approved_after = true;
      this.approv = false;
    } else {
      this.cancel = false;
    }

    if (this.params.context.componentParent.allow_edit) { this.edit_enable = true; }

  /*  const role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(existed => 'feature_partial_return_approve' === existed.name);
    if ( role_features.length > 0) {
      this.partial_approve = true;
    } else {
      this.partial_approve = false;
    }*/
    // this.partial_approve = true;
  }

  public approveClick() {

    if (this.value !== "Approved") {
      this.loader = true;
      this.approv = false;
      this.params.context.componentParent.approveClick(this.params.data, this.params.api.getSelectedNodes()).then(res => {
        if (res[0]) {
          this.value = "Approved";
          this.approved_after = true;
        } else {
          this.approv = true;
        }
        this.loader = false;
      });
    }
  }

 /* partialApprove() {
    this.params.context.componentParent.openPartialApprovePopup();
  }*/

  editOrder() {
    this.params.context.componentParent.editOrder(this.params.data, this.params.node);
  }

  refresh(): boolean {
    return false;
  }
}
