import {Component, OnInit} from "@angular/core";
import {ReportBase} from "../_common/reportbase";

@Component({
  selector: 'app-stock-availability-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class StockAvailabilityReportComponent extends ReportBase implements IReport, OnInit {

  /* showCustomFilter = true;
   customFilter:any={
     name:'Availability',
     key:'availability',
     value:1,
     values:[],
     show:true
   };*/

  setTitle() {
    throw new Error("Method not implemented.");
  }

  ngOnInit() {

    this.sharedService.setHeader('Stock Availability Report');

    this.showableFilters.territory = false;
    this.showableFilters.team = false;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;

    /* this.customFilter.values = [
       {id: '', itemName: 'All'},
       {id: 1, itemName: 'Available'},
       {id: 0, itemName: 'Not Available'}
     ];*/

    this.showProgress = true;
    this.showProgressCustomText = true;

    /*   this.userData.offsetID = 0;
       this.userData.offset = 5000;*/
    this.loadInBatches(5000, 'id');
    this.configureGrid();

  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getStockAvailablityReport';

    this.columnDefs = [
      {headerName: 'REGION', field: 'region', width: 80},
      {headerName: 'CLUSTER', field: 'cluster', width: 80},
      {headerName: 'TERRITORY', field: 'territory', width: 80},
      {headerName: 'DATE', field: 'date', width: 80},
      {
        headerName: 'TEAM DETAILS',
        children: [
          {headerName: 'SALESMAN', field: 'salesman', width: 80},
          {headerName: 'SALESMAN CODE', field: 's_code', width: 80},
          {headerName: 'TEAM', field: 'team', width: 80},
          {headerName: 'TEAM LEAD NAME', field: 'tl_name', width: 80},
          {headerName: 'TL CODE', field: 'tl_code', width: 80},
          {headerName: 'PARENT TEAM', field: 'pteam', width: 80},
          {headerName: 'PARENT TL NAME', field: 'ptl_name', width: 80},
          {headerName: 'PARENT TL CODE', field: 'ptl_code', width: 80}
        ]
      },
      {
        headerName: 'PARTNER  DETAILS',
        children: [
          {headerName: 'CHAIN CODE', field: 'p_code', width: 50, hide: true},
          {headerName: 'CHAIN NAME', field: 'p_name', resizable: true, width: 80, hide: true},
          {headerName: 'RID', field: 'retailer_id', width: 50, hide: true},
          {headerName: 'RCODE', field: 'o_code', width: 50},
          {headerName: 'RETAILER', field: 'o_name', width: 120},
          {headerName: 'STREET', field: 'street', width: 120},
          {headerName: 'CITY', field: 'city', width: 120},
          {headerName: 'STATE', field: 'state', width: 120},
          {headerName: 'ZIP', field: 'zip', width: 120},
          {headerName: 'TYPE', field: 'outlet_type', width: 80},
          {headerName: 'CHANNEL', field: 'channel', width: 80},
          {headerName: 'PROGRAM', field: 'program', width: 80},
          {headerName: 'CLASS', field: 'class', width: 80, hide: true}
        ]
      },
      {headerName: 'CATEGORY', field: 'category', width: 80},
      {headerName: 'BRAND', field: 'brand', width: 80, hide: true},
      {headerName: 'FAMILY', field: 'family', width: 80, hide: true},
      {headerName: 'CODE', field: 'prd_code', width: 80},
      {headerName: 'PRODUCT', field: 'product', width: 120},
      {
        headerName: 'Availability %', field: 'avail', width: 50, cellStyle: {textAlign: 'right'}, aggFunc: this.avgAggFunction,
        valueGetter(params) {
          if (params.data) {
            if (params.data.avail === 100) {
              return 100;
            } else {
              return 0;
            }
          }
        }
      },
      {headerName: 'STOCK', field: 'o_qty', width: 50, cellStyle: {textAlign: 'right'}, aggFunc: this.avgAggFunction},
      {headerName: 'FACING', field: 'f_qty', width: 50, cellStyle: {textAlign: 'right'}, aggFunc: this.avgAggFunction},
      {headerName: 'INWARD', field: 'i_qty', width: 50, cellStyle: {textAlign: 'right'}, aggFunc: this.avgAggFunction, hide: true},
      {headerName: 'CLOSING', field: 'c_qty', width: 50, cellStyle: {textAlign: 'right'}, aggFunc: this.avgAggFunction, hide: true},
      {
        headerName: 'OFFTAKE', field: 'o_qty', width: 50, cellStyle: {textAlign: 'right'}, aggFunc: this.avgAggFunction, hide: true,
        valueGetter(params) {
          if (params.data) {
            if (params.data.o_qty && params.data.c_qty) {

              if (params.data.o_qty > params.data.c_qty) {
                return params.data.o_qty - params.data.c_qty;
              } else {
                return 0;
              }

            } else {
              return params.data.o_qty;
            }
          }
        }
      }

    ];
  }

  avgAggFunction(params) {
    let avg;
// the average will be the sum / count
    let sum = 0;
    let count = 0;

    params.values.forEach(value => {
      const groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        // we are aggregating groups, so we take the
        // aggregated values to calculated a weighted average
        sum += value.avg * value.count;
        count += value.count;
      } else {
        // skip values that are not numbers (ie skip empty values)
        if (typeof value === 'number') {
          sum += value;
          count++;
        }
      }
    });
    // avoid divide by zero error
    if (count !== 0) {
      avg = sum / count;
    } else {
      avg = null;
    }

    avg = Math.round(Math.round((avg + Number.EPSILON) * 100) / 100);

    // the result will be an object. when this cell is rendered, only the avg is shown.
    // however when this cell is part of another aggregation, the count is also needed
    // to create a weighted average for the next level.
    const result = {
      count,
      avg,
      // the grid by default uses toString to render values for an object, so this
      // is a trick to get the default cellRenderer to display the avg value
      toString() {
        return this.avg;
      },
    };

    return result;
  }


}
