import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ApiService} from "../../_services/api.service";

@Component({
  selector: 'app-components-searchablefilter',
  templateUrl: './searchablefilter.component.html'
})

export class SearchableFilterComponent implements OnInit {

  state: [];
  @Input() searchableFilter: any;
  @Input() containerStyle: any;
  @Output() onsetSearchableFilter: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
   /* if (this.searchableFilter && this.searchableFilter.values && this.searchableFilter.values.length > 0) {
      this.selectedData = this.searchableFilter.values[0].id;
    }*/
  }

  searchableFilterChangeEvent(event) {
    this.onsetSearchableFilter.emit(event);
  }

}
