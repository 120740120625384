import {Component, OnInit} from '@angular/core';
import {ReportBase} from "../_common/reportbase";

@Component({
  selector: 'app-outlet-report',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})
export class OutletDumpReportComponent extends ReportBase implements IReport, OnInit {

  apiData: any = [];


  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    this.sharedService.setHeader('Outlet Dump Report');
    this.showDate = false;
    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_connects/outlet_dump_report';
    this.columnDefs = [
      {headerName: 'Outlet ID', field: 'id', width: 100, cellStyle: {textAlign: "right"}},
      {headerName: 'Outlet Code', field: 'code', width: 100, cellStyle: {textAlign: "right"}},
      {headerName: 'Outlet Name', field: 'outlet', width: 100},
      {headerName: 'Outlet Status', field: 'outlet_status', width: 100},
      {headerName: 'Zone', field: 'zone', width: 100},
      {headerName: 'Address', field: 'address', width: 100},
      {headerName: 'DC ID', field: 'dc_id', width: 100, cellStyle: {textAlign: "right"}},
      {headerName: 'DC', field: 'dc', width: 150},
      {headerName: 'Beat', field: 'beat', width: 100},
      {headerName: 'Van', field: 'van', width: 150},
      {headerName: 'Owner', field: 'owner', width: 150},
      {headerName: 'Owner Mobile', field: 'mobile', width: 100},
      {headerName: 'Created On', field: 'create_date', width: 100},
      {headerName: 'Retailer Type', field: 'retailer_type', width: 150},
      {headerName: 'Class', field: 'class_type', width: 150},
      {headerName: 'Pricelist', field: 'pricelist', width: 100},
      {headerName: 'Latitude', field: 'partner_latitude', width: 100},
      {headerName: 'Longitude', field: 'partner_longitude', width: 100},
      {headerName: 'Phone', field: 'phone', width: 100},
      {headerName: 'Email', field: 'email', width: 100},
      {headerName: 'City', field: 'city', width: 150},
      {headerName: 'State', field: 'state', width: 150},
      {headerName: 'Zone State', field: 'out_zone', width: 100},
      {headerName: 'Zip', field: 'zip', width: 70, cellStyle: {textAlign: "right"}},
      {headerName: 'GST Number', field: 'gst_id', width: 100},
      {headerName: 'TCS%', field: 'tcs', width: 100},
      {headerName: 'Key Outlet', field: 'key_outlet', width: 50},
      {headerName: 'Payment Mode', field: 'payment_mode', width: 150},
      {headerName: 'Credit Days', field: 'cl_days', width: 100, cellStyle: {textAlign: "right"}},
      {headerName: 'Credit Limit', field: 'credit_limit', width: 50},
      {headerName: 'Credit Dats', field: 'cl_days', width: 50},
      {headerName: 'Pay Mode', field: 'payment_mode', width: 50},
      {headerName: 'Einvoice', field: 'has_einvoice', width: 50},
      {headerName: 'Pre Order', field: 'has_purchase_order', width: 50},
      {headerName: 'UPI', field: 'upi_id', width: 100},
      {headerName: 'Einvoice', field: 'einvoice_status', width: 100},
      {headerName: 'Pre Order', field: 'po_status', width: 100},
      {headerName: 'OTP', field: 'otp_verified', width: 50},
      {headerName: 'verified', field: 'is_verified', width: 50},
      {headerName: 'Chain Code', field: 'chain_code', enableRowGroup: true, width: 80},
      {headerName: 'Chain Name', field: 'chain_name', enableRowGroup: true, width: 80},
      {headerName: 'Chain Group', field: 'chain_program', enableRowGroup: true, width: 80},
      {headerName: 'Program Type', field: 'program_type', enableRowGroup: true, width: 80},
      {
        headerName: 'TAN', field: 'tan', enableRowGroup: true, width: 80,
        valueGetter(params) {
          if (params.data) {
            if (params.data.attribute_json && params.data.attribute_json.hasOwnProperty('tan')) {
              return params.data.attribute_json.tan;
            }
          }
        }
      },
    ];
    this.loadServerData();
  }

}
