import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-eodstatuscard',
  templateUrl: './eodstatuscard.component.html',
  styleUrls: ['./eodstatuscard.component.scss']
})
export class EodstatuscardComponent implements OnInit {
  @Input() date: string;
  @Input() status: string;
  @Input() van_name: string;
  @Output() editStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  updateStatus() {
    this.editStatus.emit(true);
  }

}
