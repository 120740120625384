import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-productionlist',
  templateUrl: './productionlist.component.html',
  styleUrls: ['./productionlist.component.scss']
})
export class ProductionlistComponent implements OnInit {
  @Input() value: string;

  constructor() { }

  ngOnInit() {
  }

}
