import {Component, OnInit} from "@angular/core";
import {ReportBase} from "../_common/reportbase";

@Component({
  selector: 'app-p-bill-scheme-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class Primary_invoice_bill_scheme_reportComponent extends ReportBase implements IReport, OnInit  {

  /* showableFilters={
     max_claim:false,
     policy:false,
     salesman:false,
     status:false,
     class:false,
     beat:false,
     trax_outlet:false,
     non_trax_outlet:false,
     exclude_trax_audit:false,
     region:false,
     cluster:false,
     team:false,
     territory:false,
     outlet_type: true,
     dc: true
   }*/

  setTitle() {
    this.title = 'Bill Scheme Disbursement';
  }

  ngOnInit() {

    this.sharedService.setHeader('Primary Bill Scheme Disbursement');
    this.configureGrid();

    this.showableFilters.dc = true;
    this.userData.customFilter['type'] = 'P';
  }

  configureGrid() {
    this.API_URL = '/api/dms_connects/getBillSchemeReport';
    // this.filterConfig.salesman = true;
    // this.filterConfig.outletType = true;
    this.columnDefs = [
      { headerName: 'State',  field: 'state',   width: 100},
      { headerName: 'Scheme Code',  field: 'scheme_code',
        width: 100, },
      { headerName: 'Scheme Name',  field: 'scheme_name',
        width: 100, },
      { headerName: 'Start Date',  field: 'start_date',
        width: 100, },
      { headerName: 'End Date',  field: 'end_date',
        width: 80, },
      { headerName: 'Supplier Code',  field: 'supp_code',
        width: 100},
      { headerName: 'Supplier Name',  field: 'supp_name',
        width: 100},
      { headerName: 'Customer Code',  field: 'cust_code',
        width: 100},
      { headerName: 'Customer Name',  field: 'cust_name', cellStyle: {textAlign: 'center'},
        width: 100},
      { headerName: 'Order ID',  field: 'order', cellStyle: {textAlign: 'right'},
        width: 80},
      { headerName: 'Order Amount',  field: 'amount_total', cellStyle: {textAlign: 'right'},
        width: 80},
      { headerName: 'Promo Qty',  field: 'promo_qty', cellStyle: {textAlign: 'right'},
        width: 80},
      { headerName: 'Cash Discount',  field: 'cash_disc', cellStyle: {textAlign: 'right'},
        width: 100,
        cellRenderer: function (params) {
          if (!params.data.cash_disc) { params.data.cash_disc = '0'; }
          return params.data.cash_disc;
        }
      },
      { headerName: 'Perc Discount',  field: 'perc_scheme_discount', cellStyle: {textAlign: 'right'},
        width: 100,
        cellRenderer: function (params) {
          if (!params.data.perc_scheme_discount) { params.data.perc_scheme_discount = '0'; }
          return params.data.perc_scheme_discount;
        }
      },
      { headerNam: 'Promo Discount',  field: 'promo_scheme_discount', cellStyle: {textAlign: 'right'},
        width: 100,
        cellRenderer: function (params) {
          if (!params.data.promo_scheme_discount) { params.data.promo_scheme_discount = '0'; }
          return params.data.promo_scheme_discount;
        }},
      { headerName: 'Discount', cellStyle: {textAlign: 'right'},
        width: 100,
        cellRenderer: function (params) {
          debugger;
          if (params.data) {
            return (parseFloat(params.data.cash_disc) + parseFloat(params.data.perc_scheme_discount) + parseFloat(params.data.promo_scheme_discount))
          }
        }
      }
    ];
  }

  // loadServerData() {
  //   var indrow = {
  //     "category":"Scotch",
  //     "brands":"Black & White",
  //     "volume":"231 Cases",
  //     "market_share":"64%",
  //     "growth":"20%",
  //   }
  //
  //   this.rowData.push(indrow);
  //   this.preloader = false;
  //   this.emptyState = false;
  //
  //   this.dataLoaded = true;
  // }

}
