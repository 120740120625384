import {Component, ElementRef, NgZone, OnInit, Renderer2} from "@angular/core";
import {ApiService} from "../../_services/api.service";
import {Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {SharedService} from "../../_services/shared_service";
import {DomSanitizer} from "@angular/platform-browser";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {ImagePopupComponent} from "../../components/image_popup/image_popup.component";

@Component({
  selector: 'app-retail-asset-request-component',
  templateUrl: './retail_asset_request_details.component.html',
  styleUrls: ['./retail_asset_request_details.component.scss']
})
export class RetailAssetRequestDetailsComponent implements OnInit {

  userData = {
    start_date: null,
    end_date: null,
    period_id: 0,
    access_token: null,
    url: null,
    offset: null,
    offsetID: 0,
    flag: true,
    type: '',
    data: {
      id: null,
      date: null
    },
    filterData: {
      date: null,
      access_token: null,
      url: null,
      last_date: null,
      offset: null,
      flag: true,
      out_type: -1,
      salesmanID: 0,
      policyID: 0,
      outlet_type: 0,
      travel_type_id: 0,
      class_type: null,
      program_type: 0,
      status: null,
      max_claim: false,
      trax_outlet: false,
      non_trax_outlet: false,
      exclude_trax_audit: false,
      region_id: 0,
      cluster_id: 0,
      team_id: 0,
      territory_id: 0,
      dc_id: 0,
      displayData: [],
      payment_mode: ''
    },
    customFilter: {}
  };
  asset_ids: any = [];
  assetDetails: any = {};
  rowIndex = 0;
  vendorDetails: any = {};
  surveyDetails: any = {};
  surveyQuestions: any = [];
  showSurveyDetails = false;
  timelineData = [];
  brand;
  showDetails = false;
  deployed_on;
  verified_on;
  galleryData = [];
  photos: any = [];
  showPoDetails = false;

  constructor(private apiService: ApiService,
              private datePipe: DatePipe,
              private sharedService: SharedService,
              private domSanitizer: DomSanitizer,
              public dialog: MatDialog) {
    this.asset_ids = JSON.parse(localStorage.getItem('asset_ids'));
    this.rowIndex = parseInt(localStorage.getItem('index'), 0);
    this.userData.data.id = this.asset_ids[this.rowIndex];
    if (this.sharedService.ACLcheck('feature_assign_vendor_show_po')) {
      this.showPoDetails = true;
    } else {
      this.showPoDetails = false;
    }
    this.getAssetDetails();
  }

  ngOnInit(): void {
    this.sharedService.setHeader("Retail Asset Details");
  }

  fetchPreviousData() {
    //this.nextClick.emit('previous');
    if (this.rowIndex !== 0) {
      this.rowIndex = this.rowIndex - 1;
      this.userData.data.id = this.asset_ids[this.rowIndex];
      this.getAssetDetails();
    }
  }

  fetchNextData() {
    //this.nextClick.emit('next');
    if (this.rowIndex !== this.asset_ids.length - 1) {
      this.rowIndex = this.rowIndex + 1;
      this.userData.data.id = this.asset_ids[this.rowIndex];
      this.getAssetDetails();
    }
  }

  surveyShowClick() {
    this.showSurveyDetails = !this.showSurveyDetails;
  }

  getAssetDetails() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.id = this.userData.data.id;
    this.apiService.post('/api/partner_assets/get_partner_asset_details', paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200) && res.results.data) {
          this.assetDetails = res.results.data.master?.[0];
          this.galleryData = res.results.data.gallery?.[0]?.gallery;
          if ( this.assetDetails?.config_json?.deploy_brand) {
            this.brand = this.assetDetails?.config_json?.deploy_brand;
          } else if (this.assetDetails?.config_json?.request_brand) {
            this.brand = this.assetDetails?.config_json?.request_brand;
          } else {
            this.brand = this.assetDetails?.config_json?.brand;
          }
          this.assetDetails.status = this.assetDetails.status.toString().replace('_', ' ');
          if (this.assetDetails.create_date) {
            try {
              this.assetDetails.create_date = this.datePipe.transform((new Date(this.assetDetails.create_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } catch (e) {
            }
          }
          this.deployed_on = this.assetDetails.deployed_on;
          if (this.deployed_on) {
            try {
              this.deployed_on = this.datePipe.transform((new Date(this.deployed_on + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } catch (e) {
            }
          }
          this.verified_on = this.assetDetails?.config_json?.dv_on;
          if (this.verified_on) {
            if (this.verified_on.toString().includes('T')) {
              this.verified_on = this.verified_on.toString().replace('Z', '');
            }
            if (this.verified_on.toString().includes('T')) {
              this.verified_on = this.verified_on.toString().replace('T', ' ');
            }
            try {
              this.verified_on = this.datePipe.transform((new Date(this.verified_on + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } catch (e) {
            }
          }

          this.getServicesAndActivities();
          if (this.galleryData && this.galleryData.length > 0) {
            this.fetchImages();
          }
        }
      });
  }

  async fetchImages() {
    const gallery = [];
    this.galleryData.forEach(image => {
      gallery.push({
        store_fname: image,
        container: 'asset_gallery'
      });
    });
    const payload = {
      access_token: localStorage.getItem('resfreshToken'),
      data: gallery
    };
    const inThis = this;
    const res = await this.apiService.postPromise('/api/pwa_connects/fetch_image', payload);
    if (res.hasOwnProperty('result') && res['result'].length > 0) {
      const domSanitizer = this.domSanitizer;
      let img;
      res['result'].forEach(photo => {
        img = domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + photo.bill_datas);
        inThis.photos.push(
          {
            photo: img,
            type: photo.type
          });
      });
    }
  }

  getServicesAndActivities() {
    const inThis = this;
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.id = this.userData.data.id;
    this.apiService.post('/api/partner_assets/get_service_and_activities', paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.timelineData = [];
          const services = res.results.data.services;
          if (services && services.length > 0) {
            services.forEach(service => {
              const timeline: any = {};
              timeline.name = service.name;
              timeline.date = service.assigned_date;
              if (timeline.date && timeline.date !== '') {
                try {
                  timeline.date = inThis.datePipe.transform((new Date(timeline.date + ' UTC')).toString(), 'MMM dd, yyyy');
                } catch (e) {
                }
              }
              timeline['details'] = [];
              for (const [key, value] of Object.entries(service)) {
                if (key !== 'name' && key !== 'assigned_date' && key !== 'id') {
                  timeline['details'].push({key: inThis.sharedService.capitalize(key), value});
                }
                if (key === 'id') {
                  timeline['details'].push({key: "ID", value});
                }
              }
              this.timelineData.push(timeline);
            });
          }
          const activities = res.results.data.activities;
          if (activities && activities.length > 0) {
            activities.forEach(activity => {
              const timeline: any = {};
              timeline.name = activity.name;
              timeline.date = activity.activity_date;
              if (timeline.date && timeline.date !== '') {
                try {
                  timeline.date = inThis.datePipe.transform((new Date(timeline.date + ' UTC')).toString(), 'MMM dd, yyyy');
                } catch (e) {
                }
              }
              timeline['details'] = [];
              for (const [key, value] of Object.entries(activity)) {
                if (key !== 'name' && key !== 'activity_date' && key !== 'id') {
                  timeline['details'].push({key: inThis.sharedService.capitalize(key), value});
                }
                if (key === 'id') {
                  timeline['details'].push({key: "ID", value});
                }
              }
              this.timelineData.push(timeline);
            });
          }
          console.log(this.timelineData);
        }
      });
  }

  detailsClick() {
    this.showDetails = !this.showDetails;
  }

  showImagePopup(photo) {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "70%",
        maxHeight: "95%",
        data: {
          photo
        }
      };

      const creatediaeref = this.dialog.open(ImagePopupComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        data: {
          photo
        }
      };

      const creatediaeref = this.dialog.open(ImagePopupComponent, config);
    }
  }
}
