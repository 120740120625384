import {ReportBase} from '../_common/reportbase';
import {Component, OnInit} from '@angular/core';
import {MatDialogConfig} from "@angular/material/dialog";
import {ImagePopupComponent} from "../../components/image_popup/image_popup.component";
import {ErrorHandler} from "../../error/error-handler";

@Component({
  selector: 'app-lead-master',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

// tslint:disable-next-line:component-class-suffix
export class SaleLeadMasterReportComponent extends ReportBase implements IReport, OnInit {

  apiData: any = [];
  showCustomFilter = true;
  customFilter: any = {
    name: 'Active',
    key: 'active',
    value: 'true',
    values: [],
    show: true
  };

  uqTypes;
  uqImageTypes;
  supplierIds;
  bgIds;

  setTitle() {
    this.title = 'Sale Lead Master Report';
  }

  ngOnInit() {
    this.setTitle();
    this.showDate = false;
    this.sharedService.setHeader('Sale Lead Master Report');
    this.container = 'res_partner_gallery';
    this.API_URL = '/api/pwa_reports/getLeadsReport';

    this.customFilter.values = [
      {id: 'true', itemName: 'Active'},
      {id: 'false', itemName: 'Inactive'},
      {id: '', itemName: 'All'}
    ];

    this.userData.customFilter['type'] = 'sale';
    this.userData.offsetID = 0;
    this.userData.offset = 10000;
    this.loadInBatches(10000, 'rank');
    this.showProgressCustomText = true;

  }

  configureGrid() {
    const inThis = this;
    this.columnDefs = [
      {
        headerName: "",
        children: [
          {headerName: "Cluster", field: "cluster", enableRowGroup: true, width: 50},
          {headerName: "Region", field: "region", enableRowGroup: true, width: 50},
          {headerName: "State", field: "state", enableRowGroup: true, width: 60},
          {headerName: "Zone", field: "zone", width: 100},
          {headerName: "Latitude", field: "lat", width: 50},
          {headerName: "Longitude", field: "lng", width: 50},
          {
            headerName: "Create Date", field: "create_date", width: 60,
            valueGetter: params => {
              if (params.data) {
                if (params.data.create_date) {
                  return inThis.datePipe.transform((new Date(params.data.create_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
                } else {
                  return params.data.create_date;
                }
              } else {
                return '';
              }
            }
          },
          {headerName: "ID", field: "id", width: 50},
          {headerName: "Lead", field: "dealer_name", width: 100},
          {headerName: "LCode", field: "dealer_code", width: 100},
          {headerName: "Potential", field: "potential", width: 60},
          {headerName: "Lead Type", field: "dealer_type", width: 80},
          {headerName: "Stage", field: "stage", width: 50},
          {headerName: "PAN", field: "pan", width: 50},
          {headerName: "GST", field: "gst", width: 50},
          {headerName: "Class", field: "class", width: 50},
          {headerName: "Program Type", field: "prg_type", width: 70},
          {headerName: "Business Group", field: "bg_name", width: 70},
          {headerName: "Supplier", field: "supplier", width: 50},
          {headerName: "Chain", field: "chain", width: 50},
          {headerName: "Mobile", field: "mobile", width: 80},
          {headerName: "Email", field: "email", width: 80},
          {headerName: "Contact Name", field: "contact_name", width: 80},
          {headerName: "Street1", field: "street", width: 80},
          {headerName: "Street2", field: "street2", width: 80},
          {headerName: "City", field: "city", width: 80},
          {headerName: "Pincode", field: "zip", width: 50},
          {headerName: "Territory", field: "terr_name", width: 100},
          {headerName: "TCode", field: "terr_code", width: 100},
          {headerName: "Beat", field: "beat", width: 80},
          {headerName: "ECode", field: "emp_code", width: 50},
          {headerName: "Salesman", field: "salesman", width: 100},
          {headerName: "Role", field: "role", width: 60},
          {headerName: "Team", field: "team", width: 100},
          {headerName: "Active", field: "active", width: 40},
          {headerName: "Image", field: "image_path", width: 40, valueGetter: this.generateImageUrl('image_path'),
            cellRenderer(params) {
              if (params.data) {
                if (!params.data['image_path'] || params.data['image_path'] === "") {
                  return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
                } else {
                  return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
                }
              }
            }
            },
          {headerName: "Last Modified By", field: "write_name", width: 40},
          {
            headerName: "Last Modified Date", field: "write_date", width: 40,
            valueGetter: params => {
              if (params.data) {
                if (params.data.write_date) {
                  return inThis.datePipe.transform((new Date(params.data.write_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
                } else {
                  return params.data.write_date;
                }
              } else {
                return '';
              }
            }
          },
          {headerName: "Description", field: "description", width: 100}
        ]
      },
    ];
    let headercount = 1;
    if (this.uqTypes) {
      for (const params in this.uqTypes) {
        this.columnDefs.push(
          {
            headerName: params,
            children: []
          }
        );
        for (const key of this.uqTypes[params]) {
          this.columnDefs[headercount].children.push(
            {headerName: key, field: params + '_' + key, width: 80}
          );
        }
        headercount++;
      }
    }
    if (this.uqImageTypes) {
      for (const key of this.uqImageTypes) {
        this.columnDefs.push(
          {
            headerName: key,
            children: [
              {headerName: 'Value', field: 'img_con_' + key + '_val', width: 80},
              {
                headerName: "Image", field: 'img_con_' + key + '_img', width: 80, valueGetter: this.generateImageUrl('img_con_' + key + '_img'),
                cellRenderer(params) {
                  if (params.data) {
                    if (!params.data['img_con_' + key + '_img'] || params.data['img_con_' + key + '_img'] === "") {
                      return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
                    } else {
                      return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
                    }
                  }
                }
              }
            ]
          }
        );
        headercount++;
      }
    }
    this.dataLoaded = true;
    this.preloader = false;
    this.emptyState = (this.rowData.length === 0);
  }

  postProcessData() {
    this.dataLoaded = false;
    this.preloader = true;
    this.configureData();
  }

  configureData() {
    this.uqTypes = [];
    this.uqImageTypes = new Set();
    this.supplierIds = new Set();
    this.bgIds = new Set();
    this.apiData.forEach(obj => {
      if (obj.param_json) {
        if (obj.param_json.constructor !== Object) {
          try {
            obj.param_json = JSON.parse(obj.param_json);
          } catch (e) {
            console.log(obj);
          }
        }
        if (obj.param_json.hasOwnProperty('params') && obj.param_json.params) {
          for (const params of obj.param_json.params) {
            if (!params.s) {
              params.s = " ";
            }
            if (!this.uqTypes[params.s]) {
              this.uqTypes[params.s] = new Set();
            }
            this.uqTypes[params.s].add(params.n);
            obj[params.s + '_' + params.n] = params.v;
          }
        }
        if (obj.param_json.hasOwnProperty('verticals') && obj.param_json.verticals) {
          if (!Array.isArray(obj.param_json.verticals)) {
            if (obj.param_json.verticals['supplier_id'] && obj.param_json.verticals['supplier_id'] !== 'null') {
              this.supplierIds.add(obj.param_json.verticals['supplier_id']);
            }
            if (obj.param_json.verticals['bg_id'] && obj.param_json.verticals['bg_id'] !== 'null') {
              this.bgIds.add(obj.param_json.verticals['bg_id']);
            }
          } else {
            for (const params of obj.param_json.verticals) {
              if (params['supplier_id'] && params['supplier_id'] !== 'null') {
                this.supplierIds.add(params['supplier_id']);
              }
              if (params['bg_id'] && params['bg_id'] !== 'null') {
                this.bgIds.add(params['bg_id']);
              }
            }
          }

        }
        /* if (obj.param_json.hasOwnProperty('entity_vals') && obj.param_json.entity_vals) {
           let license = {};
           if (obj.param_json.entity_vals.hasOwnProperty('license')) {
             this.uqLicenses.add('license');
             obj['license'] = obj.param_json.entity_vals['license'];
             license = this.getLicenseImageDetails(obj.license_config, obj['license']);
             if (license) {
               obj['license_type'] = license['image_type'];
               obj['license_img'] = license['store_fname'];
             }
           }
           if (obj.param_json.entity_vals.hasOwnProperty('license2')) {
             this.uqLicenses.add('license2');
             obj['license2'] = obj.param_json.entity_vals['license2'];
             license = this.getLicenseImageDetails(obj.license_config, obj['license2']);
             if (license) {
               obj['license2_type'] = license['image_type'];
               obj['license2_img'] = license['store_fname'];
             }
           }
           if (obj.param_json.entity_vals.hasOwnProperty('license3')) {
             this.uqLicenses.add('license3');
             obj['license3'] = obj.param_json.entity_vals['license3'];
             license = this.getLicenseImageDetails(obj.license_config, obj['license3']);
             if (license) {
               obj['license3_type'] = license['image_type'];
               obj['license3_img'] = license['store_fname'];
             }
           }
         }*/
      }
      if (obj.hasOwnProperty('image_config') && obj.image_config) {
        for (const params of obj.image_config) {
          this.uqImageTypes.add(params['image_type']);
          if (!params['comment'] || params.comment === "") {
            if (params['rel_field'] === 'pan') {
              obj['img_con_' + params['image_type'] + '_val'] = obj['pan'];
            }
          } else {
            obj['img_con_' + params['image_type'] + '_val'] = params['comment'];
          }
          obj['img_con_' + params['image_type'] + '_img'] = params['store_fname'];
        }
      }
    });

    console.log(this.rowData);
    console.log(this.supplierIds);
    if ((this.supplierIds && this.supplierIds.size > 0) || (this.bgIds && this.bgIds.size > 0) ) {
      this.getSupplierAndBusinessGroups();
    } else {
      this.rowData = this.apiData;
      this.configureGrid();
    }
  }

  getSupplierAndBusinessGroups() {
    const paramObject: any = {};
    this.rowData = [];
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      supplierIds: [],
      bgIds: []
    };

    for (const key of this.supplierIds) {
      paramObject.data.supplierIds.push(key);
    }
    for (const key of this.bgIds) {
      paramObject.data.bgIds.push(key);
    }
    this.apiService.post('/api/pwa_reports/getSupplierandBusinessGroups', paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.postpostProcessData(res.results.data);
        } else {
          this.rowData = this.apiData;
          this.configureGrid();
        }
      });
  }

  postpostProcessData(data) {
    this.apiData.forEach(obj => {
      if (obj.param_json.hasOwnProperty('verticals') && obj.param_json.verticals) {
        if (!Array.isArray(obj.param_json.verticals)) {
          if (obj.param_json.verticals['supplier_id'] && obj.param_json.verticals['supplier_id'] !== 'null') {
            if (data.supplierIds) {
              const found = data.supplierIds.find(x => x.id === obj.param_json.verticals['supplier_id']);
              if (found) {
                obj.supplier = found.name;
              }
            }
          }
          if (obj.param_json.verticals['bg_id'] && obj.param_json.verticals['bg_id'] !== 'null') {
            if (data.bgIds) {
              const found = data.bgIds.find(x => x.id === obj.param_json.verticals['bg_id']);
              if (found) {
                obj.bg_name = found.name;
              }
            }
          }
        } else {
          for (const params of obj.param_json.verticals) {
            if (params['supplier_id'] && params['supplier_id'] !== 'null') {
              if (data.supplierIds) {
                const found = data.supplierIds.find(x => x.id === params['supplier_id']);
                if (found) {
                  if ( obj.supplier) {
                    obj.supplier = obj.supplier + ', ' + found.name;
                  } else {
                    obj.supplier = found.name;
                  }
                }
              }
            }
            if (params['bg_id'] && params['bg_id'] !== 'null') {
              if (data.bgIds) {
                const found = data.bgIds.find(x => x.id === params['bg_id']);
                if (found) {
                  if ( obj.bg_name) {
                    obj.bg_name = obj.bg_name + ', ' + found.name;
                  } else {
                    obj.bg_name = found.name;
                  }
                }
              }
            }
          }
        }
      }
    });
    this.rowData = this.apiData;
    // this.gridOptions.api.setRowData(this.rowData);
    // this.gridColumnApi.autoSizeAllColumns();
    this.configureGrid();
  }

  async fetch(key) {
    const res = await this.apiService.postPromise(this.API_URL, this.userData);
    console.log(res);
    if (res.hasOwnProperty('results') && (res.results.status === 200)) {
      // console.log(res);
      //this.apiData.push(res.results.data);
      for (const item of res.results.data) {
        this.apiData.push(item);
      }
      if (this.apiData === null || this.apiData === undefined) {
        this.postProcessData();
        this.dataLoaded = true;
        this.preloader = false;
        this.emptyState = (this.rowData.length === 0);
      } else if (this.apiData.length === 0) {
        this.postProcessData();
        this.dataLoaded = true;
        this.preloader = false;
        this.emptyState = (this.rowData.length === 0);
      } else {


        if (this.apiData[0].hasOwnProperty('total_prog')) {
          this.progressTotal = this.apiData[0].total_prog;
          this.showProgressBar = true;
          this.progressMode = 'determinate';
        } else {
          this.showProgressBar = false;
          this.progressTotal = 0;
        }
        if (res.results.data.length === this.userData.offset) {
          this.userData.offsetID = this.apiData[this.apiData.length - 1][key];
          if (this.progressTotal !== 0) {
            this.progress = Math.round((this.apiData.length / this.progressTotal) * 100);
            this.progressText = this.apiData.length + '/' + this.progressTotal;
          } else {
            this.progressText = 'Downloaded ' + this.apiData.length + ' records';
          }

          await this.fetch(key);
        } else {
          this.postProcessData();
          // this.rowData = this.postProcessData(this.apiData);
        }
      }
    } else if (res.hasOwnProperty('results') && res.results.status === 203) {
      if (res.hasOwnProperty('results') && res.results.hasOwnProperty('errmsg')) {
        console.error(res.results.errmsg);
      }
      this.handleError(ErrorHandler.getErrorObject(res.results.msg));
    } else {
      if (res.hasOwnProperty('results') && res.results.hasOwnProperty('errmsg')) {
        console.error(res.results.errmsg);
      }
      this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
    }
  }
  getLicenseImageDetails(license_config, license) {
    if (license_config && license) {
      return license_config.find(x => x.comment === license);
    } else {
      return null;
    }
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    if (column.includes('img') || column.includes('image')) {
      if (rows !== null && rows.length > 0 && rows[0].hasOwnProperty(column) && rows[0][column] !== null && rows[0][column] !== "") {
        let container = 'res_partner_gallery';
        if (column.includes('image')) {
          container = 'crm_lead';
        }
        if (screen.width > 991) {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "70%",
            maxHeight: "95%",
            data: {
              store_fname: rows[0][column],
              container
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        } else {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "90%",
            maxHeight: "65%",
            data: {
              store_fname: rows[0][column],
              container
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        }
      }
    }
  }

}
