
<div class="common-wrapper">
  <div class="common-container">

    <div class="main-wrapper eod">
      <div id="overlay"></div>
      <div>
        <ul>
          <div *ngIf="sharedService.new" style="display: inline-block;margin-left: 75%;margin-right: 3%;">
            <button [routerLink]="'/orders'" style="position: relative;margin-top: -2.5%;left: 91%;margin-left: -1%;" class="btn blue-btn">CANCEL</button>
          </div>
          <div *ngIf="sharedService.new" style="display: inline-block;">
            <button (click)="savePO('')" style="position: relative;margin-top: -2.5%;left: 91%;margin-left: -1%;" class="btn blue-btn">SAVE</button>
          </div>
          <div *ngIf="sharedService.invoice" style="display: inline-block;margin-left: 75%;">
            <button (click)="receivePO()" style="position: relative;margin-top: -2.5%;left: 91%;margin-left: -1%;" class="btn blue-btn">CREATE INVOICE</button>
          </div>
          <div *ngIf="sharedService.mark_dispatch" style="display: inline-block;margin-left: 75%;">
            <button (click)="dispatch()" style="position: relative;margin-top: -2.5%;left: 91%;margin-left: -1%;" class="btn blue-btn">MARK DISPATCH</button>
          </div>
        </ul>
      </div>
      <div class="eodtop-card" style="flex-wrap: nowrap;margin-top: 1.5%;margin-right: 2%;margin-left: 2%;height: max-content;">
        <ul class="list-unstyled">
          <!--<li class="margb">-->
          <!--<h2>VAN</h2>-->
          <!--</li>-->
          <li>
            <h2>PURCHASE INFO</h2>
          </li>
        </ul>
        <ul>
          <div [hidden]="!sharedService.new" class="custom-input" style="width:15%;font-size: 130%;font-weight: 500;margin-top: 2%;margin-left: 2%;display: inline-block;">
            <label style="font-size: 90%;">BEAT</label>
            <ng-template adHost></ng-template>
          </div>
          <div [hidden]="sharedService.new" class="custom-input" style="margin-top: 2%;margin-left: 2%;display: inline-block;">
            <label>REFERENCE</label>
            <input [disabled]="true" type="text" class="form-control" style="width: 107% !important;padding: 1% 10%;margin-top: -1%;" [(ngModel)]="orderNo" />
          </div>
          <div [hidden]="sharedService.new" class="custom-input" style="margin-top: 2%;margin-left: 2%;display: inline-block;">
            <label>INVOICE NO</label>
            <input [disabled]="true" type="text" class="form-control" style="width: 107% !important;padding: 1% 10%;margin-top: -1%;" [(ngModel)]="invoiceNo" />
          </div>
          <div [hidden]="sharedService.new" class="custom-input" style="margin-top: 2%;margin-left: 2%;display: inline-block;">
            <label>BEAT</label>
            <input [disabled]="true" type="text" class="form-control" style="width: 107% !important;padding: 1% 10%;margin-top: -1%;" [(ngModel)]="beatName" />
          </div>
          <label style="position: relative;left: 6%;bottom: 4vh;color: #b0bac9;font-size: 115%;letter-spacing: 1.13px;line-height: 100%;font-weight: 400;padding-bottom: 2%;">PO DATE</label>
          <div [hidden]="!sharedService.new" class="date-picker" style="display: inline-block;margin-left: 2%;">
            <span class="datepicker-icon"><img src="../../assets/date.png"></span>
            <input style="height: 35px;background-color: '#e0e7ff';" [readonly]="true" [(ngModel)]="poDate"
                   [owlDateTimeTrigger]="dt" [owlDateTime]="dt" placeholder="Date">
            <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
          </div>
          <div [hidden]="sharedService.new" class="custom-input" style="display: inline-block;margin-left: 2%;">
            <input [disabled]="true" type="text" class="form-control" style="width: 107% !important;padding: 1% 10%;margin-top: -1%;" [(ngModel)]="poDate" />
          </div>
          <div class="custom-input" style="margin-top: 2%;margin-left: 4%;display: inline-block;">
            <label>SUPPLIER</label>
            <input [disabled]="true" type="text" class="form-control" style="width: 107% !important;padding: 1% 10%;margin-top: -1%;" [(ngModel)]="supplierName" />
          </div>
        </ul>
        <ul style="margin-top: -6%;margin-left: -2%;">
          <div [hidden]="!sharedService.new" style="width: fit-content;font-size: 130%;font-weight: 500;display: inline-block;margin-left: 4%;;">
            <label style="color: #b0bac9;font-size: 85%;letter-spacing: 1.13px;line-height: 100%;font-weight: 400;padding-bottom: 2%;">CUSTOMER</label>
            <ng-template adHost1></ng-template>
          </div>
          <div [hidden]="sharedService.new" class="custom-input" style="width: fit-content;font-size: 130%;font-weight: 500;display: inline-block;margin-left: 4%;">
            <label style="color: #b0bac9;font-size: 85%;letter-spacing: 1.13px;line-height: 100%;font-weight: 400;padding-bottom: 2%;">CUSTOMER</label>
            <input [disabled]="true"  type="text" class="form-control" style="width: 107% !important;padding: 1% 10%;margin-top: -1%;" [(ngModel)]="customerName" />
          </div>
          <div class="custom-input" style="margin-top: 2%;margin-left: 4%;display: inline-block;">
            <label>CUSTOMER EMAIL</label>
            <input [disabled]="true" type="text" class="form-control" style="width: 107% !important;padding: 1% 10%;margin-top: -1%;" [(ngModel)]="customerMail" />
          </div>
          <div class="custom-input" style="margin-top: 2%;margin-left: 4%;display: inline-block;">
            <label>CUSTOMER TAX ID</label>
            <input [disabled]="true" type="text" class="form-control" style="width: 107% !important;padding: 1% 10%;margin-top: -1%;" [(ngModel)]="customerTax" />
          </div>
          <div [hidden]="!sharedService.new" class="custom-input" style="margin-top: 2%;margin-left: 4%;display: inline-block;">
            <label>PRICELIST</label>
            <input [disabled]="true" type="text" class="form-control" style="width: 107% !important;padding: 1% 10%;margin-top: -1%;" [(ngModel)]="pricelist" />
          </div>
          <div class="custom-input" style="margin-top: 2%;margin-left: 4%;display: inline-block;">
            <label>PO</label>
            <input type="text" class="form-control" style="width: 107% !important;padding: 1% 10%;margin-top: -1%;" [(ngModel)]="po" />
          </div>
          <!--          <label style="position: relative;left: 6%;bottom: 4vh;color: #b0bac9;font-size: 115%;letter-spacing: 1.13px;line-height: 100%;font-weight: 400;padding-bottom: 2%;">DELIVERY DUE</label>-->
          <!--          <div [hidden]="!sharedService.new" class="date-picker" style="display: inline-block;margin-left: -1%;">-->
          <!--            <span class="datepicker-icon"><img src="../../assets/date.png"></span>-->
          <!--            <input style="height: 35px;background-color: '#e0e7ff';" [readonly]="true" [(ngModel)]="myDate" [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1" placeholder="Date">-->
          <!--            <owl-date-time (afterPickerClosed)="changeDate(dt1)" [pickerType]="'calendar'" #dt1></owl-date-time>-->
          <!--          </div>-->
          <!--          <div [hidden]="sharedService.new" class="custom-input"  style="display: inline-block;margin-left: -1%;">-->
          <!--            <input [disabled]="true" type="text" class="form-control" style="height: 35px;background-color: '#e0e7ff';" [(ngModel)]="due_date" />-->
          <!--          </div>-->
<!--          <div [hidden]="sharedService.new" class="custom-input"  style="display: inline-block;margin-left: 3%;">-->
<!--            <input [disabled]="true" type="text" class="form-control" style="height: 35px;background-color: '#e0e7ff';" [(ngModel)]="d_status" />-->
<!--          </div>-->
        </ul>
        <ul>
          <div [hidden]="!sharedService.new || !prodShow" style="width: 25%;font-size: 130%;font-weight: 500;display: inline-block;">
            <label style="color: #b0bac9;font-size: 85%;letter-spacing: 1.13px;line-height: 100%;font-weight: 400;padding-bottom: 2%;">PRODUCT</label>
            <ng-template adHost2></ng-template>
          </div>
          <div [hidden]="!sharedService.new || !prodShow" class="custom-input" style="margin-bottom: 0%;margin-left: 4%;display: inline-block;">
            <label>Qty</label>
            <input type="text" class="form-control" style="width: 50%;padding: 1% 10%;margin-top: -1%;" [(ngModel)]="enteredQty" />
          </div>
          <div [hidden]="!sharedService.new || !prodShow" class="custom-input" style="margin-bottom: 0%;margin-left: 4%;display: inline-block;">
            <label>DISC%</label>
            <input type="text" class="form-control" style="width: 50%;padding: 1% 10%;margin-top: -1%;" [(ngModel)]="extraMargin" />
          </div>
          <div [hidden]="!sharedService.new || !prodShow" class="custom-input" style="margin-bottom: 0%;margin-left: 4%;display: inline-block;">
            <label>SPCL DISC/QTY</label>
            <input type="text" class="form-control" style="width: 50%;padding: 1% 10%;margin-top: -1%;" [(ngModel)]="tradeDisc" />
          </div>
          <div [hidden]="!sharedService.new || !prodShow">
            <button (click)="saveProduct()" style="position: relative;margin-top: -2.5%;left: 91%;margin-left: -9%;" class="btn blue-btn">ADD</button>
          </div>
          <div [hidden]="!sharedService.new || !prodShow">
            <button (click)="removeProduct()" style="position: relative;margin-top: -4.5%;left: 91%;margin-left: -1%;" class="btn blue-btn">REMOVE</button>
          </div>
<!--          <div [hidden]="!sharedService.new">-->
<!--            <button (click)="addProduct()" style="position: relative;margin-top: -2.5%;left: 91%;margin-left: -1%;" class="btn blue-btn">Add Product</button>-->
<!--          </div>-->
        </ul>
        <ul>
          <div class="eod-table">
            <div class="dms_table">
              <ag-grid-angular id="myGrid1" *ngIf="loaded" style="width: 100%;" class="ag-theme-balham"
                               [columnDefs]="columnDefs" [gridOptions]="gridOptions"  (gridReady)="onGridReady($event)"
                               [frameworkComponents]="frameworkComponents" [context]="context">
              </ag-grid-angular>
              <div *ngIf="preloader">
                <app-loadingstate></app-loadingstate>
              </div>
              <div *ngIf="emptyState">
                <app-emptystate></app-emptystate>
              </div>
            </div>
          </div>
        </ul>
        <ul *ngIf="!sharedService.new">
          <div style="background: white;height:26vh">
            <TABLE cellpadding=0 cellspacing=0 style="float:right;margin-top: 1%;">
              <TR>
                <TD><P class="p-class">Taxable Amount</P></TD>
                <TD><P class="p-class1">{{orderdetails['amount_untaxed']}}</P></TD>
              </TR>
              <TR>
                <TD><P class="p-class">Line Discount</P></TD>
                <TD><P class="p-class1">{{orderdetails['line_discount']}}</P></TD>
              </TR>
              <TR>
                <TD><P class="p-class">Special Discount</P></TD>
                <TD><P class="p-class1">{{orderdetails['spcl_discount']}}</P></TD>
              </TR>
              <TR>
                <TD><P class="p-class">Taxes</P></TD>
                <TD><P class="p-class1">{{orderdetails['amount_tax']}}</P></TD>
              </TR>
              <TR>
                <TD><P class="p-class">Total</P></TD>
                <TD><P class="p-class1">{{orderdetails['amount_total']}}</P></TD>
              </TR>
              <TR>
                <TD><P class="p-class">TDS/TCS</P></TD>
                <TD><P class="p-class1">{{orderdetails['tcs']}}</P></TD>
              </TR>
              <TR>
                <TD><P class="p-class">Grand Total</P></TD>
                <TD><P class="p-class1">{{orderdetails['amount_total']}}</P></TD>
              </TR>
            </TABLE>
          </div>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="approve-modal modal" id="serialNoModel" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <span class="close" data-dismiss="modal">
        <img src="../../assets/close.png">
      </span>
      <div class="modal-body">
        <div class="approve-modal-heading">
          <label>ENTER SERIAL NO</label>
        </div>
        <div class="approve-modal-content">
          <div style="display: inline-block;">
            <button (click)="addInput()" class="btn blue-btn">ADD</button>
          </div>
          <div #serialIn>
            <input type="text" class="form-control" style="width: 90% !important;padding: 1% 10%;margin-top: 5%;" [(ngModel)]="serno1" />
          </div>
          <div style="display: inline-block;margin-top: 6%;" data-dismiss="modal">
            <button (click)="confirmSerial()" class="btn blue-btn">CONFIRM</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<button #imageClick id="openImage" [hidden]="true" data-toggle="modal" data-target="#serialNoModel">Open Modal</button>
