<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Bulk Product Selection</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <div class="col-md-12 pad0">
        <div class="row">
          <div *ngIf="!cartMode" style="margin-right: 10px;display: inline-block;">
            <app-components-multiselect-filter [multiFilters]="categoryList"
                                               (onsetMultiFilter)="onFilterChange($event, 'category')"
                                               ></app-components-multiselect-filter>
          </div>
          <div *ngIf="!cartMode" style="margin-right: 10px;display: inline-block;">
            <app-components-multiselect-filter [multiFilters]="familyList"
                                               (onsetMultiFilter)="onFilterChange($event, 'family')"
            ></app-components-multiselect-filter>
          </div>
          <div *ngIf="!cartMode" style="margin-right: 10px;display: inline-block;">
            <app-components-multiselect-filter [multiFilters]="brandList"
                                               (onsetMultiFilter)="onFilterChange($event, 'brand')"
            ></app-components-multiselect-filter>
          </div>
          <div></div>
          <div class="row search" style="display: inline-block;margin-left: auto;margin-bottom: 0px;">
            <span class="search-control"><img src="assets/searchicon.png"/></span>
            <input id="filter-text-box" type="text" placeholder="filter" (input)="quickSearch()"
                   class="search-control-area"
                   placeholder="Search">
          </div>
        </div>

      </div>
      <div class="row" style="display: block; ">
        <div class="col-md-12 pad0">
          <div style="margin-top: 20px;">
            <ag-grid-angular id="myGrid2" *ngIf="dataLoaded" style="height: 58vh" class="ag-theme-alpine"
                             [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                             (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                             (cellClicked)="onCellClicked($event)"
                             (filterChanged)="onFilterChanged($event)"
                             (cellEditingStarted)="onCellEditingStarted($event)"
                             (cellEditingStopped)="onCellEditingStopped($event)"
                             [context]="context">
            </ag-grid-angular>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 pad0" style="margin-top:10px;padding:15px;height:50px;">
      <div class="row" style="display: flex;align-items: center;justify-content: space-between">
        <div style="margin-right: auto;align-items: center;display: flex;">
          <span style="font-size: 14px; font-weight: 500;display: inline">Row {{filteredLength}} of {{totalLength}}</span>
          <img style="width:16px;display: inline; margin-left: 20px;" src="../../../assets/images/focus_product.svg"/>
          <span style="font-size: 14px; font-weight: 500;display: inline;margin-left: 8px;color:#3A6C87">Focus</span>
          <img style="width:16px;display: inline; margin-left: 10px;" src="../../../assets/images/mustsell_product.svg"/>
          <span style="font-size: 14px; font-weight: 500;display: inline;margin-left: 8px;color: #DC562E">Must Sell</span>
        </div>
        <div>
          <button
            mat-button
            style=" margin-right:15px;"
            class="btn grey-btn"
            (click)="closeDialog()"
          >
            Cancel
          </button>
          <button
            *ngIf="!cartMode"
            mat-button
            style=" margin-right:15px;"
            class="btn blue-border-btn"
            (click)="switchMode()"
          >
            Preview ({{cartItems.length}})
          </button>
          <button
            *ngIf="cartMode"
            mat-button
            style=" margin-right:15px;"
            class="btn blue-border-btn"
            (click)="switchMode()"
          >
            Continue Editing
          </button>
          <button
            *ngIf="showSave"
            mat-button
            style=" margin-right:15px;"
            class="btn confirm-blue-btn"
            (click)="saveCart()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
