import {Component, ComponentFactoryResolver, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {GridOptions} from "@ag-grid-community/core";
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {AdDirective} from "../../ad.directive";
import {AdDirective1} from "../../ad1.directive";
import {RegenerateComponent} from "../../regenerate.component";
import {RegenerateHeaderComponent} from "../../regenerateHeader.component";
import {GstinCancelComponent} from "../../gstinCancel.component";
import {DomSanitizer} from "@angular/platform-browser";
import {PrintInvoiceComponent} from "../../printInvoice.component";
import {PrintClickRenderer} from "../../printClickRenderer.component";
import {AppComponent} from "../../app.component";
import {ReportBase} from "../_common/reportbase";
import {CustomGridToolPanelComponent} from "../_common/CustomGridToolPanel.component";

@Component({
  selector: 'app-einvoice-report',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})
export class EinvoiceReportComponent extends ReportBase implements IReport, OnInit {

  customFilter: any = {
    name: 'Order Status',
    key: 'status',
    value: 'sale',
    values: [],
    show: true
  };

  customFilter1: any = {
    name: 'Invoice Status',
    key: 'type',
    value: 'draft',
    values: [],
    show: true
  };

  searchableFilter: any = {
    name: "DC",
    bindLabel: "itemName",
    bindValue: "id",
    value: 0,
    values : [{id: 0, itemName: 'All DC'}]
  };

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    this.sharedService.setHeader('Einvoice Report');
    this.myDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.showCustomFilter = true;
    this.showCustomFilter1 = true;
    this.searchableFilter.values = this.searchableFilter.values.concat(JSON.parse(localStorage.getItem('all_dcs')));
    this.searchableFilter.value = this.searchableFilter.values[1].id;
    this.userData.searchableFilter = this.searchableFilter.values[1].id;
    this.showSearchableFilter = true;
    this.customFilter.values = [
      {id: '', itemName: 'All'},
      {id: 'sale', itemName: 'Confirm'},
      {id: 'cancel', itemName: 'Cancel'},
    ];

    this.customFilter1.values = [
      {id: '', itemName: 'All'},
      {id: 'draft', itemName: 'New'},
      {id: 'cancelled', itemName: 'Cancelled'},
      {id: 'completed', itemName: 'Complete'},
      {id: 'error', itemName: 'Error'},
    ];

    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_connects/einvoice_report';
    this.columnDefs = [
      {headerName: 'Schedule Date', field: 'schedule_date', width: 120},
      {headerName: 'DC', field: 'dc', width: 120},
      {headerName: 'Van', field: 'van', width: 120},
      {headerName: 'Code', field: 'code'},
      {headerName: 'Outlet', field: 'outlet'},
      {headerName: 'Order', field: 'order'},
      {headerName: 'Order Status', field: 'order_status'},
      {headerName: 'Order Type', field: 'order_type'},
      {headerName: 'Invoice No', field: 'invoice_no'},
      {headerName: 'IRN Status', field: 'status'},
      {headerName: 'IRN Comments', field: 'comments'},
      {headerName: 'IRN', field: 'irn'},
      {headerName: 'order_id', field: 'order_id', width: 80, hide: true},
      {headerName: 'Action', field: 'action', headerComponent: "regenerateHeadercomponent", cellRenderer: "regenerateRenderer", pinned: 'right', width: 150},
      {headerName: 'Print', field: 'print', pinned: 'right', headerComponent: "printInvoicecomponent", cellRenderer: "printClickRenderer", width: 120},
      {headerName: 'Cancel Einvoice', field: 'cancel_einvoice', cellRenderer: "gstincancelRenderer", pinned: 'right', width: 120},
    ];

    this.frameworkComponents = {
      CustomToolPanel: CustomGridToolPanelComponent,
      regenerateRenderer: RegenerateComponent,
      regenerateHeadercomponent: RegenerateHeaderComponent,
      gstincancelRenderer: GstinCancelComponent,
      printInvoicecomponent: PrintInvoiceComponent,
      printClickRenderer: PrintClickRenderer
    };
  }

  cancel_gstin(params, node) {
    const instances = node.beans.gridApi.getCellRendererInstances({columns: ['cancel_einvoice'], rowNodes: [node]});
    const data = {
      access_token: localStorage.getItem('resfreshToken'),
      order_id: params.order_id,
      einvoice_id: params.einvoice_id
    };
    this.apiService.post('/api/einvoice_orders/cancel_einvoice', data)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('result') && (res['result'].statusCode === 200)) {
          window.alert('Cancel Einvoice Success');
          instances.forEach(instance => {
            instance.loader = false;
            instance.enable_cancel = false;
          });
        } else {
          window.alert('Cancel Einvoice Failed');
          instances.forEach(instance => {
            instance.loader = false;
            instance.enable_regenerate = true;
          });
        }
      }, error => {
        window.alert('Cancel Einvoice Failed');
        instances.forEach(instance => {
          instance.loader = false;
          instance.enable_regenerate = true;
        });
      });
  }

  async callRegenerate(orders) {
    let rem_orders;
    let success_count = 0;
    let failure_count = 0;
    do {
      rem_orders = orders.splice(10);
      const order_data = {
        access_token: this.userData.access_token,
        url: this.userData.url,
        orders
      };
      const resp = await this.apiService.regenerateIrn(order_data);
      if (resp['results'] && resp['results'].statusCode === 200) {
        let fail_count = 0;
        if (resp['results'].failures) {
          fail_count = resp['results'].failures.length;
          failure_count += fail_count;
        }
        success_count += orders.length - fail_count;
      } else {
        failure_count += orders.length;
      }
      orders = rem_orders;
    } while (rem_orders.length > 0);
    return {success: success_count, failure: failure_count};

  }

  async regenerateAll(rows, nodes) {
    if (rows.length > 0) {
      this.elRef.nativeElement.querySelector('#overlay').style.display = "block";
      const instances = nodes[0].beans.gridApi.getCellRendererInstances({columns: ['action'], rowNodes: nodes});
      instances.forEach(instance => {
        instance.loader = true;
        instance.enable_regenerate = false;
      });
      const orders = [];
      if (rows.length > 200) {
        window.alert('First 200 records only will get generated');
        rows.splice(200);
      }
      rows.forEach(van_row => {
        orders.push({
          id: van_row['order_id'],
          order_type: van_row['Type'],
          invoice_no: van_row['invoice_no']
        });
      });

      const resp = await this.callRegenerate(orders);

      window.alert("IRN Regeneration Status:- Success - " + resp.success + " Failure - " + resp.failure);

      instances.forEach(instance => {
        instance.loader = false;
        instance.enable_regenerate = true;
      });

      this.loadServerData();
    } else {
      window.alert('Select Atleast one record');
    }

    this.elRef.nativeElement.querySelector('#overlay').style.display = "none";
  }

  async regenerate(params, node) {

    const instances = node.beans.gridApi.getCellRendererInstances({columns: ['action'], rowNodes: [node]});
    const order_data = {
      access_token: this.userData.access_token,
      url: this.userData.url,
      orders: [{
        id: params['order_id'],
        order_type: params['Type'],
        invoice_no: params['invoice_no']
      }]
    };

    const res_data = await this.apiService.regenerateIrn(order_data);
    if (res_data.hasOwnProperty('results') && (res_data['results'].statusCode === 200)) {
      if (res_data['results'].failures.length === 0) {
        window.alert('IRN Regeneration Success');
        this.loadServerData();
      } else {
        window.alert(res_data['results'].msg);
      }
      instances.forEach(instance => {
        instance.loader = false;
        instance.enable_regenerate = true;
      });
    } else {
      window.alert('IRN Regeneration Failed');
      instances.forEach(instance => {
        instance.loader = false;
        instance.enable_regenerate = true;
      });
    }
  }

  onCellClicked(event) {
    if (event.colDef.headerName === 'Print') {
      this.appComponent.printAll([event.data], 'order_id', '/api/pwa_connects/get_order_print_details', null);
    }
  }

  printAll(rows) {
    this.appComponent.printAll(rows, 'order_id', '/api/pwa_connects/get_order_print_details', null);
  }
}
