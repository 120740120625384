<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Approve Expense</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <div style="margin-top: 20px; margin-bottom: 20px;">
        <span class="detaillabel2">Approved Amount</span>
        <div style="position: relative">
          <input
            class="forminputwhitebg"
            style="display: block; color: #0762AD; font-size: 14px; font-weight: 500;margin-top: 5px;width: 100%;"
            type="number"
            name="amount"
            [(ngModel)]="exp.app_amount"
            (input)="onAmountChange()"
            #amount="ngModel"
            required
          >
          <div style=" position: absolute;right: 20px;top: 12px;">
            <span class="datespan">Claim Amount: </span>
            <span class="datespan">{{exp.amount}}</span>
          </div>
        </div>
      </div>
      <span *ngIf="amountExceeded" class="text-danger">Amount exceeds Claim amount</span>
    </div>
    <div class="button-row" style="float: right; margin: 15px 30px 15px 0px;">
      <button
        mat-button
        style=" margin-right:15px;"
        class="btn grey-btn"
        (click)="closeDialog()"
      >
        Cancel
      </button>
      <button
        mat-button
        class="btn confirm-blue-btn"
        [disabled]="amountExceeded || !exp.app_amount || exp.app_amount <= 0"
        (click)="performAction()"
      >
        Confirm
      </button>
    </div>
  </div>
</section>
