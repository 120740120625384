import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class VanStockReportV2Component extends ReportBase implements IReport, OnInit {

  /* showCustomFilter = true;
   customFilter: any = {
     name: 'DC',
     key: 'id',
     value: '',
     values: [],
     show: true
   };*/
  data = [];
  public all_dc_flag = false;
  public all_dc = [];
  public default = true;
  public zip_loader = false;
  public zip_ready = false;
  public zipCaption = ["", 0];
  public selectedZipDc = "0";
  public zip_url = "";
  public role_features = [];
  public zipDate = [];

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Van Stock Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.state = true;
    this.showableFilters.region = true;
    //this.showProgress = true;
    /*  this.customFilter.values = [
        {id: '', itemName: 'All'},
        {id: 'true', itemName: 'Active'},
        {id: 'false', itemName: 'Inactive'}
      ];*/
    /*  this.userData = {
        "date": null,
        "access_token": null,
        "url": null,
        "last_date": null,
        "offset": null,
        "dc_id": [],
        "flag": true,
        "zip_flag": 'normal'
      };*/
    //this.customFilter.values = JSON.parse(localStorage.getItem('all_dcs'));

    this.gridOptions.getRowStyle = this.getRowStyleFunc;
    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_connects/van_stock_reportv2';
    const inThis = this;
    this.columnDefs = [
      {
        headerName: 'DATE', field: 'schedule_date', width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.schedule_date) {
              return inThis.datePipe.transform((new Date(params.data.schedule_date + ' UTC')).toString(), 'yyyy-MM-dd');
            } else {
              return params.data.schedule_date;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: 'SALE DATE', field: 'actual_date', width: 80},
      {headerName: 'STATUS', field: 'status', width: 80},
      {headerName: 'STATE', field: 'dc_state', width: 80},
      {headerName: 'REGION', field: 'region', width: 100},
      {headerName: 'DC', field: 'dc', width: 100},
      {headerName: 'Van', field: 'user', width: 80},
      {headerName: 'Product Code', field: 'product_code', width: 150},
      {headerName: 'Product', field: 'product', width: 80},
      {headerName: 'Balance', field: 'loadin', width: 80, aggFunc: 'sum'},
      {headerName: 'Loadout', field: 'loadout', width: 80, aggFunc: 'sum'},
      {headerName: 'Opening', field: 'opening', width: 80, aggFunc: 'sum'},
      {headerName: 'Sales', field: 'sales', width: 80, aggFunc: 'sum'},
      {headerName: 'Damage', field: 'damage', width: 80, aggFunc: 'sum'},
      {headerName: 'Sample', field: 'sample', width: 80, aggFunc: 'sum'},
      {headerName: 'Promo', field: 'promo', width: 80, aggFunc: 'sum'},
      {headerName: 'Closing', field: 'closing', width: 80, aggFunc: 'sum'},
      {headerName: 'Deviation', field: 'deviation', width: 80, aggFunc: 'sum'},
      /* {headerName: 'Add Return', field: 'closing',  width: 80},*/
      {headerName: 'Cancelled', field: 'cancelled', width: 80},
      {headerName: 'Movement %', field: 'movement', width: 80, aggFunc: this.avgAggFunction,
        valueGetter(params) {
          if (params.data) {
            if (params.data.opening && params.data.opening > 0) {
              if (params.data.sales && params.data.sales > 0) {
                return parseFloat(((params.data.sales / params.data.opening) * 100).toFixed(2));
              } else {
                return 0;
              }
            }
          }
        }
      },

    ];

  }

  postProcessData() {
    this.configureData();
  }

  configureData() {
    this.rowData = [];
    const datearray = this.getDates(this.myDate[0], this.myDate[1]);
    const vanprodlist = this.apiData.vanprodlist;
    const prvsData = this.apiData.prev;
    for (let j = 0; j < vanprodlist.length; j++) {
      let datepresent = -1;
      const dataPresentObject: any = {};
      for (let i = 0; i < datearray.length; i++) {
        const indrow: any = {};
        if (vanprodlist[j].stock[0].d === null) {
          if (datepresent === -1) {
            const inditem = prvsData.find(x => x.user_id === vanprodlist[j].id && x.prod_id === vanprodlist[j].product_id);
            if (inditem !== null && inditem !== undefined) {
              datepresent = i;
              indrow.schedule_date = datearray[i];
              indrow.actual_date = inditem.schedule_date;
              indrow.product = inditem.product;
              indrow.product_code = inditem.product_code;
              indrow.user = inditem.user;
              indrow.region = inditem.region;
              indrow.dc = inditem.dc;
              indrow.status = '';
              indrow.dc_state = inditem.dc_state;
              indrow.loadin = inditem.loadin;
              indrow.loadout = 0;
              indrow.opening = inditem.opening;
              indrow.sales = 0;
              indrow.damage = 0;
              indrow.sample = 0;
              indrow.promo = 0;
              indrow.cancelled = 0;
              indrow.deviation = 0;
              indrow.closing = inditem.closing;
              //indrow.deviation = inditem.deviation;

              dataPresentObject.schedule_date = datearray[i];
              dataPresentObject.actual_date = inditem.schedule_date;
              dataPresentObject.product = inditem.product;
              dataPresentObject.product_code = inditem.product_code;
              dataPresentObject.user = inditem.user;
              dataPresentObject.region = inditem.region;
              dataPresentObject.dc = inditem.dc;
              dataPresentObject.dc_state = inditem.dc_state;
              dataPresentObject.status = '';

              dataPresentObject.loadin = inditem.loadin;
              dataPresentObject.loadout = 0;
              dataPresentObject.opening = inditem.loadin;
              dataPresentObject.sales = 0;
              dataPresentObject.damage = 0;
              dataPresentObject.sample = 0;
              dataPresentObject.promo = 0;
              dataPresentObject.cancelled = 0;
              dataPresentObject.deviation = 0;
              dataPresentObject.closing = inditem.closing;
              //dataPresentObject.deviation = inditem.deviation;
            } else {
              continue;
            }
          } else {
            indrow.actual_date = dataPresentObject.actual_date;
            indrow.schedule_date = datearray[i];
            indrow.product = dataPresentObject.product;
            indrow.product_code = dataPresentObject.product_code;
            indrow.user = dataPresentObject.user;
            indrow.region = dataPresentObject.region;
            indrow.dc = dataPresentObject.dc;
            indrow.dc_state = dataPresentObject.dc_state;
            indrow.status = dataPresentObject.status;
            indrow.loadin = dataPresentObject.loadin;
            indrow.loadout = dataPresentObject.loadout;
            indrow.opening = dataPresentObject.opening;
            indrow.sales = dataPresentObject.sales;
            indrow.damage = dataPresentObject.damage;
            indrow.sample = dataPresentObject.sample;
            indrow.promo = dataPresentObject.promo;
            indrow.cancelled = dataPresentObject.cancelled;
            indrow.deviation = dataPresentObject.deviation;
            indrow.closing = dataPresentObject.closing;
            //indrow.deviation = dataPresentObject.deviation;
          }
        } else {
          let inditem = vanprodlist[j].stock.find(x => x.p === vanprodlist[j].product_id && x.d === datearray[i]);
          if (inditem === null || inditem === undefined) {
            if (datepresent === -1) {
              inditem = prvsData.find(x => x.user_id === vanprodlist[j].id && x.prod_id === vanprodlist[j].product_id);

              if (inditem !== null && inditem !== undefined) {
                datepresent = i;
                indrow.schedule_date = datearray[i];
                indrow.actual_date = inditem.schedule_date;
                indrow.product = inditem.product;
                indrow.product_code = inditem.product_code;
                indrow.user = inditem.user;
                indrow.region = inditem.region;
                indrow.dc = inditem.dc;
                indrow.dc_state = inditem.dc_state;
                indrow.status = inditem.status;
                indrow.loadin = inditem.loadin;
                indrow.loadout = 0;
                indrow.opening = inditem.loadin;
                indrow.sales = 0;
                indrow.damage = 0;
                indrow.sample = 0;
                indrow.promo = 0;
                indrow.cancelled = 0;
                indrow.deviation = 0;
                indrow.closing = inditem.closing;
                //indrow.deviation = inditem.deviation;


                dataPresentObject.schedule_date = datearray[i];
                dataPresentObject.actual_date = inditem.schedule_date;
                dataPresentObject.product = inditem.product;
                dataPresentObject.product_code = inditem.product_code;
                dataPresentObject.user = inditem.user;
                dataPresentObject.region = inditem.region;
                dataPresentObject.dc = inditem.dc;
                dataPresentObject.dc_state = inditem.dc_state;
                dataPresentObject.status = inditem.status;

                dataPresentObject.loadin = inditem.loadin;
                dataPresentObject.loadout = 0;
                dataPresentObject.opening = inditem.loadin;
                dataPresentObject.sales = 0;
                dataPresentObject.damage = 0;
                dataPresentObject.sample = 0;
                dataPresentObject.promo = 0;
                dataPresentObject.cancelled = 0;
                dataPresentObject.deviation = 0;
                dataPresentObject.closing = inditem.closing;
                //dataPresentObject.deviation = inditem.deviation;
              } else {
                continue;
              }
            } else {
              indrow.actual_date = dataPresentObject.actual_date;
              indrow.schedule_date = datearray[i];
              indrow.product = dataPresentObject.product;
              indrow.product_code = dataPresentObject.product_code;
              indrow.user = dataPresentObject.user;
              indrow.region = dataPresentObject.region;
              indrow.dc = dataPresentObject.dc;
              indrow.dc_state = dataPresentObject.dc_state;
              indrow.status = dataPresentObject.status;

              indrow.loadin = dataPresentObject.loadin;
              indrow.loadout = dataPresentObject.loadout;
              indrow.opening = dataPresentObject.opening;
              indrow.sales = dataPresentObject.sales;
              indrow.damage = dataPresentObject.damage;
              indrow.sample = dataPresentObject.sample;
              indrow.promo = dataPresentObject.promo;
              indrow.cancelled = dataPresentObject.cancelled;
              indrow.deviation = dataPresentObject.deviation;
              indrow.closing = dataPresentObject.closing;
              //indrow.deviation = dataPresentObject.deviation;
            }
          } else {
            datepresent = i;
            indrow.schedule_date = inditem.d;
            indrow.product = vanprodlist[j].product;
            indrow.product_code = vanprodlist[j].product_code;
            indrow.user = vanprodlist[j].user;
            indrow.region = vanprodlist[j].region;
            indrow.dc = vanprodlist[j].dc;
            indrow.dc_state = vanprodlist[j].dc_state;
            indrow.status = inditem.status;
            indrow.loadin = inditem.li;
            indrow.loadout = inditem.l;
            indrow.opening = inditem.o;
            indrow.sales = inditem.sl;
            indrow.damage = inditem.dm;
            indrow.sample = inditem.sm;
            indrow.promo = inditem.pr;
            indrow.cancelled = inditem.ca;
            indrow.closing = inditem.cl;
            indrow.deviation = inditem.dev;

            dataPresentObject.actual_date = inditem.d;
            dataPresentObject.schedule_date = inditem.d;
            dataPresentObject.product = vanprodlist[j].product;
            dataPresentObject.product_code = vanprodlist[j].product_code;
            dataPresentObject.user = vanprodlist[j].user;
            dataPresentObject.region = vanprodlist[j].region;
            dataPresentObject.dc = vanprodlist[j].dc;
            dataPresentObject.dc_state = vanprodlist[j].dc_state;
            dataPresentObject.status = inditem.status;
            dataPresentObject.loadin = inditem.li;
            dataPresentObject.loadout = 0;
            dataPresentObject.opening = inditem.li;
            dataPresentObject.sales = 0;
            dataPresentObject.damage = 0;
            dataPresentObject.sample = 0;
            dataPresentObject.promo = 0;
            dataPresentObject.cancelled = 0;
            dataPresentObject.deviation = 0;
            dataPresentObject.closing = inditem.cl;
          }
        }
        this.rowData.push(indrow);
      }
    }
  }

  /*configureData() {
    this.rowData = [];
    const datearray = this.getDates(this.myDate[0], this.myDate[1]);
    const vanprodlist = this.apiData.vanprodlist;
    const currentData = this.apiData.current;
    const master = new Set();
    console.log(datearray);
    for (let j = 0; j < vanprodlist.length; j++) {
      let datepresent = -1;
      /!*if (j % 1000 === 0) {
        setTimeout(function () {
          this.progress = Math.round((j / vanprodlist.length) * 100);
        }, 500);
      }*!/
      let dataPresentObject: any = {};
      for (let i = 0; i < datearray.length; i++) {
        const indrow: any = {};
        let inditem = currentData.find(x => x.user_id === vanprodlist[j].id && x.prod_id === vanprodlist[j].product_id && x.schedule_date === datearray[i]);
        if (inditem === null || inditem === undefined) {
          if (datepresent === -1) {
            inditem = currentData.find(x => x.user_id === vanprodlist[j].id && x.prod_id === vanprodlist[j].product_id && (new Date(x.schedule_date).getTime() < new Date(datearray[i]).getTime()));
            if (inditem !== null && inditem !== undefined) {
              master.add({date: datearray[i], prod_id: inditem.prod_id, closing: inditem.closing});
              //indrow = inditem;

              indrow.actual_date = inditem.schedule_date;
              indrow.schedule_date = datearray[i];
              indrow.product = inditem.product;
              indrow.product_code = inditem.product_code;
              indrow.user = inditem.user;
              indrow.region = inditem.region;
              indrow.dc = inditem.dc;
              indrow.dc_state = inditem.dc_state;
              indrow.closing = inditem.closing;


              dataPresentObject.actual_date = inditem.schedule_date;
              dataPresentObject.schedule_date = datearray[i];
              dataPresentObject.product = inditem.product;
              dataPresentObject.product_code = inditem.product_code;
              dataPresentObject.user = inditem.user;
              dataPresentObject.region = inditem.region;
              dataPresentObject.dc = inditem.dc;
              dataPresentObject.dc_state = inditem.dc_state;
              dataPresentObject.closing = inditem.closing;

              /!* indrow.loadin = inditem.loadin;
               indrow.loadout = inditem.loadout;
               indrow.opening = inditem.opening;
               indrow.sales = inditem.sales;
               indrow.damage = inditem.damage;
               indrow.promo = inditem.promo;*!/

            } else {
              continue;
            }
          } else {
            //inditem = currentData.find(x => x.user_id === vanprodlist[j].id && x.prod_id === vanprodlist[j].product_id && x.schedule_date === datearray[datepresent]);


            indrow.actual_date = dataPresentObject.schedule_date;
            indrow.schedule_date = datearray[i];
            indrow.product = dataPresentObject.product;
            indrow.product_code = dataPresentObject.product_code;
            indrow.user = dataPresentObject.user;
            indrow.region = dataPresentObject.region;
            indrow.dc = dataPresentObject.dc;
            indrow.dc_state = dataPresentObject.dc_state;
            indrow.closing = dataPresentObject.closing;
          }
        } else {
          datepresent = i;
          master.add({date: inditem.schedule_date, prod_id: inditem.prod_id, closing: inditem.closing});
          // indrow = inditem;
          indrow.schedule_date = inditem.schedule_date;
          indrow.product = inditem.product;
          indrow.product_code = inditem.product_code;
          indrow.user = inditem.user;
          indrow.region = inditem.region;
          indrow.dc = inditem.dc;
          indrow.dc_state = inditem.dc_state;
          indrow.loadin = inditem.loadin;
          indrow.loadout = inditem.loadout;
          indrow.opening = inditem.opening;
          indrow.sales = inditem.sales;
          indrow.damage = inditem.damage;
          indrow.sample = inditem.sample;
          indrow.promo = inditem.promo;
          indrow.closing = inditem.closing;


          dataPresentObject.schedule_date = datearray[i];
          dataPresentObject.product = inditem.product;
          dataPresentObject.product_code = inditem.product_code;
          dataPresentObject.user = inditem.user;
          dataPresentObject.region = inditem.region;
          dataPresentObject.dc = inditem.dc;
          dataPresentObject.dc_state = inditem.dc_state;
          dataPresentObject.closing = inditem.closing;

        }
        this.rowData.push(indrow);
      }
    }
    console.log(master);
    console.log(this.rowData);
  }
*/
  getDates(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const date = new Date(start.getTime());

    const dates = [];

    while (date <= end) {
      dates.push(this.datePipe.transform(new Date(date), 'yyyy-MM-dd'));
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }

  getRowStyleFunc(params) {
    if (params.data) {
      if (params.data.actual_date && params.data.actual_date !== '') {
        return {
          'background-color': '#F8D1C8'
        };
      } else if (params.data.status && params.data.status !== 'complete' && params.data.status !== 'settled') {
        return {
          'background-color': '#F0E199'
        };
      }
    }
  }
}
