<div id="overlay"></div>
<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" style="float: right;">
        <i class="fas fa-times"></i>
      </button>
      <h2>Location Verify</h2>
    </div>
    <div class="body-section w-100" style="text-align: center;">
      <div *ngIf="showMap" class="map" id="map1" style="margin-top: 20px;">
        <agm-map
          [latitude]="retailerloc.lat"
          [longitude]="retailerloc.long"
          [zoom]="zoom">
          <agm-marker [latitude]="retailerloc.lat" [longitude]="retailerloc.long"
                      [iconUrl]="red_url"
                      [label]="{ text: 'Retailer'}"
                      [markerDraggable]="true"
                      (dragEnd)="markerDragEnd($event)">

            <agm-info-window
              [latitude]="retailerloc.lat"
              [longitude]="retailerloc.long">
              <span style="font-size: large;float: left"><b>Address:</b>
                {{retailerloc.address}}
              </span><br/><br/>
              <!--<span style="float: left"><b>Comments:</b> {{marker.comments}}</span>-->
            </agm-info-window>
          </agm-marker>
          <agm-marker *ngIf="userloc.lat && userloc.lat!=null && userloc.lat!=0"
                      [latitude]="userloc.lat" [longitude]="userloc.long"
                      [iconUrl]="green_url"
                      [label]="{ text: 'User'}">
          </agm-marker>
          <agm-marker *ngIf="dcloc.lat && dcloc.lat!=null && dcloc.lat!=0"
                      [latitude]="dcloc.lat" [longitude]="dcloc.long"
                      [label]="{ text: 'DC'}"
                      [iconUrl]="blue_url"
          >
          </agm-marker>
        </agm-map>
      </div>
      <!--<div id="infowindow-content1">
        <span id="place-name1" class="title"></span><br />
        <span id="place-address"></span>
      </div>
      <div id="infowindow-content2">
        <span id="place-name2" class="title"></span><br />
      </div>-->

      <div>
        <h4 style="color: #17A2B8;">Retailer Address</h4>
        <div class="row">
          <div class="col-md-6 pl-0 mt-3">
            <label style="float: left">Address 1</label>
            <input
              class="mapininput mt-3"
              type="text"
              name="name"
              style="width: 100%"
              [(ngModel)]="retailerloc.street"
            >
          </div>
          <div class="col-md-6 pl-0 mt-3">
            <label style="float: left">Address 2</label>
            <input
              class="mapininput mt-3"
              type="text"
              name="name"
              style="width: 100%"
              [(ngModel)]="retailerloc.street2"
            >
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 pl-0 mt-3">
            <label style="float: left">Area</label>
            <input
              class="mapininput mt-3"
              type="text"
              name="name"
              style="width: 100%"
              [(ngModel)]="retailerloc.area"
            >
          </div>
          <div class="col-md-6 pl-0 mt-3">
            <label style="float: left">Zone</label>
            <input
              class="mapininput mt-3"
              type="text"
              name="name"
              style="width: 100%"
              [(ngModel)]="retailerloc.zone"
            >
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 pl-0 mt-3">
            <label style="float: left">City</label>
            <input
              class="mapininput mt-3"
              type="text"
              name="name"
              style="width: 100%"
              [(ngModel)]="retailerloc.city"
            >
          </div>
          <div class="col-md-6 pl-0 mt-3">
            <label style="float: left">Pincode</label>
            <input
              class="mapininput mt-3"
              type="text"
              name="name"
              style="width: 100%"
              [(ngModel)]="retailerloc.zip"
              (change)="pincodeCheck()"
            >
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 pl-0 mt-3">
            <label style="float: left">State</label>
            <!-- <input
               class="mapininput mt-3"
               type="text"
               name="name"
               style="width: 100%"
               [(ngModel)]="retailerloc.state"
             >-->
            <div class="formselect mt-3">
              <select
                style="margin-top: 10px;"
                [(ngModel)]="retailerloc.state"
                name = "state_id"
                #state_id="ngModel"
              >
                <option
                  *ngFor="let item of allStates"
                  value="{{ item.id }}"
                > {{ item.itemName }}</option>
              </select>
              <span *ngIf="retailerloc.state === null ||  retailerloc.state === 0" class="text-danger">State not set !!</span>
            </div>
          </div>
        </div>
        <div class="mt-5" style="float: left">
          <button  *ngIf="retailerloc.state !== null" (click)="verifyLocation()" style=" margin-right:15px;" class="btn confirm-blue-btn">VERIFY</button>
          <button (click)="closeDialog()" class="btn grey-btn">CLOSE</button>
        </div>
      </div>
    </div>
  </div>
</section>
