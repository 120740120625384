import {Component, ElementRef, Renderer2} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";

@Component({
  selector: 'gstinCancel',
  template: `<span><div *ngIf="loader">
                        <img style="height: 24px;position: relative;left: 60px;" src="../assets/images/loader_icon.gif"/>
  </div><div *ngIf="enable_cancel"><button  class="btn white-blue-btn" style="height:23px !important;" (click)="cancel()">Cancel</button></div></span>`,
})
export class GstinCancelComponent implements ICellRendererAngularComp {
  public params: any;
  public enable_cancel = false;
  public loader = false;

  constructor(private elRef: ElementRef,
              private renderer: Renderer2){}

  agInit(params: any): void {
    this.params = params;
    let field = 'comments';
    if (this.params.data.hasOwnProperty('IRN Comments')) {
      field = 'IRN Comments';
    }
    if (this.params.data[field]) {
      let comm = this.params.data[field];
      comm = comm.split(':- ');
      if (comm.length > 1 && comm[1].includes('GSTIN is Cancelled')) {
        this.enable_cancel = true;
      } else {
        this.enable_cancel = false;
      }
    } else {
      this.enable_cancel = false;
    }
  }

  cancel() {

    this.loader = true;
    this.enable_cancel = false;
    this.params.context.componentParent.cancel_gstin(this.params.data, this.params.node);
  }

  refresh(): boolean {
    return false;
  }
}
