import {Component, Inject, OnInit} from "@angular/core";
import {ApiService} from "../../_services/api.service";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";


@Component({
  selector: 'app-create-team-popup',
  templateUrl: './create_edit_team.component.html',
  styleUrls: ['./create_edit_team.component.scss']

})

export class CreateEditTeamComponent implements OnInit {

  parameterObject: any = {};
  teamObject: any = {};
  salesmenList: any = [];
  teamList: any = [];
  API_URL = '';
  access_token;

  constructor(private apiService: ApiService,
              public dialog: MatDialog,
              private toastr: ToastrService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {

    this.parameterObject = dialogdata.teamObject;

    if(this.parameterObject == null) {
      this.teamObject = {};
      this.teamObject.id = 0;
    } else {
      this.teamObject = dialogdata.teamObject;
    }

    // console.log(this.teamObject);

    if (localStorage.getItem('all_salesmans') !== null && localStorage.getItem('all_salesmans') !== 'null' && JSON.parse(localStorage.getItem('all_salesmans')) !== null) {
      this.salesmenList = JSON.parse(localStorage.getItem('all_salesmans'));
      this.salesmenList.sort((a, b) => a.itemname.localeCompare(b.itemame));
    }

    if (localStorage.getItem('all_teams') !== null && localStorage.getItem('all_teams') !== 'null' && JSON.parse(localStorage.getItem('all_teams')) !== null) {
      this.teamList = JSON.parse(localStorage.getItem('all_teams'));
      this.teamList.sort((a, b) => a.itemname.localeCompare(b.itemname));
    }
    this.access_token =  localStorage.getItem('resfreshToken');

  }

  ngOnInit(): void {

  }

  onTeamLeaderChange(e) {
    const selectObject = this.salesmenList.find(item => item.id === parseInt(this.teamObject.user_id));
    if (selectObject !== undefined) {
      this.teamObject.name = selectObject.itemname + ' Team';
    }
  }

  saveTeam() {
    this.API_URL = '/api/crm_teams/saveTeamPWA';
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = this.teamObject;

    console.log(this.teamObject);

    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
          if (res.hasOwnProperty('result') && (res.result.status === 409)) {
            this.toastr.warning('Cannot create Team with existing name and code');
          } else if (res.hasOwnProperty('result') && (res.result.status === 200)) {

            this.toastr.success('Created Beat Plan Successfully');
            this.dialog.closeAll();

          } else {
            this.toastr.error('Error creating/modifing Team');
          }
        },
        error => {
          console.log(error);
          this.toastr.error('Error creating/modifing Team');
        });

  }


  closeDialog() {
    this.dialog.closeAll();
  }

}
