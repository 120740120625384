<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" style="float: right;background-color:#FDE0D4;">
        <i class="fas fa-times"></i>
      </button>
      <h2 *ngIf="prdTemplateObject == null">Create Product</h2>
      <h2 *ngIf="prdTemplateObject != null">Edit Product</h2>
    </div>
    <div class="body-section">
      <div>
        <form novalidate  id="ngForm" #createeditproductform="ngForm">
          <div class="mt-4">
            <label >CODE</label>
            <div class="mt-1 formselect">
              <input
                class="forminput"
                type="text"
                name="family_code"
                [(ngModel)]="prdTemplateObject.family_code"
                #family_code="ngModel"
                required
              >
              <div *ngIf="family_code.errors && (family_code.dirty || family_code.touched)">
                <span *ngIf="family_code.errors.required" class="text-danger">Code is required</span>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <label >PRODUCT NAME</label>
            <div class="mt-1">
                <input
                  class="forminput"
                  type="text"
                  name="name"
                  [(ngModel)]="prdTemplateObject.name"
                  #name="ngModel"
                  style="width: 90%;"
                  required
                >
                <div *ngIf="name.errors && (name.dirty || name.touched)">
                  <span *ngIf="name.errors.required" class="text-danger">Name is required</span>
                </div>
            </div>
          </div>
          <div class="mt-4">
            <label >CATEGORY</label>
            <div class="mt-1 formselect">
              <ng-select [items]="categoryList"
                         bindLabel="name"
                         bindValue="id"
                         [(ngModel)]="prdTemplateObject.categ_id"
                         addTagText="Create New Category"
                         [addTag]="CreateNewCategory">
              >
              </ng-select>
              <button type="button" class="btn-outline-secondary" (click)="CreateNewCategory()" >+</button>
            </div>
          </div>
          <div class="mt-4">
            <label >BRAND</label>
            <div class="mt-1 formselect">
              <select
                name="brand_id"
                [(ngModel)]="prdTemplateObject.brand_id"
                #brand_id="ngModel">
                <option
                  *ngFor="let item of brandList"
                  value="{{ item.id }}"
                > {{ item.name }}</option>
              </select>
            </div>
          </div>
          <div class="mt-4">
            <label >UOM</label>
            <div class="mt-1 formselect">
              <select
                name="uom_id"
                [(ngModel)]="prdTemplateObject.uom_id"
                #uom_id="ngModel">
                <option
                  *ngFor="let item of uomList"
                  value="{{ item.id }}"
                > {{ item.name }}</option>
              </select>
            </div>
          </div>
          <div class="mt-4">
            <label >TAX</label>
            <div class="mt-1 formselect">
              <select
                name="taxmaster_id"
                [(ngModel)]="prdTemplateObject.taxmaster_id"
                #taxmaster_id="ngModel">
                <option
                  *ngFor="let item of taxMasterList"
                  value="{{ item.id }}"
                > {{ item.name }}</option>
              </select>
            </div>
          </div>
          <div class="mt-4">
            <label >PRICE TO RETAILER (PTD)</label>
            <div class="mt-1">
              <input
                class="forminput"
                type="text"
                name="ptr"
                [(ngModel)]="prdTemplateObject.ptr"
                #ptr="ngModel"
                required
              >
              <div *ngIf="ptr.errors && (ptr.dirty || ptr.touched)">
                <span *ngIf="ptr.errors.required" class="text-danger">Price is required</span>
              </div>

            </div>
          </div>

          <div class="mt-4">
            <label >SEQUENCE </label>
            <div class="mt-1">
              <input
                class="forminput"
                type="number"
                name="seq"
                min="0" max="9999"
                [(ngModel)]="prdTemplateObject.seq"
                #seq="ngModel"
              >
            </div>
          </div>

          <div class="mt-4">
            <label >PRIORITY</label>
            <div class="mt-1 formselect">
              <select
                name="priority"
                [(ngModel)]="prdTemplateObject.priority"
                #priority="ngModel">
                <option
                  *ngFor="let item of priorityList"
                  value="{{ item.id }}"
                > {{ item.name }}</option>
              </select>
            </div>
          </div>

        </form>
      </div>
      <div class="button-row mt-2"  >
        <button
          mat-button
          style=" vertical-align:bottom;margin-top: 20px; margin-right: 15px;"
          class="btn teal-btn"
          (click)="saveProductTemplate()"
          form="ngForm"
          [disabled]="createeditproductform.form.invalid"
        >
          SAVE
        </button>
        <span (click)="closeDialog()">
           <a class="btn white-btn">DISCARD</a>
        </span>
      </div>
    </div>
  </div>
</section>
