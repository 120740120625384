import {PageBase} from "../../../pagebase/pagebase";
import {Component, OnInit} from "@angular/core";


@Component({
  selector: 'app-direct-invoice-list',
  templateUrl: '../../../pagebase/pagebase.html',
  styleUrls: ['../../../pagebase/pagebase.scss']
})

export class DirectSaleInvoiceListComponent extends PageBase implements OnInit, IPage {

  public attributeList: any;
  public name = '';

  ngOnInit(): void {

    this.sharedService.setHeader('Sale Invoices');
    this.API_URL = '/api/pwa_dms_connects/get_direct_invoices';

    this.route.queryParamMap
      .subscribe(params => {
        this.name = params.get('name');
        if (this.name !== undefined && this.name !== null && this.name.length > 0) {
          this.userData.customFilter['name'] = this.name;
          this.loadServerData();
        }
      });
    this.checkAndLoadPreviousSate();
    this.checkAccessControl();
    this.sharedService.setHeader('Sale Invoices');


  }

  postProcessData() {
    this.configureGrid();
    this.configureData();
  }

  configureGrid() {

    this.columnDefs = [
      {headerName: "ID", field: "id", width: 60, checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true},
      {headerName: "INVOICE NO", field: "inv_no", width: 60, hide: true},
      {headerName: "INVOICE DATE", field: "inv_date", width: 120},
      {headerName: "REFERENCE", field: "sale_ref", width: 150},
      {headerName: "CUSTOMER", field: "cust_name", width: 150},
      {headerName: "ST CODE", field: "code", width: 50},
      {headerName: "TYPE", field: "type", width: 50},
      {headerName: "SUP CODE", field: "supplier_code", width: 50},
      {headerName: "SUPPLIER", field: "supplier_name", width: 50},
      {headerName: "SUB TOTAL", field: "amount_untaxed", width: 30, aggFunc: 'sum', cellStyle: {textAlign: 'right'}},
      {headerName: "TOTAL", field: "amount_total", width: 30, aggFunc: 'sum', cellStyle: {textAlign: 'right'}},
      {headerName: "SALESMAN", field: "salesman", width: 40},
      {headerName: "EMP CODE", field: "emp_code", width: 30, hide: true},
      {headerName: "STATE", field: "state", width: 30},
      {headerName: "NOTE", field: "note", width: 40},
      {headerName: "TERRITORY", field: "territory", width: 30},
      {headerName: "CLUSTER", field: "cluster", width: 30}
    ];

    this.getUniqueAttributes();

    if (this.attributeList) {
      for (const key of this.attributeList) {
        this.columnDefs.push({
          headerName: key,
          field: 'attr_' + key,
          width: 100
        });
      }
    }

  }

  getUniqueAttributes() {
    this.attributeList = new Set();

    this.apiData.forEach(obj => {
      if (obj.config_json !== null && obj.config_json !== undefined) {
        for (const [key, value] of Object.entries(obj.config_json)) {
          this.attributeList.add(key);
        }
      }
    });
  }

  configureData() {
    this.rowData = [];
    this.apiData.forEach(obj => {
      const indrow = obj;
      if (obj.config_json !== null && obj.config_json !== undefined) {
        for (const [key, value] of Object.entries(obj.config_json)) {
          indrow['attr_' + key] = value;
        }
      }
      this.rowData.push(indrow);
    });
    this.filteredData = this.rowData;
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const selectedRows = this.gridApi.getSelectedRows();
    const active_ids = [];
    this.gridApi.forEachNodeAfterFilter(node => {
      active_ids.push(node.data.id);
    });

    console.log('filtered Row Count' + active_ids.length);
    localStorage.setItem('active_ids', JSON.stringify(active_ids));
    localStorage.setItem('activeID', JSON.stringify(selectedRows[0].id));

    if (selectedRows !== null && selectedRows.length > 0 && selectedRows[0].id !== undefined) {
      this.router.navigate([this.apiService.redirect_url + '/dms/direct_invoice'], {
        relativeTo: this.route,
        queryParams: {id: selectedRows[0].id }
      });
    }
  }

  checkAccessControl() {
    // this._localCacheService.getAccessControlList().then(list => {
    this.showCreate = true;
    // });
  }



}
