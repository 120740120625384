import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {ErrorHandler} from "../../error/error-handler";

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class UserLeadsReportComponent extends ReportBase implements IReport, OnInit {

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Leads Report');

    this.showableFilters.region = true;
    this.showableFilters.state = true;
    this.showDate = false;
    this.API_URL = '/api/pwa_reports/getGetSalesmanLeadsReport';
    this.configureGrid();
  }

  configureGrid() {
    this.columnDefs = [
      {headerName: 'REGION', field: 'region', enableRowGroup: true, width: 80},
      {headerName: 'STATE', field: 'state', enableRowGroup: true, width: 80},
      {headerName: 'TEAM', field: 'team', enableRowGroup: true, width: 80},
      {headerName: 'EMP CODE', field: 'emp_code', width: 80},
      {headerName: 'SALESMAN', field: 'user_name', width: 80},
      {headerName: 'TERRITORY', field: 'territory', width: 80},
      {headerName: 'ROLE', field: 'role', width: 80},
      {headerName: 'SALES AREA CODE', field: 'sac', enableRowGroup: true, width: 80, hide: true},
      {headerName: 'EMP STATUS', field: 'status', width: 80},
      {headerName: 'RETAILER',
        children: [
          {headerName: 'VERIFIED', valueGetter: this.getLeadCount('retailer', 'v')},
          {headerName: 'UN-VERIFIED', valueGetter: this.getLeadCount('retailer', 'u')},
        ]
      },
      {headerName: 'PROJECTS',
        children: [
          {headerName: 'VERIFIED', valueGetter: this.getLeadCount('project', 'v')},
          {headerName: 'UN-VERIFIED', valueGetter: this.getLeadCount('project', 'u')},
        ]
      },
      {headerName: 'INFLUENCER',
        children: [
          {headerName: 'VERIFIED', valueGetter: this.getLeadCount('influencer', 'v')},
          {headerName: 'UN-VERIFIED', valueGetter: this.getLeadCount('influencer', 'u')},
        ]
      },
    ];
  }

  getLeadCount(type: string, field: string) {
    return (params) => {
      if (params.data) {
        if (params.data.leads_data === null || params.data.leads_data === undefined || params.data.leads_data.length === 0) {
          return 0;
        } else {
          const selectObject = params.data.leads_data.find(item => item.type === type);
          if (selectObject !== undefined) {
            return selectObject[field];
          } else {
            return 0;
          }
        }
      } else {
        return 0;
      }
    };
  }
}
