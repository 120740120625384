import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-outlet-last-visit-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class ConsecutiveNoorderReportComponent extends ReportBase implements IReport, OnInit {

  showMultiFilter = true;
  multiFilter: any = {
    name: 'Instance',
    key: 'instance',
    value: '',
    values: []
  };
  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Consecutive No Sales Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    //this.showDate = false;
    this.multiFilter.value =  this.accountURLS[0];
    this.multiFilter.values = this.accountURLS;
    this.loadInBatches(5000, 'retailer_id');
    this.showProgressCustomText = true;
    this.multiInstance = true;
    this.configureGrid();

    this.configureGrid();

  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getConsecutiveNoorder';

    this.columnDefs = [
      {headerName: 'INSTANCE', field: 'instance', enableRowGroup: true,  width: 80},
      {headerName: 'REGION', field: 'region', enableRowGroup: true, width: 80},
      {headerName: 'CLUSTER', field: 'cluster', enableRowGroup: true, width: 80},
      {headerName: 'STATE', field: 'state', enableRowGroup: true,  width: 80},
      {headerName: 'District', field: 'zone', enableRowGroup: true,  width: 80},
      {headerName: 'TEAM', field: 'team', enableRowGroup: true, width: 80},
      {headerName: 'SALESMAN', field: 'user_name', enableRowGroup: true, width: 80},
      {headerName: 'EMP CODE', field: 'emp_code', enableRowGroup: true, width: 80},
      {headerName: 'OUTLET ID', field: 'retailer_id', enableRowGroup: true, width: 80},
      {headerName: 'OUTLET CODE', field: 'code', enableRowGroup: true, width: 80},
      {headerName: 'OUTLET', field: 'name', enableRowGroup: true, width: 80},
      {headerName: 'OUTLET CLASS', field: 'class_type', enableRowGroup: true, width: 80},
      {headerName: 'OUTLET TYPE', field: 'outlet_type', enableRowGroup: true, width: 80},
      {headerName: 'BEAT', field: 'beat_name', enableRowGroup: true, width: 80},
      {headerName: 'VISIT COUNT', field: 'vc', enableRowGroup: true, width: 80},
      {headerName: 'VISIT DAYS', field: 'vd', enableRowGroup: true, width: 80},
      {headerName: 'AVG DISTANCE', field: 'distance', enableRowGroup: true, width: 80}

    ];

  }

}
