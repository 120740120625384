import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {GridOptions} from "../../../node_modules/@ag-grid-enterprise/all-modules";
import {ApiService} from "../_services/api.service";
import {ActivatedRoute} from '@angular/router';
import {DatePipe} from '@angular/common';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import {PrintInvoiceComponent} from "../printInvoice.component";
import {PrintClickRenderer} from "../printClickRenderer.component";
import {DomSanitizer} from "@angular/platform-browser";
import {AppComponent} from "../app.component";
import {SharedService} from "../_services/shared_service";
import {CustomGridToolPanelComponent} from "../reports/_common/CustomGridToolPanel.component";

@Component({
  selector: 'app-cheque-report',
  templateUrl: './payment-approval-report.component.html'
})
export class PaymentApprovalReportComponent implements OnInit {
  @ViewChild('interPrint') public interPrint: ElementRef;
  @ViewChild('approveCheque') public approveCheque: ElementRef;
  @ViewChild('chequeApprove') public chequeApprove: ElementRef;

  user;
  res_data;
  selected_item;
  public photo;
  public displayApprove = false;
  user_data = {
    date: null,
    start_date: null,
    end_date: null,
    cd: 'All',
    cheque_id: null,
    order_id: null,
    access_token: null,
    url: null,
    last_date: null,
    offset: null,
    status: "draft",
    type: null,
    p_type: "cheque"
  };

  public modules = AllModules;
  public selectedStatus = 'Draft';
  public selectedType = 'Cheque';
  public columnDefs = [];
  public orderDetails = {};
  public all_orders = [];
  public searchValue;
  public frameworkComponents;
  public context;
  public rowData = [];
  public navigate;
  public defaultColDef;
  public sideBar;
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public styleGrid;
  public myDate = [];
  public collapse_disable;
  public report_flag;
  public first_hide = false;
  public dms_enable = false;
  private gridApi;
  private gridColumnApi;

  constructor(private apiService: ApiService,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              private renderer: Renderer2,
              public domSanitizer: DomSanitizer,
              public appComp: AppComponent,
              public sharedService: SharedService,
              private datePipe: DatePipe) {

    this.myDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.user_data.date = this.myDate[0];
    this.user_data.last_date = this.myDate[1];
    this.user_data.start_date = this.myDate[0];
    this.user_data.end_date = this.myDate[1];
    this.user_data.offset = -1 * (new Date(this.user_data.date)).getTimezoneOffset();
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');
    this.report_flag = this.route.snapshot.params["report_flag"];
    if (localStorage.getItem('dms_module') === 'true' || localStorage.getItem('module_dmsv2') === 'true') {
      this.dms_enable = true;
      this.user_data.type = 'S';
    }
    localStorage.setItem('user_data', JSON.stringify(this.user_data));

    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      rowGroupPanelShow: 'always',
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        enablePivot: true,
        enableValue: true
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      icons: {
        'custom-tools': '<span><i style="padding: 8px" class="fal fa-tools"></i></span>',
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
          {
            id: 'customTools',
            labelDefault: 'Tools',
            labelKey: 'customTools',
            iconKey: 'custom-tools',
            toolPanel: 'CustomToolPanel',
            toolPanelParams: {
              reportname: this.constructor.name
            }
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
    } as GridOptions;
    this.frameworkComponents = {
      CustomToolPanel: CustomGridToolPanelComponent,
      printInvoicecomponent: PrintInvoiceComponent,
      printClickRenderer: PrintClickRenderer
    };
  }

  collectionreport() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];
    const rowdata = [];
    const datePipe = this.datePipe;
    if (this.dms_enable) {
      this.res_data = this.apiService.chequeApprovalReportDms(this.user_data);
    } else {
      this.res_data = this.apiService.chequeApprovalReport(this.user_data);
    }
    const inthis = this;
    this.res_data.subscribe(res => {
        console.log(res);
        let dt = null;
        let tm = null;
        if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
          res['results'].data.forEach(data => {
            if (data.date_delivered) {
              dt = datePipe.transform((new Date(data.date_delivered + ' UTC')).toString(), 'dd/MM/yyyy');
              tm = datePipe.transform((new Date(data.date_delivered + ' UTC')).toString(), 'HH:mm:ss');
            } else {
              dt = null;
              tm = null;
            }
            let config_json = data.config_json;
            if (config_json !== null) {
              try {
                config_json = JSON.parse(config_json);
              } catch (e) {
                config_json = {};
              }
            } else {
              config_json = {};
            }
            let cheque_date = '';
            if (data.cheque_date) {
              cheque_date = datePipe.transform((new Date(data.cheque_date + ' UTC')).toString(), 'dd/MM/yyyy');
            }
            const pushData = {
              DATE: dt,
              TIME: tm,
              "PAYMENT DATE": datePipe.transform((new Date(data.payment_date + ' UTC')).toString(), 'dd/MM/yyyy'),
              "PAYMENT TIME": datePipe.transform((new Date(data.payment_date + ' UTC')).toString(), 'HH:mm:ss'),
              "OUTLET CODE": data.code,
              OUTLET: data.outlet,
              ID: data.out_id,
              TYPE: data.type,
              ORDER: data.order,
              "INVOICE NO": data.invoice_no,
              SALESMAN: data.user,
              BEAT: data.beat,
              STATUS: data.p_status,
              "PAYMENT TYPE": data.payment_type,
              "RCPT NO": data.rcpt_no,
              "CHEQUE NO": data.cheque_no,
              "CHEQUE DATE": cheque_date,
              BRANCH: data.branch_name,
              "CARD NAME": config_json['Card Name'],
              "UPI ID": config_json['UPI ID'],
              "BILL VALUE": parseFloat(data.total).toFixed(inthis.apiService.decimalPolicy),
              "PAID AMOUNT": parseFloat(data.paid).toFixed(inthis.apiService.decimalPolicy),
              OUTSTANDING: parseFloat(data.balance).toFixed(inthis.apiService.decimalPolicy),
              STATE: data.state,
              id: data.id,
              store_fname: data.store_fname,
              retailer_id: data.retailer_id
            };
            rowdata.push(pushData);
          });
          this.data = rowdata[0];
          this.rowData = rowdata;
          for (const key in this.data) {
            if (this.data.hasOwnProperty(key)) {
              if (key === "STATE") {
                this.columnDefs.push({
                  headerName: key, field: key, resizable: true, pinned: 'right',
                  cellStyle(params) {
                    if (params.value != null && params.value !== undefined) {
                      if (params.value === 'draft') {
                        return {'background-color': 'SkyBlue'};
                      } else if (params.value === 'rejected') {
                        return {'background-color': 'LightCoral'};
                      } else if (params.value === 'posted') {
                        return {'background-color': 'MediumSeaGreen'};
                      }
                    }
                  }
                });
              } else if (key === "DATE" || key === "PAYMENT DATE" || key === "OUTLET CODE" || key === 'PAYMENT TYPE') {
                this.columnDefs.push({
                  headerName: key, field: key, pinned: 'left', width: 100
                });
              } else if (key === "BILL VALUE" || key === "PAID AMOUNT" || key === 'OUTSTANDING') {
                this.columnDefs.push({
                  headerName: key, field: key, width: 120, cellStyle: {textAlign: "right"}, aggFunc: 'sum'
                });
              } else if (key === "id" || key === "store_fname" || key === 'retailer_id') {
                this.columnDefs.push({headerName: key, field: key, hide: true});
              } else if (key === "ID" || key === "TIME" || key === 'PAYMENT TIME' ||
                key === 'STATUS') {
                this.columnDefs.push({headerName: key, field: key, width: 100});
              } else {
                this.columnDefs.push({headerName: key, field: key, width: 200, aggFunc: 'sum'});
              }
            }
          }
          this.loaded = true;
          this.context = {componentParent: this};
        } else {
          this.preloader = false;
          this.empty_state = true;
        }
      },
      error => {
        console.log(error);
        this.preloader = false;
      });
  }

  run() {
    if (this.selectedStatus === 'All') {
      this.user_data.status = 'All';
    } else if (this.selectedStatus === 'Draft') {
      this.user_data.status = 'draft';
    } else if (this.selectedStatus === 'Posted') {
      this.user_data.status = 'posted';
    } else if (this.selectedStatus === 'Rejected') {
      this.user_data.status = 'rejected';
    }
    if (this.selectedType === 'All') {
      this.user_data.p_type = 'All';
    } else if (this.selectedType === 'Cash') {
      this.user_data.p_type = 'cash';
    } else if (this.selectedType === 'Cheque') {
      this.user_data.p_type = 'cheque';
    } else if (this.selectedType === 'UPI') {
      this.user_data.p_type = 'UPI';
    }
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = this.myDate[0];
    const secondDate = this.myDate[1];
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    if (diffDays > 31) {
      window.alert('Please select 1 Month Data ');
    } else {
      this.collectionreport();
    }
  }

  onCellClicked(event) {
    this.selected_item = event.data;
    if (this.selected_item['STATUS'] === 'draft') {
      this.displayApprove = true;
    }
    this.res_data = this.apiService.fetchImage({
      access_token: this.user_data.access_token,
      url: this.user_data.url,
      data: [{store_fname: this.selected_item.store_fname, container: "sale_order_payments"}]
    });
    this.res_data.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('result') && res['result'].length > 0 && res['result'][0].bill_datas) {
          this.photo = this.domSanitizer.bypassSecurityTrustUrl("data:image/png;base64, " + res['result'][0].bill_datas);
        }
        const el: HTMLElement = this.approveCheque.nativeElement;
        el.click();
      },
      error => console.log(error)
    );
  }

  callApprove() {
    const el: HTMLElement = this.chequeApprove.nativeElement;
    el.click();
  }

  reject() {
    const data = {
      access_token: this.user_data.access_token,
      url: this.user_data.url,
      data: {
        id: this.selected_item['RCPT NO'],
        dms_enable: this.dms_enable
      }
    };
    this.res_data = this.apiService.updateChequeReject(data);
    this.res_data.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
        this.collectionreport();
      }
    }, error => {
      console.log(error);
      window.alert('Failed');
    });
  }

  approve() {
    const data = {
      access_token: this.user_data.access_token,
      url: this.user_data.url,
      data: {
        amount: this.selected_item['PAID AMOUNT'],
        code: this.selected_item['CODE'],
        id: this.selected_item['RCPT NO'],
        order_id: this.selected_item.id,
        total: this.selected_item['BILL VALUE'],
        dms_enable: this.dms_enable,
        retailer_id: this.selected_item.retailer_id
      }
    };
    this.res_data = this.apiService.updateChequeApproval(data);
    this.res_data.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
        this.collectionreport();
      }
    }, error => {
      console.log(error);
      window.alert('Failed');
    });
  }


  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setRowData(this.rowData);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (params.api) {
          if (screen.width > 991) {
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
    } else {
      params.columnApi.autoSizeColumns();
    }
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  ngOnInit() {
    this.sharedService.setHeader('Payment Approval Report');
    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable === "true" ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {
    this.user_data.date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.user_data.last_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    localStorage.setItem('user_data', JSON.stringify(this.user_data));
  }

  showAll(event) {
    this.first_hide = !this.first_hide;
    this.gridColumnApi.setColumnsVisible(['Opening', 'Sales', 'Damage', 'Deviation', 'Closing'], this.first_hide);
    if (screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeAllColumns();
    }

  }
}
