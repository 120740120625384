import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";

@Component({
  selector: 'print-cell',
  template: `
    <div *ngIf="enable"><button class="btn white-green-btn" style="padding: 1px 20px;">Print</button></div>`,
  styles: []
})
export class PrintClickRenderer implements ICellRendererAngularComp {
  public params: any;
  public value;
  public loader = false;
  public enable = true;

  agInit(params: any): void {

    this.params = params;
    if (parseFloat(this.params.data['credit']) === 0) {
      this.enable = false;
    }

    if (params.node.rowPinned === 'bottom') {
      this.enable = false;
    }

    if (this.enable && this.params?.context?.componentParent && this.params.context.componentParent.hasOwnProperty('printShowField')) {
      if (this.params?.data[this.params.context.componentParent.printShowField]) {
        this.enable = true;
      } else {
        this.enable = false;
      }
    }
  }

  printInvoice() {
    this.params.context.componentParent.printInvoice(this.params.data).then(res => {
      this.params.context.componentParent.printDetails = res[0];
      this.params.context.componentParent.triggerFalseClick2();
    });
  }

  refresh(): boolean {
    return false;
  }
}
