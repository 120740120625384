import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";

@Component({
  selector: 'save-cell',
  template: `
    <div><button (click)="saveMTsale()" class="btn white-green-btn">Save</button></div>`,
  styles: []
})
export class SaveMTsaleComponent implements ICellRendererAngularComp {
  public params: any;
  public value;
  public loader = false;

  agInit(params: any): void {
    this.params = params;
  }

  saveMTsale() {
    this.params.context.componentParent.saveMTsale(this.params.data);
  }

  refresh(): boolean {
    return false;
  }
}
