<div class="common-wrapper">
    <div class="common-container">
      <div class="main-wrapper wflist">
              <div *ngIf="cardLoader" style="position:absolute;left:44%;top:200px">
                <img src="../../assets/images/loader_icon.gif"/>
              </div>
              <div class="van-list">
                <div class="wflist-vancard">
                  <app-van [img]="'assets/loadvanblue.png'" [value]="passedParams['TOTAL VANS']" [title]="'Total Vans'"></app-van>
                </div>
                <div class="wflist-vancard">
                  <app-van [img]="'assets/loadvangreen.png'" [value]="passedParams['ordered_vans']" [title]="'Loaded Out'"></app-van>
                </div>
                <div class="wflist-vancard">
                  <app-van [img]="'assets/loadvanred.png'" [value]="passedParams['deviation_count']" [title]="'Deviations'"></app-van>
                </div>
                <div class="wflist-vancard">
                  <app-van [img]="'assets/loadvanyellow.png'" [value]="passedParams['not_loaded']" [title]="'Not Loaded'"></app-van>
                </div>
                <div class="wflist-vancard marR0">
                  <app-van [img]="'assets/loadvanimg.png'" [value]="passedParams['VANS SETTLED']" [title]="'Settled'"></app-van>
                </div>
              </div>
        <div class="search-list">
          <div class="col-md-4 pad0">
              <div class="search">
                <span class="search-control"><img src="assets/searchicon.png"/></span>
                <input type="text" (keyup)="quickSearch()" [(ngModel)]="searchValue" class="search-control-area"
                placeholder="Search for vans/beat">
              </div>
          </div>
          <div class="col-md-8 pad0">
              <div class="filters">
                <ul class="list-unstyled">
                  <li class="margLR">
                      <app-dropdown (statusChange)="changeQty($event)"></app-dropdown>
                  </li>
                  <li class="margLR">
                      <app-dropdown-icon (statusChange)="changeStatus($event)"></app-dropdown-icon>
                  </li>
                  <li class="margLR">
                      <div class="date-picker">
                          <span class="datepicker-icon"><img src="../../assets/date.png"></span>
                          <input [readonly]="true" [(ngModel)]="myDate" [owlDateTimeTrigger]="dt" [owlDateTime]="dt" placeholder="Date">
                          <span class="dropdown-icon"><img src="../../assets/dropdown.png"></span>
                          <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
                      </div>
                  </li>
                    <li class="margLR">
                        <app-excelicon [gridOption]="gridOptions" [title]="'Van List'"></app-excelicon>
                    </li>
                  </ul>
              </div>
          </div>
          <div class="col-md-12 pad0">
            <div class="my-table wflist">
              <ag-grid-angular id="myGrid" *ngIf="loaded" style="width: 100%;height:60vh;" class="ag-theme-balham"  [columnDefs]="columnDefs" [gridOptions]="gridOptions"  (gridReady)="onGridReady($event)" [defaultColDef]="defaultColDef">
              </ag-grid-angular>
              <div *ngIf="preloader">
                <app-loadingstate></app-loadingstate>
              </div>
              <div *ngIf="empty_state">
                <app-emptystate></app-emptystate>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

