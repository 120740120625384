import {Component} from "@angular/core";
import {BaseUpload} from "../../../pagebase/base_upload/base_upload";
import {DMSBasePage} from "../../../pagebase/components/DMSBasePage";
import {UOMProductCellRendererComponent} from "../../../uomProductCellRenderer";
import {ITooltipParams} from "@ag-grid-enterprise/all-modules";

@Component({
  selector: 'app-stock-upload',
  templateUrl: '../../../pagebase/base_upload/base_upload.html',
  styleUrls: ['../../../pagebase/base_upload/base_upload.scss']

})

export class StockUploadComponent extends BaseUpload {

  uom_columns_count = 0;
  uom_master_list = [];
  product_list = [];

  setViewParams() {
    this.API_URL = '/api/pwa_dms_connects/stock_upload';
    this.header = 'Stock Upload';
    this.sampleText1 = 'DOWNLOAD ALL PRODUCT LIST';
    this.sampleText2 = 'DOWNLOAD TERRITORY LINKED PRODUCTS';
    this.excel_columns = [{C: 'A', H: 'Product Code', V: 'PC001', required: true},
      {C: 'B', H: 'Product Name', V: 'SWater bottle- 500 ml', required: false},
      {C: 'C', H: 'System', V: '412', required: true},
      {C: 'C', H: 'New Stock', V: '512', required: true},
      {C: 'D', H: 'Stock Deviation', V: '100'},
      {C: 'E', H: 'Reason for Deviation', V: 'Counter Sales/Order Not delivered', required: true},
      {C: 'F', H: 'Pack 1 Name', V: 'Box'},
      {C: 'G', H: 'Pack 1 Size', V: '10'},
      {C: 'H', H: 'Pack 1 Qty', V: '41'},
      {C: 'I', H: 'Pack 2 Name', V: 'Pcs'},
      {C: 'J', H: 'Pack 2 Size', V: '1'},
      {C: 'K', H: 'Pack 2 Qty', V: '2'},
    ];
    this.step1Text = 'Download all product lists or territory-linked product lists for reference.';
    this.step2Text = 'Required fields are product code, new stock and reason for deviation FROM CURRENT STOCK';
    this.actionText = 'Add or edit stock info in the template';
  }

  async fetchBaseData() {
    const res = await this.apiService.postPromise('/api/pwa_admin_connects/get_supplier_stock_with_uom_master', this.userData);
    if (res.hasOwnProperty('results') && (res.results.status === 200)) {
      this.dataLoaded = true;
      this.product_list = res.results.data['stock'];
      this.baseData = res.results.data['stock'];
      this.uom_master_list = res.results.data['uom_master'];
      const stock_data = this.processTerritoryProductData(res.results.data['stock']);
      this.configureGrid();
    } else {
      this.toastr.error('Failed to fetch Upload Master Data');
      this.dialog.closeAll();
    }
  }

  sampleDownload1() {
    this.apiService.post('/api/product_products/getProductsListPWA', this.userData)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            const productData = this.processProductData(res.results.data);
            this.downloadExcel(productData, 'all_products');
          } else {
            this.preloader = false;
            //this.empty_state = true;
          }
        },
        errorMsg => {
          console.log(errorMsg);
          this.preloader = false;
        });
  }

  sampleDownload2() {
    this.apiService.post('/api/pwa_admin_connects/get_supplier_stock_with_uom_master', this.userData)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {


            const stock_data = this.processTerritoryProductData(res.results.data['stock']);

            stock_data.forEach(d => {
              delete d['uom_category_id'];
            });

            this.downloadExcel(stock_data, 'stock_upload_sheet');
          } else {
            this.preloader = false;
            //this.empty_state = true;
          }
        },
        errorMsg => {
          console.log(errorMsg);
          this.preloader = false;
        });
  }

  processProductData(products) {
    const productData = [];
    for (const prod of products) {
      const indrow: any = {};
      indrow.category = prod.category;
      indrow.brand = prod.brand;
      indrow.sku_code = prod.sku_code;
      indrow.sku_name = prod.sku_name;
      indrow.sku_seq = prod.sku_seq;
      indrow.description = prod.description;
      indrow.uom = prod.uom;
      indrow.uom_factor = prod.uom_factor;
      indrow.ptd = prod.ptd;
      indrow.ptr = prod.ptr;
      indrow.priority = prod.priority;
      indrow.active = prod.active;
      if (prod.hasOwnProperty('tax_config') && prod.tax_config) {
        indrow.gst = prod.tax_config.gst;
        indrow.vat = prod.tax_config.vat;
        indrow.cess = prod.tax_config.cess;
        indrow.excise = prod.tax_config.excise;
      }
      productData.push(indrow);
    }
    return productData;
  }

  processTerritoryProductData(products, ) {
    const productData = [];
    this.uom_columns_count = 0;

    for (const prod of products) {

      const indrow: any = {};
      let uomindex = 0;
      indrow['Product Code'] = prod.default_code;
      indrow['Product Name'] = prod.name;
      indrow['Current Stock'] = prod.stock;
      indrow['New Stock'] = prod.stock;
      indrow['uom_category_id'] = prod.uom_category_id;
      indrow['Reason for Deviation'] = '';

      if (prod.uom_category_id > 0) {
        // const bigger_uoms = this.uom_master_list.filter(u => u.category_id === prod.uom_category_id);
        const packs = DMSBasePage.GetQuantityBreakupsByUOMCategory(this.uom_master_list, prod.stock, prod.uom_category_id);
        packs.forEach(p => {
          indrow['PACK ' + (uomindex + 1) + ' QTY'] = p.qty;
          indrow['PACK ' + (uomindex  + 1) + ' NAME'] = p.name;
          indrow['PACK ' + (uomindex + 1) + ' SIZE' ] = p.size;
          uomindex++;
        });
      }
      if (this.uom_columns_count < uomindex) {
        this.uom_columns_count = uomindex;
      }
      productData.push(indrow);
    }


    // Set the top rows header for Excel header creation
    for (let i = 0; i < this.uom_columns_count; i++) {
      if (!productData[0].hasOwnProperty('PACK ' + (i + 1) + ' NAME')) {
        productData[0]['PACK ' + (i + 1) + ' QTY'] = '';
        productData[0]['PACK ' + (i + 1) + ' NAME'] = '';
        productData[0]['PACK ' + (i + 1) + ' SIZE'] = '';
      }
    }

    this.baseData = productData;
    return productData;
  }

  configureGrid() {
    this.columnDefs = [
      {headerName: 'Product Code', field: 'product_code', width: 100},
      {headerName: 'Product Name', field: 'product_name', width: 200},
      {headerName: 'System', field: 'current_stock', width: 120, cellStyle: {textAlign: 'right'}},
      {headerName: 'New Stock', field: 'new_stock', width: 100, cellStyle: {textAlign: 'right'}},

      {
        headerName: 'Deviation', field: 'deviation', width: 100,
        cellStyle(params) {
          if (params.data && params.value) {
            if (params.value > 0) {
              return {color: '#379862', textAlign: 'right'};
            } else if (params.value < 0) {
              return {color: '#E24260', textAlign: 'right'};
            } else {
              return {textAlign: 'right'};
            }
          }
        },
      },
      {headerName: 'Description', field: 'description', width: 100},
      {headerName: 'Reason for Deviation', field: 'reason', width: 200}
    ];

    for (let i = 0; i < this.uom_columns_count; i++) {
      // tslint:disable-next-line:max-line-length
      this.columnDefs.push({headerName: 'PACK ' + (i + 1) + ' QTY', field: 'PACK ' + (i + 1) + ' QTY', width: 20, editable: this.checkUOMEditable, cellStyle: {textAlign: 'right'}, cellEditor: 'agNumberCellEditor', cellRenderer: UOMProductCellRendererComponent});
      this.columnDefs.push({headerName: 'PACK ' + (i + 1) + ' NAME', field: 'PACK ' + (i + 1) + ' NAME', width: 40});
    }

    this.columnDefs.push(
      {headerName: 'Valid', field: 'valid', width: 80, pinned: 'right', cellRenderer: this.statusCellRenderer},
      {
        headerName: 'Status', field: 'status', width: 80, pinned: 'right', hide: false,
        cellRenderer: this.statusIndicatorCellRenderer,
        tooltipValueGetter: (params: ITooltipParams) => {
          if (params.data.errorMsg) {
            return params.data.errorMsg;
          }
        },
      },
      {headerName: 'Error', field: 'errorMsg', maxWidth: 250, pinned: 'right', wrapText: true, autoHeight: true,  hide: false},
      {
        headerName: '',
        field: 'delete',
        width: 50, pinned: 'right',
        menuTabs: [],
        cellRenderer(params) {
          if (params.data && params.data.valid === 'Invalid') {
            return '<span title="Delete" style="color: #E24260"><i class="fas fa-trash-alt delete" data-action-type="delete"></i></span>';
          }
        },
      }
    );

    this.gridOptions.getRowStyle = params => {
      if (params.data && params.data.status === 'invalid') {
        return {background: '#F8D1C8'};
      }
    };

  }

  checkUOMEditable(params) {
    if (params && params.data) {
      const field_name = params.colDef.field.split(' ');
      if (params.data[field_name[0] + ' ' + field_name[1] + ' SIZE'] && params.data[field_name[0] + ' ' + field_name[1] + ' SIZE'] > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  validate() {
    const inThis = this;
    this.validRecords = 0;
    this.errorRecords = 0;
    this.rowData = [];
    this.uploaded_data.forEach(row => {


      const indrow: any = {};
      indrow.product_code = row['Product Code'];
      const found = inThis.baseData.find(x => x['Product Code'] && (x['Product Code'].toString() === row['Product Code'].toString()));

      if (!indrow['product_code'] || indrow['product_code'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Product Code Missing';
      } else if (!found) {
        indrow.product_name = row['Product Name'];
        indrow.current_stock = 0;
      } else {
        indrow.product_name = found['Product Name'];
        indrow.current_stock = found['Current Stock'];
      }

      indrow.new_stock = row['New Stock'];
      indrow.reason = row['Reason for Deviation'];

      if (found && found.uom_category_id > 0) {
        // const bigger_uoms = this.uom_master_list.filter(u => u.category_id === found.uom_category_id);
        indrow.description = DMSBasePage.GetStockDescription(this.uom_master_list, indrow.new_stock, found.uom_id);
        const packs = DMSBasePage.GetQuantityBreakupsByUOMCategory(this.uom_master_list, indrow.new_stock, found.uom_category_id);
        let pIndex = 0;
        packs.forEach(p => {
          indrow['PACK ' + (pIndex + 1) + ' QTY'] = row['PACK ' + (pIndex + 1) + ' QTY'];
          indrow['PACK ' + (pIndex + 1) + ' NAME'] = p.name;
          indrow['PACK ' + (pIndex + 1) + ' SIZE'] = p.size;
          pIndex++;
        });
      }


      if (indrow.new_stock === null || indrow.new_stock === undefined || indrow.new_stock === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'New Stock value Missing';
      } else if (!this.numPattern.test(indrow.new_stock)) {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Invalid Units';
      } else {
        indrow['deviation'] = parseInt(indrow.new_stock, 10) - parseInt(indrow.current_stock, 10);
      }


      if (indrow.valid === 'Invalid') {
        this.errorRecords++;
      } else {
        indrow.valid = 'Valid';
        this.validRecords++;
      }
      if (!(indrow.valid === 'Valid' && indrow['deviation'] === 0)) {
        inThis.rowData.push(indrow);
      }
    });
    if (this.gridOptions && this.gridOptions.api) {
      this.gridOptions.api.setRowData(this.rowData);
    }
  }
}
