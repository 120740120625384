import { Component } from '@angular/core';
import {ICellRendererAngularComp} from "@ag-grid-community/angular";

@Component({
  selector: 'app-team-edit-button-renderer',
  template: `
    <button type="button" (click)="onClick($event)">
      <i class="fal fa-edit"></i> {{label}}
      </button>
    `
})

export class AGGridEditButtonRendererComponent implements ICellRendererAngularComp {

  params;
  label: string;

  agInit(params): void {
    this.params = params;
    this.label = this.params.label || null;
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
      };
      this.params.onClick(params);

    }
  }
}
