import {Component, ElementRef, Inject, OnInit, Renderer2, ViewChild} from "@angular/core";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {ApiService} from "../../_services/api.service";
import {Router} from "@angular/router";
import {DatePipe, DOCUMENT} from "@angular/common";
import {SharedService} from "../../_services/shared_service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {BulkAddRetailerComponent} from "../../components/bulk_add_retailer/bulk_add_retailer.component";
import {ToastrService} from "ngx-toastr";
import {ConfirmDialogComponent} from "../../components/confirmdialog/confirmdialog.component";
import {DomSanitizer} from "@angular/platform-browser";
import {ImagePopupComponent} from "../../components/image_popup/image_popup.component";
import {TaskPublishPopupComponent} from "../../components/task_publish_popup/task_publish_popup.component";

@Component({
  selector: 'app-user_task_detail-component',
  templateUrl: './user_task_detail.component.html',
  styleUrls: ['./user_task_detail.component.scss']
})
export class UserTaskDetailComponent implements OnInit {

  @ViewChild('imageClick') imageClick: ElementRef<HTMLElement>;
  public columnDefs = [];
  public columnDefs1 = [];
  public rowData = [];
  public rowData1 = [];
  public gridOptions: GridOptions;
  public gridOptions1: GridOptions;
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public loaded1 = false;
  public preloader1 = false;
  public empty_state1 = false;
  public defaultColDef;
  public styleGrid;
  public frameworkComponents;
  public context;
  public pinnedTotalData = [
    {
      code: 'Add an Item',
      image: 'bottom',
      actions: 'bottom'
    }
  ];
  public taskDetails: any = {};
  public edit = false;
  API_URL = '';
  user_data = {
    date: null,
    access_token: null,
    url: null,
    data: {
      start_date: null,
      end_date: null,
      id: null
    }
  };
  viewtype = 'edit';
  selectedOutlets = [];
  public photo;
  res_data;
  task_types = [];
  selected_task_type: any = {};
  allow_start_date: boolean = true;
  private gridApi;
  private gridColumnApi;

  constructor(private apiService: ApiService,
              private router: Router,
              private datePipe: DatePipe,
              private renderer: Renderer2,
              private sharedService: SharedService,
              private elRef: ElementRef,
              public dialog: MatDialog,
              public domSanitizer: DomSanitizer,
              private toastr: ToastrService,
              @Inject(DOCUMENT) private _document: Document) {

    this.user_data.access_token = localStorage.getItem('resfreshToken');

    this.task_types = JSON.parse(localStorage.getItem('task_types'));
    console.log(this.task_types);

    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left'},
      /* getRowStyle: params => {
         if (params.node.rowIndex === params.api.rowModel.rowsToDisplay.length - 1){
           return { background: 'red' };
         }
         else {
           return {}
         }
       },*/
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      rowSelection: 'multiple'
    } as GridOptions;

    this.gridOptions1 = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple'
    } as GridOptions;

    let datas: any = {};
    datas = history.state;
    this.user_data.data.id = localStorage.getItem("taskid");
    this.viewtype = datas.viewtype;
    if (this.viewtype === 'create') {
      this.edit = true;
    }
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;

    this.columnDefs = [
      {headerName: "SCODE", headerClass: 'myagheader', field: "scode", sortable: true, filter: true, lockPosition: true, menuTabs: [], width: 100, resizable: true},
      {headerName: "SALESMAN", headerClass: 'myagheader', field: "user", sortable: true, filter: true, lockPosition: true, menuTabs: [], width: 150, resizable: true},
      {
        headerName: "STATUS", headerClass: 'myagheader', field: "status", sortable: true, filter: true, lockPosition: true, menuTabs: [], width: 100, resizable: true,
        cellStyle: params => {
          if (params.data) {
            if (params.data.status === 'completed') {
              return {color: '#439E6C'};
            } else if (params.data.status === 'published') {
              return {color: '#3952e2'};
            } else {
              return {color: '#D97C0E'};
            }
          }
        }
      },
      {headerName: "COMMENTS", headerClass: 'myagheader', field: "comments", sortable: true, filter: true, lockPosition: true, menuTabs: [], width: 150, resizable: true},
      {headerName: "STORE FNAME", headerClass: 'myagheader', field: "task_store_fname", hide: true, sortable: true, filter: true, lockPosition: true, menuTabs: [], width: 150, resizable: true},
      {
        headerName: "IMAGE", headerClass: 'myagheader', field: "image", sortable: true, filter: true, lockPosition: true, menuTabs: [], width: 100, resizable: true,
        cellRenderer: params => {
          if (params.data) {
            if (params.data.task_store_fname === null || params.data.task_store_fname === "") {
              return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
            } else {
              return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
            }
          }
        }
      },
      {
        headerName: "", headerClass: 'myagheader', field: "delete", cellStyle: {textAlign: 'right'}, sortable: true, filter: true, lockPosition: true, menuTabs: [], width: 50, resizable: true,
        cellRenderer: params => {
          if (params.data) {
            console.log(params._self.edit);
            if (params._self.edit) {
              if (params.data.status !== 'draft') {
                return "";
              } else {
                return ' <span title="Delete" ><i class="fas fa-trash delete" data-action-type="delete"></i></span>';
              }
            } else {
              return "";
            }
          }
        }, cellRendererParams: {
          _self: this
        }

      },

    ];
    this.fetchTaskDetails();
  }

  ngOnInit(): void {
  }

  fetchTaskDetails() {
    this.API_URL = '/api/tasks/get_task_detail';
    this.apiService.post(this.API_URL, this.user_data)
      .subscribe(res => {
          console.log(res);
          this.loaded = true;
          if (res.hasOwnProperty('results') && (res.results.status <= 200)) {

            if (res.results.status < 200 && res.results.msg !== null) {
              this.toastr.warning(res.results.msg);
            }

            this.taskDetails = res.results.data;
            this.taskDetails.progress = Math.trunc((parseInt(this.taskDetails.completed_count) / parseInt(this.taskDetails.line_count)) * 100);
            this.checkAllowStartDate();
            this.rowData = this.taskDetails.line_items;
            console.log(this.taskDetails);
            console.log(this.rowData);
            if (this.gridApi !== null && typeof this.gridApi !== 'undefined') {
              this.gridApi.setRowData(this.rowData);
            }

          } else {
            this.preloader = false;

          }
        },
        error => {
          console.log(error);
          this.preloader = false;
        });
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setRowData(this.rowData);
    this.preloader = false;

    const sortModel = [
      {colId: 'Date', sort: 'desc'}
    ];
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });

    if (screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    if (column === 'delete') {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "550px",
        maxHeight: "250px",
        data: {
          title: "Delete Task Line !!",
          message1: rows[0].retailer_code + " - " + rows[0].retailer,
          message2: "",
          showNoButton: true,
          yesButton: "DELETE",
          noButton: "CANCEL",
          deleteButton: true
        }
      };

      const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);

      confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

        console.log(data);
        if (data === 'yes') {
          rows[0].active = false;
          this.createOrUpdateTask();
        } else {

        }
      });
    } else {
      if (rows !== null && rows.length > 0 && rows[0].hasOwnProperty('task_store_fname') && rows[0].task_store_fname !== null && rows[0].task_store_fname !== "") {
        if (screen.width > 991) {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "70%",
            maxHeight: "95%",
            data: {
              store_fname: rows[0].task_store_fname,
              container: "task_line"
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        } else {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "90%",
            maxHeight: "65%",
            data: {
              store_fname: rows[0].task_store_fname,
              container: "task_line"
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        }

      } else {

      }

    }

  }

  editClick() {
    this.edit = !this.edit;
    this.gridApi.setRowData(this.rowData);
  }

  discardClick() {
    this.edit = !this.edit;
    this.gridApi.setRowData(this.rowData);
    this.fetchTaskDetails();
  }

  cancelClick() {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "550px",
      maxHeight: "250px",
      data: {
        title: "Delete Task!!",
        message1: "",
        message2: "Do you wish to Delete this Task?",
        showNoButton: true,
        yesButton: "DELETE",
        noButton: "CANCEL",
        deleteButton: true
      }
    };

    const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);

    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

      console.log(data);
      if (data === 'yes') {
        this.taskDetails.active = false;
        this.createOrUpdateTask();
      } else {

      }
    });
  }

  openPublishDialog() {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "550px",
      maxHeight: "550px",
      data: {
        title: "Publish Task",
        message1: "You will not be allowed to edit the Task once Published.",
        message2: "Do you wish to Publish the Task?",
        showNoButton: true,
        yesButton: "PUBLISH",
        noButton: "CANCEL"
      }
    };

    const confirmdiaeref = this.dialog.open(TaskPublishPopupComponent, config);

    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

      console.log(data);
     /* if (data === 'yes') {
        this.taskDetails.status = 'published';
        if (this.taskDetails.hasOwnProperty('line_items') && this.taskDetails.line_items.length > 0) {
          for (var i = 0; i < this.taskDetails.line_items.length; i++) {
            this.taskDetails.line_items[i].status = 'published';
          }
        }
        this.createOrUpdateTask();
      } else {

      }*/
      this.taskDetails.status = 'published';
      if (this.taskDetails.hasOwnProperty('line_items') && this.taskDetails.line_items.length > 0) {
        for (var i = 0; i < this.taskDetails.line_items.length; i++) {
          this.taskDetails.line_items[i].status = 'published';
        }
      }
      if (data && data.length > 0) {
        this.taskDetails.brand_id = data[0].id;
      } else {
        this.taskDetails.brand_id = null;
      }
      this.createOrUpdateTask();
    });
  }

  createDialog() {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "1000px",
      maxHeight: "620px",
    };

    const creatediaeref = this.dialog.open(BulkAddRetailerComponent, config);

    creatediaeref.componentInstance.selectedOutlets.subscribe(data => {

      this.selectedOutlets = data;
      console.log(this.selectedOutlets);

      console.log(this.rowData);

      /* this.selectedOutlets.forEach(function(data){
         this.rowData.push(data);
       });*/
      var indoutlet: any = {};
      if (!this.taskDetails.hasOwnProperty("line_items")) {
        this.taskDetails.line_items = [];
        this.taskDetails.status = 'draft';
      }

      for (var i = 0; i < this.selectedOutlets.length; i++) {
        indoutlet = {};
        indoutlet.retailer = this.selectedOutlets[i].name;
        indoutlet.beat = this.selectedOutlets[i].beat[0].bp;
        indoutlet.retailer_code = this.selectedOutlets[i].code;
        indoutlet.partner_id = this.selectedOutlets[i].id;
        indoutlet.user_id = this.selectedOutlets[i].user_id;
        indoutlet.user = this.selectedOutlets[i].user;
        indoutlet.area = this.selectedOutlets[i].area;
        indoutlet.zone = this.selectedOutlets[i].zone;
        indoutlet.class_type = this.selectedOutlets[i].class_type;
        indoutlet.program_type = this.selectedOutlets[i].program_type;
        indoutlet.status = "draft";
        indoutlet.active = true;

        //this.rowData.push(indoutlet);
        this.taskDetails.line_items.push(indoutlet);
      }

      if (this.rowData === null || this.rowData.length === 0) {
        this.rowData = this.taskDetails.line_items;
        this.loaded = true;
      }

      console.log(this.rowData);

      this.gridApi.setRowData(this.rowData);
    });
  }

  quickSearch() {
    this.gridApi.setQuickFilter(document.getElementById('filter-text-box')['value']);
  }

  setStartDate(dt) {
    //console.log(this.newExpenseData.expense_date)
    this.taskDetails.start_date = this.datePipe.transform(this.taskDetails.start_date, 'yyyy-MM-dd');
  }

  setEndDate(dt) {
    //console.log(this.newExpenseData.expense_date)
    this.taskDetails.end_date = this.datePipe.transform(this.taskDetails.end_date, 'yyyy-MM-dd');
  }

  createOrUpdateTask() {
    console.log(this.taskDetails);
    let paramObject: any = {};
    let apiTaskData: any = {};
    apiTaskData.id = this.taskDetails.id;
    apiTaskData.name = this.taskDetails.name;
    apiTaskData.type_id = this.taskDetails.type_id;
    apiTaskData.code = this.taskDetails.code;
    apiTaskData.type_id = this.taskDetails.type_id;
    apiTaskData.description = this.taskDetails.description;
    apiTaskData.instructions = this.taskDetails.instructions;
    apiTaskData.start_date = this.taskDetails.start_date;
    apiTaskData.end_date = this.taskDetails.end_date;
    apiTaskData.active = this.taskDetails.active;
    apiTaskData.status = this.taskDetails.status;
    if (this.taskDetails.brand_id) {
      apiTaskData.brand_id = this.taskDetails.brand_id;
    }
    apiTaskData.line_items = [];
    paramObject.access_token = this.user_data.access_token;
    for (let key in this.taskDetails.line_items) {
      let ind_line: any = {};
      ind_line.task_id = this.taskDetails.id;
      ind_line.id = this.taskDetails.line_items[key].id;
      ind_line.partner_id = this.taskDetails.line_items[key].partner_id;
      ind_line.status = this.taskDetails.line_items[key].status;
      ind_line.active = this.taskDetails.line_items[key].active;
      ind_line.comments = this.taskDetails.line_items[key].comments;
      ind_line.task_store_fname = this.taskDetails.line_items[key].task_store_fname;
      ind_line.reason_id = this.taskDetails.line_items[key].reason_id;
      if (this.taskDetails.line_items[key].hasOwnProperty('user_id')) {
        ind_line.user_id = this.taskDetails.line_items[key].user_id;
      }
      apiTaskData.line_items.push(ind_line);
    }
    if (!apiTaskData.hasOwnProperty("status")) {
      apiTaskData.status = 'draft';
    }


    paramObject.data = apiTaskData;
    this.API_URL = '/api/tasks/create_task';
    console.log(this.taskDetails);
    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          if (this.viewtype === 'create') {
            this.toastr.success('Task created Successfully');
            this.viewtype = 'detail';
          } else {
            this.toastr.success('Task updated Successfully');
          }

          if (res.results.hasOwnProperty('task_id')) {
            localStorage.setItem("taskid", res.results.task_id);
          }

          if (this.edit) {
            this.edit = !this.edit;
          }

          if (this.taskDetails.active) {
            //this._document.defaultView.location.reload();
            this.fetchTaskDetails();
          } else {
            window.history.go(-1);
          }

        } else if (res.hasOwnProperty('results')) {
          this.toastr.error(res.results.msg);
        } else {
          this.toastr.error("Task Updation Failed");
        }
      });

  }

  checkAllowStartDate() {
    if (this.task_types !== null) {
      let typeid = this.taskDetails.type_id;
      if (typeid) {
        typeid = parseInt(typeid, 0);
        const allowStartDate = this.task_types.filter(type => type.id === typeid).map(obj => obj.allow_start_date);
        console.log(allowStartDate);
        if (allowStartDate !== null) {
          this.allow_start_date = allowStartDate[0];
        } else {
          this.allow_start_date = true;
        }
      } else {
        typeid = 0;
        this.allow_start_date = true;
      }
    }
  }

  viewReport() {
    this.router.navigate([this.apiService.redirect_url + '/reports/task_activation'], {
      state: {

        taskID: this.taskDetails.id,
        start_date: this.taskDetails.start_date,
        end_date: this.taskDetails.end_date
      }
    });
  }

  goToSurvey() {
    this.router.navigate([this.apiService.redirect_url + '/reports/task_survey'],{state: {
        surveyID : this.taskDetails.survey_id
      }});
  }

}
