<div class="eod-sales-card">
  <div class="van-card-img">
    <img class="van-image" src={{card_img}} />
    <label>{{card_name}}</label>
    <ul>
      <li>
        <label>{{title}}</label>
        <span>{{value}}</span>
      </li>
      <li>
        <label>{{title1}}</label>
        <span>{{value1}}</span>
      </li>
      <li>
        <label>{{title2}}</label>
        <span>{{value2}}</span>
      </li>
      <li>
        <label>{{title3}}</label>
        <span>{{value3}}</span>
      </li>
      <li *ngIf="first_card">
        <label>{{title4}}</label>
        <span>{{value4}}</span>
      </li>
    </ul>
  </div>
</div>
