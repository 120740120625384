import {Component, Input, OnInit} from "@angular/core";

@Component({
  selector: 'app-star-rating',
  templateUrl: './star_rating.component.html',
  styleUrls: ['./star_rating.component.scss']
})


export class StarRatingComponent {
  @Input() index: number;
  @Input() selected: number;
  @Input() hover: number;
}
