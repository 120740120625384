import {PageBase} from "../../../pagebase/pagebase";
import {Component, OnInit} from "@angular/core";
import {ErrorHandler} from "../../../error/error-handler";
import {MatDialogConfig} from "@angular/material/dialog";
import {BulkInvoiceComponent} from "../sale_order/bulk_invoice/bulk_invoice";
import {ClaimGeneratePopupComponent} from "./claim_generate_popup/claim_generate_popup.component";


@Component({
  selector: 'app-claims-list',
  templateUrl: '../../../pagebase/pagebasev2.html',
  styleUrls: ['../../../pagebase/pagebase.scss']
})

export class ClaimsListComponent extends PageBase implements OnInit, IPage {

  customFilter: any = {
    name: 'Status',
    key: 'status',
    value: 'new',
    values: [],
    show: true
  };
  approverConfig;
  ngOnInit(): void {

    this.sharedService.setHeader('My Claims');
    this.showCreate = true;
    this.showActionButton = true;
    this.tabIndex = 1;
    this.customFilter.values = [
      {id: 'all', itemName: 'All'},
      {id: 'new', itemName: 'New'},
      {id: 'confirmed', itemName: 'Confirmed'},
      {id: 'processing', itemName: 'Processing'},
      {id: 'approved', itemName: 'Approved'},
      {id: 'rejected', itemName: 'Rejected'},
    ];
    this.statusConfig.success = 'approved';
    this.statusConfig.warning = 'processing';
    this.statusConfig.error = 'rejected';
    this.statusConfig.processed = 'confirmed';
    this.statusConfig.new = 'new';
    this.showCustomFilter = true;

    const company_config = JSON.parse(localStorage.getItem('company_config'));
    if (company_config && company_config.length > 0) {
      this.approverConfig = company_config.find(x => x.type === 'Claim Approval');
      if (this.approverConfig && this.approverConfig?.config_json?.config && this.approverConfig.config_json.config.length > 0) {
        this.approverConfig = this.approverConfig?.config_json.config[0].appr_roles;
        this.approverConfig = this.approverConfig.sort((a, b) => a.seq - b.seq);
      }
    }
    this.checkAndLoadPreviousSate();
    this.getAuthorizedActions();
    this.checkAccessControl();
    this.API_URL = '/api/pwa_dms_connects/get_partner_claims';
    this.loadServerData();
  }

  configureGrid() {
    this.columnDefs = [
      {
        headerName: "",
        children: [
          {headerName: "ID", field: "id", width: 30},
          {headerName: "Claim NO.", field: "name", width: 50},
          {headerName: "Date", field: "date", width: 60},
          {headerName: "Type", field: "type", width: 60},
          {headerName: "Amount", field: "amount", width: 50, aggFunc: 'sum', cellStyle: {textAlign: 'right'}, valueFormatter: params => this.formatNumber(params.value)},
          {headerName: "Status", field: "status", width: 100, cellRenderer: this.sharedService.statusCellRenderer},
          {headerName: "Comments", field: "comments", width: 100}
        ]
      }
    ];
    if (this.tabIndex !== 1 && this.tabIndex !== 2) {
      if (this.approverConfig && this.approverConfig.length > 0) {
        this.approverConfig.forEach(config => {
          this.columnDefs.push(
            {
              headerName: "Reviewer " + config.seq + " - " + config.role_name,
              children: [
                {headerName: "Name", field: config.role_name + "_" + config.seq, width: 60},
                {headerName: "Date", field: config.role_name + "_date_" + config.seq, width: 60,
                  valueGetter: params => {
                    if (params.data) {
                      if (params.data[config.role_name + "_date_" + config.seq]) {
                        return this.datePipe.transform((new Date(params.data[config.role_name + "_date_" + config.seq] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
                      } else {
                        return '';
                      }
                    } else {
                      return '';
                    }
                  }
                },
                {headerName: "Status", field: config.role_name + "_st_" + config.seq, width: 60, cellRenderer: this.sharedService.statusIndicatorCellRenderer},
              ]
            }
          );
        });
      }
    }
    this.getRowId = (params) => params.data.id;
  }

  postProcessData() {
    this.configureGrid();
    this.configureData();
  }

  configureData() {
    this.rowData = [];
    if (this.apiData && this.apiData.length > 0) {
      this.apiData.forEach(row => {
        if (this.approverConfig && this.approverConfig.length > 0) {
          if (row?.config_json?.app_dtls && row.config_json.app_dtls.length > 0) {
            row.config_json.app_dtls.forEach(config => {
              const found = this.approverConfig.find(x => x.role_id === config.role_id);
              if (found) {
                row[config.role_name + "_" + found.seq] = config.app_by;
                row[config.role_name + "_date_" + found.seq] = config.app_on;
                row[config.role_name + "_st_" + found.seq] = config.status;
              }
            });
          }
        }
        this.rowData.push(row);
      });
    }
  }

  onCellClicked(event) {
    const rows = this.gridApi.getSelectedRows();
    const filteredRowData = this.gridApi.rowModel.rowsToDisplay;
    if (filteredRowData !== null && filteredRowData.length > 0) {
      const ids = [];
      filteredRowData.forEach(r => {
        ids.push(r.data.id);
      });
      localStorage.setItem('active_ids', JSON.stringify(ids));
      localStorage.setItem('activeID', event.data.id);
      this.router.navigate([this.apiService.redirect_url + '/dms/claim_detail'], {
        relativeTo: this.route,
        queryParams: {id: rows[0].id}
      });
    }
  }

  checkAccessControl() {
    // this._localCacheService.getAccessControlList().then(list => {
   // this.showCreate = false;
    // });
  }

  openCreateDialog() {
    // this.router.navigate([this.apiService.redirect_url + '/dms/sale_invoice'], {
    //   relativeTo: this.route,
    //   queryParams: {id: 0 }
    // });
    const config: MatDialogConfig = {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'full-screen-modal',
      data: {
        // supplier_territory_id: supp_territory_id
      },
    };

    const dialogRef = this.dialog.open(ClaimGeneratePopupComponent, config);
    dialogRef.afterClosed().subscribe(result => {
      this.loadServerData();
    });
  }

}
