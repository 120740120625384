<table class="ui padded table" >
    <thead>
    <th class="index-column">#</th>
    <th>name</th>
    <th></th>
    </thead>
    <tr class="ui basic segment" *ngFor="let board of dashboardList; let i = index" >
        <td class="index-column">{{i + 1}}</td>
        <td>{{board}}</td>
        <td style="text-align:right">
            <a mat-mini-fab routerLink="." (click)="deleteBoard(board)">
                <mat-icon>clear</mat-icon>
            </a>
        </td>
    </tr>
</table>

<div class="ui basic segment">

    <div class="grid-row">
        <mat-form-field class="example-full-width">
            <input [(ngModel)]="newDashboardItem" matInput placeholder="Enter Dashboard Name">
        </mat-form-field>

        <a mat-mini-fab routerLink="." color='primary' (click)="createBoard(newDashboardItem)">
            <mat-icon>add</mat-icon>
        </a>
    </div>

</div>
