import {Component, ElementRef, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {ApiService} from "../../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {DomSanitizer} from "@angular/platform-browser";
import {ToastrService} from "ngx-toastr";
import {SharedService} from "../../../_services/shared_service";
import {DMSBasePage} from "../../../pagebase/components/DMSBasePage";
import {DatePipe} from "@angular/common";
import {LocalforageService} from "../../../_services/localforage.service";
import {ErrorHandler} from "../../../error/error-handler";
import {AppComponent} from "../../../app.component";

@Component({
  selector: 'app-manage-tcs-popup',
  templateUrl: './manage_tcs.component.html'
})

export class ManageTCSPopupComponent extends DMSBasePage implements OnInit {

  @Output() refreshPageAction: EventEmitter<any> = new EventEmitter<any>();

  activeObject = {
    id: 0,
    name: '',
    code: '',
    zone: '',
    gst_id: '',
    mobile: '',
    email: '',
    address: '',
    balance: '',
    credit: '',
    credit_limit: '',
    credit_days: '',
    tcs: ''
  };

  context: any;

  public tcs_percentage = 0.1;
  public gst_verified = true;

  constructor(public dialog: MatDialog,
              public apiService: ApiService,
              public sharedService: SharedService,
              public toastr: ToastrService,
              public elRef: ElementRef,
              public datePipe: DatePipe,
              protected route: ActivatedRoute,
              protected _localCacheService: LocalforageService,
              public router: Router,
              public domSanitizer: DomSanitizer,
              public appComponent: AppComponent,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    super(apiService, sharedService, elRef, datePipe, _localCacheService, toastr, route, dialog, router, domSanitizer, appComponent);

    this.activeObject.id = dialogdata.partner_id;
    if (this.sharedService.ACLcheck('module_dmsv2')) {
      const cust_obj = this.customerList.find(item => item.id === this.activeObject['partner_id']);
      if (cust_obj) {
        this.activeObject['name'] = cust_obj.name;
        this.activeObject['code'] = cust_obj.code;
        this.activeObject['gst_id'] = cust_obj.gst_id;
        this.activeObject['email'] = cust_obj.email;
        this.activeObject['address'] = cust_obj.address;
      }
    } else if (dialogdata && dialogdata.partner_id) {
      this.activeObject['name'] = dialogdata.cust_obj.name;
      this.activeObject['code'] = dialogdata.cust_obj.code;
      this.activeObject['gst_id'] = dialogdata.cust_obj.gst_id;
      this.activeObject['email'] = dialogdata.cust_obj.email;
      this.activeObject['address'] = dialogdata.cust_obj.address;
    }

    this.fetchPartnerBalanceDetails(this.activeObject.id);
  }

  ngOnInit(): void {
  }

  fetchPartnerBalanceDetails(partner_id) {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');

    paramObject.partner_id = this.activeObject.id;
    this.apiService.post('/api/res_partners/get_customer_info', paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200) && res.results.data) {
          if (res.results.data[0]) {
            this.activeObject = res.results.data[0];
          }
        }
        this.dataLoaded = true;
      });
  }

  customerChangeEvent(selected_customer_id) {
    this.fetchPartnerBalanceDetails(selected_customer_id);
  }

  changeDate(dt) {

  }

  closeDialog() {
    this.dialog.closeAll();
  }

  onSaveTCSClick() {

    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');

    paramObject.data = {
      partner_id: this.activeObject.id,
      tax_source: this.tcs_percentage
    };

    this.apiService.post('/api/res_partners/update_tcs', paramObject)
      .subscribe(res => {
          if (res.hasOwnProperty('results') && res.results.status === 200) {

            if (res.results.data.length > 0) {

              this.toastr.success('TCS amount Updated');
              this.refreshPageAction.emit(true);
              this.dialog.closeAll();
            }


          } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        error => {
          return this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
        });

  }

  onCancelClick() {

  }
}
