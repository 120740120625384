import {
  Component,
  OnInit,
  ElementRef,
  Renderer2,
  ViewChild,
  ComponentFactoryResolver,
  ChangeDetectorRef
} from '@angular/core';
import { GridOptions } from "../../../node_modules/@ag-grid-enterprise/all-modules";
import { ApiService} from "../_services/api.service";
import { Router, ActivatedRoute  } from '@angular/router';
import { ColorCellRenderer } from "../colorCell.component";
import { DatePipe } from '@angular/common';
import { LoadingstateComponent} from "../components/loadingstate/loadingstate.component"
import {TypeDropdownComponent} from "../components/type_dropdown/type-dropdown.component";
import {AdDirective3} from "../ad3.directive";
import {PrintInvoiceComponent} from "../printInvoice.component";
import {PrintClickRenderer} from "../printClickRenderer.component";
import {StockClickRendererComponent} from "../stockClickRenderer.component";
import {SharedService} from "../_services/shared_service";
import {AppComponent} from "../app.component";

@Component({
  selector: 'app-attendance-report',
  templateUrl: './attendance-report.component.html',
  styleUrls: ['./attendance-report.component.scss']
})
export class AttendanceReportComponent implements OnInit {
  @ViewChild(AdDirective3, {static: true}) adHost3: AdDirective3;

  user;
  res_data;
  user_data = {
    "date":null,
    "access_token": null,
    "url": null,
    "last_date": null,
    "offset": null,
    "flag": true,
    "out_type": null,
    my_stock: false
  };

  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public searchValue;
  private pinnedBottomRowData;
  private pinnedTottomRowData;
  public frameworkComponents;
  public context;
  public rowData = [];
  public navigate;
  public defaultColDef;
  public sideBar;
  private rowHeight;
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public styleGrid;
  public myDate = [];
  public collapse_disable;
  public report_flag;
  public all_types;
  public type_default = true;
  public orderDetails;
  public itemList = [{id: 0, itemName: 'All Types'}];
  public selectedItems = [{id: 0, itemName: 'All Types'}];

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef:ElementRef,
              private renderer: Renderer2,
              private datePipe: DatePipe,
              public appComp: AppComponent,
              private sharedService: SharedService,
              private componentFactoryResolver: ComponentFactoryResolver) {
    this.myDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.user_data.date = this.myDate[0];
    this.user_data.last_date = this.myDate[1];
    this.user_data.offset = -1 * (new Date(this.user_data.date)).getTimezoneOffset();
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');

    this.attendancereport();
    this.appComp.header_title = 'Attendance Report';

    this.gridOptions = <GridOptions>{ rowHeight: 40,
      rowStyle: { 'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left' },
      rowSelection: 'single'};

  }

  attendancereport() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];
    const rowdata = [];
    let datePipe = this.datePipe;


    this.res_data = this.apiService.attendanceReport(this.user_data);
    this.res_data.subscribe(res => {
        if (res.hasOwnProperty('results') && (res['results'].status == 200)) {
          res['results'].data.forEach(function (data) {
            if (data.check_in) { data.check_in = datePipe.transform((new Date(data.check_in + ' UTC')).toString(), 'HH:mm:ss') };
            if (data.mark_eod) { data.mark_eod = datePipe.transform((new Date(data.mark_eod + ' UTC')).toString(), 'dd-MM-yyyy HH:mm:ss') };
            let work_hr = (parseFloat(data.total_mins) / 60).toFixed(5).match(/^-?\d*\.?0*\d{0,2}/)[0];
            var pushData = {
              DC: data.dc,
              User: data.user_name,
              Date: datePipe.transform(data.schedule_date, 'dd-MM-yyyy'),
              'Attendance Time': data.check_in,
              Reason: data.reason,
              'EMP Code/ID': data.driver_name,
              'Mark Eod': data.mark_eod,
              'Time in Market(Hr)': work_hr
            };
            rowdata.push(pushData);

          });
          this.data = rowdata[0];
          this.rowData = rowdata;

          for (var key in this.data) {
            if (this.data.hasOwnProperty(key)) {
              this.columnDefs.push({ headerName: key, field: key, sortable: true, filter: true, lockPosition: true});
            }
          }
          this.loaded = true;
          this.context = { componentParent: this };

        } else {
          this.preloader = false;
          this.empty_state = true;
        }

      },
      error => {
        console.log(error);
        this.preloader = false;
      });
  }


  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData);
    this.preloader = false;
    // this.gridApi.setDomLayout("autoHeight");
    window.addEventListener('resize', function() {
      setTimeout(function() {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        }else {
          params.columnApi.autoSizeColumns();
        }
      });
    });

    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    } else {
      params.columnApi.autoSizeColumns();
    }

    // this.gridColumnApi.autoSizeColumns();


    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  ngOnInit() {
    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable == "true" ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'),
      'active');
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {
    this.user_data.date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.user_data.last_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    this.attendancereport();
  }

}
