import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {ErrorHandler} from "../../error/error-handler";

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class NewOutletReportComponent extends ReportBase implements IReport, OnInit {
  showMultiFilter = true;
  multiFilter: any = {
    name: 'Instance',
    key: 'instance',
    value: '',
    values: []
  };

  apiData: any = [];

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('New Outlets Report');

    /*this.showableFilters.salesman = true;
    this.showableFilters.team = true;*/
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.showDate = true;
    this.multiFilter.value =  this.accountURLS[0];
    this.multiFilter.values = this.accountURLS;
    this.multiInstance = true;

    this.loadInBatches(10000, 'retailer_id');
    this.showProgressCustomText = true;

    this.gridOptions.autoGroupColumnDef = {
      headerName: 'Retailer',
      field: 'r_name',
      minWidth: 300,
      cellRenderer: 'agGroupCellRenderer',
      resizable: true,
      pinned: 'left'
    };
    this.API_URL = '/api/pwa_reports/newOutletReport';
    this.configureGrid();
  }

  configureGrid() {


    this.columnDefs = [
      {headerName: 'INSTANCE', field: 'instance', enableRowGroup: true,  width: 80},
      {headerName: 'CLUSTER', field: 'cluster', enableRowGroup: true, width: 80},
      {headerName: 'REGION', field: 'region', enableRowGroup: true, width: 80},
      {headerName: 'STATE', field: 'state', enableRowGroup: true, width: 80},
      {headerName: 'TEAM', field: 'team', enableRowGroup: true, width: 80},
      {headerName: 'EMP CODE', field: 'salesman_ec', enableRowGroup: true, width: 80},
      {headerName: 'SALESMAN', field: 'salesman', enableRowGroup: true, width: 80},
      {headerName: 'TERRITORY', field: 'territory', enableRowGroup: true, width: 80},
      {headerName: 'OUTLET ID', field: 'retailer_id', enableRowGroup: true, width: 60},
      {headerName: 'OUTLET CODE', field: 'retailer_code', enableRowGroup: true, width: 60},
      {headerName: 'OUTLET NAME', field: 'retailer_name', enableRowGroup: true, width: 80},
      {headerName: 'BEAT NAME', field: 'beat_name', enableRowGroup: true, width: 80},
      {headerName: 'CREATED DATE', field: 'created_date', width: 80},
      {headerName: 'CREATED BY', field: 'created_by', enableRowGroup: true, width: 80},
      {headerName: 'ATTRIBUTE', field: 'att', hide: true, width: 80},
      {headerName: 'SALES', field: 'sales', width: 80},
      {headerName: 'ORDER COUNT', field: 'order_count', width: 80},
      {headerName: 'SKU COUNT', field: 'sku_count', width: 80},
      {headerName: 'UNIQUE SKU', field: 'unique_sku', width: 80},
      {headerName: 'AVG BILL VALUE', field: 'abv', width: 100},
      {headerName: 'AVG LINE', field: 'alc', width: 60},
      {headerName: 'VISIT COUNT', field: 'visit_count', width: 60},
      {headerName: 'NOT BILLED', field: 'not_Billed', width: 80},
      {headerName: 'NOT VISITED', width: 60, valueGetter: params => {
          if (params.data && params.data.planned_visit && params.data.visit_count && params.data.planned_visit > params.data.visit_count) {
            return params.data.planned_visit - params.data.visit_count;
          } else {
            return 0;
          }
        }},
      {headerName: 'PLANNED VISIT', field: 'planned_visit', width: 80},
      {headerName: 'VC %', field: 'vc_pct', enableRowGroup: true, width: 80},
      {headerName: 'PC %', field: 'pc_pct', enableRowGroup: true, width: 80},
    ];
  }
}
