import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";

@Component({
  selector: 'app-selection-renderer',
  template: `<span *ngIf="!editMode"  class="element-req">{{value}}</span>
  <div *ngIf="editMode" class="formselect">
      <select
        name="selecteditem"
        [(ngModel)]="value"
        #selecteditem="ngModel"
        style="width: 100%;">
        <option
          *ngFor="let item of items"
          value="{{ item[bindID] }}"
        >
          {{ item[bindLabel] }}
        </option>
      </select>
  </div>`,
})
export class SelectionCellRendererComponent implements ICellRendererAngularComp {
  private params: any;
  public editMode = false;
  public context;
  public items;
  public value;
  bindLabel = 'itemName';
  bindID = 'id';
  agInit(params: any): void {
    this.params = params;
    if (params.value) {
      this.value = params.value;
    }
    if (this.params.context.componentParent) {
      this.context = this.params.context.componentParent;
    } else {
      this.context = this.params?.context?.params?.context?.context?.componentParent;
    }
    if (this.context) {
      this.editMode = this.context.editMode;
      if (this.context.bindLabel) {
        this.bindLabel = this.context.bindLabel;
      }
      if (this.context.bindID) {
        this.bindID = this.context.bindID;
        this.items = this.context[this.params.colDef.field];
        if (!this.value ) {
          this.value = this.items[0][this.bindID];
        }
      }
    }
  }
  refresh(): boolean {
    return false;
  }
}
