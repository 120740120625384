import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-outlet-last-visit-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class DistributorLastCheckinReport extends ReportBase implements IReport, OnInit {

  setTitle() {
    throw new Error('Method not implemented.');
  }

  uqActivities: any = [];
  ngOnInit() {
    this.sharedService.setHeader('Distributor Last Checkin Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;

    this.gridOptions.headerHeight = 40;
    this.gridOptions.autoGroupColumnDef = {
      headerName: 'Distributor Name',
      field: 'name',
      minWidth: 200,
      cellRenderer: 'agGroupCellRenderer',
      resizable: true
    };
    this.API_URL = '/api/pwa_reports/getDistributorLastCheckinReport';
    this.loadServerData();
  }

  configureGrid() {
    this.columnDefs = [
      {
        headerName: "",
        children: [
                  {headerName: 'STATE', field: 'state', enableRowGroup: true, width: 80},
                  {headerName: 'REGION', field: 'region', enableRowGroup: true, width: 80},
                  {headerName: 'CLUSTER', field: 'cluster', enableRowGroup: true, width: 80},
                  {headerName: 'D ID', field: 'id', hide: true, width: 80},
                  {headerName: 'DCODE', field: 'code', enableRowGroup: true, width: 80},
                  {headerName: 'DNAME', field: 'name', enableRowGroup: true, width: 150},
                  {headerName: 'DTYPE', field: 'dist_type', enableRowGroup: true, width: 80},
                  {headerName: 'DMOBILE', field: 'mobile', enableRowGroup: true, width: 80},
                  {headerName: 'DCITY', field: 'city', enableRowGroup: true, width: 80},
                  {headerName: 'VISIT AGEING', field: 'visit_aging', enableRowGroup: true, width: 80},
                  {headerName: 'LAST VISIT BY', field: 'sman_name', enableRowGroup: true, width: 80},
                  {headerName: 'LAST VISIT BY CODE', field: 'sman_code', enableRowGroup: true, width: 80},
                  {headerName: 'LAST VISIT ROLE', field: 'sman_role', enableRowGroup: true, width: 80},
                  {headerName: 'LAST VISIT DATE', field: 'last_visited_on', enableRowGroup: true, width: 80},
                  {
                    headerName: 'NO OF VISIT', field: 'visit_cnt', enableRowGroup: true, width: 80, aggFunc: 'sum',
                    valueGetter: params => {
                      if (params.data) {
                        if (params.data.visit_cnt) {
                          return params.data.visit_cnt;
                        } else {
                          return 0;
                        }
                      }
                    }
                  },
                  {
                    headerName: 'NO OF ORDERS', field: 'ord_cnt', enableRowGroup: true, width: 80, aggFunc: 'sum',
                    valueGetter: params => {
                      if (params.data) {
                        if (params.data.ord_cnt) {
                          return params.data.ord_cnt;
                        } else {
                          return 0;
                        }
                      }
                    }
                  },
                  {headerName: 'AVG BILL VALUE', field: 'abv', enableRowGroup: true, width: 80}
          ]
      },
      {
        headerName: "ACTIVITIES",
        children: []
      }
    ];

    for (const key of this.uqActivities) {
      this.columnDefs[1].children.push(
        {headerName: key, field: 'act_' + key, width: 80,

            valueGetter: params => {
                if (params.data) {
                    if (params.data['act_' + key]) {
                        return  params.context.componentParent.datePipe.transform((new Date(params.data['act_' + key].toString().replace('T',' ') + ' UTC')).toString(), 'HH:mm:ss');
                    } else {
                        return params.data['act_' + key];
                    }
                } else {
                    return '';
                }
            }


        },
      );
    }

  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }

  configureData() {
    this.rowData = [];
    this.uqActivities = new Set();
    this.apiData.forEach(obj => {
      if (obj.activities !== null && obj.activities !== undefined) {
        obj.activities.forEach(activity => {
          for (const [key, value] of Object.entries(activity)) {
          this.uqActivities.add(key);
          obj['act_' + key] = value;
        }
        });
      }
    });
    this.rowData = this.apiData;
  }
}
