
<div class="approve-modal modal" id="CustomPrintModal" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <span class="close" data-dismiss="modal">
        <img src="../../assets/close.png">
      </span>
      <div class="modal-body">
        <div class="approve-modal-heading">
          <h1> Please select Print Template</h1>
        </div>
        <div class="approve-modal-content" style="witdh:50%;height:3vh">
          <select [(ngModel)]="selectedTemplate">
            <option>Select</option>
            <option *ngFor="let templ of templ_config"
                    [ngValue]="templ">{{templ.name}}</option>
          </select>
        </div>
        <button
          [hidden]="!selectedTemplate"
          [useExistingCss]="true"
          printSectionId="print-section-sales"
          ngxPrint>Print</button>

      </div>
    </div>
  </div>
</div>

<div #PrintingModal class="pol-modal pol-send invoice modal" id="PrintingModal" role="dialog">
  <div class="modal-dialog m1">
    <!-- Modal content-->
    <div class="modal-content m2">
      <div>
        <span class="close" data-dismiss="modal">
            <img src="../../assets/close.png">
          </span>
      </div>
      <div class="m3">

        <div id="print-section-sales">
          <div  class="invoice"  *ngFor="let print_detail of print_details">
            <app-print *ngIf="!template || (selectedTemplate && selectedTemplate.name == 'Default')"
                       [order_detail]="print_detail"></app-print>
            <app-custom-print *ngIf="template && selectedTemplate && selectedTemplate.name != 'Default'"
                              [order_detail]="print_detail"
                              [custom_template]="selectedTemplate"
            ></app-custom-print>
          </div>
        </div>
      </div>

      <div style="margin-top:1%;padding-bottom: 3%;text-align: center;">
        <button [useExistingCss]="true"
                printSectionId="print-section-sales"
                ngxPrint >PRINT</button>
        <button
          #autoprint
          [hidden]="true"
          printSectionId="print-section-sales"
          ngxPrint>Print</button>
      </div>
    </div>
  </div>
</div>
<button #customPrintClick id="openPrint" [hidden]="true" data-toggle="modal" data-target="#CustomPrintModal">Open Modal</button>
<button #interPrint id="openModalButton1" [hidden]="true" data-toggle="modal" data-target="#PrintingModal"></button>
