import {Component, OnInit} from "@angular/core";
import {ReportBase} from "../_common/reportbase";

@Component({
  selector: 'app-pre-order-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class Pre_orderComponent extends ReportBase implements IReport, OnInit  {

 /* showableFilters={
    max_claim:false,
    policy:false,
    salesman:false,
    status:false,
    class:false,
    beat:false,
    trax_outlet:false,
    non_trax_outlet:false,
    exclude_trax_audit:false,
    region:false,
    cluster:false,
    team:false,
    territory:false,
    outlet_type: true,
    dc: true
  }*/

  setTitle() {
    this.title = 'Pre Order Report';
  }

  ngOnInit() {

    this.sharedService.setHeader('Pre Order Report');
    this.configureGrid();

    this.showableFilters.outlet_type = true;
    this.showableFilters.dc = true;
    this.showPinnedFooter = true;
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/pre_order_report';
    // this.filterConfig.salesman = true;
    // this.filterConfig.outletType = true;
    const inThis = this;
    this.columnDefs = [
      { headerName: 'DC',  field: 'dc_name',   width: 100},
      { headerName: 'TI',  field: 'ti_name',
        width: 100, },
      { headerName: 'Van',  field: 'user',
        width: 100, },
      { headerName: 'Outlet',  field: 'outlet',
        width: 100, },
      { headerName: 'Out Code',  field: 'outlet_code',
        width: 80, },
      { headerName: 'Type',  field: 'outlet_type',
        width: 100},
      { headerName: 'Invoice Date',  field: 'planned_date',
        width: 100,
        valueGetter: params => {
          if (params.data) {
            if (params.data.planned_date) {
              return inThis.datePipe.transform((new Date(params.data.planned_date + ' UTC')).toString(), 'yyyy-MM-dd');
            } else {
              return params.data.planned_date;
            }
          } else {
            return '';
          }
        }},
      { headerName: 'Order Date',  field: 'order_date',
        width: 100,
        valueGetter: params => {
          if (params.data) {
            if (params.data.order_date) {
              return inThis.datePipe.transform((new Date(params.data.order_date + ' UTC')).toString(), 'yyyy-MM-dd');
            } else {
              return params.data.order_date;
            }
          } else {
            return '';
          }
        }},
      { headerName: 'Product',  field: 'product_name', cellStyle: {textAlign: 'center'},
          width: 100},
      { headerName: 'Prod Code',  field: 'product_code', cellStyle: {textAlign: 'right'},
           width: 80},
      { headerName: 'Qty',  field: 'qty', aggFunc: 'sum', cellStyle: {textAlign: 'right'},
          width: 80},
      { headerName: 'PO',  field: 'po', cellStyle: {textAlign: 'right'},
          width: 100},
      { headerName: 'Invoice ID',  field: 'invoice_id', cellStyle: {textAlign: 'right'},
           width: 100},
      { headerName: 'Invoice No',  field: 'invoice', cellStyle: {textAlign: 'right'},
           width: 100},
      { headerName: 'Einv Status',  field: 'einv_status', cellStyle: {textAlign: 'center'},
           width: 80},
      { headerName: 'Net Value', aggFunc: 'sum',  field: 'price_subtotal', cellStyle: {textAlign: 'center'},
           width: 80},
      { headerName: 'Gross Value', aggFunc: 'sum',  field: 'amount_total', cellStyle: {textAlign: 'right'},
           width: 80},
      { headerName: 'TDS/TCS',  field: 'tcs', cellStyle: {textAlign: 'right'},
           width: 70},
      { headerName: 'Grand Total', aggFunc: 'sum',  field: 'grand_total', cellStyle: {textAlign: 'right'},
           width: 70},
      { headerName: 'P Mode',  field: 'mode',
           width: 70},
      { headerName: 'Delivery Status',  field: 'd_status', cellStyle: {textAlign: 'center'},
        width: 80},
    ];
  }

  // loadServerData() {
  //   var indrow = {
  //     "category":"Scotch",
  //     "brands":"Black & White",
  //     "volume":"231 Cases",
  //     "market_share":"64%",
  //     "growth":"20%",
  //   }
  //
  //   this.rowData.push(indrow);
  //   this.preloader = false;
  //   this.emptyState = false;
  //
  //   this.dataLoaded = true;
  // }

}
