import {Component, ElementRef, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {SurveyAnswerPopupComponent} from "../survey_answer_popup/survey_answer_popup.component";

@Component({
  selector: 'app-survey_question_create-component',
  templateUrl: './survey_question_create.component.html',
  styleUrls: ['./survey_question_create.component.scss']
})
export class SurveyQuestionCreateComponent implements OnInit {

  @Output() createdQuestion: EventEmitter<any> = new EventEmitter<any>();

  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public rowData = [];
  public gridOptions: GridOptions;
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public defaultColDef;
  public styleGrid;
  public frameworkComponents;
  public context;
  user_data = {
    date: null,
    access_token: null,
    url: null
  };
  API_URL = '';
  filters = {
    area: null,
    state_id: null,
    cluster_id: null,
    salesman_id: null,
  };
  states = [];
  clusters = [];
  questionData: any = {};
  edit = false;
  surveyData = [];

  constructor(private elRef: ElementRef,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.edit = dialogdata.edit;

    if (this.dialogdata.hasOwnProperty('questionData') && dialogdata.questionData !== null) {
      this.questionData = dialogdata.questionData;
    }

    if (this.dialogdata.hasOwnProperty('surveyData') && dialogdata.surveyData !== null) {
      this.surveyData = dialogdata.surveyData;
    }

    if (this.questionData !== null && this.questionData.hasOwnProperty('ans') && this.questionData.ans.length > 0) {
      this.rowData = this.questionData.ans;
    }
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left'},
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      rowSelection: 'multiple'
    } as GridOptions;

    this.defaultColDef = {
      flex: 1,
      headerClass: 'myagheaderwhitebold',
      filter: true,
      sortable: true,
      resizable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true
    };

    this.columnDefs = [
      {headerName: "NAME", field: "name"},
      {headerName: "SCORE", field: "score"}
    ];
    if (this.questionData.id && this.questionData.type === 'single choice') {
      this.columnDefs.push({
        headerName: "", field: "view",
        cellRenderer(params) {
          if (params.data) {
            return ' <span title="view" style="color: blue">View Linked Questions</i></span>';
          }
        }
      });
    }
    this.columnDefs.push({
      headerName: "", field: "delete", cellStyle: {textAlign: 'right'}, filter: false, width: 50,
      cellRenderer(params) {
        if (params._self.edit) {
          return ' <span title="Delete" ><i class="fas fa-trash delete" data-action-type="delete"></i></span>';
        } else {
          return "";
        }
      },
      cellRendererParams: {
        _self: this
      }
    });
    this.loaded = true;
  }

  ngOnInit(): void {
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setRowData(this.rowData);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    if (screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const selectedAnswer = this.gridApi.getSelectedRows();
    if (column === 'delete') {
      if (this.questionData.ans === null) {
        this.rowData.splice(event.rowIndex, 1);
      } else {
        this.questionData.ans.splice(event.rowIndex, 1);
        this.rowData = this.questionData.ans;
      }
      this.gridApi.setRowData(this.rowData);
    } else if (column === 'view' && this.questionData['type'] === 'single choice') {
      if (selectedAnswer && selectedAnswer.length > 0 && selectedAnswer[0].hasOwnProperty('id') && selectedAnswer[0].id) {
        const config: MatDialogConfig = {
          width: "100vw",
          height: "auto",
          maxWidth: "1000px",
          maxHeight: "100%",
          panelClass: 'custom-dialog-container',
          data: {
            edit: this.edit,
            questionData: this.questionData,
            answerData: selectedAnswer[0],
            surveyData: this.surveyData
          }
        };
        const creatediaeref = this.dialog.open(SurveyAnswerPopupComponent, config);
      }
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  quickSearch() {
    this.gridApi.setQuickFilter(document.getElementById('filter-text-box1')['value']);
  }

  addQuestion() {

    const indrow = {
      name: "Enter Answer",
      score: 0
    };

    this.rowData.push(indrow);
    this.gridApi.setRowData(this.rowData);
  }

  saveQuestion() {
    this.questionData.ans = [];
    this.questionData.ans = this.rowData;
    this.createdQuestion.emit(this.questionData);
    this.dialogRef.close();
  }

}
