<div class="common-wrapper">
  <div class="common-container">

    <div class="main-wrapper eod">
      <div *ngIf="showProgress" class="overlay mouse-events-off">
        <div class="progress progress-striped active">
          <label>SAP SYNC IN PROGRESS</label>
          <mat-progress-bar class="progressbar" mode="determinate" [value]="progress"></mat-progress-bar>
          <span>{{ progress }}%</span>
        </div>
      </div>
      <div id="overlay"></div>
      <div class="eodtop-card" style="flex-wrap: nowrap;margin-top: -0.5%;margin-bottom: 1%;">
        <img *ngIf="config.prvs" src="../../assets/images/previous.png" (click)="pageChanged('prev')"
             style="float: left;cursor: pointer;position:relative;top: -0.5vh;"/>
        <ul class="list-unstyled">
          <li>
            <label>VAN:</label>
            <span>{{ van_name }}</span>
          </li>
          <li>
            <label>STATUS:</label>
            <span style="font-size: 225%;">{{ eod_status }}</span>
            <img style="cursor: pointer;" *ngIf="status_edit && eod_status == 'complete' && nxt_eod_status"
                 (click)="editVanStatus()" src="./../../assets/edit.svg"/>
          </li>
          <li>
            <div class="date-picker">
              <span class="datepicker-icon"><img src="../../assets/date.png"></span>
              <input [readonly]="true" [(ngModel)]="myDate" [owlDateTimeTrigger]="dt" [owlDateTime]="dt"
                     placeholder="Date">
              <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
            </div>
          </li>
        </ul>
        <img *ngIf="config.next" src="../../assets/images/next.png" (click)="pageChanged('next')" class="next"/>
      </div>
      <div *ngIf="top_cards" class="row eodset-card" style="flex-wrap: nowrap;margin-top: 1%;">
        <div class="col-md-3 padR eod-width">
          <app-eodcarddesc [title]="'DAILY ACHIEVEMENT'" [achv]="summary['Achievement']" [target]="summary['Target']"
                           [perc]="summary['daily_perc']"></app-eodcarddesc>
        </div>
        <div class="col-md-3 padR eod-width">
          <app-eodcarddesc2 [title]="'MONTHLY ACHIEVEMENT'" [achv]="summary['MonthAchv']"
                            [target]="summary['MonthTarget']" [perc]="summary['monthly_perc']"></app-eodcarddesc2>
        </div>
        <div class="col-md-3 padR eod-width">
          <app-eodlistcard [tc]="summary['TC']" [vc]="summary['VC']" [pc]="summary['PC']" [gc]="summary['GC']"
                           [nv]="summary['NV']"></app-eodlistcard>
        </div>
        <div class="col-md-3 pad0 eod-width">
          <app-eodcard [title1]="'ZERO SALES'" [title2]="'DEVIATION'" [dc]="summary['DC']"
                       [zs]="summary['ZS']"></app-eodcard>
        </div>
      </div>
      <div *ngIf="top_cards" class="eodset-map" style="padding-bottom: 2%;">
        <div class="eodtop-card">
          <ul class="list-unstyled">
            <li class="margb">
              <h2>TRAVEL</h2>
            </li>
            <li>
              <label>Travel Distance:</label>
              <span>{{ distance_travelled }} km</span>
            </li>
            <li>
              <img (click)="expandList()" src="./../../assets/list.svg"/>
            </li>
            <li>
              <img (click)="expandMap()" src="./../../assets/locate.svg"/>
            </li>
          </ul>
        </div>
        <div *ngIf="!hide" class="eodset-mapcard">
          <agm-map #AgmMap [mapTypeControl]="true" (mapReady)="foo($event)">
            <agm-marker *ngIf="map_loader" [iconUrl]="'../../assets/images/loader_icon.gif'" [latitude]="0"
                        [longitude]="0">
            </agm-marker>
            <agm-marker *ngFor="let marker of activity"
                        [latitude]="marker.lat"
                        [longitude]="marker.lng"
                        [iconUrl]="icon_url"
                        (markerClick)="openWindow(marker.id)">
              <agm-info-window
                [latitude]="marker.lat"
                [longitude]="marker.lng">
                <span style="font-size: large;float: left"><b>Retailer:</b> {{ marker.retailer }}</span><br/><br/>
                <span style="float: left"><b>Actions:</b> {{ marker.activity }}</span><br/>
                <span style="float: left"><b>Device Time:</b> {{ marker.device_time }}</span><br/>
              </agm-info-window>
            </agm-marker>
            <agm-marker *ngIf="is_act"
                        [iconUrl]="start_url"
                        [latitude]="start_act.lat"
                        [longitude]="start_act.lng"
                        (markerClick)="openWindow(start_act.id)">
              <agm-info-window
                [latitude]="start_act.lat"
                [longitude]="start_act.lng">
                <span style="font-size: large;float: left"><b>Retailer:</b> {{ start_act.retailer }}</span><br/><br/>
                <span style="float: left"><b>Actions:</b> {{ start_act.activity }}</span><br/>
                <span style="float: left"><b>Device Time:</b> {{ start_act.device_time }}</span><br/>
              </agm-info-window>
            </agm-marker>
            <agm-marker *ngIf="is_act"
                        [iconUrl]="end_url"
                        [latitude]="end_act.lat"
                        [longitude]="end_act.lng"
                        (markerClick)="openWindow(end_act.id)">
              <agm-info-window
                [latitude]="end_act.lat"
                [longitude]="end_act.lng">
                <span style="font-size: large;float: left"><b>Retailer:</b> {{ end_act.retailer }}</span><br/><br/>
                <span style="float: left"><b>Actions:</b> {{ end_act.activity }}</span><br/>
                <span style="float: left"><b>Device Time:</b> {{ end_act.device_time }}</span><br/>
              </agm-info-window>
            </agm-marker>
            <agm-polyline [strokeColor]="'#2196f3'">
              <ng-container *ngFor="let log of track">
                <agm-polyline-point [latitude]="log.lat" [longitude]="log.lng">
                </agm-polyline-point>
              </ng-container>
            </agm-polyline>
          </agm-map>
        </div>
        <div class="col-md-12 pad0">
          <div class="mt-default">
            <ag-grid-angular id="myGrid1" *ngIf="list_loaded" style="width: 100%;height: 72vh;" class="ag-theme-balham"
                             [columnDefs]="columnDefsList"
                             [gridOptions]="gridOptionsList"
                             (gridReady)="onGridReadyList($event)" [frameworkComponents]="frameworkComponents"
            >
            </ag-grid-angular>
            <div *ngIf="list_preloader">
              <app-loadingstate></app-loadingstate>
            </div>
            <div *ngIf="list_empty_state">
              <app-emptystate></app-emptystate>
            </div>
          </div>
        </div>
      </div>
      <div class="eodset-stocks">
        <div class="eodtop-card">
          <ul class="list-unstyled">
            <li>
              <h2>STOCKS & SALES</h2>
            </li>
            <li>
              <p *ngIf="!settle_wait" style="font-weight: 900;
    color: dimgrey;
    font-size: 115%;">Loading...</p>
            </li>
            <li *ngIf="partial_approve" style="margin-bottom: -4% !important;float: right;margin-right: 60px;"
                class="margLR">
              <button class="btn confirm-blue-btn" (click)="openPartialApprovePopup()">Manage Returns</button>
            </li>
            <li style="margin-bottom: -4% !important;float: right;" class="margLR">
              <app-excelicon [gridOption]="stockOptions" [title]="'Stock And Sales'"></app-excelicon>
            </li>
          </ul>
        </div>
        <div class="eod-table">
          <div class="stock-default">
            <ag-grid-angular id="myGrid1" *ngIf="stock_loaded" style="width: 100%;" class="ag-theme-balham"
                             [columnDefs]="stockcolumnDefs"
                             [gridOptions]="stockOptions" [modules]="modules"
                             (gridReady)="onstockGridReady($event)" (cellClicked)="onStockCellClicked($event)">
            </ag-grid-angular>
            <div *ngIf="preloader1">
              <app-loadingstate></app-loadingstate>
            </div>
            <div *ngIf="empty_state1">
              <app-emptystate></app-emptystate>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="feature_van_asset" class="eodset-stocks">
        <div class="eodtop-card">
          <ul class="list-unstyled">
            <li>
              <h2>ASSETS</h2>
            </li>
            <li>
              <p *ngIf="!settle_wait" style="font-weight: 900;
    color: dimgrey;
    font-size: 115%;">Loading...</p>
            </li>
            <li style="margin-bottom: -4% !important;float: right;" class="margLR">
              <app-excelicon [gridOption]="assetOptions" [title]="'Assets'"></app-excelicon>
            </li>
          </ul>
        </div>
        <div class="eod-table">
          <div class="stock-default">
            <ag-grid-angular id="myGrid1" *ngIf="asset_loaded" style="width: 100%;height: 15vh;" class="ag-theme-balham"
                             [columnDefs]="assetcolumnDefs"
                             [gridOptions]="assetOptions"
                             (gridReady)="onassetGridReady($event)">
            </ag-grid-angular>
            <div *ngIf="assetpreloader">
              <app-loadingstate></app-loadingstate>
            </div>
            <div *ngIf="asset_empty_state">
              <app-emptystate></app-emptystate>
            </div>
          </div>
        </div>
      </div>

      <div class="eodset-stocks">
        <div class="eodtop-card padB">
          <ul class="list-unstyled">
            <li>
              <h2>SALES APPROVAL</h2>
            </li>
            <li class="tab-search">
              <div class="search">
                <span class="search-control"><img src="assets/searchicon.png"/></span>
                <input type="text" placeholder="filter" (keyup)="quickSearch()" [(ngModel)]="searchValue"
                       class="search-control-area"
                       placeholder="Search">
              </div>
            </li>
            <div *ngIf="total_count == 0">
              <app-emptystate></app-emptystate>
            </div>
          </ul>
          <div class="mat-tab">
            <mat-tab-group (selectedTabChange)="tabClick($event)" [(selectedIndex)]="tabIndex">

              <mat-tab *ngIf="cash_enable">
                <ng-template mat-tab-label>
                  <span style="font-weight: bolder;color: black;padding-left: 4%;">CASH SALES  ({{ cash_count }})</span>
                </ng-template>
                <div class="eod-table">
                  <div>
                    <ag-grid-angular id="myGrid1" *ngIf="order3_loaded" style="width: 100%;height:70vh;"
                                     class="ag-theme-balham" [rowSelection]="rowSelection"
                                     [columnDefs]="columnDefs3" [gridOptions]="gridOptions3"
                                     (gridReady)="onGridReady3($event)"
                                     [frameworkComponents]="frameworkComponents" [context]="context"
                                     [tabToNextCell]="tabToNextCell"
                                     (cellClicked)="onCellClicked($event)">
                    </ag-grid-angular>
                    <div *ngIf="preloader4">
                      <app-loadingstate></app-loadingstate>
                    </div>
                    <div *ngIf="empty_state4">
                      <app-emptystate></app-emptystate>
                    </div>
                  </div>
                </div>

              </mat-tab>

              <mat-tab *ngIf="today_enable">
                <ng-template mat-tab-label>
                  <span style="font-weight: bolder;color: black;padding-left: 4%;">CREDIT SALES  ({{ today_count }}
                    )</span>
                </ng-template>
                <div class="eod-table">
                  <div>
                    <ag-grid-angular id="myGrid1" *ngIf="order1_loaded" style="width: 100%;height:70vh;"
                                     class="ag-theme-balham" [rowSelection]="rowSelection"
                                     [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                                     (gridReady)="onGridReady($event)"
                                     [frameworkComponents]="frameworkComponents" [context]="context"
                                     [tabToNextCell]="tabToNextCell"
                                     (cellClicked)="onCellClicked($event)">
                    </ag-grid-angular>
                    <div *ngIf="preloader2">
                      <app-loadingstate></app-loadingstate>
                    </div>
                    <div *ngIf="empty_state2">
                      <app-emptystate></app-emptystate>
                    </div>
                  </div>
                </div>

              </mat-tab>
              <mat-tab *ngIf="dispatch_enable">
                <ng-template mat-tab-label>
                  <span style="font-weight: bolder;color: black;padding-left: 4%;">DISPATCHED ({{ dispatch_count }}
                    )</span>
                </ng-template>
                <div class="eod-table">
                  <div>
                    <ag-grid-angular id="myGrid1" *ngIf="dispatch_loaded" style="width: 100%;height:70vh;"
                                     class="ag-theme-balham" [rowSelection]="rowSelection"
                                     [columnDefs]="dispatchcolumnDefs" [gridOptions]="gridOptionsDispatch"
                                     (gridReady)="onGridReadyDispatch($event)"
                                     [frameworkComponents]="frameworkComponents" [context]="context"
                                     [tabToNextCell]="tabToNextCell"
                                     (cellClicked)="onCellClicked($event)">
                    </ag-grid-angular>
                    <div *ngIf="dispatch_preloader">
                      <app-loadingstate></app-loadingstate>
                    </div>
                    <div *ngIf="empty_state_dispatch">
                      <app-emptystate></app-emptystate>
                    </div>
                  </div>
                </div>

              </mat-tab>
              <mat-tab *ngIf="replace_enable">
                <ng-template mat-tab-label>
                  <span style="font-weight: bolder;color: black;padding-left: 4%;">REPLACE  ({{ replace_count }})</span>
                </ng-template>
                <div class="eod-table">
                  <div>
                    <ag-grid-angular id="myGrid1" *ngIf="replace_loaded" style="width: 100%;height:70vh;"
                                     class="ag-theme-balham" [rowSelection]="rowSelection"
                                     [columnDefs]="replacecolumnDefs" [gridOptions]="gridOptionsReplace"
                                     (gridReady)="onGridReadyReplace($event)"
                                     [frameworkComponents]="frameworkComponents" [context]="context"
                                     [tabToNextCell]="tabToNextCell"
                                     (cellClicked)="onCellClicked($event)">
                    </ag-grid-angular>
                    <div *ngIf="replace_preloader">
                      <app-loadingstate></app-loadingstate>
                    </div>
                    <div *ngIf="empty_state_replace">
                      <app-emptystate></app-emptystate>
                    </div>
                  </div>
                </div>

              </mat-tab>
              <mat-tab *ngIf="return_enable">
                <ng-template mat-tab-label>
                  <span style="font-weight: bolder;color: black;padding-left: 4%;">RETURN  ({{ return_count }})</span>
                </ng-template>
                <div class="eod-table">
                  <div>
                    <ag-grid-angular id="myGrid1" *ngIf="return_loaded" style="width: 100%;height:70vh;"
                                     class="ag-theme-balham" [rowSelection]="rowSelection"
                                     [columnDefs]="returncolumnDefs" [gridOptions]="gridOptionsReturn"
                                     (gridReady)="onGridReadyReturn($event)"
                                     [frameworkComponents]="frameworkComponents" [context]="context"
                                     [tabToNextCell]="tabToNextCell"
                                     (cellClicked)="onCellClicked($event)">
                    </ag-grid-angular>
                    <div *ngIf="return_preloader">
                      <app-loadingstate></app-loadingstate>
                    </div>
                    <div *ngIf="empty_state_return">
                      <app-emptystate></app-emptystate>
                    </div>
                  </div>
                </div>

              </mat-tab>
              <mat-tab *ngIf="sample_enable">
                <ng-template mat-tab-label>
                  <span style="font-weight: bolder;color: black;padding-left: 4%;">SAMPLE SALES  ({{ sample_count }}
                    )</span>
                </ng-template>
                <div class="eod-table">
                  <div>
                    <ag-grid-angular id="myGrid1" *ngIf="sample_loaded" style="width: 100%;height:70vh;"
                                     class="ag-theme-balham" [rowSelection]="rowSelection"
                                     [columnDefs]="samplecolumnDefs" [gridOptions]="gridOptionsSample"
                                     (gridReady)="onGridReadySample($event)"
                                     [frameworkComponents]="frameworkComponents" [context]="context"
                                     [tabToNextCell]="tabToNextCell"
                                     (cellClicked)="onCellClicked($event)">
                    </ag-grid-angular>
                    <div *ngIf="sample_preloader">
                      <app-loadingstate></app-loadingstate>
                    </div>
                    <div *ngIf="empty_state_sample">
                      <app-emptystate></app-emptystate>
                    </div>
                  </div>
                </div>

              </mat-tab>
              <mat-tab *ngIf="prvs_enable">
                <ng-template mat-tab-label>
                  <span
                    style="font-weight: bolder;color: black;padding-left: 4%;">PENDING CREDIT SALES ({{ prvs_count }}
                    )</span>
                </ng-template>
                <div class="eod-table">
                  <div>
                    <ag-grid-angular id="myGrid1" *ngIf="order2_loaded" style="width: 100%;height:70vh;"
                                     class="ag-theme-balham" [rowSelection]="rowSelection"
                                     [columnDefs]="columnDefsPrev" [gridOptions]="gridOptionsPrev"
                                     (gridReady)="onGridReadyPrev($event)"
                                     [frameworkComponents]="frameworkComponents" [context]="context"
                                     (cellClicked)="onCellClicked($event)">
                    </ag-grid-angular>
                    <div *ngIf="preloader3">
                      <app-loadingstate></app-loadingstate>
                    </div>
                    <div *ngIf="empty_state3">
                      <app-emptystate></app-emptystate>
                    </div>
                  </div>
                </div>
              </mat-tab>
              <mat-tab *ngIf="unapp_enable">
                <ng-template mat-tab-label>
                  <span style="font-weight: bolder;color: black;padding-left: 4%;">PENDING CASH SALES ({{ unapp_count }}
                    )</span>
                </ng-template>
                <div class="eod-table">
                  <div>
                    <ag-grid-angular id="myGrid1" *ngIf="unapp_loaded" style="width: 100%;height:70vh;"
                                     class="ag-theme-balham" [rowSelection]="rowSelection"
                                     [columnDefs]="unappcolumnDefs" [gridOptions]="gridOptionsUnapp"
                                     (gridReady)="onGridReadyUnapp($event)"
                                     [frameworkComponents]="frameworkComponents" [context]="context"
                                     (cellClicked)="onCellClicked($event)">
                    </ag-grid-angular>
                    <div *ngIf="unapp_preloader">
                      <app-loadingstate></app-loadingstate>
                    </div>
                    <div *ngIf="unapp_empty_state">
                      <app-emptystate></app-emptystate>
                    </div>
                  </div>
                </div>
              </mat-tab>
              <mat-tab *ngIf="pending_pod_enable">
                <ng-template mat-tab-label>
                  <span style="font-weight: bolder;color: black;padding-left: 4%;">PENDING POD ({{ pending_pod_count }}
                    )</span>
                </ng-template>
                <div class="eod-table">
                  <div>
                    <ag-grid-angular id="myGrid1" *ngIf="pEpod_loaded" style="width: 100%;height:70vh;"
                                     class="ag-theme-balham" [rowSelection]="rowSelection"
                                     [columnDefs]="pEpodDefs" [gridOptions]="gridOptionsPEpod"
                                     (gridReady)="onGridReadyPEpod($event)"
                                     [frameworkComponents]="frameworkComponents" [context]="context"
                                     (cellClicked)="onCellClicked($event)">
                    </ag-grid-angular>
                    <div *ngIf="pEpod_preloader">
                      <app-loadingstate></app-loadingstate>
                    </div>
                    <div *ngIf="pepod_empty_state">
                      <app-emptystate></app-emptystate>
                    </div>
                  </div>
                </div>
              </mat-tab>
              <mat-tab *ngIf="mismatch_enable">
                <ng-template mat-tab-label>
                  <span style="color:red;font-weight: bolder;padding-left: 4%;">PAYMENT MISMATCH ({{ mismatch_count }}
                    )</span>
                </ng-template>
                <div class="eod-table">
                  <div>
                    <ag-grid-angular id="myGrid1" *ngIf="mismatch_loaded" style="width: 100%;height:70vh;"
                                     class="ag-theme-balham"
                                     [rowSelection]="rowSelection"
                                     [columnDefs]="mismatchcolumnDefs" [gridOptions]="gridOptionsMismatch"
                                     (gridReady)="onGridReadyMismatch($event)"
                                     [frameworkComponents]="frameworkComponents" [context]="context"
                                     (cellClicked)="onCellClicked($event)">
                    </ag-grid-angular>
                    <div *ngIf="mismatch_preloader">
                      <app-loadingstate></app-loadingstate>
                    </div>
                    <div *ngIf="empty_state_mismatch">
                      <app-emptystate></app-emptystate>
                    </div>
                  </div>
                </div>
              </mat-tab>
              <mat-tab *ngIf="logs_enable">
                <ng-template mat-tab-label>
                  <span style="color:red;font-weight: bolder;padding-left: 4%;">STOCK LOG MISMATCH ({{ logs_count }}
                    )</span>
                </ng-template>
                <div class="eod-table">
                  <div>
                    <ag-grid-angular id="myGrid1" *ngIf="logs_loaded" style="width: 100%;height:70vh;"
                                     class="ag-theme-balham"
                                     [rowSelection]="rowSelection"
                                     [columnDefs]="logcolumnDefs" [gridOptions]="gridOptionsLog"
                                     (gridReady)="onGridReadyLogs($event)"
                                     [frameworkComponents]="frameworkComponents" [context]="context"
                                     (cellClicked)="onCellClicked($event)">
                    </ag-grid-angular>
                    <div *ngIf="logs_preloader">
                      <app-loadingstate></app-loadingstate>
                    </div>
                    <div *ngIf="empty_state_logs">
                      <app-emptystate></app-emptystate>
                    </div>
                  </div>
                </div>
              </mat-tab>
              <mat-tab *ngIf="pending_einvoice_enable">
                <ng-template mat-tab-label>
                  <span
                    style="font-weight: bolder;color: black;padding-left: 4%;">PENDING E-INVOICE  ({{ pending_einvoice_count }}
                    )</span>
                </ng-template>
                <div class="eod-table">
                  <div>
                    <ag-grid-angular id="myGrid1" *ngIf="pEinvoice_loaded" style="width: 100%;height:70vh;"
                                     class="ag-theme-balham" [rowSelection]="rowSelection"
                                     [columnDefs]="pEinvoiceDefs" [gridOptions]="gridOptionsPEinvoice"
                                     (gridReady)="onGridReadyPEinvoice($event)"
                                     [frameworkComponents]="frameworkComponents" [context]="context"
                                     [tabToNextCell]="tabToNextCell"
                                     (cellClicked)="onCellClicked($event)">
                    </ag-grid-angular>
                    <div *ngIf="pEinvoice_preloader">
                      <app-loadingstate></app-loadingstate>
                    </div>
                    <div *ngIf="peinvoice_empty_state">
                      <app-emptystate></app-emptystate>
                    </div>
                  </div>
                </div>
              </mat-tab>
              <mat-tab *ngIf="pending_saporder_enable && sapSync">
                <ng-template mat-tab-label>
                  <span
                    style="font-weight: bolder;color: black;padding-left: 4%;">SAP UNSYNCED ({{ pending_saporder_count }}
                    )</span>
                </ng-template>
                <div class="eod-table">
                  <div>
                    <ag-grid-angular id="myGrid1" *ngIf="sapOrder_loaded" style="width: 100%;height:70vh;"
                                     class="ag-theme-balham" [rowSelection]="rowSelection"
                                     [columnDefs]="sapOrderDefs" [gridOptions]="gridOptionsSapOrder"
                                     (gridReady)="onGridReadySapOrder($event)"
                                     [frameworkComponents]="frameworkComponents" [context]="context"
                                     [tabToNextCell]="tabToNextCell"
                                     (cellClicked)="onCellClicked($event)">
                    </ag-grid-angular>
                    <div *ngIf="sapOrder_preloader">
                      <app-loadingstate></app-loadingstate>
                    </div>
                    <div *ngIf="sapOrder_empty_state">
                      <app-emptystate></app-emptystate>
                    </div>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>

      </div>
      <div class="row eodset-total">
        <div class="col-md-7 pad0">
        </div>
        <div class="col-md-5 pad0">
          <div class="eodset-cash">
            <div class="eodtop-card" style="padding-top: 15px;">
              <ul class="list-unstyled">
                <li>
                  <h2>SUMMARY</h2>
                </li>
              </ul>
            </div>
            <div class="eodcash-card" style="padding-top: 10px;">
              <div class="amount-heading">
                <h2>AMOUNT</h2>
              </div>
              <div *ngIf="settlement" class="cash-bottomcard">
                <ul>
                  <li *ngIf="settlement['loadout_value'] > 0">
                    <label style="font-weight: 100;">Loadout Value</label>
                    <span>{{ settlement['loadout_value'] }}</span>
                  </li>
                  <li *ngIf="settlement['opening_value'] > 0">
                    <label style="font-weight: 100;">Opening Value</label>
                    <span>{{ settlement['opening_value'] }}</span>
                  </li>
                  <li *ngIf="settlement['unapproved_sale'] > 0">
                    <label style="font-weight: 100;">Unapproved Cash Sales</label>
                    <span>{{ settlement['unapproved_sale'] }}</span>
                  </li>
                  <li *ngIf="settlement['credit'] > 0">
                    <label>Credit Sales</label>
                    <span style="font-weight: 100;" class="up">+{{ settlement['credit'] }}</span>
                  </li>
                  <li *ngIf="settlement['cash'] > 0">
                    <label>Cash Sales</label>
                    <span style="font-weight: 100;">{{ settlement['cash'] }}</span>
                  </li>
                  <li *ngIf="partial_approve">
                    <label>Bonus/Penalty</label>
                    <span style="font-weight: 100;">{{ bonus }}/{{ penalty }}</span>
                  </li>
                  <li *ngIf="partial_approve">
                    <label>Total Return</label>
                    <span style="font-weight: 100;">{{ total_return }}</span>
                  </li>
                </ul>
              </div>
            </div>

            <div class="eodtop-card" style="padding-top: 15px;">
              <ul class="list-unstyled">
                <li>
                  <h2>COLLECTION</h2>
                </li>
              </ul>
            </div>
            <div class="eodcash-card" style="padding-top: 10px;">
              <div *ngIf="settlement" class="cash-bottomcard">
                <ul>
                  <li *ngIf="!expense_hide">
                    <label>Total Expense</label>
                    <span class="down">-{{ settlement['expense'] }}</span>
                  </li>
                  <li *ngIf="settlement['pay_cash'] > 0">
                    <label>Cash</label>
                    <span style="font-weight: 100;">{{ settlement['pay_cash'] }}</span>
                  </li>
                  <li *ngIf="settlement['pay_cheque'] > 0">
                    <label>Cheque</label>
                    <span style="font-weight: 100;">{{ settlement['pay_cheque'] }}</span>
                  </li>
                  <ng-container *ngFor="let item of settlement['paymentObj'];">
                    <li>
                      <label>{{ item.name }}</label>
                      <span style="font-weight: 100;">{{ item.value }}</span>
                    </li>
                  </ng-container>
                  <li *ngIf="settlement['credits'] > 0">
                    <label>Today's Credits</label>
                    <span style="font-weight: 100;">{{ settlement['credits'] }}</span>
                  </li>
                  <li *ngIf="settlement['todays_payment'] > 0">
                    <label>Todays Payment</label>
                    <span style="font-weight: 100;">{{ settlement['todays_payment'] }}</span>
                  </li>
                  <div *ngIf="showOld">
                    <ng-container *ngFor="let item of settlement['oldPaymentObj'];">
                      <li>
                        <label>{{ item.name }}</label>
                        <span style="font-weight: 100;">{{ item.value }}</span>
                      </li>
                    </ng-container>
                  </div>
                </ul>
                <div class="settle-section">
                  <ul>
                    <li>
                      <label> Amount Payable</label>
                      <span>Rs. {{ settlement['total'] }}</span>
                    </li>
                    <li *ngIf="show_outstanding && settlement['total_out'] > 0">
                      <label> Total Outstanding</label>
                      <span>{{ settlement['total_out'] }}</span>
                    </li>
                  </ul>
                  <label *ngIf="checkEodSettleShow()"
                         data-toggle="modal"
                         data-target="#approveModal"
                         class="btn confirm-blue-btn"
                         style="margin-left: 25px; margin-top: 10px;"
                  >SETTLE</label>
                  <label class="mainerror">{{ getSettleMainErrorMessage() }}</label>
                  <label class="suberror">{{ getSettleSupportErrorMessage() }}</label>
                  <ul *ngIf="settle_enable && settled">
                    <li *ngIf="settle_enable && settled">
                      <span *ngIf="settle_enable && settled">{{ comments }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="approve-modal modal" id="approveModal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <span class="close" data-dismiss="modal">
        <img src="../../assets/close.png">
      </span>
      <div *ngIf="settlement" class="modal-body">
        <div class="approve-modal-heading">
          <label>APPROVE SETTLEMENT</label>
          <h1> Kindly approve the settled amount</h1>
        </div>
        <div class="approve-modal-content">
          <div class="custom-input">
            <label>SETTLE AMOUNT</label>
            <input [readonly]="true" type="text" class="form-control" [(ngModel)]="settlement['total']"/>
          </div>
          <div class="custom-input">
            <label>COMMENTS</label>
            <textarea
              class="form-control"
              rows="5"
              placeholder="Type message"
              [(ngModel)]="comments"
            ></textarea>
          </div>
          <a (click)="verify()" data-dismiss="modal" class="btn confirm-blue-btn">Approve</a>
          <span class="modal-btn" data-dismiss="modal">
            <a class="btn white-btn">Cancel</a>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="approve-modal modal" id="DeviationModal" role="dialog">
  <div class="modal-dialog" style="width:50%">
    <div class="modal-content">
      <span class="close" data-dismiss="modal">
        <img src="../../assets/close.png">
      </span>
      <div class="modal-body">
        <div class="pol-modal-heading">
          <h1 style="padding-bottom: 3%;">Deviations</h1>
        </div>
        <div class="pol-modal-content">
          <div *ngFor="let dev_product of dev_data">
            <label style="font-size: medium;font-weight: 400;padding-bottom: 2%;">{{ dev_product['product'] }}</label>
            <table cellpadding="0" cellspacing="0" style="width: 100%;">
              <tbody>
              <tr>
                <td class="row-style">DATE</td>
                <td class="row-style">RETAILER</td>
                <td class="row-style">INVOICE</td>
                <td class="row-style">TYPE</td>
                <td class="row-style">QUANTITY</td>
                <td class="row-style">COMMENTS</td>
              </tr>
              <div style="display: contents;" *ngFor="let dev of dev_product['devs']">
                <tr style="border-top: black 1px solid;">
                  <td class="row-val-style">{{ dev['date'] }}</td>
                  <td class="row-val-style">{{ dev['retailer'] }}</td>
                  <td class="row-val-style">{{ dev['invoice'] }}</td>
                  <td class="row-val-style">{{ dev['type'] }}</td>
                  <td class="row-val-style">{{ dev['quantity'] }}</td>
                  <td class="row-val-style">{{ dev['comments'] }}</td>
                </tr>
              </div>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="approve-modal modal" id="editOrderModal" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <span class="close" data-dismiss="modal">
        <img src="../../assets/close.png">
      </span>
      <div class="modal-body">
        <div class="approve-modal-heading">
          <label>UPDATE INVOICE</label>
          <h1> You can update the following details of sale {{ editOrderID }}</h1>
        </div>
        <div class="approve-modal-content">
          <div *ngIf="invoice_edit && editOrdMode != 'return' && editOrdMode != 'replace'" class="custom-input"
               style="margin-bottom: 1%;">
            <label>INVOICE NO</label>
            <input type="text" class="form-control" style="width: 70%;padding: 4% 7%;" [disabled]="!invoice_num_edit"
                   [(ngModel)]="editIN"/>
          </div>
          <div *ngIf="invoice_edit && editOrdMode != 'return' && editOrdMode != 'replace' && invoice_num_edit">
            <img (click)="updateInvoiceComment(editIN, editComments, editOrderID)"
                 style="cursor:pointer;margin-left: 80%;margin-top: -13%;" src="./../../assets/update.svg"/>
          </div>
          <div *ngIf="editOrdMode == 'return' || editType != 'cash'" class="custom-input" style="margin-bottom: 1%;">
            <label>PO</label>
            <input type="text" class="form-control" style="width: 70%;padding: 4% 7%;" [(ngModel)]="editPO"/>
          </div>
          <div *ngIf="editOrdMode == 'return' || editType != 'cash'" class="custom-input" style="margin-bottom: 1%;">
            <label *ngIf="editOrdMode != 'return'">GRN</label>
            <label *ngIf="editOrdMode == 'return'">GRV</label>
            <input type="text" class="form-control" style="width: 70%;padding: 4% 7%;" [(ngModel)]="editGRN"/>
          </div>
          <div *ngIf="editOrdMode != 'return' && editOrdMode != 'replace'" class="custom-input">
            <label>COMMENTS</label>
            <input type="text" class="form-control" style="width: 70%;padding: 4% 7%;" [(ngModel)]="editComments"/>
          </div>
          <div style="color:red;margin-bottom: 6%;font-size: 120%;">{{ updateText }}</div>
          <a (click)="updateOrder(editIN, editPO, editGRN, editComments)" data-dismiss="modal"
             class="btn confirm-blue-btn">Confirm</a>
          <span class="modal-btn" data-dismiss="modal">
            <a class="btn white-btn">Cancel</a>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<button #myDiv id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#BillingModal"></button>
<button #devDiv id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#DeviationModal"></button>
<button #editDiv id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#editOrderModal"></button>


<div #BillingModal class="pol-modal pol-send invoice modal" id="BillingModal" role="dialog">
  <div class="modal-dialog">

    <div class="modal-content">
          <span class="close" data-dismiss="modal">
            <img src="../../assets/close.png">
          </span>
      <div class="billing-details">
        <div class="billing-name">
          <ul class="list-unstyled">
            <li>
              <h1>{{ orderDetails['partner'] }}</h1>

            </li>
            <li>
              <h1>{{ orderDetails['code'] }}</h1>
              <label>ROUTE NAME : {{ orderDetails['beat_name'] }}</label>
            </li>
            <li>
              <h1>TYPE : {{ orderDetails['order_type'] }}</h1>
            </li>
            <a *ngIf="orderDetails['state'] =='cancel' && cancel_button" style="margin-top: 4%;background-color: grey;"
               class="btn confirm-blue-btn">Cancelled</a>
            <a *ngIf="!cancel_hide && !orderDetails['approved'] &&  orderDetails['tid'] == null && (orderDetails['d_status'] == 'delivered' ||
                                                                     (orderDetails['d_status'] == 'dispatched' && eod_status == 'complete')) &&
                 orderDetails['state'] !='cancel'" (click)="cancelPopup(orderDetails)" data-toggle="modal"
               data-target="#cancelModal" data-dismiss="modal" style="margin-top: 4%;" class="btn confirm-blue-btn">Cancel
              Invoice</a>
          </ul>
        </div>
        <div class="billing-order">
          <ul class="list-unstyled">
            <li>
              <label>INVOICE NO : {{ orderDetails['invoice_no'] }}</label>
            </li>
            <li>
              <label>REF NO : {{ orderDetails['name'] }}</label>
            </li>
            <li>
              <label>ID : {{ orderDetails['id'] }}</label>
            </li>
            <li>
              <label>GST/TAX ID : {{ orderDetails['partner_gstn'] }}</label>
            </li>
            <li>
              <label>DATE : {{ orderDetails['order_date'] }}</label>
            </li>
            <li>
              <label>DELIVERY DATE : {{ orderDetails['date_delivered'] }}</label>
            </li>
          </ul>
        </div>
        <div *ngIf="cancel_button" class="photo-eod1">
          <img (click)="clickPhoto2($event)" src={{src_url}}/>
        </div>
        <div *ngIf="!cancel_button" class="photo-eod2">
          <img (click)="clickPhoto2($event)" src={{src_url}}/>
        </div>
        <div style="margin-left: 90%;margin-top: -4.5%;">
          <button
            *ngIf="!template"
            [useExistingCss]="true"
            printSectionId="print-section"
            ngxPrint>Print
          </button>
          <button
            *ngIf="template"
            (click)="printCustom(orderDetails)"
          >Print
          </button>
        </div>
      </div>
      <div class="modal-body">

        <div class="pol-modal-heading">
          <h1>DETAILS</h1>
        </div>
        <div class="pol-modal-content">
          <ul class="pol-content-heading list-unstyled">
            <li>
              <label>PRODUCT</label>
            </li>
            <li>
              <label>TYPE</label>
            </li>
            <li>
              <label>QUANTITY</label>
            </li>
            <li>
              <label>TAX</label>
            </li>
            <li>
              <label>TAX AMOUNT</label>
            </li>
            <li>
              <label>TCS/TDS</label>
            </li>
            <li>
              <label>VALUE</label>
            </li>
          </ul>
          <div class="pol-content-text" *ngFor="let product of orderDetails['sale_items']">
            <ul class="list-unstyled">
              <li>
                <h2>{{ product['product_name'] }}</h2>
              </li>
              <li>
                <label>{{ product['type'] }}</label>
              </li>
              <li>
                <label *ngIf="orderDetails['d_status'] =='delivered'">{{ product['delivered_qty'] }}</label>
                <label *ngIf="orderDetails['d_status'] =='dispatched'">{{ product['qty'] }}</label>
                <img *ngIf="orderDetails['d_status'] !='dispatched' && orderDetails['state'] !='cancel' &&
                             !orderDetails['approved'] && (eod_status != 'complete') && (eod_status != 'settled') &&
                             cancel_button && ((product['type'] == 'damaged' && !replace_hide) || (product['type'] == 'return' && !return_hide))"
                     style="position: absolute;cursor:pointer;left: 40%;" data-toggle="modal" data-target="#returnModal"
                     (click)="updateReturnValue(orderDetails,product)"
                     src="./../../assets/edit.svg"/>
              </li>
              <li>
                <label>{{ product['tax'] }}</label>
              </li>
              <li>
                <label>{{ parseFloat(product['price_tax']).toFixed(2) }}</label>
              </li>
              <li>
                <label>{{ product['tcs'].toFixed(5) }}</label>
              </li>
              <li>
                <label> {{ parseFloat(product['amount_subtotal']).toFixed(2) }}</label>
              </li>
            </ul>
          </div>
          <div *ngIf="payment_enable" class="payment">
            <div class="pol-modal-heading">
              <h1>PAYMENT</h1>
            </div>
            <ul class="pol-content-heading list-unstyled">
              <li>
                <label>TRANSCTION ID</label>
              </li>
              <li>
                <label>DATE</label>
              </li>
              <li>
                <label>PAYMENT TYPE</label>
              </li>
              <li>
                <label>AMOUNT</label>
              </li>
            </ul>
            <div class="pol-content-text" *ngFor="let payment of orderDetails['pay_items']">
              <ul class="list-unstyled">
                <li>
                  <h2>#{{ payment['id'] }}</h2>
                </li>
                <li style="width: 241px;">
                  <label>{{ payment['payment_date'] }}</label>
                </li>
                <li>
                  <span style="font-size: small; float: left">{{ payment['payment_type'] }}</span>
                </li>
                <li>
                  <span style="font-size: small"> {{ payment['amount'].toFixed(this.apiService.decimalPolicy) }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="approve-modal modal" id="returnModal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <span class="close" data-dismiss="modal">
        <img src="../../assets/close.png">
      </span>
      <div class="modal-body">
        <div class="approve-modal-heading">
          <label>UPDATE RETURN QTY</label>
          <h1> Kindly update the return qty with reason</h1>
        </div>
        <div class="approve-modal-content">
          <div class="custom-input">
            <label>RETURN QTY</label>
            <input type="text" class="form-control" [(ngModel)]="updated_value"/>
          </div>
          <div class="custom-input">
            <label>REASON</label>
            <textarea
              class="form-control"
              rows="5"
              placeholder="Type message"
              [(ngModel)]="reason"
            ></textarea>
          </div>
          <p style="font-size:125%;color:red;">* Reason field is mandatory</p>
          <a (click)="updateReturn($event)" data-dismiss="modal" class="btn confirm-blue-btn">Update</a>
          <span class="modal-btn" data-dismiss="modal">
            <a class="btn white-btn">Cancel</a>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="approve-modal modal" id="cancelModal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <span class="close" data-dismiss="modal">
        <img src="../../assets/close.png">
      </span>
      <div class="modal-body">
        <div class="approve-modal-heading">
          <label>CANCEL ORDER</label>
          <h1> Kindly update reason</h1>
        </div>
        <div class="approve-modal-content">
          <div class="custom-input">
            <label>REASON</label>
            <textarea
              class="form-control"
              rows="5"
              placeholder="Type message"
              [(ngModel)]="cancel_reason"
            ></textarea>
          </div>
          <div *ngIf="selected_order && selected_order['payment_mode'] !='cash'" class="row" style="margin-top: 5px;">
            <input
              type="checkbox"
              [checked]="generate_credit_note"
              name="generatecn"
              [(ngModel)]="generate_credit_note"
              #generatecn=ngModel
              style="width:14px;height:14px;display: inline-block; "
            />
            <p style="margin-left: 5px;margin-top:2px;font-size: 13px;">Generate Credit Note</p>
          </div>
          <a (click)="cancelOrder()" data-dismiss="modal" class="btn confirm-blue-btn">Confirm</a>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="approve-modal modal" id="revertStatusModal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <span class="close" data-dismiss="modal">
        <img src="../../assets/close.png">
      </span>
      <div class="modal-body">
        <div class="approve-modal-heading">
          <h1> Are You Sure You Want To Revert Your Status?</h1>
        </div>
        <div class="approve-modal-content">
          <a (click)="updateVanStatus()" data-dismiss="modal" class="btn confirm-blue-btn">Yes</a>
          <span class="modal-btn" data-dismiss="modal">
            <a class="btn white-btn">No</a>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="pol-modal pol-send invoice-img modal" id="podModal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
        <span class="close" data-dismiss="modal">
          <img src="../../assets/close.png">
        </span>
      <div class="modal-body">
        <div>
          <div class="row">
            <span>Delivery Verified</span>
            <span><i class="fad fa-th-large" style="color:#183153"></i></span>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="pol-modal pol-send invoice-img modal" id="invoiceModal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
        <span class="close" data-dismiss="modal">
          <img src="../../assets/close.png">
        </span>
      <div class="modal-body">
        <img [src]="photo">
      </div>
    </div>
  </div>
</div>

<div class="approve-modal modal" id="ConvertModal" role="dialog">
  <div id="overlay1"></div>
  <div style="width:60%;" class="modal-dialog">
    <div class="modal-content">
      <span class="close" data-dismiss="modal">
        <img src="../../assets/close.png">
      </span>
      <div class="modal-body">
        <div class="eod-table">
          <div class="convert-only">
            <ag-grid-angular id="myGrid1" *ngIf="convert_loaded" style="width: 100%;height:60vh" class="ag-theme-balham"
                             [columnDefs]="convertDefs" [gridOptions]="gridOptionsConvert"
                             (gridReady)="onGridConvertReady($event)" [frameworkComponents]="frameworkComponents"
                             [context]="context"
                             [tabToNextCell]="tabToNextCell">
            </ag-grid-angular>
          </div>
          <p style="font-size: medium" [style.color]="convert_log[1] == 0 ? 'green' : 'red'">{{ convert_log[0] }}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="approve-modal modal" id="LogsModal" role="dialog">
  <div class="modal-dialog" style="width:50%">
    <div class="modal-content">
      <span class="close" data-dismiss="modal">
        <img src="../../assets/close.png">
      </span>
      <div class="modal-body">
        <div class="pol-modal-heading">
          <h1 style="padding-bottom: 3%;">Logs</h1>
        </div>
        <div class="pol-modal-content">
          <div class="eod-table">
            <div>
              <ag-grid-angular id="myGrid1" *ngIf="orderLogs_loaded" style="width: 100%;height:100%;"
                               class="ag-theme-balham" [rowSelection]="rowSelection"
                               [columnDefs]="orderLogsDefs" [gridOptions]="gridOptionsOrderLogs"
                               (gridReady)="onGridReadyOrderLogs($event)"
                               [frameworkComponents]="frameworkComponents" [context]="context"
              >
              </ag-grid-angular>
              <div *ngIf="orderLogs_preloader">
                <app-loadingstate></app-loadingstate>
              </div>
              <div *ngIf="orderLogs_empty_state">
                <app-emptystate></app-emptystate>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="print-section" [hidden]="true">
  <div *ngIf="all_orders.length > 0">
    <app-print *ngIf="!template || (selectedTemplate && selectedTemplate.name == 'Default')"
               [order_detail]="orderDetails"></app-print>
    <app-custom-print *ngIf="template && selectedTemplate && selectedTemplate.name != 'Default'"
                      [order_detail]="orderDetails"
                      [custom_template]="selectedTemplate"
    ></app-custom-print>
  </div>
</div>

<div class="approve-modal modal" id="CustomPrintModal" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <span class="close" data-dismiss="modal">
        <img src="../../assets/close.png">
      </span>
      <div class="modal-body">
        <div class="approve-modal-heading">
          <h1> Please select Print Template</h1>
        </div>
        <div class="approve-modal-content" style="witdh:50%;height:3vh">
          <select [(ngModel)]="selectedTemplate">
            <option>Select</option>
            <option *ngFor="let templ of templ_config"
                    [ngValue]="templ">{{ templ.name }}
            </option>
          </select>
        </div>
        <button
          [hidden]="!selectedTemplate"
          [useExistingCss]="true"
          printSectionId="print-section"
          ngxPrint>Print
        </button>
      </div>
    </div>
  </div>
</div>
<button #imageClick id="openImage" [hidden]="true" data-toggle="modal" data-target="#invoiceModal">Open Modal</button>

<button #podClick id="openPod" [hidden]="true" data-toggle="modal" data-target="#podModal">Open Modal</button>
<button #revertStatus id="revert" [hidden]="true" data-toggle="modal" data-target="#revertStatusModal">Open Modal
</button>
<button #convertClick id="openConvertPopup" [hidden]="true" data-toggle="modal" data-target="#ConvertModal">Open Modal
</button>
