<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper" style="margin-top: 50px!important">
      <div style="background-color: white;margin-left: 5px;margin-right: 25px;height:88vh;margin-top: 0px;">
        <div style="margin:10px;">
          <!--<div class="row" style="margin-top: 10px;">
            <div *ngIf="edit" class="button-row" style="margin-top: 10px;">
              <button
                mat-button
                style="margin-right:15px;"
                class="btn teal-btn"
                (click)="editClick()"
              >
                SAVE
              </button>
              <span>
               <a class="btn white-btn">DISCARD</a>
               </span>
            </div>
            <div *ngIf="!edit" class="button-row" style="margin-top: 10px;">
              <span>
               <a class="btn white-btn" style="margin-right:15px;" (click)="editClick()">EDIT</a>
               </span>
              <span>
               <a class="btn white-btn">DELETE</a>
               </span>
            </div>
            <div *ngIf="!edit" class="button-row" style="position: absolute;  right: 30px;margin-top: 10px; ">
              <div style="width: 350px;">
                <progress max="100" value="66" data-label="Progress 2/3"></progress>
              </div>
              <button
                mat-button
                style="margin-left:15px; margin-right:15px;"
                class="btn teal-btn"
              >
                PUBLISH
              </button>
            </div>
          </div>-->
          <div >
            <div>
              <div class="search-list" style="margin-top: 0px;">
                <div class="col-md-6 col-12 nopadding pl-0" style="margin-bottom: 50px;margin-top: 0px;">
                  <div class="row" style="margin-top: 10px;">
                    <div *ngIf="edit" class="button-row" style="margin-top: 10px;">
                      <button
                        mat-button
                        style="margin-right:15px;"
                        class="btn teal-btn"
                        form="ngForm"
                      >
                        SAVE
                      </button>
                      <span>
                         <a class="btn white-btn" (click)="discardClick()">DISCARD</a>
                      </span>
                    </div>
                    <div *ngIf="!edit" class="button-row" style="margin-top: 10px;">
                      <span>
                         <a class="btn white-btn" style="margin-right:15px;" (click)="editClick()">EDIT</a>
                      </span>
                    <!--  <span>
                        <a class="btn white-btn">DELETE</a>
                      </span>-->
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-12 pad0 text-right" style="margin-bottom: 50px;margin-top: 10px;">
                  <div class="filters">
                    <ul class="list-unstyled">
                      <li class="margLR" style="margin-right: 20px;margin-top: 10px;">
                        <span *ngIf="!edit">
                         <a class="btn teal-border-btn" style="margin-right:15px;" (click)="viewReport()">VIEW REPORT</a>
                        </span>
                      </li>
                      <li class="margLR" style="margin-right: 20px;">
                        <div style="height:12px;margin-top: 10px;">
                          <span class="scorespan">COMPLETED MTD</span>
                        </div>
                        <div>
                          <span *ngIf="retailAuditDetails.score>30" class="scorepercentspangreen">{{retailAuditDetails.completed}}</span>
                          <span *ngIf="retailAuditDetails.score<=30" class="scorepercentspanred">{{retailAuditDetails.completed}}</span>
                        </div>
                      </li>
                      <li class="margLR">
                        <div style="height:12px;margin-top: 10px;">
                          <span class="scorespan">SCORE</span>
                          <img
                            *ngIf="retailAuditDetails.score>30"
                            style="margin-bottom: 8px;"
                            src="./../../../assets/thumbsup.svg" />
                          <img
                            *ngIf="retailAuditDetails.score<=30"
                            src="./../../../assets/thumbsdown.svg" />
                        </div>
                        <div>
                          <span *ngIf="retailAuditDetails.score>30" class="scorepercentspangreen">{{retailAuditDetails.score}}%</span>
                          <span *ngIf="retailAuditDetails.score<=30" class="scorepercentspanred">{{retailAuditDetails.score}}%</span>

                        </div>
                       <!-- <div>
                          <span class="scorediffspan">+10%</span>
                        </div>-->
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 pad0">
              <form novalidate (ngSubmit)="updateRetailAudit()" id="ngForm" #taskform="ngForm">
                <div class="col-md-2 pl-0 pr-20">
                  <label style="color:#7D7D7D">CODE</label>
                  <div style="margin-top: 5px;">
                    <div *ngIf="edit">
                      <input
                        hidden=true
                        class="forminput"
                        style="width: 70%;"
                        type="text"
                        name="code"
                        [(ngModel)]="retailAuditDetails.code"
                        #code="ngModel"
                        required
                      >
                      <div *ngIf="code.errors && (code.dirty || code.touched)">
                        <span *ngIf="code.errors.required" class="text-danger">Retail Audit Code is required</span>
                      </div>
                    </div>
                    <p >{{retailAuditDetails.code}}</p>
                  </div>
                </div>
                <div class="col-md-2 pl-0 pr-20">
                  <label style="color:#7D7D7D">TYPE</label>
                  <div style="margin-top: 5px;" class="formselect">
                    <select
                      hidden=true
                      name="task_type"
                      [(ngModel)]="retailAuditDetails.type"
                      #task_type="ngModel"
                      style="width: 80%;">
                      <option value="Activation" selected>Activation</option>
                      <option value="BTL Activity">BTL Activity</option>
                      <option value="Scheme">Scheme</option>
                    </select>
                    <p >{{retailAuditDetails.type}}</p>
                  </div>
                </div>
                <div class="col-md-4 pl-0 pr-30">
                  <label style="color:#7D7D7D">AUDIT NAME</label>
                  <div style="margin-top: 5px;">
                    <div *ngIf="edit">
                      <input
                        hidden=true
                        class="forminput"
                        style="width: 70%;"
                        type="text"
                        name="taskname"
                        [(ngModel)]="retailAuditDetails.name"
                        #taskname="ngModel"
                        required
                      >
                      <div *ngIf="taskname.errors && (taskname.dirty || taskname.touched)">
                        <span *ngIf="taskname.errors.required" class="text-danger">Retail Audit name is required</span>
                      </div>
                    </div>
                    <p >{{retailAuditDetails.name}}</p>
                  </div>
                </div>
                <div class="col-md-2 pl-0 pr-20">
                  <label style="color:#7D7D7D">DESCRIPTION</label>
                  <div style="margin-top: 5px;">
                    <div *ngIf="edit">
                      <input
                        hidden=true
                        class="forminput"
                        type="text"
                        name="taskdescription"
                        [(ngModel)]="retailAuditDetails.description"
                        #taskdescription="ngModel"
                        style="width: 80%;"
                        required
                      >
                      <div *ngIf="taskdescription.errors && (taskdescription.dirty || taskdescription.touched)">
                        <span *ngIf="taskdescription.errors.required" class="text-danger">Retail Audit description is required</span>
                      </div>
                    </div>
                    <p [innerHTML]="retailAuditDetails.description"></p>
                  </div>
                </div>
                <div class="col-md-2 pl-0 pr-20">
                  <label style="color:#7D7D7D">LAST MODIFIED BY</label>
                  <div style="margin-top: 5px;">
                    <div *ngIf="edit">
                      <input
                        hidden=true
                        class="forminput"
                        type="text"
                        name="taskdescription"
                        [(ngModel)]="retailAuditDetails.last_modified"
                        #taskdescription="ngModel"
                        style="width: 80%;"
                        required
                      >
                      <div *ngIf="taskdescription.errors && (taskdescription.dirty || taskdescription.touched)">
                        <span *ngIf="taskdescription.errors.required" class="text-danger">Updated by is required</span>
                      </div>
                    </div>
                    <p >{{retailAuditDetails.last_modified}}</p>
                  </div>
                </div>
              </form>
            </div>

            <div style="margin-top: 50px;">
              <div class="search-list">
                <div class="col-md-6 col-12 nopadding pl-0" style="margin-top: 30px;display: inline-block">
                  <!--
                                      <ul >
                                      <li >
                                        SKU LIST
                                      </li>
                                      <li >
                                        TERRITORY
                                      </li>
                                      <li >
                                        CRITERIA
                                      </li>
                                    </ul>-->

                  <div>
                    <mat-tab-group (selectedTabChange)="changeActiveTab($event)">
                      <mat-tab label="SKU LIST" >
                      </mat-tab>
                      <mat-tab label="TERRITORY">
                      </mat-tab>
                      <mat-tab label="CRITERIA" >
                      </mat-tab>
                    </mat-tab-group>
                  </div>

                </div>
                <div class="col-md-6 col-12 pad0 text-right" style="margin-top: 30px;">
                  <div class="filters">
                    <ul class="list-unstyled">
                      <!-- <li class="margLR">
                         <div style="width: 350px;">
                           <progress max="100" value="66" data-label="Progress 2/3"></progress>
                         </div>
                       </li>-->
                      <li class="margLR" style="margin-left: 10px;">
                      <!--  <button *ngIf="edit && activetab=='SKU LIST'" class="icon-btn icon-btn-teal"
                                style="height: 42px;margin-bottom: 10px;margin-right:10px;"
                                (click)="createDialog()"
                        > <i class="fas fa-plus-circle"></i>
                          ADD</button>-->
                        <div style="display: inline-block;margin-top: 5px;margin-right:15px;cursor:pointer;" (click)="createDialog()" *ngIf="edit && activetab=='SKU LIST'">
                          <i class="fas fa-plus-circle" style="color:#17A2B8;margin-right: 5px;margin-top:5px;font-size:16px;"></i>
                          <span style="font-size: 16px; font-weight: 400;color:#5D5D5D">Add</span>
                        </div>
                        <div class="search" style="display: inline-block;">
                          <span class="search-control"><img src="assets/searchicon.png"/></span>
                          <input  id="filter-text-box" type="text" placeholder="filter" (input)="quickSearch()" class="search-control-area"
                                  placeholder="Search">
                        </div>
                        <div style="display: inline-block;margin-right: 10px;" >
                          <!-- <button (click)="createDialog()" class="btn teal-btn" style="height: 42px;margin-bottom: 10px;margin-left:10px;">NEW TASK</button>-->
                        </div>
                      </li>
                     <!-- <li class="margLR" style="margin-left: 10px;">
                        <div
                          class="actionbutton"
                          title="Upload"
                        >
                          <img style=" display:block;margin:auto;" src="../../assets/upload2.svg">
                        </div>
                      </li>-->
                      <li class="margLR" *ngIf="activetab==='SKU LIST'">
                        <app-excelicon  [gridOption]="gridOptions" [title]="'SKU List Report'"></app-excelicon>
                      </li>
                      <li class="margLR" *ngIf="activetab==='TERRITORY'">
                        <app-excelicon  [gridOption]="gridOptions1" [title]="'Territory Report'"></app-excelicon>
                      </li>
                      <li class="margLR" *ngIf="activetab==='CRITERIA'">
                        <app-excelicon  [gridOption]="gridOptions2" [title]="'Criteria Report'"></app-excelicon>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-12 pad0">
                  <div *ngIf="activetab==='SKU LIST'">
                    <ag-grid-angular id="myGrid1" *ngIf="loaded" style="width: 100%;height:60vh;" class="ag-theme-balham"   [defaultColDef]="defaultColDef"
                                     [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                                     (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                                     (cellClicked)="onCellClicked($event)"
                                     [context]="context">
                    </ag-grid-angular>
                    <div *ngIf="preloader">
                      <app-loadingstate></app-loadingstate>
                    </div>
                    <div *ngIf="empty_state">
                      <app-emptystate></app-emptystate>
                    </div>
                  </div>

                  <div *ngIf="activetab==='TERRITORY'">
                    <ag-grid-angular id="myGrid2" *ngIf="loaded1" style="width: 100%;height:60vh;" class="ag-theme-balham"   [defaultColDef]="defaultColDef"
                                     [columnDefs]="columnDefs1" [gridOptions]="gridOptions1"
                                     (gridReady)="onGridReady1($event)" [frameworkComponents]="frameworkComponents"
                                     (cellClicked)="onCellClicked1($event)"
                                     [context]="context">
                    </ag-grid-angular>
                    <div *ngIf="preloader1">
                      <app-loadingstate></app-loadingstate>
                    </div>
                    <div *ngIf="empty_state1">
                      <app-emptystate></app-emptystate>
                    </div>
                  </div>

                  <div *ngIf="activetab==='CRITERIA'">
                    <ag-grid-angular id="myGrid3" *ngIf="loaded2" style="width: 100%;height:60vh;" class="ag-theme-balham"   [defaultColDef]="defaultColDef"
                                     [columnDefs]="columnDefs2" [gridOptions]="gridOptions2"
                                     (gridReady)="onGridReady2($event)" [frameworkComponents]="frameworkComponents"
                                     (cellClicked)="onCellClicked2($event)"
                                     [context]="context">
                    </ag-grid-angular>
                    <div *ngIf="preloader2">
                      <app-loadingstate></app-loadingstate>
                    </div>
                    <div *ngIf="empty_state2">
                      <app-emptystate></app-emptystate>
                    </div>
                  </div>

                  <!--<p *ngIf="edit" style="font-size: 12px;color:#17A2B8;margin-top: 10px;margin-left: 10px;cursor:pointer" (click)="createDialog()">Add an Item</p>-->
                  <!--<img *ngIf="edit && activetab=='SKU LIST'"
                       style="margin-top: 10px;margin-left: 10px;cursor:pointer"
                       (click)="createDialog()"
                       src="./../../../assets/additem.svg" />-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
