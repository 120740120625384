<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Approve Survey</h2>
    </div>
    <div class="body-section" style="margin-top: 20px; padding: 20px 15px;">
      <div style="height: 70px;margin-top: 20px;">
                    <textarea
                      class="forminput"
                      style="height: 90px; width: 100%"
                      type="text"
                      name="comments"
                      #reject_reason="ngModel"
                      [(ngModel)]="updateData.comments"
                      placeholder="Enter Comments"
                    ></textarea>
      </div>
    </div>
    <div class="button-row" style="margin-top: 20px;margin-left: 15px;">
      <button (click)="performAction()" data-dismiss="modal" class="btn blue-btn">Submit</button>
    </div>
  </div>
</section>
