import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {ApiService} from '../../../_services/api.service';
import {LocationPopupComponent} from '../../../components/location_popup/location_popup.component';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-change-request-popup',
  templateUrl: './change_request_popup.component.html',
  styleUrls: ['./change_request_popup.component.scss']

})

export class ChangeRequestPopupComponent implements OnInit {

  public photo;
  API_URL = '';
  container = '';
  user_data = {
    date: null,
    access_token: null,
    url: null,
    data: {
      start_date: null,
      end_date: null,
      id: null
    }
  };
  start_lat;
  start_lng;
  end_lat;
  end_lng;
  zoom: number;
  starticon;
  endicon;
  approveAccess = true;
  changeRequestData: any = {};
  request_type_text = '';
  showMap = false;
  role_features;
  enableApprove = false;
  @Output() crAction: EventEmitter<any> = new EventEmitter<any>();

  constructor(private apiService: ApiService,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private toastr: ToastrService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.changeRequestData = dialogdata;
    this.request_type_text = this.changeRequestData.request_type;
    this.formatValue(this.changeRequestData.request_type, this.changeRequestData.old_val_json, 'old');
    this.formatValue(this.changeRequestData.request_type, this.changeRequestData.new_val_json, 'new');
    if (this.request_type_text != null) {
      this.request_type_text = this.request_type_text
        .replace(/_/g, ' ')
        .replace(/(^\w|\s\w)/g, m => m.toUpperCase());
    }
    this.zoom = 15;
    this.starticon = {url: '../../../../assets/icons/grey_marker.svg', scaledSize: {height: 40, width: 40}};
    this.endicon = {url: '../../../../assets/icons/blue_marker.svg', scaledSize: {height: 40, width: 40}};
    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(existed => 'feature_enable_approve' === existed.name);
    if (this.role_features.length > 0) {
      this.enableApprove = true;
    }
    const userid = JSON.parse(localStorage.getItem('user')).id;
    if ((userid != null && userid === this.changeRequestData.create_uid) || (this.changeRequestData.status !== 'pending' && this.changeRequestData.status !== 'draft')) {
        this.approveAccess = false;
    }
    if (this.changeRequestData.request_type.toLowerCase().includes('location') ||
      this.changeRequestData.l_lat !== null && this.changeRequestData.l_lat !== undefined
      && this.changeRequestData.l_lat !== "") {
      this.showMap = true;
      if (this.changeRequestData.l_lat !== null && this.changeRequestData.l_lat !== undefined
        && this.changeRequestData.l_lat !== "") {
        this.end_lat = parseFloat(this.changeRequestData.l_lat);
        this.end_lng = parseFloat(this.changeRequestData.l_lng);
      }
    } else {
      this.showMap = false;
    }
  }

  ngOnInit(): void {
  }


  closeDialog() {
    this.dialog.closeAll();
  }

  isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  formatValue(requestType, requestValue, type) {
    let isJson = true;
    if (requestValue !== null && requestValue !== "") {
      try {
        JSON.parse(requestValue);
      } catch (e) {
        isJson = false;
      }
    } else {
      isJson = false;
    }
    if (isJson) {
      if (this.isJsonString(requestValue)) {
        const jsonValue = JSON.parse(requestValue);
        if (requestType.toLowerCase().includes('location')) {
          let address: any = '';
          if (jsonValue.hasOwnProperty("street1")) {
            address = address.concat(jsonValue.street1);
          }
          if (jsonValue.hasOwnProperty("street2")) {
            address = address.concat(jsonValue.street2);
          }
          if (jsonValue.hasOwnProperty("area")) {
            address = address.concat(jsonValue.area) + ",";
          }
          if (jsonValue.hasOwnProperty("zone")) {
            address = address.concat(jsonValue.zone) + ",";
          }
          if (jsonValue.hasOwnProperty("city")) {
            address = address.concat(jsonValue.city) + ",";
          }
          if (jsonValue.hasOwnProperty("state")) {
            address = address.concat(jsonValue.state) + ",";
          }
          if (jsonValue.hasOwnProperty("zip")) {
            address = address.concat(jsonValue.zip) + ",";
          }
          if (jsonValue.hasOwnProperty("country")) {
            address = address.concat(jsonValue.country);
          }
          if (jsonValue.hasOwnProperty("latitude")) {
            if (type === 'old') {
              this.start_lat = parseFloat(jsonValue.latitude);
            } else {
              this.end_lat = parseFloat(jsonValue.latitude);
            }
          }
          if (jsonValue.hasOwnProperty("longitude")) {
            if (type === 'old') {
              this.start_lng = parseFloat(jsonValue.longitude);
            } else {
              this.end_lng = parseFloat(jsonValue.longitude);
            }
          }

          return address;
        } else if (requestType === 'program_type') {
          if (jsonValue.hasOwnProperty("value")) {
            return jsonValue.value;
          } else {
            return "";
          }
        } else if (requestType === 'classification') {
          if (jsonValue.hasOwnProperty("outlet_type")) {
            if (jsonValue.outlet_type.hasOwnProperty("value")) {
              return jsonValue.outlet_type.value;
            } else {
              return "";
            }
          }
          if (jsonValue.hasOwnProperty("class_type")) {
            return jsonValue.class_type;
          } else {
            return "";
          }
        } else if (requestType === 'preorder') {
          if (jsonValue.hasOwnProperty("preorder")) {
            return jsonValue.preorder;
          } else {
            return "";
          }
        } else if (requestType === "asset_transfer") {
          let asset: any = '';
          if (jsonValue.hasOwnProperty('asset_name')) {
            asset = "Asset : " + jsonValue.asset_name + ", ";
          }
          if (jsonValue.hasOwnProperty('partner_name')) {
            asset += "Partner : " + jsonValue.partner_name;
          }
          return asset;
        } else if (jsonValue.hasOwnProperty("value")) {
          return jsonValue.value;
        } else {
          const str = JSON.stringify(jsonValue)
            .replace(/{/g, "")
            .replace(/"/g, "")
            .replace(/,/g, ", ")
            .replace(/:/g, ": ")
            .replace("\"", "")
            .replace("\",", "")
            .replace(/}/g, "");
          return str;
        }
      } else {
        return requestValue;
      }
    } else {
      return "";
    }
  }

  showLocationPopup() {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: ' 100vw',
        height: ' 100vh',
        maxWidth: ' 70%',
        maxHeight: ' 95%',
        data: {
          latitude: this.start_lat,
          longitude: this.start_lng,
          end_latitude: this.end_lat,
          end_longitude: this.end_lng,
          title1: 'Old Location',
          title2: 'New Location'
        }
      };
      const creatediaeref = this.dialog.open(LocationPopupComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: ' 100vw',
        height: ' 100vh',
        maxWidth: ' 90%',
        maxHeight: ' 65%',
        data: {
          latitude: this.start_lat,
          longitude: this.start_lng,
          end_latitude: this.end_lat,
          end_longitude: this.end_lng,
          title1: 'Old Location',
          title2: 'New Location'
        }
      };

      const creatediaeref = this.dialog.open(LocationPopupComponent, config);
    }
  }

  performAction(state) {
    this.crAction.emit(state);
    //this.dialog.closeAll();
    this.dialogRef.close();
  }

  mapReady(map) {
    const bonds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
    if (this.start_lat !== null && this.start_lat !== undefined && this.start_lat !== 0) {
      bonds.extend(new google.maps.LatLng(this.start_lat, this.start_lng));
    }
    bonds.extend(new google.maps.LatLng(this.end_lat, this.end_lng));
    map.fitBounds(bonds);
  }

  goToLink(type) {
    let url = '';
    if (type === 'old') {
      url = 'http://maps.google.com/maps?q=' + this.start_lat + ',' + this.start_lng;
    } else {
      url = 'http://maps.google.com/maps?q=' + this.end_lat + ',' + this.end_lng;
    }
    window.open(url, "_blank");
  }

}
