<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/semantic.min.css"
/>
<script src="https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/semantic.min.js"></script>

<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper">
      <div class="col-md-12 pad0">
        <div class="search-list row" style="display: block; margin-bottom: 15px;">
          <div class="filters">
            <ul class="list-unstyled">
              <li style="margin-right: 1px !important;margin-left: 1px !important;" class="margLR">
                <div>
                  <select
                    name="period" class="ui dropdown"
                    (change)="changePeriod($event.target.value)"
                  >
                    <option value="TM" selected="selected">This Month</option>
                    <option value="LM">Last Month</option>
                    <option value="TQ">This Quarter</option>
                    <option value="LQ">Last Quarter</option>
                    <option value="TM-LY">This Month (vs Last Year)</option>
                    <option value="TY">This Year</option>
                    <option value="FY">Financial Year</option>
                  </select>
                </div>
                <div>

                </div>
              </li>
              <li class="margLR">
                <div style="background-color: white;width:45px;height:45px;display: flex;
    align-items: center; justify-content: center;cursor:pointer; "
                >
                  <!--   <mat-icon style="height: 25px;">filter_list</mat-icon>-->
                  <span title="Add Filter">
                    <img
                      style="height: 20px;"
                      src="../../assets/filter.svg"
                      [matMenuTriggerFor]="filterdropdown"
                    >
                  </span>
                  <mat-menu #filterdropdown="matMenu">
                    <app-components-filters [matMenuTriggerFor]="filterdropdown"
                                            [showableFilters]="showableFilters"
                                            (onsetFilter)="setFilter($event)">
                    </app-components-filters>
                  </mat-menu>
                </div>
              </li>
              <li class="margLR">
                <span (click)="refreshBoard()" style="cursor: pointer;">
                  <i class="fad fa-sync-alt fa-2x mt-2" ></i>
                </span>
              </li>
              <li>
                <div *ngIf="showAddRows" [matMenuTriggerFor]="moredropwdown" style="background-color: white;width:45px;height:45px;display: inline-block;
    align-items: center; justify-content: center;cursor:pointer;padding: 12px;margin-right: 10px; "
                >
                  <!--   <mat-icon style="height: 25px;">filter_list</mat-icon>-->
                  <span title="More Options">
                  <!--  <img
                      style="height: 20px;"
                      src="../../assets/filter.svg"
                      [matMenuTriggerFor]="filterdropdown"
                    >-->
              <i style="padding-left: 6px;" class="far fa-ellipsis-v fa-2x"></i>
                    <!--  <i *ngIf="(this.userData.filterData | json) != '{}'" class="fas fa-filter fa-2x"></i>
                       <i *ngIf="(this.userData.filterData | json) === '{}'" class="fas fa-filter fa-2x"></i>-->
                  </span>
                  <mat-menu #moredropwdown="matMenu">
                    <button mat-menu-item (click)="add_grid_row(4)">
                      <mat-icon style="margin-bottom: 5px;">add</mat-icon>
                      <span>Add 4 col Row</span>
                    </button>
                    <button mat-menu-item (click)="add_grid_row(2)">
                      <mat-icon style="margin-bottom: 5px;">add</mat-icon>
                      <span>Add 2 Col Row</span>
                    </button>
                    <button mat-menu-item (click)="add_grid_row(1)">
                      <mat-icon style="margin-bottom: 5px;">add</mat-icon>
                      <span>Add 1 Col Row</span>
                    </button>
                    <button mat-menu-item (click)="delete_last_row()">
                      <mat-icon style="margin-bottom: 5px;">delete_forever</mat-icon>
                      <span>Delete last Row</span>
                    </button>
                  </mat-menu>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <app-grid-component></app-grid-component>
      </div>
    </div>
  </div>
</div>
