import {ReportBase} from '../_common/reportbase';
import {Component, OnInit} from '@angular/core';
import {ErrorHandler} from '../../error/error-handler';

@Component({
  selector: 'app-invoice-report',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

// tslint:disable-next-line:component-class-suffix
export class InvoiceReport extends ReportBase implements IReport, OnInit {

  apiData: any = [];
  showCustomFilter = true;

  customFilter: any = {
    name: 'Type',
    key: 'type',
    value: 'S',
    values: [],
    show: true
  };

  setTitle() {
    this.title = 'Invoice Report';
  }

  ngOnInit() {
    this.setTitle();
    this.sharedService.setHeader('Invoice Report');
    this.API_URL = '/api/pwa_reports/getInvoiceReport';
    this.customFilter.values = [
      {id: "S", itemName: "Secondary"},
      {id: "P", itemName: "Primary"}
    ];
      }

  configureGrid() {
    this.columnDefs = [];
    this.columnDefs = [
      {headerName: "Invoice Date", field: "date_invoice", width: 100},
      {headerName: "Customer Name", field: "cust_name", width: 100},
      {headerName: "Customer Code", field: "cust_code", width: 100},
      {headerName: "Customer Status", field: "cust_status", width: 100},
      {headerName: "Customer GST", field: "cust_gst", width: 100},
      {headerName: "Customer Mobile", field: "cust_mobile", width: 100},
      {headerName: "Customer Email", field: "cust_email", width: 100},
      {headerName: "Customer Class", field: "cust_class", width: 100},
      {headerName: "Customer Type", field: "cust_type", width: 100},
      {headerName: "Customer Program Type", field: "cust_pgm_type", width: 100},
      {headerName: "Customer Lat", field: "cust_lat", width: 100},
      {headerName: "Customer Long", field: "cust_long", width: 100},
      {headerName: "Customer Address", field: "cust_address", width: 100},
      {headerName: "Customer State", field: "cust_state", width: 100},
      {headerName: "Customer District", field: "cust_zone", width: 100},
      {headerName: "Customer Area", field: "cust_area", width: 100},
      {headerName: "Customer City", field: "cust_city", width: 100},
      {headerName: "Customer Created", field: "cust_created", width: 100},
      {headerName: "Customer Target", field: "cust_target", width: 100},

      {headerName: "User", field: "user_name", width: 100},
      {headerName: "Emp Code", field: "emp_code", width: 100},
      {headerName: "Role", field: "user_role", width: 100},
      {headerName: "SAC", field: "sac", width: 100},
      {headerName: "Team", field: "team", width: 100},
      {headerName: "Team Lead", field: "team_lead", width: 100},
      {headerName: "User State", field: "user_state", width: 100},

      {headerName: "Beat ID", field: "beat_id", width: 100},
      {headerName: "Beat Name", field: "beat_name", width: 100},
      {headerName: "Terr Code", field: "terr_code", width: 100},
      {headerName: "Terr Name", field: "terr_name", width: 100},
      {headerName: "Region", field: "region", width: 100},

      {headerName: "Distributor Name", field: "dist_name", width: 100},
      {headerName: "Distributor Code", field: "dist_code", width: 100},
      {headerName: "Distributor City", field: "dist_city", width: 100},
      {headerName: "Distributor State", field: "dist_state", width: 100},

      {headerName: "Invoice ID", field: "invoice_id", width: 100},
      {headerName: "Invoice No", field: "invoice_ref", width: 100},
      {headerName: "Delivery Status", field: "dlr_status", width: 100},
      {headerName: "Delivered Date", field: "date_delivered", width: 100},
      {headerName: "Sale Ref", field: "order_ref", width: 100},

      {headerName: "Product Name", field: "prd_name", width: 100},
      {headerName: "Product Code", field: "prd_code", width: 100},
      {headerName: "Product Family", field: "family", width: 100},
      {headerName: "Product Brand", field: "brand", width: 100},
      {headerName: "Product Category", field: "categ", width: 100},

      {headerName: "Line Type", field: "line_type", width: 100},
      {headerName: "Qty", field: "qty", width: 100},

      {headerName: "Price", field: "price_unit", width: 100},
      {headerName: "Line Disc(%)", field: "line_disc_in_pct", width: 100},
      {
        headerName: "Line Disc", field: "line_disc_in_amt", width: 100,
        valueGetter: params => {
          if (params.data) {
            return Math.round(parseFloat(params.data.line_disc_in_amt) * 100) / 100;
          }
        }
      },
      {headerName: "SPL Disc", field: "spl_discount", width: 100},
      {headerName: "Scheme Disc", field: "scheme_disc", width: 100},
      {
        headerName: "Subtotal", field: "price_subtotal", width: 100,
        valueGetter: params => {
          if (params.data) {
            return Math.round(parseFloat(params.data.price_subtotal) * 100) / 100;
          }
        }
      }
    ];

    const taxMaster = this.apiData.taxmaster;
    if (taxMaster && taxMaster.length > 0) {
      for (const key in taxMaster) {
        this.columnDefs.push({
          headerName: taxMaster[key].description,
          field: taxMaster[key].description,
          cellStyle: {textAlign: 'center'},
          width: 100,
        });
      }
    }

    this.columnDefs.push({
      headerName: "Tax", field: "amt_tax", cellStyle: {textAlign: 'center'}, width: 100,
      valueGetter: params => {
        if (params.data) {
          return Math.round(parseFloat(params.data.amt_tax) * 100) / 100;
        }
      }
    });
    this.columnDefs.push({
      headerName: "Total", field: "amt_total", cellStyle: {textAlign: 'center'}, width: 100,
      valueGetter: params => {
        if (params.data) {
          return Math.round(parseFloat(params.data.amt_total) * 100) / 100;
        }
      }
    });
    this.columnDefs.push({
      headerName: "TDS/TCS", field: "tax_source", width: 100,
      valueGetter: params => {
        if (params.data) {
          return Math.round((params.data.tax_source + Number.EPSILON) * 100) / 100;
        }
      }
    });
    this.columnDefs.push({
      headerName: "Roundoff", field: "roundoff", cellStyle: {textAlign: 'center'}, width: 100,
      valueGetter: params => {
        if (params.data) {
          return Math.round(parseFloat(params.data.roundoff) * 100) / 100;
        }
      }
    });
  }

  loadServerData() {
    this.goClicked = true;
    this.dataLoaded = false;
    this.preloader = true;
    this.emptyState = false;
    this.errorExists = false;
    this.pageData = [];
    this.selectedCount = 0;
    this.showApprove = false;

    if (this.API_URL === '') {
      return this.handleError(ErrorHandler.getErrorObject('API Not Configured'));
    }
    if (this.showSearchableFilter) {
      if (!this.userData.searchableFilter && this.searchableFilter && this.searchableFilter.values && this.searchableFilter.values.length > 0) {
        this.userData.searchableFilter = this.searchableFilter.values[0][this.searchableFilter['bindValue']];
        this.searchableFilter.value = this.searchableFilter.values[0][this.searchableFilter['bindValue']];
      }
    }
    if (this.showCustomFilter) {
      if (this.userData.customFilter != null && !this.userData.customFilter.hasOwnProperty(this.customFilter.key)) {
        this.userData.customFilter[this.customFilter.key] = this.customFilter.value;
      }
    }
    if (this.showCustomFilter1) {
      if (this.userData.customFilter != null && !this.userData.customFilter.hasOwnProperty(this.customFilter1.key)) {
        this.userData.customFilter[this.customFilter1.key] = this.customFilter1.value;
      }
    }
    if (this.dateSelectMode === 'single') {
      this.userData.start_date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
      this.userData.end_date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    } else if (this.dateFilterType === 'period') {
      const periodData = JSON.parse(localStorage.getItem('jc_months'));

      if (!this.periodID || this.periodID === 0) {
        this.periodID = periodData[0].id;
      } else {
        if (periodData.length > 0) {
          periodData.reverse();
          this.periodID = periodData[0].id;
        }
      }
      const period = periodData.find(p => p.id === this.periodID);
      if (period.length > 0) {
        this.userData.period_id = period.id;
        this.userData.start_date = period.start_date;
        this.userData.end_date = period.end_date;
      }

    } else {
      this.userData.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
      this.userData.end_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    }

    if (this.showProgress === false) {

      //Load Data in one shot
      this.apiService.post(this.API_URL, this.userData)
        .subscribe(res => {
            console.log(res);
            // t.stop();
            if (res.hasOwnProperty('results') && (res.results.status === 200)) {
              this.apiData = res.results; //TODO: check if this line is needed
              this.postProcessData();
              //this.rowData should be ready now
              this.preloader = false;
              if (this.rowData === null || this.rowData === undefined) {
                this.emptyState = true;
              } else if (this.rowData.length === 0) {
                this.emptyState = true;
              } else {
                this.dataLoaded = true;
              }
            } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
              this.emptyState = true;
              if (res.hasOwnProperty('results') && res.results.hasOwnProperty('errmsg')) {
                console.error(res.results.errmsg);
              }
              this.handleError(ErrorHandler.getErrorObject(res.results.msg));
            } else {
              this.emptyState = true;
              if (res.hasOwnProperty('results') && res.results.hasOwnProperty('errmsg')) {
                console.error(res.results.errmsg);
              }
              this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
            }
          },
          // tslint:disable-next-line:no-shadowed-variable
          error => this.handleError(ErrorHandler.getErrorObject(error)));
    } else {
      //Load data in batches
      this.loadInBatches(this.userData.offset, this.keyColumn);
      if (this.selectedMultiFilter !== null && this.selectedMultiFilter !== undefined &&
        this.selectedMultiFilter.length > 0 && this.multiInstance) {
        this.fetchAllAccountsData(this.keyColumn);
      } else {
        this.fetch(this.keyColumn);
      }
    }
  }

  postProcessData() {
    this.configureGrid();
    this.configureData();
  }

  configureData() {
    this.rowData = [];
    const taxMaster = this.apiData.taxmaster;
    const salesMaster = this.apiData.sales;
    const taxLines = this.apiData.taxlines;

    for (const key in this.apiData.sales) {
      let indrow = {};
      const taxRow: any = {};
      let salesRow: any = {};
      let taxLine: any = [];

      salesRow = this.apiData.sales[key];
      if (taxMaster && taxMaster.length > 0 && taxLines && taxLines.length > 0) {
        taxLine = taxLines.filter(tax => tax.id === salesRow.line_id && tax.description);

        if (taxLine && taxLine.length > 0) {
          for (const key1 in taxMaster) {
            taxRow[taxMaster[key1].description] = taxLine.filter(tax => tax.description === taxMaster[key1].description)[0];
            if (taxRow[taxMaster[key1].description] != null && taxRow[taxMaster[key1].description] !== undefined) {
              taxRow[taxMaster[key1].description] = taxRow[taxMaster[key1].description].tax_amt;
            }
          }
        }
      }

      indrow = {
        ...salesRow,
        ...taxRow
      };
      this.rowData.push(indrow);
    }
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllColumns().length;
    params.api.setRowData(this.rowData);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (params.api) {
          if (noOfColumns < 16 && screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (noOfColumns < 16 && screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }
}
