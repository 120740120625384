import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";

@Component({
  selector: 'reuse-editor',
  template: `<div class="eod-top" layout="row">` +
  `<span class="element-po"><input type="text" [(ngModel)]="reuseValue" (ngModelChange)="editCell($event)" style="border: 0;width: 60% !important;"/></span>` +
  `</div>`
})
export class ReuseRenderer implements ICellRendererAngularComp {
  public params: any;
  public reuseValue = 0;

  agInit(params: any): void {
    this.params = params;
    this.reuseValue = this.params.data['Reusable Qty'];
  }

  public editCell(event) {

    this.params.data['Reusable Qty'] = this.reuseValue;
    this.params.data['Damaged Qty'] = this.params.data['QTY'] - this.params.data['Reusable Qty'];
    this.params.context.componentParent.changeDamageValue(this.params.data['Damaged Qty'], this.params.node);
  }

  refresh(): boolean {
    return false;
  }
}
