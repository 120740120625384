import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";

declare var $: any;
@Component({
  selector: 'app-addresscell-editor',
  template: `
    <div *ngIf="isAddress" layout="row">
      <div *ngIf="enableEdit" class="formselectsmall">
        <select
          #addressselect
          id="addressselect"
          name="address"
          [(ngModel)]="selectedAddressId"
          (change)="selectAddress($event)"
          #address="ngModel"
          style="width: 100%;">
          <option
            *ngFor="let item of addressList"
            value="{{ item.id}}"
          > {{ item.name }}</option>
        </select>
      </div>
      <span *ngIf="!enableEdit">{{selectedAddressId}}</span>
    </div>`,
  styleUrls: ['./../app/prolist/prolist.component.scss']
})
export class AddressCellRendererComponent implements ICellRendererAngularComp {
  public params: any;
  billTo_list = [];
  shipTo_list = [];
  selectedAddressId = 0;
  selectedAddressName = '';
  addressList = [];
  isAddress = false;
  enableEdit = false;

  agInit(params: any): void {
    this.params = params;
    if (this.params.data.hasOwnProperty('Bill To') || this.params.data.hasOwnProperty('Ship To')) {
      this.isAddress  = true;
      if (this.params.data.invoice === null) {
        this.enableEdit = true;
      } else {
        this.enableEdit = false;
      }
      this.billTo_list = params.data.billto_adrr_list;
      this.shipTo_list = params.data.shipto_adrr_list;
      if (this.params.colDef.field === 'Bill To') {
        this.addressList = this.billTo_list;
        if (this.billTo_list) {
          this.selectedAddressId = this.billTo_list[0].id;
          this.selectedAddressName = this.billTo_list[0].name;
        }
      } else {
        this.addressList = this.shipTo_list;
        if (this.shipTo_list) {
          this.selectedAddressId = this.shipTo_list[0].id;
          this.selectedAddressName = this.shipTo_list[0].name;
        }
      }
    }
  }

  refresh(): boolean {
    return false;
  }

  selectAddress(event) {
    console.log(event);
    console.log(this.params.colDef.field);
    if (this.params.colDef.field === 'Bill To') {
      this.params.data['Bill To'] = this.selectedAddressId;
    } else {
      this.params.data['Ship To'] = this.selectedAddressId;
    }
  }
}
