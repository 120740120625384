<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" style="float: right;background-color:#D8E2EF;">
        <i class="fas fa-times"></i>
      </button>
      <h2>{{attendanceDate}} : {{empName}} ({{empCode}})</h2>
    </div>
    <div class="body-section w-100" style="text-align: center;">
      <div style="display: flex; width: 800px;margin: 0 auto;">
        <div class="togglearrow">
          <span *ngIf="rowIndex!==0"
                (click)="fetchPreviousData()"
          >
            <i class="fad fa-chevron-circle-left fa-4x" style="cursor:pointer;"></i>
          </span>
        </div>
        <div>
          <div style="position: relative">
            <img [src]="photo" class="selfieimage">
            <div class="row maindetail">
              <img [src]="staticMapUrl" class="staticmap">
              <div class="details">
                <span style="font-size: 16px;">{{outletAddress}}</span>
                <span>Lat : {{latitude}}</span>
                <span>Long : {{longitude}}</span>
                <span>{{attendanceTime}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="togglearrow">
          <span *ngIf="rowIndex!==rowData.length-1"
                (click)="fetchNextData()">
            <i class="fad fa-chevron-circle-right fa-4x" style="cursor:pointer;"></i>
          </span>
        </div>
      </div>

    </div>
  </div>
</section>
