import {
  Component,
  OnInit,
  ElementRef,
  Renderer2,
  ViewChild,
  ComponentFactoryResolver,
  ChangeDetectorRef
} from '@angular/core';
import { GridOptions } from '../../../../node_modules/@ag-grid-enterprise/all-modules';
import { ApiService} from '../../_services/api.service';
import { Router, ActivatedRoute  } from '@angular/router';
import { DatePipe } from '@angular/common';
import {AdDirective3} from '../../ad3.directive';
import {StockClickRendererComponent} from '../../stockClickRenderer.component';
import {SharedService} from '../../_services/shared_service';
import {AppComponent} from '../../app.component';
import * as XLSX from "xlsx-js-style";

@Component({
  selector: 'app-mystock',
  templateUrl: './my-stock.component.html',
  styleUrls: ['./my-stock.component.scss']
})
export class MyStockComponent implements OnInit {
  @ViewChild(AdDirective3, {static: true}) adHost3: AdDirective3;
  @ViewChild('fileUp') fileUp: ElementRef;

  user;
  res_data;
  user_data = {
    "date":null,
    "access_token": null,
    "url": null,
    "last_date": null,
    "flag": true,
    "out_type": null,
    my_stock: true,
    limit: 0,
    offset: 0,
    dist_id: null
  };

  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public searchValue;
  private pinnedBottomRowData;
  private pinnedTottomRowData;
  public frameworkComponents;
  public context;
  public rowData = [];
  public navigate;
  public defaultColDef;
  public sideBar;
  private rowHeight;
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public styleGrid;
  public myDate = [];
  public collapse_disable;
  public report_flag;
  public all_types;
  public type_default = true;
  public orderDetails;
  public itemList = [{id: 0, itemName: 'All Types'}];
  public selectedItems = [{id: 0, itemName: 'All Types'}];
  public upload_stock = false;
  public uploadCaption = ['', 0];
  public fileUploaded: File;
  public storeData: any;
  public worksheet: any;
  public uploading = false;
  public uploadLoader = false;
  public uploadEnable = false;
  public uploadPodata;
  public poData;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef:ElementRef,
              private renderer: Renderer2,
              private datePipe: DatePipe,
              public appComp: AppComponent,
              private sharedService: SharedService,
              private componentFactoryResolver: ComponentFactoryResolver) {

    this.user_data.date = this.myDate[0];
    this.user_data.last_date = this.myDate[1];
    this.user_data.offset = -1 * (new Date(this.user_data.date)).getTimezoneOffset();
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');
    this.report_flag = this.route.snapshot.params["report_flag"];
    this.itemList = this.itemList.concat(JSON.parse(localStorage.getItem('all_dist_types')));
    let role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function (existed) {
      return existed.name === 'feature_upload_stock';
    });

    if (role_features.length > 0) {
      this.upload_stock = true;
    }

    this.appComp.header_title = 'My Stock';

    this.livestockreport();

    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'single',
      // groupDisplayType: 'groupRows',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      rowGroupPanelShow: 'always',
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheader',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      /* onColumnVisible: this.onColumnVisible,
       onDragStopped : this.onDragStopped*/
    } as GridOptions;

  }

  uploadedFile(event) {

    this.uploadCaption = ['', 0];
    this.fileUploaded = event.target.files[0];
    this.readExcel();
  }

  readExcel() {

    const readFile = new FileReader();
    readFile.onload = (e) => {
      this.storeData = readFile.result;
      const data = new Uint8Array(this.storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) { arr[i] = String.fromCharCode(data[i]); }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[firstSheetName];

      this.uploadPodata = XLSX.utils.sheet_to_json(this.worksheet, {raw: true});
      this.poData = XLSX.utils.sheet_to_json(this.worksheet, {raw: true});
      console.log(XLSX.utils.sheet_to_json(this.worksheet, {raw: true}));
    };
    readFile.readAsArrayBuffer(this.fileUploaded);
  }

  async uploadexcel() {
    //debugger;
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    this.uploading = true;
    this.uploadLoader = true;
    let inthis = this;
    let succcessCount = 0;
    let failedCount = 0;
    let paramObject: any = {};
    let obj = {};
    paramObject.access_token =  this.user_data.access_token;
    let total_len = this.poData.length;
    let upload_stock;
    if (this.poData.length > 0) {
      do {
        upload_stock = this.poData.splice(1000);
        paramObject.data = this.poData;
        let resp = await this.apiService.uploadStock(paramObject);
        if (resp.hasOwnProperty('results') && resp['results'].statusCode === 200) {
          succcessCount += this.poData.length;
        } else {
          failedCount += 1;
        }
        this.poData = upload_stock;
      } while (this.poData.length > 0);
      this.uploadCaption = ['Uploading completed, Successes: ' + succcessCount + ',Failures: ' + failedCount, 0];
      this.uploading = false;
      this.uploadLoader = false;
      this.poData = [];
      this.fileUp.nativeElement.value = null;
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
      this.livestockreport();
    } else {
      // this.toastr.warning('Customer List Not Loaded Yet.Try Again Later');
    }
  }


  typeChange(event) {
    debugger;
    if (event.length > 0) {
      if (event[0].id !== 0) {
        this.user_data.out_type = event[0].id;
        this.livestockreport();
      } else {
        this.user_data.out_type = null;
        this.livestockreport();
      }
    }
  }

  async livestockreport() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];
    let rowdata = [];
    let loop_data = [];
    let total = 0;
    let all_types = [];
    let loop_dict = {};
    let item_obj = {};
    let inthis = this;


    this.res_data = await this.apiService.liveStockReport(this.user_data);
    if (this.res_data.hasOwnProperty('results') && (this.res_data['results'].status == 200)) {
      this.res_data['results'].data.forEach(function (data) {
        var stock_value = (parseFloat(data.stock) * parseFloat(data.cost_price)).toFixed(inthis.apiService.decimalPolicy);
        var pushData = {
          "Territory": data.territory,
          "Territory Code": data.code,
          "Product Code": data.default_code,
          "Product": data.name,
          Category: data.category,
          Brand: data.brand,
          "Cost Price": data.cost_price,
          "Live Stock": data.stock,
          "Stock Value": stock_value,
          "Cost History": 'cost',
          "Stock Log": 'stock',
          "distributor_id": data.dist_id,
          "product_id": data.prod_id,
          "territory_id": data.territory_id
        };
        rowdata.push(pushData);

      });
      this.data = rowdata[0];
      this.rowData = rowdata;

      for (var key in this.data) {
        if (this.data.hasOwnProperty(key)) {
          if (key == 'Territory Code' || key == 'Product Code' || key == 'Product') {
            this.columnDefs.push({ headerName: key, field: key, sortable: true, filter: true, lockPosition: true });
          }else if (key == "Cost Price" || key == 'Live Stock' || key == 'Stock Value') {
            this.columnDefs.push({
              headerName: key, field: key, resizable: true, filter: true,cellStyle: {textAlign: "right"},sortable: true,
            });
          }else if (key == "Cost History" || key == 'Stock Log') {
            this.columnDefs.push({
              headerName: key, field: key, resizable: true, filter: true,
              cellRenderer: "stockClickRenderer",
            });
          }else if (key == "distributor_id" || key == 'product_id' || key == 'territory_id') {
            this.columnDefs.push({
              headerName: key, field: key, resizable: true, filter: true,hide:true
            });
          } else {
            this.columnDefs.push({ headerName: key, field: key, sortable: true, filter: true, lockPosition: true, cellStyle: {textAlign: "right"} });
          }
        }
      }
      this.loaded = true;
      this.context = { componentParent: this };

      this.frameworkComponents = {
        stockClickRenderer: StockClickRendererComponent
      };

    }else {
      this.preloader = false;
      this.empty_state = true;
    }
  }

  goToLogs(value, params) {
    this.sharedService.logData.distributor_id = params.distributor_id;
    this.sharedService.logData.product_id = params.product_id;
    this.sharedService.logData.territory_id = params.territory_id;

    if (value === 'stock') {
      this.router.navigate([this.apiService.redirect_url+"/stock_log_entry"]);
    }else if( value === 'cost') {
      this.router.navigate([this.apiService.redirect_url+"/cost_log_entry"]);
    }
  }


  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData);
    this.preloader = false;
    // this.gridApi.setDomLayout("autoHeight");
    window.addEventListener('resize', function() {
      setTimeout(function() {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        }else {
          params.columnApi.autoSizeColumns();
        }
      });
    });

    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    }else {
      params.columnApi.autoSizeColumns();
    }

    // this.gridColumnApi.autoSizeColumns();


    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  ngOnInit() {

    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.sharedService.setHeader('My Stock');
    this.renderer[this.collapse_disable == "true" ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {

    this.user_data.date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.user_data.last_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    localStorage.setItem('user_data', JSON.stringify(this.user_data));

    this.livestockreport();
  }

}
