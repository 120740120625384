import {Component, ElementRef, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DomSanitizer} from "@angular/platform-browser";
import {ToastrService} from "ngx-toastr";
import {SharedService} from "../../_services/shared_service";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-assign-vendor-popup',
  templateUrl: './assign_vendor_popup.component.html',
  styleUrls: ['./assign_vendor_popup.component.scss']

})

export class AssignVendorPopupComponent implements OnInit {

  @Output() vendorAssignedEvent = new EventEmitter<boolean>();
  assetDetails = {};
  vendorDetails: any = {};
  vendorList = [];
  assetList;
  totalAssets = 0;
  totalBrands = 0;
  totalQty = 0;
  assetIds = [];
  showPoDetails = false;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              public datePipe: DatePipe,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private domSanitizer: DomSanitizer,
              private toastr: ToastrService,
              private sharedService: SharedService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.assetList = dialogdata.rows;
    this.totalAssets = this.assetList.length;
    this.assetList.forEach(asset => {
      this.assetIds.push(asset.id);
      if (asset.qty) {
        this.totalQty += parseInt(asset.qty, 10);
      }
    });
    const unique_brands = this.sharedService.unique(this.assetList, ['attr_brand']);
    if (unique_brands) {
      this.totalBrands = unique_brands.length;
    }
    this.vendorDetails.ids =  this.assetIds;

    if (this.sharedService.ACLcheck('feature_assign_vendor_show_po')) {
      this.showPoDetails = true;
    } else {
      this.showPoDetails = false;
    }
    this.fetchVendors();
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  changeDate(dt) {
    this.vendorDetails.po_date = this.datePipe.transform(this.vendorDetails.po_date, 'yyyy-MM-dd');
  }

  fetchVendors() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      profile_name: 'Vendor'
    };
    this.apiService.post('/api/res_users/get_users_from_profile', paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.vendorList = res.results.data;
        }
      });
  }

  assignVendor() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = this.vendorDetails;
    this.apiService.post('/api/partner_assets/assign_vendor', paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success("Vendor Assigned Successfully");
          this.vendorAssignedEvent.emit(true);
          this.dialogRef.close();
        } else {
          this.toastr.error("Failed to Assign Vendor");
        }
      });
  }
}
