import {Component, OnInit, ElementRef, Renderer2, ViewChild} from '@angular/core';
import { ApiService} from "../_services/api.service";
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import {GridOptions,ChartType} from "@ag-grid-enterprise/all-modules";
import * as agCharts from 'ag-charts-community';
import {SharedService} from "../_services/shared_service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @ViewChild('chart1') chart1: ElementRef<HTMLElement>;
  @ViewChild('chart2') chart2: ElementRef<HTMLElement>;
  @ViewChild('chart3') chart3: ElementRef<HTMLElement>;
  @ViewChild('chart4') chart4: ElementRef<HTMLElement>;
  @ViewChild('chart5') chart5: ElementRef<HTMLElement>;
  @ViewChild('chart6') chart6: ElementRef<HTMLElement>;
  @ViewChild('chart7') chart7: ElementRef<HTMLElement>
  @ViewChild('chart8') chart8: ElementRef<HTMLElement>;
  @ViewChild('chart9') chart9: ElementRef<HTMLElement>;


  public collapse_disable;
  public categ_class = [];
  public checkedYr = false;
  user_data = {
    "date":null,
    "dc_id": 0,
    "settlement_id": null,
    "access_token": null,
    "url": null,
    "offset": null,
  };
  res_data;
  public dashDisplay = false;
  public myDate;
  public vansale_cards = false;
  public cardData = {
    'total_vans': 0,
    'loaded_out': 0,
    'deviation_count': 0,
    'settled': 0,
    'not_loaded': 0,
    'total_expense': 0,
    'unapproved_orders': '...',
    'cash_collected': '...',
    'pay_amount': '...',
    'credit_sales': '...'
  };
  public preloader = false;
  public role_features;
  public expense = false;
  public settlemnt_dash = false;
  public dc_dash = false;
  public rowData = [];
  public rowData2 = [];
  public rowData3 = [];
  public rowData4 = [];
  public rowData5 = [];
  public poData = [];
  public chart_show = false;
  private gridApi;
  private gridColumnApi;
  public context;
  public columnDefs = [];
  public columnDefs2 = [];
  public columnDefs3 = [];
  public columnDefs4 = [];
  public columnDefs5 = [];
  public columnDefs6 = [];
  public data;
  public data2;
  public data3;
  public data4;
  public data5;
  public datapo;
  public gridOptions: GridOptions;
  public gridOptions2: GridOptions;
  public gridOptions3: GridOptions;
  public gridOptions4: GridOptions;
  public gridOptions5: GridOptions;
  public gridOptions6: GridOptions;
  public gridOptionsPO: GridOptions;
  public cx: ChartType;
  public loaded = false;
  public loadedPO = false;
  public preloader1 = false;
  public preloaderPO = false;
  public empty_state = false;
  public empty_statePO = false;
  public loaded2 = false;
  public preloader2 = false;
  public empty_state2 = false;
  public loaded3 = false;
  public preloader3 = false;
  public empty_state3 = false;
  public loaded4 = false;
  public preloader4 = false;
  public empty_state4 = false;
  public loaded5 = false;
  public preloader5 = false;
  public empty_state5 = false;
  public browserRefresh = false;
  public subscription;
  public last_updated;
  public po_dash = false;
  public po_enable = false;
  public dms_dash = false;
  public mon_dict = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec'
  };
  public options1: any;
  public options2: any;
  public options3: any;
  public options4: any;
  public options5: any;
  public options6: any;
  public options7: any;
  public options8: any;
  public options9: any;
  public colors = ['#006400','#000080','#FF6262','#6495ED', '#DB7093', '#800080', '#B22222', '#696969', '#5F9EA0', '#BDB76B',
                   '#FFDAB9', '#FF69B4', '#F08080', '#8B0000','#90EE90', '#DEB887', '#BC8F8F', '#CD853F'];

  constructor(private elRef:ElementRef,
              private renderer: Renderer2,
              private apiService: ApiService,
              private datePipe: DatePipe,
              private sharedService: SharedService,
              private router: Router) {

    this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.user_data.date = this.myDate;
    this.user_data.offset = -1 * (new Date(this.user_data.date)).getTimezoneOffset();
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');
    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids;
    let inside_this = this;
    this.preloader = true;
    this.last_updated = localStorage.getItem('last_updated')

    // this.vanreport();

    this.gridOptions = <GridOptions>{ rowHeight: 28,
      rowStyle: { 'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left' },
      rowSelection: 'single',
      enableRangeSelection: true,
      enableCharts: true,
      chartThemes: ['ag-pastel-dark']
    };

    this.gridOptions2 = <GridOptions>{ rowHeight: 28,
      rowStyle: { 'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left' },
      rowSelection: 'single',
      enableRangeSelection: true,
      enableCharts: true
    };
    this.gridOptions4 = <GridOptions>{ rowHeight: 28,
      rowStyle: { 'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left' },
      rowSelection: 'single',
      enableRangeSelection: true,
      enableCharts: true
    };
    this.gridOptions5 = <GridOptions>{ rowHeight: 28,
      rowStyle: { 'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left' },
      rowSelection: 'single',
      enableRangeSelection: true,
      enableCharts: true
    };
    this.gridOptions6 = <GridOptions>{ rowHeight: 28,
      rowStyle: { 'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left' },
      rowSelection: 'single',
      enableRangeSelection: true,
      enableCharts: true
    };
    this.gridOptionsPO = <GridOptions>{ rowHeight: 28,
      rowStyle: { 'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left' },
      rowSelection: 'single',
      enableRangeSelection: true,
      enableCharts: true
    };

    let updated = this.role_features.filter(function (existed) {
      return 'module_po' === existed.name;
    });

    if (updated.length > 0) {
      this.po_dash = true;
    }

    updated = this.role_features.filter(function (existed) {
      return 'feature_expense' === existed.name;
    });

    if (updated.length > 0) {
      this.expense = true;
    }

    updated = this.role_features.filter(function (existed) {
      return 'feature_vansale_card' === existed.name;
    });

    if (updated.length > 0) {
      this.vansale_cards = true;
    }

    let expense = this.expense;

    updated = this.role_features.filter(function (existed) {
      return 'module_settelment_dashboard' === existed.name;
    });

    if (updated.length > 0) {
      this.settlemnt_dash = true;
    }

    updated = this.role_features.filter(function (existed) {
      return 'module_dc_dashboard' === existed.name;
    });

    if (updated.length > 0) {
      this.dc_dash = true;
    }

    updated = this.role_features.filter(function (existed) {
      return 'module_po' === existed.name;
    });

    if (updated.length > 0) {
      this.po_enable = true;
    }

    updated = this.role_features.filter(function (existed) {
      return 'feature_ceo_dashboard' === existed.name;
    });

    if (updated.length > 0) {
      this.dashDisplay = true;
    }

    updated = this.role_features.filter(function (existed) {
      return 'module_dms' === existed.name;
    });

    if (updated.length > 0) {
      this.dms_dash = true;
    }


      if (this.settlemnt_dash) {
        inside_this.user_data.settlement_id = JSON.parse(localStorage.getItem('user')).id;
        inside_this.res_data = inside_this.apiService.dashBoardSettleSummary(inside_this.user_data);

        inside_this.res_data.subscribe(res => {
            console.log(res);
            if (res.hasOwnProperty('results') && (res['results'].status == 200)) {
              if (res['results'].data.length > 0) {
                var data = res['results'].data[0];
                inside_this.cardData['total_vans'] = data.no_of_vans;
                inside_this.cardData['loaded_out'] = data.ordered_vans;
                inside_this.cardData['deviation_count'] = data.deviation_count;
                inside_this.cardData['settled'] = data.settled_vans;
                inside_this.cardData['not_loaded'] = parseInt(data.no_of_vans, 10) - parseInt(data.ordered_vans, 10);
                // inside_this.cardData['total_expense'] = data.expense_amt;
                inside_this.cardData['unapproved_orders'] = '...';
                inside_this.cardData['cash_collected'] ='...';
                inside_this.cardData['credit_sales'] = '...';
                inside_this.cardData['pay_amount'] = '...';
                // if (expense) {
                //   inside_this.cardData['pay_amount'] = data.unsettled_amount - parseInt(data.expense_amt, 10);
                // }else {
                //   inside_this.cardData['pay_amount'] = data.unsettled_amount;
                // }
              }
            }
            inside_this.preloader = false;
            inside_this.res_data = inside_this.apiService.dashBoardSettleSummary2(inside_this.user_data);

            inside_this.res_data.subscribe(res => {
              console.log(res);
              var data2 = res['results'].data[0];
              if (res.hasOwnProperty('results') && (res['results'].status == 200)) {
                if (res['results'].data.length > 0) {
                  inside_this.cardData['unapproved_orders'] = parseFloat(data2.unapp_order).toFixed(this.apiService.decimalPolicy);
                  inside_this.cardData['cash_collected'] = parseFloat(data2.cash_sales).toFixed(this.apiService.decimalPolicy);
                  inside_this.cardData['credit_sales'] = parseFloat(data2.credit_sales).toFixed(this.apiService.decimalPolicy);
                  inside_this.cardData['pay_amount'] = parseFloat(data2.unsettled_amount).toFixed(this.apiService.decimalPolicy);
                }
              }
            }, error => {
              console.log(error);
              inside_this.preloader = false;
            });
          },
          error => {
            console.log(error);
            inside_this.preloader = false;
          });
        if (this.po_dash) {
          this.res_data = this.apiService.poDash(inside_this.user_data);
          let poData = [];
          let mtd = 0, today = 0, mtd_po = 0, today_po = 0;
          this.res_data.subscribe(res => {
            console.log(res);
            if (res.hasOwnProperty('results') && (res['results'].status == 200) && res['results'].data.length > 0) {
              res['results'].data.forEach(function (data) {
                var pushData = {
                  "Customer": data.customer,
                  "Date": data.date,
                  "Received": parseFloat(data.pe_amount),
                  "PO": parseFloat(data.po_amount)
                };
                mtd += parseFloat(data.pe_amount);
                today = parseFloat(data.pe_amount);
                mtd_po += parseFloat(data.po_amount);
                today_po = parseFloat(data.po_amount);
                poData.push(pushData);
              });
              this.poData = poData;
              this.cardData['mtd'] = mtd.toFixed(this.apiService.decimalPolicy);
              this.cardData['today'] = today.toFixed(this.apiService.decimalPolicy);
              this.cardData['mtd_po'] = mtd_po.toFixed(this.apiService.decimalPolicy);
              this.cardData['today_po'] = today_po.toFixed(this.apiService.decimalPolicy);
            }
          }, error => {
            console.log(error);
          });
        }


      }else if (this.dc_dash) {
        inside_this.user_data.dc_id  = JSON.parse(localStorage.getItem('user')).partner_id;
        inside_this.res_data  = inside_this.apiService.dashBoardDCSummary(inside_this.user_data);

        inside_this.res_data.subscribe(res => {
            console.log(res);
            if (res.hasOwnProperty('results') && (res['results'].status == 200)) {
              if (res['results'].data.length > 0) {
                var data = res['results'].data[0];
                inside_this.cardData['total_vans'] = data.no_of_vans;
                inside_this.cardData['loaded_out'] = data.ordered_vans;
                inside_this.cardData['deviation_count'] = data.deviation_count;
                inside_this.cardData['settled'] = data.settelled_vans;
                inside_this.cardData['not_loaded'] = parseInt(data.no_of_vans, 10) - parseInt(data.ordered_vans, 10);
                // inside_this.cardData['total_expense'] = data.expense_amt;
                inside_this.cardData['unapproved_orders'] = data.unapp_order;
                inside_this.cardData['cash_collected'] = data.cash_sales;
                inside_this.cardData['credit_sales'] = data.credit_sales;
                // if (expense) {
                //   inside_this.cardData['pay_amount'] = data.cash_sales - parseInt(data.expense_amt, 10);
                // }else {
                //   inside_this.cardData['pay_amount'] = data.cash_sales;
                // }
              }
            }
            inside_this.preloader = false;
          },
          error => {
            console.log(error);
            inside_this.preloader = false;
          });
      }else {
        inside_this.preloader = false;
      }

      if(this.dms_dash) {
        this.res_data = this.apiService.dmsDash(this.user_data);
        this.res_data.subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res['results'].status == 200) && res['results'].data.length > 0) {
            this.cardData['s_order'] = res['results'].data[0].s_order;
            this.cardData['p_order'] = res['results'].data[0].p_order;
            this.cardData['inv_s_order'] = res['results'].data[0].inv_s_order;
            this.cardData['inv_p_order'] = res['results'].data[0].inv_p_order;
          }
        });
      }

    // this.user_data.dc_id = JSON.parse(localStorage.getItem('user')).partner_id;
  }

  expandChart() {

    this.chart_show = !this.chart_show;
    if (this.chart_show) {
      this.vanreport();
    }
  }

  changeToYr() {
    this.checkedYr = !this.checkedYr;
  }

  vanreport() {
    let rowdata = [];
    this.preloader1 = true;
    this.empty_state = false;
    let growth_yr;
    let growth_mn;
    let prvs_sale;
    let prvs_month = 0;
    let mon_dict = this.mon_dict;
    let logged_in =  (localStorage.getItem('already_logged') == "true");
    if (!logged_in) {
      localStorage.setItem('already_logged', true.toString());
    }

    if (logged_in) {
      this.rowData = JSON.parse(localStorage.getItem('chart1_data'));
      this.rowData2 = JSON.parse(localStorage.getItem('chart2_data'));
      this.rowData3 = JSON.parse(localStorage.getItem('chart3_data'));
      this.rowData4 = JSON.parse(localStorage.getItem('chart4_data'));
      this.rowData5 = JSON.parse(localStorage.getItem('chart5_data'));
      this.poData = JSON.parse(localStorage.getItem('chart8_data'));
      let categ_color = JSON.parse(localStorage.getItem('categ_color'));
      this.categ_class = JSON.parse(localStorage.getItem('categ_class'))

      this.chart1.nativeElement.innerHTML = '';
      this.chart2.nativeElement.innerHTML = '';
      this.chart3.nativeElement.innerHTML = '';
      this.chart4.nativeElement.innerHTML = '';
      this.chart5.nativeElement.innerHTML = '';
      this.chart6.nativeElement.innerHTML = ''
      this.chart7.nativeElement.innerHTML = '';
      if (this.po_enable) this.chart8.nativeElement.innerHTML = '';

      let el: HTMLElement = this.chart1.nativeElement;
      this.options1 = {
        container: el,
        data: this.rowData,
        title: {
          text: 'Previous Year Sale Vs Current Year Sale',
          fontSize: 13
        },
        series: [{
          type: 'column',
          xKey: 'Month',
          yKeys: ['Prvs Sale', 'Crnt Sale'],
          yNames: ['Last Year', 'This Year'],
          fills: this.colors,
          grouped: true,
        },
          {
            type: 'line',
            xKey: 'Month',
            yKey: 'Growth',
            stroke: '#FF6262',
            strokeWidth: 5,
            marker: {
              size: 10,
              shape: 'circle',
              fill: '#FF6262',
            },
          }],
        axes: [
          {
            type: 'category',
            position: 'bottom',
            label: {
              fontSize: 10,
            }
          },
          {
            type: 'number',
            position: 'left',
            keys: ['Prvs Sale', 'Crnt Sale'],
            title: {
              text: 'Sales',
            },
            label: {
              fontSize: 10,
              formatter: function (params) {
                return params.value / 100000 + 'L';
              },
            },
          },
          {
            type: 'number',
            position: 'right',
            keys: ['Growth'],
            title: {
              text: 'Growth rate',
            },
            label: {
              fontSize: 10,
              formatter: function (params) {
                return params.value;
              },
            },
          },
        ],
        legend: {
          position: 'top',
          fontSize: 10,
        },
        theme: 'ag-solar',
      };
      agCharts.AgChart.create(this.options1);
      let el7: HTMLElement = this.chart7.nativeElement;
      this.options7 = {
        container: el7,
        data: this.rowData,
        title: {
          text: 'Current Year Sale',
          fontSize: 13
        },
        series: [{
          type: 'column',
          xKey: 'Month',
          yKeys: ['Crnt Sale'],
          yNames: ['This Year'],
          fills: ['#000080'],
          grouped: true,
        },
          {
            type: 'line',
            xKey: 'Month',
            yKey: 'Mnt Growth',
            yname: 'Growth',
            stroke: '#FF6262',
            strokeWidth: 5,
            marker: {
              size: 10,
              shape: 'circle',
              fill: '#FF6262',
            },
          }],
        axes: [
          {
            type: 'category',
            position: 'bottom',
            label: {
              fontSize: 10,
            }
          },
          {
            type: 'number',
            position: 'left',
            keys: ['Crnt Sale'],
            title: {
              text: 'Sales',
            },
            label: {
              fontSize: 10,
              formatter: function (params) {
                return params.value / 100000 + 'L';
              },
            },
          },
          {
            type: 'number',
            position: 'right',
            keys: ['Mnt Growth'],
            title: {
              text: 'Growth rate',
            },
            label: {
              fontSize: 10,
              formatter: function (params) {
                return params.value;
              },
            },
          },
        ],
        legend: {
          position: 'top',
          fontSize: 10,
        },
        theme: 'ag-solar',
      };
      agCharts.AgChart.create(this.options7);
      this.preloader1 = false;
      let el2: HTMLElement = this.chart5.nativeElement;
      this.options6 = {
        container: el2,
        data: this.rowData2,
        title: {
          text: 'DC wise Sales',
          fontSize: 13,
        },
        series: [{
          type: 'pie',
          labelKey: 'DC',
          angleKey: 'Sale',
          fills: this.colors
        }],
        theme: 'ag-vivid',
      };
      agCharts.AgChart.create(this.options6);
      this.preloader2 = false;

      let el3: HTMLElement = this.chart2.nativeElement;
      this.options2 = {
        container: el3,
        data: this.rowData3,
        title: {
          text: 'Target Vs Achievement',
          fontSize: 13,
        },
        series: [{
          type: 'bar',
          xKey: 'Region',
          yKeys: ['Target', 'Sale'],
          normalizedTo: 100,
          fills: ['#FF6262', '#000080'],
        }],
        axes: [
          {
            type: 'category',
            position: 'left',
            label: {
              fontSize: 10,
            }
          },
          {
            type: 'number',
            position: 'bottom',
            label: {
              fontSize: 10,
            }
          },
        ],
        theme: 'ag-vivid',
      };
      agCharts.AgChart.create(this.options2);
      let el4: HTMLElement = this.chart4.nativeElement;
      this.options4 = {
        container: el4,
        data: this.rowData3,
        title: {
          text: 'Region wise Sales',
          fontSize: 13
        },
        series: [{
          type: 'pie',
          labelKey: 'Region',
          angleKey: 'Sale',
          fills: this.colors,
          innerRadiusOffset: -40,
          label: {
            enabled: false,
          },
        }],
        legend: {
          fontSize: 10
        },
        theme: 'ag-vivid',
      };
      agCharts.AgChart.create(this.options4);
      this.preloader3 = false;

      let el5: HTMLElement = this.chart3.nativeElement;
      this.options3 = {
        container: el5,
        data: this.rowData4,
        title: {
          enabled: true,
          text: 'Sales Spread',
          fontSize: 11
        },
        series: [
          {
            type: 'scatter',
            xKey: 'Qty',
            xName: 'Quantity',
            yKey: 'Sale',
            yName: 'Sales Spread',
            sizeKey: 'Count',
            sizeName: 'Count Of Products',
            labelKey: 'Category',
            labelName: 'Category',
            fontSize: 8,
            marker: {
              size: 15,
              maxSize: 40,
              formatter: function (params) {
                return {
                  fill: categ_color[params.datum['Category']],
                  strokeWidth: 0,
                };
              },
            },
            fillOpacity: 0.9,
          },
        ],
        axes: [
          {
            position: 'bottom',
            type: 'number',
            title: {
              text: 'Quantity',
              fontSize: 10
            },
            label: {
              fontSize: 7,
              formatter: function (params) {
                return params.value / 1000 + 'K';
              },
            },
          },
          {
            position: 'left',
            type: 'number',
            title: {
              text: 'Sales',
              fontSize: 10
            },
            label: {
              fontSize: 10,
              formatter: function (params) {
                return params.value / 100000 + 'L';
              },
            },
          },
        ],
        legend: {
          enabled: false
        }
      };
      agCharts.AgChart.create(this.options3);
      this.preloader4 = false;

      let el6: HTMLElement = this.chart6.nativeElement;
      this.options5 = {
        container: el6,
        data: this.rowData5,
        title: {
          text: 'Top 5 SKU',
          fontSize: 13,
        },
        series: [{
          type: 'column',
          xKey: 'Product',
          yKeys: ['Sale'],
          fills: ['#000080']
        }],
        axes: [
          {
            type: 'category',
            position: 'bottom',
            label: {
              fontSize: 10,
              fontWeight: 500,
              rotation: 30,
            },
          },
          {
            type: 'number',
            position: 'left',
            keys: ['Sale'],
            title: {
              text: 'Sales',
            },
            label: {
              fontSize: 10,
              formatter: function (params) {
                return params.value / 100000 + 'L';
              },
            },
          }
        ],
        legend: {
          enabled: false,
        },
        theme: 'ag-solar',
      };
      agCharts.AgChart.create(this.options5);
      this.preloader5 = false;

      let el8: HTMLElement = this.chart8.nativeElement;
      this.options8 = {
        container: el8,
        data: this.poData,
        title: {
          text: 'PO Day Vs Amount',
          fontSize: 13,
        },
        series: [{
          type: 'line',
          xKey: 'Date',
          yKey: 'Received',
          yname: 'Received',
          stroke: '#FF6262',
          strokeWidth: 5,
          marker: {
            size: 10,
            shape: 'circle',
            fill: '#FF6262',
          },
        }, {
          type: 'line',
          xKey: 'Date',
          yKey: 'PO',
          yname: 'PO',
          stroke: '#006400',
          strokeWidth: 5,
          marker: {
            size: 10,
            shape: 'circle',
            fill: '#006400',
          },
        }],
        axes: [
          {
            type: 'category',
            position: 'bottom',
            label: {
              fontSize: 10,
              fontWeight: 500,
              rotation: 30,
            },
          },
          {
            type: 'number',
            position: 'left',
            keys: ['Received', 'PO'],
            title: {
              text: 'Amount',
            },
            label: {
              fontSize: 10,
              formatter: function (params) {
                return params.value / 100000 + 'L';
              },
            },
          }
        ],
        legend: {
          enabled: true,
        },
        theme: 'ag-solar',
      };
      agCharts.AgChart.create(this.options8);
      this.preloaderPO = false;

      let el9: HTMLElement = this.chart9.nativeElement;
      this.options9 = {
        container: el9,
        data: this.poData,
        title: {
          text: 'PO Supplier Vs Mtd',
          fontSize: 13,
        },
        series: [{
          type: 'column',
          xKey: 'Supplier',
          yKeys: ['Amount'],
          yNames: ['MTD'],
          fills: this.colors,
        }],
        axes: [
          {
            type: 'category',
            position: 'bottom',
            label: {
              fontSize: 10,
              fontWeight: 500,
              rotation: 30,
            },
          },
          {
            type: 'number',
            position: 'left',
            keys: ['Amount'],
            title: {
              text: 'MTD',
            },
            label: {
              fontSize: 10,
              formatter: function (params) {
                return params.value / 100000 + 'L';
              },
            },
          }
        ],
        legend: {
          enabled: false,
        },
        theme: 'ag-solar',      };
      agCharts.AgChart.create(this.options9);
      this.preloaderPO = false;
    }else {
      this.preloader2 = true;
      this.last_updated = new Date().toLocaleTimeString();
      localStorage.setItem('last_updated', this.last_updated);

      this.res_data = this.apiService.dashCeo(this.user_data);
      this.res_data.subscribe(res => {

        console.log(res);
        let inthis = this;
        if (res.hasOwnProperty('results') && (res['results'].status == 200)) {
          if (res['results'].data.length > 0) {
            res['results'].data.forEach(function (data) {
              // prvs_sale = (Math.floor(Math.random() * (180000000 - 20000000) + 20000000)).toFixed(this.apiService.decimalPolicy);
              growth_yr = (((parseFloat(data.crnt) - parseFloat(data.prvs)) / parseFloat(data.prvs)) * 100).toFixed(inthis.apiService.decimalPolicy)
              growth_mn = (((parseFloat(data.crnt) - prvs_month) / prvs_month) * 100).toFixed(inthis.apiService.decimalPolicy);
              prvs_month = parseFloat(data.crnt);
              var pushData = {
                "Month": mon_dict[parseInt(data.month, 10)],
                "Crnt Sale": parseFloat(data.crnt),
                "Prvs Sale": parseFloat(data.prvs),
                "Growth": parseFloat(growth_yr),
                "Mnt Growth": parseFloat(growth_mn),
              };
              rowdata.push(pushData);
            });
          }else {
            var pushData = {
              "Month": '',
              "Crnt Sale": 0,
              "Prvs Sale": 0,
              "Growth": 0,
              "Mnt Growth": 0,
            };
            rowdata.push(pushData);
          }

          this.data = rowdata[0];
          this.rowData = rowdata;

          localStorage.setItem('chart1_data', JSON.stringify(this.rowData));

          let el: HTMLElement = this.chart1.nativeElement;
          this.options1 = {
            container: el,
            data: this.rowData,
            title: {
              text: 'Previous Year Sale Vs Current Year Sale',
              fontSize: 13
            },
            series: [{
              type: 'column',
              xKey: 'Month',
              yKeys: ['Prvs Sale', 'Crnt Sale'],
              yNames: ['Last Year', 'This Year'],
              fills: this.colors,
              grouped: true,
            },
              {
                type: 'line',
                xKey: 'Month',
                yKey: 'Growth',
                stroke: '#FF6262',
                strokeWidth: 5,
                marker: {
                  size: 10,
                  shape: 'circle',
                  fill: '#FF6262',
                },
              }],
            axes: [
              {
                type: 'category',
                position: 'bottom',
                label: {
                  fontSize: 10,
                }
              },
              {
                type: 'number',
                position: 'left',
                keys: ['Prvs Sale', 'Crnt Sale'],
                title: {
                  text: 'Sales',
                },
                label: {
                  fontSize: 10,
                  formatter: function (params) {
                    return params.value / 100000 + 'L';
                  },
                },
              },
              {
                type: 'number',
                position: 'right',
                keys: ['Growth'],
                title: {
                  text: 'Growth rate',
                },
                label: {
                  fontSize: 10,
                  formatter: function (params) {
                    return params.value;
                  },
                },
              },
            ],
            legend: {
              position: 'top',
              fontSize: 10,
            },
            theme: 'ag-solar',
          };
          agCharts.AgChart.create(this.options1);

          localStorage.setItem('chart7_data', JSON.stringify(this.rowData));

          let el7: HTMLElement = this.chart7.nativeElement;
          this.options7 = {
            container: el7,
            data: this.rowData,
            title: {
              text: 'Current Year Sale',
              fontSize: 13
            },
            series: [{
              type: 'column',
              xKey: 'Month',
              yKeys: ['Crnt Sale'],
              yNames: ['This Year'],
              fills: ['#000080'],
              grouped: true,
            },
              {
                type: 'line',
                xKey: 'Month',
                yKey: 'Mnt Growth',
                yname: 'Growth',
                stroke: '#FF6262',
                strokeWidth: 5,
                marker: {
                  size: 10,
                  shape: 'circle',
                  fill: '#FF6262',
                },
              }],
            axes: [
              {
                type: 'category',
                position: 'bottom',
                label: {
                  fontSize: 10,
                }
              },
              {
                type: 'number',
                position: 'left',
                keys: ['Crnt Sale'],
                title: {
                  text: 'Sales',
                },
                label: {
                  fontSize: 10,
                  formatter: function (params) {
                    return params.value / 100000 + 'L';
                  },
                },
              },
              {
                type: 'number',
                position: 'right',
                keys: ['Mnt Growth'],
                title: {
                  text: 'Growth rate',
                },
                label: {
                  fontSize: 10,
                  formatter: function (params) {
                    return params.value;
                  },
                },
              },
            ],
            legend: {
              position: 'top',
              fontSize: 10,
            },
            theme: 'ag-solar',
          };
          agCharts.AgChart.create(this.options7);
          this.preloader1 = false;

          // for (var key in this.data) {
          //   if (this.data.hasOwnProperty(key)) {
          //     if (key == 'Crnt Sale' || key == 'Prvs Sale' || key == 'Growth') {
          //       this.columnDefs.push({
          //         headerName: key, field: key, sortable: true, filter: true, resizable: true,width:100,
          //         enableValue: true
          //       });
          //     }else if (key == 'Month' ) {
          //       this.columnDefs.push({
          //         headerName: key, field: key, sortable: true, filter: true, resizable: true,width:120
          //       });
          //     }
          //   }
          // }
          // // this.loaded = true;
        } else {
          this.preloader1 = false;
          this.empty_state = true;
        }
      }, error => {
        console.log(error);
      });

      let rowdata2 = [];
      this.loaded2 = false;
      this.empty_state2 = false;

      this.res_data = this.apiService.dashCeoMtd(this.user_data);
      this.res_data.subscribe(res => {

        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status == 200)) {
          if (res['results'].data.length > 0) {
            res['results'].data.forEach(function (data) {
              var pushData = {
                "DC": data.dc,
                "Sale": parseFloat(data.sale)
              };
              rowdata2.push(pushData);
            });
          }else {
            var pushData = {
              "DC": '',
              "Sale": 0
            };
            rowdata2.push(pushData);
          }

          this.data2 = rowdata2[0];
          this.rowData2 = rowdata2;

          localStorage.setItem('chart2_data', JSON.stringify(this.rowData2));

          let el2: HTMLElement = this.chart5.nativeElement;
          this.options6 = {
            container: el2,
            data: this.rowData2,
            title: {
              text: 'DC wise Sales',
              fontSize: 13,
            },
            series: [{
              type: 'pie',
              labelKey: 'DC',
              angleKey: 'Sale',
              fills: this.colors
            }],
            theme: 'ag-vivid',
          };
          agCharts.AgChart.create(this.options6);
          this.preloader2 = false;

          // for (var key in this.data2) {
          //   if (this.data2.hasOwnProperty(key)) {
          //     if (key == 'Sale' ) {
          //       this.columnDefs2.push({
          //         headerName: key, field: key, sortable: true, filter: true, resizable: true,width:100,
          //         chartDataType: 'series', type: 'numericColumn', aggFunc: 'sum',enableValue: true
          //       });
          //     }else if (key == 'State' ) {
          //       this.columnDefs2.push({
          //         headerName: key, field: key, sortable: true, filter: true, resizable: true,width:120,
          //         chartDataType: 'category'
          //       });
          //     }
          //   }
          // }
          // this.loaded2 = true;
        } else {
          this.preloader2 = false;
          this.empty_state2 = true;
        }
      }, error => {
        console.log(error);
      });

      let rowData3 = [];

      this.res_data = this.apiService.dashRegion(this.user_data);
      this.res_data.subscribe(res => {


        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status == 200)) {
          if (res['results'].data.length > 0) {
            res['results'].data.forEach(function (data) {
              var pushData = {
                "Region": data.region,
                "Sale": parseFloat(data.sale),
                "Target": parseFloat(data.target)
              };
              rowData3.push(pushData);
            });
          }else {
            var pushData = {
              "Region": '',
              "Sale": 0,
              "Target": 0
            };
            rowData3.push(pushData);
          }

          this.data3 = rowData3[0];
          this.rowData3 = rowData3;

          localStorage.setItem('chart3_data', JSON.stringify(this.rowData3));

          let el3: HTMLElement = this.chart2.nativeElement;
          this.options2 = {
            container: el3,
            data: this.rowData3,
            title: {
              text: 'Target Vs Achievement',
              fontSize: 13,
            },
            series: [{
              type: 'bar',
              xKey: 'Region',
              yKeys: ['Target', 'Sale'],
              normalizedTo: 100,
              fills: ['#FF6262','#000080'],
            }],
            axes: [
              {
                type: 'category',
                position: 'left',
                label: {
                  fontSize: 10,
                }
              },
              {
                type: 'number',
                position: 'bottom',
                label: {
                  fontSize: 10,
                }
              },
            ],
            theme: 'ag-vivid',
          };
          agCharts.AgChart.create(this.options2);
          let el4: HTMLElement = this.chart4.nativeElement;
          this.options4 = {
            container: el4,
            data: this.rowData3,
            title: {
              text: 'Region wise Sales',
              fontSize: 13
            },
            series: [{
              type: 'pie',
              labelKey: 'Region',
              angleKey: 'Sale',
              fills: this.colors,
              innerRadiusOffset: -40,
              label: {
                enabled: false,
              },
            }],
            legend: {
              fontSize: 10
            },
            theme: 'ag-vivid',
          };
          agCharts.AgChart.create(this.options4);
          this.preloader3 = false;

          // for (var key in this.data3) {
          //   if (this.data3.hasOwnProperty(key)) {
          //     if (key == 'Sale' || key == 'Target') {
          //       this.columnDefs4.push({
          //         headerName: key, field: key, sortable: true, filter: true, resizable: true,width:100,
          //         chartDataType: 'series', type: 'numericColumn', aggFunc: 'sum',enableValue: true
          //       });
          //     }else if (key == 'Region') {
          //       this.columnDefs4.push({
          //         headerName: key, field: key, sortable: true, filter: true, resizable: true,width:120,
          //         chartDataType: 'category'
          //       });
          //     }
          //   }
          // }
          // this.loaded3 = true;
        } else {
          this.preloader3 = false;
          this.empty_state3 = true;
        }
      }, error => {
        console.log(error);
      });

      let rowData4 = [];
      let categ_color = {};
      let categ_class = [];
      let i = 0;
      let colors = this.colors;

      this.res_data = this.apiService.dashCateg(this.user_data);
      this.res_data.subscribe(res => {


        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status == 200)) {
          if (res['results'].data.length > 0) {
            res['results'].data.forEach(function (data) {
              var pushData = {
                "Category": data.category,
                "Sale": parseFloat(data.sale),
                "Qty": parseFloat(data.qty),
                "Count": parseInt(data.count, 10)
              };
              categ_color[data.category] = colors[i];
              categ_class.push({
                "name": data.category,
                "class": 'c' + i + '-class'
              });
              i++;
              rowData4.push(pushData);
            });
          }else {
            var pushData = {
              "Category": '',
              "Sale": 0,
              "Qty": 0,
              "Count": 0
            };
            rowData4.push(pushData);
          }
          this.categ_class = categ_class;
          this.data4 = rowData4[0];
          this.rowData4 = rowData4;

          localStorage.setItem('chart4_data', JSON.stringify(this.rowData4));
          localStorage.setItem('categ_color', JSON.stringify(categ_color));
          localStorage.setItem('categ_class', JSON.stringify(categ_class));

          let el5: HTMLElement = this.chart3.nativeElement;
          this.options3 = {
            container: el5,
            data: this.rowData4,
            title: {
              enabled: true,
              text: 'Sales Spread',
              fontSize: 11
            },
            series: [
              {
                type: 'scatter',
                xKey: 'Qty',
                xName: 'Quantity',
                yKey: 'Sale',
                yName: 'Sales Spread',
                sizeKey: 'Count',
                sizeName: 'Count Of Products',
                labelKey: 'Category',
                labelName: 'Category',
                fontSize: 8,
                marker: {
                  size: 15,
                  maxSize: 40,
                  formatter: function (params) {
                    return {
                      fill: categ_color[params.datum['Category']],
                      strokeWidth: 0,
                    };
                  },
                },
                fillOpacity: 0.9,
              },
            ],
            axes: [
              {
                position: 'bottom',
                type: 'number',
                title: {
                  text: 'Quantity',
                  fontSize: 10
                },
                label: {
                  fontSize: 7,
                  formatter: function (params) {
                    return params.value / 1000 + 'K';
                  },
                },
              },
              {
                position: 'left',
                type: 'number',
                title: {
                  text: 'Sales',
                  fontSize: 10
                },
                label: {
                  fontSize: 10,
                  formatter: function (params) {
                    return params.value / 100000 + 'L';
                  },
                },
              },
            ],
            legend: {
              enabled: false
            }
          };
          agCharts.AgChart.create(this.options3);
          this.preloader4 = false;

          // for (var key in this.data4) {
          //   if (this.data4.hasOwnProperty(key)) {
          //     if (key == 'Sale' || key == 'Qty' || key == 'Count') {
          //       this.columnDefs5.push({
          //         headerName: key, field: key, sortable: true, filter: true, resizable: true,width:100,
          //         chartDataType: 'series', type: 'numericColumn', aggFunc: 'sum',enableValue: true
          //       });
          //     }else if (key == 'Category') {
          //       this.columnDefs5.push({
          //         headerName: key, field: key, sortable: true, filter: true, resizable: true,width:120,
          //         chartDataType: 'category'
          //       });
          //     }
          //   }
          // }
          // this.loaded4 = true;
        } else {
          this.preloader4 = false;
          this.empty_state4 = true;
        }
      }, error => {
        console.log(error);
      });

      let rowData5 = [];

      this.res_data = this.apiService.dashProd(this.user_data);
      this.res_data.subscribe(res => {


        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status == 200)) {
          if (res['results'].data.length > 0) {
            res['results'].data.forEach(function (data) {
              var pushData = {
                "Product": data.product,
                "Sale": parseFloat(data.sale)
              };
              rowData5.push(pushData);
            });
          }else {
            var pushData = {
              "Product": '',
              "Sale": 0
            };
            rowData5.push(pushData);
          }

          this.data5 = rowData5[0];
          this.rowData5 = rowData5;

          localStorage.setItem('chart5_data', JSON.stringify(this.rowData5));

          let el6: HTMLElement = this.chart6.nativeElement;
          this.options5 = {
            container: el6,
            data: this.rowData5,
            title: {
              text: 'Top 5 SKU',
              fontSize: 13,
            },
            series: [{
              type: 'column',
              xKey: 'Product',
              yKeys: ['Sale'],
              fills: ['#000080']
            }],
            axes: [
              {
                type: 'category',
                position: 'bottom',
                label: {
                  fontSize: 10,
                  fontWeight: 500,
                  rotation: 30,
                },
              },
              {
                type: 'number',
                position: 'left',
                keys: ['Sale'],
                title: {
                  text: 'Sales',
                },
                label: {
                  fontSize: 10,
                  formatter: function (params) {
                    return params.value / 100000 + 'L';
                  },
                },
              }
            ],
            legend: {
              enabled: false,
            },
            theme: 'ag-solar',
          };
          agCharts.AgChart.create(this.options5);
          this.preloader5 = false;

          // for (var key in this.data5) {
          //   if (this.data5.hasOwnProperty(key)) {
          //     if (key == 'Sale') {
          //       this.columnDefs6.push({
          //         headerName: key, field: key, sortable: true, filter: true, resizable: true,width:100,
          //         chartDataType: 'series', type: 'numericColumn', aggFunc: 'sum',enableValue: true
          //       });
          //     }else if (key == 'Product') {
          //       this.columnDefs6.push({
          //         headerName: key, field: key, sortable: true, filter: true, resizable: true,width:120,
          //         chartDataType: 'category'
          //       });
          //     }
          //   }
          // }
          // this.loaded5 = true;
        } else {
          this.preloader5 = false;
          this.empty_state5 = true;
        }
      }, error => {
        console.log(error);
      });

      this.loadedPO = false;
      this.empty_statePO = false;
      this.datapo = this.poData[0];

      localStorage.setItem('chart8_data', JSON.stringify(this.poData));

      let el8: HTMLElement = this.chart8.nativeElement;
      this.options8 = {
        container: el8,
        data: this.poData,
        title: {
          text: 'PO Day Vs Amount',
          fontSize: 13,
        },
        series: [{
          type: 'line',
          xKey: 'Date',
          yKey: 'Received',
          yname: 'Received',
          stroke: '#FF6262',
          strokeWidth: 5,
          marker: {
            size: 10,
            shape: 'circle',
            fill: '#FF6262',
          },
        }, {
          type: 'line',
          xKey: 'Date',
          yKey: 'PO',
          yname: 'PO',
          stroke: '#006400',
          strokeWidth: 5,
          marker: {
            size: 10,
            shape: 'circle',
            fill: '#006400',
          },
        }],
        axes: [
          {
            type: 'category',
            position: 'bottom',
            label: {
              fontSize: 10,
              fontWeight: 500,
              rotation: 30,
            },
          },
          {
            type: 'number',
            position: 'left',
            keys: ['Received', 'PO'],
            title: {
              text: 'Amount',
            },
            label: {
              fontSize: 10,
              formatter: function (params) {
                return params.value / 100000 + 'L';
              },
            },
          }
        ],
        legend: {
          enabled: true,
        },
        theme: 'ag-solar',
      };
      agCharts.AgChart.create(this.options8);
      this.preloaderPO = false;

      let el9: HTMLElement = this.chart9.nativeElement;
      this.options9 = {
        container: el9,
        data: this.poData,
        title: {
          text: 'PO Supplier Vs Mtd',
          fontSize: 13,
        },
        series: [{
            type: 'column',
            xKey: 'Supplier',
            yKeys: ['Amount'],
            yNames: ['MTD'],
            fills: this.colors,
        }],
        axes: [
          {
            type: 'category',
            position: 'bottom',
            label: {
              fontSize: 10,
              fontWeight: 500,
              rotation: 30,
            },
          },
          {
            type: 'number',
            position: 'left',
            keys: ['Amount'],
            title: {
              text: 'MTD',
            },
            label: {
              fontSize: 10,
              formatter: function (params) {
                return params.value / 100000 + 'L';
              },
            },
          }
        ],
        legend: {
          enabled: false,
        },
        theme: 'ag-solar',      };
      agCharts.AgChart.create(this.options9);
      this.preloaderPO = false;
    }
  }

  onChart9() {
    let el9: HTMLElement = this.chart9.nativeElement;
    this.cx = <ChartType>'groupedColumn';
    let chart_params9 = {
      cellRange: {
        rowStartIndex: 0,
        rowEndIndex: 4,
        columns: ['Supplier', 'Amount'],
      },
      chartThemeOverrides: {
        common: {
          title: {
            enabled: true,
            text: 'PO Supplier Vs MTD',
          },
          legend: {
            enabled: false,
          },
        },
      },
      chartType: this.cx,
      chartThemeName: 'ag-pastel',
      aggFunc: 'sum',
      chartContainer: el9,
      suppressChartRanges: true,
    };

    this.gridOptionsPO.api.createRangeChart(chart_params9);
  }

  onChart8() {
    let el8: HTMLElement = this.chart8.nativeElement;
    this.cx = <ChartType>'groupedColumn';
    let chart_params8 = {
      cellRange: {
        rowStartIndex: 0,
        rowEndIndex: 4,
        columns: ['Date', 'Amount', 'PO Amount'],
      },
      chartThemeOverrides: {
        common: {
          title: {
            enabled: true,
            text: 'PO Day Vs Amount',
          },
          legend: {
            enabled: true,
          },
        },
      },
      chartType: this.cx,
      chartThemeName: 'ag-pastel',
      aggFunc: 'sum',
      chartContainer: el8,
      suppressChartRanges: true,
    };

    this.gridOptionsPO.api.createRangeChart(chart_params8);
  }

  onChart1() {
    let el: HTMLElement = this.chart1.nativeElement;
    this.cx = <ChartType>'groupedColumn';
    let chart_params = {
      cellRange: {
        columns: ['Month', 'Crnt Sale', 'Prvs Sale', 'Growth'],
        rowStartIndex: null,
        rowEndIndex: null
      },
      chartType: this.cx,
      // aggFunc: 'sum',
      chartContainer: el,
      suppressChartRanges: true,
      // chartThemeName: 'ag-pastel-dark'
    };

    this.gridOptions.api.createRangeChart(chart_params);
  }

  onChart2() {
    let el: HTMLElement = this.chart2.nativeElement;
    this.cx = <ChartType>'normalizedBar';
    let chart_params2 = {
      cellRange: {
        columns: ['Region', 'Target', 'Sale'],
        rowStartIndex: null,
        rowEndIndex: null
      },
      chartType: this.cx,
      chartThemeName: 'ag-solar',
      aggFunc: 'sum',
      chartContainer: el,
      chartThemeOverrides: {
        common: {
          title: {
            enabled: true,
            text: 'Bronze Medal by Country',
          },
          legend: {
            enabled: false,
          },
        },
      },
      suppressChartRanges: true
    };

    this.gridOptions4.api.createRangeChart(chart_params2);
  }

  onChart3() {
    let el: HTMLElement = this.chart3.nativeElement;
    this.cx = <ChartType>'bubble';
    let chart_params3 = {
      cellRange: {
        columns: ['Category', 'Qty', 'Sale', 'Count'],
        rowStartIndex: null,
        rowEndIndex: null
      },
      chartType: this.cx,
      chartThemeName: 'ag-pastel',
      aggFunc: 'sum',
      chartContainer: el,
      suppressChartRanges: true,
      chartThemeOverrides: {
        common: {
          title: {
            enabled: true,
            text: 'Bronze Medal by Country',
          },
          legend: {
            enabled: false,
          },
        },
      },
    };

    this.gridOptions5.api.createRangeChart(chart_params3);
  }

  onChart4() {
    let el: HTMLElement = this.chart4.nativeElement;
    this.cx = <ChartType>'pie';
    let chart_params4 = {
      cellRange: {
        columns: ['Region', 'Sale'],
        rowStartIndex: null,
        rowEndIndex: null
      },
      chartType: this.cx,
      chartThemeName: 'ag-pastel',
      chartThemeOverrides: {
        common: {
          title: {
            enabled: true,
            text: 'Bronze Medal by Country',
          },
          legend: {
            enabled: false,
          },
        },
      },
      aggFunc: 'sum',
      chartContainer: el,
      suppressChartRanges: true,
    };

    this.gridOptions4.api.createRangeChart(chart_params4);
  }
  onChart5() {
    let el: HTMLElement = this.chart5.nativeElement;
    this.cx = 'pie' as ChartType;
    let chart_params5 = {
      cellRange: {
        columns: ['State', 'Sale'],
        rowStartIndex: null,
        rowEndIndex: null
      },
      chartThemeOverrides: {
        common: {
          title: {
            enabled: true,
            text: 'Bronze Medal by Country',
          },
          legend: {
            enabled: false,
          },
        },
      },
      chartType: this.cx,
      chartThemeName: 'ag-pastel',
      aggFunc: 'sum',
      chartContainer: el,
      suppressChartRanges: true,
    };

    this.gridOptions2.api.createRangeChart(chart_params5);
  }

  onChart6() {
    let el: HTMLElement = this.chart6.nativeElement;
    this.cx = <ChartType>'groupedColumn';
    let chart_params6 = {
      cellRange: {
        rowStartIndex: 0,
        rowEndIndex: 4,
        columns: ['Product', 'Sale'],
      },
      chartThemeOverrides: {
        common: {
          title: {
            enabled: true,
            text: 'Bronze Medal by Country',
          },
          legend: {
            enabled: false,
          },
        },
      },
      chartType: this.cx,
      chartThemeName: 'ag-pastel',
      aggFunc: 'sum',
      chartContainer: el,
      suppressChartRanges: true,
    };

    this.gridOptions6.api.createRangeChart(chart_params6);
  }

  onGridReady(params) {
    params.api.setRowData(this.rowData);
    this.preloader1 = false;
    this.onChart1();
  }

  onGridReady2(params) {
    params.api.setRowData(this.rowData2);
    this.preloader2 = false;
    this.onChart5();
  }

  onGridReady4(params) {
    params.api.setRowData(this.rowData3);
    this.preloader3 = false;
    this.onChart2();
    this.onChart4();
  }

  onGridReady5(params) {
    params.api.setRowData(this.rowData4);
    this.preloader4 = false;
    this.onChart3();
  }

  onGridReady6(params) {
    params.api.setRowData(this.rowData5);
    this.preloader5 = false;
    this.onChart6();
  }


  ngOnInit() {

    this.sharedService.setHeader('Dashboard');

    var mon_dict = {
      1: 'Jan',
      2: 'Feb',
      3: 'Mar',
      4: 'Apr',
      5: 'May',
      6: 'Jun',
      7: 'Jul',
      8: 'Aug',
      9: 'Sep',
      10: 'Oct',
      11: 'Nov',
      12: 'Dec'
    };
    var growth;
    var prvs_sale;
    let rowData = [];
    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable == "true" ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }

  sampleData: any[] = [
    { Day: 'P1', Return: 30, Loadout: 15, Carryover: 85, Sale: 125 },
    { Day: 'P2', Return: 100, Loadout: 25, Carryover: 90, Sale: 105 },
    { Day: 'P3', Return: 30, Loadout: 100, Carryover: 85, Sale: 85 },
    { Day: 'P4', Return: 55, Loadout: 125, Carryover: 45, Sale: 25 },
    { Day: 'P5', Return: 10, Loadout: 120, Carryover: 85, Sale: 20 },
    { Day: 'P6', Return: 120, Loadout: 20, Carryover: 60, Sale: 70 },
    { Day: 'P7', Return: 60, Loadout: 80, Carryover: 90, Sale: 60 }
  ];
  getWidth(): any {
    if (document.body.offsetWidth < 768) {
      return '100%';
    }

    return '100%';
  }

  padding: any = { left: 5, top: 5, right: 5, bottom: 5 };
  titlePadding: any = { left: 90, top: 0, right: 0, bottom: 10 };
  xAxis: any =
    {
      dataField: 'Day',
      showGridLines: true
    };
  seriesGroups: any[] =
    [
      {
        type: 'column',
        columnsGapPercent: 50,
        seriesGapPercent: 0,
        valueAxis:
        {
          unitInterval: 25,
          minValue: 0,
          maxValue: 150,
          displayValueAxis: true,
          // description: 'Time in minute',
          axisSize: 'auto',
          tickMarksColor: '#888888'
        },
        series: [
          { dataField: 'Return', displayText: 'Return' },
          { dataField: 'Loadout', displayText: 'Loadout' },
          { dataField: 'Carryover', displayText: 'Carryover' },
          { dataField: 'Sale', displayText: 'Sale' },
        ]
      }
    ];

  // line chart
  sampleData1: any[] = [
    { Day: 'Mar 1', PRODUCT1: 4, PRODUCT2: 8, },
    { Day: 'Mar 8', PRODUCT1: 5, PRODUCT2: 9, },
    { Day: 'Mar 15', PRODUCT1: 6, PRODUCT2: 12, },
    { Day: 'Mar 22', PRODUCT1: 8, PRODUCT2: 13, },
    { Day: 'Mar 29', PRODUCT1: 10, PRODUCT2: 15, },
    { Day: 'Apr 5', PRODUCT1: 12, PRODUCT2: 14, },
    { Day: 'Apr 12', PRODUCT1: 7, PRODUCT2: 15, },
    { Day: 'Apr 19', PRODUCT1: 9, PRODUCT2: 11, },
    { Day: 'Apr 26', PRODUCT1: 7, PRODUCT2: 20, },
    { Day: 'May 2', PRODUCT1: 12, PRODUCT2: 24, },
    { Day: 'May 9', PRODUCT1: 19, PRODUCT2: 28, },
    { Day: 'May 16', PRODUCT1: 20, PRODUCT2: 23, },
    { Day: 'Mar 23', PRODUCT1: 22, PRODUCT2: 27, },
    { Day: 'May 30', PRODUCT1: 25, PRODUCT2: 28, },
  ];
  // padding: any = { left: 10, top: 10, right: 15, bottom: 10 };
  // titlePadding: any = { left: 90, top: 0, right: 0, bottom: 10 };
  getWidth1(): any {
    if (document.body.offsetWidth < 768) {
      return '100%';
    }

    return '100%';
  }

  xAxis1: any =
    {
      dataField: 'Day',
      unitInterval: 1,
      tickMarks: { visible: true, interval: 1 },
      gridLinesInterval: { visible: true, interval: 1 },
      valuesOnTicks: false,
      padding: { bottom: 10 }
    };
  valueAxis: any =
    {
      unitInterval: 5,
      minValue: 0,
      maxValue: 30,

    };
  seriesGroups1: any[] =
    [
      {
        type: 'line',
        series:
          [
            {
              dataField: 'PRODUCT1',
              symbolType: 'square',
            },
            {
              dataField: 'PRODUCT2',
              symbolType: 'square',
            }
          ]
      }
    ];

}

