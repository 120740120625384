<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Service Request Product Selector</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <div class="col-md-12 pt-4 pad0">
        <div class="col-md-6 pl-0 pr-30">
          <label class="lbl">PRODUCT</label>
          <div class="formselect">
            <ng-select
              #productSelector
              [items]="productList"
              bindLabel="name"
              bindValue="id"
              [virtualScroll]="true"
              [(ngModel)]="selectedProduct"
              (ngModelChange)="productSelectorChange()"
            >
            </ng-select>
          </div>
        </div>
        <div class="col-md-6 pt-4 pl-0 pr-30">
          <button class="btnsmall confirm-blue-btn"
                  (click)="addProductClick()"
          >
            Add
          </button>
        </div>
      </div>
      <div class="col-md-12 pt-4 pad0">
        <div class="dms_table">
          <ag-grid-angular id="myGrid2" style="width: 100%;" class="ag-theme-alpine"
                           [columnDefs]="columnDefs"
                           [gridOptions]="gridOptions"
                           (gridReady)="onGridReady($event)"
                           [frameworkComponents]="frameworkComponents"
                           [context]="context"
                           (cellEditingStarted)="onCellEditingStarted($event)"
                           (cellEditingStopped)="onCellEditingStopped($event)"
                           (cellClicked)="onCellClicked($event)">
          </ag-grid-angular>
        </div>
      </div>
    </div>
    <div class="button-row footer-section">
      <button
        mat-button
        style=" margin-right:15px;"
        class="btn grey-btn"
        (click)="closeDialog()"
      >
        Cancel
      </button>
      <button
        mat-button
        style=" margin-right:15px;"
        class="btn confirm-blue-btn"
        (click)="submitClick()"
        [disabled]="!selectedProducts || selectedProducts.length === 0"
      >
        Submit
      </button>
    </div>
  </div>
</section>
