import {Component, OnInit} from "@angular/core";
import {PageBase} from "../../pagebase/pagebase";
import {MatDialogConfig} from "@angular/material/dialog";
import {ApprovePopupComponent} from "./approve_popup/approve-popup.component";


@Component({
  selector: 'app-approve-retailer',
  templateUrl: '../../pagebase/pagebase.html',
  styleUrls: ['../../pagebase/pagebase.scss']
})

export class ApproveRetailerComponent extends PageBase implements OnInit, IPage {

  matActionMenuItemDeclaration = [{name: 'Generate Claim'}];

  public pinnedTotalData = [];
  customFilter: any = {
    name: 'Status',
    key: 'status',
    value: 'pending',
    values: [],
    show: true
  };

  ngOnInit(): void {

    this.sharedService.setHeader('Approve Retailers');
    this.showCustomFilter = true;
    this.customFilter.values = [
      {id: 'all', itemName: 'All'},
      {id: 'pending', itemName: 'Pending'},
      {id: 'approved', itemName: 'Approved'},
      {id: 'rejected', itemName: 'Rejected'}
    ];
    this.statusConfig.success = 'Approved';
    this.statusConfig.error = 'Rejected';
    this.statusConfig.warning = 'pending';
    this.showApproveBtn = true;
    this.showRejectBTn = true;
    /*this.myDate[0] = '2023-07-01';
    this.myDate[1] = '2023-07-31';*/
    this.customFilter.type = 'verified';
    this.configureGrid();
    this.loadServerData();
  }


  configureGrid() {
    this.API_URL = '/api/res_partners/get_verified_retailers';
    const inThis = this;
    this.columnDefs = [
      {
        headerName: "ID", field: "id", width: 60, pinned: 'left',
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: params => {
          if (params.data) {
            return true;
          }
        }
      },
      {headerName: "CODE", field: "code", width: 80, pinned: 'left'},
      {headerName: 'NAME', field: 'name', width: 200, pinned: 'left'},
      {
        headerName: 'Actions', field: 'action', width: 100, pinned: 'left',
        cellRenderer(params) {
          if (params.data) {
              return '<span class="ml-1 status1" style="background: #59a7a2;" data-action-type="approve">Approve</span><span class="ml-1 status1" style="background: #df7373;" data-action-type="reject">Reject</span>';
          }
        },
        cellRendererParams: {
          _self: this
        }
      },
      {headerName: "STATE", field: "state", width: 80},
      {headerName: "TERRITORY", field: "territory", width: 80},
      {headerName: "BEAT", field: "beats", width: 100},
      {headerName: "TYPE", field: "out_type", width: 80},
      {headerName: "ADDRESS", field: "address", width: 100},
      {headerName: "PAYMENT MODE", field: "payment_mode", width: 80},
      {headerName: "CREDIT LIMIT", field: "credit_limit", width: 60},
      {headerName: "GSTIN", field: "gst_id", width: 100},
      {headerName: "MOBILE", field: "mobile", width: 80},
      {headerName: "EMAIL", field: "email", width: 80},
      {headerName: "CREATED BY", field: "created_by", width: 80},
      {headerName: "UPDATED BY", field: "updated_by", width: 80},
      {
        headerName: "UPDATED DATE", field: "write_date", width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.write_date) {
              return inThis.datePipe.transform((new Date(params.data.write_date + ' UTC')).toString(), 'yyyy-MM-dd');
            } else {
              return params.data.write_date;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: "STATUS", field: "status", width: 100, pinned: 'right'},
    ];
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllColumns().length;

    params.api.setRowData(this.rowData);
    this.preloader = false;
    const inThis = this;

    if (this.showPinnedFooter && this.columnsWithAggregation.length > 0) {
      setTimeout(() => {
        const pinnedBottomData = this.generatePinnedBottomData();
        this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
      }, 500);
    }

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        if (noOfColumns < 11 && screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeAllColumns();
        }
      });
    });

    if (noOfColumns < 11 && screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeAllColumns();
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
    if (this.sharedService.currListData && this.sharedService.currListData.url === this.router.url) {
      this.agGridFilter = this.sharedService.currListData.agGridFilter;
      this.gridApi.setFilterModel(this.agGridFilter);
    }
    if (this.columnState) {
      this.gridColumnApi.applyColumnState(
        {
          state: this.columnState,
          applyOrder: true,
        }
      );
      this.quickSearch();
    }
  }

  onSelectionChanged(event) {
    this.tabIndex = 0;
    if (this.prevIndex !== event.rowIndex) {
      if (event.node.selected) {
        this.showApprove = true;
        this.prevIndex = event.rowIndex;
      }
    } else {
      if (event.node.selected) {
        this.showApprove = true;
      } else {
        this.showApprove = false;
      }
      this.prevIndex = event.rowIndex;
    }
  }

  onCellClicked($event) {
    const column = $event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    if (column === 'action') {
      const actionType = $event.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "approve": {
          this.openConfirmationDialog($event.data, 'APPROVE');
          break;
        }
        case "reject": {
          this.openConfirmationDialog($event.data, "REJECT");
          break;
        }
      }
    } else {
      if (screen.width > 991) {
        const config: MatDialogConfig = {
          width: '70vw',
          height: 'fit-content',
          maxWidth: '80%',
          maxHeight: '98%',
          data: {
            params: rows[0]
          }
        };
        const creatediaeref = this.dialog.open(ApprovePopupComponent, config);
        creatediaeref.afterClosed().subscribe(result => {
          if (creatediaeref.componentInstance.refreshDlg) {
            this.loadServerData();
          }
        });
      } else {
        const config: MatDialogConfig = {
          width: '100vw',
          height: '80vh',
          maxWidth: '90%',
          maxHeight: '100%',
          data: {
            params: rows[0]
          }
        };

        const creatediaeref = this.dialog.open(ApprovePopupComponent, config);
        creatediaeref.afterClosed().subscribe(result => {
          if (creatediaeref.componentInstance.refreshDlg) {
            this.loadServerData();
          }
        });
      }
    }
  }

}
