<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Assign Service Engineer</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <form novalidate id="ngForm" #servicereq="ngForm">
      <div>
        <div class="mt-4">
          <span class="dialoglabel">Service Rep</span>
          <div style="margin-top: 10px;">
            <ng-select [items]="vendorList"
                       bindLabel="user_name"
                       bindValue="id"
                       class="custom"
                       name="se_id"
                       #se_id="ngModel"
                       (ngModelChange)="vendorChange($event)"
                       [(ngModel)]="serviceExecutiveDetails.se_id"
                       required>
              >
            </ng-select>
          </div>
        </div>
        <div class="mt-4">
          <span class="dialoglabel">Visit Date</span>
          <div>
            <input
              class="forminput"
              type="text"
              name="date"
              [(ngModel)]="serviceExecutiveDetails.date"
              #date="ngModel"
              [owlDateTime]="dt1"
              [owlDateTimeTrigger]="dt1"
              required
            >
            <owl-date-time (afterPickerClosed)="changeDate(dt1)" [pickerType]="'calendar'"
                           #dt1></owl-date-time>
            <div *ngIf="date.errors && (date.dirty || date.touched)">
              <span *ngIf="date.errors.required" class="text-danger">Visit Date is required</span>
            </div>
          </div>
        </div>
      </div>
      </form>
    </div>
    <div class="button-row footer-section">
      <button
        mat-button
        style=" margin-right:15px;"
        class="btn grey-btn"
        (click)="closeDialog()"
      >
        Cancel
      </button>
      <button
        mat-button
        style=" margin-right:15px;"
        class="btn confirm-blue-btn"
        (click)="assignServiceExecutive()"
        form="ngForm"
        [disabled]="servicereq.form.invalid"
      >
        Assign
      </button>
    </div>
  </div>
</section>
