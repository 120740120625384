import {Component, ElementRef, Input, OnInit} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {ApiService} from "../../_services/api.service";
import {SharedService} from "../../_services/shared_service";
import {ToastrService} from "ngx-toastr";
import {DatePipe} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {LocalforageService} from "../../_services/localforage.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-meet-print',
  templateUrl: './meet_print.component.html',
  styleUrls: ['./meet_print.component.scss']
})
export class MeetPrintComponent implements OnInit {
  @Input() meet_id: object;

  meet_detail: any = {};
  company_logo;
  expense_json: any[] = [];
  sponsor_json: any[] = [];
  meet_budget: any[] = [];
  meet_config: any[] = [];
  terms = '';
  totalExp = 0;
  totalExpInwords = '';
  totalCompShareInwords = '';
  advRecievedInwords = '';
  managerName = '';
  totalCompShare = 0;
  private a = ['Zero ', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ', 'Thirteen ',
    'Fourteen ', 'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen '];
  private b = ['', '', 'Twenty ', 'Thirty ', 'Forty ', 'Fifty ', 'Sixty ', 'Seventy ', 'Eighty ', 'Ninety '];

  constructor(public dialog: MatDialog,
              public apiService: ApiService,
              public sharedService: SharedService,
              public toastr: ToastrService,
              public elRef: ElementRef,
              public datePipe: DatePipe,
              protected route: ActivatedRoute,
              protected _localCacheService: LocalforageService,
              public router: Router,
              private domSanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.getMeetData(this.meet_id);
  }


  inWords(num) {
    if ((num = num.toString()).length > 9) {
      return 'overflow';
    }
    const n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) {
      return;
    }
    let str = '';

    str += (n[1] !== '00') ? (this.a[Number(n[1])] || this.b[n[1][0]] + ' ' + this.a[n[1][1]]) + 'Crore ' : '';
    str += (n[2] !== '00') ? (this.a[Number(n[2])] || this.b[n[2][0]] + ' ' + this.a[n[2][1]]) + 'Lakh ' : '';
    str += (n[3] !== '00') ? (this.a[Number(n[3])] || this.b[n[3][0]] + ' ' + this.a[n[3][1]]) + 'Thousand ' : '';
    str += (n[4] !== '0') ? (this.a[Number(n[4])] || this.b[n[4][0]] + ' ' + this.a[n[4][1]]) + 'Hundred ' : '';
    if (this.a[Number(n[5])]) {
      str += this.a[Number(n[5])];
    } else {
      if (this.a[n[5][1]] !== 'Zero ') {
        str += this.b[n[5][0]] + ' ' + this.a[n[5][1]];
      } else {
        str += this.b[n[5][0]];
      }
    }
    return str;
  }

  getMeetData(meetid) {
    const meet_data = {
      access_token: localStorage.getItem('resfreshToken'),
      meet_id: meetid
    };

    this.apiService.post('/api/partner_meets/get_meet_detail', meet_data)
      .subscribe(async res => {
        if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
          this.meet_detail = res.results.data[0];
          this.company_logo = localStorage.getItem('company_logo');
          if (this.meet_detail['comp_logo']) {
            const resp = await this.apiService.fetchImageWait({
              access_token: localStorage.getItem('resfreshToken'),
              data: [{store_fname: this.meet_detail['comp_logo'], container: "res_partner"}]
            });
            console.log(resp);
            if (resp['result'].length > 0) {
              this.company_logo = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + resp['result'][0].bill_datas);
            }

          }
          //this.company_logo = localStorage.getItem('company_logo');

          this.expense_json = this.meet_detail.expense_json;
          this.sponsor_json = this.meet_detail.host_json;
          this.meet_budget = this.meet_detail.meet_budget;

          this._localCacheService.getAppConfig().then(async app_config => {
            if (app_config && app_config.hasOwnProperty('config_json')) {
              this.meet_config = app_config['config_json']['meet_config'];
              if (this.meet_detail['type']) {
                const type = this.meet_config.find(item => item.type = this.meet_detail['type']);
                if (type) {
                  this.terms = type.terms;
                }
              }
            }
          });

          this.managerName = this.meet_detail['manager_name'];
          if (this.meet_detail['user_name'] === this.meet_detail['manager_name']) {
            this.managerName = this.meet_detail['parent_lead_name'];
          }

          if (this.meet_detail['adv_received']) {
            this.advRecievedInwords = '(' + this.inWords(this.meet_detail['adv_received']) + ' Only)';
          }

          if (this.expense_json && this.expense_json.length > 0) {
            for (let i = 0; i < this.expense_json.length; i++) {
              const obj = this.expense_json[i];
              this.totalExp = this.totalExp + obj.total;
              if (obj.comp_share) {
                this.totalCompShare = this.totalCompShare + ((obj.comp_share * obj.total) / 100);
              }
            }
            this.totalCompShareInwords = this.inWords(this.totalCompShare);
            this.totalExpInwords = this.inWords(this.totalExp);
          }

          if (this.meet_budget && this.meet_budget.length > 0) {
            for (let i = 0; i < this.meet_budget.length; i++) {
              if (this.meet_budget[i].variance) {
                this.meet_budget[i].variance = parseFloat(this.meet_budget[i].variance).toFixed(2);
              }
            }
          }

          if (this.meet_detail['t_var']) {
            this.meet_detail['t_var'] = parseFloat(this.meet_detail['t_var']).toFixed(2);
          }
        }
      });
  }
}
