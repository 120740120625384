<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Available Answers</h2>
    </div>
    <div class="body-section w-100" style=" padding: 20px 15px;">
      <div>
        <form novalidate id="ngForm" #questionform="ngForm">
          <div class="col-md-12 pl-0 pr-30">
            <div class="col-md-4 pl-0 pr-30" style="margin-top: 20px;">
              <label class="dialoglabel1">NAME</label>
              <div style="margin-top: 5px;">
                <div *ngIf="edit">
                  <input
                    class="forminput"
                    type="text"
                    name="name"
                    [(ngModel)]="answerDetails.name"
                    #name="ngModel"
                    style="width: 90%;"
                    required
                  >
                  <div *ngIf="name.errors && (name.dirty || name.touched)">
                    <span *ngIf="name.errors.required" class="text-danger">Name is required</span>
                  </div>
                </div>
                <p class="detailvalue1" *ngIf="!edit">{{answerDetails.name}}</p>
              </div>
            </div>
            <div class="col-md-4 pl-0 pr-30" style="margin-top: 20px;">
              <label class="dialoglabel1">SCORE</label>
              <div style="margin-top: 5px;">
                <div *ngIf="edit">
                  <input
                    class="forminput"
                    type="text"
                    name="score"
                    [(ngModel)]="answerDetails.score"
                    #score="ngModel"
                    style="width: 90%;"
                    required
                  >
                  <div *ngIf="score.errors && (score.dirty || score.touched)">
                    <span *ngIf="score.errors.required" class="text-danger">Score is required</span>
                  </div>
                </div>
                <p class="detailvalue1" *ngIf="!edit">{{answerDetails.score}}</p>
              </div>
            </div>
            <div class="col-md-4 pl-0 pr-30" style="margin-top: 20px;">
            </div>
          </div>
        </form>
      </div>

      <div class="col-md-12 pad0" style="margin-top: 20px;margin-bottom: 30px;">
        <div style="display: inline-block;">
          <span style="font-size: 20px; color:#59A7A2;font-weight: 400;margin-top: 5px;">Linked Questions</span>
        </div>
        <div style="float:right;margin-top: 5px;cursor:pointer;" (click)="addQuestion()" *ngIf="edit">
          <i class="fas fa-plus-circle" style="color:#17A2B8;margin-right: 5px;margin-top:5px;font-size:16px;"></i>
          <span style="font-size: 14px; font-weight: 400;">Add</span>
        </div>
        <div>
          <ag-grid-angular *ngIf=loaded id="myGrid1" style="width: 100%;height:280px;" class="ag-theme-balham"
                           [defaultColDef]="defaultColDef"
                           [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                           (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                           (cellClicked)="onCellClicked($event)"
                           [context]="context">
          </ag-grid-angular>
          <div *ngIf="preloader">
            <app-loadingstate></app-loadingstate>
          </div>
          <div *ngIf="empty_state">
            <app-emptystate></app-emptystate>
          </div>
        </div>
      </div>
      <div *ngIf="edit" class="button-row" style="float: right; margin-bottom: 30px;">
        <button
          mat-button
          style=" margin-right:15px;"
          class="btn grey-btn"
          (click)="closeDialog()"
        >
          Discard
        </button>
        <button
          mat-button
          class="btn confirm-blue-btn"
          form="ngForm"
          [disabled]="questionform.form.invalid"
          (click)="saveQuestion()"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</section>
