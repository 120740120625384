import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-survey_card',
  templateUrl: './survey_card.component.html',
  styleUrls: ['./survey_card.component.scss']
})
export class SurveyCardComponent implements OnInit {
  @Input() title: string;
  @Input() id: string;
  @Input() code: string;
  @Input() type: string;
  @Output() reportClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() listClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  goToReport() {

    this.reportClick.emit(true);
  }

  goToList(){
    this.listClick.emit(true);
  }

}

