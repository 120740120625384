<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" style="float: right;background-color:#FDE0D4;">
        <i class="fas fa-times"></i>
      </button>
      <h2>OUTLET VISIT</h2>
      <!-- <button class="close-dialog" (click)="closeDialog()">
         <i class="fas fa-times"></i>
       </button>-->
    </div>
    <div class="body-section">
      <div class="search-list" style="margin-top: 10px;">
        <!-- <div class="col-md-2 pad0">
           <div style="display: inline-block;" >
             <button class="btn teal-btn" >NEW TASK</button>
           </div>
         </div>-->
        <div class="col-md-5 pad0">
          <!-- <div *ngIf="show_approve" style="display: inline-block;margin-right: 10px;" >
             <button (click)="createAssetPopup()" class="btn teal-btn" style="height: 42px;margin-bottom: 10px;">Approve</button>
           </div>-->
          <div class="search" style="display: inline-block;">
            <span class="search-control"><img src="assets/searchicon.png"/></span>
            <input  id="filter-text-box" type="text" placeholder="filter" (input)="quickSearch()" class="search-control-area"
                    placeholder="Search">
          </div>
          <!-- <progress value="50" max="100"></progress >-->
          <!--  <div class="row">
              <div class="progresscontainer">
                <div class="skill html"></div>
              </div>
              <div style="display:inline;height:5px;margin-bottom: 5px;">65</div>
            </div>-->
        </div>

        <div class="col-md-7 pad0">
          <div class="filters">
            <ul class="list-unstyled">
              <li style="margin-right: 2px !important;margin-left: 2px !important;" class="margLR">
                <!--<div style="margin-top: -15px;">
                  <span style="color:#17A2B8;">Status</span>
                </div>
                <div class="date-picker">
                  &lt;!&ndash; <span style="margin-top: 15px;margin-right: 5px;">Retail Audit Type </span>&ndash;&gt;

                  <div style="width:150px;" class="periodselect">
                    <select
                      name="review"
                      [(ngModel)]="user_data.data.reviewed"
                      #review="ngModel"
                    >
                      <option value="not_reviewed" selected>Not Reviewed</option>
                      <option value="reviewed" >Reviewed</option>

                    </select>
                  </div>
                </div>-->
                <app-components-customfilter [customFilters]="customFilter" (onsetCustomFilter)="setCustomFilter($event)"></app-components-customfilter>
              </li>

              <li style="margin-left:5px;margin-right:5px !important;" class="margLR">
                <div style="display: inline-block;" >
                  <button
                    class="btn blue-btn1"
                    style="height: 44px;margin-bottom: 10px;"
                    (click)="fetchOutlets()"
                  >GO</button>
                </div>
              </li>
              <li class="margLR">
                <app-excelicon [gridOption]="gridOptions" [title]="'Assets Report'"></app-excelicon>
              </li>

            </ul>
          </div>
        </div>
        <div class="col-md-12 pad0">
          <div>
            <ag-grid-angular id="myGrid1" *ngIf="loaded" style="height:62vh;" class="ag-theme-balham"   [defaultColDef]="defaultColDef"
                             [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                             (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                             (cellClicked)="onCellClicked($event)"
                             [context]="context">
            </ag-grid-angular>
            <div *ngIf="preloader">
              <app-loadingstate></app-loadingstate>
            </div>
            <div *ngIf="empty_state">
              <app-emptystate></app-emptystate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
