<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>{{surveyDetails.survey}} - {{surveyDetails.completion_date}}</h2>
    </div>
    <div class="body-section w-100">
      <div>
        <div class="col-md-12 pad0" style="background-color: #F3F6FD;padding: 15px; text-align: center;">
          <img [src]="photo" style="height:450px; margin: auto;"/>
        </div>
        <div class="col-md-12 pad0" style="background-color: #F3F6FD;padding: 15px;">
          <div class="col-md-12 pr-30">
            <div class="row">
              <label class="detaillabel1">Question : </label>
              <span class="dialogspan" style="margin-left:5px">{{surveyDetails.question}}</span>
            </div>
          </div>
          <div class="col-md-12 pad0"  style="margin-top: 20px;">
            <div class="col-md-6 pr-30">
              <div class="row">
                <label class="detaillabel1">Cluster : </label>
                <span class="dialogspan" style="margin-left:5px">{{surveyDetails.cluster}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label class="detaillabel1">Territory Code: </label>
                <span class="dialogspan" style="margin-left:5px">{{surveyDetails.tcode}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label class="detaillabel1">Territory : </label>
                <span class="dialogspan" style="margin-left:5px">{{surveyDetails.territory}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label class="detaillabel1">Program : </label>
                <span class="dialogspan" style="margin-left:5px">{{surveyDetails.program_type}}</span>
              </div>
            </div>
            <div class="col-md-6 pr-30">
              <div class="row">
                <label class="detaillabel1">Retailer Code : </label>
                <span class="dialogspan" style="margin-left:5px">{{surveyDetails.rcode}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label class="detaillabel1">Retailer : </label>
                <span class="dialogspan" style="overflow-wrap: break-word; width: 188px;margin-left:5px;">{{surveyDetails.retailer}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label class="detaillabel1">Salesman Code : </label>
                <span class="dialogspan" style="margin-left:5px">{{surveyDetails.emp_code}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label class="detaillabel1">Salesman : </label>
                <span class="dialogspan" style="margin-left:5px">{{surveyDetails.salesman}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
