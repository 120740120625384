import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class StockDisputeReportComponent extends ReportBase implements IReport, OnInit {

  showCustomFilter = true;
  customFilter: any = {
    name: 'Type',
    key: 'type',
    value: '',
    values: [],
    show: true
  };

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Stock Dispute Report');

   /* this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.state = true;
    this.showableFilters.region = true;*/
    this.customFilter.values = [
      {id: '', itemName: 'All'},
      {id: 'Secondary Return', itemName: 'Secondary Return'},
      {id: 'Damage', itemName: 'Damage'},
      {id: 'loadout', itemName: 'Loadout'},
    ];

    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/stockDisputeReport';
    const inthis = this;
    this.columnDefs = [

      /*  {headerName: 'REGION', field: 'region', enableRowGroup: true, width: 80},
        {headerName: 'STATE', field: 'state', enableRowGroup: true, width: 80},
        {headerName: 'TERRITORY', field: 'state', enableRowGroup: true, width: 80},*/
      {headerName: 'DC', field: 'dc', width: 100, enableRowGroup: true,
        valueGetter: params => {
          if (params.data) {
            if (params.data.type !== 'Secondary Return') {
              return params.data.van;
            } else {
              return params.data.dc;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: 'VAN', field: 'van', width: 100, enableRowGroup: true,
        valueGetter: params => {
          if (params.data) {
            if (params.data.type !== 'Secondary Return') {
              return params.data.outlet;
            } else {
              return params.data.van;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: 'OUTLET', field: 'outlet', width: 100, enableRowGroup: true,
        valueGetter: params => {
          if (params.data) {
            if (params.data.type === 'Secondary Return') {
              return params.data.outlet;
            } else {
              return '';
            }
          } else {
            return '';
          }
        }
      },
      {
        headerName: 'REQ DATE', field: 'req_date', enableRowGroup: true, width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.req_date) {
              return inthis.datePipe.transform((new Date(params.data.req_date + ' UTC')).toString(), 'yyyy-MM-dd');
            } else {
              return params.data.req_date;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: 'P CODE', field: 'p_code', enableRowGroup: true, width: 80},
      {headerName: 'PRODUCT', field: 'p_name', enableRowGroup: true, width: 80},
      {headerName: 'OLD VAL', field: 'val1', enableRowGroup: true, width: 80},
      {headerName: 'NEW VAL', field: 'val2', enableRowGroup: true, width: 80},
      {headerName: 'DISPUTE QTY', field: 'deviation', enableRowGroup: true, width: 80},
      {headerName: 'BATCH DATE', field: 'batch_date', enableRowGroup: true, width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.batch_date) {
              return inthis.datePipe.transform((new Date(params.data.batch_date + ' UTC')).toString(), 'yyyy-MM-dd');
            } else {
              return params.data.batch_date;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: 'REQUESTED BY', field: 'create_name', enableRowGroup: true, width: 80},
      {headerName: 'APPROVED DATE', field: 'app_date', enableRowGroup: true, width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.app_date) {
              return inthis.datePipe.transform((new Date(params.data.app_date + ' UTC')).toString(), 'yyyy-MM-dd');
            } else {
              return params.data.app_date;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: 'APPROVED BY', field: 'app_name', enableRowGroup: true, width: 80},
      {headerName: 'TYPE', field: 'type', enableRowGroup: true, width: 80},
      {headerName: 'ORDER TYPE', field: 'order_type', enableRowGroup: true, width: 80},
      {headerName: 'STATUS', field: 'status', enableRowGroup: true, width: 80},
      {headerName: 'REASON', field: 'reason', enableRowGroup: true, width: 80},
    /*  {headerName: 'REASON TYPE', field: 'reason_type', enableRowGroup: true, width: 80},*/
      {headerName: 'COMMENTS', field: 'comments', enableRowGroup: true, width: 80},
    ];

  }

}
