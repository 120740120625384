<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Create Customer</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <div>
        <div style="margin-top: 10px;">
          <span class="dialoglabel">Name</span>
          <span class="requiredspan"> *</span>
          <input
            class="forminputwhitebg"
            style="display: block; color: #0762AD; font-size: 14px; margin-top: 5px;width: 100%;"
            type="text"
            name="customer_name"
            [(ngModel)]="activeObject.name"
            #customer_name="ngModel"
            placeholder="Enter Customer Name"
            required
          >
          <div *ngIf="customer_name.errors && (customer_name.dirty || customer_name.touched)">
            <span *ngIf="customer_name.errors.required" class="text-danger">Customer Name is required</span>
          </div>
        </div>
        <div style="margin-top: 20px;">
          <div class="row">
            <div class="col-md-6 pl-0 mt-0">
              <span class="dialoglabel">Code</span>
              <div *ngIf="!enterCodeManually" style="display: block; color: #0762AD; font-size: 14px; margin-top: 5px;">
                <span class="dialoglabel" (click)="enterCodeManualClick()"> Click here to enter code Manually  </span>
              </div>
              <div *ngIf="enterCodeManually">
                <input
                  class="forminputwhitebg"
                  style="display: block; color: #0762AD; font-size: 14px; margin-top: 5px;width: 100%;"
                  type="text"
                  name="customer_code"
                  [(ngModel)]="activeObject.code"
                  #customer_code="ngModel"
                  (keydown)="onCodeKeyDown($event)"
                  placeholder="unique Code or alias"
                  required
                >
                <div *ngIf="customer_code.errors && (customer_code.dirty || customer_code.touched)">
                  <span *ngIf="customer_code.errors.required" class="text-danger">Customer Code is required</span>
                </div>
              </div>


            </div>
            <div class="col-md-6 pl-0 mt-0 pr-0">
              <span class="dialoglabel">Type</span>
              <span class="requiredspan"> *</span>
              <div style="margin-top: 10px;">
                <ng-select [items]="customerTypeList"
                           bindLabel="itemName"
                           bindValue="id"
                           class="custom"
                           (ngModelChange)="typeChange($event)"
                           [(ngModel)]="activeObject.retailer_type">
                  >
                </ng-select>
              </div>
            </div>
          </div>
        </div>


          <div class="row">
            <div class="col-md-6 pl-0 mt-0">
              <span class="dialoglabel">Contact Person</span>
              <span class="requiredspan"> *</span>
              <input
                class="forminputwhitebg"
                style="display: block; color: #0762AD; font-size: 14px; margin-top: 5px;width: 100%;"
                type="text"
                name="customer_contact"
                [(ngModel)]="activeObject.contact_name"
                #customer_contact="ngModel"
                placeholder="Enter Contact Person"
                required
              >
              <div *ngIf="customer_contact.errors && (customer_contact.dirty || customer_contact.touched)">
                <span *ngIf="customer_contact.errors.required" class="text-danger">Customer Contact is required</span>
              </div>
            </div>
            <div class="col-md-6 pl-0 mt-0 pr-0">
              <span class="dialoglabel">Mobile</span>
              <span class="requiredspan"> *</span>
              <input
                class="forminputwhitebg"
                style="display: block; color: #0762AD; font-size: 14px; margin-top: 5px;width: 100%;"
                type="number"
                name="customer_mobile"
                [(ngModel)]="activeObject.mobile"
                #customer_mobile="ngModel"
                placeholder="Enter Mobile No"
                required
              >
              <div *ngIf="customer_mobile.errors && (customer_mobile.dirty || customer_mobile.touched)">
                <span *ngIf="customer_mobile.errors.required" class="text-danger">Customer Mobile is required</span>
              </div>
            </div>
          </div>


        <div style="margin-top: 10px;">
          <div class="row">
            <div class="col-md-6 pl-0 mt-0">
              <span class="dialoglabel">Beat</span>
              <span class="requiredspan"> *</span>
              <div style="margin-top: 10px;">
                <ng-select [items]="beatList"
                           bindLabel="itemName"
                           bindValue="id"
                           class="custom"
                           (ngModelChange)="beatChangeEvent($event)"
                           [(ngModel)]="activeObject.beat_plan_id">
                  >
                </ng-select>
              </div>
            </div>
            <div class="col-md-6 pl-0 mt-0 pr-0">
              <span class="dialoglabel">GSTIN</span>
              <span class="requiredspan"> *</span>
              <input
                class="forminputwhitebg"
                style="display: block; color: #0762AD; font-size: 14px; margin-top: 5px;width: 100%;"
                type="text"
                name="customer_gst"
                [(ngModel)]="activeObject.gst_id"
                #customer_gst="ngModel"
                placeholder="GST/VAT/TAN"
                required
              >
              <div *ngIf="customer_gst.errors && (customer_gst.dirty || customer_gst.touched)">
                <span *ngIf="customer_gst.errors.required" class="text-danger">GST/VAT/TAN is required</span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 pl-0 mt-0">
              <span class="dialoglabel">Address</span>
              <input
                class="forminputwhitebg"
                style="display: block; color: #0762AD; font-size: 14px; margin-top: 5px;width: 100%;"
                type="text"
                name="customer_street1"
                [(ngModel)]="activeObject.street"
                #customer_street1="ngModel"
                placeholder="Street1"
              >
            </div>
            <div class="col-md-6 pl-0 mt-0 pr-0">
              <span class="dialoglabel"> </span>
              <input
                class="forminputwhitebg"
                style="display: block; color: #0762AD; font-size: 14px; margin-top: 10px;width: 100%;"
                type="text"
                name="customer_street1"
                [(ngModel)]="activeObject.street2"
                #customer_street2="ngModel"
                placeholder="Street2"
              >
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 pl-0 mt-0">
              <input
                class="forminputwhitebg"
                style="display: block; color: #0762AD; font-size: 14px; margin-top: 5px;width: 100%;"
                type="text"
                name="customer_city"
                [(ngModel)]="activeObject.city"
                #customer_gst="ngModel"
                placeholder="City"
              >
            </div>
            <div class="col-md-6 pl-0 mt-0 pr-0">
              <input
                class="forminputwhitebg"
                style="display: block; color: #0762AD; font-size: 14px; margin-top: 5px;width: 100%;"
                type="text"
                name="customer_district"
                [(ngModel)]="activeObject.zone_id"
                #customer_district="ngModel"
                placeholder="District"
              >
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 pl-0 mt-0" style="display:inline-block;">

              <ng-select [items]="stateList"
                         bindLabel="itemName"
                         bindValue="id"
                         class="custom"
                         placeholder="State"
                         style=" margin-top: 5px;"
                         (ngModelChange)="beatChangeEvent($event)"
                         [(ngModel)]="activeObject.state_id">
                >
              </ng-select>

            </div>
            <div class="col-md-6 pl-0 mt-0 pr-0">
              <input
                class="forminputwhitebg"
                style="display: block; color: #0762AD; font-size: 14px; margin-top: 5px;width: 100%;"
                type="number"
                name="customer_pincode"
                [(ngModel)]="activeObject.zip"
                #customer_pincode="ngModel"
                placeholder="Pincode"
              >
              <div *ngIf="customer_pincode.errors && (customer_pincode.dirty || customer_pincode.touched)">
                <span *ngIf="customer_pincode.errors.required" class="text-danger">Customer pincode is required</span>
              </div>
            </div>
          </div>

          <div style="margin-top: 20px;">
            <div class="row">
              <div class="col-md-6 pl-0 mt-0">
                <span class="dialoglabel">Credit Type</span>
                <div style="margin-top: 10px;">
                  <ng-select [items]="creditTypeList"
                             bindLabel="itemName"
                             bindValue="id"
                             class="custom"
                             (ngModelChange)="CreditTypeChange($event)"
                             [(ngModel)]="activeObject.payment_mode">
                    >
                  </ng-select>
                </div>
              </div>
              <div class="col-md-6 pl-0 mt-0 pr-0" *ngIf="activeObject.payment_mode !== 'cash'">
                <span class="dialoglabel">Credit Limit</span>
                <input
                  class="forminputwhitebg"
                  style="display: block; color: #0762AD; font-size: 14px; margin-top: 5px;width: 100%;"
                  type="number"
                  name="customer_cr_limit"
                  [(ngModel)]="activeObject.credit_limit"
                  #customer_cr_limit="ngModel"
                  placeholder="Enter Credit Limit"
                >
              </div>
            </div>
          </div>

          <div style="margin-top: 20px;" >
            <div class="row">
              <div class="col-md-6 pl-0 mt-0">
                <span class="dialoglabel">Supplier</span>
                <div style="margin-top: 10px;">
                  <ng-select [items]="territorySupplierList"
                             bindLabel="name"
                             bindValue="id"
                             class="custom"
                             [(ngModel)]="activeObject.supplier_id">
                    >
                  </ng-select>
                </div>
              </div>
              <div class="col-md-6 pl-0 mt-0 pr-0" *ngIf="activeObject.supplier_id > 0">
                <span class="dialoglabel">Balance</span>
                <div>
                  <ng-select [items]="balanceTypeList"
                             bindLabel="itemName"
                             bindValue="id"
                             class="custom"
                             [(ngModel)]="activeObject.balanceType"></ng-select>

                  <input
                    class="forminputwhitebg"
                    style="display: inline; color: #0762AD;"
                    type="number"
                    name="customer_balance"
                    [(ngModel)]="activeObject.balance"
                    #customer_balance="ngModel"
                    placeholder="Enter Balance/Credits"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
    <div class="button-row" style="margin-top: 20px;">

      <div style="position: absolute;display: inline-block; right: 0px;">
        <button
          mat-button
          style=" margin-right:15px;"
          class="btn grey-btn"
          (click)="closeDialog()"
        >
          Cancel
        </button>
        <button
          mat-button
          style=" margin-right:15px;"
          type="submit" ng-disabled="form.$invalid"
          class="btn confirm-blue-btn"
          (click)="saveCustomer()"
        >
          Save
        </button>
      </div>

    </div>
  </div>
</section>
