
<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper dcvanlist">
      <div class="search-list">
        <div class="col-md-3 pad0">
          <div class="search">
            <span class="search-control"><img src="assets/searchicon.png"/></span>
            <input type="text" placeholder="filter" (keyup)="quickSearch()" [(ngModel)]="searchValue" class="search-control-area"
                   placeholder="Search">
          </div>
        </div>
        <div class="col-md-9 pad0">
          <div class="filters">
            <ul class="list-unstyled">
              <li style="margin-left:5px;margin-right:5px !important;" class="margLR">
                <div style="display: inline-block;" >
                  <button
                    class="btn blue-btn2"
                    style="height: 44px;width:50px;margin-bottom: 10px;"
                    (click)="apiDmsOrderList()"
                  >GO</button>
                </div>
              </li>
<!--              <li class="margLR">-->
<!--                <div class="date-picker">-->
<!--                  <span class="datepicker-icon"><img src="../../../assets/date.png"></span>-->
<!--                  <input [selectMode]="'range'" [readonly]="true" [(ngModel)]="myDate" [owlDateTimeTrigger]="dt" [owlDateTime]="dt" placeholder="Date">-->
<!--                  <span class="dropdown-icon"><img src="../../../assets/dropdown.png"></span>-->
<!--                  <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>-->
<!--                </div>-->
<!--              </li>-->
              <li class="margLR">
                <app-excelicon [gridOption]="gridOptions" [title]="'DMS Order'"></app-excelicon>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-12 pad0">
          <div>
            <ag-grid-angular id="myGrid1" *ngIf="loaded" style="height:75vh;" class="ag-theme-balham"  [columnDefs]="columnDefs"
                             [gridOptions]="gridOptions"
                             (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                             [context]="context"
                             >
            </ag-grid-angular>
            <div *ngIf="preloader">
              <!--<div style="position:absolute;left:44%;top:200px;font-size: medium">-->
              <!--<img src="../../assets/images/preloader.gif"/>-->
              <!--</div>-->
              <app-loadingstate></app-loadingstate>
            </div>
            <div *ngIf="empty_state">
              <app-emptystate></app-emptystate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="pol-modal pol-width pol-send" id="invoiceModal" [hidden]="showInvoices">
  <div class="modal-dialog" style="width: 900px !important;">
    <div class="modal-content" style="height: 550px;margin-top: -290px;">
      <span class="close" (click)="close()">
        <img src="../../../assets/close.png">
      </span>
      <div class="modal-body">
        <div style="margin-left: 40%;" class="pol-modal-heading">
          <h1 style="font-size: 170%;
                     font-weight: 600;">Invoices</h1>
        </div>
        <div class="search" style="margin-top: -95px;">
          <span class="search-control"><img src="../../../assets/searchicon.png"/></span>
          <input type="text" placeholder="filter" (keyup)="quickSearch()" [(ngModel)]="searchValue1" class="search-control-area"
                 placeholder="Search">
        </div>
        <div class="eod-table" style="margin-top: 160px;">
          <div class="dms_table">
            <ag-grid-angular id="myGrid2" *ngIf="loaded1" style="width: 100%;height:50vh" class="ag-theme-balham"
                             [columnDefs]="columnDefs1" [gridOptions]="gridOptions1"  (gridReady)="onGridReady1($event)"
                             >
            </ag-grid-angular>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
