<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper dcvanlist">
      <div class="search-list">
        <div class="col-md-6 pad0">
          <div class="search">
            <span class="search-control"><img src="assets/searchicon.png"/></span>
            <input type="text" placeholder="Search" (keyup)="quickSearch()" [(ngModel)]="searchValue" class="search-control-area">
          </div>
        </div>
        <div class="col-md-6 pad0">
          <div class="filters">
            <ul class="list-unstyled">
              <li style="font-size: 130%;
    margin-top: 1%;">Van:</li>
              <li style="margin-right: 1px !important;margin-left: 1px !important;" class="margLR">
                <ng-template adHost></ng-template>
              </li>
              <li style="font-size: 130%;
    margin-top: 1%;
    padding-right: 10px;">Outlet Type:</li>
              <li style="margin-right: 1px !important;margin-left: 1px !important;" class="margLR">
                <ng-template adHost3></ng-template>
              </li>
              <li>
                <div style="display: inline-block;">
                  <label style="font-size: 120%;
    font-weight: 400;">Credit Days</label>
                </div>
                <div style="display: inline-block;padding-left: 4px;">
                  <input type="text" class="form-control" style="width: 30%;
                                height: 30px;
                                font-size: 120%;" [(ngModel)]="limit" />
                </div>
                <div style="display: inline-block;">
                  <button (click)="run()" style="position: relative;margin-top: 2.5%;margin-left: -15%;" class="btn blue-btn">GO</button>
                </div>
              </li>
              <li style="margin-top: 0px;" class="margLR">
                <app-excelicon [gridOption]="gridOptions" [title]="'Collections'"></app-excelicon>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div style="width: max-content;position: relative;bottom: 35px;height:0px;font-size: 130%;font-weight: 600;">
            <ng-template adHost></ng-template>
          </div>
        </div>
        <div class="col-md-12 pad0" style="margin-top: 10px;">
          <div class="component-container">
            <ag-grid-angular id="myGrid2" *ngIf="loaded" style="height: 75vh;" class="ag-theme-alpine"  [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                             (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents">
            </ag-grid-angular>
            <div *ngIf="preloader">

              <app-loadingstate></app-loadingstate>
            </div>
            <div *ngIf="empty_state">
              <app-emptystate></app-emptystate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
