import {Component, OnInit} from "@angular/core";
import {DMSBasePage} from "../../../pagebase/components/DMSBasePage";
import {NumericEditorComponent} from "../../../pagebase/components/NumericEditorComponent";
import {DeleteComponent} from "../../../deleteCell.component";
import {GetRowIdFunc, GetRowIdParams} from "@ag-grid-enterprise/all-modules";
import {DetailPageBase} from "../../../pagebase/DetailPageBase";
import {ErrorHandler} from "../../../error/error-handler";
import {MatDialogConfig} from "@angular/material/dialog";
import {
  ApproveRejectCommentsPopupComponent
} from "../../../components/approve_reject_comments_popup/approve_reject_comments_popup.component";
import {ImagePopupComponent} from "../../../components/image_popup/image_popup.component";

@Component({
  selector: 'app-payment-detail',
  templateUrl: 'payment_detail.html',
  styleUrls: ['../../../pagebase/pagebase_detail.scss']
})

export class PaymentDetailComponent extends DetailPageBase implements IDetailPage, OnInit {

  public show_approve_button = false;
  photo;

  ngOnInit(): void {
    // this.setTitle('Return Invoice');
    this._Detail_GET_URL = "/api/pwa_dms_connects/get_payment_detail";
    this.context = {componentParent: this};
    this.sharedService.setHeader('Payment Receipt');

    this.show_approve_button = this.sharedService.ACLcheck('feature_payment_approve');
    this.configureGrid();

    this.readPageParms(params => {

      if (this.activeID > 0) {
        this.newMode = false;
        this.editMode = false;
        //   View Mode
        //   Page Can be ready only after detail Object is fetched
        this.loadPageForView(() => {
          this.pageReady = true;
        });
      } else {
        this.newMode = true;
        this.editMode = true;
        //   Page Can be ready only after detail Object is fetched
        //    Throw Error
      }
    });
  }

  configureGrid() {

    this.columnDefs = [
      {headerName: 'ID', field: 'id', width: 50},
      {headerName: 'INVOICE', field: 'invoice_no', width: 80},
      {headerName: 'DATE', field: 'date', width: 50},
      {headerName: 'CR/DR', field: 'cd', width: 50},
      {headerName: 'TYPE', field: 'payment_type', width: 50},
      {headerName: 'AMOUNT', field: 'amount', width: 50},
    ];
  }

  loadPage() {
    this.pageReady = false;
    this.loadPageForView(() => {
      this.pageReady = true;
    });
  }

  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.id;
  }

  loadPageForView(callback) {

    function prepareLineData(lineItems) {
      const rowData = lineItems;
      return rowData;
    }

    this.loadActiveObject(() => {

      if (this.activeObject['supplier_id'] === undefined) {
        this.toastr.error('invalid state: supplier_id not found');
      }
      if (this.activeObject['store_fname']) {
        this.fetchImage();
      }
      if (this.activeObject && this.activeObject['entries'] && this.activeObject.entries.length > 0) {
        this.rowData = this.activeObject.entries;
        this.gridOptions.api.setRowData(this.rowData);
      }

    });

  }

  action_edit() {

  }

  openApproveRejectPopup(state) {
    let title = '';
    let message1 = '';
    let message2 = '';
    let buttonText = '';
    let type = '';

    if (state === 'rejected') {
      title = "Reject Payment Receipt";
      message1 = "Are you sure you want to Reject Payment entry?";
      message2 = "Please provide a reason for the rejection";
      buttonText = "Reject";
      type = 'reject';
    } else {
      title = "Payment Clearance";
      message1 = "Are you sure you want to Approve Payment Clearance?";
      message2 = "Please provide Comments if any";
      buttonText = "Approve";
      type = 'approve';
    }

    let maxWidth = "450px";

    if (screen.width > 991) {
      maxWidth = "50%";
    } else {
      maxWidth = "95%";
    }
    const config: MatDialogConfig = {
      width: "100vw",
      height: 'auto',
      maxWidth,
      maxHeight: '100%',
      panelClass: 'custom-dialog-container',
      data: {
        title,
        message1,
        message2,
        type,
        state,
        buttonText
      }
    };
    const creatediaeref = this.dialog.open(ApproveRejectCommentsPopupComponent, config);
    creatediaeref.componentInstance.submitClicked.subscribe(comments => {
      this.approveRejectPayment(state, comments);
    });
  }

  approveRejectPayment(action, comments) {
    this.userContext['payment_id'] = this.activeID;
    this.userContext['action'] = action;
    this.userContext['comments'] = comments;
    this.apiService.post('/api/pwa_dms_connects/update_payment_status', this.userContext)
      .subscribe(res => {
          if (res.hasOwnProperty('results') && res.results.status === 200) {
            if (res.results.hasOwnProperty('data') && res.results.data.hasOwnProperty('state')) {
              this.activeObject['state'] = res.results.data['state'];
              // navigate to invoice.
              this.activeObject.emit('success');
              this.toastr.success('Record Saved! : ' + this.activeID);
            } else {
              this.toastr.success('Record Saved!');
            }
          } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        error => {
          this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
        });
  }

  action_cancel() {

  }


  action_approve_claim() {

  }


  discard() {
    this.editMode = false;
    if (this.activeObject && (!this.activeObject.hasOwnProperty('id') || this.activeObject.id === 0)) {
      history.back();
    } else {
      this.loadPage();
    }
  }

  async fetchImage() {
    const resp = await this.apiService.fetchImageWait({
      access_token: localStorage.getItem('resfreshToken'),
      data: [{store_fname: this.activeObject['store_fname'], container: "sale_payment"}]
    });
    console.log(resp);
    if (resp['result'].length > 0 && resp['result'][0].bill_datas) {
      this.photo = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + resp['result'][0].bill_datas);
    } else {
      this.photo = null;
    }
  }

  openImagePopup() {
    if (this.photo) {
      if (screen.width > 991) {
        const config: MatDialogConfig = {
          width: "100vw",
          height: "100vh",
          maxWidth: "70%",
          maxHeight: "95%",
          data: {
           photo: this.photo
          }
        };

        const creatediaeref = this.dialog.open(ImagePopupComponent, config);
      } else {
        const config: MatDialogConfig = {
          width: "100vw",
          height: "100vh",
          maxWidth: "90%",
          maxHeight: "65%",
          data: {
            photo: this.photo
          }
        };

        const creatediaeref = this.dialog.open(ImagePopupComponent, config);
      }

    }
  }

}
