<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button class="closebtn" (click)="closeDialog()">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Add Invoices</h2>
    </div>
    <div class="body-section w-100" style="padding: 15px;">
      <div class="search-list row">
        <div class="col-md-8 pad0">
          <div [matMenuTriggerFor]="filterdropdown" style="background-color: white;width:45px;height:45px;display: inline-block;
    align-items: center; justify-content: center;cursor:pointer;padding: 12px;margin-right: 10px; "
          >
            <!--   <mat-icon style="height: 25px;">filter_list</mat-icon>-->
            <span title="Add Filter">
                  <!--  <img
                      style="height: 20px;"
                      src="../../assets/filter.svg"
                      [matMenuTriggerFor]="filterdropdown"
                    >-->
              <i *ngIf="!filterSet" style="color:#5D5D5D" class="fas fa-filter fa-2x"></i>
              <i *ngIf="filterSet" style="color:#17A2B8" class="fas fa-filter fa-2x"></i>
              <!--  <i *ngIf="(this.userData.filterData | json) != '{}'" class="fas fa-filter fa-2x"></i>
                 <i *ngIf="(this.userData.filterData | json) === '{}'" class="fas fa-filter fa-2x"></i>-->
                  </span>
            <mat-menu #filterdropdown="matMenu">
              <app-components-filters [matMenuTriggerFor]="filterdropdown" [showableFilters]="showableFilters"
                                      (onsetFilter)="setFilter($event)"></app-components-filters>
            </mat-menu>
          </div>
          <div class="search" style="display: inline-block;">
            <span class="search-control"><img src="assets/searchicon.png"/></span>
            <input id="filter-text-box" type="text" placeholder="filter" (input)="quickSearch()"
                   class="search-control-area"
                   placeholder="Search">
          </div>
        </div>

        <div class="col-md-4 pad0">
          <div class="filters">
            <ul class="list-unstyled">
              <li>
                <div (click)="getInvoices()" style="display: inline-block;">
                  <button (click)="getInvoices()" style="position: relative;margin-top: 2.5%;margin-left: -86%;" class="btn blue-btn">GO</button>
                </div>
              </li>
              <li style="margin-right: 1px !important;margin-left: 1px !important;" class="margLR">
                <div class="date-picker">
                  <span class="datepicker-icon"><img src="../../assets/date.png"></span>
                  <input [selectMode]="'range'" [readonly]="true" [(ngModel)]="myDate" [owlDateTimeTrigger]="dt"
                         [owlDateTime]="dt" placeholder="Date">
                  <span class="dropdown-icon"><img src="../../assets/dropdown.png"></span>
                  <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-12 pad0">
        <div style="margin: 5px;">
          <ng-container style="margin: 5px;" *ngFor="let displayData of this.userData.filterData.displayData">
            <div title="Remove Filter" class="inputfilter" (click)="removeFilter(displayData)">
              <span class="filtername">{{displayData.name}}</span>
              <span class="filtervalue">{{displayData.value}}<i style="margin-left: 5px;cursor:pointer"
                                                                class="fal fa-times"></i></span>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-md-12 pad0 mt-2">
        <div>
          <ag-grid-angular id="myGrid1" *ngIf="dataLoaded" style="height: 62vh" class="ag-theme-balham"
                           [columnDefs]="columnDefs" [gridOptions]="gridOptions" [context]="context"
                           (cellClicked)="onCellClicked($event)"
                           (rowSelected)="onRowSelected($event)"
                           (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents">
          </ag-grid-angular>
          <div *ngIf="preloader">
            <app-loadingstate></app-loadingstate>
          </div>
          <div *ngIf="emptyState">
            <app-emptystate></app-emptystate>
          </div>
          <app-error-handler
            [errorObject]="errorObject"
            [errorExists]="errorExists">
          </app-error-handler>
        </div>
      </div>
    </div>
    <div class="button-row" style="margin-top: 10px;">
      <div style="float: right; margin: 15px 30px 15px 0px;">
          <span style=" vertical-align:bottom;margin-top: 20px; margin-right: 15px;">
           <a class="btn grey-btn">CANCEL</a>
        </span>
        <button
          *ngIf="invoiceSelected"
          mat-button
          class="btn confirm-green-btn"
          data-dismiss="modal"
          (click)="addInvoices()">
          DONE
        </button>
      </div>
    </div>
  </div>
</section>
