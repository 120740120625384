import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-eodcarddesc2',
  templateUrl: './eodcarddesc2.component.html',
  styleUrls: ['./eodcarddesc2.component.scss']
})
export class EodcarddescComponent2 implements OnInit {
  @Input() title: string;
  @Input() achv: string;
  @Input() target: string;
  @Input() perc: string;

  constructor() { }

  ngOnInit() {
  }

}

