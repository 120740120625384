import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {formatDate} from '@angular/common';
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {ErrorHandler} from '../../error/error-handler';

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class PricelistReportV2Component extends ReportBase implements IReport, OnInit  {

  public attributeTypes = [];
  apiData: any = [];

  setTitle() {
    this.title = 'Price List Master';
  }

  ngOnInit() {

    this.sharedService.setHeader('Price List Master');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.showDate = false;

    this.setTitle();
    this.API_URL = '/api/pwa_reports/getPricelistReport';
      }

  configureGrid() {

    this.columnDefs = [
      { headerName: "PRICE LIST NAME",  field: "pricelist", enableRowGroup: true,    width: 100},
      { headerName: "CATEGORY",  field: "category",  enableRowGroup: true,   width: 80},
      { headerName: "PRODUCT CODE",  field: "code",   enableRowGroup: true,  width: 80},
      { headerName: "PRODUCT NAME",  field: "product",  enableRowGroup: true,   width: 120},
    ];

    if ( this.apiData.prd_attr != null) {
      this.attributeTypes = this.apiData.prd_attr.map(p => p.attribute)
        .filter((type, index, arr) => arr.indexOf(type) === index);

      for (const key in this.attributeTypes) {
        this.columnDefs.push({
          headerName: this.attributeTypes[key],
          field: this.attributeTypes[key],
          width: 100,
          aggFunc: 'sum'
        });
      }
    }

    this.columnDefs.push(
      { headerName: "FAMILY CODE",  field: "family_code", enableRowGroup: true,   width: 80},
      { headerName: "FAMILY NAME",  field: "family",  enableRowGroup: true,   width: 120},
      { headerName: "MODE",  field: "mode",  hide: true, enableRowGroup: true,   width: 80},
      { headerName: "MRP",  field: "mrp",    width: 80},
      { headerName: "PTR",  field: "price",    width: 80},
      { headerName: "MARGIN",  field: "margin_perc",  enableRowGroup: true,  width: 80},
      { headerName: "FIXED COST",  field: "fixed_cost",    width: 80},
      { headerName: "EXTRA MARIN",  field: "extra_margin",    width: 80},
      { headerName: "MIN MARGIN",  field: "price_min_margin",    width: 80},
      { headerName: "MAX MARGIN",  field: "price_max_margin",    width: 80},
      { headerName: "FINAL PRICE",  field: "final_price",    width: 80},
      { headerName: "TAX IN PRICE",  field: "price_include",    width: 80},
      { headerName: "TAX",  field: "tax",    width: 80}
    );

  }

  loadServerData() {
    this.goClicked = true;
    //debugger;
    /*   const t = trace(this.appModule.perf, this.sharedService.headerTitleSource.value);
       t.start();
       t.putAttribute('companyKey', localStorage.getItem('company_key'));*/
    console.log(this.sharedService.headerTitleSource.value);
    this.dataLoaded = false;
    this.preloader = true;
    this.emptyState = false;
    this.errorExists = false;

    if (this.API_URL === '') {
      return this.handleError(ErrorHandler.getErrorObject('API Not Configured'));
    }

    this.userData.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.userData.end_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    this.apiService.post(this.API_URL, this.userData)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            //this.rowData = res.results.data;
            this.apiData = res.results.data;
            this.configureGrid();
            this.configureData();
            this.dataLoaded = true;
            this.preloader = false;
            this.emptyState = (this.rowData.length === 0);
          } else if (res.results.status === 203) {
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        // tslint:disable-next-line:no-shadowed-variable
        error => this.handleError(ErrorHandler.getErrorObject(error)));

  }

  configureData() {
    this.rowData = [];
    let masterRow: any = {};
    masterRow = this.apiData.master;
    const inThis = this;
    for (const key in masterRow) {
      let indRow = {};
      const attRow = [];

      for (const key1 in inThis.attributeTypes) {
        attRow[inThis.attributeTypes[key1]] = this.apiData.prd_attr.filter(api => api.prd_id === masterRow[key].prd_id
          && api.attribute === inThis.attributeTypes[key1]);
        if (attRow[inThis.attributeTypes[key1]] != null && attRow[inThis.attributeTypes[key1]].length > 0) {
          attRow[inThis.attributeTypes[key1]] = attRow[inThis.attributeTypes[key1]][0].value;
        }
      }

      indRow = {
        ...masterRow[key],
        ...attRow
      };
      this.rowData.push(indRow);
    }
  }

}
