import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {GridsterItem} from 'angular-gridster2';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-widget-line-chart',
  template: `
    <div class="info-box">
      <span class="heading">{{widget.data.label}}</span>
      <div style="height: 78%; width: 100%; margin-top: 10px;">
        <ngx-charts-line-chart
          [scheme]="colorScheme"
          [results]="graphData"
          [gradient]=true
          [xAxis]=true
          [yAxis]=true
          [legend]=true
          [legendPosition]="'below'"
          [legendTitle]="''"
          [showXAxisLabel]=false
          [showYAxisLabel]=false
          [xAxisLabel]="widget.data.x_label"
          [yAxisLabel]="widget.data.y_label">
          <!--<ng-template #tooltipTemplate let-model="model">
            <div style="padding: 4px;">{{ model.name}}</div>
            <div style="padding-bottom: 4px;font-size: 16px;">{{ formatDataLabel(model.value)}}</div>
          </ng-template>
          <ng-template #seriesTooltipTemplate  let-model="model">
            <div *ngFor="let tooltipItem of model">
              <div style="padding-bottom: 4px;">{{ model.name}}: {{formatDataLabel(tooltipItem.value)}}</div>
            </div>
          </ng-template>-->
        </ngx-charts-line-chart>
      </div>
    </div>`,
  styleUrls: ['./../dashboard.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LineChartWidgetComponent implements OnInit, OnDestroy {
  @Input()
  widget;
  @Input()
  resizeEvent: EventEmitter<GridsterItem>;

  resizeSub: Subscription;
  colorScheme: any = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };
  graphData;

  ngOnInit(): void {
    this.resizeSub = this.resizeEvent.subscribe(widget => {
      if (widget === this.widget) {
        // or check id , type or whatever you have there
        // resize your widget, chart, map , etc.
        console.log(widget);
      }
    });
    this.graphData = this.widget.data.value;
    this.setCustomColor();
  }

  ngOnDestroy(): void {
    this.resizeSub.unsubscribe();
  }

  setCustomColor() {
    this.colorScheme.domain = [];
    if (this.graphData && this.graphData.length > 0) {
      for (let i = 0; i < this.graphData.length; i++) {
        if (this.graphData[i]['indicator_colour']) {
          this.colorScheme.domain.push(this.graphData[i]['indicator_colour']);
        } else {
          this.colorScheme.domain = ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA'];
          break;
        }
      }
    }
  }

  formatDataLabel(value) {
    if (value) {
      if (this.widget?.data?.value_type === 'currency') {
        value = parseFloat(value);
        if (value >= 10000000) {
          return (value / 10000000).toFixed(2) + 'Cr';
        } else if (value >= 100000) {
          return (value / 100000).toFixed(2) + 'L';
        } else if (value >= 1000) {
          return (value / 1000).toFixed(2) + 'K';
        } else {
          return value;
        }
      } else {
        return value;
      }
    } else {
      return 0;
    }
  }
}
