<div class="common-wrapper">
        <div class="common-container">
          <div class="main-wrapper dashboard">
                <div class="row eodset-card">
                  <div *ngIf="vansale_cards" class="col-md-3 padR chart-top-card">
                    <app-dashcard [card_name]="'Van Details'"
                                  [title]="'Total Vans'" [value]="cardData['total_vans']"
                                  [title1]="'Loaded Out'" [value1]="cardData['loaded_out']"
                                  [title2]="'Deviations'" [value2]="cardData['deviation_count']"
                                  [title3]="'Not Loaded'" [value3]="cardData['not_loaded']"
                                  [title4]="'Settled'" [value4]="cardData['settled']"
                                  [card_img]="'./../../../assets/vandetails.svg'"
                                  [first_card]="true"
                    ></app-dashcard>
                  </div>
                  <div *ngIf="vansale_cards" class="col-md-3 padR chart-top-card">
                    <app-dashcard [card_name]="'Sales Details'"
                                  [title]="'Cash'" [value]="cardData['cash_collected']"
                                  [title1]="'Credit'" [value1]="cardData['credit_sales']"
                                  [title2]="'Unapproved'" [value2]="cardData['unapproved_orders']"
                                  [title3]="'Unsettled (TD)'" [value3]="cardData['pay_amount']"
                                  [card_img]="'./../../../assets/todayssales.svg'"
                                  [first_card]="false"
                    ></app-dashcard>
                    <img *ngIf="vansale_cards && dashDisplay" (click)="expandChart()" class="chartIcon" src="./../../assets/chart-646.svg"/>
                    <div *ngIf="vansale_cards && dashDisplay" class="chartUpdate">Last Updated On: {{last_updated}}</div>
                    <div [hidden]="!chart_show || preloader1" style="position: relative;top:110px;margin-left:15%;">
                      <label class="switch">
                        <span style="position: relative;left: -2vw;font-weight: bold;">Mon</span>
                        <input (click)="changeToYr()" type="checkbox">
                        <span class="slider round"></span>
                        <span style="position: relative;top: -2vh;padding-left:4vw;font-weight: bold;">Yr</span>
                      </label>
                    </div>
                  </div>
                  <div *ngIf="po_dash" class="col-md-3 pad0 eod-width">
                    <div class="eod-sales-card">
                      <label style="font-size: 160%;
                                    font-weight: 500;
                                    padding-right: 50px;
                                    position: relative;
                                    top: -10px;">PURCHASE ORDER(RECIEVED/PO): </label>
                      <span style="font-size: 170%;
                                    font-weight: 600;position: relative;
                                    top: -10px;">{{cardData['today']}}/{{cardData['today_po']}}</span>
                    </div>
                    <div *ngIf="po_dash" class="eod-sales-card" style="position: relative;
    left: 200%;
    top: -50%;">
                      <label style="font-size: 160%;
                                    font-weight: 500;
                                    padding-right: 50px;
                                    position: relative;
                                    top: -10px;">MTD(RECIEVED/PO): </label>
                      <span style="font-size: 170%;
                                    font-weight: 600;position: relative;
                                    top: -10px;">{{cardData['mtd']}}/{{cardData['mtd_po']}}</span>
                    </div>
                  </div>
                  <div *ngIf="dms_dash" class="col-md-3 pad0 eod-width" style="margin-top: 45px;">
                    <div class="eod-sales-card">
                      <label style="font-size: 160%;
                                    font-weight: 500;
                                    padding-right: 50px;
                                    position: relative;
                                    top: -10px;">SECONDARY ORDER(INVOICED/PLACED): </label>
                      <span style="font-size: 170%;
                                    font-weight: 600;position: relative;
                                    top: -10px;">{{cardData['inv_s_order']}}/{{cardData['s_order']}}</span>
                    </div>
                    <div class="eod-sales-card" style="position: relative;
    left: 200%;
    top: -50%;">
                      <label style="font-size: 160%;
                                    font-weight: 500;
                                    padding-right: 50px;
                                    position: relative;
                                    top: -10px;">PRIMARY ORDER(INVOICED/PLACED): </label>
                      <span style="font-size: 170%;
                                    font-weight: 600;position: relative;
                                    top: -10px;">{{cardData['inv_p_order']}}/{{cardData['p_order']}}</span>
                    </div>
                  </div>
<!--                  <div class="col-md-3 padR">-->
<!--                    <app-dashcard [title]="'Todays Unapproved Sale'" [value]="cardData['unapproved_orders']"></app-dashcard>-->
<!--                  </div>-->
<!--                  <div class="col-md-3 pad0">-->
<!--                    <app-dashcard [title]="'Unsettled Amount'" [value]="cardData['pay_amount']"></app-dashcard>-->
<!--                  </div>-->
                </div>
                <div *ngIf="preloader">
                  <div style="position:absolute;left:47%;top:13%;font-size: medium">
                    <img src="../../assets/images/preloader.gif"/>
                  </div>
                </div>
<!--            <ag-charts-angular-->
<!--              style="top: 0; right: 0; bottom: 0; left: 0;"-->
<!--              [options]="options">-->
<!--            </ag-charts-angular>-->
<!--            <div class="col-md-12 pad0">-->
<!--              <div class="mt-default" >-->
            <div *ngIf="preloader1">
              <app-loadingstate></app-loadingstate>
            </div>
            <div *ngIf="empty_state">
              <app-emptystate></app-emptystate>
            </div>
            <div #chart1 [hidden]="!(chart_show && checkedYr)" id="chart1" class="chart-1">
            </div>
            <div #chart7 [hidden]="!chart_show || (checkedYr || preloader1)" id="chart7" class="chart-1">
            </div>
            <div #chart4  [hidden]="!chart_show || preloader1" id="chart4" class="chart-4">
            </div>
            <div #chart6 [hidden]="!chart_show || preloader1" id="chart6" class="chart-6">
            </div>
            <div #chart2 [hidden]="!chart_show || preloader1" id="chart2" class="chart-2">
            </div>
            <div #chart3  [hidden]="!chart_show || preloader1" id="chart3" class="chart-3">
            </div>
            <div #chart5 [hidden]="!chart_show || preloader1" id="chart5" class="chart-5">
            </div>
            <div *ngIf="po_enable" #chart8 [hidden]="!chart_show || preloader1" id="chart8" class="chart-8">
            </div>
<!--            <div #chart9 [hidden]="!chart_show || preloader1" id="chart9" class="chart-8">-->
<!--            </div>-->
            <div *ngIf="preloader2">
              <!--<div style="position:absolute;left:44%;top:200px;font-size: medium">-->
              <!--<img src="../../assets/images/preloader.gif"/>-->
              <!--</div>-->
              <app-loadingstate></app-loadingstate>
            </div>
            <div *ngIf="empty_state2 ">
              <app-emptystate></app-emptystate>
            </div>
            <div *ngIf="chart_show && po_enable" class="custom-legend" >
              <ul class="legend" *ngFor="let categ of categ_class">
                <li><span class={{categ.class}}></span>{{categ.name}}</li>
              </ul>
            </div>
            <div *ngIf="chart_show && !po_enable" class="custom-legend" style="bottom:123vh;">
              <ul class="legend" *ngFor="let categ of categ_class">
                <li><span class={{categ.class}}></span>{{categ.name}}</li>
              </ul>
            </div>

            <!--                <ag-grid-angular id="myGrid1" *ngIf="loaded" style="width: 50%;height: 0vh;" class="ag-theme-balham"  [columnDefs]="columnDefs" [gridOptions]="gridOptions"-->
<!--                                 (gridReady)="onGridReady($event)">-->
<!--                </ag-grid-angular>-->
<!--              </div>-->
<!--            </div>-->
            <div class="col-md-12 pad0">
              <div class="mt-default" >
<!--                <div #chart2 *ngIf="loaded3" id="chart2" style="width: 50%;height: 56vh;margin-top: 2%;">-->
<!--                </div>-->
<!--                <div #chart3 *ngIf="loaded4" id="chart3" style="width: 70%;height: 56vh;margin-top: 2%;">-->
<!--                </div>-->
<!--                <div #chart6 *ngIf="loaded5" id="chart6" style="width: 50%;height: 56vh;margin-top: 2%;">-->
<!--                </div>-->
<!--                <div #chart4 *ngIf="loaded3" id="chart4" style="width: 49%;height: 56vh;margin-top: 2%;">-->
<!--                </div>-->
<!--                <div #chart5 *ngIf="loaded2" id="chart5" style="width: 49%;height: 56vh;margin-top: 2%;float:right;position:relative;bottom: 435px;">-->
<!--                </div>-->
<!--                <ag-grid-angular id="myGrid1" *ngIf="loaded2" style="width: 50%;height: 0vh;" class="ag-theme-balham"-->
<!--                                 [columnDefs]="columnDefs2" [gridOptions]="gridOptions2"-->
<!--                                 (gridReady)="onGridReady2($event)">-->
<!--                </ag-grid-angular>-->
<!--                <ag-grid-angular id="myGrid1" *ngIf="loaded5" style="width: 50%;height: 0vh;" class="ag-theme-balham"-->
<!--                                 [columnDefs]="columnDefs6" [gridOptions]="gridOptions6"-->
<!--                                 (gridReady)="onGridReady6($event)">-->
<!--                </ag-grid-angular>-->
<!--                <ag-grid-angular id="myGrid1" *ngIf="loaded3" style="width: 50%;height: 0vh;" class="ag-theme-balham"-->
<!--                                 [columnDefs]="columnDefs4" [gridOptions]="gridOptions4"-->
<!--                                 (gridReady)="onGridReady4($event)">-->
<!--                </ag-grid-angular>-->
<!--                <ag-grid-angular id="myGrid1" *ngIf="loaded4" style="width: 50%;height: 0vh;" class="ag-theme-balham"-->
<!--                                 [columnDefs]="columnDefs5" [gridOptions]="gridOptions5"-->
<!--                                 (gridReady)="onGridReady5($event)">-->
<!--                </ag-grid-angular>-->
<!--                <div *ngIf="preloader2">-->
<!--                  &lt;!&ndash;<div style="position:absolute;left:44%;top:200px;font-size: medium">&ndash;&gt;-->
<!--                  &lt;!&ndash;<img src="../../assets/images/preloader.gif"/>&ndash;&gt;-->
<!--                  &lt;!&ndash;</div>&ndash;&gt;-->
<!--                  <app-loadingstate></app-loadingstate>-->
<!--                </div>-->
<!--                <div *ngIf="empty_state2 ">-->
<!--                  <app-emptystate></app-emptystate>-->
<!--                </div>-->
              </div>
            </div>
<!--            <div class="col-md-12 pad0">-->
<!--              <div class="mt-default" >-->
<!--                <div #chart3 *ngIf="loaded2" id="chart3" style="width: 70%;height: 56vh;margin-top: 2%;">-->
<!--                </div>-->
<!--                <div *ngIf="preloader2">-->
<!--                  &lt;!&ndash;<div style="position:absolute;left:44%;top:200px;font-size: medium">&ndash;&gt;-->
<!--                  &lt;!&ndash;<img src="../../assets/images/preloader.gif"/>&ndash;&gt;-->
<!--                  &lt;!&ndash;</div>&ndash;&gt;-->
<!--                  <app-loadingstate></app-loadingstate>-->
<!--                </div>-->
<!--                <div *ngIf="empty_state2 ">-->
<!--                  <app-emptystate></app-emptystate>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
                <!--<div class="dashboard-card hide">-->
                        <!--<h1>DAILY RETURNS</h1>-->
                        <!--<div class="dashboard-filters">-->
                                <!--<ul class="list-unstyled">-->
                                    <!--<li class="margLR">-->
                                        <!--<app-multiselect></app-multiselect>-->
                                    <!--</li>-->
                                    <!--<li class="margLR">-->
                                        <!--<div class="date-picker">-->
                                            <!--<span class="datepicker-icon"><img src="../../assets/date.png"></span>-->
                                            <!--<input [readonly]="true" [owlDateTimeTrigger]="dt" [owlDateTime]="dt" placeholder="Date">-->
                                            <!--<span class="dropdown-icon"><img src="../../assets/dropdown.png"></span>-->
                                            <!--<owl-date-time [pickerType]="'calendar'" #dt></owl-date-time>-->
                                        <!--</div>-->
                                    <!--</li>-->
                                    <!--<li class="margLR">-->
                                        <!--<div class="sales-tabs">-->
                                            <!--<div class="sales-tab active">-->
                                                <!--<label>Sales in Value</label>-->
                                            <!--</div>-->
                                            <!--<div class="sales-tab">-->
                                                <!--<label>Sales in Qty </label>-->
                                            <!--</div>-->
                                        <!--</div>-->
                                    <!--</li>-->
                                <!--</ul>-->
                        <!--</div>-->
                        <!--<div class="line-chart">-->
                                <!--<jqxChart #myChart-->
                                <!--[width]="getWidth1()" [height]="300"-->
                                <!--[title]="null"-->
                                <!--[description]="null"-->
                                <!--[showLegend]="true" [enableAnimations]="true" [padding]="padding"-->
                                <!--[titlePadding]="titlePadding" [source]="sampleData1" [xAxis]="xAxis1"-->
                                <!--[valueAxis]="valueAxis" [seriesGroups]="seriesGroups1" [colorScheme]="'scheme05'">-->
                            <!--</jqxChart>-->
                        <!--</div>-->
                <!--</div>-->
                <!--<div class="dashboard-card hide">-->
                    <!--<h1>STOCK REPORT</h1>-->
                    <!--<div class="bar-chart">-->
                        <!--<jqxChart-->
                            <!--[width]="getWidth()" [height]="350"-->
                            <!--[title]="null"-->
                            <!--[description]="null"-->
                            <!--[showLegend]="true" [enableAnimations]="true" [padding]="padding"-->
                            <!--[titlePadding]="titlePadding" [source]="sampleData" [xAxis]="xAxis"-->
                            <!--[seriesGroups]="seriesGroups" [colorScheme]="'scheme01'">-->
                        <!--</jqxChart>-->
                    <!--</div>-->
                <!--</div>-->
          </div>
        </div>
      </div>

