<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Reject Retailer</h2>
    </div>
    <div class="body-section" style="margin-top: 20px;">
      <!--<div class="user-input-wrp" style="margin-top: 40px;">
        <br/>
        <input
          type="text"
          class="inputText"
          [(ngModel)]="rejectComments"
          required/>
        <span class="floating-label">Enter Comments</span>
      </div>-->
      <span>Reason</span>
      <div style="margin-top: 5px;" class="formselect">
        <select name="comments"
                id="reason"
                style="width: 50%"
                [(ngModel)]="reasonID"
                #reason="ngModel">
          <option
            *ngFor="let item of beatActivityData"
            value="{{ item.id }}"
          > {{ item.name }}</option>
        </select>
      </div>


      <div style="margin-top: 20px;">
        <span >Comments</span>
        <input
          class="forminput"
          style="width: 100%;"
          type="text"
          name="comment"
          [(ngModel)]="comments"
          #comment="ngModel"
        >
      </div>
      <div style="margin-top: 20px;">
        <button (click)="performAction()" data-dismiss="modal" class="btn red-btn">Reject</button>
      </div>
    </div>
  </div>
</section>
