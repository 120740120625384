import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {ErrorHandler} from "../../error/error-handler";

@Component({
  selector: 'app-day-wise-summary-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class VanDayWiseSummaryReportComponent extends ReportBase implements IReport, OnInit {
  product_codes = [];

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Van Day Wise Summary');

    this.showableFilters.salesman = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.dateFilterType = 'single';

    const date = new Date();
    this.myDate[0] = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.API_URL = '/api/pwa_reports/getVanDayWiseSummaryReport';
  }

  loadServerData() {
    this.goClicked  = true;
    this.dataLoaded = false;
    this.preloader = true;
    this.emptyState = false;
    this.errorExists = false;

    if (this.API_URL === '') {
      return this.handleError(ErrorHandler.getErrorObject('API Not Configured'));
    }

    if (this.showCustomFilter) {
      if ( this.userData.customFilter != null && !this.userData.customFilter.hasOwnProperty(this.customFilter.key)) {
        this.userData.customFilter[this.customFilter.key] = this.customFilter.value;
      }
    }

    if (this.dateSelectMode === 'single') {
      this.userData.start_date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
      this.userData.end_date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    } else {
      this.userData.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
      this.userData.end_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    }
    this.apiService.post(this.API_URL, this.userData)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.prepareGridData(res.results.data);

            this.dataLoaded = true;
            this.preloader = false;
            this.emptyState = (this.rowData.length === 0);
          } else if (res.results.status === 203) {
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        error => this.handleError(ErrorHandler.getErrorObject(error)));
  }


  prepareGridData(apiData) {
    this.rowData = [];
    this.product_codes = [];

    apiData.forEach(row => {
      row.products.forEach(item => {
        if (!(this.product_codes.includes(item.code))) {
          this.product_codes.push(item.code);
        }

        row[item.code] = item.qty;
      });
    });

    this.columnDefs = [];
    this.columnDefs = [
      {headerName: 'REGION', field: 'region', width: 100,  enableRowGroup: true},
      {headerName: 'CLUSTER', field: 'cluster', width: 100,  enableRowGroup: true},
      {headerName: 'VAN', field: 'van', width: 100, enableRowGroup: true},
      {headerName: 'BEAT', field: 'beat_name', width: 100,  enableRowGroup: true}
    ];

    this.product_codes.forEach(key => {
      this.columnDefs.push({
        headerName: key,
        field: key,
        cellStyle: {textAlign: 'center'},
        width: 100,
        aggFunc: 'sum'
      });
    });

    this.columnDefs.push({headerName: 'Value', field: 'sales_value', pivot: false,  width: 80, aggFunc: 'sum'});

    this.rowData = apiData;
  }

  configureGrid() {
  }

}
