<div class="dash-card">

    <app-gadget-header
            (mouseover)="showGadgetControls(true)"
            (mouseleave)="showGadgetControls(false)"
            [globalOptions]="globalOptions"
            [title]="title"
            [inRun]="inRun"
            [inConfig]="inConfig"
            [actionInitiated]="actionInitiated"
            [showOperationControls]="showOperationControls"
            [gadgetHasOperationControls]="gadgetHasOperationControls"
            [showConfigurationControl]="showConfigurationControl"
            [showControls]="showControls"
            [showDeleteControl]="showDeleteControl"
            (toggleConfigModeEvent)="toggleChartProperties()"
            (removeEvent)="remove()"
            (runEvent)="run()"
            (stopEvent)="stop()">
    </app-gadget-header>


    <div *ngIf="dataUpdated">
        <div *ngIf="!inConfig" style="min-height: 300px">
          <combo-chart-component
            [view]="view"
            [scheme]="comboBarScheme"
            [colorSchemeLine]="lineChartScheme"
            [results]="comboData.barChart"
            [animations]="animations"
            [lineChart]="comboData.lineChartSeries"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [legendTitle]="legendTitle"
            [legendPosition]="legendPosition"
            [showGridLines]="showGridLines"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [showRightYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [yAxisLabelRight]="yAxisLabelRight">
          </combo-chart-component>
        </div>

    </div>

    <!-- use the side bar content -->
    <div *ngIf="globalOptions.displayGadgetOptionsInSideBar == true" class="ui sidebar left vertical menu"
         #chartOptionsSideBar_tag>
        <br>
        <div class="ct-title">{{title}}</div>
        <br>
        <app-dynamic-form
                [gadgetTags]="gadgetTags"
                [propertyPages]="propertyPages"
                [instanceId]="instanceId"
                (updatePropertiesEvent)="updateProperties($event)">
        </app-dynamic-form>
    </div>


    <app-dynamic-form *ngIf="inConfig && globalOptions.displayGadgetOptionsInSideBar == false"
                      [gadgetTags]="gadgetTags"
                      [propertyPages]="propertyPages"
                      [instanceId]="instanceId"
                      (updatePropertiesEvent)="updateProperties($event)">
    </app-dynamic-form>

    <app-error-handler
            [errorObject]="errorObject"
            [errorExists]="errorExists">
    </app-error-handler>

<!--    <app-vis-drill-down-modal #drillDownPopUp></app-vis-drill-down-modal>-->

</div>


