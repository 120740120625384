// @ts-ignore
import {Component, OnInit} from '@angular/core';
// @ts-ignore
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class SalesmanSubTargetAchievementMonthReportComponent extends ReportBase implements IReport, OnInit {

  showCustomFilter = true;
  customFilter: any = {
    name: 'Type',
    key: 'type',
    value: 'secondary',
    values: [],
    show: true
  };
  apiData: any = [];

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Salesman - Month Wise');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.state = true;
    this.showableFilters.region = true;
    this.showDate = true;
    const date = new Date();
    this.myDate[0] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd');
    this.userData.start_date = this.myDate[0];
    this.userData.end_date = this.myDate[1];
    this.customFilter.values = [
      {id: 'primary', itemName: 'Primary'},
      {id: 'secondary', itemName: 'Secondary'},
      {id: 'tertiary', itemName: 'Tertiary'}
    ];
    this.gridOptions.pivotMode = true;
    this.gridOptions.autoGroupColumnDef = {
      headerName: 'GROUP',
      minWidth: 250,
      cellRenderer: 'agGroupCellRenderer',
      pinned: 'left'
    };

    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getSalesmanSubTargetvsAchievementReport';

    this.columnDefs = [
      {headerName: 'REGION', field: 'region', width: 120},
      {headerName: 'STATE', field: 'state', width: 120},
      {headerName: 'SCODE', field: 'emp_code', width: 80},
      {headerName: 'SALESMAN', field: 'salesman', width: 150, rowGroup: true},
      {headerName: 'TEAM NAME', field: 'team_name', width: 150, rowGroup: true},
      {headerName: 'TEAM LEAD', field: 'tl_name', width: 150, rowGroup: true},
      {headerName: 'TL CODE', field: 'tl_code', width: 150, rowGroup: true},
      {headerName: 'PARENT TEAM NAME', field: 'parent_team', width: 150, rowGroup: true},
      {headerName: 'PARENT TL NAME', field: 'parent_tl', width: 150, rowGroup: true},
      {headerName: 'PARENT TL CODE', field: 'parent_tl_code', width: 150, rowGroup: true},
      {
        headerName: 'TARGET TYPE', field: 'target_type', width: 150, rowGroup: true,
        enableRowGroup: true, hide: true
      },
      {
        headerName: 'MEASURE TYPE', field: 'measure_type', width: 150,
        enableRowGroup: true
      },
      {
        headerName: 'RESOURCE', field: 'resource_name', menuTabs: [], width: 150,
        enableRowGroup: true
      },
      {
        headerName: 'MONTH', field: 'jc_name', menuTabs: [], width: 150, pivot: true,
        enablePivot: true, pivotComparator: this.MyMonthPivotComparator
      },
      /* {
         headerName: 'MAIN TARGET', field: 'main_target', enableRowGroup: true, width: 120, aggFunc: 'sum',
         valueGetter: params => {
           if (params.data) {
             if (params.data.main_target !== null) {
               return params.data.main_target;
             } else {
               return 0;
             }
           }
         }
       },
       {
         headerName: 'MAIN ACHIEVEMENT', field: 'main_achievement', width: 120, aggFunc: 'sum',
         valueGetter: params => {
           if (params.data) {
             if (params.data.main_achievement !== null) {
               return params.data.main_achievement;
             } else {
               return 0;
             }
           }
         }
       },
       {
         headerName: 'MAIN PROGRESS', field: 'progress', width: 120, aggFunc: 'sum',
         valueGetter: params => {
           if (params.data) {
             if (params.data.main_target && params.data.main_achievement) {
               if (params.data.main_target === null || parseInt(params.data.main_target) === 0 || params.data.main_achievement === null || parseInt(params.data.main_achievement) === 0) {
                 return 0;
               } else {
                 return Math.trunc((parseInt(params.data.main_achievement) / parseInt(params.data.main_target)) * 100);
               }
             } else {
               return 0;
             }
           }
         },
         cellRenderer: params => {

           if (params.value === null) {
             return '';
           } else {
             let displayElem = '<div class="row">\n' +
               '            <div class="progresscontainer">\n' +
               '              <div class="progressinside" style=" ';
             if (params.value > 50) {
               displayElem = displayElem + ' background-color: #379862;';
             } else if (params.value > 20) {
               displayElem = displayElem + ' background-color: #D97C0E;';
             } else {
               displayElem = displayElem + ' background-color: #E24260;';
             }

             displayElem = displayElem + 'width:' + params.value + '%;"></div>\n' +
               '            </div>\n' +
               '            <div class="progresstext" style="';

             if (params.value > 50) {
               displayElem = displayElem + ' color: #379862;';
             } else if (params.value > 20) {
               displayElem = displayElem + ' color: #D97C0E;';
             } else {
               displayElem = displayElem + ' color: #E24260;';
             }

             displayElem = displayElem + '">' + params.value + '%</div></div>';

             return displayElem;
           }
         }
       },*/
      {
        headerName: 'TARGET', field: 'target', type: 'numericColumn', enableRowGroup: true, width: 120, aggFunc: 'sum',
        valueGetter: params => {
          if (params.data) {
            if (params.data.target !== null) {
              return parseFloat(params.data.target.toFixed(2));
            } else {
              return 0;
            }
          }
        }
      }, {
        headerName: 'PLANNED', field: 'planned', type: 'numericColumn', enableRowGroup: true, width: 120, aggFunc: 'sum',
        valueGetter: params => {
          if (params.data) {
            if (params.data.planned !== null) {
              return parseFloat(params.data.planned.toFixed(2));
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: 'ACHIEVEMENT', field: 'achievement', type: 'numericColumn', width: 120, aggFunc: 'sum',
        valueGetter: params => {
          if (params.data) {
            if (params.data.achievement !== null) {
              return parseFloat(params.data.achievement.toFixed(2));
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: 'PROGRESS', field: 'progress', minWidth: 180, menuTabs: [], aggFunc: this.progAvg,
        valueGetter: params => {
          if (params.data) {
            /*if (params.data.target && params.data.achievement) {
              if (params.data.target === null || parseInt(params.data.target) === 0 || params.data.achievement === null || parseInt(params.data.achievement) === 0) {
                return 0;
              } else {
                return Math.trunc((parseInt(params.data.achievement) / parseInt(params.data.target)) * 100);
              }
            } else {
              return 0;
            }*/
            if (!(params.node && params.node.group)) {
              let avg = 0;
              if (params.data.target && params.data.target > 0) {
                avg = (params.data.achievement / params.data.target) * 100;
              }
              return {
                target: params.data.target,
                achievement: params.data.achievement,
                toString: () => avg.toFixed(2),
              };
            }
          }
        },
        cellRenderer: params => {
          let displayElem = '<div class="row">\n' +
            '            <div class="progresscontainer1">\n' +
            '              <div class="progressinside1" style=" ';

          if (params.value > 50) {
            displayElem = displayElem + ' background-color: #379862;';
          } else if (params.value > 20) {
            displayElem = displayElem + ' background-color: #D97C0E;';
          } else {
            displayElem = displayElem + ' background-color: #E24260;';
          }
          let prog = params.value;
          if (params.value && parseFloat(params.value) > 100) {
            prog = 100;
          }
          displayElem = displayElem + 'width:' + prog + '%;"></div>\n' +
            '            </div>\n' +
            '            <div class="progresstext1" style="';

          if (params.value > 50) {
            displayElem = displayElem + ' color: #379862;';
          } else if (params.value > 20) {
            displayElem = displayElem + ' color: #D97C0E;';
          } else {
            displayElem = displayElem + ' color: #E24260;';
          }

          displayElem = displayElem + '">' + params.value + '%</div></div>';


          return displayElem;
        }
      },
    ];

  }

  MyMonthPivotComparator(a, b) {
    return b - a;
  }

  configureData() {
    let key;
    this.rowData = [];
    if (this.apiData.target != null) {
      for (key in this.apiData.target) {

        let indrow = {};
        let targetrow: any = {};
        let subtargetrow: any = {};

        targetrow = this.apiData.target[key];
        //this.apiData.subtarget = [];
        if (this.apiData.subtarget != null && this.apiData.subtarget.length > 0) {
          subtargetrow = this.apiData.subtarget.filter(subtarget => subtarget.master_id === targetrow.id);

          if (subtargetrow && subtargetrow.length > 0) {
            for (key in subtargetrow) {
              const indrow1 = subtargetrow[key];
              indrow = {
                ...targetrow,
                ...indrow1
              };

              this.rowData.push(indrow);
            }
          } else {
            this.rowData.push(targetrow);
          }
        } else {
          this.rowData.push(targetrow);
        }
      }
      this.rowData.sort((a, b) => b.month_id - a.month_id);
    }
  }

  mainAggFunction(params) {
    let avg;
// the average will be the sum / count
    let sum = 0;
    let count = 0;
    let salesmanGroup = false;
    let loopParam = params.rowNode;
    while (loopParam.hasOwnProperty('parent')) {
      if (loopParam.parent.field === 'salesman') {
        salesmanGroup = true;
      }
      loopParam = loopParam.parent;
    }
    if (params.rowNode.hasOwnProperty('rowGroupColumn') && params.rowNode.rowGroupColumn.colDef.headerName === 'SALESMAN') {
      salesmanGroup = true;
    }

    params.values.forEach(function(value) {
      const groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        // we are aggregating groups, so we take the
        // aggregated values to calculated a weighted average
        if (!salesmanGroup) {
          // sum += value.avg * value.count;
          sum += value.avg;
          // count += value.count;
        } else {
          sum = value.avg;
        }
        /*  sum += value;
          count += value.count;*/
      } else {
        // skip values that are not numbers (ie skip empty values)
        if (typeof value === 'number') {
          //sum += value;
          sum = value;
          //count++;
        } else {
        }
      }
    });
    // avoid divide by zero error
    if (count !== 0) {
      avg = sum / count;
    } else {
      avg = null;
    }

    //avg = Math.round(Math.round((avg + Number.EPSILON) * 100) / 100);

    avg = sum;
    count = sum;

    // the result will be an object. when this cell is rendered, only the avg is shown.
    // however when this cell is part of another aggregation, the count is also needed
    // to create a weighted average for the next level.
    const result = {
      count,
      avg,
      // the grid by default uses toString to render values for an object, so this
      // is a trick to get the default cellRenderer to display the avg value
      toString() {
        return this.avg;
      },
    };

    return result;
  }

  progAvg(params) {
    let targetSum = 0;
    let achievementSum = 0;
    let avg = 0;
    params.values.forEach(value => {
      if (value && value.target) {
        targetSum += value.target;
      }
      if (value && value.achievement) {
        achievementSum += value.achievement;
      }
    });
    if (targetSum && targetSum > 0) {
      avg = (achievementSum / targetSum) * 100;
    }
    return {
      target: targetSum,
      achievement: achievementSum,
      toString: () => {
        return avg.toFixed(2);
      },
    };
  }

  avgAggFunction(params) {
    let avg;
// the average will be the sum / count
    let sum = 0;
    let count = 0;

    params.values.forEach(function(value) {
      const groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        // we are aggregating groups, so we take the
        // aggregated values to calculated a weighted average
        sum += value.avg * value.count;
        count += value.count;
      } else {
        // skip values that are not numbers (ie skip empty values)
        if (typeof value === 'number') {
          sum += value;
          count++;
        }
      }
    });
    // avoid divide by zero error
    if (count !== 0) {
      avg = sum / count;
    } else {
      avg = null;
    }

    avg = Math.round(Math.round((avg + Number.EPSILON) * 100) / 100);

    // the result will be an object. when this cell is rendered, only the avg is shown.
    // however when this cell is part of another aggregation, the count is also needed
    // to create a weighted average for the next level.
    const result = {
      count,
      avg,
      // the grid by default uses toString to render values for an object, so this
      // is a trick to get the default cellRenderer to display the avg value
      toString() {
        return this.avg.toFixed(2);
      },
    };

    return result;
  }


  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllColumns().length;

    params.api.setRowData(this.rowData);
    this.preloader = false;
    const inThis = this;

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        params.columnApi.autoSizeAllColumns();
      });
    });

    params.columnApi.autoSizeAllColumns();

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');

    /* const columnState = JSON.parse(localStorage.getItem('myColumnState'));
     if (columnState) {
       this.gridColumnApi.applyColumnState(
         {
           state: columnState,
           applyOrder: true,
         }
       );
     }*/

    /*fetch('C:\Users\Nivil\Downloads\pwaconfig.txt').then(response => response.text())
      .then(data => {
        // Do something with your data
        console.log(data);
      });*/
  }

  changeDate(dt) {
    const temp = this.myDate;
    if (temp !== null) {
      this.myDate = [];
      this.myDate[0] = this.datePipe.transform(new Date(temp[0].getFullYear(), temp[0].getMonth(), 1), 'yyyy-MM-dd');
      this.myDate[1] = this.datePipe.transform(new Date(temp[1].getFullYear(), temp[1].getMonth() + 1, 0), 'yyyy-MM-dd');

      this.userData.start_date = this.myDate[0];
      this.userData.end_date = this.myDate[1];

      this.changeDetector.detectChanges();
    }

  }
}
