import {Component, ElementRef, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {DomSanitizer} from "@angular/platform-browser";
import {ToastrService} from "ngx-toastr";
import {SharedService} from "../../_services/shared_service";
import {AssignServiceEngineerPopupComponent} from "../assign_service_engineer_popup/assign_service_engineer_popup.component";
import {ServiceProductPopupComponent} from "../../components/service_product_popup/service_product_popup.component";

@Component({
  selector: 'app-create-service-request-popup',
  templateUrl: './create_service_request.component.html',
  styleUrls: ['./create_service_request.component.scss']

})

export class CreateServiceRequestPopupComponent implements OnInit {

  @Output() createServiceEvent = new EventEmitter<boolean>();
  serviceDetails: any = {};
  priority = 0;
  stars = [1, 2, 3];
  selected: number;
  hover: number;
  customerList = null;
  assetList = null;
  productList = null;
  assetLinked = false;
  stateList = [];
  userData = {
    access_token: null,
    partner_id: 0,
    distributor_id: 0,
    territory_id: 0
  };
  service_types = [];
  dataLoaded = false;
  selectedProductCount = 0;
  sources = [];

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              public datePipe: DatePipe,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private domSanitizer: DomSanitizer,
              private toastr: ToastrService,
              private sharedService: SharedService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.userData.access_token = localStorage.getItem('resfreshToken');
    this.serviceDetails.ppf = 'company';
    this.serviceDetails.referrer_type = 'company';
    this.serviceDetails.warranty = false;
    this.stateList = JSON.parse(localStorage.getItem('all_states'));
    this.sources = JSON.parse(localStorage.getItem('sources'));
    if (localStorage.getItem('sources')) {
      try {
        this.sources = JSON.parse(localStorage.getItem('sources'));
      } catch (e) {
      }
    }
    if (this.sources && this.sources.length > 0) {
      this.serviceDetails.source = this.sources[0].name;
      this.fetchCustomerData();
    } else {
      this.toastr.error("Sources not configured");
      this.dialog.closeAll();
    }
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  async fetchCustomerData() {
    const res = await this.apiService.postPromise('/api/res_partners/get_customer_sel_list', this.userData);
    if (res && res.results.status === 200) {
      this.customerList = [];
      this.customerList = res.results.data;
      if (!this.customerList || this.customerList.length === 0) {
        this.toastr.error("Customers not configured");
        this.dialog.closeAll();
      } else {
        this.serviceDetails.customer_id = this.customerList[0].id;
        this.customerChange(this.serviceDetails.customer_id);
        this.fetchServiceTypes();
      }
    } else {
      this.toastr.error('Error fetching customer list');
    }
  }

  async fetchServiceTypes() {
    const res = await this.apiService.postPromise('/api/service_types/get_service_types', this.userData);
    if (res && res.results.status === 200) {
      this.service_types = res.results.data;
      if (!this.service_types || this.service_types.length === 0) {
        this.toastr.error("Service Types not configured");
        this.dialog.closeAll();
      } else {
        this.serviceDetails.service_type = this.service_types[0].id;
        this.dataLoaded = true;
      }
    } else {
      this.toastr.error('Error fetching Service Types');
    }
  }

  assetLinkedChange(event) {
    if (this.assetLinked && this.serviceDetails && this.serviceDetails.customer_id) {
      if (!this.assetList || this.assetList.length === 0) {
        this.toastr.error("No Assets for Customer!!");
      } else {
        if (!this.serviceDetails.partner_asset_id || this.serviceDetails.partner_asset_id === 0) {
          this.serviceDetails.partner_asset_id = this.assetList[0].id;
        }
      }
      if (!this.productList || this.productList.length === 0) {
        this.toastr.error("No Products for Customer!!");
      }
    }
  }

  customerChange(event) {
    console.log(event);
    this.userData.partner_id = event;
    this.serviceDetails.partner_asset_id = 0;

    const customer = this.customerList.find(x => x.id === event);
    this.serviceDetails.cust_mobile = customer.mobile;
    this.serviceDetails.cust_email = customer.email;

    this.serviceDetails.street = customer.street;
    this.serviceDetails.street2 = customer.street2;
    this.serviceDetails.city = customer.city;
    this.serviceDetails.zip = customer.zip;
    this.serviceDetails.state_id = customer.state_id;
    this.serviceDetails.territory_id = customer.territory_id;
    this.serviceDetails.distributor_id = customer.distributor_id;
    this.userData.territory_id = customer.territory_id;
    this.userData.distributor_id = customer.distributor_id;
    this.productList = [];
    this.fetchAssetData();
    this.fetchProductData();
  }

  async fetchAssetData() {
    this.assetList = [];
    const res = await this.apiService.postPromise('/api/partner_assets/get_assets_for_partner', this.userData);
    if (res && res.results.status === 200) {
      this.assetList = [];
      this.assetList = res.results.data;
      if (this.assetLinked && this.serviceDetails && this.serviceDetails.customer_id) {
        if (!this.assetList || this.assetList.length === 0) {
          this.toastr.error("No Assets for Customer!!");
        } else {
          this.serviceDetails.partner_asset_id = this.assetList[0].id;
        }
      }
    } else {
      if (this.assetLinked) {
        this.toastr.error('Error fetching asset list');
      }
    }
  }

  async fetchProductData() {
    this.productList = [];
    const res = await this.apiService.postPromise('/api/territory_products/get_territory_product_sel_list', this.userData);
    if (res && res.results.status === 200) {
      this.productList = [];
      this.productList = res.results.data;
      if (!this.assetLinked && this.serviceDetails && this.serviceDetails.customer_id) {
        if (!this.productList || this.productList.length === 0) {
          this.toastr.error("No Products for Customer!!");
        } else {

        }
      }
    } else {
      if (this.assetLinked) {
        this.toastr.error('Error fetching asset list');
      }
    }
  }
  goToProductSelectorPopup() {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "90%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          productList: this.productList,
          selectedProducts: this.serviceDetails.lines
        }
      };
      const creatediaeref = this.dialog.open(ServiceProductPopupComponent, config);
      creatediaeref.componentInstance.submitClicked.subscribe(data => {
        this.serviceDetails.lines = [];
        this.serviceDetails.lines = data;
        this.selectedProductCount = this.serviceDetails.lines.length;
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {
          productList: this.productList,
          selectedProducts: this.serviceDetails.lines
        }
      };
      const creatediaeref = this.dialog.open(ServiceProductPopupComponent, config);
      creatediaeref.componentInstance.submitClicked.subscribe(data => {
        this.serviceDetails.lines = [];
        this.serviceDetails.lines = data;
        this.selectedProductCount = this.serviceDetails.lines.length;
      });
    }
  }
  createServiceRequest() {
    if (!this.selected) {
      this.serviceDetails.priority = "0";
    } else {
      this.serviceDetails.priority = this.selected.toString();
    }
    let url = "";
    if (this.assetLinked) {
      url = '/api/service_requests/create_asset_service_request';
    } else {
      url = '/api/service_requests/create_product_service_request';
      if (!this.serviceDetails.lines) {
        this.serviceDetails.lines = [];
      }
    }
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = this.serviceDetails;
    this.apiService.post(url, paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success("Service Request Created Successfully");
          this.createServiceEvent.emit(true);
          this.dialogRef.close();
        } else {
          this.toastr.error("Failed to Create Service Request");
        }
      });
  }
}
