import {Component, ElementRef, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {ApiService} from "../../../../../_services/api.service";
import {SharedService} from "../../../../../_services/shared_service";
import {ToastrService} from "ngx-toastr";
import {DatePipe} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {LocalforageService} from "../../../../../_services/localforage.service";
import {DomSanitizer} from "@angular/platform-browser";
import {AppComponent} from "../../../../../app.component";
import {ImagePopupComponent} from "../../../../../components/image_popup/image_popup.component";

@Component({
  selector: 'app-add-claim-expense-popup',
  templateUrl: './add_claim_expense_popup.component.html'
})

export class AddClaimExpensePopupComponent implements OnInit {
  @Output() expenseCreated = new EventEmitter<any>();
  expenseDetails: any = {};
  expensephoto = null;
  expensephotos = [];
  expensephotodata = null;
  expensephotodatas = [];
  expenseFileData = {};
  expenseMasterData: any = {};
  policyLineData = [];
  selectedPolicy: any = {};
  maxamountExceeded = false;
  masterCreated = false;

  constructor(public dialog: MatDialog,
              public apiService: ApiService,
              public sharedService: SharedService,
              public toastr: ToastrService,
              public elRef: ElementRef,
              public datePipe: DatePipe,
              protected route: ActivatedRoute,
              protected _localCacheService: LocalforageService,
              public router: Router,
              public domSanitizer: DomSanitizer,
              public appComponent: AppComponent,
              public dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.expenseDetails.expense_date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.fetchMaster();
    this.getUserExpensePolicies();
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  fetchPolicyLines() {
    const paramObject: any = {};
    paramObject.mode = 'Distributor';
    paramObject.access_token = localStorage.getItem('resfreshToken');
    this.apiService.post('/api/expense_policy_lines/fetch_policy_lines', paramObject)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          if (res.results.data.length > 0) {
            this.policyLineData = res.results.data;
            this.policyLineData = this.policyLineData.sort((a, b) => a.exp_type.localeCompare(b.exp_type));
            this.policyLineData.forEach(policyLine => {
              policyLine.itemName = this.sharedService.capitalize(policyLine.exp_type) + ' - ' + policyLine.name;
            });
          } else {
            this.toastr.error("Policy Lines not Configured for Distributor");
          }
        }
      });
  }

  getUserExpensePolicies() {
    const paramObject: any = {};
    paramObject.cid = JSON.parse(localStorage.getItem('user')).cid;
    paramObject.user_id = JSON.parse(localStorage.getItem('user')).id;
    this.apiService.getFilter('/api/exp_user_mappings/findOne', localStorage.getItem('resfreshToken'), paramObject)
      .subscribe(res => {
        this.expenseDetails.app1_id = res.user_id1;
        this.expenseDetails.app2_id = res.user_id2;
        this.fetchPolicyLines();
      });
  }

  fetchMaster() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.start_date = this.expenseDetails.expense_date;
    this.apiService.post('/api/expense_masters/fetch_expensesv2', paramObject)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.configureMasterData(res.results.data);
        }
      });
  }

  configureMasterData(responseData) {
    if (responseData !== null && responseData.hasOwnProperty('master') && responseData.hasOwnProperty('detail')) {
      const master = responseData.master;
      const detail = responseData.detail;
      if (!master || master.length === 0) {
        this.masterCreated = false;
      } else {
        this.masterCreated = true;
        this.expenseMasterData = master[0];
      }
    }
  }


  onTypeChange() {
    this.expenseDetails.expense_line_id = parseInt(String(this.expenseDetails.expense_line_id), 0);
    this.selectedPolicy = this.policyLineData.find(x => x.id === this.expenseDetails.expense_line_id);
    this.expenseDetails.expense_policy_id = parseInt(String(this.selectedPolicy.policy_id), 0);
    this.expenseDetails.name = this.selectedPolicy.itemName;
    this.expenseDetails.exp_type = this.selectedPolicy.exp_type;
    this.checkMaxClaimExceeded();
  }

  changeDate(dt) {
    this.fetchMaster();
  }

  onAmountChange() {
    if (document.getElementById('tot_amount')['value']) {
      this.expenseDetails.amount = parseInt(String(document.getElementById('tot_amount')['value']), 0);
    }
    this.checkMaxClaimExceeded();
  }

  checkMaxClaimExceeded() {
    if (this.selectedPolicy && this.expenseDetails.amount > this.selectedPolicy.max_claim_amount) {
      this.maxamountExceeded = true;
    } else {
      this.maxamountExceeded = false;
    }
  }

  handleFileSelect(evt) {
    // console.log(evt);
    const files = evt.target.files;
    const file = files[0];
    this.expenseFileData = {};
    if (files && file) {
      const reader = new FileReader();
      this.expenseFileData['name'] = file.name;
      this.expenseFileData['type'] = file.type;
      reader.onload = this._handleReaderLoaded.bind(this);

      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt, file) {
    const binaryString = readerEvt.target.result;
    const base64textString = btoa(binaryString);
    this.expensephotodata = base64textString;
    this.expensephotodatas.push({
      name: this.expenseFileData['name'],
      type: this.expenseFileData['type'],
      photodata: base64textString
    });
    if (this.expenseFileData['type'] === 'application/pdf') {
      this.expensephoto = this.domSanitizer.bypassSecurityTrustUrl('data:application/pdf;base64, ' + base64textString);
    } else {
      this.expensephoto = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + base64textString);
    }
    this.expensephotos.push({
      name: this.expenseFileData['name'],
      type: this.expenseFileData['type'],
      photos: this.expensephoto
    });
  }

  showImagePopup(image) {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: '100vw',
        height: '100vh',
        maxWidth: '70%',
        maxHeight: '95%',
        data: {
          store_fname: '',
          photo: image,
          container: 'expense'
        }
      };

      const creatediaeref = this.dialog.open(ImagePopupComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: '100vw',
        height: '100vh',
        maxWidth: '90%',
        maxHeight: '65%',
        data: {
          store_fname: '',
          photo: image,
          container: 'expense'
        }
      };

      const creatediaeref = this.dialog.open(ImagePopupComponent, config);
    }

  }

  openPDFinNewTab(pdf) {
    if (pdf && pdf !== 'null') {
      let pdfSrc = pdf.toString();
      pdfSrc = pdfSrc.replace('SafeValue must use [property]=binding: data:application/pdf;base64, ', '');
      pdfSrc = pdfSrc.replace(' (see http://g.co/ng/security#xss)', '');
      let byteCharacters;
      if (pdfSrc === "null") {
        console.log(pdf);
        this.toastr.error("Invalid PDF");
      } else {
        try {
          byteCharacters = atob(pdfSrc);
        } catch (e) {
          console.log(pdf);
          this.toastr.error("Invalid PDF");
        }
        if (byteCharacters) {
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const file = new Blob([byteArray], {type: 'application/pdf'});
          const blobUrl = URL.createObjectURL(file);
          window.open(blobUrl);
        }
      }
    } else {
      console.log(pdf);
      this.toastr.error("Invalid PDF");
    }

  }

  checkAndCreate() {
    if (this.masterCreated) {
      this.createExpenseClaim();
    } else {
      this.createMaster();
    }
  }

  createMaster() {
    this.expenseMasterData = {};
    this.expenseMasterData.name = JSON.parse(localStorage.getItem('user')).login;
    this.expenseMasterData.user_id = JSON.parse(localStorage.getItem('user')).id;
    this.expenseMasterData.cid = JSON.parse(localStorage.getItem('user')).cid;
    this.expenseMasterData.access_token = localStorage.getItem('resfreshToken');
    this.expenseMasterData.create_uid = JSON.parse(localStorage.getItem('user')).id;
    this.expenseMasterData.create_date = new Date();
    this.expenseMasterData.write_uid = JSON.parse(localStorage.getItem('user')).id;
    this.expenseMasterData.write_date = new Date();
    this.expenseMasterData.state = 'new';
    this.expenseMasterData.total_claim = 0;
    this.expenseMasterData.expense_date = this.expenseDetails.expense_date;
    this.apiService.post('/api/expense_masters', this.expenseMasterData)
      .subscribe(res => {
        // console.log(res);
        this.masterCreated = true;
        this.expenseMasterData = res;
        this.createExpenseClaim();
      });
  }

  createExpenseClaim() {
    this.expenseDetails.salesman_id = JSON.parse(localStorage.getItem('user')).id;
    this.expenseDetails.user_id = JSON.parse(localStorage.getItem('user')).id;
    this.expenseDetails.team_id = JSON.parse(localStorage.getItem('user')).sale_team_id;
    this.expenseDetails.app1_state = 'pending';
    this.expenseDetails.app2_state = 'pending';
    this.expenseDetails.state = 'new';
    this.expenseDetails.cid = JSON.parse(localStorage.getItem('user')).cid;
    if (this.masterCreated) {
      this.expenseDetails.master_id = this.expenseMasterData.id;
    }
    if (this.expensephotodatas != null) {
      this.expenseDetails.gallery = [];
      for (let i = 0; i < this.expensephotodatas.length; i++) {
        const imageObject: any = {};
        imageObject.datas = this.expensephotodatas[i].photodata;
        imageObject.store_fname = 'Expense_image_' + this.expenseDetails.expense_date;
        imageObject.name = imageObject.store_fname;
        if (this.expensephotodatas[i].type && this.expensephotodatas[i].type.toString().includes('pdf')) {
          imageObject.type = 'pdf';
        } else {
          imageObject.type = 'image';
        }

        this.expenseDetails.gallery.push(imageObject);
      }
    }
    this.expenseDetails.access_token = localStorage.getItem('resfreshToken');
    this.apiService.post('/api/sales_expenses/createExpense', this.expenseDetails)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data)) {
          this.toastr.success("Expense successfully created!");
          this.expenseCreated.emit(true)
          this.dialogRef.close();
        } else {
          this.toastr.error('Failed to create Expense');
        }
      });

  }


}
