<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" style="float: right;background-color:#FDE0D4;">
        <i class="fas fa-times"></i>
      </button>
      <h2>PDF</h2>
    </div>
    <div class="body-section w-100" style=" text-align: center;">
      <div>

      </div>
    </div>
  </div>
</section>
