<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper dcvanlist">

      <div class="search-list">
        <div class="col-md-3 pad0">
          <div class="search">
            <span class="search-control"><img src="assets/searchicon.png"/></span>
            <input  id="filter-text-box" type="text" (input)="quickSearch()" class="search-control-area"
                    placeholder="Search">
          </div>
        </div>
        <div class="col-md-4 pad0 nopadding">
          <div class="row">
            <div class="input-color" style="margin-left:20px;">
              <span class="input-color color-box" style="background-color: #2D2D2D"></span>
              <span  class="input-color color-box-text" >Settled</span>
            </div>
            <div class="input-color">
              <span class="input-color color-box" style="background-color: #379862"></span>
              <span  class="input-color color-box-text" >Approved</span>
            </div>
            <div class="input-color">
              <span class="input-color color-box" style="background-color: #E24260"></span>
              <span  class="input-color color-box-text" >Rejected</span>
            </div>
            <div class="input-color">
              <span class="input-color color-box" style="background-color: #F6A82C"></span>
              <span class="input-color color-box-text">Pending</span>
            </div>
            <div class="input-color">
              <span class="input-color color-box" style="background-color: #22A6B3"></span>
              <span class="input-color color-box-text">New</span>
            </div> <div class="input-color">
          </div>
          </div>


        </div>
        <div class="col-md-5 pad0">
          <div class="filters">
            <ul class="list-unstyled">
              <li style="margin-right: 15px !important;" class="margLR">
                <div style="display: inline-block;" >
                  <button class="icon-btn icon-btn-blue" (click)="showAddExpensePopup()"> <i class="fas fa-plus-circle"></i>
                    ADD EXPENSE</button>
                </div>
              </li>
              <li style="margin-right: 1px !important;margin-left: 1px !important;" class="margLR">
                <ng-template adHost3></ng-template>
              </li>
              <li style="margin-right: 1px !important;margin-left: 1px !important;" class="margLR">
                <div class="date-picker">
                  <span class="datepicker-icon"><img src="../../assets/date.png"></span>
                  <input [selectMode]="'range'" [readonly]="true" [(ngModel)]="myDate" [owlDateTimeTrigger]="dt" [owlDateTime]="dt" placeholder="Date">
                  <span class="dropdown-icon"><img src="../../assets/dropdown.png"></span>
                  <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
                </div>
              </li>
              <li class="margLR">
                <app-excelicon [gridOption]="gridOptions" [title]="'Expense Report'"></app-excelicon>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-12 pad0">
          <div>
            <ag-grid-angular id="myGrid2" *ngIf="loaded" style="height: 80vh" class="ag-theme-balham"   [defaultColDef]="defaultColDef"
                             [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                             (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                             (cellClicked)="onCellClicked($event)"
                             [context]="context"
                             (filterChanged)="onFilterChanged($event)"
                             (filterModified)="onFilterModified($event)">
            </ag-grid-angular>
            <div *ngIf="preloader">
              <app-loadingstate></app-loadingstate>
            </div>
            <div *ngIf="empty_state">
              <app-emptystate></app-emptystate>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="addexpense-modal modal" id="expensemasterModal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row" style="margin-bottom: 10px;">
          <div style="/*border-bottom: 2px solid #f3f3f3;*/">
            <div class="addexpense-modal-heading" >
              <label>New Expense</label>
              <button class="spanclose" data-dismiss="modal">
                <i (click)="close()" class="fas fa-times"></i>
           </button>
            </div>
          </div>
        </div>
        <form novalidate (ngSubmit)="mainexpenseclick()" #mainexpenseform="ngForm">
          <div class="addexpense-modal-grey-content">
            <input
              name="master_date"
              #master_date="ngModel"
              style="visibility: hidden;width: 0;height:0;margin:0px;"
              [readonly]="true"
              [(ngModel)]="expenseDate"
              [owlDateTime]="dt1"
              placeholder="Select Date"
              required
            >
            <span class="datepicker-icon" [owlDateTimeTrigger]="dt1"><img src="../../assets/date.png" ></span>
            <span style="font-weight:bold;display: inline; margin-left: 5px;font-size:12px;" >{{expenseDate}}</span>
            <owl-date-time (afterPickerClosed)="setExpenseDate(dt1)" [pickerType]="'calendar'" #dt1></owl-date-time>

            <div class="row" style="margin-top:15px;">
              <div class="col-md-6  pl-4 pr-5" >
                <p>Travel Type</p>
                <div class="form-group theme-select">
                  <select
                    name="beat_travel_type"
                    [(ngModel)]="expenseMasterData.travel_type_id"
                    #beat_travel_type="ngModel"
                    [disabled]="masterCreated"
                    required
                  >
                    <option
                      *ngFor="let item of beatTravelTypes"
                      value="{{ item.id }}"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6 pl-4 pr-5" >
                <p>City Class</p>
                <div class="form-group theme-select">
                  <select
                    name="class_type"
                    [(ngModel)]="expenseMasterData.class_type"
                    #class_type="ngModel"
                    [disabled]="masterCreated"
                    required>
                    <!--    <option value="any" selected>Any</option>-->
                    <option value="a" selected>A</option>
                    <option value="b">B</option>
                    <option value="c">C</option>
                    <option value="d">D</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="addexpense-modal-content" *ngIf="masterCreated">
            <ng-container *ngFor="let exptype of masterexpensetypes">
              <div style="margin-top: 10px">
                <span style="font-weight:500;">{{to_upperCase(exptype)}}</span>
                <span style="font-weight:bold;float:right; color:#379862">₹ {{expenseMasterData[exptype+'amt']}}</span>
              </div>
            </ng-container>
            <div style="margin-top: 15px">
              <span style="font-weight:bold;font-size:16px">Claimed</span>
              <span style="font-weight:bold;float:right;font-size:16px">₹ {{expenseMasterData.claimamt}}</span>
            </div>
          </div>
          <div style="padding:15px; height:70px;">
            <div class="col-md-6">
            </div>
            <div class="col-md-6 text-right">
              <button
                style="margin-left:15px; margin-right:15px;"
                class="btn blue-btn"
                type="submit"
              >
                Next
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="addexpense-modal modal" id="expensetypepopupModal" role="dialog" #expensetypepopupModal>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row" style="margin-bottom: 10px;">
          <div style="/*border-bottom: 2px solid #f3f3f3;*/">
            <div class="addexpense-modal-heading">
              <span title="Back" (click)="goBack('#expensetypepopupModal')"><i class="far fa-long-arrow-alt-left fa-2x" style="color:#183153;display:inline;margin-left: 5px;"></i></span>
              <label>Expense Type</label>
              <button class="spanclose" data-dismiss="modal">
                <i (click)="close()" class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="addexpense-modal-grey-content">
          <div class="eod-list-card" *ngIf="policyLineTypes.indexOf('travel')> -1">
            <div class="eod-list-card-heading" (click)="showtravel = !showtravel">
              <span style="display:inline"><img src="assets/travel.svg" width="30px" /></span>
              <h3 style="display:inline;text-align: center">Travel</h3>
              <span *ngIf="showtravel"
                    style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/up_arrow.svg" width="15px" />
              </span>
              <span *ngIf="!showtravel"
                    style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/down_arrow.svg" width="15px" />
              </span>
            </div>
            <div *ngIf="showtravel" style="margin-top:15px;">
              <div class="cardgrid" >
                <ng-container *ngFor="let tile of travelRows">
                  <div>
                    <span *ngIf="tile.travel_type==='flight'" style="display:block;text-align: center">
                      <img src="assets/travel_air.svg" height="30px" width="30px"
                           data-dismiss="modal" data-toggle="modal"
                           (click)="expenseTypeClick(tile)"/>
                    </span>
                    <span *ngIf="tile.travel_type==='train'" style="display:block;text-align: center">
                      <img src="assets/travel_train.svg" height="30px" width="30px"
                           data-dismiss="modal" data-toggle="modal"
                           (click)="expenseTypeClick(tile)"/>
                    </span>
                    <span *ngIf="tile.travel_type==='car'" style="display:block;text-align: center">
                      <img src="assets/travel_car.svg" height="30px" width="30px"
                           data-dismiss="modal" data-toggle="modal"
                           (click)="expenseTypeClick(tile)"/>
                    </span>
                    <span *ngIf="tile.travel_type==='bike'" style="display:block;text-align: center">
                      <img src="assets/travel_bike.svg" height="30px" width="30px"
                           data-dismiss="modal" data-toggle="modal"
                           (click)="expenseTypeClick(tile)"/>
                    </span>
                    <span *ngIf="tile.travel_type==='bus'" style="display:block;text-align: center">
                      <img src="assets/travel_bus.svg" height="30px" width="30px"
                           data-dismiss="modal" data-toggle="modal"
                           (click)="expenseTypeClick(tile)"/>
                    </span>
                    <span *ngIf="tile.travel_type==='taxi'" style="display:block;text-align: center">
                      <img src="assets/travel_taxi.svg" height="30px" width="30px"
                           data-dismiss="modal" data-toggle="modal"
                           (click)="expenseTypeClick(tile)"/>
                    </span>
                    <span *ngIf="tile.travel_type==='others'" style="display:block;text-align: center">
                      <img src="assets/travel_others.svg" height="30px" width="30px"
                           data-dismiss="modal" data-toggle="modal"
                           (click)="expenseTypeClick(tile)"/>
                    </span>
                    <span style="display:block;text-align: center">{{tile.name}}</span>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="eod-list-card" *ngIf="policyLineTypes.indexOf('stay')> -1">
            <div class="eod-list-card-heading" (click)="showstay = !showstay">
              <span style="margin-right:5px;display:inline"><img src="assets/stay.svg" width="30px" /></span>
              <h3 style="display:inline">Stay</h3>
              <span *ngIf="showstay"
                    style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/up_arrow.svg" width="15px" />
              </span>
              <span *ngIf="!showstay"
                    style="float:right;margin-right:5px;display:inline" >
                <img src="assets/down_arrow.svg" width="15px" />
              </span>
            </div>
            <div *ngIf="showstay" style="margin-top:15px;">
              <div class="cardgrid" >
                <ng-container *ngFor="let tile of stayRows">
                  <div>
                    <span *ngIf="tile.stay_type==='company'" style="display:block;text-align: center">
                      <img src="assets/stay_company.svg" height="30px" width="30px"
                           data-dismiss="modal" data-toggle="modal"
                           (click)="expenseTypeClick(tile)"/>
                    </span>
                    <span *ngIf="tile.stay_type==='private'" style="display:block;text-align: center">
                      <img src="assets/stay_personal.svg" height="30px" width="30px"
                           data-dismiss="modal" data-toggle="modal"
                           (click)="expenseTypeClick(tile)"/>
                    </span>
                    <span *ngIf="tile.stay_type==='others'" style="display:block;text-align: center">
                      <img src="assets/travel_others.svg" height="30px" width="30px"
                           data-dismiss="modal" data-toggle="modal"
                           (click)="expenseTypeClick(tile)"/>
                    </span>
                    <span  style="display:block;text-align: center">{{tile.name}}</span>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="eod-list-card" *ngIf="policyLineTypes.indexOf('allowance')> -1">
            <div class="eod-list-card-heading" (click)="showallowances = !showallowances">
              <span style="margin-right:5px;display:inline"><img src="assets/allowances.svg" width="30px" /></span>
              <h3 style="display:inline">Allowances</h3>
              <span *ngIf="showallowances"
                    style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/up_arrow.svg" width="15px" />
              </span>
              <span *ngIf="!showallowances"
                    style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/down_arrow.svg" width="15px" />
              </span>
            </div>
            <div *ngIf="showallowances" style="margin-top:15px;">
              <div class="cardgrid" >
                <ng-container *ngFor="let tile of allowanceRows">
                  <div>
                    <span *ngIf="tile.allowance_type==='da'" style="display:block;text-align: center">
                      <img src="assets/allowances_da.svg" height="30px" width="30px"
                           data-dismiss="modal" data-toggle="modal"
                           (click)="expenseTypeClick(tile)"/>
                    </span>
                    <span *ngIf="tile.allowance_type==='sa'" style="display:block;text-align: center">
                      <img src="assets/allowances_stay.svg" height="30px" width="30px"
                           data-dismiss="modal" data-toggle="modal"
                           (click)="expenseTypeClick(tile)"/>
                    </span>
                    <span *ngIf="tile.allowance_type==='ta'" style="display:block;text-align: center">
                      <img src="assets/allowances_travel.svg" height="30px" width="30px"
                           data-dismiss="modal" data-toggle="modal"
                           (click)="expenseTypeClick(tile)"/>
                    </span>
                    <span  style="display:block;text-align: center">{{tile.name}}</span>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="eod-list-card" *ngIf="policyLineTypes.indexOf('scheme')> -1">
            <div class="eod-list-card-heading" (click)="showscheme = !showscheme">
              <span style="margin-right:5px;display:inline"><img src="assets/expense_others.svg" width="30px" /></span>
              <h3 style="display:inline">Scheme Claims</h3>
              <span *ngIf="showscheme"
                    style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/up_arrow.svg" width="15px" />
              </span>
              <span *ngIf="!showscheme"
                    style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/down_arrow.svg" width="15px" />
              </span>
            </div>
            <div *ngIf="showscheme" style="margin-top:15px;">
              <div class="cardgrid" >
                <ng-container *ngFor="let tile of schemeRows">
                  <div>
                    <span style="display:block;text-align: center">
                      <img src="assets/allowances_da.svg" height="30px" width="30px"
                           data-dismiss="modal" data-toggle="modal"
                           (click)="expenseTypeClick(tile)"/>
                    </span>

                    <span  style="display:block;text-align: center">{{tile.name}}</span>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="eod-list-card" *ngIf="policyLineTypes.indexOf('damage')> -1">
            <div class="eod-list-card-heading" (click)="showdamage = !showdamage">
              <span style="margin-right:5px;display:inline"><img src="assets/expense_others.svg" width="30px" /></span>
              <h3 style="display:inline">Return Claims</h3>
              <span *ngIf="showdamage"
                    style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/up_arrow.svg" width="15px" />
              </span>
              <span *ngIf="!showdamage"
                    style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/down_arrow.svg" width="15px" />
              </span>
            </div>
            <div *ngIf="showdamage" style="margin-top:15px;">
              <div class="cardgrid" >
                <ng-container *ngFor="let tile of damageRows">
                  <div>
                    <span style="display:block;text-align: center">
                      <img src="assets/allowances_da.svg" height="30px" width="30px"
                           data-dismiss="modal" data-toggle="modal"
                           (click)="expenseTypeClick(tile)"/>
                    </span>

                    <span  style="display:block;text-align: center">{{tile.name}}</span>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="eod-list-card">
            <div class="eod-list-card-heading" (click)="showothers = !showothers">
              <span style="margin-right:5px;display:inline"><img src="assets/expense_others.svg" width="30px" /></span>
              <h3 style="display:inline">Others</h3>
              <span *ngIf="showothers"
                    style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/up_arrow.svg" width="15px" />
              </span>
              <span *ngIf="!showothers"
                    style="float:right;margin-right:5px;display:inline" >
                <img  src="assets/down_arrow.svg" width="15px" />
              </span>
            </div>
            <div *ngIf="showothers" style="margin-top:15px;">
              <div class="cardgrid" >
                <ng-container *ngFor="let tile of otherRows">
                  <div>
                    <span *ngIf="tile.exp_type==='food'" style="display:block;text-align: center">
                      <img src="assets/allowances_da.svg" height="30px" width="30px"
                           data-dismiss="modal" data-toggle="modal"
                           (click)="expenseTypeClick(tile)"/>
                    </span>
                    <span *ngIf="tile.exp_type==='others'" style="display:block;text-align: center">
                      <img src="assets/allowances_stay.svg" height="30px" width="30px"
                           data-dismiss="modal" data-toggle="modal"
                           (click)="expenseTypeClick(tile)"/>
                    </span>
                    <span  style="display:block;text-align: center">{{tile.name}}</span>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="addexpense-modal modal" id="staypopupModal" role="dialog" #staypopupModal>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div  style="margin-bottom: 10px;">
          <div >
            <div class="addexpense-modal-heading" >
              <span title="Back" (click)="goBack('#staypopupModal')"><i class="far fa-long-arrow-alt-left fa-2x" style="color:#183153;display:inline;margin-left: 5px;"></i></span>
              <label>Stay Expense ({{newExpenseData.stay_type}})</label>
              <button class="spanclose" data-dismiss="modal">
                <i (click)="close()" class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>  <form novalidate (ngSubmit)="createExpenseClick()" #stayform="ngForm">
        <div class="addexpense-modal-content">

          <div style=" padding: 15px;">
            <div class="row">
              <span title="Policy Info" (click)="showPolicyInfoPopup()"><i class="fas fa-info-circle fa-2x" style="color:#22A6B3;cursor:pointer;"></i></span>
            </div>
            <div class="row">
              <div class="col-md-6 pl-0 pr-5" >
                <input
                  type="number"
                  name="stayamount"
                  [(ngModel)]="newExpenseData.amount"
                  #stayamount="ngModel"
                  (input)="onAmountChange()"
                  min="1"
                  required
                >
                <span class="highlight"></span>
                <span class="bar"></span>
                <label>Amount</label>
                <div *ngIf="stayamount.errors && (stayamount.dirty || stayamount.touched)">
                  <span *ngIf="stayamount.errors.required" class="text-danger">Amount is required</span>
                </div>
                <span *ngIf="maxamountExceeded" class="text-danger">Amount exceeds Max Claim amount({{newExpenseData.max_claim_amount}})</span>
              </div>
            </div>
            <div class="row" (click)="locationPicker('start')" style="margin-top: 20px;">
              <div *ngIf="!address">
                <span ><i class="fas fa-plus-circle" style="color:#D82128;cursor:pointer;"></i></span>
                <span style="color:#D82128;margin-left: 5px; cursor:pointer;">Add Location</span>
              </div>
              <div *ngIf="address">
                <span ><img src="assets/location.svg" width="20px" style="cursor:pointer;"/></span>
                <span style="margin-left: 5px;cursor:pointer;">{{address}}</span>
              </div>
            </div>
            <div class="row" style="margin-top:20px;margin-bottom:20px;">
              <div class="col-md-4 pl-0 pr-5" style="margin-bottom:20px;">
                <input
                  name="expense_date"
                  #expense_date="ngModel"
                  style="display:inline;"
                  [(ngModel)]="newExpenseData.expense_date"
                  [owlDateTime]="dt2"
                  [owlDateTimeTrigger]="dt2"
                  required
                >
                <span class="highlight"></span>
                <span class="bar"></span>
                <label>Start Date</label>
                <owl-date-time (afterPickerClosed)="setExpenseStartDate(dt2)" [pickerType]="'calendar'" #dt2></owl-date-time>
                <div *ngIf="expense_date.errors && (expense_date.dirty || expense_date.touched)">
                  <span *ngIf="expense_date.errors.required" class="text-danger">Start Date is required</span>
                </div>
              </div>
              <div class="col-md-4 pl-0 pr-5" style="margin-bottom:20px;">
                <input
                  name="end_date"
                  #end_date="ngModel"
                  style="display:inline;"
                  [(ngModel)]="newExpenseData.end_date"
                  [owlDateTime]="dt3"
                  [owlDateTimeTrigger]="dt3"
                  required
                >
                <span class="highlight"></span>
                <span class="bar"></span>
                <label>End Date</label>
                <owl-date-time (afterPickerClosed)="setExpenseEndDate(dt3)" [pickerType]="'calendar'" #dt3></owl-date-time>
                <div *ngIf="end_date.errors && (end_date.dirty || end_date.touched)">
                  <span *ngIf="end_date.errors.required" class="text-danger">End Date is required</span>
                </div>
              </div>
            </div>
            <div class="row" style="margin-top:20px;">
              <div class="col-md-12 pl-0 pr-5" >
                <input type="text"
                       name="stay_note"
                       #stay_note="ngModel"
                       [(ngModel)]="newExpenseData.note"
                >
                <span class="highlight"></span>
                <span class="bar"></span>
                <label>Comments</label>
              </div>
            </div>
            <div class="row" style="margin-top:20px;">
              <div class="galleryrowgrid" >
                <ng-container *ngFor="let tile of expensephotos">
                  <div style=" display: inline-block;align-items: center;">
                    <img *ngIf="tile.type !== 'application/pdf'" [src]="tile.photos" style="height: 100px;width:100px;margin-right: 8px;margin-bottom: 8px;"/>
                    <img *ngIf="tile.type === 'application/pdf'" src="./../../../assets/pdf_icon.png" style="height: 100px;width:100px;margin-right: 8px;margin-bottom: 8px;"/>
                    <p style="display: block">{{tile.name}}</p>
                  </div>
                </ng-container>
                <div style=" display: inline-flex;align-items: center;">
                  <input
                    type="file"
                    #file
                    style="display:none"
                    accept="image/png, image/jpeg, application/pdf"
                    (change)="handleFileSelect($event)"/>
                  <span style="margin-right:5px;display:inline" (click)="file.click()"
                  >
                  <img  src="assets/upload_picture.svg" width="40px" /></span>
                  <span *ngIf="!expensephotos" style=" display: inline-flex;align-items: center;">Upload Picture</span>
                </div>
              </div>
            </div>

            <div *ngIf="showTax">
              <div class="row" style="margin-top:20px;">
                <div class="col-8 pl-0 pr-5">
                  <h4>Include GST</h4>
                  <p class="m-0">
                    Upload Bill with GST if you select this
                  </p>
                </div>
                <div class="col-4 my-auto text-right">
                  <label class="switch">
                    <input
                      type="checkbox"
                      name="stayhas_gst"
                      [checked]="newExpenseData.has_gst"
                      [(ngModel)]="newExpenseData.has_gst"
                      #stayhas_gst="ngModel"
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>

              <div *ngIf="newExpenseData.has_gst">
                <div class="row" style="margin-top:10px;">
                  <div class="col-md-6 pl-0 pr-5" >
                    <input
                      type="text"
                      name="staygstinvoice"
                      [(ngModel)]="newExpenseData.inv_no"
                      #staygstinvoice="ngModel"
                      required>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>Invoice No</label>
                    <div *ngIf="staygstinvoice.errors && (staygstinvoice.dirty || staygstinvoice.touched)">
                      <span *ngIf="staygstinvoice.errors.required" class="text-danger">Invoice Number is required</span>
                    </div>
                  </div>
                </div>

                <div class="row" style="margin-top:20px;">
                  <div class="col-md-6 pl-0 pr-5" >
                    <div >
                      <input
                        name="stay_inv_date"
                        #stay_inv_date="ngModel"
                        [(ngModel)]="newExpenseData.inv_date"
                        [owlDateTime]="dt4"
                        [owlDateTimeTrigger]="dt4"
                        required
                      >
                      <span class="highlight"></span>
                      <span class="bar"></span>
                      <label>Invoice Date</label>
                      <owl-date-time (afterPickerClosed)="setGSTInvoiceDate(dt4)" [pickerType]="'calendar'" #dt4></owl-date-time>
                      <div *ngIf="stay_inv_date.errors && (stay_inv_date.dirty || stay_inv_date.touched)">
                        <span *ngIf="stay_inv_date.errors.required" class="text-danger">Invoice Date is required</span>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="row" style="margin-top:20px;">
                  <div class="col-md-6 pl-0 pr-5" >
                    <div  >
                      <input
                        type="text"
                        name="staypartygstn"
                        [(ngModel)]="newExpenseData.inv_tax_id"
                        #staypartygstn="ngModel"
                        required>
                      <span class="highlight"></span>
                      <span class="bar"></span>
                      <label>Party GSTN No</label>
                      <div *ngIf="staypartygstn.errors && (staypartygstn.dirty || staypartygstn.touched)">
                        <span *ngIf="staypartygstn.errors.required" class="text-danger">Party GSTN No is required</span>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="row" style="margin-top:20px;">
                  <div class="col-md-6 pl-0 pr-5" >
                    <div  >
                      <input
                        type="text"
                        name="staycompanygstn"
                        [(ngModel)]="newExpenseData.company_tax_id"
                        #staycompanygstn="ngModel"
                      >
                      <span class="highlight"></span>
                      <span class="bar"></span>
                      <label>Company GSTN</label>
                      <div *ngIf="staycompanygstn.errors && (staycompanygstn.dirty || staycompanygstn.touched)">
                        <span *ngIf="staycompanygstn.errors.required" class="text-danger">Company GSTN is required</span>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>

        </div>

        <div>
          <div style="padding:15px;border-top: 2px solid #f3f3f3;height:70px;">

            <div class="button-row" style="position: absolute;  right: 0px;">
             <span class="modal-btn" data-dismiss="modal" style="margin-bottom: 15px;"  data-toggle="modal">
            <a class="btn white-btn">Cancel</a>
          </span>
              <button
                mat-button
                style="margin-left:15px; margin-right:15px;"
                class="btn blue-btn"
                type="submit"
                [disabled]="stayform.invalid || maxamountExceeded || submitClicked"
              >
                SUBMIT
              </button>
            </div>

          </div>
        </div>
      </form>
      </div>
    </div>
  </div>
</div>

<div class="addexpense-modal modal" id="travelpopupModal" role="dialog" #travelpopupModal>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div  style="margin-bottom: 10px;">
          <div >
            <div class="addexpense-modal-heading" >
              <span title="Back" (click)="goBack('#travelpopupModal')"><i class="far fa-long-arrow-alt-left fa-2x" style="color:#183153;display:inline;margin-left: 5px;"></i></span>
              <label>Travel Expense ({{newExpenseData.travel_type}})</label>
              <button class="spanclose" data-dismiss="modal">
                <i (click)="close()" class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
        <form novalidate (ngSubmit)="createExpenseClick()" #travelform="ngForm">
          <div class="addexpense-modal-content">
            <div style="padding: 15px;">
              <div class="row">
                  <span title="Policy Info" (click)="showPolicyInfoPopup()"><i class="fas fa-info-circle fa-2x" style="color:#22A6B3;cursor:pointer;"></i></span>
              </div>
              <div class="row">
                <div class="col-md-6 pl-0 pr-5" style="margin-top:20px;" >
                  <p style="color:#5264AE">Amount</p>
                  <input
                    id="amount"
                    type="number"
                    name="amount"
                    [(ngModel)]="newExpenseData.amount"
                    #amount="ngModel"
                    [disabled]="travelamountdisabled"
                    (focus)="true"
                    (input)="onAmountChange()"
                    required
                  >
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <div *ngIf="amount.errors && (amount.dirty || amount.touched)">
                    <span *ngIf="amount.errors.required" class="text-danger">Amount is required</span>
                  </div>
                  <span *ngIf="maxamountExceeded" class="text-danger">Amount exceeds Max Claim amount({{newExpenseData.max_claim_amount}})</span>
                </div>
                <div class="col-md-6 pl-0 pr-5" style="margin-top:20px;">
                  <p style="color:#5264AE">Distance(in KM)</p>
                  <input id="distance"
                         name="distance"
                         [(ngModel)]="newExpenseData.distance"
                         #distance="ngModel"
                         [disabled]="true"
                         (focus)="true"
                         required>
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <!--  <label>Distance(in KM)</label>-->
                  <div *ngIf="distance.errors && (distance.dirty || distance.touched)">
                    <span *ngIf="distance.errors.required" class="text-danger">Distance is required</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 pr-5"  style="margin-top:20px;">
                  <div class="row" (click)="locationPicker('start')" style="margin-top: 20px;">
                    <div *ngIf="!address">
                      <span ><i class="fas fa-plus-circle fa-2x" style="color:#D82128;cursor:pointer;display: inline-block;
  vertical-align: middle;padding-bottom: 4px;"></i></span>
                      <span style="color:#D82128;margin-left: 5px; cursor:pointer;font-weight: 500">Start Location</span>
                    </div>
                    <div *ngIf="address">
                      <span ><i class="fas fa-location-circle fa-2x" style="color:#D82128;cursor:pointer;display: inline-block;
  vertical-align: middle;padding-bottom: 4px;"></i></span>
                      <span style="margin-left: 5px;cursor:pointer;">{{address}}</span>
                    </div>
                  </div>
                  <div class="row">
                    <span style="font-size: 15px;font-weight: 500;margin-left: 8px;">.</span>
                  </div>
                  <div class="row">
                    <span style="font-size: 15px;font-weight: 500;margin-left: 8px;">.</span>
                  </div>
                </div>
              </div>

              <div *ngIf="address && end_address">
                <div class="row">
                  <div class="col-md-12 pr-5">

                    <div class="row" (click)="locationPicker('stop')" style="margin-top: 3px;margin-bottom: 3px;">
                      <div>
                        <span ><i class="fas fa-plus-circle fa-2x" style="color:#2e5bff;cursor:pointer;display: inline-block;
  vertical-align: middle;padding-bottom: 4px;"></i></span>
                        <span style="color:#2e5bff;margin-left: 5px; cursor:pointer;font-weight: 500">Add Stop</span>
                      </div>

                    </div>
                    <div class="row">
                      <span style="font-size: 15px;font-weight: 500;margin-left: 8px;">.</span>
                    </div>
                    <div class="row">
                      <span style="font-size: 15px;font-weight: 500;margin-left: 8px;">.</span>
                    </div>
                  </div>
                </div>

                <div class="row">

                  <ng-container *ngFor="let location of stopLocations;let i = index">
                    <div class="col-md-12 pr-5">
                      <div>
                        <span ><i class="fas fa-location-circle fa-2x" style="color:#2e5bff;cursor:pointer;display: inline-block;
  vertical-align: middle;padding-bottom: 4px;" ></i></span>
                        <span style="margin-right:15px;margin-left: 5px;cursor:pointer;">{{location.address}}</span>
                        <span title="Remove Stop" (click)="removeStop(i)"><i class="far fa-window-close fa-2x" style="color:#D82128;cursor:pointer;display: inline-block;
  vertical-align: middle;padding-bottom: 4px;"></i></span>
                      </div>
                      <div class="row">
                        <span style="font-size: 15px;font-weight: 500;margin-left: 8px;">.</span>
                      </div>
                      <div class="row">
                        <span style="font-size: 15px;font-weight: 500;margin-left: 8px;">.</span>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 pr-5" >
                  <div class="row" (click)="locationPicker('end')">
                    <div *ngIf="!end_address">
                      <span ><i class="fas fa-plus-circle fa-2x" style="color:#027F62;cursor:pointer;display: inline-block;
  vertical-align: middle;padding-bottom: 4px;"></i></span>
                      <span style="color:#027F62;margin-left: 5px; cursor:pointer;font-weight: 500">End Location</span>
                    </div>
                    <div *ngIf="end_address">
                      <span ><i class="fas fa-location-circle fa-2x" style="color:#027F62;cursor:pointer;display: inline-block;
  vertical-align: middle;padding-bottom: 4px;"></i></span>
                      <span style="margin-left: 5px;cursor:pointer;">{{end_address}}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" style="margin-top:40px;">
                <div class="col-md-12 pl-0 pr-5" >
                  <input type="text"
                         name="travel_note"
                         #travel_note="ngModel"
                         [(ngModel)]="newExpenseData.note"
                  >
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label>Comments</label>
                </div>
              </div>

              <div class="row" style="margin-top:20px;">
                <div class="galleryrowgrid" >
                  <ng-container *ngFor="let tile of expensephotos">
                    <div style=" display: inline-block;align-items: center;">
                      <img *ngIf="tile.type !== 'application/pdf'" [src]="tile.photos" style="height: 100px;width:100px;margin-right: 8px;margin-bottom: 8px;"/>
                      <img *ngIf="tile.type === 'application/pdf'" src="./../../../assets/pdf_icon.png" style="height: 100px;width:100px;margin-right: 8px;margin-bottom: 8px;"/>
                      <p style="display: block">{{tile.name}}</p>
                    </div>
                  </ng-container>
                  <div style=" display: inline-flex;align-items: center;">
                    <input
                      type="file"
                      #file
                      style="display:none"
                      accept="image/png, image/jpeg, application/pdf"
                      (change)="handleFileSelect($event)"/>
                    <span style="margin-right:5px;display:inline" (click)="file.click()"
                    >
                  <img  src="assets/upload_picture.svg" width="40px" /></span>
                    <span *ngIf="!expensephotos" style=" display: inline-flex;align-items: center;">Upload Picture</span>
                  </div>
                </div>
              </div>

              <div *ngIf="showTax">
                <div class="row" style="margin-top:20px;">
                  <div class="col-8 pl-0 pr-5">
                    <h4>Include GST</h4>
                    <p class="m-0">
                      Upload Bill with GST if you select this
                    </p>
                  </div>
                  <div class="col-4 my-auto text-right">
                    <label class="switch">
                      <input
                        type="checkbox"
                        name="travelhas_gst"
                        [checked]="newExpenseData.has_gst"
                        [(ngModel)]="newExpenseData.has_gst"
                        [disabled]="newExpenseData.tax_type=='strict_tax'"
                        #travelhas_gst="ngModel"
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>

                <div *ngIf="newExpenseData.has_gst">
                  <div class="row" style="margin-top:10px;">
                    <div class="col-md-6 pl-0 pr-5" >
                      <input
                        type="text"
                        name="travelgstinvoice"
                        [(ngModel)]="newExpenseData.inv_no"
                        #travelgstinvoice="ngModel"
                        required>
                      <span class="highlight"></span>
                      <span class="bar"></span>
                      <label>Invoice No</label>
                      <div *ngIf="travelgstinvoice.errors && (travelgstinvoice.dirty || travelgstinvoice.touched)">
                        <span *ngIf="travelgstinvoice.errors.required" class="text-danger">Invoice Number is required</span>
                      </div>
                    </div>
                  </div>

                  <div class="row" style="margin-top:20px;">
                    <div class="col-md-6 pl-0 pr-5" >
                      <div >
                        <input
                          name="travel_inv_date"
                          #travel_inv_date="ngModel"
                          [(ngModel)]="newExpenseData.inv_date"
                          [owlDateTime]="dt5"
                          [owlDateTimeTrigger]="dt5"
                          required
                        >
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label>Invoice Date</label>
                        <owl-date-time (afterPickerClosed)="setGSTInvoiceDate(dt5)" [pickerType]="'calendar'" #dt5></owl-date-time>
                        <div *ngIf="travel_inv_date.errors && (travel_inv_date.dirty || travel_inv_date.touched)">
                          <span *ngIf="travel_inv_date.errors.required" class="text-danger">Invoice Date is required</span>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="row" style="margin-top:20px;">
                    <div class="col-md-6 pl-0 pr-5" >
                      <div  >
                        <input
                          type="text"
                          name="travelpartygstn"
                          [(ngModel)]="newExpenseData.inv_tax_id"
                          #travelpartygstn="ngModel"
                          required>
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label>Party GSTN No</label>
                        <div *ngIf="travelpartygstn.errors && (travelpartygstn.dirty || travelpartygstn.touched)">
                          <span *ngIf="travelpartygstn.errors.required" class="text-danger">Party GSTN No is required</span>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="row" style="margin-top:20px;">
                    <div class="col-md-6 pl-0 pr-5" >
                      <div  >
                        <input
                          type="text"
                          name="travelcompanygstn"
                          [(ngModel)]="newExpenseData.company_tax_id"
                          #travelcompanygstn="ngModel"
                        >
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label>Company GSTN</label>
                        <div *ngIf="travelcompanygstn.errors && (travelcompanygstn.dirty || travelcompanygstn.touched)">
                          <span *ngIf="travelcompanygstn.errors.required" class="text-danger">Company GSTN is required</span>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
              </div>

            </div>

          </div>
          <div style="padding:15px;border-top: 2px solid #f3f3f3;height:70px;">
            <div class="button-row" style="position: absolute;  right: 0px;">
             <span class="modal-btn" data-dismiss="modal" style="margin-bottom: 15px;"  data-toggle="modal" >
            <a class="btn white-btn">Cancel</a>
          </span>
              <button
                mat-button
                style="margin-left:15px; margin-right:15px;"
                class="btn blue-btn"
                type="submit"
                [disabled]="travelform.invalid || maxamountExceeded || submitClicked || !newExpenseData.amount"
              >
                SUBMIT
              </button>
            </div>
          </div>
        </form>
        <div>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="addexpense-modal modal" id="allowancepopupModal" role="dialog" #allowancepopupModal>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div  style="margin-bottom: 10px;">
          <div >
            <div class="addexpense-modal-heading" >
              <span title="Back" (click)="goBack('#allowancepopupModal')"><i class="far fa-long-arrow-alt-left fa-2x" style="color:#183153;display:inline;margin-left: 5px;"></i></span>
              <label>Allowances ({{newExpenseData.allowance_type}})</label>
              <button class="spanclose" data-dismiss="modal">
                <i (click)="close()" class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
        <form novalidate (ngSubmit)="createExpenseClick()" #allowanceform="ngForm">
          <div class="addexpense-modal-content">

            <div style=" padding: 15px;">
              <div class="row">
                <span title="Policy Info" (click)="showPolicyInfoPopup()"><i class="fas fa-info-circle fa-2x" style="color:#22A6B3;cursor:pointer;"></i></span>
              </div>
              <div class="row">
                <div class="col-md-12 pl-0 pr-5" >
                  <input
                    type="number"
                    name="allowanceamount"
                    [(ngModel)]="newExpenseData.amount"
                    #allowanceamount="ngModel"
                    (input)="onAmountChange()"
                    min="1"
                    required
                  >
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label>Amount</label>
                  <div *ngIf="allowanceamount.errors && (allowanceamount.dirty || allowanceamount.touched)">
                    <span *ngIf="allowanceamount.errors.required" class="text-danger">Amount is required</span>
                  </div>
                  <span *ngIf="maxamountExceeded" class="text-danger">Amount exceeds Max Claim amount({{newExpenseData.max_claim_amount}})</span>
                </div>
              </div>

              <div class="row" style="margin-top:20px;">
                <div class="col-md-12 pl-0 pr-5" >
                  <input
                    type="text"
                    name="allowance_note"
                    #allowance_note="ngModel"
                    [(ngModel)]="newExpenseData.note">
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label>Comments</label>
                </div>
              </div>

              <div class="row" style="margin-top:20px;">
                <div class="galleryrowgrid" >
                  <ng-container *ngFor="let tile of expensephotos">
                    <div style=" display: inline-block;align-items: center;">
                      <img *ngIf="tile.type !== 'application/pdf'" [src]="tile.photos" style="height: 100px;width:100px;margin-right: 8px;margin-bottom: 8px;"/>
                      <img *ngIf="tile.type === 'application/pdf'" src="./../../../assets/pdf_icon.png" style="height: 100px;width:100px;margin-right: 8px;margin-bottom: 8px;"/>
                      <p style="display: block">{{tile.name}}</p>
                    </div>
                  </ng-container>
                  <div style=" display: inline-flex;align-items: center;">
                    <input
                      type="file"
                      #file
                      style="display:none"
                      accept="image/png, image/jpeg, application/pdf"
                      (change)="handleFileSelect($event)"/>
                    <span style="margin-right:5px;display:inline" (click)="file.click()"
                    >
                  <img  src="assets/upload_picture.svg" width="40px" /></span>
                    <span *ngIf="!expensephotos" style=" display: inline-flex;align-items: center;">Upload Picture</span>
                  </div>
                </div>
              </div>

              <div *ngIf="showTax">
                <div class="row" style="margin-top:20px;">
                  <div class="col-8 pl-0 pr-5">
                    <h4>Include GST</h4>
                    <p class="m-0">
                      Upload Bill with GST if you select this
                    </p>
                  </div>
                  <div class="col-4 my-auto text-right">
                    <label class="switch">
                      <input
                        type="checkbox"
                        name="allowancehas_gst"
                        [checked]="newExpenseData.has_gst"
                        [(ngModel)]="newExpenseData.has_gst"
                        #allowancehas_gst="ngModel"
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>

                <div *ngIf="newExpenseData.has_gst">
                  <div class="row" style="margin-top:10px;">
                    <div class="col-md-6 pl-0 pr-5" >
                      <input
                        type="text"
                        name="allowancegstinvoice"
                        [(ngModel)]="newExpenseData.inv_no"
                        #allowancegstinvoice="ngModel"
                        required>
                      <span class="highlight"></span>
                      <span class="bar"></span>
                      <label>Invoice No</label>
                      <div *ngIf="allowancegstinvoice.errors && (allowancegstinvoice.dirty || allowancegstinvoice.touched)">
                        <span *ngIf="allowancegstinvoice.errors.required" class="text-danger">Invoice Number is required</span>
                      </div>
                    </div>
                  </div>

                  <div class="row" style="margin-top:20px;">
                    <div class="col-md-6 pl-0 pr-5" >
                      <div >
                        <input
                          name="da_inv_date"
                          #da_inv_date="ngModel"
                          [(ngModel)]="newExpenseData.inv_date"
                          [owlDateTime]="dt6"
                          [owlDateTimeTrigger]="dt6"
                          required
                        >
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label>Invoice Date</label>
                        <owl-date-time (afterPickerClosed)="setGSTInvoiceDate(dt6)" [pickerType]="'calendar'" #dt6></owl-date-time>
                        <div *ngIf="da_inv_date.errors && (da_inv_date.dirty || da_inv_date.touched)">
                          <span *ngIf="da_inv_date.errors.required" class="text-danger">Invoice Date is required</span>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="row" style="margin-top:20px;">
                    <div class="col-md-6 pl-0 pr-5" >
                      <div  >
                        <input
                          type="text"
                          name="dapartygstn"
                          [(ngModel)]="newExpenseData.inv_tax_id"
                          #dapartygstn="ngModel"
                          required>
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label>Party GSTN No</label>
                        <div *ngIf="dapartygstn.errors && (dapartygstn.dirty || dapartygstn.touched)">
                          <span *ngIf="dapartygstn.errors.required" class="text-danger">Party GSTN No is required</span>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="row" style="margin-top:20px;">
                    <div class="col-md-6 pl-0 pr-5" >
                      <div  >
                        <input
                          type="text"
                          name="dacompanygstn"
                          [(ngModel)]="newExpenseData.company_tax_id"
                          #dacompanygstn="ngModel"
                        >
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label>Company GSTN</label>
                        <div *ngIf="dacompanygstn.errors && (dacompanygstn.dirty || dacompanygstn.touched)">
                          <span *ngIf="dacompanygstn.errors.required" class="text-danger">Company GSTN is required</span>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>

          </div>

          <div>
            <div style="padding:15px;border-top: 2px solid #f3f3f3;height:70px;">
              <div class="button-row" style="position: absolute;  right: 0px;">
             <span class="modal-btn" data-dismiss="modal" style="margin-bottom: 15px;"  data-toggle="modal" >
            <a class="btn white-btn">Cancel</a>
          </span>
                <button
                  style="margin-left:15px; margin-right:15px;"
                  class="btn blue-btn"
                  type="submit"
                  [disabled]="allowanceform.invalid || maxamountExceeded || submitClicked"
                >
                  SUBMIT
                </button>
              </div>

            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="addexpense-modal modal" id="otherspopupModal" role="dialog" #otherspopupModal>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div  style="margin-bottom: 10px;">
          <div >
            <div class="addexpense-modal-heading" >
              <span title="Back" (click)="goBack('#otherspopupModal')"><i class="far fa-long-arrow-alt-left fa-2x" style="color:#183153;display:inline;margin-left: 5px;"></i></span>
              <label>Other Expenses ({{newExpenseData.exp_type}})</label>
              <button class="spanclose" data-dismiss="modal">
                <i (click)="close()" class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
        <form novalidate (ngSubmit)="createExpenseClick()" #othersform="ngForm">
          <div class="addexpense-modal-content">

            <div style=" padding: 15px;">
              <div class="row">
                <span title="Policy Info" (click)="showPolicyInfoPopup()"><i class="fas fa-info-circle fa-2x" style="color:#22A6B3;cursor:pointer;"></i></span>
              </div>
              <div class="row" style="margin-top:10px;">
                <div class="col-md-6 pl-0 pr-5" >
                  <input
                    type="number"
                    name="othersamount"
                    [(ngModel)]="newExpenseData.amount"
                    #othersamount="ngModel"
                    (input)="onAmountChange()"
                    min="1"
                    required>
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label>Amount</label>
                  <div *ngIf="othersamount.errors && (othersamount.dirty || othersamount.touched)">
                    <span *ngIf="othersamount.errors?.required" class="text-danger">Amount is required</span>
                  </div>
                  <span *ngIf="maxamountExceeded" class="text-danger">Amount exceeds Max Claim amount({{newExpenseData.max_claim_amount}})</span>
                </div>
              </div>

              <div class="row" style="margin-top:20px;">
                <div class="col-md-6 pl-0 pr-5" >
                  <div  >
                    <input
                      type="text"
                      name="name"
                      [(ngModel)]="newExpenseData.name"
                      #name="ngModel"
                      required>
                    <span class="highlight"></span>
                    <span class="bar"></span>
                    <label>Name</label>
                    <div *ngIf="name.errors && (name.dirty || name.touched)">
                      <span *ngIf="name.errors.required" class="text-danger">Name is required</span>
                    </div>
                  </div>
                </div>

              </div>


              <div class="row" style="margin-top:20px;">
                <div class="col-md-12 pl-0 pr-5" >
                  <input type="text"
                         name="other_note"
                         #other_note="ngModel"
                         [(ngModel)]="newExpenseData.note">
                  <span class="highlight"></span>
                  <span class="bar"></span>
                  <label>Comments</label>
                </div>
              </div>

              <div class="row" style="margin-top:20px;">
                <div class="galleryrowgrid" >
                  <ng-container *ngFor="let tile of expensephotos">
                    <div style=" display: inline-block;align-items: center;">
                      <img *ngIf="tile.type !== 'application/pdf'" [src]="tile.photos" style="height: 100px;width:100px;margin-right: 8px;margin-bottom: 8px;"/>
                      <img *ngIf="tile.type === 'application/pdf'" src="./../../../assets/pdf_icon.png" style="height: 100px;width:100px;margin-right: 8px;margin-bottom: 8px;"/>
                      <p style="display: block">{{tile.name}}</p>
                    </div>
                  </ng-container>
                  <div style=" display: inline-flex;align-items: center;">
                    <input
                      type="file"
                      #file
                      style="display:none"
                      accept="image/png, image/jpeg, application/pdf"
                      (change)="handleFileSelect($event)"/>
                    <span style="margin-right:5px;display:inline" (click)="file.click()"
                    >
                  <img  src="assets/upload_picture.svg" width="40px" /></span>
                    <span *ngIf="!expensephotos" style=" display: inline-flex;align-items: center;">Upload Picture</span>
                  </div>
                </div>
              </div>

              <div *ngIf="showTax">
                <div class="row" style="margin-top:20px;">
                  <div class="col-8 pl-0 pr-5">
                    <h4>Include GST</h4>
                    <p class="m-0">
                      Upload Bill with GST if you select this
                    </p>
                  </div>
                  <div class="col-4 my-auto text-right">
                    <label class="switch">
                      <input
                        type="checkbox"
                        name="otherhas_gst"
                        [checked]="newExpenseData.has_gst"
                        [(ngModel)]="newExpenseData.has_gst"
                        #otherhas_gst="ngModel"
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>

                <div *ngIf="newExpenseData.has_gst">
                  <div class="row" style="margin-top:10px;">
                    <div class="col-md-6 pl-0 pr-5" >
                      <input
                        type="text"
                        name="othergstinvoice"
                        [(ngModel)]="newExpenseData.inv_no"
                        #othergstinvoice="ngModel"
                        required>
                      <span class="highlight"></span>
                      <span class="bar"></span>
                      <label>Invoice No</label>
                      <div *ngIf="othergstinvoice.errors && (othergstinvoice.dirty || othergstinvoice.touched)">
                        <span *ngIf="othergstinvoice.errors.required" class="text-danger">Invoice Number is required</span>
                      </div>
                    </div>
                  </div>

                  <div class="row" style="margin-top:20px;">
                    <div class="col-md-6 pl-0 pr-5" >
                      <div >
                        <input
                          name="others_inv_date"
                          #others_inv_date="ngModel"
                          [(ngModel)]="newExpenseData.inv_date"
                          [owlDateTime]="dt7"
                          [owlDateTimeTrigger]="dt7"
                          required
                        >
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label>Invoice Date</label>
                        <owl-date-time (afterPickerClosed)="setGSTInvoiceDate(dt7)" [pickerType]="'calendar'" #dt7></owl-date-time>
                        <div *ngIf="others_inv_date.errors && (others_inv_date.dirty || others_inv_date.touched)">
                          <span *ngIf="others_inv_date.errors.required" class="text-danger">Invoice Date is required</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" style="margin-top:20px;">
                    <div class="col-md-6 pl-0 pr-5" >
                      <div  >
                        <input
                          type="text"
                          name="otherpartygstn"
                          [(ngModel)]="newExpenseData.inv_tax_id"
                          #otherpartygstn="ngModel"
                          required>
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label>Party GSTN No</label>
                        <div *ngIf="otherpartygstn.errors && (otherpartygstn.dirty || otherpartygstn.touched)">
                          <span *ngIf="otherpartygstn.errors.required" class="text-danger">Party GSTN No is required</span>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="row" style="margin-top:20px;">
                    <div class="col-md-6 pl-0 pr-5" >
                      <div  >
                        <input
                          type="text"
                          name="othercompanygstn"
                          [(ngModel)]="newExpenseData.company_tax_id"
                          #othercompanygstn="ngModel"
                        >
                        <span class="highlight"></span>
                        <span class="bar"></span>
                        <label>Company GSTN</label>
                        <div *ngIf="othercompanygstn.errors && (othercompanygstn.dirty || othercompanygstn.touched)">
                          <span *ngIf="othercompanygstn.errors.required" class="text-danger">Company GSTN is required</span>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>

          <div>
            <div style="padding:15px;border-top: 2px solid #f3f3f3;height:70px;">
              <div class="button-row" style="position: absolute;  right: 0px;">
             <span class="modal-btn" data-dismiss="modal" style="margin-bottom: 15px;"  data-toggle="modal" >
            <a class="btn white-btn">Cancel</a>
          </span>
                <button
                  style="margin-left:15px; margin-right:15px;"
                  class="btn blue-btn"
                  type="submit"
                  [disabled]="othersform.invalid || maxamountExceeded || submitClicked"
                >
                  SUBMIT
                </button>
              </div>

            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="addexpense-modal modal" id="locationpopupModal" role="dialog" #locationpopupModal>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div  style="margin-bottom: 10px;">
          <div >
            <div class="addexpense-modal-heading" >
              <label>Select Location</label>
              <button class="spanclose" data-dismiss="modal">
                <i (click)="close()" class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="addexpense-modal-content">
          <div class="col-md-6" style="margin-bottom: 10px;">
            <input id="pac-input"  type="text" placeholder="Enter a location" value="" />
          </div>
          <div class="col-md-6 no padding" style="margin-bottom: 40px;">
             <span class="modal-btn" data-dismiss="modal"   data-toggle="modal" >
            <a id="mapsubmit" class="btn blue-btn">Submit</a>
             </span>
          </div>
          <div  class="map" id="map" style="margin-top: 20px;">
            <agm-map
              [latitude]="latitude"
              [longitude]="longitude"
              [zoom]="zoom" >
            </agm-map>
          </div>
          <div id="infowindow-content">
            <span id="place-name" class="title"></span><br />
            <span id="place-address"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" style="max-width:200px;" id="filterpopupModal" role="dialog" #filterpopupModal>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="addexpense-modal-heading" >
          <label>Filters</label>
          <span class="close" data-dismiss="modal">
              <img (click)="close()" src="../../assets/close.png">
           </span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="addexpense-modal modal" id="policyinfopopupModal" role="dialog" #policyinfopopupModal>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div  style="margin-bottom: 10px;">
          <div >
            <div class="addexpense-modal-heading" >
              <label>Policy Info</label>
              <button class="spanclose" data-dismiss="modal">
                <i (click)="close()" class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="addexpense-modal-content">
          <span id="policyinfo"></span>
        </div>
      </div>
    </div>
  </div>
</div>

<button #galleryClick id="openImage2" style="margin-left: -14%;" [hidden]="true" data-toggle="modal" data-target="#policyModal">Open Modal</button>
<button #imageClick id="openImage1" style="margin-left: -14%;" [hidden]="true" data-toggle="modal" data-target="#approveModel">Open Modal</button>
<button #detailClick id="openDetail" style="margin-left: -14%;" [hidden]="true" (click)="openDetailDialog()">Open Modal</button>


