import {PageBase} from "../../../pagebase/pagebase";
import {Component, OnInit} from "@angular/core";
import {ErrorHandler} from "../../../error/error-handler";


@Component({
  selector: 'app-credit-note-list',
  templateUrl: '../../../pagebase/pagebase.html',
  styleUrls: ['../../../pagebase/pagebase.scss']
})

export class DebitNoteListComponent extends PageBase implements OnInit, IPage {

  customFilter: any = {
    name: 'Status',
    key: 'status',
    value: '1',
    values: [],
    show: true
  };

  ngOnInit(): void {

    this.sharedService.setHeader('Debit Notes');
    this.showActionButton = true;
    this.customFilter.values = [
      {id: '0', itemName: 'All'},
      {id: '1', itemName: 'Open'},
      {id: '2', itemName: 'Used'},
    ];
    this.showCustomFilter = true;
    this.showPinnedFooter = true;
    this.columnsWithAggregation = ['amount'];
    this.checkAndLoadPreviousSate();
    this.getAuthorizedActions();
    this.checkAccessControl();
    this.configureGrid();
    this.loadServerData();
  }

  configureGrid() {

    this.API_URL = '/api/pwa_dms_connects/get_debit_notes';
    this.columnDefs = [
      {headerName: "ID", field: "id", width: 60, checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true},
      {headerName: "CN NO", field: "name", width: 50},
      {headerName: "CN DATE", field: "cn_date", width: 60},
      {headerName: "CUSTOMER CODE", field: "cust_code", width: 100},
      {headerName: "CUSTOMER NAME", field: "cust_name", width: 250},
      {headerName: "PAYMENT TYPE", field: "payment_type", width: 50, hide: true},
      {headerName: "AMOUNT", field: "amount", width: 50,  aggFunc: 'sum', cellStyle: {textAlign: 'right'}, valueFormatter: params => this.formatNumber(params.value)},
      {headerName: "BALANCE", field: "balance", width: 50, aggFunc: 'sum', cellStyle: {textAlign: 'right'}, valueFormatter: params => this.formatNumber(params.value)},
      {headerName: "INVOICE", field: "invoice_no", width: 50},
      {headerName: "CN TYPE", field: "cn_type", width: 50},
      {headerName: "NARRATION", field: "comments", width: 100},
    ];

    this.getRowId = (params) => params.data.id;
  }

  // onCellClicked(event) {
  //   const rows = this.gridApi.getSelectedRows();
  //   const filteredRowData = this.gridApi.rowModel.rowsToDisplay;
  //   if (filteredRowData !== null && filteredRowData.length > 0) {
  //     const ids = [];
  //     filteredRowData.forEach(r => {
  //       ids.push(r.data.id);
  //     });
  //     localStorage.setItem('active_ids', JSON.stringify(ids));
  //     this.router.navigate([this.apiService.redirect_url + '/dms/sale_invoice'], {
  //       relativeTo: this.route,
  //       queryParams: {id: rows[0].id}
  //     });
  //   }
  // }

  checkAccessControl() {
    // this._localCacheService.getAccessControlList().then(list => {
    this.showCreate = false;
    // });
  }

  openCreateDialog() {
    // this.router.navigate([this.apiService.redirect_url + '/dms/sale_invoice'], {
    //   relativeTo: this.route,
    //   queryParams: {id: 0 }
    // });
  }

}
