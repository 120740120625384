import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {ErrorHandler} from '../../error/error-handler';

@Component({
  selector: 'app-retail-stock-report',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

// tslint:disable-next-line:component-class-suffix
export class RetailStockRangeDistributionReport extends ReportBase implements OnInit {

  apiData: any = [];
  private ranges: any = [];
  showCustomFilter = true;
  customFilter: any = {
    name: 'Range',
    key: 'id',
    value: 25,
    values: [],
    show: true
  };


  ngOnInit() {

    this.sharedService.setHeader('Retail Stock Range Distribution');
    this.API_URL = '/api/pwa_reports/get_retail_stock_range_distribution';

    this.showableFilters.territory = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;

    this.customFilter.values = [
      {id: 25, itemName: "25"},
      {id: 50, itemName: "50"},
      {id: 100, itemName: "100"},
      {id: 200, itemName: "200"},
      {id: 500, itemName: "500"},
    ];

    // this.configureGrid();
  }


  loadServerData() {
    this.goClicked = true;
    this.dataLoaded = false;
    this.preloader = true;
    this.emptyState = false;
    this.errorExists = false;

    if (this.API_URL === '') {
      return this.handleError(ErrorHandler.getErrorObject('API Not Configured'));
    }

    this.userData.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.userData.end_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    if (this.showCustomFilter) {
      if ( this.userData.customFilter != null && !this.userData.customFilter.hasOwnProperty(this.customFilter.key)) {
        this.userData.customFilter[this.customFilter.key] = this.customFilter.value;
      }
    }
    this.apiService.post(this.API_URL, this.userData)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            //this.rowData = res.results.data;
            this.apiData = res.results.data;
            this.configureGrid();
            // this.configureData();
            this.dataLoaded = true;
            this.preloader = false;
            this.emptyState = (this.rowData.length === 0);
          } else if (res.results.status === 203) {
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        // tslint:disable-next-line:no-shadowed-variable
        error => this.handleError(ErrorHandler.getErrorObject(error)));

  }

  configureGrid() {
    this.columnDefs = [];

    this.columnDefs = [
      {headerName: 'REGION', field: 'region', enableRowGroup: true,  width: 80},
      {headerName: 'CLUSTER', field: 'cluster', enableRowGroup: true, width: 80},
      {headerName: 'TERRITORY', field: 'territory', enableRowGroup: true, width: 80},
      {headerName: 'SALESMAN', field: 'salesman', enableRowGroup: true, width: 100},
    ];


    this.apiData.forEach(row => {
      row.placement.forEach(pRow => {
        if (!this.ranges.includes(pRow['r'])) {
          this.ranges.push(pRow['r']);
        }
      });
    });

    this.ranges.sort((a, b) => a - b);
    this.ranges.forEach(item => {
      this.columnDefs.push({
        headerName: item + '-' + (item + parseInt(this.userData.customFilter['id'], 0)),
        field: 'r_' + item,
        width: 50,
        aggFunc: 'sum'
      });
    });

    this.rowData = [];
    this.apiData.forEach(row => {
      row.placement.forEach(pRow => {
        row['r_' + pRow.r] = pRow.c;
      });

      this.rowData.push(row);
    });
  }
}
