import {Component, OnInit} from "@angular/core";
import {ReportBase} from "../_common/reportbase";

@Component({
  selector: 'app-stock-availability-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class StockDistributionReportComponent extends ReportBase implements IReport, OnInit {

  setTitle() {
    throw new Error("Method not implemented.");
  }

  ngOnInit() {

    this.sharedService.setHeader('Stock Distribution Report');

    this.showableFilters.territory = false;
    this.showableFilters.team = false;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;

    this.configureGrid();

  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getStockDistributionReport';

    this.gridOptions.autoGroupColumnDef = {
      headerName: 'Territory',
      field: 'territory',
      minWidth: 250,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        checkbox: true,
      },
    };


    this.columnDefs = [
      {headerName: 'REGION', field: 'region', rowGroup: true, width: 80, enableRowGroup: true, hide: true},
      {headerName: 'CLUSTER', field: 'cluster', width: 80, enableRowGroup: true, hide: true},
      {headerName: 'TERRITORY', field: 'territory', width: 80, enableRowGroup: true, hide: true},
      {headerName: 'MANAGER', field: 'manager', width: 80, enableRowGroup: true, hide: true},
      {headerName: 'AUDIT BY', field: 'audited_by', enableRowGroup: true, width: 80},
      {headerName: 'PROMOTER', field: 'promoter', enableRowGroup: true, width: 80},
      {headerName: 'RID', field: 'retailer_id', enableRowGroup: true, width: 50},
      {headerName: 'RCODE', field: 'outlet_code', enableRowGroup: true, width: 50},
      {headerName: 'RETAILER', field: 'outlet_name', enableRowGroup: true, width: 120},
      {headerName: 'CHANNEL', field: 'retailer_type', enableRowGroup: true, width: 80},
      {headerName: 'PROGRAM', field: 'program', enableRowGroup: true, width: 80},
      {headerName: 'PRICE LIST', field: 'pl_name', enableRowGroup: true, width: 80},
      {headerName: 'DATE', field: 'audit_date', enableRowGroup: true, width: 80},
      {headerName: 'Distribution %', field: 'dist_score', width: 80, cellStyle: {textAlign: 'right'}, aggFunc: this.avgAggFunction},
      {headerName: 'MSS%', field: 'p5_score', width: 50, cellStyle: {textAlign: 'right'}, aggFunc: this.avgAggFunction},
      {headerName: 'FOCUS%', field: 'p4_score', width: 50, cellStyle: {textAlign: 'right'}, aggFunc: this.avgAggFunction},
      {headerName: 'REGIONAL%', field: 'p3_score', width: 60, cellStyle: {textAlign: 'right'}, aggFunc: this.avgAggFunction},
      {headerName: 'Stock Value', field: 'stock_value', width: 60, cellStyle: {textAlign: 'right'}},
      {headerName: 'Avail SKU', field: 'total_sku', width: 50, cellStyle: {textAlign: 'right'}},
      {headerName: 'TOTAl SKU', field: 'sku_count', width: 50, cellStyle: {textAlign: 'right'}}
    ];
  }


  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllColumns().length;

    params.api.setRowData(this.rowData);
    this.preloader = false;

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });

    if (screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  avgAggFunction(params) {
    let avg;
// the average will be the sum / count
    let sum = 0;
    let count = 0;

    params.values.forEach(value => {
      const groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        // we are aggregating groups, so we take the
        // aggregated values to calculated a weighted average
        sum += value.avg * value.count;
        count += value.count;
      } else {
        // skip values that are not numbers (ie skip empty values)
        if (typeof value === 'number') {
          sum += value;
          count++;
        }
      }
    });
    // avoid divide by zero error
    if (count !== 0) {
      avg = sum / count;
    } else {
      avg = null;
    }

    avg = Math.round(Math.round((avg + Number.EPSILON) * 100) / 100);

    // the result will be an object. when this cell is rendered, only the avg is shown.
    // however when this cell is part of another aggregation, the count is also needed
    // to create a weighted average for the next level.
    const result = {
      count,
      avg,
      // the grid by default uses toString to render values for an object, so this
      // is a trick to get the default cellRenderer to display the avg value
      toString() {
        return this.avg;
      },
    };

    return result;
  }


}
