import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {LocalforageService} from "./localforage.service";
import {DiageoCustomConfig} from "../boards/services/custom_config_diageo";
import {BehaviorSubject, EMPTY, Observable} from "rxjs";
import {GTManagerBoardCollection} from "../boards/services/board_gt_manager";
import {MTManagerBoardCollection} from "../boards/services/board_mt_manager";
import {ReportMasterList} from "../report/ReportMasterList";
import {VansalesManagerBoardCollection} from "../boards/services/board_vansales_manager";
import {HILManagerBoardCollection} from "../boards/services/board_hil_manager";
import {GTDistributorBoardCollection} from "../boards/services/board_gt_dms";

@Injectable()
export class CustomConfigService {

  currentBoardModel: any;

  constructor(protected apiService: ApiService,
              protected _localforageService: LocalforageService) {

  }

  private GetCustomConfig(): Observable<any> {

    return new Observable(observer => {

      this._localforageService.get('user_custom_config').then(r => {
        if (r == null) {
          const paramObject: any = {};
          paramObject.cid = JSON.parse(localStorage.getItem('user')).cid;
          paramObject.access_token = localStorage.getItem('resfreshToken');
          this.apiService.post('/api/res_users/getUsersReportJSON', paramObject)
            .subscribe(res => {
              if (res.hasOwnProperty('results') && (res.results.status === 200)) {
                const data = res.results.data;
                if (data && data.user_config != null) {
                  this._localforageService.set('user_custom_config', data.user_config).then(() => {
                    observer.next(data.user_config);
                    return () => {
                    };
                  });
                } else if (data && data.role_config != null) {
                  this._localforageService.set('role_custom_config', data.role_config).then(() => {
                    //  do nothing...
                    observer.next(null);
                    return () => {
                    };
                  });
                } else {

                  observer.next(null);
                  return () => {
                  };
                }

              } else {
                console.error('Error Calling getUsersReportJSON :' + res);
                observer.next(null);
                return () => {
                };
              }
            }, error => {
              console.error(error);
              observer.next(null);
              return () => {
              };
            });

        } else {
          observer.next(r);
          return () => {
          };
        }
      });

    });
  }

  private getConfigFromLocal() {
    if (localStorage.getItem('board_diageo_manager') === 'true') {
      return DiageoCustomConfig;
    } else if (localStorage.getItem('board_mt_manager') === 'true') {
      return MTManagerBoardCollection;
    } else if (localStorage.getItem('board_vansales_manager') === 'true') {
      return VansalesManagerBoardCollection;
    } else if (localStorage.getItem('board_hil_manager') === 'true') {
      return HILManagerBoardCollection;
    } else if (localStorage.getItem('dms_module') === 'true') {
      return GTDistributorBoardCollection;
    } else {
      return GTManagerBoardCollection;
    }
  }

  public getDefaultBoard()  {
    return new Observable(observer => {
      this.GetCustomConfig().subscribe(conf => {
        if (conf != null && conf['boards'] && Object.keys(conf['boards']) && Object.keys(conf['boards']).length > 0) {
          observer.next(conf['boards'][0]);
          return () => {
          };
        } else {
          this._localforageService.get('role_custom_config').then(r => {
            if (r !== null && r !== undefined && r['boards'] && Object.keys(r['boards']) && Object.keys(r['boards']).length > 0) {
              observer.next(r['boards'][0]);
              return () => {
              };
            } else {
              const localconfig = this.getConfigFromLocal();
              if (localconfig !== null && localconfig !== undefined && localconfig['boards'] && Object.keys(localconfig['boards']) && Object.keys(localconfig['boards']).length > 0) {
                observer.next(localconfig['boards'][0]);
                return () => {
                };
              } else {
                observer.next(null);
                return () => {
                };
              }
            }
          });
        }
      });
    });
  }

  public getBoards() {
    return new Observable(observer => {
      this.GetCustomConfig().subscribe(conf => {
        let boards = [];
        if (conf != null && conf['boards'] && Object.keys(conf['boards']) && Object.keys(conf['boards']).length > 0) {
          boards = conf['boards'];
        }
        observer.next(boards);
        return () => {
        };
      });
    });
  }

  public getBoardByTitle(title: string) {
    return new Observable(observer => {
      this.GetCustomConfig().subscribe(conf => {
        let board = {};
        if (conf != null && conf['boards'] && Object.keys(conf['boards']) && Object.keys(conf['boards']).length > 0) {
          conf['boards'].forEach(boardModel => {
            if (boardModel.title === title) {
              board = boardModel;
            }
          });
        }
        observer.next(board);
        return () => {
        };
      });
    });
  }

  public saveBoard(board: any) {

    //TODO: avoid this and fix why defalt gt board is getting saved
    if (localStorage.getItem('board_diageo_manager') === 'true') {
      return;
    }
    this._localforageService.get('user_custom_config').then(config => {
      if (config == null) {
        config = {
          boards : [],
          reports : []
        };
      }

      //Delete board instance
      if (config['boards'] === undefined) {
        config['boards'] = [];
      }
      let index;
      index = config['boards'].findIndex(item => {
        return item.title === board.title;
      });
      if (index >= 0) {
        config['boards'].splice(index, 1);
      }

      //Push new board
      config['boards'].push(board);

      this._localforageService.set('user_custom_config', config).then(value => {
        const paramObject: any = {};
        paramObject.cid = JSON.parse(localStorage.getItem('user')).cid;
        paramObject.access_token = localStorage.getItem('resfreshToken');
        paramObject.data = value;
        this.apiService.post('/api/res_users/saveUsersReportJSON', paramObject)
          .subscribe(res => {
            if (res.hasOwnProperty('results') && (res.results.status === 200)) {

              console.log('board saved tp DB');

            } else {
              console.error('Error Calling saveCustomJSON :' + res);
            }
          }, error => console.error(error));
      });
    });

  }



  /*
   when a gadget instance's property page is updated and saved, the change gets communicated to all
   gadgets. The gadget instance id that caused the change will update their current instance. todo - this might be able to be
   improved. For now the utility of this approach allows the configuration service to capture the property page change in a way
   that allows us to update the persisted board model.
   */
  notifyGadgetOnPropertyChange(gadgetConfig: string, instanceId: number) {

    this.savePropertyPageConfigurationToStore(gadgetConfig, instanceId);
  }

  setCurrentModel(_currentModel: any) {
    this.currentBoardModel = _currentModel;
  }

  savePropertyPageConfigurationToStore(gadgetConfig: string, instanceId: number) {

    this.currentBoardModel.rows.forEach(row => {

      row.columns.forEach(column => {

        if (column.gadgets) {
          column.gadgets.forEach(gadget => {
            this.updateProperties(gadgetConfig, gadget, instanceId);

          });
        }
      });
    });

    this.saveBoard(this.currentBoardModel);

  }

  updateProperties(updatedProperties: any, gadget: any, instanceId: number) {

    const updatedPropsObject = JSON.parse(updatedProperties);

    if (gadget.instanceId === instanceId) {

      gadget.config.propertyPages.forEach(propertyPage => {
        propertyPage.properties.forEach(property => {
          for (const prop in updatedPropsObject) {
            if (updatedPropsObject.hasOwnProperty(prop)) {
              if (prop === property.key) {
                property.value = updatedPropsObject[prop];
              }
            }
          }
        });
        // for (let x = 0; x < propertyPage.properties.length; x++) {
        //
        //   for (const prop in updatedPropsObject) {
        //     if (updatedPropsObject.hasOwnProperty(prop)) {
        //       if (prop === propertyPage.properties[x].key) {
        //         propertyPage.properties[x].value = updatedPropsObject[prop];
        //       }
        //     }
        //   }
        // }
      });
    }
  }

  deleteBoard(name: string) {
    return new Observable(observer => {
      //  TODO: implement delete board.
      observer.next({});
      return () => {
      };

    });

  }


  /* Reports related functions */
  public getReportByName(component_name) {
    return new Observable(observer => {
      this.GetCustomConfig().subscribe(conf => {
        let report = {
          name: component_name
        };
        if (conf != null && Object.keys(conf['reports']).length > 0) {
          report = conf['reports'].find(item => {
            return item.name === component_name;
          });
        }
        this._localforageService.get('role_custom_config').then(r => {
          if (r !== null && r !== undefined && Object.keys(r['reports']).length > 0) {
            const roleReport = r['reports'].find(item => {
              return item.name === component_name;
            });

            if (roleReport.hasOwnProperty('config') && roleReport['config'].length > 0) {
              report['role_config'].push(roleReport['config']);
            }
            // return report;
            observer.next(report);
            return () => {
            };
          } else {
            // return report;
            observer.next(report);
            return () => {
            };
          }
        });
      });
    });
  }

  public addReportConfig(component_name, fav_config, fav_defaut = false) {
    return new Observable(observer => {
      let report_obj = {};
      this._localforageService.get('user_custom_config').then(config => {
        if (config == null) {
          config = {
            boards: [],
            reports: []
          };
        }

        if (config['reports'] === undefined) {
          config['reports'] = [];
        }
        let rIndex;
        rIndex = config['reports'].findIndex(item => {
          return item.name === component_name;
        });
        if (rIndex >= 0) {
          report_obj = config['reports'][rIndex];

          // delete the entry
          config['reports'].splice(rIndex, 1);
        } else {

          report_obj['name'] = component_name;
          report_obj['config'] = [];
        }

        if (report_obj['config'] === undefined) {
          report_obj['config'] = [];
        }

        let cIndex;
        cIndex = report_obj['config'].findIndex(item => {
          return item.name === fav_config.name;
        });
        if (cIndex >= 0) {
          // delete the entry
          report_obj['config'].splice(cIndex, 1);
        }
        report_obj['config'].unshift(fav_config);

        if (fav_defaut) {
          report_obj['default'] = fav_config.name;
        }
        //Push new board
        config['reports'].push(report_obj);

        this._localforageService.set('user_custom_config', config).then(value => {
          const paramObject: any = {};
          paramObject.cid = JSON.parse(localStorage.getItem('user')).cid;
          paramObject.access_token = localStorage.getItem('resfreshToken');
          paramObject.data = value;
          this.apiService.post('/api/res_users/saveUsersReportJSON', paramObject)
            .subscribe(res => {
              if (res.hasOwnProperty('results') && (res.results.status === 200)) {

                observer.next(report_obj);
                return () => {
                };
              } else {
                console.error('Error Calling saveCustomJSON :' + res);
              }
            }, error => console.error(error));
        });
      });
    });
  }


  deleteReportConfig(component_name: string, config_name: string) {
    return new Observable(observer => {


      let report_obj = {};
      this._localforageService.get('user_custom_config').then(config => {
        if (config == null) {
          config = {
            boards: [],
            reports: []
          };
        }

        if (config['reports'] === undefined) {
          config['reports'] = [];
        }
        let rIndex;
        rIndex = config['reports'].findIndex(item => {
          return item.name === component_name;
        });
        if (rIndex >= 0) {
          report_obj = config['reports'][rIndex];

          // delete the entry
          config['reports'].splice(rIndex, 1);
        } else {

          report_obj['name'] = component_name;
          report_obj['config'] = [];
        }

        if (report_obj['config'] === undefined) {
          report_obj['config'] = [];
        }

        let cIndex;
        cIndex = report_obj['config'].findIndex(item => {
          return item.name === config_name;
        });
        if (cIndex >= 0) {
          // delete the entry
          report_obj['config'].splice(cIndex, 1);
        }

        //Push new board
        config['reports'].push(report_obj);

        this._localforageService.set('user_custom_config', config).then(value => {
          const paramObject: any = {};
          paramObject.cid = JSON.parse(localStorage.getItem('user')).cid;
          paramObject.access_token = localStorage.getItem('resfreshToken');
          paramObject.data = value;
          this.apiService.post('/api/res_users/saveUsersReportJSON', paramObject)
            .subscribe(res => {
              if (res.hasOwnProperty('results') && (res.results.status === 200)) {

                observer.next(report_obj);
                return () => {
                };
              } else {
                console.error('Error Calling saveCustomJSON :' + res);
              }
            }, error => console.error(error));
        });
      });
    });
  }
}
