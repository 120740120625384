export const DiageoCustomConfig = {
  boards: [{
    title: 'Dashboard',
    structure: '4-4-4-4',
    id: 9,
    boardInstanceId: 3,
    rows: [
      {
        columns: [
          {
            styleClass: 'four wide',
            gadgets: [
              {
                componentType: 'StatisticGadgetComponent',
                name: 'diageo_universe',
                description: 'General statistic.',
                icon: 'images/statistic.png',
                instanceId: 101,
                tags: [
                  {
                    facet: 'Statistic',
                    name: 'counter'
                  }
                ],
                config: {
                  propertyPages: [
                    {
                      displayName: 'Run',
                      groupId: 'run',
                      position: 10,
                      properties: [
                        {
                          key: 'title',
                          value: 'Universe',
                          label: 'Title',
                          required: false,
                          order: 1,
                          controlType: 'textbox'
                        },
                        {
                          key: 'kpi_name',
                          required: true,
                          order: 2,
                          controlType: 'dropdown',
                          label: "KPI Name",
                          value: "diageo_universe",
                        }
                      ]
                    }
                  ]
                }
              }
            ]
          },
          {
            styleClass: 'four wide',
            gadgets: [
              {
                componentType: 'StatisticGadgetComponent',
                name: 'diageo_coverage',
                description: 'General statistic.',
                icon: 'images/statistic.png',
                instanceId: 103,
                tags: [
                  {
                    facet: 'Statistic',
                    name: 'counter'
                  }
                ],
                config: {
                  propertyPages: [
                    {
                      displayName: 'Run',
                      groupId: 'run',
                      position: 10,
                      properties: [
                        {
                          key: 'title',
                          value: 'Coverage',
                          label: 'Title',
                          required: false,
                          order: 1,
                          controlType: 'textbox'
                        },
                        {
                          key: 'kpi_name',
                          required: true,
                          order: 2,
                          controlType: 'dropdown',
                          label: "KPI Name",
                          value: "diageo_coverage"
                        }
                      ]
                    }
                  ]
                }
              }
            ]
          },
          {
            styleClass: 'four wide',
            gadgets: [
              {
                componentType: 'StatisticGadgetComponent',
                name: 'diageo_callage',
                description: 'General statistic.',
                icon: 'images/statistic.png',
                instanceId: 102,
                tags: [
                  {
                    facet: 'Statistic',
                    name: 'counter'
                  }
                ],
                config: {
                  propertyPages: [
                    {
                      displayName: 'Run',
                      groupId: 'run',
                      position: 10,
                      properties: [
                        {
                          key: 'title',
                          value: 'Callage',
                          label: 'Title',
                          required: false,
                          order: 1,
                          controlType: 'textbox'
                        },
                        {
                          key: 'kpi_name',
                          required: true,
                          order: 2,
                          controlType: 'dropdown',
                          label: "KPI Name",
                          value: "diageo_callage",
                          options: [{
                            key: "diageo_callage",
                            value: "diageo_callage"
                          }]
                        }
                      ]
                    }
                  ]
                }
              }
            ]
          },
          {
            styleClass: 'four wide',
            gadgets: [
              {
                componentType: 'StatisticGadgetComponent',
                name: 'diageo_visits_virtual_phisical',
                description: 'General statistic.',
                icon: 'images/statistic.png',
                instanceId: 104,
                tags: [
                  {
                    facet: 'Statistic',
                    name: 'counter'
                  }
                ],
                config: {
                  propertyPages: [
                    {
                      displayName: 'Run',
                      groupId: 'run',
                      position: 10,
                      properties: [
                        {
                          key: 'title',
                          value: 'Vists',
                          label: 'Title',
                          required: false,
                          order: 1,
                          controlType: 'textbox'
                        },
                        {
                          key: 'kpi_name',
                          required: true,
                          order: 2,
                          controlType: 'dropdown',
                          label: "KPI Name",
                          value: "diageo_visits_virtual_phisical",
                          options: [{
                            key: "diageo_visits_virtual_phisical",
                            value: "diageo_visits_virtual_phisical"
                          }]
                        }
                      ]
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      {
        columns: [
          {
            styleClass: 'four wide',
            gadgets: [
              {
                componentType: 'StatisticGadgetComponent',
                name: 'diageo_trax_msl',
                description: 'General statistic.',
                icon: 'images/statistic.png',
                instanceId: 201,
                tags: [
                  {
                    facet: 'Statistic',
                    name: 'counter'
                  }
                ],
                config: {
                  propertyPages: [
                    {
                      displayName: 'Run',
                      groupId: 'run',
                      position: 10,
                      properties: [
                        {
                          key: 'title',
                          value: 'Trax MSL Score',
                          label: 'Title',
                          required: false,
                          order: 1,
                          controlType: 'textbox'
                        },
                        {
                          key: 'kpi_name',
                          required: true,
                          order: 2,
                          controlType: 'dropdown',
                          label: "KPI Name",
                          value: "diageo_trax_msl",
                          options: [{
                            key: "diageo_universe",
                            value: "diageo_universe"
                          }, {
                            key: "diageo_callage",
                            value: "diageo_callage"
                          }, {
                            key: "diageo_beat_adherance",
                            value: "diageo_beat_adherance"
                          }, {
                            key: "diageo_visits_virtual_phisical",
                            value: "diageo_visits_virtual_phisical"
                          }, {
                            key: "diageo_trax_msl",
                            value: "diageo_trax_msl"
                          }, {
                            key: "diageo_trax_visiblity",
                            value: "diageo_trax_visiblity"
                          }, {
                            key: "diageo_availablity_audit",
                            value: "diageo_availablity_audit"
                          }, {
                            key: "diageo_availablity_focus",
                            value: "diageo_availablity_focus"
                          }, {
                            key: "diageo_availablity_regional_focus",
                            value: "diageo_availablity_regional_focus"
                          }]
                        }
                      ]
                    }
                  ]
                }
              }
            ]
          },
          {
            styleClass: 'four wide',
            gadgets: [
              {
                componentType: 'StatisticGadgetComponent',
                name: 'diageo_trax_visiblity',
                description: 'General statistic.',
                icon: 'images/statistic.png',
                instanceId: 202,
                tags: [
                  {
                    facet: 'Statistic',
                    name: 'counter'
                  }
                ],
                config: {
                  propertyPages: [
                    {
                      displayName: 'Run',
                      groupId: 'run',
                      position: 10,
                      properties: [
                        {
                          key: 'title',
                          value: 'Trax Visiblity',
                          label: 'Title',
                          required: false,
                          order: 1,
                          controlType: 'textbox'
                        },
                        {
                          key: 'kpi_name',
                          required: true,
                          order: 2,
                          controlType: 'dropdown',
                          label: "KPI Name",
                          value: "diageo_trax_visiblity",
                          options: [{
                            key: "diageo_universe",
                            value: "diageo_universe"
                          }, {
                            key: "diageo_callage",
                            value: "diageo_callage"
                          }, {
                            key: "diageo_beat_adherance",
                            value: "diageo_beat_adherance"
                          }, {
                            key: "diageo_visits_virtual_phisical",
                            value: "diageo_visits_virtual_phisical"
                          }, {
                            key: "diageo_trax_msl",
                            value: "diageo_trax_msl"
                          }, {
                            key: "diageo_trax_visiblity",
                            value: "diageo_trax_visiblity"
                          }, {
                            key: "diageo_availablity_audit",
                            value: "diageo_availablity_audit"
                          }, {
                            key: "diageo_availablity_focus",
                            value: "diageo_availablity_focus"
                          }, {
                            key: "diageo_availablity_regional_focus",
                            value: "diageo_availablity_regional_focus"
                          }]
                        }
                      ]
                    }
                  ]
                }
              }
            ]
          },
          {
            styleClass: 'four wide',
            gadgets: [
              {
                componentType: 'StatisticGadgetComponent',
                name: 'diageo_availablity_audit',
                description: 'General statistic.',
                icon: 'images/statistic.png',
                instanceId: 203,
                tags: [
                  {
                    facet: 'Statistic',
                    name: 'counter'
                  }
                ],
                config: {
                  propertyPages: [
                    {
                      displayName: 'Run',
                      groupId: 'run',
                      position: 10,
                      properties: [
                        {
                          key: 'title',
                          value: 'Availablity Score',
                          label: 'Title',
                          required: false,
                          order: 1,
                          controlType: 'textbox'
                        },
                        {
                          key: 'kpi_name',
                          required: true,
                          order: 2,
                          controlType: 'dropdown',
                          label: "KPI Name",
                          value: "diageo_availablity_audit",
                          options: [{
                            key: "diageo_universe",
                            value: "diageo_universe"
                          }, {
                            key: "diageo_callage",
                            value: "diageo_callage"
                          }, {
                            key: "diageo_beat_adherance",
                            value: "diageo_beat_adherance"
                          }, {
                            key: "diageo_visits_virtual_phisical",
                            value: "diageo_visits_virtual_phisical"
                          }, {
                            key: "diageo_trax_msl",
                            value: "diageo_trax_msl"
                          }, {
                            key: "diageo_trax_visiblity",
                            value: "diageo_trax_visiblity"
                          }, {
                            key: "diageo_availablity_audit",
                            value: "diageo_availablity_audit"
                          }, {
                            key: "diageo_availablity_focus",
                            value: "diageo_availablity_focus"
                          }, {
                            key: "diageo_availablity_regional_focus",
                            value: "diageo_availablity_regional_focus"
                          }]
                        }
                      ]
                    }
                  ]
                }
              }
            ]
          },
          {
            styleClass: 'four wide',
            gadgets: [
              {
                componentType: 'StatisticGadgetComponent',
                name: 'diageo_availablity_focus',
                description: 'General statistic.',
                icon: 'images/statistic.png',
                instanceId: 304,
                tags: [
                  {
                    facet: 'Statistic',
                    name: 'counter'
                  }
                ],
                config: {
                  propertyPages: [
                    {
                      displayName: 'Run',
                      groupId: 'run',
                      position: 10,
                      properties: [
                        {
                          key: 'title',
                          value: 'Focus Score',
                          label: 'Title',
                          required: false,
                          order: 1,
                          controlType: 'textbox'
                        },
                        {
                          key: 'kpi_name',
                          required: true,
                          order: 2,
                          controlType: 'dropdown',
                          label: "KPI Name",
                          value: "diageo_availablity_focus",
                          options: [{
                            key: "diageo_universe",
                            value: "diageo_universe"
                          }, {
                            key: "diageo_callage",
                            value: "diageo_callage"
                          }, {
                            key: "diageo_beat_adherance",
                            value: "diageo_beat_adherance"
                          }, {
                            key: "diageo_visits_virtual_phisical",
                            value: "diageo_visits_virtual_phisical"
                          }, {
                            key: "diageo_trax_msl",
                            value: "diageo_trax_msl"
                          }, {
                            key: "diageo_trax_visiblity",
                            value: "diageo_trax_visiblity"
                          }, {
                            key: "diageo_availablity_audit",
                            value: "diageo_availablity_audit"
                          }, {
                            key: "diageo_availablity_focus",
                            value: "diageo_availablity_focus"
                          }, {
                            key: "diageo_availablity_regional_focus",
                            value: "diageo_availablity_regional_focus"
                          }]
                        }
                      ]
                    }
                  ]
                }
              }
            ]
          }
        ]
      },
      {
        columns: [
          {
            styleClass: 'sixteen wide',
            gadgets: [
              {
                componentType: 'HeatGraphGadgetComponent',
                name: 'Heat Chart',
                description: 'Activities Heat Map',
                icon: 'images/donut.png',
                instanceId: 301,
                tags: [
                  {
                    facet: 'Chart',
                    name: 'metric'
                  },
                  {
                    facet: "Chart",
                    name: "tree"
                  }
                ],
                config: {
                  propertyPages: [
                    {
                      displayName: "Run",
                      groupId: "run",
                      position: 10,
                      properties: [
                        {
                          key: "title",
                          controlType: "textbox",
                          label: "Title",
                          value: "Activities Heat Map",
                          required: true,
                          order: 1
                        },
                        {
                          key: "state",
                          controlType: "hidden",
                          label: "",
                          value: "stop",
                          required: true,
                          order: -1
                        },
                        {
                          key: 'kpi_name',
                          required: true,
                          order: 3,
                          controlType: 'dropdown',
                          label: "KPI Name",
                          value: "visits_by_date",
                          options: [{
                            key: "visits_by_date",
                            value: "visits_by_date"
                          },
                          {
                            key: "volume_by_date",
                            value: "volume_by_date"
                          }]
                        }
                      ]
                    },
                    {
                      displayName: "Chart",
                      groupId: "chart",
                      position: 11,
                      properties: [
                        {
                          key: "yAxisLabel",
                          controlType: "textbox",
                          label: "Y Axis Label",
                          value: "",
                          required: false,
                          order: 2
                        },
                        {
                          controlType: "textbox",
                          key: "xAxisLabel",
                          label: "X Axis Label",
                          value: "",
                          required: false,
                          order: 3
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "gradient",
                          label: "Show colors in gradient",
                          value: true,
                          required: true,
                          order: 4
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showXAxis",
                          label: "Show x axis",
                          value: true,
                          required: false,
                          order: 6
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showYAxis",
                          label: "Show y axis",
                          value: true,
                          required: false,
                          order: 7
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showLegend",
                          label: "Show chart legend",
                          value: false,
                          required: false,
                          order: 8
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showXAxisLabel",
                          label: "Show x axis label",
                          value: true,
                          required: false,
                          order: 9
                        },
                        {
                          controlType: "checkbox",
                          type: "checkbox",
                          key: "showYAxisLabel",
                          label: "Show y axis label",
                          value: false,
                          required: false,
                          order: 10
                        }
                      ]
                    }
                  ]
                }
              }
            ]
          }
        ]
      }
    ]
  }],
  reports: [],
};

