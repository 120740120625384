<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper dcvanlist">
      <div class="search-list">
        <div style="width: fit-content" class="pad0">
          <div class="search">
            <span class="search-control"><img src="assets/searchicon.png"/></span>
            <input type="text" placeholder="filter" (keyup)="quickSearch()" [(ngModel)]="searchValue" class="search-control-area"
                   placeholder="Search for DC">
          </div>
        </div>
        <div class="pad0">
          <div class="filters">
            <ul class="list-unstyled">
<!--              <li style="margin-right: 1px !important;margin-left: 1px !important;" class="margLR">-->
<!--                &lt;!&ndash;                <app-reportdropdown [itemList]="all_dc"></app-reportdropdown>&ndash;&gt;-->
<!--                <ng-template adHost></ng-template>-->
<!--              </li>-->
              <li style="margin-right: 1px !important;margin-left: 1px !important;" class="margLR">
                <ng-select class="filter-container filter-box" [items]="dcList"
                           bindLabel="itemName"
                           multiple="true"
                           maxSelectedItems="1"
                           [(ngModel)]="selectedDC"
                           (ngModelChange)="dcChange($event)"
                >
                </ng-select>
              </li>
              <li style="margin-right: 1px !important;margin-left: 1px !important;" class="margLR">
                <ng-select class="filter-container filter-box" [items]="typeList"
                           bindLabel="itemName"
                           multiple="true"
                           maxSelectedItems="1"
                           [(ngModel)]="selectedType"
                           (ngModelChange)="typeChange($event)"
                >
                </ng-select>
              </li>
              <li style="margin-right: 1px !important;margin-left: 1px !important;" class="margLR">
                <div class="date-picker">
                  <span class="datepicker-icon"><img src="../../assets/date.png"></span>
                  <input [selectMode]="'range'" [readonly]="true" [(ngModel)]="myDate" [owlDateTimeTrigger]="dt" [owlDateTime]="dt" placeholder="Date">
                  <span class="dropdown-icon"><img src="../../assets/dropdown.png"></span>
                  <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
                </div>
              </li>
              <li style="padding: 6px;">
                <div style="display: inline-block;">
                  <button (click)="run()" class="btn blue-btn">GO</button>
                </div>
              </li>
<!--              <li style="margin-right: 1px !important;margin-left: 1px !important;" class="margLR">-->
<!--                <app-printicon [gridOption]="gridOptions" [styleGrid]="styleGrid"></app-printicon>-->
<!--              </li>-->
              <li style="margin-right: 1px !important;margin-left: 1px !important;" class="margLR">
                <app-excelicon [gridOption]="gridOptions" [title]="'Outlet-Aggregate-Report'"></app-excelicon>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-12 pad0">
          <div class="mt-default" >
            <ag-grid-angular id="myGrid1" *ngIf="loaded" style="width: 100%;height: 72vh;" class="ag-theme-balham"  [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                             (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents">
            </ag-grid-angular>
            <div *ngIf="preloader">
              <!--<div style="position:absolute;left:44%;top:200px;font-size: medium">-->
              <!--<img src="../../assets/images/preloader.gif"/>-->
              <!--</div>-->
              <app-loadingstate></app-loadingstate>
            </div>
            <div *ngIf="empty_state">
              <app-emptystate></app-emptystate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
