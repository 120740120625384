import {
  AfterViewInit,
  Component,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {ICellRendererAngularComp} from "@ag-grid-community/angular";

// backspace starts the editor on Windows
const KEY_BACKSPACE = 'Backspace';
const KEY_F2 = 'F2';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';

@Component({
  selector: 'app-numeric-cell',
  template: `
    <input *ngIf="editMode && showUOM && !isPinnedBottomRow"
           #input
           [(ngModel)]="value"
           class="custom-inputbox"
    />`,
})

export class UOMProductCellRendererComponent implements ICellRendererAngularComp {
  private params: any;
  public value!: number;
  public editMode = false;
  public context;
  public packUOM;
  packSize = 0;
  attributeJson;
  showUOM;
  isPinnedBottomRow = false;
  packDisplay = '';

  agInit(params: any): void {
    this.params = params;
    this.value = this.params.data[params.colDef.field];
    const field_name = params.colDef.field.split(' ');
    if (params.colDef.field === 'units') {
      this.showUOM = true;
      this.packSize = 1;
    } else if (params.data && params.data[params.colDef.field + '_factor_inv'] && params.data[params.colDef.field + '_factor_inv'] > 1) {
      this.showUOM = true;
      this.packSize = params.data[params.colDef.field + '_factor_inv'];
    } else if (field_name.length === 3 && params.data && params.data[field_name[0] + ' ' + field_name[1] + ' SIZE'] && params.data[field_name[0] + ' ' + field_name[1] + ' SIZE'] > 0) {
        this.showUOM = true;
        this.packSize =  params.data[field_name[0] + ' ' + field_name[1] + ' SIZE'];
    } else {
      this.showUOM = false;
    }
    if (params && params.node && params.node.rowPinned === 'bottom') {
      this.isPinnedBottomRow = true;
    } else {
      this.isPinnedBottomRow = false;
    }
    if (this.showUOM) {
      this.editMode = true;
    }
    if (this.params.data.price_unit && this.params.data.price_unit === 0) {
      this.editMode = false;
    }
  }

  refresh(): boolean {
    return false;
  }
}
