<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Advance Payment</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <div class="row">
        <div class="col-md-12 pad0">
          <div class="col-md-7 pl-0 pr-20">
            <div style="width: 100%">
              <span class="namespan">Order Details</span>

              <span class="codespan" style="font-weight: 500;">{{orderDetails['supplier_name']}}</span>
              <span class="codespan">Supplier </span>
            </div>
            <div class="cardbox cardgriddash" style="margin-top: 35px;width: 100%">
              <div>
                <label class="headerlabel">Order Number</label>
                <span class="headerspan">{{orderDetails['name']}}</span>
              </div>
              <div>
                <label class="headerlabel">Order Date</label>
                <span class="headerspan">{{orderDetails['date_order']}}</span>
              </div>
              <div>
                <label class="headerlabel">Order Value</label>
                <span class="headerspan">{{orderDetails['amount_total']}}</span>
              </div>
            </div>
            <div class="cardbox" style="margin-top: 20px;width: 100%">
              <span class="headerspan">Payment Method</span>
              <label class="headerlabel">Choose customer payment method.</label>
              <mat-radio-group aria-label="last_retailer_option"
                               [(ngModel)]="paymentDetails.payment_mode" [ngModelOptions]="{standalone: true}">
                <div
                  [ngClass]="{'paymentselected': paymentDetails.payment_mode === 'upi','paymentnotselected': paymentDetails.payment_mode !== 'upi'}">
                  <mat-radio-button value="upi" checked style="display: block;">
                    UPI
                  </mat-radio-button>
                  <div *ngIf="paymentDetails.payment_mode === 'upi'">
                    <div class="row" style="width: 100%;display: block">
                      <span style="display: inline-block;float: left;margin-top: 12px;">Please Enter your UPI ID</span>
                      <button
                        class="btnsmall confirm-blue-btn"
                        style="float: right"
                      >Verify
                      </button>
                    </div>
                    <div class="row" style="display: flex;width: 100%;">
                      <input
                        class="forminputwhitebg"
                        style="display: inline; color: #2D2D2D; font-size: 14px;margin-top: 10px;flex: 1; "
                        type="text"
                        name="upi"
                        [(ngModel)]="paymentDetails.upi_id"
                        #upi="ngModel"
                        placeholder="UPI ID"
                      >
                    </div>
                  </div>
                </div>
                <div
                  [ngClass]="{'paymentselected': paymentDetails.payment_mode === 'card','paymentnotselected': paymentDetails.payment_mode !== 'card'}">
                  <mat-radio-button value="card" style="margin-top: 5px;display: block;">
                    Credit/Debit Card
                  </mat-radio-button>
                  <div *ngIf="paymentDetails.payment_mode === 'card'">
                    <div class="row" style="width: 100%;display: block">
                    <span
                      style="display: inline-block;float: left;margin-top: 12px;">Please Enter your Card Details</span>
                    </div>
                    <div class="row" style="display: flex;width: 100%;">
                      <input
                        class="forminputwhitebg"
                        style="display: inline; color: #2D2D2D; font-size: 14px;margin-top: 10px;flex: 1; "
                        type="text"
                        name="card"
                        [(ngModel)]="paymentDetails.card"
                        #card="ngModel"
                        placeholder="Card Details"
                      >
                    </div>
                  </div>
                </div>
                <div
                  [ngClass]="{'paymentselected': paymentDetails.payment_mode === 'cheque','paymentnotselected': paymentDetails.payment_mode !== 'cheque'}">
                  <mat-radio-button value="cheque" style="margin-top: 5px;display: block;">
                    Cheque
                  </mat-radio-button>
                  <div *ngIf="paymentDetails.payment_mode === 'cheque'">
                    <div class="row" style="width: 100%;display: block">
                      <span style="display: inline-block;float: left;margin-top: 12px;">Please Enter Cheque Number, Bank Name and attach proof</span>
                    </div>
                    <div class="row" style="display: flex;width: 100%;">
                      <input
                        class="forminputwhitebg"
                        style="display: inline; color: #2D2D2D; font-size: 14px;margin-top: 10px;flex: 1; margin-right: 10px;"
                        type="text"
                        name="cheque_no"
                        [(ngModel)]="paymentDetails.cheque_no"
                        #cheque_no="ngModel"
                        placeholder="Cheque No"
                      >
                      <input
                        class="forminputwhitebg"
                        style="display: inline; color: #2D2D2D; font-size: 14px;margin-top: 10px;flex: 1; "
                        type="text"
                        name="bank"
                        [(ngModel)]="paymentDetails.bank"
                        #cheque_no="ngModel"
                        placeholder="Bank Name"
                      >
                    </div>
                    <div style="margin-top: 10px;">
                      <span style="margin-right:5px;display:inline">
                        <img src="assets/upload_picture.svg" width="40px"/>
                      </span>
                      <span style=" display: inline-flex;align-items: center;">Attach Proof</span>
                    </div>
                  </div>
                </div>
              </mat-radio-group>
            </div>
          </div>
          <div class="col-md-5 pl-0 pr-20">
            <div class="cardbox" style="margin-top: 15px;width: 100%">
              <div style="width: 100%">
                <span class="headerspan" style="float: left">Amount Payable</span>
              </div>
              <input
                id="tot_amount"
                class="forminput"
                style="display: block; color: #0762AD; font-size: 22px; font-weight: 500;margin-top: 10px;width: 100%;height:45px;"
                type="number"
                name="code"
                [(ngModel)]="paymentDetails.tot_amount"
                (change)="onAmountChange()"
                #code="ngModel"
              >
            </div>
            <div class="cardbox" style="margin-top: 20px;width: 100%">
              <span class="headerspan">Narration</span>
              <textarea
                class="forminputwhitebg"
                style="display: block; color: #7D7D7D; font-size: 14px;margin-top: 5px;width: 100%;height:100px;"
                type="text"
                name="narration"
                [(ngModel)]="paymentDetails.narration"
                #narration="ngModel"
                placeholder="Enter Narration"
              ></textarea>
            </div>
            <div>
              <button
                class="btnsmall confirm-blue-btn"
                style="width: 100%; margin-top: 15px;padding: 10px 30px;"
                (click)="collectPayment()"
              >
                Pay Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
