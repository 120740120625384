import {PageBase} from "../pagebase/pagebase";
import {Component, OnInit} from "@angular/core";
import {MatDialogConfig} from "@angular/material/dialog";
import {CreateBeatPlanPopupComponent} from "./create_beat_plan_popup/create_beat_plan_popup.component";

@Component({
  selector: 'app-beat-plan-list',
  templateUrl: '../pagebase/pagebase.html',
  styleUrls: ['../pagebase/pagebase.scss']
})


export class TerritoryListComponent extends PageBase implements OnInit, IPage {

  ngOnInit(): void {

    this.sharedService.setHeader('Territory');

    this.showableFilters.region = true;
    this.showableFilters.cluster = true;
    this.showableFilters.territory = false;
    this.showableFilters.salesman = true;
    this.showableFilters.team = true;

    this.showDate = false;
    this.showCreate = false;
    this.configureGrid();
    this.loadServerData();
  }


  configureGrid() {
    this.API_URL = '/api/res_territories/get_territory_list_pwa';
    const inthis = this;
    this.columnDefs = [
      {headerName: "ID", field: "id", width: 40},
      {headerName: "CODE", field: "code", width: 60},
      {headerName: "TERRITORY", field: "name", width: 120},
      {headerName: "SALESMAN", field: "salesman", width: 100},
      {headerName: "EMP CODE", field: "emp_code", width: 40, hide: true},
      {headerName: "SALESMAN ID", field: "manager_id", width: 40, hide: true},
      {headerName: "REGION", field: "region", width: 60},
      {headerName: "CLUSTER", field: "cluster", width: 60},
      {headerName: "ACTIVE", field: "active", width: 40, hide: true},
      {headerName: "BEAT COUNT", field: "beat_count", width: 40, aggFunc: 'sum'},
      {headerName: "SALESMAN COUNT", field: "salesman_count", width: 40, aggFunc: 'sum'},
      {headerName: "SUPPLIER COUNT", field: "dis_count", width: 40, aggFunc: 'sum'},
      {headerName: "PRODUCTS COUNT", field: "prod_count", width: 40, aggFunc: 'avg'},
      {headerName: "PRICE LIST", field: "pl_name", width: 40, hide: true},
      {headerName: "CREATED BY", field: "created_by", width: 60},
      {
        headerName: "CREATE DATE", field: "create_date", width: 60,
        valueGetter: params => {
          if (params.data) {
            if (params.data.create_date) {
              return inthis.datePipe.transform((new Date(params.data.create_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return params.data.create_date;
            }
          } else {
            return '';
          }
        }
      },
    ];
  }

  // onCellClicked(event) {
  //   const column = event.column.getColId();
  //   const rows = this.gridApi.getSelectedRows();
  //   localStorage.setItem('beat_plan_data', JSON.stringify(rows[0]));
  //   this.router.navigate([this.apiService.redirect_url + '/beats/beat_plan/details'], {
  //     state: {
  //       type: 'detail',
  //       beatplandata: rows[0]
  //     }
  //   });
  // }
  //
  // openCreateDialog() {
  //   const config: MatDialogConfig = {
  //     width: "100vw",
  //     height: "100vh",
  //     maxWidth: "450px",
  //     maxHeight: "550px",
  //     data: {}
  //   };
  //
  //   const confirmdiaeref = this.dialog.open(CreateBeatPlanPopupComponent, config);
  // }
  // onCellClicked(event) {
  //   const column = event.column.getColId();
  //   const rows = this.gridApi.getSelectedRows();
  //   if (rows !== null && rows.length > 0 ) {
  //     localStorage.setItem('beat_plan_data', JSON.stringify(rows[0]));
  //     this.router.navigate([this.apiService.redirect_url + '/beats/beat_plan/details'], {
  //       state: {
  //         type: 'detail',
  //         beatplandata: rows[0]
  //       }
  //     });
  //   }
  // }
  //
  // openCreateDialog() {
  //   const config: MatDialogConfig = {
  //     width: "100vw",
  //     height: "100vh",
  //     maxWidth: "450px",
  //     maxHeight: "550px",
  //     data: {}
  //   };
  //
  //   const confirmdiaeref = this.dialog.open(CreateBeatPlanPopupComponent, config);
  // }
}
