import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2} from '@angular/core';
import {SharedService} from "../../_services/shared_service";
import {Router} from "@angular/router";
import {ApiService} from "../../_services/api.service";

@Component({
  selector: 'app-header-v2',
  templateUrl: './headerv2.component.html',
  styleUrls: ['./headerv2.component.scss']
})
export class HeaderV2Component implements OnInit {
  _title: string;
  public user_info;
  public user_image = "../../assets/images/person.svg";
  company_logo = 'assets/images/sd-logo.png';

  @Input('title')
  set title(value: string) {
    this._title = value;
  }


  @Output() profileExpansion: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() collapseExpansion: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private elRef: ElementRef,
              private renderer: Renderer2,
              private sharedService: SharedService,
              private changeDetector: ChangeDetectorRef,
              private router: Router,
              private apiService: ApiService) {

    this.user_info = JSON.parse(localStorage.getItem('user'));
    if (this.user_info.image_small != null) {
      this.user_image = this.user_info.image_small;
    }

    this.company_logo = localStorage.getItem('company_logo');
    if (this.company_logo == null) {
      this.company_logo = 'assets/images/sd-logo.png';
    }
    //var width = document.getElementById('sidebar').offsetWidth;
   /* setTimeout(function() {
      const element = document.getElementById("sidebar").offsetWidth;
      console.log(element);
      document.getElementById("header-content").style['paddingLeft'] = element + 30 + 'px';
    }, 100);*/
  }

  ngOnInit() {
    this._title = '';
    this.sharedService.headerTitle.subscribe((headerTitle: string) => {
      this._title = headerTitle;
      this.changeDetector.detectChanges();
    });
  }

  profileClick() {
    this.profileExpansion.emit(true);
  }
}

