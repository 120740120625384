import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {MatDialogConfig} from "@angular/material/dialog";
import {ImagePopupComponent} from "../../components/image_popup/image_popup.component";
import {LocationPopupComponent} from "../../components/location_popup/location_popup.component";
import {TaskActivationPopupComponent} from "../../components/task_activation_popup/task_activation_popup.component";
import {PartnerMeetPopupComponent} from "../../components/partner_meet_popup/partner_meet_popup.component";

@Component({
  selector: 'app-outlet-last-visit-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class PartnerMeetGalleryReportComponent extends ReportBase implements IReport, OnInit {

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Partner Meet Gallery Report');
    this.showableFilters.salesman = true;
    this.showableFilters.territory = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;

    this.showViewSelector = true;
    this.showGalleryview = true;
    this.container = 'meet_gallery';
    this.galleryData = {
      pageSize: 50,
      id: 'id',
      image: 'store_fname',
      name: 'name',
      field1: 'user',
      field2: 'att_partner',
      field3: 'type_name',
      status: 'status',
      statusTypes: [
        {name: "started", type: "new"},
        {name: "scheduled", type: "pending"},
        {name: "completed", type: "success"},
        {name: "cancelled", type: "error"}
      ],
      reviewed: 'status',
      reviewTypes: {
        yes: 'approved',
        no: 'rejected'
      },
      container: this.container,
      selectionCriteria: [
        {
          field: 'status',
          matches: true,
          values: ['completed']
        },
        {
          field: 'reviewed',
          matches: true,
          values: ['new']
        }
      ]
    };


    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/partnerMeetGalleryReport';
    const inThis = this;
    this.columnDefs = [
      {
        headerName: '',
        children: [
          {headerName: 'REGION', field: 'region', width: 80},
          {headerName: 'STATE', field: 'state', width: 80},
          {headerName: 'CLUSTER', field: 'cluster', width: 80},
          {headerName: 'TERRITORY', field: 'territory', width: 80},
          {headerName: 'MEET ID', field: 'id', width: 80},
          {headerName: 'SUBJECT', field: 'name', width: 150},
          {headerName: 'PLANNED DATE', field: 'planned_date', width: 100},
          {
            headerName: 'START TIME', field: 'start_time', width: 100,
            valueGetter: params => {
              if (params.data) {
                if (params.data.start_time) {
                  return inThis.datePipe.transform((new Date(params.data.start_time + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
                } else {
                  return params.data.start_time;
                }
              } else {
                return '';
              }
            }
          },
          {
            headerName: 'END TIME', field: 'end_time', width: 100,
            valueGetter: params => {
              if (params.data) {
                if (params.data.end_time) {
                  return inThis.datePipe.transform((new Date(params.data.end_time + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
                } else {
                  return params.data.end_time;
                }
              } else {
                return '';
              }
            }
          },
          {
            headerName: 'TIME SPENT', field: 'time_spent', enableRowGroup: true, width: 80,
            valueGetter: params => {
              if (params.data) {
                if (params.data.end_time && params.data.start_time) {
                  const diff = new Date(params.data.end_time + ' UTC').getTime() - new Date(params.data.start_time + ' UTC').getTime();
                  //return (diff / (1000 * 60)).toFixed(2);
                  let msec = diff;
                  const hh = Math.floor(msec / 1000 / 60 / 60);
                  msec -= hh * 1000 * 60 * 60;
                  const mm = Math.floor(msec / 1000 / 60);
                  msec -= mm * 1000 * 60;
                  const ss = Math.floor(msec / 1000);
                  msec -= ss * 1000;

                  return ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2);
                } else {
                  return 0;
                }
              } else {
                return '';
              }
            }
          },
          {headerName: 'TYPE', field: 'type', width: 80},
          {headerName: 'SCODE', field: 'emp_code', width: 80},
          {headerName: 'SALESMAN', field: 'user', width: 80},
          {headerName: 'ROLE', field: 'user_role', width: 50},
          {headerName: 'TEAM', field: 'team', width: 80},
          {
            headerName: 'LOCATION', field: 'location', width: 80,
            valueGetter(params) {
              if (params.data) {
                if (params.data.lat != null && params.data.lat !== "0.0") {
                  return 'http://maps.google.com/maps?q=' + params.data.lat + ',' + params.data.lng;
                }
              }
            },
            cellRenderer: params => {
              if (params.data) {
                if (params.data.lat != null && params.data.lat !== "0.0") {
                  return '<div style="text-align: center;"> ' +
                    '<span>' +
                    ' <i class="fas fa-map-marker-check fa-2x" style="color:#379862;' +
                    'display: inline-block;\n' +
                    '  vertical-align: middle;padding-bottom: 4px;cursor:pointer;"></i>' +
                    '</span>' +
                    '</div>';
                } else {
                  return '<div style="text-align: center;"> ' +
                    '<span>' +
                    ' <i class="fas fa-map-marker-slash fa-2x" style="color:#D82128;' +
                    'display: inline-block;\n' +
                    '  vertical-align: middle;padding-bottom: 4px;cursor:pointer;"></i>' +
                    '</span>' +
                    '</div>';
                }
              }
            }
          },
          {headerName: 'ADDRESS', field: 'address', width: 150, valueGetter: this.addressValueGetter()},
          {headerName: 'DESCRIPTION', field: 'description', width: 100},
          {headerName: 'STATUS', field: 'status', width: 80},
          {
            headerName: 'IMAGE', field: 'store_fname', width: 80,
            valueGetter: this.generateImageUrl('store_fname'),
            cellRenderer(params) {
              if (params.data) {
                if (params.data.store_fname === null || params.data.store_fname === "") {
                  return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
                } else {
                  return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
                }
              }
            }
          },
          {headerName: 'ATT COUNT', field: 'att_count', width: 80},
          {headerName: 'TOTAL ATT', field: 'total_att_count', width: 80}
        ]
      }
    ];

  }

  onCellClicked(event) {

    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    console.log(column);
    console.log(rows);
    if (column === 'location' &&  rows[0].hasOwnProperty('lat') && rows[0].lat != null && rows[0].lat !== '') {
      if (screen.width > 991) {
        const config: MatDialogConfig = {
          width: '100vw',
          height: '100vh',
          maxWidth: '70%',
          maxHeight: '95%',
          data: {
            latitude: parseFloat(rows[0].lat),
            longitude: parseFloat(rows[0].lng),
            title1: 'Partner Meet',
            title2: ''
          }
        };

        const creatediaeref = this.dialog.open(LocationPopupComponent, config);
      } else {
        const config: MatDialogConfig = {
          width: '100vw',
          height: '100vh',
          maxWidth: '90%',
          maxHeight: '65%',
          data: {
            latitude: parseFloat(rows[0].lat),
            longitude: parseFloat(rows[0].lng),
            title1: 'Partner Meet',
            title2: ''
          }
        };

        const creatediaeref = this.dialog.open(LocationPopupComponent, config);
      }
    } else if (column === 'store_fname') {
      console.log(rows[0].store_fname);
      //rows[0].store_fname = '964/9640c96d933643095139096372fda9ed189a8ff360c5bca0b4bc9f7d0ba661c1'

      if (rows !== null && rows.length > 0 && rows[0].hasOwnProperty('store_fname') && rows[0].store_fname !== null && rows[0].store_fname !== "") {
        if (screen.width > 991) {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "70%",
            maxHeight: "95%",
            data: {
              store_fname: rows[0].store_fname,
              container: this.container
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        } else {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "90%",
            maxHeight: "65%",
            data: {
              store_fname: rows[0].store_fname,
              container: this.container
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        }

      } else {

      }
    }
  }

  addressValueGetter() {
    return (params) => {
      if (params.data && params.data.config_json) {
        try {
          const config_json = JSON.parse(params.data.config_json);
          if (config_json && config_json.hasOwnProperty('address')) {
            return config_json['address'];
          }
        } catch (e) {
        }
      } else {
        return '';
      }
    };
  }

  giftValueGetter(field: string) {
    return (params) => {
      if (params.data && params.data.config_json) {
        try {
          const config_json = JSON.parse(params.data.config_json);
          if (config_json && config_json.hasOwnProperty('gift_json')) {
            return config_json['gift_json'][0][field];
          }
        } catch (e) {
        }
      } else {
        return '';
      }
    };
  }

  expenseValueGetter(field: string) {
    return (params) => {
      if (params.data && params.data.expense_json) {
        try {
          const expense_json = JSON.parse(params.data.expense_json);
          return expense_json[0][field];
        } catch (e) {
        }
      } else {
        return '';
      }
    };
  }

  showDetailPopup() {
    const row = this.rowData.find(x => x.id === this.selectedGalleryItem.id);
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "33%",
        maxHeight: "94%",
        data: {
          taskDetails: row,
          photo:  this.selectedGalleryItem.photo
        },
        panelClass: 'custom-dialog-container'
      };
      const creatediaeref = this.dialog.open(PartnerMeetPopupComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "95%",
        maxHeight: "90%",
        data: {
          taskDetails: row,
          photo:  this.selectedGalleryItem.photo
        },
        panelClass: 'custom-dialog-container'
      };
      const creatediaeref = this.dialog.open(PartnerMeetPopupComponent, config);
    }
  }


}
