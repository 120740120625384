import {Component, OnInit} from "@angular/core";
import {ApiService} from "../_services/api.service";
import {Router} from "@angular/router";
import {SharedService} from "../_services/shared_service";

@Component({
  selector: 'app-all-menu',
  templateUrl: './all_menus.component.html',
  styleUrls: ['./all_menus.component.scss']
})
export class AllMenuComponent implements OnInit {
  menuList;
  showAll = false;

  constructor(private apiService: ApiService,
              private router: Router,
              private sharedService: SharedService
  ) {
    this.sharedService.prepareMenuList();
    this.menuList = this.sharedService.menuList;
  }

  ngOnInit() {
    this.sharedService.setHeader('Menu Detail');

  }

  showDetails() {

  }

  getIcon(img) {
    if (img) {
      return '/assets/menu/' + img + '_sel.svg';
    } else {
      return '/assets/menu/sales_sel.svg';
    }
  }

  getsubMenuIcon(img) {
    if (img) {
      return '/assets/menu/' + img + '.svg';
    } else {
      return '/assets/menu/sales.svg';
    }
  }

  goToRoute(menuItem) {
    if (menuItem.route) {
      this.router.navigate([this.apiService.redirect_url + menuItem.route]);
    }
  }

  menuClick(menuItem) {
    if (menuItem.sub_menu && menuItem.sub_menu.length > 0) {
      menuItem.show = !menuItem.show;
    } else if (menuItem.route) {
      this.goToRoute(menuItem);
    }
  }
}
