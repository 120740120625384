<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>BATCH CONFIGURE</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <div>
        <ag-grid-angular *ngIf="dataLoaded" id="myGridpo" style="height: 60vh;" class="ag-theme-balham"
                         [columnDefs]="columnDefs" [gridOptions]="gridOptions" (cellValueChanged)="onCellValueChanged($event)"
                         (gridReady)="onGridReady($event)"
                         [getRowId]="getRowId"
                         [context]="context"
                         (cellEditingStarted)="onCellEditingStarted($event)"
                         (cellEditingStopped)="onCellEditingStopped($event)">
        </ag-grid-angular>
      </div>
    </div>
    <span *ngIf="!showSave" style="margin-left: 15px;color: #0762AD; margin-top: 15px;"> Editing in Progress ...</span>
    <div class="button-row" style="float: right; margin: 15px 30px 15px 0px;">
      <button
        mat-button
        style=" margin-right:15px;"
        class="btn grey-btn"
        (click)="closeDialog()"
      >
        Cancel
      </button>
      <button
        mat-button
        class="btn confirm-blue-btn"
        (click)="confirm()"
        [disabled]="!showSave"
      >
        Confirm
      </button>
    </div>
  </div>
</section>
