import {Component, ElementRef, EventEmitter, OnInit, Output} from "@angular/core";
import {ErrorObject} from "../../../error/error-model";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {ErrorHandler} from "../../../error/error-handler";
import {ApiService} from "../../../_services/api.service";
import {SharedService} from "../../../_services/shared_service";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-dms-customer-selection',
  templateUrl: './dms_customer_selection.html'
})
export class DMSCustomerSelectionPopupComponent implements OnInit {
  @Output() selectedOutlet: EventEmitter<any> = new EventEmitter<any>();

  customerList;
  searchValue;
  loaded = false;
  public userContext = {
    access_token: null,
  };
  preloader: any;
  emptyState: any;
  errorObject: ErrorObject;
  errorExists = false;
  dataLoaded: any;
  gridOptions: GridOptions;
  styleGrid: any;
  columnDefs = [];
  frameworkComponents: any = {};
  context: any;
  rowData = [];
  public gridColumnApi: any;
  public gridApi: any;
  public pinnedParams = [];
  public math = Math;

  constructor(protected apiService: ApiService,
              public sharedService: SharedService,
              public elRef: ElementRef,
              public dialog: MatDialog) {
    this.gridOptions = {
      rowSelection: 'single',
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      defaultColDef: {
        headerClass: 'myagheader',
        filter: false,
        sortable: false,
        resizable: false,
        enableRowGroup: false,
        menuTabs: []
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
    } as GridOptions;
  }

  loadCustomerList() {

    this.columnDefs = [
      { headerName: 'CUSTOMER', field: 'name' , sortable: true, filter : true, resizable: true, lockPosition: true,
        checkboxSelection: false},
      { headerName: 'CODE', field : 'code', sortable: true, filter : true, resizable: true, lockPosition: true},
      { headerName: 'BEAT', field: 'beat', sortable: true, filter : true, resizable: true, lockPosition: true,
        valueGetter(params) {
          if (params.data.beat) {
            const beats = [...new Set(params.data.beat.map(item => item.bp))];
            return beats.join();
          } else {
            return params.data.beat;
          }
        }},
      { headerName: 'id', field: 'id', hide: true},
      { headerName: 'beat_id', field: 'beat_id', hide: true,
        valueGetter(params) {
          if (params.data.beat) {
            const beats = [...new Set(params.data.beat.map(item => item.bp_id))];
            return JSON.stringify(beats);
          } else {
            return params.data.beat;
          }
        }},
      { headerName: 'supplier', field: 'supplier', hide: true},
      { headerName: 'user_id', field: 'user_id', hide: true},
      { headerName: 'user', field: 'user', hide: true},
      { headerName: 'email', hide: true},
      { headerName: 'gst_id', hide: true},
      { headerName: 'tax_source', hide: true},
      { headerName: 'pricelist_id', hide: true},
      { headerName: 'pricelist', hide: true},
      { headerName: 'label', hide: true},
      { headerName: 'cust_supp', hide: true}
    ];

    this.customerList =  JSON.parse(localStorage.getItem('customers_list'));

    this.dataLoaded = true;
    this.preloader = false;
    this.emptyState = false;

  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllColumns().length;

    params.api.setRowData(this.customerList);
    this.preloader = false;
    const inThis = this;
    // this.gridApi.setDomLayout('autoHeight');
    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        if (noOfColumns < 11 && screen.width > 991) {
          params.api.sizeColumnsToFit();
          if (inThis.pinnedParams.length > 0) {
            inThis.sharedService.resetPinned(inThis.gridOptions, inThis.pinnedParams);
          }
        } else {
          params.columnApi.autoSizeAllColumns();
          inThis.sharedService.clearPinned(inThis.gridOptions);
        }
      });
    });

    this.gridApi.sizeColumnsToFit();
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }


  closeDialog() {
    this.dialog.closeAll();
  }

  quickSearch() {
    this.gridApi.setQuickFilter(document.getElementById('filter-text-box')['value']);
  }

  ngOnInit(): void {
    this.loadCustomerList();
  }

  selectCustomer() {
    const selectedRows = this.gridApi.getSelectedRows();
    this.selectedOutlet.emit(selectedRows[0]);
  }

  public handleError(error: ErrorObject) {
    this.preloader = false;
    this.emptyState = false;
    this.dataLoaded = false;
    this.errorExists = true;
    this.errorObject = error;
  }

}
