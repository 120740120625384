import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class VanStockReconciliationReportComponent extends ReportBase implements IReport, OnInit {

  /* showCustomFilter = true;
   customFilter: any = {
     name: 'DC',
     key: 'id',
     value: '',
     values: [],
     show: true
   };*/
  data = [];
  public all_dc_flag = false;
  public all_dc = [];
  public default = true;
  public zip_loader = false;
  public zip_ready = false;
  public zipCaption = ["", 0];
  public selectedZipDc = "0";
  public zip_url = "";
  public role_features = [];
  public zipDate = [];

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Van Stock Reconciliation Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.state = true;
    this.showableFilters.region = true;
    /*  this.customFilter.values = [
        {id: '', itemName: 'All'},
        {id: 'true', itemName: 'Active'},
        {id: 'false', itemName: 'Inactive'}
      ];*/
    /*  this.userData = {
        "date": null,
        "access_token": null,
        "url": null,
        "last_date": null,
        "offset": null,
        "dc_id": [],
        "flag": true,
        "zip_flag": 'normal'
      };*/
    //this.customFilter.values = JSON.parse(localStorage.getItem('all_dcs'));

    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_connects/van_stock_reconciliation_report';
    const inThis = this;
    this.columnDefs = [

      {
        headerName: 'START DATE', field: 'start_date', enableRowGroup: true, width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.start_date) {
              return inThis.datePipe.transform((new Date(params.data.start_date + ' UTC')).toString(), 'yyyy-MM-dd');
            } else {
              return params.data.start_date;
            }
          } else {
            return '';
          }
        }
      },
      {
        headerName: 'END DATE', field: 'end_date', enableRowGroup: true, width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.end_date) {
              return inThis.datePipe.transform((new Date(params.data.end_date + ' UTC')).toString(), 'yyyy-MM-dd');
            } else {
              return params.data.end_date;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: 'STATE', field: 'dc_state', enableRowGroup: true, width: 80},
      {headerName: 'REGION', field: 'region', width: 100, enableRowGroup: true},
      {headerName: 'DC', field: 'dc', width: 100, enableRowGroup: true},
      {headerName: 'Van', field: 'user', menuTabs: [], width: 80},
      {headerName: 'Product Code', field: 'product_code', enableRowGroup: true, width: 150},
      {headerName: 'Product', field: 'product', menuTabs: [], width: 80},
      {headerName: 'Balance', field: 'loadin', menuTabs: [], width: 80},
      {headerName: 'Loadout', field: 'loadout', menuTabs: [], width: 80},
      {headerName: 'Opening', field: 'opening', menuTabs: [], width: 80},
      {headerName: 'Sales', field: 'sales', menuTabs: [], width: 80},
      {headerName: 'Damage', field: 'damage', menuTabs: [], width: 80},
      {headerName: 'Sample', field: 'sample', menuTabs: [], width: 80},
      {headerName: 'Promo', field: 'promo', menuTabs: [], width: 80},
      {headerName: 'Return', field: 'return', menuTabs: [], width: 80},
      {headerName: 'Closing', field: 'closing', menuTabs: [], width: 80},
      /* {headerName: 'Add Return', field: 'closing', menuTabs: [], width: 80},*/
      {headerName: 'Cancelled', field: 'cancelled', menuTabs: [], width: 80},
      {headerName: 'Deviation', field: 'deviation', menuTabs: [], width: 80},

    ];

  }

  /*loadServerData(): void {
    this.goClicked = true;
    this.dataLoaded = false;
    this.preloader = true;
    this.emptyState = false;
    this.columnDefs = [];
    let rowdata = [];
    let loop_data = [];
    let total = 0;
    const datePipe = this.datePipe;

    let paramobject =  {
      "date": this.userData.start_date,
      "access_token": this.userData.access_token,
      "url": this.userData.url,
      "last_date": this.userData.end_date,
      "offset": this.userData.offset,
      "dc_id": '',
      "flag": true,
      "zip_flag": 'normal'
    };


    this.apiService.post(this.API_URL, this.userData).subscribe(res => {
        console.log(res);
        if (!paramobject.flag) {
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            res['results'].data.forEach(function(data) {
              total = parseInt(data.opening, 10) + parseInt(data.sales, 10) + parseInt(data.closing, 10);
              if (total !== 0) {
                var pushData = {
                  "Date": datePipe.transform((new Date(data.stock_date + ' UTC')).toString(), 'dd/MM/yy'),
                  "State": data.dc_state,
                  "Region": data.region,
                  "DC": data.dc,
                  "Van": data.user,
                  "Product Code": data.product_code,
                  "Product": data.product,
                  "Balance": data.loadin,
                  "Loadout": parseInt(data.loadout, 10),
                  "Opening": parseInt(data.opening, 10),
                  "Sales": parseFloat(data.sales),
                  "Damage": parseInt(data.damage, 10),
                  "Sample": parseInt(data.sample, 10),
                  "Promo": parseInt(data.promo, 10),
                  "Return": parseInt(data.return, 10),
                  "Closing": parseInt(data.closing, 10),
                  "Cancelled": parseInt(data.cancelled, 10),
                  "Deviation": parseInt(data.deviation, 10)
                };
                rowdata.push(pushData);
              }

            });
            this.data = rowdata[0];
            this.rowData = rowdata;

            for (var key in this.data) {
              if (this.data.hasOwnProperty(key)) {
                if (key == 'Date' || key == 'User ID' || key == 'Product ID') {
                  this.columnDefs.push({
                    headerName: key,
                    field: key,
                    sortable: true,
                    filter: true,
                    lockPosition: true,
                    width: 100,
                    resizable: true,
                    enableRowGroup: true,
                    enablePivot: true,
                    aggFunc: 'sum',
                    enableValue: true
                  });
                } else if (key == 'Van') {
                  this.columnDefs.push({
                    headerName: key,
                    field: key,
                    sortable: true,
                    filter: true,
                    lockPosition: true,
                    width: 220,
                    resizable: true,
                    enableRowGroup: true,
                    enablePivot: true,
                    aggFunc: 'sum',
                    enableValue: true
                  });
                } else if (key == 'Product') {
                  this.columnDefs.push({
                    headerName: key,
                    field: key,
                    sortable: true,
                    filter: true,
                    lockPosition: true,
                    width: 220,
                    resizable: true,
                    enableRowGroup: true,
                    enablePivot: true,
                    aggFunc: 'sum',
                    enableValue: true
                  });
                } else if (key == 'Product Code' || key == 'Opening' || key == 'Sales' || key == 'Damage' || key == 'Deviation' || key == 'Closing' ||
                  key == 'Sample' || key == 'Promo' || key == 'Return' || key == 'Cancelled') {
                  this.columnDefs.push({
                    headerName: key, field: key, hide: true, sortable: true, filter: true, lockPosition: true,
                    width: 90, resizable: true, aggFunc: 'sum', enablePivot: true, cellStyle: {textAlign: "right"},
                    enableValue: true
                  });
                } else if (key == 'Balance' || key == 'Loadout') {
                  this.columnDefs.push({
                    headerName: key, field: key, sortable: true, filter: true, lockPosition: true,
                    width: 90, resizable: true, aggFunc: 'sum', enablePivot: true, cellStyle: {textAlign: "right"},
                    enableValue: true
                  });
                } else {
                  this.columnDefs.push({
                    headerName: key, field: key, sortable: true, filter: true, lockPosition: true,
                    width: 90, resizable: true, aggFunc: 'sum', enablePivot: true, enableRowGroup: true,
                    enableValue: true
                  });
                }
              }
            }
            this.dataLoaded = true;

          } else {
            this.preloader = false;
            this.emptyState = true;
          }
        } else {
         /!* if (this.all_dc_flag) {
            this.all_dc = [{"id": 0, "itemName": "All DC", "checked": false}];
            Array.prototype.push.apply(this.all_dc, res['results'].dcs);
          } else {
            this.all_dc = res['results'].dcs;
          }*!/
          //this.dcList();
          this.preloader = false;
          this.emptyState = true;
        }

      },
      error => {
        console.log(error);
        this.preloader = false;
      });
  }*/

  /*dcList() {
    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ReportDropdownComponent);

    const componentRef = viewContainerRef.createComponent<ReportDropdownComponent>(componentFactory);
    componentRef.instance.itemList = this.all_dc;
    if (this.default || this.userData.dc_id.length == 0) {
      componentRef.instance.selectedItems = [this.all_dc[this.all_dc.length - 1]];
    } else {
      componentRef.instance.selectedItems = JSON.parse(localStorage.getItem('selected_dc'));
    }

    this.userData.dc_id = [componentRef.instance.selectedItems[0].id];

    componentRef.instance.statusChange.subscribe(res => {

      this.default = false;
      let dc_ids = [];
      res.forEach(function(each_dc) {
        dc_ids.push(each_dc.id);
      });
      this.user_data.dc_id = dc_ids;

    });
  }
*/
}
