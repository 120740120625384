import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-volume-service-spare-parts-request',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class ServiceSparePartsRequestComponent extends ReportBase implements IReport, OnInit {

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Service Spare Parts Request');

    this.showableFilters.salesman = false;
    this.showableFilters.team = false;
    this.showableFilters.state = false;
    this.showableFilters.region = false;
    this.showDate = true;
    this.configureGrid();
    this.API_URL = '/api/pwa_v2_reports/serviceRequestSparePartsReport';
  }

  configureGrid() {

    this.columnDefs = [
      {
        headerName: "SERVICE",
        children: [
          {headerName: 'TICKET', field: 'name', width: 80, pinned: 'left'},
          {headerName: 'CUSTOMER ID', field: 'customer_id', width: 100, pinned: 'left', hide: true},
          {headerName: 'CUSTOMER', field: 'customer_name', width: 100, pinned: 'left'},
          {headerName: 'CUSTOMER MOBILE', field: 'customer_mobile', width: 100, pinned: 'left', hide: true},
          {headerName: 'STATUS', field: 'status', width: 80, pinned: 'left'},
          {headerName: 'REQ DATE', field: 'req_date', width: 80, pinned: 'left'},
          {headerName: 'SERVICE ENGINEER', field: 'service_engineer', width: 80, pinned: 'left'},
          {headerName: 'EMP CODE', field: 'emp_code', width: 80, pinned: 'left'}
        ]
      },
      {
        headerName: "SPARES",
        children: [
          {headerName: 'ID', field: 'product_id', width: 60},
          {headerName: 'NAME', field: 'product_name', width: 120},
          {headerName: 'CODE', field: 'default_code', width: 80},
          {headerName: 'CATEGORY', field: 'category', width: 120},
          {headerName: 'BUSINESS GROUP', field: 'business_group', width: 100},
          {headerName: 'SPARE STATUS', field: 'd_status', width: 80},
          {headerName: 'REQ QTY', field: 'qty', width: 80},
          {headerName: 'APPROVED QTY', field: 'app_qty', width: 80},
          {headerName: 'DELIVERED QTY', field: 'del_qty', width: 80},
        ]
      },
      {
        headerName: "APPROVAL",
        children: [
          {headerName: 'USER', field: 'app_by', width: 80},
          {headerName: 'DATE', field: 'sp_app_on', width: 80},
          {headerName: 'COMMENTS', field: 'sp_app_comments', width: 80},
        ]
      },
      {
        headerName: "DELIVERED",
        children: [
          {headerName: 'USER', field: 'del_by', width: 80},
          {headerName: 'DATE', field: 'sp_del_on', width: 80},
          {headerName: 'COMMENTS', field: 'sp_del_comments', width: 80},
        ]
      }
    ];
  }

}
