import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";

@Component({
  selector: 'color-cell',
  template: `<div class="dc-top" layout="row">` +
  `<span class="total-data">{{params.value.total}}</span>` +
  `</div><div class="dc-bottom" layout="row"><span class="value-data" [ngClass]="{'high': params.value.value[0] == '+', 'low': params.value.value[0] == '-'}">{{params.value.value}}</span></div>`,
})
export class ColorCellRenderer implements ICellRendererAngularComp {
  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
