import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-partner-alert-master-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class PartnerAlertMasterComponent extends ReportBase implements IReport, OnInit {

  public attributeTypes = [];
  showCustomFilter = true;
  customFilter: any = {
    name: 'Active',
    key: 'active',
    value: 'true',
    values: [],
    show: true
  };
  apiData: any = [];
  uqTypes;

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Partner Alerts Master Report');

    this.sharedService.statusConfig.success = 'Completed';
    this.sharedService.statusConfig.error = 'Not Visited';
    this.sharedService.statusConfig.warning = 'Incomplete';

    // this.showableFilters.cluster = true;
    this.showableFilters.territory = true;
    this.showableFilters.salesman = true;
    // this.showableFilters.class = true;
    // this.showableFilters.outlet_type = true;
    this.showDate = true;
    this.customFilter.values = [
      {id: true, itemName: 'Active'},
      {id: false, itemName: 'Inactive'}
    ];

    this.loadInBatches(10000, 'id');
    this.showProgressCustomText = true;
    this.API_URL = '/api/pwa_v2_reports/getPartnerAlertsReport';
  }

  configureGrid() {
    const inThis = this;
    this.columnDefs = [
      {
        headerName: "Alert",
        children: [
          {headerName: 'ALERT ID', field: 'a_id', width: 80},
          {headerName: 'ALERT NAME', field: 'name', enableRowGroup: true, width: 80},
          {headerName: 'CODE', field: 'code', width: 80},
          {headerName: 'LINE', field: 'line_name', enableRowGroup: true, width: 80},
          {headerName: 'LINE ID', field: 'line_id', enableRowGroup: true, width: 80},
          {headerName: 'ALERT TYPE', field: 'type_name', enableRowGroup: true, width: 100},
          {headerName: 'START DATE', field: 'start_date', width: 100},
          {headerName: 'END DATE', field: 'end_date', width: 100},
          {headerName: 'DESCRIPTION', field: 'description', width: 80},
          {headerName: 'STATUS', field: 'line_status', width: 80}
        ]
      }, {
        headerName: "Partner Details",
        children: [
          {headerName: 'PARTNER NAME', field: 'partner_name', enableRowGroup: true, width: 80},
          {headerName: 'CODE', field: 'partner_code', width: 80},
          {headerName: 'PARTNER TYPE', field: 'partner_type', width: 80},
          {headerName: 'OUTLET TYPE', field: 'outlet_type', width: 80},
          {headerName: 'PROGRAM TYPE', field: 'program_type', width: 80},
          {headerName: 'CLASS', field: 'partner_class', width: 80}
        ]
      }, {
        headerName: "Territory Details",
        children: [
          {headerName: 'TERRITORY NAME', field: 'rt_name', enableRowGroup: true, width: 80},
          {headerName: 'MANAGER', field: 'tm_name', enableRowGroup: true, width: 80},
          {headerName: 'EMP CODE', field: 'tm_code', width: 80}
        ]
      }, {
        headerName: "Product Details",
        children: [
          {headerName: 'PRODUCT NAME', field: 'prod_name', enableRowGroup: true, width: 80},
          {headerName: 'CODE', field: 'prod_code', width: 80},
          {headerName: 'FAMILY', field: 'prod_family', width: 80},
          {headerName: 'CATEGORY', field: 'prod_cat', width: 80},
          {headerName: 'BRAND', field: 'prod_brand', width: 80}
        ]
      }, {
        headerName: "Last Visit",
        children: [
          {
            headerName: 'STATUS', field: 'state', enableRowGroup: true, width: 80,
            valueGetter(params) {
              if (params.data.state) {
                return 'Completed';
              } else if (params.data.state) {
                return 'Incomplete';
              } else {
                return 'Not Visited';
              }
            }, cellRenderer: this.sharedService.statusCellRenderer
          },
          {headerName: 'PURPOSE', field: 'purpose', width: 80},
          {
            headerName: 'VISIT START', field: 'visit_start', width: 80,
            valueGetter: params => {
              if (params.data.visit_start) {
                return inThis.datePipe.transform((new Date(params.data.visit_start + ' UTC')).toString(), 'yyyy-MM-dd HH:mm');
              } else {
                return 'NA';
              }
            }
          },
          {
            headerName: 'VISIT END', field: 'visit_end', width: 80,
            valueGetter: params => {
              if (params.data.visit_end) {
                return inThis.datePipe.transform((new Date(params.data.visit_end + ' UTC')).toString(), 'yyyy-MM-dd HH:mm');
              } else {
                return 'NA';
              }
            }
          },
          {
            headerName: 'DURATION', field: 'visit_duration', width: 80,
            valueGetter(params) {
              if (params.data.visit_duration) {
                return (params.data.visit_duration / 60).toFixed(1) + " mins";
              } else {
                return 'NA';
              }
            }
          },
          {headerName: 'START COMMENT', field: 'start_comment', width: 80},
          {headerName: 'END COMMENT', field: 'end_comment', width: 80},
          {
            headerName: 'VISIT TYPE', field: 'is_virtual', enableRowGroup: true, width: 80,
            valueGetter(params) {
              if (params.data.is_virtual) {
                if (params.data.virtual_verified && params.data.virtual_verified === 'Verified') {
                  return 'Virtual Call';
                } else {
                  return 'Unverified Call';
                }
              } else {
                return 'Physical Visit';
              }
            }
          },
          {
            headerName: 'UNSCHEDULED', field: 'uv', width: 80,
            valueGetter(params) {
              if (params.data.uv) {
                return 'Scheduled';
              } else {
                return 'Unscheduled';
              }
            }
          },
          {
            headerName: "DEVIATION", field: "visit_deviation", hide: false, width: 100,
            valueGetter(params) {
              if (!params.data.visit_deviation || params.data.visit_deviation === -1) {
                return 'NA';
              } else {
                return params.data.visit_deviation;
              }
            },
            cellRenderer(params) {
              if (params.data) {
                let displayElem = '';
                if (params._self.checkInDistance == null && params.value > 300) {
                  displayElem = '<div style=" color:#E24260;  text-align: center; padding: 0px;">' + params.value + 'm</div>';
                } else if (params._self.checkInDistance && params._self.checkInDistance !== null && params.value !== null && params._self.checkInDistance > 0 && params.value > params._self.checkInDistance) {
                  //if (params.value > 300) {
                  displayElem = '<div style=" color:#E89221;  text-align: center;">' + params.value + 'm</div>';
                  // return {textAlign: 'center', backgroundColor: '#F8D1C8'};
                } else if (params.value === 'NA') {
                  displayElem = `<div style="text-align: center;">
                                  <span><img src="../../../assets/cross.svg"></span>
                                </div>`;
                  //return {textAlign: 'center'};
                } else {
                  displayElem = '<div style=" color:#379862;  text-align: center;">' + params.value + 'm</div>';
                  //return {textAlign: 'center'};
                }
                return displayElem;
              }
            },
            cellRendererParams: {
              _self: this
            }
          },
        ]
      }
    ];
  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }


  configureData() {
    this.rowData = this.apiData;
    console.log(this.rowData);
  }
}
