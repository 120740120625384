<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>{{header}}</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <span style="color: #2D2D2D; font-size: 14px;">Total Records</span><span style="margin-left: 5px;color: #0762AD; font-weight: bold;font-size: 14px;">{{totalRecords}}</span>
      <span style="margin-left: 20px; color: #2D2D2D;font-size: 14px;">Valid Records</span><span style="margin-left: 5px;color: #0762AD; font-weight: bold;font-size: 14px;">{{validRecords}}</span>
      <span style="margin-left: 20px; color: #2D2D2D;font-size: 14px;">Records with Error</span><span style="margin-left: 5px;color: #E24260; font-weight: bold;font-size: 14px;">{{errorRecords}}</span>
      <div class="row" style="display: block; margin-top: 10px;">
        <div class="col-md-12 pad0">
          <div>
            <ag-grid-angular id="myGrid2" *ngIf="dataLoaded" style="height: 60vh" class="ag-theme-alpine"
                             [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                             (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                             (cellClicked)="onCellClicked($event)"
                             [context]="context">
            </ag-grid-angular>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 pad0" style="margin-top:20px;padding:15px;height:50px;">
      <div class="button-row" style="position: absolute;  right: 0px;">
             <span class="modal-btn" data-dismiss="modal" style="margin-bottom: 15px;" data-toggle="modal">
            <a class="btn white-btn">Close</a>
          </span>
        <button
          mat-button
          style="margin-left:15px; margin-right:15px;"
          class="btn confirm-blue-btn"
          (click)="saveData()"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</section>
