import {Component, ElementRef, Renderer2} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";
import {SharedService} from "./_services/shared_service";
import {ApiService} from "./_services/api.service";

@Component({
  selector: 'price-editor',
  template: `<div class="po-top multi" layout="row">` +
    `<input *ngIf="editable" type="text" [(ngModel)]="searchValue" (keydown.enter)="updateCell()" (keyup)="editCell()" class="custom-inputbox multi"/>` +
    `<label *ngIf="!editable">{{searchValue}}</label>` +
    `</div>`
})
export class PriceCellRendererComonent implements ICellRendererAngularComp {
  public params: any;
  public searchValue;
  public editable = false;

  constructor(private elRef: ElementRef,
              private renderer: Renderer2,
              private apiService: ApiService,
              private sharedService: SharedService){}

  agInit(params: any): void {

    this.params = params;
    this.searchValue = params.data['PRICE'];
    if (this.sharedService.new) {
      this.editable = true;
    }
  }

  public editCell() {

    this.params.data['PRICE']  = this.searchValue;
  }

  updateCell() {
    if (this.searchValue != '') {
      this.params.data['TOTAL'] = (parseFloat(this.searchValue) * parseFloat(this.params.data['QTY'])).toFixed(this.apiService.decimalPolicy);
      this.params.context.componentParent.gridApi.setRowData(this.params.context.componentParent.rowData);
    }
  }

  refresh(): boolean {
    return false;
  }
}
