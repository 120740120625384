<script defer src="./node_modules/@fortawesome/fontawesome-pro"></script>

<div class="sidebar">
  <ul id="sidebarul" class="list-unstyled" style="padding-top: 12%;">
    <li>
      <a (click)="goToDashboard()">
        <!-- <img src="./../../../assets/dasboard_grey.svg" />-->
        <span><i class="fad fa-th-large fa-2x" style="color:#183153"></i></span>
        <label class="hide">Dashboard</label>
      </a>
    </li>
    <li *ngIf="showBeats">
      <a class="dropdown-toggle" data-toggle="dropdown" (click)="gotoDropdown('beats')">
        <!--  <img style="width:25px;" src="./../../../assets/customer.png" />-->
        <span><i class="fad fa-route fa-2x" style="color:#183153"></i></span>
        <!-- <span><i class="fas fa-shuttle-van fa-2x" style="color:#4F566B"></i></span>-->
        <label class="hide">Beats</label>
      </a>
    </li>
    <li id="beatplan" class="hide">
      <a style="margin-left: 20%;" (click)="goToBeatPlan('beatplan')">
        <span class="bracketspan">[</span><span class="textspan">BP</span><span class="bracketspan">]</span>
        <label class="hide">Beat Plan</label>
      </a>
    </li>
    <li id="beatretailer" class="hide">
      <a style="margin-left: 20%;" (click)="goToBeatRetailer()">
        <span class="bracketspan">[</span><span class="textspan">BR</span><span class="bracketspan">]</span>
        <label class="hide">Beat Retailer</label>
      </a>
    </li>
    <li id="territory" class="hide">
      <a style="margin-left: 20%;" (click)="goToTerritory()">
        <span class="bracketspan">[</span><span class="textspan">TR</span><span class="bracketspan">]</span>
        <label class="hide">Territory</label>
      </a>
    </li>
    <li id="beatschedule" class="hide">
      <a style="margin-left: 20%;" (click)="goToBeatSchedule()">
        <span class="bracketspan">[</span><span class="textspan">BS</span><span class="bracketspan">]</span>
        <label class="hide">Beat Schedule</label>
      </a>
    </li>
    <li *ngIf="showRetailers">
      <a class="dropdown-toggle" data-toggle="dropdown" (click)="gotoDropdown('customers')">
        <!--  <img style="width:25px;" src="./../../../assets/customer.png" />-->
        <span><i class="fad fa-users fa-2x" style="color:#183153"></i></span>
        <!-- <span><i class="fas fa-shuttle-van fa-2x" style="color:#4F566B"></i></span>-->
        <label class="hide">Customers</label>
      </a>
    </li>
    <li id="taggedRet" class="hide">
      <a style="margin-left: 20%;" (click)="goToRetailer()">
        <span class="bracketspan">[</span><span class="textspan">RET</span><span class="bracketspan">]</span>
        <label class="hide">Retailers</label>
      </a>
    </li>
    <li *ngIf="showInfluencers" id="taggedInfl" class="hide">
      <a style="margin-left: 20%;" (click)="goToInfluencer()">
        <span class="bracketspan">[</span><span class="textspan">INF</span><span class="bracketspan">]</span>
        <label class="hide">Influencers</label>
      </a>
    </li>
    <li *ngIf="showProjects" id="taggedProj" class="hide">
      <a style="margin-left: 20%;" (click)="goToProjects()">
        <span class="bracketspan">[</span><span class="textspan">PRJ</span><span class="bracketspan">]</span>
        <label class="hide">Projects</label>
      </a>
    </li>
    <li *ngIf="showLeads" id="taggedLeads" class="hide">
      <a style="margin-left: 20%;" (click)="goToLeads()">
        <span class="bracketspan">[</span><span class="textspan">SL</span><span class="bracketspan">]</span>
        <label class="hide">Sale Leads</label>
      </a>
    </li>
    <li *ngIf="showPartnerLeads" id="taggedPartnerLeads" class="hide">
      <a style="margin-left: 20%;" (click)="goToPartnerLeads()">
        <span class="bracketspan">[</span><span class="textspan">PL</span><span class="bracketspan">]</span>
        <label class="hide">Partner Leads</label>
      </a>
    </li>
    <li *ngIf="outletProductRecommendataion" id="prodReco" class="hide">
      <a style="margin-left: 20%;" (click)="goToOutletProductReco()">
        <span class="bracketspan">[</span><span class="textspan">PR</span><span class="bracketspan">]</span>
        <label class="hide">Recommendations</label>
      </a>
    </li>
    <li *ngIf="changeRequest" id="requestRet" class="hide">
      <a style="margin-left: 20%;" (click)="goToChangeReq()">
        <span class="bracketspan">[</span><span class="textspan">CR</span><span class="bracketspan">]</span>
        <label class="hide">Change Request</label>
      </a>
    </li>
    <li *ngIf="approveOutlet" id="newRet" class="hide">
      <a style="margin-left: 20%;" (click)="goToNewRetailer()">
        <span class="bracketspan">[</span><span class="textspan">NEW</span><span class="bracketspan">]</span>
        <label class="hide">New Outlets</label>
      </a>
    </li>
    <li *ngIf="showPartnerApprove" id="partnerAppr" class="hide">
      <a style="margin-left: 20%;" (click)="goToPartnerApprove()">
        <span class="bracketspan">[</span><span class="textspan">APPR</span><span class="bracketspan">]</span>
        <label class="hide">Approve partner</label>
      </a>
    </li>
    <li *ngIf="showRetailerApprove" id="retAppr" class="hide">
      <a style="margin-left: 20%;" (click)="goToRetailerApprove()">
        <span class="bracketspan">[</span><span class="textspan">APPR</span><span class="bracketspan">]</span>
        <label class="hide">Approve Retailer</label>
      </a>
    </li>
    <li *ngIf="showPlanogram" id="retPlg" class="hide">
      <a style="margin-left: 20%;" (click)="goToPlanogramList()">
        <span class="bracketspan">[</span><span class="textspan">PLG</span><span class="bracketspan">]</span>
        <label class="hide">Planogram</label>
      </a>
    </li>
   <!-- <li *ngIf="showRetailers">
      <a class="dropdown-toggle" data-toggle="dropdown" (click)="gotoDropdown('suppliers')">
        &lt;!&ndash;  <img style="width:25px;" src="./../../../assets/customer.png" />&ndash;&gt;
        <span><i class="fad fa-users fa-2x" style="color:#183153"></i></span>
        &lt;!&ndash; <span><i class="fas fa-shuttle-van fa-2x" style="color:#4F566B"></i></span>&ndash;&gt;
        <label class="hide">Suppliers</label>
      </a>
    </li>-->
   <!-- <li id="suppList" class="hide">
      <a style="margin-left: 20%;" (click)="goToSupplier()">
        <span class="bracketspan">[</span><span class="textspan">SUP</span><span class="bracketspan">]</span>
        <label class="hide">Suppliers</label>
      </a>
    </li>-->
    <li id="direct_primary_orders" *ngIf="show_direct_primary_orders">

      <a (click)="gotoPage('dms/direct_primary_orders')">
        <span><i class="fad fa-store fa-2x" style="color:#183153"></i></span>
        <label class="hide" (click)="gotoPage('dms/direct_primary_orders')">Orders</label>
      </a>
    </li>
    <li id="direct_primary_invoice" *ngIf="show_direct_primary_invoices">
      <a (click)="gotoPage('dms/direct_primary_invoices')">
        <span><i class="fad fa-file-invoice fa-3x" style="color:#183153"></i></span>
        <label class="hide" (click)="gotoPage('dms/direct_primary_invoices')">Invoices</label>
      </a>
    </li>
    <li *ngIf="showDistributorAudit">
      <a (click)="goToDistributorAudit()">
        <img src="./../../../assets/retail_audit.svg"/>
        <!-- <span><i class="fas fa-shuttle-van fa-2x" style="color:#4F566B"></i></span>-->
        <label class="hide">Distributor Audit</label>
      </a>
    </li>
    <li *ngIf="showRetailAudit">
      <a (click)="goToRetailAudit()">
        <img src="./../../../assets/retail_audit.svg"/>
        <!-- <span><i class="fas fa-shuttle-van fa-2x" style="color:#4F566B"></i></span>-->
        <label class="hide">Retail Audit</label>
      </a>
    </li>
    <li id="availability" class="hide">
      <a (click)="goToRetailAuditAvailability()" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">AVA</span><span class="bracketspan">]</span>
        <label class="hide">Availability</label>
      </a>
    </li>
    <li id="visibility" class="hide">
      <a (click)="goToRetailAuditVisibility()" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">VIS</span><span class="bracketspan">]</span>
        <label class="hide">Visibility</label>
      </a>
    </li>
    <li id="marketshare" class="hide">
      <a (click)="goToRetailAuditMarketShare()" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">MKT</span><span class="bracketspan">]</span>
        <label class="hide">Market Share</label>
      </a>
    </li>
    <li id="stocks" class="hide">
      <a (click)="goToRetailAuditStocks()" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">STK</span><span class="bracketspan">]</span>
        <label class="hide">Stocks</label>
      </a>
    </li>
    <!-- <li *ngIf="showInfluencers">
       <a class="dropdown-toggle" data-toggle="dropdown" (click)="gotoDropdown('influencers')">
         &lt;!&ndash;  <img style="width:25px;" src="./../../../assets/customer.png" />&ndash;&gt;
         <span><i class="fad fa-users fa-2x" style="color:#183153"></i></span>
         &lt;!&ndash; <span><i class="fas fa-shuttle-van fa-2x" style="color:#4F566B"></i></span>&ndash;&gt;
         <label class="hide">Influencers</label>
       </a>
     </li>
     <li id="taggedInfl" class="hide">
       <a style="margin-left: 20%;" (click)="goToInfluencer()">
         <span class="bracketspan">[</span><span class="textspan">CUS</span ><span class="bracketspan">]</span>
         <label class="hide">Customers</label>
       </a>
     </li>-->
    <li *ngIf="showRetailSurvey">
      <a class="dropdown-toggle" data-toggle="dropdown" (click)="gotoDropdown('retail_survey')">
        <!--<img style="width:20px;" src="./../../../assets/retail_survey.svg" />-->
        <span><i class="fas fa-drone fa-2x" style="color:#183153"></i></span>
        <!-- <span><i class="fas fa-shuttle-van fa-2x" style="color:#4F566B"></i></span>-->
        <label class="hide">Retail Survey</label>
      </a>
    </li>
    <li id="market" class="hide">
      <a (click)="goToRetailSurveyMarket('market')" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">MKT</span><span class="bracketspan">]</span>
        <label class="hide">Market</label>
      </a>
    </li>
    <li id="outletkyc" class="hide">
      <a (click)="goToRetailSurveyKYC()" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">KYC</span><span class="bracketspan">]</span>
        <label class="hide">Outlet KYC</label>
      </a>
    </li>
    <!-- <li id="consumer" class="hide">
       <a (click)="goToRetailSurveyMarket()" style="margin-left: 20%;">
         <span class="bracketspan">[</span><span class="textspan">CMR</span ><span class="bracketspan">]</span>
         <label class="hide">Consumer</label>
       </a>
     </li>-->
    <!-- <li id="scouting" class="hide">
       <a (click)="goToRetailSurveyMarket()" style="margin-left: 20%;">
         <span class="bracketspan">[</span><span class="textspan">GEN</span ><span class="bracketspan">]</span>
         <label class="hide">General</label>
       </a>
     </li>-->
    <!-- <li id="gifts" class="hide">
       <a (click)="goToRetailSurveyMarket()" style="margin-left: 20%;">
         <span class="bracketspan">[</span><span class="textspan">GFT</span ><span class="bracketspan">]</span>
         <label class="hide">Gifts</label>
       </a>
     </li>-->
    <li *ngIf="showTasks">
      <a class="dropdown-toggle" data-toggle="dropdown" (click)="gotoDropdown('task')">
        <!--<img style="width:20px;" src="./../../../assets/retail_survey.svg" />-->
        <span><i class="fas fa-tasks fa-2x" style="color:#183153"></i></span>
        <!-- <span><i class="fas fa-shuttle-van fa-2x" style="color:#4F566B"></i></span>-->
        <label class="hide">Tasks</label>
      </a>
    </li>
    <li id="task_master" class="hide">
      <a (click)="goToTasks()" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">TSK</span><span class="bracketspan">]</span>
        <label class="hide">Task</label>
      </a>
    </li>
    <li *ngIf="showTaskPlanner" id="task_planner" class="hide">
      <a (click)="goToTaskPlanner()" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">TP</span><span class="bracketspan">]</span>
        <label class="hide">Task Planner</label>
      </a>
    </li>
    <li id="task_survey" class="hide">
      <a (click)="goToTaskSurvey()" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">SUR</span><span class="bracketspan">]</span>
        <label class="hide">Survey</label>
      </a>
    </li>
    <li id="task_user" class="hide">
      <a (click)="goToTaskUsers()" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">UST</span><span class="bracketspan">]</span>
        <label class="hide">User Tasks</label>
      </a>
    </li>
    <li *ngIf="showRetailRequestAsset">
      <a (click)="goToRetailAssetRequest()">
        <img src="./../../../assets/assets.svg"/>
        <label class="hide">Retail Asset</label>
      </a>
    </li>
    <li *ngIf="showService">
      <a class="dropdown-toggle" data-toggle="dropdown" (click)="gotoDropdown('services')">
        <span><i class="fad fa-clipboard-list fa-2x"  style="color:#183153"></i></span>
        <!-- <span><i class="fas fa-shuttle-van fa-2x" style="color:#4F566B"></i></span>-->
        <label class="hide">Services</label>
      </a>
    </li>
    <li *ngIf="showAssetServiceTicket" id="installation" class="hide">
      <a (click)="goToAssetServiceTicket()" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">INST</span><span class="bracketspan">]</span>
        <label class="hide">Installation</label>
      </a>
    </li>
    <li *ngIf="showServiceRequest" id="servicerequest" class="hide">
      <a (click)="goToServiceRequest()" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">REQ</span><span class="bracketspan">]</span>
        <label class="hide">Service Request</label>
      </a>
    </li>
    <li *ngIf="showAsset">
      <a class="dropdown-toggle" data-toggle="dropdown" (click)="gotoDropdown('assets')">
        <img style="width:20px;" src="./../../../assets/assets.svg"/>
        <!-- <span><i class="fas fa-shuttle-van fa-2x" style="color:#4F566B"></i></span>-->
        <label class="hide">Assets</label>
      </a>
    </li>
    <li id="request" class="hide">
      <a (click)="goToAssetRequest()" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">REQ</span><span class="bracketspan">]</span>
        <label class="hide">Request</label>
      </a>
    </li>
    <li id="tagged" class="hide">
      <a (click)="goToAssetTagged()" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">DEP</span><span class="bracketspan">]</span>
        <label class="hide">Deployed</label>
      </a>
    </li>
    <!-- <li id="service" class="hide">
       <a (click)="goToAssetService()" style="margin-left: 20%;">
         <span class="bracketspan">[</span><span class="textspan">SVC</span ><span class="bracketspan">]</span>
         <label class="hide">Service</label>
       </a>
     </li>-->
    <li *ngIf="showUserSurvey">
      <a (click)="goToUserSurvey()">
        <!-- <img style="width:20px;" src="./../../../assets/distributor_survey.svg" />-->
        <span><i class="fas fa-drone-alt fa-2x" style="color:#183153"></i></span>
        <label class="hide">User Survey</label>
      </a>
    </li>
    <li *ngIf="showCustomers">
      <a (click)="goToCustomers()">
        <!--<img style="width:25px;" src="./../../../assets/customer.png" />-->
        <span><i class="fas fa-users fa-2x" style="color:#183153"></i></span>
        <label class="hide">Distributors</label>
      </a>
    </li>
    <li *ngIf="show_dcList">
      <a (click)="gotoDCList()">
        <img src="./../../../assets/tick.svg"/>
        <label class="hide">Settlement</label>
      </a>
    </li><li *ngIf="show_dc_transferList">
      <a (click)="gotoDCTransfer()">
        <img src="./../../../assets/van_transfer.svg"/>
        <label class="hide">Transfer</label>
      </a>
    </li>
    <li *ngIf="show_poList">
      <a (click)="goToPoList()">
        <!--  <span>
            <img src="./../../../assets/production_order_grey.svg" />
          </span>-->
        <span><i class="fas fa-industry-alt fa-2x" style="color:#183153"></i></span>
        <label class="hide">{{loadout_name}}</label>
      </a>
    </li>
    <li *ngIf="show_mt_module">
      <a (click)="gotoMTSales()">
        <img src="./../../../assets/MTO.svg"/>
        <label class="hide">Pre Orders</label>
      </a>
    </li>
    <li *ngIf="show_mt_module">
      <a (click)="gotoPO()">
        <img src="./../../../assets/purchase%20order.svg"/>
        <label class="hide">Purchase Order</label>
      </a>
    </li>
    <li class="dropdown" (click)="gotoDropdown('primary_po')" *ngIf="show_po_module">
      <a class="dropdown-toggle" data-toggle="dropdown" (click)="gotoDropdown('primary_po')">
        <!-- <img (click)="gotoDropdown('primary_po')" src="./../../../assets/primary%20purchase.svg" />-->
        <span (click)="gotoDropdown('primary_po')"><i class="fad fa-cart-arrow-down fa-2x"
                                                      style="color:#183153"></i></span>
        <label class="hide" (click)="gotoDropdown('primary_po')">Purchase</label>
      </a>
    </li>
    <li class="hide" id="po" *ngIf="show_po_module">
      <a (click)="gotoPO2()" style="margin-left: 20%;">
        <img src="./../../../assets/PO.svg"/>
        <label class="hide">PO</label>
      </a>
    </li>
    <li class="hide" id="receipt" *ngIf="show_po_module">
      <a (click)="gotoReceipts()" style="margin-left: 20%;">
        <img src="./../../../assets/receipt.svg"/>
        <label class="hide">Receipts</label>
      </a>
    </li>
    <li class="hide" id="return" *ngIf="show_po_module">
      <a (click)="gotoReturns()" style="margin-left: 20%;">
        <img src="./../../../assets/returns.svg"/>
        <label class="hide">Returns</label>
      </a>
    </li>
    <li *ngIf="show_credit_notes">
      <a (click)="gotoVansaleCreditNotes()">
        <span><i class="fad fa-file-alt fa-2x"></i></span>
        <label class="hide">Credit Notes</label>
      </a>
    </li>

<!--    DMS V2 BEGIN-->

    <li *ngIf="show_dmsv2_customers">
      <a class="dropdown-toggle" data-toggle="dropdown" (click)="hideShowDMSPartners()">
        <span><i class="fad fa-users fa-2x" style="color:#183153"></i></span>
        <label class="hide">Partners</label>
      </a>
    </li>
    <li class="hide" id="dmsv2_customers" *ngIf="show_dmsv2_customers">
      <a (click)="gotoDMS('customers', 'dmsv2_customers')" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">CUS</span><span class="bracketspan">]</span>
        <label class="hide">Customers</label>
      </a>
    </li>
    <li class="hide" id="dmsv2_suppliers" *ngIf="show_dmsv2_customers">
      <a (click)="gotoDMS('suppliers', 'dmsv2_suppliers')" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">SUP</span><span class="bracketspan">]</span>
        <label class="hide">Suppliers</label>
      </a>
    </li>
    <li *ngIf="changeRequest" id="dmsv2_change_request" class="hide">
<!--      <a (click)="gotoDMS('customers/request', 'dmsv2_change_request')" style="margin-left: 20%;">-->
      <a style="margin-left: 20%;" (click)="goToChangeReq()">
        <span class="bracketspan">[</span><span class="textspan">CR</span><span class="bracketspan">]</span>
        <label class="hide">Requests</label>
      </a>
    </li>

    <li *ngIf="show_dmsv2_module">
      <a class="dropdown-toggle" data-toggle="dropdown" (click)="hideShowDMSSecondary()">
        <span><i class="fas fa-store fa-2x" style="color:#183153"></i></span>
        <label class="hide">Sales</label>
      </a>
    </li>
    <li class="hide" id="dmsv2_orders" *ngIf="show_dmsv2_module">
      <a (click)="gotoDMS('sec_sale_orders', 'dmsv2_orders')" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">ORD</span><span class="bracketspan">]</span>
        <label class="hide">Orders</label>
      </a>
    </li>
    <li class="hide" id="dmsv2_tertiary" *ngIf="show_dmsv2_module">
      <a (click)="gotoDMS('ter_sale_orders', 'dmsv2_tertiary')" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">CSO</span><span class="bracketspan">]</span>
        <label class="hide">Counter Sales</label>
      </a>
    </li>
    <li class="hide" id="dmsv2_delivery_plan" *ngIf="show_dmsv2_module">
      <a (click)="gotoDMS('delivery_plans', 'dmsv2_delivery_plan')" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">DLP</span><span class="bracketspan">]</span>
        <label class="hide">Delivery Plan</label>
      </a>
    </li>
    <li class="hide" id="dmsv2_invoices" *ngIf="show_dmsv2_module">
      <a (click)="gotoDMS('sec_sale_invoices', 'dmsv2_invoices')" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">INV</span><span class="bracketspan">]</span>
        <label class="hide">Invoices</label>
      </a>
    </li>
    <li class="hide" id="dmsv2_sec_return_inv" *ngIf="show_dmsv2_module">
      <a (click)="gotoDMS('sec_return_invoices', 'dmsv2_sec_return_inv')" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">RIN</span><span class="bracketspan">]</span>
        <label class="hide">Returns</label>
      </a>
    </li>
    <li class="hide" id="dmsv2_sec_invoice_claims" *ngIf="show_dmsv2_module">
      <a (click)="gotoDMS('sec_schemes', 'dmsv2_sec_invoice_claims')" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">SCH</span><span class="bracketspan">]</span>
        <label class="hide">Scheme</label>
      </a>
    </li>


    <li *ngIf="show_module_vansales">
      <a class="dropdown-toggle" data-toggle="dropdown" (click)="hideShowVansale()">
        <span><i class="fas fa-truck fa-2x" style="color:#183153"></i></span>
        <label class="hide">Van Sales</label>
      </a>
    </li>
    <li class="hide" id="dmsv2_loadout" *ngIf="show_module_vansales">
      <a (click)="gotoVansales('load_outs', 'dmsv2_loadout')" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">LDT</span><span class="bracketspan">]</span>
        <label class="hide">Loadout</label>
      </a>
    </li>
    <li class="hide" id="dmsv2_settlement" *ngIf="show_module_vansales">
      <a (click)="gotoVansales('settlements', 'dmsv2_settlement')" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">EOD</span><span class="bracketspan">]</span>
        <label class="hide">Settlement</label>
      </a>
    </li>
    <li class="hide" id="dmsv2_transfer" *ngIf="show_module_vansales">
      <a (click)="gotoVansales('transfers', 'dmsv2_transfer')" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">STF</span><span class="bracketspan">]</span>
        <label class="hide">Transfers</label>
      </a>
    </li>

    <li *ngIf="show_module_payments">
      <a class="dropdown-toggle" data-toggle="dropdown" (click)="hideShowPayments()">
        <span><i class="fas fa-money-bill-wave fa-2x" style="color:#183153"></i></span>
        <label class="hide">Payments</label>
      </a>
    </li>
    <li class="hide" id="dmsv2_pay_receipts" *ngIf="show_module_payments">
      <a (click)="gotoV2Payments('pay_receipts', 'dmsv2_pay_receipts')" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">RCT</span><span class="bracketspan">]</span>
        <label class="hide">Pay Receipts</label>
      </a>
    </li>
    <li class="hide" id="dmsv2_credit_notes" *ngIf="show_module_payments">
      <a (click)="gotoV2Payments('credit_notes', 'dmsv2_credit_notes')" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">CRN</span><span class="bracketspan">]</span>
        <label class="hide">Credit Notes</label>
      </a>
    </li>
    <li class="hide" id="dmsv2_ledger" *ngIf="show_module_payments">
      <a (click)="gotoV2Payments('ledger', 'dmsv2_ledger')" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">LGR</span><span class="bracketspan">]</span>
        <label class="hide">Ledger</label>
      </a>
    </li>


    <li *ngIf="show_dmsv2_purchase">
      <a class="dropdown-toggle" data-toggle="dropdown" (click)="hideShowDMSPurchase()">
        <span><i class="fad fa-cart-arrow-down fa-2x"style="color:#183153"></i></span>
        <label class="hide">Purchase</label>
      </a>
    </li>
    <li class="hide" id="dmsv2_purchase_order" *ngIf="show_dmsv2_purchase">
      <a (click)="gotoDMS('purchase_orders', 'dmsv2_purchase_order')" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">PO</span><span class="bracketspan">]</span>
        <label class="hide">Order</label>
      </a>
    </li>
    <li class="hide" id="dmsv2_grn" *ngIf="show_dmsv2_purchase">
      <a (click)="gotoDMS('purchase_invoices', 'dmsv2_grn')" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">GRN</span><span class="bracketspan">]</span>
        <label class="hide">Goods Received</label>
      </a>
    </li>
    <li class="hide" id="dmsv2_p_schemes" *ngIf="show_dmsv2_purchase">
      <a (click)="gotoDMS('p_schemes', 'dmsv2_p_schemes')" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">SCH</span><span class="bracketspan">]</span>
        <label class="hide">Scheme</label>
      </a>
    </li>
    <li class="hide" id="dmsv2_p_stock" *ngIf="show_dmsv2_module">
      <a (click)="gotoDMS('my_stock', 'dmsv2_p_stock')" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">STK</span><span class="bracketspan">]</span>
        <label class="hide">My Stock</label>
      </a>
    </li>
    <li class="hide" id="dmsv2_batch_stock" *ngIf="showBatchStock">
      <a (click)="gotoDMS('batch_stock', 'dmsv2_batch_stock')" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">BSK</span><span class="bracketspan">]</span>
        <label class="hide">Batch Stock</label>
      </a>
    </li>

    <li *ngIf="show_dmsv2_claims">
      <a class="dropdown-toggle" data-toggle="dropdown" (click)="hideShowDMSClaims()">
        <span><i class="fas fa-money-check fa-2x" style="color:#183153"></i></span>
        <label class="hide">Claims</label>
      </a>
    </li>
    <li class="hide" id="dmsv2_claims" *ngIf="show_dmsv2_claims">
      <a (click)="gotoDMS('claims', 'dmsv2_claims')" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">CLA</span><span class="bracketspan">]</span>
        <label class="hide">My Claims</label>
      </a>
    </li>
    <li class="hide" id="dmsv2_scheme_raise_claim" *ngIf="show_dmsv2_claims">
      <a (click)="gotoDMS('raise_scheme_claims', 'dmsv2_scheme_raise_claim')" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">SC</span><span class="bracketspan">]</span>
        <label class="hide">Schemes</label>
      </a>
    </li>
    <li class="hide" id="dmsv2_sale_return_raise_claim" *ngIf="show_dmsv2_claims">
      <a (click)="gotoDMS('raise_sale_return_claims', 'dmsv2_sale_return_raise_claim')" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">RC</span><span class="bracketspan">]</span>
        <label class="hide">Returns</label>
      </a>
    </li>
    <li class="hide" id="dmsv2_multi_bill_raise_claim" *ngIf="show_dmsv2_claims">
      <a (click)="gotoDMS('raise_multi_bill_claims', 'dmsv2_multi_bill_raise_claim')" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">MB</span><span class="bracketspan">]</span>
        <label class="hide">Multi Bills</label>
      </a>
    </li>
    <li class="hide" id="dmsv2_claims_list" *ngIf="show_dmsv2_claim_approve">
      <a (click)="gotoDMS('claims_list', 'dmsv2_claims_list')" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">CTA</span><span class="bracketspan">]</span>
        <label class="hide">To Approve</label>
      </a>
    </li>
    <li class="hide" id="dmsv2_debit_notes" *ngIf="show_dmsv2_module">
      <a (click)="gotoDMS('debit_notes', 'dmsv2_debit_notes')" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">DBN</span><span class="bracketspan">]</span>
        <label class="hide">Debit Notes</label>
      </a>
    </li>
    <li class="hide" id="dmsv2_my_expenses" *ngIf="show_dmsv2_claims">
      <a (click)="gotoDMS('my_expense_claims', 'dmsv2_my_expenses')" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">EXP</span><span class="bracketspan">]</span>
        <label class="hide">Claim Expenses</label>
      </a>
    </li>
    <li class="hide" id="dmsv2_approve_expenses" *ngIf="show_dmsv2_claims">
      <a (click)="gotoDMS('approve_expense_claims', 'dmsv2_approve_expenses')" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">CAPPR</span><span class="bracketspan">]</span>
        <label class="hide">Approve Expenses</label>
      </a>
    </li>

    <!--    DMS V2 END-->

    <li class="dropdown" (click)="gotoDropdown('dms_secondary')" *ngIf="show_dms_module && !show_dmsv2_module">
      <a class="dropdown-toggle" data-toggle="dropdown" (click)="gotoDropdown('dms_secondary')">
        <span><i class="fad fa-store fa-2x" (click)="gotoDropdown('dms_secondary')" style="color:#183153"></i></span>
        <label class="hide" (click)="gotoDropdown('dms_secondary')">Sales</label>
      </a>
    </li>
    <li class="hide" id="dms_orders" *ngIf="show_dms_module">
      <a (click)="gotoOrders()" style="margin-left: 20%;">
        <!-- <img src="./../../../assets/PO.svg" />-->
        <span class="bracketspan">[</span><span class="textspan">ORD</span><span class="bracketspan">]</span>
        <label class="hide">Orders</label>
      </a>
    </li>
    <li class="hide" id="dms_invoice" *ngIf="show_dms_module">
      <a (click)="gotoInvoices()" style="margin-left: 20%;">
        <!--  <img src="./../../../assets/receipt.svg" />-->
        <span class="bracketspan">[</span><span class="textspan">INV</span><span class="bracketspan">]</span>
        <label class="hide">Invoices</label>
      </a>
    </li>
    <li class="hide" id="dms_s_return" *ngIf="show_dms_module">
      <a (click)="gotoRefund()" style="margin-left: 20%;">
        <!-- <img src="./../../../assets/returns.svg" />-->
        <span class="bracketspan">[</span><span class="textspan">RET</span><span class="bracketspan">]</span>
        <label class="hide">Returns</label>
      </a>
    </li>
    <li class="hide" id="dms_scheme_claims" *ngIf="show_dms_module">
      <a (click)="gotoDMS('sec_invoice_claims', 'dms_scheme_claims')" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">SCM</span><span class="bracketspan">]</span>
        <label class="hide">Schemes</label>
      </a>
    </li>

    <li class="hide" id="dms_s_payment" *ngIf="show_dms_module">
      <a (click)="gotoPayments('S')" style="margin-left: 20%;">
        <!-- <img src="./../../../assets/receipt.svg" />-->
        <span class="bracketspan">[</span><span class="textspan">PAY</span><span class="bracketspan">]</span>
        <label class="hide">Payments</label>
      </a>
    </li>
    <li class="hide" id="dms_s_credit" *ngIf="show_dms_module">
      <a (click)="gotoCredits('S')" style="margin-left: 20%;">
        <!-- <img src="./../../../assets/receipt.svg" />-->
        <span class="bracketspan">[</span><span class="textspan">CN</span><span class="bracketspan">]</span>
        <label class="hide">Credit Notes</label>
      </a>
    </li>
    <li class="hide" id="dms_del_plan" *ngIf="show_dms_module && showDeliveryPlan">
      <a (click)="gotoDeliveryPlan('S')" style="margin-left: 20%;">
        <!-- <img src="./../../../assets/receipt.svg" />-->
        <span class="bracketspan">[</span><span class="textspan">DP</span><span class="bracketspan">]</span>
        <label class="hide">Delivery Plan</label>
      </a>
    </li>

    <li class="dropdown" (click)="gotoDropdown('dms_primary')" *ngIf="show_dms_module && !show_dmsv2_module">
      <a class="dropdown-toggle" data-toggle="dropdown" (click)="gotoDropdown('dms_primary')">
        <!--<img (click)="gotoDropdown('dms_primary')" src="./../../../assets/primary%20purchase.svg" />-->
        <span (click)="gotoDropdown('dms_primary')"><i class="fad fa-cart-arrow-down fa-2x"
                                                       style="color:#183153"></i></span>
        <label class="hide" (click)="gotoDropdown('dms_primary')">Purchase</label>
      </a>
    </li>
    <li class="hide" id="dms_orders_p" *ngIf="show_dms_module">
      <a (click)="gotoOrdersP()" style="margin-left: 20%;">
        <!--  <img src="./../../../assets/PO.svg" />-->
        <span class="bracketspan">[</span><span class="textspan">ORD</span><span class="bracketspan">]</span>
        <label class="hide">Orders</label>
      </a>
    </li>
    <li class="hide" id="dms_invoice_p" *ngIf="show_dms_module">
      <a (click)="gotoInvoicesP()" style="margin-left: 20%;">
        <!-- <img src="./../../../assets/receipt.svg" />-->
        <span class="bracketspan">[</span><span class="textspan">INV</span><span class="bracketspan">]</span>
        <label class="hide">Invoices</label>
      </a>
    </li>
    <li class="hide" id="dms_p_return" *ngIf="show_dms_module">
      <a (click)="gotoRefundInv()" style="margin-left: 20%;">
        <!-- <img src="./../../../assets/returns.svg" />-->
        <span class="bracketspan">[</span><span class="textspan">RET</span><span class="bracketspan">]</span>
        <label class="hide">Returns</label>
      </a>
    </li>
    <li class="hide" id="dms_p_payment" *ngIf="show_dms_module">
      <a (click)="gotoPayments('P')" style="margin-left: 20%;">
        <!-- <img src="./../../../assets/receipt.svg" />-->
        <span class="bracketspan">[</span><span class="textspan">PAY</span><span class="bracketspan">]</span>
        <label class="hide">Payments</label>
      </a>
    </li>
    <li class="hide" id="dms_p_credit" *ngIf="show_dms_module">
      <a (click)="gotoCredits('P')" style="margin-left: 20%;">
        <!--<img src="./../../../assets/receipt.svg" />-->
        <span class="bracketspan">[</span><span class="textspan">DN</span><span class="bracketspan">]</span>
        <label class="hide">Debit Notes</label>
      </a>
    </li>
    <li *ngIf="show_dms_module">
      <a (click)="gotoStocks()">
        <!--  <img src="./../../../assets/MTO.svg" />-->
        <span><i class="fad fa-boxes-alt fa-2x" style="color:#183153"></i></span>
        <label class="hide">Stock</label>
      </a>
    </li>
<!--    <li class="dropdown" *ngIf="show_expense_module" (click)="gotoDropdown('claim')">-->
<!--      <a class="dropdown-toggle" data-toggle="dropdown">-->
<!--        <span><i class="fas fa-money-bill fa-2x" style="color:#183153"></i></span>-->
<!--        <label class="hide">Claims</label>-->
<!--      </a>-->
<!--    </li>-->
<!--    <li class="hide" id="viewclaim" *ngIf="show_expense_module">-->
<!--      <a (click)="gotoClaims()" style="margin-left: 20%;">-->
<!--        &lt;!&ndash;  <img src="./../../../assets/inventory.svg" />&ndash;&gt;-->
<!--        <span class="bracketspan">[</span><span class="textspan">CLA</span><span class="bracketspan">]</span>-->
<!--        <label class="hide">View</label>-->
<!--      </a>-->
<!--    </li>-->
<!--    <li class="hide" id="raiseclaim" *ngIf="show_expense_module && show_dmsv2_module">-->
<!--      <a (click)="gotoRaiseClaims()" style="margin-left: 20%;">-->
<!--        &lt;!&ndash;  <img src="./../../../assets/inventory.svg" />&ndash;&gt;-->
<!--        <span class="bracketspan">[</span><span class="textspan">RAI</span><span class="bracketspan">]</span>-->
<!--        <label class="hide">Raise</label>-->
<!--      </a>-->
<!--    </li>-->
<!--    <li class="hide" id="returnclaim" *ngIf="show_expense_module && show_dmsv2_module">-->
<!--      <a (click)="gotoReturnClaims()" style="margin-left: 20%;">-->
<!--        <span class="bracketspan">[</span><span class="textspan">RIN</span><span class="bracketspan">]</span>-->
<!--        <label class="hide">Returns</label>-->
<!--      </a>-->
<!--    </li>-->
<!--    <li class="hide" id="approveclaim" *ngIf="show_expense_module && show_dmsv2_module">-->
<!--      <a (click)="gotoApproveClaims()" style="margin-left: 20%;">-->
<!--        <span class="bracketspan">[</span><span class="textspan">APPR</span><span class="bracketspan">]</span>-->
<!--        <label class="hide">Approve</label>-->
<!--      </a>-->
<!--    </li>-->

    <li class="dropdown" *ngIf="show_expense_module" (click)="gotoDropdown('expense')">
      <a class="dropdown-toggle" data-toggle="dropdown">
        <img src="./../../../assets/purchase%20order.svg"/>
        <label class="hide">Expense</label>
      </a>
    </li>
    <li class="hide" id="expense_2" *ngIf="show_expense_module && show_expense_approve">
      <a (click)="gotoExpense()" style="margin-left: 20%;">
        <!-- <img src="./../../../assets/inventory.svg" />-->
        <span class="bracketspan">[</span><span class="textspan">APR</span><span class="bracketspan">]</span>
        <label class="hide">Approve</label>
      </a>
    </li>
    <li class="hide" id="expense_5" *ngIf="show_expense_module && showExpenseToSettle">
      <a (click)="getExpenseToSettle()" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">SET</span><span class="bracketspan">]</span>
        <label class="hide">Settle</label>
      </a>
    </li>
    <li class="hide" id="expense_3" *ngIf="show_expense_module">
      <a (click)="gotoMyExpense()" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">EXP</span><span class="bracketspan">]</span>
        <label class="hide">My Expenses</label>
      </a>
    </li>

    <li class="dropdown" *ngIf="show_products_module">
      <a class="dropdown-toggle" data-toggle="dropdown" (click)="gotoDropdown('products')">
        <span><i class="fad fa-box fa-2x" style="color:#183153"></i></span>
        <label class="hide">Products</label>
      </a>
    </li>
    <li id="products" class="hide">
      <a (click)="goToProducts()" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">PRD</span><span class="bracketspan">]</span>
        <label class="hide">Products</label>
      </a>
    </li>
    <li id="pricelist" class="hide">
      <a (click)="goToPricelist()" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">PRL</span><span class="bracketspan">]</span>
        <label class="hide">Pricelist</label>
      </a>
    </li>
    <li id="stock" class="hide">
      <a (click)="goToStock()" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">STK</span><span class="bracketspan">]</span>
        <label class="hide">Stock</label>
      </a>
    </li>
    <li id="schemes" class="hide">
      <a (click)="goToSchemes()" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">SCM</span><span class="bracketspan">]</span>
        <label class="hide">Schemes</label>
      </a>
    </li>
    <li class="dropdown" *ngIf="show_user_module">
      <a class="dropdown-toggle" data-toggle="dropdown" (click)="gotoDropdown('users')">
        <span><i class="fad fa-user fa-2x" style="color:#183153"></i></span>
        <label class="hide">Users</label>
      </a>
    </li>
    <li id="users" class="hide">
      <a (click)="goToUsers()" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">USR</span><span class="bracketspan">]</span>
        <label class="hide">Users</label>
      </a>
    </li>
    <li id="team" class="hide">
      <a (click)="goToUserTeam()" style="margin-left: 20%;">
        <span class="bracketspan">[</span><span class="textspan">TM</span><span class="bracketspan">]</span>
        <label class="hide">Team</label>
      </a>
    </li>
    <!-- <li id="assessment" class="hide">
       <a (click)="goToUserAssessment()" style="margin-left: 20%;">
         <span class="bracketspan">[</span><span class="textspan">AST</span ><span class="bracketspan">]</span>
         <label class="hide">Assessment</label>
       </a>
     </li>-->
    <!-- <li id="kpiscorecard" class="hide">
       <a (click)="goToUserKPIScoreCard()" style="margin-left: 20%;">
         <span class="bracketspan">[</span><span class="textspan">KPI</span ><span class="bracketspan">]</span>
         <label class="hide">Score Card</label>
       </a>
     </li>-->
    <li id="sales" *ngIf="show_sales">
      <a (click)="gotoSales()">
        <img src="../../../assets/invoice_grey.svg"/>
        <label class="hide">Invoices</label>
      </a>
    </li>



    <li *ngIf="showMaster">
      <a class="dropdown-toggle" data-toggle="dropdown" (click)="gotoDropdown('master')">
        <span><i class="fas fa-database fa-2x" style="color:#183153"></i></span>
        <label class="hide">Master</label>
      </a>
    </li>
    <li id="schememaster" class="hide">
      <a style="margin-left: 20%;" (click)="goToURL('/master/schemes')">
        <span class="bracketspan">[</span><span class="textspan">SCH</span><span class="bracketspan">]</span>
        <label class="hide">Schemes</label>
      </a>
    </li>
    <li id="pricelistmaster" class="hide">
      <a style="margin-left: 20%;" (click)="goToURL('/master/pricelist')">
        <span class="bracketspan">[</span><span class="textspan">PRL</span><span class="bracketspan">]</span>
        <label class="hide">Pricelist</label>
      </a>
    </li>

    <li *ngIf="showGstr">
      <a class="dropdown-toggle" data-toggle="dropdown" (click)="gotoDropdown('gstr')">
        <span><i class="fas fa-database fa-2x" style="color:#183153"></i></span>
        <label class="hide">GSTR</label>
      </a>
    </li>
    <li id="gstr1" class="hide">
      <a style="margin-left: 20%;" (click)="goToURL('/gstr/1')">
        <span class="bracketspan">[</span><span class="textspan">GSTR1</span><span class="bracketspan">]</span>
        <label class="hide">GSTR1</label>
      </a>
    </li>
    <li id="gstr2" class="hide">
      <a style="margin-left: 20%;" (click)="goToURL('/gstr/2')">
        <span class="bracketspan">[</span><span class="textspan">GSTR2</span><span class="bracketspan">]</span>
        <label class="hide">GSTR2</label>
      </a>
    </li>
    <li id="tcs" class="hide">
      <a style="margin-left: 20%;" (click)="goToURL('/gstr/tcs')">
        <span class="bracketspan">[</span><span class="textspan">TCS</span><span class="bracketspan">]</span>
        <label class="hide">TCS</label>
      </a>
    </li>
    <li>
      <a (click)="gotoReports()">
        <span><i class="fas fa-file-chart-line fa-2x" style="color:#183153"></i></span>
        <label class="hide" (click)="gotoReports()">Reports</label>
      </a>
    </li>
  </ul>
</div>
