import {Component, OnInit} from "@angular/core";
import {ReportBase} from "./_common/reportbase";

@Component({
  selector: 'app-credit-notes-component',
  templateUrl: './_common/reportbase.html',
  styleUrls: ['./_common/reportbase.scss']
})

export class Credit_notes_reportComponent extends ReportBase implements IReport, OnInit  {

  /* showableFilters={
     max_claim:false,
     policy:false,
     salesman:false,
     status:false,
     class:false,
     beat:false,
     trax_outlet:false,
     non_trax_outlet:false,
     exclude_trax_audit:false,
     region:false,
     cluster:false,
     team:false,
     territory:false,
     outlet_type: true,
     dc: true
   }*/

  setTitle() {
    this.title = 'Claim Scheme Disbursement';
  }

  ngOnInit() {

    this.sharedService.setHeader('Credit Notes');
    this.sharedService.dmsParams.dmsType = 'S';
    this.router.navigate([this.apiService.redirect_url + "/credit_notes"]);
    this.configureGrid();
  }

  configureGrid() {}

}
