import {Component, OnInit} from "@angular/core";
import {ReportBase} from "../_common/reportbase";

@Component({
  selector: 'app-market_intelligence_summary_report',
  templateUrl: '../_common/reportbase.html',
})
export class MarketIntelligenceSummaryReportComponent extends ReportBase implements OnInit {


  ngOnInit(): void {
    this.sharedService.setHeader('Market Intelligence Summary Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;

    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getRetailerSurveySummary';

    this.columnDefs = [
      {headerName: "CLUSTER", field: "cluster",  width: 80},
      {headerName: "TERRITORY", field: "territory",  width: 80},
      {headerName: "TCODE", field: "tcode",  width: 80,},
      {headerName: "SALESMAN", field: "salesman",  width: 80},
      {headerName: "SCODE", field: "scode",  width: 80},
      {headerName: "TOWN TIER", field: "program_type",  width: 80},
      {headerName: "SURVEY NAME", field: "survey",  width: 80},
      {headerName: "CODE", field: "retailer_code",  width: 50},
      {headerName: "RETAILER", field: "retailer",  width: 150},
      {headerName: "SCORE %", field: "score", cellStyle: {textAlign: 'center'},  width: 50},
      {headerName: "DATE", field: "completion_date",  width: 80},

    ];
  }


}
