<div style="margin-top: -15px;">
  <span style="color:#17A2B8;">{{customFilters.name}} </span>
</div>
<div  class="date-picker">
  <!-- <span style="margin-top: 15px;margin-right: 5px;">Retail Audit Type </span>-->

  <div id="custfilter" style="width:180px;" class="periodselect">
    <select
      #customfilter
      id="customfilter"
      name="filterid"
      [(ngModel)]="selectedData[customFilters.key]"
      (change)="setCustomFilter()"
      #filterid="ngModel"

    >
      <option
        *ngFor="let item of customFilters.values"
        value="{{ item.id }}"
      >
        {{ item.itemName }}
      </option>
    </select>
  </div>
</div>

