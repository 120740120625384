import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-eodlistcard",
  templateUrl: "./eodlistcard.component.html",
  styleUrls: ["./eodlistcard.component.scss"]
})
export class EodlistcardComponent implements OnInit {
  @Input() tc: string;
  @Input() vc: string;
  @Input() pc: string;
  @Input() gc: string;
  @Input() nv: string;

  constructor() {}

  ngOnInit() {}
}
