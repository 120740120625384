import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-outlet-last-visit-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class BeatProductivityReportComponent extends ReportBase implements IReport, OnInit {

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Beat Productivity Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    //this.showDate = false;

    this.configureGrid();

    this.gridOptions.headerHeight = 40;
    this.gridOptions.autoGroupColumnDef = {
      headerName: 'Retailer Name',
      field: 'retailer_name',
      minWidth: 200,
      cellRenderer: 'agGroupCellRenderer',
      resizable: true
    };
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/beatProductivityReport';

    this.columnDefs = [
      {headerName: 'REGION', field: 'region', enableRowGroup: true, width: 80},
      {headerName: 'CLUSTER', field: 'cluster', enableRowGroup: true, width: 80},
      {headerName: 'TCODE', field: 'terr_code', enableRowGroup: true, hide: true, width: 80},
      {headerName: 'TERRITORY', field: 'territory', enableRowGroup: true, width: 80},
      {headerName: 'DATE', field: 'schedule_date', enableRowGroup: true, width: 80},
      {headerName: 'BEAT', field: 'beat', enableRowGroup: true, width: 120},
      {headerName: 'SALESMAN', field: 'salesman', enableRowGroup: true, width: 80},
      {headerName: 'EMP CODE', field: 'emp_code', enableRowGroup: true, hide: true, width: 80},
      {headerName: 'PRODUCT', field: 'prd_name',  width: 80},
      {headerName: 'UOM', field: 'uom', width: 80},
      {headerName: 'QTY', field: 'qty', maxwidth: 80},
      {headerName: 'SUBTOTAL', field: 'subtotal', maxwidth: 80},
      {headerName: 'TAX', field: 'tax', maxwidth: 80},
      {headerName: 'TOTAL', field: 'total', maxwidth: 80}

    ];

  }

}
