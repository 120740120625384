import {ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {ApiService} from "../../_services/api.service";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {LocalforageService} from "../../_services/localforage.service";
import {ErrorObject} from "../../error/error-model";

@Component({
  selector: 'app-move-beat-retailer-popup',
  templateUrl: './change_beat_salesman_popup.component.html',
  styleUrls: ['./change_beat_salesman_popup.component.scss']

})

export class ChangeBeatSalesmanPopupComponent implements OnInit {

  @Output() changeData: EventEmitter<any> = new EventEmitter<any>();

  selectedData = {
    role_id: 0,
    salesman_id: 0,
    beat_ids: [],
    region_name: '',
    team_name: ''
  };

  regionsList: any = [];
  teamList: any = [];
  rolesList: any = [{id: 0, itemname: 'All roles'}];
  salesmenList: any = [];

  filteredSalesmanList: any = [];

  API_URL = '';
  access_token;
  // beatsData;
  message = '';
  // uq_bp;
  // uq_ter;

  preloader: any;
  errorObject: ErrorObject;
  errorExists = false;
  dataLoaded: any;

  @Output() saveActionEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(private apiService: ApiService,
              public dialog: MatDialog,
              private toastr: ToastrService,
              private _changeDetectionRef: ChangeDetectorRef,
              protected _localforageService: LocalforageService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {

    // this.beatsData = dialogdata.beatsData;
    this.selectedData.beat_ids = dialogdata.beat_ids;
    this.message = dialogdata.message;

    // this.uq_bp = dialogdata.uq_bp;
    // this.uq_ter = dialogdata.uq_ter;
    if (localStorage.getItem('all_salesmans') !== null && localStorage.getItem('all_salesmans') !== 'null' && JSON.parse(localStorage.getItem('all_salesmans')) !== null) {
      this.salesmenList = JSON.parse(localStorage.getItem('all_salesmans'));
      this.filteredSalesmanList = this.salesmenList;
    }

    if (localStorage.getItem('all_roles') !== null && localStorage.getItem('all_roles') !== 'null' && JSON.parse(localStorage.getItem('all_roles')) !== null) {
      const allRolesList = JSON.parse(localStorage.getItem('all_roles'));
      const uniqueRoles = [...new Set(this.salesmenList.map(obj => obj.role_id))];
      const filteredRoles = allRolesList.filter(el => {
        return uniqueRoles.some(r => {
          return r === el.id;
        });
      });
      this.rolesList = this.rolesList.concat(filteredRoles);
      this.rolesList.sort((a, b) => (a.itemname < b.itemname ? -1 : 1));
    }

    if (localStorage.getItem('all_regions') != null && localStorage.getItem('all_regions') !== 'null' && JSON.parse(localStorage.getItem('all_regions')) != null) {
      this.regionsList = JSON.parse(localStorage.getItem('all_regions'));
      this.regionsList.sort((a, b) => (a.itemname < b.itemname ? -1 : 1));
    }

    if (localStorage.getItem('all_teams') !== null && localStorage.getItem('all_teams') !== 'null' && JSON.parse(localStorage.getItem('all_teams')) !== null) {
      this.teamList = JSON.parse(localStorage.getItem('all_teams'));
      this.teamList.sort((a, b) => (a.itemname < b.itemname ? -1 : 1));
    }


    this.access_token = localStorage.getItem('resfreshToken');

  }

  ngOnInit(): void {
    this.filterSalesmanList();
  }


  public handleError(error: ErrorObject) {
    this.preloader = false;
    this.dataLoaded = false;
    this.errorExists = true;
    this.errorObject = error;
  }

  closeDialog() {
    this.dialog.closeAll();
  }


  onChangeRole() {
    this.selectedData.salesman_id = 0;
    // this.filterTeamList();
    this.filterSalesmanList();
  }


  filterSalesmanList() {
    const role_id = parseInt(String(this.selectedData.role_id), 0);
    this.selectedData.salesman_id = 0;

    this.filteredSalesmanList = this.salesmenList.filter(item => {
      if (role_id > 0) {
        return (item.role_id === role_id);
      }
      return true;
    });
  }

  onSalesmanChange() {
    const salesman_id = parseInt(String(this.selectedData.salesman_id), 0);
    const salesman_obj = this.salesmenList.find(item => item.id === salesman_id);
    this.selectedData.team_name = '';
    this.selectedData.region_name = '';

    if (salesman_obj) {
      const team_obj = this.teamList.find(item => item.id = salesman_obj.sale_team_id);
      const region_obj = this.regionsList.find(item => item.id = salesman_obj.region_id);
      if (team_obj) {
        this.selectedData.team_name = team_obj.itemname;
      }
      if (region_obj) {
        this.selectedData.region_name = region_obj.itemname;
      }
    }
  }

  onSave() {

    this.API_URL = '/api/beat_plans/change_salesman';
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {};
    paramObject.data.beat_ids = this.selectedData.beat_ids;
    paramObject.data.salesman_id = this.selectedData.salesman_id;

    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.changeData.emit('success');
          } else if (res.hasOwnProperty('results') && (res.results.status === 501)) {
            this.toastr.error(res.results.msg);
          } else {
            this.toastr.error('Error changing salesman');
          }
        },
        error => {
          console.log(error);
          this.toastr.error('Error Changing Salesman');
        });
  }
}
