import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {DetailPageBase} from "../../../pagebase/DetailPageBase";
import {DeleteComponent} from "../../../deleteCell.component";
import {NgSelectComponent} from "@ng-select/ng-select";
import {CellEditingStartedEvent, CellEditingStoppedEvent, GetRowIdFunc, GetRowIdParams, RowEditingStartedEvent, RowEditingStoppedEvent} from "@ag-grid-enterprise/all-modules";
import {NumericEditorComponent} from "../../../pagebase/components/NumericEditorComponent";
import {DMSBasePage} from "../../../pagebase/components/DMSBasePage";
import {ErrorHandler} from "../../../error/error-handler";


@Component({
  selector: 'app-return-invoice',
  templateUrl: 'return_invoice.html',
  styleUrls: ['../../../pagebase/pagebase_detail.scss']
})

export class ReturnInvoiceComponent extends DMSBasePage implements IDetailPage, OnInit {
  @ViewChild('productSelector') productSelector: NgSelectComponent;
  @ViewChild('qty', {read: ElementRef}) qtyElement: ElementRef;
  OPERATION_MODE = 'S';

  dateDisable;
  myDate;
  public addressLibrary;
  public inco_terms_list;

  public priceEdited = false;
  public uomEdited = false;

  public pricelist_id;
  public pricelist_name;
  public partner_tcs;
  public salesman_id;
  public billto_adrr_list;
  public shipto_adrr_list;
  public selected_billto_addr_id;



  selectedProduct;
  selectedUOM_id;
  enteredQty;
  stock;
  order_ref;

  public selectedUser;

  showPlantField = false;
  showIncoTerms = false;
  showTotalWeight = false;
  showTotalMeasure = false;

  plantFieldValue = '';
  incoTermsFieldValue = '';
  totalMeasureFieldValue = '';
  totalWeightFieldValue = '';

  reference_invoice_mode = false;

  ngOnInit(): void {
    // this.setTitle('Return Invoice');
    this._Detail_GET_URL = "/api/pwa_dms_connects/get_invoice_detail";
    this.context = {componentParent: this};
    this.sharedService.setHeader('Sales Return');

    this.configureGrid();

    this.initialize_default_values();

    this.loadMasterDataForView(() => {
      this.readPageParms(params => {

        if (params.get('mode') === 'P') {
          this.OPERATION_MODE = 'P';
          this.sharedService.setHeader('Purchase Return');
        }

        if (params.get('reference_invoice') === 'true') {

          const ref_invoice_object = JSON.parse(localStorage.getItem('reference_invoice'));
          if (ref_invoice_object !== undefined) {

            this.loadPartnersDataForEdit(() => {

              this.prepare_referance_object(ref_invoice_object);
              //   Page Can be ready only after detail Object is fetched

              this.loadProducts(this.activeObject['partner_id'], this.activeObject['supplier_id'], this.activeObject['territory_id'], this.activeObject['pricelist_id'], () => {
                this.pageReady = true;
                this.compute_scheme_and_tax();
                // this.rowData = schme_tax_res.items;
                // this.activeObject['tax_notes'] = schme_tax_res.tax_notes;
                // this.compute_tax();
                this.compute_totals();

                this.enableDisableGridEdit();
              });
            });

          }
        } else {

          if (this.activeID > 0) {
            this.newMode = false;
            this.editMode = false;
            //   View Mode
            //   Page Can be ready only after detail Object is fetched
            this.loadPageForView(() => {
              this.pageReady = true;
              this.enableDisableGridEdit();
            });
          } else {
            this.newMode = true;
            this.editMode = true;
            //   Page Can be ready only after detail Object is fetched
            this.loadPartnersDataForEdit(() => {
              this.pageReady = true;
              this.enableDisableGridEdit();
            });
          }
        }
      });
    });
  }

  enableDisableGridEdit() {
    this.gridOptions.suppressClickEdit = !this.editMode;
    this.gridOptions.singleClickEdit = this.editMode;
  }

  loadPage() {
    this.pageReady = false;
    this.loadPageForView(() => {
      this.pageReady = true;
    });
  }

  //Page Initilizefunctions

  prepare_referance_object(ref_invoice_object) {

    this.activeObject = {...ref_invoice_object}; //Shallow Copy the reference Object.
    this.activeObject.id = 0;
    this.activeObject.name = '';
    this.activeObject.reference = ref_invoice_object.name;
    this.activeObject['state'] = 'draft';

    this.activeObject.lines = [];
    this.rowData = [];

    let rtn_qty_in_base_uom = 0;
    let uid = 10;
    ref_invoice_object.lines.forEach( item => {
      const ret_item = {...item};
      ret_item.uid = uid;

      const base_uom_factor = this.get_base_uom_factor(ret_item.uom_id);
      const base_uom_id = this.get_base_uom(ret_item.uom_id);
      if (base_uom_id) {
        ret_item.uom_id = base_uom_id;
      }

      ret_item.inv_qty = ret_item.quantity * base_uom_factor;
      rtn_qty_in_base_uom = 0;
      if (ref_invoice_object.ret) {
        const rIts = ref_invoice_object.ret.filter(i => i.p_id === ret_item.product_id);
        if (rIts && rIts.length > 0) {
          rIts.forEach(ri => {
            rtn_qty_in_base_uom = rtn_qty_in_base_uom + ( ri.qty * this.get_base_uom_factor(ret_item.uom_id));
          });
        }
      }
      ret_item.inv_qty = ret_item.inv_qty - rtn_qty_in_base_uom;
      ret_item.inv_rate =  item.price_unit;
      ret_item.reusable_qty = 0;
      ret_item.quantity = 0;
      ret_item.damage = 0;
      ret_item.mid = this.get_mid(uid);

      ret_item.discount = 0;
      ret_item.scheme_discount = 0;
      ret_item.price_subtotal = 0;
      ret_item.price_tax = 0;
      ret_item.tax_notes = {};
      ret_item.price_total = 0;

      uid = uid + 10;
      if (item.scheme_discount && item.scheme_discount > 0) {
        ret_item.price_unit = item.price_unit - (item.scheme_discount / item.quantity);
        ret_item.scheme_discount = 0;
      }
      if (ret_item.hasOwnProperty('config_json') && ret_item.config_json !== null && ret_item.config_json !== undefined) {
        if (ret_item.config_json.hasOwnProperty('base_price') && ret_item.config_json.base_price > 0) {
          ret_item['price_unit'] = ret_item.config_json.base_price;
        } else {
          const prod = this.prodList.find(p => p.p_id === ret_item.product_id);
          if (prod) {
            ret_item['base_price'] = DMSBasePage.GetBasePrice(prod);
          } else {
            ret_item['base_price'] = ret_item.price_unit;
          }
        }
      }
      if (ret_item.inv_qty > 0) {
        this.rowData.push(ret_item);
      }
    });

    this.gridOptions.api.setRowData(this.rowData);

    this.compute_totals();
    this.activeID = 0;
    this.newMode = false;
    this.editMode = true;
    this.reference_invoice_mode = true;

  }
  initialize_default_values() {
    this.activeObject.amount_tax = 0;
    this.activeObject.amount_total = 0;
    this.activeObject.amount_untaxed = 0;
    this.activeObject.tax_source = 0;
    this.activeObject.charges = 0;
    this.activeObject.total = 0;
    this.activeObject.discount = 0;
    this.activeObject.line_discount = 0;
    this.activeObject.spcl_discount = 0;
    this.activeObject.date_invoice = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.partner_tcs = 0;

    this.activeObject['mid'] = this.get_mid(undefined);
    this.activeObject['date_invoice'] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  }

  prepareLineData(lineItems) {
    const rowData = [];
    let unique_id = 1;

    if (lineItems) {
      lineItems.forEach(line => {
        line['uid'] = unique_id;
        line['damage'] = line['quantity'] - line['reusable_qty'];

        // if (line.hasOwnProperty('config_json') && line.config_json !== null && line.config_json !== undefined) {
        //   if (line.config_json.hasOwnProperty('base_price') && line.config_json.base_price > 0) {
        //     line['base_price'] = line.config_json.base_price;
        //   } else {
        //     const prod = this.prodList.find(item => item.p_id === line.product_id);
        //     if (prod) {
        //       line['base_price'] = DMSBasePage.GetBasePrice(prod);
        //     } else {
        //       line['base_price'] = line.price_unit;
        //     }
        //   }
        // }

        rowData.push(line);
        unique_id++;
      });
    }
    return rowData;
  }

  loadPageForView(callback) {

    this.loadActiveObject(() => {

      if (this.activeObject['partner_id'] === undefined) {
        this.toastr.error('invalid state: partner_id not found');
      }

      if (!this.activeObject['shipping_partner_id'] || this.activeObject['shipping_partner_id'] === 0) {
        this.activeObject['shipping_partner_id'] = this.activeObject['partner_id'];
      }

      if (this.activeObject && this.activeObject['lines'] && this.activeObject.lines.length > 0) {
        this.rowData = this.prepareLineData(this.activeObject.lines);
        // this.gridOptions.api.setRowData(this.rowData);
      }

      if (this.activeObject.hasOwnProperty('config_json') && this.activeObject.config_json !== null && this.activeObject.config_json !== undefined) {
        if (this.activeObject.config_json.hasOwnProperty('plant') && this.activeObject.config_json.plant !== null && this.activeObject.config_json.plant !== undefined) {
          this.showPlantField = true;
          this.plantFieldValue = this.activeObject.config_json.plant_name;
        }
        if (this.activeObject.config_json.hasOwnProperty('inco_term') && this.activeObject.config_json.inco_term !== null && this.activeObject.config_json.inco_term !== undefined) {
          this.showIncoTerms = true;
          this.incoTermsFieldValue = this.activeObject.config_json.inco_term;
        }
        if (this.activeObject.config_json.hasOwnProperty('totalWeight') && this.activeObject.config_json.totalWeight !== null && this.activeObject.config_json.totalWeight !== undefined) {
          this.showTotalWeight = true;
          this.totalWeightFieldValue = parseFloat(this.activeObject.config_json.totalWeight).toFixed(2);
        }
        if (this.activeObject.config_json.hasOwnProperty('totalMeasure') && this.activeObject.config_json.totalMeasure !== null && this.activeObject.config_json.totalMeasure !== undefined) {
          this.showTotalMeasure = true;
          this.totalMeasureFieldValue = parseFloat(this.activeObject.config_json.totalMeasure).toFixed(2);
        }
      }

      if (this.activeObject.amount_tax) {
        this.activeObject.amount_tax = parseFloat(this.activeObject.amount_tax);
      }
      if (this.activeObject.amount_total) {
        this.activeObject.amount_total = parseFloat(this.activeObject.amount_total);
      }
      if (this.activeObject.amount_untaxed) {
        this.activeObject.amount_untaxed = parseFloat(this.activeObject.amount_untaxed);
      }
      if (this.activeObject.tax_source) {
        this.activeObject.tax_source = parseFloat(this.activeObject.tax_source);
      } else if (this.activeObject.charges) {
        this.activeObject.charges = parseFloat(this.activeObject.charges);
      }

      this.compute_totals();
      this.get_supplier_available_stocks([this.activeObject], (err, result) => {
        if (err) {
          this.handleError(err);
        } else if (result) {
          this.rowData.forEach(line => {
            result.forEach(tp => {
              if (line.product_id === tp.product_id) {
                line.stock = tp.stock;
              }
            });
          });
        }
        this.gridOptions.api.setRowData(this.rowData);
        callback();
      });
    });

  }


  onCellEditingStarted(event: CellEditingStartedEvent) {
    console.log('cellEditingStarted');
    this.showSave = false;
  }

  onCellEditingStopped(event: CellEditingStoppedEvent) {
    if (event.newValue !== event.oldValue) {
      event.data['price_tax'] = 0;
      let unit_measure = 1;
      if (event.data.hasOwnProperty('unit_measure') && event.data.unit_measure > 0) {
        unit_measure = event.data.unit_measure;
      }
      if (!event.newValue) {
        event.newValue = 0;
      }

      if (event.colDef.field === 'quantity') {

        event.data['reusable_qty'] = parseInt(event.newValue, 10);
        event.data['price_tax'] = 0;
        event.data['promo_qty'] = 0;

        const prod = this.prodList.find(p => p.p_id === event.data['product_id']);
        if (prod && prod.volume) {
          const selected_uom_factor = this.get_factor_for_selected_uom(event.data['uom_id'], event.data['default_uom_id']);
          event.data['volume'] = selected_uom_factor * event.newValue * prod.volume;
        }
        event.data['price_total'] = parseFloat(event.data['price_subtotal']) + parseFloat(event.data['price_tax']);
        event.data['damage'] = 0;
        event.data['reusable_qty'] = parseInt(event.newValue, 10);

        // this.compute_scheme_and_tax();
      } else if (event.colDef.field === 'damage') {
        if (parseInt(event.newValue, 10) > event.data['quantity']) {
          event.data['damage'] = event.data['quantity'];
        }

        event.data['reusable_qty'] = event.data['quantity'] - parseInt(event.newValue, 10);
      }
      this.compute_tax();
    }
    this.showSave = true;
  }

  isExternalFilterPresent(): boolean {
    // to hide based on deleted flag
    return true;
  }

  doesExternalFilterPass(node: any): boolean {
    if (node.data) {
      return !(node.data.deleted);
    }
    return true;
  }

  //Page Events

  customerChangeEvent(selected_customer_id) {
    const data = this.customerList.find( item => item.id === selected_customer_id);
    // this.changeCustomer(selected_customer);
    if (data) {
      this.activeObject['partner_id'] = data.id;
      this.activeObject['partner_invoice_id'] = data.id;
      this.activeObject['partner_shipping_id'] = data.id;
      this.activeObject['customer_name'] = data.name;
      this.activeObject['customer_code'] = data.code;
      this.activeObject['customer_gst_id'] = data.gst_id;
      this.activeObject['customer_email'] = data.email;
      this.activeObject['customer_address'] = data.address;

      this.activeObject['area_id'] = data.area_id;
      this.activeObject['zone_id'] = data.zone_id;
      this.activeObject['state_id'] = data.state_id;

      if (data.hasOwnProperty('partner_type') && data['partner_type'] === 'supplier') {
        this.OPERATION_MODE = 'P';
        this.activeObject['type'] = 'P';
      } else {
        this.OPERATION_MODE = 'S';
        this.activeObject['type'] = 'S';
      }

      if (data.tcs && data.tcs > 0) {
        this.activeObject['tcs'] = data.tcs;
      }

      const prev_pricelist_id = this.activeObject['pricelist_id'];
      this.activeObject['pricelist_id'] = data.pricelist_id;
      this.activeObject['pricelist_name'] = data.pricelist_name;
      this.activeObject['shipto_address'] = data.address;
      // this.supplierList = [];
      this.beatList = [];

      if (data['beats']) {
        this.beatList = data['beats'];
        if (this.beatList.length > 0) {
          this.activeObject['beat_id'] = this.beatList[0].id;
          this.activeObject['territory_id'] = this.beatList[0].t_id;
          this.activeObject['user_id'] = this.beatList[0].sm_id;
          this.activeObject['beat_name'] = this.beatList[0].name;
          this.salesmanChange(this.activeObject['user_id']);
        }
      }
      if (!this.activeObject['territory_id'] && data.territory_id) {
        this.activeObject['territory_id'] = data.territory_id;
      }

      if (this.supplierList.length === 0) {
        this.toastr.error('Unable to load suppliers');
      } else {
        this.supplierChangeEvent({id: this.supplierList[0].id });
      }
    }

  }

  supplierChangeEvent(event) {

    if (event.id) {
      if (this.activeObject['supplier_id'] !== event.id) {
        this.activeObject['supplier_id'] = event.id;
        //Supplier Chnage Detected
        if (this.populateSupplierAttributes(this.activeObject['supplier_id'])) {
          this.loadProducts(this.activeObject['partner_id'], this.activeObject['supplier_id'], this.activeObject['territory_id'], this.activeObject['pricelist_id'], () => {
          });
        }
      }
    }
  }

  plantChangeEvent(plant_code) {
    if (plant_code) {
      this.activeObject['config_json']['plant'] = plant_code;

      const select_plan_obj = this.plantList.find(item => item.code = plant_code);
      if (select_plan_obj) {
        this.activeObject['config_json']['plant_name'] = select_plan_obj.name;
      }
    }
  }

  beatChange(beat_id) {
    this.activeObject['beat_id'] = beat_id;
    const selected_beat = this.beatList.find( item => item.id === beat_id);
    if (selected_beat) {
      this.activeObject['beat_name'] = selected_beat.name;
      this.activeObject['user_id'] = selected_beat.sm_id;
      this.activeObject['team_id'] = selected_beat.team_id;
      this.activeObject['salesman'] = selected_beat.sm;
    }
  }

  salesmanChange(user_id) {
    if (user_id) {
      const selecteduser = this.userList.find(item => item.id === user_id);
      if (selecteduser) {
        this.activeObject['salesman'] = selecteduser.itemname;
        this.activeObject['emp_code'] = selecteduser.emp_code;
        this.activeObject['team_id'] = selecteduser.sale_team_id;
      }
    }
  }

  productSelectorChange() {
    console.log(this.selectedProduct);
    const prod = this.prodList.find(item => item.p_id === this.selectedProduct);
    if (prod) {
      this.UOMList = this.UOMMasterList.filter(item => item.categ_id === prod.uom_categ_id);
      if (this.UOMList.length <= 0) {
        this.toastr.error('CONFIG ERROR, UOMS NOT FOUND for selected product!, Contact support');
        return;
      }
      this.selectedUOM_id = prod.default_uom_id;
      this.stock = prod.stock;
    }
    if (this.qtyElement.nativeElement.value === "0") {
      this.qtyElement.nativeElement.value = "";
    }
    this.qtyElement.nativeElement.focus();
  }

  productUOMChange(uom_id) {
    console.log(this.selectedUOM_id);
  }

  changeDate(dt) {

  }

  discard() {
    this.editMode = false;
    if (this.activeObject && (!this.activeObject.hasOwnProperty('id') || this.activeObject.id === 0)) {
      history.back();
    } else {
      this.enableDisableGridEdit();
      this.loadPage();
    }
  }

  action_edit() {
    this.editMode = true;
    this.pageReady = false;
    this.loadPartnersDataForEdit(() => {
      this.loadProducts(this.activeObject['partner_id'], this.activeObject['supplier_id'], this.activeObject['territory_id'], this.activeObject['pricelist_id'], () => {
        this.pageReady = true;
        this.enableDisableGridEdit();
      });
    });
    this.gridApi.redrawRows();
  }

  gotoinvoice() {

    const order = this.activeObject;
    order['mid'] = undefined;
    localStorage.setItem('invoice_create', JSON.stringify(order));

    this.router.navigate([this.apiService.redirect_url + '/dms/sale_invoice'],
      {
        relativeTo: this.route,
        queryParams: {invoice_form_order: true}
      });
  }


  view_order() {

    if (this.activeObject.hasOwnProperty('order_id')) {
      this.router.navigate([this.apiService.redirect_url + '/dms/sale_order'],
        {
          relativeTo: this.route,
          queryParams: {id: this.activeObject['order_id']}
        });
    }
  }

  //GRID Functions
  configureGrid() {
    this.columnDefs = [
      {headerName: 'UID', field: 'uid', hide: true},
      {headerName: 'CODE', field: 'code', width: 50},
      {headerName: 'PRODUCT', field: 'name', width: 120},
      {headerName: 'INV QTY', field: 'inv_qty', width: 50},
      {headerName: 'INV RATE', field: 'inv_rate', width: 50, valueFormatter: params => this.formatNumber(params.data.inv_rate)},
      {headerName: 'INV SCHEME', field: 'scheme_code', width: 50 },
      {headerName: 'RETURNS', field: 'quantity', width: 60,  editable: true, cellEditor: 'agNumberCellEditor', cellRenderer: NumericEditorComponent,
        cellStyle: params => {
          if (params.data) {
            if (this.editMode) {
              return {backgroundColor: '#f2f6d7'};
            } else {
              return {backgroundColor: '#cae4f8'};
            }
          }
        }
      },
      {headerName: 'DAMAGE', field: 'damage', width: 60, editable: true, cellEditor: 'agNumberCellEditor', cellRenderer: NumericEditorComponent,
        cellStyle: params => {
          if (params.data) {
            if (this.editMode) {
              return {backgroundColor: '#f2f6d7'};
            } else {
              return {backgroundColor: '#cae4f8'};
            }
          }
        }
      },
      {headerName: 'REUSE', field: 'reusable_qty', width: 80},
      {headerName: 'UOM', field: 'uom_name', width: 60},
      {headerName: 'PRICE', field: 'price_unit', width: 80, valueFormatter: params => this.formatNumber(params.data.price_unit)},
      {headerName: 'DISC%', field: 'discount', width: 80, editable: true, cellEditor: 'agNumberCellEditor', cellRenderer: NumericEditorComponent, hide: true},
      {headerName: 'INV DISC', field: 'scheme_discount', width: 50, valueFormatter: params => this.formatNumber(params.data.scheme_discount), hide: true},
      {headerName: 'SUB TOTAL', field: 'price_subtotal', width: 60, valueFormatter: params => this.formatNumber(params.data.price_subtotal)},
      {headerName: 'TAX', field: 'price_tax', width: 50, valueFormatter: params => this.formatNumber(params.data.price_tax)},
      {headerName: 'TAX Breakup', field: 'tax_notes', width: 50, hide: true},
      {headerName: 'TOTAL', field: 'price_total', width: 60, valueFormatter: params => this.formatNumber(params.data.price_total)},
      {headerName: 'Serial NO', hide: true, width: 100},
      {headerName: '', field: 'delete', lockPosition: true, cellRenderer: 'cellDeleteRenderer', width: 40},
    ];
    this.frameworkComponents = {
      cellDeleteRenderer: DeleteComponent,
    };
  }

  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.uid;
  }

  addProductClick() {

    if (this.activeObject['partner_id'] === undefined || this.activeObject['partner_id'] <= 0) {
      this.toastr.error('Invalid Customer for invoice');
      return;
    }

    this.newMode = false;

    this.addProductLine(this.selectedProduct, this.selectedUOM_id, this.stock, this.enteredQty, undefined);

    this.compute_tax();

    this.enteredQty = 0;
    this.selectedProduct = 0;
    this.stock = 0;
    this.productSelector.focus();
  }

  removeProduct(index) {
    if (this.editMode) {
      const old_line = this.rowData[index];
      if (this.rowData[index].line_id !== undefined && this.rowData[index].line_id > 0) {
        this.rowData[index]['deleted'] = true;
        this.rowData[index]['quantity'] = 0;
        this.rowData[index]['reusable_qty'] = 0;

        this.gridApi.onFilterChanged();
      } else {
        this.rowData.splice(index, 1);
        this.gridApi.setRowData(this.rowData);
      }
      this.compute_totals();
    }
  }



  //Page core functions
  saveCart() {
    //TODO: Complete basic validation of page and line items
    if (!this.activeObject['partner_id'] || this.activeObject['partner_id'] === 0) {
        return this.toastr.error('Invalid Customer');
    }
    if (!this.activeObject['supplier_id'] || this.activeObject['supplier_id'] === 0) {
      return this.toastr.error('Invalid Supplier');
    }
    if (!this.activeObject['user_id'] || this.activeObject['user_id'] === 0) {
      this.activeObject['user_id'] = JSON.parse(localStorage.getItem('user')).id;
    }
    this.rowData.forEach(line => {
      if (line['mid'] === undefined || line['mid'].length === 0) {
        return this.toastr.error('Invalid mid for line');
      }
    });
    this.activeObject.id = this.activeID;
    this.activeObject['lines'] = [];
    this.rowData.forEach(line => {
      if (this.reference_invoice_mode) {
        if (line.quantity > line.inv_qty) {
          return this.toastr.error('Return Quantity Cannot be greater then Invoiced Quantity');
        }
      }
      if (parseInt(line.damage, 10) > parseInt(line.quantity, 10)) {
        return this.toastr.error('Damage Quantity Cannot be greater then Return Quantity');
      }
      if (line.quantity > 0) {
        line.reusable_qty = line.quantity - line.damage;
        if (line.config_json === undefined || line.config_json === null) {
          line.config_json = {};
        }
        line.config_json['damage'] = line.damage;
        this.activeObject['lines'].push(line);
      }
    });

    if (this.activeObject['lines'].length === 0) {
      return this.toastr.error('No product lines to save');
    }

    if (this.activeID <= 0 ) {
      this.activeObject.mid = this.get_mid(this.activeObject.partner_id);
      this.activeObject.invoice_type = 'S';
      this.activeObject.state = 'draft';
      this.activeObject.type = 'out_refund';
      this.activeObject.note = 'pwa';
      this.activeObject['journal_id'] = 5;
      this.activeObject['account_id'] = 25;
      this.activeObject['currency_id'] = 21;
      this.activeObject.po = '';
      this.activeObject.payment_mode = 'credit';
      this.activeObject.has_einvoice = false;
      this.activeObject.date_delivery = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      this.activeObject.approved = false;
      this.activeObject.period_id = DetailPageBase.get_current_period_id(this.activeObject.date_delivery);
    }



    //TODO: Validation
    this._Detail_SAVE_URL = "/api/pwa_dms_connects/save_return_invoice";
    this.saveDetailPage((err, result) => {
      if (err) {
        this.toastr.error(err);
      }  else {
        this.editMode = false;
        this.enableDisableGridEdit();
        this.loadPage();
      }
    });
  }


  action_create_invoice() {


  }

  action_cancel() {
    const inThis = this;
    this.userContext.data.id = this.activeObject.id;
    this.userContext['action'] = 'cancel';
    this.userContext['feature_disable_supplier_live_stock'] = this.sharedService.ACLcheck('feature_disable_supplier_live_stock');
    this.apiService.post('/api/pwa_dms_connects/sale_invoice_status_change', this.userContext)
      .subscribe(res => {
          if (res.hasOwnProperty('results') && res.results.status === 200) {
            if (res.results.hasOwnProperty('data') && res.results.data.length > 0) {

              inThis.activeObject.state = res.results.data[0]['state'];
              this.toastr.success('Invoice successfully Canceled : ' + this.activeID);

            } else {
              this.toastr.success('Record Saved!');
            }
          } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        error => {
          this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
        });
  }

  action_confirm_invoice() {
    const inThis = this;
    this.userContext.data.id = this.activeObject.id;
    this.userContext['action'] = 'confirm';
    this.userContext['feature_disable_supplier_live_stock'] = this.sharedService.ACLcheck('feature_disable_supplier_live_stock');
    this.apiService.post('/api/pwa_dms_connects/sale_invoice_status_change', this.userContext)
      .subscribe(res => {
          if (res.hasOwnProperty('results') && res.results.status === 200) {
            if (res.results.hasOwnProperty('data') && res.results.data.length > 0) {

              inThis.activeObject.state = res.results.data[0]['state'];
              this.toastr.success('Mark Confirm successfully : ' + this.activeID);

            } else {
              this.toastr.success('Record Saved!');
            }
          } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        error => {
          this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
        });
  }

  getPrintDetails() {
    this.appComponent.printAll([this.activeObject], 'id',  '/api/pwa_dms_connects/get_invoice_print_details', null);
  }

}
