import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-outlet-last-visit-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class SaleMetricBeatwiseReportComponent extends ReportBase implements IReport, OnInit {
  showCustomFilter = false;
  showDate = false;
  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    this.sharedService.setHeader('Sale Metric BeatWise Report');
    this.showDate = false;
    this.configureGrid();
    this.loadUserDetails();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_v2_reports/saleMetricBeatWiseReport';

    this.columnDefs = [

      {headerName: 'TEAM', field: 'team', width: 80},
      {headerName: 'EMP CODE', field: 'emp_code', width: 80},
      {headerName: 'SALESMAN', field: 'salesman', width: 80},

      {headerName: 'BEAT', field: 'beat', width: 80},
      {headerName: 'RETAILER COUNT', field: 'retailer_count', width: 80},
      {headerName: 'KEY OUTLETS', field: 'key_outlet_count', width: 80},
      {headerName: 'SCHEDULED COUNT', field: 'scheduled_count', width: 80},
      {headerName: 'TARGET', field: 'target', width: 80},
      {headerName: 'SALES (CM)', field: 'beat_sales_cm', width: 80},
      {headerName: 'UNIQUE VC', field: 'unique_vc', width: 80},
      {headerName: 'UNIQUE PC', field: 'unique_pc', width: 80},
      {headerName: 'KEY OUTLETS PC COUNT', field: 'key_outlet_order_count', width: 80},
      {headerName: 'KEY OUTLETS SALES', field: 'key_outlet_order_value', width: 80},
      {headerName: 'NEW OUTLETS', field: 'new_outlets', width: 80},
      {headerName: 'NEW OUTLETS SALES', field: 'new_outlets_sales', width: 80},
      {headerName: 'AVG VC (Last 3 months)', field: 'avg_vc_l3m', width: 80},
      {headerName: 'AVG SALES (Last 3 months)', field: 'avg_sales_l3m', width: 80},

      {headerName: 'ACTIVE OUTLETS (Last 3 months)', field: 'active_outlets_l3m', width: 80},
      {headerName: 'SALES (PM)', field: 'beat_sales_pm', width: 80},
      {headerName: 'SALES GROWTH', field: 'sales_growth', width: 80},
      {headerName: 'SALES GROWTH (Last 3 months)', field: 'sales_growth_l3m', width: 80},
      {
        headerName: 'View Details', width: 80,
        valueGetter(params) {
          if (params.data) {
            return 'View';
          }
          else{
            return ''
          }
        }, cellRenderer: this.sharedService.statusCellRenderer
      }
    ];

  }
  private loadUserDetails() {
    const user_id = localStorage.getItem('user_id');
    const start_date = localStorage.getItem('start_date');
    const end_date = localStorage.getItem('end_date');
    this.userData.customFilter['user_id'] = user_id;
    this.userData.customFilter['start_date'] = start_date;
    this.userData.customFilter['end_date'] = end_date;
    this.loadServerData();
  }

  onCellClicked(event) {
    if (event.colDef.headerName === 'View Details' && event.data.beat_id) {
      this.redirectToDetailedReport(event.data.beat_id);
      console.log("Redirecting with Beat - " + event.data.beat_id);
    }
  }
  private redirectToDetailedReport(beat_id) {
    localStorage.setItem('beat_id', beat_id);
    localStorage.setItem('start_date',this.userData.start_date);
    localStorage.setItem('end_date',this.userData.end_date);
    this.router.navigate([this.apiService.redirect_url + '/reports/sale_metric_outletwise_report'], {
      relativeTo: this.route,
      queryParams: {beat_id: beat_id}
    });
  }

}
