import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-outlet-last-visit-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class SaleMetricOutletwiseReportComponent extends ReportBase implements IReport, OnInit {
  showCustomFilter = false;
  showDate = false;
  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    this.sharedService.setHeader('Sale Metric OutletWise Report');
    this.configureGrid();
    this.loadBeatDetails();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_v2_reports/saleMetricOutletWiseReport';

    this.columnDefs = [

      {headerName: 'TEAM', field: 'team', width: 80},
      {headerName: 'EMP CODE', field: 'emp_code', width: 80},
      {headerName: 'SALESMAN', field: 'salesman', width: 80},

      {headerName: 'BEAT', field: 'beat', width: 80},
      {headerName: 'OUTLET', field: 'outlet', width: 80},
      {headerName: 'OUTLET CODE', field: 'outlet_code', width: 80},
      {headerName: 'GST', field: 'gstin_tin', width: 80},
      {headerName: 'MOBILE', field: 'mobile', width: 80},
      {headerName: 'KEY OUTLET', field: 'key_outlet', width: 80},
      {headerName: 'TARGET', field: 'target', width: 80},
      {headerName: 'UNIQUE PC', field: 'unique_pc', width: 80},
      {headerName: 'SALES', field: 'total_sales', width: 80},
      {headerName: 'AVG SALES', field: 'avg_sales', width: 80},
      {headerName: 'LAST VISIT DAYS', field: 'last_visit_days', width: 80},
      {headerName: 'MSS SALES', field: 'mss_sales', width: 80},
      {headerName: 'ZS OUTLET', field: 'zs_outlet', width: 80}
    ];

  }
  private loadBeatDetails() {
    const beat_id = localStorage.getItem('beat_id');
    const start_date = localStorage.getItem('start_date');
    const end_date = localStorage.getItem('end_date');
    this.userData.customFilter['start_date'] = start_date;
    this.userData.customFilter['end_date'] = end_date;
    this.userData.customFilter['beat_id'] = beat_id;
    this.loadServerData();
  }


}
