<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper">
      <div class="col-md-12 pad0" style="height:42px; width: 99%;">
        <div class="centeralign row" style="justify-content: space-between">
          <div class="centeralign" style="margin-right: auto;align-items: center;display: flex;">
            <div *ngIf="customFilter && customFilter.values && customFilter.values.length > 0">
              <mat-tab-group (selectedTabChange)="changeActiveTab($event)"  [(selectedIndex)]="tabIndex">
                <ng-container *ngFor="let tab of customFilter.values;">
                  <mat-tab label="{{tab.itemName}}">
                  </mat-tab>
                </ng-container>
              </mat-tab-group>
            </div>
          </div>
          <div>
            <div *ngIf="showCustomFilter1"
                 style="margin-right: 3px !important;margin-left: 3px !important;display: inline-block;">
              <app-components-customfilterv2 [customFilters]="customFilter1"
                                             (onsetCustomFilter)="setCustomFilter($event)"></app-components-customfilterv2>
            </div>
            <div class="date-picker1" style="display: inline-block;">
              <span class="datepicker-icon"><img src="../../assets/images/date.svg"></span>
              <input [selectMode]="dateSelectMode" [readonly]="true" [(ngModel)]="myDate" [owlDateTimeTrigger]="dt"
                     [owlDateTime]="dt" placeholder="Date">
              <span class="dropdown-icon"><img src="../../assets/images/down_arrow.svg"></span>
              <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
            </div>
            <div style="display: inline-block;">
              <button
                class="btn confirm-blue-btn1"
                style="height: 35px;width:45px;margin-left: 6px;margin-bottom:4px;font-size: 12px !important;padding: 8px 12px !important;border-radius: 5px;"
                (click)="loadServerData()"
              >GO
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 pad0" style="padding:15px;height:70px;background: white; width: 99%;">
        <div class="centeralign row" style="justify-content: space-between">
          <div class="centeralign" style="margin-right: auto;align-items: center;display: flex;">
            <div *ngIf="showCreate" title="Create" style="cursor: pointer;margin-right: 10px;"
                 (click)="openCreateDialog()">
            <span>
              <img src="../../assets/images/create.svg" style="width: 40px;">
                  </span>
            </div>
            <div title="Upload" *ngIf="uploadTask && !showBaseUpload" class="menuIcon" style="margin-left: 20px;">
              <img  data-toggle="modal" data-target="#popupModal" src="./../../assets/images/upload.svg"/>
            </div>
            <div title="Upload" *ngIf="!uploadTask && showBaseUpload" class="menuIcon" style="margin-left: 20px;" (click)="showUploadPopup()">
              <img  style="cursor: pointer;"  src="./../../assets/images/upload.svg"/>
            </div>
            <div [matMenuTriggerFor]="filterdropdown" class="menuIcon" title="Add Filter">
              <span title="Add Filter">
                <img src="../../assets/images/filter.svg">
               </span>
              <mat-menu #filterdropdown="matMenu">
                <app-components-filters [matMenuTriggerFor]="filterdropdown" [showableFilters]="showableFilters"
                                        (onsetFilter)="setFilter($event)"
                                        (onGridSearch)="gridSearch($event)"></app-components-filters>
              </mat-menu>
            </div>
            <div *ngIf="matAuthorizedActionMenuItems && matAuthorizedActionMenuItems.length > 0" [matMenuTriggerFor]="moredropwdown" class="menuIcon" title="Actions">
              <span title="Actions">
                <img src="../../assets/images/actions_dot.svg">
               </span>
              <mat-menu #moredropwdown="matMenu">
                <ng-container *ngFor="let menu of matAuthorizedActionMenuItems">
                  <button mat-menu-item (click)="onMenuItemSelected(menu)">
                    <img *ngIf="menu.type && menu.type === 'upload'" style="margin-right: 10px;" src="../../assets/images/menu_upload.svg">
                    <img *ngIf="menu.type && menu.type === 'approve'" style="margin-right: 10px;" src="../../assets/images/menu_approve.svg">
                    <img *ngIf="menu.type && menu.type === 'reject'" style="margin-right: 10px;" src="../../assets/images/menu_reject.svg">
                    <span style="font-size: 12px; color: #5D5D5D; font-weight: 600;">{{menu.name}}</span>
                  </button>
                </ng-container>
              </mat-menu>
            </div>
            <div class="search1" style="display: inline-block;margin-left: 20px;">
              <span class="search-control"><img src="assets/searchicon.png"/></span>
              <input id="filter-text-box" type="text" placeholder="filter" (input)="quickSearch()"
                     class="search-control-area"
                     placeholder="Search">
            </div>
          </div>
          <div class="menuIcon" title="Download Excel">
            <div (click)="onBtExport()">
              <span>
                 <img src="../../assets/images/download.svg">
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 pad0" style="width: 99%;">
        <div *ngIf="!goClicked" style="text-align: center;margin-top: 80px;">
          <img src="./../../../assets/images/placeholder.png" style="display: block;
                        margin-left: auto;
                        margin-right: auto;
                        width: 25%;"/>
          <p style="display: block; color: #A2ACB9; font-size: 15px;">Kindly select the required filter and hit go to
            view report</p>
        </div>
        <div *ngIf="goClicked">
          <ag-grid-angular id="myGrid2" *ngIf="viewGrid && dataLoaded" style="height: 73vh" class="ag-theme-alpine"
                           [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                           (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                           (cellClicked)="onCellClicked($event)"
                           (filterChanged)="onFilterChanged($event)"
                           (filterModified)="onFilterModified($event)"
                           (rowSelected)="onSelectionChanged($event)"
                           [getRowId]="getRowId"
                           [context]="context">
          </ag-grid-angular>
          <div *ngIf="viewGallery && dataLoaded" class="col-md-12 pad0">
            <app-gallerycontainer [rowData]="rowData"
                                  [filteredData]="filteredData"
                                  [galleryData]="galleryData"
                                  (cardClick)="cardClick($event)">
            </app-gallerycontainer>
          </div>

          <div *ngIf="preloader">
            <div *ngIf="!showProgress">
              <app-loadingstate></app-loadingstate>
            </div>

            <!-- <span>{{progress}}</span>-->
            <div *ngIf="showProgress">
              <div
                style=" text-align: center;display: flex;align-items: center;justify-content: center; margin-top: 180px;">
                <mat-progress-bar style="width: 200px;" [mode]="progressMode"
                                  [value]="progress"></mat-progress-bar>
                <span *ngIf="showProgressBar" style="margin-left: 5px;">{{progress}}%</span>
              </div>
              <span style="margin-left: 5px; text-align:center; display:block !important">{{progressText}}</span>
            </div>
          </div>
          <div *ngIf="emptyState">
            <app-emptystate></app-emptystate>
          </div>
          <app-error-handler
            [errorObject]="errorObject"
            [errorExists]="errorExists">
          </app-error-handler>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="approve-modal modal" id="popupModal" role="dialog">
  <div id="overlay"></div>
  <div id="overlay2"></div>
  <div class="modal-dialog">
    <div class="modal-content">
      <h2 style="padding-left: 25px; padding-top: 15px; padding-bottom: 15px;">Upload</h2>
      <span class="close" data-dismiss="modal" style="top: 18px; right: 21px;">
        <img src="../../assets/close.png">
      </span>
      <div class="modal-body" style="background-color: #F5F7F9; padding: 25px;">
        <div class="approve-modal-heading" style="padding-bottom: 0px;">
          <span *ngIf="uploadTaskwithType" style="color: #2D2D2D; padding-bottom: 10px;">Select an option from the dropdown before proceeding with file upload</span>
          <div *ngIf="uploadTaskwithType" class="formselectwhitebg" style="margin-top: 10px;">
            <select
              style="width: 223px;"
              name="stype"
              [(ngModel)]="selectedUploadType"
              #stype="ngModel">
              <option
                *ngFor="let item of uploadTypeList"
                value="{{ item.id }}"
              > {{ item.itemName }}</option>
            </select>
          </div>
          <div *ngIf="showExcelSample" style="margin-top: 20px;">
            <span style="color: #2D2D2D; padding-bottom: 10px;">Download the file format template for reference</span>
            <button
              style="display: block; width: 120px;margin-top: 10px"
              mat-button
              class="icon-btn-small-rounded icon-btn-blue"
              (click)="downloadSampleExcel()"
            ><i class="fas fa-arrow-to-bottom"></i>
              Download
            </button>

          </div>
        </div>
        <div class="col-md-12 pad0">
          <div class="row">
            <input #fileUp type="file" class="form-control" style="display:none" (change)="uploadedFile($event)"
                   placeholder="Upload file" accept=".xlsx,.csv">
          </div>
          <div style="margin-top: 5px; ">
            <div class="dottedcard" style="background-color: white" (click)="fileUp.click()">
              <div class="row" *ngIf="fileUploaded">
                <img style="height: 56px; padding: 10px;" src="../../assets/excel_icon.png"/>
                <div style="padding-top: 10px;">
                  <span
                    style="font-size:14px; font-weight: 600; display: block; color: #2D2D2D">{{fileUploaded.name}}</span>
                  <span
                    style="font-size:12px; font-weight: 400; display: block; color: #7D7D7D; text-align: start">{{filesize}}
                    Kb</span>
                </div>

              </div>
              <div *ngIf="!fileUploaded">
                <span><i class="fal fa-cloud-upload fa-5x"></i></span>
                <span style="margin-top: 10px;display: block; font-size: 18px;color: #183153; font-weight: 500;">Select a file to Upload</span>
              </div>
            </div>
          </div>
        </div>
        <p style="font-size: medium" [style.color]="uploadCaption[1] == 0 ? 'green' : 'red'">{{uploadCaption[0]}}</p>

      </div>
      <div class="button-row" style="margin: 10px;">
        <div style="float: right">
            <span data-dismiss="modal" style=" vertical-align:bottom;margin-top: 20px; margin-right: 15px;">
           <a class="btn grey-btn">Cancel</a>
        </span>
          <a *ngIf="!uploading" class="btn blue-btn" (click)="uploadexcel()">Upload</a>
        </div>

        <div *ngIf="upload_loader">
          <img style="width:5%;margin-top: 10%;" src="assets/images/loader_icon.gif"/>
        </div>
      </div>
    </div>
  </div>
</div>
