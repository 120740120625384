import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class ProductionOrderReportComponent extends ReportBase implements IReport, OnInit {

  uniqueChannels;

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Production Order Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.state = true;
    this.showableFilters.region = true;
    this.userData['dc_id'] = JSON.parse(localStorage.getItem('user')).partner_id;
    this.API_URL = '/api/pwa_reports/getProductionOrderReport';
    //this.configureGrid();
  }

  /* configureGrid() {

     this.columnDefs = [
       {headerName: 'PO ID', field: 'po_id', width: 100},
       {headerName: 'DATE', field: 'date', width: 100},
       {headerName: 'DC', field: 'dc_name', width: 100},
       /!*{headerName: 'PRODUCT', field: 'prod_name', width: 100},*!/
       {headerName: 'ORDERED', field: 'qty_ordered', width: 100, aggFunc: 'sum'},
       {headerName: 'RECEIVED', field: 'qty_received', width: 100, aggFunc: 'sum'},
       {headerName: 'OLD STOCK', field: 'qty_old_stock', width: 100, aggFunc: 'sum'},
       {headerName: 'ADJUSTED', field: 'qty_adjusted', width: 100, aggFunc: 'sum'},
       {headerName: 'DISPATCHED', field: 'qty_dispatched', width: 100, aggFunc: 'sum'}
     ];
   }*/

  configureGrid() {


    this.columnDefs = [

      {headerName: 'PO ID', field: 'po_id', width: 100},
      {headerName: 'STATUS', field: 'status', width: 100},
      {headerName: 'DATE', field: 'date', width: 100},
      {headerName: 'COUNTRY', field: 'country', width: 100},
      {headerName: 'PLANT', field: 'plant', width: 100},
      {headerName: 'REGION', field: 'region', width: 100},
      {headerName: 'CLUSTER', field: 'cluster', width: 100},
      {headerName: 'DC', field: 'dc_name', width: 100},
      {headerName: 'PCODE', field: 'prod_code', width: 100},
      {headerName: 'PRODUCT', field: 'prod_name', width: 100},
      {
        headerName: 'REQUESTED', field: 'qty_suggested', width: 100, aggFunc: 'sum',
        valueGetter(params) {
          if (params.data) {
            if (!params.data.qty_suggested && !params.data.po_id) {
              return params.data.sug_calc;
            } else {
              return params.data.qty_suggested;
            }
          }
        }
      },
      /* {
         headerName: 'REQUESTED', field: 'qty_ordered', width: 100,
         valueGetter(params) {
           if (params.data) {
             return params.data.qty_ordered + params.data.qty_suggested;
           }
         }
       },*/
      {
        headerName: 'ORDERED', field: 'qty_ordered', width: 100, aggFunc: 'sum', hide: true,
        valueGetter(params) {
          if (params.data) {
            if (!params.data.qty_ordered && !params.data.po_id) {
              return params.data.sug_calc;
            } else {
              return params.data.qty_ordered;
            }
          }
        }
      },
      {
        headerName: 'SUGGESTED', field: 'qty1_suggested',
        children: [
          {headerName: 'TOTAL', field: 'mbq_qty', width: 100, aggFunc: 'sum'},
          {headerName: 'PRE-ORDER', field: 'mbq_pre_qty', width: 100, aggFunc: 'sum'},
          {headerName: 'CLOSING', field: 'mbq_closing', width: 100, aggFunc: 'sum'}
        ]
      },
    ];
    if (this.uniqueChannels && this.uniqueChannels.length > 0) {
      for (const key of this.uniqueChannels) {
        this.columnDefs[8].children.push(
          {headerName: key, field: key, width: 100, aggFunc: 'sum'},
        );
      }
    }
    this.columnDefs.push({headerName: 'RECEIVED', field: 'qty_received', width: 100, aggFunc: 'sum'},
      {
        headerName: 'OLD STOCK', field: 'qty_old_stock', width: 100, aggFunc: 'sum',
        valueGetter(params) {
          if (params.data) {
            if (!params.data.qty_old_stock && !params.data.po_id) {
              return params.data.in_stock;
            } else {
              return params.data.qty_old_stock;
            }
          }
        }
      },
      {headerName: 'ADJUSTED', field: 'qty_adjusted', width: 100, aggFunc: 'sum', hide: true},
      {headerName: 'FINAL REQ', field: 'final_req', width: 100, aggFunc: 'sum'},
      {headerName: 'DISPATCHED', field: 'qty_dispatched', width: 100, aggFunc: 'sum'},
    );

    for (const key of this.uniqueChannels) {
      this.columnDefs.push({headerName: key, field: key, width: 100, aggFunc: 'sum'});
    }

  }

  postProcessData() {
    this.configureData();
  }

  configureData() {
    this.rowData = [];
    this.uniqueChannels = new Set();
    const masterData = this.apiData.master;
    const consolidatedMBQData = this.getDCWiseProductMBQ();
    console.log(consolidatedMBQData);

    for (const item of consolidatedMBQData) {
      const poexistdata = masterData.filter(x => x.dc_id === item.dc_id && x.prod_id === item.prod_id);
      if (poexistdata && poexistdata.length > 0) {
        for (const po of poexistdata) {
          let indrow: any = {};
          indrow = po;
          if (po.prod_id !== null && po.hasOwnProperty('po_config') && po.po_config !== null) {
            if (po.po_config.hasOwnProperty('sug') && po.po_config.sug) {
              indrow.mbq_qty = po.po_config.sug.qty;
              indrow.mbq_pre_qty = po.po_config.sug.pre;
              indrow.mbq_closing = po.po_config.sug.clos;

              if (po.po_config.sug.hasOwnProperty('channel') && po.po_config.sug.channel) {
                for (const key of this.uniqueChannels) {
                  indrow[key] = po.po_config.sug.channel[key];
                }
              }
            } else {
              indrow.mbq_qty = po.po_config.mbqqty;
              indrow.mbq_pre_qty = po.po_config.mbqpre;
              indrow.mbq_closing = po.po_config.mbqclos;
            }
          } else {
            const mbqdata = consolidatedMBQData.find(x => x.dc_id === po.dc_id && x.prod_id === po.prod_id);
            if (mbqdata !== null && mbqdata !== undefined) {
              indrow.prod_id = mbqdata.prod_id;
              indrow.mbq_qty = mbqdata[indrow.dc_id + '_' + indrow.prod_id + '_mbq_qty'];
              indrow.mbq_pre_qty = mbqdata[indrow.dc_id + '_' + indrow.prod_id + '_mbq_pre_qty'];
              indrow.mbq_closing = mbqdata[indrow.dc_id + '_' + indrow.prod_id + '_mbq_closing'];
              indrow.sug_calc = mbqdata[indrow.dc_id + '_' + indrow.prod_id + '_sug_calc'];
              for (const key of this.uniqueChannels) {
                indrow[key] = mbqdata[indrow.dc_id + '_' + indrow.prod_id + '_' + key];
              }
            }
          }
          /* indrow.po_id = poexist.po_id;
           indrow.qty_ordered = poexist.qty_ordered;
           indrow.qty_received = poexist.qty_received;
           indrow.qty_old_stock = poexist.qty_old_stock;*/
          let ordered = indrow.qty_ordered;
          if (ordered === null || ordered === undefined) {
            ordered = 0;
          }
          let received = indrow.qty_received;
          if (received === null || received === undefined) {
            received = 0;
          }
          let old_stock = indrow.qty_old_stock;
          if (old_stock === null || old_stock === undefined) {
            old_stock = 0;
          }
          let suggested = indrow.qty_suggested;
          if (suggested === null || suggested === undefined) {
            suggested = 0;
          }
          let fq = suggested - received - old_stock;
          if (fq < 0) {
            fq = 0;
          }
          indrow.final_req = fq;
          this.rowData.push(indrow);
        }
      } else {
        const indrow: any = {};
        indrow.dc_id = item.dc_id;
        indrow.dc_name = item.dc_name;
        indrow.prod_id = item.prod_id;
        indrow.prod_name = item.prod_name;
        indrow.prod_code = item.prod_code;
        indrow.country = item.country;
        indrow.region = item.region;
        indrow.cluster = item.cluster;
        indrow.plant = item.plant;
        indrow.mbq_qty = item[indrow.dc_id + '_' + indrow.prod_id + '_mbq_qty'];
        indrow.mbq_pre_qty = item[indrow.dc_id + '_' + indrow.prod_id + '_mbq_pre_qty'];
        indrow.mbq_closing = item[indrow.dc_id + '_' + indrow.prod_id + '_mbq_closing'];
        indrow.sug_calc = item[indrow.dc_id + '_' + indrow.prod_id + '_sug_calc'];
        indrow.in_stock = item[indrow.dc_id + '_' + indrow.prod_id + '_in_stock'];
        for (const key of this.uniqueChannels) {
          indrow[key] = item[indrow.dc_id + '_' + indrow.prod_id + '_' + key];
        }
        let ordered = indrow.qty_ordered;
        if (ordered === null || ordered === undefined) {
          ordered = indrow.sug_calc;
        }
        let received = indrow.qty_received;
        if (received === null || received === undefined) {
          received = 0;
        }
        let old_stock = indrow.in_stock;
        if (old_stock === null || old_stock === undefined) {
          old_stock = 0;
        }
        let suggested = indrow.qty_suggested;
        if (suggested === null || suggested === undefined) {
          suggested = indrow.sug_calc;
        }
        let fq = suggested - received - old_stock;
        if (fq < 0) {
          fq = 0;
        }
        indrow.final_req = fq;

        this.rowData.push(indrow);
      }
    }


    /* if (masterData !== null && masterData !== undefined && masterData.length > 0) {
       for (const item of masterData) {
         let indrow: any = {};
         indrow = item;
         if (item.prod_id !== null && item.hasOwnProperty('po_config') && item.po_config !== null) {
           indrow.mbq_qty = item.po_config.mbqqty;
           indrow.mbq_pre_qty = item.po_config.mbqpre;
           indrow.mbq_closing = item.po_config.mbqclos;
         } else {
           const mbqdata = consolidatedMBQData.find(x => x.dc_id === item.dc_id && x.prod_id === item.prod_id);
           if (mbqdata !== null && mbqdata !== undefined) {
             indrow.prod_id = mbqdata.prod_id;
             indrow.mbq_qty = mbqdata[indrow.dc_id + '_' + indrow.prod_id + '_mbq_qty'];
             indrow.mbq_pre_qty = mbqdata[indrow.dc_id + '_' + indrow.prod_id + '_mbq_pre_qty'];
             indrow.mbq_closing = mbqdata[indrow.dc_id + '_' + indrow.prod_id + '_mbq_closing'];
             indrow.sug_calc = mbqdata[indrow.dc_id + '_' + indrow.prod_id + '_sug_calc'];
             for (const key of this.uniqueChannels) {
               indrow[key] = mbqdata[indrow.dc_id + '_' + indrow.prod_id + '_' + key];
             }
           }
         }

         let ordered = item.qty_ordered;
         if (ordered === null || ordered === undefined) {
           ordered = 0;
         }
         let received = item.qty_received;
         if (received === null || received === undefined) {
           received = 0;
         }
         let old_stock = item.qty_old_stock;
         if (old_stock === null || old_stock === undefined) {
           old_stock = 0;
         }
         let fq = ordered - received - old_stock;
         if (fq < 0) {
           fq = 0;
         }
         indrow.final_req = fq;

         this.rowData.push(indrow);
       }
     } else {
       for (const item of consolidatedMBQData) {
         let indrow: any = {};
         indrow.dc_id = item.dc_id;
         indrow.dc_name = item.dc_name;
         indrow.prod_id = item.prod_id;
         indrow.prod_name = item.prod_name;
         indrow.mbq_qty = item[indrow.dc_id + '_' + indrow.prod_id + '_mbq_qty'];
         indrow.mbq_pre_qty = item[indrow.dc_id + '_' + indrow.prod_id + '_mbq_pre_qty'];
         indrow.mbq_closing = item[indrow.dc_id + '_' + indrow.prod_id + '_mbq_closing'];
         indrow.sug_calc = item[indrow.dc_id + '_' + indrow.prod_id + '_sug_calc'];
         indrow.in_stock = item[indrow.dc_id + '_' + indrow.prod_id + '_in_stock'];
         for (const key of this.uniqueChannels) {
           indrow[key] = item[indrow.dc_id + '_' + indrow.prod_id + '_' + key];
         }


         let ordered = indrow.qty_ordered;
         if (ordered === null || ordered === undefined) {
           ordered = indrow.sug_calc;
         }
         let received = indrow.qty_received;
         if (received === null || received === undefined) {
           received = 0;
         }
         let old_stock = indrow.in_stock;
         if (old_stock === null || old_stock === undefined) {
           old_stock = 0;
         }
         let fq = ordered - received - old_stock;
         if (fq < 0) {
           fq = 0;
         }
         indrow.final_req = fq;

         this.rowData.push(indrow);
       }
     }*/

    /* if (masterData !== null && masterData !== undefined && masterData.length > 0) {
       for (const item of masterData) {
         let indrow: any = {};
         indrow = item;
         const mbqdata = this.apiData.mbq.filter(x => x.dc_id === item.dc_id && x.prod_id === item.prod_id);
         indrow.mbq_qty = 0;
         indrow.mbq_pre_qty = 0;
         indrow.mbq_closing = 0;
         if (mbqdata !== null && mbqdata !== undefined && mbqdata.length > 0) {
           for (const mbqitem of mbqdata) {
             indrow.mbq_qty += mbqitem.qty;
             if (mbqitem.hasOwnProperty('config_json') && mbqitem.config_json !== null) {
               if (mbqitem.config_json.hasOwnProperty('preorder')) {
                 indrow.mbq_pre_qty += mbqitem.config_json.preorder.qty;
               }
               if (mbqitem.config_json.hasOwnProperty('expected_closing')) {
                 indrow.mbq_closing += mbqitem.config_json.expected_closing;
               }
               if (mbqitem.config_json.hasOwnProperty('channel_split') && mbqitem.config_json.channel_split !== null
                 && mbqitem.config_json.channel_split !== undefined && mbqitem.config_json.channel_split.length > 0 && mbqitem.config_json.channel_split !== '{}') {
                 for (const channelitem in mbqitem.config_json.channel_split[0]) {
                   this.uniqueChannels.add(channelitem);
                   indrow[channelitem] = indrow[channelitem] ? indrow[channelitem] : 0;
                   indrow[channelitem] += mbqitem.config_json.channel_split[0][channelitem];
                 }
               }
             }
           }
         }
         this.rowData.push(indrow);
       }
     }*/
    console.log(this.rowData);
    this.configureGrid();
  }

  getDCWiseProductMBQ() {
    const consolidatedMBQData: any = [];
    const mbqdata = this.apiData.mbq;
    if (mbqdata !== null && mbqdata !== undefined && mbqdata.length > 0) {
      const uniquedcs = this.unique(mbqdata, ['dc_id', 'prod_id']);
      for (const item of uniquedcs) {
        const indrow: any = {};
        const proddata = mbqdata.filter(x => x.dc_id === item['dc_id'] && x.prod_id === item['prod_id']);
        if (proddata !== null && proddata !== undefined && proddata.length > 0) {
          indrow.dc_id = proddata[0].dc_id;
          indrow.dc_name = proddata[0].dc_name;
          indrow.prod_id = proddata[0].prod_id;
          indrow.prod_name = proddata[0].prod_name;
          indrow.prod_code = proddata[0].prod_code;
          indrow.country = proddata[0].country;
          indrow.region = proddata[0].region;
          indrow.cluster = proddata[0].cluster;
          indrow.plant = proddata[0].plant;
          indrow[indrow.dc_id + '_' + indrow.prod_id + '_in_stock'] = proddata[0].in_stock;
          indrow[indrow.dc_id + '_' + indrow.prod_id + '_mbq_qty'] = 0;
          indrow[indrow.dc_id + '_' + indrow.prod_id + '_mbq_pre_qty'] = 0;
          indrow[indrow.dc_id + '_' + indrow.prod_id + '_mbq_closing'] = 0;
          indrow[indrow.dc_id + '_' + indrow.prod_id + '_sug_calc'] = 0;
          for (const mbqitem of proddata) {
            //indrow = mbqitem;
            let preorder = 0;
            let closing = 0;
            indrow[mbqitem.dc_id + '_' + mbqitem.prod_id + '_mbq_qty'] += mbqitem.qty;
           // indrow[mbqitem.dc_id + '_' + mbqitem.prod_id + '_in_stock'] += mbqitem.in_stock;
            if (mbqitem.hasOwnProperty('config_json') && mbqitem.config_json !== null) {
              if (mbqitem.config_json.hasOwnProperty('preorder')) {
                preorder = mbqitem.config_json.preorder.qty;
                indrow[mbqitem.dc_id + '_' + mbqitem.prod_id + '_mbq_pre_qty'] += mbqitem.config_json.preorder.qty;
              }
              if (mbqitem.config_json.hasOwnProperty('expected_closing')) {
                closing = mbqitem.config_json.expected_closing;
                indrow[mbqitem.dc_id + '_' + mbqitem.prod_id + '_mbq_closing'] += mbqitem.config_json.expected_closing;
              }
              if (mbqitem.config_json.hasOwnProperty('channel_split') && mbqitem.config_json.channel_split !== null
                && mbqitem.config_json.channel_split !== undefined && mbqitem.config_json.channel_split.length > 0 && mbqitem.config_json.channel_split !== '{}') {
                for (const channelitem in mbqitem.config_json.channel_split[0]) {
                  this.uniqueChannels.add(channelitem);
                  indrow[mbqitem.dc_id + '_' + mbqitem.prod_id + '_' + channelitem] = indrow[mbqitem.dc_id + '_' + mbqitem.prod_id + '_' + channelitem] ? indrow[mbqitem.dc_id + '_' + mbqitem.prod_id + '_' + channelitem] : 0;
                  indrow[mbqitem.dc_id + '_' + mbqitem.prod_id + '_' + channelitem] += mbqitem.config_json.channel_split[0][channelitem];
                }
              }
            }
            let sug_calc = 0;
            //sug_calc = mbqitem.qty - preorder - closing;
            sug_calc = mbqitem.qty - closing;
            if (sug_calc < 0) {
              sug_calc = 0;
            }
            indrow[indrow.dc_id + '_' + indrow.prod_id + '_sug_calc'] += sug_calc;
          }
        }
        consolidatedMBQData.push(indrow);
      }
    }

    return consolidatedMBQData;
  }
}
