<div class="login">
  <div class="auth-header">
    <!--<img src="assets/images/sd-logo.png" />-->
  </div>
  <div class="auth-card">
    <div class="col-md-6">
      <div class="auth-logo-sec">
        <img class="auth-client-logo" src={{company_name}} />
      </div>
      <!--<form [formGroup]="angForm" novalidate>-->
      <img *ngIf="enable_loader" style="margin-left: 40%;margin-top: 70px;" src="assets/images/loader_icon.gif"/>
      <div *ngIf="!enable_loader" class="auth-card-form login" id="login" style="margin-top: 50px;">
        <div class="custom-input">
          <label style="color:#17A2B8">https://{{company_key_input}}.salesdiary.in/</label>
          <input
            type="text"
            [(ngModel)]="company_key_input"
            class="form-control"
            placeholder="Company Key"
            (keydown.enter)="login_user()"
          />
        </div>
        <img *ngIf="loader" src="./../../assets/images/loader_icon.gif" style="width:7%;margin-left: 46%;"/>
        <a *ngIf="!loader" (click)="login_user()" class="btn confirm-blue-btn" style="width: 100%;">NEXT</a>
      </div>
    </div>
    <div class="col-md-6">
      <div class="auth-card-img-section">
        <div class="auth-card-img">
          <img src={{company_bg}} />
        </div>
      </div>
    </div>
  </div>
</div>
