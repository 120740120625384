<div class="ui long modal" #boardconfigmodal_tag>
    <div class="header">
        <h2>{{modalheader}}</h2>
    </div>


    <div class="ui top attached tabular menu">

        <a *ngFor="let tab of tabsModel; let i = index" class="item"
           [ngClass]="{'active': tab.displayName == currentTab }"
           attr.data-tab="{{tab.groupId}}" (click)="setCurrentTab(i)">{{tab.displayName}}</a>

    </div>

    <div *ngFor="let tab of tabsModel; let i = index" [ngClass]="{'active': tab.displayName == currentTab }"
         class="ui bottom attached tab segment"
         attr.data-tab="{{tab.groupId}}" [@contentSwitch]="tab.displayName == currentTab ? 'active':'inactive'">


        <div *ngIf="tab.groupId == 'boards'">

            <app-boards-config-tab  (dashboardCreateEvent)="createBoard($event)"
                                   (dashboardEditEvent)="editBoard($event)"
                                   (dashboardDeleteEvent)="deleteBoard($event)"
                                   [dashboardList]="dashboardList"></app-boards-config-tab>
        </div>


        <div *ngIf="tab.groupId == 'options'">

            <app-options-config-tab></app-options-config-tab>

        </div>
    </div>


    <div class="actions">
        <button class="ui button" (click)="hideMessageModal()">Close </button>
    </div>
</div>
