import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StatisticGadgetComponent} from './statistic/statistic-gadget.component';
import {DynamicFormModule} from '../dynamic-form/dynamic-form-module';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {GadgetSharedModule} from './_common/gadget-shared.module';
import {ErrorHandlerModule} from '../../error/error.module';

import {
    MatButtonModule, MatCheckboxModule, MatExpansionModule, MatIconModule, MatInputModule, MatOptionModule,
    MatProgressBarModule, MatSelectModule
} from '@angular/material';
import {FormsModule} from '@angular/forms';
import {DataListModule} from '../datalist/data-list.module';
import {FacetModule} from '../facet/facet.module';
import {TypeAheadInputModule} from '../typeahead-input/typeahead-input.module';
import {BarGraphGadgetComponent} from './bar_graph/bar-graph-gadget.component';
import {PieGraphGadgetComponent} from './pie_graph/pie-graph-gadget.component';
import {TreeGraphGadgetComponent} from './tree_graph/tree-graph-gadget.component';
import {HeatGraphGadgetComponent} from './heat_graph/heat-graph-gadget.component';
import {GuageGraphGadgetComponent} from './gauge_graph/gauge-graph-gadget.component';
import {BarlineGraphGadgetComponent} from './barline_graph/barline-graph-gadget.component';
import {ComboChartComponent, ComboSeriesVerticalComponent} from './combo-chart';
import {DashboardFilterService} from "../services/dashboard-filter.service";
import {StatisticArrayGadgetComponent} from "./statistic-array/statistic-array-gadget.component";





@NgModule({
    imports: [
        CommonModule,
        GadgetSharedModule,
        DynamicFormModule,
        ErrorHandlerModule,
        NgxChartsModule,
        MatButtonModule,
        MatIconModule,
        MatCheckboxModule,
        MatInputModule,
        MatProgressBarModule,
        MatExpansionModule,
        MatOptionModule,
        MatSelectModule,
        FormsModule,
        FacetModule,
        TypeAheadInputModule,
        DataListModule
    ],
    declarations: [
        StatisticArrayGadgetComponent,
        StatisticGadgetComponent,
        BarGraphGadgetComponent,
        PieGraphGadgetComponent,
        TreeGraphGadgetComponent,
        HeatGraphGadgetComponent,
        GuageGraphGadgetComponent,
        ComboChartComponent,
        ComboSeriesVerticalComponent,
        BarlineGraphGadgetComponent
    ],

    providers: [
      DashboardFilterService
    ],

    exports: [
        StatisticArrayGadgetComponent,
        StatisticGadgetComponent,
        BarGraphGadgetComponent,
        PieGraphGadgetComponent,
        TreeGraphGadgetComponent,
        HeatGraphGadgetComponent,
        GuageGraphGadgetComponent,
        ComboChartComponent,
        ComboSeriesVerticalComponent,
        BarlineGraphGadgetComponent
    ]
})
export class GadgetModule {
}

