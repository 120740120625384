<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button class="closebtn" (click)="closeDialog()">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>{{pmeetDetails.name}}</h2>
    </div>
    <div class="body-section w-100">
      <div>
        <div class="col-md-12 pad0" style="background-color: #F3F6FD;padding: 15px;">
          <img [src]="photo" style="height:450px; margin: auto;"/>
        </div>
        <div class="col-md-12 pad0" style="background-color: #F3F6FD;padding: 15px;">
          <div class="col-md-12 pr-30">
          <div class="row">
            <label>Meet ID: </label>
            <span>{{pmeetDetails.id}}</span>
          </div>
          </div>
          <div class="col-md-12 pad0">
            <div class="col-md-6 pr-30">
              <div class="row" style="margin-top: 10px;">
                <label>Type : </label>
                <span>{{pmeetDetails.type}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label>Start Time : </label>
                <span>{{pmeetDetails.start_time}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label>End Time : </label>
                <span>{{pmeetDetails.end_time}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label>Time Spent : </label>
                <span>{{pmeetDetails.time_spent}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label>Status : </label>
                <span>{{pmeetDetails.status}}</span>
              </div>
            </div>
            <div class="col-md-6 pr-30">
              <div class="row" style="margin-top: 10px;">
                <label>Salesman Code : </label>
                <span>{{pmeetDetails.emp_code}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label>Salesman : </label>
                <span>{{pmeetDetails.user}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label>Team : </label>
                <span>{{pmeetDetails.team}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label>Att Count: </label>
                <span>{{pmeetDetails.att_count}}</span>
              </div>
              <div class="row" style="margin-top: 10px;">
                <label>Total Att : </label>
                <span>{{pmeetDetails.total_att_count}}</span>
              </div>
            </div>
            <div class="col-md-12 pr-30">
              <div class="row" style="margin-top: 10px;">
                <label>Address : </label>
                <span>{{pmeetDetails.address}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
