import {ChangeDetectorRef, ComponentFactoryResolver, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ApiService} from '../../_services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {SharedService} from '../../_services/shared_service';
// @ts-ignore
import {IReport} from './ireport';
import {ErrorObject} from '../../error/error-model';
import {ErrorHandler} from '../../error/error-handler';
import {ngxCsv} from 'ngx-csv/ngx-csv';
import {MatDialog} from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';
import {MatMenuTrigger} from '@angular/material';
import {GridOptions, RowNode} from "@ag-grid-enterprise/all-modules";
import * as XLSX from "xlsx-js-style";
import {ToastrService} from "ngx-toastr";
import {CustomConfigService} from "../../_services/CustomConfigService";
import {FavoritesDialog} from "./dialog-favoraite.component";
import {CustomGridToolPanelComponent} from "./CustomGridToolPanel.component";
import {AppComponent} from "../../app.component";

export abstract class ReportBase implements IReport, OnInit {

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild('fileUp') fileUp: ElementRef;
  @ViewChild('myDiv') myDiv: ElementRef<HTMLElement>;
  @ViewChild('interPrint') public interPrint: ElementRef;

  public getRowId;
  apiData: any = [];
  userData = {
    start_date: null,
    end_date: null,
    period_id: 0,
    access_token: null,
    url: null,
    offset: null,
    offsetID: 0,
    flag: true,
    type: '',
    filterData: {
      date: null,
      access_token: null,
      url: null,
      last_date: null,
      offset: null,
      flag: true,
      out_type: -1,
      salesmanID: 0,
      policyID: 0,
      outlet_type: 0,
      travel_type_id: 0,
      class_type: null,
      program_type: 0,
      status: null,
      max_claim: false,
      trax_outlet: false,
      non_trax_outlet: false,
      exclude_trax_audit: false,
      region_id: 0,
      cluster_id: 0,
      team_id: 0,
      territory_id: 0,
      dc_id: 0,
      displayData: [],
      payment_mode: '',
      active: true
    },
    customFilter: {},
    searchableFilter: 0
  };
  showCustomFilter = false;
  showCustomFilter1 = false;
  showMultiFilter = false;
  showSearchableFilter = false;
  showActions = false;
  customFilter: any = {};
  customFilter1: any = {};
  multiFilter: any = {};
  selectedMultiFilter: any = [];
  searchableFilter: any = {};
  periodID: 0;
  showDate = true;
  dateFilterType: any = 'range';
  title = '';
  preloader: any;
  emptyState: any;
  errorObject: ErrorObject;
  errorExists = false;
  dataLoaded: any;

  myDate: any;
  searchValue: any;
  gridOptions: GridOptions;
  styleGrid: any;
  columnDefs = [];
  frameworkComponents: any;
  context: any;

  filterData: any;

  API_URL = '';

  mapData = [];

  rowData = [];
  public gridColumnApi: any;
  public gridApi: any;
  public pinnedParams = [];
  dateSelectMode = 'range';

  showableFilters = {
    max_claim: false,
    policy: false,
    salesman: false,
    status: false,
    class: false,
    beat: false,
    trax_outlet: false,
    non_trax_outlet: false,
    exclude_trax_audit: false,
    region: false,
    cluster: false,
    team: false,
    territory: false,
    outlet_type: false,
    payment_mode: false,
    dc: false,
    state: false,
    active: false
  };
  filterSet = false;
  progress = 0;
  progressText = '';
  showProgress = false;
  showProgressBar = false;
  progressMode = 'indeterminate';
  showProgressCustomText = false;
  progressTotal = 0;
  keyColumn = '';
  progressMultiInstance: any = [];

  uploadTask = false;
  public uploadCaption = ['', 0];
  public fileUploaded: File;
  public storeData: any;
  public worksheet: any;
  public uploading = false;
  public upload_loader = false;
  public upload_enable = false;
  public upload_task_data;
  public order_data = [];
  succcessCount = 0;
  failedCount = 0;
  uploadexcel;
  public orderDetails = {label_config: {}};
  public print_details = [];
  public payment_enable: boolean;
  goClicked = false;

  user_report_config = [];
  role_report_config = [];
  showDownloadJSON = false;
  accountURLS: any = [];
  private user_report_json: unknown;
  multiInstance = false;

  actionMenuItems = [];
  currentYear;
  currentMonth;
  prvsYear;
  public subdomain: string;
  public container = '';
  public mapkey = '';

  viewGrid = true;
  viewGallery = false;
  viewMap = false;

  showViewSelector = false;
  showGalleryview = false;
  showMapView = false;
  selectedGalleryItem;
  galleryViewOnlyImages = false;
  galleryData = {
    pageSize: 50,
    id: 'id',
    image: '',
    name: '',
    field1: '',
    field2: '',
    field3: '',
    status: '',
    statusTypes: [],
    reviewed: '',
    reviewTypes: {
      yes: 'approved',
      no: 'rejected'
    },
    container: '',
    selectionCriteria: []
  };

  MapViewMetaData = {
    name: '',
    latitude: '',
    longitude: '',
    color: '',
  };

  filteredData = [];
  columnState = [];
  pageData = [];
  showApprove = false;
  public showApproveBtn = false;
  public showRejectBTn = false;
  selectedCount = 0;
  allSelect = false;

  showPinnedFooter = false;
  agGridFilter;
  printType = 'Invoice';
  public statusConfig = {
    success: 'success',
    success1: 'success',
    error: 'fail',
    error1: 'fail',
    warning: 'warning',
    warning1: 'warning',
    new: 'new',
    processed: 'processed'
  };

  public label_config = {};
  public math = Math;
  public amount_in_words;
  public tcs = 0;
  public invoice_date: any;
  public print_orders: boolean;
  private a = ['Zero ', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ', 'Thirteen ',
    'Fourteen ', 'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen '];
  private b = ['', '', 'Twenty ', 'Thirty ', 'Forty ', 'Fifty ', 'Sixty ', 'Seventy ', 'Eighty ', 'Ninety '];

  constructor(public apiService: ApiService,
              public router: Router,
              public route: ActivatedRoute,
              public elRef: ElementRef,
              private renderer: Renderer2,
              public datePipe: DatePipe,
              public sharedService: SharedService,
              public dialog: MatDialog,
              public toastr: ToastrService,
              public componentFactoryResolver: ComponentFactoryResolver,
              protected changeDetector: ChangeDetectorRef,
              public domSanitizer: DomSanitizer,
              protected customConfigService: CustomConfigService,
              public appComponent: AppComponent) {

    // this.myDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    const date = new Date();
    if (this.dateSelectMode === 'single') {
      this.myDate = this.datePipe.transform(date, 'yyyy-MM-dd');
      /* this.myDate[0] = this.datePipe.transform(date, 'yyyy-MM-dd');
       this.myDate[1] = this.datePipe.transform(date, 'yyyy-MM-dd');*/
      this.userData.start_date = this.myDate;
      this.userData.end_date = this.myDate;
    } else {
      this.myDate = [];
      this.myDate[0] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
      this.myDate[1] = this.datePipe.transform(date, 'yyyy-MM-dd');
      this.userData.start_date = this.myDate[0];
      this.userData.end_date = this.myDate[1];
    }
    this.mapkey = localStorage.getItem('map_key');

    this.userData.start_date = this.myDate[0];
    this.userData.end_date = this.myDate[1];
    this.userData.offset = -1 * (new Date(this.userData.start_date)).getTimezoneOffset();
    this.userData.access_token = localStorage.getItem('resfreshToken');
    this.userData.url = localStorage.getItem('private_url');
    this.userData.offsetID = 0;
    this.sharedService.setHeader('Reports');
    this.accountURLS.push(
      {
        id: localStorage.getItem('company_key'),
        itemName: localStorage.getItem('company_key'),
        access_token: localStorage.getItem('resfreshToken'),
        url: localStorage.getItem('private_url'),
        checked: false
      }
    );
    const accountData = JSON.parse(localStorage.getItem('account_urls'));
    if (accountData !== null && accountData !== undefined) {
      accountData.forEach(data => {
        this.accountURLS.push({
          id: data.instance,
          itemName: data.instance,
          access_token: data.access_token,
          url: data.url,
          checked: false
        });
      });
    }
    this.gridOptions = {
      context: {componentParent: this},
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      // groupDisplayType: 'groupRows',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      rowGroupPanelShow: 'always',
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      icons: {
        'custom-tools': '<span><i style="padding: 8px" class="fal fa-tools"></i></span>',
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
          {
            id: 'customTools',
            labelDefault: 'Tools',
            labelKey: 'customTools',
            iconKey: 'custom-tools',
            toolPanel: 'CustomToolPanel',
            toolPanelParams: {
              reportname: this.constructor.name
            }
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            if (field === 'url') {
              return `=HYPERLINK("${params.value}")`;
            } else {
              return params.value;
            }
            //return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        }
      }
      /* onColumnVisible: this.onColumnVisible,
       onDragStopped : this.onDragStopped*/
    } as GridOptions;
    this.frameworkComponents = {
      CustomToolPanel: CustomGridToolPanelComponent
    };

    /* this.gridOptions.onColumnVisible = event => {
       if (event.visible) {
         console.log(event.column.colId + ' was made visible');
       } else {
         console.log(event.column.colId  + ' was hidden')
       }
     };*/
    this.getSubdomain();
    this.columnState = [];
    this.sharedService.filterSetEvent
      .subscribe((set: boolean) => {
        if (set) {
          this.filterSet = true;
        } else {
          this.filterSet = false;
        }
      });
  }


  ngOnInit() {
    /*  const t = trace(this.appModule.perf, 'pages');
      t.start();
      t.putAttribute('reports', this.API_URL);*/
    this.dateSelectMode = 'range';

    // this.customConfigService.getReportByName(this.constructor.name).subscribe(rConfig => {
    //   this.report_config = rConfig;
    // });
  }

  public handleError(error: ErrorObject) {
    this.preloader = false;
    this.emptyState = false;
    this.dataLoaded = false;
    this.errorExists = true;
    this.errorObject = error;
  }

  loadServerData() {
    this.goClicked = true;
    this.dataLoaded = false;
    this.preloader = true;
    this.emptyState = false;
    this.errorExists = false;
    this.pageData = [];
    this.selectedCount = 0;
    this.showApprove = false;

    if (this.API_URL === '') {
      return this.handleError(ErrorHandler.getErrorObject('API Not Configured'));
    }
    if (this.showSearchableFilter) {
      if (!this.userData.searchableFilter && this.searchableFilter && this.searchableFilter.values && this.searchableFilter.values.length > 0) {
        this.userData.searchableFilter = this.searchableFilter.values[0][this.searchableFilter['bindValue']];
        this.searchableFilter.value = this.searchableFilter.values[0][this.searchableFilter['bindValue']];
      }
    }
    if (this.showCustomFilter) {
      if (this.userData.customFilter != null && !this.userData.customFilter.hasOwnProperty(this.customFilter.key)) {
        this.userData.customFilter[this.customFilter.key] = this.customFilter.value;
      }
    }
    if (this.showCustomFilter1) {
      if (this.userData.customFilter != null && !this.userData.customFilter.hasOwnProperty(this.customFilter1.key)) {
        this.userData.customFilter[this.customFilter1.key] = this.customFilter1.value;
      }
    }
    if (this.dateSelectMode === 'single') {
      this.userData.start_date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
      this.userData.end_date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    } else if (this.dateFilterType === 'period') {
      const periodData = JSON.parse(localStorage.getItem('jc_months'));

      if (!this.periodID || this.periodID === 0) {
        this.periodID = periodData[0].id;
      } else {
        if (periodData.length > 0) {
          periodData.reverse();
          this.periodID = periodData[0].id;
        }
      }
      const period = periodData.find(p => p.id === this.periodID);
      if (period.length > 0) {
        this.userData.period_id = period.id;
        this.userData.start_date = period.start_date;
        this.userData.end_date = period.end_date;
      }

    } else {
      this.userData.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
      this.userData.end_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    }

    if (this.showProgress === false) {

      //Load Data in one shot
      this.apiService.post(this.API_URL, this.userData)
        .subscribe(res => {
            console.log(res);
            // t.stop();
            if (res.hasOwnProperty('results') && (res.results.status === 200)) {
              this.apiData = res.results.data; //TODO: check if this line is needed
              this.postProcessData();
              //this.rowData should be ready now
              this.preloader = false;
              if (this.rowData === null || this.rowData === undefined) {
                this.emptyState = true;
              } else if (this.rowData.length === 0) {
                this.emptyState = true;
              } else {
                this.dataLoaded = true;
              }
            } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
              this.emptyState = true;
              if (res.hasOwnProperty('results') && res.results.hasOwnProperty('errmsg')) {
                console.error(res.results.errmsg);
              }
              this.handleError(ErrorHandler.getErrorObject(res.results.msg));
            } else {
              this.emptyState = true;
              if (res.hasOwnProperty('results') && res.results.hasOwnProperty('errmsg')) {
                console.error(res.results.errmsg);
              }
              this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
            }
          },
          // tslint:disable-next-line:no-shadowed-variable
          error => this.handleError(ErrorHandler.getErrorObject(error)));
    } else {
      //Load data in batches
      this.loadInBatches(this.userData.offset, this.keyColumn);
      if (this.selectedMultiFilter !== null && this.selectedMultiFilter !== undefined &&
        this.selectedMultiFilter.length > 0 && this.multiInstance) {
        this.fetchAllAccountsData(this.keyColumn);
      } else {
        this.fetch(this.keyColumn);
      }
    }
  }

  loadInBatches(limit, keyColumn) {
    this.showProgress = true;
    this.showProgressBar = true;
    this.progress = 0;
    this.progressText = '';
    this.progressTotal = 0;
    this.userData.offsetID = 0;
    this.userData.offset = limit;
    this.keyColumn = keyColumn;
    this.apiData = [];
  }

  loadInBatchesAll(limit, keyColumn) {
    this.showProgress = true;
    this.showProgressBar = true;
    /* this.progress = 0;
     this.progressText = '';
     this.progressTotal = 0;*/
    this.userData.offsetID = 0;
    this.userData.offset = limit;
    this.keyColumn = keyColumn;
  }

  async fetch(key) {
    const res = await this.apiService.postPromise(this.API_URL, this.userData);
    console.log(res);
    if (res.hasOwnProperty('results') && (res.results.status === 200)) {
      // console.log(res);
      //this.apiData.push(res.results.data);
      for (const item of res.results.data) {
        this.apiData.push(item);
      }
      if (this.apiData === null || this.apiData === undefined) {
        this.postProcessData();
        this.dataLoaded = true;
        this.preloader = false;
        this.emptyState = (this.rowData.length === 0);
      } else if (this.apiData.length === 0) {
        this.postProcessData();
        this.dataLoaded = true;
        this.preloader = false;
        this.emptyState = (this.rowData.length === 0);
      } else {


        if (this.apiData[0].hasOwnProperty('total_prog')) {
          this.progressTotal = this.apiData[0].total_prog;
          this.showProgressBar = true;
          this.progressMode = 'determinate';
        } else {
          this.showProgressBar = false;
          this.progressTotal = 0;
        }
        if (res.results.data.length >= this.userData.offset) {
          this.userData.offsetID = this.apiData[this.apiData.length - 1][key];
          if (this.progressTotal !== 0) {
            this.progress = Math.round((this.apiData.length / this.progressTotal) * 100);
            this.progressText = this.apiData.length + '/' + this.progressTotal;
          } else {
            this.progressText = 'Downloaded ' + this.apiData.length + ' records';
          }

          await this.fetch(key);
        } else {
          this.postProcessData();
          // this.rowData = this.postProcessData(this.apiData);
          this.dataLoaded = true;
          this.preloader = false;
          this.emptyState = (this.rowData.length === 0);
        }
      }
    } else if (res.hasOwnProperty('results') && res.results.status === 203) {
      if (res.hasOwnProperty('results') && res.results.hasOwnProperty('errmsg')) {
        console.error(res.results.errmsg);
      }
      this.handleError(ErrorHandler.getErrorObject(res.results.msg));
    } else {
      if (res.hasOwnProperty('results') && res.results.hasOwnProperty('errmsg')) {
        console.error(res.results.errmsg);
      }
      this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
    }
  }


  removeFilter(displayData: any) {
    this.sharedService.filterCancelCLick(displayData.key);
  }

  setFilter(filterdata) {
    this.userData.filterData.displayData = [...this.userData.filterData.displayData];
    this.userData.filterData = filterdata;
    this.trigger.closeMenu();
    this.loadServerData();
  }

  setSearchableFilter(searchableFilterData) {
    this.userData.searchableFilter = searchableFilterData;
    this.searchableFilter.value = this.searchableFilter.values.find(x => x.id === searchableFilterData);
  }

  setCustomFilter(customfilterdata) {

    for (const [key, value] of Object.entries(customfilterdata)) {
      this.userData.customFilter[key] = value;
    }
  }

  setMultiFilter(multiFilterData) {
    this.selectedMultiFilter = multiFilterData;
  }

  changeDate(dt) {
    if (this.dateSelectMode === 'single') {
      this.userData.start_date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
      this.userData.end_date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    } else {
      this.userData.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
      this.userData.end_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    }
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllColumns().length;

    params.api.setRowData(this.rowData);
    this.preloader = false;
    const inThis = this;

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        if (noOfColumns < 11 && screen.width > 991) {
          params.api.sizeColumnsToFit();
          // if (inThis.pinnedParams.length > 0) {
          //   inThis.sharedService.resetPinned(inThis.gridOptions, inThis.pinnedParams);
          // }
        } else {
          params.columnApi.autoSizeAllColumns();
          //inThis.sharedService.clearPinned(inThis.gridOptions);
        }
      });
    });

    if (noOfColumns < 11 && screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
      // if (inThis.pinnedParams.length > 0) {
      //   inThis.sharedService.resetPinned(inThis.gridOptions, inThis.pinnedParams);
      // }
    } else {
      this.gridColumnApi.autoSizeAllColumns();
      //inThis.sharedService.clearPinned(inThis.gridOptions);
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');

    if (this.showPinnedFooter) {
      setTimeout(() => {
        const pinnedBottomData = this.sharedService.generatePinnedBottomData(this.gridApi, this.gridColumnApi);
        this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
      }, 500);
    }

    // this.get_favorites();
    if (this.sharedService.currListData && this.sharedService.currListData.url === this.router.url) {
      this.agGridFilter = this.sharedService.currListData.agGridFilter;
      this.gridApi.setFilterModel(this.agGridFilter);
    }
    if (this.columnState) {
      this.gridColumnApi.applyColumnState(
        {
          state: this.columnState,
          applyOrder: true,
        }
      );
      this.quickSearch();
    }
  }

  get_favorites() {
    this.user_report_config = [];
    this.customConfigService.getReportByName(this.constructor.name).subscribe(rConfig => {
      if (rConfig !== undefined && rConfig.hasOwnProperty('config') && rConfig['config'].length > 0) {
        this.user_report_json = rConfig;
        this.user_report_config = rConfig['config'];
        let columnState = [];
        let default_config = {};
        if (this.user_report_json.hasOwnProperty('default')) {
          default_config = this.user_report_json['config'].filter(item => {
            return (item.name === this.user_report_json['default']);
          });
          columnState = default_config[0]['columnState'];
        }
        // TODO: Load default from role Config
        // if (rConfig !== undefined && rConfig.hasOwnProperty('role_config') && rConfig['role_config'].length > 0) {
        //   this.role_report_config = rConfig['role_config'];
        //   if (Object.keys(columnState).length === 0 && this.role_report_config.length > 0 && this.role_report_config['default']) {
        //     default_config = this.role_report_config['config'].filter(item => {
        //       return (item.name === this.role_report_config['default']);
        //     });
        //     columnState = default_config['columnState'];
        //   }
        // }

        // if (Object.keys(columnState).length) {
        //   this.gridColumnApi.applyColumnState(
        //     {
        //       state: columnState,
        //       applyOrder: true,
        //     }
        //   );
        // }
      }
    });

  }

  quickSearch() {
    const searchtext = document.getElementById('filter-text-box')['value'];
    if (this.gridOptions['api']) {
      this.gridApi.setQuickFilter(searchtext);
      //this.filteredData = this.gridApi.rowModel.rowsToDisplay;
    }
    if (this.rowData) {
      this.filteredData = this.rowData.filter(x =>
        (x[this.galleryData['name']] && x[this.galleryData['name']].toLocaleLowerCase().includes(searchtext.toLocaleLowerCase())) ||
        (x[this.galleryData['field1']] && x[this.galleryData['field1'].toLocaleLowerCase()].includes(searchtext.toLocaleLowerCase())) ||
        (x[this.galleryData['field2']] && x[this.galleryData['field2'].toLocaleLowerCase()].includes(searchtext.toLocaleLowerCase())) ||
        (x[this.galleryData['field3']] && x[this.galleryData['field3'].toLocaleLowerCase()].includes(searchtext.toLocaleLowerCase())) ||
        (x[this.galleryData['status']] && x[this.galleryData['status'].toLocaleLowerCase()].includes(searchtext.toLocaleLowerCase())));
    }

    if (this.showPinnedFooter) {
      setTimeout(() => {
        const pinnedBottomData = this.sharedService.generatePinnedBottomData(this.gridApi, this.gridColumnApi);
        this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
      }, 500);
    }
  }

  setPeriod(selectedPeriod: any) {
    this.userData.start_date = selectedPeriod.start_date;
    this.userData.end_date = selectedPeriod.end_date;
    this.periodID = selectedPeriod.id;
    this.userData.period_id = this.periodID;

  }

  onBtExport() {
    const header = this.sharedService.headerTitleSource.getValue();
    const params = {
      fontSize: undefined,
      rowHeight: undefined,
      headerRowHeight: undefined,
      fileName: header + '.csv'
    };
    const filteredRowData = this.gridApi.rowModel.rowsToDisplay;
    if (filteredRowData.length > 50000) {
      this.gridApi.exportDataAsCsv(params);
    } else {
      this.gridApi.exportDataAsExcel(params);
    }
  }

  onBtnClick(gridOption, title) {
    const exportData = [];
    const headers = [];
    const fields = [];
    gridOption.columnDefs.forEach(header => {
      if (header.hasOwnProperty('children')) {
        header.children.forEach(each_child => {
          if (each_child.hide === undefined || each_child.hide === false) {
            headers.push(each_child.headerName);
            fields.push(each_child.field);
          }
        });
      } else {
        if (header.hide === undefined || header.hide === false) {
          headers.push(header.headerName);
          fields.push(header.field);
        }
      }
    });

    let passObject = {};

    gridOption.api.forEachNode(node => {
      passObject = {};
      fields.forEach(key => {
        if ((node.data[key] != null) && (typeof (node.data[key]) === 'object')) {
          passObject[key] = node.data[key][Object.keys(node.data[key])[0]];
        } else {
          passObject[key] = node.data[key];
        }
      });
      exportData.push(passObject);
    });

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'report',
      useBom: true,
      noDownload: false,
      headers
    };

    // tslint:disable-next-line:no-unused-expression
    new ngxCsv(exportData, 'report', options);
  }


  gridSearch(searchText) {
    this.gridApi.setQuickFilter(searchText);
  }

  datechanged(dt) {

  }

  uploadedFile(event) {

    this.uploadCaption = ['', 0];
    this.fileUploaded = event.target.files[0];
    this.readExcel();
  }

  readExcel() {
    const readFile = new FileReader();
    readFile.onload = (e) => {
      this.storeData = readFile.result;
      const data = new Uint8Array(this.storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, {type: 'binary'});
      const first_sheet_name = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[first_sheet_name];

      this.upload_task_data = XLSX.utils.sheet_to_json(this.worksheet, {raw: true});
    };
    readFile.readAsArrayBuffer(this.fileUploaded);
  }

  ExcelDateToJSDate(serial) {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    const date_info = new Date(utc_value * 1000);

    const fractional_day = serial - Math.floor(serial) + 0.0000001;

    let total_seconds = Math.floor(86400 * fractional_day);

    const seconds = total_seconds % 60;

    total_seconds -= seconds;

    const hours = Math.floor(total_seconds / (60 * 60));
    const minutes = Math.floor(total_seconds / 60) % 60;

    // return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
    return (date_info.getMonth() + 1) + '/' + date_info.getDate() + '/' + date_info.getFullYear();
  }

  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  progressUpdate(prg) {
    this.progress = prg;
  }

  onFilterChanged(event) {
    this.agGridFilter = this.gridApi.getFilterModel();
    this.sharedService.currListData = {
      url: this.router.url,
      userdata: this.userData,
      searchText: document.getElementById('filter-text-box')['value'],
      agGridFilter: this.agGridFilter
    };
    if (this.showPinnedFooter) {
      setTimeout(() => {
        const pinnedBottomData = this.sharedService.generatePinnedBottomData(this.gridApi, this.gridColumnApi);
        this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
      }, 500);
    }
  }

  onFilterModified(event) {
    this.agGridFilter = this.gridApi.getFilterModel();
    this.sharedService.currListData = {
      url: this.router.url,
      userdata: this.userData,
      searchText: document.getElementById('filter-text-box')['value'],
      agGridFilter: this.agGridFilter
    };
    if (this.showPinnedFooter) {
      setTimeout(() => {
        const pinnedBottomData = this.sharedService.generatePinnedBottomData(this.gridApi, this.gridColumnApi);
        this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
      }, 500);
    }
  }

  onCellClicked(event) {
  }

  onSaveProfileClicked() {
    let fav_name = '';
    if (this.user_report_config) {
      fav_name = 'Favorites ' + parseInt(String(this.user_report_config.length + 1), 10);
    }
    const config = {
      name: fav_name,
      default: true
    };


    const dialogRef = this.dialog.open(FavoritesDialog, {
      width: '300px',
      data: {favoritesData: config}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined && result.hasOwnProperty('name')) {
        const colState = JSON.stringify(this.gridColumnApi.getColumnState());
        result['columnState'] = JSON.parse(colState);
        this.customConfigService.addReportConfig(this.constructor.name, result).subscribe(rConfig => {
          this.user_report_config = rConfig['config'];
        });
      }
    });
  }

  delete_state(name) {
    this.customConfigService.deleteReportConfig(this.constructor.name, name).subscribe(rConfig => {
      if (rConfig.hasOwnProperty('config') && rConfig['config'].length > 0) {
        this.user_report_config = rConfig['config'];
      }
    });
  }


  load_favorites(name) {
    this.user_report_config.forEach(fav => {
      if (name === fav.name) {
        if (Object.keys(fav.columnState).length) {
          this.gridColumnApi.applyColumnState(
            {
              state: fav.columnState,
              applyOrder: true,
            }
          );
        }
      }
    });
  }


  load_role_favorites(name) {
    this.role_report_config.forEach(fav => {
      if (name === fav.name) {
        if (Object.keys(fav.columnState).length) {
          this.gridColumnApi.applyColumnState(
            {
              state: fav.columnState,
              applyOrder: true,
            }
          );
        }
      }
    });
  }

  postProcessData() {
    this.rowData = this.apiData;
    this.filteredData = [];
    if (!this.galleryViewOnlyImages) {
      this.filteredData = this.rowData;
    } else {
      this.rowData.forEach(row => {
        if (row[this.galleryData.image] && row[this.galleryData.image] !== "") {
          this.filteredData.push(row);
        }
      });
    }
    return;
  }

  downloadJSON() {

  }

  viewSelector(selectedview) {
    if (this.gridOptions.columnApi) {
      this.columnState = this.gridColumnApi.getColumnState();
      this.gridColumnApi.setRowGroupColumns([]);
    }
    if (selectedview === 'gallery') {
      if (this.gridOptions.api) {
        const filter = this.gridApi.rowModel.rowsToDisplay;
        this.filteredData = [];
        if (!this.galleryViewOnlyImages) {
          for (const key in filter) {
            if (filter[key].hasOwnProperty('data')) {
              this.filteredData.push(filter[key].data);
            }
          }
        } else {
          for (const key in filter) {
            if (filter[key].hasOwnProperty('data') && filter[key].data[this.galleryData.image] && filter[key].data[this.galleryData.image] !== "") {
              this.filteredData.push(filter[key].data);
            }
          }
        }
      } else {
        this.showApprove = false;
        this.filteredData = this.rowData;
      }
      this.viewGallery = true;


      this.viewGrid = false;
      this.viewMap = false;

    } else if (selectedview === 'map') {

      this.viewMap = true;

      let lat = 13.001897683461342;
      const lng = 77.63579374636537;
      let color = 'amber';
      if (this.gridOptions.api) {
        const filter = this.gridApi.rowModel.rowsToDisplay;
        this.mapData = [];
        filter.forEach(row => {

          lat = lat + 0.01;
          if (row.hasOwnProperty('data')) {
            if (color === 'amber') {
              color = 'teel';
            } else {
              color = 'amber';
            }
            // this.mapData.push({
            //   lat: lat,
            //   lng: lng,
            //   name: filter[key].data[this.MapViewMetaData.name],
            //   color: filter[key].data[this.MapViewMetaData.color]
            // });

            if (row.data[this.MapViewMetaData.latitude] && !isNaN(row.data[this.MapViewMetaData.latitude])) {
              if (row.data[this.MapViewMetaData.longitude] && !isNaN(row.data[this.MapViewMetaData.longitude])) {
                this.mapData.push({
                  lat: parseFloat(row.data[this.MapViewMetaData.latitude]),
                  lng: parseFloat(row.data[this.MapViewMetaData.longitude]),
                  name: row.data[this.MapViewMetaData.name],
                  color: row.data[this.MapViewMetaData.color]
                });
              }
            }
          }
        });
      }

      this.viewGrid = false;
      this.viewGallery = false;

    } else {

      this.viewGrid = true;

      this.viewGallery = false;
      this.viewMap = false;
      if (this.showGalleryview) {
        this.showApprove = false;
      }
    }
  }

  cardClick(clickObject) {
    if (clickObject.hasOwnProperty('item') && clickObject.item && clickObject.item.click === 'double') {
      this.selectedGalleryItem = clickObject.item;
      this.showDetailPopup();
    } else {
      this.pageData = clickObject.data;
      const itemSelected = this.pageData.filter(x => x['item_selected'] === true);
      if (itemSelected && itemSelected.length > 0) {
        this.showApprove = true;
        this.selectedCount = itemSelected.length;
      } else {
        this.showApprove = false;
        this.selectedCount = 0;
      }
    }
  }

  checkIfSelectAllowed(selectedItem, selectionCriteria) {
    if (selectionCriteria.matches) {
      if (!selectedItem[selectionCriteria.field]) {
        return true;
      } else if (selectedItem[selectionCriteria.field].includes(selectionCriteria.values)) {
        return true;
      } else {
        return false;
      }
    } else {
      if (!selectedItem[selectionCriteria.field].includes(selectionCriteria.values)) {
        return true;
      } else {
        return false;
      }
    }
  }

  checkSelectionCriteria(selectedItem) {
    if (this.galleryData.selectionCriteria) {
      let matches = true;
      for (const criteria of this.galleryData.selectionCriteria) {
        if (!this.checkIfSelectAllowed(selectedItem, criteria)) {
          matches = false;
          return false;
        }
      }
      return matches;
    } else {
      return true;
    }
  }

  selectAll() {
    let item_selected = false;
    let selectedCount = 0;
    const currentIndex = parseInt(localStorage.getItem('currentgalleryIndex'), 10);
    const currentPage = parseInt(localStorage.getItem('currentgalleryPage'), 10);

    let range = ((currentPage * this.galleryData.pageSize) + 1);
    if ((currentIndex + this.galleryData.pageSize) > this.filteredData.length) {
      range = this.filteredData.length + 1;
    }

    if (this.allSelect) {
      item_selected = true;
    } else {
      item_selected = false;
    }
    this.pageData = [];
    for (let i = currentIndex; i < range; i++) {
      this.pageData.push(this.filteredData[i - 1]);
      if (item_selected) {
        if (this.checkSelectionCriteria(this.filteredData[i - 1])) {
          selectedCount += 1;
          this.filteredData[i - 1]['item_selected'] = true;
        } else {
          this.filteredData[i - 1]['item_selected'] = false;
        }
      } else {
        if (item_selected) {
          selectedCount += 1;
        }
        this.filteredData[i - 1]['item_selected'] = item_selected;
      }
    }
    if (this.allSelect) {
      this.selectedCount = selectedCount;
    } else {
      this.selectedCount = 0;
    }

    if (this.selectedCount > 0) {
      this.showApprove = true;
    } else {
      this.showApprove = false;
    }
    const clickObject = {
      item: "all",
      data: this.pageData
    };
    this.cardClick(clickObject);
  }

  showDetailPopup() {
  }

  performAction(action) {

  }

  openConfirmationDialog(state: string) {

  }

  async fetchAllAccountsData(key) {
    this.progressMultiInstance = [];
    this.loadInBatches(this.userData.offset, this.keyColumn);
    for (let i = 0; i <= this.selectedMultiFilter.length - 1; i++) {
      const indItem: any = {};
      indItem.instance = this.selectedMultiFilter[i].itemName;
      indItem.downloaded = 0;
      indItem.total = 0;
      this.progressMultiInstance.push(indItem);
    }
    for (let i = 0; i <= this.selectedMultiFilter.length - 1; i++) {
      this.loadInBatchesAll(this.userData.offset, this.keyColumn);
      this.userData.access_token = this.selectedMultiFilter[i].access_token;
      this.userData.url = this.selectedMultiFilter[i].url;
      //this.userData.url = 'http://localhost:3000';
      await this.fetchR(i, key);
    }
    this.postProcessData();
    this.dataLoaded = true;
    this.preloader = false;
    this.emptyState = (this.rowData.length === 0);
  }

  async fetchR(i, key) {
    const res = await this.apiService.postPromisewithURL(this.API_URL, this.userData);
    if (res.hasOwnProperty('results') && (res.results.status === 200)) {
      for (const item of res.results.data) {
        item['instance'] = this.selectedMultiFilter[i].itemName;
        this.apiData.push(item);
      }
      console.log(this.apiData);
      if (res.results.data !== null && res.results.data.length > 0) {
        for (const j in this.progressMultiInstance) {
          if (this.progressMultiInstance[j].instance === this.selectedMultiFilter[i].itemName) {
            if (this.progressMultiInstance[j].downloaded === 0 && this.apiData[0].hasOwnProperty('total_prog')) {
              this.progressMultiInstance[j].total = res.results.data[0].total_prog;
            }
            this.progressMultiInstance[j].downloaded += res.results.data.length;
            if (this.apiData[0].hasOwnProperty('total_prog')) {
              this.progressMode = 'determinate';
              let startingprog = 0;
              if (i === 0) {
                startingprog = 0;
              } else {
                startingprog = ((i) / (this.selectedMultiFilter.length)) * 100;
              }
              this.progress = startingprog + Math.round((this.progressMultiInstance[j].downloaded / this.progressMultiInstance[j].total) * (100 / this.selectedMultiFilter.length));
            } else {
              this.progressMode = 'determinate';
              this.progress = Math.round(100 / this.selectedMultiFilter.length);
            }
          }
        }
      }
      if (this.apiData === null || this.apiData === undefined || this.apiData.length === 0) {
        return;
      } else {
        if (res.results.data.length === this.userData.offset) {
          this.userData.offsetID = this.apiData[this.apiData.length - 1][key];
          this.progressText = 'Downloaded ' + this.apiData.length + ' records';
          await this.fetchR(i, key);
        }
      }
    } else {
      this.emptyState = true;
      if (res.hasOwnProperty('results') && res.results.hasOwnProperty('errmsg')) {
        console.error(res.results.errmsg);
      }
      this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
    }
  }

  unique(arr, keyProps) {
    const kvArray = arr.map(entry => {
      const key = keyProps.map(k => entry[k]).join('|');
      return [key, entry];
    });
    const map = new Map(kvArray);
    return Array.from(map.values());
  }

  avgAggFunction(params) {
    let avg;
// the average will be the sum / count
    let sum = 0;
    let count = 0;

    params.values.forEach(value => {
      const groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        // we are aggregating groups, so we take the
        // aggregated values to calculated a weighted average
        sum += value.avg * value.count;
        count += value.count;
      } else {
        // skip values that are not numbers (ie skip empty values)
        if (typeof value === 'number') {
          sum += value;
          count++;
        }
      }
    });
    // avoid divide by zero error
    if (count !== 0) {
      avg = sum / count;
    } else {
      avg = null;
    }

    avg = Math.round(Math.round((avg + Number.EPSILON) * 100) / 100);

    // the result will be an object. when this cell is rendered, only the avg is shown.
    // however when this cell is part of another aggregation, the count is also needed
    // to create a weighted average for the next level.
    const result = {
      count,
      avg,
      // the grid by default uses toString to render values for an object, so this
      // is a trick to get the default cellRenderer to display the avg value
      toString() {
        return this.avg;
      },
    };

    return result;
  }

  select(pText: string) {
    // this.selectedAction = pText;
    console.log(pText);
  }

  dateCheckandUpdate() {
    const date = new Date();
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    const fiscalYear = currentMonth < 4 ? currentYear - 1 : currentYear;
    const adjustedMonth = (currentMonth + 8) % 12 + 1;
    const currentQuarterIndex = Math.floor((adjustedMonth - 1) / 3);
    const quarters = [
      { start: '04-01', end: '06-30' }, // Q1
      { start: '07-01', end: '09-30' }, // Q2
      { start: '10-01', end: '12-31' }, // Q3
      { start: '01-01', end: '03-31' }  // Q4
    ];
    const lastQuarterIndex = (currentQuarterIndex + 3) % 4;
    const lastQuarterYear = lastQuarterIndex === 3 && currentQuarterIndex === 0 ? fiscalYear - 1 : fiscalYear;
    let endDate;
    const isFirstHalf = currentMonth >= 4 && currentMonth <= 9;
    switch (this.customFilter.value) {
      case 'currentSixMonths':
        if (isFirstHalf) {
          //First half: April to September of current fiscal year
          this.myDate[0] = `${fiscalYear}-04-01`;
          this.myDate[1] = `${fiscalYear}-09-30`;
        } else {
          //Second half: October to March of next fiscal year
          this.myDate[0] = `${fiscalYear}-10-01`;
          this.myDate[1] = `${fiscalYear + 1}-03-31`;
        }
        break;

      case 'lastSixMonths':
        if (isFirstHalf) {
          // Last six months before the first half: October to March of previous fiscal year
          this.myDate[0] = `${fiscalYear - 1}-10-01`;
          this.myDate[1] = `${fiscalYear}-03-31`;
        } else {
          // Last six months before the second half: April to September of same fiscal year
          this.myDate[0] = `${fiscalYear}-04-01`;
          this.myDate[1] = `${fiscalYear}-09-30`;
        }
        break;

      case 'currentMonth':
        this.myDate[0] = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-01`;
        endDate = new Date(currentYear, currentMonth, 0);
        endDate.setDate(endDate.getDate() + 1);
        this.myDate[1] = endDate.toISOString().slice(0, 10);
        break;

      case 'lastMonth':
        const lastMonth = currentMonth - 1;
        const lastMonthYear = lastMonth === 0 ? currentYear - 1 : currentYear;
        const lastMonthAdjusted = lastMonth === 0 ? 12 : lastMonth;
        this.myDate[0] = `${lastMonthYear}-${lastMonthAdjusted.toString().padStart(2, '0')}-01`;
        endDate = new Date(lastMonthYear, lastMonthAdjusted, 0);
        endDate.setDate(endDate.getDate() + 1);
        this.myDate[1] = endDate.toISOString().slice(0, 10);
        break;

      case 'currentQuarter':
        this.myDate[0] = `${fiscalYear}-${quarters[currentQuarterIndex].start}`;
        this.myDate[1] = `${fiscalYear}-${quarters[currentQuarterIndex].end}`;
        break;

      case 'lastQuarter':
        this.myDate[0] = `${lastQuarterYear}-${quarters[lastQuarterIndex].start}`;
        this.myDate[1] = `${lastQuarterYear}-${quarters[lastQuarterIndex].end}`;
        break;

      case this.currentYear.toString():
        if (this.currentMonth < 4) {
          this.myDate[0] = this.prvsYear + '-04-01';
        } else {
          this.myDate[0] = this.currentYear + '-04-01';
        }
        this.myDate[1] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
        break;

      default:
        if (this.currentMonth < 4) {
          this.myDate[0] = (this.prvsYear - 1) + '-04-01';
          this.myDate[1] = this.prvsYear + '-03-31';
        } else {
          this.myDate[0] = this.prvsYear + '-04-01';
          this.myDate[1] = this.currentYear + '-03-31';
        }
        break;
    }
    this.userData.start_date = this.myDate[0];
    this.userData.end_date = this.myDate[1];
  }

  getSubdomain() {
    const domain = window.location.hostname;
    this.subdomain = domain.split('.')[0];
  }

  generateImageUrl(field: string) {
    return (params) => {
      if (params.data) {
        if (!params.data[field] || params.data[field] === "") {
          return '';
        } else {
          const url = 'https://' + this.subdomain + '.salesdiary.in/imagerender?url=' + this.apiService.api_url +
            '&sf=' + params.data[field] + '&cont=' + this.container;
          return url;
        }
      } else {
        return '';
      }
    };
  }

  generateLocationUrl(lat, long) {
    return (params) => {
      if (params.data) {
        if (params.data[lat] !== null && params.data[long] !== null) {
          return 'http://maps.google.com/maps?q=' + params.data[lat] + ',' + params.data[long];
        } else {
          return '';
        }
      } else {
        return '';
      }
    };
  }

  locationIconRenderer(lat, long) {
    return (params) => {
      if (params.data) {
        if (params.data[lat] !== null && params.data[long] !== null) {
          return `<div style="text-align: center;">
            <span><img src="../../../assets/location_set.svg"></span>
            </div>`;
        } else {
          return `<div style="text-align: center;">
            <span><img src="../../../assets/location_not_set.svg"></span>
            </div>`;
        }
      } else {
        return '';
      }
    };
  }

  formatNumber(number) {
    if (number) {
      number = parseFloat(number).toFixed(this.apiService.decimalPolicy);
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return '';
    }
  }

  isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  activeCellRenderer(params) {
    let displayElem = '';
    if (params.value === true || params.value === 'Active') {
      displayElem = '<span class="ml-1 status" style="background-color:#E1F0E7; border:#E1F0E7; color: #379862" >' + params.value + '</span>';
    } else {
      displayElem = '<span class="ml-1 status" style="background-color:#FBE3E7; border:#FBE3E7; color: #E24260">' + params.value + '</span>';
    }
    return displayElem;
  }

  compute_totals(keys) {
    const total_arr = {};
    for (const key of keys) {
      total_arr[key] = 0;
    }
    this.rowData.forEach(line => {
      for (const key of keys) {
        if (line[key]) {
          if (line[key] % 1 !== 0) {
            total_arr[key] += parseFloat(line[key]);
          } else {
            total_arr[key] += parseInt(line[key], 10);
          }
        }
      }
    });
  }
  inWords(num, inthis) {
    if ((num = num.toString()).length > 9) {
      return 'overflow';
    }
    const n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) {
      return;
    }
    let str = '';

    str += (n[1] !== '00') ? (inthis.a[Number(n[1])] || inthis.b[n[1][0]] + ' ' + inthis.a[n[1][1]]) + 'Crore ' : '';
    str += (n[2] !== '00') ? (inthis.a[Number(n[2])] || inthis.b[n[2][0]] + ' ' + inthis.a[n[2][1]]) + 'Lakh ' : '';
    str += (n[3] !== '00') ? (inthis.a[Number(n[3])] || inthis.b[n[3][0]] + ' ' + inthis.a[n[3][1]]) + 'Thousand ' : '';
    str += (n[4] !== '0') ? (inthis.a[Number(n[4])] || inthis.b[n[4][0]] + ' ' + inthis.a[n[4][1]]) + 'Hundred ' : '';
    if (inthis.a[Number(n[5])]) {
      str += inthis.a[Number(n[5])];
    } else {
      if (inthis.a[n[5][1]] !== 'Zero ') {
        str += inthis.b[n[5][0]] + ' ' + inthis.a[n[5][1]];
      } else {
        str += inthis.b[n[5][0]];
      }
    }
    return str;
  }

  generatePrintData(column, rows, field) {
    this.print_orders = false;
    let total_qty = 0;
    let total_discount = 0;
    const order_ids = [];
    rows.forEach(row => {
      order_ids.push(row[field]);
    });
    const orderData = {
      access_token: this.userData.access_token,
      order_id: order_ids
    };

    const res_data = this.apiService.getOrderDetail(orderData);
    res_data.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
          this.orderDetails = res['results'].data[0];
          this.orderDetails['tax_display'] = false;
          this.orderDetails['new_tax'] = 0;
          const tax_breaks = [];
          const taxnotes = [];
          const inthis = this;
          this.orderDetails['label_config'] = {};
          const labels = this.orderDetails['labels'];
          if (labels) {
            labels.forEach(lab => {
              inthis.orderDetails['label_config'][lab.label] = lab.display;
            });
          } else {
            inthis.orderDetails['label_config']['PrimaryCurrency'] = '';
            inthis.orderDetails['label_config']['SecondaryCurrency'] = '';
            inthis.orderDetails['label_config']['SupplierLabel'] = 'Supplier';
            inthis.orderDetails['label_config']['TaxLabel'] = 'GST';
          }
          this.orderDetails['tax_breakup'].forEach(each_tax => {
            if (each_tax.name !== 'CGST' && each_tax.name !== 'SGST' && each_tax.name !== 'IGST' &&
              each_tax.name !== 'VAT' && each_tax.name !== 'CESS' && each_tax.name !== 'SCESS' && each_tax.name !== 'GST') {
              inthis.orderDetails['tax_display'] = true;
              inthis.orderDetails['new_tax'] += parseFloat(each_tax.tax);
              tax_breaks.push(each_tax);
              taxnotes.push(each_tax.name);
            } else {
              tax_breaks.push(each_tax);
              taxnotes.push(each_tax.name);
            }
          });
          this.orderDetails['tax_breakup'] = tax_breaks;
          if (this.orderDetails['logo']) {
            this.orderDetails['logo'] = this.domSanitizer.bypassSecurityTrustUrl("data:image/png;base64, " + this.orderDetails['logo']);
          }
          if (this.orderDetails['partner_id'] === this.orderDetails['partner_shipping_id']) {
            this.orderDetails['customer_skip'] = true;
          } else {
            this.orderDetails['customer_skip'] = false;
          }
          this.orderDetails['tcs'] = parseFloat(this.orderDetails['tcs']).toFixed(5);
          if (this.orderDetails['order_type'] === 'return') {
            this.amount_in_words = this.inWords(0, this);
          } else {
            const amount = this.orderDetails['amount_pay'].split('.');
            if (amount.length > 1 && parseInt(amount[1], 10) > 0) {
              this.amount_in_words = this.inWords(Math.abs(parseInt(amount[0], 10)), this);
              this.amount_in_words = this.orderDetails['label_config']['PrimaryCurrency'] + ' ' +
                this.amount_in_words + ' And ' + this.orderDetails['label_config']['SecondaryCurrency'] +
                ' ' + this.inWords(Math.abs(parseInt(amount[1], 10)), this) + ' Only';
            } else {
              this.amount_in_words = this.orderDetails['label_config']['PrimaryCurrency'] + ' ' + this.inWords(Math.abs(parseInt(amount[0], 10)), this) + ' Only';
            }
          }
          if (this.orderDetails['date_delivered']) {
            this.invoice_date = this.datePipe.transform((new Date(this.orderDetails['date_delivered'] + ' UTC')).toString(), 'dd/MM/yyyy');
          }
          const d_status = this.orderDetails['d_status'];
          this.orderDetails['sale_items'].forEach(sale_item => {
            sale_item['label'] = '[' + sale_item.type + ']';
            const breaks = [];
            sale_item['new_tax'] = 0;
            sale_item['tax_notes'].forEach(each_tax => {
              if (each_tax.name !== 'CGST' && each_tax.name !== 'SGST' && each_tax.name !== 'IGST' &&
                each_tax.name !== 'VAT' && each_tax.name !== 'CESS' && each_tax.name !== 'SCESS' && each_tax.name !== 'GST') {
                sale_item['new_tax'] += parseFloat(each_tax.tax);
                breaks.push(each_tax);
              } else {
                breaks.push(each_tax);
              }
            });
            taxnotes.forEach(order_tax => {
              let flag = false;
              breaks.forEach(line_tax => {
                if (line_tax.name === order_tax) {
                  flag = true;
                }
              });
              if (!flag) {
                breaks.push({name: order_tax, rate: 0, tax: 0});
              }
            });
            sale_item['tax_notes'] = breaks;

            total_discount += parseFloat(sale_item.vps_dis);
            sale_item['abs_qty'] = Math.abs(sale_item['qty']);
            total_qty += parseInt(sale_item.qty, 0);
            sale_item.unit_price = parseFloat(sale_item.unit_price) - (parseFloat(sale_item.scheme_discount) / parseFloat(sale_item.qty)) -
              (parseFloat(sale_item.unit_price) * (parseFloat(sale_item.discount) / 100));
            sale_item.unit_price = sale_item.unit_price.toFixed(inthis.apiService.decimalPolicy);
          });
          this.orderDetails['total_qty'] = total_qty;
          this.orderDetails['discount'] = total_discount.toFixed(this.apiService.decimalPolicy);
          this.orderDetails['amount_in_words'] = this.amount_in_words;
          this.orderDetails['invoice_date'] = this.invoice_date;
          this.orderDetails['order_date'] = this.datePipe.transform((new Date(this.orderDetails['order_date'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
          if (this.orderDetails['date_delivered'] !== null) {
            this.orderDetails['date_delivered'] = this.datePipe.transform((new Date(this.orderDetails['date_delivered'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
          }
          if (this.orderDetails['pay_items'].length === 0) {
            this.payment_enable = false;
          } else {
            const datePipe = this.datePipe;
            this.orderDetails['pay_items'].forEach(pay_item => {
              if (pay_item['payment_type'] === 'Cr Used') {
                pay_item['payment_type'] = 'Cr Note';
              } else if (pay_item['payment_type'] === 'cash_and_credit') {
                pay_item['payment_type'] = 'Credit With Payment';
              }
              if (pay_item['payment_date']) {
                pay_item['payment_date'] = datePipe.transform((new Date(pay_item['payment_date'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
              }
            });
            this.payment_enable = true;
          }
          this.orderDetails['payment_enable'] = this.payment_enable;
          this.print_details = [this.orderDetails];
        } else {
          this.orderDetails = {label_config: {}};
          this.payment_enable = false;
          this.print_details = [];
        }
        this.printAll(this.print_details);
      },
      error => {
        console.log(error);
      }
    );
  }

  printRow() {
    const el: HTMLElement = this.myDiv.nativeElement;
    el.click();
  }

  printAll(rows) {
    const el: HTMLElement = this.interPrint.nativeElement;
    el.click();
  }
}

