import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {GridsterItem} from 'angular-gridster2';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-widget-single',
  template: `
    <div class="info-box">
      <span class="heading">{{widget.data.label}}</span>
      <span class="desc">{{value}}</span>
    </div>`,
  styleUrls: ['./../dashboard.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SingleWidgetComponent implements OnInit, OnDestroy {
  @Input()
  widget;
  @Input()
  resizeEvent: EventEmitter<GridsterItem>;
  resizeSub: Subscription;
  value;

  ngOnInit(): void {
    this.resizeSub = this.resizeEvent.subscribe(widget => {
      if (widget === this.widget) {
        // or check id , type or whatever you have there
        // resize your widget, chart, map , etc.
        console.log(widget);
      }
    });
    if (this.widget.data.value) {
      this.value = this.widget.data.value;
      this.value = Number(this.value);
      if (this.value % 1 !== 0) {
        this.value = parseFloat(this.value).toFixed(2);
      }
      if (this.widget.data.value_type) {
        this.formatNUmber();
      }

    } else {
      this.value = 0;
    }
  }

  ngOnDestroy(): void {
    this.resizeSub.unsubscribe();
  }

  formatNUmber() {
    if (this.widget.data.value_type === 'currency') {
      if (this.value >= 10000000) {
        this.value = (this.value / 10000000).toFixed(2) + 'Cr';
      } else if (this.value >= 100000) {
        this.value = (this.value / 100000).toFixed(2) + 'L';
      } else if (this.value >= 1000) {
        this.value = (this.value / 1000).toFixed(2) + 'K';
      }
    }
  }
}
