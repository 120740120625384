import {ReportBase} from '../_common/reportbase';
import {Component, OnInit} from '@angular/core';
import {ErrorHandler} from '../../error/error-handler';
import { textAlign } from 'html2canfast/dist/types/css/property-descriptors/text-align';


@Component({
  selector: 'app-payment-ageing-report',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})
export class PaymentAgeingReportComponent extends ReportBase implements OnInit, IReport {
  user_data = {
    date: null,
    access_token: null,
    url: null,
    last_date: null,
    offset: null,
    flag: true,
    out_type: 0,
    van_id: 0
  };
  ngOnInit(): void {
    this.sharedService.setHeader('Payment Ageing Report');
    this.configureGrid();
    this.showDate = false;
    this.loadServerData();
  }

  setTitle() {
    this.title = 'Payment Ageing Report';
  }

  configureGrid() {
    this.API_URL = '/api/pwa_v2_reports/get_payment_ageing';
    this.columnDefs = [
      {headerName: 'Order Date', field: 'order_date', aggFunc: 'sum', pinned: 'left'},
      {headerName: 'Outlet', field: 'outlet_name', aggFunc: 'sum', pinned: 'left'},
      {headerName: 'OUT ID', field: 'outlet_id', aggFunc: 'sum', cellStyle: {textAlign: "right"}, width: 100},
      {headerName: 'Code', field: 'outlet_code', aggFunc: 'sum', width: 200},
      {headerName: 'Type', field: 'outlet_type', aggFunc: 'sum', width: 200},
      {headerName: 'Area', field: 'area', aggFunc: 'sum', width: 200},
      {headerName: 'Zone', field: 'zone', aggFunc: 'sum', width: 200},
      {headerName: 'Order', field: 'order_ref', aggFunc: 'sum', width: 200},
      {headerName: 'Invoice', field: 'invoice_no', aggFunc: 'sum', width: 200},
      {headerName: 'Beat', field: 'beat_name', aggFunc: 'sum', width: 200},
      {headerName: 'Salesman', field: 'salesman_name', aggFunc: 'sum', pinned: 'left'},
      {headerName: 'Mobile', field: 'outlet_mobile', aggFunc: 'sum', width: 200},
      {headerName: 'Credit Limit', field: 'credit_limit', cellStyle: {textAlign: 'right'}, aggFunc: 'sum', width: 150},
      {headerName: 'Order Type', field: 'order_type', aggFunc: 'sum', cellStyle: {textAlign: "right"}, width: 100},
      {headerName: 'Order Amount', field: 'order_amount', aggFunc: 'sum', cellStyle: {textAlign: "right"}, width: 100},
      {headerName: 'Order Outstanding', field: 'outstanding_amount', cellStyle: {textAlign: "right"}, aggFunc: 'sum', width: 150},
      {headerName: 'Current Outstanding', field: 'current_outstanding', cellStyle: {textAlign: "right"}, aggFunc: 'sum', width: 150},
      {headerName: 'Overdue Date', field: 'overdue_date', aggFunc: 'sum', cellStyle: {textAlign: "right"}, width: 100},
      {headerName: 'Days Pending', field: 'days_pending', hide: true}
    ];
  }

}
