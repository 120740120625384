import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-outlet-last-visit-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class SRScorecardReportComponent extends ReportBase implements IReport, OnInit {

  showMultiFilter = true;
  multiFilter: any = {
    name: 'Instance',
    key: 'instance',
    value: '',
    values: []
  };
  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('SR Score Card Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    //this.showDate = false;
    this.multiFilter.value =  this.accountURLS[0];
    this.multiFilter.values = this.accountURLS;

    this.loadInBatches(5000, 'id');
    this.showProgressCustomText = true;
    this.multiInstance = true;
    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getSRScoreCardReport';

    this.columnDefs = [
      {headerName: 'INSTANCE', field: 'instance',  width: 80},
      {headerName: 'REGION', field: 'region', enableRowGroup: true,  width: 80},
      {headerName: 'STATE', field: 'state', enableRowGroup: true,  width: 80},
      {headerName: 'TEAM', field: 'team', enableRowGroup: true, width: 80},
      {headerName: 'EMP ID', field: 'salesman_id', enableRowGroup: true,  width: 80},
      {headerName: 'SALESMAN', field: 'salesman_name', enableRowGroup: true, width: 80},
      {headerName: 'TARGET', field: 'target', enableRowGroup: true,  width: 80},
      {headerName: 'ACHIEVED', field: 'achieved', enableRowGroup: true, width: 80},
      {headerName: 'VC', field: 'visited_count',  width: 80},
      {headerName: 'PC', field: 'pc',  width: 80},
      {headerName: 'JC ADHERENCE %', field: 'jc_adh_pct',  width: 80},
      {headerName: 'BILLS CUT %', field: 'bills_cut_pct',  width: 80},
      {headerName: 'ACHIEVED %', field: 'achieved_pct',  width: 80},
      {headerName: 'UNIQUE BILLED OUTLET %', field: 'unique_outlet_billed_pct',  width: 80},
      {headerName: 'ALS', field: 'average_lines_cut',  width: 80},
      {headerName: 'AVG BILL VALUE', field: 'average_bill_value',  width: 80},
      {headerName: 'MSS ACHV %', field: 'mss_achieved_pct',  width: 80},
      {headerName: 'MSS SALES', field: 'mss_sales',  width: 80},
      {headerName: 'ORDERS COUNT', field: 'order_count',  width: 80},
      {headerName: 'SALES', field: 'sales',  width: 80},
      {headerName: 'SKU COUNT', field: 'sku_count',  width: 80},
      {headerName: 'UNIQUE SKU', field: 'unique_sku',  width: 80},
      {headerName: 'RANK', field: 'rank',  width: 80},
      {headerName: 'SCORE', field: 'score',  width: 80}

    ];

  }

}
