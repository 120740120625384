import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class PurchaseOrderReportComponent extends ReportBase implements IReport, OnInit {

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    /*this.myDate[0] = '2021-03-01';
    this.myDate[1] = '2023-03-31';*/
    this.sharedService.setHeader('Purchase Order Report');
    this.sharedService.statusConfig = {
      success: 'success',
      success1: 'success',
      error: 'fail',
      error1: 'fail',
      warning: 'warning',
      warning1: 'warning'
    };
    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getPurchaseOrder';
    const inThis = this;
    this.columnDefs = [
      {headerName: 'State', field: 'partner_state', width: 100},
      {headerName: 'PO NO', field: 'po', width: 100},
      {headerName: 'Customer', field: 'customer_name', width: 100},
      {headerName: 'Customer Email', field: 'customer_email', width: 100},
      {headerName: 'Customer State', field: 'customer_state', width: 100},
      {headerName: 'Supplier', field: 'supplier', width: 80},
      {headerName: 'Supplier Email', field: 'supplier_email', width: 80},
      {headerName: 'Supplier State', field: 'supplier_state', width: 80},
      {
        headerName: 'Date', field: 'date', width: 100,
        valueGetter: params => {
          if (params.data) {
            if (params.data.date) {
              return inThis.datePipe.transform((new Date(params.data.date)).toString(), 'yyyy-MM-dd');
            } else {
              return params.data.date;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: 'Product', field: 'prod_name', width: 100},
      {headerName: 'P Code', field: 'prod_code', width: 150},
      {headerName: 'Category', field: 'category', width: 100},
      {headerName: 'Brand', field: 'brand', width: 100},
      {headerName: 'Price', field: 'unit_price', width: 100},
      {headerName: 'Qty', field: 'qty_ordered', width: 100},
      {headerName: 'Delivered Qty', field: 'qty_delivered', width: 100},
      {headerName: 'Returned Qty', field: 'qty_returned', width: 100},
      {headerName: 'Subtotal', field: 'amount_untaxed', width: 100,
        valueGetter(params1) {
          if (params1.data) {
            if (params1.data.amount_untaxed) {
              return parseFloat(params1.data.amount_untaxed).toFixed(2);
            }
          }
        }
      },
      {headerName: 'Tax', field: 'amount_tax', width: 100,
        valueGetter(params1) {
          if (params1.data) {
            if (params1.data.amount_tax) {
              return parseFloat(params1.data.amount_tax).toFixed(2);
            }
          }
        }
      },
      {headerName: 'Total', field: 'amount_total', width: 100,
        valueGetter(params1) {
          if (params1.data) {
            if (params1.data.amount_total) {
              return parseFloat(params1.data.amount_total).toFixed(2);
            }
          }
        }
      },
      {headerName: 'Invoice No', field: 'inv_no', width: 150},
      {headerName: 'Status', field: 'status', width: 80,
        valueGetter: this.capitalise('status'),
        cellRenderer(params) {
          let displayElem = '';
          if (params.data) {
            if (params.value === 'Confirmed') {
              displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #379862;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
            } else if (params.value === 'Cancelled') {
              displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #E24260;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
            } else if (params.value === 'Draft') {
              displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #F6A82C;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
            } else {
              if (params.value) {
                displayElem = '<span>' + params.value + '</span>';
              }
            }

            return displayElem;
          }
        }
      },
      {headerName: 'Delivery Status', field: 'd_status', width: 80,
        valueGetter: this.capitalise('d_status'),
        cellRenderer(params) {
          let displayElem = '';
          if (params.data) {
            if (params.value === 'Delivered') {
              displayElem = '<span class="ml-1 status2" style="background-color:#E7F3EC; border:#E7F3EC">' + params.value + '</span>';
            } else if (params.value === 'Dispatched') {
              displayElem = '<span class="ml-1 status2" style="background-color:#E3F4F6; border:#E3F4F6">' + params.value + '</span>';
            } else if (params.value === 'Partial') {
              displayElem = '<span class="ml-1 status2" style="background-color:#EEEFF8; border:#EEEFF8">' + params.value + '</span>';
            } else {
              if (params.value) {
                displayElem = '<span class="ml-1 status2" style="background-color:#FEF5E6; border:#FEF5E6">' + params.value + '</span>';
              }
            }
            return displayElem;
          }
        }
      },
    ];

  }
  capitalise(field: string) {
    return (params) => {
      if (params.data) {
        if (params.data[field] === null || params.data[field] === "") {
          return '';
        } else {
          /*const word = params.data[field];

          const firstLetter = word.charAt(0);

          const firstLetterCap = firstLetter.toUpperCase();

          const remainingLetters = word.slice(1);

          const capitalizedWord = firstLetterCap + remainingLetters;
          return capitalizedWord;*/
          return params.data[field].replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
        }
      } else {
        return '';
      }
    };
  }


}
