import {Component, ElementRef, Inject, Injectable, Input, OnInit, ViewChild} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ApiService} from "../../_services/api.service";
import {SharedService} from "../../_services/shared_service";
import {ToastrService} from "ngx-toastr";
import {DatePipe} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {LocalforageService} from "../../_services/localforage.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-base-print',
  templateUrl: './base_print.component.html',
  styleUrls: ['./base_print.component.scss']
})
export class BasePrintComponent implements OnInit {
  @ViewChild('interPrint') public interPrint: ElementRef;
  @ViewChild('customPrintClick') customPrintClick: ElementRef<HTMLElement>;

  public templ_config;
  public template;
  public selectedTemplate = null;
  public print_details = [];
  userData = {
    access_token: null
  };
  API_URL = '';
  order_ids = [];
  type = '';
  gst_template;
  non_gst_template;

  constructor(public dialog: MatDialog,
              public apiService: ApiService,
              public sharedService: SharedService,
              public toastr: ToastrService,
              public elRef: ElementRef,
              public datePipe: DatePipe,
              protected route: ActivatedRoute,
              protected _localCacheService: LocalforageService,
              public router: Router,
              private domSanitizer: DomSanitizer,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any
             ) {


    this.type = dialogdata.type;
    this.order_ids = dialogdata.order_ids;
    if (this.type === 'invoice') {
      this.API_URL = '/api/pwa_dms_connects/get_invoice_print_details';
    } else {
      this.API_URL = '/api/pwa_connects/get_order_print_details';
    }
    this.getPrintDetails(this.order_ids, this.API_URL);
  }
  ngOnInit(): void {
  }
  getTemplates() {
    this.selectedTemplate = null;
    this.templ_config = null;
    this.template = false;
    if (localStorage.getItem('custom_templates')) {
      const custom_templates = JSON.parse(localStorage.getItem('custom_templates'));
      const updated = custom_templates.filter(elem => {
        return elem.type === 'Secondary Print PDF';
      });
      if (updated.length > 0) {
        this.templ_config = [{name: 'Default'}].concat(updated);
        this.template = true;
      }
    }
    if (localStorage.getItem('custom_templates')) {
      const custom_templates = JSON.parse(localStorage.getItem('custom_templates'));
      const updated = custom_templates.filter(elem => {
        return elem.type === 'Secondary Print PDF';
      });
      if (updated.length > 0) {
        this.templ_config = [{name: 'Default'}].concat(updated);
        this.template = true;
      }

      const appConfig = JSON.parse(localStorage.getItem('app')).config_json;
      if (appConfig && appConfig.hasOwnProperty('default_gst_print')) {
        const gst_template = updated.find(x => x.id === appConfig.default_gst_print);
        if (gst_template) {
          this.gst_template = gst_template;
        } else {
          this.gst_template = null;
        }
      } else {
        this.gst_template = null;
      }

      if (appConfig && appConfig.hasOwnProperty('default_non_gst_print')) {
        const non_gst_template = updated.find(x => x.id === appConfig.default_non_gst_print);
        if (non_gst_template) {
          this.non_gst_template = non_gst_template;
        } else {
          this.non_gst_template = null;
        }
      } else {
        this.non_gst_template = null;
      }
    }
  }

  getPrintDetails(order_ids, url) {
    this.getTemplates();
    this.userData.access_token = localStorage.getItem('resfreshToken');
    const order_data = {
      access_token: this.userData.access_token,
      order_id: order_ids
    };
    this.template = false;
    this.apiService.post(url, order_data)
      .subscribe(async res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
          const orderdata = res.results.data;
          await this.processPrintDetails(orderdata);
          if (this.gst_template || this.non_gst_template) {
            const el: HTMLElement = this.interPrint.nativeElement;
            el.click();
          } else {
            if (this.template) {
              const el: HTMLElement = this.customPrintClick.nativeElement;
              el.click();
            } else {
              const el: HTMLElement = this.interPrint.nativeElement;
              el.click();
            }
          }
        }
      });
  }
  async processPrintDetails(orderdata) {
    const inThis = this;
    let total_qty = 0;
    let total_discount = 0;
    let printDetails = {};
    const all_orders = [];
    for (const data of orderdata) {
      // res['results'].data.forEach(async data => {
      total_qty = 0;
      printDetails = data;
      const tax_breaks = [];
      const taxnotes = [];
      printDetails['label_config'] = {};
      const labels = JSON.parse(localStorage.getItem('custom_labels'));
      if (labels) {
        labels.forEach(lab => {
          printDetails['label_config'][lab.label] = lab.display;
        });
      } else {
        printDetails['label_config']['PrimaryCurrency'] = '';
        printDetails['label_config']['SecondaryCurrency'] = '';
        printDetails['label_config']['SupplierLabel'] = 'Supplier';
        printDetails['label_config']['TaxLabel'] = 'GST';
      }
      if (typeof printDetails['tax_notes'] === 'string') {
        printDetails['tax_notes'] = JSON.parse(printDetails['tax_notes']);
      }
      if (printDetails['tax_notes']) {
        Object.keys(printDetails['tax_notes']).forEach(each_tax => {
          tax_breaks.push({
            name: each_tax,
            tax: printDetails['tax_notes'][each_tax].toFixed(inThis.apiService.decimalPolicy)
          });
          taxnotes.push(each_tax);
        });
      }
      printDetails['tax_breakup'] = tax_breaks;
      if (printDetails['partner_id'] === printDetails['partner_shipping_id']) {
        printDetails['customer_skip'] = true;
      } else {
        printDetails['customer_skip'] = false;
      }
      printDetails['print_text'] = inThis.domSanitizer.bypassSecurityTrustHtml(printDetails['print_text']);
      let rate = 0;
      let spli;
      printDetails['sale_items'].forEach(sale_item => {
        sale_item['label'] = '[' + sale_item.type + ']';
        const breaks = [];
        sale_item['new_tax'] = 0;
        if (sale_item['tax_notes']) {
          sale_item['tax_notes'] = JSON.parse(sale_item['tax_notes']);
          if (typeof sale_item['tax_notes'] === 'string' && sale_item['tax_notes'] !== "") {
            sale_item['tax_notes'] = JSON.parse(sale_item['tax_notes']);
          }
        }
        if (sale_item['tax_notes'] && sale_item['tax_notes'].constructor === Object) {
          Object.keys(sale_item['tax_notes']).forEach(each_tax => {
            if (sale_item['tax_notes'][each_tax].includes('(')) {
              spli = sale_item['tax_notes'][each_tax].split('(');
              rate = spli[1].split('%')[0].split(')')[0];
              breaks.push({name: each_tax, tax: spli[0], rate});
            } else {
              if (sale_item.hasOwnProperty('tax')) {
                spli = sale_item['tax'].split('(');
                rate = spli[1].split('%')[0].split(')')[0];
              } else {
              }
              breaks.push({name: each_tax, tax: sale_item['tax_notes'][each_tax], rate});
            }

          });
        }
        sale_item['tax_notes'] = breaks;

        total_discount += parseFloat(sale_item.vps_dis);
        if (printDetails['d_status'] === 'delivered') {
          sale_item['abs_qty'] = Math.abs(sale_item['delivered_qty']);
          total_qty += parseInt(sale_item.delivered_qty, 0);
          sale_item.unit_price = parseFloat(sale_item.unit_price) - (parseFloat(sale_item.scheme_discount) / parseFloat(sale_item.delivered_qty)) -
            (parseFloat(sale_item.unit_price) * (parseFloat(sale_item.discount) / 100));
        } else {
          sale_item['abs_qty'] = Math.abs(sale_item['qty']);
          total_qty += parseInt(sale_item.qty, 0);
          sale_item.unit_price = parseFloat(sale_item.unit_price) - (parseFloat(sale_item.scheme_discount) / parseFloat(sale_item.qty)) -
            (parseFloat(sale_item.unit_price) * (parseFloat(sale_item.discount) / 100));
        }
        sale_item.unit_price = sale_item.unit_price.toFixed(inThis.apiService.decimalPolicy);
      });
      printDetails['total_qty'] = total_qty;
      printDetails['discount'] = total_discount.toFixed(inThis.apiService.decimalPolicy);
      if (printDetails['date_delivery']) {
        printDetails['invoice_date'] = inThis.datePipe.transform((new Date(printDetails['date_delivery'] + ' UTC')).toString(), 'dd/MM/yyyy');
      } else if (printDetails['date_delivered'] != null) {
        printDetails['invoice_date'] = inThis.datePipe.transform((new Date(printDetails['date_delivered'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
      }
      printDetails['order_date'] = inThis.datePipe.transform((new Date(printDetails['order_date'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
      if (printDetails['date_delivered'] != null) {
        printDetails['date_delivered'] = inThis.datePipe.transform((new Date(printDetails['date_delivered'] + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
      }
      if (printDetails['logo']) {
        const resp = await inThis.apiService.fetchImageWait({
          access_token: inThis.userData.access_token,
          data: [{store_fname: printDetails['logo'], container: "res_partner"}]
        });
        // res_data.subscribe(resp => {
        console.log(resp);
        if (resp['result'].length > 0) {
          printDetails['logo'] = inThis.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + resp['result'][0].bill_datas);
        }
        all_orders.push(printDetails);
      } else {
        all_orders.push(printDetails);
      }
      this.print_details = all_orders;
      console.log(all_orders);
    }
  }
}
