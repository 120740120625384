<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>BULK RETAILER SELECTION</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <div class="row">
        <div class="col-md-12 pl-0 pr-0">
          <div class="col-md-9 pl-0 pr-30">
            <div class="col-md-2 pl-0 pr-30">
              <label style="color:#6D6D6D">PROGRAM TYPE</label>
              <div style="margin-top: 5px;" class="formselectwhitebg">
                <ng-select [items]="program_types"
                           bindLabel="itemName"
                           bindValue="id"
                           class="custom"
                           [(ngModel)]="outletFilters.program_type">
                  >
                </ng-select>
              </div>
            </div>
            <div class="col-md-2 pl-0 pr-30">
              <label style="color:#6D6D6D">OUTLET TYPE</label>
              <div style="margin-top: 5px;" class="formselectwhitebg">
                <ng-select [items]="outlet_types"
                           bindLabel="itemName"
                           bindValue="id"
                           class="custom"
                           [(ngModel)]="outletFilters.outlet_type">
                  >
                </ng-select>
              </div>
            </div>
            <div class="col-md-2 pl-0 pr-30">
              <label style="color:#6D6D6D">SALESMAN</label>
              <div style="margin-top: 5px;" class="formselectwhitebg">
                <ng-select [items]="salesmen"
                           bindLabel="itemname"
                           bindValue="id"
                           class="custom"
                           [(ngModel)]="outletFilters.salesman_id">
                  >
                </ng-select>
              </div>
            </div>
            <div class="col-md-2 pl-0 pr-30">
              <label style="color:#6D6D6D">STATE</label>
              <div style="margin-top: 5px;" class="formselectwhitebg">
                <ng-select [items]="states"
                           bindLabel="itemName"
                           bindValue="id"
                           class="custom"
                           [(ngModel)]="outletFilters.state_id">
                  >
                </ng-select>
              </div>
            </div>
            <div class="col-md-2 pl-0 pr-30">
              <label style="color:#6D6D6D">CLUSTER</label>
              <div style="margin-top: 5px;" class="formselectwhitebg">
                <ng-select [items]="clusters"
                           bindLabel="itemname"
                           bindValue="id"
                           class="custom"
                           [(ngModel)]="outletFilters.cluster_id">
                  >
                </ng-select>
              </div>
            </div>
            <div class="col-md-2 pl-0 pr-30">
            </div>
          </div>
          <div class="col-md-3 pl-0 pr-0">
            <div style="display: inline-block; float: right;margin-top: 15px;">
              <div class="search" style="display: inline-block;margin-right: 15px;">
                <span class="search-control"><img src="../../../assets/searchicon.png"/></span>
                <input id="filter-text-box1" type="text" placeholder="filter" (input)="quickSearch()"
                       class="search-control-area"
                       placeholder="Search">
              </div>
              <button
                mat-button
                class="btn confirm-blue-btn1"
                style="height: 44px;"
                (click)="fetchOutlets()"
              >
                GO
              </button>
            </div>
          </div>
        </div>
        <div style="margin-top: 20px;width: 100%">
          <div class="col-md-12 pl-0 pr-0">
            <div>
              <ag-grid-angular *ngIf=loaded id="myGrid2" style="width: 100%;height:54vh" class="ag-theme-alpine"
                               [defaultColDef]="defaultColDef"
                               [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                               (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                               (cellClicked)="onCellClicked($event)"
                               (rowSelected)="onRowSelected($event)"
                               [context]="context">
              </ag-grid-angular>
              <div *ngIf="preloader" style="width: 100%;height:54vh">
                <app-loadingstate></app-loadingstate>
              </div>
              <div *ngIf="empty_state">
                <app-emptystate></app-emptystate>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button-row" style="margin-top: 20px;">
      <div style="display: inline-block; left: 0px;">
        <span style="font-size: 16px; font-weight: 500;display: inline; color: #0762AD; margin-left: 15px">{{selectedCount}}</span>
        <span style="font-size: 14px; font-weight: 500;display: inline"> selected</span>
      </div>
      <div style="position: absolute;display: inline-block; right: 0px;">
        <button
          mat-button
          style=" margin-right:15px;"
          class="btn grey-btn"
          (click)="closeDialog()"
        >
          Cancel
        </button>
        <button
          mat-button
          style=" margin-right:15px;"
          class="btn confirm-blue-btn"
          (click)="addOutlets()"

        >
          Add
        </button>
      </div>
    </div>
  </div>
</section>
