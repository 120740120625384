import {Component, OnInit} from '@angular/core';
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DomSanitizer} from "@angular/platform-browser";
import {SharedService} from "../../_services/shared_service";

@Component({
  selector: 'app-imagerender',
  templateUrl: './imagerender.component.html',
  styleUrls: ['./imagerender.component.scss']
})

export class ImageRenderComponent implements OnInit {

  public photo;
  store_fname;
  API_URL = '';
  container = '';
  user_data = {
    date: null,
    access_token: null,
    url: null,
    data: {
      start_date: null,
      end_date: null,
      id: null
    }
  };

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private domSanitizer: DomSanitizer,
              public sharedService: SharedService) {
    /* this.store_fname = dialogdata.store_fname;
     this.container = dialogdata.container;*/
    this.user_data.access_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImNpZCI6ODI4LCJjb250ZW50X3VybCI6bnVsbCwiaWF0IjoxNjgyNDA1Mjg0ODA4LCJ1c2VyX2lkIjo5MzEsInBhcnRuZXJfaWQiOjI0MjI5NSwicm9sZV9pZCI6MTksIm1vYmlsZW51bWJlciI6Ijc2NTQ1NjciLCJpbnN0YW5jZSI6ImRpYWdlb3VhdDIiLCJtZW1iZXJzIjpudWxsLCJkY19pZHMiOm51bGwsInByb2ZpbGUiOiJNYW5hZ2VyIiwidGVhbV9pZCI6bnVsbCwic3VwcGxpZXJfaWRzIjpudWxsLCJjb21wYW55X2lkIjo0fSwiaWF0IjoxNjgyNDA1Mjg0LCJleHAiOjE2ODI0NjI4ODR9.rgUIkJQUPiTyjjNCasn7w4shdXnVw3cCNJa7Nz-CJbk';
    this.getParams();
    this.fetchImage();

  }

  getParams() {
    this.store_fname = this.route.snapshot.queryParamMap.get('sf');
    this.container = this.route.snapshot.queryParamMap.get('cont');
    this.API_URL = this.route.snapshot.queryParamMap.get('url');
   /* this.route.fragment.subscribe(fragment => {
      this.store_fname = new URLSearchParams(fragment).get('sf');
      this.container = new URLSearchParams(fragment).get('cont');
      this.API_URL = new URLSearchParams(fragment).get('url');
    });*/
  }

  ngOnInit(): void {
  }

  fetchImage() {
    //const inthis = this;
    const paramObject: any = {};
    paramObject.url = this.API_URL;
    paramObject.store_fname = this.store_fname;
    paramObject.container = this.container;
    const res_data = this.apiService.fetchPublicImage (paramObject);
    res_data.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('result') && res['result'].hasOwnProperty('data')) {
          this.photo = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + res['result'].data);
        } else {
        }
      },
      error => console.log(error)
    );
    /* this.API_URL = '/api/pwa_connects/fetch_file';
     const paramObject: any = {};
     paramObject.access_token = localStorage.getItem('resfreshToken');
     paramObject.store_fname = this.store_fname;
     paramObject.container = this.container;
     console.log(paramObject);
     this.apiService.post(this.API_URL, paramObject)
       .subscribe(res => {
           console.log(res);

           if (res.hasOwnProperty('result') && res.result.hasOwnProperty('data')) {
             this.photo = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + res['result'].data);
           } else {


           }
         },
         error => {
           console.log(error);

         });*/
  }

}
