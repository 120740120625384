import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dropdown-icon',
  templateUrl: './dropdown-icon.component.html',
  styleUrls: ['./dropdown-icon.component.scss']
})
export class DropdownIconComponent implements OnInit {
  @Output() statusChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  itemList = [];
  selectedItems = [];
  settings = {};

  constructor() { }

  ngOnInit() {
    this.itemList = [
      { "id": 1, "itemName": "Status All" },
      { "id": 2, "itemName": "complete" },
      { "id": 3, "itemName": "draft" },
      { "id": 4, "itemName": "settled"},
      { "id": 5, "itemName": "started"}
    ];

    this.selectedItems = [
      { "id": 1, "itemName": "Status All" }];
    this.settings = { singleSelection: true, text: "StatusAll" };
  }

  onItemSelect(event) {
    this.statusChange.emit(event);
  }
}
