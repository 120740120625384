import {Component, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {DatePipe} from "@angular/common";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-reject-popup-reason',
  templateUrl: './approve_reject_comments_popup.component.html',
  styleUrls: ['./approve_reject_comments_popup.component.scss']

})

export class ApproveRejectCommentsPopupComponent implements OnInit {

  @Output() submitClicked: EventEmitter<any> = new EventEmitter<any>();
  title = "";
  message1 = "";
  message2 = "";
  comments;
  type = "";
  buttonText = "";

  constructor(public datePipe: DatePipe,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.title = dialogdata.title;
    this.message1 = dialogdata.message1;
    this.message2 = dialogdata.message2;
    this.type = dialogdata.type;
    this.buttonText = dialogdata.buttonText;
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  submitClick() {
    this.submitClicked.emit(this.comments);
    this.dialog.closeAll();
  }
}
