<div id="overlay"></div>
<div class="login">
  <div class="auth-header">
    <!--<img src="assets/images/sd-logo.png" />-->
  </div>
  <div class="auth-card">
    <div class="col-md-6">
      <div class="auth-logo-sec">
        <img class="auth-client-logo" src={{company_name}} />
      </div>
      <!--<form [formGroup]="angForm" novalidate>-->
      <div *ngIf="login_usrpwd_show" class="auth-card-form login" id="login">
        <!-- <div *ngIf="subdomain === ''" class="custom-input">
           <label>Company Key</label>
           <input type="text" [(ngModel)]="company_key_input" class="form-control" />
         </div>-->
        <div class="custom-input">
          <label>USERNAME</label>
          <input type="text" [(ngModel)]="username" class="form-control" />
        </div>
        <div class="custom-input">
          <label>PASSWORD</label>
          <input (keydown.enter)="login_user()" type="password" [(ngModel)]="password" class="form-control"/>
        </div>
        <div style="color:red;font-size: small;margin-bottom:5%;">
          <p style="margin-top: -5%">{{error_msg}}</p>
        </div>
        <div style="color:green;font-size: small;">
          <p style="margin-top: -5%">{{prgs_msg}}</p>
        </div>
        <div *ngIf="false" class="forgot-pass">
          <a href="#">Forgot password?</a>
        </div>
        <img *ngIf="loader" src="./../../assets/images/loader_icon.gif" style="width:7%;margin-left: 46%;"/>
        <a *ngIf="!loader" (click)="login_user()" class="btn confirm-blue-btn" style="width: 100%;">Sign in</a>
        <div class="auth-card-notes">
          <label>
            By logging in, you agree to our
            <span> <a herf="#">Terms & Conditions & Privacy Policy.</a></span>
          </label>
        </div>
      </div>
      <div class="auth-card-form getotp hide">
        <div class="custom-input">
          <label>MOBILE NUMBER</label>
          <input type="number" [(ngModel)]="phone" class="form-control" />
        </div>
        <div style="color:red;font-size: small">
          <p>{{error_msg}}</p>
        </div>
        <a (click)="otpClick()" id="verifyotp" class="btn confirm-blue-btn" style="width: 100%;">GET OTP</a>
      </div>
      <div *ngIf="login_mobileotp_show" class="auth-card-form verifyotp hide">
        <div class="custom-input otp">
          <label>OTP</label>
          <input
            id="otp1"
            type="text"
            class="form-control"
            maxlength="1"
            [(ngModel)]="otp1"
            (keyup)="focusNextOTPInput($event)"
          />
          <input
            id="otp2"
            type="text"
            class="form-control"
            maxlength="1"
            [(ngModel)]="otp2"
            (keyup)="focusNextOTPInput($event)"
          />
          <input
            id="otp3"
            type="text"
            class="form-control"
            maxlength="1"
            [(ngModel)]="otp3"
            (keyup)="focusNextOTPInput($event)"
          />
          <input
            id="otp4"
            type="text"
            class="form-control"
            maxlength="1"
            [(ngModel)]="otp4"
            (keyup)="focusNextOTPInput($event)"

          />
        </div>
        <div class="auth-card-resend-otp" style="cursor: pointer">
          <a (click)="otpClick()">Resend OTP</a>
        </div>
        <a id="otpverify" (click)="login_user()" class="btn confirm-blue-btn" style="width: 100%;">VERIFY OTP</a>
      </div>

      <div *ngIf="login_options_show" style="margin-top: 50px;margin-bottom: 50px;">
        <a *ngIf="login_credentials_show" (click)="logincredentialsClick()" class="btn confirm-blue-btn" style="width: 100%;margin-top: 30px;">Log In with Credentials</a>
        <a *ngIf="login_otp_show" (click)="loginwithOTPClick()" class="btn confirm-blue-btn" style="width: 100%;margin-top: 30px;">Login with OTP</a>
        <a *ngIf="login_okta_show" (click)="login_okta_user()" class="btn confirm-blue-btn" style="margin-top: 30px; width: 100%;">Log In with OKTA</a>
        <div *ngIf="ssoEnabled">
          <ng-container *ngFor="let item of ssoConfigs;">
            <a (click)="login_sso(item)" class="btn confirm-blue-btn" style="margin-top: 30px; width:100%;">Log In with {{item.name}}</a>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="auth-card-img-section">
        <div class="auth-card-img">
          <img src={{company_bg}} />
        </div>
      </div>
    </div>
  </div>
</div>
