
import {Component, OnInit} from "@angular/core";
import {MatDialogConfig} from "@angular/material/dialog";
import {GenrateClaimsPopupComponent} from "./generate_claims_popup/generate_claims_popup.component";
import {PageBase} from "../../../pagebase/pagebase";


@Component({
  selector: 'app-eligible-schemes',
  templateUrl: '../../../pagebase/pagebase.html',
  styleUrls: ['../../../pagebase/pagebase.scss']
})

export class RaiseSaleReturnClaimListComponent extends PageBase implements OnInit, IPage {

  matActionMenuItemDeclaration = [{name: 'Generate Claim'}];

  public pinnedTotalData = [];

  customFilter: any = {
    name: 'Status',
    key: 'status',
    value: 'notclaimed',
    values: [],
    show: true
  };

  ngOnInit(): void {

    this.sharedService.setHeader('Sale Return Claim');
    this.showCustomFilter = true;
    this.showActionButton = true;

    this.customFilter.values = [
      {id: 'all', itemName: 'All'},
      {id: 'claimed', itemName: 'Claimed'},
      {id: 'notclaimed', itemName: 'Not Claimed'},
    ];
    this.statusConfig.success = 'Claimed';
    this.statusConfig.error = 'Not Claimed';
    this.statusConfig.warning = 'processing';

    this.getAuthorizedActions();
    this.checkAccessControl();
    this.configureGrid();
    this.loadServerData();
  }


  configureGrid() {
    this.API_URL = '/api/pwa_dms_connects/get_sale_return_claims';
    const inThis = this;
    this.columnDefs = [
      {headerName: "ID", field: "id", width: 20, checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true,
        cellRenderer(params) {
          if (params.data) {
            if (params.data.invoice_no === 'TOTAL') {
              const displayElem = '<span style="font-weight: bold">' + params.value + '</span>';
              return displayElem;
            } else {
              return params.value;
            }
          }
        }},
      {headerName: "INVOICE NO", field: "invoice_no", width: 40},
      {headerName: "DATE", field: "date_invoice", width: 40},
      {headerName: "BEAT", field: "beat", width: 40},
      {headerName: "SUPP CODE", field: "supp_code", width: 40},
      {headerName: "SUPP NAME", field: "supp_name", width: 100},
      {headerName: "CUST CODE", field: "code", width: 40},
      {headerName: "CUST NAME", field: "name", width: 100},
      {headerName: "CUST CITY", field: "city", width: 100},
      {headerName: "CUST PIN", field: "zip", width: 100},
      {headerName: "PRODUCT CODE", field: "default_code", width: 40},
      {headerName: "PRODUCT NAME", field: "product_name", width: 100},
      {headerName: "PRODUCT CATEGORY", field: "product_categ", width: 40},
      {headerName: "QTY", field: "quantity", width: 40, cellStyle: {textAlign: 'right'}},
      {headerName: "LINE TOTAL", field: "price_total", width: 40, aggFunc: 'sum', cellStyle: {textAlign: 'right'}},
      {headerName: "TYPE", field: "claim_type", width: 40,
        cellStyle(params) {
          if (params.value) {
            if (params.value === 'Scheme') {
              return {color: '#17A2B8', 'font-weight': 600};
            } else if (params.value === 'Discount') {
              return {color: '#F6A82C', 'font-weight': 600};
            } else {
              return {color: '#fa4188', 'font-weight': 600};
            }
          }
        },
      },
      { headerName: "CLAIM NO", field: "claim_no", width: 40},
      { headerName: "STATUS", field: "claim_status", width: 40, cellRenderer: inThis.sharedService.statusCellRenderer},
    ];

    this.getRowId = (params) => params.data.id;
  }


  onMenuItemSelected(menuitem) {
    const rows = this.gridApi.getSelectedRows();
    if (rows === null || rows === undefined || rows.length === 0) {
      this.toastr.error('Please select at least 1 Retailer');
    } else  if (rows.length > 100) {
      this.toastr.error('Please select a maximum of 100 rows : ' + rows.length + ' selected');
    } else {
      if (menuitem.name === 'Generate Claim') {
        this.openClaimsDialog(rows);
      }
    }
  }

  checkAccessControl() {
    this.showCreate = false;
  }

  openClaimsDialog(rows) {
    const config: MatDialogConfig = {
      maxWidth: "480px",
      height: "auto",
      maxHeight: '100%',
      data: {
        lines: rows,
        type: 'sale return'
      },
      panelClass: 'custom-dialog-container'
    };

    const confirmdiaeref = this.dialog.open(GenrateClaimsPopupComponent, config);
    confirmdiaeref.componentInstance.saveData.subscribe(data => {
      if (data !== undefined ) {
        this.dialog.closeAll();
        this.toastr.success('Claim Generated Successfully');
        const claim = data;

        const rowsToUpdate = [];
        rows.forEach(r => {
          const ord = this.rowData.find(item => item.id === r['id']);
          rowsToUpdate.push(this.updateImmutableObject(ord, {claim_status: claim.status, claim_no: claim.id}));
        });

        this.gridApi.applyTransactionAsync({update: rowsToUpdate}, () => {
        });

        if (claim.id > 0) {
          this.router.navigate([this.apiService.redirect_url + '/dms/claim_detail'], {
            relativeTo: this.route,
            queryParams: {id: claim.id}
          });
        }

        // const rowsToUpdate = [];
        // data.line_ids.forEach(line_id => {
        //   const line = this.rowData.find(item => item.id === line_id);
        //   rowsToUpdate.push(this.updateImmutableObject(line, {claim_status: data.status, claim_no: data.name}));
        // });
        //
        // this.gridApi.applyTransactionAsync({update: rowsToUpdate}, () => {
        // });


      }
    });
  }

  postProcessData() {
    this.rowData = this.apiData;
    this.calculateTotalData();
  }

  calculateTotalData() {
    let discount = 0.00;
    let price_total = 0.00;
    let quantity = 0;
    this.rowData.forEach(data => {
      discount += parseFloat(data.discount);
      price_total += parseFloat(data.price_total);
      quantity += parseInt(data.quantity, 10);
    });

    this.pinnedTotalData = [{
      id: 'TOTAL',
      invoice_no: null,
      date_invoice: null,
      code: null,
      name: null,
      default_code: null,
      product_name: null,
      scheme_id: null,
      scheme_start: null,
      scheme_end: null,
      claim_type: null,
      quantity,
      discount: discount.toFixed(this.apiService.decimalPolicy),
      price_total: price_total.toFixed(this.apiService.decimalPolicy),
    }];
    this.gridOptions.pinnedBottomRowData = this.pinnedTotalData;
  }


}
