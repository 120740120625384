<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button class="closebtn" (click)="closeDialog()">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>{{type}} Beat Plan Retailers</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F3F6FD; padding: 15px;">
      <div style="display: inline-block">
        <label style="font-weight: 400;font-size: 12px;color: #2D2D2D">
          You have selected
        </label>
        <label style="font-weight: 500;font-size: 12px;color: #2D2D2D; margin-left: 3px;">
          {{uq_ret}}
        </label>
        <label style="font-weight: 400;font-size: 12px;color: #2D2D2D; margin-left: 3px;">
          Beat Plan retailers from
        </label>
        <label style="font-weight: 500;font-size: 12px;color: #2D2D2D; margin-left: 3px;">
          {{uq_bp}}
        </label>
        <label style="font-weight: 400;font-size: 12px;color: #2D2D2D; margin-left: 3px;">
          Beat Plan (
        </label>
        <label style="font-weight: 500;font-size: 12px;color: #2D2D2D; margin-left: 3px;">
          {{uq_ter}}
        </label>
        <label style="font-weight: 400;font-size: 12px;color: #2D2D2D; margin-left: 3px;">
          territories)
        </label>
      </div>
      <div style="display: inline-block; margin-top: 10px; width: 100%">
        <form novalidate id="ngForm" #beatplanretailerform="ngForm">
          <div style="margin-top: 15px;">
            <label>Salesman</label>
            <div style="margin-top: 5px;">
              <div class="formselectwhitebg">
                <select
                  name="salesman_id"
                  [(ngModel)]="selectedData.salesman_id"
                  (change)="onSalesmanChange()"
                  #salesman_id="ngModel"
                  style="width: 100%;">
                  <option
                    *ngFor="let item of salesmenList"
                    value="{{ item.id }}"
                  > {{ item.itemname }}</option>
                </select>
              </div>
            </div>
          </div>
          <div style="margin-top: 15px;">
            <label>Territory</label>
            <div style="margin-top: 5px;">
              <div class="formselectwhitebg">
                <select
                  name="territory_id"
                  [(ngModel)]="selectedData.territory_id"
                  (change) = "populateSelectedSalemanBeats()"
                  #territory_id="ngModel"
                  style="width: 100%;">
                  <option
                    *ngFor="let item of filteredTerritoryList"
                    value="{{ item.id }}"
                  > {{ item.itemname }}</option>
                </select>
              </div>
            </div>
          </div>
          <div style="margin-top: 15px;">
            <label>Beat Plan</label>
            <div style="margin-top: 5px;">
              <div class="formselectwhitebg">
                <select
                  name="beat_plan_id"
                  [(ngModel)]="selectedData.beat_plan_id"
                  required
                  #beat_plan_id="ngModel"
                  style="width: 100%;"
                >
                  <option
                    *ngFor="let item of filteredBeatList"
                    value="{{ item.id }}"
                  >
                    {{ item.name }}
                  </option>
                </select>
                <!--  <p *ngIf="!edit && questionData.question_type!=''">{{questionData.question_type}}</p>
                  <p *ngIf="!edit  && !questionData.question_type!=''">NA</p>-->
              </div>
            </div>
          </div>
        </form>
      </div>

      <div style="margin-top: 20px; margin-bottom: 20px; border-left: 4px solid #17A2B8">
        <div style="display: inline;">
          <p *ngIf="type === 'Move' && selectedData.beat_plan_id" style="font-weight: 400;font-size: 12px;color: #2D2D2D; padding-left: 8px;">
            The selected beat plan retailers will be
            <span style=" font-weight: 400;font-size: 12px;color: #E24260; margin-left: 3px; margin-right: 3px;">removed</span>
            from the current beat plans and
            <span style="font-weight: 400;font-size: 12px;color: #379862; margin-left: 3px; margin-right: 3px;">moved</span>
            to the selected beat plan. To continue, click save.
          </p>
          <p *ngIf="type === 'Copy'  && selectedData.beat_plan_id" style="font-weight: 400;font-size: 12px;color: #2D2D2D; padding-left: 8px;">
            The selected beat plan retailers will be
            <span style="font-weight: 400;font-size: 12px;color: #379862; margin-left: 3px; margin-right: 3px;">added</span>
            to the newly selected beat plans, however they will
            <span style="font-weight: 400;font-size: 12px;color: #F6A82C; margin-left: 3px; margin-right: 3px;">continue to exist</span>
            in the current beat plans. To continue, click save.
          </p>
          <p *ngIf="!selectedData.beat_plan_id" style="font-weight: 400;font-size: 12px;color: #2D2D2D; padding-left: 8px;">
            <span style="font-weight: 400;font-size: 12px;color: #E24260; margin-left: 3px; margin-right: 3px;">No Beats available!</span>
          </p>
        </div>
      </div>

    </div>
    <div class="button-row" style="margin-top: 10px;">
      <div style="float: right; margin: 15px 30px 15px 0px;">
           <span style=" vertical-align:bottom;margin-top: 20px; margin-right: 15px;" (click)="closeDialog()">
           <a class="btn grey-btn" >CANCEL</a>
        </span>
        <button
          mat-button
          class="btn confirm-green-btn"
          (click)="moveBeatRetailers()"
          form="ngForm"
          [disabled]="beatplanretailerform.form.invalid"
        >
          SAVE
        </button>
      </div>
    </div>
  </div>
</section>
