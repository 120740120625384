import {Component, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() title: string;
  public currentApplicationVersion = environment.appVersion;

  constructor(private elRef: ElementRef,
              private renderer: Renderer2,
              private router: Router) {
  }

  ngOnInit() {

  }

}
