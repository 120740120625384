import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {ErrorHandler} from "../../error/error-handler";

@Component({
  selector: 'project-master-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class ProjectMasterReportComponent extends ReportBase implements IReport, OnInit {

  public attributeTypes = [];
  showCustomFilter = true;
  customFilter: any = {
    name: 'Active',
    key: 'active',
    value: 'true',
    values: [],
    show: true
  };
  apiData: any = [];
  uqTypes;

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Project Master Report');

    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.showDate = false;

    this.loadInBatches(10000, 'p_id');
    this.showProgressCustomText = true;


    this.customFilter.values = [
      {id: '', itemName: 'All'},
      {id: 'true', itemName: 'Active'},
      {id: 'false', itemName: 'Inactive'}
    ];

    this.API_URL = '/api/pwa_reports/getProjectMasterReport';
    this.configureGrid();
  }

  configureGrid() {


    this.columnDefs = [
      {
        headerName: "",
        children: [
          {
            headerName: 'REGION', field: 'region', enableRowGroup: true,
            valueGetter(params) {
              if (params.data) {
                if (params.data.region) {
                  return params.data.region;
                } else {
                  return 'NA';
                }
              }
            }
          },
          {
            headerName: 'CLUSTER', field: 'cluster', enableRowGroup: true,
            valueGetter(params) {
              if (params.data) {
                if (params.data.cluster) {
                  return params.data.cluster;
                } else {
                  return 'NA';
                }
              }
            }
          },
          {headerName: 'ID', field: 'p_id', width: 80},
          {headerName: 'CODE', field: 'p_code', width: 80},
          {headerName: 'NAME', field: 'p_name', width: 100},
          {headerName: 'MOBILE', field: 'mobile', width: 100},
          {headerName: 'GST', field: 'gst', width: 100},
          {headerName: 'TYPE', field: 'p_type', enableRowGroup: true, width: 80},
          {headerName: 'CHANNEL', field: 'channel', enableRowGroup: true, hide: true, width: 80},
          {headerName: 'PROGRAM', enableRowGroup: true, field: 'program', hide: true, width: 100},
          {headerName: 'POTENTIAL', field: 'target', width: 50},
          {headerName: 'ADDRESS', field: 'p_address', width: 80},
          {headerName: 'CITY', field: 'p_city', enableRowGroup: true, width: 60},
          {headerName: 'ZIP', field: 'p_zip', enableRowGroup: true, width: 60},
          {headerName: 'STATE', field: 'state', enableRowGroup: true, width: 80},
          {headerName: 'DISTRICT', field: 'zone', enableRowGroup: true, width: 80},
          {headerName: 'STATEUS', field: 'status', enableRowGroup: true, width: 80},
          {headerName: 'CREATED ON', field: 'created_on', enableRowGroup: true, width: 100},
          {headerName: 'CREATED BY', field: 'create_name', enableRowGroup: true, width: 60},
          {headerName: 'LAST MODIFIED', field: 'writed_on', enableRowGroup: true, width: 80},
          {headerName: 'LAST MODIFIED BY', field: 'write_name', enableRowGroup: true, width: 80},
          {headerName: 'TCODE', field: 'territory_code', enableRowGroup: true, width: 60},
          {headerName: 'TERRITORY', field: 'territory', enableRowGroup: true, width: 60},
          {headerName: 'OTP VERIFIED', field: 'otp_verified', hide: true, width: 50},
          {headerName: 'VERIFIED', field: 'is_verified', hide: true, width: 50}
        ]
      }
    ];
    if (this.uqTypes) {
      let headercount = 1;
      for (const params in this.uqTypes) {
        this.columnDefs.push(
          {
            headerName: params,
            children: []
          }
        );
        for (const key of this.uqTypes[params]) {
          this.columnDefs[headercount].children.push(
            {headerName: key, field: params + '_' + key, width: 80}
          );
        }
        headercount++;
      }
    }
  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }

  configureData() {
    this.uqTypes = [];
    this.apiData.forEach(obj => {
      if (obj.params) {
        if (obj.params.constructor !== Object) {
          try {
            obj.params = JSON.parse(obj.params);
          } catch (e) {
            console.log(obj);
          }
        }
        if (obj.params && obj.params.length > 0) {
          for (const params of obj.params) {
            if (!params.s) {
              params.s = " ";
            }
            if (!this.uqTypes[params.s]) {
              this.uqTypes[params.s] = new Set();
            }
            this.uqTypes[params.s].add(params.n);
            obj[params.s + '_' + params.n] = params.v;
          }
        }

        if (obj.params.params && obj.params.params.length > 0) {
          for (const params of obj.params.params) {
            if (!params.s) {
              params.s = " ";
            }
            if (!this.uqTypes[params.s]) {
              this.uqTypes[params.s] = new Set();
            }
            this.uqTypes[params.s].add(params.n);
            obj[params.s + '_' + params.n] = params.v;
          }
        }
      }
    });
    this.rowData = this.apiData;
    // console.log(this.rowData);
    this.configureGrid();
  }

}
