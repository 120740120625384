import {Component, ElementRef, NgZone, OnInit, Renderer2} from "@angular/core";
import {Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {ApiService} from "../../_services/api.service";
import {SharedService} from "../../_services/shared_service";
import {PageBase} from "../../pagebase/pagebase";

@Component({
  selector: 'app-user_survey_list-component',
  templateUrl: './../../pagebase/pagebase.html',
  styleUrls: ['./../../pagebase/pagebase.scss']
})
export class UserSurveyListComponent extends PageBase implements OnInit {
  ngOnInit(): void {
    this.sharedService.setHeader('User Survey');
    this.showDate = false;
    this.userData.customFilter['type'] = 'survey';
    this.configureGrid();
    this.loadServerData();
  }

  configureGrid() {
    this.API_URL = '/api/crm_profiling_questionnaires/get_user_survey_list';
    this.columnDefs = [
      {headerName: "CODE",  field: "code", width: 150},
      {headerName: "QUESTIONNAIRE",  field: "name", width: 150},
      {headerName: "NO OF QUESTIONS",  field: "questions", cellStyle: {textAlign: 'right'},   width: 80},
      {headerName: "START DATE",  field: "start_date", width: 100},
      {headerName: "END DATE",  field: "end_date", width: 80},
      {headerName: "FREQUENCY",  field: "period", width: 80},
      {
        headerName: "SCORE MTD  %",  field: "score", cellStyle: {textAlign: 'center'},   width: 80,
        valueGetter(params) {
          return Math.round((params.data.score + Number.EPSILON) * 100) / 100;
        }
      },
      {headerName: "COMPLETED MTD",  field: "completed", cellStyle: {textAlign: 'center'},   width: 80},
    ];
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    localStorage.setItem('surveytype', 'user');
    localStorage.setItem('surveyid', rows[0].id);
    this.router.navigate([this.apiService.redirect_url + '/survey_detail'], {state: {
        type: 'detail',
        surveyid : rows[0].id
      }});
  }
}
