import {Component, OnInit} from '@angular/core';
import {ColorCellRenderer} from '../colorCell.component';
import * as XLSX from "xlsx-js-style";
import {PageBase} from "../pagebase/pagebase";

@Component({
  selector: 'app-posales',
  templateUrl: '../pagebase/pagebase.html',
  styleUrls: ['../pagebase/pagebase.scss']
})
export class PurchaseOrderComponent extends PageBase implements OnInit {

  res_data;
  po_data;
  /*user;
  res_data;
  user_data = {
    date: null,
    dc_id: null,
    access_token: null,
    url: null,
    offset: null,
    loadout_data: null,
    dc_centers: [],
  };*/

  /*constructor(private apiService: ApiService,
              private router: Router,
              private elRef: ElementRef,
              private renderer: Renderer2,
              private datePipe: DatePipe,
              private dateTimeAdapter: DateTimeAdapter<any>) {

    this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.user_data.date = localStorage.getItem('my_date');
    if (this.user_data.date === null || this.user_data.date === 'null') {
      this.user_data.date = this.myDate;
      localStorage.setItem('my_date', this.myDate);
    } else {
      this.myDate = this.user_data.date;
    }
    this.user_data.offset = -1 * (new Date(this.user_data.date)).getTimezoneOffset();
    this.user_data.dc_id = JSON.parse(localStorage.getItem('user')).partner_id;
    this.user_data.dc_centers = JSON.parse(localStorage.getItem('user')).dc_centers;
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');

    localStorage.setItem('user_data', JSON.stringify(this.user_data));

    dateTimeAdapter.setLocale('en-IN');

    this.apiDcPoList();

    /!* this.gridOptions = { rowHeight: 35,
                                       rowStyle: { 'text-align': 'left', padding: '20px 0px' },
                                       rowSelection: 'single',
                                       suppressAggFuncInHeader: true,
                                       pivotMode: false,
                                       groupHideOpenParents: true,
                                       groupDefaultExpanded: 9,
                                       groupMultiAutoColumn: true,
                                       autoGroupColumnDef: {
                                         pinned: 'left'
                                       },
                                       onRowSelected: params => {
                                         localStorage.setItem('purchase_params', JSON.stringify(params.data));
                                         this.router.navigate([this.apiService.redirect_url + '/po2']);
                                       }
                                     } as GridOptions;*!/
  }*/

  ngOnInit() {

    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable === 'true' ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
    this.sharedService.setHeader('Purchase Order');

    this.uploadTask = true;
    this.frameworkComponents['colorCellRenderer'] = ColorCellRenderer;
    this.showExcelSample = true;
    //this.excel_sample = 'https://docs.google.com/spreadsheets/d/1l0P-pxS_vC99Ceg3rQI066MCtY_4SJqldApoKZM8Ia0/edit?usp=sharing';
    const date = new Date();
    const start_date = this.datePipe.transform(date, 'dd/MM/yyyy');
    date.setDate(date.getDate() + 1);
    const end_date = this.datePipe.transform(date, 'dd/MM/yyyy');
    this.excel_sample = [
      {
        OutCode: '4074',
        PO: '12151736',
        ProductCode: '280',
        Qty: 180,
        StartDate: start_date,
        ExpiryDate: end_date
      },
      {
        OutCode: '4074',
        PO: '12151736',
        ProductCode: '281',
        Qty: 120,
        StartDate: start_date,
        ExpiryDate: end_date
      },

    ];
    this.excel_sample_title = 'purchase_order';

    this.userData['dc_id'] = JSON.parse(localStorage.getItem('user')).partner_id;
    this.userData['dc_centers'] = JSON.parse(localStorage.getItem('user')).dc_centers;
    this.userData['date'] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.configureGrid();
    this.loadServerData();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_connects/get_pos';
    this.columnDefs = [
      {headerName: "DC", field: "dc", width: 100},
      {headerName: "VAN", field: "van", width: 100},
      {headerName: "PO", field: "po", width: 100},
      {headerName: "OUTLET", field: "outlet", width: 200},
      {headerName: "CODE", field: "outlet_code", width: 100},
      {headerName: "START DATE", field: "sale_date", width: 100},
      {headerName: "EXPIRY DATE", field: "validity_date", width: 100},
      {
        headerName: 'STATUS', field: 'state', width: 100,
        cellStyle: params => {
          if (params.value === 'draft') {
            return {color: '#987E46'};
          } else if (params.value === 'cancel') {
            return {color: '#AD1D30'};
          } else if (params.value === 'sale') {
            return {color: '#59AA5A'};
          } else {
            return {color: '#F6A82C'};
          }
        },
        cellRenderer: params => {
          let displayElem = '';
          if (params.value === 'draft') {
            displayElem = '<span class="ml-1 status" style="background-color:#FFF4D8; border:#FFF4D8">' + params.value + '</span>';
          } else if (params.value === 'cancel') {
            displayElem = '<span class="ml-1 status" style="background-color:#FAD5D5; border:#FAD5D5">' + params.value + '</span>';
          } else if (params.value === 'sale') {
            displayElem = '<span class="ml-1 status" style="background-color:#DEF8DC; border:#DEF8DC">' + 'complete' + '</span>';
          } else {
            displayElem = '<span class="ml-1 status" style="background-color:#F6A82C; border:#F6A82C">' + params.value + '</span>';
          }
          return displayElem;
        }
      },
      {headerName: "out_id", field: "out_id", width: 100, hide: true}
    ];
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    localStorage.setItem('purchase_params', JSON.stringify(rows[0]));
    this.router.navigate([this.apiService.redirect_url + '/po2']);
  }

  uploadedFile(event) {

    this.uploadCaption = ['', 0];
    this.fileUploaded = event.target.files[0];
    this.readExcel();
  }

  readExcel() {

    const readFile = new FileReader();
    readFile.onload = (e) => {
      this.storeData = readFile.result;
      const data = new Uint8Array(this.storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, {type: 'binary'});
      const first_sheet_name = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[first_sheet_name];

      this.upload_task_data = XLSX.utils.sheet_to_json(this.worksheet, {raw: true});
      this.po_data = XLSX.utils.sheet_to_json(this.worksheet, {raw: true});
      console.log(XLSX.utils.sheet_to_json(this.worksheet, {raw: true}));
    };
    readFile.readAsArrayBuffer(this.fileUploaded);
  }

  uploadexcel() {
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    this.uploading = true;
    this.upload_loader = true;
    this.userData['loadout_data'] = this.po_data;
    if (this.userData['dc_centers'].length === 0) {
      this.userData['dc_centers'].push(this.userData['dc_id']);
    }
    let invalid = false;
    for (const item of this.userData['loadout_data']) {
      try {
        if (!item['StartDate']) {
          this.toastr.error('Start Date cannot be empty');
          invalid = true;
          break;
        }
        if (!item['ExpiryDate']) {
          this.toastr.error('Start Date cannot be empty');
          invalid = true;
          break;
        }
        if (!item['OutCode']) {
          this.toastr.error('Outlet Code cannot be empty');
          invalid = true;
          break;
        }
        if (!item['ProductCode']) {
          this.toastr.error('Product Code cannot be empty');
          invalid = true;
          break;
        }
        if (!item['Qty']) {
          this.toastr.error('Quantity cannot be empty');
          invalid = true;
          break;
        }
        item['format_date'] = this.sharedService.convertExcelStringToDate(item['StartDate']);
        if (item['format_date'] === '') {
          invalid = true;
          break;
        }
        item['StartDate'] =  item['format_date'];
        item['format1_date'] = this.sharedService.convertExcelStringToDate(item['ExpiryDate']);
        if (item['format1_date'] === '') {
          invalid = true;
          break;
        }
        item['ExpiryDate'] =  item['format_date1'];
      } catch (e) {
        invalid = true;
        this.toastr.error('Invalid Date');
      }
    }
    if (!invalid) {
      this.res_data = this.apiService.poUpload(this.userData);
      this.res_data.subscribe(res => {
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
          console.log(res);
          this.uploading = false;
          this.upload_loader = false;
          if (res.hasOwnProperty('results')) {
            if (res.results.statusCode === 200) {
              this.uploadCaption = [res.results.msg, 0];
            } else {
              this.uploadCaption = [res.results.msg, 1];
            }
          }
          this.po_data = [];

          this.fileUp.nativeElement.value = null;

          this.loadServerData();

        },
        error => {
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
          console.log(error);
          this.uploading = false;
          this.upload_loader = false;
          if (error.error.error.msg === undefined) {
            this.uploadCaption = ['Upload Failed', 1];
          } else {
            this.uploadCaption = [error.error.error.msg, 1];
          }

          this.po_data = [];
          this.fileUp.nativeElement.value = null;
        });
    } else {
      this.uploading = false;
      this.upload_loader = false;
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    }

  }
  /*apiDcPoList() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];
    const rowdata = [];

    this.res_data = this.apiService.getPos(this.userData);
    this.res_data.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data.length > 0)) {
          res.results.data.forEach(data => {

            rowdata.push({
              DC: data.dc,
              VAN: data.van,
              PO: data.po,
              OUTLET: data.outlet,
              CODE: data.outlet_code,
              'START DATE': data.sale_date,
              'EXPIRY DATE': data.validity_date,
              STATUS: data.state,
              out_id: data.out_id
            });
          });
          this.data = rowdata[0];
          this.rowData = rowdata;

          for (const key in this.data) {
            if (this.data.hasOwnProperty(key)) {
              if (key === 'STATUS') {
                this.columnDefs.push({
                  headerName: key, field: key, unSortIcon: true,
                  cellStyle(params) {
                    if (params.value !== null && params.value !== undefined) {
                      if (params.value === 'draft') {
                        return {'background-color': 'SkyBlue'};
                      } else if (params.value === 'cancel') {
                        return {'background-color': 'LightCoral'};
                      } else if (params.value === 'sale') {
                        return {'background-color': 'MediumSeaGreen'};
                      }
                    }
                  }
                });

              } else if (key === 'out_id') {
                this.columnDefs.push({
                  headerName: key, field: key, hide: true, unSortIcon: true,
                });
              } else {
                this.columnDefs.push({
                  headerName: key, field: key, unSortIcon: true,
                });
              }
            }
          }
          this.loaded = true;

          this.frameworkComponents = {
            colorCellRenderer: ColorCellRenderer
          };

        } else {
          this.preloader = false;
          this.empty_state = true;
        }

      },
      error => {
        console.log(error);
        this.preloader = false;
      });
  }*/


  /*onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData);
    this.preloader = false;
    this.gridApi.setDomLayout('autoHeight');
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    if (screen.width > 991) {
      this.gridOptions.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }*/


}
