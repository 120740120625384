<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" style="float: right;background-color:#FDE0D4;">
        <i class="fas fa-times"></i>
      </button>
      <h2>Location</h2>
    </div>
    <div class="body-section w-100" style="text-align: center;">
      <div  class="map" id="map1" style="margin-top: 20px;">
        <agm-map
          [latitude]="latitude"
          [longitude]="longitude"
          [zoom]="zoom" >
          <agm-marker [latitude]="latitude" [longitude]="longitude"  [label]="{ text: title1}">
          </agm-marker>
          <agm-marker *ngIf="endLatitude && endLatitude!=null && endLatitude!=0"
                      [latitude]="endLatitude" [longitude]="endLongitude" [label]="{ text: title2}">
          </agm-marker>
        </agm-map>
      </div>
      <!--<div id="infowindow-content1">
        <span id="place-name1" class="title"></span><br />
        <span id="place-address"></span>
      </div>
      <div id="infowindow-content2">
        <span id="place-name2" class="title"></span><br />
      </div>-->
    </div>
  </div>
</section>
