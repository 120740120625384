import {Component, ElementRef, NgZone, OnInit, Renderer2, ViewChild} from "@angular/core";
import {PageBase} from "../pagebase/pagebase";
import {MatDialogConfig} from "@angular/material/dialog";
import {TaskPlannerCreateUploadComponent} from "./task_planner/task_planner_create_upload.component";
import {TaskActivationUploadComponent} from "./task_activation_upload.component";

@Component({
  selector: 'app-task',
  templateUrl: '../pagebase/pagebase.html',
  styleUrls: ['./task.component.scss']
})
export class TaskComponent extends PageBase implements OnInit, IPage {

  task_types = [];
  ngOnInit(): void {

    this.sharedService.setHeader('Tasks');
    if (this.sharedService.role_features && this.sharedService.ACLcheck('feature_upload_task')) {
      this.showBaseUpload = true;
    }
    this.task_types = JSON.parse(localStorage.getItem('task_types'));
    const date = new Date();
    this.myDate[0] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd');
    this.showExcelSample = true;
    this.excel_sample = [
      {
        TaskCode: 'JWBSK1099866',
        TaskName: 'JWB Shelf Presence',
        Type: 'Activation',
        RetailerCode: 'RJ42686',
        'Start Date': this.myDate[0],
        'End Date': this.myDate[1]
      },
      {
        TaskCode: 'JWBSK1099866',
        TaskName: 'JWB Shelf Presence',
        Type: 'Activation',
        RetailerCode: 'RJ42686',
        'Start Date': this.myDate[0],
        'End Date': this.myDate[1]
      }
    ];
    this.excel_sample_title = 'task_upload';
    this.statusConfig.success = 'approved';
    this.statusConfig.warning = 'updated';
    this.statusConfig.error = 'rejected';
    this.statusConfig.new = 'draft';
    this.statusConfig.processed = 'published';
    this.configureGrid();
    this.loadServerData();
  }

  configureGrid() {
    const inThis = this;
    this.API_URL = '/api/tasks/get_task_list';
    this.columnDefs = [
      {headerName: "CODE",  field: "code",     width: 100},
      {headerName: "TASK",  field: "name",     width: 250},
      {headerName: "DESCRIPTION",  field: "description",     width: 150},
      {headerName: "TYPE",  field: "type_name",     width: 100},
      {headerName: "BRAND",  field: "brand",     width: 100},
      {headerName: "QCODE",  field: "q_code",     width: 100},
      {headerName: "QUESTIONNAIRE",  field: "q_name",     width: 150},
      {headerName: "OWNER",  field: "user_name",     width: 100},
      {
        headerName: "CREATE DATE", field: "create_date", width: 150,
        valueGetter: params => {
          if (params.data) {
            if (params.data.create_date) {
              return inThis.datePipe.transform((new Date(params.data.create_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return params.data.create_date;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: "START DATE",  field: "start_date",     width: 100},
      {headerName: "END DATE",  field: "end_date",     width: 100},
      {headerName: "NO OF OUTLET",  field: "line_count",   cellStyle: {textAlign: 'right'},   width: 100},
      {
        headerName: "PROGRESS",  field: "progress",   width: 120, menuTabs: [],

        valueGetter: params => {
          if (parseInt(params.data.line_count, 10) === 0) {
            return 0;
          } else {
            return Math.trunc((parseInt(params.data.completed_count, 10) / parseInt(params.data.line_count, 10)) * 100);
          }

        },
        cellRenderer: params => {
          let displayElem = '<div class="row">\n' +
            '            <div class="progresscontainer1">\n' +
            '              <div class="progressinside1" style=" ';

          if (params.value > 50) {
            displayElem = displayElem + ' background-color: #379862;';
          } else if (params.value > 20) {
            displayElem = displayElem + ' background-color: #D97C0E;';
          } else {
            displayElem = displayElem + ' background-color: #E24260;';
          }

          displayElem = displayElem + 'width:' + params.value + '%;"></div>\n' +
            '            </div>\n' +
            '            <div class="progresstext1" style="';

          if (params.value > 50) {
            displayElem = displayElem + ' color: #379862;';
          } else if (params.value > 20) {
            displayElem = displayElem + ' color: #D97C0E;';
          } else {
            displayElem = displayElem + ' color: #E24260;';
          }

          displayElem = displayElem + '">' + params.value + '%</div></div>';


          return displayElem;
        }
      },
      {headerName: "COMPLETED NO",  field: "completed_count", cellStyle: {textAlign: 'right'},   width: 100},
      {headerName: "STATUS",  field: "status", width: 100, cellRenderer: inThis.sharedService.statusCellRenderer},
    ];
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.setRowData(this.rowData);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      });
    });
    this.gridApi.sizeColumnsToFit();
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }
  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    localStorage.setItem('taskid', rows[0].id);
    this.router.navigate([this.apiService.redirect_url + '/task/task_detail'], {
      state: {
        viewtype: 'detail',
        taskid: rows[0].id
      }
    });
  }

  showUploadPopup() {
    let creatediaeref;
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "90%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
      };
      creatediaeref = this.dialog.open(TaskActivationUploadComponent, config);
      creatediaeref.afterClosed().subscribe(result => {
        this.loadServerData();
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
      };
      creatediaeref = this.dialog.open(TaskActivationUploadComponent, config);
      creatediaeref.afterClosed().subscribe(result => {
        this.loadServerData();
      });
    }
  }


}
