import {Component, OnInit} from "@angular/core";
import {ReportBase} from "../_common/reportbase";

@Component({
  selector: 'app-claim-scheme-order-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class ClaimSchemeOrderReportComponent extends ReportBase implements IReport, OnInit  {

  showCustomFilter = true;
  customFilter: any = {
    name: 'Sale Type',
    key: 'type',
    value: 'P',
    values: [],
    show: true
  };

  setTitle() {
    this.title = 'Scheme Claim Disbursement Report';
  }

  ngOnInit() {
    this.sharedService.setHeader('Claim Scheme Order Disbursement');
    this.configureGrid();

    this.showableFilters.dc = true;
    this.userData.customFilter['type'] = 'P';
    this.customFilter.values = [
      {id: "P", itemName: "Primary"},
      {id: "S", itemName: "Secondary"},
    ];
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getClaimSchemeOrderReport';
    this.columnDefs = [
      {headerName: 'State', field: 'state', width: 100},
      {headerName: 'Supplier ID', field: 'supp_id', hide: true, width: 100},
      {headerName: 'Supplier Code', field: 'supp_code', width: 100},
      {headerName: 'Supplier Name', field: 'supp_name', width: 100},
      {headerName: 'Customer ID', field: 'cust_id', hide: true, width: 100},
      {headerName: 'Customer Code', field: 'cust_code', width: 100},
      {headerName: 'Customer Name', field: 'cust_name', cellStyle: {textAlign: 'center'}, width: 100},
      /*{ headerName: 'Sale Type',  field: 's_type',
        width: 100,
        valueGetter: params => {
          if (params.data) {
            if (params.data.s_type) {
              if (params.data.s_type === 'S') {
                return 'Secondary';
              } else if (params.data.s_type === 'P') {
                return 'Primary';
              }
            }
          }
        }
        },*/
      {headerName: 'Scheme Type', field: 'scheme_type', width: 100},
      {headerName: 'Scheme Code', field: 'scheme_code', width: 100},
      {headerName: 'Scheme Name', field: 'scheme_name', width: 100},
      { headerName: 'Order',  field: 'order', width: 80},
      {headerName: 'Prod Code', field: 'prod_code', width: 100},
      {headerName: 'Prod Name', field: 'prod_name', width: 100},
      {headerName: 'Type', field: 'type', width: 100},
      {
        headerName: 'Promo Qty', field: 'promo_qty', cellStyle: {textAlign: 'right'}, width: 80,
        valueGetter: params => {
          if (params.data) {
            if (!params.data.promo_qty) {
              params.data.promo_qty = 0;
            }
            return params.data.promo_qty;
          }
        }, aggFunc: 'sum'
      },
      {
        headerName: 'Cash Discount', field: 'cash_disc', cellStyle: {textAlign: 'right'}, width: 100,
        valueGetter: params => {
          if (params.data) {
            if (!params.data.cash_disc) {
              params.data.cash_disc = 0;
            }
            return params.data.cash_disc;
          }
        }, aggFunc: 'sum'
      },
      {
        headerName: 'Perc Discount', field: 'perc_scheme_discount', hide: true, cellStyle: {textAlign: 'right'},
        width: 100,
        valueGetter: params => {
          if (params.data) {
            if (!params.data.perc_scheme_discount) {
              params.data.perc_scheme_discount = 0;
            }
            return params.data.perc_scheme_discount;
          }
        }, aggFunc: 'sum'
      },
      {
        headerName: 'Promo Discount', field: 'promo_scheme_discount', hide: true, cellStyle: {textAlign: 'right'},
        width: 100,
        valueGetter: params => {
          if (params.data) {
            if (!params.data.promo_scheme_discount) {
              params.data.promo_scheme_discount = 0;
            }
            return params.data.promo_scheme_discount;
          }
        }, aggFunc: 'sum'
      },
      {
        headerName: 'Order Discount', cellStyle: {textAlign: 'right'}, width: 100,
        valueGetter: params => {
          if (params.data) {
            let order_disc = 0;
            if (params.data.cash_disc) {
              order_disc = order_disc + parseFloat(params.data.cash_disc);
            }
            if (params.data.perc_scheme_discount) {
              order_disc = order_disc + parseFloat(params.data.perc_scheme_discount);
            }
            if (params.data.promo_scheme_discount) {
              order_disc = order_disc + parseFloat(params.data.promo_scheme_discount);
            }
            return order_disc;
          }
        }, aggFunc: 'sum'
      },
      {
        headerName: 'Line Discount', field: 'line_scheme_discount', cellStyle: {textAlign: 'right'},
        width: 100, aggFunc: 'sum'
      },
      {
        headerName: 'Line  Total', field: 'price_total', hide: true, cellStyle: {textAlign: 'right'},
        width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.price_total) {
              return parseFloat(params.data.price_total).toFixed(2);
            } else {
              return 0;
            }
          }
        }, aggFunc: 'sum'
      },
      {headerName: 'Amount Total', field: 'amount_total', cellStyle: {textAlign: 'right'}, width: 80, aggFunc: 'sum'},
      { headerName: 'Delivery Status',  field: 'd_status',
        width: 100}
    ];
  }


}
