<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" style="float: right;background-color:#FDE0D4;">
        <i class="fas fa-times"></i>
      </button>
      <h2>CUSTOMERS</h2>
      <!-- <button class="close-dialog" (click)="closeDialog()">
         <i class="fas fa-times"></i>
       </button>-->
    </div>
    <div class="body-section">
      <div class="search" style="display: inline-block;margin-top: 40px;margin-right: 15px;">
        <span class="search-control"><img src="assets/searchicon.png"/></span>
        <input  id="filter-text-box1" type="text" placeholder="filter" (input)="quickSearch()" class="search-control-area"
                placeholder="Search">
      </div>

      <div class="col-md-12 pad0" style="margin-top: 30px;">
        <div>
          <ag-grid-angular *ngIf=loaded id="myGrid1" style="width: 100%;height:280px;" class="ag-theme-balham"   [defaultColDef]="defaultColDef"
                           [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                           (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                           [context]="context">
          </ag-grid-angular>
          <div *ngIf="preloader">
            <app-loadingstate></app-loadingstate>
          </div>
          <div *ngIf="emptyState">
            <app-emptystate></app-emptystate>
          </div>
        </div>
      </div>



    </div>
  </div>
</section>
