import {ReportBase} from '../_common/reportbase';
import {Component, OnInit} from '@angular/core';
import {MatDialogConfig} from '@angular/material/dialog';
import {ImageComparePopupComponent} from '../../components/image_compare_popup/image_compare_popup.component';

@Component({
  selector: 'app-shelf-analysis-report',
  templateUrl: '../_common/reportbase.html',

})
// tslint:disable-next-line:component-class-suffix
export class ShelfAnalysisReport extends ReportBase implements IReport, OnInit {

  ngOnInit() {
    this.setTitle();
    this.sharedService.setHeader('Shelf Analysis Report');
    this.gridOptions.autoGroupColumnDef = {
      headerName: 'Salesman',
      field: 'user_name',
      minWidth: 200,
      cellRenderer: 'agGroupCellRenderer',
      resizable: true
    };
    this.container = 'res_partner_gallery';
    this.configureGrid();
  }

  setTitle() {
    this.title = 'Shelf Analysis report';
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/get_shelf_analysis';
    this.showableFilters.salesman = true;
    this.columnDefs = [
      {headerName: 'Region', field: 'region', hide: true, rowGroup: true, width: 80},
      {headerName: 'Salesman', field: 'user_name', hide: true, rowGroup: true, width: 80},
      {headerName: 'State', field: 'state', width: 50},
      {headerName: 'Zone', field: 'zone', hide: true, width: 80},
      {headerName: 'Code', field: 'code', width: 50},
      {headerName: 'Outlet', field: 'name', width: 80},
      {headerName: 'Type', field: 'outlet_type', width: 50},
      {headerName: 'class', field: 'class_type', width: 50},
      {headerName: 'Area', field: 'area', hide: true, width: 50},
      {headerName: 'Date', field: 'create_date',  width: 60},
      {headerName: 'Category', field: 'category', width: 60},
      {
        headerName: 'Before', field: 'visibility', width: 50,
        cellStyle(params) {
          if (params.value === 'high') {
            return {textAlign: 'center', backgroundColor: '#B3DFB0'};
          } else if (params.value === 'low') {
            return {textAlign: 'center', backgroundColor: '#F8D1C8'};
          } else if (params.value === 'medium') {
            return {textAlign: 'center', backgroundColor: '#F0E199'};
          } else {
            return {textAlign: 'center'};
          }

        }
      },
      {
        headerName: 'Before Score%', field: 'visibility', width: 50, aggFunc: this.avgAggFunction,
        cellStyle(params) {
          if (params.value === 100) {
            return {textAlign: 'center', backgroundColor: '#B3DFB0'};
          } else if (params.value >= 50) {
            return {textAlign: 'center', backgroundColor: '#F0E199'};
          } else if (params.value >= 25) {
            return {textAlign: 'center', backgroundColor: '#F8D1C8'};
          } else {
            return {textAlign: 'center'};
          }
        },
        valueGetter(params) {
          if (params.data) {
            if (params.data.visibility === 'high') {
              return 100;
            } else if (params.data.visibility === 'medium') {
              return 50;
            } else if (params.data.visibility === 'high') {
              return 25;
            } else {
              return 0;
            }
          }
        }

      },
      {
        headerName: 'Image', field: 'store_fname', menuTabs: [], width: 40,
        valueGetter: this.generateImageUrl('store_fname'),
        cellRenderer(params) {
          if (params.data) {
            if (params.data.store_fname == null || params.data.store_fname === '') {
              return '<img style="padding:2px;" src="../../../assets/images/bill-disable.png"></img>';
            } else {
              return '<img style="padding:2px;" src="../../../assets/bill.png"></img>';
            }
          }
        }
      },
      {
        headerName: 'After', field: 'after_visibility', width: 50,
        cellStyle(params) {
          if (params.value === 'high') {
            return {textAlign: 'center', backgroundColor: '#B3DFB0'};
          } else if (params.value === 'low') {
            return {textAlign: 'center', backgroundColor: '#F8D1C8'};
          } else if (params.value === 'medium') {
            return {textAlign: 'center', backgroundColor: '#F0E199'};
          } else {
            return {textAlign: 'center'};
          }
        }
      },
      {
        headerName: 'After Score%', field: 'after_visibility', width: 50, aggFunc: this.avgAggFunction,
        cellStyle(params) {
          if (params.value === 100) {
            return {textAlign: 'center', backgroundColor: '#B3DFB0'};
          } else if (params.value >= 50) {
            return {textAlign: 'center', backgroundColor: '#F0E199'};
          } else if (params.value >= 25) {
            return {textAlign: 'center', backgroundColor: '#F8D1C8'};
          } else {
            return {textAlign: 'center'};
          }
        },
        valueGetter(params) {
          if (params.data) {
            if (params.data.after_visibility === 'high') {
              return 100;
            } else if (params.data.after_visibility === 'medium') {
              return 50;
            } else if (params.data.after_visibility === 'high') {
              return 25;
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: 'Image', field: 'after_store_fname', menuTabs: [], width: 40,
        valueGetter: this.generateImageUrl('after_store_fname'),
        cellRenderer(params) {
          if (params.data) {
            if (params.data.after_store_fname == null || params.data.after_store_fname === '') {
              return '<img style="padding:2px;" src="../../../assets/images/bill-disable.png"></img>';
            } else {
              return '<img style="padding:2px;" src="../../../assets/bill.png"></img>';
            }
          }
        }
      },
      {headerName: 'Comments', field: 'comment', width: 50},

    ];
  }

  onCellClicked(event) {
    const rows = this.gridApi.getSelectedRows();
    if (rows != null && rows.length > 0 && rows[0].hasOwnProperty('after_store_fname') && rows[0].after_store_fname != null && rows[0].after_store_fname != '') {
      if (screen.width > 991) {
        const config: MatDialogConfig = {
          width: '100vw',
          height: '100vh',
          maxWidth: '70%',
          maxHeight: '95%',
          data: {
            store_fname1: rows[0].store_fname,
            store_fname2: rows[0].after_store_fname,
            beforevis: rows[0].visibility,
            aftervis: rows[0].after_visibility,
            comments: rows[0].comment,
            category: rows[0].category,
            container: 'res_partner_gallery'
          }
        };
        const creatediaeref = this.dialog.open(ImageComparePopupComponent, config);
      } else {
        const config: MatDialogConfig = {
          width: '100vw',
          height: '100vh',
          maxWidth: '90%',
          maxHeight: '65%',
          data: {
            store_fname1: rows[0].store_fname,
            store_fname2: rows[0].after_store_fname,
            beforevis: rows[0].visibility,
            aftervis: rows[0].after_visibility,
            comments: rows[0].comment,
            category: rows[0].category,
            container: 'res_partner_gallery'
          }
        };
        const creatediaeref = this.dialog.open(ImageComparePopupComponent, config);
      }
    }
  }
}
