import {Component, OnInit} from '@angular/core';
import {ReportBase} from "../_common/reportbase";

@Component({
  selector: 'app-van-productivity-report',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})
export class CancelReportComponent extends ReportBase implements IReport, OnInit {

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    this.sharedService.setHeader('Cancellation Report');
    this.myDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.configureGrid();
    this.loadServerData();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_connects/cancel_report';
    const inThis = this;
    this.columnDefs = [
      {
        headerName: 'Cancelled Date', field: 'stock_date', width: 100,
        valueGetter: params => {
          if (params.data) {
            if (params.data.stock_date) {
              return inThis.datePipe.transform((new Date(params.data.stock_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return params.data.stock_date;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: 'Cancelled By', field: 'user', width: 100},
      {headerName: 'Remarks', field: 'comments', width: 100},
      {headerName: 'State', field: 'state', width: 100},
      {headerName: 'Region', field: 'region', width: 100},
      {headerName: 'DC Code', field: 'code', width: 100},
      {headerName: 'DC', field: 'dc', width: 100},
      {headerName: 'Van', field: 'van', width: 100},
      {headerName: 'TI', field: 'ti', width: 100},
      {headerName: 'Beat', field: 'beat', width: 100},
      {headerName: 'Zone', field: 'zone', width: 100},
      {headerName: 'Area', field: 'area', width: 100},
      {headerName: 'Pricelist', field: 'pricelist', width: 100},
      {headerName: 'Outlet Code', field: 'out_code', width: 100},
      {headerName: 'Outlet', field: 'outlet', width: 100},
      {headerName: 'Outlet Type', field: 'out_type', width: 100},
      {headerName: 'Sale Date', field: 'schedule_date', width: 100},
      {headerName: 'Type', field: 'order_type', width: 100},
      {headerName: 'Quantity', field: 'qty', width: 100},
      {headerName: 'Product Code', field: 'p_code', width: 100},
      {headerName: 'Product', field: 'product', width: 100},
      {headerName: 'Gross Value', field: 'gross_value', width: 100},
      {headerName: 'Invoice', field: 'invoice_no', width: 100},
      {headerName: 'InvoiceID', field: 'invoice', width: 100},
      {headerName: 'Tax Amount', field: 'tax', width: 100},
      {headerName: 'Net Value', field: 'net_value', width: 100},
      {headerName: 'Payment Mode', field: 'payment_mode', width: 100},
      {headerName: 'Category', field: 'category', width: 100},
      {headerName: 'PO', field: 'po', width: 100},
      {headerName: 'GRN', field: 'grn', width: 100}
    ];
  }
}
