import {Component, ElementRef, Renderer2} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "mt-cell-editor",
  template: `<div style="margin-top: 6%;" class="po-top po-page" layout="row">` +
    `<span style="color: lightgrey;font-weight: bolder;font-size: 120%;padding-right: 6%;">{{sug}}</span>` +
    `<input *ngIf="!auto_update" [disabled]="!edit_enable" (ngModelChange)="modelChanged($event)" [(ngModel)]="searchValue" autocomplete="off" type="text" style="font-size: 150%;" class="custom-inputbox"/>` +
    `<input *ngIf="auto_update" [disabled]="!edit_enable" (ngModelChange)="modelChanged($event)" [(ngModel)]="ord" autocomplete="off" type="text" style="font-size: 150%;" class="custom-inputbox"/>` +
    `<span style="font-size: 100%;padding-left: 5%;" class="element-req">{{ord}}</span>` +
    `<span *ngIf="scheme" style="font-size: 100%;color: green;position: relative;top: 3vh;right: 4vw;}">1 offer</span>` +
    `</div><div *ngIf="showSug"><span style="color: #2e5bff">{{mbqType}}</span></div>`
})
export class MtCellEditComponent implements ICellRendererAngularComp {
  public params: any;
  public sug;
  public ord;
  public searchValue;
  public scheme;
  public edit_enable = false;
  public auto_update = false;
  public total;
  public prvs = {};
  public actChannelProd;
  public mbqType = "";
  public showSug = false;

  constructor(private elRef: ElementRef,
              private renderer: Renderer2) {
  }

  agInit(params: any): void {

    this.params = params;
    if (this.params.value == null) {
      this.searchValue = 0;
      this.sug = 0;
      this.ord = 0;
      this.scheme = false;
      this.showSug = false;
    } else {
      this.searchValue = params.value.qty;
      this.sug = params.value.sug;
      this.ord = params.value.stock;
      this.scheme = params.value.scheme;
      this.mbqType = params.value.mbq_type;
      this.showSug = params.value.showSug;
    }
    if (params.data.status === 'Suspended') {
      this.edit_enable = false;
    } else {
      if (params.data.invoice == null) {
        this.edit_enable = true;
      }
      if (this.params.context.componentParent.all_products[this.params.data.partner_id]) {
        const prods = this.params.context.componentParent.all_products[this.params.data.partner_id];
        if (prods[this.params.colDef.field].hide) {
          this.edit_enable = false;
        }
      }
    }

    this.total = this.params.context.componentParent.gridOptions.pinnedBottomRowData;
    this.actChannelProd = this.params.context.componentParent.actChannelProds;
  }

  modelChanged(event) {
    if (event !== '') {
      const chSplit = this.params.context.componentParent.actChannelProds[this.params.colDef.field];
      if (chSplit) {
        if (chSplit[this.params.data.type]) {
          chSplit[this.params.data.type] += (parseFloat(event) - parseFloat(this.params.value.qty));
        } else {
          chSplit[this.params.data.type] = parseFloat(event);
        }
        this.params.context.componentParent.actChannelProds[this.params.colDef.field] = chSplit;
      }

      //this.params.context.componentParent.actChannelProds[this.params.colDef.field] = chSplit;
      this.params.value.qty = parseInt(event, 10);
      //this.params.value['edited_value'] = parseInt(event, 10);
      if (this.prvs[this.params.data.order_id]) {
        this.total[0][this.params.colDef.field] += (this.params.value.qty - this.prvs[this.params.data.order_id]);
      } else {
        this.total[0][this.params.colDef.field] += this.params.value.qty;
      }
      this.prvs[this.params.data.order_id] = this.params.value.qty;
      this.params.context.componentParent.gridApi.setPinnedBottomRowData(this.total);
    }
  }

  refresh(): boolean {
    return false;
  }
}
