import {Component, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-task-publish-popup',
  templateUrl: './task_publish_popup.component.html',
  styleUrls: ['./task_publish_popup.component.scss']
})
export class TaskPublishPopupComponent implements OnInit {

  @Output() selectedAction: EventEmitter<any> = new EventEmitter<any>();
  brandList = [];
  selectedBrand: any = [];

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.brandList = JSON.parse(localStorage.getItem('all_brands'));
    //this.selectedBrand.push(this.brandList[0]);
  }

  ngOnInit(): void {

  }

  performAction(action) {
    this.selectedAction.emit(this.selectedBrand);
    this.dialog.closeAll();
  }

  closeDialog() {
    this.dialog.closeAll();
  }
}
