<!--<div id="overlay"></div>-->
<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>{{title}}</h2>
    </div>
    <div class="body-section" *ngIf="wizard_step === 1">
      <div>
        <div class="row">
          <span class="span-number">1</span>
          <span class="header-span">Download Template</span>
        </div>
        <div style="margin-left: 40px;margin-top: 10px;">
          <div class="col-md-6 pl-0 pr-10">
            <div class="card-box" (click)="downloadBeatPlanData()">
              <span style="margin-left: 2px;"><i class="far fa-arrow-to-bottom fa-2x"></i></span>
              <span style="color:#183153; margin-left: 15px;font-size: 14px;font-weight: 500;">DOWNLOAD TEMPLATE WITH DATA</span>
            </div>
          </div>
          <div class="col-md-6 pl-0 pr-10">
            <div class="card-box"  (click)="downloadBeatPlanTemplate()">
              <span style="margin-left: 2px;"><i class="far fa-arrow-to-bottom fa-2x"></i></span>
              <span style="color:#183153; margin-left: 15px;font-size: 14px;font-weight: 500;">DOWNLOAD EXCEL TEMPLATE</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 pad0" style="margin-top: 30px;">
        <div class="row">
          <span class="span-number">2</span>
          <span class="header-span">Add or edit Beat info in the template</span>
        </div>
        <div style="margin-left: 40px;margin-top: 5px;">
          <span style="color:#9D9D9D;">Required fields are name,  code, territory, salesman. </span>
          <span style="color:#183153;font-weight: bold;cursor:pointer;"> Learn about template</span>
          <th style="margin-top: 5px;">
            <table class="customtable">
              <tr>
                <th *ngFor="let c of stub_data">{{ c.F }}</th>
              </tr>
              <tr>
                <th *ngFor="let c of excel_columns">{{ c.headerName }}</th>
              </tr>
              <tr>
                <td *ngFor="let c of stub_data">{{ c.V }}</td>
              </tr>
            </table>

          </div>
        </div>
      <div class="col-md-12 pad0" style="margin-top: 30px;">
        <div class="row">
          <span class="span-number">3</span>
          <span class="header-span">Upload File</span>
          <input #fileUp type="file" class="form-control" style="display:none"  (change)="uploadedFile($event)" placeholder="Upload file" accept=".xlsx">
        </div>
        <div style="margin-left: 40px;margin-top: 5px;">
          <span style="color:#9D9D9D;">Maximum File Size : 10 MB</span>
          <div class="dottedcard" (click)="fileUp.click()">
            <span *ngIf="fileUploaded" style="font-weight: 500; display: block;">{{fileUploaded.name}}</span>
            <span><i class="fal fa-cloud-upload fa-5x"></i></span>
            <span style="margin-top: 10px;display: block; font-size: 18px;color: #183153; font-weight: 500;">Select a file to Upload</span>
            <span style="margin-top: 5px;display: block;color: #183153">Or drag and drop file here</span>
          </div>
        </div>
      </div>
    </div>
    <div class="body-section" *ngIf="wizard_step === 2">
      <div class="col-md-12 pad0">
        <div style="display: inline;">
          <p style="font-weight: 400;font-size: 12px;color: #2D2D2D; padding-left: 8px;">
            TOTAL: {{totalDataCount}},  Valid Records : {{validDataCount}},  Records with error : {{errorDataCount}}<br>
            Only validated records will be uploaded on upload button click
          </p>
        </div>
        <div>
          <ag-grid-angular id="myGrid1" style="width: 100%; height: 70vh" class="ag-theme-balham"
                           [rowHeight]=50
                           [columnDefs]="columnDefs"
                           [gridOptions]="gridOptions"
                           (gridReady)="onGridReady($event)"
                           (rowEditingStarted)="onRowEditingStarted($event)"
                           (rowEditingStopped)="onRowEditingStopped($event)"
                           (cellEditingStarted)="onCellEditingStarted($event)"
                           (cellEditingStopped)="onCellEditingStopped($event)"
                           [getRowId]="getRowId"
                           [context]="context"
          >
          </ag-grid-angular>
        </div>
      </div>
    </div>
    <div  class="col-md-12 pad0" style="margin-top:20px;padding:15px;border-top: 1px solid #9D9D9D;height:50px;">

      <div  *ngIf="wizard_step === 1" class="button-row"  style="position: absolute;  right: 0px;">
        <button
          mat-button
          style="margin-left:15px; margin-right:15px;"
          class="btn blue-btn"
          (click)="onValidateClick()">
          NEXT
        </button>
      </div>
      <div  *ngIf="wizard_step === 2" class="button-row">
        <div style="float: right;">
          <button
            mat-button
            style="margin-left:15px; margin-right:15px;"
            class="btn blue-btn"
            (click)="onUploadClick()">
            UPLOAD
          </button>
        </div>
        <div style="float: left;">
          <button
            mat-button
            class="btn white-btn"
            (click)="onBackClick()">
            BACK
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
