<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button class="closebtn" (click)="closeDialog()">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Remove Retailers From Beat Plan</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F3F6FD; padding: 15px;">
      <div style="display: inline-block">
        <label style="font-weight: 400;font-size: 12px;color: #2D2D2D">
          You have selected
        </label>
        <label style="font-weight: 500;font-size: 12px;color: #2D2D2D; margin-left: 3px;">
          {{uq_ret}}
        </label>
        <label style="font-weight: 400;font-size: 12px;color: #2D2D2D; margin-left: 3px;">
          Retailers from
        </label>
        <label style="font-weight: 500;font-size: 12px;color: #2D2D2D; margin-left: 3px;">
          {{uq_bp}}
        </label>
        <label style="font-weight: 400;font-size: 12px;color: #2D2D2D; margin-left: 3px;">
          Beat Plan (
        </label>
        <label style="font-weight: 500;font-size: 12px;color: #2D2D2D; margin-left: 3px;">
          {{uq_ter}}
        </label>
        <label style="font-weight: 400;font-size: 12px;color: #2D2D2D; margin-left: 3px;">
          territories)
        </label>
      </div>
      <div style="display: inline-block; margin-top: 10px; width: 100%">
        <form novalidate id="ngForm" #removebeatplanretailerform="ngForm">

          <span style="font-weight: 400;font-size: 12px;color: #2D2D2D">
            If the retailer belongs to only ONE beatplan.
          </span>
          <div style="margin-top: 20px; margin-bottom: 20px; border-left: 4px solid #17A2B8">
            <div style="display: inline;">
              <p style="padding-left: 8px;">
                <mat-radio-group aria-label="last_retailer_option"
                                 [(ngModel)]="retailer_active_mode" [ngModelOptions]="{standalone: true}">
                  <mat-radio-button value="1" checked>
                    Retain the retailer as
                    <span style="font-weight: 500;color: #379862;"> ACTIVE </span>
                    & the retailer will be counted as uncovered retailer
                  </mat-radio-button>
                  <mat-radio-button value="2" style="margin-top: 5px;">
                    De-activate the retailer
                    <span style="font-weight: 500;color: #E24260;"> (status: INACTIVE)</span>
                    and will no longer appear in the app for the salesman or in any reports.
                  </mat-radio-button>
                </mat-radio-group>
              </p>
              <p *ngIf="type === 'Move' && selectedData.beat_plan_id > 0"
                 style="font-weight: 400;font-size: 12px;color: #2D2D2D; padding-left: 8px;">
                The selected beat plan retailers will be
                <span style=" font-weight: 400;font-size: 12px;color: #E24260; margin-left: 3px; margin-right: 3px;">removed</span>
                from the current beat plans. To continue, click remove.
              </p>
            </div>
          </div>

        </form>
      </div>

    </div>
    <div class="button-row" style="margin-top: 10px;">
      <div style="float: right; margin: 15px 30px 15px 0px;">
           <span style=" vertical-align:bottom;margin-top: 20px; margin-right: 15px;" (click)="closeDialog()">
           <a class="btn grey-btn">CANCEL</a>
        </span>
        <button
          mat-button
          class="btn confirm-green-btn"
          (click)="onRemove()"
          form="ngForm"
          [disabled]="removebeatplanretailerform.form.invalid"
        >
          REMOVE
        </button>
      </div>
    </div>
  </div>
</section>
