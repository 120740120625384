import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {MatDialogConfig} from "@angular/material/dialog";
import {LocationPopupComponent} from "../../components/location_popup/location_popup.component";

@Component({
  selector: 'app-monthly-partner-visits',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class MonthlyPartnerVisitReviewComponent extends ReportBase implements IReport, OnInit {

  public attributeTypes = [];
  showCustomFilter = false;
  apiData: any = [];

  customFilter: any = {
    name: 'Salesman',
    key: 'id',
    value: '',
    values: [],
    show: true
  };

  MapViewMetaData = {
    name: 'name',
    latitude: 'lat',
    longitude: 'lng',
    color: 'color',
  };

  keys_array = [];

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Monthly partner visits');

    this.showDate = true;
    this.dateFilterType = 'period';
    this.showMapView = true;
    this.showViewSelector = true;

    const salesmanProfiles = ['Manager', 'Executive'];
    const userMasterList = JSON.parse(localStorage.getItem('all_salesmans'));
    userMasterList.forEach(user => {
      if (salesmanProfiles.includes(user.profile)) {
        this.customFilter.values.push({id: parseInt(user.id, 10), itemName: user.itemname});
      }
    });
    this.customFilter.value =  this.customFilter.values[0].id;

    this.showCustomFilter = true;
    this.showableFilters.state = true;
    this.showableFilters.outlet_type = true;
    this.showDate = true;

    this.statusConfig.success = 'Completed';
    this.statusConfig.error = 'Incomplete';

    this.API_URL = '/api/pwa_v2_reports/getSalesmanUniverseAndVisitsReport';

  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }

  configureData() {
    this.rowData = this.apiData['partners'];
    const visit_data = this.apiData['visits'];

    const unique_visits = [];
    visit_data.forEach(v => {
      if (!unique_visits.includes(v.partner_id)) {
        unique_visits.push(v.partner_id);
      }
    });


    this.rowData.forEach(obj => {
      const partner_visits = visit_data.filter(v => v.partner_id === obj.id);
      if (obj['lat'] && !isNaN(obj['lat'])) {
        if (obj['lng'] && !isNaN(obj['lng'])) {
          obj['lat'] = parseFloat(obj['lat']);
          obj['lng'] = parseFloat(obj['lng']);
        }
      }
      if (partner_visits && partner_visits.length > 0) {
        obj['color'] = 'teel';
        obj['visit_count'] = 0;
        partner_visits.forEach(pv => {
          const key = 'day_' + (new Date(pv.start_day)).getDate();
          if (!this.keys_array.includes(key)) {
            this.keys_array.push(key);
            obj['visit_count']++;
          }
          obj[key] = pv;
          if (pv && pv.hasOwnProperty('lat') && !isNaN(pv.lat)) {
            obj['lat'] = pv.lat;
          }
          if (pv && pv.hasOwnProperty('lng') && !isNaN(pv.lng)) {
            obj['lng'] = pv.lng;
          }

          if (pv.hasOwnProperty('uv') && visit_data.uv === true) {
            obj['color'] = 'amber';
          } else if (visit_data && visit_data.hasOwnProperty('config_json') && visit_data.config_json.hasOwnProperty('activities')) {
            const order_activity = visit_data.config_json.activities.find(a => a.action === 'Order');
            if (order_activity) {
              obj['color'] = 'green';
            }
          }

        });
      } else {
        obj['color'] = 'grey';
      }
    });
    console.log(this.rowData);
  }

  configureGrid() {
    const inThis = this;
    this.columnDefs = [
      {headerName: 'PARTNER NAME', field: 'name', enableRowGroup: true, pinned: 'left', width: 80},
      {headerName: 'PARTNER ID', field: 'id', width: 80},
      {headerName: "BEAT", field: "beats", width: 100},
      {headerName: 'TYPE', field: 'partner_type', enableRowGroup: true, hide: true, width: 80},
      {headerName: 'CODE', field: 'code', width: 80},
      {headerName: 'OUTLET TYPE', field: 'type', enableRowGroup: true, width: 80},
      {headerName: 'CLASS', field: 'class', enableRowGroup: true, hide: true, width: 80},
      {headerName: 'PROGRAM TYPE', field: 'program', enableRowGroup: true, hide: true, width: 80},
      {headerName: "LAT", field: "lat", width: 50},
      {headerName: "LNG", field: "lng", width: 50},
      {headerName: 'ZIP', field: 'zip', enableRowGroup: true, hide: true, width: 80},
      {headerName: 'CITY', field: 'city', enableRowGroup: true, width: 80},
      {headerName: 'DISTRICT', field: 'zone', enableRowGroup: true, width: 80},
      {headerName: 'STATE', field: 'state', enableRowGroup: true, hide: true, width: 80},
      {headerName: 'TERRITORY', field: 'territory', enableRowGroup: true, hide: true, width: 100},
      {headerName: "REGION", field: "region", enableRowGroup: true, hide: true, width: 100},
      {headerName: "CLUSTER", field: "cluster", enableRowGroup: true, hide: true, width: 100},
      {headerName: "COLOR", field: "color"},
      {headerName: "VISTS", field: "visit_count", width: 50},
      // {
      //   headerName: "Partner Details",
      //   children: [
      //
      //   ]
      // }, {
      //   headerName: "Visit Details",
      //   children: []
      // }
    ];

    const start = new Date(this.userData.start_date);
    const end = new Date(this.userData.end_date);
    let loop = new Date(start);
    let newDate;
    // this.columnDefs[1].children = [];
    while (loop <= end) {
      this.columnDefs.push(
        {headerName: loop.getDate() + ' ' + loop.toLocaleString('en-US', { weekday: 'short' }),
          field: 'day_' + loop.getDate(),
          valueGetter(params) {
            if (params.data) {
              if (params.data['name'] && (params.data['name'] === 'Order Value' || params.data['name'] ==='Total Visit')) {
                return params.data[params.colDef.field];
              } else {
                if (params.data[params.colDef.field]) {
                  const visit_data = params.data[params.colDef.field];
                  if (visit_data && visit_data.hasOwnProperty('config_json') && visit_data.config_json.hasOwnProperty('activities')) {
                    const order_activity = visit_data.config_json.activities.find(a => a.action === 'Order');
                    if (order_activity) {
                      return order_activity.value;
                    }
                  }
                }
              }
            }
          },
          cellStyle(params) {
            if (params.data) {
              if (params.data['name'] && (params.data['name'] === 'Order Value' || params.data['name'] === 'Total Visit')) {
                return '';
              } else {
                if (params.data[params.colDef.field]) {
                  const visit_data = params.data[params.colDef.field];
                  let order_activity = {};
                  if (visit_data && visit_data.hasOwnProperty('config_json') && visit_data.config_json.hasOwnProperty('activities')) {
                    order_activity = visit_data.config_json.activities.find(a => a.action === 'Order');
                  }

                  if (visit_data.hasOwnProperty('uv') && visit_data.uv === true) {
                    return {background: '#F4A460'};
                  } else if (visit_data && visit_data.hasOwnProperty('config_json') && visit_data.config_json.hasOwnProperty('activities')) {
                    if (order_activity) {
                      return {background: 'MediumSeaGreen'};
                    } else {
                      return {background: 'Blue'};
                    }
                  }
                }
              }
            }
          },
        },
      );
      // this.columnDefs[1].children = this.getColumns(loop);
      newDate = loop.setDate(loop.getDate() + 1);
      loop = new Date(newDate);
    }
  }


  getColumns(day) {
    const childColumns = [{
        headerName: 'VISIT TYPE', field: 'is_virtual', enableRowGroup: true, hide: true, width: 80,
        valueGetter(params) {
          if (params.data) {
            if (params.data.is_virtual) {
              if (params.data.virtual_verified && params.data.virtual_verified === 'Verified') {
                return 'Virtual Call';
              } else {
                return 'Unverified Call';
              }
            } else {
              return 'Physical Visit';
            }
          }
        }
      },
      {headerName: 'VIRTUAL TYPE', field: 'virtual_type', enableRowGroup: true, hide: true, width: 80},
      {headerName: 'PURPOSE', field: 'purpose', width: 80, hide: true},
      {headerName: 'PROGRESS', field: 'visit_progress', width: 80, hide: true},
      {
        headerName: 'DURATION', field: 'visit_duration', width: 80,
        valueGetter(params) {
          if (params.data) {
            if (params.data.visit_duration) {
              return (params.data.visit_duration / 60).toFixed(1) + " mins";
            } else {
              return 'NA';
            }
          }
        }
      },
      {
        headerName: 'ORDER VALUE', field: 'order_value', width: 80, cellStyle: {textAlign: 'right'},
        valueGetter(params) {
          if (params.data) {
            if (params.data.visit_actions) {
              for (const action_row of params.data.visit_actions) {
                if (action_row.action === 'Order') {
                  if (action_row.value) {
                    return action_row.value;
                  } else {
                    return 0;
                  }
                }
              }
              return 0;
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: "LOCATION", field: "url", maxWidth: 100, menuTabs: [],
        valueGetter(params) {
          if (params.data) {
            if (params.data) {
              if (params.data.r_lat != null && params.data.r_lat !== "0.0") {
                return 'http://www.google.com/maps/dir/' + params.data.r_lat + ',' + params.data.r_long + '/'
                  + params.data.a_lat + ',' + params.data.a_long;
              } else {
                return 'http://maps.google.com/maps?q=' + params.data.a_lat + ',' + params.data.a_long;
              }
            }
          }
        }, cellRenderer: this.locationIconRenderer('a_lat', 'a_long')
      },
      {
        headerName: "DEVIATION", field: "visit_deviation", hide: true, width: 100,
        valueGetter(params) {
          if (params.data) {
            if (!params.data.visit_deviation || params.data.visit_deviation === -1) {
              return 'NA';
            } else {
              return params.data.visit_deviation;
            }
          }
        },
        cellRenderer(params) {
          if (params.data) {
            let displayElem = '';
            if (params._self.checkInDistance == null && params.value > 300) {
              displayElem = '<div style=" color:#E24260;  text-align: center; padding: 0px;">' + params.value + 'm</div>';
            } else if (params._self.checkInDistance && params._self.checkInDistance !== null && params.value !== null && params._self.checkInDistance > 0 && params.value > params._self.checkInDistance) {
              //if (params.value > 300) {
              displayElem = '<div style=" color:#E89221;  text-align: center;">' + params.value + 'm</div>';
              // return {textAlign: 'center', backgroundColor: '#F8D1C8'};
            } else if (params.value === 'NA') {
              displayElem = `<div style="text-align: center;">
                              <span><img src="../../../assets/cross.svg"></span>
                            </div>`;
              //return {textAlign: 'center'};
            } else {
              displayElem = '<div style=" color:#379862;  text-align: center;">' + params.value + 'm</div>';
              //return {textAlign: 'center'};
            }
            return displayElem;
          }
        },
        cellRendererParams: {
          _self: this
        }
      }];
    return childColumns;
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    if (column === 'url') {

      if (rows != null && rows.length > 0 && rows[0].hasOwnProperty('a_lat') && rows[0].a_lat != null && rows[0].a_lat !== '') {
        if (screen.width > 991) {
          const config: MatDialogConfig = {
            width: '100vw',
            height: '100vh',
            maxWidth: '70%',
            maxHeight: '95%',
            data: {
              latitude: parseFloat(rows[0].a_lat),
              longitude: parseFloat(rows[0].a_long),
              end_latitude: parseFloat(rows[0].r_lat),
              end_longitude: parseFloat(rows[0].r_long),
              title1: 'Activity',
              title2: 'Outlet'
            }
          };

          const creatediaeref = this.dialog.open(LocationPopupComponent, config);
        } else {
          const config: MatDialogConfig = {
            width: '100vw',
            height: '100vh',
            maxWidth: '90%',
            maxHeight: '65%',
            data: {
              latitude: parseFloat(rows[0].a_lat),
              longitude: parseFloat(rows[0].a_long),
              end_latitude: parseFloat(rows[0].r_lat),
              end_longitude: parseFloat(rows[0].r_long),
              title1: 'Activity',
              title2: 'Outlet'
            }
          };

          const creatediaeref = this.dialog.open(LocationPopupComponent, config);
        }


      }
    }
  }

  iconRenderer() {
    return (params) => {
      if (params.data) {
        if (params.value && params.value === 'NA') {
          return `<div style="text-align: center;">
            <span><img src="../../../assets/cross.svg"></span>
            </div>`;
        } else {
          return `<div style="text-align: center;">
            <span><img src="../../../assets/tickgreen.svg"></span>
            </div>`;
        }
      } else {
        return '';
      }
    };
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllColumns().length;

    params.api.setRowData(this.rowData);
    this.preloader = false;
    const inThis = this;

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        if (noOfColumns < 11 && screen.width > 991) {
          params.api.sizeColumnsToFit();
          // if (inThis.pinnedParams.length > 0) {
          //   inThis.sharedService.resetPinned(inThis.gridOptions, inThis.pinnedParams);
          // }
        } else {
          params.columnApi.autoSizeAllColumns();
          //inThis.sharedService.clearPinned(inThis.gridOptions);
        }
      });
    });

    if (noOfColumns < 11 && screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
      // if (inThis.pinnedParams.length > 0) {
      //   inThis.sharedService.resetPinned(inThis.gridOptions, inThis.pinnedParams);
      // }
    } else {
      this.gridColumnApi.autoSizeAllColumns();
      //inThis.sharedService.clearPinned(inThis.gridOptions);
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');

    setTimeout(() => {
      const headerRows = this.generatePinnedBottomData();
      this.gridApi.setPinnedTopRowData(headerRows);
    }, 500);
    //}

    // this.get_favorites();
    if (this.sharedService.currListData && this.sharedService.currListData.url === this.router.url) {
      this.agGridFilter = this.sharedService.currListData.agGridFilter;
      this.gridApi.setFilterModel(this.agGridFilter);
    }
    if (this.columnState) {
      this.gridColumnApi.applyColumnState(
        {
          state: this.columnState,
          applyOrder: true,
        }
      );
      this.quickSearch();
    }
  }

  generatePinnedBottomData() {
    const filteredRowData = this.gridApi.rowModel.rowsToDisplay;

    const visit_row = { name: 'Total Visit'};
    const order_value_row = { name: 'Order Value'};

    this.keys_array.forEach(k => {
      let order_value = 0;
      let visit_count = 0;
      filteredRowData.forEach(node => {
        const visit_data = node.data[k];
        if (visit_data) {
          if (visit_data && visit_data.hasOwnProperty('config_json') && visit_data.config_json.hasOwnProperty('activities')) {
            const order_activity = visit_data.config_json.activities.find(a => a.action === 'Order');
            if (order_activity) {
              order_value = order_value + parseFloat(order_activity.value);
            }
          }
          if (visit_data && visit_data.hasOwnProperty('is_complete') && visit_data.is_complete === true) {
            visit_count++;
          }
        }
        order_value_row[k] = order_value;
        visit_row[k] = visit_count;
      });
    });

    const headerRows = [];
    headerRows.push(visit_row);
    headerRows.push(order_value_row);
    return headerRows;
  }

}
