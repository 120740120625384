import {Component, OnInit} from "@angular/core";
import {ReportBase} from "../_common/reportbase";
import {MatDialogConfig} from "@angular/material/dialog";
import {ImagePopupComponent} from "../../components/image_popup/image_popup.component";
import {TaskActivationPopupComponent} from "../../components/task_activation_popup/task_activation_popup.component";
import {SurveyGalleryPopupComponent} from "../../components/survey_gallery_popup/survey_gallery_popup.component";

@Component({
  selector: 'app-market_intelligence_report',
  templateUrl: '../_common/reportbase.html',
})
export class MarketIntelligenceReportComponent extends ReportBase implements OnInit {
  ngOnInit(): void {
    let datas: any = {};
    datas = history.state;

    this.userData.customFilter['id'] = datas.surveyID;

    this.sharedService.setHeader('Retail Survey Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;

    this.showViewSelector = true;
    this.showGalleryview = true;
    this.galleryViewOnlyImages = true;
    this.container = 'partner_answer';
    this.galleryData = {
      pageSize: 50,
      id: 'id',
      image: 'store_fname',
      name: 'survey',
      field1: 'question',
      field2: 'rcode',
      field3: 'retailer',
      status: 'question_code',
      statusTypes: [
        {name: "published", type: "completed"},
        {name: "completed", type: "success"},
        {name: "new", type: "draft"}
      ],
      reviewed: 'status',
      reviewTypes: {
        yes: 'approved',
        no: 'rejected'
      },
      container: this.container,
      selectionCriteria: [
        {
          field: 'status',
          matches: true,
          values: ['completed']
        },
        {
          field: 'reviewed',
          matches: true,
          values: ['new']
        }
      ]
    };


    this.loadInBatches(5000, 'id');
    this.showProgressCustomText = true;
    this.configureGrid();
    if (this.userData.customFilter['id'] && this.userData.customFilter['id'] !== 0) {
      this.loadServerData();
    }

  }

  configureGrid() {

    this.gridOptions.autoGroupColumnDef = {
      headerName: 'Region',
      field: 'region',
      minWidth: 250,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        checkbox: true,
      },
      pinned: 'left'
    };

    this.API_URL = '/api/pwa_reports/getRetailerSurveyAnswers';
    this.userData.type = 'retailer';
    this.columnDefs = [
      {headerName: "REGION", field: "region",  width: 100},
      {headerName: "CLUSTER", field: "cluster",  width: 100},
      {headerName: "TCODE", field: "tcode", width: 100},
      {headerName: "TERRITORY", field: "territory", width: 100},
      {headerName: "SALESMAN", field: "salesman", rowGroup: true,  width: 100},
      {headerName: "ECODE", field: "emp_code", width: 100},
      {headerName: "SURVEY", field: "survey",  width: 120},
      {headerName: "DATE", field: "completion_date",  width: 100},
      {headerName: "PROGRAM", field: "program_type",  width: 80},
      {headerName: "RCODE", field: "rcode", width: 100},
      {headerName: "RETAILER", field: "retailer",  width: 130},
      {headerName: "RETAILER TYPE", field: "retailer_type",  width: 130},
      {headerName: "QCODE", field: "question_code", width: 150},
      {headerName: "QUESTION", field: "question",  width: 150},
      {headerName: "ANSWER", field: "answer_text",  width: 80},
      {headerName: "REMARKS", field: "remarks", width: 100},
      {headerName: "SCORE", field: "ind_score", cellStyle: {textAlign: 'right'}, width: 80},
      {
        headerName: "TOT SCORE %", field: "score", cellStyle: {textAlign: 'right'}, width: 80,
        valueGetter(params) {
          if (params.data) {
            if (params.data.work_days) {
              return Math.round((params.data.score + Number.EPSILON) * 100) / 100;
            } else {
              return 0;
            }
          }
        }

      },
      {
        headerName: "PHOTO", field: "store_fname", width: 80,
        valueGetter: this.generateImageUrl('store_fname'),
        cellRenderer(params) {
          if (params.data) {
            if (params.data.store_fname === null || params.data.store_fname === "") {
              return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
            } else {
              return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
            }
          }
        }
      }
    ];
  }

  onCellClicked(event) {

    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    console.log(column);
    console.log(rows);
    if (column !== 'store_fname') {

    } else {
      console.log(rows[0].store_fname);
      //rows[0].store_fname = '964/9640c96d933643095139096372fda9ed189a8ff360c5bca0b4bc9f7d0ba661c1'

      if (rows !== null && rows.length > 0 && rows[0].hasOwnProperty('store_fname') && rows[0].store_fname !== null && rows[0].store_fname !== "") {
        if (screen.width > 991) {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "70%",
            maxHeight: "95%",
            data: {
              store_fname: rows[0].store_fname,
              container: "partner_answer"
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        } else {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "90%",
            maxHeight: "65%",
            data: {
              store_fname: rows[0].store_fname,
              container: "partner_answer"
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        }

      } else {

      }
    }
  }

  showDetailPopup() {
    const row = this.rowData.find(x => x.id === this.selectedGalleryItem.id);
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "33%",
        maxHeight: "100%",
        data: {
          surveyDetails: row,
          photo: this.selectedGalleryItem.photo
        },
        panelClass: 'custom-dialog-container'
      };
      const creatediaeref = this.dialog.open(SurveyGalleryPopupComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "95%",
        maxHeight: "100%",
        data: {
          surveyDetails: row,
          photo: this.selectedGalleryItem.photo
        },
        panelClass: 'custom-dialog-container'
      };
      const creatediaeref = this.dialog.open(SurveyGalleryPopupComponent, config);
    }
  }
}
