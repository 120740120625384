import {Component, OnInit} from '@angular/core';
import {PrintInvoiceComponent} from "../printInvoice.component";
import {PrintClickRenderer} from "../printClickRenderer.component";
import {PageBase} from "../pagebase/pagebase";

@Component({
  selector: 'app-sales',
  templateUrl: '../pagebase/pagebase.html',
  styleUrls: ['../pagebase/pagebase.scss']
})
export class SalesComponent extends PageBase implements OnInit {

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    this.sharedService.setHeader('Sales');
    this.myDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.showZipDownload = true;
    this.configureGrid();
    this.loadServerData();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_connects/get_sales';
    const inThis = this;
    this.columnDefs = [
      {headerName: 'DC', field: 'dc', width: 100, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true},
      {headerName: 'Region', field: 'region', width: 100},
      {headerName: 'Van', field: 'van', width: 100},
      {headerName: 'DC', field: 'dc', width: 100},
      {headerName: 'Retailer', field: 'retailer', width: 100},
      {headerName: 'Retailer Code', field: 'retailer_code', width: 100},
      {headerName: 'Invoice NO', field: 'invoice_no', width: 100},
      {headerName: 'PO', field: 'po', width: 100},
      {headerName: 'GRN', field: 'grn', width: 100},
      {
        headerName: 'Sale Date', field: 'order_date', width: 100,
        valueGetter: params => {
          if (params.data && params.data.order_date) {
            return inThis.datePipe.transform((new Date(params.data.order_date + ' UTC')).toString(), 'dd/MM/yy');
          }
        }
      },
      {
        headerName: 'Payment Mode', field: 'payment_mode', width: 100,
        valueGetter: params => {
          if (params.data && params.data.payment_mode) {
            if (params.data === 'Cr Used') {
              return 'Cr Note';
            } else if (params.data === 'cash_and_credit') {
              return 'Credit With Payment';
            } else {
              return params.data.payment_mode;
            }
          }
        }
      },
      {headerName: 'Status', field: 'approved', width: 100},
      {headerName: 'Amount', field: 'amount', width: 100},
      {
        headerName: "PRINT ALL", field: "PRINT ALL", resizable: true, pinned: 'right',
        headerComponent: "printInvoicecomponent",
        cellRenderer: "printClickRenderer"
      }
    ];
    this.frameworkComponents = {
      printInvoicecomponent: PrintInvoiceComponent,
      printClickRenderer: PrintClickRenderer
    };
  }

  printAll(rows) {
    this.appComponent.printAll(rows, 'order_id', '/api/pwa_connects/get_order_print_details', null);
  }

  zipDownload() {
    const rows = this.gridApi.getSelectedRows();
    this.appComponent.printAll(rows, 'order_id', '/api/pwa_connects/get_order_print_details', true);
  }

  onCellClicked(event) {
    this.appComponent.printAll([event.data], 'order_id', '/api/pwa_connects/get_order_print_details', null);
  }
}
