<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper" style="margin-top: 50px!important">
      <div style="background-color: white;margin-left: 5px;margin-right: 25px;height:650px;margin-top: 0px;">
        <div style="margin:10px;">
          <div>
            <div>
              <div class="search-list" style="margin-top: 0px;">
                <div class="col-md-6 col-12 nopadding pl-0" style="margin-bottom: 50px;margin-top: 0px;">
                  <div class="row" style="margin-top: 10px;">
                    <div *ngIf="edit && schemeDetails.status!='published'" class="button-row" style="margin-top: 10px;">
                      <button
                        mat-button
                        style="margin-right:15px;"
                        class="btn teal-btn"
                        form="ngForm"
                        [disabled]="taskform.form.invalid"
                      >
                        SAVE
                      </button>
                      <span>
                         <a *ngIf="viewtype!='create'" class="btn white-btn" (click)="discardClick()">DISCARD</a>
                      </span>
                    </div>
                    <div *ngIf="!edit && schemeDetails.status!='published'" class="button-row" style="margin-top: 10px;">
                      <span>
                         <a class="btn white-btn" style="margin-right:15px;" (click)="editClick()">EDIT</a>
                      </span>
                      <span>
                        <a class="btn white-btn" (click)="cancelClick()">CANCEL</a>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-12 pad0 text-right" style="margin-bottom: 50px;margin-top: 0px;">
                  <!--<div class="filters">
                    <ul class="list-unstyled">
                      <li class="margLR" style="margin-right: 20px;margin-top: 10px;">
                        <span *ngIf="!edit">
                         <a class="btn teal-border-btn" style="margin-right:15px;"
                            (click)="viewReport()">VIEW REPORT</a>
                        </span>
                      </li>
                      <li class="margLR" *ngIf="viewtype!='create'">
                        <div style="width: 200px;margin-top: 15px;">
                          &lt;!&ndash;  <label for="file" style="display: block;text-align: center;font-size: 1.0em;">&ndash;&gt;
                          <label for="file" class="progresslabel">
                            PROGRESS</label>
                          <label *ngIf="schemeDetails.progress<30" class="progresslabelcount"
                                 style="color:#B82525">{{schemeDetails.completed_count}}</label>
                          <label *ngIf="schemeDetails.progress>30"
                                 class="progresslabelcount">{{schemeDetails.completed_count}}</label>
                          <label style="color: black;font-size: 12px;"> /{{schemeDetails.line_count}}</label>
                          <progress id="file" max="100" value="{{schemeDetails.progress}}"
                                    style="margin-top: 5px;"></progress>
                        </div>
                      </li>
                      <li class="margLR" *ngIf="schemeDetails.status=='published'"
                          style="margin-left: 5px;margin-bottom: 5px;">
                        <div style="margin-top: 15px;margin-left: 10px;margin-right: 10px;">
                          <img *ngIf="schemeDetails.status=='published' && schemeDetails.active"
                               style="height: 18px;width:18px;margin-bottom: 8px;" src="./assets/tickteal.svg">
                          <img *ngIf="schemeDetails.status=='published' && !schemeDetails.active"
                               style="height: 18px;width:18px;margin-bottom: 8px;" src="./assets/crossred.svg">
                          <span *ngIf="schemeDetails.status=='published' && schemeDetails.active"
                                class="activetext">ACTIVE</span>
                          <span *ngIf="schemeDetails.status=='published' && !schemeDetails.active" class="activetext"
                                style="color:#B82525">INACTIVE</span>
                        </div>
                      </li>
                      <li class="margLR" style="margin-left: 10px;"
                          *ngIf="!edit && schemeDetails!=null && schemeDetails.status!='published' && schemeDetails['line_items']!=null && schemeDetails['line_items'].length>0">
                        <button
                          mat-button
                          style="margin-left:15px; margin-right:15px;margin-top: 10px;"
                          class="btn teal-btn"
                          (click)="openPublishDialog()"
                        >
                          PUBLISH
                        </button>
                      </li>
                    </ul>
                  </div>-->
                </div>
              </div>
            </div>

            <div class="col-md-12 pad0">
              <form novalidate (ngSubmit)="createOrUpdateTask()" id="ngForm" #taskform="ngForm">
                <div class="col-md-3 pl-0 pr-30">
                  <label style="color:#7D7D7D">SCHEME</label>
                  <div style="margin-top: 5px;">
                    <div *ngIf="edit">
                      <input
                        class="forminput"
                        style="width: 70%;"
                        type="text"
                        name="schemename"
                        [(ngModel)]="schemeDetails.name"
                        #schemename="ngModel"
                        required
                      >
                      <div *ngIf="schemename.errors && (schemename.dirty || schemename.touched)">
                        <span *ngIf="schemename.errors.required" class="text-danger">Scheme name is required</span>
                      </div>
                    </div>
                    <p *ngIf="!edit">{{schemeDetails.name}}</p>
                  </div>
                </div>
                <div class="col-md-2 pl-0 pr-30">
                  <label style="color:#7D7D7D">TYPE</label>
                  <div style="margin-top: 5px;" class="formselect">
                    <select
                      *ngIf="edit"
                      name="s_type"
                      [(ngModel)]="schemeDetails.type_id"
                      #s_type="ngModel"
                      style="width: 80%;"
                    >
                          <option value="s" selected>Secondary</option>
                          <option value="p">Primary</option>
                          <option value="t">Tertiary</option>
                    </select>
                    <p *ngIf="!edit">{{schemeDetails.s_type}}</p>
                  </div>
                </div>
                <div class="col-md-2 pl-0 pr-30">
                  <label style="color:#7D7D7D">SCHEME TYPE</label>
                  <div style="margin-top: 5px;" class="formselect">
                    <select
                      *ngIf="edit"
                      name="scheme_type"
                      [(ngModel)]="schemeDetails.scheme_type"
                      #scheme_type="ngModel"
                      style="width: 80%;"
                    >
                      <option value="vps" selected>Product Scheme</option>
                      <option value="qps">Bill Scheme</option>
                      <option value="bxgx">BXGX</option>
                    </select>
                    <p *ngIf="!edit">{{schemeDetails.scheme_type}}</p>
                  </div>
                </div>
                <div *ngIf="allow_start_date" class="col-md-2 pl-0 pr-20">
                  <label style="color:#7D7D7D">START DATE</label>
                  <div style="margin-top: 5px;">
                    <div *ngIf="edit">
                      <input
                        class="forminput"
                        type="text"
                        name="taskstart_date"
                        [(ngModel)]="schemeDetails.start_date"
                        #taskstart_date="ngModel"
                        [owlDateTime]="dt1"
                        [owlDateTimeTrigger]="dt1"
                        required
                      >
                      <owl-date-time (afterPickerClosed)="setStartDate(dt1)" [pickerType]="'calendar'"
                                     #dt1></owl-date-time>
                      <div *ngIf="taskstart_date.errors && (taskstart_date.dirty || taskstart_date.touched)">
                        <span *ngIf="taskstart_date.errors.required" class="text-danger">Start Date is required</span>
                      </div>
                    </div>
                    <p *ngIf="!edit">{{schemeDetails.start_date}}</p>
                  </div>
                </div>
                <div class="col-md-2 pl-0 pr-20">
                  <label style="color:#7D7D7D">END DATE</label>
                  <div style="margin-top: 5px;">
                    <div *ngIf="edit">
                      <input
                        class="forminput"
                        type="text"
                        name="taskend_date"
                        #taskend_date="ngModel"
                        [(ngModel)]="schemeDetails.end_date"
                        [owlDateTime]="dt2"
                        [owlDateTimeTrigger]="dt2"
                        required
                      >
                      <owl-date-time (afterPickerClosed)="setEndDate(dt2)" [pickerType]="'calendar'"
                                     #dt2></owl-date-time>
                      <div *ngIf="taskend_date.errors && (taskend_date.dirty || taskend_date.touched)">
                        <span *ngIf="taskend_date.errors.required" class="text-danger">End Date is required</span>
                      </div>
                    </div>
                    <p *ngIf="!edit">{{schemeDetails.end_date}}</p>
                  </div>
                </div>
              </form>
            </div>

            <div class="col-md-12 pl-0" style="margin-top: 15px;">
              <div class="col-md-3 pl-0 pr-30">
                <label style="color:#7D7D7D">CODE</label>
                <div style="margin-top: 5px;" >
                  <div *ngIf="edit">
                    <input
                      class="forminput"
                      style="width: 70%;"
                      type="text"
                      name="code"
                      [(ngModel)]="schemeDetails.code"
                      #code="ngModel"
                      required
                    >
                    <div *ngIf="code.errors && (code.dirty || code.touched)">
                      <span *ngIf="code.errors.required" class="text-danger">Scheme Code is required</span>
                    </div>
                  </div>
                  <p *ngIf="!edit">{{schemeDetails.code}}</p>
                </div>
              </div>
              <div class="col-md-2 pl-0 pr-30">
                <label style="color:#7D7D7D">PRODUCT</label>
                <div style="margin-top: 5px;" class="formselect">
                  <select
                    *ngIf="edit"
                    name="product_id"
                    [(ngModel)]="schemeDetails.product_id"
                    #product_id="ngModel"
                    style="width: 80%;"
                  >
                    <option
                      *ngFor="let item of task_types"
                      value="{{ item.id }}"
                    >
                      {{ item.itemname }}
                    </option>
                  </select>
                  <p *ngIf="!edit">{{schemeDetails.product}}</p>
                </div>
              </div>
              <div class="col-md-2 pl-0 pr-30">
                <label style="color:#7D7D7D">PRODUCT UOM</label>
                <div style="margin-top: 5px;" class="formselect">
                  <select
                    *ngIf="edit"
                    name="uom"
                    [(ngModel)]="schemeDetails.uom"
                    #uom_qty="ngModel"
                    style="width: 80%;"
                  >
                    <option
                      *ngFor="let item of task_types"
                      value="{{ item.id }}"
                    >
                      {{ item.itemname }}
                    </option>
                  </select>
                  <p *ngIf="!edit">{{schemeDetails.uom}}</p>
                </div>
              </div>
              <div class="col-md-2 pl-0 pr-30">
                <label style="color:#7D7D7D">UOM QTY</label>
                <div style="margin-top: 5px;" >
                  <div *ngIf="edit">
                    <input
                      class="forminput"
                      style="width: 70%;"
                      type="text"
                      name="uom_qty"
                      [(ngModel)]="schemeDetails.uom_qty"
                      #uom_qty="ngModel"
                      required
                    >
                    <div *ngIf="uom_qty.errors && (uom_qty.dirty || uom_qty.touched)">
                      <span *ngIf="uom_qty.errors.required" class="text-danger">UOM qty is required</span>
                    </div>
                  </div>
                  <p *ngIf="!edit">{{schemeDetails.uom_qty}}</p>
                </div>
              </div>
              <div class="col-md-2 pl-0 pr-30">
                <label style="color:#7D7D7D">REWARD TYPE</label>
                <div style="margin-top: 5px;" class="formselect">
                  <select
                    *ngIf="edit"
                    name="scheme_type"
                    [(ngModel)]="schemeDetails.reward_type"
                    #scheme_type="ngModel"
                    style="width: 80%;"
                  >
                    <option value="discount" selected>Discount</option>
                    <option value="product">Product</option>
                    <option value="amount">Amount</option>
                    <option value="points">Points</option>
                  </select>
                  <p *ngIf="!edit">{{schemeDetails.reward_type}}</p>
                </div>
              </div>
              <div class="col-md-1 pl-0 pr-30">
                <label style="color:#7D7D7D">MARGIN</label>
                <div style="margin-top: 5px;" >
                  <div *ngIf="edit">
                    <input
                      class="forminput"
                      style="width: 70%;"
                      type="text"
                      name="margin"
                      [(ngModel)]="schemeDetails.margin"
                      #margin="ngModel"
                      required
                    >
                    <div *ngIf="margin.errors && (margin.dirty || margin.touched)">
                      <span *ngIf="margin.errors.required" class="text-danger">Margin is required</span>
                    </div>
                  </div>
                  <p *ngIf="!edit">{{schemeDetails.margin}}</p>
                </div>
              </div>
            </div>

            <div style="margin-top: 50px;">
              <div class="search-list">
                <div class="col-md-6 col-12 nopadding pl-0" style="margin-top: 30px;">
                  <div class="search" style="display: inline-block;">
                    <span class="search-control"><img src="assets/searchicon.png"/></span>
                    <input id="filter-text-box" type="text" placeholder="filter" (input)="quickSearch()"
                           class="search-control-area"
                           placeholder="Search">
                  </div>
                  <div style="display: inline-block;margin-right: 10px;">
                    <!-- <button (click)="createDialog()" class="btn teal-btn" style="height: 42px;margin-bottom: 10px;margin-left:10px;">NEW TASK</button>-->

                    <!--   <button *ngIf="edit" class="icon-btn icon-btn-teal"
                               style="height: 42px;margin-bottom: 10px;margin-left:10px;"
                               (click)="createDialog()"
                       > <i class="fas fa-plus-circle"></i>
                         ADD</button>-->

                    <div style="display: inline-block;margin-top: 5px;margin-left:15px;cursor:pointer;"
                         (click)="createDialog()" *ngIf="edit">
                      <i class="fas fa-plus-circle"
                         style="color:#17A2B8;margin-right: 5px;margin-top:5px;font-size:16px;"></i>
                      <span style="font-size: 16px; font-weight: 400;color:#5D5D5D">Add</span>
                    </div>

                  </div>
                </div>
                <div class="col-md-6 col-12 pad0 text-right" style="margin-top: 30px;">
                  <div class="filters">
                    <ul class="list-unstyled">
                      <!-- <li class="margLR">
                         <div style="width: 350px;">
                           <progress max="100" value="66" data-label="Progress 2/3"></progress>
                         </div>
                       </li>-->
                      <!-- <li class="margLR" style="margin-left: 10px;">
                         <div
                           class="actionbutton"
                           title="Upload"
                         >
                           <img style=" display:block;margin:auto;" src="../../assets/upload2.svg">
                         </div>
                       </li>-->
                      <li class="margLR">
                        <app-excelicon [gridOption]="gridOptions" [title]="'Task Lines Report'"></app-excelicon>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-12 pad0">
                  <div>
                    <ag-grid-angular id="myGrid1" *ngIf="loaded" style="width: 100%;height:300px;"
                                     class="ag-theme-balham" [defaultColDef]="defaultColDef"
                                     [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                                     (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                                     (cellClicked)="onCellClicked($event)"
                                     [context]="context">
                    </ag-grid-angular>
                    <div *ngIf="preloader">
                      <app-loadingstate></app-loadingstate>
                    </div>
                    <div *ngIf="empty_state">
                      <app-emptystate></app-emptystate>
                    </div>
                  </div>
                  <!--  <p *ngIf="edit" style="font-size: 12px;color:#17A2B8;margin-top: 10px;margin-left: 10px;cursor:pointer" (click)="createDialog()">Add an Item</p>-->
                  <!-- <img *ngIf="edit"
                        style="margin-top: 10px;margin-left: 10px;cursor:pointer"
                        title="Add Item"
                        (click)="createDialog()"
                        src="./../../../assets/additem.svg" />-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="pol-modal pol-send invoice-img modal" id="invoiceModal" role="dialog">
  <div class="modal-dialog" style="width: 300px;">
    <!-- Modal content-->
    <div class="modal-content">
        <span class="close" data-dismiss="modal">
          <img src="../../assets/close.png">
        </span>
      <div class="modal-body">
        <img [src]="photo">
      </div>
    </div>
  </div>
</div>

<button #imageClick id="openImage" [hidden]="true" data-toggle="modal" data-target="#invoiceModal">Open Modal</button>
