import {Component, ComponentFactoryResolver, ElementRef, OnInit, Renderer2, ViewChild, HostListener} from "@angular/core";
import {GridOptions} from "../../../node_modules/@ag-grid-enterprise/all-modules";
import {ApiService} from "../_services/api.service";
import {Router} from '@angular/router';
import {ColorCellRenderer} from "../colorCell.component";
import {DatePipe} from '@angular/common';
import * as XLSX from "xlsx-js-style";
import {DateTimeAdapter} from 'ng-pick-datetime';
import {AdDirective2} from "../ad2.directive";
import {AdDirective3} from "../ad3.directive";
import {SuppProdDropdownComponent} from "../components/supp_prod_dropdown/supp-prod-dropdown.component";
import {SupplierDropdownComponent} from "../components/supplier_dropdown/supplier-dropdown.component";
import {CellEdit2Renderer} from "../editCell2.component";
import {ToastrService} from "ngx-toastr";
import {SharedService} from "../_services/shared_service";

@Component({
  selector: 'app-pro-dc-list',
  templateUrl: './pro-dc-list.component.html'
})
export class ProDCListComponent implements OnInit {
  @ViewChild(AdDirective2, {static: true}) adHost2: AdDirective2;
  @ViewChild(AdDirective3, {static: true}) adHost3: AdDirective3;
  @ViewChild('fileUp') fileUp: ElementRef;
  @ViewChild('newLoadout') newLoadout: ElementRef<HTMLElement>;
  public columnDefs = [];
  public columnDefs1 = [];
  public van_code;
  public searchValue;
  public frameworkComponents;
  public context;
  public rowData = [];
  public rowData1 = [];
  public productList = [];
  public statproductList = [];
  public defaultColDef;
  public sideBar;
  public gridOptions: GridOptions;
  public gridOptions1: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public data1;
  public loaded1 = false;
  public preloader1 = false;
  public myDate;
  public styleGrid;
  public collapse_disable;
  public upload_loadout_data;
  public loadout_data = [];
  public uploadCaption = ["", 0];
  public fileUploaded: File;
  public storeData: any;
  public worksheet: any;
  public uploading = false;
  public upload_loader = false;
  public upload_enable = false;
  public role_features;
  public no_po = false;
  public loadoutProds;
  public supplier_products = [];
  public loadout_prod = [];
  public multple_loadout = false;
  res_data;
  user_data = {
    date: null,
    dc_id: null,
    access_token: null,
    url: null,
    loadout_data: null,
    dc_centers: [],
    offset: null,
    flag: false,
    supplier_id: null,
    customer_id: 1,
    pricelist_id: 0,
    feature_sug: false
  };
  public new_po = false;
  public createPO = false;
  private gridApi;
  private gridColumnApi;
  private gridApi1;
  private gridColumnApi1;
  apiloaded = false;

  constructor(private apiService: ApiService,
              private router: Router,
              private elRef: ElementRef,
              private renderer: Renderer2,
              private datePipe: DatePipe,
              private toastr: ToastrService,
              public sharedService: SharedService,
              private componentFactoryResolver: ComponentFactoryResolver,
              private dateTimeAdapter: DateTimeAdapter<any>) {

    this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.user_data.date = localStorage.getItem('my_date');
    if (this.user_data.date === null || this.user_data.date === "null") {
      this.user_data.date = this.myDate;
      localStorage.setItem('my_date', this.myDate);
    } else {
      this.myDate = this.user_data.date;
    }

    dateTimeAdapter.setLocale('en-IN');
    this.user_data.offset = -1 * (new Date(this.user_data.date)).getTimezoneOffset();
    this.user_data.dc_id = JSON.parse(localStorage.getItem('user')).partner_id;
    this.user_data.supplier_id = this.user_data.dc_id;
    this.user_data.dc_centers = JSON.parse(localStorage.getItem('user')).dc_centers;
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');
    localStorage.setItem('user_data', JSON.stringify(this.user_data));
    if (this.sharedService.ACLcheck('feature_upload_loadout')) {
      this.upload_enable = true;
    }
    if (this.sharedService.ACLcheck('feature_enable_recommented')) {
      this.upload_enable = false;
      this.user_data.feature_sug = true;
    }
    if (this.sharedService.ACLcheck('feature_loadout_multiple')) {
      this.multple_loadout = true;
    }
    if (this.sharedService.ACLcheck('feature_create_po')) {
      this.createPO = true;
    }
    this.res_data = this.apiService.getProducts(this.user_data);
    this.res_data.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
        this.statproductList = res['results'].data;
        this.productList = res['results'].data;
      }
    }, error => {
      console.log(error);
    });

    this.res_data = this.apiService.supplierProducts(this.user_data);
    this.res_data.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
        this.loadoutProds = res['results'].data;
      }
    }, error => {
      console.log(error);
    });
    this.apiDcProd();
    this.gridOptions1 = {
      rowHeight: 35,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      enableRangeSelection: true,
      enableCharts: true,
      chartThemes: ['ag-pastel-dark']
    } as GridOptions;

    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids;

    let prolistv3 = this.role_features.filter(existed => 'feature_pro_listV3' === existed.name);
    if (prolistv3.length > 0) {
      prolistv3 = true;
    } else {
      prolistv3 = false;
    }

    let prolistv4 = this.role_features.filter(existed => 'feature_pro_listV4' === existed.name);
    if (prolistv4.length > 0) {
      prolistv4 = true;
    } else {
      prolistv4 = false;
    }
    this.gridOptions = {
      rowHeight: 75,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left', padding: '20px 0px'},
      rowSelection: 'single',
      suppressHorizontalScroll: true,
      onRowSelected: params => {
        localStorage.setItem('params', JSON.stringify(params.data));
        localStorage.setItem('po_status', params.data['STATUS']);
        if (prolistv4) {
          this.router.navigate([this.apiService.redirect_url + "/prolistv4"]);
        } else if (prolistv3) {
          this.router.navigate([this.apiService.redirect_url + "/prolistv3"]);
        } else {
          this.router.navigate([this.apiService.redirect_url + "/prolist"]);
        }
      }
    } as GridOptions;
  }

  apiDcProd() {
    const rowdata = [];
    this.columnDefs = [];
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.new_po = false;
    this.no_po = this.multple_loadout;
    this.user_data.flag = this.multple_loadout;
    this.apiloaded = false;

    this.res_data = this.apiService.dclist2(this.user_data);
    this.res_data.subscribe(res => {
        console.log(res);
        this.apiloaded = true;
        const inthis = this;
        this.elRef.nativeElement.querySelector('#overlay').style.display = "none";
        if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
          if (res['results'].data.length > 0) {
            localStorage.setItem('dc_territory_id', res['results'].data[0]['dc_territory_id']);
            this.supplier_products = res['results'].vans;
            localStorage.setItem('loadout_vans', JSON.stringify(this.supplier_products));
            let no_po = this.no_po;
            this.new_po = true;
            res['results'].data.forEach(data => {
              let order_perc = '0';
              let mtd_perc = '0';
              if (data.loadout_val !== 0 && data.yest_loadout_val !== 0) {
                order_perc = (((data.loadout_val - data.yest_loadout_val) / data.yest_loadout_val) * 100).toFixed(inthis.apiService.decimalPolicy);
                if (parseFloat(order_perc) > 0) {
                  order_perc = '+' + order_perc;
                }
              }

              if (parseFloat(data.mtd_sale) !== 0 && parseFloat(data.lmtd_sale) !== 0) {
                mtd_perc = (((parseFloat(data.mtd_sale) - parseFloat(data.lmtd_sale)) / parseFloat(data.lmtd_sale)) * 100).toFixed(inthis.apiService.decimalPolicy);
                if (parseFloat(mtd_perc) > 0) {
                  mtd_perc = '+' + mtd_perc;
                }
              }
              if (data.status !== 'completed') {
                inthis.new_po = false;
              }
              const pushData = {
                PO: data.po_id,
                DC: data.dc_name,
                REGION: data.region,
                STATUS: data.status,
                "TOTAL VANS": data.total_vans,
                "VANS NOT PLACED": data.total_vans - data.ordered_vans,
                "VANS PLACED": data.ordered_vans,
                LOADIN: data.carryover,
                "LOADOUT VALUE": {
                  total: (parseFloat(data.loadout_val) / 1000).toFixed(3) + 'K',
                  value: order_perc + '%',
                },
                "MONTHLY TARGET": data.month_target,
                "MTD ACHIEVEMENT": {total: data.mtd_sale, value: mtd_perc + '%'},
                dc_id: data.dc_id,
                mtd_perc,
                po_id: data.po_id,
                country_id: data.country_id,
                attributes: data.attributes

              };
              if (data.status !== 'draft') {
                no_po = false;
              }
              rowdata.push(pushData);
            });
            this.no_po = no_po;
            this.user_data.flag = no_po;
            this.data = rowdata[0];
            this.rowData = rowdata;
            for (const key in this.data) {
              if (this.data.hasOwnProperty(key)) {
                if (this.data[key] !== null && typeof (this.data[key]) === 'object') {
                  if (key === "attributes") {
                    this.columnDefs.push({
                      headerName: key, field: key, hide: true
                    });
                  } else {
                    this.columnDefs.push({
                      headerName: key, field: key, sortable: true, filter: false, lockPosition: true,
                      cellRenderer: "colorCellRenderer",
                      pinnedRowCellRenderer(params) {
                        return params.value;
                      }
                    });
                  }
                } else {
                  if (key === 'STATUS') {
                    this.columnDefs.push({
                      headerName: key, field: key, sortable: true, filter: false, lockPosition: true,
                      cellRenderer(params) {
                        console.log(params.value);
                        const displayElem = '<div class="dcstatus-top" layout="row">' +
                          '<span class="dc-status-data ' + params.value + '">' + params.value + '</span>' +
                          '</div>';
                        return displayElem;
                      },
                    });

                  } else if (key === "DC") {
                    this.columnDefs.push({
                      headerName: key,
                      field: key,
                      sortable: true,
                      filter: false,
                      unSortIcon: true,
                      lockPosition: true,
                      cellRenderer(params) {
                        return '<b>' + params.value + '</b>';
                      },
                    });

                  } else if (key === "dc_id") {
                    this.columnDefs.push({
                      headerName: key, field: key, hide: true
                    });
                  } else if (key === "mtd_perc") {
                    this.columnDefs.push({
                      headerName: key, field: key, hide: true
                    });
                  } else if (key === "po_id" || key === "country_id" || key === "attributes") {
                    this.columnDefs.push({
                      headerName: key, field: key, hide: true
                    });
                  } else {
                    this.columnDefs.push({
                      headerName: key,
                      field: key,
                      sortable: true,
                      filter: false,
                      lockPosition: true,
                    });
                  }
                }
              }
            }
            this.loaded = true;
            this.frameworkComponents = {
              colorCellRenderer: ColorCellRenderer,
              cellEdit2Renderer: CellEdit2Renderer
            };
          } else {
            this.new_po = true;
            this.supplier_products = res['results'].vans;
            this.preloader = false;
            this.empty_state = true;
          }

        } else {
          this.preloader = false;
          this.empty_state = true;
        }

      },
      error => {
        console.log(error);
        this.preloader = false;
        this.apiloaded = true;
      });
  }

  vanList() {
    const viewContainerRef2 = this.adHost2.viewContainerRef;
    viewContainerRef2.clear();

    const componentFactory2 = this.componentFactoryResolver.resolveComponentFactory(SupplierDropdownComponent);

    const componentRef2 = viewContainerRef2.createComponent<SupplierDropdownComponent>(componentFactory2);
    componentRef2.instance.itemList = this.supplier_products;
    componentRef2.instance.statusChange.subscribe(res => {

      if (res && res.length > 0) {
        this.van_code = res[0].code;
      }
    });
  }

  prodList() {
    const viewContainerRef3 = this.adHost3.viewContainerRef;
    viewContainerRef3.clear();

    const componentFactory3 = this.componentFactoryResolver.resolveComponentFactory(SuppProdDropdownComponent);

    const componentRef3 = viewContainerRef3.createComponent<SuppProdDropdownComponent>(componentFactory3);
    componentRef3.instance.itemList = this.loadoutProds[0].products;
    componentRef3.instance.statusChange.subscribe(res => {

      if (res && res.length > 0) {
        this.loadout_prod.push(res[0]);
      }
    });
  }

  po_create() {
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    this.apiService.post('/api/pwa_connects/create_po', this.user_data)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success('Created Successfully');
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
          this.apiDcProd();
        } else {
          this.toastr.error('Error Creating po ' + res.results.msg);
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
        }
      }, error => {
        this.toastr.error('Error Creating po');
        this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
      });
  }

  new_loadout() {
    this.rowData1 = [];
    this.vanList();
    this.prodList();
    this.productEntry();
    const el: HTMLElement = this.newLoadout.nativeElement;
    el.click();
  }

  addProduct() {
    const prod = this.loadout_prod[0];
    this.rowData1.push({
      PRODUCT: prod.itemName,
      STOCK: prod.stock,
      QTY: 0,
      PRICE: 0,
      code: prod.code,
      loadout: true
    });
    this.gridApi1.setRowData(this.rowData1);
    this.loadout_prod = [];
  }

  confirmProd() {

    this.elRef.nativeElement.querySelector('#overlay').style.display = "block";
    const loadout_data = [];
    const van_code = this.van_code;
    if (van_code) {
      const date = this.datePipe.transform(this.user_data.date, 'dd/MM/yyyy');
      this.rowData1.forEach(prod => {
        if (parseFloat(prod['QTY']) > 0) {
          loadout_data.push({
            VanCode: van_code,
            LoadoutDate: date,
            ProductCode: prod.code,
            Qty: prod['QTY']
          });
        }
      });
      if (loadout_data.length > 0) {
        this.user_data.loadout_data = loadout_data;
        if (this.user_data.dc_centers.length === 0) {
          this.user_data.dc_centers.push(this.user_data.dc_id);
        }
        this.res_data = this.apiService.uploadLoadout(this.user_data);
        this.res_data.subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results')) {
            window.alert(res['results'].msg);
          }
          this.apiDcProd();
        }, error => {
          console.log(error);
          if (error.error.error['msg'] === undefined) {
            window.alert("Failed");
          } else {
            window.alert(error.error.error['msg']);
          }
        });
      } else {
        window.alert('Enter Qty');
      }
    } else {
      window.alert('please select van');
    }
  }

  uploadedFile(event) {

    this.uploadCaption = ["", 0];
    this.fileUploaded = event.target.files[0];
    this.readExcel();
  }

  readExcel() {

    const readFile = new FileReader();
    readFile.onload = (e) => {
      this.storeData = readFile.result;
      const data = new Uint8Array(this.storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join("");
      const workbook = XLSX.read(bstr, {type: "binary"});
      const first_sheet_name = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[first_sheet_name];

      this.upload_loadout_data = XLSX.utils.sheet_to_json(this.worksheet, {raw: true});
      this.loadout_data = XLSX.utils.sheet_to_json(this.worksheet, {raw: true});
      console.log(XLSX.utils.sheet_to_json(this.worksheet, {raw: true}));
    };
    readFile.readAsArrayBuffer(this.fileUploaded);
  }

  uploadexcel() {
    this.uploading = true;
    this.upload_loader = true;
    this.user_data.loadout_data = this.loadout_data;
    if (this.user_data.dc_centers.length === 0) {
      this.user_data.dc_centers.push(this.user_data.dc_id);
    }
    this.res_data = this.apiService.uploadLoadout(this.user_data);


    this.res_data.subscribe(res => {
        console.log(res);
        this.uploading = false;
        this.upload_loader = false;
        if (res.hasOwnProperty('results')) {
          if (res['results'].statusCode === 200) {
            this.uploadCaption = [res['results'].msg, 0];
          } else {
            this.uploadCaption = [res['results'].msg, 1];
          }
        }
        this.loadout_data = [];

        this.fileUp.nativeElement.value = null;

        this.apiDcProd();

      },
      error => {
        console.log(error);
        this.uploading = false;
        this.upload_loader = false;
        if (error.error.error['msg'] === undefined) {
          this.uploadCaption = ["Upload Failed", 1];
        } else {
          this.uploadCaption = [error.error.error['msg'], 1];
        }

        this.loadout_data = [];
        this.fileUp.nativeElement.value = null;
      });
  }

  productEntry() {
    this.loaded1 = false;
    this.rowData1 = [];
    this.data1 = {
      PRODUCT: "",
      STOCK: "",
      QTY: "",
      PRICE: "",
      code: "",
      loadout: null
    };
    for (const key in this.data1) {
      if (this.data1.hasOwnProperty(key)) {
        if (key === 'PRICE' || key === 'code' || key === 'loadout') {
          this.columnDefs1.push({
            headerName: key, field: key, sortable: true, filter: false, unSortIcon: true, lockPosition: true, editable: false,
            hide: true
          });
        } else if (key === 'QTY') {
          this.columnDefs1.push({
            headerName: key, field: key, sortable: true, filter: false, unSortIcon: true, lockPosition: true, editable: false,
            cellRenderer: "cellEdit2Renderer", width: 100
          });
        } else if (key === 'STOCK') {
          this.columnDefs1.push({
            headerName: key, field: key, sortable: true, filter: false, unSortIcon: true, lockPosition: true, editable: false,
            width: 100
          });
        } else {
          this.columnDefs1.push({
            headerName: key,
            field: key,
            sortable: true,
            filter: false,
            unSortIcon: true,
            lockPosition: true,
            editable: false,
            resizable: true,
            width: 450
          });
        }
      }
    }
    this.loaded1 = true;
    this.context = this;
    this.frameworkComponents = {
      colorCellRenderer: ColorCellRenderer,
      cellEdit2Renderer: CellEdit2Renderer
    };
  }

  removeProduct(index) {
    this.rowData1.splice(index, 1);
    this.gridApi1.setRowData(this.rowData1);
  }
  @HostListener('gridReady', ['$event'])
  onGridReady1(params) {
    //new
    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.ColumnApi;
    params.api.setRowData(this.rowData1);
    this.gridApi1.setDomLayout("autoHeight");
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (params.columnApi) {
          if (screen.width > 991) {
            // params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      // this.gridOptions1.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi1.autoSizeColumns();
    }
  }
  @HostListener('gridReady', ['$event'])
  onGridReady(params) {
    //new
    this.gridApi = params.api;
    this.gridColumnApi = params.ColumnApi;
    params.api.setRowData(this.rowData);
    this.preloader = false;
    this.gridApi.setDomLayout("autoHeight");
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (params.api) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      this.gridOptions.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid');
  }

  ngOnInit() {
    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.sharedService.setHeader('Production Order List');
    this.renderer[this.collapse_disable === "true" ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {

    this.user_data.date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    localStorage.setItem('my_date', this.user_data.date);
    localStorage.setItem('user_data', JSON.stringify(this.user_data));

    this.apiDcProd();
  }

  changeStatus(event) {

    this.loaded = false;
    const filtStatus = event.itemName;
    const changedData = [];
    if (filtStatus === 'Status All') {
      this.gridOptions.api.setRowData(this.rowData);
      this.loaded = true;
    } else {
      this.rowData.forEach(row => {
        if (row['STATUS'] === filtStatus) {
          changedData.push(row);
        }
      });

      this.gridOptions.api.setRowData(changedData);
      this.loaded = true;
    }
  }
}
