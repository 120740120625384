<div id="overlay"></div>
<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>GSTIN Verification</h2>
    </div>
    <div *ngIf="enable_gstin" class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <div>
        <div class="mt-1">
          <label class="headerlabel" style="display: block">GSTIN</label>
          <input
            class="forminputwhitebg mt-2"
            type="text"
            name="email"
            style="width: 40%"
            [(ngModel)]="gstin"
            [disabled]="editGstn"
          >
          <p *ngIf="editGstn" (click)="edit_gstn()" class="teal_text ml-2">Edit</p>
          <p *ngIf="!editGstn" (click)="gstinVerify()" class="teal_text ml-2">Update</p>
        </div>
        <div class="mt-3">
          <div class="row">
            <div class="col-md-5 pl-0 mt-3">
              <label class="headerlabel">PAN</label>
              <input
                class="forminputwhitebg mt-2"
                type="text"
                name="name"
                [(ngModel)]="entered_pan"
                style="width: 100%"
              >
            </div>
            <div class="col-md-1 pl-0 mt-3">

            </div>
            <div class="col-md-5 pl-0 mt-3">
              <label class="headerlabel">TAN</label>
              <input
                class="forminputwhitebg mt-2"
                type="text"
                name="tan"
                style="width: 100%"
                [(ngModel)]="tan"
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5 pl-0 mt-3">
            <h4 style="color: #17A2B8;">User Entered</h4>
          </div>
          <div class="col-md-1 pl-0 mt-3">
            <img (click)="copyGsttoEntered()" src="assets/Main_arrow.svg">
          </div>
          <div class="col-md-5 pl-0 mt-3">
            <h4 style="color: #17A2B8;">GST Portal</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5 pl-0 mt-3">
            <label class="headerlabel">Name</label>
            <input
              class="forminputwhitebg mt-2"
              type="text"
              name="name"
              style="width: 100%"
              [(ngModel)]="entered_name"
            >
          </div>
          <div class="col-md-1 pl-0 mt-3">
            <img (click)="entered_name = portal_name" class="mt-5" src="assets/blue_arrow.svg">
          </div>
          <div class="col-md-5 pl-0 mt-3">
            <label class="headerlabel">Name</label>
            <input
              class="forminputwhitebg mt-2"
              type="text"
              name="name"
              style="width: 100%"
              [(ngModel)]="portal_name"
            >
          </div>
        </div>

        <div class="row">
          <div class="col-md-5 pl-0 mt-3">
            <label class="headerlabel">Address 1</label>
            <input
              class="forminputwhitebg mt-2"
              type="text"
              name="name"
              style="width: 100%"
              [(ngModel)]="entered_address1"
            >
          </div>
          <div class="col-md-1 pl-0 mt-3">
            <img (click)="entered_address1 = portal_address1" class="mt-5" src="assets/blue_arrow.svg">
          </div>
          <div class="col-md-5 pl-0 mt-3">
            <label class="headerlabel">Address 1</label>
            <input
              class="forminputwhitebg mt-2"
              type="text"
              name="name"
              style="width: 100%"
              [(ngModel)]="portal_address1"
            >
          </div>
        </div>
        <div class="row">
          <div class="col-md-5 pl-0 mt-3">
            <label class="headerlabel">Address 2</label>
            <input
              class="forminputwhitebg mt-2"
              type="text"
              name="name"
              style="width: 100%"
              [(ngModel)]="entered_address2"
            >
          </div>
          <div class="col-md-1 pl-0 mt-3">
            <img (click)="entered_address2 = portal_address2" class="mt-5" src="assets/blue_arrow.svg">
          </div>
          <div class="col-md-5 pl-0 mt-3">
            <label class="headerlabel">Address 2</label>
            <input
              class="forminputwhitebg mt-2"
              type="text"
              name="name"
              style="width: 100%"
              [(ngModel)]="portal_address2"
            >
          </div>
        </div>
        <div class="row">
          <div class="col-md-5 pl-0 mt-3">
            <label class="headerlabel">State</label>
            <input
              class="forminputwhitebg mt-2"
              type="text"
              name="name"
              style="width: 100%"
              [(ngModel)]="entered_state"
            >
          </div>
          <div class="col-md-1 pl-0 mt-3">
            <img (click)="stateChange()" class="mt-5" src="assets/blue_arrow.svg">
          </div>
          <div class="col-md-5 pl-0 mt-3">
            <label class="headerlabel">State</label>
            <input
              class="forminputwhitebg mt-2"
              type="text"
              name="name"
              style="width: 100%"
              [(ngModel)]="portal_state"
            >
          </div>
        </div>
        <div class="row">
          <div class="col-md-5 pl-0 mt-3">
            <label class="headerlabel">Pincode</label>
            <input
              class="forminputwhitebg mt-2"
              type="text"
              name="name"
              style="width: 100%"
              [(ngModel)]="entered_pincode"
              (change)="pincodeCheck()"
            >
          </div>
          <div class="col-md-1 pl-0 mt-3">
            <img (click)="entered_pincode = portal_pincode" class="mt-5" src="assets/blue_arrow.svg">
          </div>
          <div class="col-md-5 pl-0 mt-3">
            <label class="headerlabel">Pincode</label>
            <input
              class="forminputwhitebg mt-2"
              type="text"
              name="name"
              style="width: 100%"
              [(ngModel)]="portal_pincode"
            >
          </div>
        </div>
        <div class="mt-4">
          <input
            type="checkbox"
            name="name"
            class="mr-3"
            [(ngModel)]="has_einvoice"
            #einv="ngModel"
            [disabled]="einvoicedisable"
            style="height: 16px;width: 16px;vertical-align: middle;"
          >
          <label class="headerlabel" style="display: inline-block;margin-top: 4px; vertical-align: middle">Has Einvoice</label>
        </div>
        <div class="mt-3">
          <div class="row">
            <div class="col-md-5 pl-0 mt-3">
              <label class="headerlabel">Billing Address : </label>
              <div class="formselectwhitebg mt-2">
                <select name="selectedStatus" id="billingaddr" class="form-control status-drp"
                        [(ngModel)]="bill_addrss">
                  <option *ngFor="let mode of ['Same AS Outlet', 'GSTIN Billing']">{{mode}}</option>
                </select>
              </div>
            </div>
            <div class="col-md-1 pl-0 mt-3">

            </div>
            <div class="col-md-5 pl-0 mt-3">
              <label class="headerlabel">Shipping Addresss : </label>
              <div class="formselectwhitebg mt-2">
                <select name="selectedStatus" id="shippingaddr" class="form-control status-drp"
                        [(ngModel)]="ship_addrss">
                  <option *ngFor="let mode of ['Same AS Outlet', 'GSTIN Billing']">{{mode}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!enable_gstin" class="body-section">
        <div class="ml-3">
          <div *ngIf="!chain_verified" class="mt-5">
            <img src="../../../../assets/warning_red.svg">
            <p class="pink-text ml-2">Warning! You cannot proceed without verifying Chain</p>
          </div>
          <div *ngIf="type==='create' && !location_verified" class="mt-5">
            <img src="../../../../assets/warning_red.svg">
            <p class="pink-text ml-2">Warning! You cannot proceed without verifying Location</p>
          </div>
        </div>
      </div>
    </div>
    <div class="button-row" style="position: absolute;  right: 0px; margin-top: 20px;">
      <button
        mat-button
        style=" margin-right:15px;"
        class="btn grey-btn"
        (click)="closeDialog()"
      >
        Cancel
      </button>
      <button
        mat-button
        style=" margin-right:15px;"
        class="btn confirm-blue-btn"
        (click)="checkVerify()"
      >
        Verify
      </button>
    </div>
  </div>
</section>
