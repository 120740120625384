import {Component, ElementRef, Inject, OnInit, Renderer2} from "@angular/core";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {ApiService} from "../../../_services/api.service";
import {Router} from "@angular/router";
import {DatePipe, DOCUMENT} from "@angular/common";
import {SharedService} from "../../../_services/shared_service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {SurveyQuestionCreateComponent} from "../../../survey/survey_question_create/survey_question_create.component";
import {SurveyTerritoryCreateComponent} from "../../../survey/survey_territory_create/survey_territory_create.component";

@Component({
  selector: 'app-user_assessment_detail-component',
  templateUrl: './user_assessment_detail.component.html',
  styleUrls: ['./user_assessment_detail.component.scss']
})
export class UserAssessmentDetailComponent implements OnInit {

  private gridApi;
  private gridApi1;
  private gridApi2;
  private gridColumnApi;
  private gridColumnApi1;
  private gridColumnApi2;
  public columnDefs = [];
  public columnDefs1 = [];
  public columnDefs2 = [];
  public rowData = [];
  public rowData1 = [];
  public rowData2 = [];
  public gridOptions: GridOptions;
  public gridOptions1: GridOptions;
  public gridOptions2: GridOptions;
  public loaded = false;
  public loaded1 = false;
  public loaded2 = false;
  public preloader = false;
  public preloader1 = false;
  public preloader2 = false;
  public empty_state = false;
  public empty_state1 = false;
  public empty_state2 = false;
  public defaultColDef;
  public styleGrid;
  public frameworkComponents;
  public context;
  public edit = false;
  API_URL = '';
  user_data = {
    date: null,
    access_token: null,
    url: null,
    data: {
      id: null
    }
  };
  viewtype = 'edit';
  assessmentDetails: any = {};
  activetab = 'QUESTIONS';
  //score = 35;
  selectedProducts = [];

  constructor(private apiService: ApiService,
              private datePipe: DatePipe,
              private elRef: ElementRef,
              private sharedService: SharedService,
              public dialog: MatDialog,
              private toastr: ToastrService,
              @Inject(DOCUMENT) private _document: Document) {

    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.data.id = localStorage.getItem("userassessmentid");
    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left'},
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      rowSelection: 'multiple'
    } as GridOptions;

    this.gridOptions1 = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left'},
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      rowSelection: 'multiple'
    } as GridOptions;

    this.gridOptions2 = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left'},
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      rowSelection: 'multiple'
    } as GridOptions;
    this.columnDefs = [
      {headerName: "SEQUENCE", field: "sequence", width: 80},
      {headerName: "QUESTIONS", field: "name", width: 400},
      {headerName: "QUESTION TYPE", field: "type"},
      {
        headerName: "ANSWERS", field: "ans", width: 200,
        valueGetter(params) {
          return params.data.ans.length;
        }
      },
      {
        headerName: "", field: "actions", cellStyle: {textAlign: 'right'}, width: 50,
        cellRenderer(params) {
          if (params.value === 'bottom') {
            return "";
          } else {
            return ' <span title="Delete" ><i class="fas fa-trash delete" data-action-type="delete"></i></span>';
          }
        }
      },
    ];

    this.columnDefs1 = [
      {headerName: "CODE", field: "code"},
      {headerName: "NAME", field: "name"},
      {headerName: "BEAT COUNT", field: "beat_count"},
      {headerName: "ZONE", field: "zone"},
      {headerName: "STATE", field: "state"},
    ];

    this.columnDefs2 = [
      {headerName: "TYPE", field: "type"},
      {headerName: "CONDITION", field: "expression"},
      {
        headerName: "VALUE", field: "value",
        valueGetter(params) {
          if (params.data.outlet_type !== null && params.data.outlet_type !== "") {
            return params.data.outlet_type;
          } else if (params.data.program_type !== null && params.data.program_type !== "") {
            return params.data.program_type;
          } else {
            return params.data.value;
          }
        }
      },
    ];
    this.fetchAssessmentDetails();

  }


  ngOnInit(): void {
    this.sharedService.setHeader('Users');
  }

  fetchAssessmentDetails() {

    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;

    this.loaded1 = false;
    this.preloader1 = true;
    this.empty_state1 = false;

    this.loaded2 = false;
    this.preloader2 = true;
    this.empty_state2 = false;

    this.API_URL = '/api/crm_profiling_questionnaires/get_retail_survey_details';

    this.apiService.post(this.API_URL, this.user_data)
      .subscribe(res => {

          if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.hasOwnProperty('data'))) {

            this.assessmentDetails = res.results.data;
            console.log(this.assessmentDetails);
            this.rowData = this.assessmentDetails.questions;
            this.rowData1 = this.assessmentDetails.territories;
            this.rowData2 = this.assessmentDetails.criterias;

            this.loaded = true;
            this.loaded1 = true;
            this.loaded2 = true;

            console.log(this.rowData);

          } else {
            this.preloader = false;
            this.empty_state = true;

            this.preloader1 = false;
            this.empty_state1 = true;

            this.preloader2 = false;
            this.empty_state2 = true;
          }
        },
        error => {
          console.log(error);
          this.preloader = false;
          this.preloader1 = false;
          this.preloader2 = false;
        });

  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setRowData(this.rowData);
    this.preloader = false;
    const sortModel = [
      {colId: 'Date', sort: 'desc'}
    ];
    this.gridApi.setSortModel(sortModel);
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    if (screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  onGridReady1(params) {
    this.gridOptions1.api.showLoadingOverlay();
    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
    params.api.setRowData(this.rowData1);
    this.preloader1 = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    if (screen.width > 991) {
      this.gridApi1.sizeColumnsToFit();
    } else {
      this.gridColumnApi1.autoSizeColumns();
    }
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid2');
  }

  onGridReady2(params) {
    this.gridOptions2.api.showLoadingOverlay();
    this.gridApi2 = params.api;
    this.gridColumnApi2 = params.columnApi;
    params.api.setRowData(this.rowData2);
    this.preloader2 = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    if (screen.width > 991) {
      this.gridApi2.sizeColumnsToFit();
    } else {
      this.gridColumnApi2.autoSizeColumns();
    }
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid3');
  }


  quickSearch() {
    this.gridApi.setQuickFilter(document.getElementById('filter-text-box')['value']);
  }


  onCellClicked(event) {
    let selectedQuestion = this.gridApi.getSelectedRows();
    if (selectedQuestion !== null) {
      selectedQuestion = selectedQuestion[0];
    }

    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "1000px",
      maxHeight: "600px",
      panelClass: 'custom-dialog-container',
      data: {
        edit: false,
        questionData: selectedQuestion
      }
    };

    const creatediaeref = this.dialog.open(SurveyQuestionCreateComponent, config);
  }

  onCellClicked1(event) {
    const column = event.column.getColId();
  }

  onCellClicked2(event) {
    const column = event.column.getColId();
  }

  changeActiveTab(event: MatTabChangeEvent) {
    const tab = event.tab.textLabel;
    this.activetab = tab;
    console.log(this.activetab);
  }

  setStartDate(dt) {
    this.assessmentDetails.start_date = this.datePipe.transform(this.assessmentDetails.start_date, 'yyyy-MM-dd');
  }

  setEndDate(dt) {
    this.assessmentDetails.end_date = this.datePipe.transform(this.assessmentDetails.end_date, 'yyyy-MM-dd');
  }

  updateMarketIntelligence() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = this.assessmentDetails;
    this.API_URL = '/api/crm_profiling_questionnaires/retail_survey_update';
    console.log(paramObject);
    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success('Survey updated Successfully');
          this._document.defaultView.location.reload();
        } else {
          this.toastr.error('Failed to update Survey');
        }
      });
  }

  editClick() {
    this.edit = !this.edit;
  }

  discardClick() {
    this.edit = !this.edit;
    this._document.defaultView.location.reload();
    // this.fetchRetailAuditDetails();
  }

  createDialog() {
    if (this.activetab === "QUESTIONS") {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "1000px",
        maxHeight: "600px",
        panelClass: 'custom-dialog-container',
        data: {
          edit: true,
          questionData: null
        }
      };

      const creatediaeref = this.dialog.open(SurveyQuestionCreateComponent, config);

      creatediaeref.componentInstance.createdQuestion.subscribe(data => {

        if (data !== []) {
          this.assessmentDetails.questions.push(data);
          if (this.rowData === null || this.rowData.length === 0) {
            this.rowData = this.assessmentDetails.questions;
            this.loaded = true;
          }

          this.gridApi.setRowData(this.rowData);
        }

      });
    } else if (this.activetab === "TERRITORY") {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "1000px",
        maxHeight: "600px",
      };

      const creatediaeref = this.dialog.open(SurveyTerritoryCreateComponent, config);

      creatediaeref.componentInstance.selectedTerritories.subscribe(data => {

        if (data !== []) {
          let indterritory: any = {};
          if (!this.assessmentDetails.hasOwnProperty("territories")) {
            this.assessmentDetails.territories = [];
          }
          for (let i = 0; i < data.length; i++) {
            indterritory = {};
            indterritory.territory_id = data[i].id;
            indterritory.name = data[i].itemname;
            indterritory.code = data[i].code;
            indterritory.zone = data[i].zone;
            indterritory.zone_id = data[i].zone_id;
            indterritory.state = data[i].state;
            indterritory.state_id = data[i].state_id;
            indterritory.beat_count = data[i].beat_count;
            this.assessmentDetails.territories.push(indterritory);
          }
          if (this.rowData1 === null || this.rowData1.length === 0) {
            this.rowData1 = this.assessmentDetails.territories;
            this.loaded1 = true;
          }
          this.gridApi1.setRowData(this.rowData1);
        }
      });
    }
  }
}
