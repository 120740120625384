import {Component} from "@angular/core";
import {IHeaderAngularComp} from "@ag-grid-community/angular";

@Component({
  selector: 'print-header',
  template: `<span><button class="btn white-blue-btn" (click)="printAll()">Print All</button></span>`,
  styles: []
})
export class PrintInvoiceComponent implements IHeaderAngularComp {
  public params: any;
  public enable_approve;
  public printformats = ['A5L'];

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  printformat(event) {
    this.params.context.componentParent.printFormat = event.target.value;
  }

  printAll() {
    this.params.context.componentParent.printAll(this.params.api.getSelectedRows());
  }
}
