import {Component, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {ApiService} from "../../_services/api.service";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {LocalforageService} from "../../_services/localforage.service";
import {DomSanitizer} from "@angular/platform-browser";
import {DatePipe} from "@angular/common";
import {DMSBasePage} from "../../pagebase/components/DMSBasePage";

@Component({
  selector: 'app-discount-popup',
  templateUrl: './discount_popup.component.html',
  styleUrls: ['./discount_popup.component.scss']

})

export class DiscountPopupComponent implements OnInit {
  @Output() discountappplied: EventEmitter<any> = new EventEmitter<any>();
  productObject: any = {};

  discountMode = 'disc_qty';
  user_dis = 0;
  cash_dis = 0;
  user_input = 0;

  editMode;
  error = false;
  base_total = 0;
  max_rate;
  unit_measure = 1;
  allow_discount_per_volume = false;

  constructor(public apiService: ApiService,
              public dialog: MatDialog,
              protected _localforageService: LocalforageService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {

    this.editMode = dialogdata.editMode;
    this.productObject = dialogdata.productObject;
    // this.cash_dis = dialogdata.cash_dis;
    this.discountMode = dialogdata.discountMode;

    this.base_total = parseInt(this.productObject['quantity'], 10) * parseFloat(this.productObject['price_unit']);
    this.max_rate = (this.base_total / parseInt(this.productObject['quantity'], 10)).toFixed(2);
    if (this.productObject.hasOwnProperty('unit_measure') && this.productObject.unit_measure > 0) {
      this.unit_measure = this.productObject.unit_measure;
    }

    if (!this.productObject.hasOwnProperty('config_json')) {
      this.productObject['config_json'] = {disc_details : { user_dis: 0}};
    } else if (!this.productObject.config_json.hasOwnProperty('disc_details')) {
      this.productObject.config_json.disc_details = { user_dis: 0};
    } else {
      if (this.productObject.config_json.disc_details.hasOwnProperty('cash_dis')) {
        this.cash_dis = parseFloat(this.productObject.config_json.disc_details.cash_dis);
      }
      if (this.productObject.config_json.disc_details.hasOwnProperty('user_dis')) {
        this.user_dis = parseFloat(this.productObject.config_json.disc_details.user_dis);
      }
    }

    this.user_input = this.user_dis;
    if (!this.productObject.hasOwnProperty('discount')) {
      this.productObject.discount = 0;
    }
  }

  ngOnInit(): void {

  }

  closeDialog() {
    this.dialog.closeAll();
  }

  discountSelected() {
    if (this.discountMode === 'disc_value') {
      this.max_rate = this.base_total;
      if (this.productObject.disc_value) {
        this.user_input = this.productObject.disc_value;
      }
    } else if (this.discountMode === 'disc_qty') {
      this.max_rate = (this.base_total / parseInt(this.productObject['quantity'], 10)).toFixed(2);
      if (this.productObject.disc_qty) {
        this.user_input = this.productObject.disc_qty;
      }
    } else if (this.discountMode === 'disc_volume') {
      this.max_rate = (this.base_total / parseInt(this.productObject['quantity'], 10)).toFixed(2);
      if (this.productObject.disc_volume) {
        this.user_input = this.productObject.disc_volume;
      }
    } else if (this.discountMode === 'discount') {
      this.max_rate = 99;
      if (this.productObject.discount) {
        this.user_input = this.productObject.discount;
      }
    }
  }

  discountChanged(newValue) {

    let discount_pct = 0;
    if (this.discountMode === 'discount') {
      discount_pct = parseFloat(newValue);
      const discount_value = (discount_pct / 100) * this.base_total;
      if (discount_value >= this.base_total) {
        //this.productObject['discount'] = event.oldValue;
        this.error = true;
      } else {
        this.error = false;
        //this.productObject['discount'] = discount_pct;
        this.productObject['disc_value'] = discount_value;
        this.productObject['disc_qty'] = discount_value / parseInt(this.productObject['quantity'], 10);
        this.productObject['disc_volume'] = this.productObject['disc_qty'] / this.unit_measure;
      }
    } else if (this.discountMode === 'disc_value') {
      const discount_value = parseFloat(newValue);
      if (discount_value >= this.base_total) {
        //this.productObject['disc_value'] = event.oldValue;
        this.error = true;
      } else {
        this.error = false;
        this.productObject['disc_value'] = discount_value;
        discount_pct = (discount_value * 100) / this.base_total;
        // this.productObject['discount'] = (discount_value * 100) / this.base_total;
        this.productObject['disc_qty'] = discount_value / parseInt(this.productObject['quantity'], 10);
        this.productObject['disc_volume'] = discount_value / this.unit_measure;
      }

    } else if (this.discountMode === 'disc_qty') {
      const discount_per_qty = parseFloat(newValue);
      const discount_value = parseInt(this.productObject['quantity'], 10) * discount_per_qty;
      if (discount_value >= this.base_total) {
        //this.productObject['disc_qty'] = event.oldValue;
        this.error = true;
      } else {
        this.error = false;
        this.productObject['disc_qty'] = discount_per_qty;
        this.productObject['disc_value'] = discount_value;
        discount_pct = (discount_value * 100) / this.base_total;
        // this.productObject['discount'] = (discount_value * 100) / this.base_total;
        this.productObject['disc_volume'] = discount_per_qty / this.unit_measure;
      }
    } else if (this.discountMode === 'disc_volume') {
      const discount_per_volume = parseFloat(newValue);
      const discount_per_qty = discount_per_volume * this.unit_measure;
      const discount_value = parseInt(this.productObject['quantity'], 10) * discount_per_qty;
      if (discount_value >= this.base_total) {
        //this.productObject['disc_volume'] = event.oldValue;
        this.error = true;
      } else {
        this.error = false;
        this.productObject['disc_volume'] = discount_per_volume;
        this.productObject['disc_qty'] = discount_per_qty;
        this.productObject['disc_value'] = discount_value;
        discount_pct = (discount_value * 100) / this.base_total;
        // this.productObject['discount'] = (discount_value * 100) / this.base_total;
      }
    }

    if (!this.productObject.hasOwnProperty('config_json')) {
      this.productObject.config_json = {disc_details: {}};
    } else if (!this.productObject.config_json.hasOwnProperty('disc_details')) {
      this.productObject.config_json['disc_details'] = {};
    }

    // this.user_dis = discount_pct;
    this.productObject.config_json.disc_details['user_dis'] = discount_pct;
    this.productObject.discount = this.cash_dis + discount_pct;
  }

  applyDiscount() {
    this.discountappplied.emit(this.productObject);
    this.dialog.closeAll();
  }
}
