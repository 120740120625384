import {Component, OnInit, ElementRef, Renderer2, ViewChild, ComponentFactoryResolver} from '@angular/core';
import { GridOptions } from "../../../node_modules/@ag-grid-enterprise/all-modules";
import { ApiService} from "../_services/api.service";
import { Router, ActivatedRoute  } from '@angular/router';
import { ColorCellRenderer } from "../colorCell.component";
import { DatePipe } from '@angular/common';
import { LoadingstateComponent} from "../components/loadingstate/loadingstate.component"
import {AdDirective} from "../ad.directive";
import {AdDirective1} from "../ad1.directive";
import {ReportDropdownComponent} from "../components/report_dropdown/report-dropdown.component";
import {TypeDropdownComponent} from "../components/type_dropdown/type-dropdown.component";
import {SharedService} from "../_services/shared_service";

@Component({
  selector: 'app-stock_in_hand_report',
  templateUrl: './stock_in_hand_report.component.html',
  styleUrls: ['./stock_in_hand_report.component.scss']
})
export class Stock_in_hand_reportComponent implements OnInit {
  @ViewChild(AdDirective, {static: true}) adHost: AdDirective;
  @ViewChild(AdDirective1, {static: true}) adHost1: AdDirective1;

  user;
  res_data;
  user_data = {
    "date":null,
    "access_token": null,
    "url": null,
    "last_date": null,
    "offset": null,
    "dc_id": [],
    "type_id": null,
    "flag": true
  };

  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public searchValue;
  private pinnedBottomRowData;
  private pinnedTottomRowData;
  public frameworkComponents;
  public rowData = [];
  public navigate;
  public defaultColDef;
  public sideBar;
  private rowHeight;
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public styleGrid;
  public myDate;
  public collapse_disable;
  public report_flag;
  public all_dc = [];
  public all_types = [];
  public default = true;
  public type_default = true;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef:ElementRef,
              private renderer: Renderer2,
              private sharedService: SharedService,
              private datePipe: DatePipe,
              private componentFactoryResolver: ComponentFactoryResolver) {

    this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.user_data.date = this.myDate;
    this.user_data.offset = -1 * (new Date(this.user_data.date)).getTimezoneOffset();
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');
    this.report_flag = this.route.snapshot.params["report_flag"];

    localStorage.setItem('user_data', JSON.stringify(this.user_data));

    this.all_dc = JSON.parse(localStorage.getItem('all_dcs'));

    this.stockhand();

    this.gridOptions = <GridOptions>{ rowHeight: 28,
      rowStyle: { 'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left' },
      rowSelection: 'single',
      enableRangeSelection: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      pivotMode: false,
      autoGroupColumnDef: {
        pinned: 'left'
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      }};

  }

  stockhand() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];
    let rowdata = [];
    let datePipe = this.datePipe;
    let loadout = 0, cash = 0, credit = 0, rep = 0, ret = 0, gross = 0, grant = 0, out = 0, net = 0, tcs = 0, tot = 0;


    this.res_data = this.apiService.stockInHand(this.user_data);
    this.res_data.subscribe(res => {
        console.log(res);
        if (!this.user_data.flag) {

          if (res.hasOwnProperty('results') && (res['results'].status == 200) && (res['results'].data.length > 0)) {
            res['results'].data.forEach(function (data) {
              var pushData = {
                "Date": datePipe.transform((new Date(data.stock_date + ' UTC')).toString(), 'dd/MM/yy HH:mm:ss'),
                "DC": data.dc,
                "Van": data.van,
                "Beat": data.beat,
                "Code": data.code,
                "Outlet": data.outlet,
                "Type": data.type,
                "Prod Code": data.prod_code,
                "Product": data.product,
                "Stock": data.stock
              };
              rowdata.push(pushData);
            });

            this.data = rowdata[0];
            this.rowData = rowdata;


            for (var key in this.data) {
              if (this.data.hasOwnProperty(key)) {
                if (key == 'DC' || key == 'Van' || key == 'Date') {
                  this.columnDefs.push({
                    headerName: key, field: key, sortable: true, filter: true, lockPosition: true, resizable: true,
                    enableRowGroup: true, enablePivot: true, aggFunc: 'sum', enableValue: true
                  });
                } else {
                  this.columnDefs.push({
                    headerName: key,
                    field: key,
                    sortable: true,
                    filter: true,
                    lockPosition: true,
                    resizable: true,
                    cellStyle: {textAlign: "right"},
                    enableRowGroup: true,
                    enablePivot: true,
                    aggFunc: 'sum',
                    enableValue: true
                  });
                }
              }
            }
            this.loaded = true;

          } else {
            this.preloader = false;
            this.empty_state = true;
          }
        }else {
         /* this.all_dc = res['results'].dcs;*/
          this.dcList();
          this.all_types = res['results'].types;
          this.typeList();
          this.preloader = false;
          this.empty_state = true;
        }

      },
      error => {
        console.log(error);
        this.preloader = false;
      });
  }

  dcList() {
    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ReportDropdownComponent);

    const componentRef = viewContainerRef.createComponent<ReportDropdownComponent>(componentFactory);
    componentRef.instance.itemList = this.all_dc;
    if (this.default || this.user_data.dc_id.length == 0) {
      componentRef.instance.selectedItems = [this.all_dc[this.all_dc.length-1]];
    }else {
      componentRef.instance.selectedItems = JSON.parse(localStorage.getItem('selected_dc'));
    }

    this.user_data.dc_id = [componentRef.instance.selectedItems[0].id];

    componentRef.instance.statusChange.subscribe(res => {

      this.default = false;
      let dc_ids = [];
      res.forEach(function(each_dc) {
        dc_ids.push(each_dc.id);
      });
      this.user_data.dc_id = dc_ids;

    });
  }

  typeList() {
    const viewContainerRef1 = this.adHost1.viewContainerRef;
    viewContainerRef1.clear();

    const componentFactory1 = this.componentFactoryResolver.resolveComponentFactory(TypeDropdownComponent);

    const componentRef1 = viewContainerRef1.createComponent<TypeDropdownComponent>(componentFactory1);
    componentRef1.instance.itemList = this.all_types;
    if (this.type_default || this.user_data.type_id.length == 0) {
      componentRef1.instance.selectedItems = [this.all_types[this.all_types.length-1]];
    }else {
      componentRef1.instance.selectedItems = JSON.parse(localStorage.getItem('selected_type'));
    }
    this.user_data.type_id = componentRef1.instance.selectedItems[0].id;
    componentRef1.instance.statusChange.subscribe(res => {

      if (res.id == 0) {
        this.type_default = true;
      }else {
        this.type_default = false;
      }
      this.user_data.type_id = res.id;
      localStorage.setItem('selected_type', JSON.stringify([res]));
    });
  }

  run() {
    this.user_data.flag = false;
    this.stockhand();
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.setRowData(this.rowData);
    this.preloader = false;

    if (screen.width > 991) {
      this.gridOptions.api.sizeColumnsToFit();
    }
    else {
      this.gridColumnApi.autoSizeColumns();
    }
    // this.gridApi.setDomLayout("autoHeight");
    window.addEventListener('resize', function() {
      setTimeout(function() {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        }else {
          params.columnApi.autoSizeColumns();
        }
      });
    });

    // this.gridColumnApi.autoSizeColumns();


    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  ngOnInit() {
    this.sharedService.setHeader('Retailer Stock Report');
    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable == "true" ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {

    this.user_data.date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    localStorage.setItem('user_data', JSON.stringify(this.user_data));
  }
}
