import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-reportcard',
  templateUrl: './report-cards.component.html',
  styleUrls: ['./report-cards.component.scss']
})
export class ReportCardsComponent implements OnInit {
  @Input() title: string;
  @Input() id: string;
  @Input() code: string;
  @Input() type: string;
  @Output() reportClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  goToReport() {

    this.reportClick.emit(true);
  }

}

