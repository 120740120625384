import {Component, ElementRef, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer} from "@angular/platform-browser";
import {ToastrService} from "ngx-toastr";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {CustomGridToolPanelComponent} from "../../reports/_common/CustomGridToolPanel.component";
import {jsPDF} from "jspdf";
import html2canvas from "html2canvas";
import html2canfast from "html2canfast";
import * as JSZip from "jszip";
import {saveAs} from 'file-saver';

@Component({
  selector: 'app-zip-invoice-download',
  templateUrl: './zip_invoice_download.component.html',
  styleUrls: ['./zip_invoice_download.component.scss']

})

export class ZipInvoiceDownloadComponent implements OnInit {

  @Output() progressStatus: EventEmitter<any> = new EventEmitter<any>();
  public photo;
  title = '';
  API_URL = '';
  progress = 0;
  container = '';
  user_data: any = {};
  data = [];
  dataname = '';
  batchSize = 1;
  completed = 0;
  total = 0;
  rowData = [];
  type = '';
  failedResponse = [];
  processingComplete = false;

  dataLoaded = false;
  columnDefs = [];
  gridOptions: GridOptions;
  context: any;
  gridApi;
  gridColumnApi;
  styleGrid: any;
  gridData = [];
  all_orders = [];
  zip_order;
  template;
  selectedTemplate;

  constructor(public toastr: ToastrService,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private domSanitizer: DomSanitizer,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.title = dialogdata.title;
    this.template = dialogdata.template;
    this.selectedTemplate = dialogdata.selectedTemplate;
    this.all_orders = dialogdata.all_orders;
  }

  ngOnInit(): void {
    this.generateBlob(this, this.all_orders);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async generateBlob(inthis, all_orders) {
    const zip = new JSZip();
    const count = all_orders.length;
    this.total = all_orders.length;
    let i = 0;
    this.template = false;
    this.progress = 10;
    const initial = this.progress;
    const diff = 100 - this.progress;

    for (const key in all_orders) {
      i++;
      inthis.zip_order = null;
      inthis.zip_order = all_orders[key];
      inthis.canvas = null;
      await this.timeout(100);
      const element = document.getElementById('print-section-sales-zip');
      const htmlresp1 = await html2canfast(document.querySelector("#print-section-sales-zip"), {
        renderName: 'invoice',
        replaceSelector: '#print-section-sales-zip',
        removeContainer: false,
        allowTaint: true,
        useCORS: true,
        backgroundColor: '#ffffff'
      }).then(canvas => {
        inthis.canvas = canvas.toDataURL();
      });
      const pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      const imgProps = pdf.getImageProperties(inthis.canvas);
      const pdfWidth = pdf.internal.pageSize.getWidth() - 40;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(inthis.canvas, 'JPEG', 20, 20, pdfWidth, pdfHeight, '', 'FAST');
      await this.addFiletoZip(zip, all_orders[key]['invoice_no'] + `.pdf`, pdf.output('blob'));
      this.progress = Math.round(initial + ((i / count) * diff));
      this.completed += 1;
      if (i === count) {
        zip.generateAsync({type: 'blob'}).then(zipFile => {
          const currentDate = new Date().getTime();
          const fileName = `invoice-${currentDate}.zip`;
          return saveAs(zipFile, fileName);
        });
      }
    }
    this.dialog.closeAll();
  }

  addFiletoZip(zip, name, pdf) {
    return new Promise(async (resolve, reject) => {
      try {
        zip.file(name, pdf);
        resolve('Dont do part2 until the.thecanvas is populated');
      } catch (error) {
        reject(error);
      }
    });
  }
}
