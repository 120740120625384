import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";

@Component({
  selector: 'damage-editor',
  template: `<div class="eod-top" layout="row">` +
  `<span class="element-po"><input style="width: 60% !important;" type="text" [(ngModel)]="damageValue" (ngModelChange)="editCell($event)" class="custom-inputbox"/></span>` +
  `</div>`
})
export class DamageEditRenderer implements ICellRendererAngularComp {
  public params: any;
  public damageValue = "";
  public disable = false;

  agInit(params: any): void {
    this.params = params;
    this.damageValue = this.params.data['Damaged Qty'];
  }

  public editCell(event) {

    this.params.data['Damaged Qty'] = this.damageValue;
    this.params.data['Reusable Qty'] = this.params.data['QTY'] - this.params.data['Damaged Qty'];
    this.params.context.componentParent.changeReuseValue(this.params.data['Reusable Qty'], this.params.node);
  }

  refresh(): boolean {
    return false;
  }
}
