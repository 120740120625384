import {Component} from "@angular/core";
import {BaseUpload} from "../../pagebase/base_upload/base_upload";

@Component({
  selector: 'app-task-retailer-upload',
  templateUrl: '../../pagebase/base_upload/base_upload.html',
  styleUrls: ['../../pagebase/base_upload/base_upload.scss']

})

export class TaskRetailerUploadComponent extends BaseUpload {

  quarterPeriodData = [];
  task_types = [];
  task_type: any = {};
  setViewParams() {
    this.header = 'Add Retailers to Task';
    this.sampleText1 = 'DOWNLOAD SAMPLE TEMPLATE';
    this.excel_columns = [{C: 'A', H: 'Retailer Code', V: 'R123', required: true},
      {C: 'B', H: 'Start Date', V: '01-04-2024', required: true},
      {C: 'C', H: 'End Date', V: '30-04-2024', required: true}
    ];
    this.step1Text = 'Download sample template for reference.';
    this.step2Text = 'Required fields are Retailer Code, Start Date and End Date';
    this.actionText = 'Add Retailers to Task';
    this.baseData = this.sampleData1;
    this.dataLoaded = true;
  }

  sampleDownload1() {
    this.downloadSampleData('task_retailer_template');
  }

  configureGrid() {
    this.API_URL = '/api/tasks/task_retailer_upload';
    this.columnDefs = [
      {headerName: 'Retailer Code', field: 'rcode', width: 100},
      {headerName: 'Start Date', field: 'start_date', width: 100},
      {headerName: 'End Date', field: 'end_date', width: 100}
    ];
  }

  validate() {
    const inThis = this;
    this.validRecords = 0;
    this.errorRecords = 0;
    this.uploaded_data.forEach(data => {
      const indrow: any = {};
      indrow.description = data['Description'];
      indrow.task_id = inThis.dialogdata.taskDetails.id;
      if (!data['Retailer Code'] || data['Retailer Code'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Retailer Code Missing';
      } else {
        indrow.rcode = data['Retailer Code'];
      }
      if (!data['Start Date'] || data['Start Date'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Start Date Missing';
      } else {
        indrow.start_date = this.sharedService.convertExcelStringToDate(data['Start Date']);
        if (new Date(indrow.start_date) < new Date(inThis.dialogdata.taskDetails.start_date)) {
          indrow.valid = 'Invalid';
          indrow.errorMsg = 'Start Date before Task Start Date';
        }
      }
      if (!data['End Date'] || data['End Date'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'End Date Missing';
      } else {
        indrow.end_date = this.sharedService.convertExcelStringToDate(data['End Date']);
        if (new Date(indrow.end_date) > new Date(inThis.dialogdata.taskDetails.end_date)) {
          indrow.valid = 'Invalid';
          indrow.errorMsg = 'End Date after Task End Date';
        }
      }
      if (indrow.valid === 'Invalid') {
        this.errorRecords++;
      } else {
        if (new Date(indrow.start_date) > new Date(indrow.end_date)) {
          indrow.valid = 'Invalid';
          indrow.errorMsg = 'End Date before Start Date';
          this.errorRecords++;
        } else {
          indrow.valid = 'Valid';
          this.validRecords++;
        }
      }
      this.rowData.push(indrow);
    });
    this.totalRecords = this.rowData.length;
    if (this.gridOptions && this.gridOptions.api) {
      this.gridOptions.api.setRowData(this.rowData);
    }
  }
}
