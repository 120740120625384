import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {ErrorHandler} from '../../error/error-handler';

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class TertiarySalesReportComponent extends ReportBase implements IReport, OnInit {

  public attributeTypes = [];
  apiData: any = [];

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Tertiary Sales Report');

    this.showableFilters.state = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    // this.showDate = false;

    this.gridOptions.autoGroupColumnDef = {
      headerName: 'CATEGORY',
      field: 'categ',
      minWidth: 250,
      cellRenderer: 'agGroupCellRenderer',
      resizable: true
    };
    this.API_URL = '/api/pwa_reports/tertiarySalesReport';
      }

  configureGrid() {

    this.columnDefs = [
      {headerName: 'REGION', field: 'region', rowGroup: true, enableRowGroup: true, menuTabs: [], width: 80},
      {
        headerName: 'CLUSTER', field: 'cluster', rowGroup: true, enableRowGroup: true, hide: true, menuTabs: [], width: 100,
        valueGetter: params => {
          if (params.data) {
            if (params.data.cluster) {
              return params.data.cluster;
            } else {
              return 'NA';
            }
          }
        }
      },
      {headerName: 'DATE', field: 'order_date', menuTabs: [], width: 80},
      {headerName: 'REPORTING MANAGER', field: 'team_lead', hide: true, enableRowGroup: true, width: 80},
      {headerName: 'PARENT TEAM', field: 'parent_name', width: 100, enableRowGroup: true},
      {headerName: 'TEAM', field: 'team_name', width: 100, enableRowGroup: true},
      {headerName: 'EMP CODE', field: 'emp_code', menuTabs: [], width: 80},
      {headerName: 'SALESMAN', field: 'emp_name', enableRowGroup: true, menuTabs: [], width: 100},
      {headerName: 'ROLE', field: 'role', enableRowGroup: true, menuTabs: [], width: 80},

      {headerName: 'ORDER Ref', field: 'order_ref', menuTabs: [], width: 100},
      {headerName: 'OUTLET CODE', field: 'cust_code', menuTabs: [], width: 100},
      {headerName: 'OUTLET NAME', field: 'cust_name', enableRowGroup: true, menuTabs: [], width: 100},
      {headerName: 'OUTLET TYPE', field: 'outlet_type', enableRowGroup: true, hide: true, menuTabs: [], width: 100},
      {headerName: 'PROGRAM TYPE', field: 'program_type', enableRowGroup: true, hide: true, menuTabs: [], width: 100},

      {headerName: 'CATEGORY', field: 'categ', enableRowGroup: true, hide: true, menuTabs: [], width: 100},
      {headerName: 'BRAND', field: 'brand', enableRowGroup: true, menuTabs: [], width: 100},
      {headerName: 'FAMILY', field: 'family', enableRowGroup: true, menuTabs: [], width: 100},
      {headerName: 'SKU CODE', field: 'prd_code', menuTabs: [], width: 80},
      {headerName: 'SKU NAME', field: 'prd_name', enableRowGroup: true, menuTabs: [], width: 100},
    ];

    if ( this.apiData.prd_attr != null) {
      this.attributeTypes = this.apiData.prd_attr.map(p => p.attribute)
        .filter((type, index, arr) => arr.indexOf(type) === index);

      for (const key in this.attributeTypes) {
        this.columnDefs.push({
          headerName: this.attributeTypes[key],
          field: this.attributeTypes[key],
          width: 100,
          aggFunc: 'sum'
        });
      }
    }

    this.columnDefs.push(
      {headerName: 'MRP', field: 'mrp', menuTabs: [], width: 80},
      {headerName: 'UOM', field: 'uom', enableRowGroup: true, menuTabs: [], width: 100},
      {headerName: 'TERTIARY QTY', field: 'qty', width: 100, aggFunc: 'sum'},
      {headerName: 'AMOUNT', field: 'total', width: 100, aggFunc: 'sum'},
      {headerName: 'REGION MGR', field: 'region_manager', enableRowGroup: true, menuTabs: [], width: 100},
      {headerName: 'CLUSTER MGR', field: 'cluster_manager', enableRowGroup: true, menuTabs: [], width: 100},
      {headerName: 'TERRITORY CODE', field: 'ter_code', menuTabs: [], width: 50},
      {headerName: 'TERRITORY', field: 'territory', enableRowGroup: true, menuTabs: [], width: 100},
      {headerName: 'TERRITORY MGR', field: 'territory_manager', enableRowGroup: true, menuTabs: [], width: 100},
    );

  }
  loadServerData() {
 this.goClicked = true;
     //debugger;
    /*   const t = trace(this.appModule.perf, this.sharedService.headerTitleSource.value);
       t.start();
       t.putAttribute('companyKey', localStorage.getItem('company_key'));*/
    console.log(this.sharedService.headerTitleSource.value);
    this.dataLoaded = false;
    this.preloader = true;
    this.emptyState = false;
    this.errorExists = false;

    if (this.API_URL === '') {
      return this.handleError(ErrorHandler.getErrorObject('API Not Configured'));
    }

    this.userData.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.userData.end_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    this.apiService.post(this.API_URL, this.userData)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            //this.rowData = res.results.data;
            this.apiData = res.results.data;
            this.configureGrid();
            this.configureData();
            this.dataLoaded = true;
            this.preloader = false;
            this.emptyState = (this.rowData.length === 0);
          } else if (res.results.status === 203) {
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        // tslint:disable-next-line:no-shadowed-variable
        error => this.handleError(ErrorHandler.getErrorObject(error)));

  }

  configureData() {
    this.rowData = [];
    let saleRow: any = {};
    saleRow = this.apiData.sales;
    const inThis = this;
    for (const key in saleRow) {
      let indRow = {};
      const attRow = [];

      for (const key1 in inThis.attributeTypes) {
        attRow[inThis.attributeTypes[key1]] = this.apiData.prd_attr.filter(api => api.prd_id === saleRow[key].prd_id
          && api.attribute === inThis.attributeTypes[key1]);
        if (attRow[inThis.attributeTypes[key1]] != null && attRow[inThis.attributeTypes[key1]].length > 0) {
          attRow[inThis.attributeTypes[key1]] = attRow[inThis.attributeTypes[key1]][0].value;
        }
      }

      indRow = {
        ...saleRow[key],
        ...attRow
      };
      this.rowData.push(indRow);
    }
  }

}
