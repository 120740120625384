import {AfterViewInit, Component, ElementRef, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {ApiService} from '../../../_services/api.service';
import {ToastrService} from 'ngx-toastr';
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-gstin-verify-popup',
  templateUrl: './gstin-verify-popup.component.html',
  styleUrls: ['./gstin-verify-popup.component.scss']

})

export class GstinVerifyPopupComponent implements OnInit, AfterViewInit {

  @Output() verifyClicked: EventEmitter<any> = new EventEmitter<any>();
  public gstin = null;
  public entered_name = null;
  public portal_name = null;
  /* public entered_mobile = null;
   public portal_mobile = null;
   public entered_email = null;
   public portal_email = null;*/
  public entered_pan = null;
  public portal_pan = null;
  public entered_address1 = null;
  public portal_address1 = null;
  public entered_address2 = null;
  public portal_address2 = null;
  public entered_state = null;
  public portal_state = null;
  public entered_pincode = null;
  public portal_pincode = null;
  public bill_addrss = 'Same AS Outlet';
  public ship_addrss = 'Same AS Outlet';
  public has_einvoice = false;
  public editGstn = true;
  public verify_json;
  public partner_id;
  public refreshDlg = false;
  public enable_gstin = false;
  public valid_state = false;
  public final_data = {};
  gst_state = null;
  type = 'create';
  chain_verified = false;
  location_verified = false;
  public country_id = 0;
  einvoicedisable = true;
  validGst = false;
  tan;
  attribute_json;

  constructor(private apiService: ApiService,
              public dialog: MatDialog,
              private toastr: ToastrService,
              private elRef: ElementRef,
              private datePipe: DatePipe,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.type = dialogdata.type;
    this.verify_json = dialogdata.params.verify;
    this.partner_id = dialogdata.params.id;
    this.gstin = dialogdata.params.gst_id;
    this.entered_name = dialogdata.params.name;
    this.country_id = this.dialogdata.params.country_id;
    this.attribute_json = this.dialogdata.params.attributes;
    if (!this.attribute_json) {
      this.attribute_json = {};
    } else {
      if (this.attribute_json.tan) {
        this.tan = this.attribute_json['tan'];
      }
    }
    /*   this.entered_mobile = dialogdata.params.mobile;
       this.entered_email = dialogdata.params.email;*/
    if (dialogdata.params.pan !== null && dialogdata.params.pan !== '') {
      this.entered_pan = dialogdata.params.pan;
    } else {
      if (dialogdata.params.gst_id !== null && dialogdata.params.gst_id !== '' && this.country_id === 105) {
        this.entered_pan = this.gstin.substring(2, 12);
      } else {
      }
    }
    this.entered_address1 = dialogdata.params.street;
    this.entered_address2 = dialogdata.params.street2;
    this.entered_state = dialogdata.params.state;
    if (dialogdata.params.zip !== null) {
      this.entered_pincode = dialogdata.params.zip.replace(/[^0-9]/g, '');
    }
    this.has_einvoice = dialogdata.params.has_einvoice;
    if (((this.verify_json.chain.verified === true || this.verify_json.chain.verified === 'true'))
      || this.type === 'edit') {
      this.enable_gstin = true;
    }

    if (this.verify_json.chain.verified === true || this.verify_json.chain.verified === 'true') {
      this.chain_verified = true;
    } else {
      this.chain_verified = false;
    }

    /*  if (this.verify_json.location.verified === true || this.verify_json.location.verified === 'true') {
        this.location_verified = true;
      } else {
        this.location_verified = false;
      }*/
    this.eInvoiceCheck();
  }

  eInvoiceCheck() {
    if (this.gstin !== null && this.gstin !== "") {
      if (this.country_id !== 105) {
        //this.einvoicedisable = true;
        this.has_einvoice = false;
      } else {
          if (this.validGst) {
            //this.einvoicedisable = true;
            this.has_einvoice = true;
          } else {
            //this.einvoicedisable = true;
            this.has_einvoice = false;
          }
      }
    }
    if (this.country_id !== 105) {
      //this.einvoicedisable = true;
      this.has_einvoice = false;
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.gstinVerify();
  }

  edit_gstn() {
    this.editGstn = false;
  }

  pincodeCheck() {
    if (this.entered_pincode !== null) {
      this.entered_pincode = this.entered_pincode.replace(/[^0-9]/g, '');
    }
  }

  gstinVerify() {
    if (this.country_id !== 105) {
      this.editGstn = true;
    } else {
      this.valid_state = false;
      this.portal_name = null;
      this.portal_address1 = null;
      this.portal_address2 = null;
      this.portal_state = null;
      this.portal_pincode = null;
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
      this.editGstn = true;
      const inThis = this;
      if (this.gstin) {
        if (this.gstin !== null && this.gstin !== undefined && this.gstin.length > 0) {
          this.gstin = this.gstin.trim();
        }
        const userData = {
          access_token: localStorage.getItem('resfreshToken'),
          tax_code: '27AAICM3930G1ZH',
          gstin: this.gstin
        };
        this.apiService.post('/api/einvoice_orders/getGSTINDetails', userData)
          .subscribe(resp => {
              console.log(resp);
              if (resp.result) {
                this.validGst = true;
                this.eInvoiceCheck();
                this.entered_pan = this.entered_pan = this.gstin.substring(2, 12);
                inThis.portal_name = resp.result.TradeName;
                inThis.portal_address1 = resp.result.AddrBnm;
                inThis.portal_address2 = resp.result.AddrBno;
                inThis.portal_state = resp.result.StateCode;
                inThis.portal_pincode = resp.result.AddrPncd;
                if (inThis.portal_pincode !== null) {
                  inThis.portal_pincode = inThis.portal_pincode.toString().replace(/[^0-9]/g, '');
                }
                inThis.gst_state = inThis.portal_state;
                console.log(resp.result.StateCode);
                if (resp.result.StateCode) {
                  const states = JSON.parse(localStorage.getItem('all_states'))
                    .filter(existed => existed.tax_code === resp.result.StateCode.toString());
                  if (states.length > 0) {
                    console.log(states[0]);
                    inThis.portal_state = states[0].itemName;
                    inThis.valid_state = true;
                  }
                }
              } else {
                this.toastr.error('Error Fetching GSTIN details');
              }
              this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
            },
            error => {
              console.log(error);
              this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
              this.toastr.error('Error Fetching GSTIN details ');
            });
      } else {
        this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
        this.toastr.warning('Please enter valid GST');
      }
    }
  }

  stateChange() {
    if (this.valid_state) {
      this.entered_state = this.portal_state;
    } else {
      this.toastr.error('Not A Valid State');
    }
  }

  copyGsttoEntered() {
    this.entered_name = this.portal_name;
    this.entered_address1 = this.portal_address1;
    this.entered_address2 = this.portal_address2;
    this.entered_pincode = this.portal_pincode;
    this.stateChange();
  }

  checkVerify() {
    if (this.gstin !== null && this.gstin !== undefined && this.gstin.length > 0) {
      this.gstin = this.gstin.trim();
    }
    if (this.entered_pan !== null && this.entered_pan !== undefined && this.entered_pan.length > 0) {
      this.entered_pan = this.entered_pan.trim();
    }
    if (this.type === 'create') {
      this.verifyGSTIN();
    } else {
      this.pincodeCheck();
      this.attribute_json['tan'] = this.tan;
      const data = {
        gst_id: this.gstin,
        name: this.entered_name,
        pan: this.entered_pan,
        street: this.entered_address1,
        street2: this.entered_address2,
        state: this.entered_state,
        zip: this.entered_pincode,
        has_einvoice: this.has_einvoice,
        partner_id: this.partner_id,
        partner_billing_id: this.partner_id,
        partner_shipping_id: this.partner_id,
        verification_json: this.verify_json,
        gst_name: this.portal_name,
        gst_street: this.portal_address1,
        gst_street2: this.portal_address2,
        gst_zip: this.portal_pincode,
        gst_state: this.gst_state,
        attribute_json: this.attribute_json
      };
      if (this.bill_addrss === 'GSTIN Billing') {
        data.partner_billing_id = -1;
      }
      if (this.ship_addrss === 'GSTIN Billing') {
        data.partner_shipping_id = -1;
      }
      this.verifyClicked.emit(data);
    }
  }

  verifyGSTIN() {
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    this.final_data = {};
    this.verify_json.gst.verified = true.toString();
    this.verify_json.gst.date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.verify_json.gst.by = JSON.parse(localStorage.getItem('user')).user_name;
    this.attribute_json['tan'] = this.tan;
    const userData = {
      access_token: localStorage.getItem('resfreshToken'),
      data: {
        gst_id: this.gstin,
        name: this.entered_name,
        pan: this.entered_pan,
        street: this.entered_address1,
        street2: this.entered_address2,
        state: this.entered_state,
        zip: this.entered_pincode,
        has_einvoice: this.has_einvoice,
        partner_id: this.partner_id,
        partner_billing_id: this.partner_id,
        partner_shipping_id: this.partner_id,
        verification_json: this.verify_json,
        gst_name: this.portal_name,
        gst_street: this.portal_address1,
        gst_street2: this.portal_address2,
        gst_zip: this.portal_pincode,
        gst_state: this.gst_state,
        attribute_json: this.attribute_json
      }
    };
    if (this.bill_addrss === 'GSTIN Billing') {
      userData.data.partner_billing_id = -1;
    }
    if (this.ship_addrss === 'GSTIN Billing') {
      userData.data.partner_shipping_id = -1;
    }
    this.final_data = userData.data;
    this.apiService.post('/api/res_partners/updateCustomer', userData)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.toastr.success('Updated Successfully');
            this.refreshDlg = true;
            this.dialog.closeAll();
          } else {
            this.toastr.error('Error updating Approval Request');
          }
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
        },
        error => {
          console.log(error);
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
          this.toastr.error('Error updating Approval Request');
        });

  }

  closeDialog() {
    this.dialog.closeAll();
  }

}
