<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Update Order Status</h2>
    </div>
    <div class="body-section" style="margin-top: 20px; padding: 20px 15px;">
      <label class="lbl">Reason</label>
<!--      <div style="margin-top: 5px;" class="formselect">-->
<!--        <select name="comments"-->
<!--                id="comments"-->
<!--                [(ngModel)]="updateData.reason"-->
<!--                #comments="ngModel">-->
<!--          <option> Shortfall communicated</option>-->
<!--          <option> Order picking started</option>-->
<!--          <option> Order picking completed</option>-->
<!--          <option> Vehicle planned</option>-->
<!--          <option> Vehicle reported at Plant</option>-->
<!--          <option> Loading Started</option>-->
<!--          <option> Loading ends</option>-->
<!--          <option> TMS entry generated</option>-->
<!--          <option> Vehicle released</option>-->

<!--        </select>-->
<!--      </div>-->
      <div class="formselect">
        <ng-select *ngIf="statusReasons"
                   [items]="statusReasons"
                   bindLabel="comments"
                   bindValue="comments"
                   [(ngModel)]="updateData.reason">
          >
        </ng-select>
        </div>
      <div style="height: 70px;margin-top: 20px;">
                    <textarea
                      class="forminput"
                      style="height: 90px; width: 100%"
                      type="text"
                      name="reject_comments"
                      #reject_reason="ngModel"
                      [(ngModel)]="updateData.updateComments"
                      placeholder="Enter Comments"
                    ></textarea>
      </div>
    </div>
    <div class="button-row" style="margin-top: 20px;margin-left: 15px;">
      <button (click)="performAction()" data-dismiss="modal" class="btn blue-btn">Submit</button>
    </div>
  </div>
</section>
