<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <h2>{{title}}</h2>
    </div>
    <div class="body-section w-100" style="text-align: center;">
      <!--<div class="progress progress-striped active">
        <label>{{title}}</label>
        <mat-progress-bar class="progressbar" mode="determinate" [value]="progress"></mat-progress-bar>
        <span >{{progress}}%</span>
      </div>-->
      <mat-progress-bar style="height:25px;margin-top: 30px;" class="progressbar" mode="determinate" [value]="progress"></mat-progress-bar>
     <!-- <span style="
    position: absolute;
        margin-top: -20px;
        z-index: 10000001;
        color: saddlebrown;
        font-size: 9px;">{{progress}}%</span>-->
      <span  style="display:block;float:left;">{{completed}}/{{total}}</span>
      <span  style="display:block;float:right;">{{progress}}%</span>
     <!-- <div class="row" style="width: 100%">
        <span  style="display:block;float:left">{{completed}}/{{total}}</span>
        <span  style="display:block;float:right">{{progress}}</span>
      </div>-->
    </div>
    <div style="clear: both; display: block; margin-top: 10px;">
    <ag-grid-angular *ngIf="dataLoaded" id="myGridpo" style="height: 35vh" class="ag-theme-balham"
                     [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                     (gridReady)="onGridReady($event)"
                     [context]="context">
    </ag-grid-angular>
    </div>
  </div>
</section>
