import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {ErrorHandler} from "../../error/error-handler";

@Component({
  selector: 'app-kpi-report-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class UserKpiSummaryComponent extends ReportBase implements IReport, OnInit {

  public attributeTypes = [];
  // showCustomFilter = true;
  // customFilter: any = {
  //   name: 'Active',
  //   key: 'active',
  //   value: 'true',
  //   values: [],
  //   show: true
  // };
  apiData: any = [];
  uqTypes;

  customFilter: any = {
    name: 'Kpi',
    key: 'id',
    value: 0,
    values: [],
    show: true
  };

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('User Kpi Summary');
    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.showDate = true;
    let datas: any = {};
    datas = history.state;
    this.userData.customFilter['id'] = datas.kpiID;
    // this.loadInBatches(5000, 'rowid');
    this.showProgressCustomText = true;

    this.API_URL = '/api/pwa_v2_reports/kpiUserReport';

    this.fetchKpiPlans();
    if (this.userData.customFilter['id'] && this.userData.customFilter['id'] !== 0) {
      this.showCustomFilter = false;
      this.loadServerData();
    }
  }

  fetchKpiPlans() {
    this.elRef.nativeElement.querySelector('#overlay1').style.display = "block";
    this.dataLoaded = false;
    this.preloader = true;
    this.emptyState = false;
    this.errorExists = false;
    this.apiService.post('/api/pwa_v2_reports/getUserKpiPlans', this.userData)
      .subscribe(res => {
          console.log(res);
          // t.stop();
          this.elRef.nativeElement.querySelector('#overlay1').style.display = "none";
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            this.customFilter.values = [];
            if (res.results.data && res.results.data.length > 0) {
              for (const item of res.results.data) {
                this.customFilter.values.push({id: item.id, itemName: item.name});
              }
              this.customFilter.value = this.customFilter.values[0].id;
              this.userData.customFilter['id'] = this.customFilter.values[0].id;
              /* this.elRef.nativeElement.querySelector('#customfilter')['options'] = this.customFilter.values;
               this.elRef.nativeElement.querySelector('#customfilter')['options'][0].selected = true;*/
              this.showCustomFilter = true;
              console.log(this.userData);
            }
          } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
            this.emptyState = true;
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.emptyState = true;
            if (res.hasOwnProperty('results') && res.results.hasOwnProperty('errmsg')) {
              console.error(res.results.errmsg);
            }
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        // tslint:disable-next-line:no-shadowed-variable
        error => {
          this.elRef.nativeElement.querySelector('#overlay1').style.display = "none";
          this.handleError(ErrorHandler.getErrorObject(error));
        });
  }

  configureGrid() {
    this.columnDefs = [
      {
        headerName: "PLAN",
        children: [
          {headerName: 'NAME', field: 'plan_name', enableRowGroup: true, width: 80, hide: false},
          {headerName: 'STATE', field: 'state', enableRowGroup: true, width: 80, hide: true},
          {headerName: 'START DATE', field: 'start_date', enableRowGroup: true, width: 80, hide: true},
          {headerName: 'END DATE', field: 'end_date', width: 80, hide: true}
        ]
      }
    ];

    this.apiData.forEach(data => {
      let alreadyExist = false;
      let lineHeader;
      if (data.hasOwnProperty("entity_type") && data.entity_type === "user") {
        lineHeader = "USER";
        for (let i = 0; i < this.columnDefs.length; i++) {
          if (this.columnDefs[i].headerName === lineHeader) {
            alreadyExist = true;
            break;
          }
        }
        if (!alreadyExist) {
          this.columnDefs.push({
            headerName: lineHeader,
            children: [
              {
                headerName: "SALESMAN",
                field: 'user_name',
                width: 100
              },
              {
                headerName: "EMP CODE",
                field: 'emp_code',
                width: 100
              },
              {
                headerName: "TEAM",
                field: 'team',
                width: 100
              },
              {
                headerName: "ACTIVE",
                field: 'active',
                width: 100, hide: true
              },
              {
                headerName: "EMAIL",
                field: 'user_email',
                width: 100, hide: true
              },
              {
                headerName: "MOBILE",
                field: 'user_mobile',
                width: 100, hide: true
              },
              {
                headerName: "PARENT TEAM",
                field: 'parent_team',
                width: 100, hide: true
              },
              {
                headerName: "STATE",
                field: 'state',
                width: 100, hide: true
              },
              {
                headerName: "ROLE",
                field: 'user_role',
                width: 100, hide: true
              },
              {
                headerName: "REPORTING MGR",
                field: 'reporting_manager',
                width: 100, hide: true
              },
              {
                headerName: "TERRITORY",
                field: 'territory',
                width: 100, hide: true
              },
              {
                headerName: "TERR CODE",
                field: 'tcode',
                width: 100, hide: true
              },
              {
                headerName: "TERRITORY MGR",
                field: 'territory_manager',
                width: 100, hide: true
              },
              {
                headerName: "CLUSTER",
                field: 'cluster',
                width: 100
              },
              {
                headerName: "CLUSTER MGR",
                field: 'cluster_manager',
                width: 100, hide: true
              },
              {
                headerName: "REGION",
                field: 'region',
                width: 100
              },
              {
                headerName: "REGION MGR",
                field: 'region_manager',
                width: 100, hide: true
              }
            ]
          });
        }
      } else if (data.hasOwnProperty("entity_type") && data.entity_type === "territory") {
        lineHeader = "TERRITORY";
        for (let i = 0; i < this.columnDefs.length; i++) {
          if (this.columnDefs[i].headerName === lineHeader) {
            alreadyExist = true;
            break;
          }
        }
        if (!alreadyExist) {
          this.columnDefs.push({
            headerName: lineHeader,
            children: [
              {
                headerName: "TERRITORY",
                field: 'territory_name',
                width: 100
              },
              {
                headerName: "TERRITORY CODE",
                field: 'territory_code',
                width: 100
              }
            ]
          });
        }
      }
      alreadyExist = false;
      let scoreAvailable = false;
      data.value_json.forEach(line_json => {
        if (line_json && line_json.hasOwnProperty("kpi_name")) {
          const kpiHeader = line_json.kpi_name;
          for (let i = 0; i < this.columnDefs.length; i++) {
            if (this.columnDefs[i].headerName === kpiHeader) {
              alreadyExist = true;
              break;
            }
          }
          if (!alreadyExist) {

            if (line_json && line_json.hasOwnProperty("kpi_type")) {
              const kpi_type = line_json.kpi_type;
              if (kpi_type === "result") {
                if (line_json.rank) {
                  this.columnDefs.push({
                    headerName: kpiHeader,
                    children: [
                      {
                        headerName: "VALUE",
                        field: kpiHeader + 'kpi_result_value',
                        width: 100
                      }, {
                        headerName: "RANK",
                        field: kpiHeader + 'rank',
                        width: 100
                      }
                    ]
                  });
                } else {
                  this.columnDefs.push({
                    headerName: kpiHeader,
                    children: [
                      {
                        headerName: "VALUE",
                        field: kpiHeader + 'kpi_result_value',
                        width: 100
                      }
                    ]
                  });
                }

              } else if (kpi_type === "score") {
                scoreAvailable = true;
                this.columnDefs.push({
                  headerName: kpiHeader,
                  children: [
                    {
                      headerName: "SCORE",
                      field: kpiHeader + 'kpi_result_score',
                      width: 100
                    }
                  ]
                });
              } else if (kpi_type === "master") {
                Object.keys(line_json.attribute_json).forEach(key => {
                  let masterKeyExist = false;
                  let masterPosition = -1;
                  for (let i = 0; i < this.columnDefs.length; i++) {
                    if (this.columnDefs[i].headerName === kpiHeader) {
                      masterKeyExist = true;
                      masterPosition = i;
                      break;
                    }
                  }
                  if (!masterKeyExist) {
                    this.columnDefs.push({
                      headerName: kpiHeader,
                      children: [
                        {
                          headerName: key,
                          field: kpiHeader + key,
                          width: 100
                        }
                      ]
                    });
                  } else {
                    let childrenExist = false;
                    if (masterPosition >= 0 && this.columnDefs[masterPosition].hasOwnProperty("children")) {
                      for (let i = 0; i < this.columnDefs[masterPosition].children.length; i++) {
                        if (this.columnDefs[masterPosition].children[i].field === kpiHeader + key) {
                          childrenExist = true;
                          break;
                        }
                      }
                    }

                    if (!childrenExist) {
                      this.columnDefs[masterPosition].children.push({
                        headerName: key,
                        field: kpiHeader + key,
                        width: 100
                      });
                    }
                  }
                });
              }
            }
          }
        }
      });


      if (scoreAvailable) {
        this.columnDefs.push({
          headerName: "TOTAL SCORE",
          children: [
            {
              headerName: "SCORE",
              field: 'total_score',
              width: 100
            }, {
              headerName: "MAX SCORE",
              field: 'max_score',
              width: 100
            },

            {
              headerName: "PROGRESS",
              headerClass: 'myagheader',
              field: "score_ach_percent",
              minWidth: 150,
              aggFunc: this.avgAggFunction,
              valueGetter: params => {
                if (params.data) {
                  if (params.data.score_ach_percent) {
                    return parseFloat(params.data.score_ach_percent.toFixed(2));
                  } else {
                    return 0;
                  }
                }
              },
              cellRenderer: params => {

                let displayElem = '<div class="row">\n' +
                  '            <div class="progresscontainer1">\n' +
                  '              <div class="progressinside1" style=" ';

                if (params.value > 50) {
                  displayElem = displayElem + ' background-color: #379862;';
                } else if (params.value > 20) {
                  displayElem = displayElem + ' background-color: #D97C0E;';
                } else {
                  displayElem = displayElem + ' background-color: #E24260;';
                }

                displayElem = displayElem + 'width:' + params.value + '%;"></div>\n' +
                  '            </div>\n' +
                  '            <div class="progresstext1" style="';

                if (params.value > 50) {
                  displayElem = displayElem + ' color: #379862;';
                } else if (params.value > 20) {
                  displayElem = displayElem + ' color: #D97C0E;';
                } else {
                  displayElem = displayElem + ' color: #E24260;';
                }

                displayElem = displayElem + '">' + params.value + '%</div></div>';


                return displayElem;
              }
            }, {
              headerName: "RANK",
              field: 'score_rank',
              width: 100,
              cellRenderer(params) {
                if (params.data) {
                  let displayElem = '';
                  if (params.data.score_rank) {
                    if (params.data.score_rank === 1) {
                      displayElem = displayElem + ' <img style="width:16px;" src="../../../assets/images/ic_gold_medal.svg"/>';
                    } else if (params.data.score_rank === 2) {
                      displayElem = displayElem + ' <img style="width:16px;" src="../../../assets/images/ic_silver_medal.svg"/>';
                    } else if (params.data.score_rank === 3) {
                      displayElem = displayElem + ' <img style="width:16px;" src="../../../assets/images/ic_bronze_medal.svg"/>';
                    } else {
                      // displayElem = displayElem + ' color: #D97C0E;';
                      displayElem = params.value;
                    }
                  } else {
                    return '';
                  }
                  return displayElem;
                }
              }
            }
          ]
        });
      }
    });
  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }


  configureData() {
    let scoreAvailable = false;
    this.apiData.forEach(data => {
      // let totalScore = 0;
      data.value_json.forEach(line_json => {
        const kpiHeader = line_json.kpi_name;
        if (line_json && line_json.hasOwnProperty("kpi_type")) {
          const kpi_type = line_json.kpi_type;
          if (kpi_type === "result") {
            data[kpiHeader + 'kpi_result_value'] = line_json['kpi_result_value'];
            data[kpiHeader + 'rank'] = line_json['rank'];
          } else if (kpi_type === "score") {
            scoreAvailable = true;
            data[kpiHeader + 'kpi_result_score'] = line_json['kpi_result_score'];
            // totalScore = totalScore + line_json['kpi_result_score'];
          } else if (kpi_type === "master") {
            Object.keys(line_json.attribute_json).forEach(key => {
              data[kpiHeader + key] = line_json.attribute_json[key];
            });
          }
        }
      });
      if (data.total_score && data.max_score && data.total_score > 0 && data.max_score > 0) {
        data.score_ach_percent = (data.total_score * 100) / data.max_score;
      } else {
        data.score_ach_percent = 0;
      }
      // data['total_score'] = totalScore;
    });
    if (scoreAvailable) {
      this.apiData.sort((a, b) => (b['total_score'] - a['total_score']));
      for (let i = 0; i < this.apiData.length; i++) {
        this.apiData[i]['score_rank'] = i + 1;
      }
    }
    this.rowData = this.apiData;
    console.log(this.rowData);
  }
}
