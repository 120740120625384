import {Component, ElementRef, NgZone, OnInit, Renderer2, ViewChild} from '@angular/core';
import {GridOptions} from '@ag-grid-enterprise/all-modules';
import {ApiService} from '../../_services/api.service';
import {SharedService} from '../../_services/shared_service';
import {Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {DateTimeAdapter} from 'ng-pick-datetime';
import {ToastrService} from 'ngx-toastr';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {PaymentPopupComponent} from "../../components/payment_popup/payment_popup.component";
import {AgmMap, LatLngBounds, MapsAPILoader, MouseEvent} from "@agm/core";
import {ErrorHandler} from "../../error/error-handler";
import {ErrorObject} from "../../error/error-model";
import {MatMenuTrigger} from "@angular/material";
import {ChangePromoterPopupComponent} from "../change_promoter_popup/change_pomoter.component";
import {LocalforageService} from "../../_services/localforage.service";
import {OutstandingUploadComponent} from "../../dmsv2/partners/upload/outstanding_upload.component";

declare var $: any;
declare var google: any;

@Component({
  selector: 'app-customers',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {
  @ViewChild('AgmMap') agmMap: AgmMap;
  @ViewChild('search') search: ElementRef;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  user;
  res_data;
  user_data1 = {
    date: null,
    supplier_id: null,
    access_token: null,
    url: null,
    offset: null,
    data: null
  };
  userData = {
    supplier_id: 0,
    start_date: null,
    end_date: null,
    period_id: 0,
    access_token: null,
    url: null,
    offset: 10000,
    offsetID: 0,
    flag: true,
    type: '',
    filterData: {
      date: null,
      access_token: null,
      url: null,
      last_date: null,
      offset: null,
      flag: true,
      out_type: -1,
      salesmanID: 0,
      policyID: 0,
      outlet_type: 0,
      travel_type_id: 0,
      class_type: null,
      program_type: 0,
      status: null,
      max_claim: false,
      trax_outlet: false,
      non_trax_outlet: false,
      exclude_trax_audit: false,
      region_id: 0,
      cluster_id: 0,
      team_id: 0,
      territory_id: 0,
      dc_id: 0,
      displayData: [],
      payment_mode: ''
    },
    data: {
      partner_type: 'retailer',
      start_date: null,
      end_date: null,
      territory_ids: [],
      showAll: true
    }
  };
  public columnDefs = [];
  public searchValue;
  public frameworkComponents;
  public rowData = [];
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public dataLoaded = false;
  public preloader = false;
  public emptyState = false;
  errorObject: ErrorObject;
  errorExists = false;
  public styleGrid;
  public myDate;
  public collapse_disable;
  public isModelOpened = true;
  public newRetailer = {
    code: null,
    name: null,
    mobile: null,
    email: null,
    retailer_type: null,
    class_type: null,
    territory: null,
    beat: null,
    beat_name: null,
    street: null,
    street2: null,
    owner: null,
    target: null,
    gstin: null,
    city: null,
    area: null,
    zone: null,
    state: null,
    country: null,
    zip: null,
    lat: null,
    lng: null
  };
  public typeList;
  public selectedType = [];
  public classType = [];
  public selectedClass = [];
  public selectedTerr = [];
  public selectedBeat = [];
  public beatList = [];
  public terrList = [];
  public confirmEnable = true;
  public team_territories_list = [];
  public API_URL: string;
  public custRefresh = true;
  public datas;
  public paymentMode = '';
  public comments = '';
  public amount = 0;
  public out_amount = 0;
  public writeoff = false;
  public credit_note = false;
  public writeoff_amt = 0;
  public paymentModes = [{id: 1, name: 'cash'}, {id: 2, name: 'cheque'}, {id: 3, name: 'online'}];
  public retail_credits;
  public order_paids;
  public chequeNo;
  public chequeDate;
  public branch;
  public ref;
  public payDate: any;
  public generateCredt = false;
  public createRet = false;
  public changePromoter = false;
  public currentLocation;
  public icon_url = {
    url: '../../assets/retailer.svg',
    scaledSize: {
      width: 40,
      height: 40
    }
  };
  public beatSel = false;
  public mapShow = false;
  public codeEnable = false;
  public searchElementRef: ElementRef;
  public context;
  showableFilters = {
    max_claim: false,
    policy: false,
    salesman: false,
    status: false,
    class: false,
    beat: false,
    trax_outlet: false,
    non_trax_outlet: false,
    exclude_trax_audit: false,
    region: false,
    cluster: false,
    team: false,
    territory: false,
    outlet_type: false,
    payment_mode: false,
    dc: false,
    state: false
  };
  filterSet = false;
  progress = 0;
  progressText = '';
  showProgress = true;
  showProgressBar = true;
  progressMode = 'determinate';
  showProgressCustomText = true;
  progressTotal = 0;
  keyColumn = 'rank';
  apiData: any = [];
  private gridApi;
  private gridColumnApi;
  private roleFeatures: any;
  private latitude: any;
  private longitude: any;
  private agm_map: any;
  private geoCoder;
  retailer_ids = [];
  dms = false;
  showUpload = false;

  constructor(private apiService: ApiService,
              private sharedService: SharedService,
              private router: Router,
              public elRef: ElementRef,
              private renderer: Renderer2,
              private datePipe: DatePipe,
              private toastr: ToastrService,
              public dialog: MatDialog,
              public apiloader: MapsAPILoader,
              private ngZone: NgZone,
              protected _localCacheService: LocalforageService,
              private dateTimeAdapter: DateTimeAdapter<any>) {

    this.userData.access_token = localStorage.getItem('resfreshToken');
    this.user_data1.access_token = localStorage.getItem('resfreshToken');
    this.userData.url = localStorage.getItem('private_url');
    this.userData.supplier_id = JSON.parse(localStorage.getItem('user')).partner_id;
    this.team_territories_list = JSON.parse(localStorage.getItem('team_territories'));

    this._localCacheService.getAccessControlList().then(list => {
      this.generateCredt = list['feature_retailer_generate_credit'];
      this.createRet = list['feature_create_retailer'];
      this.beatSel = list['feature_beat_selection'];
      this.changePromoter = list['feature_change_promoter'];
    });
    if (localStorage.getItem('module_dmsv2') || localStorage.getItem('dms_module')) {
      this.dms = true;
    } else {
      this.dms = false;
    }

    // this.roleFeatures = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function(existed) {
    //   return 'feature_retailer_generate_credit' === existed.name;
    // });
    //
    // if (this.roleFeatures.length > 0) {
    //   this.generateCredt = true;
    // }

    // this.roleFeatures = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function(existed) {
    //   return 'feature_create_retailer' === existed.name;
    // });
    //
    // if (this.roleFeatures.length > 0) {
    //   this.createRet = true;
    // }
    //
    // this.roleFeatures = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function(existed) {
    //   return 'feature_beat_selection' === existed.name;
    // });
    //
    // if (this.roleFeatures.length > 0) {
    //   this.beatSel = true;
    // }

    if (this.team_territories_list != null) {
      for (const item of this.team_territories_list) {
        this.userData.data.territory_ids.push(item.id);
      }
    }

    if (localStorage.getItem('dms_module') === 'true' && localStorage.getItem('feature_show_all_outlets') !== 'true') {
      this.userData.data.showAll = false;
    }

    this.typeList = JSON.parse(localStorage.getItem('all_out_types'));
    this.classType = JSON.parse(localStorage.getItem('all_out_classes'));
    this.beatList = JSON.parse(localStorage.getItem('terr_beats'));
    this.terrList = JSON.parse(localStorage.getItem('user_territories'));

    if (localStorage.getItem('map_key')) {
      this.mapShow = true;
    }
    ;

    localStorage.setItem('user_data', JSON.stringify(this.userData));

    dateTimeAdapter.setLocale('en-IN');
    /*   this.showProgress = true;
       this.showProgressCustomText = true;
       this.loadInBatches(5000, 'id');*/
    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.state = true;
    this.showableFilters.region = true;


    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      // groupDisplayType: 'groupRows',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      rowGroupPanelShow: 'always',
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheader',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      /* onColumnVisible: this.onColumnVisible,
       onDragStopped : this.onDragStopped*/
    } as GridOptions;

    this.configureGrid();
    this.getCustomers();

  }

  configureGrid() {
    this.columnDefs = [
      {headerName: "id", field: "id", hide: true, pinned: 'left'},
      {headerName: 'Name', field: 'name', width: 250, checkboxSelection: true, pinned: 'left', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true},
      {headerName: "Code", field: "code", width: 100, pinned: 'left'},
      {headerName: "Region", field: "region", width: 100},
      {headerName: "Cluster", field: "cluster", width: 120},
      {headerName: "Territory", field: "territory", width: 120},
      {headerName: "Zone", field: "zone", width: 80},
      {headerName: "Area", field: "area", width: 80},
      {headerName: "Mobile", field: "mobile", width: 100},
      {headerName: "Type", field: "type", width: 100},
      {headerName: "Program", field: "program_type", width: 120},
      {headerName: "Verified", field: "is_verified", width: 60},
      {headerName: "State", field: "state", width: 100},
      {
        headerName: "Beat", field: "beat", width: 100,
        valueGetter(params) {
          if (params.data.beat) {
            const beats = [...new Set(params.data.beat.map(item => item.bp))];
            return beats.join();
          } else {
            return params.data.beat;
          }
        }
      },
      {headerName: "PROMOTER CODE", field: "promoter_code", width: 50},
      {headerName: "PROMOTER", field: "promoter", width: 100},
      {headerName: "Created By", field: "created_by", width: 80},
      {headerName: "Create Date", field: "create_date", width: 90},
      {headerName: "Gstin", field: "gst_id", width: 80},
      {headerName: "Outstanding", field: "outstanding", width: 80, cellRenderer: 'outstandingShowRenderer'},
      {headerName: "Credit Limit", field: "credit_limit", width: 80},
      {headerName: "user_id", field: "user_id", hide: true},

    ];

  }

  close() {
    this.isModelOpened = true;
  }

  typeChange(event) {
    this.newRetailer.retailer_type = event[0].id;
  }

  classChange(event) {
    this.newRetailer.class_type = event[0].class_type;
  }

  terrChange(event) {
    this.newRetailer.territory = event[0].id;
  }

  beatChange(event) {
    this.newRetailer.beat = event[0].id;
    this.newRetailer.beat_name = event[0].beatName;
  }

  foo(event) {

    console.log(event);
    const bounds: LatLngBounds = new google.maps.LatLngBounds();
    this.agm_map = event;
    bounds.extend(new google.maps.LatLng(this.newRetailer.lat, this.newRetailer.lng));
    this.agm_map.fitBounds(bounds);
  }

  addMarker(event) {
    debugger;
    let new_addrss = {
      subLocality: null,
      locality: null,
      subAdminArea: null,
      adminArea: null,
      countryName: null,
      postalCode: null,
      addressLine: []
    };
    this.newRetailer.street2 = '';
    this.newRetailer.street = '';
    this.latitude = event.coords.lat;
    this.longitude = event.coords.lng;
    this.newRetailer.lat = this.latitude;
    this.newRetailer.lng = this.longitude;
    this.apiloader.load().then(() => {
      const geocoder = new google.maps.Geocoder;
      let latlng = {
        lat: this.latitude,
        lng: this.longitude
      };
      geocoder.geocode({
        location: latlng
      }, results => {
        results.forEach(loop1 => {
          if (loop1.hasOwnProperty('address_components')) {
            const address = loop1.address_components;
            let Addressline0 = "";
            let Addressline1 = "";
            let Addressline2 = "";
            address.forEach(obj => {
              if (obj.hasOwnProperty('types')) {
                if (obj.types.toString().includes('street_number')) {
                  if (!Addressline0.includes(obj.long_name)) {
                    Addressline0 += obj.long_name + ',';
                  }
                } else if (obj.types.toString().includes('route')) {
                  if (!Addressline0.includes(obj.long_name)) {
                    Addressline0 += obj.long_name + ',';
                  }
                } else if (obj.types.toString().includes('sublocality_level_2')) {
                  if (!Addressline2.includes(obj.long_name)) {
                    Addressline2 += obj.long_name + ',';
                  }
                } else if (obj.types.toString().includes('sublocality_level_3')) {
                  if (!Addressline2.includes(obj.long_name)) {
                    Addressline2 += obj.long_name + ',';
                  }
                } else if (obj.types.toString().includes('sublocality_level_1') && !new_addrss.subLocality) {
                  new_addrss.subLocality = obj.long_name;
                } else if (obj.types.toString().includes('locality') && !new_addrss.locality) {
                  new_addrss.locality = obj.long_name;
                } else if (obj.types.toString().includes('administrative_area_level_2') && !new_addrss.subAdminArea) {
                  new_addrss.subAdminArea = obj.long_name;
                } else if (obj.types.toString().includes('administrative_area_level_1') && !new_addrss.adminArea) {
                  new_addrss.adminArea = obj.long_name;
                } else if (obj.types.toString().includes('country') && !new_addrss.countryName) {
                  new_addrss.countryName = obj.long_name;
                } else if (obj.types.toString().includes('postal_code') && !new_addrss.postalCode) {
                  new_addrss.postalCode = obj.long_name;
                } else if (obj.types.toString().includes('neighborhood')) {
                  if (!new_addrss.subLocality && !new_addrss.locality) {
                    new_addrss.locality = obj.long_name;
                  } else {
                    if (!Addressline1.includes(obj.long_name)) {
                      Addressline1 += obj.long_name;
                    }
                  }
                }
              }
            });
            if (Addressline0 !== '') {
              new_addrss.addressLine[0] = Addressline0;
            }
            if (Addressline1 !== '') {
              new_addrss.addressLine[1] = Addressline1;
            }
            if (Addressline2 !== '') {
              new_addrss.addressLine[2] = Addressline2;
            }
            console.log(new_addrss);

          }
        });
        console.log(new_addrss);
        this.newRetailer.street = new_addrss.addressLine[0] + ',' + new_addrss.addressLine[1];
        this.newRetailer.street2 = new_addrss.addressLine[2];
        this.newRetailer.city = new_addrss.locality;
        this.newRetailer.area = new_addrss.subLocality;
        this.newRetailer.zone = new_addrss.subAdminArea;
        this.newRetailer.state = new_addrss.adminArea;
        this.newRetailer.country = new_addrss.countryName;
        this.newRetailer.zip = new_addrss.postalCode;
      });
    });
  }

  newRetailerCreation() {
    $('#retailermasterModal').modal('show');
    this.newRetailer = {
      code: null,
      name: null,
      mobile: null,
      email: null,
      retailer_type: null,
      class_type: null,
      territory: null,
      beat: null,
      beat_name: null,
      street: null,
      street2: null,
      owner: null,
      target: null,
      gstin: null,
      city: null,
      area: null,
      zone: null,
      state: null,
      country: null,
      zip: null,
      lat: null,
      lng: null
    };
    this.selectedTerr = [this.terrList[0]];
    this.newRetailer.territory = this.selectedTerr[0].id;
    this.selectedType = [];
    this.selectedBeat = [];
    this.selectedClass = [];
    this.codeEnable = false;
    this.confirmEnable = true;
  }

  newRetailerCreation2() {
    $('#retailermasterModal2').modal('show');
    this.newRetailer = {
      code: null,
      name: null,
      mobile: null,
      email: null,
      retailer_type: null,
      class_type: null,
      territory: null,
      beat: null,
      beat_name: null,
      street: null,
      street2: null,
      owner: null,
      target: null,
      gstin: null,
      city: null,
      area: null,
      zone: null,
      state: null,
      country: null,
      zip: null,
      lat: null,
      lng: null
    };
    this.selectedTerr = [this.terrList[0]];
    this.newRetailer.territory = this.selectedTerr[0].id;
    this.selectedType = [];
    this.selectedBeat = [];
    this.selectedClass = [];
    this.codeEnable = false;
    this.confirmEnable = true;
  }

  quickCreate() {
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    if (!this.newRetailer.name) {
      this.toastr.error('Name field is required');
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    } else if (!this.newRetailer.mobile) {
      this.toastr.error('Mobile field is required');
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    } else if (!this.newRetailer.retailer_type) {
      this.toastr.error('Type field is required');
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    } else if (!this.newRetailer.territory) {
      this.toastr.error('Territory field is required');
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    } else if (!this.newRetailer.gstin) {
      this.toastr.error('Tax Code field is required');
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    } else if (this.beatSel && !this.newRetailer.beat) {
      this.toastr.error('Beat field is required');
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    } else {
      $('#retailermasterModal2').modal('hide');
      this.confirmEnable = false;
      this.user_data1.data = this.newRetailer;
      this.apiService.post('/api/res_partners/retailer_quick_create', this.user_data1)
        .subscribe(res => {
          if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
            this.toastr.success('Successfully Created.');
            this.newRetailer = {
              code: null,
              name: null,
              mobile: null,
              email: null,
              retailer_type: null,
              class_type: null,
              territory: null,
              beat: null,
              beat_name: null,
              street: null,
              street2: null,
              owner: null,
              target: null,
              gstin: null,
              city: null,
              area: null,
              zone: null,
              state: null,
              country: null,
              zip: null,
              lat: null,
              lng: null
            };
            this.refreshCust();
          } else {
            this.toastr.error('Failed to create:-' + res['results'].msg);
          }
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
        }, error => {
          this.toastr.error('Failed to create:-');
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
        });
    }
  }

  createRetailer() {
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    if (!this.newRetailer.name) {
      this.toastr.error('Name field is required');
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    } else if (!this.newRetailer.mobile) {
      this.toastr.error('Mobile field is required');
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    } else if (!this.newRetailer.class_type) {
      this.toastr.error('Class field is required');
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    } else if (!this.newRetailer.retailer_type) {
      this.toastr.error('Type field is required');
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    } else if (!this.newRetailer.territory) {
      this.toastr.error('Territory field is required');
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    } else if (this.beatSel && !this.newRetailer.beat) {
      this.toastr.error('Beat field is required');
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    } else if (this.mapShow && !this.newRetailer.state) {
      this.toastr.error('state field is required');
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    } else if (!this.newRetailer.email) {
      this.toastr.error('email field is required');
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    } else if (!this.newRetailer.street) {
      this.toastr.error('address1 field is required');
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    } else if (!this.newRetailer.street2) {
      this.toastr.error('address2 field is required');
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    } else if (!this.newRetailer.owner) {
      this.toastr.error('Owner field is required');
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    } else if (!this.newRetailer.gstin) {
      this.toastr.error('Tax code field is required');
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    } else if (this.mapShow && !this.newRetailer.city) {
      this.toastr.error('City field is required');
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    } else if (this.mapShow && !this.newRetailer.area) {
      this.toastr.error('Area field is required');
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    } else if (this.mapShow && !this.newRetailer.zone) {
      this.toastr.error('Zone field is required');
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    } else if (this.mapShow && !this.newRetailer.zip) {
      this.toastr.error('zip field is required');
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
    } else {
      $('#retailermasterModal').modal('hide');
      this.confirmEnable = false;
      this.user_data1.data = this.newRetailer;
      this.apiService.post('/api/res_partners/dms_retailer_create', this.user_data1)
        .subscribe(res => {
          if (res.hasOwnProperty('results') && (res['results'].status == 200)) {
            this.toastr.success('Successfully Created');
            this.newRetailer = {
              code: null,
              name: null,
              mobile: null,
              email: null,
              retailer_type: null,
              class_type: null,
              territory: null,
              beat: null,
              beat_name: null,
              street: null,
              street2: null,
              owner: null,
              target: null,
              gstin: null,
              city: null,
              area: null,
              zone: null,
              state: null,
              country: null,
              zip: null,
              lat: null,
              lng: null
            };
            this.refreshCust();
          } else {
            this.toastr.error('Failed to create:-' + res['results'].msg);
          }
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
        }, error => {
          this.toastr.error('Failed to create:-');
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
        });
    }
    this.close();
  }

  /*getCustomers() {
    debugger;
    this.dataLoaded = false;
    this.preloader = true;
    this.emptyState = false;
    let beat_dict = {};
    let terr_dict = {};
    let inthis = this;
    const rowdata = [];
    const datePipe = this.datePipe;
    this.API_URL = '/api/res_partners/get_customer_list';
    this.apiService.post(this.API_URL, this.userData)
      .subscribe(res => {
          if (res.hasOwnProperty('results') && (res.results.status == 200) && (res.results.data.length > 0)) {
            this.data = res.results.data[0];
            this.rowData = res.results.data;
            this.dataLoaded = true;
            this.context = {componentParent: this};
            this.frameworkComponents = {
              outstandingShowRenderer: OutstandingShowComponent,
            };
          } else {
            this.preloader = false;
            this.emptyState = true;
          }
        },
        error => {
          console.log(error);
          this.preloader = false;
        });
  }*/

  public handleError(error: ErrorObject) {
    this.preloader = false;
    this.emptyState = false;
    this.dataLoaded = false;
    this.errorExists = true;
    this.errorObject = error;
  }

  getCustomers() {
    this.API_URL = '/api/res_partners/get_customer_list';
    this.dataLoaded = false;
    this.preloader = true;
    this.emptyState = false;
    this.errorExists = false;

    if (this.API_URL === '') {
      return this.handleError(ErrorHandler.getErrorObject('API Not Configured'));
    }

    if (this.showProgress === false) {
      //Load Data in one shot
      this.apiService.post(this.API_URL, this.userData)
        .subscribe(res => {
            console.log(res);
            // t.stop();
            if (res.hasOwnProperty('results') && (res.results.status === 200)) {
              this.apiData = res.results.data; //TODO: check if this line is needed
              this.storeCustomerData(this.apiData);
              if (this.dms) {
                this.fetchCustomerSupplierBalance();
              } else {
                this.postProcessData(null);
              }
            } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
              this.emptyState = true;
              this.handleError(ErrorHandler.getErrorObject(res.results.msg));
            } else {
              this.emptyState = true;
              this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
            }
          },
          // tslint:disable-next-line:no-shadowed-variable
          error => this.handleError(ErrorHandler.getErrorObject(error)));
    } else {
      //Load data in batches
      this.loadInBatches( this.userData.offset, this.keyColumn);
      this.fetch(this.keyColumn);
    }
  }

  fetchCustomerSupplierBalance() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      retailer_ids: this.retailer_ids
    };
    this.apiService.post('/api/res_partners/get_customer_supplier_balance', paramObject)
      .subscribe(res1 => {
        console.log(res1);
        // t.stop();
        if (res1.hasOwnProperty('results') && (res1.results.status === 200)) {
          this.postProcessData(res1.results.data);
        }
      });
  }

  loadInBatches(limit, keyColumn) {
    this.showProgress = true;
    this.showProgressBar = true;
    this.progress = 0;
    this.progressText = '';
    this.progressTotal = 0;
    this.userData.offsetID = 0;
    this.userData.offset = limit;
    this.keyColumn = keyColumn;
    this.apiData = [];
  }

  async fetch(key) {
    const res = await this.apiService.postPromise(this.API_URL, this.userData);
    console.log(res);
    if (res.hasOwnProperty('results') && (res.results.status === 200)) {
      // console.log(res);
      //this.apiData.push(res.results.data);
      for (const item of res.results.data) {
        this.apiData.push(item);
      }
      if (this.apiData[0].hasOwnProperty('total_prog')) {
        this.progressTotal = this.apiData[0].total_prog;
        this.showProgressBar = true;
        this.progressMode = 'determinate';
      } else {
        this.showProgressBar = false;
        this.progressTotal = 0;
      }
      if (res.results.data.length === this.userData.offset) {
        this.userData.offsetID = this.apiData[this.apiData.length - 1][key];
        if (this.progressTotal !== 0) {
          this.progress = Math.round((this.apiData.length / this.progressTotal) * 100);
          this.progressText = this.apiData.length + '/' + this.progressTotal;
        } else {
          this.progressText = 'Downloaded ' + this.apiData.length + ' records';
        }

        await this.fetch(key);
      } else {
        this.storeCustomerData(this.apiData);
        if (this.dms) {
          this.fetchCustomerSupplierBalance();
        } else {
          this.postProcessData(null);
        }
      }
    } else if (res.results.status === 203) {
      this.handleError(ErrorHandler.getErrorObject(res.results.msg));
    } else {
      this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
    }
  }

  postProcessData(supplierBalanceData) {
    if (supplierBalanceData) {
      this.apiData.forEach(row => {
        const supplierBalanceRow = supplierBalanceData.find(x => x.partner_id === row.id);
        if (supplierBalanceRow) {
          row.outstanding = supplierBalanceRow.balance;
          row.credit_limit = supplierBalanceRow.credit;
        }
      });
    }
    this.rowData = this.apiData;
    //this.rowData should be ready now
    this.preloader = false;
    if (this.rowData === null || this.rowData === undefined) {
      this.emptyState = true;
    } else if (this.rowData.length === 0) {
      this.emptyState = true;
    } else {
      this.dataLoaded = true;
    }
    return;
  }

  storeCustomerData(data) {
    const ids = [];
    for (const key in data) {
      ids.push(data[key].id);
    }
    this.retailer_ids = ids;
    localStorage.setItem('customerdata', JSON.stringify(ids));
  }

  refreshCust() {
    this.dataLoaded = false;
    this.preloader = true;
    this.emptyState = false;
    this.getCustomers();
  }

  generateCredit() {
    debugger;
    const datas = {
      write_off: false,
      paid_amount: 0,
      invoices: [],
      payment_date: '',
      payment_type: '',
      comments: '',
      retailer: null,
      partner_id: null,
      user_id: null,
      credits: 0,
      code: null,
      ref_id: null,
      cheque_no: null,
      cheque_date: null,
      branch_name: null,
      credit_used: false,
      cr_used_credits: 0,
      credit_amt: 0,
      invoice_type: 'out_invoice'
    };
    let customer = this.gridApi.getSelectedRows();
    if (customer.length > 0) {
      datas.retailer = customer[0].name;
      datas.partner_id = customer[0].id;
      datas.user_id = customer[0].user_id;
      datas.code = customer[0].code;
      datas.credits = customer[0].credits;
      datas.cr_used_credits = datas.credits;
    }
    this.datas = datas;
    let paymentData: any = {};
    paymentData.amount = this.amount;
    paymentData.out_amount = customer[0].outstanding;
    paymentData.writeoff = this.writeoff;
    paymentData.writeoff_amt = this.writeoff_amt;
    paymentData.payDate = this.payDate;
    paymentData.chequeDate = this.chequeDate;
    paymentData.chequeNo = this.chequeNo;
    paymentData.branch = this.branch;
    paymentData.ref = this.ref;
    paymentData.title = 'GENERATE CREDIT NOTE';
    paymentData.credit_creation = true;

    let config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "600px",
      maxHeight: "600px",
      data: {
        datas: this.datas,
        paymentData
      }
    };
    const creatediaeref = this.dialog.open(PaymentPopupComponent, config);
    creatediaeref.componentInstance.markPayment.subscribe(data => {
      this.createCreditNote(data);
    });
  }

  toLedger(params) {
    debugger;
    this.sharedService.dmsParams.ret_id = params.id;
    this.router.navigate([this.apiService.redirect_url + '/reports/customer_ledger']);
  }

  markerDragEnd($event: MouseEvent) {
    console.log($event);
    this.newRetailer.lat = $event.coords.lat;
    this.newRetailer.lng = $event.coords.lng;
    this.getAddress(this.newRetailer.lat, this.newRetailer.lng);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({'location': {lat: latitude, lng: longitude}}, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.addMarker({coords: {lat: latitude, lng: longitude}});
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  createCreditNote(params) {
    this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
    this.datas.payment_date = params.payDate;
    this.datas.payment_type = params.paymentMode;
    this.datas.paid_amount = parseFloat(String(params.amount)).toFixed(2);
    this.datas.comments = params.comments;
    this.datas.write_off = params.writeoff;
    this.datas.cheque_date = params.chequeDate;
    this.datas.cheque_no = params.chequeNo;
    this.datas.branch = params.branch;
    this.datas.ref = params.ref;
    this.datas.credit_amt = params.amount;
    let data = {
      datas: this.datas,
      access_token: localStorage.getItem('resfreshToken')
    };
    this.API_URL = '/api/pwa_connects/generate_credit_note';
    this.apiService.post(this.API_URL, data)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          debugger;
          this.close();
          this.toastr.success('Payment Successfull');
          this.getCustomers();
        } else {
          this.toastr.error('Payment Failed' + res.results.msg);
        }
        this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
      });
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData);
    this.preloader = false;
    // this.gridApi.setDomLayout("autoHeight");
    window.addEventListener('resize', function() {
      setTimeout(function() {
        if (screen.width > 991) {
          // params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });
    if (screen.width > 991) {
      // this.gridOptions.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  ngOnInit() {
    this.sharedService.setHeader('Retailers');
    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable == 'true' ? 'addClass' : 'removeClass']
    (this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
    this.apiloader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      const autocomplete = new google.maps.places.Autocomplete(this.search.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.newRetailer.lat = place.geometry.location.lat();
          this.newRetailer.lng = place.geometry.location.lng();
          this.addMarker({coords: {lat: this.newRetailer.lat, lng: this.newRetailer.lng}});
        });
      });
    });
  }

  quickSearch() {
    this.gridApi.setQuickFilter(document.getElementById('filter-text-box')['value']);
    console.log(this.gridApi);
  }


  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    const filteredRowData = this.gridApi.rowModel.rowsToDisplay;
    const filteredRows = [];
    for (const key in filteredRowData) {
      /* if (!filteredRowData[key].hasOwnProperty('data')) {
         filteredRows.push(filteredRowData[key]);
       }*/
      filteredRows.push(filteredRowData[key].data);
    }
    if (filteredRows !== null && filteredRows.length > 0) {
      this.storeCustomerData(filteredRows);
    }
    localStorage.setItem('customerid', rows[0].id);
    const index = JSON.parse(localStorage.getItem("customerdata")).indexOf(rows[0].id);
    localStorage.setItem('customerindex', index);
    this.router.navigate([this.apiService.redirect_url + '/customers/retailers/details'], {
      state: {
        viewtype: 'detail',
        customerid: rows[0].id
      }
    });
  }

  setFilter(filterdata) {
    this.userData.filterData.displayData = [...this.userData.filterData.displayData];
    this.userData.filterData = filterdata;
    this.trigger.closeMenu();
    this.getCustomers();
  }

  removeFilter(displayData: any) {
    this.sharedService.filterCancelCLick(displayData.key);
  }

  onBtExport() {
    const header = this.sharedService.headerTitleSource.getValue();
    const params = {
      fontSize: undefined,
      rowHeight: undefined,
      headerRowHeight: undefined,
      fileName: header + '.csv'
    };
    this.gridApi.exportDataAsExcel(params);
    /* this.sharedService.getHeader().subscribe(value => {

     });*/
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.newRetailer.lat = position.coords.latitude;
        this.newRetailer.lng = position.coords.longitude;
      });
    }
  }

  changePromoterClick() {
    const rows = this.gridApi.getSelectedRows();
    if (rows === null || rows === undefined || rows.length === 0) {
      this.toastr.error('Please select at least 1 Beat');
    } else if (rows.length > 100) {
      this.toastr.error('Please select a maximum of 100 retailers : ' + rows.length + ' selected');
    } else {

      const r_ids = [...new Set(rows.map(obj => obj.id))];

      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "450px",
        maxHeight: "350px",
        data: {
          retailer_ids: r_ids,
        },
        panelClass: 'custom-dialog-container'
      };

      const confirmdiaeref = this.dialog.open(ChangePromoterPopupComponent, config);
      confirmdiaeref.componentInstance.changeData.subscribe(status => {
        if (status === 'success') {
          this.dialog.closeAll();
          window.location.reload();
        }
      });
    }
  }

  showUploadPopup() {

  }


}
