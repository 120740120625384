import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-eodcard',
  templateUrl: './eodcard.component.html',
  styleUrls: ['./eodcard.component.scss']
})
export class EodcardComponent implements OnInit {
  @Input() dc: string;
  @Input() zs: string;
  @Input() title1: string;
  @Input() title2: string;

  constructor() { }

  ngOnInit() {
  }

}

