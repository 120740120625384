<!--<angular2-multiselect [data]="itemList"-->
<!--                      [(ngModel)]="selectedItems"-->
<!--                      [settings]="settings"-->
<!--                      (onSelect)="onItemSelect($event)"-->
<!--  (onDeSelect)="OnItemDeSelect($event)">-->
<!--  (onSelectAll)="onSelectAll($event)"-->
<!--  (onDeSelectAll)="onDeSelectAll($event)">-->
<!--</angular2-multiselect>-->
<!--<multi-selector-dropdown-->
<!--  [list]="list"-->
<!--  (shareCheckedList)="onItemSelect($event)"-->
<!--  (shareIndividualCheckedList)="onItemSelect($event)">-->
<!--</multi-selector-dropdown>-->
<ng-select [items]="itemList"
           bindLabel="itemName"
           appendTo="body"
           multiple="true"
           maxSelectedItems="1"
           [(ngModel)]="selectedItems"
           (ngModelChange)="dcChange($event)">
</ng-select>
