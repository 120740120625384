import {StatisticGadgetComponent} from './gadgets/statistic/statistic-gadget.component';
import {BarGraphGadgetComponent} from './gadgets/bar_graph/bar-graph-gadget.component';
import {PieGraphGadgetComponent} from './gadgets/pie_graph/pie-graph-gadget.component';
import {TreeGraphGadgetComponent} from './gadgets/tree_graph/tree-graph-gadget.component';
import {HeatGraphGadgetComponent} from './gadgets/heat_graph/heat-graph-gadget.component';
import {GuageGraphGadgetComponent} from './gadgets/gauge_graph/gauge-graph-gadget.component';
import {BarlineGraphGadgetComponent} from './gadgets/barline_graph/barline-graph-gadget.component';
import {StatisticArrayGadgetComponent} from "./gadgets/statistic-array/statistic-array-gadget.component";


export class GadgetFactory {


    /**
     * todo - return new instances  instead of the same instance. This requires the creation of new configuration options.
     * @param gadgetType
     * @returns {any}
     */

    static getComponentType(gadgetType): any {

        switch (gadgetType) {

            case 'StatisticArrayGadgetComponent':
              return StatisticArrayGadgetComponent;
            case 'StatisticGadgetComponent':
                return StatisticGadgetComponent;
            case 'BarGraphGadgetComponent':
                return BarGraphGadgetComponent;
            case 'PieGraphGadgetComponent':
              return PieGraphGadgetComponent;
            case 'TreeGraphGadgetComponent':
              return TreeGraphGadgetComponent;
            case 'HeatGraphGadgetComponent':
              return HeatGraphGadgetComponent;
            case 'GuageGraphGadgetComponent':
              return GuageGraphGadgetComponent;
          case 'BarlineGraphGadgetComponent':
            return BarlineGraphGadgetComponent;
          default:
              return null; // TODO: add default gadget that would be displayed. Useful for troubleshooting new gadget dev
        }
    }
}
