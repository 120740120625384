import {Component, ElementRef, Inject, OnInit, Renderer2} from "@angular/core";
import {GridOptions} from "@ag-grid-enterprise/all-modules";

import {Router} from "@angular/router";
import {DatePipe, DOCUMENT} from "@angular/common";

import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {ApiService} from "../../../_services/api.service";
import {SharedService} from "../../../_services/shared_service";
import {SurveyQuestionCreateComponent} from "../../../survey/survey_question_create/survey_question_create.component";
import {SurveyTerritoryCreateComponent} from "../../../survey/survey_territory_create/survey_territory_create.component";


@Component({
  selector: 'app-task_survey_detail-component',
  templateUrl: './task_survey_detail.component.html',
  styleUrls: ['./task_survey_detail.component.scss']
})
export class TaskSurveyDetailComponent implements OnInit {

  private gridApi;
  private gridApi1;
  private gridApi2;
  private gridColumnApi;
  private gridColumnApi1;
  private gridColumnApi2;
  public columnDefs = [];
  public columnDefs1 = [];
  public columnDefs2 = [];
  public rowData = [];
  public rowData1 = [];
  public rowData2 = [];
  public gridOptions: GridOptions;
  public gridOptions1: GridOptions;
  public gridOptions2: GridOptions;
  public loaded = false;
  public loaded1 = false;
  public loaded2 = false;
  public preloader = false;
  public preloader1 = false;
  public preloader2 = false;
  public empty_state = false;
  public empty_state1 = false;
  public empty_state2 = false;
  public defaultColDef;
  public styleGrid;
  public frameworkComponents;
  public context;
  public edit = false;
  API_URL = '';
  user_data = {
    date: null,
    access_token: null,
    url: null,
    data: {
      id: null
    }
  };
  viewtype = 'edit';
  userSurveyDetails: any = {};
  activetab = 'QUESTIONS';
  //score = 35;
  selectedProducts = [];

  constructor(private apiService: ApiService,
              private router: Router,
              private datePipe: DatePipe,
              private renderer: Renderer2,
              private sharedService: SharedService,
              private elRef: ElementRef,
              public dialog: MatDialog,
              private toastr: ToastrService,
              @Inject(DOCUMENT) private _document: Document) {

    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.data.id = localStorage.getItem("taskSurveyID");

    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left'},
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      rowSelection: 'multiple'
    } as GridOptions;

    this.gridOptions1 = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left'},
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      rowSelection: 'multiple'
    } as GridOptions;

    this.gridOptions2 = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left'},
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      rowSelection: 'multiple'
    } as GridOptions;

    /*  this.userSurveyDetails.code = "POP01";
      this.userSurveyDetails.name = "KA FD POP";
      this.userSurveyDetails.description = "popo outlet survey";
      this.userSurveyDetails.start_date = "12-06-2021";
      this.userSurveyDetails.end_date = "12-06-2021";
      this.userSurveyDetails.score = 35;
      this.userSurveyDetails.completed = 300;*/


    var indrow = {
      "sequence": "1",
      "question": "What is the name of the retailer?",
      "type": "Text",
      "answer": "Shobha Supermarket"

    };

    var indrow1 = {
      "code": "89374",
      "name": "Abhis territory",
      "beat_count": "4",
      "zone": "Default Zone",
      "state": "Karnataka"
    };

    var indrow2 = {
      "type": "outlet",
      "expression": "=",
      "value": "supermarket"
    };

    this.columnDefs = [
      {headerName: "SEQUENCE", headerClass: 'myagheader', field: "sequence", width: 80, sortable: true, filter: true, lockPosition: true, menuTabs: [], resizable: true,},
      {headerName: "CODE", headerClass: 'myagheader', field: "code", width: 80, sortable: true, filter: true, lockPosition: true, menuTabs: [], resizable: true,},
      {headerName: "QUESTIONS", headerClass: 'myagheader', field: "name", width: 400, sortable: true, filter: true, lockPosition: true, menuTabs: [], resizable: true},
      {headerName: "QUESTION TYPE", headerClass: 'myagheader', field: "type", sortable: true, filter: true, lockPosition: true, menuTabs: [], resizable: true},
      {
        headerName: "ANSWERS", headerClass: 'myagheader', field: "ans", width: 200, sortable: true, filter: true, lockPosition: true, menuTabs: [], resizable: true,
        valueGetter: function(params) {
          return params.data.ans.length;
        }
      },
      {
        headerName: "", headerClass: 'myagheader', field: "actions", cellStyle: {textAlign: 'right'}, sortable: true, filter: true, lockPosition: true, menuTabs: [], width: 50, resizable: true,
        cellRenderer: function(params) {
          if (params.value == 'bottom') {
            return "";
          } else {
            return ' <span title="Delete" ><i class="fas fa-trash delete" data-action-type="delete"></i></span>';
          }
        }
      },
    ];

    this.columnDefs1 = [
      {headerName: "CODE", headerClass: 'myagheader', field: "code", sortable: true, filter: true, lockPosition: true, menuTabs: [], resizable: true},
      {headerName: "NAME", headerClass: 'myagheader', field: "name", sortable: true, filter: true, lockPosition: true, menuTabs: [], resizable: true,},
      {headerName: "BEAT COUNT", headerClass: 'myagheader', field: "beat_count", sortable: true, filter: true, lockPosition: true, menuTabs: [], resizable: true},
      {headerName: "ZONE", headerClass: 'myagheader', field: "zone", sortable: true, filter: true, lockPosition: true, menuTabs: [], resizable: true},
      {headerName: "STATE", headerClass: 'myagheader', field: "state", sortable: true, filter: true, lockPosition: true, menuTabs: [], resizable: true},
    ];

    this.columnDefs2 = [
      {headerName: "TYPE", headerClass: 'myagheader', field: "type", sortable: true, filter: true, lockPosition: true, menuTabs: [], resizable: true},
      {headerName: "CONDITION", headerClass: 'myagheader', field: "expression", sortable: true, filter: true, lockPosition: true, menuTabs: [], resizable: true,},
      /*{headerName: "OUTLET TYPE", headerClass: 'myagheader', field: "outlet_type", sortable: true, filter: true, lockPosition: true,menuTabs: [], resizable: true},
      {headerName: "PROGRAM TYPE", headerClass: 'myagheader', field: "program_type", sortable: true, filter: true, lockPosition: true,menuTabs: [], resizable: true},*/
      {
        headerName: "VALUE", headerClass: 'myagheader', field: "value", sortable: true, filter: true, lockPosition: true, menuTabs: [], resizable: true,
        valueGetter: function(params) {
          if (params.data.outlet_type != null && params.data.outlet_type != "") {
            return params.data.outlet_type;
          } else if (params.data.program_type != null && params.data.program_type != "") {
            return params.data.program_type;
          } else {
            return params.data.value;
          }
        }
      },
    ];

    /*   this.rowData.push(indrow);
      this.rowData1.push(indrow1);
      this.rowData2.push(indrow2);
     /!*  this.rowData.push(indrow1);
       this.rowData.push(indrow2);*!/



      this.loaded = false;
      this.preloader = true;
      this.empty_state = false;

      this.loaded = true;
      this.loaded1 = true;
      this.loaded2 = true;*/

    this.fetchAssessmentDetails();

  }


  ngOnInit(): void {
  }

  fetchAssessmentDetails() {

    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;

    this.loaded1 = false;
    this.preloader1 = true;
    this.empty_state1 = false;

    this.loaded2 = false;
    this.preloader2 = true;
    this.empty_state2 = false;

    this.API_URL = '/api/crm_profiling_questionnaires/get_retail_survey_details';

    this.apiService.post(this.API_URL, this.user_data)
      .subscribe(res => {

          if (res.hasOwnProperty('results') && (res.results.status == 200) && (res.results.hasOwnProperty('data'))) {

            this.userSurveyDetails = res.results.data;
            this.rowData = this.userSurveyDetails.questions;
            this.rowData1 = this.userSurveyDetails.territories;
            this.rowData2 = this.userSurveyDetails.criterias;

            this.loaded = true;
            this.loaded1 = true;
            this.loaded2 = true;

          } else {
            this.preloader = false;
            this.empty_state = true;

            this.preloader1 = false;
            this.empty_state1 = true;

            this.preloader2 = false;
            this.empty_state2 = true;
          }
        },
        error => {
          console.log(error);
          this.preloader = false;
          this.preloader1 = false;
          this.preloader2 = false;
        });

  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData);
    //this.gridApi.setDomLayout('autoHeight');
    this.preloader = false;

    const sortModel = [
      {colId: 'Date', sort: 'desc'}
    ];

    // this.gridApi.setSortModel(sortModel);

    window.addEventListener('resize', function() {
      setTimeout(function() {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });

    if (screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }

    // this.gridColumnApi.autoSizeColumns();


    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  onGridReady1(params) {
    this.gridOptions1.api.showLoadingOverlay();

    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;

    params.api.setRowData(this.rowData1);
    //this.gridApi1.setDomLayout('autoHeight');

    this.preloader1 = false;

    window.addEventListener('resize', function() {
      setTimeout(function() {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });

    if (screen.width > 991) {
      this.gridApi1.sizeColumnsToFit();
    } else {
      this.gridColumnApi1.autoSizeColumns();
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid2');
  }

  onGridReady2(params) {
    this.gridOptions2.api.showLoadingOverlay();

    this.gridApi2 = params.api;
    this.gridColumnApi2 = params.columnApi;

    params.api.setRowData(this.rowData2);
    //this.gridApi2.setDomLayout('autoHeight');

    this.preloader2 = false;

    window.addEventListener('resize', function() {
      setTimeout(function() {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });

    if (screen.width > 991) {
      this.gridApi2.sizeColumnsToFit();
    } else {
      this.gridColumnApi2.autoSizeColumns();
    }

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid3');
  }


  quickSearch() {
    this.gridApi.setQuickFilter(document.getElementById('filter-text-box')['value']);
  }


  onCellClicked(event) {
    const column = event.column.getColId();
    var selectedQuestion = this.gridApi.getSelectedRows();
    if (selectedQuestion != null) {
      selectedQuestion = selectedQuestion[0];
    }

    let config: MatDialogConfig = {
      width: "100vw",
      height: "auto",
      maxWidth: "1000px",
      maxHeight: "100%",
      panelClass: 'custom-dialog-container',
      data: {
        edit: false,
        questionData: selectedQuestion
      }
    };

    const creatediaeref = this.dialog.open(SurveyQuestionCreateComponent, config);
  }

  onCellClicked1(event) {
    const column = event.column.getColId();
  }

  onCellClicked2(event) {
    const column = event.column.getColId();
  }

  changeActiveTab(event: MatTabChangeEvent) {
    const tab = event.tab.textLabel;
    this.activetab = tab;
  }

  setStartDate(dt) {
    this.userSurveyDetails.start_date = this.datePipe.transform(this.userSurveyDetails.start_date, 'yyyy-MM-dd');
  }

  setEndDate(dt) {
    this.userSurveyDetails.end_date = this.datePipe.transform(this.userSurveyDetails.end_date, 'yyyy-MM-dd');
  }

  updateMarketIntelligence() {
    let paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = this.userSurveyDetails;
    this.API_URL = '/api/crm_profiling_questionnaires/retail_survey_update';
    this.apiService.post(this.API_URL, paramObject)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status == 200)) {
          this.toastr.success('Survey updated Successfully');
          this._document.defaultView.location.reload();
        } else {
          this.toastr.error('Failed to update Survey');
        }
      });
  }

  editClick() {
    this.edit = !this.edit;
  }

  discardClick() {
    this.edit = !this.edit;
    this._document.defaultView.location.reload();
    // this.fetchRetailAuditDetails();
  }

  createDialog() {
    if (this.activetab == "QUESTIONS") {
      let config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "1000px",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          edit: true,
          questionData: null
        }
      };

      const creatediaeref = this.dialog.open(SurveyQuestionCreateComponent, config);

      creatediaeref.componentInstance.createdQuestion.subscribe(data => {

        if (data != []) {
          this.userSurveyDetails.questions.push(data);
          if (this.rowData == null || this.rowData.length == 0) {
            this.rowData = this.userSurveyDetails.questions;
            this.loaded = true;
          }

          this.gridApi.setRowData(this.rowData);
        }

      });
    } else if (this.activetab == "TERRITORY") {
      let config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "1000px",
        maxHeight: "600px",
      };

      const creatediaeref = this.dialog.open(SurveyTerritoryCreateComponent, config);

      creatediaeref.componentInstance.selectedTerritories.subscribe(data => {

        if (data != []) {
          var indterritory: any = {};
          if (!this.userSurveyDetails.hasOwnProperty("territories")) {
            this.userSurveyDetails.territories = [];
          }
          for (var i = 0; i < data.length; i++) {
            indterritory = {};
            indterritory.territory_id = data[i].id;
            indterritory.name = data[i].itemname;
            indterritory.code = data[i].code;
            indterritory.zone = data[i].zone;
            indterritory.zone_id = data[i].zone_id;
            indterritory.state = data[i].state;
            indterritory.state_id = data[i].state_id;
            indterritory.beat_count = data[i].beat_count;

            this.userSurveyDetails.territories.push(indterritory);

          }

          if (this.rowData1 == null || this.rowData1.length == 0) {
            this.rowData1 = this.userSurveyDetails.territories;
            this.loaded1 = true;
          }

          this.gridApi1.setRowData(this.rowData1);

        }

      });
    }

  }

  viewReport() {
    this.router.navigate([this.apiService.redirect_url + '/reports/task_survey_report'], {
      state: {

        taskSurveyID: this.userSurveyDetails.id
      }
    });
  }


}
