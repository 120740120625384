import {Component, ElementRef, Inject, OnInit, Renderer2, ViewChild} from "@angular/core";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {ApiService} from "../../_services/api.service";
import {Router} from "@angular/router";
import {DatePipe, DOCUMENT} from "@angular/common";
import {SharedService} from "../../_services/shared_service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {ConfirmDialogComponent} from "../../components/confirmdialog/confirmdialog.component";
import {DomSanitizer} from "@angular/platform-browser";
import {ImagePopupComponent} from "../../components/image_popup/image_popup.component";

@Component({
  selector: 'app-distributor-audit-detail-component',
  templateUrl: './distributor_audit_detail.component.html',
  styleUrls: ['./distributor_audit_detail.component.scss']
})
export class DistributorAuditDetailComponent implements OnInit {

  @ViewChild('imageClick') imageClick: ElementRef<HTMLElement>;
  public columnDefs = [];
  public columnDefs1 = [];
  public rowData = [];
  public rowData1 = [];
  public gridOptions: GridOptions;
  public gridOptions1: GridOptions;
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public loaded1 = false;
  public preloader1 = false;
  public empty_state1 = false;
  public defaultColDef;
  public styleGrid;
  public frameworkComponents;
  public context;
  public pinnedTotalData = [
    {
      code: 'Add an Item',
      image: 'bottom',
      actions: 'bottom'
    }
  ];
  public DADetails: any = {};
  public edit = false;
  API_URL = '';
  user_data = {
    date: null,
    access_token: null,
    url: null,
    data: {
      start_date: null,
      end_date: null,
      id: null
    }
  };
  viewtype = 'edit';
  selectedOutlets = [];
  public photo;
  res_data;
  task_types = [];
  selected_task_type: any = {};
  allow_start_date: boolean = true;
  private gridApi;
  private gridColumnApi;

  constructor(private apiService: ApiService,
              private router: Router,
              private datePipe: DatePipe,
              private renderer: Renderer2,
              private sharedService: SharedService,
              private elRef: ElementRef,
              public dialog: MatDialog,
              public domSanitizer: DomSanitizer,
              private toastr: ToastrService,
              @Inject(DOCUMENT) private _document: Document) {

    this.user_data.access_token = localStorage.getItem('resfreshToken');

    this.task_types = JSON.parse(localStorage.getItem('task_types'));
    console.log(this.task_types);

    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      // groupDisplayType: 'groupRows',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      rowGroupPanelShow: 'always',
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheader',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agTotalRowCountComponent', align: 'center'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      /* onColumnVisible: this.onColumnVisible,
       onDragStopped : this.onDragStopped*/
    } as GridOptions;

    this.gridOptions1 = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple'
    } as GridOptions;

    let datas: any = {};
    datas = history.state;

    // console.log(datas);
    this.user_data.data.id = localStorage.getItem("DAid");
    this.viewtype = datas.viewtype;
    if (this.viewtype === 'create') {
      this.edit = true;
    }

    /*  this.taskDetails.name = "Johnie Walker Gift Display Promo";
      this.taskDetails.description = "Gift Display Promo";
      this.taskDetails.type = "Activation";
      this.taskDetails.start_date = "12/06/2021";
      this.taskDetails.end_date = "19/06/2021";*/

    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;

    this.columnDefs = [
      {headerName: "PRODUCT", field: "product", menuTabs: [], width: 200},
      {headerName: "CODE", field: "default_code", menuTabs: [], width: 90},
      {headerName: "STOCK DATE", field: "stock_date", menuTabs: [], width: 60},
      {headerName: "PRIMARY", field: "primary", menuTabs: [], width: 60},
      {headerName: "SECONDARY", field: "secondary", menuTabs: [], width: 60},
      {headerName: "SYSTEM", field: "system", menuTabs: [], width: 60},
      {headerName: "AUDIT", field: "audit", menuTabs: [], width: 60},
      {
        headerName: "DEVIATION", field: "deviation", menuTabs: [], width: 60,
        cellStyle(params) {
          if (params.value != null && params.value !== undefined) {
            if (params.value < 0) {
              return {'background-color': '#F8D1C8'};
            } else {
              return '';
            }
          }
        }
      },
      {
        headerName: "DEVIATION %", field: "deviation", menuTabs: [], width: 60,
        valueGetter: params => {
          if (params.data) {
            if (params.data.audit === 0 && params.data.system > 0) {
              return 100;
            } else if (params.data.system === 0) {
              return params.data.audit * 100;
            } else {
              return Math.round(((params.data.audit - params.data.system) / params.data.system) * 100);
            }

          }
        },
        cellStyle(params) {
          if (params.value != null && params.value !== undefined) {
            if (params.value < 0) {
              return {'background-color': '#F8D1C8'};
            } else {
              return '';
            }
          }
        }
      },
      {headerName: "APPROVED", field: "approved", menuTabs: [], width: 60},
      {
        headerName: "APPROVED DEVIATION", field: "app_deviation", menuTabs: [], width: 60,
        valueGetter: params => {
          if (params.data) {
            return params.data.approved - params.data.audit;
          }
        }
      },
      {
        headerName: "APPROVED DEVIATION %", field: "app_deviation", menuTabs: [], width: 60,
        valueGetter: params => {
          if (params.data) {
            if (params.data.audit === 0 && params.data.approved > 0) {
              return 100;
            } else if (params.data.approved === 0) {
              return params.data.approved * 100;
            } else {
              return Math.round(((params.data.audit - params.data.approved) / params.data.approved) * 100);
            }

          }
        },
        cellStyle(params) {
          if (params.value != null && params.value !== undefined) {
            if (params.value < 0) {
              return {'background-color': '#F8D1C8'};
            } else {
              return '';
            }
          }
        }
      },
      /*{headerName: "REJECTED", field: "rejected", menuTabs: [], width: 60},*/
    ];
    //this.loaded = true;
    this.fetchDADetails();
  }

  ngOnInit(): void {
    this.sharedService.setHeader("Distributor Audit Detail");
  }

  fetchDADetails() {
    this.API_URL = '/api/audits/getDistributorAuditDetail';
    this.apiService.post(this.API_URL, this.user_data)
      .subscribe(res => {
          console.log(res);
          this.loaded = true;
          if (res.hasOwnProperty('results') && (res.results.status <= 200)) {

            if (res.results.status < 200 && res.results.msg !== null) {
              this.toastr.warning(res.results.msg);
            }

            this.DADetails = res.results.data;
            this.DADetails.progress = Math.trunc((parseInt(this.DADetails.completed_count) / parseInt(this.DADetails.line_count)) * 100);
            // this.taskDetails.status = 'published';
            // this.taskDetails.active = true;
            this.checkAllowStartDate();
            this.rowData = this.DADetails.stock;
            console.log(this.DADetails);
            console.log(this.rowData);
            if (this.gridApi !== null && typeof this.gridApi !== 'undefined') {
              this.gridApi.setRowData(this.rowData);
            }
            //this.loaded = true;


          } else {
            this.preloader = false;
            //this.empty_state = true;
          }
        },
        error => {
          console.log(error);
          this.preloader = false;
        });
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // this.gridApi.setDomLayout("normal");
    params.api.setRowData(this.rowData);
    //this.gridApi.setDomLayout('autoHeight');
    //console.log(this.rowData);
    this.preloader = false;
    // this.gridApi.setDomLayout("autoHeight");

    const sortModel = [
      {colId: 'Date', sort: 'desc'}
    ];

    //this.gridApi.setSortModel(sortModel);
    //console.log(this.rowData);

    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (screen.width > 991) {
          params.api.sizeColumnsToFit();
        } else {
          params.columnApi.autoSizeColumns();
        }
      });
    });

    if (screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }

    // this.gridColumnApi.autoSizeColumns();


    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    /* this.res_data = this.apiService.fetchImage({
       "access_token": this.user_data.access_token,
       "url": this.user_data.url,
       "data": [{"store_fname": "ba0/ba0873d3e8ace4b26d83520f6735b3d2240ef18c3aa5e49386cb2803f024a341", "container": "expense_gallery"}]
     });
     this.res_data.subscribe(res => {
         console.log(res);
         this.photo = this.domSanitizer.bypassSecurityTrustUrl("data:image/png;base64, "+res['result'][0].bill_datas);
         let el: HTMLElement = this.imageClick.nativeElement;
         el.click();
       },
       error => console.log(error)
     );*/
    const rows = this.gridApi.getSelectedRows();
    if (column === 'delete') {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "550px",
        maxHeight: "250px",
        data: {
          title: "Delete Task Line !!",
          message1: rows[0].retailer_code + " - " + rows[0].retailer,
          message2: "",
          showNoButton: true,
          yesButton: "DELETE",
          noButton: "CANCEL",
          deleteButton: true
        }
      };

      const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);

      confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

        console.log(data);
        if (data === 'yes') {
          rows[0].active = false;
        } else {

        }
      });
    } else {
      if (rows !== null && rows.length > 0 && rows[0].hasOwnProperty('task_store_fname') && rows[0].task_store_fname !== null && rows[0].task_store_fname !== "") {
        if (screen.width > 991) {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "70%",
            maxHeight: "95%",
            data: {
              store_fname: rows[0].task_store_fname,
              container: "task_line"
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        } else {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "90%",
            maxHeight: "65%",
            data: {
              store_fname: rows[0].task_store_fname,
              container: "task_line"
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        }

      } else {

      }

    }

  }

  editClick() {
    this.edit = !this.edit;
    this.gridApi.setRowData(this.rowData);
  }

  discardClick() {
    this.edit = !this.edit;
    this.gridApi.setRowData(this.rowData);
    this.fetchDADetails();
  }

  cancelClick() {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "550px",
      maxHeight: "250px",
      data: {
        title: "Delete Task!!",
        message1: "",
        message2: "Do you wish to Delete this Task?",
        showNoButton: true,
        yesButton: "DELETE",
        noButton: "CANCEL",
        deleteButton: true
      }
    };

    const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);

    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

      console.log(data);
      if (data === 'yes') {
        this.DADetails.active = false;
      } else {

      }
    });
  }

  quickSearch() {
    this.gridApi.setQuickFilter(document.getElementById('filter-text-box')['value']);
  }

  setStartDate(dt) {
    //console.log(this.newExpenseData.expense_date)
    this.DADetails.start_date = this.datePipe.transform(this.DADetails.start_date, 'yyyy-MM-dd');
  }

  setEndDate(dt) {
    //console.log(this.newExpenseData.expense_date)
    this.DADetails.end_date = this.datePipe.transform(this.DADetails.end_date, 'yyyy-MM-dd');
  }

  checkAllowStartDate() {
    if (this.task_types !== null) {
      const typeid = this.DADetails.type_id;
      const allowStartDate = this.task_types.filter(type => type.id === typeid).map(obj => obj.allow_start_date);
      console.log(allowStartDate);
      if (allowStartDate !== null) {
        this.allow_start_date = allowStartDate[0];
      }
    }
  }

  viewReport() {
    this.router.navigate([this.apiService.redirect_url + '/reports/task_activation'], {
      state: {

        taskID: this.DADetails.id,
        start_date: this.DADetails.start_date,
        end_date: this.DADetails.end_date
      }
    });
  }

  updateAudit() {

  }

}
