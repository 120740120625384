<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <h2>{{title}}</h2>
    </div>
    <div class="body-section w-100" style="text-align: center;">
      <mat-progress-bar style="height:25px;margin-top: 30px;" class="progressbar" mode="determinate" [value]="progress"></mat-progress-bar>
      <span  style="display:block;float:left;">{{completed}}/{{total}}</span>
      <span  style="display:block;float:right;">{{progress}}%</span>
    </div>
  </div>
</section>

<div #zipDiv *ngIf="zip_order" id="print-section-sales-zip" style=" position:absolute;
   left:-10000px;background: #FFFFFF !important; background-color: #FFFFFF !important;" >
  <div  class="invoice" >
    <app-print *ngIf="!template || (selectedTemplate && selectedTemplate.name == 'Default')"
               [order_detail]="zip_order"></app-print>
    <app-custom-print *ngIf="template && selectedTemplate && selectedTemplate.name != 'Default'"
                      [order_detail]="zip_order"
                      [custom_template]="selectedTemplate"
    ></app-custom-print>
  </div>
</div>
