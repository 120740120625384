import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {MatDialogConfig} from "@angular/material/dialog";
import {ImagePopupComponent} from "../../components/image_popup/image_popup.component";
import {LocationPopupComponent} from "../../components/location_popup/location_popup.component";
import {MeetReviewPopupComponent} from "../../components/meet_review_popup/meet_review_popup.component";

@Component({
  selector: 'app-outlet-last-visit-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class PartnerMeetReportComponent extends ReportBase implements IReport, OnInit {

  uqGifts: any = [];
  uqExpenses: any = [];
  maxImages: any = [];
  meetData;

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    this.printType = 'meet';
    this.sharedService.setHeader('Partner Meet Report');
    this.showableFilters.salesman = true;
    this.showableFilters.territory = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.container = 'meet_gallery';
    this.API_URL = '/api/pwa_reports/partnerMeetReport';
    this.configureGrid();
  }

  configureGrid() {
    const inThis = this;
    this.columnDefs = [
      {
        headerName: '',
        children: [
          {headerName: 'MEET ID', field: 'id', width: 80},
          {headerName: 'REGION', field: 'region', width: 80},
          {headerName: 'STATE', field: 'state', width: 80},
          {headerName: 'CLUSTER', field: 'cluster', width: 80},
          {headerName: 'TERRITORY', field: 'territory', width: 80},
          {headerName: 'SUBJECT', field: 'name', width: 150},
          {headerName: 'PLANNED DATE', field: 'planned_date', width: 100},
          {
            headerName: 'START TIME', field: 'start_time', width: 100,
            valueGetter: params => {
              if (params.data) {
                if (params.data.start_time) {
                  return inThis.datePipe.transform((new Date(params.data.start_time + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
                } else {
                  return params.data.start_time;
                }
              } else {
                return '';
              }
            }
          },
          {
            headerName: 'END TIME', field: 'end_time', width: 100,
            valueGetter: params => {
              if (params.data) {
                if (params.data.end_time) {
                  return inThis.datePipe.transform((new Date(params.data.end_time + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
                } else {
                  return params.data.end_time;
                }
              } else {
                return '';
              }
            }
          },
          {
            headerName: 'TIME SPENT', field: 'time_spent', enableRowGroup: true, width: 80,
            valueGetter: params => {
              if (params.data) {
                if (params.data.end_time && params.data.start_time) {
                  const diff = new Date(params.data.end_time + ' UTC').getTime() - new Date(params.data.start_time + ' UTC').getTime();
                  //return (diff / (1000 * 60)).toFixed(2);
                  let msec = diff;
                  const hh = Math.floor(msec / 1000 / 60 / 60);
                  msec -= hh * 1000 * 60 * 60;
                  const mm = Math.floor(msec / 1000 / 60);
                  msec -= mm * 1000 * 60;
                  const ss = Math.floor(msec / 1000);
                  msec -= ss * 1000;

                  return ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2);
                } else {
                  return 0;
                }
              } else {
                return '';
              }
            }
          },
          {headerName: 'TYPE', field: 'type', width: 80},
          {headerName: 'SCODE', field: 'emp_code', width: 80},
          {headerName: 'SALESMAN', field: 'user', width: 80},
          {headerName: 'ROLE', field: 'user_role', width: 80},
          {headerName: 'TEAM', field: 'team', width: 80},
          {
            headerName: 'LOCATION', field: 'location', width: 80,
            valueGetter(params) {
              if (params.data) {
                if (params.data.lat != null && params.data.lat !== "0.0") {
                  return 'http://maps.google.com/maps?q=' + params.data.lat + ',' + params.data.lng;
                }
              }
            },
            cellRenderer: params => {
              if (params.data) {
                if (params.data.lat != null && params.data.lat !== "0.0") {
                  return '<div style="text-align: center;"> ' +
                    '<span>' +
                    ' <i class="fas fa-map-marker-check fa-2x" style="color:#379862;' +
                    'display: inline-block;\n' +
                    '  vertical-align: middle;padding-bottom: 4px;cursor:pointer;"></i>' +
                    '</span>' +
                    '</div>';
                } else {
                  return '<div style="text-align: center;"> ' +
                    '<span>' +
                    ' <i class="fas fa-map-marker-slash fa-2x" style="color:#D82128;' +
                    'display: inline-block;\n' +
                    '  vertical-align: middle;padding-bottom: 4px;cursor:pointer;"></i>' +
                    '</span>' +
                    '</div>';
                }
              }
            }
          },
          {headerName: 'ADDRESS', field: 'address', width: 150, valueGetter: this.configValueGetter('address')},
          {headerName: 'DESCRIPTION', field: 'description', width: 100},
          {headerName: 'PRODUCT CATEGORY', field: 'product_category', width: 100},
          {
            headerName: 'ADVANCE RECEIVED',
            field: 'adv_received',
            width: 100,
            valueGetter: this.configValueGetter('adv_received')
          },
          {headerName: 'NEXT ACTION PLAN', field: 'next_action_plan', width: 100},
          {headerName: 'SUMMARY', field: 'summary', width: 100},
          {headerName: 'EXP ATT', field: 'exp_attendees', width: 80},
          {headerName: 'ATT COUNT', field: 'att_count', width: 80},
          {headerName: 'TOTAL ATT', field: 'total_att_count', width: 80},
          {headerName: 'STATUS', field: 'status', width: 80},
          {headerName: 'REQ. BUDGET', field: 'req_bud', width: 80},
          {headerName: 'APPR. BUDGET', field: 'appr_bud', width: 80},
          {headerName: 'APPR. BY', field: 'appr_by_name', width: 80},
        ]
      },
      {
        headerName: 'IMAGES',
        children: []
      },
      {
        headerName: 'GIFTS',
        children: []
      },
      {
        headerName: 'EXPENSES',
        children: []
      },
      {
        headerName: '',
        children: []
      },

    ];

    if (this.maxImages > 0) {
      for (let i = 0; i < this.maxImages; i++) {
        this.columnDefs[1].children.push(
          {
            headerName: "Image " + (i + 1),
            field: 'img_' + i,
            width: 80,
            valueGetter: this.generateImageUrl('img_' + i),
            cellRenderer(params) {
              if (params.data) {
                if (!params.data['img_' + i] || params.data['img_' + i] === "") {
                  return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
                } else {
                  return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
                }
              }
            }
          },
        );
      }
    }

    if (this.uqGifts) {
      for (const key of this.uqGifts) {
        this.columnDefs[2].children.push(
          {headerName: key, field: 'gift_' + key, width: 80, aggFunc: 'sum'},
        );
      }
    }

    if (this.uqExpenses && this.uqExpenses.size > 0) {
      for (const key of this.uqExpenses) {
        this.columnDefs[3].children.push(
          {headerName: key, field: 'exp_' + key, width: 80, aggFunc: 'sum'},
        );
      }
      this.columnDefs[3].children.push(
        {headerName: "Approved", field: 'exp_approved', width: 80, aggFunc: 'sum'},
      );
    }
    this.columnDefs[4].children.push({
      headerName: '', field: 'print', width: 80,
      cellRenderer(params) {
        if (params.data) {
          return '<div><button class="btn white-green-btn" style="padding: 2px 30px !important;">Print</button></div>';
        }
      },
    });
    if (this.sharedService.ACLcheck('feature_review_meet')) {
      this.columnDefs.push(
        {
          headerName: 'Review',
          children: [
            {
              headerName: 'Actions', field: 'action', width: 100, pinned: 'left',
              cellRenderer(params) {
                if (params.data) {
                  if (params._self.userid != null && params._self.userid === params.data.create_uid) {
                    return '';
                  } else {
                    if (params.data.reviewed_by > 0) {
                      return '<span style="color: #59a7a2;">Reviewed</span>';
                    } else if (params.data.status === 'completed') {
                      return '<span class="ml-1 status1" style="background: #59a7a2;" data-action-type="review">Review</span>';
                    } else {
                      return '';
                    }
                  }
                }
              },
              cellRendererParams: {
                _self: this
              }
            },
            {headerName: 'REVIEWED BY', field: 'reviewed_by_name', width: 100},
            {headerName: 'COMMENTS', field: 'comments', width: 100},
            {
              headerName: 'TIME', field: 'time', width: 100,
              valueGetter: params => {
                if (params.data) {
                  if (params.data.time && params.data.time !== "") {
                    let dateCreated = params.data.time.replace('T', ' ').replace('|', '').replace('Z', '');
                    return inThis.datePipe.transform((new Date(dateCreated + ' UTC')).toString(), 'yyyy-MM-dd HH:mm');
                  } else {
                    return params.data.time;
                  }
                } else {
                  return '';
                }
              }
            },
          ]
        }
      );
    }
  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }

  configureData() {
    this.rowData = [];
    this.uqExpenses = new Set();
    this.uqGifts = new Set();
    this.maxImages = 0;
    this.apiData.forEach(obj => {
      if (obj.config_json) {
        if (obj.config_json.constructor !== Object) {
          try {
            obj.config_json = JSON.parse(obj.config_json);
          } catch (e) {
          }
        }
        if (obj.config_json.constructor === Object && obj.config_json.hasOwnProperty('gift_json') && obj.config_json.gift_json && obj.config_json.gift_json.length > 0) {
          obj.config_json.gift_json.forEach(gift => {
            this.uqGifts.add(gift.type);
            obj['gift_' + gift.type] = gift.qty;
          });
        }
      }
      if (obj.expense_json) {
        if (!Array.isArray(obj.expense_json)) {
          try {
            obj.expense_json = JSON.parse(obj.expense_json);
          } catch (e) {
          }
        }
        if (obj.expense_json && Array.isArray(obj.expense_json) && obj.expense_json.length > 0) {
          obj['exp_approved'] = 0;
          obj.expense_json.forEach(expense => {
            this.uqExpenses.add(expense.type);
            obj['exp_' + expense.type] = expense.total;
            if (expense.app_amount) {
              obj['exp_approved'] += expense.app_amount;
            }
          });
        }
      }
      if (obj.images) {
        if (obj.images.length > this.maxImages) {
          this.maxImages = obj.images.length;
        }
        for (let i = 0; i < obj.images.length; i++) {
          obj['img_' + i] = obj.images[i];
        }
      }
    });
    this.rowData = this.apiData;
  }

  onCellClicked(event) {

    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    if (column === 'location' && rows[0].hasOwnProperty('lat') && rows[0].lat != null && rows[0].lat !== '') {
      if (screen.width > 991) {
        const config: MatDialogConfig = {
          width: '100vw',
          height: '100vh',
          maxWidth: '70%',
          maxHeight: '95%',
          data: {
            latitude: parseFloat(rows[0].lat),
            longitude: parseFloat(rows[0].lng),
            title1: 'Partner Meet',
            title2: ''
          }
        };

        const creatediaeref = this.dialog.open(LocationPopupComponent, config);
      } else {
        const config: MatDialogConfig = {
          width: '100vw',
          height: '100vh',
          maxWidth: '90%',
          maxHeight: '65%',
          data: {
            latitude: parseFloat(rows[0].lat),
            longitude: parseFloat(rows[0].lng),
            title1: 'Partner Meet',
            title2: ''
          }
        };

        const creatediaeref = this.dialog.open(LocationPopupComponent, config);
      }
    } else if (column.includes('img')) {

      if (rows !== null && rows.length > 0 && rows[0].hasOwnProperty(column) && rows[0][column] !== null && rows[0][column] !== "") {
        if (screen.width > 991) {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "70%",
            maxHeight: "95%",
            data: {
              store_fname: rows[0][column],
              container: this.container
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        } else {
          const config: MatDialogConfig = {
            width: "100vw",
            height: "100vh",
            maxWidth: "90%",
            maxHeight: "65%",
            data: {
              store_fname: rows[0][column],
              container: this.container
            }
          };

          const creatediaeref = this.dialog.open(ImagePopupComponent, config);
        }

      } else {

      }
    } else if (column === 'print') {
      this.print_details = rows;
      const el: HTMLElement = this.interPrint.nativeElement;
      el.click();
    } else if (column === 'action') {
      const actionType = event.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "review": {
          //console.log("Edit action clicked");
          this.openConfirmationDialog(event.data);
          break;
        }
      }
    }


  }

  configValueGetter(field) {
    return (params) => {
      if (params.data && params.data.config_json) {
        try {
          let config_json;
          if (params.data.config_json.constructor !== Object) {
            config_json = JSON.parse(params.data.config_json);
          } else {
            config_json = params.data.config_json;
          }
          if (config_json && config_json.hasOwnProperty(field)) {
            return config_json[field];
          }
        } catch (e) {
        }
      } else {
        return '';
      }
    };
  }

  addressValueGetter() {
    return (params) => {
      if (params.data && params.data.config_json) {
        try {
          let config_json;
          if (params.data.config_json.constructor !== Object) {
            config_json = JSON.parse(params.data.config_json);
          } else {
            config_json = params.data.config_json;
          }
          if (config_json && config_json.hasOwnProperty('address')) {
            return config_json['address'];
          }
        } catch (e) {
        }
      } else {
        return '';
      }
    };
  }

  giftValueGetter(field: string) {
    return (params) => {
      if (params.data && params.data.config_json) {
        try {
          const config_json = JSON.parse(params.data.config_json);
          if (config_json && config_json.hasOwnProperty('gift_json')) {
            return config_json['gift_json'][0][field];
          }
        } catch (e) {
        }
      } else {
        return '';
      }
    };
  }

  expenseValueGetter(field: string) {
    return (params) => {
      if (params.data && params.data.expense_json) {
        try {
          const expense_json = JSON.parse(params.data.expense_json);
          return expense_json[0][field];
        } catch (e) {
        }
      } else {
        return '';
      }
    };
  }

  openConfirmationDialog(row: any) {


    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "550px",
      maxHeight: "500px",
    };

    const confirmdiaeref = this.dialog.open(MeetReviewPopupComponent, config);
    confirmdiaeref.componentInstance.meetReviewSubmit.subscribe(data => {
      this.markReview(row, data);
    });
  }


  markReview(row, comments) {
    let apiurl = '';
    apiurl = '/api/partner_meets/mark_review';
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.id = row.id;
    paramObject.comments = comments;
    this.apiService.post(apiurl, paramObject)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
            this.toastr.success('Mark Reviewed Successfully');
            //window.location.reload();
            this.loadServerData();
          } else {
            this.toastr.error('Error marking Review');
          }
        },
        error => {
          console.log(error);
          this.toastr.error('Error updating Change Request');
        });
  }

}
