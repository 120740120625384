import {ReportBase} from '../_common/reportbase';
import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-retailer-asset-sales',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class UserPrformanceReportComponent extends ReportBase implements IReport, OnInit {

  showDate = false;
  showCustomFilter = true;
  showCustomFilter1 = true;
  customFilter: any = {
    name: 'Year',
    key: 'type',
    value: '',
    values: [],
    show: true
  };
  customFilter1: any = {
    name: 'Performance',
    key: 'id',
    value: 10,
    values: [],
    show: true
  };
  currentYear;
  currentMonth;
  prvsYear;
  monthNames = ["April", "May", "June",
    "July", "August", "September", "October", "November", "December", "January", "February", "March"];

  showQ1 = false;
  showQ2 = false;
  showQ3 = false;
  showQ4 = false;


  setTitle() {
    this.title = 'User Performance Report';
  }

  ngOnInit() {
    this.setTitle();
    this.sharedService.setHeader('Bottom X Performers Report');
    /* this.myDate[0] = '01-03-2022';
     this.myDate[1] = '01-03-2023';
     this.userData.start_date = '01-03-2022';
     this.userData.end_date = '12-03-2023';*/
    this.currentYear = new Date().getFullYear();
    this.prvsYear = this.currentYear - 1;
    this.currentMonth = new Date().getMonth() + 1;
    this.customFilter1.values = [
      {id: 5, itemName: '<5'},
      {id: 10, itemName: '<10'},
      {id: 15, itemName: '<15'},
      {id: 20, itemName: '<20'},
      {id: 25, itemName: '<25'},
      {id: 30, itemName: '<30'},
      {id: 40, itemName: '<40'},
      {id: 50, itemName: '<50'},
    ];
    this.getCustomFilterValues();
    this.dateCheckandUpdate();
    this.configureGrid();
  }

  getCustomFilterValues() {
    if (this.currentMonth < 4) {
      this.customFilter.values = [
        {id: this.currentYear.toString(), itemName: this.prvsYear + '-' + this.currentYear},
        {id: this.prvsYear.toString(), itemName: (this.prvsYear - 1) + '-' + this.prvsYear}
      ];
    } else {
      this.customFilter.values = [
        {id: this.currentYear.toString(), itemName: this.currentYear + '-' + (this.currentYear + 1)},
        {id: this.prvsYear.toString(), itemName: this.prvsYear + '-' + this.currentYear}
      ];
    }
    this.customFilter.value = this.currentYear.toString();
  }

  setCustomFilter(customfilterdata) {
    if (customfilterdata.hasOwnProperty('type')) {
      this.customFilter.value = customfilterdata.type;
    }
    for (const [key, value] of Object.entries(customfilterdata)) {
      this.userData.customFilter[key] = value;
    }
    this.dateCheckandUpdate();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getUserPerformanceReport';
    this.checkQuarterShow();
    this.columnDefs = [
      {
        headerName: '',
        children: [
          {headerName: "STATE", field: "state", width: 100},
          /* {headerName: "PERIOD", field: "period", enableRowGroup: true, width: 100},
           {headerName: "Quarter", field: "qtr", enableRowGroup: true, width: 100},*/
          {headerName: "ID", field: "user_id", width: 100},
          {headerName: "EMP CODE", field: "emp_code", width: 100},
          {headerName: "SALESMAN", field: "user_name", width: 100},
          {headerName: "TEAM", field: "team", width: 100},
          {headerName: "PARENT TEAM", field: "parent_team", width: 100},
        ]
      },
    ];
    if (this.showQ1) {
      this.columnDefs.push(
        {
          headerName: 'Q1',

          children: [
            {
              headerName: "TARGET", field: "q1_target", width: 100, aggFunc: 'sum'},
            {headerName: "ACH", field: "q1_ach", width: 100},
            {
              headerName: "ACH %", field: "q1_ach_pct", width: 100, aggFunc: this.avgAggFunction,
              cellStyle: params => {
                if (params.data) {
                  if (params.value >= 75) {
                    return {backgroundColor: '#B3DFB0'};
                  } else if (params.value > 50) {
                    return {backgroundColor: '#F0E199'};
                  } else {
                    return {backgroundColor: '#F8D1C8'};
                  }
                } else if (params.node && params.node.hasOwnProperty('aggData')) {
                  const value = params.node.aggData.q1_ach_pct.avg;
                  if (value >= 75) {
                    return {backgroundColor: '#B3DFB0'};
                  } else if (value > 50) {
                    return {backgroundColor: '#F0E199'};
                  } else {
                    return {backgroundColor: '#F8D1C8'};
                  }
                }
              }
            }
          ]
        },
      );
    }
    if (this.showQ2) {
      this.columnDefs.push(
        {
          headerName: 'Q2',

          children: [
            {headerName: "TARGET", field: "q2_target", width: 100, aggFunc: 'sum'},
            {headerName: "ACH", field: "q2_ach", width: 100, aggFunc: 'sum'},
            {
              headerName: "ACH %", field: "q2_ach_pct", width: 100, aggFunc: this.avgAggFunction,
              cellStyle: params => {
                if (params.data) {
                  if (params.value >= 75) {
                    return {backgroundColor: '#B3DFB0'};
                  } else if (params.value > 50) {
                    return {backgroundColor: '#F0E199'};
                  } else {
                    return {backgroundColor: '#F8D1C8'};
                  }
                } else if (params.node && params.node.hasOwnProperty('aggData')) {
                  const value = params.node.aggData.q2_ach_pct.avg;
                  if (value >= 75) {
                    return {backgroundColor: '#B3DFB0'};
                  } else if (value > 50) {
                    return {backgroundColor: '#F0E199'};
                  } else {
                    return {backgroundColor: '#F8D1C8'};
                  }
                }
              }
            }
          ]
        });
    }
    if (this.showQ3) {
      this.columnDefs.push(
        {
          headerName: 'Q3',

          children: [
            {headerName: "TARGET", field: "q3_target", width: 100, aggFunc: 'sum'},
            {headerName: "ACH", field: "q3_ach", width: 100, aggFunc: 'sum'},
            {
              headerName: "ACH %", field: "q3_ach_pct", width: 100, aggFunc: this.avgAggFunction,
              cellStyle: params => {
                if (params.data) {
                  if (params.value >= 75) {
                    return {backgroundColor: '#B3DFB0'};
                  } else if (params.value > 50) {
                    return {backgroundColor: '#F0E199'};
                  } else {
                    return {backgroundColor: '#F8D1C8'};
                  }
                } else if (params.node && params.node.hasOwnProperty('aggData')) {
                  const value = params.node.aggData.q3_ach_pct.avg;
                  if (value >= 75) {
                    return {backgroundColor: '#B3DFB0'};
                  } else if (value > 50) {
                    return {backgroundColor: '#F0E199'};
                  } else {
                    return {backgroundColor: '#F8D1C8'};
                  }
                }
              }
            }
          ]
        },
      );
    }
    if (this.showQ4) {
      this.columnDefs.push(
        {
          headerName: 'Q4',

          children: [
            {headerName: "TARGET", field: "q4_target", width: 100, aggFunc: 'sum'},
            {headerName: "ACH", field: "q4_ach", width: 100, aggFunc: 'sum'},
            {
              headerName: "ACH %", field: "q4_ach_pct", width: 100, aggFunc: this.avgAggFunction,
              cellStyle: params => {
                if (params.data) {
                  if (params.value >= 75) {
                    return {backgroundColor: '#B3DFB0'};
                  } else if (params.value > 50) {
                    return {backgroundColor: '#F0E199'};
                  } else {
                    return {backgroundColor: '#F8D1C8'};
                  }
                } else if (params.node && params.node.hasOwnProperty('aggData')) {
                  const value = params.node.aggData.q4_ach_pct.avg;
                  if (value >= 75) {
                    return {backgroundColor: '#B3DFB0'};
                  } else if (value > 50) {
                    return {backgroundColor: '#F0E199'};
                  } else {
                    return {backgroundColor: '#F8D1C8'};
                  }
                }
              }
            }
          ]
        }
      );
    }
    this.columnDefs.push({
        headerName: this.getHeaderName(0),
        children: [
          {headerName: "TARGET", field: "m1_target", width: 100, aggFunc: 'sum'},
          {headerName: "ACH", field: "m1_ach", width: 100, aggFunc: 'sum'},
          {
            headerName: "ACH %", field: "m1_ach_pct", width: 100, aggFunc: this.avgAggFunction,
            cellStyle: params => {
              if (params.data) {
                if (params.value >= 75) {
                  return {backgroundColor: '#B3DFB0'};
                } else if (params.value > 50) {
                  return {backgroundColor: '#F0E199'};
                } else {
                  return {backgroundColor: '#F8D1C8'};
                }
              } else if (params.node && params.node.hasOwnProperty('aggData')) {
                const value = params.node.aggData.m1_ach_pct.avg;
                if (value >= 75) {
                  return {backgroundColor: '#B3DFB0'};
                } else if (value > 50) {
                  return {backgroundColor: '#F0E199'};
                } else {
                  return {backgroundColor: '#F8D1C8'};
                }
              }
            }
          }
        ]
      },
      {
        headerName: this.getHeaderName(1),
        children: [
          {headerName: "TARGET", field: "m2_target", width: 100, aggFunc: 'sum'},
          {headerName: "ACH", field: "m2_ach", width: 100, aggFunc: 'sum'},
          {
            headerName: "ACH %", field: "m2_ach_pct", width: 100, aggFunc: this.avgAggFunction,
            cellStyle: params => {
              if (params.data) {
                if (params.value >= 75) {
                  return {backgroundColor: '#B3DFB0'};
                } else if (params.value > 50) {
                  return {backgroundColor: '#F0E199'};
                } else {
                  return {backgroundColor: '#F8D1C8'};
                }
              } else if (params.node && params.node.hasOwnProperty('aggData')) {
                const value = params.node.aggData.m2_ach_pct.avg;
                if (value >= 75) {
                  return {backgroundColor: '#B3DFB0'};
                } else if (value > 50) {
                  return {backgroundColor: '#F0E199'};
                } else {
                  return {backgroundColor: '#F8D1C8'};
                }
              }
            }
          }
        ]
      },
      {
        headerName: this.getHeaderName(2),
        children: [
          {headerName: "TARGET", field: "m3_target", width: 100, aggFunc: 'sum'},
          {headerName: "ACH", field: "m3_ach", width: 100, aggFunc: 'sum'},
          {
            headerName: "ACH %", field: "m3_ach_pct", width: 100, aggFunc: this.avgAggFunction,
            cellStyle: params => {
              if (params.data) {
                if (params.value >= 75) {
                  return {backgroundColor: '#B3DFB0'};
                } else if (params.value > 50) {
                  return {backgroundColor: '#F0E199'};
                } else {
                  return {backgroundColor: '#F8D1C8'};
                }
              } else if (params.node && params.node.hasOwnProperty('aggData')) {
                const value = params.node.aggData.m3_ach_pct.avg;
                if (value >= 75) {
                  return {backgroundColor: '#B3DFB0'};
                } else if (value > 50) {
                  return {backgroundColor: '#F0E199'};
                } else {
                  return {backgroundColor: '#F8D1C8'};
                }
              }
            }
          }
        ]
      }
    );

    this.columnDefs.push(
      {
        headerName: 'YTD',

        children: [
          {headerName: "TARGET", field: "y_target", width: 100, aggFunc: 'sum'},
          {headerName: "ACH", field: "y_ach", width: 100, aggFunc: 'sum'},
          {
            headerName: "ACH %", field: "y_ach_pct", width: 100, aggFunc: this.avgAggFunction,
            cellStyle: params => {
              if (params.data) {
                if (params.value >= 75) {
                  return {backgroundColor: '#B3DFB0'};
                } else if (params.value > 50) {
                  return {backgroundColor: '#F0E199'};
                } else {
                  return {backgroundColor: '#F8D1C8'};
                }
              } else if (params.node && params.node.hasOwnProperty('aggData')) {
                const value = params.node.aggData.y_ach_pct.avg;
                if (value >= 75) {
                  return {backgroundColor: '#B3DFB0'};
                } else if (value > 50) {
                  return {backgroundColor: '#F0E199'};
                } else {
                  return {backgroundColor: '#F8D1C8'};
                }
              }
            }
          }
        ]
      },
      {headerName: "ROW NO", field: "rn", hide: true, width: 100},
      {headerName: "ROW NO %", field: "rnc", hide: true, width: 100});
  }

  getHeaderName(month) {
    if (this.currentMonth >= 4 && this.currentMonth <= 6) {
      return this.monthNames[month];
    } else if (this.currentMonth >= 7 && this.currentMonth <= 9) {
      return this.monthNames[month + 3];
    } else if (this.currentMonth >= 10 && this.currentMonth <= 12) {
      return this.monthNames[month + 6];
    } else {
      return this.monthNames[month + 9];
    }
  }

  checkQuarterShow() {
    if (this.customFilter.value !== this.currentYear.toString()) {
      this.showQ1 = true;
      this.showQ2 = true;
      this.showQ3 = true;
      this.showQ4 = true;
    } else {
      if (this.currentMonth >= 4) {
        this.showQ1 = true;
      }
      if (this.currentMonth >= 7) {
        this.showQ1 = true;
        this.showQ2 = true;
      }
      if (this.currentMonth >= 10) {
        this.showQ1 = true;
        this.showQ2 = true;
        this.showQ3 = true;
      }
      if (this.currentMonth <= 3) {
        this.showQ1 = true;
        this.showQ2 = true;
        this.showQ3 = true;
        this.showQ4 = true;
      }
    }
  }

  postProcessData() {
    this.configureGrid();
    this.configureData();
  }

  configureData() {
    this.rowData = [];
    const innerthis = this;
    this.apiData.forEach(obj => {
      let indrow = {};
      indrow = obj;
      indrow['q1_target'] = 0;
      indrow['q2_target'] = 0;
      indrow['q3_target'] = 0;
      indrow['q4_target'] = 0;
      indrow['y_target'] = 0;
      indrow['m1_target'] = 0;
      indrow['m2_target'] = 0;
      indrow['m3_target'] = 0;

      indrow['q1_ach'] = 0;
      indrow['q2_ach'] = 0;
      indrow['q3_ach'] = 0;
      indrow['q4_ach'] = 0;
      indrow['y_ach'] = 0;
      indrow['m1_ach'] = 0;
      indrow['m2_ach'] = 0;
      indrow['m3_ach'] = 0;

      const q1obj = obj.performance.filter(x => x.m >= 4 && x.m <= 6);
      if (q1obj !== null && q1obj !== undefined && q1obj.length > 0) {
        q1obj.forEach(q1 => {
          indrow['q1_target'] += q1.t;
          indrow['q1_ach'] += q1.ach;
          if (this.currentMonth >= 4 && this.currentMonth <= 6 && q1.m >= 4 && q1.m <= 6) {
            indrow['m' + (q1.m - 3) + '_target'] = q1.t;
            indrow['m' + (q1.m - 3) + '_ach'] = q1.ach;
          }
        });
      }
      indrow['q1_ach_pct'] = this.calculateAchPercent(indrow['q1_target'], indrow['q1_ach']);

      const q2obj = obj.performance.filter(x => x.m >= 7 && x.m <= 9);
      if (q2obj !== null && q2obj !== undefined && q2obj.length > 0) {
        q2obj.forEach(q2 => {
          indrow['q2_target'] += q2.t;
          indrow['q2_ach'] += q2.ach;
          if (this.currentMonth >= 7 && this.currentMonth <= 9 && q2.m >= 7 && q2.m <= 9) {
            indrow['m' + (q2.m - 3) + '_target'] = q2.t;
            indrow['m' + (q2.m - 3) + '_ach'] = q2.ach;
          }
        });
      }
      indrow['q2_ach_pct'] = this.calculateAchPercent(indrow['q2_target'], indrow['q2_ach']);

      const q3obj = obj.performance.filter(x => x.m >= 10 && x.m <= 12);
      if (q3obj !== null && q3obj !== undefined && q3obj.length > 0) {
        q3obj.forEach(q3 => {
          indrow['q3_target'] += q3.t;
          indrow['q3_ach'] += q3.ach;
          if (this.currentMonth >= 10 && this.currentMonth <= 12 && q3.m >= 10 && q3.m <= 12) {
            indrow['m' + (q3.m - 6) + '_target'] = q3.t;
            indrow['m' + (q3.m - 6) + '_ach'] = q3.ach;
          }
        });
      }
      indrow['q3_ach_pct'] = this.calculateAchPercent(indrow['q3_target'], indrow['q3_ach']);

      const q4obj = obj.performance.filter(x => x.m >= 1 && x.m <= 3);
      if (q4obj !== null && q4obj !== undefined && q4obj.length > 0) {
        q4obj.forEach(q4 => {
          indrow['q4_target'] += q4.t;
          indrow['q4_ach'] += q4.ach;
          if (this.currentMonth >= 1 && this.currentMonth <= 3 && q4.m >= 1 && q4.m <= 3) {
            indrow['m' + (q4.m) + '_target'] = q4.t;
            indrow['m' + (q4.m) + '_ach'] = q4.ach;
          }
        });
      }
      indrow['q4_ach_pct'] = this.calculateAchPercent(indrow['q4_target'], indrow['q4_ach']);

      indrow['y_target'] = indrow['q1_target'] + indrow['q2_target'] + indrow['q3_target'] + indrow['q4_target'];
      indrow['y_ach'] = indrow['q1_ach'] + indrow['q2_ach'] + indrow['q3_ach'] + indrow['q4_ach'];
      indrow['y_ach_pct'] = this.calculateAchPercent(indrow['y_target'], indrow['y_ach']);


      indrow['m1_ach_pct'] = this.calculateAchPercent(indrow['m1_target'], indrow['m1_ach']);
      indrow['m2_ach_pct'] = this.calculateAchPercent(indrow['m1_target'], indrow['m2_ach']);
      indrow['m3_ach_pct'] = this.calculateAchPercent(indrow['m1_target'], indrow['m3_ach']);
      innerthis.rowData.push(indrow);
    });
  }

  calculateAchPercent(target, ach) {
    if (target === 0) {
      return 0;
    } else if (ach > target) {
      return 100;
    } else {
      return Math.floor((ach * 100) / target);
    }
  }

  /*configureData() {
    this.rowData = [];
    const innerthis = this;
    let salesmanData = [];
    salesmanData = this.unique(this.apiData, ['user_id']);
    salesmanData.forEach(obj => {
      let indrow = {};
      indrow = obj;
      indrow['q1_target'] = 0;
      indrow['q2_target'] = 0;
      indrow['q3_target'] = 0;
      indrow['q4_target'] = 0;

      indrow['q1_ach'] = 0;
      indrow['q2_ach'] = 0;
      indrow['q3_ach'] = 0;
      indrow['q4_ach'] = 0;

      const q1obj = innerthis.apiData.filter(x => x.user_id === obj.user_id && x.qtr.split(' ')[0] === 'Q1');
      if (q1obj !== null && q1obj !== undefined && q1obj.length > 0) {
        q1obj.forEach(q1 => {
          indrow['q1_target'] += q1.target;
          indrow['q1_ach'] += q1.ach;
        });
      }
      if (indrow['q1_ach'] > indrow['q1_target']) {
        indrow['q1_ach_pct'] = 100;
      } else if (indrow['q1_target'] === 0) {
        indrow['q1_ach_pct'] = 0;
      } else {
        indrow['q1_ach_pct'] = Math.floor((indrow['q1_ach'] * 100) / indrow['q1_target']);
      }
      const q2obj = innerthis.apiData.filter(x => x.user_id === obj.user_id && x.qtr.split(' ')[0] === 'Q2');
      if (q2obj !== null && q2obj !== undefined && q2obj.length > 0) {
        q2obj.forEach(q2 => {
          indrow['q2_target'] += q2.target;
          indrow['q2_ach'] += q2.ach;
        });
      }
      if (indrow['q2_ach'] > indrow['q2_target']) {
        indrow['q2_ach_pct'] = 100;
      } else if (indrow['q2_target'] === 0) {
        indrow['q2_ach_pct'] = 0;
      } else {
        indrow['q2_ach_pct'] = Math.floor((indrow['q2_ach'] * 100) / indrow['q2_target']);
      }

      const q3obj = innerthis.apiData.filter(x => x.user_id === obj.user_id && x.qtr.split(' ')[0] === 'Q3');
      if (q3obj !== null && q3obj !== undefined && q3obj.length > 0) {
        q3obj.forEach(q3 => {
          indrow['q3_target'] += q3.target;
          indrow['q3_ach'] += q3.ach;
        });
      }
      if (indrow['q3_ach'] > indrow['q3_target']) {
        indrow['q3_ach_pct'] = 100;
      } else if (indrow['q3_target'] === 0) {
        indrow['q3_ach_pct'] = 0;
      } else {
        indrow['q3_ach_pct'] = Math.floor((indrow['q3_ach'] * 100) / indrow['q3_target']);
      }

      const q4obj = innerthis.apiData.filter(x => x.user_id === obj.user_id && x.qtr.split(' ')[0] === 'Q4');
      if (q4obj !== null && q4obj !== undefined && q4obj.length > 0) {
        q4obj.forEach(q4 => {
          indrow['q4_target'] += q4.target;
          indrow['q4_ach'] += q4.ach;
        });
      }
      if (indrow['q4_ach'] > indrow['q4_target']) {
        indrow['q4_ach_pct'] = 100;
      } else if (indrow['q4_target'] === 0) {
        indrow['q4_ach_pct'] = 0;
      } else {
        indrow['q4_ach_pct'] = Math.floor((indrow['q4_ach'] * 100) / indrow['q4_target']);
      }

      innerthis.rowData.push(indrow);
    });
  }*/
}
