import {Component, OnInit} from "@angular/core";
import {formatDate} from "@angular/common";
import {ReportBase} from '../_common/reportbase';
import {MatDialogConfig} from "@angular/material/dialog";
import {RetailAuditPopupComponent} from "../../retail_audit/retail_audit_popup/retail_audit_popup.component";

@Component({
  selector: 'app-retail_audit_report',
  templateUrl: '../_common/reportbase.html',
})

export class RetailAuditReportComponent extends ReportBase implements OnInit {

  showCustomFilter = true;
  showDate = true;
  customFilter: any = {
    name: 'Retail Audit Type',
    key: 'type',
    value: '',
    values: [],
    show: true
  };

  /*showableFilters={
    max_claim:false,
    policy:false,
    salesman:true,
    status:false,
    class:false,
    beat:false,
    trax_outlet:false,
    non_trax_outlet:false,
    exclude_trax_audit:false,
    region:true,
    cluster:true,
    team:true,
    territory:false,
    outlet_type: false,
    dc: false
  }*/

  ngOnInit() {
    this.sharedService.setHeader('Availability Score Report');

    let datas: any = {};
    datas = history.state;
    this.userData.customFilter['id'] = datas.retailauditid;
    this.customFilter.values = [
      {id: "", itemName: "All"},
      {id: "availability", itemName: "Availability"},
      {id: "trax_availability", itemName: "Trax Availability"},
      {id: "trax_branding", itemName: "Trax Branding"}
    ];

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;

    this.configureGrid();


  }


  setTitle() {
    // this.title = 'Beat Performance Reports';
  }

  configureGrid() {

    this.API_URL = '/api/pwa_reports/getAvailabilityAuditsReport';

    // this.myDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    const date = new Date();
    this.myDate[0] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
    date.setDate(date.getDate() + 1);
    this.myDate[1] = this.datePipe.transform(date, 'yyyy-MM-dd');
    //this.dateFilterType = 'period';

    /* this.gridOptions = { rowHeight: 40,
       rowStyle: { 'border-bottom': '#E5E5E5 10px solid', 'text-align': 'left' },
       sideBar: {
         toolPanels: [
           {
             id: 'filters',
             labelDefault: 'Filters',
             labelKey: 'filters',
             iconKey: 'filter',
             toolPanel: 'agFiltersToolPanel',
           },
         ],
         position: 'right',
         defaultToolPanel: '',
       },
       rowSelection: 'multiple'} as GridOptions;*/

    this.columnDefs = [
      {
        headerName: "DATE", field: "date", width: 60,
        cellRenderer: (data) => {
          return data.value ? formatDate(new Date(data.value), 'dd/MM/yyyy', 'en-US').substring(0, 10) : '';
        }
      },
      {headerName: "CLUSTER", field: "cluster", width: 80},
      {headerName: "TERRITORY", field: "territory", width: 80},
      {headerName: "TCODE", field: "tcode", width: 80},
      {headerName: "ZONE", field: "zone", width: 80},
      {headerName: "SALESMAN", field: "user_name", width: 100},
      {headerName: "SCODE", field: "scode", width: 80},
      {headerName: "RETAILER", field: "retailer", width: 100},
      {headerName: "RCODE", field: "code", width: 50},
      {headerName: "AUDIT NAME", field: "audit_name", width: 100},
      {headerName: "TYPE", field: "type", width: 100},
      {headerName: "SCORE %", field: "score", cellStyle: {textAlign: 'center'}, width: 50},
      {headerName: "SCORE JSON", field: "score_json", hide: true, width: 50},
      {
        headerName: "MSL SCORE %", field: "msl_score", cellStyle: {textAlign: 'center'}, width: 50,
        valueGetter: params => {
          if (params.data.score_json != null) {
            const jsonValue = JSON.parse(JSON.stringify(params.data.score_json));
            if (params.data.score_json.hasOwnProperty("P5")) {
              return jsonValue.P5;
            } else {
              return "NA";
            }
          } else {
            return "";
          }
        },
      },
      {
        headerName: "FOCUS SCORE %", field: "focus_score", cellStyle: {textAlign: 'center'}, width: 50,
        valueGetter: params => {
          if (params.data.score_json != null) {
            const jsonValue = JSON.parse(JSON.stringify(params.data.score_json));
            if (jsonValue.hasOwnProperty("P4")) {
              return jsonValue.P4;
            } else {
              return "NA";
            }
          } else {
            return "";
          }
        },
      },
      {
        headerName: "REGIONAL SCORE %", field: "regional_score", cellStyle: {textAlign: 'center'}, width: 50,
        valueGetter: params => {
          if (params.data.score_json != null) {
            const jsonValue = JSON.parse(JSON.stringify(params.data.score_json));
            if (jsonValue.hasOwnProperty("P3")) {
              return jsonValue.P3;
            } else {
              return "NA";
            }
          } else {
            return "";
          }
        },
      },
      {
        headerName: "PRIORITY 2 SCORE %", field: "priority2score", cellStyle: {textAlign: 'center'}, width: 50,
        valueGetter: params => {
          if (params.data.score_json != null) {
            const jsonValue = JSON.parse(JSON.stringify(params.data.score_json));
            if (jsonValue.hasOwnProperty("P2")) {
              return jsonValue.P2;
            } else {
              return "NA";
            }
          } else {
            return "";
          }
        },
      },
      {
        headerName: "PRIORITY 1 SCORE %", field: "priority1score", cellStyle: {textAlign: 'center'}, width: 50,
        valueGetter: params => {
          if (params.data.score_json != null) {
            const jsonValue = JSON.parse(JSON.stringify(params.data.score_json));
            if (jsonValue.hasOwnProperty("P1")) {
              return jsonValue.P1;
            } else {
              return "NA";
            }
          } else {
            return "";
          }
        },
      },
      {headerName: "ERROR", field: "custom_config", cellStyle: {textAlign: 'center'}, width: 100,
        valueGetter: params => {
          if (params.data.custom_config != null) {
            if (params.data.custom_config.hasOwnProperty("error")) {
              return params.data.custom_config.error;
            } else {
              return "NA";
            }
          } else {
            return "";
          }
        },
      },
      {headerName: "COMMENT", field: "custom_config", cellStyle: {textAlign: 'center'}, width: 150,
        valueGetter: params => {
          if (params.data.custom_config != null) {
            if (params.data.custom_config.hasOwnProperty("error_comments")) {
              return params.data.custom_config.error_comments;
            } else {
              return "NA";
            }
          } else {
            return "";
          }
        },
      }

    ];

  }


  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    console.log(rows);
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "700px",
      maxHeight: "600px",
      data: {
        rowData: rows[0]
      }
    };

    const confirmdiaeref = this.dialog.open(RetailAuditPopupComponent, config);

  }


}
