import {Component, OnInit} from "@angular/core";
import {PageBase} from "../pagebase/pagebase";
import {MatDialogConfig} from "@angular/material/dialog";
import {CreateEditProductTemplateComponent} from "./create_edit_product/create_edit_product_template.component";
import {AGGridEditButtonRendererComponent} from "../users/AGGridEditButtonRendererComponent";
import {BulkUploadPopupComponent} from "../components/bulk_upload_popup/bulk_upload_popup.component";


@Component({
  selector: 'app-product-list',
  templateUrl: '../pagebase/pagebase.html',
  styleUrls: ['../pagebase/pagebase.scss']
})

export class ProductsListComponent extends PageBase implements OnInit, IPage {

  ngOnInit(): void {

    this.sharedService.setHeader('Products Master');

    this.frameworkComponents = {
      buttonRenderer: AGGridEditButtonRendererComponent,
    };

    this.customFilter.values = [
      {id: 'true', itemName: 'Active'},
      {id: 'false', itemName: 'Inactive'},
      {id: '', itemName: 'All'}
    ];

    this.showDate = false;
    this.showCreate = true;
    this.showUpload = true;
    this.configureGrid();
    this.loadServerData();
  }

  checkAccessControl() {
    this._localCacheService.getAccessControlList().then(list => {
      this.showCreate = list['action_product_create'];
      this.showUpload = list['action_product_create'];
    });
  }

  configureGrid() {
    this.API_URL = '/api/product_products/getProductsListPWA';
    const inthis = this;
    this.columnDefs = [

      {headerName: 'ID', field: 'id', hide: true},
      {headerName: 'CODE', field: 'family_code'},
      {headerName: 'NAME', field: 'family_name', width: 250},
      {headerName: 'DESC', field: 'description', hide: true},
      {headerName: 'BRAND_ID', field: 'brand_id', hide: true},
      {headerName: 'BRAND', field: 'brand'},
      {headerName: 'CATEG_ID', field: 'categ_id', hide: true},
      {headerName: 'CATEGORY', field: 'category'},
      {
        headerName: 'TAX',
        valueGetter: params => {
          if (params.data) {
            if (params.data.variants && params.data.variants.length > 0) {
              return params.data.variants[0].tax;
            } else {
              return '';
            }
          } else {
            return '';
          }
        }
      },
      {headerName: 'UOM_ID', field: 'uom_id', hide: true},
      {headerName: 'UOM', field: 'uom'},
      {headerName: 'PTD', field: 'ptd', hide: true},
      {headerName: 'PTR', field: 'ptr'},
      {headerName: 'priority', field: 'priority'},
      {headerName: 'SEQ', field: 'seq'},
      {headerName: 'image_path', field: 'image_path', hide: true},
      {headerName: 'image_small_path', field: 'image_small_path', hide: true},
      {headerName: 'ACTIVE', field: 'active'},
      {headerName: 'Variants', field: 'variants', hide: true},
     /* {
        headerName: '',
        cellRenderer: 'buttonRenderer',
        cellRendererParams: {
          onClick: this.onEditClick.bind(this),
          label: 'edit'
        }
      },*/
    ];
  }

  onCellClicked(event) {
    this.onEditClick(event);
  }


  onEditClick(e) {
    const config: MatDialogConfig = {
      data: {
        teamObject: e.rowData
      }
    };

    const confirmdiaeref = this.dialog.open(CreateEditProductTemplateComponent, config);

    confirmdiaeref.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.loadServerData();
      }
    });

  }

  openCreateDialog() {
    const config: MatDialogConfig = {
      data: {
        teamObject: null
      }
    };

    const confirmdiaeref = this.dialog.open(CreateEditProductTemplateComponent, config);

    confirmdiaeref.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.loadServerData();
      }
    });

  }

  showUploadPopup() {

    const tableData = [
      ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
      ['Code', 'Name', 'Category', 'Brand', 'PTR', 'UOM', 'Tax'],
      ['BA12345', 'Dosa Batter', 'Batter', 'ID Fresh', '125.00', 'Units', 'GST (5%)']
    ];
    const sampleData2 = [
      {
        CATEGORY: '',
        BRAND: '',
        'FAMILY CODE': '',
        'FAMILY NAME': '',
        'FAMILY SEQ': '',
        'SKU CODE': '',
        'SKU NAME': '',
        'SKU SEQ': '',
        Description: '',
        UOM: '',
        'UOM CATEGORY': '',
        PTD: '',
        PTR: '',
        PRIORITY: '',
        ACTIVE: '',
        GST: '',
        VAT: '',
        CESS: '',
        EXCISE: '',
      }
    ];
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "85vh",
        maxWidth: "90%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          header: 'Bulk Update Products',
          type: 'upload_product',
          actionText: 'Add or edit product info in the template',
          sampleText1: 'DOWNLOAD EXISTING PRODUCT INFO',
          sampleURL1: '/api/product_products/getProductTemplateListPWA',
          sampleFile1: 'existing_product',
          sampleType1: 'api',
          sampleData1: [],
          sampleText2: 'DOWNLOAD EXCEL TEMPLATE',
          sampleURL2: '/api/product_products/getProductTemplateListPWA',
          sampleFile2: 'product_upload_template',
          sampleType2: 'download',
          sampleData2,
          tableData,
          step1Text: 'Download existing product info or sample excel template for reference',
          step2Text: 'Required fields are Product name, code, category, brand, PTR, UOM, and tax.'
        }
      };
      const creatediaeref = this.dialog.open(BulkUploadPopupComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {
          header: 'Bulk Update Products',
          type: 'upload_product',
          actionText: 'Add or edit product info in the template',
          sampleText1: 'DOWNLOAD EXISTING PRODUCT INFO',
          sampleURL1: '/api/product_products/getProductTemplateListPWA',
          sampleFile1: 'existing_product',
          sampleType1: 'api',
          sampleData1: [],
          sampleText2: 'DOWNLOAD EXCEL TEMPLATE',
          sampleURL2: '/api/product_products/getProductTemplateListPWA',
          sampleFile2: 'product_upload_template',
          sampleType2: 'download',
          sampleData2,
          tableData,
          step1Text: 'Download existing product info or sample excel template for reference',
          step2Text: 'Required fields are Product name, code, category, brand, PTR, UOM, and tax.'
        }
      };
      const creatediaeref = this.dialog.open(BulkUploadPopupComponent, config);
    }
  }

}
