import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-master-zero-sales-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class MasterZeroSalesReportComponent extends ReportBase implements IReport, OnInit {

  showMultiFilter = true;
  multiFilter: any = {
    name: 'Instance',
    key: 'instance',
    value: '',
    values: []
  };

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Master Zero Sales Report');
    // this.showableFilters.salesman = true;
    // this.showableFilters.team = true;
    // this.showableFilters.cluster = true;
    // this.showableFilters.region = true;
    this.showDate = true;
    this.multiFilter.value = this.accountURLS[0];
    this.multiFilter.values = this.accountURLS;

    this.loadInBatches(5000, 'outlet_id');
    this.showProgressCustomText = true;
    this.multiInstance = true;
    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_v2_reports/getMasterZerosales';

    this.columnDefs = [
      {
        headerName: 'Geography',
        children: [
          {headerName: 'INSTANCE', field: 'instance', enableRowGroup: true, width: 80},
          {headerName: 'REGION', field: 'region', enableRowGroup: true, width: 80},
          {headerName: 'CLUSTER', field: 'cluster', enableRowGroup: true, width: 80},
          {headerName: 'TERRITORY', field: 'territory', enableRowGroup: true, width: 80},
          {headerName: 'STATE', field: 'state', enableRowGroup: true, width: 80},
          {headerName: 'DISTRICT', field: 'zone', enableRowGroup: true, width: 80},
        ]
      },
      {
        headerName: 'Outlet',
        children: [
          {headerName: 'ID', field: 'outlet_id', width: 80},
          {headerName: 'CODE', field: 'code', width: 80},
          {headerName: 'NAME', field: 'name', width: 80},
          {headerName: 'CLASS', field: 'class_type', enableRowGroup: true, width: 80},
          {headerName: 'TYPE', field: 'outlet_type', enableRowGroup: true, width: 80},
          {headerName: 'PROGRAM', field: 'program_type', enableRowGroup: true, width: 80}
        ]
      },
      {
        headerName: 'Visits',
        children: [
          {
            headerName: 'STATUS', field: 'visit_count', enableRowGroup: true, width: 80,
            valueGetter: params => {
              if (params.data) {
                if (params.data.visit_count && params.data.visit_count > 0) {
                  return 'NOT BILLED';
                } else {
                  return 'NOT VISITED';
                }
              }
            }
          },
          {headerName: 'LAST VISIT', field: 'last_visited', width: 80},
          {headerName: 'VISIT BY', field: 'user_name', enableRowGroup: true, width: 80},
          {headerName: 'EMP CODE', field: 'emp_code', width: 80},
          {headerName: 'REASON', field: 'reason', enableRowGroup: true, width: 80},
          {headerName: 'COUNT', field: 'visit_count', width: 80}
        ]
      }
    ];

  }

}
