<div class="dash-card {{category}}" >

  <app-gadget-header
    (mouseover)="showGadgetControls(true)"
    (mouseleave)="showGadgetControls(false)"
    [title]="title"
    [inRun]="inRun"
    [inConfig]="inConfig"
    [actionInitiated]="actionInitiated"
    [showOperationControls]="showOperationControls"
    [gadgetHasOperationControls]="gadgetHasOperationControls"
    [showConfigurationControl]="showConfigurationControl"
    [showControls]="showControls"
    [showDeleteControl]="showDeleteControl"
    (toggleConfigModeEvent)="toggleConfigMode()"
    (removeEvent)="remove()"
    (runEvent)="run()"
    (stopEvent)="stop()"
    (redirectEvent)="redirect()"
    (helpEvent)="help()">
  </app-gadget-header>
<div *ngIf="dataUpdated && !inConfig">
  <div class="dash-card-three-col-row">

    <div *ngFor="let col of data; let index = index" [ngClass]="getClassOf(index)">
      <div><h2>{{col.value1}}</h2></div>
      <div><span>{{col.value2}}</span></div>
      <div><label>{{col.label}}</label></div>
    </div>

  </div>
  <app-dynamic-form *ngIf="inConfig"
                    [gadgetTags]="gadgetTags"
                    [propertyPages]="propertyPages"
                    [instanceId]="instanceId"
                    (updatePropertiesEvent)="updateProperties($event)">
  </app-dynamic-form>

  <app-error-handler
    [errorObject]="errorObject"
    [errorExists]="errorExists">
  </app-error-handler>

</div>
