import {Component, ElementRef, NgZone, OnInit, Renderer2} from "@angular/core";
import {ApiService} from "../../../../../_services/api.service";
import {Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {SharedService} from "../../../../../_services/shared_service";
import {DomSanitizer} from "@angular/platform-browser";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {PartialApprovePopupComponent} from "../../../../../components/partial_approve_popup/partial_approve_popup.component";
import {PartialRejectPopupComponent} from "../../../../../components/partial_reject_popup/partial_reject_popup.component";
import {ImagePopupComponent} from "../../../../../components/image_popup/image_popup.component";
import {ConfirmDialogComponent} from "../../../../../components/confirmdialog/confirmdialog.component";

@Component({
  selector: 'app-expense-claim-detail-component',
  templateUrl: './expense_claim_detail.component.html',
  styleUrls: ['./expense_claim_detail.component.scss']
})
export class ExpenseClaimDetailComponent implements OnInit {

  dataLoaded = false;
  allSelect = false;
  selectedCount = 0;
  expenseDetailsData: any = [];
  expenseMasterData: any = {};
  active_ids = [];
  activeID = 0;
  rowIndex = 0;
  listLength = 0;
  showApprove = false;
  showCancel = false;
  apprtype = 'appr2';
  beatActivityData = [];
  showDetailText = 'View More';
  expensesToReview = 0;

  constructor(private apiService: ApiService,
              private router: Router,
              private datePipe: DatePipe,
              private renderer: Renderer2,
              private sharedService: SharedService,
              private ngzone: NgZone,
              private elRef: ElementRef,
              private domSanitizer: DomSanitizer,
              public dialog: MatDialog,
              private toastr: ToastrService) {
    this.active_ids = JSON.parse(localStorage.getItem('active_ids'));
    this.activeID = parseInt(localStorage.getItem('activeID'), 10);
    this.rowIndex = this.active_ids.indexOf(this.activeID);
    this.listLength = this.active_ids.length;
    if (localStorage.getItem('beat_activity_types') != null) {
      this.beatActivityData = JSON.parse(localStorage.getItem('beat_activity_types'));
    }
    this.beatActivityData = this.beatActivityData.filter(bat => bat.action_type === 'Expense Reject Reason');
    this.fetchExpenseDetails();
  }

  ngOnInit(): void {
    this.sharedService.setHeader("Expense Claim Details");
  }

  fetchExpenseDetails() {
    this.expensesToReview = 0;
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.expense_id = this.activeID;
    this.apiService.post('/api/sales_expenses/expense_claim_detail', paramObject)
      .subscribe(async res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200) && (res.results.data)) {
          if (res.results.data.master && res.results.data.master.length > 0) {
            this.expenseMasterData = res.results.data.master[0];
            if (this.expenseMasterData.user_id === JSON.parse(localStorage.getItem('user')).id) {
              this.showCancel = true;
            }
          }
          this.expenseDetailsData = res.results.data.detail;
          this.dataLoaded = true;
          let images = [];
          for (const exp of this.expenseDetailsData) {
            if (exp.state === 'new' || exp.state === 'processing') {
              this.expensesToReview++;
            }
            exp.selected = false;
            exp.show_details = false;
            if (exp.apr1_date) {
              exp.apr1_date = this.datePipe.transform((new Date(exp.apr1_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            }
            if (exp.apr2_date) {
              exp.apr2_date = this.datePipe.transform((new Date(exp.apr2_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            }
            if (exp.state === 'rejected' && exp.config_json) {
              if (exp.config_json.reject_reason) {
                exp.reject_comments = exp.config_json.reject_reason;
                if (exp.config_json.reject_comments) {
                  exp.reject_comments += " - " + exp.config_json.reject_comments;
                }
              }
            }

            images = [];
            exp.photo = null;
            if (exp.gallery.length > 0) {
              exp.gallery.forEach(image => {
                if (image != null) {
                  images.push({store_fname: image.store_fname, container: 'expense_gallery', type: image.type});
                }
              });
              if (images.length > 0) {
                const payload = {
                  access_token: localStorage.getItem('resfreshToken'),
                  data: images
                };
                const res1 = await this.apiService.postPromise('/api/pwa_connects/fetch_image', payload);
                if (res1.hasOwnProperty('result') && res1['result'].length > 0) {
                  exp.gallery = [];
                  let img;
                  const domSanitizer = this.domSanitizer;
                  res1['result'].forEach(photo => {
                    if (!exp.photo) {
                      if (photo.type && photo.type === 'pdf') {
                        exp.photo = 'assets/pdf_icon.png';
                      } else {
                        exp.photo = this.domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + res1['result'][0].bill_datas);
                      }
                    }
                    if (photo.type && photo.type === 'pdf') {
                      img = domSanitizer.bypassSecurityTrustUrl('data:application/pdf;base64, ' + photo.bill_datas);
                    } else {
                      img = domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + photo.bill_datas);
                    }
                    exp.gallery.push(
                      {
                        photo: img,
                        type: photo.type
                      });
                  });
                }
              }
            }
          }
          if (this.sharedService.ACLcheck('feature_expense_approve2')) {
            this.apprtype = 'appr2';
            this.showApprove = true;
          } else if (this.sharedService.ACLcheck('feature_expense_approve1')) {
            this.apprtype = 'appr1';
            this.showApprove = true;
          }
        }
      });
  }

  showDetails(expense) {
    expense.show_details = !expense.show_details;
    if (expense.show_details) {
      this.showDetailText = 'View Less';
    } else {
      this.showDetailText = 'View More';
    }
  }

  moveNext() {

    const curIndex = this.active_ids.indexOf(this.activeID);
    if (curIndex >= 0 && curIndex < (this.active_ids.length - 1)) {
      this.rowIndex = curIndex + 1;
      this.activeID = this.active_ids[this.rowIndex];
      this.fetchExpenseDetails();
    }
  }

  movePrevious() {
    const curIndex = this.active_ids.indexOf(this.activeID);
    if (curIndex > 0) {
      this.rowIndex = curIndex - 1;
      this.activeID = this.active_ids[this.rowIndex];
      this.fetchExpenseDetails();
    }
  }

  cancelMaster() {

  }

  checkExpenseSelectable(expense) {
    if (expense.state === 'new' ||
      (expense.state === 'processing' && this.apprtype === 'appr1' && expense.app1_state !== 'rejected') ||
      (expense.state === 'processing' && this.apprtype === 'appr2') || (expense.state === 'approved' && this.apprtype === 'appr2')) {

    }
  }


  selectAll() {
    if (this.expenseDetailsData && this.expenseDetailsData.length > 0) {
      this.expenseDetailsData.forEach((expense) => {
        expense.selected = this.allSelect;
      });
    }
  }

  selectExpense() {

  }

  approvePopup(exp) {
    exp.app_amount = exp.amount;
    const config: MatDialogConfig = {
      width: '100vw',
      height: 'auto',
      maxWidth: '350px',
      maxHeight: '100%',
      data: {
        exp
      },
      panelClass: 'custom-dialog-container'
    };

    const confirmdiaeref = this.dialog.open(PartialApprovePopupComponent, config);

    confirmdiaeref.componentInstance.partialrapprovesubmit.subscribe(data => {
      exp.app_amount = data;
      this.approveSales(exp);

    });

  }

  rejectPopup(exp) {
    const config: MatDialogConfig = {
      width: '100vw',
      height: 'auto',
      maxWidth: '400px',
      maxHeight: '100%',
      panelClass: 'custom-dialog-container',
      data: {
        beatActivityData: this.beatActivityData,
        title: 'Reject Expense'
      }
    };

    const confirmdiaeref = this.dialog.open(PartialRejectPopupComponent, config);

    confirmdiaeref.componentInstance.partialrejectsubmit.subscribe(data => {

      exp.reason_id = data.reason_id;
      exp.reject_reason = data.reject_reason;
      exp.reject_comments = data.reject_comments;
      this.rejectSales(exp);

    });
  }

  approveSales(exp) {
    const expense_ids = [];
    expense_ids.push(exp.se_id);
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.app_amount = exp.app_amount;
    paramObject.expense_id = exp.se_id;
    paramObject.apprtype = this.apprtype;
    paramObject.master_id = exp.master_id;
    this.apiService.post('/api/sales_expenses/sales_expense_approve', paramObject)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success('Approved Successfully');
          if (this.apprtype === 'appr2') {
            exp.state = 'approved';
            exp.app2_state = 'approved';
          } else {
            exp.state = 'processing';
            exp.app1_state = 'approved';

          }
          this.fetchExpenseDetails();
        } else {
          this.toastr.error('Approval Failure');
        }
      }, error => {
        this.toastr.error('Approval Failure');
      });
  }

  rejectSales(exp) {
    const expense_ids = [];
    expense_ids.push(exp.se_id);
    const paramObject: any = {};
    paramObject.cid = JSON.parse(localStorage.getItem('user')).cid;
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.expense_ids = expense_ids;
    paramObject.apprtype = this.apprtype;
    paramObject.reason_id = exp.reason_id;
    paramObject.reject_reason = exp.reject_reason;
    paramObject.reject_comments = exp.reject_comments;
    paramObject.master_id = exp.master_id;
    this.apiService.post('/api/sales_expenses/reject_sales_expense', paramObject)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          if (this.apprtype === 'appr2') {
            exp.state = 'rejected';
            exp.app2_state = 'rejected';
            exp.app_amount = 0;
          } else {
            exp.state = 'processing';
            exp.app1_state = 'rejected';
            exp.app_amount = 0;
          }
          this.fetchExpenseDetails();
          this.toastr.success('Rejection Success');
        } else {
          this.toastr.error('Rejection Failure');
        }
      }, error => {
        this.toastr.error('Rejection Failure');
      });
  }

  showImagePopup(image) {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: '100vw',
        height: '100vh',
        maxWidth: '70%',
        maxHeight: '95%',
        data: {
          store_fname: '',
          photo: image,
          container: 'expense'
        }
      };

      const creatediaeref = this.dialog.open(ImagePopupComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: '100vw',
        height: '100vh',
        maxWidth: '90%',
        maxHeight: '65%',
        data: {
          store_fname: '',
          photo: image,
          container: 'expense'
        }
      };

      const creatediaeref = this.dialog.open(ImagePopupComponent, config);
    }

  }

  openPDFinNewTab(pdf) {
    if (pdf && pdf !== 'null') {
      let pdfSrc = pdf.toString();
      pdfSrc = pdfSrc.replace('SafeValue must use [property]=binding: data:application/pdf;base64, ', '');
      pdfSrc = pdfSrc.replace(' (see http://g.co/ng/security#xss)', '');
      let byteCharacters;
      if (pdfSrc === "null") {
        console.log(pdf);
        this.toastr.error("Invalid PDF");
      } else {
        try {
          byteCharacters = atob(pdfSrc);
        } catch (e) {
          console.log(pdf);
          this.toastr.error("Invalid PDF");
        }
        if (byteCharacters) {
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const file = new Blob([byteArray], {type: 'application/pdf'});
          const blobUrl = URL.createObjectURL(file);
          window.open(blobUrl);
        }
      }
    } else {
      console.log(pdf);
      this.toastr.error("Invalid PDF");
    }

  }

  showCancelPopup(exp) {

    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "550px",
      maxHeight: "250px",
      data: {
        title: "Cancel Expense",
        message1: "Are you sure you want to cancel the Expense?",
        message2: "",
        showNoButton: true,
        yesButton: "CONFIRM",
        noButton: "CANCEL",
        deleteButton: true
      }
    };

    const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);
    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {
      if (data === 'yes') {
        this.cancelSales(exp);
      } else {
        this.dialog.closeAll();
      }
    });
  }

  showMasterApproveRejectPopup(type, rejectdata) {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "550px",
      maxHeight: "250px",
      data: {
        title: type + " Expense",
        message1: "Are you sure you want to " + type.toString().toLowerCase() + " all the Expenses?",
        message2: "",
        showNoButton: true,
        yesButton: "CONFIRM",
        noButton: "CANCEL",
      }
    };
    if (type === 'Reject') {
      config.data.deleteButton = true;
    }


    const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);
    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {
      if (data === 'yes') {
        if (type === 'Approve') {
          this.masterApprove();
        } else {
          this.masterReject(rejectdata);
        }
      } else {
        this.dialog.closeAll();
      }
    });
  }

  masterRejectPopup() {
    const config: MatDialogConfig = {
      width: '100vw',
      height: 'auto',
      maxWidth: '400px',
      maxHeight: '100%',
      panelClass: 'custom-dialog-container',
      data: {
        beatActivityData: this.beatActivityData,
        title: 'Reject Expense'
      }
    };

    const confirmdiaeref = this.dialog.open(PartialRejectPopupComponent, config);

    confirmdiaeref.componentInstance.partialrejectsubmit.subscribe(data => {
      this.showMasterApproveRejectPopup('Reject', data);
    });
  }


  masterApprove() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.expense_ids = [this.expenseMasterData.id];
    paramObject.apprtype = this.apprtype;
    this.apiService.post('/api/sales_expenses/approve_expense', paramObject)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success('Approved Successfully');
          this.fetchExpenseDetails();
        } else {
          this.toastr.error('Approve Failed');
        }
      }, error => {
        this.toastr.error('Approve Failed');
      });
  }

  masterReject(data) {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.expense_ids = [this.expenseMasterData.id];
    paramObject.apprtype = this.apprtype;
    paramObject.reason_id = data.reason_id;
    paramObject.note = data.reject_comments;
    this.apiService.post('/api/sales_expenses/reject_expense', paramObject)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success('Rejected Successfully');
          this.fetchExpenseDetails();
        } else {
          this.toastr.error('Reject Failed');
        }
      }, error => {
        this.toastr.error('Reject Failed');
      });
  }


  cancelSales(exp) {
    const paramObject: any = {};
    paramObject.cid = JSON.parse(localStorage.getItem('user')).cid;
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.app_amount = exp.app_amount;
    paramObject.expense_id = exp.se_id;
    paramObject.apprtype = this.apprtype;
    this.apiService.post('/api/sales_expenses/cancel_expense', paramObject)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success('Cancelled Successfully');
          exp.state = 'cancelled';
          this.fetchExpenseDetails();

        } else {
          this.toastr.error('Cancel Failure');
        }
      }, error => {
        this.toastr.error('Cancel Failure');
      });
  }

}
