import {Component, ElementRef, NgZone, OnInit, Renderer2} from "@angular/core";
import {ApiService} from "../../_services/api.service";
import {Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {SharedService} from "../../_services/shared_service";
import {DomSanitizer} from "@angular/platform-browser";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {ApproveRejectCommentsPopupComponent} from "../../components/approve_reject_comments_popup/approve_reject_comments_popup.component";
import {NumericEditorComponent} from "../../pagebase/components/NumericEditorComponent";
import {AssignServiceEngineerPopupComponent} from "../assign_service_engineer_popup/assign_service_engineer_popup.component";

@Component({
  selector: 'app-service-request-details-component',
  templateUrl: './service_request_details.component.html',
  styleUrls: ['./service_request_details.component.scss']
})
export class ServiceRequestDetailsComponent implements OnInit {
  userData = {
    start_date: null,
    end_date: null,
    period_id: 0,
    access_token: null,
    url: null,
    offset: null,
    offsetID: 0,
    flag: true,
    type: '',
    data: {
      id: null,
      date: null
    },
    filterData: {
      date: null,
      access_token: null,
      url: null,
      last_date: null,
      offset: null,
      flag: true,
      out_type: -1,
      salesmanID: 0,
      policyID: 0,
      outlet_type: 0,
      travel_type_id: 0,
      class_type: null,
      program_type: 0,
      status: null,
      max_claim: false,
      trax_outlet: false,
      non_trax_outlet: false,
      exclude_trax_audit: false,
      region_id: 0,
      cluster_id: 0,
      team_id: 0,
      territory_id: 0,
      dc_id: 0,
      displayData: [],
      payment_mode: ''
    },
    customFilter: {}
  };
  service_ids: any = [];
  serviceDetails: any = {};
  rowIndex = 0;
  profile_name = '';
  dataLoaded = false;
  productDataLoaded = false;
  spareDataLoaded = false;

  productEmptyState = false;
  spareEmptyState = false;
  gridOptions: GridOptions;
  styleGrid: any;
  columnDefs = [];
  frameworkComponents: any = {};
  context: any;
  public getRowId;
  public gridColumnApi: any;
  public gridApi: any;

  gridOptions1: GridOptions;
  styleGrid1: any;
  columnDefs1 = [];
  frameworkComponents1: any = {};
  context1: any;
  public getRowId1;
  public gridColumnApi1: any;
  public gridApi1: any;

  productData = [];
  spareData = [];
  showAssets = true;
  showProducts = false;
  showSpares = false;
  priority = 0;
  editMode = false;
  assetLinked = false;
  showServiceEngineerAssign = false;

  constructor(private apiService: ApiService,
              private router: Router,
              private datePipe: DatePipe,
              private renderer: Renderer2,
              private sharedService: SharedService,
              private ngzone: NgZone,
              private elRef: ElementRef,
              private domSanitizer: DomSanitizer,
              public dialog: MatDialog,
              private toastr: ToastrService) {
    this.service_ids = JSON.parse(localStorage.getItem('service_ids'));
    this.rowIndex = parseInt(localStorage.getItem('index'), 0);
    this.userData.data.id = this.service_ids[this.rowIndex];
    this.profile_name = JSON.parse(localStorage.getItem('user')).profile_name;


    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        }
      }
    } as GridOptions;

    this.gridOptions1 = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        }
      }
    } as GridOptions;

    this.configureProductGrid();
    this.configureSpareGrid();
    this.getServiceDetails();
  }

  ngOnInit(): void {
    this.sharedService.setHeader("Service Request Details");
  }

  fetchPreviousData() {
    if (this.rowIndex !== 0) {
      this.rowIndex = this.rowIndex - 1;
      this.userData.data.id = this.service_ids[this.rowIndex];
      this.getServiceDetails();
    }
  }

  fetchNextData() {
    if (this.rowIndex !== this.service_ids.length - 1) {
      this.rowIndex = this.rowIndex + 1;
      this.userData.data.id = this.service_ids[this.rowIndex];
      this.getServiceDetails();
    }
  }

  getServiceDetails() {
    this.dataLoaded = false;
    this.productDataLoaded = false;
    this.productEmptyState = false;
    this.spareDataLoaded = false;
    this.serviceDetails = {};
    this.productData = [];
    this.spareData = [];
    this.priority = 0;
    this.editMode = false;
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject['data'] = {
      id: this.userData.data.id
    };
    this.apiService.post('/api/service_requests/getProductServiceTicketDetails', paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.dataLoaded = true;
          this.serviceDetails = res.results.data[0];
          if (this.sharedService.ACLcheck('feature_service_assign_service_engineer') && this.serviceDetails.service_status === 'UNASSIGNED') {
            this.showServiceEngineerAssign = true;
          } else {
            this.showServiceEngineerAssign = false;
          }
          if (this.serviceDetails.partner_asset_id) {
            this.assetLinked = true;
          } else {
            this.assetLinked = false;
          }
          this.priority = this.serviceDetails['priority'];
          if (!this.priority) {
            this.priority = 0;
          } else {
            this.priority = parseInt(String(this.priority), 10);
          }
          /*if (this.serviceDetails.service_start) {
            this.serviceDetails.start_date = this.datePipe.transform((new Date(this.serviceDetails.start_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm');
          }
          if (this.serviceDetails.service_closed) {
            this.serviceDetails.service_closed = this.datePipe.transform((new Date(this.serviceDetails.service_closed + ' UTC')).toString(), 'yyyy-MM-dd HH:mm');
          }*/
          /*if (this.serviceDetails.d_status) {
            this.serviceDetails.d_status = this.sharedService.removeUnderscoreAndFirstLetterCapital(this.serviceDetails.d_status);
          }*/
          if (this.serviceDetails.sp_app_on) {
            this.serviceDetails.sp_app_on = this.datePipe.transform((new Date(this.serviceDetails.sp_app_on + ' UTC')).toString(), 'yyyy-MM-dd HH:mm');
          }
          if (this.serviceDetails.sp_del_on) {
            this.serviceDetails.sp_del_on = this.datePipe.transform((new Date(this.serviceDetails.sp_del_on + ' UTC')).toString(), 'yyyy-MM-dd HH:mm');
          }
          if (this.serviceDetails?.service_line_json) {
            this.productData = this.serviceDetails.service_line_json;
          }
          if (this.productData === null || this.productData === undefined) {
            this.productEmptyState = true;
          } else if (this.productData.length === 0) {
            this.productEmptyState = true;
          } else {
            this.productDataLoaded = true;
            this.productEmptyState = false;
          }

          if (this.serviceDetails?.spare_request_json) {
            this.spareData = this.serviceDetails.spare_request_json;
          }
          if (this.spareData === null || this.spareData === undefined) {
            this.spareEmptyState = true;
          } else if (this.spareData.length === 0) {
            this.spareEmptyState = true;
          } else {
            this.spareDataLoaded = true;
            this.spareEmptyState = false;
          }
        }
      });
  }

  configureProductGrid() {
    this.columnDefs = [
      {headerName: 'Code', field: 'product_code', minWidth: 150},
      {headerName: 'Product', field: 'product_name', minWidth: 250},
      {headerName: 'Quantity', field: 'qty', cellStyle: {textAlign: 'right'}},
      {headerName: 'Category', field: 'category'},
      {headerName: 'Within Warranty', field: 'warranty'},
      {headerName: 'Invoice By', field: 'invoiced_by'},
      {headerName: 'Invoice No. ', field: 'invoiced_by'},
      {headerName: 'Inv file atch', field: 'file'},
      {headerName: 'Type of Defect', field: 'product_code'},
      {headerName: 'Repair Status', field: 'product_code'},
      {headerName: 'Spare Used', field: 'product_code'},
    ];
  }

  configureSpareGrid() {
    this.columnDefs1 = [
      {headerName: 'Code', field: 'product_code', minWidth: 150},
      {headerName: 'Product', field: 'product_name', minWidth: 250},
      {headerName: 'Type', field: 'qty'},
      {headerName: 'Requested Date', field: 'category'},
      {headerName: 'Requested By', field: 'warranty'},
      {headerName: 'Requested Qty', field: 'qty', cellStyle: {textAlign: 'right'}},
      {
        headerName: 'Approved Qty ', field: 'app_qty', editable: true, cellRenderer: NumericEditorComponent,
        cellStyle: params => {
          if (params.data) {
            if (this.editMode) {
              return {backgroundColor: '#f2f6d7', textAlign: 'right'};
            } else {
              return {backgroundColor: '#cae4f8', textAlign: 'right'};
            }
          }
        }
      },
      {headerName: 'Delivered Qty', field: 'del_qty', cellStyle: {textAlign: 'right'}, editable: true, cellRenderer: NumericEditorComponent}
    ];
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllDisplayedColumns().length;
    params.api.setRowData(this.productData);
    //this.preloader = false;
    const inThis = this;

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      });
    });
    this.gridApi.sizeColumnsToFit();
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  onGridReady1(params) {
    this.gridOptions1.api.showLoadingOverlay();

    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
    params.api.setRowData(this.spareData);
    //this.preloader = false;
    const inThis = this;

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      });
    });
    this.gridApi1.sizeColumnsToFit();
    this.styleGrid1 = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  onCellClicked(event) {

  }

  onCellClicked1(event) {

  }

  assetCardClick() {
    this.showAssets = !this.showAssets;
  }
  productCardClick() {
    this.showProducts = !this.showProducts;
  }

  spareCardClick(event) {
    event.stopPropagation();
    this.showSpares = !this.showSpares;
  }

  editClick(event) {
    event.stopPropagation();
    this.editMode = true;
  }

  openApproveRejectPopup(type, event) {
    event.stopPropagation();
    let title = '';
    let message1 = '';
    let message2 = '';
    let buttonText = '';

    if (type === 'reject') {
      title = "Reject Spare Request";
      message1 = "Are you sure you want to Reject Spare Request?";
      message2 = "Please provide a detailed reason for the rejection";
      buttonText = "Reject";
    } else {
      title = "Approve Spare Request";
      message1 = "Are you sure you want to Approve Spare Request?";
      message2 = "Please provide Approval Comments";
      buttonText = "Approve";
    }

    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: 'auto',
        maxWidth: "50%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          title,
          message1,
          message2,
          type,
          buttonText
        }
      };
      const creatediaeref = this.dialog.open(ApproveRejectCommentsPopupComponent, config);
      creatediaeref.componentInstance.submitClicked.subscribe(data => {
        if (type === 'reject') {
          this.approveRejectService('reject', data);
        } else {
          this.approveRejectService('approve', data);
        }
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: 'auto',
        maxWidth: "95%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          title,
          message1,
          message2,
          type,
          buttonText
        }
      };
      const creatediaeref = this.dialog.open(ApproveRejectCommentsPopupComponent, config);
      creatediaeref.componentInstance.submitClicked.subscribe(data => {
        if (type === 'reject') {
          this.approveRejectService('reject', data);
        } else {
          this.approveRejectService('approve', data);
        }
      });
    }
  }

  approveRejectService(state, comments) {
    let status = '';
    if (state === 'reject') {
      status = 'rejected';
    } else {
      status = 'approved';
    }
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      id: this.serviceDetails.id,
      comments,
      status,
      spares: this.spareData
    };
    this.apiService.post('/api/service_requests/approve_spare_request', paramObject)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success(state.toUpperCase() + " Successfully");
          this.getServiceDetails();
        } else {
          this.toastr.error("Failed to " + state.toString().toUpperCase() + " Spare Request");
        }
      });
  }

  openMarkDispatchPopup(event) {
    event.stopPropagation();
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: 'auto',
        maxWidth: "50%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          title : "Dispatch Spare Request",
          message1 : "Are you sure you want to Dispatch Spare Request?",
          message2 : "Please provide Dispatch Comments",
          buttonText : "Dispatch",
          type: 'delivered'
        }
      };
      const creatediaeref = this.dialog.open(ApproveRejectCommentsPopupComponent, config);
      creatediaeref.componentInstance.submitClicked.subscribe(data => {
        this.markDispatch(data);
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: 'auto',
        maxWidth: "95%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          title : "Dispatch Spare Request",
          message1 : "Are you sure you want to Dispatch Spare Request?",
          message2 : "Please provide Dispatch Comments",
          buttonText : "Dispatch",
          type: 'delivered'
        }
      };
      const creatediaeref = this.dialog.open(ApproveRejectCommentsPopupComponent, config);
      creatediaeref.componentInstance.submitClicked.subscribe(data => {
        this.markDispatch(data);
      });
    }

  }

  markDispatch(comments) {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      id: this.serviceDetails.id,
      comments,
      status: 'delivered',
      spares: this.spareData
    };
    this.apiService.post('/api/service_requests/deliver_spare_request', paramObject)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success("Marked Dispatch Successfully");
          this.getServiceDetails();
        } else {
          this.toastr.error("Failed to Marked Dispatch Spare Request");
        }
      });
  }

  openAssignServiceEngineerPopup() {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "650px",
        height: "auto",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          rows: [this.serviceDetails]
        }
      };
      const creatediaeref = this.dialog.open(AssignServiceEngineerPopupComponent, config);
      creatediaeref.componentInstance.serviceExeAssignedEvent.subscribe(data => {
        this.getServiceDetails();
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {
          rows: [this.serviceDetails]
        }
      };
      const creatediaeref = this.dialog.open(AssignServiceEngineerPopupComponent, config);
      creatediaeref.componentInstance.serviceExeAssignedEvent.subscribe(data => {
        this.getServiceDetails();
      });
    }
  }

}
